import React, {useEffect, useState} from 'react'
import {Button, Drawer, notification, Form, Card} from 'antd'
import {listAllUniversities, listQuickSearchUniversities} from '../../university/actions/university'
import {listAllCourse, loadCourseIntakesFxn} from '../../course/actions/courseAction'
import {useDispatch} from 'react-redux'
import {listAllCountries} from '../../countries/actions/countries'
import {checkBranchUserExists} from '../../student/actions/student'
import {FormUtils as GetAllFormFields} from 'sz-react-utils-lite'
import _ from 'lodash'
import {RowTable} from '../../../components/_utils/RowTable'
import {getCurrentStatusForActivation} from '../../../components/_utils/appUtils'
import {getBranchUserForReActivateApplication} from '../../users/actions/user'
import {reActivateApplication} from '../../student/actions/student'

const ReActivateApplication = (props) => {
    const {
        form: {getFieldDecorator, setFieldsValue, getFieldValue, resetFields},
        onClose, studentId, visible, application, studentData
    } = props
    const dispatch = useDispatch()
    let [allCountry, setAllCountry] = useState([])
    let [countryUniversity, setCountryUniversity] = useState([])
    let [universityCourse, setUniversityCourse] = useState([])
    let [selectedCourse, setSelectedCourse] = useState({})
    let [intakesList, setIntakesList] = useState([])
    let [intake, setIntake] = useState({})
    let [status, setStatus] = useState('')
    let [userList, setUserList] = useState([])

    useEffect(() => {
        events.loadCountry()
        setDefaultFields()
    }, [])

    const setDefaultFields = () => {
        if (application) {
            if (application.universityCountry && application.universityCountry._id) {
                events.loadUniversity(application.universityCountry._id)
            }
            if (application.courseUniversity && application.courseUniversity._id) {
                events.loadCourse(application.courseUniversity._id)
            }
            /* if (application.courseId && application.courseId._id) {
                 events.loadIntakes(application.courseId._id)
             }*/
            if (application.courseId && application.courseId._id && application.campusId && application.campusId._id) {
                events.loadIntakes(application.courseId._id, application.campusId._id)
            }
        }
        // let statusObj = getCurrentStatusForActivation(application.statusList)
        setStatus(application.statusList.status)

        let userFilterObj = {
            countryId: application.universityCountry._id,
            universityId: application.courseUniversity._id,
            status: application.statusList.status,
            studentId,
            applicationId: application._id
        }
        loadUserForAssign(userFilterObj)
        let obj = {
            countryId: application.universityCountry._id,
            universityId: application.courseUniversity._id,
            courseId: application.courseId._id,
            // userId: application.assignedUserId._id
        }
        if (application.intake && application.intake.month) {
            obj.intakeTemp = `${application.intake.month}-${application.intake.year}`
            setIntake(application.intake)
        }
        setTimeout(() => {
            setFieldsValue(obj)
        }, 500)
    }

    const loadUserForAssign = async (obj) => {
        if (props.canadaOnshore !== undefined) {
            obj.showOnShoreCanada = true
        }
        let {success, data} = await getBranchUserForReActivateApplication(obj)
        if (success) {
            setUserList(data)
        }
    }
    const loadUserOnChooseUniversity = async () => {
        let obj = {
            universityId: getFieldValue('universityId'),
            countryId: getFieldValue('countryId'),
            status: status
        }
        loadUserForAssign(obj)
    }

    const events = {
        loadCountry: async () => {
            let filters = {
                sortField: 'countryName', sortOrder: 'ascend'
            }
            let {data} = await dispatch(listAllCountries(filters))
            setAllCountry(data)
        },
        loadUniversity: async (item) => {
            let obj = {
                results: 1000000,
                universityCountry: item,
                sortField: 'universityName',
                sortOrder: 'ascend'
            }
            let data = await dispatch(listQuickSearchUniversities(obj))
            setCountryUniversity(data)
        },
        loadCourse: async (item) => {
            let obj = {
                results: 1000000,
                courseUniversity: item,
                sortField: 'courseName',
                sortOrder: 'ascend'
            }

            let {data} = await dispatch(listAllCourse(obj))
            setUniversityCourse(data)
        },
        loadIntakes: async (courseId, campusId) => {
            let resp = await dispatch(loadCourseIntakesFxn({courseId, campusId}))
            let data = []
            _.each(resp, (item) => {
                if (item) {
                    item.value = `${item.intake}-${item.year}`
                    item.label = `${item.intake}, ${item.year}`
                    data.push(item)
                }
            })
            setIntakesList(data)
        }
    }
    let inputTypes = {
        fields: [
            {
                key: 'userId',
                label: 'Assign to',
                type: 'select',
                showSearch: true,
                options: userList,
                required: true,
                keyAccessor: x => x._id,
                valueAccessor: x => x.name,
                onChange: (x) => {
                    setFieldsValue({
                        userId: x
                    })
                }
            }
        ]
    }

    const submitFormLayout = {
        wrapperCol: {
            xs: {span: 24, offset: 0},
            sm: {span: 10, offset: 7},
            md: {span: 12, offset: 8}
        }
    }
    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    const handleSubmit = (e) => {
        const {form} = props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                valData.intake = intake
                valData.studentId = studentId
                valData.applicationId = application._id
                valData.status = status
                let resp = await dispatch(reActivateApplication(valData))
                if (resp && resp.success) {
                    onClose()
                    setIntake({})
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }

    return (
        <Drawer visible={visible}
                title={`Re-open Case to ${status}`}
                width={600}
                closable={true}
                maskClosable={true}
                onClose={onClose}>
            <Card>
                <div className={'tableBox striped'}>
                    <RowTable title={'Student Name'} value={studentData.name}/>
                    <RowTable title={'Student Id'} value={studentData.studentId}/>
                    {application.assignedUserId && application.assignedUserId.name ?
                        <RowTable title={'Current User'} value={application.assignedUserId.name}/> : null}
                    <RowTable title={'Status'} value={status}/>
                    {application.universityCountry && application.universityCountry.countryName ?
                        <RowTable title={'Country'} value={application.universityCountry.countryName}/> : null}
                    {application.courseUniversity && application.courseUniversity.universityName ?
                        <RowTable title={'University'} value={application.courseUniversity.universityName}/> : null}
                    <RowTable title={'Course'} value={application.courseName}/>

                    {application.intake && application.intake.year ?
                        <RowTable title={'Intake'}
                                  value={`${application.intake.month}, ${application.intake.year}`}/> : null}

                </div>
                <Form className={'vertical-form'} autoComplete="off" onSubmit={handleSubmit}>
                    <GetAllFormFields
                        inputSchema={inputTypes}
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}/>
                    <Form.Item style={{marginTop: 25}}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Drawer>
    )
}
const WrappedApplyReActivateApplication = Form.create()(ReActivateApplication)
export default WrappedApplyReActivateApplication
