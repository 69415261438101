import React, { useState, useEffect } from 'react'
import { Button, Card, Drawer, Form, Input, Modal, notification } from 'antd'
import { InputBox } from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import { updateGcKeyFxn } from '../actions/chooseStudent'

const { confirm } = Modal

let gcTypes = [
  'GCKey',
  'Acknowledgement'
]
let initState = {
  gcKey: '',
  gcKeyType: '',
  document: {}
}
const { TextArea } = Input
const GrKeyDrawer = (props) => {
  let {
    studentData, applicationId, visible, onClose
  } = props
  let [state, setState] = useState(initState)
  let dispatch = useDispatch()
  let [document, setDocument] = useState('')
  useEffect(() => {
    events.setDefaultData()
  }, [])
  const events = {
    setDefaultData: () => {
      let { applications } = studentData
      events._updateState({
        gcKey: applications.gcKey ? applications.gcKey : '',
        gcKeyType: applications.gcKeyType ? applications.gcKeyType : '',
        document: applications.acknowledgementFile && applications.acknowledgementFile.path ? applications.acknowledgementFile : {}
      })
    },
    _updateState: (data) => {
      setState((prevData) => {
        return {
          ...prevData,
          ...data
        }
      })
    },
    handleSubmit: async () => {
      if (!state.gcKeyType) {
        notification.warning({ message: 'Please choose type.' })
        return
      }
      if (state.gcKeyType == 'GCKey') {
        if (!state.gcKey) {
          notification.warning({ message: 'Please enter GCKey' })
          return
        }
      }
      if (state.gcKeyType == 'Acknowledgement') {
        if (!document || (document && !document.name)) {
          notification.warning({ message: 'Please choose Acknowledgement file.' })
          return
        }
      }
      if (state.gcKeyType == 'GCKey') {
        events.finalSubmit()
      } else {
        confirm({
          title: 'GCKey required if you still want to proceed click yes?',
          onOk() {
            events.finalSubmit()
          },
          onCancel() {

          },
          okText: 'Yes',
          cancelText: 'No',
          width: '45%'
        })
      }
    },
    finalSubmit: async () => {
      let obj = {
        studentId: studentData._id,
        ...state,
        applicationId: applicationId
      }
      let fd = new FormData()
      fd.append('obj', JSON.stringify(obj))
      if (document && document.name) {
        fd.append('document', document)
      }
      let data = await dispatch(updateGcKeyFxn(fd))
      if (data && !data.error) {
        setState(initState)
        onClose()
      }
    },
    chooseDocument: (e) => {
      let { files } = e.target
      if (files && files.length) {
        setDocument(files[0])
      }
    }
  }

  return (
    <Drawer width={700}
            title={'Add GCKey'}
            placement='left'
            visible={visible}
            onClose={() => {
              onClose()
            }}>
      <Card bordered={false}>
        <Form>
          <InputBox title={'Choose Type'}>
            <select
              value={state.gcKeyType}
              className={'form-control'}
              onChange={({ target }) => {
                events._updateState({
                  gcKeyType: target.value,
                  gcKey: ''
                })
                setDocument('')
              }}>
              <option value={''}>Choose Type</option>
              {gcTypes.map((item) => {
                return (
                  <option value={item} key={item}>{item}</option>
                )
              })}
            </select>
          </InputBox>
          {state.gcKeyType == 'GCKey' ?
            <InputBox title={'GC Key'}>
              <TextArea rows={5}
                        onChange={(e) => {
                          let { value } = e.target
                          events._updateState({ gcKey: value })
                        }}
                        name={'gcKey'}
                        className={'form-control gcKey'}
                        placeholder={'Note'} value={state.gcKey} />
            </InputBox> : null}
          {state.gcKeyType == 'Acknowledgement' ?
            <>
              <InputBox title={'Acknowledgement File'}>
                <Input type={'file'} name={'document'} id={'document'}
                       className={'form-control'}
                       onChange={(e) => {
                         events.chooseDocument(e)
                       }} />
              </InputBox>

              {state.document && state.document.path ? <div>
                <a href={state.document.path} target={'_blank'}>Download Acknowledgement File</a>
              </div> : null}
            </> : null}
          <Form.Item className={'mt20 alignRight'}>
            <Button type='primary' className={'main-btn'} onClick={events.handleSubmit}>
              SUBMIT
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  )
}
export default GrKeyDrawer
