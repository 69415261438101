export default (
    state = {
        reloadRecord: false,
        countryId: null,
        counsellorId: "",
        reloadEnquiry: false,
        waiting: false,
        socketData: {},
        refreshDashboard: false
    },
    action
) => {
    switch (action.type) {
        case 'REFRESH_COUNTRY_RECORD':
            return {
                ...state,
                reloadRecord: action.value,
                countryId: action.countryId,
                counsellorId: action.counsellorId
            }
        case 'UPDATE_COUNSELLOR_ENQUIRY':
            return {
                ...state,
                reloadEnquiry: action.value,
                socketData: action.socketData
            }
        case 'REFRESH_COUNSELLOR_DASHBOARD':
            return {
                ...state,
                refreshDashboard: action.value
            }
        default:
            return state
    }
}
