import React from "react"
import MarketingManagerDashboard from "../../dashboard/marketingManagerDashboard"

const MarketingManagerOldDashboard = (props) => {
    return (
        <>
            <MarketingManagerDashboard/>
        </>
    )
}
export default MarketingManagerOldDashboard
