import React, { PureComponent } from 'react'
import {
  Form,
  Input,
  Select,
  Button, Col, Row, notification, Icon, Card, Table
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { hideLoader, hidePageLoad } from '../../../modules/actions'
import { connect } from 'react-redux'
import { listAllUniversities } from '../../university/actions/university'
import { listAllCountries } from '../../countries/actions/countries'
import {
  InputBox, CountryOptions
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { addUser, listAllUsers } from '../actions/user'
import { CountryCodeWithFlag, LoadState } from '../../../components/_utils/countryUtil'

const FormItem = Form.Item
const { Option } = Select
const { TextArea } = Input

@Form.create()
class AddMarketingManager extends PureComponent {

  agentEvents = {
    fetchAgent: async (name) => {
      let { dispatch } = this.props
      let obj = {
        results: 100,
        userType: 'agent',
        sortField: 'name',
        sortOrder: 'ascend',
        select: ['name', 'email', 'companyName'],
        name: name,
        block: false
      }
      let { data } = await dispatch(listAllUsers(obj))
      this.setState({
        agentList: data
      })
    },
    removeAgent: (index) => {
      let cloneAgent = _.clone(this.state.agents)
      cloneAgent.splice(index, 1)
      this.setState({
        agents: cloneAgent
      })
    },
    chooseAgent: (data) => {
      let { agents, agentList } = this.state
      let findUser = _.find(agents, (item) => {
        return item._id && item._id.toString() == data
      })
      if (findUser) {
        this.setState({
          agentName: '',
          agentList: []
        })
        return notification.warning({
          message: 'Agent already exists.'
        })
      } else {
        let findAgent = _.find(agentList, (item) => {
          return item._id && item._id.toString() == data
        })
        if (findAgent) {
          let cloneAgents = _.clone(agents)
          cloneAgents.push(findAgent)
          this.setState({
            agentName: '',
            agentList: [],
            agents: cloneAgents
          })
        }
      }
    }
  }
  events = {
    selectAllUni: () => {
      const { form: { setFieldsValue } } = this.props
      let { countryList } = this.state
      let countries = []
      _.each(countryList, (item) => {
        if (item && item._id) {
          countries.push(item._id)
        }
      })
      setFieldsValue({
        countryArr: countries
      })
    },
    deSelectAllUni: () => {
      const { form: { setFieldsValue } } = this.props
      setFieldsValue({
        countryArr: []
      })
    },
    countryName: (id) => {
      let country = _.find(this.state.countryList, (item) => {
        return item._id === id
      })
      return country && country.countryName ? country.countryName : null
    }
  }


  constructor(props) {
    super(props)
    this.state = {
      allBranch: [],
      universityList: [],
      countryList: [],
      stateList: [],
      mainBranchArr: [],
      logo: {},
      uploadKey: moment(),
      agentList: [],
      agents: [],
      assignStateCountry: [],
      countryCode: ''
    }
  }

  componentDidMount() {
    this.loadCountry()
    this.LoadStateFxn()
    let { dispatch } = this.props
    dispatch({ type: 'USER_HIDELOADER' })
    dispatch(hideLoader())
    dispatch(hidePageLoad())
  }

  async loadCountry() {
    let { dispatch } = this.props
    let countryFilter = {
      results: 10,
      sortField: 'countryName',
      sortOrder: 'ascend'
    }
    let { data } = await listAllCountries(countryFilter)(dispatch)
    this.setState({
      countryList: data
    })
  }

  async LoadStateFxn() {
    let { dispatch } = this.props
    let data = await LoadState('101')
    this.setState({
      stateList: data
    })
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    let { getFieldValue } = form
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { address, logo, countryCode } = this.state
        if (!countryCode) {
          notification.error({
            message: 'Please choose Country Code'
          })
          return
        }
        valData.countryISOCode = countryCode
        valData.address = address
        valData.userType = 'master'
        valData.assignIntakesRight = true

        let fd = new FormData()
        fd.append('obj', JSON.stringify(valData))
        if (logo && logo.name) {
          fd.append('logo', logo)
        }
        let data = await dispatch(addUser(fd))
        if (data && !data.error) {
          form.resetFields()
          this.setState({
            address: '',
            uploadKey: moment(),
            logo: {},
            assignStateCountry: [],
            agents: [],
            countryCode: ''
          })
        }
      }
    })
  }


  render() {

    const { submitting, dispatch } = this.props
    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue }
    } = this.props

    const { editorState, agentList, agentName, agents } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }

    let inputTypes = {
      fields: [
        {
          label: 'Contact Person Name',
          key: 'name',
          required: true
        },
        {
          key: 'countryCode',
          span: 3,
          customField: (
            <div style={{ marginTop: 5 }}>
              <CountryCodeWithFlag countryCode={this.state.countryCode} chooseCode={(val) => {
                this.setState({
                  countryCode: val
                })
              }} />
            </div>
          )
        },
        {
          label: 'Mobile no',
          key: 'mobile',
          span: 5,
          required: true
        },
        {
          label: 'Email',
          key: 'email',
          required: true
        },
        {
          label: 'Password',
          key: 'password',
          type: 'password',
          required: true
        },
        {
          label: 'Post Name',
          key: 'postName'
        },
        {
          key: 'countryRight',
          label: 'Country',
          type: 'select',
          required: true,
          keyAccessor: x => x.value,
          valueAccessor: x => `${x.name}`,
          options: CountryOptions,
          onChange: x => {
            setFieldsValue({
              countryRight: x
            })
          }
        },
        {
          key: 'countryArr',
          label: '',
          label1: '* Select Country',
          type: 'select',
          extraBox1: (
            <React.Fragment>
              <a onClick={() => this.events.selectAllUni()}>Select All</a>{' '}
              <a onClick={() => this.events.deSelectAllUni()}>De-select All</a>
              {getFieldValue('universities') && getFieldValue('universities').length ?
                <a className={'selectedUni'}>{getFieldValue('universities').length}</a>
                : null}
            </React.Fragment>
          ),
          required: true,
          showSearch: true,
          options: this.state.countryList,
          hidden: !(getFieldValue('countryRight') == 'Selected'),
          mode: 'multiple',
          keyAccessor: x => x._id,
          valueAccessor: x => `${x.countryName}`,
          onChange: x => {
            setFieldsValue({
              countryArr: x
            })
          }
        },
        {
          key: 'logo',
          customField: (
            <InputBox title={'Profile Picture'} className={'logoCls'}>
              <Input type={'file'} className={'form-control'} key={this.state.uploadKey} name={'logo'}
                     id={'logo'}
                     onChange={(e) => {
                       this.setState({
                         logo: e.target.files && e.target.files.length ? e.target.files[0] : null
                       })
                     }} />
            </InputBox>
          )
        },

        {
          key: 'address',
          span: 24,
          customField: (
            <InputBox title={'Address'}>
              <TextArea rows={4} className={'form-control'} value={this.state.address} onChange={(e) => {
                this.setState({ address: e.target.value })
              }}>
              </TextArea>
            </InputBox>
          )
        }
      ]
    }


    return (
      <div className='row'>
        <div className='col-lg-12'>
          <Form
            onSubmit={this.handleSubmit}
          >
            <div className='form-box mt-4'>

              <div className='d-flex align-items-center mb-3 heading-form'>
                <h5>Add Master User</h5>
              </div>

              <div className='card unizportal'>

                <Row gutter={16} className={'rowWrap'}>
                  {inputTypes.fields.map((item, key) => {
                    return (
                      <React.Fragment key={key}>
                        {item.customField ? !item.hidden && <Col span={item.span ? item.span : 8}>
                          {item.customField}
                        </Col> : !item.hidden &&
                          <Col span={item.span ? item.span : 8} key={key}
                               style={item.extraBox1 ? { paddingBottom: 1 } : {}}>
                            <InputBox title={item.label1 ? item.label1 : ''} extra={item.extraBox1}>
                              <GetEachFormFields
                                item={item}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={formItemLayout} />
                            </InputBox>
                          </Col>}
                      </React.Fragment>
                    )
                  })}

                </Row>


                <Form.Item>
                  <Button type='primary' htmlType='submit' loading={this.props.loading} className={'btn'}>
                    SAVE
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>

        </div>
      </div>

    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddMarketingManager)
