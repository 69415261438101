import React, { useState, useEffect } from 'react'
import { Button, Col, Collapse, Icon, Row, Tooltip, Drawer, Input, Form, Card } from 'antd'
import moment from 'moment'
import lodash from 'lodash'
import { connect, useDispatch, useSelector } from 'react-redux'
import UploadDocumentComponent from './uploadDocumentComponent'
import { loadCompanyDocuments } from '../actions/company'

const { Panel } = Collapse
const { TextArea } = Input
const initialState = {
  documentDrawerVisible: false,
  title: '',
  description: '',
  document: {},
  documentKey: moment()
}
const DocumentBlock = (props) => {
  const dispatch = useDispatch()
  let [state, setState] = useState(initialState)
  let [documents, setDocuments] = useState([])
  let [defaultActiveKey, setDefaultActiveKey] = useState(['1'])
  let { companyId } = props

  let { title } = state
  useEffect(() => {
    loadDocuments()
  }, [])

  const newStatusList = []

  const loadDocuments = async () => {
    let resp = await dispatch(loadCompanyDocuments({ companyId }))
    if (resp && resp.document && resp.document.length) {
      setDocuments(resp.document)
      let defaultActiveKey = []
      for (let i = 1; i <= resp.document.length; i++) {
        defaultActiveKey.push(i.toString())
      }
      setDefaultActiveKey(defaultActiveKey)
    }
  }


  const resp = useSelector(state => {
    let { loadDocumentRedux } = state.chooseApplication
    if (loadDocumentRedux) {
      loadDocuments()
      dispatch({ type: 'END_LOAD_DOCUMENT' })
    }
  })


  const getDocumentObj = (documents, name) => {
    let findObj = lodash.filter(documents, (item) => {
      return item.documentType == name
    })
    lodash.each(findObj, (item) => {
      if (item && item.date) {
        item.stringDate = moment(item.date).format('DD MMMM, YYYY hh:mm a')
      }
      item.oldDoc = true
      item.title = `${item.name}, Uploaded : ${item.stringDate}`
    })
    findObj = lodash.sortBy(findObj, (item) => {
      return new Date(item.date)
    })
    if (findObj && findObj.length) {
      findObj[findObj.length - 1].oldDoc = false
    }
    return findObj
  }

  const documentSubName = (name) => {
    let exp = name.split('.').pop()
    let nameVal = name.split('.')[0]
    if (nameVal.length > 10) {
      nameVal = `${nameVal.substring(0, 10)}[...].${exp}`
      return nameVal
    } else {
      return name
    }
  }

  const events = {
    showDrawer: (title) => {
      setState({ ...state, documentDrawerVisible: true, title })
    },
    hideDrawer: () => {
      setState({ ...state, documentDrawerVisible: false })
    }
  }

  return (
    <React.Fragment>
      <div className="attachment-box">
        <div className="head d-flex align-items-center">
          <h5>attachments</h5>
          <button className="ml-auto" onClick={() => {
            events.showDrawer('', 'document')
          }}>+ add
          </button>
        </div>

        {documents && documents.length ? <div>
          {documents.map((item, key) => {
            return (
              <div className="box" key={key}>
                <h4>
                  {item.documentType}
                </h4>
                <Tooltip placement="topRight" title={item.title} key={key}>
                  <a href={item.url} download={item.url}
                     className={item.oldDoc ? 'oldDoc' : ''}>
                    <span>  {documentSubName(item.name)}</span>
                  </a>
                </Tooltip>
                <div className="upload" onClick={() => {
                  events.showDrawer(item.name, 'appDocument')
                }}>
                  <img src="/dist/img/upload-box-1.png" alt=""/>
                </div>
              </div>
            )
          })}
        </div> : null}
      </div>

      {state.documentDrawerVisible ?
        <UploadDocumentComponent visible={state.documentDrawerVisible}
                                 title={title}
                                 companyId={companyId}
                                 onClose={events.hideDrawer}/> : ''}

    </React.Fragment>
  )
}


const mapStateToProps = ({ global, router, chooseApplication }) => ({})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(DocumentBlock)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedComponent)

