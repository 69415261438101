import React, { useEffect, useState } from 'react'
import { Drawer, Form, Button, Select, Input, Row, Col, notification } from 'antd'
import { useDispatch } from 'react-redux'
import { InputBox } from '../../../components/_utils/appUtils'
import { cambieOnshoreLoaLetterFxn, generateAgentInvoiceFxn } from './actions'
import TextArea from 'antd/es/input/TextArea'
import { cambieAgentListFxn } from '../../CambieApp/actions'

const GenerateAgentInvoice = (props) => {
  let dispatch = useDispatch()
  let { Option } = Select
  let { visible, onClose, onSubmit, studentId, applicationId } = props
  let [invoiceType, setInvoiceType] = useState('')
  let [agencyName, setAgencyName] = useState('')
  let [companyName, setCompanyName] = useState('')
  let [address, setAddress] = useState('')
  let [state, setState] = useState('')
  let [city, setCity] = useState('')
  let [postalCode, setPostalCode] = useState('')
  let [total, setTotal] = useState('')
  let [registeredAgents, setRegisteredAgents] = useState([])
  let [registeredAgent, setRegisteredAgent] = useState('')
  let [paymentType, setPaymentType] = useState('')
  let [accountDetails, setAccountDetails] = useState('')
  let [email, setEmail] = useState('')

  const handleSubmit = async () => {
    let obj = {
      applicationId: applicationId,
      studentId: studentId,
      invoiceType: invoiceType,
    }
    if (invoiceType == 'selfInvoice') {
      obj.cambieAgentId = registeredAgent
    } else {
      obj.name = agencyName
      obj.companyName = companyName
      obj.cityName = city
      obj.stateName = state
      obj.pinCode = postalCode
      obj.address = address
    }
    obj.paymentMode = paymentType
    if (paymentType == 'email'){
      obj.emailDetail = email
    }
    if(paymentType == 'bankAccount'){
      obj.accountDetails = accountDetails
    }
    if (!invoiceType){
      notification.warning({message: "Please choose Invoice Type."})
      return
    }
    if (invoiceType == 'selfInvoice' && !registeredAgent){
      notification.warning({message: "Please choose Registered Agent."})
      return
    }
    if (invoiceType == 'agencyInvoice'){
      if (!agencyName){
        notification.warning({message: "Please Enter Agency Name."})
        return
      }
      if (!companyName){
        notification.warning({message: "Please Enter Company Name."})
        return
      }
      if (!state){
        notification.warning({message: "Please Enter State Name."})
        return
      }
      if (!city){
        notification.warning({message: "Please Enter City Name."})
        return
      }
      if (!postalCode){
        notification.warning({message: 'Please Enter Postal Code.'})
        return
      }
      if (!address){
        notification.warning({message: 'Please Enter Address.'})
        return
      }
    }
    if (!paymentType){
      notification.warning({message: "Please choose Payment Mode."})
      return
    }
    if (paymentType == 'email'){
      if (!email){
        notification.warning({message: "Please Enter Email."})
        return
      }
    }
    if (paymentType == 'bankAccount'){
      if (!accountDetails){
        notification.warning({message: "Please Enter Bank Account Details."})
        return
      }
    }
    let resp = await dispatch(generateAgentInvoiceFxn(obj))
    if (resp && resp.success) {
      onSubmit()
    }
  }

  const getCambieRegisteredAgents = async (params = {}) => {
    params.status = 'Approved'
    let resp = await dispatch(cambieAgentListFxn({
      ...params,
      regExFilters: ['name', 'companyName', 'countryName', 'stateName', 'address', 'pincode', 'cityName']
    }))
    setTotal(resp.total)
    setRegisteredAgents(resp.data)
  }

  useEffect(() => {
    getCambieRegisteredAgents()
  }, [])

  return (
    <>
      <Drawer title={'Generate Agent Invoice'}
              visible={visible} width={'45%'}
              onClose={onClose}
      >
        <div className='form-box'>
          <div className='card unizportal'>
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                  <InputBox title={'Invoice Type *'}>
                    <Select
                      placeholder='Invoice Type'
                      value={invoiceType}
                      onChange={(value) => {
                        setInvoiceType(value)
                      }}
                    >
                      <Option key={''} value={''}>Choose Invoice Type</Option>
                      <Option key={'selfInvoice'} value={'selfInvoice'}>Self Invoice</Option>
                      <Option key={'agencyInvoice'} value={'agencyInvoice'}>Agency Invoice</Option>
                    </Select>
                  </InputBox>
                </Col>
                {
                  invoiceType == 'selfInvoice' ?
                    <Col span={12}>
                      <InputBox title={'Choose Registered Agent *'}>
                        <Select
                          placeholder='Registered Agent'
                          value={registeredAgent}
                          onChange={(value) => {
                            setRegisteredAgent(value)
                          }}
                        >
                          <Option key={''} value={''}>Choose Registered Agent</Option>
                          {registeredAgents && registeredAgents.length && registeredAgents.map((item) => {
                            return (
                              <Option key={item._id} value={item._id}>
                                {item.name}
                              </Option>
                            )
                          })}
                        </Select>
                      </InputBox>
                    </Col>
                    :
                    invoiceType == 'agencyInvoice' &&
                    <>
                      <Col span={12}>
                        <InputBox title={'Agency Name *'}>
                          <Input
                            placeholder='Agency Name'
                            className={'form-control'}
                            value={agencyName}
                            onChange={(e) => {
                              setAgencyName(e.target.value)
                            }}
                          />
                        </InputBox>
                      </Col>
                      <Col span={12}>
                        <InputBox title={'Company Name *'}>
                          <Input
                            placeholder='Company Name'
                            className={'form-control'}
                            value={companyName}
                            onChange={(e) => {
                              setCompanyName(e.target.value)
                            }}
                          />
                        </InputBox>
                      </Col>
                      <Col span={12}>
                        <InputBox title={'State *'}>
                          <Input
                            placeholder='State'
                            className={'form-control'}
                            value={state}
                            onChange={(e) => {
                              setState(e.target.value)
                            }}
                          />
                        </InputBox>
                      </Col>
                      <Col span={12}>
                        <InputBox title={'City *'}>
                          <Input
                            placeholder='City'
                            className={'form-control'}
                            value={city}
                            onChange={(e) => {
                              setCity(e.target.value)
                            }}
                          />
                        </InputBox>
                      </Col>
                      <Col span={12}>
                        <InputBox title={'Postal Code *'}>
                          <Input
                            placeholder='Postal Code'
                            className={'form-control'}
                            value={postalCode}
                            onChange={(e) => {
                              setPostalCode(e.target.value)
                            }}
                          />
                        </InputBox>
                      </Col>
                      <Col span={24}>
                        <InputBox title={'Address *'}>
                          <TextArea
                            value={address}
                            className={'form-control'}
                            onChange={(e) => {
                              setAddress(e.target.value)
                            }}
                            placeholder='Address'
                            autoSize={{ minRows: 3, maxRows: 5 }}
                          />
                        </InputBox>
                      </Col>
                    </>
                }

                <Col span={12}>
                  <InputBox title={'Choose Payment Receive Mode *'}>
                    <Select
                      placeholder='Choose Payment Mode'
                      value={paymentType}
                      onChange={(value) => {
                        setPaymentType(value)
                      }}
                    >
                      <Option key={''} value={''}>Choose Payment Mode</Option>
                      <Option key={'email'} value={'email'}>Email</Option>
                      <Option key={'bankAccount'} value={'bankAccount'}>Bank Account</Option>
                    </Select>
                  </InputBox>
                </Col>
                {
                  paymentType == 'bankAccount' ?
                    <Col span={24}>
                      <InputBox title={'Bank Details *'}>
                        <TextArea
                          value={accountDetails}
                          className={'form-control'}
                          onChange={(e) => {
                            setAccountDetails(e.target.value)
                          }}
                          placeholder='Enter Bank Details'
                          autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                      </InputBox>
                    </Col> : paymentType == 'email' ?
                    <Col span={24}>
                      <InputBox title={'Email *'}>
                        <Input
                          value={email}
                          className={'form-control'}
                          onChange={(e) => {
                            setEmail(e.target.value)
                          }}
                          placeholder='Enter Email'
                        />
                      </InputBox>
                    </Col> : null
                }
              </Row>
              <Button
                type='primary'
                htmlType='submit'
                className='btn'
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  )
}
export default GenerateAgentInvoice
