import React from "react"
import {Card} from "antd";

const UniversityInfoComponent = (props) => {
    let {universityId, countryId} = props;
    return (
        <div className={`universityParentBox`}>
            <Card className={'commissionUniInfo'}>
                {universityId && universityId.universityName ?
                    <>
                        {universityId && universityId.logo && universityId.logo.url ?
                            <div className={'uniLogo'}>
                                <img src={universityId.logo.url}/>
                            </div> : null}
                        <div className={'studentInfoBox'}>
                            <div className={'uniName'}>
                                {universityId.universityName}
                                {countryId && countryId.countryName ? `, ${countryId.countryName}` : null}
                            </div>
                        </div>
                    </>
                    : null}
            </Card>
        </div>
    )
}
export default UniversityInfoComponent
