import React, { useState, useEffect } from 'react'
import {
  canadacompanyDatateps,
  defaultLogo,
  DocumentTypes,
  nameAvatar,
  qualificationOptions, ukcompanyDatateps, ApplicationDeskList, showRefundBtn, displayDate
} from '../../../components/_utils/appUtils'
import {
  Avatar,
  Button,
  Col,
  Collapse,
  Icon,
  notification,
  Row,
  Select,
  Tabs,
  Tag,
  Drawer,
  Tooltip,
  Input,
  Card, Form, Popconfirm, Modal, Radio
} from 'antd'
import { useDispatch } from 'react-redux'

import { InputBox } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import moment from 'moment'
import lodash from 'lodash'


import { LazyLoadImage } from 'react-lazy-load-image-component'
import { getPushPathWrapper } from '../../../routes'
import { sendEmailToCompany, sendSmsToCompany, uploadCompanyProfileImage } from '../actions/company'
import { AllowComponentRightsWise } from '../../WebComponent/allowComponentRightsWise'
import { getPendingPendency } from '../../../components/_utils/utils'

const { TabPane } = Tabs
const { Panel } = Collapse
const { Option } = Select
const { TextArea } = Input
const initialState = {
  newStatus: '',
  statusDrawerVisible: false,
  smsDrawerVisible: false,
  emailDrawerVisible: false,
  smsContent: '',
  emailContent: '',
  emailSubject: '',
  currentStatusIndex: 0,
  nextStatusIndex: 0,
  intake: {
    month: '',
    year: ''
  },
  moveToNextDrawerVisible: false,
  description: '',
  documentKey: moment(),
  profileKey: moment() + 1

}
const InfoBlock = (props) => {
  const dispatch = useDispatch()
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields }, showNoteDrawer, companyData } = props
  let [state, setState] = useState(initialState)
  let [profileDrawerVisible, setProfileDrawerVisible] = useState(false)
  let [profileImage, setProfileImage] = useState({})
  let [newProfileImage, setNewProfileImage] = useState({})
  let [visiblePendency, setVisiblePendency] = useState(false)
  let [companyDataClone, setcompanyDataClone] = useState({})
  let [intake, setIntake] = useState(null)


  useEffect(() => {

    if (companyData && companyData.profile && companyData.profile.url) {
      setProfileImage(companyData.profile)
    }


  }, [])


  const loadSample = () => {
    // resolve(dispatch(listAllSampleDocument()))
  }

  const events = {
    showDrawer: () => {
      setState({ ...state, statusDrawerVisible: true })
    },
    hideDrawer: () => {
      setState({ ...state, statusDrawerVisible: false })
    },
    showSmsDrawer: () => {
      setState({ ...state, smsDrawerVisible: true })
    },
    hideSmsDrawer: () => {
      setState({ ...state, smsDrawerVisible: false })
    },
    showEmailDrawer: () => {
      setState({ ...state, emailDrawerVisible: true })
    },
    hideEmailDrawer: () => {
      setState({ ...state, emailDrawerVisible: false })
    },
    setLocalState: (e) => {
      let { name, value } = e.target
      setState({
        ...state,
        [name]: value
      })
    },
    submitSms: async () => {
      if (!state.smsContent) {
        return notification.warn({
          message: 'Please enter SMS content'
        })
      }
      let resp = await dispatch(sendSmsToCompany({
        companyId: companyData._id,
        mobile: companyData.mobile,
        smsContent: state.smsContent
      }))
      if (resp && !resp.error) {
        setState({
          ...state,
          smsContent: '',
          smsDrawerVisible: false
        })
        dispatch({ type: 'START_LOAD_SMS' })
      }
    },
    submitEmail: async (e) => {
      const { form } = props
      e.preventDefault()
      form.validateFieldsAndScroll(async (err, valData) => {
        if (!err) {

          if (!state.emailSubject) {
            return notification.warn({
              message: 'Please enter email subject'
            })
          }
          if (!valData.emailContent) {
            return notification.warn({
              message: 'Please enter email content'
            })
          }
          let resp = await dispatch(sendEmailToCompany({
            companyId: companyData._id,
            email: companyData.email,
            emailContent: valData.emailContent,
            emailSubject: state.emailSubject
          }))
          if (resp && !resp.error) {
            setState({
              ...state,
              emailContent: '',
              emailSubject: '',
              emailDrawerVisible: false
            })
            setFieldsValue({ emailContent: '' })
            dispatch({ type: 'START_LOAD_EMAIL' })
          }
        } else {
          notification.warning({
            message: 'Fill All Required Fields'
          })
        }
      })


    },
    chooseIntake: (value, key) => {
      let intakeVal = value.split('-')
      let intake = {
        month: intakeVal[0],
        year: intakeVal[1]
      }
      setIntake(intakeVal)
      events.updateIntakes(intake)
    },
    updateIntakes: async (intake) => {
      if (!intake || (intake && !intake.month)) {
        return notification.warning({
          message: 'Please choose Intake'
        })
      }

    },

    sendWhatsapp: (record) => {
      if (companyData && companyData.mobile) {
        let link = `http://api.whatsapp.com/send?phone=91${companyData.mobile}&text=&source=&data=`
        window.open(link, '_blank')
      } else {
        notification.warning({ message: 'Please update mobile no.' })
      }

    },
    chooseDocument: (e) => {
      let { name, files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          document: files[0]
        })
      }
    },


    showProfileDrawer: () => {
      setState({
        ...state,
        profileKey: moment() + 1
      })
      setProfileDrawerVisible(true)

    },
    chooseProfileDocument: (e) => {
      let { name, files } = e.target
      if (files && files.length) {
        setNewProfileImage(files[0])
      }
    },

    hideProfileDrawer: () => {
      setProfileDrawerVisible(false)
    },
    uploadProfileImage: async () => {
      if (!newProfileImage || (newProfileImage && !newProfileImage.name)) {
        return notification.warn({
          message: 'Please choose profile Image'
        })
      }
      let fd = new FormData()
      fd.append('obj', JSON.stringify({ companyId: companyData._id }))
      fd.append('profile', newProfileImage)
      let { success, profile } = await dispatch(uploadCompanyProfileImage(fd))
      if (success) {
        setProfileDrawerVisible(false)
        setNewProfileImage({})
        setProfileImage(profile)
      }
    }

  }
  let emailFields = {
    key: 'emailContent',
    label: '',
    placeholder: 'Email',
    type: 'editor',
    onChange: (e) => {
      setState({
        ...state,
        emailContent: e.target.value
      })
      setFieldsValue({ emailContent: e.target.value })
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  return (
    <div className={'bgWhite infoBox'}>
      <div className="profile-main">
        <button className="btn back" onClick={() => {
          window.history.back()
        }}><img src="/dist/img/left-arrow.svg" alt=""/> back
        </button>
        <div className="profile-box-main">
          <span>
             {profileImage && profileImage.url ?
               <Avatar size={150} src={profileImage.url}></Avatar> :
               <Avatar size={150}>{nameAvatar(companyData.name)}</Avatar>}
          </span>
          <a onClick={events.showProfileDrawer}><img src="/dist/img/upload-plus.svg" alt=""/></a>
        </div>
        <div className="profile-box-inner">
          <h5>{companyData.name}
            <a style={{ marginLeft: 6 }}
               onClick={() => {
                 dispatch(
                   getPushPathWrapper('company.editCompany', { id: companyData._id }))
               }}><Icon type={'edit'}/></a>
          </h5>

          <div className="info-detail">
            <ul>
              <li onClick={showNoteDrawer}>
                <span><img src="/dist/img/edit-icon.svg" alt=""/></span>
                <p>note</p>
              </li>
              <li onClick={events.showEmailDrawer}>
                <span><img src="/dist/img/email.svg" alt=""/></span>
                <p>email</p>
              </li>
              <li onClick={events.showSmsDrawer}>
                <span><img src="/dist/img/sms.svg" alt=""/></span>
                <p>sms</p>
              </li>
              <li onClick={events.sendWhatsapp}>
                <span><img src="/dist/img/whatsapp.svg" alt=""/></span>
                <p>whatsapp</p>
              </li>
            </ul>
          </div>

          <div className="filter-box mt-0">

            <div className="accordion" id="accordionExample">

              <div className="card">
                <div className="card-header" id="headingOne">
                  <h2 className="mb-0">
                    <button className="btn btn-link d-flex" type="button" data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="false" aria-controls="collapseOne">
                      about company <img src="/dist/img/arrow-down-white.svg" alt="" className="ml-auto"/>
                    </button>
                  </h2>
                </div>

                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                     data-parent="#accordionExample">
                  <div className="card card-body">
                    <div className="university-profile">
                      <div className="fee-box">
                        {companyData ?
                          <div className="status status1 mt-4">

                            <div className="row">
                              <div className="col-lg-5">
                                <label>Contact Name : </label>
                              </div>
                              <div className="col-lg-7">
                                <p>  {companyData.contactPerson ? companyData.contactPerson : null}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-5">
                                <label>Contact No : </label>
                              </div>
                              <div className="col-lg-7">
                                <p>{companyData.mobile ? companyData.mobile : null}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-5">
                                <label>Email : </label>
                              </div>
                              <div className="col-lg-7">
                                <p> {companyData.email ? companyData.email : null}</p>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-5">
                                <label>Address : </label>
                              </div>
                              <div className="col-lg-7">
                                <p>
                                  {companyData.address ? companyData.address : null}
                                  {companyData.cityName ? `, ${companyData.cityName}` : null}
                                  {companyData.postalCode ? ` - ${companyData.postalCode}` : null}
                                  {companyData.stateName ? `, ${companyData.stateName}` : null}
                                  {companyData.countryName ? `, ${companyData.countryName}` : null}
                                </p>
                              </div>
                            </div>


                          </div>
                          : ''}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>


      <Drawer width={700}
              title={'Send SMS'}
              placement="left"
              visible={state.smsDrawerVisible}
              onClose={events.hideSmsDrawer}>
        <Card bordered={false}>
          <InputBox title={'SMS'}>
            <TextArea placeholder={'SMS Content'} value={state.smsContent} name={'smsContent'} rows={10}
                      onChange={events.setLocalState}/>
          </InputBox>


          <div className={'mt20 alignRight'}>
            <Button onClick={events.hideSmsDrawer} type={'cancel'}>Cancel</Button> {' '}
            <Button type={'success'} onClick={events.submitSms}>Submit</Button>
          </div>
        </Card>
      </Drawer>
      <Drawer width={700}
              title={'Send Email'}
              placement="left"
              visible={state.emailDrawerVisible}
              onClose={events.hideEmailDrawer}>
        <Card bordered={false}>
          <Form className={'vertical-form'}>
            <InputBox title={'Subject'}>
              <Input placeholder={'Subject'} value={state.emailSubject} name={'emailSubject'}
                     onChange={events.setLocalState}/>
            </InputBox>
            <InputBox title={'Email'}>
              <GetEachFormFields
                item={emailFields}
                getFieldDecorator={getFieldDecorator}
                formItemLayout={formItemLayout}/>
            </InputBox>
            <div className={'mt20 alignRight'}>
              <Button onClick={events.hideSmsDrawer} type={'cancel'}>Cancel</Button> {' '}
              <Button type={'success'} onClick={events.submitEmail}>Submit</Button>
            </div>
          </Form>
        </Card>
      </Drawer>

      {profileDrawerVisible ?
        <Drawer
          width={450}
          title={'Upload Profile Image'}
          onClose={events.hideProfileDrawer}
          visible={profileDrawerVisible}>
          <Card>
            <Form>
              <InputBox title={`Profile Image`}>
                <Input type={'file'} name={'profile'} id={'profile'}
                       accept="image/x-png,image/jpeg,,image/jpg"
                       key={state.profileKey}
                       onChange={(e) => {
                         events.chooseProfileDocument(e)
                       }}/>
              </InputBox>
              <Form.Item className={'mt20'}>
                <Button type="primary" onClick={events.uploadProfileImage}>
                  SUBMIT
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Drawer> : ''}


    </div>
  )
}

const WrappedInfoBlock = Form.create()(InfoBlock)
export default WrappedInfoBlock
