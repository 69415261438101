import React, {useState, useEffect} from 'react';
import {Drawer, Form, Button, Col, Row, notification, DatePicker} from 'antd';
import {useDispatch} from 'react-redux';
import {FileInput} from '../../components/_utils/appUtils';
import GetEachFormFields from '../../components/_utils/appFormUtils';
import {updateUnizhomeContractFxn, getSingleContractFxn} from "./actions";
import moment from 'moment'


const UpdateContractDrawer = (props) => {
    const {
        form: {getFieldDecorator, validateFieldsAndScroll, setFieldsValue},
        visible,
        onClose,
        contractId,
        reloadTable
    } = props;
    const [file, setFile] = useState(null);
    let [oldContract, setOldContract] = useState({})
    const dispatch = useDispatch();

    const apiRequest = async () => {
        try {
            const resp = await dispatch(getSingleContractFxn({contractId: contractId}));
            if (resp) {
                setFieldsValue({
                    companyName: resp.companyName,
                    contactPersonName: resp.contactPersonName,
                    contactMobileNo: resp.contactMobileNo,
                    email: resp.email,
                    expiryDate: resp.expiryDate ? moment(resp.expiryDate) : null,
                });
                if (resp && resp.contract && resp.contract.path) {
                    setOldContract(resp.contract)
                }
            }

        } catch (error) {
            // console.error('Error fetching note history:', error);
        }
    };

    useEffect(() => {
        apiRequest()
    }, [])

    const handleFileChange = (target) => {
        const file = target.files[0];
        setFile(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        validateFieldsAndScroll(async (err, values) => {
            if (err) {
                notification.warning({message: 'Fill All Required Fields'});
                return;
            }
            const fd = new FormData();
            fd.append('obj', JSON.stringify({...values, contractId: contractId}));
            if (file && file.name) {
                fd.append('contract', file);
            }

            const response = await dispatch(updateUnizhomeContractFxn(fd));
            if (response && !response.error) {
                notification.success({message: response.message});
                onClose();
                reloadTable();
            }
        });
    };

    const formFields = {
        fields: [
            {
                key: 'companyName',
                label: 'Company Name',
                type: 'text',
                required: true,
            },
            {
                key: 'contactPersonName',
                label: 'Contact Person Name',
                type: 'text',
                required: true,
            },
            {
                key: 'contactMobileNo',
                label: 'Contact Mobile No',
                type: 'text',
                required: true,
            },
            {
                key: 'email',
                label: 'Email Id',
                type: 'text',
                required: true,
            },
            {
                key: 'uploadContract',
                label: 'Upload Contract',
                type: 'file',
                onChange: ({target}) => handleFileChange(target)
            },
            {
                key: 'expiryDate',
                label: 'Expiry Date',
                type: 'date',
            }
        ]
    };

    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    return (
        <Drawer
            title="Update Contract"
            width={'35%'}
            onClose={onClose}
            visible={visible}
            bodyStyle={{paddingBottom: 80}}
        >

            <div className="form-box">
                <div className="card unizportal">
                    <Form onSubmit={handleSubmit}>
                        <Row gutter={24}>
                            {formFields.fields.map((item, key) => (

                                <Col span={24}
                                     key={key}>
                                    {item.type == 'file' ?
                                        <>
                                            <FileInput name={item.fileName}
                                                       label={item.label}
                                                       className={'mt20'}
                                                       file={{url: oldContract && oldContract.path ? oldContract.path : ""}}
                                                       chooseDocument={item.onChange}/>
                                        </> :
                                        <GetEachFormFields
                                            item={item}
                                            getFieldDecorator={getFieldDecorator}
                                            formItemLayout={formItemLayout}
                                        />
                                    }
                                </Col>
                            ))}
                        </Row>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">Update</Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Drawer>
    );
};

const WrappedUpdateContractDrawer = Form.create()(UpdateContractDrawer);
export default WrappedUpdateContractDrawer;
