import React, { useEffect, useState } from 'react'
import { Popconfirm, Tooltip, Tag, Modal, Col, Input } from 'antd'
import UploadTuitionFeesCambieOnshore from './uploadTutionFeesCambieOnshore'
import UploadTravelDocument from './uploadTravelDocument'
import {
  closeTuitionFeesDocumentFxn, disRegardTuitionFeesDocumentFxn,
  updateTuitionFeesDirectUniversityFxn,
  verifyTuitionFeesDocumentFxn
} from '../actions/fcmtAction'
import { useDispatch } from 'react-redux'
import UploadedTuitionFeesListCambieOnshore from './uploadedTuitionFeeListCambieOnshore'
import { displayDate, InputBox } from '../../../components/_utils/appUtils'
import _ from 'lodash'
import { notification } from 'antd/lib/index'
import { updateChargeAmountFxn } from '../cambieOnshoreApplicationList/actions'
import BankVerifyModal from '../cambieOnshoreApplicationList/bankVerifyModal'
import { AllowComponentRightsWise } from '../../WebComponent/allowComponentRightsWise'

let init = {
  visible: false,
  applicationId: '',
  studentId: '',
  totalTuitionFees: '',
  chargingAmount: '',
  allRecord: ''
}

const AgentUniversityDealClose = (props) => {
  let { dealClose, universityId } = props
  let [dealAmount, setDealAmount] = useState(0)
  let findDealFxn = () => {
    let findDoc = _.find(dealClose, (item) => {
      return item.universityId == universityId
    })
    if (findDoc) {
      setDealAmount(findDoc.amount)
    }
  }
  useEffect(() => {
    findDealFxn()
  }, [dealClose])
  return (
    dealAmount ? <label className={'label label-default xs'}>
      Deal : CAD {dealAmount}
    </label> : null
  )
}
const allowReGenerateEnrollment = (user) => {
  let { email, userType } = user
  return (userType == 'admin' || email == 'ashu@unizportal.com')
}
const showToUser = (user) => {
  let { email, userType } = user
  return (userType == 'admin' || email == 'sandeep@unizportal.com')
}
const showUploadTT = (user) => {
  let { userType } = user
  return (userType == 'cambieManager', 'cambieBranchUser')
}
const UploadedDocuments = (props) => {
  let { applications, _reGenerateEnrollmentFunc, user } = props
  let {
    academicSchedule,
    enrolmentLetter,
    enrollmentLetter,
    verifiedTuitionFeeDocuments,
    tuitionFeeDocumentStatus
  } = applications
  return (
    <>
      {verifiedTuitionFeeDocuments ? (
        <div className={'mb10 mt10 alignCenter'}>
          {tuitionFeeDocumentStatus == 'Approved' ?
            <>
              <img src={'/assets/icons/doneCheck.png'} style={{ height: 30 }} />
              <div className={'mt5'}>
                Approved
              </div>
            </> :
            <>
              <div className={'mt5'}>
                Pending
              </div>
            </>
          }
        </div>
      ) : null}


      <div className={'fcmtDiv alignCenter'}>
        {enrolmentLetter && enrolmentLetter.path ? <>
          <Tag><a href={enrolmentLetter.path} target={'_blank'}>Enrolment Letter</a></Tag>
          <br />
        </> : null}

        {enrollmentLetter && enrollmentLetter.path ? <>
          <Tag><a href={enrollmentLetter.path} target={'_blank'}>Enrolment Letter</a></Tag>
        </> : null}
        <br />
        {academicSchedule && academicSchedule.path ? <>
          <Tag style={{ marginTop: 5 }}><a href={academicSchedule.path} target={'_blank'}>Academic Schedule</a></Tag>
        </> : null}

        {enrollmentLetter && enrollmentLetter.path && allowReGenerateEnrollment(user) ?
          <div className={'mt10'}>
            <Popconfirm
              title={'Are you sure, you want to Re-Generate Enrolment Letter?'}
              okText={'Yes'}
              cancelText={'No'}
              onConfirm={() => {
                _reGenerateEnrollmentFunc()
              }}>
              <a className={'btn btn-info btn-xs'}>
                Re-generate
              </a>
            </Popconfirm>
          </div> : null}

      </div>
    </>
  )
}
const UploadTTComponentCambieOnshore = (props) => {
  let dispatch = useDispatch()
  let { student, ttVerifiedStatus, reloadTable, user } = props
  let { applications } = student
  let { verifiedTuitionFeeDocuments, tuitionFeeDocument, chargingAmount } = applications
  let [state, setState] = useState(init)
  let [travelState, setTravelState] = useState(init)
  let [detailState, setDetailState] = useState({
    visible: false,
    details: ''
  })
  let [chargeAmountState, setChargeAmountState] = useState({
    visible: false,
    applicationId: '',
    studentId: '',
    chargingAmount: ''
  })
  let [visibleTTList, setVisibleTTList] = useState(false)
  const ttEvents = {
    showDrawer: () => {
      setState((prevState) => {
        return {
          visible: true,
          applicationId: applications._id,
          studentId: student._id,
          totalTuitionFees: student.applications.tuitionFee,
          chargingAmount: chargingAmount,
          allRecord: student
        }
      })
    },
    hideDrawer: () => {
      setState((prevState) => {
        return {
          ...prevState,
          ...init
        }
      })
    },
    showTravelDrawer: () => {
      setTravelState((prevState) => {
        return {
          visible: true,
          applicationId: applications._id,
          studentId: student._id
        }
      })
    },
    hideTravelDrawer: () => {
      setTravelState((prevState) => {
        return {
          ...prevState,
          ...init
        }
      })
    },
    cancelTuitionFees: async () => {
      let obj = {
        applicationId: applications._id,
        studentId: student._id,
        status: 'Cancel'
      }
      let fd = new FormData()
      fd.append('obj', JSON.stringify(obj))
      let resp = await dispatch(updateTuitionFeesDirectUniversityFxn(fd))
      if (resp && resp.success) {
        reloadTable()
      }
    },
    showDetailModal: (details) => {
      setDetailState((prevState) => {
        return {
          visible: true,
          details: details
        }
      })
    },
    hideDetailModal: () => {
      setDetailState((prevState) => {
        return {
          ...prevState,
          visible: false
        }
      })
    },
    showChargeAmountModal: () => {
      setChargeAmountState(() => {
        return {
          visible: true,
          applicationId: applications._id,
          studentId: student._id,
          chargingAmount: ''
        }
      })
    },
    hideChargeAmountModal: () => {
      setChargeAmountState(() => {
        return {
          ...chargeAmountState,
          visible: false
        }
      })
    },
    submitChargeAmount: async () => {
      if (!chargeAmountState.chargingAmount) {
        notification.warning({
          message: 'Please Enter Charge Amount'
        })
        return
      }
      let obj = {
        studentId: student._id,
        applicationId: applications._id,
        chargingAmount: chargeAmountState.chargingAmount
      }
      let resp = await dispatch(updateChargeAmountFxn({ ...obj }))
      if (resp && resp.success) {
        reloadTable()
        setChargeAmountState({
          visible: false,
          applicationId: '',
          studentId: '',
          chargingAmount: ''
        })
      }
    }
  }

  return (
    <>


      {tuitionFeeDocument && tuitionFeeDocument.amount ? <div className={'mb10'}>
        Tuition Fees : {tuitionFeeDocument.amount}<br />
        Date : {displayDate(tuitionFeeDocument.date)}<br />
        TT :
        <> {tuitionFeeDocument && tuitionFeeDocument.document && tuitionFeeDocument.document.path ?
          <a href={tuitionFeeDocument.document.path}>
            Download
          </a> : null}</><br />
        {tuitionFeeDocument.details ?
          <>Details : <a className={'bs-link'}
                         onClick={() => ttEvents.showDetailModal(tuitionFeeDocument.details)}>View</a><br /></> : null}
      </div> : null}


      <div>
        {user.userType == 'cambieManager' || user.userType == 'cambieBranchUser' ?
          <>
            {((verifiedTuitionFeeDocuments != true
                && !['Verified', 'Disregard'].includes(ttVerifiedStatus)
                && !(tuitionFeeDocument
                  && tuitionFeeDocument.amount))
              && showUploadTT(user)) ?
              <div className={'mt10'}>
                <Tooltip title={'Upload TT'}>
                  <a onClick={() => chargingAmount ? ttEvents.showDrawer() : ttEvents.showChargeAmountModal()}>
                    <img src={'/assets/icons/cloud-computing-upload.png'} />
                  </a>
                </Tooltip>
              </div> : null
            }
          </> : null}


        <div className={'mt10'}>
          {!ttVerifiedStatus || ttVerifiedStatus == 'Pending' ?
            <label className={'label label-info sm'}>Pending</label> : null}
          {ttVerifiedStatus == 'Verified' ?
            <label className={'label label-success-green sm'}>
              {ttVerifiedStatus} {applications.ttVerifiedBy && applications.ttVerifiedBy.name ? ` by ${applications.ttVerifiedBy.name}` : null}
            </label>
            : null}
          {ttVerifiedStatus == 'Bank Verified' ?
            <label className={'label label-success sm'}>
              {ttVerifiedStatus}
            </label>
            : null}
          {ttVerifiedStatus == 'Disregard' ?
            <label className={'label label-danger sm'}>
              {ttVerifiedStatus}
            </label>
            : null}
        </div>

      </div>

      {state.visible ?
        <UploadTuitionFeesCambieOnshore
          {...state}
          onClose={ttEvents.hideDrawer}
          onSubmit={() => {
            ttEvents.hideDrawer()
            reloadTable()
          }} />
        : null}
      {travelState.visible ?
        <UploadTravelDocument
          {...travelState}
          onClose={ttEvents.hideTravelDrawer}
          onSubmit={() => {
            ttEvents.hideTravelDrawer()
            reloadTable()
          }} />
        : null}

      {detailState.visible ? <Modal
        visible={detailState.visible}
        title='Details'
        onCancel={ttEvents.hideDetailModal}
        onOk={ttEvents.hideDetailModal}
        footer={null}>
        {detailState.details}
      </Modal> : null}

      {chargeAmountState.visible ?
        <Modal
          visible={chargeAmountState.visible}
          title='Charge Amount'
          onCancel={() => {
            ttEvents.hideChargeAmountModal()
          }}
          onOk={() => {
            ttEvents.submitChargeAmount()
          }}
          closable={() => {
            ttEvents.hideChargeAmountModal()
          }}
        >
          <InputBox title={'Charge Amount'}>
            <Input
              type='number'
              placeholder='Enter Charge Amount'
              value={chargeAmountState.chargingAmount}
              onChange={e => {
                setChargeAmountState({ ...chargeAmountState, chargingAmount: e.target.value })
              }}
            />
          </InputBox>
        </Modal> : null}


      {visibleTTList ?
        <UploadedTuitionFeesListCambieOnshore
          visible={visibleTTList}
          application={applications}
          onClose={() => {
            setVisibleTTList(false)
          }} /> : null}

    </>
  )
}
const TotalTTComponentCambieOnshore = (props) => {
  let dispatch = useDispatch()
  let { student, ttVerifiedStatus, reloadTable, user, allowDeleteTT = false, showAgentDeal = false } = props
  let { applications } = student
  let {
    tuitionFeeDocuments,
    verifiedTuitionFeeDocuments,
    universityCountry,
    totalTTAmount,
    tuitionFeeDocumentStatus,
    agentId,
    courseUniversity,
    status
  } = applications
  let [state, setState] = useState(init)
  let [travelState, setTravelState] = useState(init)
  let [detailState, setDetailState] = useState({
    visible: false,
    details: ''
  })
  let [visibleTTList, setVisibleTTList] = useState(false)
  let [isBankVerifyModalOpen, setIsBankVerifyModalOpen] = useState(false)
  const ttEvents = {
    showDrawer: () => {
      setState((prevState) => {
        return {
          visible: true,
          applicationId: applications._id,
          studentId: student._id
        }
      })
    },
    hideDrawer: () => {
      setState((prevState) => {
        return {
          ...prevState,
          ...init
        }
      })
    },
    showTravelDrawer: () => {
      setTravelState((prevState) => {
        return {
          visible: true,
          applicationId: applications._id,
          studentId: student._id
        }
      })
    },
    hideTravelDrawer: () => {
      setTravelState((prevState) => {
        return {
          ...prevState,
          ...init
        }
      })
    },
    cancelTuitionFees: async () => {
      let obj = {
        applicationId: applications._id,
        studentId: student._id,
        status: 'Cancel'
      }
      let fd = new FormData()
      fd.append('obj', JSON.stringify(obj))
      let resp = await dispatch(updateTuitionFeesDirectUniversityFxn(fd))
      if (resp && resp.success) {
        reloadTable()
      }
    },
    showDetailModal: (details) => {
      setDetailState((prevState) => {
        return {
          visible: true,
          details: details
        }
      })
    },
    hideDetailModal: () => {
      setDetailState((prevState) => {
        return {
          ...prevState,
          visible: false
        }
      })
    },
    verifiedTTFxn: async (data) => {
      let obj = {
        applicationId: applications._id,
        studentId: student._id
      }
      let resp = await dispatch(verifyTuitionFeesDocumentFxn(obj))
      if (resp && resp.success) {
        reloadTable()
      }
    },
    closeTTFxn: async (data) => {
      let obj = {
        applicationId: applications._id,
        studentId: student._id
      }
      let resp = await dispatch(closeTuitionFeesDocumentFxn(obj))
      if (resp && resp.success) {
        reloadTable()
      }
    },
    disRegardTTFxn: async (data) => {
      let obj = {
        applicationId: applications._id,
        studentId: student._id
      }
      let resp = await dispatch(disRegardTuitionFeesDocumentFxn(obj))
      if (resp && resp.success) {
        reloadTable()
      }
    }
  }

  return (
    <>
      <div>
        {universityCountry && universityCountry.currency && totalTTAmount ? universityCountry.currency : ''} {totalTTAmount ? totalTTAmount : ''}
        <br />
        <a className={'bs_btn bs-default mt5 bs-xxs'}
           style={{ width: 120 }}
           onClick={() => {
             setVisibleTTList(true)
           }}>
          View TT
        </a>

        <br />
        {tuitionFeeDocuments && tuitionFeeDocuments.length && verifiedTuitionFeeDocuments ? (
          <div className={'mb10'}>
            {showToUser(user) ? (
              <div>
                {tuitionFeeDocumentStatus !== 'Approved' ? (
                  <>
                    <a className={`bs_btn bs-default mt5 bs-xxs`} style={{ width: 120 }}
                       onClick={() => {
                         ttEvents.showTravelDrawer()
                       }}>
                      Upload Travel Docs
                    </a>

                  </>
                ) : null}
              </div>
            ) : (
              ''
            )}
          </div>
        ) : null}

        <AllowComponentRightsWise rightUserType={['cambieManager', 'cambieBranchUser']}>

          {verifiedTuitionFeeDocuments !== true && ttVerifiedStatus ? <>
            {ttVerifiedStatus == 'Verified' ?
              <Tooltip title={'Click To Bank Verify'}>
                <a className={'bs_btn bs-default mt5 bs-xxs'}
                   style={{ width: 120 }}
                   onClick={() => {
                     setIsBankVerifyModalOpen(true)
                   }}>
                  Click To Bank Verify
                </a>
              </Tooltip> : null}


            {(ttVerifiedStatus != 'Disregard' && ttVerifiedStatus != 'Verified') ?
              <Tooltip title={'Disregard TT'}>
                <Popconfirm
                  title={'Are you sure, you want to Disregard TT?'}
                  onConfirm={() => {
                    ttEvents.disRegardTTFxn()
                  }}>
                  <a className={'bs_btn bs-danger mt5 bs-xxs'} style={{ width: 120 }}>
                    Disregard TT
                  </a>
                </Popconfirm>
              </Tooltip> : null}

          </> : null}
        </AllowComponentRightsWise>
      </div>


      {showAgentDeal && (user.userType == 'admin' || user.email == 'ashu@unizportal.com') ?
        <div className={'mt5'}>
          {agentId && agentId.dealClose && agentId.dealClose.length ?
            <AgentUniversityDealClose dealClose={agentId.dealClose} universityId={courseUniversity} /> : null}
        </div> : null}

      {isBankVerifyModalOpen ?
        <BankVerifyModal
          visible={isBankVerifyModalOpen}
          onClose={() => {
            setIsBankVerifyModalOpen(false)
          }
          }
          onSubmit={() => {
            setIsBankVerifyModalOpen(false)
            reloadTable()
          }}
          chargeAmount={applications.chargingAmount}
          applications={applications}
          student={student}
        />
        : null}

      {state.visible ?
        <UploadTuitionFeesCambieOnshore
          {...state}
          onClose={ttEvents.hideDrawer}
          onSubmit={() => {
            ttEvents.hideDrawer()
            reloadTable()
          }} />
        : null}
      {travelState.visible ?
        <UploadTravelDocument
          {...travelState}
          onClose={ttEvents.hideTravelDrawer}
          onSubmit={() => {
            ttEvents.hideTravelDrawer()
            reloadTable()
          }} />
        : null}

      {detailState.visible ? <Modal
        visible={detailState.visible}
        title='Details'
        onCancel={ttEvents.hideDetailModal}
        onOk={ttEvents.hideDetailModal}
        footer={null}>
        {detailState.details}
      </Modal> : null}


      {visibleTTList ?
        <UploadedTuitionFeesListCambieOnshore
          visible={visibleTTList}
          application={applications}
          studentId={student._id}
          dispatch={dispatch}
          user={user}
          allowDeleteTT={allowDeleteTT}
          onSubmit={() => {
            reloadTable()
            setVisibleTTList(false)
          }}
          onClose={() => {
            setVisibleTTList(false)
          }} /> : null}

    </>
  )
}
const TotalTTViewComponent = (props) => {
  let dispatch = useDispatch()
  let { student, reloadTable, user, allowDeleteTT = false } = props
  let { applications } = student
  let {
    universityCountry,
    totalTTAmount,
    tuitionFeeDocument
  } = applications
  let [detailState, setDetailState] = useState({
    visible: false,
    details: ''
  })
  let [visibleTTList, setVisibleTTList] = useState(false)

  const ttEvents = {
    showDetailModal: (details) => {
      setDetailState((prevState) => {
        return {
          visible: true,
          details: details
        }
      })
    },
    hideDetailModal: () => {
      setDetailState((prevState) => {
        return {
          ...prevState,
          visible: false
        }
      })
    }

  }

  return (
    <>
      <div>
        {universityCountry && universityCountry.currency && totalTTAmount ? universityCountry.currency : ''} {totalTTAmount ? totalTTAmount : ''}
        <br />
        <a className={'bs_btn bs-default mt10 bs-xs'}
           style={{ width: 120 }}
           onClick={() => {
             setVisibleTTList(true)
           }}>
          View TT
        </a>
      </div>

      {tuitionFeeDocument && tuitionFeeDocument.amount ? <div className={'mt10'}>
        Tuition Fees : {tuitionFeeDocument.amount}<br />
        Date : {displayDate(tuitionFeeDocument.date)}<br />
        TT :
        <> {tuitionFeeDocument && tuitionFeeDocument.document && tuitionFeeDocument.document.path ?
          <a href={tuitionFeeDocument.document.path}>
            Download
          </a> : null}</><br />
        {tuitionFeeDocument.details ?
          <>Details : <a className={'bs-link'}
                         onClick={() => ttEvents.showDetailModal(tuitionFeeDocument.details)}>View</a><br /></> : null}
      </div> : null}

      {visibleTTList ?
        <UploadedTuitionFeesListCambieOnshore
          visible={visibleTTList}
          application={applications}
          studentId={student._id}
          dispatch={dispatch}
          user={user}
          allowDeleteTT={allowDeleteTT}
          onSubmit={() => {
            reloadTable()
            setVisibleTTList(false)
          }}
          onClose={() => {
            setVisibleTTList(false)
          }} /> : null}


      {detailState.visible ? <Modal
        visible={detailState.visible}
        title='Details'
        onCancel={ttEvents.hideDetailModal}
        onOk={ttEvents.hideDetailModal}
        footer={null}>
        {detailState.details}
      </Modal> : null}

    </>
  )
}

UploadTTComponentCambieOnshore.UploadedDocuments = UploadedDocuments
UploadTTComponentCambieOnshore.TotalTTComponent = TotalTTComponentCambieOnshore
UploadTTComponentCambieOnshore.TotalTTViewComponent = TotalTTViewComponent
export default UploadTTComponentCambieOnshore
