import React, { useState, useRef } from 'react'
import {
  Icon,
  DatePicker,
  Tooltip, Row, Col, Select
} from 'antd'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { TableComp } from 'sz-react-utils-lite'
import { dateFilterRange, departmentObj, displayDate } from '../../components/_utils/appUtils'
import InputBox from '../../components/_utils/InputBox'
import { CounsellingFollowupListFxn } from './actions/counselling'
import FollowupComponent from './components/followup'
import CloseCounsellingFollowupComponent from './components/closeCounsellingFollowupComponent'

const { RangePicker } = DatePicker
const { Option } = Select
const initialState = {
  applicationList: [],
  applicationObj: {}
}
let initFilters = {
  fromDate: moment(),
  toDate: moment(),
  status: 'Pending'

}
const dateFormat = 'DD/MM/YYYY'

const CounsellingFollowUpList = (props) => {
  let { user } = props
  const tableRef = useRef()
  const dispatch = useDispatch()
  const [closeFollowState, setCloseFollowState] = useState({
    counsellingId: '',
    visible: false
  })
  const [addFollowState, setAddFollowState] = useState({
    counsellingId: '',
    visible: false
  })
  const [filters, setFilters] = useState(initFilters)
  const [state, setState] = useState(initialState)

  const events = {
    openAddNoteDrawer: value => {
      setState({
        ...state
      })
      setAddFollowState({
        counsellingId: value,
        visible: true
      })
    },
    closeAddNoteDrawer: () => {
      events.tableReload()

      setState({
        ...state
      })
      setAddFollowState({
        counsellingId: '',
        visible: false
      })
    },
    openCloseNoteDrawer: value => {
      setState({
        ...state
      })
      setCloseFollowState({
        counsellingId: value,
        visible: true
      })
    },
    closeNoteDrawer: () => {
      setState({
        ...state
      })
      setCloseFollowState({
        counsellingId: '',
        visible: false
      })
    },
    updateFilter: (data) => {
      setFilters((prevState) => ({
        ...prevState,
        ...data
      }))
    },
    searchFxn: () => {
      if (tableRef.current) {
        tableRef.current.reload()
      }
    },
    clearFxn: () => {
      setFilters(initFilters)
      setTimeout(() => {
        if (tableRef.current) {
          tableRef.current.reload()
        }
      }, 500)
    },
    tableReload: () => {
      if (tableRef.current) {
        tableRef.current.reload()
      }
    }
  }

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let { fromDate, toDate, status } = filters
      if (fromDate) {
        let date = {}
        date.$gte = moment(fromDate).startOf('day')
        date.$lte = moment(toDate).endOf('day')
        params.date = date
      }
      if (status) {
        params.status = status
      }

      const resp = await dispatch(CounsellingFollowupListFxn(params))
      resolve(resp)
    })
  }

  const FilterComponent = () => {
    return (
      <Row gutter={12} className={'filter_box'}>
        <Col span={6}>
          <div className='filter-box'>
            <InputBox title={'Search by date'}>
              <RangePicker
                format={dateFormat}
                value={[
                  filters.fromDate ? moment(filters.fromDate, dateFormat) : null,
                  filters.toDate ? moment(filters.toDate, dateFormat) : null
                ]}
                onChange={(dates) => {
                  events.updateFilter({
                    fromDate: dates[0],
                    toDate: dates[1]
                  })
                }}
                ranges={dateFilterRange}
              />
            </InputBox>
          </div>
        </Col>
        <Col span={6}>
          <div className='filter-box'>
            <InputBox title={'Search by Status'}>
              <Select value={filters.status} onChange={(item) => {
                events.updateFilter({ status: item })
              }}>
                <Option value={'Pending'}>Opened</Option>
                <Option value={'Closed'}>Closed</Option>
              </Select>
            </InputBox>
          </div>
        </Col>
        <Col span={24}>
          <div className={'btn_group'}>
            <a className={'default_btn'} onClick={events.searchFxn}>Search</a>
            <a className={'default_btn'} onClick={events.clearFxn}>Clear</a>
          </div>
        </Col>
      </Row>
    )
  }

  const columns = [
    {
      title: '#',
      key: '_id',
      dataIndex: '_id',
      width: 35,
      render: (item, record, index) => {
        return <div>{index + 1}</div>
      }
    },

    {
      title: 'Student',
      key: 'counsellingId',
      dataIndex: 'counsellingId',
      width: 100,
      render: (item) => {
        return (
          <div>
            {item && item.studentName ? item.studentName : ''}
          </div>
        )
      }
    },
    {
      title: 'Content',
      key: 'content',
      dataIndex: 'content',
      width: 200,
      render: item => {
        return <div>{item}</div>
      }
    },
    {
      title: 'Added On',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 100,
      render: (item, record) => {
        return (
          <div>
            {displayDate(record ? record.createdAt : '')}
          </div>
        )
      }
    },
    {
      title: 'Follow Up Date',
      key: 'date',
      dataIndex: 'date',
      width: 60,
      render: item => {
        return <div style={{ width: 80 }}>{displayDate(item)}</div>
      }
    },


    /*{
      title: 'Time',
      key: 'time',
      dataIndex: 'time',
      width: 60,
      render: (item, record) => {
        return (
          <div style={{ width: 80 }}>
            {record.meetingId
              ? displayTime(record.meetingId.fromTime)
              : ''}
          </div>
        )
      }
    },*/

    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 40,
      render: (item, record) => {
        return (
          <div style={{ width: 60 }}>
            {record.status == 'Pending' ? (
              <>
                <label className={'label label-success'}>{record.status}</label>
              </>
            ) : (
              <>
                <label className={'label label-danger'}>{record.status}</label>
              </>
            )}
          </div>
        )
      }
    },
    {
      title: 'Action',
      key: 'Action',
      dataIndex: 'Action',
      width: 40,
      render: (item, record) => {
        let counsellingId = record.counsellingId && record.counsellingId._id ? record.counsellingId._id : record.counsellingId
        return (
          <div>
            {user.userType == 'counsellingManager' || user.department == departmentObj.counselling ?
              <>
                {record.status == 'Pending' ? (
                  <>
                    <div className='ml4'>

                      <Tooltip title={'Add Follow Up'}>
                        <button
                          className='btn'
                          onClick={() => {
                            events.openAddNoteDrawer(counsellingId)
                          }}>
                          <Icon type={'plus'} />
                        </button>
                      </Tooltip>
                      <Tooltip title={'Close Follow Up'}>
                        <button
                          className='btn'
                          onClick={() => {
                            events.openCloseNoteDrawer(counsellingId)
                          }}>
                          <img
                            src='../dist/img/cross.svg'
                            alt=''
                            style={{ width: 30, height: 12, color: 'black' }}
                          />
                        </button>
                      </Tooltip>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </> : null}
          </div>
        )
      }
    }
  ]
  return (
    <>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>

            <div className='table-head d-flex align-items-center all-student-search'
                 style={{ height: 60 }}>

              <h5>All Followups </h5>
              <div className='search-box-table' style={{ backgroundColor: 'transparent' }}>

              </div>
              <div className='sort-box-table mark-btn'>

              </div>
            </div>
            <div className='card-body table-responsive'>
              <FilterComponent />

              <TableComp
                apiRequest={apiRequest}
                columns={columns}
                size={'small'}
                bordered={true}
                ref={tableRef}
              />
            </div>
          </div>
        </div>
      </div>


      {addFollowState.visible ? (
        <FollowupComponent
          {...addFollowState}
          addedType={'direct'}
          onClose={() => {
            events.closeAddNoteDrawer()
            events.tableReload()
          }}
        />
      ) : null}
      {closeFollowState.visible ? (
        <CloseCounsellingFollowupComponent
          {...closeFollowState}
          addedType={'closed'}
          onClose={() => {
            events.closeNoteDrawer()
            events.tableReload()
          }}
        />
      ) : null}
    </>
  )
}

export default CounsellingFollowUpList
