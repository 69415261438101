import React, {useState, useRef} from 'react'
import {Form, Button, Col, Row, notification, Tooltip} from 'antd'
import {FileInput, newFormatDisplayDate} from '../../components/_utils/appUtils'
import {useDispatch} from 'react-redux'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import {
    addUnizhomeContractFxn,
    unizhomeContractListFxn
} from "./actions";
import {TableComp} from "sz-react-utils-lite";
import NotesHistory from "./noteHistory";
import WrappedUpdateContractDrawer from "./updateUnizhomeContract";

const UnizhomeContract = (props) => {
    const {form: {getFieldDecorator}} = props
    const [file, setFile] = useState(null)
    const [total, setTotal] = useState(0)

    const [isNoteHistoryVisible, setNoteHistoryVisible] = useState({
        visible: false,
        contract: {}
    });
    const [isUpdateDrawerVisible, setUpdateDrawerVisible] = useState({
        visible: false,
        contractId: ''
    });
    const dispatch = useDispatch()
    const tableRef = useRef()

    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }
    const submitFormLayout = {
        wrapperCol: {
            xs: {span: 24, offset: 0},
            sm: {span: 24, offset: 0},
            md: {span: 24, offset: 0}
        }
    }

    const events = {
        apiRequest: (params) => {
            return new Promise(async (resolve) => {
                let resp = await dispatch(unizhomeContractListFxn({
                    ...params
                    // regExFilters: ['semester', 'courseName', 'name']
                }))
                setTotal(resp.total)
                resolve(resp)
            })
        },
        handleFileChange: (target) => {
            const file = target.files[0]
            setFile(file)

        },
        handleSubmit: async (e) => {
            e.preventDefault();
            const {form} = props;
            form.validateFieldsAndScroll(async (err, valData) => {
                if (err) {
                    notification.warning({message: 'Fill All Required Fields'});
                    return;
                }
                const fd = new FormData();


                fd.append('obj', JSON.stringify(valData))
                if (file && file.name) {
                    fd.append('contract', file)
                }

                const response = await dispatch(addUnizhomeContractFxn(fd));
                if (response && !response.error) {
                    form.resetFields();
                    setFile(null)
                    notification.success({message: response.message});
                    tableRef.current.reload()
                }
            });
        },

        showUpdateDrawer: (contractId) => {
            setUpdateDrawerVisible({
                visible: true,
                contractId
            });
        },

        closeUpdateDrawer: () => {
            setUpdateDrawerVisible({
                visible: false,
                contractId: ''
            });
            tableRef.current.reload();
        },

        showNoteHistoryModal: (contract) => {
            setNoteHistoryVisible({
                visible: true,
                contract: contract
            });
        },

        closeNoteHistoryModal: () => {
            setNoteHistoryVisible({
                visible: false,
                contract: {}
            });
            tableRef.current.reload()
        },

    }

    const formFields = {
        fields: [
            {
                key: 'companyName',
                label: 'Company Name',
                placeholder: 'Enter Company Name',
                type: 'text',
                required: true,

            },
            {
                key: 'contactPersonName',
                label: 'Contact Person Name',
                placeholder: 'Enter Contact Person Name',
                type: 'text',
                required: true,
            },
            {
                key: 'contactMobileNo',
                label: 'Contact Mobile No',
                placeholder: 'Enter Mobile No',
                type: 'text',
                required: true,

            },
            {
                key: 'email',
                label: 'Email Id',
                placeholder: 'Enter Email',
                type: 'text',
                required: true,

            },
            {
                key: 'uploadContract',
                label: 'Upload Contract',
                required: true,
                placeholder: 'Upload Contract',
                type: 'file',
                fileName: 'contract',
                onChange: ({target}) => {
                    events.handleFileChange(target)
                },

            },
            {
                key: 'expiryDate',
                label: 'Expiry Date',
                placeholder: 'Expiry Date',
                type: 'date',

            },
        ]
    }

    const columns = [
        {
            title: 'Sr No.',
            dataIndex: 'serialNo',
            width: 80,
            render: (val, record, index) => <div>{index + 1}</div>
        },

        {
            title: 'Company Name',
            key: 'companyName',
            dataIndex: 'companyName',

        },
        {
            title: 'Contact Person',
            key: 'contactPersonName',
            dataIndex: 'contactPersonName',

        },
        {
            title: 'Mobile',
            key: 'contactMobileNo',
            dataIndex: 'contactMobileNo',

        },
        {
            title: 'Email',
            key: 'email',
            dataIndex: 'email',

        },
        {
            title: 'Expiry Date',
            dataIndex: 'expiryDate',
            key: 'expiryDate',
            render: (item) => <div>{newFormatDisplayDate(item)}</div>
        },
        {
            title: 'Added By',
            dataIndex: 'addedBy',
            key: 'addedBy',
            width: 150,
            render: (item, record) => {
                return item && item.name ? item.name : ''
            }
        },
        {
            title: 'Contract',
            dataIndex: 'contract',
            key: 'contract',
            width: 120,
            render: (item) => {
                return (
                    <>
                        {item && item.path ?
                            <a href={item.path} target={'_blank'}>
                                Download
                            </a>
                            : null}
                    </>
                )
            }
        },
        {
            title: 'Latest Note',
            dataIndex: 'latestNote',
            key: 'latestNote',
            width: 180,
            render: (item, record) => {
                return item && item.note ? item.note : ''
            }
        },
        {
            key: 'actions',
            title: 'Actions',
            fixed: 'right',
            width: 100,
            render: (text, record) => {
                return (
                    <>
                        <Tooltip title="Update">
                            <Button
                                shape="circle"
                                size="small"
                                icon="edit"
                                onClick={() => events.showUpdateDrawer(record._id)}
                            />
                        </Tooltip>

                      <Tooltip title="Notes History">
                          <Button
                              shape="circle"
                              size="small"
                              onClick={() => events.showNoteHistoryModal(record)}
                              icon="history"
                          />
                      </Tooltip>
                  </>
                );
            }
        }
    ];
    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <Form onSubmit={events.handleSubmit}>
                        <div className="form-box mt-4">

                            <div className="d-flex align-items-center mb-3 heading-form">
                                <h5>Unizhome Contract</h5>
                            </div>

                            <div className="card unizportal">
                                <Row gutter={24}>
                                    {formFields.fields.map((item, key) => {
                                        return (
                                            <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8} sm={24}
                                                 xs={24}
                                                 key={key}>
                                                {item.type == 'file' ?
                                                    <FileInput name={item.fileName}
                                                               label={item.label}
                                                               className={'mt10'}
                                                               chooseDocument={item.onChange}/>
                                                    : <GetEachFormFields
                                                        item={item}
                                                        getFieldDecorator={getFieldDecorator}
                                                        formItemLayout={formItemLayout}/>
                                                }
                                            </Col>
                                        )
                                    })}
                                </Row>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="btn">
                                        Submit
                                    </Button>
                                </Form.Item>

                            </div>
                        </div>

                    </Form>
                </div>
            </div>
            <div className="card mt-1">
                <div className="table-head d-flex align-items-center">
                    <h5>Contract List: (Total: {total})</h5>
                </div>
                <div className="card-body table-responsive">
                    <TableComp
                        apiRequest={events.apiRequest}
                        columns={columns}
                        ref={tableRef}
                        extraProps={{scroll: {x: 600}}}
                        pagination={{
                            position: 'top',
                            pageSizeOptions: ['10', '20', '25', '50'],
                            defaultPageSize: 10,
                        }}
                    />
                </div>
            </div>
            {isUpdateDrawerVisible.visible && (
                <WrappedUpdateContractDrawer
                    visible={isUpdateDrawerVisible.visible}
                    onClose={events.closeUpdateDrawer}
                    contractId={isUpdateDrawerVisible.contractId}
                    reloadTable={() => tableRef.current.reload()}
                />
            )}

            {
                isNoteHistoryVisible.visible ? (
                    <NotesHistory
                        visible={isNoteHistoryVisible.visible}
                        onClose={events.closeNoteHistoryModal}
                        contract={isNoteHistoryVisible.contract}
                    />
                ) : null
            }

        </>

    )
}


const UnizhomeContractWrapped = Form.create()(UnizhomeContract)
export default UnizhomeContractWrapped
