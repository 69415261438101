import { apiUrl2, apiUrl } from '../../../settings'

export const travelPlanUrl = () => {
  return apiUrl2 + '/travel-plan'
}

export const userTravelPlanUrl = (userId) => {
  return `${apiUrl2}/travel-plan/${userId}`
}

export const allTravelPlanUrl = () => {
  return `${apiUrl2}/api/getAllTravelPlan`
}

export const allTravelPlan = () => {
  return `${apiUrl2}/all-travel-plan`
}

export const addCollaborationUrl = () => {
  return `${apiUrl2}/add-Collaboration`
}
export const updateCollaborationUrl = () => {
  return `${apiUrl2}/update-Collaboration`
}
export const updateTravelPlanUrl = () => {
  return `${apiUrl2}/update-travelPlan`
}
export const getCityAndDateWiseTravelPlanUrl = () => {
  return `${apiUrl2}/get-cityWise-travelPlan-dashboard`
}
export const addOfficeVisitsUrl = () => {
  return `${apiUrl2}/add-office-visits`
}
export const addNewOfficeVisitRequestUrl = () => {
  return `${apiUrl2}/add-new-office-visits`
}
export const travelHistoryUrl = () => {
  return `${apiUrl}/travel-history`
}
