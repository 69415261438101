import {Icon, Tooltip} from 'antd'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import {useDispatch} from 'react-redux'
import {
    CambieHCollege,
    LesterCollege,
    RoyalArtsCollege,
    fcmtUniversity,
    departmentObj,
    CanadaRoyalHighSchool,
    CornerStoneCollege,
    NationalCollege, cyprusUniversityList, KasaHighSchool, CasaColleges, CasaHCollege
} from '../../../components/_utils/appUtils'
import {getUrlPushWrapper} from '../../../routes'
import CambieUniversity from '../../evaluateProfile/cambie/cambieUniversity'
import FlairUniversity from '../../evaluateProfile/fcmt/flairUniversity'
import {canadaUniversityRecordsCountFxn, cyprusUniversityRecordsCountFxn} from '../../reporting/actions'
import DirectUniversityComponent from '../drawers/directUniversityComponent'

const ButtonLink = props => {
    let {
        searchData,
        item,
        record,
        userCountryName,
        userData,
        applyForCourse,
    } = props
    const btnA = (
        <li className={'canadaDirectUniCount canadaDirectUniBox'} key={item.id}>
            <div className={'innerBox'}>
                <div className={'uniCountBox'} onClick={() => searchData(item)}>
                    <LazyLoadImage src={item.image} style={{height: '100%'}}/>
                    <h5 className={'record-count'}>{record.total ? record.total : 0}</h5>
                </div>
                <div className={'btnBox'}>
                    <a
                        onClick={() => {
                            applyForCourse(item.id)
                        }}>
                        <Icon type={'plus-circle'} size={13}/> Add Student
                    </a>
                </div>
            </div>
        </li>
    )
    let branchUserCheck = () => {
        let checkUniversityExists = _.find(userData.universities, item1 => {
            return item1.toString() == item.id
        })
        return userData.department == 'Visa Approved Department' ||
        userData.department == departmentObj.marketing || userCountryName == 'Cyprus'
            ? btnA
            : checkUniversityExists
                ? btnA
                : null
    }
    return (
        <>
            {userData.userType == 'admin' || userData.userType == 'agent' || userData.userType == 'subAgent' ? btnA : null}
            {userData.userType == 'branchManager' && userCountryName == 'Cyprus' ? btnA : null}
            {userData.userType == 'branchUser' ? branchUserCheck() : null}
        </>
    )
}

const EuropeUniversityRecordCount = props => {
    let {directUniversityRecords, userData} = props;
    let dispatch = useDispatch()

    let [directApplicationDrawer, setDirectApplicationDrawer] = useState({
        visible: false,
        universityId: ''
    })


    let searchData = (item, type = '') => {
        if (item.id == KasaHighSchool) {
            dispatch(
                getUrlPushWrapper('kasaApplicationList', {
                    appUniversityId: KasaHighSchool
                })
            )
        } else if (item.id == CasaHCollege) {
            dispatch(
                getUrlPushWrapper('casaApplicationList', {
                    appUniversityId: CasaHCollege
                })
            )
        }
    }

    const events = {
        showDirectApplicationDrawer: universityId => {
            setDirectApplicationDrawer({
                visible: true,
                universityId: universityId
            })
        },
        hideDirectApplicationDrawer: () => {
            setDirectApplicationDrawer({
                visible: false,
                universityId: ''
            })
        },
        applyForCourse: universityId => {
            events.showDirectApplicationDrawer(universityId)
        },
    }

    return userData && userData._id ? (
        <>
            <div className='card-pane-new listing-section w-100 direct-uni-box' style={{border: 'none'}}>
                <ul className={'count flex-wrap'}>
                    {cyprusUniversityList.map(item => {
                        return (
                            <ButtonLink
                                key={item.id}
                                uniId={item.id}
                                {...props}
                                item={item}
                                userData={userData}
                                record={directUniversityRecords[item.id] ? directUniversityRecords[item.id] : {}}
                                searchData={searchData}
                                applyForCourse={events.applyForCourse}
                            />
                        )
                    })}
                </ul>
            </div>

            {directApplicationDrawer && directApplicationDrawer.visible && directApplicationDrawer.universityId ? (
                <DirectUniversityComponent
                    visible={directApplicationDrawer.visible}
                    selectedUniversity={directApplicationDrawer.universityId}
                    onClose={events.hideDirectApplicationDrawer}
                    {...props}
                />
            ) : null}
        </>
    ) : null
}

export default EuropeUniversityRecordCount
