import React, { useRef, useState } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { connect, useDispatch } from 'react-redux'
import { displayDate } from '../../../components/_utils/appUtils'
import { CheckMyRights } from '../../WebComponent/CheckUserRights'
import { getCornetStoneStudentListFxn, updateCornerStoneStudentsFxn } from '../actions'
import { Button, Col, DatePicker, Icon, Input, Modal, notification, Popconfirm, Row, Tooltip } from 'antd'
import { getUrlPushWrapper } from '../../../routes'
import moment from 'moment'
import TextArea from 'antd/es/input/TextArea'
import { push } from 'connected-react-router'

const RenderComment = props => {
  let { item } = props
  let [hideV, setHideV] = useState(true)
  let text = item.details || item.reason
  return (
    item && (
      <div>
        <div className={hideV ? 'appCommentDotsReplace' : ''}>
          {text ? text : ''}
        </div>
        {text && text.length && text.length > 50 ? (
          <div className={'alignRight'}>
            {hideV ? (
              <a
                onClick={() => {
                  setHideV(false)
                }}>
                Show More
              </a>
            ) : (
              <a
                onClick={() => {
                  setHideV(true)
                }}>
                Show Less
              </a>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  )
}

const StudentListCornetStone = (props) => {
  let { currentUser } = props
  // let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  const dispatch = useDispatch()
  const tableAppRef = useRef()
  let [total, setTotal] = useState(0)
  const [name, setName] = useState('')
  const [date, setDate] = useState('')
  let [filters, setFilters] = useState({})
  let [tableSearch, setTableSearch] = useState(false)
  let [details, setDetails] = useState('')
  let [reason, setReason] = useState('')
  let [detailsModal, setDetailsModal] = useState({
    visible: false,
    id: ''
  })

  let [cancelModal, setCancelModal] = useState({
    visible: false,
    id: ''
  })
  const apiRequest = (params) => {
    params.sortField = '_id'
    params.sortOrder = 'descend'
    if (tableSearch) {
      params.page = 1
    }
    if (name) {
      params.name = name
    }
    if (date) {
      const startDate = new Date(date)
      startDate.setHours(0, 0, 0, 0)
      const endDate = new Date(date)
      endDate.setHours(23, 59, 59, 999)
      params.createdAt = { $gte: startDate, $lte: endDate }
    }
    setFilters(params)
    return dispatch(getCornetStoneStudentListFxn({ ...params, regExFilters: ['name'] })).then((data) => {
      setTotal(data.total)
      setTableSearch(false)
      return data
    })
  }

  const updateStudent = async (id, status) => {
    if (status == 'cancel' && !reason) {
      notification.warning({ message: 'Enter reason' })
      return
    }
    let data = await dispatch(updateCornerStoneStudentsFxn({ id, status, details, reason }))
    if (data) {
      setDetailsModal({ visible: false, id: '' })
      setCancelModal({ visible: false, id: '' })
      tableAppRef.current.reload()
    }
  }

  let columns = [
    {
      title: '#',
      key: '_id',
      dataIndex: '_id',
      width: 50,
      render: (ite, record, index) => {
        return (
          <React.Fragment>
            {index + 1}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      searchTextName: 'name',
      width: 100
    },
    {
      title: 'Date Of Birth',
      dataIndex: 'dob',
      key: 'dob',
      width: 100,
      render: (item, record) => {
        return (
          <React.Fragment>
            {item ? displayDate(item) : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Added On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      render: (item, record) => {
        return (
          <React.Fragment>
            {item ? displayDate(item) : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Invoice Link',
      key: 'invoiceLink',
      dataIndex: 'invoiceLink',
      searchTextName: 'invoiceLink',
      width: 100,
      render: (item, record) => (
        item && (
          <React.Fragment>
            <a href={item.startsWith('http') ? item : `https://${item}`} target='_blank'
               className={'btn btn-default roundNew xs'}
               rel='noopener noreferrer'>View</a>
          </React.Fragment>
        )
      )
    },
    {
      title: 'PAL Document',
      dataIndex: 'palDocument',
      key: 'palDocument',
      width: 100,
      render: (item, record) => {
        return (
          item && (
            <React.Fragment>
              <a className={'btn btn-default roundNew xs'} onClick={() => window.open(item.path, '_blank')}>Download</a>
            </React.Fragment>
          )
        )
      }
    },
    {
      title: 'LOA Document',
      dataIndex: 'loaDocument',
      key: 'loaDocument',
      width: 100,
      render: (item, record) => {
        return (
          item && (
            <React.Fragment>
              <a className={'btn btn-default roundNew xs'} onClick={() => window.open(item.path, '_blank')}>Download</a>
            </React.Fragment>
          )
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 70,
      render: (item, record) => {
        return (
          <React.Fragment>
            {item == 'done' ? <label className={'label label-success sm'}>Done</label> : ''}
            {item == 'cancel' ? <label className={'label label-danger sm'}>Cancel</label> : ''}
            {item == 'Pending' ? <label className={'label label-warning sm'}>Pending</label> : ''}
          </React.Fragment>
        )
      }
    },
    /* {
       title: 'Details',
       key: 'details',
       dataIndex: 'details',
       width: 200
     },
     {
       title: 'Reason',
       key: 'reason',
       dataIndex: 'reason',
       width: 200
     },*/
    {
      title: 'Details/Reason',
      key: 'details',
      dataIndex: 'details',
      width: 300,
      render: (item, record) => {
        return <RenderComment item={record} />
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 70,
      render: (text, record) => {
        return <React.Fragment>
          {
            (record.status == 'Pending' && currentUser.email == 'khushi@unizportal.com') ? (
              <>
                <Tooltip title='Done'>
                  <button
                    className={'btn'}
                    style={{ marginRight: 6 }}
                    onClick={() => {
                      setDetailsModal({ visible: true, id: record._id })
                    }}
                  >
                    <Icon type={'check'} />
                  </button>
                </Tooltip>
                <Tooltip title='Cancel'>
                  <button
                    className={'btn'}
                    style={{ marginRight: 6 }}
                    onClick={() => {
                      setCancelModal({ visible: true, id: record._id })
                    }}
                  >
                    <Icon type={'cross'} />
                  </button>
                </Tooltip>
              </>
            ) : null
          }
        </React.Fragment>
      }
    }
  ]

  const searchFxn = async () => {
    setTimeout(() => {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
    }, 200)
  }

  const clearFxn = async () => {
    let obj = {}
    setName('')
    setDate('')
    dispatch(
      getUrlPushWrapper('student-list', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      tableAppRef.current.reload()
    }, 200)
  }

  const events = {
    reloadFxn: () => {
      tableAppRef.current.reload()
    },

    refreshApplication: () => {
      tableAppRef.current.reload()
    },

    enterName: (value) => {
      setName(value)
    },
    renderToAdd: () => {
      dispatch(push('/add-pal'))
    }
  }

  const extra = (
    <div className={'mt10 filter_box'}>
      <Row gutter={12}>
        <Col md={5} sm={5} xs={8} lg={5}>
          <input
            type='search'
            placeholder='Search by name...'
            value={name}
            onChange={e => {
              events.enterName(e.target.value)
            }}
          />
        </Col>
        <Col md={5} sm={5} xs={8} lg={5}>
          <DatePicker
            selected={date}
            onChange={(value) => {
              setDate(moment(value).toDate())
            }}
          />
        </Col>
        <Col md={4} sm={4} xs={12} lg={4}>
          <Button onClick={() => searchFxn()} className={'roundBtn'}>
            Search
          </Button>
          <Button onClick={() => clearFxn()} className={'roundBtn'}>
            Clear
          </Button>
        </Col>
      </Row>
    </div>
  )


  return (
    <div>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>Student List (Total : {total})</h5>
              <div className='search-box-table'>
              </div>
              <div className='sort-box-table mark-btn'>
                {currentUser && currentUser.userType == 'cornerStoneManager' ? <>
                  <a className='btn' onClick={events.renderToAdd}>
                    <img src={'../dist/icons/plus.png'} className={'plus'} />
                    Upload Pal</a>
                </> : ''}
              </div>
            </div>
            <div className='card-body table-responsive'>
              {extra}
              <TableComp columns={columns}
                         pagination={{
                           position: 'top',
                           showSizeChanger: true,
                           pageSizeOptions: ['10', '20', '50', '100'],
                           defaultPageSize: 10,
                           current: filters.page ? filters.page : 1
                         }}
                         apiRequest={apiRequest}
                         ref={tableAppRef}
                         extraProps={{ scroll: { x: 1000 } }}

              />
            </div>
            {
              detailsModal.visible ? (
                <Modal
                  title='Enter Detail'
                  visible={detailsModal.visible}
                  onOk={() => {
                    updateStudent(detailsModal.id, 'done')
                  }}
                  onCancel={() => {
                    setDetailsModal({ ...detailsModal, visible: false })
                  }}
                >
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    value={details}
                    onChange={(e) => setDetails(e.target.value)}
                    placeholder='Enter Details..'
                  />
                </Modal>
              ) : null
            }
            {
              cancelModal.visible ? (
                <Modal
                  title='Enter Reason'
                  visible={cancelModal.visible}
                  onOk={() => {
                    updateStudent(cancelModal.id, 'cancel')
                  }}
                  onCancel={() => {
                    setCancelModal({ ...cancelModal, visible: false })
                  }}
                >
                  <TextArea
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder='Enter reason for Cancel'
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Modal>
              ) : null
            }
          </div>
        </div>
      </div>

    </div>)
}


const mapStateToProps = ({ global }) => ({
  currentUser: global.currentUser
})
export default connect(
  mapStateToProps,
  null
)(StudentListCornetStone)
