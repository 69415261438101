import React, { useState, useEffect, useRef } from 'react'
import {
  Tabs
} from 'antd'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { DefaultTablePagination, displayDate } from '../../../components/_utils/appUtils'
import { getUserBirthdayList } from '../actions/user'
// import { agentCommissionCount } from '../actions'


const { TabPane } = Tabs

const UserBirthdayList = () => {
  let [user, setUser] = useState({})
  const [itemNo, setItemNo] = useState(1)
  const [visible, setVisible] = useState(false)
  const [visiblePayCommission, setVisiblePayCommission] = useState(false)
  const [agentId, setAgentId] = useState('')
  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let resp = await dispatch(getUserBirthdayList())
      resolve(resp)
    })
  }


  useEffect(() => {
    loadUser()
  }, [])

  const loadUser = async () => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    setUser(user)
  }


  const columns = [

    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (value, item, index) => (itemNo - 1) * 10 + index + 1
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'dateOfBirth',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      render: (item, record) => {
        return (
          <div>
            {displayDate(item)}
          </div>
        )
      }
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (item, record) => {
        return (
          <div>
            {item}
          </div>
        )
      }
    }
  ]


  return (
    <div className='row  mt-4'>
      <div className='col-lg-12'>
        {/*<div className="card">*/}
        {/*  <div className="table-head d-flex align-items-center">*/}
        {/*    <h5>Today Birthday</h5>*/}
        {/*  </div>*/}
        {/*  <div className="card-body table-responsive">*/}
        {/*    <TableComp columns={columns}*/}
        {/*               apiRequest={apiRequest}*/}
        {/*               pagination={DefaultTablePagination()}*/}
        {/*               ref={tableRef}/>*/}

        {/*  </div>*/}
        {/*</div>*/}
        <div className='card'>
          <div className='table-head d-flex align-items-center'>
            <h5>Upcoming Birthday's</h5>
          </div>
          <div className='card-body table-responsive'>
            <TableComp columns={columns}
                       apiRequest={apiRequest}
                       pagination={DefaultTablePagination()}
                       ref={tableRef} />

          </div>
        </div>
      </div>

    </div>
  )
}

export default UserBirthdayList
