import React, {useEffect, useState} from "react"
import {Col, Drawer, Form, Input, notification, Row, Select} from "antd";
import {RowTable} from "../../../components/_utils/RowTable";
import {InputBox} from "../../../components/_utils/appUtils";
import {uploadAppDocumentAndStatus} from "../../onShoreCanada/actions";
import {useDispatch} from "react-redux";

let {Option} = Select;
let ttStatus = "Tuition Fee Paid & TT Received"
const OnShoreMoveToNextStage = (props) => {
    let dispatch = useDispatch()
    let {visible, statusArr, onClose, studentData, reloadFxn} = props;
    let [status, setStatus] = useState(undefined)
    let [state, setState] = useState({});

    useEffect(() => {
        if (statusArr && statusArr.length == 1) {
            setStatus(statusArr[0])
        }
    }, [statusArr])

    const events = {
        chooseDocument: (e) => {
            let {name, files} = e.target
            if (files && files.length) {
                setState({
                    ...state,
                    [name]: files[0]
                })
            }
        },
        handleSubmit: async () => {
            let fd = new FormData();
            if (!status) {
                return notification.warn({message: `Please choose status`})
            }
            let obj = {
                _id: studentData._id,
                status: status,
                studentId: studentData._id,
                applicationId: studentData.applications._id
            }
            fd.append('obj', JSON.stringify(obj))

            if (status !== 'Case Revoked') {
                if (!state[status] || (state[status] && !state[status].name)) {
                    return notification.warn({message: `Please choose ${status}`})
                }
                fd.append('document', state[status])
            }

            if (status == ttStatus) {
                if (!state["loaLetter"] || (state["loaLetter"] && !state["loaLetter"].name)) {
                    fd.append('loaLetter', state["loaLetter"])
                }
            }

            let data = await dispatch(uploadAppDocumentAndStatus(fd))
            if (data && data.success) {
                onClose()
                reloadFxn()
            }
        }
    }
    return (
        <Drawer
            title={`Move to ${status ? status : ""} stage`}
            onClose={onClose}
            width={'40%'}
            visible={visible}>
            <div className='row'>
                <div className='col-lg-12'>
                    <Form className={'vertical-form'} autoComplete='off'>
                        <div className='form-box'>
                            <div className='card unizportal'>
                                {studentData && studentData.applications && studentData.applications.id ?
                                    <div className={'tableBox striped'}>
                                        <RowTable title={'Name (ID)'}
                                                  value={<span>{studentData.name} ({studentData.studentId})</span>}/>
                                        <RowTable title={'Application ID'}
                                                  value={<span>{studentData.applications.id}</span>}/>
                                        <RowTable title={'Application'}
                                                  value={<span>{studentData.applications.courseName}</span>}/>
                                    </div> : null}
                                <br/>

                                <div className='inner-form'>
                                    <Row gutter={24}>
                                        {statusArr && statusArr.length > 1 ?
                                            <Col span={24}>
                                                <Select
                                                    placeholder={'Choose Status'}
                                                    value={status}
                                                    onChange={(status) => {
                                                        setStatus(status)
                                                    }}>
                                                    {statusArr.map((item) => {
                                                        return (
                                                            <Option key={item} value={item}>{item}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Col> : null}

                                        {status !== "Case Revoked" ? <Col span={24}>
                                            <InputBox title={`${status ? status : ""} Document`}>
                                                <Input type={'file'} name={status} id={status}
                                                       className={'form-control'}
                                                       onChange={(e) => {
                                                           events.chooseDocument(e)
                                                       }}/>
                                            </InputBox>
                                        </Col> : null}

                                        {status == ttStatus ? <Col span={24}>
                                            <InputBox title={`LOA Document`}>
                                                <Input type={'file'} name={"loaLetter"} id={"loaLetter"}
                                                       className={'form-control'}
                                                       onChange={(e) => {
                                                           events.chooseDocument(e)
                                                       }}/>
                                            </InputBox>
                                        </Col> : null}
                                    </Row>

                                    <button className='btn' type='submit' onClick={events.handleSubmit}>save</button>
                                </div>
                            </div>

                        </div>
                    </Form>
                </div>
            </div>
        </Drawer>
    )
}
export default OnShoreMoveToNextStage
