import {
    Button,
    Col,
    Form,
    Icon,
    Input, notification,
    Row,
    Select,
    Tooltip
} from 'antd'
import React, {Suspense, useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {
    DefaultTablePagination,
    InputBox,
    departmentObj
} from '../../../components/_utils/appUtils'
import {listAllUsers} from '../../users/actions/user'
import {listUsers} from '../actions/userTarget'
import EditUserTarget from './editUserTarget'
import _ from "lodash"
import moment from "moment";
import AddTarget from "../drawers/addTarget";

const {Option} = Select

let initState = {
    userId: '',
    target: '',
    userType: '',
    universityTarget: []
}

const userTarget = () => {
    let tableRef = useRef()
    const [visibleUpdateTarget, setVisibleUpdateTarget] = useState(false)
    const [formValues, setFormValues] = useState(initState)
    let [userList, setUserList] = useState([])
    const [toEditTargetId, setToEditTargetId] = useState()
    let [addTargetVisible, setAddTargetVisible] = useState(false)
    const events = {
        showAddTarget: () => {
            setAddTargetVisible(true)
        },
        hideAddTarget: () => {
            setAddTargetVisible(false)
        }
    }


    useEffect(() => {
    }, [])

    const dispatch = useDispatch()

    const apiRequest = params => {
        return new Promise(async resolve => {
            let resp = await dispatch(
                listUsers({
                    ...params,
                    regExFilters: ['userType', 'userId', 'target']
                })
            )
            resolve(resp)
        })
    }
    const loadBranchManagers = async () => {
        return new Promise(async (resolve) => {
            let obj = {
                userType: 'branchManager',
                customQuery: {
                    'agents.0': {$exists: true}
                },
                block: false,
                results: 100,
                select: ['name', 'email', 'mobile', 'agents', 'countryId']
            }
            let {data} = await dispatch(listAllUsers(obj))
            resolve(data)
        })
    }

    const loadMarketingUsers = async () => {
        return new Promise(async (resolve) => {
            let obj = {
                userType: 'branchUser',
                department: departmentObj.marketing,
                results: 100,
                block: false,
                select: ['name', 'email', 'mobile', 'agents', 'marketingCountries']
            }
            let {data} = await dispatch(listAllUsers(obj))
            resolve(data)
        })
    }


    const columns = [
        {
            title: 'User ID',
            dataIndex: 'userId.name',
            key: 'userId.name',
            searchTextName: 'name'
        },
        {
            title: 'User Type',
            dataIndex: 'userType',
            key: 'userType',
            searchTextName: 'userType'
        },
        {
            title: 'Target',
            dataIndex: 'target',
            key: 'target',
            searchTextName: 'target'
        },
        {
            title: 'Action',
            width: 70,
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Tooltip title="Edit">
                        <button
                            className={'btn'}
                            style={{marginRight: 8}}
                            onClick={() => {
                                {
                                    setToEditTargetId(record._id)
                                    setVisibleUpdateTarget(true)
                                }
                            }}>
                            <Icon type={'edit'}/>
                        </button>

                    </Tooltip>
                )
            }
        }
    ]


    return (
        <>
            <div className="row mt-4">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="table-head d-flex align-items-center">
                            <h5>All Targets</h5>
                            <div className="search-box-table">

                            </div>
                            <div className="sort-box-table mark-btn">
                                <a className="btn"
                                   onClick={() => {
                                       events.showAddTarget()
                                   }}>
                                    <img src={'../dist/icons/plus.png'} className={'plus'}/>
                                    Add Target</a>
                            </div>
                        </div>
                        <div className="card-body table-responsive">
                            <TableComp
                                columns={columns}
                                pagination={DefaultTablePagination()}
                                apiRequest={apiRequest}
                                ref={tableRef}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {visibleUpdateTarget ? (
                <EditUserTarget
                    targetDataId={toEditTargetId}
                    visible={visibleUpdateTarget}
                    onClose={() => {
                        setVisibleUpdateTarget(false)
                        tableRef.current.reload()
                    }}
                />
            ) : null}

            {addTargetVisible ? (
                <AddTarget
                    visible={addTargetVisible}
                    onClose={() => {
                        events.hideAddTarget()
                    }}
                    onSubmit={() => {
                        events.hideAddTarget()
                        tableRef.current.reload()
                    }}
                />
            ) : null}
        </>

    )
}

export default userTarget
