import React from "react";
import {Button, Col, Drawer, Form, notification, Row} from "antd";
import {connect} from "react-redux";
import GetEachFormFields from "../../../components/_utils/appFormUtils";
import {cancelReceivedCommissionFxn} from "../actions";
import {useDispatch} from "react-redux";

const CancelCommissionDrawer = (props) => {
    let dispatch = useDispatch()
    let {visible, onClose, onSubmit, status, form: {getFieldDecorator}, record} = props;
    let inputTypes = {
        fields: [
            {
                key: 'details',
                label: 'Details',
                placeholder: 'Details',
                type: 'textarea',
                required: true
            }
        ]
    }
    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    const handleStatusUpdate = (e) => {
        const {form} = props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                valData.receivedCommissionId = record.receivedCommissionId;
                let resp = await dispatch(cancelReceivedCommissionFxn(valData));
                if (resp && resp.success) {
                    onSubmit()
                    onClose()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }

    return (
        <>
            <Drawer
                visible={visible}
                onClose={onClose}
                title={`Cancel Commission`}
                width={'40%'}>
                <div className="form-box commissionBox">
                    <div className="card unizportal">
                        <Form onSubmit={handleStatusUpdate}>
                            <Row gutter={16} className={'wrapBox'}>
                                {inputTypes.fields.map((item, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <Col span={24} key={key}>
                                                <GetEachFormFields
                                                    item={item}
                                                    getFieldDecorator={getFieldDecorator}
                                                    formItemLayout={formItemLayout}/>
                                            </Col>
                                        </React.Fragment>
                                    )
                                })}
                                <Col span={4}>
                                    <Form.Item>
                                        <Button
                                            type="primary" htmlType="submit" className={'btn mt40'}>
                                            Commission Cancel
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Drawer>
        </>
    )
}

const UpdateStatusDrawer = Form.create()(CancelCommissionDrawer)
const mapStateToProps = ({global, router}) => ({
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateStatusDrawer)
