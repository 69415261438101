import React, { useEffect, useState } from 'react'
import { CountryJson, LoadState } from '../../../components/_utils/countryUtil'
import { Form, notification, Drawer, Col, Row, Select } from 'antd'
import { getStudent } from '../../student/actions/student'
import moment from 'moment'
import _ from 'lodash'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { useDispatch } from 'react-redux'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { updateStudentFxn } from './actions'
import { loadCourseIntakesFxn } from '../../course/actions/courseAction'
import InputBox from '../../../components/_utils/InputBox'

let initialState = {
  currentStep: 0,
  fileList: [],
  uploadKey: moment(),
  allCountries: CountryJson,
  allStates: [],
  allCities: [],
  countryName: '',
  stateName: '',
  cityName: '',
  studentId: '',
  documents: []
}
let { Option } = Select
const EditStudentDrawer = (props) => {
  let dispatch = useDispatch()
  let {
    visible,
    onClose,
    onSubmit,
    studentId,
    familyName,
    applicationId,
    courseId,
    application,
    form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields }
  } = props
  const [state, setState] = useState(initialState)
  let [intake, setIntake] = useState(null)
  let [intakesList, setIntakes] = useState(null)


  useEffect(() => {
    loadStudentData()
  }, [studentId])
  useEffect(() => {
    events.getCourseIntake()
  }, [courseId])

  let formFields = {
    generateInputs: [
      { key: 'name', label: 'Student Name', required: true, placeholder: 'Enter Name' },
      { key: 'email', label: 'Email',disabled: true },
      { key: 'familyName', label: 'Family Name', placeholder: 'Enter Family Name' },

      {
        key: 'dateOfBirth',
        label: 'Date Of Birth',
        required: true,
        type: 'date',
        placeholder: 'Choose Date of Birth'
      },
      { key: 'mobile', span: 5, label: 'Phone', required: true, placeholder: 'Enter Phone No' },

      { key: 'address', label: 'Address', required: true, span: 16, placeholder: 'Enter Address' },
      {
        key: 'countryName',
        label: 'Country',
        required: true,
        type: 'select',
        showSearch: true,
        options: state.allCountries,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            countryName: x
          })
          events.chooseCountry(x)
        }
      },
      {
        key: 'stateName',
        label: 'Province/State',
        required: true,
        type: 'select',
        showSearch: true,
        options: state.allStates,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            stateName: x
          })
          events.chooseState(x)
        }
      },
      {
        key: 'cityName',
        label: 'City/Town',
        required: true,

        onChange: x => {
          props.form.setFieldsValue({
            cityName: x.target.value
          })
          events.chooseCity(x.target.value)
        }
      },
      {
        key: 'postalCode',
        label: 'Postal/Zip Code',
        // type: 'number',
        placeholder: 'Postal/Zip Code',
        required: true
      }


    ]
  }
  const loadStudentData = async () => {
    if (studentId) {
      dispatch(showPageLoad())
      let data = await dispatch(getStudent(studentId))
      dispatch(hidePageLoad())

      setTimeout(() => {
        let obj = {}
        _.each(data, (item, key) => {
          obj[key] = item
        })
        if (obj.dateOfBirth) {
          obj.dateOfBirth = moment(obj.dateOfBirth)
        }
        console.log(obj)
        setFieldsValue({ ...obj, familyName: familyName ? familyName : '' })

        setState({
          ...state,
          studentId: data._id,
          email: data.email,
          documents: obj.document,
          countryName: obj.countryName,
          stateName: obj.stateName,
          cityName: obj.cityName
        })
      }, 500)
    }
  }

  const events = {
    handleSubmit: (e) => {
      const { form } = props
      e.preventDefault()
      form.validateFieldsAndScroll(async (err, valData) => {
        if (!err) {
          let { countryName, stateName, cityName } = state
          valData = { ...valData, countryName, stateName, cityName }
          let intakeVal = intake.split('-')
          let intakeArr = {
            month: intakeVal[0],
            year: intakeVal[1]
          }
          if (!intakeArr || (intakeArr && !intakeArr.month)) {
            return notification.warning({
              message: 'Please choose Intake'
            })
          }

          let data = await dispatch(updateStudentFxn({ ...valData, studentId, applicationId, intake: intakeArr }))
          onSubmit()
        } else {
          notification.warning({
            message: 'Fill All Required Fields'
          })
        }
      })
    },

    chooseCountry: (name) => {
      let countryName = _.find(state.allCountries, (item) => {
        return item.name == name
      })
      if (countryName) {
        setState({
          ...state,
          countryName: countryName.name ? countryName.name : '',
          allStates: countryName.id ? LoadState(countryName.id) : []
        })
      }
    },
    chooseState: (name) => {
      let stateName = _.find(state.allStates, (item) => {
        return item.name == name
      })
      if (stateName) {
        setState({
          ...state,
          stateName: stateName.name ? stateName.name : ''
        })
      }
    },
    chooseCity: (name) => {
      setState({
        ...state,
        cityName: name ? name : ''
      })
    },
    getCourseIntake: async () => {
      let resp = await dispatch(loadCourseIntakesFxn({ courseId: courseId }))
      let intakeList = []
      _.each(resp, (item) => {
        if (item.intake) {
          intakeList.push({
            value: `${item.intake}-${item.year}`,
            name: `${item.intake}, ${item.year}`
          })
        }
      })
      if (application.intake && application.intake.month) {
        let { intake } = application
        setIntake(`${intake.month}-${intake.year}`)
      }

      setIntakes(intakeList)
    },
    chooseIntake: (value, key) => {
      setIntake(value)
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  return (
    <>
      <Drawer title={'Update Student'} visible={visible} width={'45%'} onClose={onClose}>
        <div className='form-box'>
          <div className='card unizportal'>
            <Form>
              <Row gutter={24}>
                {formFields.generateInputs.map((item, key) => {
                  return (
                    <Col span={12} md={12} sm={12} xs={24} key={key} className={'mb10'}>
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout} />
                    </Col>
                  )
                })}
                <Col span={12}>
                  <InputBox title={'Choose Intake'}>
                    <Select name='intake' id='intake'
                            className={'from-control'}
                            value={intake}
                            onChange={(e) => {
                              events.chooseIntake(e)
                            }}>
                      {intakesList && intakesList.length ? intakesList.map((item, key) => {
                        return (
                          <Option key={key}
                                  value={item.value}>{item.name}</Option>
                        )
                      }) : null}
                    </Select>
                  </InputBox>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <button className='btn' type='submit' onClick={events.handleSubmit}>save</button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  )
}
const WrappedEditStudentDrawer = Form.create()(EditStudentDrawer)
export default WrappedEditStudentDrawer
