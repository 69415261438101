import React from 'react'
import { push } from 'connected-react-router'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import loadable from '@loadable/component'
import { useDispatch } from 'react-redux'

const HomeSlider = loadable(() => import('./homeSlider'))
const ProcessComponent = loadable(() => import('./homeComponents/processComponent'))
const InnovationComponent = loadable(() => import('./homeComponents/innovation'))
const OpportunityComponent = loadable(() => import('./homeComponents/opportunityComponent'))

const Home = props => {
  let dispatch = useDispatch()
  const goToLogin = () => {
    dispatch(push('/login'))
  }

  return (
    <div>
      <section className="banner">
        <LazyLoadImage src="/assets/image/banner.png" alt=""/>

        <div className="container">
          <div className="col-lg-6 mr-auto">
            <div className='content'>
              <h1>A <span>Virtual Gateway</span> <br /> to your most Coveted Universities Abroad</h1>
              <p className={'outMotto'}>Our motto is to make pre-requisite information about <br /> premium universities
                accessible pervasively
                among <br /> the student diaspora and establish valuable links among students, immigration partners and
                state-of-the-art universities</p>

              <button className='btn' type='button' onClick={goToLogin}>
                apply online <LazyLoadImage src='/assets/image/right-arrow.svg' alt='' />
              </button>

              <div className={'icefBlock'}>
                <h1>
                    Internationally recognised <br/>
                    accreditations
                </h1>
                  <LazyLoadImage src='../assets/image/newImages/icef.png'/>
              </div>
            </div>
          </div>


        </div>

      </section>

      <HomeSlider {...props}/>


      {/*process*/}
      <ProcessComponent {...props}/>

      {/*innovation*/}
      <InnovationComponent {...props}/>

      {/*opportunity*/}
      <OpportunityComponent/>

    </div>
  )
}
export default Home
