import { Button, Card, Col, Drawer, Form, Row, notification } from 'antd'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import GetAllFormFields from '../../../components/_utils/formUtils'
import {updateStatusExpense} from '../actions/dailyExpense'

const TakeAction = props => {
    const dispatch = useDispatch()
    const {
        form: {getFieldDecorator},
        onClose,
        expense,
        visible,
        updateType,
        onSubmit
    } = props

  let inputTypes = {
    fields: [
      {
        key: 'approvedAmount',
        label: 'Approved Amount',
        required: true,
        hidden: updateType && updateType == 'Cancel'
      },
      {
        key: 'note',
        label: 'Enter Note',
        placeholder: 'Enter Note',
        type: 'textarea',
        span: 22,
        rows: 8
      }
    ]
  }


  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = async e => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        if (updateType == 'Cancel') {
          if (!valData.note) {
            return notification.warning({
              message: 'Please give Note'
            })
          }
        }
        valData.status = updateType
        valData.expenseId = expense._id
        let resp = await dispatch(updateStatusExpense(valData))
        if (resp && !resp.error) {
            onSubmit()
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  useEffect(() => {
    setTimeout(() => {
      props.form.setFieldsValue({
        approvedAmount: `${expense.amount || ''}`
      })
    }, 100)
  }, [])

  return (
    <div>
      <Drawer
          visible={visible}
          width={'35%'}
          placement="right"
          onClose={onClose}
          title={'Update Expense'}>
        <div className="form-box">
          <div className="card unizportal">
            <Form onSubmit={handleSubmit}>
              <GetAllFormFields
                inputSchema={inputTypes}
                formItemLayout={formItemLayout}
                getFieldDecorator={getFieldDecorator}
              />
              <Form.Item>
                <Button type="primary" htmlType="submit" className={'btn'}>
                  SAVE
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

const UpdateStatus = Form.create()(TakeAction)
export default UpdateStatus
