import {hidePageLoad, showPageLoad} from '../../../modules/actions'
import {customAxios as axios, getToken} from '../../../request'
import {notification} from 'antd'
import {uploadApDocumentAndStatusUrl} from '../apis'

export const uploadAppDocumentAndStatus = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let {data} = await axios.post(uploadApDocumentAndStatusUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
