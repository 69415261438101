import React, { useEffect, useState } from 'react'
import { Button, Col, Drawer, Form, Icon, notification, Row, Tooltip, Card, Checkbox } from 'antd'
import { connect } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { calculateAgentCommissionFxn, onshoreSubmitAgentCommissionFxn } from '../actions'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import lodash from 'lodash'
import SingleStudentRow from './studentRow'
import CommissionListDrawer from './commissionListDrawer'
import { CustomInput, InputBox } from '../../../components/_utils/appUtils'
import AgentInfoComponent from '../Components/agentInfoComponent'

let initState = {
  visible: false,
  selectedCommissions: []
}

const RaiseOnshoreAgentInvoiceComponent = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, onSubmit, form: { getFieldDecorator, setFieldsValue }, record } = props
  let { agentId, currency } = record
  let commissionId = record._id
  let [commissionList, setCommissionList] = useState([])
  let [totalCommission, setTotalCommission] = useState('')
  let [totalCommissionInInr, setTotalCommissionInInr] = useState('')
  let [state, setState] = useState(initState)
  let [sendMail, setSendMail] = useState(false)
  let [bonus, setBonus] = useState(false)
  let [approvedByAdminCommissionObj, setApprovedByAdminCommissionObj] = useState({
    approvedByAdminCommission: 0,
    approvedByAdminCommissionInInr: 0
  })

  useEffect(() => {
    setTimeout(() => {
      setFieldsValue({ invoiceDate: moment(), invoiceAddress: 'Unizportal' })
    }, 500)
    defaultUserCommission()
  }, [])

  useEffect(() => {
    reCalculateAmount()
  }, [commissionList, bonus])

  let defaultUserCommission = () => {
    getCommission(record).then((newRecord) => {
      setCommissionList([...commissionList, newRecord])
    })
  }

  const getCommission = (body) => {
    return new Promise(async (resolve) => {
      let obj = {
        agentId: record.agentId._id,
        commissionId: record._id,
        countryId: record.countryId._id
      }
      let data = await calculateAgentCommissionFxn(obj)
      if (data) {
        body.commissionType = data.commissionType
        body.commissionPack = data.commissionPack
        body.commissionValue = data.commissionValue
        body.currentLevel = data.currentLevel
        body.commission = data.commissionAmount
        body.orgCommission = data.orgCommission
        resolve(body)
      } else {
        resolve(body)
      }
    })
  }

  let reCalculateAmount = () => {
    let totalAmount = 0
    let totalCommissionInInr = 0
    let approvedByAdminCommission = 0
    let approvedByAdminCommissionInInr = 0
    lodash.each(commissionList, (item) => {
      totalAmount = parseFloat(totalAmount) + (item.commission ? parseFloat(item.commission) : 0)
      totalCommissionInInr = parseFloat(totalCommissionInInr) + (item.commissionInInr ? parseFloat(item.commissionInInr) : 0)
      if (item.approvedByAdminCommission) {
        approvedByAdminCommission = parseFloat(approvedByAdminCommission) + (item.approvedByAdminCommission ? parseFloat(item.approvedByAdminCommission) : 0)
        approvedByAdminCommissionInInr = parseFloat(approvedByAdminCommissionInInr) + (item.approvedByAdminCommissionInInr ? parseFloat(item.approvedByAdminCommissionInInr) : 0)
      }
    })
    setTotalCommissionInInr(totalCommissionInInr)
    setTotalCommission(totalAmount)
    if (approvedByAdminCommission) {
      setApprovedByAdminCommissionObj({
        approvedByAdminCommission,
        approvedByAdminCommissionInInr
      })
    } else {
      setApprovedByAdminCommissionObj({
        approvedByAdminCommission,
        approvedByAdminCommissionInInr
      })
    }
  }

  let inputTypes = {
    fields: [
      {
        key: 'invoiceDate',
        label: 'Date',
        placeholder: 'Date',
        type: 'date',
        required: true,
        span: 8
      },

      {
        key: 'totalCommission',
        label: 'Total Amount',
        placeholder: 'Total Amount',
        type: 'number',
        span: 8,
        customField: (
          <CustomInput
            title={'Total Amount'}
            leftContent={currency}
            value={totalCommission}
            disabled={true}
            onChange={(value) => {
              setTotalCommission(value)
            }}
          />
        )
      },
      {
        key: 'invoiceAddress',
        label: 'Invoice Address',
        placeholder: 'Invoice Address',
        type: 'select',
        options: ['Unizportal'],
        span: 8,
        disabled: true,
        required: true,
        onChange: (value) => {
          setFieldsValue({ invoiceAddress: value })
        }
      },
      {
        key: 'bonus',
        label: 'bonus',
        placeholder: 'Bonus',
        span: 24,
        customField: (
          <>
            <label style={{ marginTop: 20 }}>
              <Checkbox checked={bonus} onChange={(e) => {
                setBonus(e.target.checked)
              }} />{' '} Bonus
            </label>
          </>
        )
      }
      /* {
           key: 'sendMail',
           label: 'Send Mail',
           placeholder: 'Send Mail',
           span: 16,
           customField: (
               <>
                   <label style={{marginTop: 20}}>
                       <Checkbox checked={sendMail} onChange={(e) => {
                           setSendMail(e.target.checked)
                       }}/>{' '} Send Invoice to Agent?
                   </label>
               </>
           )
       },*/
    ]
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleStatusUpdate = (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let commissions = []
        let findNull = lodash.find(commissionList, (item) => {
          return !item.commission
        })
        if (findNull) {
          notification.warning({
            message: 'Please Enter Student Commission'
          })
          return
        }
        if (bonus) {
          let findNull = lodash.find(commissionList, (item) => {
            return parseFloat(item.commission) > parseFloat(item.approvedByAdminCommission)
          })
          if (findNull) {
            notification.warning({
              message: 'Please Enter valid Approved by Admin Commission'
            })
            return
          }
        }


        lodash.each(commissionList, (item) => {
          commissions.push({
            commissionId: item._id,
            commission: item.commission,
            commissionType: item.commissionType,
            commissionPack: item.commissionPack,
            commissionValue: item.commissionValue,
            currentLevel: item.currentLevel,
            currency: item.currency,
            countryId: item.countryId && item.countryId._id ? item.countryId._id : undefined,
            universityId: item.universityId && item.universityId._id ? item.universityId._id : undefined,
            studentId: item.studentId && item.studentId._id ? item.studentId._id : undefined,
            commissionInInr: item.commissionInInr,
            currencyRate: item.currencyRate,
            orgCommission: item.orgCommission,
            approvedByAdminCommission: bonus ? item.approvedByAdminCommission : 0,
            approvedByAdminCommissionInInr: bonus ? item.approvedByAdminCommissionInInr : 0
          })
        })
        valData.commissions = commissions
        valData.countryId = commissions && commissions.length ? commissions[0].countryId : null
        valData.currency = commissions && commissions.length ? commissions[0].currency : null
        valData.totalCommission = totalCommission
        valData.totalCommissionInInr = totalCommissionInInr
        if (bonus) {
          valData.totalApprovedByAdminCommission = approvedByAdminCommissionObj.approvedByAdminCommission
          valData.totalApprovedByAdminCommissionInInr = approvedByAdminCommissionObj.approvedByAdminCommissionInInr
        }
        valData.agentId = agentId._id
        valData.sendMail = sendMail
        valData.bonus = bonus
        valData.invoiceAddress = 'Unizportal'
        let resp = await dispatch(onshoreSubmitAgentCommissionFxn(valData))
        if (resp && resp.success) {
          onSubmit()
          onClose()
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }


  const events = {
    showVisibleCommission: () => {
      let { agentId } = record
      let selectedCommissions = []
      lodash.each(commissionList, (item) => {
        selectedCommissions.push(item._id)
      })
      setState({
        ...state,
        visible: true,
        selectedCommissions
      })
    },
    hideVisibleCommission: () => {
      setState({
        ...state,
        visible: false
      })
    },
    chooseCommission: (data) => {
      let commissionListTemp = lodash.clone(commissionList)
      let findCom = lodash.find(commissionListTemp, (item) => {
        return item._id == data._id
      })
      if (findCom) {
        notification.warning({ message: 'Commission already exists.' })
      } else {
        getCommission(data).then((newRecord) => {
          commissionListTemp.push(newRecord)
          setCommissionList(commissionListTemp)
        })
      }
    },
    removeStu: (commissionId) => {
      let commissionListTemp = lodash.clone(commissionList)
      commissionListTemp = lodash.reject(commissionListTemp, (item) => {
        return item._id == commissionId
      })
      setCommissionList(commissionListTemp)
    },
    updateRecord: (data) => {
      let commissionListTemp = lodash.clone(commissionList)
      let findCom = lodash.find(commissionListTemp, (item) => {
        return item._id == data._id
      })
      if (findCom) {
        findCom.commission = data.commission
        findCom.commissionInInr = data.commissionInInr
        setCommissionList(commissionListTemp)
      }
    },
    updateApprovedByAdminRecord: (data) => {
      let commissionListTemp = lodash.clone(commissionList)
      let findCom = lodash.find(commissionListTemp, (item) => {
        return item._id == data._id
      })
      if (findCom) {
        findCom.approvedByAdminCommission = data.approvedByAdminCommission
        findCom.approvedByAdminCommissionInInr = data.approvedByAdminCommissionInInr
        setCommissionList(commissionListTemp)
      }
    }
  }

  return (
    <>
      <Drawer
        visible={visible}
        onClose={onClose}
        title={`Raise Agent Commission`}
        width={'90%'}>
        <div className='form-box commissionBox'>
          <div className='card unizportal'>
            <Form onSubmit={handleStatusUpdate}>
              <Row gutter={16} className={'wrapBox'}>
                <Col span={6}>
                  <AgentInfoComponent agentId={record.agentId} />
                </Col>
                <Col span={18}>
                  <Card style={{ display: 'flex' }}>
                    <Row gutter={16}>
                      {inputTypes.fields.map((item, key) => {
                        return (
                          !item.hidden &&
                          <Col span={item.span} key={key}>
                            {item.customField ? item.customField :
                              <GetEachFormFields
                                item={item}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={formItemLayout} />}
                          </Col>
                        )
                      })}
                    </Row>
                  </Card>
                </Col>

                <Col span={24}>

                  <div className={'commissionListBox'}>
                    {commissionList && commissionList.length ? commissionList.map((item, key) => {
                      return <>
                        <SingleStudentRow item={item} key={key} index={key}
                                          bonus={bonus}
                                          removeStu={events.removeStu}
                                          updateRecord={events.updateRecord}
                                          updateApprovedByAdminRecord={events.updateApprovedByAdminRecord}
                        />
                      </>
                    }) : null}
                    <Tooltip title={'Add Commission'}>
                      <a className={'btn btn-default btn-sm addMoreCommissionBtn'}
                         onClick={() => {
                           events.showVisibleCommission()
                         }}>
                        <Icon type={'plus'} />
                      </a>
                    </Tooltip>
                  </div>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    <Button
                      type='primary' htmlType='submit' className={'btn mt40'}>
                      Raise Agent Invoice
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

          </div>
        </div>
        {state.visible ?
          <CommissionListDrawer
            record={record}
            commissionId={commissionId}
            selectedCommissions={state.selectedCommissions}
            onClose={events.hideVisibleCommission}
            onSubmit={events.chooseCommission}
            status={'Commission Claimed'}
            visible={state.visible} />
          : null}
      </Drawer>
    </>
  )
}

const RaiseOnshoreAgentInvoiceComponentComponent = Form.create()(RaiseOnshoreAgentInvoiceComponent)
const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RaiseOnshoreAgentInvoiceComponentComponent)
