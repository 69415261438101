import { apiUrl } from '../../../settings'

export const addUserTargetUrl = () => {
  return apiUrl + '/userTarget'
}

export const listUsersUrl = () => {
  return apiUrl + '/userTarget'
}
export const getSingleTrgetUrl = _id => {
  return apiUrl + `/userTarget/${_id}`
}

export const updateTargetUrl = _id => {
  return apiUrl + `/userTarget/${_id}`
}
