import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'
import {
  addCollaborationUrl, addNewOfficeVisitRequestUrl, addOfficeVisitsUrl,
  allTravelPlan,
  allTravelPlanUrl, getCityAndDateWiseTravelPlanUrl, travelHistoryUrl,
  travelPlanUrl,
  updateCollaborationUrl, updateTravelPlanUrl,
  userTravelPlanUrl
} from '../api'


export const addTravelPlanFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    console.log(valData)
    let { data } = await axios.post(travelPlanUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
          message: data.message || 'Success'
        })
    } else {
      notification.error({
        message: data.message || 'Error'
      })
    }
    return data
  }

  export const travelPlanListFxn = (filters) => async (dispatch) => {
    let config = {
      params: { ...filters },
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
    }
    let { data } = await axios.get(allTravelPlanUrl(), config)
    if (data.error) {
      notification.error({
        message: data.message || 'Error'
      })
    }
    return data
  }

  export const userTravelPlanListFxn = ({ userId, ...filters }) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
      params: { ...filters },
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
    }
    let { data } = await axios.get(allTravelPlan(userId), config)
    dispatch(hidePageLoad())
    if (data.error) {
      notification.error({
        message: data.message || 'Error'
      })
    }
    return data.data
  }

  export const yourTravelPlanFxn = ({ userId, ...filters }) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
      params: { ...filters },
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
    }
    let { data } = await axios.get(userTravelPlanUrl(userId), config)
    dispatch(hidePageLoad())
    if (data.error) {
      notification.error({
        message: data.message || 'Error'
      })
    }
    return data.data
  }

export const addCollaborationAction = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  console.log(valData)
  let { data } = await axios.post(addCollaborationUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const updateCollaborationAction = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  console.log(valData)
  let { data } = await axios.post(updateCollaborationUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const updateTravelPlanFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateTravelPlanUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getCityAndDateWiseTravelPlanFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(getCityAndDateWiseTravelPlanUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const addOfficeVisitsFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addOfficeVisitsUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const addNewOfficeVisitRequestFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addNewOfficeVisitRequestUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const travelHistoryFxn = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...await getToken()
  }
  console.log(config)
  let { data } = await axios.get(travelHistoryUrl(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
