import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'
import moment from 'moment'
import {addStoryUrl, deleteStoryUrl} from '../api'


export const addStoryAction = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    console.log(valData)
    let { data } = await axios.post(addStoryUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
          message: data.message || 'Success'
        })
    } else {
      notification.error({
        message: data.message || 'Error'
      })
    }
    return data
  }

  export const listAllStory = (filters) => async (dispatch) => {
    dispatch({ type: 'STUDENT_SHOW_LOADER' })
    dispatch({ type: 'EMPTY_STUDENT_LIST' })
    let config = {
      params: { ...filters },
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
    }
    let { data } = await axios.get(addStoryUrl(), config)
    // dispatch({ type: 'STUDENT_HIDE_LOADER' })
    // dispatch({ type: 'LIST_ALL_STUDENT', payload: data.data })
    if (data.error) {
      notification.error({
        message: data.message || 'Error'
      })
    }
    return data.data
  }


  export const deleteStory = (id) => async (dispatch) => {
    dispatch(showPageLoad())
    let { data } = await axios.delete(deleteStoryUrl(id), getToken())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    dispatch(hidePageLoad())
    return data
}
