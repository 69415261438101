import React, { useState } from 'react'
import { Drawer, notification, Form, Button, DatePicker } from 'antd'
import { useDispatch } from 'react-redux'
import { InputBox } from '../../../components/_utils/appUtils'
import { fcmtOnshoreEnrollmentLetterFxn } from './actions'
import { ReCreateFcmtOfferLetter } from '../actions/chooseStudent'

const ReCreateOfferLetterDrawer = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, onSubmit, studentId, applicationId } = props
  let [date, setDate] = useState(props.date)

  const handleSubmit = async () => {
    if (!date) {
      notification.warning({ message: 'Choose Date.' })
      return
    }
    let resp = await dispatch(ReCreateFcmtOfferLetter({ date, applicationId, studentId }))
    if (resp && resp.success) {
      onSubmit()
    }
  }

  return (
    <>
      <Drawer title={'Re Create Offer letter'} visible={visible} width={'45%'} onClose={onClose}>
        <div className='form-box'>
          <div className='card unizportal'>
            <Form>
              <InputBox title={'Date'}>
                <DatePicker className={'form-control'}
                            type={'date'}
                            value={date}
                            onChange={value => {
                              setDate(value)
                            }} />
              </InputBox>


              <Button type='primary' htmlType='submit' className='btn' onClick={handleSubmit}>
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  )
}
export default ReCreateOfferLetterDrawer
