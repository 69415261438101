import {
  Button,
  Col,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  notification
} from 'antd'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Intakes,
  IntakesYears,
  MonthIndex,
  countryIds,
  filterOption, departmentObj
} from '../../components/_utils/appUtils'
import { listAllCampus } from '../campus/actions/campus'
import { listAllCountries } from '../countries/actions/countries'
import { listAllCourse } from '../course/actions/courseAction'
import { listAllUniversities } from '../university/actions/university'
import {
  addCampusIntakeInCourseFxn,
  addCourseIntake,
  updateCampusIntakeTimeFxn
} from './actions'


const { Option } = Select
const AssignIntakes = props => {
  let [allCountries, setAllCountries] = useState([])
  let [countryId, setCountryId] = useState('')
  let [selectedIntake, setSelectedIntake] = useState({})

  let [intakeId, setIntakeId] = useState('')
  let [selectedCourse, setSelectedCourse] = useState([])
  const [allUniversities, setAllUniversities] = useState([])
  const [intakeStatus, setIntakeStatus] = useState('')
  const [intakeCanadaStatus, setIntakeCanadaStatus] = useState('')
  const [pickUniversity, setPickUniversity] = useState('')
  const [intakeMonth, setIntakeMonth] = useState('')
  const [intakeYear, setIntakeYear] = useState('')
  const [intakeMonthList, setIntakeMonthList] = useState([])
  const [intakesYearList, setIntakesYearList] = useState([])
  const [allCourse, setAllCourse] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [campusList, setCampusList] = useState([])
  const [campusId, setCampusId] = useState('')
  const [toShowButton, setToShowButton] = useState(false)
  let [loginUser, setLoginUser] = useState({})
  const tableRef = useRef()
  const dispatch = useDispatch()

  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null


  const showUpdateTimeBtn = () => {
    let { userType, department, countryId } = user
    let onshoreCountryId = countryId
    if ((userType == 'branchUser' && department == departmentObj.assDepartment) ||
      (userType == 'branchManager') && onshoreCountryId == countryIds.canadaOnshore) {
      return true
    }
  }


  useEffect(() => {
    loadAllCountry()
    getAllIntake()
  }, [])
  useEffect(
    () => {
      universityList()
    },
    [countryId]
  )

  const getAllIntake = async () => {
    setIntakesYearList(IntakesYears)
    setIntakeMonthList(Intakes)
  }

  const loadAllCountry = async () => {
    let { dispatch } = props
    let { data } = await dispatch(listAllCountries())
    if (data && data.length) {
      if (user.userType == 'branchManager' || user.userType == 'branchUser') {
        setCountryId(user.countryId)
      } else {
        setCountryId(data[0]._id)
      }
    }
    setLoginUser(user)
    setAllCountries(data)
  }

  const loadCampus = async universityId => {
    let params = {
      campusUniversity: universityId,
      results: 50,
      count: 50
    }
    let { data } = await dispatch(
      listAllCampus({
        ...params,
        regExFilters: ['campusAddress', 'campusName'],
        select: ['campusName']
      })
    )
    setCampusList(data)
  }

  const universityList = async id => {
    let obj = {
      results: 1000,
      universityCountry: id ? id : countryId,
      regExFilters: ['universityName'],
      showUniversity: [true, false]
    }
    let respData = await dispatch(listAllUniversities(obj))
    let UniData = respData.data
    let data = []
    if (UniData && UniData.length) {
      UniData.map(item => {
        let obj = {
          name: item.universityName,
          key: item._id,
          _id: item._id,
          intakes: item.intakes,
          country: item.universityCountry.countryName
        }
        data.push(obj)
      })
    }

    setAllUniversities(data)
  }
  const CourseList = async id => {
    let params = {
      results: 1000000,
      courseUniversity: pickUniversity,
      campusName: id,
      sortField: 'courseName',
      sortOrder: 'ascend',
      select: [
        'courseName',
        'tuitionFee',
        'intakes',
        'courseIntakes',
        'campusIntakes'
      ]
    }
    let { data } = await dispatch(listAllCourse(params))
    let courseList = []
    data.map(item => {
      let obj = {
        name: item.courseName,
        key: item._id,
        _id: item._id,
        courseIntakes: item.courseIntakes,
        campusIntakes: item.campusIntakes,
        country: item.universityCountry.countryName
      }
      courseList.push(obj)
    })
    setAllCourse(courseList)
  }

  const searchData = async id => {
    universityList(id)
  }
  const searchCourseData = async id => {
    CourseList(id)
  }
  const setCountryIdCheck = id => {
    setCountryId(id)
  }

  const chooseIntake = intakeValue => {
    // let findIntake = _.find(intakes, (item) => {
    //   return item._id == intakeValue
    // })
    if (intakeYear && intakeMonth) {
      setSelectedIntake({
        // intakeId: findIntake._id,/
        month: intakeMonth,
        year: intakeYear
      })
    }
    setIntakeId(intakeValue)
  }

  const columns = [
    {
      title: 'name',
      width: 400,
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Intakes',
      key: 'campusIntakes',
      // width: 600,
      dataIndex: 'campusIntakes',
      render: (item, record) => {
        return (
          <div>
            {record &&
            record.campusIntakes &&
            record.campusIntakes.map((itr, index) => {
              if (
                itr.status &&
                itr.campusId &&
                itr.campusId._id == campusId
              ) {
                if (itr.status == 'open') {
                  return (
                    <Tag
                      style={{
                        backgroundColor: 'white',
                        marginBottom: 5,
                        verticalAlign: 'middle'
                      }}>
                        <span style={{ verticalAlign: 'middle' }}>
                          {itr.month},{itr.year}
                        </span>
                      <img
                        src='/dist/img/check.png'
                        alt=''
                        width={14}
                        style={{ marginLeft: 4, verticalAlign: 'middle' }}
                        height={14}
                        className='mr-1'
                      />
                      {/*{index + 1 !== record.campusIntakes.length ? <a> | </a> : null}*/}
                    </Tag>
                  )
                } else {
                  return (
                    <Tag
                      style={{
                        backgroundColor: 'white',
                        marginBottom: 5,
                        verticalAlign: 'middle'
                      }}>
                      <div
                        style={{
                          verticalAlign: 'middle',
                          display: 'inline-block'
                        }}>
                        {itr.month},{itr.year}
                        {/*{itr.closeStatus ? ` (${itr.closeStatus})` : null}*/}
                      </div>
                      <img
                        src='/dist/img/delete.png'
                        style={{
                          marginLeft: 8,
                          verticalAlign: 'middle',
                          display: 'inline-block'
                        }}
                        alt=''
                        height={14}
                        width={14}
                        className='mr-1'
                      />
                    </Tag>
                  )
                }
              }
            })}
          </div>
        )
      }
    }
  ]

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,

    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      setSelectedRowKeys(selectedRowKeys)
      setSelectedCourse(selectedRowKeys)
    }
  }

  const submit = async () => {
    if (!intakeMonth) {
      notification.error({ message: 'Please select Intake Month' })
      return
    }
    if (!intakeYear) {
      notification.error({ message: 'Please select Intake Year' })
      return
    }
    if (!intakeStatus) {
      notification.error({ message: 'Please select Intake status' })
      return
    }
    if (!selectedCourse.length) {
      notification.error({ message: 'Please select Course' })
      return
    }
    if (!pickUniversity) {
      notification.error({ message: 'Please select University' })
      return
    }
    if (!campusId) {
      notification.error({ message: 'Please select Campus' })
      return
    }
    let obj = {
      intake: {
        month: intakeMonth,
        year: intakeYear,
        status: intakeStatus,
        closeStatus: intakeCanadaStatus,
        index: MonthIndex[intakeMonth]
      },
      universityId: pickUniversity,
      selectedCourse: selectedCourse,
      campusId: campusId
    }
    let findCampusName = _.find(campusList, item => {
      return item._id && item._id.toString() == campusId
    })
    if (findCampusName) {
      obj.campusName = findCampusName.campusName
    }

    let resp = await dispatch(addCampusIntakeInCourseFxn(obj))
    if (resp) {
      setIntakeCanadaStatus('')
      setSelectedRowKeys([])
      searchCourseData(pickUniversity)
    }
  }
  const updateCampusIntakeTime = async () => {
    if (countryId && countryId == countryIds.canadaOnshore) {
      let resp = await dispatch(
        updateCampusIntakeTimeFxn({ countryId: countryId })
      )
      if (resp) {

      }
    }
  }

  return (
    <div className='row  mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center'>
            <h5>Assign Intake</h5>
            <div className='search-box-table' />

            <div className='sort-box-table mark-btn' />
            {showUpdateTimeBtn() ? (
              <div className='sort-box-table mark-btn'>
                                <span className={'sort-box-table-right'}>
                                 <Popconfirm
                                   title={'Are you sure, you want to refresh Intake Time?'}
                                   onConfirm={() => updateCampusIntakeTime()}>
                                    <Tooltip title={'Update Intake Time'}>
                                    <Button
                                      className={'roundBtn'}>
                                      Update Intake Time
                                    </Button>
                                  </Tooltip>
                                 </Popconfirm>
                                </span>
              </div>
            ) : null}
          </div>
          <Row gutter={16} style={{ paddingLeft: 10, paddingTop: 10 }}>
            <Col md={8} sm={8} xs={8} lg={6}>
              <div className='sort-box-table custom-sort-box-new'>
                <Select
                  name='Search By Country'
                  //                   onChange={item => {
                  // setCountryIdCheck(item)                  }}
                  onSelect={item => {
                    setCountryIdCheck(item)
                  }}
                  filterOption={filterOption}
                  className={'antSelect'}
                  placeholder='Country'
                  disabled={
                    loginUser.userType == 'branchManager' ||
                    loginUser.userType == 'branchUser'
                  }
                  allowClear={true}
                  showSearch={true}
                  value={countryId || undefined}>
                  {allCountries && allCountries.length
                    ? allCountries.map((item, key) => {
                      return (
                        <Option value={item._id} key={key}>
                          {item.countryName}
                        </Option>
                      )
                    })
                    : null}
                </Select>
              </div>
            </Col>
            <Col md={8} sm={8} xs={8} lg={6}>
              <div className='sort-box-table custom-sort-box-new'>
                <Select
                  name='Search By University'
                  onSelect={item => {
                    setPickUniversity(item)
                    setCampusId('')
                    setAllCourse([])
                    setSelectedRowKeys([])
                    setSelectedCourse([])
                    loadCampus(item)
                  }}
                  filterOption={filterOption}
                  className={'antSelect'}
                  placeholder='University'
                  allowClear={true}
                  showSearch={true}
                  value={pickUniversity || undefined}>
                  {allUniversities && allUniversities.length
                    ? allUniversities.map((item, key) => {
                      return (
                        <Option value={item._id} key={key}>
                          {item.name}
                        </Option>
                      )
                    })
                    : null}
                </Select>
              </div>
            </Col>
            <Col md={8} sm={8} xs={8} lg={6}>
              <div className='sort-box-table custom-sort-box-new'>
                <Select
                  name='Search By Campus'
                  onChange={item => {
                    setCampusId(item)
                    searchCourseData(item)
                  }}
                  filterOption={filterOption}
                  className={'antSelect'}
                  placeholder='Campus'
                  allowClear={true}
                  showSearch={true}
                  value={campusId || undefined}>
                  {campusList && campusList.length
                    ? campusList.map((item, key) => {
                      return (
                        <Option value={item._id} key={key}>
                          {item.campusName}
                        </Option>
                      )
                    })
                    : null}
                </Select>
              </div>
            </Col>
          </Row>
          <Row gutter={16} style={{ paddingLeft: 10 }}>
            <Col md={6} sm={6} xs={6} lg={4}>
              <div className='sort-box-table custom-sort-box-new'>
                <Select
                  name='Intakes'
                  // onChange={(item) => chooseIntake(item)}
                  onChange={item => {
                    setIntakeMonth(item)
                  }}
                  filterOption={filterOption}
                  className={'antSelect'}
                  placeholder='Intake Month'
                  allowClear={true}
                  showSearch={true}
                  value={intakeMonth || undefined}>
                  {intakeMonthList && intakeMonthList.length
                    ? intakeMonthList.map((item, key) => {
                      return (
                        <Option value={item} key={key}>
                          {item}
                        </Option>
                      )
                    })
                    : null}
                </Select>
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} lg={4}>
              <div className='sort-box-table custom-sort-box-new'>
                <Select
                  name='Intakes'
                  // onChange={(item) => chooseIntake(item)}
                  onChange={item => setIntakeYear(item)}
                  filterOption={filterOption}
                  className={'antSelect'}
                  placeholder='Intake Year'
                  allowClear={true}
                  showSearch={true}
                  value={intakeYear || undefined}>
                  {intakesYearList && intakesYearList.length
                    ? intakesYearList.map((item, key) => {
                      return (
                        <Option value={item} key={key}>
                          {item}
                        </Option>
                      )
                    })
                    : null}
                </Select>
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} lg={4}>
              <div className='sort-box-table custom-sort-box-new'>
                <Select
                  name='Intakes Status'
                  onChange={item => {
                    setIntakeStatus(item)
                    setIntakeCanadaStatus('')
                  }}
                  className={'antSelect'}
                  placeholder='Intake Status'
                  allowClear={true}
                  showSearch={true}
                  value={intakeStatus || undefined}>
                  <Option value={'open'} key={'open'}>
                    Open
                  </Option>
                  <Option value={'close'} key={'close'}>
                    Close
                  </Option>
                  <Option value={'delete'} key={'delete'}>
                    Delete
                  </Option>
                </Select>
              </div>
            </Col>
            {/*{countryId && intakeStatus && countryId === countryIds.canada && intakeStatus === 'close' ?
                            <Col md={6} sm={6} xs={6} lg={4}>
                                <div className='sort-box-table custom-sort-box-new'>
                                    <Select name='Canada Intakes Status'
                                            onChange={(item) => setIntakeCanadaStatus(item)}
                                            className={'antSelect'}
                                            placeholder='Canada Intake Status'
                                            allowClear={true}
                                            showSearch={true}
                                            value={intakeCanadaStatus || undefined}>
                                        <Option value={'All'} key={'Offshore'}>All</Option>
                                        <Option value={'Offshore'} key={'Offshore'}>Offshore</Option>
                                        <Option value={'Onshore'} key={'Onshore'}>Onshore</Option>
                                    </Select>
                                </div>
                            </Col> : null}*/}
            <Col span={6}>
              <Popconfirm
                title={'Are your sure, you want to Add Intake?'}
                onConfirm={() => {
                  submit()
                }}
                okText='Yes'
                cancelText='No'>
                <Button className={'roundBtn'}>Submit</Button>
              </Popconfirm>
            </Col>
          </Row>

          {allCourse && allCourse.length ? (
            <Table
              rowSelection={{
                type: 'checkbox',
                ...rowSelection
              }}
              ref={tableRef}
              columns={columns}
              pagination={false}
              dataSource={allCourse}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  activeUser: global.activeUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignIntakes)
