import React, { useState, useEffect } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { Form, Select, Button, Card, notification, Spin, Row, Col } from 'antd'
import _ from 'lodash'
// import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { useDispatch, useSelector } from 'react-redux'
import { listAllCountries } from '../../countries/actions/countries'
import { listAllStates } from '../../state/actions/state'
import { listAllCities } from '../../city/actions/city'
import { listAllUniversities, getUniversity } from '../../university/actions/university'
import { addCampus } from '../actions/campus'
// import { addState } from '../actions/state'
import GetAllFormFields from '../../../components/_utils/formUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { CountryCodeWithFlag } from '../../../components/_utils/countryUtil'
import { FileInput } from '../../../components/_utils/appUtils'

const AddCampus = (props) => {
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } } = props
  const dispatch = useDispatch()
  let [allCountry, setAllCountry] = useState([])

  let [allUniversities, setAllUniversities] = useState([])

  const { loader, allCountries, allCities, allStates } = useSelector(state => ({
    loader: state.campusReducers.loader,
    allCountries: state.countryReducers.allCountries,
    allStates: state.stateReducers.allStates,
    allCities: state.cityReducers.allCities
  }))
  let [bannerImage, setBannerImage] = useState({})
  useEffect(() => {
    loadCountry()
  }, [])
  const handleCountrySelect = (x) => {
    dispatch(listAllStates({
      results: 1000000,
      customQuery: { country: x },
      sortField: 'stateName',
      sortOrder: 'ascend'
    }))
  }

  const loadCountry = async () => {
    let obj = {
      results: 1000,
      regExFilters: ['countryName'],
      active: [true, false]
    }
    let respData = await dispatch(listAllCountries(obj))
    setAllCountry(respData.data)
  }

  const loadUniversity = async () => {
    let obj = {
      results: 1000,
      universityCountry: getFieldValue('campusCountry'),
      regExFilters: ['universityName'],
      showUniversity: [true, false]
    }
    let respData = await dispatch(listAllUniversities(obj))
    setAllUniversities(respData.data)
  }

  const handleStateSelect = (x) => {
    dispatch(listAllCities({
      results: 1000000,
      customQuery: { state: x },
      sortField: 'cityName',
      sortOrder: 'ascend'
    }))
  }
  const chooseDocument = (e) => {
    let { name, files } = e.target
    if (files && files.length) {

    }
  }

  let inputTypes = {
    fields: [
      {
        key: 'campusCountry', label: 'Country', type: 'select',
        required: true,
        options: allCountry,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.countryName}`,
        onChange: x => {
          handleCountrySelect(x)
          props.form.setFieldsValue({
            campusCountry: x,
            campusState: undefined,
            campusCity: undefined
          })
          loadUniversity()
        }
      },
      {
        key: 'campusUniversity', label: 'University', type: 'select',
        required: true,
        showSearch: true,
        options: allUniversities,
        keyAccessor: x =>
          // console.log(x),
          x._id,

        valueAccessor: x => `${x.universityName}`,
        onChange: async x => {
          let data = await dispatch(getUniversity(x))

          handleStateSelect(data.universityState._id)
          handleCountrySelect(data.universityCountry._id)
          props.form.setFieldsValue({
            campusUniversity: x,
            // campusCountry: data.universityCountry._id,
            campusCity: data.universityCity._id
          })
          props.form.setFieldsValue({
            campusState: data.universityState._id
          })

        }
      },
      {
        key: 'campusState', label: 'State', type: 'select',
        required: true,
        options: allStates,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.stateName}`,
        onChange: x => {
          handleStateSelect(x)
          // console.log(props.form.getFieldsValue({campusUniversity}))
          props.form.setFieldsValue({
            campusState: x,
            campusCity: undefined
          })
        }
      },
      {
        key: 'campusCity', label: 'City', type: 'select',
        options: allCities,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.cityName}`,
        onChange: x => {
          props.form.setFieldsValue({
            campusCity: x
          })
        }
      },

      { key: 'campusName', label: 'Campus Name', required: true },
      { key: 'campusAddress', label: 'Address', type: 'textArea' }

      /* {
           key: 'Banner Image',
           customField: (
               <div>
                   <FileInput name={'bannerImage'}
                              label={'Banner Image'}
                              chooseDocument={chooseDocument}/>
               </div>
           )
       },*/

    ]
  }

  let rightInputTypes = {
    fields: [
      {
        key: 'campusCountry', label: 'Country', type: 'select',
        required: true,
        options: allCountries,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.countryName}`,
        onChange: x => {
          // console.log('calledsdfdsa')
          handleCountrySelect(x)
          props.form.setFieldsValue({
            campusCountry: x,
            campusState: undefined,
            campusCity: undefined
          })
        }
      },
      {
        key: 'campusState', label: 'State', type: 'select',
        required: true,
        options: allStates,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.stateName}`,
        onChange: x => {
          handleStateSelect(x)
          // console.log(props.form.getFieldsValue({campusUniversity}))
          props.form.setFieldsValue({
            campusState: x,
            campusCity: undefined
          })
        }
      },
      {
        key: 'campusCity', label: 'City', type: 'select',
        required: true,
        options: allCities,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.cityName}`,
        onChange: x => {
          props.form.setFieldsValue({
            campusCity: x
          })
        }
      }

      /* { key: 'AdmissionPersonName', label: 'Admission Person Name' },
         { key: 'AdmissionPersonEmail', label: 'Admission Person Email' },
         { key: 'AdmissionPersonMobile', label: 'Admission Person Mobile', type: 'Number' },
         { key: 'additionalRequirments', label: 'Additional Requirments' },*/
    ]
  }

  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
    // labelCol: {
    //     xs: { span: 24 },
    //     sm: { span: 8 },
    //     md: { span: 8 }
    // },
    // wrapperCol: {
    //     xs: { span: 24 },
    //     sm: { span: 16 },
    //     md: { span: 12 }
    // }
  }

  const handleSubmit = e => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let data = await dispatch(addCampus(valData))
        if (data && !data.error) {
          form.resetFields()
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  useEffect(() => {
    dispatch({ type: 'CAMPUS_HIDE_LOADER' })
    dispatch(listAllCountries({ results: 1000000, sortField: 'countryName', sortOrder: 'ascend' }))
    dispatch({ type: 'EMPTY_STATE_LIST' })
    dispatch({ type: 'EMPTY_CITY_LIST' })
    dispatch(listAllUniversities({ results: 1000000, sortField: 'universityName', sortOrder: 'ascend' }))
  }, [])

  return (
    <div className='row'>
      <div className='col-lg-12'>
        <Form onSubmit={handleSubmit}>
          <div className='form-box mt-4'>

            <div className='d-flex align-items-center mb-3 heading-form'>
              <h5>Add New Campus</h5>
            </div>

            <div className='card unizportal'>
              <Row gutter={16}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    item.customField ?
                      <Col span={8} md={8} sm={12} xs={24} key={key}>
                        {item.customField}
                      </Col> :
                      <Col span={8} md={8} sm={12} xs={24} key={key} className={'mb10'}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout} />
                      </Col>
                  )
                })}
                <Col span={24}>
                  <Form.Item>
                    <Button type='primary' htmlType='submit' className={'btn'}>
                      ADD
                    </Button>
                  </Form.Item>
                </Col>

              </Row>

              {/* <Row gutter={16}>
                <Col span={11}>
                  <GetAllFormFields
                    inputSchema={inputTypes}
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                  />
                </Col>
                <Col span={11} offset={2}>
                  <GetAllFormFields
                    inputSchema={rightInputTypes}
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                  />
                </Col>
              </Row>*/}

            </div>
          </div>

        </Form>
      </div>
    </div>
  )
}
const WrappedCampus = Form.create()(AddCampus)
export default WrappedCampus
