import React, { useState, useEffect } from 'react'
import { Timeline, Card, Form } from 'antd'
import moment from 'moment'
import {
  getApplicationActivity
} from '../../actions/chooseStudent'
import { connect, useDispatch } from 'react-redux'
import { InputBox, displayDate, longDisplayDate } from '../../../../components/_utils/appUtils'
import { useSelector } from 'react-redux'
import renderHTML from 'react-render-html'

const NotesBlock = (props) => {
  const dispatch = useDispatch()
  let [activity, setActivity] = useState([])
  let { applicationId, studentId } = props
  useEffect(() => {
    loadActivities()
  }, [])


  const loadActivities = async () => {
    let { data } = await dispatch(getApplicationActivity({ applicationId, studentId }))
    // console.log(data)
    setActivity(data)
  }


  const resp = useSelector(state => {
    let { loadActivityRedux } = state.chooseApplication
    if (loadActivityRedux) {
      loadActivities()
      dispatch({ type: 'END_LOAD_ACTIVITY' })
    }
  })


  return (
    <div className='card-body'>
      <ul>
        {activity && activity.length ? activity.map((item, key) => {
          return (
            <li key={key}>
              <div className='date'>
                <span> {displayDate(item.createdAt)}<br />{moment(item.createdAt).format('hh:mm a')}</span>
              </div>
              <div className='content'>
                <label>
                  {item.title}
                </label>
                <div class={'actBy'}> by {item && item.createdByUser.name}</div>
                {item.subject ?
                  <p>
                    <strong>Subject</strong> : {item.subject}
                  </p> : null}
                <div style={{ marginLeft: 25 }}>{item.message ? renderHTML(item.message) : ''}</div>
              </div>
            </li>
          )

        }) : null}
      </ul>
    </div>
  )
}


const mapStateToProps = ({ global, router, chooseApplication }) => ({})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(NotesBlock)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedComponent)

