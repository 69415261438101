import React, { useState, useEffect } from 'react'
import { Drawer, Form, Button, notification, Card, Spin, Row, Col } from 'antd'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { useDispatch, useSelector } from 'react-redux'
import { createMatchSelector, goBack } from 'connected-react-router'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { getCity, updateCity } from '../actions/city'
import { listAllStates } from '../../state/actions/state'
import { listAllCountries } from '../../countries/actions/countries'
import GetEachFormFields from '../../../components/_utils/appFormUtils'


const Edit = (props) => {
  const dispatch = useDispatch()
  const [city, setCity] = useState({})
  const [cityId, setCityId] = useState(null)
  const { loader, allCountries, allStates } = useSelector(state => ({
    loader: state.stateReducers.loader,
    allCountries: state.countryReducers.allCountries,
    allStates: state.stateReducers.allStates

  }))
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } } = props

  const handleCountrySelect = (x) => {
    // console.log('inside handle counry', x)
    dispatch(listAllStates({
      results: 1000000,
      customQuery: { country: x },
      sortField: 'stateName',
      sortOrder: 'ascend'
    }))
  }

  let inputTypes = {
    fields: [
      {
        key: 'country', label: 'Country', type: 'select', span: 8,
        required: true,
        options: allCountries,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.countryName}`,
        onChange: x => {
          handleCountrySelect(x)
          props.form.setFieldsValue({
            country: x,
            state: undefined,
            cityName: undefined
          })
        }
      },
      {
        key: 'state', label: 'State', type: 'select', span: 8,
        required: true,
        options: allStates,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.stateName}`,
        onChange: x => {
          props.form.setFieldsValue({
            state: x,
            cityName: undefined
          })
        }
      },
      { key: 'cityName', label: 'City Name', required: true, span: 8 }
    ]
  }

  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = async (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        await dispatch(updateCity(valData, cityId))
        getCityData()
        form.resetFields()

        dispatch(goBack())
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  useEffect(() => {
    dispatch({ type: 'CITY_HIDE_LOADER' })
    dispatch(listAllCountries({ results: 1000000, sortField: 'countryName', sortOrder: 'ascend' }))
    dispatch(listAllStates({ results: 1000000, sortField: 'stateName', sortOrder: 'ascend' }))
    getCityData()
  }, [])


  const getCityData = async () => {
    let searchParams = new URLSearchParams(window.location.search)
    let id = searchParams.get('id')
    setCityId(() => id)
    if (id) {
      let data = await dispatch(getCity(id))
      if (data) {
        setCity(() => data)
      }
    }
  }

  useEffect(() => {
    props.form.setFieldsValue({
      country: city.country ? city.country._id : undefined,
      state: city.state ? city.state._id : undefined,
      cityName: city.cityName || undefined
    })

  }, [city])

  return (
    <div className="row">
      <div className="col-lg-12">
        <Form onSubmit={handleSubmit}>
          <div className="form-box mt-4">

            <div className="d-flex align-items-center mb-3 heading-form">
              <h5>Update City</h5>
            </div>

            <div className="card unizportal">
              <Row gutter={24}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <Col span={8} key={key}>
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout}/>
                    </Col>
                  )
                })}
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="btn">
                  SAVE
                </Button>
              </Form.Item>

            </div>
          </div>

        </Form>
      </div>
    </div>
  )
}

const EditCity = Form.create()(Edit)
export default EditCity
