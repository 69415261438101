import React from 'react'
import GicListComponent from './components/gicListComponent'

const GicList = () => {
  return (
    <>
      <GicListComponent title={'Gic Account Opened List'} status={'Gic Account Opened'}
                        pathName={'gic.gicAccountOpened'} />
    </>
  )
}

export default GicList
