import React from 'react'
import { Modal } from 'antd'
import { useDispatch } from 'react-redux'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { addAdvanceCommissionFxn } from '../../actions/advanceCommission'

const LoaReqMessage = (props) => {
  let { visible, onClose } = props
  let dispatch = useDispatch()
  let events = {
    submitRequest: async () => {
      let resp = await dispatch(addAdvanceCommissionFxn({}))
      if (resp && resp.success) {
        onClose()
      }
    }
  }
  return (
    <Modal
      title='Advance Commission Policy'
      visible={visible}
      onOk={onClose}
      footer={false}
      width={'40%'}
      onCancel={onClose}>
      <div style={{ fontSize: 16 }} className={'alignCenter'}>
        <LazyLoadImage src={'../assets/Unizportal-Logo.png'} style={{ height: 80 }} />
        <div className={'mt10'}>
          If you are interested in working with <strong>advance commission policy</strong> (Request here to activate
          your <strong>Unizportal</strong>
          Account with advance commission policy).
        </div>
        <div className={'mt10'}>
          <a className={'btn btn-link'} onClick={events.submitRequest}>Click here to Request</a>
        </div>
      </div>
    </Modal>
  )
}
export default LoaReqMessage
