import { Button, Card, Col, Drawer, Form, Row, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GetAllFormFields from '../../../components/_utils/formUtils'
import {
    updateCanadaExpenseWithdrawalFxn
} from '../actions'

const Status = props => {
    const dispatch = useDispatch()
    const [pendency, setPendency] = useState({})
    const {
        form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields },
        onClose,
        withDrawlId,
        visible
    } = props
    const Statuses = ['Approved', 'Cancel']
    let inputTypes = {
        fields: [
            {
                key: 'status',
                label: 'Expense Type',
                type: 'select',
                span: 8,
                required: true,
                showSearch: true,
                options: Statuses,
                keyAccessor: x => x,
                valueAccessor: x => `${x}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        status: x
                    })
                }
            },
            {
                key: 'reason',
                label: 'Reason',
                placeholder: 'Reason',
                required: getFieldValue('status') == 'Cancel',
                type: 'textarea',
                span: 22,
                rows: 8
            }
        ]
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 0 },
            sm: { span: 0 },
            md: { span: 0 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 }
        }
    }

    const handleSubmit = async e => {
        const { form } = props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                valData.withdrawalId = withDrawlId
                let resp = await dispatch(updateCanadaExpenseWithdrawalFxn(valData))
                if (resp && !resp.error) {
                    onClose()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }

    return (
      <div>
          <Drawer
            visible={visible}
            width={'40%'}
            placement='right'
            onClose={onClose}
            title={'Update Withdrawal'}>
              <div className='form-box'>
                  <div className='card unizportal'>
                      <Form onSubmit={handleSubmit}>
                          <GetAllFormFields
                            inputSchema={inputTypes}
                            formItemLayout={formItemLayout}
                            getFieldDecorator={getFieldDecorator}
                          />
                          <Form.Item>
                              <Button type='primary' htmlType='submit' className={'btn'}>
                                  SAVE
                              </Button>
                          </Form.Item>
                      </Form>
                  </div>
              </div>
          </Drawer>
      </div>
    )
}

const UpdateWithDrawalStatus = Form.create()(Status)
export default UpdateWithDrawalStatus
