import React from "react"
import {displayDate} from "../../../components/_utils/appUtils";
import {Drawer, Table, Card, Icon, Popconfirm} from "antd";
import {deleteTuitionFeesDocumentFxn} from "../actions/chooseStudent";

const UploadedTuitionFeesList = (props) => {
  let {application, visible, onClose, dispatch, studentId, onSubmit, allowDeleteTT, user} = props;
  const columns = [
    {
      title: '#',
      key: 'index',
      dataIndex: 'index',
      width: 50,
      render: (item, record, index) => {
        return index + 1
      }
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      width: 100
    },
    {
      title: 'Transaction Date',
      key: 'date',
      dataIndex: 'date',
      width: 150,
      render: (item) => {
        return item ? displayDate(item) : null
      }
    },
    {
      title: 'Brief of Transaction',
      key: 'briefOfTransaction',
      dataIndex: 'briefOfTransaction',
      width: 200,
      render: (item,record) => {
        return item != 'Other' ? item : record.otherBriefOfTransaction
      }
    },
    {
      title: 'Details',
      key: 'details',
      dataIndex: 'details',
      width: 200,
    },
    {
      title: 'Document',
      key: 'document',
      dataIndex: 'document',
      width: 100,
      render: (item) => {
        return item && item.path ?
          <a href={item.path} download={item.fileName}> {item.fileName}</a>
          : null
      }
    },
    {
      title: 'Received By User',
      key: 'receivedByUser',
      dataIndex: 'receivedByUser',
      width: 200,
    },
    /*{
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 100,
      render: (item, record) => {
        return (
           user.email == "kamal.bawa@unizportal.com" ? <>
            <Popconfirm
              title={'Are your sure, you want to delete TT?'}
              onConfirm={() => {
                deleteTtFxn(record._id)
              }}
              okText="Yes" cancelText="No">
              <button
                className={'btn'}
                style={{marginRight: 6}}>
                <Icon type={'delete'}/>
              </button>
            </Popconfirm>
          </> : null
        )
      }
    },*/
  ];

  const deleteTtFxn = async (ttId) => {
    let obj = {
      studentId,
      applicationId: application._id,
      ttId
    }
    let resp = await dispatch(deleteTuitionFeesDocumentFxn(obj));
    if (resp && resp.success) {
      onSubmit()
    }
  }

  return (
    <>
      <Drawer
        width={"70%"}
        title={'Uploaded TT List'}
        visible={visible}
        onClose={onClose}>
        <div className='card'>
          <div className='card-body table-responsive mt10'>
            <Table bordered={true}
                   size={'small'}
                   className={'table table-striped table-valign-middle'}
                   dataSource={application.tuitionFeeDocuments}
                   columns={columns} pagination={false}/>
          </div>
        </div>
      </Drawer>
    </>
  )
}
export default UploadedTuitionFeesList
