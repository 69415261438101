import React, { Suspense, useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Button, Col, Form, Input, notification, Row } from 'antd'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import { singleInstituteEventFxn } from '../institutes/action'
import { onShoreStudentRegisteredFxn, studentRegistrationForCanadaFxn } from '../login/action'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { CountryCodeWithFlag, LoadCity, LoadState } from '../../components/_utils/countryUtil'
import { countryIds } from '../../components/_utils/appUtils'
import { hidePageLoad } from '../../modules/actions'
import { push } from 'connected-react-router'
import _ from 'lodash'

const EventAndStudentRegistration = (props) => {
  const { form: { getFieldDecorator, getFieldValue, setFieldsValue } } = props
  let dispatch = useDispatch()
  let [eventId, setEventId] = useState()
  let [eventData, setEventData] = useState({})
  let [instituteData, setInstituteDate] = useState({})
  let [formData, setFormData] = useState({})
  let [countryCode, setCountryCode] = useState('IN_+91')
  let [isOtpScreen, setIsOtpScreen] = useState(false)
  let [cityList, setCityList] = useState([])
  let [allStates, setAllStates] = useState([])
  useEffect(() => {
    let x = new URLSearchParams(window.location.search)
    if (x.get('eventId')) {
      setEventId(x.get('eventId'))
    }
  }, [])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const fetchInstituteEventData = async () => {
    try {
      const { data } = await dispatch(singleInstituteEventFxn({ eventId: eventId }))
      setEventData(data)
      setInstituteDate(data.instituteId)
    } catch (error) {
      console.error('Error fetching institute event data:', error)
    }
  }

  useEffect(() => {
    events.loadState()
  }, [])
  useEffect(() => {
    if (eventId) {
      events.checkIsLoggedIn()
    }
  }, [eventId])

  const handleSubmit = (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFields(async (err, values) => {
      if (!err) {
        let fd = new FormData()
        let obj = { ...formData, ...values }
        obj.eventId = eventId
        obj.canadaOnshore = false
        obj.onshoreReferBy = instituteData.referCode
        obj.instituteId = instituteData._id
        fd.append(
          'obj',
          JSON.stringify({ ...obj })
        )
        let x = await dispatch(onShoreStudentRegisteredFxn(fd))
        let { success, data, token } = x
        if (x && success) {
          localStorage.setItem('token', token)
          localStorage.setItem('user', JSON.stringify(data))
          setIsOtpScreen(false)
          dispatch({
            type: 'SET_CURRENT_USER',
            user: data
          })
          dispatch({
            type: 'START_LOAD_CURRENT_USER'
          })
          setTimeout(() => {
            dispatch(hidePageLoad())
            dispatch(push('/dashboard'))
          }, 1000)
          props.form.setFieldsValue({
            name: '',
            mobile: '',
            email: '',
            dob: '',
            state: '',
            city: ''
          })
        }
      }
    })
  }

  const submitForOtp = (e) => {
    e.preventDefault()
    const { form } = props
    form.validateFields(async (err, values) => {
      if (!err) {
        if (!countryCode) {
          notification.warning({ message: 'Please Choose Country Code' })
          return
        }
        values.eventId = eventId
        values.countryISOCode = countryCode
        values.registerCountry = countryIds.canadaOnshore
        try {
          let resp = await dispatch(studentRegistrationForCanadaFxn(values))
          let { success } = resp
          if (success) {
            setFormData(values)
            setIsOtpScreen(true)
          }
        } catch (e) {
          if (e) {
            notification.error({ message: JSON.stringify(e) })
          }
          dispatch(hidePageLoad())
        }

      }
    })
  }

  const events = {
    loadState: () => {
      setAllStates(LoadState('101'))
    },
    chooseState: name => {
      let stateName = _.find(allStates, item => {
        return item.name == name
      })
      setFieldsValue({
        cityName: undefined,
        stateName: stateName.name
      })
      if (stateName) {
        setCityList(LoadCity(stateName.id))
      }
    },
    checkIsLoggedIn() {
      let resp = localStorage.getItem('token')
      if (resp) {
        dispatch(push('/login'))
      } else {
        fetchInstituteEventData()
      }
    }

  }

  let formFields = {
    generateInputs: [
      {
        key: 'name',
        label: 'Name',
        required: true,
        placeholder: 'Enter Name',
        span: 12
      },
      {
        key: 'dob',
        label: 'Date Of Birth',
        required: true,
        type: 'date',
        span: 12
      },
      {
        key: 'email',
        label: 'Email',
        required: true,
        type: 'email',
        placeholder: 'Enter Email',
        span: 12
      },
      {
        key: 'countryCode',
        span: 4,
        customField: (
          <div className={'mt10'}>
            <CountryCodeWithFlag
              countryCode={countryCode}
              chooseCode={val => {
                setCountryCode(val)
              }}
            />
          </div>
        )
      },
      {
        key: 'mobile',
        label: 'Phone',
        required: true,
        type: 'number',
        placeholder: 'Enter Phone No',
        span: 8
      },

      {
        key: 'stateName',
        label: 'Province/State',
        required: true,
        type: 'select',
        showSearch: true,
        options: allStates,
        span: 12,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          events.chooseState(x)
        }
      },
      {
        key: 'cityName',
        label: 'City',
        required: true,
        type: 'select',
        span: 12,
        showSearch: true,
        options: cityList,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          setFieldsValue({ cityName: x })
        }
      }
    ]
  }
  return (
    <div id={'loginForm'}>
      <section className='banner banner1 blog-banner about-banner'>
        <div className='container'>
          <div className='col-lg-6 mr-auto'>
            <div className='content text-left'>
              <h1 className={'mb20'}>Event at {eventData ? eventData.venue : null} </h1>
              <div style={{ fontSize: 16 }}>
                <div className={'mb0'}><strong>{eventData.state}, {eventData.city}</strong></div>
                <div className={'mb0'}><strong>Date
                  : </strong> {eventData ? moment(eventData.date).format('LL') : null}</div>
                <div className={'mb0'}><strong>Time
                  : </strong> {eventData ? moment(eventData.time).format('LT') : null}</div>
                <div className={'mb0'}><strong>Organised By : </strong>
                  {instituteData ? instituteData.companyName : null}
                </div>
                {eventData && eventData.details ? <div className={'mb0'}><strong>About Event : </strong>
                  {eventData.details}
                </div> : null}
              </div>
            </div>
          </div>

          <div className='img'>
            <LazyLoadImage src='/assets/image/EventReg.png' alt='' />
          </div>
        </div>
      </section>
      <Suspense fallback={<div />}>
        <div className='align-center'>
          <div className='agent-name mb10'>Hello Student</div>
          <p className={'font14_1'}>Please fill this form to get yourself Registered!</p>
        </div>

      </Suspense>
      {eventData.status == 'Expired' ?
        <div className='card container login--wCard mb20'>
          <div className={'registrationClose'} style={{ height: 300 }}>
            <h3>Registration Close</h3>
          </div>
        </div> :
        <div>
          {!isOtpScreen ? (
            <div>
              <Form onSubmit={submitForOtp}>

                <Suspense fallback={<></>}>
                  <div className='card container login--wCard'>
                    <Row gutter={16}>
                      {formFields.generateInputs.map((item, key) => {
                        return (
                          <React.Fragment key={key}>
                            {item.customField
                              ? <Col span={item.span} md={item.span} sm={24} xs={24}>
                                {item.customField}
                              </Col>
                              : <Col span={item.span} md={item.span} sm={24} xs={24} key={key}>
                                <GetEachFormFields
                                  item={item}
                                  getFieldDecorator={
                                    getFieldDecorator
                                  }
                                  formItemLayout={formItemLayout}
                                />
                              </Col>}
                          </React.Fragment>
                        )
                      })}
                      <Col span={24}>
                        <Form.Item className={'mt10'}>
                          <Button className='btn ml-auto lgBtn'
                                  type='button'
                                  htmlType='submit'>
                            SUBMIT
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <br />
                  <br />
                </Suspense>
              </Form>
            </div>
          ) : null}
          {isOtpScreen && (
            <>
              <div className='card container login--wCard'>
                <h5>
                  Email : {formData.email}
                </h5>
                {countryCode == 'IN_+91' ?
                  <h6 style={{ marginBottom: 10 }}>
                    Please enter your verification code sent on your phone
                    & email.
                  </h6> :
                  <h6 style={{ marginBottom: 10 }}>
                    Please enter your verification code sent on your email address.
                  </h6>}
                <Form onSubmit={handleSubmit}>
                  <div className='form-group'>
                    <Form.Item>
                      {getFieldDecorator('otp', {
                        rules: [
                          {
                            required: true,
                            message: 'Please input your 4 digit otp!'
                          }
                        ]
                      })(
                        <Input
                          type='password'
                          minLength={4}
                          className={'form-control'}
                          maxLength={4}
                          autoComplete='otp'
                          placeholder={
                            countryCode == 'IN_+91' ? 'OTP – Check your phone & email address' : 'OTP – Check your email address'
                          }
                        />
                      )}
                    </Form.Item>
                  </div>
                  <div className='d-flex align-items-center'>
                    <Button
                      className='btn ml-auto lgBtn'
                      type='button'
                      htmlType='submit'>
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>

              <br />
              <br />
              <br />
            </>
          )}
        </div>}
    </div>
  )
}

const WrapForm = Form.create()(EventAndStudentRegistration)
export default WrapForm
