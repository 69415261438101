import React, { useState, useEffect } from 'react'
import { Icon, Tabs, Button, Drawer, Timeline, Card, Form, Input, Row, Col, notification, Tag, Tooltip } from 'antd'
import moment from 'moment'
import { connect, useDispatch } from 'react-redux'
import { InputBox, displayDate, longDisplayDate } from '../../../components/_utils/appUtils'
import { submitComment } from '../../student/actions/student'
import { useSelector, shallowEqual } from 'react-redux'
import lodash from 'lodash'
import async from 'async'
import renderHTML from 'react-render-html'
import {
  getCompanyNotes,
  getCompanySingleNotes,
  companyEmailActivities,
  companySmsActivities
} from '../actions/company'

import { LazyLoadImage } from 'react-lazy-load-image-component'

const { TabPane } = Tabs
const { TextArea } = Input
const initialState = {
  document: {},
  note: '',
  documentKey: moment(),
  commentDrawerVisible: false,
  noteId: ''
}


const NotesBlock = (props) => {
  const dispatch = useDispatch()
  let [state, setState] = useState(initialState)
  let [notes, setNotes] = useState([])
  let [smsArr, setSmsArr] = useState([])
  let [emailArr, setEmailArr] = useState([])
  let { companyId, showNoteDrawer } = props
  useEffect(() => {
    loadNotes()
    loadEmail()
    loadSms()
  }, [])

  const setStateData = (data) => {
    Promise.resolve()
      .then(() => {
        setState({
          ...state,
          ...data
        })
      })
      .then(() => {
        // console.log(state)
      })
  }

  const loadNotes = async () => {
    let { data } = await dispatch(getCompanyNotes({ companyId }))
    setNotes(data)
  }
  const loadSingleNotes = async (noteId) => {
    let { data, success } = await dispatch(getCompanySingleNotes({ companyId, noteId }))
    // console.log(data)
    dispatch({ type: 'END_PUSH_NOTE_BY_REDUX' })
    setTimeout(() => {
      if (success) {
        let noteArr = lodash.clone(notes)
        let findObj = lodash.find(noteArr, (item) => {
          return item._id.month === data._id.month && item._id.year === data._id.year
        })
        if (findObj) {
          if (!findObj.items) {
            findObj.items = []
          }
          let findId = lodash.find(findObj.items, (item1) => {
            return item1._id == data.items[0]._id
          })
          if (!findId) {
            findObj.items.unshift(data.items[0])
            setNotes(noteArr)
          }
        } else {
          noteArr.unshift(data)
          setNotes(noteArr)
        }
      }
    }, 500)
  }

  const loadEmail = async () => {
    let obj = {}
    let commAct = await dispatch(companyEmailActivities({ companyId }))
    if (commAct && commAct.data && commAct.data.length) {
      let item = commAct.data[0]
      let emailArr = item.arr && item.arr.length ? item.arr : []
      setEmailArr(emailArr)
    } else {

    }
  }
  const loadSms = async () => {
    let obj = {}
    let commAct = await dispatch(companySmsActivities({ companyId }))
    if (commAct && commAct.data && commAct.data.length) {
      let item = commAct.data[0]
      let smsArr = item.arr && item.arr.length ? item.arr : []
      setSmsArr(smsArr)
    }
  }


  const resp = useSelector(state => {
    let { loadNoteRedux, loadEmailRedux, loadSmsRedux, noteId } = state.chooseApplication
    if (loadNoteRedux) {
      loadNotes()
      dispatch({ type: 'END_LOAD_NOTE' })
    }

    if (loadEmailRedux) {
      loadEmail()
      dispatch({ type: 'END_LOAD_EMAIL' })
    }
    if (loadSmsRedux) {
      loadSms()
      dispatch({ type: 'END_LOAD_SMS' })
    }
    if (noteId) {
      loadSingleNotes(noteId)
    }
  })


  const events = {
    showCommentDrawer: (noteId) => {
      setState({ ...state, commentDrawerVisible: true, noteId: noteId })
    },
    hideCommentDrawer: () => {
      setState({ ...state, commentDrawerVisible: false })
    },
    setLocalState: (e) => {
      let { name, value } = e.target
      setState({
        ...state,
        [name]: value
      })
    },
    commentSubmit: async () => {
      if (!state.noteId) {
        return notification.error({
          message: 'Please try again.'
        })
      }
      if (!state.comment) {
        return notification.error({
          message: 'Please enter comment.'
        })
      }
      let obj = {
        noteId: state.noteId,
        comment: state.comment
      }
      let { comment, success } = await dispatch(submitComment(obj))
      if (success) {
        let noteArr = lodash.clone(notes)

        lodash.each(noteArr, (item) => {
          let findObj = lodash.find(item.items, (item) => {
            return item._id === state.noteId
          })
          if (findObj) {
            findObj.comments.unshift(comment)
          }
        })

        setNotes(noteArr)
        setState({
          ...state,
          commentDrawerVisible: false,
          noteId: '',
          comment: ''
        })
      }
      // loadSingleNotes(state.noteId)
      // loadNotes()
    }
  }

  const sortSubject = (data) => {
    if (data) {
      if (data.length > 80) {
        data = data.substring(1, 80)
        return data + '...'
      } else {
        return data
      }
    } else {
      return null
    }
  }

  return (
    <div className={'profile-6-column noteBlock'}>
      <div className="student-tab">
        <ul className="nav nav-tabs card" id="myTab" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" id="Notes-tab" data-toggle="tab" href="#notes" role="tab"
               aria-controls="Notes" aria-selected="true">Notes</a>
          </li>

          <li className="nav-item">
            <a className="nav-link" id="SMS-tab" data-toggle="tab" href="#SMS" role="tab" aria-controls="SMS"
               aria-selected="false">SMS</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="email-tab" data-toggle="tab" href="#email" role="tab" aria-controls="email"
               aria-selected="false">Email</a>
          </li>

        </ul>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="notes" role="tabpanel" aria-labelledby="notes-tab">
            <div className={'scrollView'}>
              {!notes.length ?
                <div className={'dateLabel'} style={{ background: 'white', textAlign: 'right', padding: 20 }}>
                  <button className="btn" type="button" onClick={showNoteDrawer}>+ create note</button>
                </div> : ''}

              {notes && notes.length ? notes.map((data, key1) => {
                return (
                  <div className="box" key={key1}>
                    <div className="head d-flex align-items-center">
                      <h5>{moment().month(data._id.month - 1).format('MMMM')}, {data._id.year}</h5>
                      {key1 == 0 ?
                        <button className="btn" type="button" onClick={showNoteDrawer}>+ create note</button> : null}

                    </div>

                    {data && data.items && data.items.length ? data.items.map((item, key) => {
                      return (
                        <div className="inner card" key={key}>
                          <img src="/dist/img/edit-icon.svg" alt="" className="edit"/>
                          <div className={'noteTitle'}>
                            <h5>note</h5>
                            <span className={'date'}>
                              {longDisplayDate(item.createdAt)}
                              </span>
                          </div>
                          <p>
                            {item.assignedUserId && item.assignedUserId.name ?
                              <strong>@{item.assignedUserId.name} </strong>
                              : null}
                            {item.note}
                          </p>
                          <div className={'paddingHz'}>
                            {item.attachment && item.attachment.url ?
                              <Tooltip title={item.attachment.name}>
                                <a download={item.attachment.name} href={item.attachment.url}>
                                  <Tag>{item.attachment.name}</Tag>
                                </a>
                              </Tooltip>
                              : null}
                          </div>
                          <span><img src="../../btnImgs/user.png" alt=""/> {item.createdByUser.name} left a note.</span>

                          <div>
                            {item.comments && item.comments.length ? item.comments.map((eacCom, key1) => {
                              return (
                                <React.Fragment key={key}>
                                  <div className={'commentBox'}>
                                    <Row>
                                      <Col span={2} className={'avatar'}>
                                        <Icon type={'user'}></Icon>
                                      </Col>
                                      <Col span={22}>
                                        <div className={'noteTitle'}>
                                          <div>
                                            @{eacCom.addedByUserId && eacCom.addedByUserId.name ? eacCom.addedByUserId.name : null}
                                          </div>
                                          <span className={'date'}><Icon
                                            type="calendar"/> {longDisplayDate(eacCom.time)}</span>
                                        </div>
                                        <div className={'commentText'}>
                                          {eacCom.text}
                                        </div>
                                      </Col>

                                    </Row>
                                  </div>

                                </React.Fragment>
                              )
                            }) : null}
                          </div>
                          <br/>
                          <button type="button" onClick={() => {
                            events.showCommentDrawer(item._id)
                          }}>+ add comment
                          </button>
                        </div>
                      )
                    }) : null}


                  </div>
                )
              }) : null}
            </div>
          </div>
          <div className="tab-pane fade" id="SMS" role="tabpanel" aria-labelledby="SMS-tab">
            <div className={'scrollView'}>
              {smsArr && smsArr.length ? smsArr.map((data, key1) => {
                return (
                  <div className="box">
                    <div className="head d-flex align-items-center">
                      <h5> {moment().month(data.month - 1).format('MMMM')}, {data.year}</h5>
                    </div>

                    {data && data.items && data.items.length ? data.items.map((item, key) => {
                      return (
                        <div className="inner card" key={key}>
                          <img src="/dist/img/edit-icon.svg" alt="" className="edit"/>
                          <div className={'noteTitle'}>
                            <h5>SMS</h5>
                            <span>
                               {longDisplayDate(item.createdAt)}
                            </span>
                          </div>
                          <p>
                            @{item.createdByUser.name} left a SMS.
                          </p>
                          <p className={'paddingHz'}>
                            {item.message}
                          </p>
                        </div>
                      )
                    }) : null}


                  </div>
                )
              }) : null}
            </div>
          </div>
          <div className="tab-pane fade" id="email" role="tabpanel" aria-labelledby="email-tab">
            <div className={'scrollView'}>
              {emailArr && emailArr.length ? emailArr.map((data, key1) => {
                return (
                  <div className="box" key={key1}>
                    <div className="head d-flex align-items-center">
                      <h5> {moment().month(data.month - 1).format('MMMM')}, {data.year}</h5>
                    </div>

                    {data && data.items && data.items.length ? data.items.map((item, key) => {
                      return (
                        <div className="inner card" key={key}>
                          <img src="/dist/img/edit-icon.svg" alt="" className="edit"/>
                          <div className={'noteTitle'}>
                            <h5>{sortSubject(item.subject)} </h5>
                            <span>
                               {longDisplayDate(item.createdAt)}
                            </span>
                          </div>
                          <p>
                            @{item.createdByUser.name} left a Mail.
                          </p>
                          <p className={'paddingHz'}>
                            {renderHTML(item.message)}
                          </p>
                        </div>
                      )
                    }) : null}


                  </div>
                )
              }) : null}
            </div>
          </div>
        </div>
      </div>


      <Drawer width={700}
              title={'Comment'}
              placement="left"
              visible={state.commentDrawerVisible}
              onClose={events.hideCommentDrawer}>
        <Card bordered={false}>
          <Form>
            <InputBox title={'Comment'}>
              <TextArea rows={5} onChange={events.setLocalState} name={'comment'} placeholder={'Comment'}
                        value={state.comment}/>
            </InputBox>
            <Form.Item className={'mt20 alignRight'}>
              <Button type="primary" onClick={events.commentSubmit}>
                SUBMIT
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Drawer>
    </div>
  )
}


const mapStateToProps = ({ global, router, chooseApplication }) => ({})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(NotesBlock)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedComponent)

