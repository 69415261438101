import { customAxios as axios, getToken } from '../../../request'
import { notification } from 'antd'
import { callStatusChangesUrl, getCanadaApplicationsListUrl } from './api'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { enrollmentLetterUrl } from '../fcmtOnshoreApplicationList/apis'

export const canadaApplicationList = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getCanadaApplicationsListUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const gicCallStatusChangesFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(callStatusChangesUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.success) {
    notification.success({ message: data.message })
  } else {
    notification.error({ message: data.message })
  }
  return data
}