import { notification } from 'antd'
import moment from 'moment'
import { customAxios as axios, getToken } from '../../../request'
import {
  addTaskUrl,
  updateTaskUrl,
  deleteTaskUrl,
  singleTaskUrl,
  taskListUrl,
  priorityUpdateUrl,
  assignedUserListUrl,
  taskGroupedUrl,
  addFutureTaskUrl,
  updateFutureTaskUrl,
  deleteFutureTaskUrl,
  futureTaskGroupedUrl,
  futureTaskListUrl,
  futureTaskSingleUrl,
  addTaskCommentUrl,
  taskCommentListUrl,
  taskActivityUrl, taskUsersUrl
} from '../apis/taskManager'
import { hideLoader, hidePageLoad, showLoader, showPageLoad } from '../../../modules/actions'

export const addTask = (valData) => async dispatch => {
  dispatch(showPageLoad())
  try {

    const response = await axios.post(addTaskUrl(), valData, getToken())
    if (!response.data.error) {
      dispatch(hidePageLoad())
      notification.success({
        message: response.data.message || 'Success'
      })

    } else {
      notification.error({
        message: response.data.message || 'Error'
      })
    }

    return response.data
  } catch (error) {
    console.error('Error adding task:', error)
    throw error
  }
}
export const updateTask = (id, { status, priority, assignee, deadline }) => async (dispatch) => {
  try {
    const requestData = { taskId: id, status, priority, assignee, deadline }
    const response = await axios.post(updateTaskUrl(), requestData, getToken())
    if (!response.data.error) {
      notification.success({
        message: response.data.message || 'Success'
      })
    } else {
      notification.error({
        message: response.data.message || 'Error'
      })
    }
    return response.data
  } catch (error) {
    console.error('Error updating task:', error)
    throw error
  }
}

export const deleteTask = (id) => async dispatch => {
  try {
    const requestData = { taskId: id }
    const response = await axios.post(deleteTaskUrl(), requestData, getToken())
    if (!response.data.error) {
      notification.success({
        message: response.data.message || 'Success'
      })
    } else {
      notification.error({
        message: response.data.message || 'Error'
      })
    }

    return response.data
  } catch (error) {
    console.error('Error deleting task:', error)
    throw error
  }
}

export const singleTask = (valData) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(singleTaskUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {

  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const taskLists = (valData) => async (dispatch) => {
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  const response = await axios.get(taskListUrl(), config)
  return response.data
}
export const priorityUpdate = (valData) => async dispatch => {

  let { data } = await axios.post(priorityUpdateUrl(), valData, getToken())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const assignedUserList = (valData) => async dispatch => {

  let { data } = await axios.post(assignedUserListUrl(), valData, getToken())
  if (!data.error) {

  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getTaskGrouped = (valData) => async (dispatch) => {
  try {
    const url = taskGroupedUrl()
    let config = {
      params: { ...valData },
      ...await getToken()
    }
    let response = await axios.get(url, config)
    return response.data
  } catch (error) {
    return { error: true, message: 'Error fetching task list' }
  }
}

export const addFutureTask = (valData) => async (dispatch) => {
  try {
    dispatch(showPageLoad())
    const response = await axios.post(addFutureTaskUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!response.data.error) {
      notification.success({
        message: response.data.message || 'Success'
      })
    } else {
      notification.error({
        message: response.data.message || 'Error'
      })
    }
    return response.data
  } catch (error) {
    throw error
  }
}

export const updateFutureTask = (valData) => async (dispatch) => {
  try {
    const response = await axios.post(updateFutureTaskUrl(), valData, getToken())
    if (!response.data.error) {
      notification.success({
        message: response.data.message || 'Success'
      })
    } else {
      notification.error({
        message: response.data.message || 'Error'
      })
    }
    return response.data
  } catch (error) {
    console.error('Error updating future task:', error)
    throw error
  }
}


export const deleteFutureTask = (id) => async (dispatch) => {
  try {
    const requestData = { taskId: id }
    const response = await axios.post(deleteFutureTaskUrl(), requestData, getToken())
    if (!response.data.error) {
      notification.success({
        message: response.data.message || 'Success'
      })
    } else {
      notification.error({
        message: response.data.message || 'Error'
      })
    }

    return response.data
  } catch (error) {
    console.error('Error deleting future task:', error)
    throw error
  }
}


export const futureTaskList = (valData) => async (dispatch) => {
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  const { data } = await axios.get(futureTaskListUrl(), config)
  return data.data
}

export const futureTaskGrouped = () => async (dispatch) => {
  try {
    const response = await axios.get(await futureTaskGroupedUrl(), getToken())
    if (!response.data.error) {
    } else {
      notification.error({
        message: response.data.message || 'Error'
      })
    }

    return response.data
  } catch (error) {
    console.error('Error fetching future task grouped data:', error)
    throw error
  }
}

export const futureTaskSingle = (valData) => async (dispatch) => {

  try {
    const response = await axios.post(futureTaskSingleUrl(), valData, getToken())
    if (!response.data.error) {
      // Handle successful response
    } else {
      notification.error({
        message: response.data.message || 'Error'
      })
    }

    return response.data
  } catch (error) {
    console.error('Error fetching future task single data:', error)
    throw error
  }
}

export const updateTaskFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  const response = await axios.post(updateTaskUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!response.data.error) {
    notification.success({
      message: response.data.message || 'Success'
    })
  } else {
    notification.error({
      message: response.data.message || 'Error'
    })
  }

  return response.data
}


export const addTaskComment = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  const response = await axios.post(addTaskCommentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!response.data.error) {
    notification.success({
      message: response.data.message || 'Success'
    })
  } else {
    notification.error({
      message: response.data.message || 'Error'
    })
  }
  return response.data
}
export const taskCommentList = (valData) => async (dispatch) => {
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  const { data } = await axios.get(taskCommentListUrl(), config)
  return data.data
}

export const taskActivityList = (filter) => async (dispatch) => {
  let config = {
    params: { ...filter },
    ...await getToken()
  }
  dispatch(showPageLoad())
  try {
    const response = await axios.get(taskActivityUrl(), config)
    dispatch(hidePageLoad())
    return response.data
  } catch (error) {
    throw error
  }
}
export const handleStatusChange = (valData) => async (dispatch) => {

}

export const taskUsersFxn = async () => {
  let config = {
    params: {},
    ...await getToken()
  }
  const { data } = await axios.get(taskUsersUrl(), config)
  return data
}
