import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import { addPendencyUrl, listPendencyUrl, singlePendencyUrl } from '../api/pendency'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'

export const addPendency = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addPendencyUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const listAllPendency = (filters) => async (dispatch) => {
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(listPendencyUrl(), config)
  dispatch({ type: 'LIST_ALL_PENDENCY', payload: data.data })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data

}


export const getPendency = id => async (dispatch) => {
  let config = {
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(singlePendencyUrl(id), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    dispatch({ type: 'LIST_PENDENCY', payload: data })
  }
  return data.data
}


export const updatePendency = (valData, id) => async (dispatch) => {
  let { data } = await axios.put(singlePendencyUrl(id), valData, getToken())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}

