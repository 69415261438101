import { apiUrl } from '../../../settings'

export const addStateUrl = () => {
  return apiUrl + '/state'
}

export const listStatesUrl = () => {
  return apiUrl + '/state'
}

export const listStateUrl = (id) => {
  return apiUrl + '/state/' + id
}

export const IndiaStateUrl = () => {
  return apiUrl + '/quickSearch/indiaState'
}
