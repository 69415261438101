import React, { useState, useEffect } from 'react'
import { notification, Form, Button, Select } from 'antd'
import { Opportunity } from './opportunityComponent'
import { CheckEnquiryFeedbackFxn, EnquiryFeedbackFxn } from '../users/actions/user'
import { useDispatch } from 'react-redux'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { AgentList } from '../users/actions/user'
import debounce from 'lodash/debounce'
import { push } from 'connected-react-router'

const sourceList = [
  'Newspaper', 'Social Media', 'Website', 'Digital Advertising', 'Email'
]
const fields = {
  name: '',
  country: '',
  university: '',
  shareYourExperience: '',
  rating: null
}
const countryList = [
  'Canada',
  'United States',
  'Australia',
  'United Kingdom'
]
const ratingList = [
  { name: '1 Star', value: 1 },
  { name: '2 Star', value: 2 },
  { name: '3 Star', value: 3 },
  { name: '4 Star', value: 4 },
  { name: '5 Star', value: 5 }
]
const { Option } = Select
const ContactUs = (props) => {
  const dispatch = useDispatch()
  let [state, setState] = useState(fields)
  let [feedbackExists, setFeedbackExists] = useState(false)
  let [message, setMessage] = useState('')

  useEffect(() => {
    checkFeedbackExists()
  }, [])

  const checkFeedbackExists = async () => {
    let { pathname } = window.location
    let studentId = pathname.split('/').pop()
    let { success, message, notFound } = await dispatch(CheckEnquiryFeedbackFxn({ studentId }))
    if (!success) {
      if (notFound) {
        dispatch(push('/login'))
      } else {
        setMessage(message)
        setFeedbackExists(true)
      }
    } else {
      setMessage('')
      setFeedbackExists(false)
    }
  }

  const setData = (e) => {
    let { value, name } = e.target
    setState({ ...state, [name]: value })
  }

  const sendMessage = async () => {
    let { shareYourExperience, rating } = state
    let { pathname } = window.location
    let studentId = pathname.split('/').pop()
    if (!rating) {
      notification.warning({ message: 'Choose rating' })
      return
    }
    if (!shareYourExperience) {
      notification.warning({ message: 'Enter your feedback' })
      return
    }
    let { success, message } = await dispatch(EnquiryFeedbackFxn({ ...state, studentId }))
    if (success) {
      setMessage(message)
      setFeedbackExists(true)
      setState({ ...fields })
    } else {
      notification.warning({ message: message })
    }
  }
  return (
    <React.Fragment>
      <section className="banner blog-banner contact-banner banner11">

        <div className="container">
          <div className="mr-auto">

          </div>
        </div>

      </section>

      <section className="contact-form">
        <div className="container">

          <div className="row">

            <div className="col-lg-7">
              <div className={'alignRight'}>
                <label className={'feedbackLabel'}>
                  Feedback
                </label>
              </div>
              <div className="box">

                {feedbackExists ? <div className={'feedbackInfo'}>
                    {message}
                  </div> :
                  <Form noValidate={true}>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputEmail4">Overall Edu Fest Rating</label>
                        <br/>
                        <div className={'ratioBox'}>
                          {ratingList.map((item, key) => {
                            return (
                              <label key={key}>{item.name}
                                <input type="radio" name={'rating'} value={item.value}
                                       checked={item.value == state.rating}
                                       onChange={(e) => {
                                         setData(e)
                                       }}/></label>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputEmail4">Write Your Comments</label>
                        <textarea className="form-control customTextArea" placeholder={'Share Your Experience'}
                                  name={'shareYourExperience'} value={state.shareYourExperience}
                                  onChange={(e) => setData(e)}>
                      </textarea>
                      </div>
                    </div>

                    <div className={'alignRight'}>
                      <button type="submit" className="btn btn-primary" onClick={() => sendMessage()}>
                        Submit
                      </button>
                    </div>
                  </Form>
                }

              </div>
            </div>

            <div className="col-lg-5">
              <div className="content">
                <h3>For the users, <br/> You can download the app here:</h3>
                <div className="store">
                  <a href="https://play.google.com/store/apps/details?id=com.unizportal"><LazyLoadImage
                    src="/assets/image/google-play.png" alt=""/></a>
                  <a href="https://apps.apple.com/in/app/unizportal/id1569018281"><LazyLoadImage
                    src="/assets/image/app-store.png" alt=""/></a>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>
      <div style={{ marginTop: 200 }}>
        <Opportunity/>
      </div>


    </React.Fragment>
  )
}
export default ContactUs
