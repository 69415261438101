import React, { useState, useEffect } from 'react'
import { CustomRowTable } from '../../../components/_utils/RowTable'
import { Drawer, Card, Skeleton, Tooltip, Icon } from 'antd'
import { getUniversity } from '../actions/university'
import { useDispatch } from 'react-redux'
import { listAllCampus } from '../../campus/actions/campus'
import { countryIds } from '../../../components/_utils/appUtils'

const getNotes = (notes) => {
  if (notes && notes.length) {
    return (
      <ul style={{ listStyle: 'disc' }}>
        {notes.map((item, index) => {
          return (
            <li key={index}>{item}</li>
          )
        })}
      </ul>
    )
  } else {
    return null
  }
}
const getIntakes = (intakes) => {
  if (intakes && intakes.length) {
    return (
      <>
        {intakes.map((item, index) => {
          return (
            <span key={index} style={{ color: 'black' }}>{item} {index < intakes.length - 1 ?
              <i>/ </i> : null}</span>
          )
        })}
      </>
    )
  } else {
    return null
  }
}
const getCampus = (campusList) => {
  if (campusList && campusList.length) {
    return (
      <>
        {campusList.map((item, index) => {
          return (
            <span key={index} style={{ color: 'black' }}>{item.campusName}{index < campusList.length - 1 ?
              <i>, </i> : null}</span>
          )
        })}
      </>
    )
  } else {
    return null
  }
}
const UniversityEligibility = (props) => {
  let dispatch = useDispatch()
  let { universityId, visible, onClose } = props
  let [universityData, setUniversityData] = useState({})
  let [countryId, setCountryId] = useState('')
  let [loading, setLoading] = useState(true)
  let [campusList, setCampusList] = useState([])
  useEffect(() => {
    loadUniversityData()
    loadUniversityCampus()
  }, [])
  const loadUniversityData = async () => {
    let data = await dispatch(getUniversity(universityId))
    if (data) {
      setUniversityData(data)
      if (data.universityCountry && data.universityCountry._id) {
        setCountryId(data.universityCountry._id.toString())
      }
      setLoading(false)
    }
  }
  const loadUniversityCampus = async () => {
    let params = {
      campusUniversity: universityId,
      sortField: 'campusName',
      sortOrder: 'ascend',
      regExFilters: ['campusAddress', 'campusName'],
      select: ['campusName']
    }
    let { data } = await dispatch(listAllCampus(params))
    if (data) {
      setCampusList(data)
    }
  }

  return (
    <Drawer visible={visible}
            title={`${universityData.universityName ? universityData.universityName : ''} Eligibility`}
            width={'70%'}
            onClose={onClose}>
      <Card>
        {loading ? <Skeleton loading={loading} /> :
          <div className={'infoUni tableBox striped'}>
            <CustomRowTable title={'Campus'} value={(
              <div>
                {getCampus(campusList)}
              </div>
            )} />
            <CustomRowTable title={'Eligibility Year For UG'} value={(
              <div>
                {universityData && universityData.entryRequirements ? universityData.entryRequirements : null}
              </div>
            )} />
            <CustomRowTable title={'Eligibility Year For PG'} value={(
              <div>
                {universityData && universityData.eligibilityForPg ? universityData.eligibilityForPg : null}
              </div>
            )} />
            {universityData && universityData.universityCountry && universityData.universityCountry._id != countryIds.canada ?
              <CustomRowTable title={'CAS Deposit'} value={(
                <div>
                  {universityData && universityData.minimumCasDeposit ? universityData.minimumCasDeposit : null}
                  {universityData && universityData.maximumCasDeposit ? `${universityData.maximumCasDeposit}` : null} GBP
                </div>
              )} /> : null}
            <CustomRowTable title={'Undergraduate Score Requirements'} value={(
              <div>
                {universityData && universityData.ugMinimumRequired ? `${universityData.ugMinimumRequired}%` : null}
                {/*{universityData && universityData.ugMaximumRequired ? ` - ${universityData.ugMaximumRequired}` : null}%*/}
              </div>
            )} />
            <CustomRowTable title={'Postgraduate Score Requirements'} value={(
              <div>
                {universityData && universityData.pgMinimumRequired ? `${universityData.pgMinimumRequired}%` : null}
                {/*{universityData && universityData.pgMaximumRequired ? ` - ${universityData.pgMaximumRequired}` : null}%*/}
              </div>
            )} />
            {countryId == countryIds.canada ?
              <CustomRowTable title={'Math Requirements'} value={(
                universityData && universityData.mathRequired && (universityData.mathRequired != 0) ?
                  <div>
                    {universityData && universityData.mathRequired ? universityData.mathRequired : null}
                  </div> : ' NA'
              )} /> : null}
            <CustomRowTable title={'IELTS for UG'} value={(
              universityData && universityData.iletsMinimumForUg && (universityData.iletsMaximumForUg != 0 || universityData.iletsMinimumForUg != 0) ?
                <div>
                  {universityData && universityData.iletsMaximumForUg ? universityData.iletsMaximumForUg : null}
                  {universityData && universityData.iletsMinimumForUg ? `/${universityData.iletsMinimumForUg}` : null}
                </div> : ' NA'
            )} />

            <CustomRowTable title={'IELTS for PG'} value={(
              universityData && universityData.iletsMinimumForPg && (universityData.iletsMinimumForPg != 0 || universityData.iletsMaximumForPg != 0) ?
                <div>
                  {universityData && universityData.iletsMaximumForPg ? universityData.iletsMaximumForPg : null}
                  {universityData && universityData.iletsMinimumForPg ? `/${universityData.iletsMinimumForPg}` : null}
                </div> : ' NA'
            )} />
            <CustomRowTable title={'PTE for UG'} value={(
              universityData && universityData.pteMinimumForUg && (universityData.pteMinimumForUg != 0 || universityData.pteMaximumForUg != 0) ?
                <div>
                  {universityData && universityData.pteMaximumForUg ? universityData.pteMaximumForUg : null}
                  {universityData && universityData.pteMinimumForUg ? `/${universityData.pteMinimumForUg}` : null}
                </div>
                : ' NA'
            )} />
            <CustomRowTable title={'PTE for PG'} value={(
              universityData && universityData.pteMinimumForPg && (universityData.pteMaximumForPg != 0 || universityData.pteMinimumForPg != 0) ?
                <div>
                  {universityData && universityData.pteMaximumForPg ? universityData.pteMaximumForPg : null}
                  {universityData && universityData.pteMinimumForPg ? `/${universityData.pteMinimumForPg}` : null}
                </div> : 'NA'
            )} />
            <CustomRowTable title={'Intakes Available'} value={(
              <div>
                {getIntakes(universityData.intakes)}
              </div>
            )} />
            {countryId == countryIds.uk ?
              <div>
                <CustomRowTable title={'English Waiver for State Board'} value={(
                  <div>
                    {universityData && universityData.waiverForStateBoard && universityData.waiverForStateBoard == 'Yes' ?
                      <>
                        {universityData.waiverForStateBoard}
                        {universityData.englishWaiverForStateBoardMinimum ? ` (${universityData.englishWaiverForStateBoardMinimum}%)` : null}
                      </>
                      : null}
                  </div>
                )} />
                <CustomRowTable title={'English Waiver for CBSE/ICSE'} value={(
                  <div>
                    {universityData && universityData.minWaiverForCbsc ? `${universityData.minWaiverForCbsc}%` : null}
                    {/*{universityData && universityData.maxWaiverForCbsc ? ` - ${universityData.maxWaiverForCbsc}` : null}%*/}
                  </div>
                )} />
              </div> : null}

            {universityData && universityData.universityCountry && universityData.universityCountry._id != countryIds.canada ?
              <CustomRowTable title={'Interview Type'} value={(
                <div>
                  {universityData && universityData.interviewType ? universityData.interviewType : null}
                </div>
              )} /> : null}

            {countryId == countryIds.uk ?
              <div>
                <CustomRowTable title={'Offer Letter Time Duration'} value={(
                  <div>
                    {universityData && universityData.timeMinimumDuration ? universityData.timeMinimumDuration : null}
                    {universityData && universityData.timeMaximumDuration ? `${universityData.timeMaximumDuration}` : null} Weeks
                  </div>
                )} />
                <CustomRowTable title={'CAS Time'} value={(
                  <div>
                    {universityData && universityData.casTimeMinimum ? universityData.casTimeMinimum : null}
                    {universityData && universityData.casTimeMaximum ? `${universityData.casTimeMaximum}` : null} Weeks
                  </div>
                )} /></div>
              : null}
            <CustomRowTable title={'Application Fee'} value={(
              <div>
                {universityData && universityData.applicationFee ? universityData.applicationFee : null}
              </div>
            )} />
            <CustomRowTable title={'Note'} value={(
              <div>
                {getNotes(universityData.notes)}
              </div>
            )} />
          </div>}
      </Card>
    </Drawer>
  )
}


let EligibilityIcon = (props) => {
  let { universityId, countryId, btn = 'defaultEligBtn' } = props
  let [visible, setVisible] = useState(false)
  return (
    <>
      {(countryIds['uk'] == countryId ||
        countryIds['usa'] == countryId ||
        countryIds['canada'] == countryId ||
        countryIds['australia'] == countryId) && universityId ?
        <Tooltip title={'View Eligibility'}>
          <a onClick={() => setVisible(true)} className={btn}>
            <Icon type={'eye'} />{' '}Eligibility
          </a>
        </Tooltip> : null}
      {visible ?
        <UniversityEligibility
          visible={visible}
          onClose={() => setVisible(false)}
          universityId={universityId}
        /> : null}
    </>
  )
}


export { UniversityEligibility, EligibilityIcon }
