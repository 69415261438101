import React, { useState, useEffect, useRef } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Button,
  Card, Col, Drawer, Icon, notification,
  Popconfirm, Row, Select,
  Tooltip
} from 'antd'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { getUrlPushWrapper } from '../../../routes'
import { deleteCampus, listAllCampus } from '../actions/campus'
import { push } from 'connected-react-router'
import { listAllStates } from '../../state/actions/state'
import { listAllCities } from '../../city/actions/city'

const ListAllCampus = (props) => {
  let { campusUniversity, universityName, onClose, visible } = props
  let [allCities, setAllCities] = useState([])
  let [allState, setAllState] = useState([])
  let [campusName, setCampusName] = useState('')
  let [filters, setFilters] = useState({})
  let [tableSearch, setTableSearch] = useState(false)
  const { totalCampus } = useSelector((state) => ({
    totalCampus: state.campusReducers.totalCampus
  }))


  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.sortField = 'campusName'
      params.sortOrder = 'ascend'
      if (campusName) {
        params.campusName = campusName
      }
      if (campusUniversity) {
        params.campusUniversity = campusUniversity
      }
      let page = params.page
      if (tableSearch) {
        params.page = 1
      }
      if (!params.results) {
        params.results = 50
        params.count = 50
      }

      resolve(dispatch(listAllCampus({ ...params, page, regExFilters: ['campusAddress', 'campusName'] })))
      setTableSearch(false)
    })
  }
  const [itemNo, setItemNo] = useState(1)

  let events = {
    searchData: () => {
      tableRef.current.reload()
    }
  }

  useEffect(() => {
    loadAllState()
    loadAllCities()
  }, [])


  const loadAllState = async () => {
    let { data } = await dispatch(listAllStates({
      results: 1000,
      sortField: 'stateName',
      sortOrder: 'ascend'
    }))
    setAllState(data)
  }
  const loadAllCities = async () => {
    let { data } = await dispatch(listAllCities({
      results: 1000,
      sortField: 'cityName',
      sortOrder: 'ascend'
    }))
    setAllCities(data)
  }

  const confirmDelete = async (id) => {
    let resp = await dispatch(deleteCampus(id))
    if (resp && resp.success) {
      notification.warning({ message: resp.message })
      tableRef.current.reload()
    }
  }

  const columns = [

    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (item, record, index) => {
        return <a className={'linkPointer'} onClick={() => {
          dispatch(
            getUrlPushWrapper('masters.editCampus', {
              id: record._id
            }))
        }}>
          {(itemNo - 1) * 10 + index + 1}
        </a>
      }
    },
    {
      title: 'Name',
      dataIndex: 'campusName',
      width: 150,
      key: 'campusName',
      render: (item, record) => {
        return (
          <a className={'linkPointer'}
             href={`/masters/edit-campus?id=${record._id}`}
          >
            {item}
          </a>
        )
      }

    },

    {
      title: 'State',
      width: 150,
      key: 'campusState',
      dataIndex: 'campusState.stateName',
      filters: allState.map(x => ({ value: x._id, text: x.stateName }))

    },
    {
      title: 'City',
      width: 150,
      key: 'campusCity',
      dataIndex: 'campusCity.cityName',
      filters: allCities.map(x => ({ value: x._id, text: x.cityName }))

    },

    {
      title: 'Address',
      key: 'campusAddress',
      width: 150,
      dataIndex: 'campusAddress',
      searchTextName: 'campusAddress'
    },


    {
      key: 'actions',
      title: 'Actions',
      // fixed: 'right',
      width: 80,
      render: (text, record) => {
        return <React.Fragment>
          <Tooltip title="Edit">
            <a
              className={'btn'}
              style={{ marginRight: 6 }}
              href={`/masters/edit-campus?id=${record._id}`}>
              <Icon type={'edit'}/>
            </a>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm title={'Are your sure, you want to delete campus?'}
                        onConfirm={() => {
                          confirmDelete(record._id)
                        }}
                        okText="Yes" cancelText="No">
              <button
                className={'btn'}
                style={{ marginRight: 6 }}>
                <Icon type={'delete'}/>
              </button>
            </Popconfirm>
          </Tooltip>

        </React.Fragment>

      }
    }
  ]


  return (
    <Drawer visible={visible} onClose={onClose}
            width={'90%'}
    >
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="table-head d-flex align-items-center">
              <h5>{universityName} - Campus: {totalCampus || 0}</h5>
              <div className="search-box-table">

              </div>
              <div className="sort-box-table mark-btn" style={{ marginRight: 20 }}>
                <a className="btn"
                   href={'/masters/add-campus'}>
                  <img src={'../dist/icons/plus.png'} className={'plus'}/>
                  Add Campus</a>
              </div>
            </div>
            <div className="card-body table-responsive">

              <Row gutter={16}>
                <Col span={6}>
                  <div className="sort-box-table custom-sort-box-new">
                    <div className="search-box-table round">
                      <input className="form-control form-control" type="search"
                             placeholder="Search by course name..." value={campusName} onChange={(e) => {
                        setCampusName(e.target.value)
                      }}/>
                      <img src="/dist/img/search.png" alt=""/>
                    </div>
                  </div>
                </Col>

                <Col span={6}>
                  <Button onClick={() => events.searchData()} className={'roundBtn'}>Search</Button>
                </Col>

              </Row>
              <TableComp columns={columns}
                         apiRequest={apiRequest}
                         pagination={{
                           pageSizeOptions: ['50', '100', '200', '500'],
                           defaultPageSize: 50,
                           current: filters.page ? filters.page : 1
                         }}
                         ref={tableRef}
              />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default ListAllCampus
