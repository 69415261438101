import { Col, Drawer, Row, Select } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import {connect, useDispatch} from 'react-redux'
import {oxfordUniversity} from '../../../components/_utils/appUtils'
import {listAllCourse} from '../../course/actions/courseAction'
import OxfordAddStudent from '../../student/views/oxfordAddAtudent'
import { getUser } from '../../users/actions/user'
import {
  getEvaluateLoadMoreCourse,
} from '../action'
const { Option } = Select

const OxfordUniverstiy = props => {
  let { visible, onClose, dispatch, selectedUniversity } = props
  let [currentUser, setCurrentUser] = useState({})
  let [coursesList, setCoursesList] = useState([])
  let [courseCount, setCourseCount] = useState(0)
  let [courseId, setCourseId] = useState('')

  let events = {
    loadCourse: async () => {
      let params = {
          courseUniversity: oxfordUniversity,
          results: 1000
      }
      let { data } = await dispatch(listAllCourse(params))
      setCoursesList(data)
    },
    loadMoreSearchCourse: async () => {
      let skip = coursesList.length
      let params = {
          universityId: oxfordUniversity,
          limit: 9,
          skip
      }
      let data = await dispatch(getEvaluateLoadMoreCourse(params))
      let cloneCourseList = _.clone(coursesList)
      cloneCourseList = [...cloneCourseList, ...data]
      setCoursesList(cloneCourseList)
    }
  }

  const chooseStudentFxn = value => {
    console.log(value, '-----------')
  }

  useEffect(() => {
    loadCurrentUser()
    // events.loadCourse()
  }, [])

  const loadCurrentUser = async () => {
    let user =
      localStorage.getItem('user') != 'undefined'
        ? JSON.parse(localStorage.getItem('user'))
        : null
    let resp = await dispatch(getUser(user._id))
    setCurrentUser(resp)
  }
  return (
    <Drawer visible={visible} onClose={onClose} width={'85%'}>
      <OxfordAddStudent
        selectedUniversity={selectedUniversity}
        onClose={onClose}
      />

    </Drawer>
  )
}

const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OxfordUniverstiy)
