import React, { PureComponent } from 'react'
import {
  Form,
  Input,
  Select,
  Button, Col, Row, notification, Icon, Card
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { hideLoader, hidePageLoad } from '../../../modules/actions'
import { connect } from 'react-redux'
import { listAllUniversities } from '../../university/actions/university'
import { listAllCountries } from '../../countries/actions/countries'
import {
  InputBox,
  departmentList,
  BooleanOptions,
  UniversityOptions, departmentObj, managerType, assignAgentTypeOptions, countryIds, ConditionOptions,
  canadaOnshoreStatesList, assignMarketingTypeOptions, currencyArrList, UniversityOptionsForOnshoreCanada
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { addUser, listAllUsers } from '../actions/user'
import debounce from 'lodash/debounce'
import { CountryCodeWithFlag } from '../../../components/_utils/countryUtil'

let users = [
  {
    name: 'Admin',
    value: 'admin'
  },
  {
    name: 'Branch Manager',
    value: 'branchManager'
  },
  {
    name: 'Marketing Manager',
    value: 'marketingManager'
  },
  {
    name: 'Branch User',
    value: 'branchUser'
  },
  {
    name: 'Agent',
    value: 'agent'
  }
]
const { Option } = Select
const { TextArea } = Input

@Form.create()
class AddUser extends PureComponent {

  agentEvents = {
    fetchAgent: async (name) => {
      let { dispatch } = this.props
      let obj = {
        results: 100,
        userType: 'agent',
        sortField: 'name',
        sortOrder: 'ascend',
        select: ['name', 'email', 'companyName'],
        name: name,
        block: false
      }
      let { data } = await dispatch(listAllUsers(obj))
      this.setState({
        agentList: data
      })
    },
    removeAgent: (index) => {
      let cloneAgent = _.clone(this.state.agents)
      cloneAgent.splice(index, 1)
      this.setState({
        agents: cloneAgent
      })
    },
    chooseAgent: (data) => {
      let { agents, agentList } = this.state
      let findUser = _.find(agents, (item) => {
        return item._id && item._id.toString() == data
      })
      if (findUser) {
        this.setState({
          agentName: '',
          agentList: []
        })
        return notification.warning({
          message: 'Agent already exists.'
        })
      } else {
        let findAgent = _.find(agentList, (item) => {
          return item._id && item._id.toString() == data
        })
        if (findAgent) {
          let cloneAgents = _.clone(agents)
          cloneAgents.push(findAgent)
          this.setState({
            agentName: '',
            agentList: [],
            agents: cloneAgents
          })
        }
      }
    }
  }
  events = {
    selectAllUni: () => {
      const { form: { setFieldsValue } } = this.props
      let { universityList } = this.state
      let universities = []
      _.each(universityList, (item) => {
        if (item && item._id) {
          universities.push(item._id)
        }
      })
      setFieldsValue({
        universities: universities
      })
    },
    deSelectAllUni: () => {
      const { form: { setFieldsValue } } = this.props
      let universities = []
      setFieldsValue({
        universities: universities
      })
    }
  }
  conditionalFxn = {
    countryFieldFxn: () => {
      const {
        form: { getFieldValue }
      } = this.props
      return (getFieldValue('userType') && ((getFieldValue('userType') == 'branchUser' && (getFieldValue('department') !== departmentObj.visaApproved && getFieldValue('department') !== departmentObj.warningDepartment) && getFieldValue('department')) || getFieldValue('userType') == 'branchManager' || getFieldValue('userType') == 'marketingManager'))
    },
    branchManagerFieldFxn: () => {
      const {
        form: { getFieldValue }
      } = this.props
      return ((getFieldValue('userType') && getFieldValue('department')) && (getFieldValue('userType') == 'branchUser' && (getFieldValue('department') !== departmentObj.visaApproved && getFieldValue('department') !== departmentObj.warningDepartment)))
    },
    universitySelectFieldFxn: () => {
      const {
        form: { getFieldValue }
      } = this.props
      return (getFieldValue('userType') &&
        ((getFieldValue('userType') == 'branchUser' && getFieldValue('department') && (getFieldValue('department').indexOf('Visa') < 0 && getFieldValue('department') !== departmentObj.warningDepartment)) || (getFieldValue('userType') == 'branchManager' && getFieldValue('branchManagerType') == 'universityWise') || getFieldValue('userType') == 'marketingManager')
      )
    },
    universitySelectFieldFxnRequired: () => {
      const {
        form: { getFieldValue }
      } = this.props
      return (getFieldValue('userType') &&
        ((getFieldValue('userType') == 'branchUser' && getFieldValue('department') && (getFieldValue('department').indexOf('Visa') < 0 && getFieldValue('department') !== departmentObj.warningDepartment)) || (getFieldValue('userType') == 'branchManager' && getFieldValue('branchManagerType') == 'universityWise') || getFieldValue('userType') == 'marketingManager')
      )
    },
    assignMarketingUserRequirement: () => {
      const { form: { getFieldValue } } = this.props
      if (getFieldValue('userType') && (getFieldValue('userType') == 'marketingManager' ||
        getFieldValue('department') == departmentObj.marketing ||
        (getFieldValue('department') == departmentObj.assDepartment && getFieldValue('countryId') == countryIds.canadaOnshore))) {
        return true
      }
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      allBranch: [],
      universityList: [],
      countryList: [],
      mainBranchArr: [],
      logo: {},
      uploadKey: moment(),
      agentList: [],
      agents: [],
      countryCode: '',
      marketingUserList: []

      // assignAgentType:'all'
    }

  }

  componentDidMount() {
    this.loadCountry()
    this.loadMarketingUsers()
    let { dispatch } = this.props
    dispatch({ type: 'USER_HIDELOADER' })
    dispatch(hideLoader())
    dispatch(hidePageLoad())
  }

  loadMarketingUsers = async () => {
    let { dispatch } = this.props
    let obj = {
      userType: 'branchUser',
      department: departmentObj.marketing,
      results: 100,
      select: ['name', 'email', 'mobile']
    }
    let { data } = await dispatch(listAllUsers(obj))
    this.setState({
      marketingUserList: data
    })
  }


  async loadCountry() {
    let { dispatch } = this.props
    let countryFilter = {
      results: 10,
      sortField: 'countryName',
      sortOrder: 'ascend'
    }
    let { data } = await listAllCountries(countryFilter)(dispatch)
    this.setState({
      countryList: data
    })
  }

  async loadCountryUniversity(countryId) {
    let { dispatch } = this.props
    let filters = {
      sortField: 'universityName',
      sortOrder: 'ascend',
      results: 1000000, universityCountry: countryId
    }
    let { data } = await dispatch(listAllUniversities(filters))
    this.setState({
      universityList: data
    })
  }


  handleSubmit = e => {
    const { dispatch, form } = this.props
    let { getFieldValue } = form
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { address, agents, logo, countryCode } = this.state
        if (!countryCode) {
          notification.error({
            message: 'Please choose County Code'
          })
          return
        }
        if (valData.assignAgentType == 'selected' || valData.assignAgentType == 'exceptSelected') {
          if ((agents && agents.length == 0) || !agents) {
            notification.error({
              message: 'Please choose Agents'
            })
            return
          }
        }
        valData.address = address
        valData.countryISOCode = countryCode
        let fd = new FormData()
        let selectedAgents = []
        if (getFieldValue('userType') == 'branchManager' && getFieldValue('branchManagerType') == 'agentWise') {
          if (!agents || (agents && !agents.length)) {
            notification.warning({
              message: 'Choose Agents'
            })
            return
          }
        }
        _.each(agents, (item) => {
          if (item && item._id) {
            selectedAgents.push(item._id)
          }
        })
        if (selectedAgents && selectedAgents.length) {
          valData.agents = selectedAgents
        }
        const assignProvisionsValues = getFieldValue('assignProvisions')

        valData.assignProvisions = Array.isArray(assignProvisionsValues) ? assignProvisionsValues : assignProvisionsValues ? [assignProvisionsValues] : []

        fd.append('obj', JSON.stringify(valData))

        if (logo && logo.name) {
          fd.append('logo', logo)
        }

        let data = await dispatch(addUser(fd))
        if (data && !data.error) {
          form.resetFields()
          this.setState({
            address: '',
            uploadKey: moment(),
            logo: {},
            agents: [],
            countryCode: ''
          })
        }
      }
    })
  }

  showAssignAgentField = () => {
    const { form: { getFieldValue } } = this.props
    if (getFieldValue('userType') == 'branchUser') {
      if ((getFieldValue('department') == departmentObj.loaDepartment && getFieldValue('countryId') == countryIds.canada)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
  showAssignMarketingField = () => {
    const { form: { getFieldValue } } = this.props
    if (getFieldValue('userType') == 'branchUser') {
      if (getFieldValue('department') == departmentObj.assDepartment && getFieldValue('countryId') == countryIds.canadaOnshore) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }


  render() {
    const { dispatch } = this.props
    const { form: { getFieldDecorator, getFieldValue, setFieldsValue } } = this.props
    const { agentList, agentName, agents, marketingUserList } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    let inputTypes = {
      fields: [
        {
          label: 'Contact Person Name',
          key: 'name',
          required: true
        },
        {
          key: 'countryCode',
          span: 3,
          customField: (
            <CountryCodeWithFlag
              customStyle={{ marginTop: 12 }}
              countryCode={this.state.countryCode}
              chooseCode={(val) => {
                this.setState({
                  countryCode: val
                })
              }} />
          )
        },
        {
          label: 'Mobile no',
          key: 'mobile',
          required: true,
          type: 'number',
          span: 5
        },
        {
          label: 'Email',
          key: 'email',
          required: true
        },
        {
          label: 'Password',
          key: 'password',
          type: 'password',
          required: true
        },
        {
          label: 'User Type',
          key: 'userType',
          required: true,
          type: 'select',
          options: users,
          valueAccessor: x => x.name,
          keyAccessor: x => x.value,
          onChange: async (v) => {
            setFieldsValue({
              userType: v,
              branchMangerId: ''
            })

            if (v == 'agent') {
              setTimeout(() => {
                setFieldsValue({
                  allowAddingUser: true,
                  showCommission: true
                })
              }, 500)
            }
            if (v == 'branchManager') {
              setTimeout(() => {
                setFieldsValue({
                  showVisaFileStages: true
                })
              }, 500)
            }
          }
        },

        {
          label: 'Manager Type',
          key: 'branchManagerType',
          type: 'select',
          hidden: !(getFieldValue('userType') && (getFieldValue('userType') == 'branchManager')),
          options: managerType,
          allowClear: true,
          valueAccessor: x => x.name,
          keyAccessor: x => x.value,
          onChange: async (v) => {
            setFieldsValue({
              branchManagerType: v,
              universities: [],
              showAllUniversity: ''
            })
            if (v == 'universityWise') {
              setFieldsValue({
                managerViewOnly: true
              })
            }
            this.setState({
              agentName: '',
              agents: []
            })
          }
        },
        {
          label: 'Show Visa File Stages',
          key: 'showVisaFileStages',
          type: 'select',
          hidden: !(getFieldValue('userType') && (getFieldValue('userType') == 'branchManager')),
          options: ConditionOptions,
          valueAccessor: x => x.name,
          keyAccessor: x => x.value,
          onChange: async (v) => {
            setFieldsValue({
              showVisaFileStages: v
            })
          }
        },


        {
          label: 'Post Name',
          key: 'postName',
          hidden: !(getFieldValue('userType') && (getFieldValue('userType') == 'branchUser' || getFieldValue('userType') == 'branchManager' || getFieldValue('userType') == 'marketingManager' || getFieldValue('userType') == 'admin'))
        },

        {
          label: 'Department',
          key: 'department',
          required: true,
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'branchUser'),
          type: 'select',
          options: departmentList,
          onChange: v => {
            setFieldsValue({
              department: v
            })
          }
        },
        {
          label: 'Country',
          key: 'countryId',
          required: true,
          type: 'select',
          showSearch: true,
          options: this.state.countryList,
          hidden: !this.conditionalFxn.countryFieldFxn(),
          valueAccessor: x => x.countryName,
          keyAccessor: x => x._id,
          onChange: async (v) => {
            setFieldsValue({
              countryId: v,
              universities: [],
              branchMangerId: '',
              showAllUniversity: undefined
            })
            if (getFieldValue('userType') === 'branchUser') {
              let countryId = getFieldValue('countryId')
              let department = getFieldValue('department')
              if (department === departmentObj.loaDepartment && countryId === countryIds.canada) {
                setTimeout(() => {
                  setFieldsValue({ assignAgentType: 'all' })
                }, 300)
              }
              if (countryId) {
                let { data } = await listAllUsers({ userType: 'branchManager', countryId })(dispatch)
                this.setState({
                  mainBranchArr: data
                })
              } else {
                this.setState({
                  mainBranchArr: []
                })
              }
            }

            this.loadCountryUniversity(v)
          }
        },
        {
          label: 'Assign Provisions',
          key: 'assignProvisions',
          type: 'select',
          mode: 'multiple',
          hidden: !((getFieldValue('userType') === 'branchManager') && (getFieldValue('countryId') === countryIds.canadaOnshore)),
          options: canadaOnshoreStatesList,
          valueAccessor: x => x.name,
          keyAccessor: x => x.name,
          onChange: v => {
            setFieldsValue({
              assignProvisions: v
            })
          }
        },


        {
          label: 'Branch Manger',
          key: 'branchMangerId',
          required: true,
          hidden: !this.conditionalFxn.branchManagerFieldFxn(),
          type: 'select',
          valueAccessor: x => x.name,
          keyAccessor: x => x._id,
          options: this.state.mainBranchArr,
          onChange: v => {
            setFieldsValue({
              branchMangerId: v
            })
          }
        },
        {
          key: 'showAllUniversity',
          label: 'University',
          hidden: !this.conditionalFxn.universitySelectFieldFxn(),
          type: 'select',
          required: this.conditionalFxn.universitySelectFieldFxnRequired(),
          keyAccessor: x => x.value,
          valueAccessor: x => `${x.name}`,
          options: (getFieldValue('countryId') == countryIds.canadaOnshore && getFieldValue('department') == departmentObj.offerDepartment) ? UniversityOptionsForOnshoreCanada : UniversityOptions,
          onChange: x => {
            setFieldsValue({
              showAllUniversity: x
            })
          }
        },

        {
          key: 'universities',
          label: '',
          label1: '* Select University',
          type: 'select',
          extraBox1: (
            <div>
              <a onClick={() => this.events.selectAllUni()}>Select All</a>{' '}
              <a onClick={() => this.events.deSelectAllUni()}>De-select All</a>
              {getFieldValue('universities') && getFieldValue('universities').length ?
                <a className={'selectedUni'}>{getFieldValue('universities').length}</a>
                : null}
            </div>
          ),
          required: true,
          showSearch: true,
          options: this.state.universityList,
          // hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'branchUser' && getFieldValue('department') && getFieldValue('department').indexOf('Visa') < 0),
          hidden: !(getFieldValue('showAllUniversity') == 'Selected' || getFieldValue('showAllUniversity') == 'ExceptSelected'),
          mode: 'multiple',
          keyAccessor: x => x._id,
          valueAccessor: x => `${x.universityName}`,
          onChange: x => {
            setFieldsValue({
              universities: x
            })
          }
        },
        {
          key: 'companyName',
          label: 'Company Name',
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
          required: true
        },

        {
          key: 'allowAddingUser',
          label: 'Add Team',
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
          type: 'select',
          required: true,
          keyAccessor: x => x.value,
          valueAccessor: x => `${x.name}`,
          options: BooleanOptions,
          onChange: x => {
            setFieldsValue({
              allowAddingUser: x
            })
          }
        },
        {
          key: 'showCommission',
          label: 'Show Commission',
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
          type: 'select',
          required: true,
          keyAccessor: x => x.value,
          valueAccessor: x => `${x.name}`,
          options: BooleanOptions,
          onChange: x => {
            setFieldsValue({
              showCommission: x
            })
          }
        },

        {
          key: 'enabledStudentLogin',
          label: (
            <>Student Login <small>(Note : Ryan Branch)</small></>
          ),
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
          type: 'select',
          required: true,
          placeholder: 'Student Login',
          keyAccessor: x => x.value,
          valueAccessor: x => `${x.name}`,
          options: BooleanOptions,
          onChange: x => {
            setFieldsValue({
              enabledStudentLogin: x
            })
          }
        },
        {
          key: 'managerViewOnly',
          label: 'Manager View Only',
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'branchManager'),
          options: BooleanOptions,
          type: 'select',
          keyAccessor: x => x.value,
          valueAccessor: x => `${x.name}`,
          onChange: x => {
            setFieldsValue({
              managerViewOnly: x
            })
          }
        },
        {
          label: 'Joining Date',
          key: 'joiningDate',
          required: (getFieldValue('userType') == 'marketingManager' || getFieldValue('department') == departmentObj.marketing),
          type: 'date'
        },
        {
          label: 'Default Currency',
          key: 'defaultCurrency',
          type: 'select',
          span: 8,
          showSearch: true,
          hidden: !(getFieldValue('department') == departmentObj.marketing),
          required: true,
          options: currencyArrList,
          valueAccessor: x => `${x.name}`,
          keyAccessor: x => x.name,
          onChange: async (v) => {
            setFieldsValue({
              defaultCurrency: v
            })
          }
        },
        {
          key: 'logo',
          customField: (
            <InputBox title={'Logo'} className={'logoCls mt10'}>
              <Input type={'file'} className={'form-control'} key={this.state.uploadKey} name={'logo'}
                     id={'logo'}
                     onChange={(e) => {
                       this.setState({
                         logo: e.target.files && e.target.files.length ? e.target.files[0] : null
                       })
                     }} />
            </InputBox>
          )
        },
        {
          label: 'Assign Marketing Type',
          key: 'assignMarketingType',
          hidden: !(getFieldValue('userType') && this.showAssignMarketingField()),
          required: (getFieldValue('userType') && this.showAssignMarketingField()),
          type: 'select',
          keyAccessor: x => x.value,
          valueAccessor: x => `${x.name}`,
          options: assignMarketingTypeOptions,
          onChange: v => {
            this.setState({ agents: [] })
            setFieldsValue({
              assignMarketingType: v
            })
          }
        },
        {
          label: 'Assign Marketing Users',
          key: 'assignedMarketingUsers',
          type: 'select',
          span: 8,
          showSearch: true,
          mode: 'multiple',
          required: true,
          options: marketingUserList,
          hidden: !this.conditionalFxn.assignMarketingUserRequirement(),
          valueAccessor: x => `${x.name}`,
          keyAccessor: x => x._id,
          onChange: async (v) => {
            setFieldsValue({
              assignedMarketingUsers: v
            })
          }
        },

        {
          key: 'alternateEmails',
          label: (
            <span>
              Alternate Emails <small style={{ color: 'black', fontWeight: 'bold' }}>( Note : Use comma for multiple emails )</small>
            </span>
          ),
          placeholder: 'Alternate Emails',
          span: 24,
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent')
        },
        {
          key: 'alternateMobileNo',
          label: (
            <span>
              Staff Member Mobile No <small style={{ color: 'black', fontWeight: 'bold' }}>( Note : Use comma for multiple mobiles no )</small>
            </span>
          ),
          placeholder: 'Staff Member Mobile No',
          span: 24,
          hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent')
        },

        {
          key: 'address',
          span: 24,
          customField: (
            <InputBox title={'Address'}>
              <TextArea rows={4} className={'form-control'} value={this.state.address} onChange={(e) => {
                this.setState({ address: e.target.value })
              }}>
              </TextArea>
            </InputBox>
          )
        },


        {
          label: 'Assign Agent Type',
          key: 'assignAgentType',
          hidden: !(getFieldValue('userType') && this.showAssignAgentField()),
          type: 'select',
          keyAccessor: x => x.value,
          valueAccessor: x => `${x.name}`,
          options: assignAgentTypeOptions,
          onChange: v => {
            this.setState({ agents: [] })
            setFieldsValue({
              assignAgentType: v
            })
          }
        },
        {
          key: 'agentId',
          hidden: !(getFieldValue('userType') && ((getFieldValue('userType') == 'branchManager' && getFieldValue('branchManagerType') == 'agentWise')
            || (getFieldValue('userType') == 'branchUser' && getFieldValue('assignAgentType') != 'all' && getFieldValue('department') == departmentObj.loaDepartment && getFieldValue('countryId') == countryIds.canada))),
          customField: (
            <InputBox title={'Agents'} className={'logoCls'}>
              <Select
                showSearch={true}
                onSearch={debounce(this.agentEvents.fetchAgent, 500)}
                filterOption={false}
                autoClearSearchValue={true}
                allowClear={true}
                value={agentName}
                style={{ width: '100%' }}
                showArrow={true}
                onChange={(e) => {
                  this.setState({
                    agentName: e
                  })
                }}
                onSelect={(e) => {
                  this.agentEvents.chooseAgent(e)
                }}
                optionLabelProp='label'
                placeholder='Search Agent'>
                {agentList && agentList.length && agentList.map((item, index) => {
                  return (
                    <Option key={item._id} value={item._id} label={item.name}>
                      <div>
                        {item.name} ({item.companyName})
                      </div>
                    </Option>
                  )
                })}
              </Select>
            </InputBox>
          )

        }

      ]
    }

    return (
      <div className='row'>
        <div className='col-lg-12'>
          <Form onSubmit={this.handleSubmit}>
            <div className='form-box mt-4'>

              <div className='d-flex align-items-center mb-3 heading-form'>
                <h5>Add User</h5>
              </div>

              <div className='card unizportal'>

                <Row gutter={16} className={'rowWrap'}>
                  {inputTypes.fields.map((item, key) => {
                    return (
                      <React.Fragment key={key}>
                        {item.customField ? !item.hidden &&
                          <Col span={item.span ? item.span : 8}>
                            {item.customField}
                          </Col> : !item.hidden &&
                          <Col span={item.span ? item.span : 8} key={key}
                               style={item.extraBox1 ? { paddingBottom: 1, marginTop: 10 } : {}}>
                            <InputBox title={item.label1 ? item.label1 : ''}
                                      extra={item.extraBox1}>
                              <GetEachFormFields
                                item={item}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={formItemLayout} />
                            </InputBox>
                          </Col>}
                      </React.Fragment>
                    )
                  })}


                  {agents && agents.length ? <Col span={24}>
                    <Card title={'Agents'} size={'small'}>
                      <div className={'guestEmailBox'}>
                        <ul>
                          {agents.map((item, index) => {
                            return (
                              <li className={'emailTag'} key={index}>
                                {item.name} ({item.companyName})
                                <Icon type={'close'}
                                      onClick={() => this.agentEvents.removeAgent(index)}
                                      className={'emailIcon'} />
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </Card>
                  </Col> : null}
                </Row>


                <Form.Item>
                  <Button type='primary' htmlType='submit' loading={this.props.loading}
                          className={'btn'}>
                    SAVE
                  </Button>
                </Form.Item>


              </div>
            </div>

          </Form>
        </div>
      </div>

    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUser)
