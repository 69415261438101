import { notification } from 'antd'
import lodash from 'lodash'
import { customAxios as axios, getToken } from '../../../request'
import { addStateUrl, listStateUrl, listStatesUrl } from '../api/state'
// import { addStateUrl, listStatesUrl, listStateUrl } from '../api/States';

import StateJson from '../../../assets/jsonFiles/states'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'

export const addState = valData => async dispatch => {
  dispatch({ type: 'STATE_SHOWLOADER' })
  let { data } = await axios.post(addStateUrl(), valData, getToken())
  dispatch({ type: 'STATE_HIDELOADER' })
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const listAllStates = filters => async dispatch => {
  dispatch({ type: 'STATE_SHOWLOADER' })
  let config = {
    params: { ...filters },
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(listStatesUrl(), config)
  dispatch({ type: 'STATE_HIDELOADER' })
  dispatch({ type: 'LIST_ALL_STATE', payload: data.data })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const getState = id => async dispatch => {
  dispatch({ type: 'STATE_SHOWLOADER' })
  let config = {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(listStateUrl(id), config)
  dispatch({ type: 'STATE_HIDELOADER' })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    dispatch({ type: 'LIST_STATE', payload: data })
  }
  return data.data
}

export const updateState = (valData, id) => async dispatch => {
  dispatch({ type: 'STATE_SHOWLOADER' })
  let { data } = await axios.put(listStateUrl(id), valData, getToken())
  dispatch({ type: 'STATE_HIDELOADER' })
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}

export const indiaAllStates = filters => async dispatch => {
  let indianState = lodash.filter(StateJson, item => {
    return item.country_id == '101'
  })
  // console.log(indianState)
  dispatch({ type: 'INDIA_ALL_STATE', payload: indianState })

  return indianState
}

export const deleteState = id => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.delete(listStateUrl(id), getToken())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  dispatch(hidePageLoad())
  return data
}
