import React from "react"
import {displayDate, longStringDate} from "../../../components/_utils/appUtils";

const DateComponent = (props) => {
    let {date} = props;
    return <>
        {date ? <div className={'statusDate'}>
            {longStringDate(date)}
        </div> : null}
    </>
}

const StatusComponent = (props) => {
    let {record} = props
    let {status} = record;
    return (
        <div className={'statusLabel'}>
            {status == 'Pending' ? <>
                <label className={'label label-info sm'}>To Be Claimed</label>
                <DateComponent date={record.invoiceDate}/>
            </> : null}
            {status == 'Cancelled' ? <>
                <label className={'label label-danger sm'}>{status}</label>
                <DateComponent date={record.claimCancelDate}/>
            </> : null}
            {status == 'Commission Claimed' ? <>
                <label className={'label label-warning sm'}>{status}</label>
                <DateComponent date={record.claimDate}/>
            </> : null}
            {status == 'Commission Received' ? <>
                <label className={'label label-success sm'}>{status}</label>
                <DateComponent date={record.claimReceivedDate}/>
            </> : null}
            {status == 'Commission Cancelled' ? <>
                <label className={'label label-danger sm'}>{status}</label>
                <DateComponent date={record.claimCancelDate}/>
            </> : null}
            {status == 'Agent Invoice Raised' ?
                <>
                    <label className={'label label-info-new sm'}>{status}</label>
                    <DateComponent date={record.commissionRaisedDate}/>
                </> : null}
            {status == 'Commission Approved' ?
                <>
                    <label className={'label label-success-green sm'}>{status}</label>
                    <DateComponent date={record.commissionApprovedDate}/>
                </> : null}
            {status == 'Agent Invoice Paid' ? <>
                <label className={'label label-paid sm'}>{status}</label>
            </> : null}

        </div>
    )
}
export default StatusComponent
