import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux";
import {getStudentOtherApplication} from "../actions/chooseStudent";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {defaultLogo, statusColors} from "../../../components/_utils/appUtils";
import {Icon} from "antd";

const OtherApplicationsListComponentRow = (props) => {
    const dispatch = useDispatch()
    let {studentId, applicationId} = props
    const [otherApps, setOtherApps] = useState([])
    useEffect(() => {
        loadStudentOtherApplication()
    }, [])
    let loadStudentOtherApplication = async () => {
        let resp = await dispatch(getStudentOtherApplication({
            studentId: studentId,
            applicationId: applicationId
        }))
        setOtherApps(resp)
    }

    return (
        <div className={'othAppContainer'}>
            {otherApps && otherApps.length ?
                <>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <h6>Other Applications</h6>
                        </div>
                    </div>

                    <div>
                        {otherApps.map((item, index) => {
                            let {applications} = item;
                            return (
                                applications.courseUniversity ?
                                    <div className='otherAppList' key={index}>
                                        <div className={'leftBox'}>
                                            <div>
                                                {applications.courseUniversity.logo && applications.courseUniversity.logo.url ?
                                                    <LazyLoadImage
                                                        src={applications.courseUniversity.logo.url}
                                                        className={'logo'}/> :
                                                    <LazyLoadImage src={defaultLogo} className={'logo'}/>}
                                            </div>
                                            <div className={'label label1'}
                                                 style={{
                                                     background: statusColors(applications.status),
                                                     width: 100,
                                                     padding: 3
                                                 }}>{applications.status}</div>


                                        </div>
                                        <div className={'rightBox'}>
                                            <a href={`/student/application?studentId=${studentId}&appId=${applications._id}`}
                                               target={'_blank'}>
                                                <p>
                                                    {applications.courseUniversity.universityName ? applications.courseUniversity.universityName : null}
                                                </p>
                                                <Icon type={'arrow-right'}/>
                                            </a>
                                        </div>
                                    </div> : null
                            )
                        })}
                    </div>
                </> : null}
        </div>
    )
}

const OtherApplicationsListComponent = (props) => {
    const dispatch = useDispatch()
    let {studentId, applicationId} = props
    const [otherApps, setOtherApps] = useState([])
    useEffect(() => {
        loadStudentOtherApplication()
    }, [])
    let loadStudentOtherApplication = async () => {
        let resp = await dispatch(getStudentOtherApplication({
            studentId: studentId,
            applicationId: applicationId
        }))
        setOtherApps(resp)
    }

    return (
        <div className={'othAppContainer'}>
            {otherApps && otherApps.length ?
                <>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <h6>Other Applications</h6>
                        </div>
                    </div>

                    <div>
                        {otherApps.map((item, index) => {
                            let {applications} = item;
                            return (
                                applications.courseUniversity ?
                                    <div className='otherAppList' key={index}>
                                        <div className={'leftBox'}>
                                            <div>
                                                {applications.courseUniversity.logo && applications.courseUniversity.logo.url ?
                                                    <LazyLoadImage
                                                        src={applications.courseUniversity.logo.url}
                                                        className={'logo'}/> :
                                                    <LazyLoadImage src={defaultLogo} className={'logo'}/>}
                                            </div>
                                            <div className={'label label1'}
                                                 style={{
                                                     background: statusColors(applications.status),
                                                     width: 100,
                                                     padding: 3
                                                 }}>{applications.status}</div>


                                        </div>
                                        <div className={'rightBox'}>
                                            <div>
                                                {applications.courseUniversity.universityName ? applications.courseUniversity.universityName : null}
                                            </div>
                                            <div>
                                                Current User
                                                : {applications && applications.assignedUserId && applications.assignedUserId.name ? applications.assignedUserId.name : null}
                                            </div>
                                        </div>
                                    </div> : null
                            )
                        })}
                    </div>
                </> : null}
        </div>
    )
}
export {
    OtherApplicationsListComponent,
    OtherApplicationsListComponentRow
};
