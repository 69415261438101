import React, {PureComponent} from 'react'
import {
    Form,
    Input,
    Button, Col, Row, notification, Icon, Table
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import {hideLoader, hidePageLoad} from '../../../modules/actions'
import {connect} from 'react-redux'
import {listAllUniversities} from '../../university/actions/university'
import {listAllCountries} from '../../countries/actions/countries'
import {
    InputBox, departmentObj, currencyArrList, marketingForList
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {addUser, listAllUsers} from '../actions/user'
import {CountryCodeWithFlag, LoadState} from '../../../components/_utils/countryUtil'


const {TextArea} = Input


@Form.create()
class AddMarketingManager extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            allBranch: [],
            universityList: [],
            countryList: [],
            stateList: [],
            mainBranchArr: [],
            logo: {},
            uploadKey: moment(),
            agentList: [],
            agents: [],
            assignStateCountry: [],
            countryCode: '',
            marketingUserList: []
        }
    }

    agentEvents = {
        fetchAgent: async (name) => {
            let {dispatch} = this.props
            let obj = {
                results: 100,
                userType: 'agent',
                sortField: 'name',
                sortOrder: 'ascend',
                select: ['name', 'email', 'companyName'],
                name: name,
                block: false
            }
            let {data} = await dispatch(listAllUsers(obj))
            this.setState({
                agentList: data
            })
        },
        removeAgent: (index) => {
            let cloneAgent = _.clone(this.state.agents)
            cloneAgent.splice(index, 1)
            this.setState({
                agents: cloneAgent
            })
        },
        chooseAgent: (data) => {
            let {agents, agentList} = this.state
            let findUser = _.find(agents, (item) => {
                return item._id && item._id.toString() == data
            })
            if (findUser) {
                this.setState({
                    agentName: '',
                    agentList: []
                })
                return notification.warning({
                    message: 'Agent already exists.'
                })
            } else {
                let findAgent = _.find(agentList, (item) => {
                    return item._id && item._id.toString() == data
                })
                if (findAgent) {
                    let cloneAgents = _.clone(agents)
                    cloneAgents.push(findAgent)
                    this.setState({
                        agentName: '',
                        agentList: [],
                        agents: cloneAgents
                    })
                }
            }
        }
    }
    events = {
        selectAllUni: () => {
            const {form: {setFieldsValue}} = this.props
            let {universityList} = this.state
            let universities = []
            _.each(universityList, (item) => {
                if (item && item._id) {
                    universities.push(item._id)
                }
            })
            setFieldsValue({
                universities: universities
            })
        },
        deSelectAllUni: () => {
            const {form: {setFieldsValue}} = this.props
            let universities = []
            setFieldsValue({
                universities: universities
            })
        },
        removeStateCountry: (index) => {
            let cloneArr = _.clone(this.state.assignStateCountry)
            cloneArr.splice(index, 1)
            this.setState({
                assignStateCountry: cloneArr
            })
        },
        countryName: (id) => {
            let country = _.find(this.state.countryList, (item) => {
                return item._id === id
            })
            return country && country.countryName ? country.countryName : null
        }
    }

    componentDidMount() {
        this.setDefaultSource()
        this.loadCountry()
        this.LoadStateFxn()
        this.loadManagers()
        this.loadCountryUniversity()
        let {dispatch} = this.props
        dispatch({type: 'USER_HIDELOADER'})
        dispatch(hideLoader())
        dispatch(hidePageLoad())
    }

    setDefaultSource = () => {
        this.props.form.setFieldsValue({
            marketingFor: "unizportal",
        })
    }

    async loadCountry() {
        let {dispatch} = this.props
        let countryFilter = {
            results: 10,
            sortField: 'countryName',
            sortOrder: 'ascend'
        }
        let {data} = await listAllCountries(countryFilter)(dispatch)
        this.setState({
            countryList: data
        })
    }

    async LoadStateFxn() {
        let {dispatch} = this.props
        let data = await LoadState('101')
        this.setState({
            stateList: data
        })
    }

    async loadManagers() {
        let {dispatch} = this.props
        let {data} = await listAllUsers({
            userType: 'branchManager', customQuery: {
                countryId: {$exists: false}
            }
        })(dispatch)
        this.setState({
            mainBranchArr: data
        })
    }

    async loadCountryUniversity() {
        let {dispatch} = this.props
        let filters = {
            sortField: 'universityName',
            sortOrder: 'ascend',
            results: 1000000
            // universityCountry: '',
        }

        let {data} = await dispatch(listAllUniversities(filters))
        this.setState({
            universityList: data
        })
    }

    async AddStateCountry() {
        const {
            form: {getFieldValue, setFieldsValue}
        } = this.props
        let state = getFieldValue('state') ? getFieldValue('state') : undefined
        let countryId = getFieldValue('countryId') ? getFieldValue('countryId') : undefined
        if (state == undefined) {
            notification.error({
                message: 'Please choose State.'
            })
            return
        }
        if (countryId == undefined) {
            notification.error({
                message: 'Please choose Country.'
            })
            return
        }
        let obj = {
            stateName: state,
            countryId: countryId
        }
        let cloneD = _.clone(this.state.assignStateCountry)
        let findA = _.find(cloneD, (item) => {
            return item.stateName == obj.stateName && item.countryId == obj.countryId
        })
        if (findA) {
            notification.warning({message: 'Already exists'})
        } else {
            this.setState({assignStateCountry: [...cloneD, obj]})
            setFieldsValue({
                state: undefined,
                countryId: undefined
            })
        }
    }


    handleSubmit = e => {
        const {dispatch, form} = this.props
        let {getFieldValue} = form
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let {address, logo, assignStateCountry, agents, countryCode} = this.state
                if (!countryCode) {
                    notification.error({
                        message: 'Please choose Country Code'
                    })
                    return
                }
                valData.countryISOCode = countryCode
                valData.address = address
                valData.department = departmentObj.marketing
                valData.userType = 'branchUser'
                valData.assignStateCountry = assignStateCountry

                let selectedAgents = []
                _.each(agents, (item) => {
                    if (item && item._id) {
                        selectedAgents.push(item._id)
                    }
                })
                if (selectedAgents && selectedAgents.length) {
                    valData.agents = selectedAgents
                }

                let fd = new FormData()
                fd.append('obj', JSON.stringify(valData))
                if (logo && logo.name) {
                    fd.append('logo', logo)
                }
                let data = await dispatch(addUser(fd))
                if (data && !data.error) {
                    form.resetFields()
                    this.setState({
                        address: '',
                        uploadKey: moment(),
                        logo: {},
                        assignStateCountry: [],
                        agents: [],
                        countryCode: ''
                    })
                }
            }
        })
    }


    render() {

        const {submitting, dispatch} = this.props
        const {
            form: {getFieldDecorator, getFieldValue, setFieldsValue}
        } = this.props

        const {editorState, agentList, agentName, agents} = this.state

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            }
        }

        const assignStateColumns = [
            {
                title: 'State',
                key: 'stateName',
                dataIndex: 'stateName'
            },
            {
                title: 'Country',
                key: 'countryId',
                dataIndex: 'countryId',
                render: (item) => {
                    return this.events.countryName(item)
                }
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: 'action',
                render: (item, record, index) => {
                    return (
                        <Icon type={'close'}
                              style={{color: 'red'}}
                              onClick={() => {
                                  this.events.removeStateCountry(index)
                              }}/>
                    )
                }
            }

        ]

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }

        let inputTypes = {
            fields: [
                {
                    label: 'Contact Person Name',
                    key: 'name',
                    required: true
                },
                {
                    key: 'countryCode',
                    span: 3,
                    customField: (
                        <div style={{marginTop: 5}}>
                            <CountryCodeWithFlag countryCode={this.state.countryCode} chooseCode={(val) => {
                                this.setState({
                                    countryCode: val
                                })
                            }}/>
                        </div>
                    )
                },
                {
                    label: 'Mobile no',
                    key: 'mobile',
                    span: 5,
                    type: "number",
                    required: true
                },
                {
                    label: 'Email',
                    key: 'email',
                    required: true
                },
                {
                    label: 'Password',
                    key: 'password',
                    type: 'password',
                    required: true
                },
                {
                    label: 'Post Name',
                    key: 'postName'
                },
                {
                    label: 'Marketing For',
                    key: 'marketingFor',
                    required: true,
                    type: 'select',
                    valueAccessor: x => x.name,
                    keyAccessor: x => x.value,
                    options: marketingForList,
                    onChange: v => {
                        setFieldsValue({
                            marketingFor: v
                        })
                    }
                },
                {
                    label: 'Branch Manger',
                    key: 'branchMangerId',
                    required: true,
                    type: 'select',
                    valueAccessor: x => x.name,
                    keyAccessor: x => x._id,
                    options: this.state.mainBranchArr,
                    onChange: v => {
                        setFieldsValue({
                            branchMangerId: v
                        })
                    }
                },
                {
                    label: 'Joining Date',
                    key: 'joiningDate',
                    required: true,
                    type: 'date'
                },
                {
                    key: 'logo',
                    customField: (
                        <InputBox title={'Profile Picture'} className={'logoCls'}>
                            <Input type={'file'} className={'form-control'} key={this.state.uploadKey} name={'logo'}
                                   id={'logo'}
                                   onChange={(e) => {
                                       this.setState({
                                           logo: e.target.files && e.target.files.length ? e.target.files[0] : null
                                       })
                                   }}/>
                        </InputBox>
                    )
                },
                {
                    key: 'universities',
                    label: '',
                    label1: 'Select University',
                    type: 'select',
                    extraBox1: (
                        <React.Fragment>
                            <a onClick={() => this.events.selectAllUni()}>Select All</a>{' '}
                            <a onClick={() => this.events.deSelectAllUni()}>De-select All</a>
                            {getFieldValue('universities') && getFieldValue('universities').length ?
                                <a className={'selectedUni'}>{getFieldValue('universities').length}</a>
                                : null}
                        </React.Fragment>
                    ),
                    showSearch: true,
                    options: this.state.universityList,
                    mode: 'multiple',
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.universityName}`,
                    onChange: x => {
                        setFieldsValue({
                            universities: x
                        })
                    }
                },
                {
                    label: 'Default Currency',
                    key: 'defaultCurrency',
                    type: 'select',
                    span: 8,
                    showSearch: true,
                    required: true,
                    options: currencyArrList,
                    valueAccessor: x => `${x.name}`,
                    keyAccessor: x => x.name,
                    onChange: async (v) => {
                        setFieldsValue({
                            defaultCurrency: v
                        })
                    }
                },
                {
                    key: 'address',
                    span: 24,
                    customField: (
                        <InputBox title={'Address'}>
                            <TextArea rows={4} className={'form-control'} value={this.state.address} onChange={(e) => {
                                this.setState({address: e.target.value})
                            }}>
                            </TextArea>
                        </InputBox>
                    )
                },
                {
                    label: 'State',
                    // key: 'stateName',
                    key: 'state',
                    type: 'select',
                    // mode: 'multiple',
                    showSearch: true,
                    allowClear: true,
                    span: 10,
                    options: this.state.stateList,
                    valueAccessor: x => x.name,
                    keyAccessor: x => x.name,
                    onChange: async (v) => {
                        setFieldsValue({
                            state: v
                        })
                    }
                },
                {
                    label: 'Country',
                    key: 'countryId',
                    // key: 'country',
                    type: 'select',
                    // mode: 'multiple',
                    span: 10,
                    showSearch: true,
                    allowClear: true,
                    options: this.state.countryList,
                    valueAccessor: x => x.countryName,
                    keyAccessor: x => x._id,
                    onChange: async (v) => {
                        setFieldsValue({
                            countryId: v
                        })
                    }
                },
                {
                    key: 'add',
                    span: 4,
                    customField: (
                        <InputBox title={'Add'}>
                            <a className='btn mt10' type='primary'
                               onClick={() => {
                                   this.AddStateCountry()
                               }}
                               style={{backgroundColor: '#1556bc', textColor: 'white', color: 'white'}}>
                                ADD
                            </a>
                        </InputBox>
                    )
                },
                /*  {
                    key: 'agentId',
                    customField: (
                      <InputBox title={'Agents'} className={'logoCls'}>
                        <Select
                          showSearch={true}
                          onSearch={debounce(this.agentEvents.fetchAgent, 500)}
                          filterOption={false}
                          autoClearSearchValue={true}
                          allowClear={true}
                          value={agentName}
                          style={{ width: '100%' }}
                          showArrow={true}
                          onChange={(e) => {
                            this.setState({
                              agentName: e
                            })
                          }}
                          onSelect={(e) => {
                            this.agentEvents.chooseAgent(e)
                          }}
                          optionLabelProp='label'
                          placeholder='Search Agent'>
                          {agentList && agentList.length && agentList.map((item, index) => {
                            return (
                              <Option key={item._id} value={item._id} label={item.name}>
                                <div>
                                  {item.name} ({item.companyName})
                                </div>
                              </Option>
                            )
                          })}
                        </Select>
                      </InputBox>
                    )
                  }*/
            ]
        }


        return (
            <div className='row'>
                <div className='col-lg-12'>
                    <Form onSubmit={this.handleSubmit}>
                        <div className='form-box mt-4'>

                            <div className='d-flex align-items-center mb-3 heading-form'>
                                <h5>Add Marketing User</h5>
                            </div>

                            <div className='card unizportal'>

                                <Row gutter={16} className={'rowWrap'}>
                                    {inputTypes.fields.map((item, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                {item.customField ? !item.hidden &&
                                                    <Col span={item.span ? item.span : 8}>
                                                        {item.customField}
                                                    </Col> : !item.hidden &&
                                                    <Col span={item.span ? item.span : 8} key={key}
                                                         style={item.extraBox1 ? {paddingBottom: 1} : {}}>
                                                        <InputBox title={item.label1 ? item.label1 : ''}
                                                                  extra={item.extraBox1}>
                                                            <GetEachFormFields
                                                                item={item}
                                                                getFieldDecorator={getFieldDecorator}
                                                                formItemLayout={formItemLayout}/>
                                                        </InputBox>
                                                    </Col>}
                                            </React.Fragment>
                                        )
                                    })}


                                    {/* {agents && agents.length ? <Col span={24} style={{ marginBottom: 20 }}>
                    <Card title={'Agents'} size={'small'}>
                      <div className={'guestEmailBox'}>
                        <ul>
                          {agents.map((item, index) => {
                            return (
                              <li className={'emailTag'} key={index}>
                                {item.name} ({item.companyName})
                                <Icon type={'close'}
                                      onClick={() => this.agentEvents.removeAgent(index)}
                                      className={'emailIcon'} />
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </Card>
                  </Col> : null}*/}
                                </Row>


                                {this.state.assignStateCountry && this.state.assignStateCountry.length ?
                                    <Table dataSource={this.state.assignStateCountry}
                                           className={'table table-bordered'}
                                           bordered={true} size={'small'}
                                           pagination={false} columns={assignStateColumns}/>
                                    : null
                                }
                                <Form.Item>
                                    <Button type='primary' htmlType='submit' loading={this.props.loading}
                                            className={'btn'}>
                                        SAVE
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>

                </div>
            </div>

        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddMarketingManager)
