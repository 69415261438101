import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'
import moment from 'moment'
import {addTarget, singleTargetUrl, managerTargetUrl, getAchievedTargetsUrl} from '../api'


export const addTargetAction = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let { data } = await axios.post(addTarget(), valData, getToken())
    // console.log(data)
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
          message: data.message || 'Success'
        })
    } else {
      notification.error({
        message: data.message || 'Error'
      })
    }
    return data
  }

  export const listAllTargets = (filters) => async (dispatch) => {
    dispatch({ type: 'STUDENT_SHOW_LOADER' })
    dispatch({ type: 'EMPTY_STUDENT_LIST' })
    let config = {
      params: { ...filters },
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
    }
    let { data } = await axios.get(addTarget(), config)
    // dispatch({ type: 'STUDENT_HIDE_LOADER' })
    // dispatch({ type: 'LIST_ALL_STUDENT', payload: data.data })
    if (data.error) {
      notification.error({
        message: data.message || 'Error'
      })
    }
    return data.data
  }


  export const getSigleTarget = (id) => async (dispatch) => {
    dispatch({ type: 'STUDENT_SHOW_LOADER' })
    let config = {
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
    }
    let { data } = await axios.get(singleTargetUrl(id), config)
    dispatch({ type: 'STUDENT_HIDE_LOADER' })
    if (data.error) {
      notification.error({
        message: data.message || 'Error'
      })
    } else {
      dispatch({ type: 'LIST_STUDENT', payload: data })
    }
    return data.data
  }

  export const updateTargetfxn = (valData, id) => async (dispatch) => {
    dispatch(showPageLoad())
    console.log(valData,id);
    let { data } = await axios.put(singleTargetUrl(id), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
      notification.success({
        message: data.message || `Updated Successfully`
      })
    } else {
      notification.error({
        message: data.message || 'Error updating'
      })
    }
    return data
  }

  export const managerTargetfxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let { data } = await axios.post(managerTargetUrl(), valData, getToken())
    // console.log(data)
    dispatch(hidePageLoad())
    // if (!data.error) {
    //     notification.success({
    //       message: data.message || 'Success'
    //     })
    // } else {
    //   notification.error({
    //     message: data.message || 'Error'
    //   })
    // }
    return data
  }

export const getAchievedTargetsFxn = (valData) => async (dispatch) => {
  // dispatch(showPageLoad())
  let {data} = await axios.post(getAchievedTargetsUrl(), valData, getToken())
  // dispatch(hidePageLoad())
  return data
}
