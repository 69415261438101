import { Drawer, Col, Row, Avatar, Button, Input, Collapse, Card, Icon, Divider, Tag, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import {
  icefAddCommentFxn,
  icefCommentListFxn,
  icefMeetingListFxn,
  icefParticipantListFxn,
  singleParticipantFxn,
  icefParticipantHistoryFxn
} from '../../actions'
import './style.css'
import { useDispatch } from 'react-redux'
import { nameAvatar, displayDate, displayTime } from '../../../../components/_utils/appUtils'
import styles from '../../styles.less'
import moment from 'moment'

const { TabPane } = Tabs

const { Panel } = Collapse
const { TextArea } = Input
const { Meta } = Card
const MAX_DESCRIPTION_LENGTH = 50

let SingleHistoryComponent = (props) => {
  let { data, getDate } = props
  let { eventId } = data
  return (
    <>
      <div className={'single_event_card'}>
        <div className={styles.single_info_card}>
          <div className={styles.information}>
            <h6>{eventId.eventName}</h6>
            <div className={'align_center'}>
              <Icon type='calendar' />&nbsp; {getDate(eventId)}
            </div>
            <div className={styles.alignCenter}>
              <Icon type='environment' />&nbsp; {eventId.city}, {eventId.state}, {eventId.country}
            </div>
          </div>
        </div>
        <Collapse>
          <Panel header={`Comments (${data.comments ? data.comments.length : 0})`} key='1'>

            <div className='comment-section'>
              <div className='comment-wrap-new' style={{ overflowY: 'auto' }}>
                {data.comments && data.comments.length ? data.comments.map((item) => {
                  return (
                    <div className='comment' key={item._id}>
                      <div className={styles.comment_text}>
                        <p className='comment-author'>
                                      <span className={'name'}><strong>{item.createdByUser.name}</strong>
                                        <span
                                          className='date1'>{displayDate(item.createdAt)} | {displayTime(item.createdByUser.time)}</span>
                                      </span>
                        </p>
                        <div className={'mb5'}>
                          {item.comment}
                        </div>
                      </div>
                    </div>
                  )
                }) : null}
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    </>
  )
}

const ParticipantDetailDrawer = (props) => {
  let { visible, eventId, onClose, currentUser, meetingType, participantId } = props
  let [participantData, setParticipantData] = useState({})
  let [historyArr, setHistoryArr] = useState([])
  let [countryPhoto, setCountryPhoto] = useState({})
  let [eventData, setEventData] = useState({})

  const [showMore, setShowMore] = useState(false)


  const [comments, setComments] = useState('')
  let [commentData, setCommentData] = useState([])
  let [commentState, setCommentState] = useState({
    data: [],
    total: 0,
    page: 1,
    count: 50
  })
  let [meetingListState, setMeetingListState] = useState({
    visible: false,
    eventId: '',
    meetingType: '',
    eventName: '',
    companyId: ''
  })
  const [currentIndex, setCurrentIndex] = useState(0)
  const dispatch = useDispatch()


  useEffect(() => {
    events.loadSingleParticipant()
  }, [])


  const events = {
    getIdGroup: () => {
      let companyId = participantData.companyId && participantData.companyId._id ? participantData.companyId._id : ''
      let eventId = eventData._id
      let participantId = props.participantId
      return { companyId, eventId, participantId }
    },
    showCommentDrawer: (eventId) => {
      setCommentState({
        eventId: eventId,
        visible: true
      })
    },
    hideCommentDrawer: () => {
      setCommentState({
        eventId: '',
        visible: false
      })
    },
    reloadComment: () => {
      events.loadComments()
    },
    handleCommentSave: async () => {

      let resp = await dispatch(icefAddCommentFxn({ comment: comments, ...events.getIdGroup() }))
      if (resp && resp.success) {
        setComments('')
        events.loadComments()
      }
      events.reloadComment()
    },
    loadComments: async () => {
      let params = {}
      params.results = commentState.count
      params.count = commentState.count
      params.page = commentState.page
      let { participantId } = events.getIdGroup()
      const resp = await dispatch(icefCommentListFxn({ ...params, participantId }))
      setCommentData(resp.data)
      setCommentState((prevData) => {
        return {
          ...prevData,
          data: resp.data,
          total: resp.total
        }
      })
    },
    loadParticipantHistory: async () => {
      let params = {}
      params.results = commentState.count
      params.count = commentState.count
      params.page = commentState.page
      params.participantId = participantId
      let { companyId } = events.getIdGroup()
      const { data } = await dispatch(icefParticipantHistoryFxn({ ...params, companyId }))
      setHistoryArr(data)

    },
    showMeetingList: (eventId, data) => {
      setMeetingListState({
        visible: true,
        eventId,
        meetingType: data.meetingType,
        eventName: data.eventName
      })
    },
    hideMeetingList: () => {
      setMeetingListState({
        visible: false,
        eventId: '',
        meetingType: '',
        eventName: ''
      })
    },
    changeEvent: async (index, eventId) => {
      setCurrentIndex(index)
      const resp = await dispatch(icefCommentListFxn({ eventId }))
      setCommentData(resp.data)
    },
    changeComment: async (eventId) => {
      const resp = await dispatch(icefCommentListFxn({ eventId }))
      setCommentData(resp.data)
    },

    loadSingleParticipant: async () => {
      let { data } = await dispatch(singleParticipantFxn(props))
      if (data && data.eventId && data.eventId._id) {
        setEventData(data.eventId)
      }
      setParticipantData(data)
    },
    getDate: (eventDataObj) => {
      let starDay = moment(eventDataObj.startDate).format('MMMM D')
      let endDay = moment(eventDataObj.endDate).format('D')
      let endYear = moment(eventDataObj.endDate).format('YYYY')
      return `${starDay} - ${endDay}, ${endYear}`
    }
  }

  useEffect(() => {
    if (participantData && participantData._id) {
      events.loadComments()
      events.loadParticipantHistory()
    }
  }, [participantData])


  return (
    <Drawer visible={visible}
            onClose={onClose}
            width={'80%'}
            title={`Event`}>
      <div className={styles.participant_card}>
        {participantData && participantData._id ?
          <Row gutter={16}>
            <Col span={16}>
              <div className={styles.info_card}>
                <div className={styles.information}>
                  {eventData.countryPhoto && eventData.countryPhoto.path ? <>
                    <img src={eventData.countryPhoto.path} className={styles.banner} />
                  </> : null}
                  <div>
                    <h4>{eventData.eventName}</h4>
                    <div className={'align_center'}>
                      <Icon type='calendar' />&nbsp; {events.getDate(eventData)}
                    </div>
                    <div className={styles.alignCenter}>
                      <Icon type='environment' />&nbsp; {eventData.city}, {eventData.state}, {eventData.country}
                    </div>
                  </div>
                </div>

                <div>
                  <Tabs defaultActiveKey='1'>
                    <TabPane tab='Comments' key='1' className={styles.comments}>
                      <div className='comment-section'>
                        <div className='comment-wrap-new' style={{ overflowY: 'auto' }}>
                          {Array.isArray(commentData.data) && commentData.data.map(comment => (
                            <>
                              <div className='comment' key={comment._id}>
                                <Avatar className={'textUpp avatar_text1'} size='large'>
                                  {nameAvatar(comment.createdByUser.name)}
                                </Avatar>
                                <div className={styles.comment_text}>
                                  <p className='comment-author'>
                                      <span className={'name'}><strong>{comment.createdByUser.name}</strong>
                                        <span
                                          className='date1'>{displayDate(comment.createdAt)} | {displayTime(comment.createdByUser.time)}</span>
                                      </span>
                                  </p>
                                  <div className={'mb5'}>
                                    {comment.comment}
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>

                        <div className={styles.input_box}>

                          <Avatar className={'textUpp avatar_text1'} size='large'>
                            {nameAvatar(currentUser.name)}
                          </Avatar>

                          <Input
                            placeholder='Add Comment' className={styles.input_comment1}
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                events.handleCommentSave()
                              }
                            }}
                          />
                        </div>
                      </div>
                    </TabPane>
                  </Tabs>
                </div>

              </div>


            </Col>
            <Col span={8}>
              <Card title={'History'}>
                <div className={'event_list_card'}>
                  {historyArr && historyArr.length ?
                    historyArr.map((item) => {
                      return (
                        <SingleHistoryComponent data={item} getDate={events.getDate} />
                      )
                    }) : null}
                </div>
              </Card>

            </Col>
          </Row> : null}

      </div>
    </Drawer>
  )
}

export default ParticipantDetailDrawer
