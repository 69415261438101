import React, {useState} from "react"
import { Drawer, Form, Card, notification, Row, Col, Button, Tooltip } from 'antd'
import { InputBox, newFormatDisplayDate } from '../../../components/_utils/appUtils'
import GetEachFormFields from "../../../components/_utils/appFormUtils";
import {useDispatch} from "react-redux";
import {uploadMultiTuitionFeesDocumentFxn, uploadTuitionFeesDocumentFxn} from "../actions/fcmtAction";
import { RowTable } from '../../../components/_utils/RowTable'
import moment from 'moment'

const formItemLayout = {
  labelCol: {
    xs: {span: 0},
    sm: {span: 0},
    md: {span: 0}
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 24},
    md: {span: 24}
  }
}
const UploadTuitionFeesCambieOnshore = (props) => {
  let {studentId,totalTuitionFees,chargingAmount,allRecord, applicationId, visible, onClose, onSubmit, form: {getFieldDecorator, getFieldValue}} = props;
  let [document, setDocument] = useState({})
  let dispatch = useDispatch()
  let briefOfTransactionArray = [
    {name: 'Email interact'},
    {name: 'Bank deposit'},
    {name: 'Cdmx'},
    {name: 'Credit card'},
    {name: 'Other'}
  ];

  const events = {
    chooseDocument: (e) => {
      let {name, files} = e.target
      if (files && files.length) {
        setDocument(files[0])
      }
    },
    handleSubmit: (e) => {
      const {form} = props
      e.preventDefault()
      form.validateFieldsAndScroll(async (err, valData) => {
        if (!err) {
          if (!document || (document && !document.name)) {
            return notification.error({
              message: 'Please choose document'
            })
          }
          if (valData.amount > allRecord.applications.chargingAmount - (allRecord.applications.totalTTAmount? allRecord.applications.totalTTAmount : 0)){
            return notification.error({
              message: 'Amount Cannot be greater Than Charge Amount'
            })
          }
          let obj = {
            ...valData,
            applicationId: applicationId,
            studentId: studentId
          }

          let fd = new FormData()
          fd.append('obj', JSON.stringify(obj))
          if (document && document.name) {
            fd.append("document", document);
          }
          let resp = await dispatch(uploadMultiTuitionFeesDocumentFxn(fd));
          if (resp && resp.success) {
            onSubmit()
          }
        } else {
          notification.warning({
            message: 'Fill All Required Fields'
          })
        }
      })
    }
  }
  let inputTypes = {
    fields: [
      {
        key: 'amount',
        label: 'Enter tuition fees *',
        placeholder: 'Enter tuition fees',
        type: 'number',
        required: true,
        span: 12,
      },
      {
        key: 'date',
        label: 'Transaction date *',
        placeholder: 'Transaction date',
        type: 'date',
        required: true,
        span: 12,

      },
      {
        key: 'briefOfTransaction',
        label: 'Brief of Transaction *',
        placeholder: 'Brief of Transaction',
        type: 'select',
        showSearch: true,
        required: true,
        options: briefOfTransactionArray,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            briefOfTransaction: x
          })
        },
        span: 12,
      },
      {
        key: 'otherBriefOfTransaction',
        label: 'Other Brief of Transaction *',
        placeholder: 'Other Brief of Transaction',
        rows: 4,
        hidden: !(getFieldValue('briefOfTransaction') == 'Other'),
        required: (getFieldValue('briefOfTransaction') == 'Other'),
        span: 12,

      },
      {
        key: 'document',
        span: 12,
        customField: (
          <>
            <InputBox title={'Choose Attachment *'}>
              <input type={'file'} className={'form-control mt-2'}
                     onChange={(e) => {
                       events.chooseDocument(e)
                     }}
              />
            </InputBox>
          </>
        )
      },
      {
        key: 'receivedByUser',
        label: 'Received By User *',
        placeholder: 'Received By User',
        span: 12,
        required: true,
      },
      {
        key: 'details',
        label: 'Enter Details',
        placeholder: 'Enter Details',
        type: 'textarea',
        rows: 4
      },

    ]
  }

  return (
    <Drawer
      width={"45%"}
      title={`Upload Tuition Fees & Generate Travel Document`}
      visible={visible}
      onClose={onClose}>
      <Card bordered={false}>
        <Form
          onSubmit={events.handleSubmit}
          hideRequiredMark
          className={'inputForm'}>
          <Row gutter={16}>
            <div className={'tableBox striped'}>
              <RowTable title={'Name'} value={(allRecord.name)} />
              <RowTable title={'Email'} value={(allRecord.email)} />
              <RowTable title={'Date Of Birth'} value={moment(allRecord.dateOfBirth).format('DD-MM-YYYY')} />
              <RowTable title={'Student Id'} value={(allRecord.studentId)} />
              <RowTable title={'Charge Amount'}   value={<b>{`CAD $${chargingAmount}`}</b>} />
            </div>

            {inputTypes.fields.map((item) => {
              return (
                <Col span={item.span? item.span : 24} key={item.key}>
                  {item.customField ? item.customField : <GetEachFormFields
                    item={item}
                    getFieldDecorator={getFieldDecorator}
                    formItemLayout={formItemLayout}
                  />}
                </Col>
              )
            })}
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Drawer>
  )
}
const UploadTuitionFeesWrapper = Form.create()(UploadTuitionFeesCambieOnshore)
export default UploadTuitionFeesWrapper
