import { notification } from 'antd'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import {
  agentCommissionGraphUrl,
  directOnshoreCanadaCountUrl,
  footFallRecordForBarChartUrl,
  getDashboardAustraliaOnShoreCount,
  getDashboardCanadaOnShoreCountUrl,
  getDashboardCountryWise,
  getDashboardUCountryCountUrl,
  getDashboardUniversityCountUrl,
  getDashboardUserRecord,
  getUniversityPieChartCountUrl,
  getUserTargetUrl,
  last10dayDataReportUrl,
  marketingUserForAgentsUrl,
  showUpdatePasswordApi,
  studentCountYearWiseUrl,
  studentRecordCountAgentWiseUrl,
  visaChartLast10dayUrl,
  visaChartYearWiseUrl,
  addCallBackRequestUrl,
  requestCallBackListUrl,
  updateRequestCallBackUrl, gicWeeklyReportUrl, agentYearWiseUrl, addCallbackFeedbackUrl, getCountryStatusWiseUrl,
} from '../api/dashboardApi'

export const countryWiseDashboard = valData => async dispatch => {
  let { data } = await axios.post(
    getDashboardCountryWise(),
    valData,
    getToken()
  )
  // dispatch({ type: 'DASHBOARD_COUNTRY_WISE_DATA', payload: data })
  return data
}

export const dashboardUserRecord = async valData => {
  let { data } = await axios.post(getDashboardUserRecord(), valData, getToken())
  return data
}

export const dashboardUniversityCount = valData => async dispatch => {
  let { data } = await axios.post(
    getDashboardUniversityCountUrl(),
    valData,
    getToken()
  )
  return data
}

export const dashboardCountryCount = valData => async dispatch => {
  let { data } = await axios.post(
    getDashboardUCountryCountUrl(),
    valData,
    getToken()
  )
  return data
}
export const universityPieChartCount = valData => async dispatch => {
  let { data } = await axios.post(
    getUniversityPieChartCountUrl(),
    valData,
    getToken()
  )
  return data
}
export const dashboardAustraliaOnShoreFxn = async valData => {
  let { data } = await axios.post(
    getDashboardAustraliaOnShoreCount(),
    valData,
    getToken()
  )
  return data
}

export const showUpdatePasswordFxn = async () => {
  let { data } = await axios.post(showUpdatePasswordApi(), null, getToken())
  return data
}

export const dashboardCanadaOnShoreFxn = async valData => {
  let { data } = await axios.post(
    getDashboardCanadaOnShoreCountUrl(),
    valData,
    getToken()
  )
  return data
}
export const getUserTargetFxn = async valData => {
  let { data } = await axios.post(getUserTargetUrl(), valData, getToken())
  /* if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }*/
  return data
}
export const studentCountYearWiseFxn = async valData => {
  let { data } = await axios.post(
    studentCountYearWiseUrl(),
    valData,
    getToken()
  )
  return data
}

export const studentRecordCountAgentWiseFxn = async () => {
  let { data } = await axios.post(
    studentRecordCountAgentWiseUrl(),
    {},
    getToken()
  )
  return data
}

export const footFallRecordForBarChartFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    footFallRecordForBarChartUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const marketingUserForAgentsFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    marketingUserForAgentsUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  return data
}

export const directOnshoreCanadaCountFxn = async valData => {
  let { data } = await axios.post(
    directOnshoreCanadaCountUrl(),
    valData,
    getToken()
  )
  return data
}
export const visaChartYearWiseFxn = async valData => {
  let { data } = await axios.post(visaChartYearWiseUrl(), valData, getToken())
  return data
}

export const visaChartLast10dayFxn = async valData => {
  let { data } = await axios.post(visaChartLast10dayUrl(), valData, getToken())
  return data
}

export const last10dayDataReportFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(last10dayDataReportUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}


export const agentCommissionGraphFxn = valData => async dispatch => {
  // dispatch(showPageLoad())
  let { data } = await axios.post(agentCommissionGraphUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const addCallBackRequest = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addCallBackRequestUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const requestCallBackListFxn = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...await getToken()
  }
  let { data } = await axios.get(requestCallBackListUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}

export const updateCallBackFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateRequestCallBackUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const gicReportChartFxn = async valData => {
  let { data } = await axios.post(gicWeeklyReportUrl(), valData, getToken())
  return data
}

export const agentsYearWiseFxn = async valData => {
  let { data } = await axios.post(agentYearWiseUrl(), valData, getToken())
  return data
}

export const addCallbackFeedbackFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addCallbackFeedbackUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

// export const fetchingCountry = (valData) => async dispatch => {
//
//   dispatch(showPageLoad())
//   let { data } = await axios.post(fetchingCountryUrl(), valData)
//   dispatch(hidePageLoad())
//   if (!data.error) {
//     notification.success({
//       message: data.message || 'Success'
//     })
//   } else {
//     notification.error({
//       message: data.message || 'Error'
//     })
//   }
//   return data
// }
export const getCountryStatusWiseFxn = valData => async dispatch => {
  let {data} = await axios.post(
      getCountryStatusWiseUrl(),
      valData,
      getToken()
  )
  return data
}
