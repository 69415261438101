import React, { useEffect, useState } from 'react'
import { Form, Steps, Row, Col, Button, Input, Card } from 'antd'
import { connect, useDispatch } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import _ from 'lodash'
import moment from 'moment'
import { listAllCountries } from "../../countries/actions/countries"
import { addUpdatesAction } from '../actions'
import { listAllUniversities } from '../../university/actions/university'


const AddTarget = (props) => {
    const {
        form: { getFieldDecorator, setFieldsValue, getFieldValue }, currentUser
    } = props
    const dispatch = useDispatch()
    let [allCountry, setAllCountry] = useState([])
    const [universityList, setUniversityList] = useState([])

    useEffect(() => {
        loadCountry()
        setFieldsValue({date:moment()})
    }, [])

    useEffect(() => {
        loadUniversityNameList()
    }, [getFieldValue('countryId')])


    const loadCountry = async () => {
        let filters = {
            sortField: 'countryName', sortOrder: 'ascend'
        }
        let { data } = await dispatch(listAllCountries(filters))
        setAllCountry(data)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 }
        }
    }

    const loadUniversityNameList = async () => {
        let country = getFieldValue('countryId')
        let params = {
            results: 5000,
            select: ['universityName'],
            sortField: 'universityName',
            sortOrder: 'ascend',
            regExFilters: ['universityName'],
            showUniversity: [true, false]
        }
        params.universityCountry = country
        let {data} = await dispatch(listAllUniversities(params))
        if (data && data.length) {
            setUniversityList(data)
        }
    }

    let formFields = [
            {
                key: 'countryId',
                label: 'Country',
                required: true,
                type: 'select',
                options: allCountry,
                span: 8,
                keyAccessor: x => x._id,
                valueAccessor: x => `${x.countryName}`,
                onChange: x => {
                    setFieldsValue({
                        countryId: x
                    })
                    setFieldsValue({universityId: ''})
                }
            },
        {
            key: 'universityId',
            label: 'University',
            showSearch: true,
            required: true,
            type: 'select',
            span: 8,
            options: universityList,
            keyAccessor: x => x._id,
            valueAccessor: x => `${x.universityName}`,
            onChange: x => {
                setFieldsValue({
                    universityId: x
                })
            }
        },
        {
            key: 'date',
            label: 'Date',
            required: true,
            span: 8,
            placeholder: 'Date',
            type: 'date',
        },
        {
            key: 'content',
            label: 'Details',
            span: 24,
            required: true,
            placeholder: 'Content',
            type: 'textarea',
        },

        ]

    const submitUpdates = (e) => {
        let {form} = props;
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (valData && valData.countryId) {
                console.log(valData)
                let data = await dispatch(addUpdatesAction(valData))
                if (data && !data.error) {
                    form.resetFields()
                }

            }
        })
    }


    return (
        <div className={'mt-4'}>
            <div className='row'>
                <div className='col-lg-12'>
                    <Form className={'vertical-form'} autoComplete='off' onSubmit={submitUpdates}>
                        <div className='form-box'>

                            <div className='heading-form stuHeader  d-flex align-items-center mb-3'
                                 id={'generalInformation'}>
                                <h5><span className='img'><img src='../dist/img/check.svg' alt=''/></span> Add
                                    Updates</h5>
                            </div>
                            <div className='card unizportal'>
                                {/*general*/}
                                <Row gutter={24} className={'rowWrap'}>

                                    {formFields.map((item, key) => {
                                        return (
                                            <Col span={item.span} md={item.span} sm={item.span} xs={item.span} key={key}
                                                 className={'mb10'}>
                                                <GetEachFormFields
                                                    item={item}
                                                    getFieldDecorator={getFieldDecorator}
                                                    formItemLayout={formItemLayout}/>
                                            </Col>
                                        )
                                    })}
                                </Row>

                                <button className='btn' type='submit' >save
                                </button>
                            </div>


                        </div>
                    </Form>
                </div>
            </div>

        </div>
    )
}


const WrappedAddTarget = Form.create()(AddTarget);

const mapStateToProps = ({ global, router }) => ({
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    null
)(WrappedAddTarget)


