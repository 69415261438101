import React, {useEffect, useState} from "react"
import {Drawer, Input, notification, Select} from "antd";
import {useDispatch} from "react-redux";
import {updateInterviewStatusFxn} from "../../../actions/oxfordAction";
import InputBox from "../../../../../components/_utils/InputBox";
import {filterOption} from "../../../../../components/_utils/appUtils";

const {TextArea} = Input

const {Option} = Select
let statusList = [
    'Pass',
    'Fail',
    'Reappear',
]

const UpdateInterviewStatusDrawer = (props) => {
    let {onClose, visible, studentData, onSubmit} = props;
    let [oxfordInterviewStatus, setOxfordInterviewStatus] = useState("")
    let [note, setNote] = useState("")
    let dispatch = useDispatch()
    useEffect(() => {
    }, [])

    let updateInterviewStatus = async () => {
        if (!oxfordInterviewStatus) {
            notification.warning({
                message: "Please choose Interview status."
            })
            return
        }
        if (!note && (oxfordInterviewStatus == 'Fail' || oxfordInterviewStatus == 'Reappear')) {
            notification.warning({
                message: "Please enter note."
            })
            return
        }
        let obj = {
            studentId: studentData._id,
            applicationId: studentData.applications._id,
            oxfordInterviewStatus: oxfordInterviewStatus,
            note: note,
        }
        let resp = await dispatch(updateInterviewStatusFxn(obj));
        if (resp && resp.success) {
            onSubmit()
        }
    }
    return (
        <Drawer title={'Update Interview Status'} width={'35%'} onClose={onClose} visible={visible}>
            <div className="card unizportal">
                <InputBox>
                    <Select name='Interview Status'
                            onChange={(item) => {
                                setOxfordInterviewStatus(item)
                            }}
                            filterOption={filterOption}
                            placeholder='Interview Status'
                            allowClear={true}
                            showSearch={true}
                            value={oxfordInterviewStatus || undefined}>
                        {statusList && statusList.length ? statusList.map((item, key) => {
                            return (
                                <Option value={item} key={key}>
                                    {item}
                                </Option>
                            )
                        }) : null}
                    </Select>
                </InputBox>
                <InputBox title={'Note'}>
                    <TextArea
                        rows={7}
                        onChange={(e) => {
                            setNote(e.target.value)
                        }}
                        name={'note'}
                        className={'form-control'}
                        placeholder={'Note'} value={note}/>
                </InputBox>
                <button className='btn' type='submit' onClick={updateInterviewStatus}>save
                </button>
            </div>
        </Drawer>
    )
}
export default UpdateInterviewStatusDrawer
