import {
    Button,
    Col,
    DatePicker,
    Input,
    InputNumber,
    Modal,
    Row,
    notification
} from 'antd'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import InputBox from '../../../components/_utils/InputBox'

let initState = {
    studentPhoneNumber: ''
}

const OxfordMessage = props => {
    const dispatch = useDispatch()
    let {
        visible,
        title,
        onClose,
        handleCancel,
        finalSubmit,
        setNumber,
        phoneNumber,
        studentPhoneNumber
    } = props
    const [state, setState] = useState(initState)
    useEffect(() => {
        events.updateState({studentPhoneNumber: phoneNumber})
    }, [])
    let events = {
        updateState: data => {
            setState(prevData => {
                return {
                    ...prevData,
                    ...data
                }
            })
        },
        submitQuery: () => {
            if (!state.studentPhoneNumber) {
                notification.warning({message: 'Please Fill Phone Number.'})
                return
            }
            finalSubmit(state.studentPhoneNumber)
        },
        handleCancel: () => {
            handleCancel()
        }
    }

    return (
        <>
            <Modal
                visible={visible}
                onClose={onClose}
                footer={false}
                title="Student Mobile No Confirmation"
                width={880}>
                <div style={{textAlign: 'center', marginBottom: 10}}>
                    <h6>
                        Please confirm this is a student Personal Phone Number to get a
                        compliance call from Oxford College of Education. If contact details
                        are incorrect then case will be closed immediately.
                    </h6>
                    <div className={'alignCenter'}>
                        <InputBox title={'Student Phone Number'}
                                  customStyle={{width: "40%", marginRight: "auto", marginLeft: "auto"}}>
                            <InputNumber
                                placeholder={'Phone Number'}
                                type={'number'}
                                value={state.studentPhoneNumber}
                                onChange={e => {
                                    events.updateState({studentPhoneNumber: e})
                                }}
                            />
                        </InputBox>
                    </div>

                    <div className={'mt10'}>
                        <Button
                            className={'btn btn-success'}
                            onClick={events.submitQuery}>
                            Submit
                        </Button>
                        <Button
                            className={'btn btn-danger ml10'}
                            onClick={events.handleCancel}>
                            Cancel
                        </Button>
                    </div>

                </div>
            </Modal>
        </>
    )
}
export default OxfordMessage
