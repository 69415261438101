import React, { useState, useEffect } from 'react'
import { Drawer, Form, Button, notification, Card, Spin, Row, Col } from 'antd'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { useDispatch, useSelector } from 'react-redux'
import { createMatchSelector, goBack } from 'connected-react-router'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { getState, updateState } from '../actions/state'
import { listAllCountries } from '../../countries/actions/countries'
import GetEachFormFields from '../../../components/_utils/appFormUtils'


const Edit = (props) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({})
  const [stateId, setStateId] = useState(null)
  const { loader, allCountries } = useSelector(state => ({
    loader: state.stateReducers.loader,
    allCountries: state.countryReducers.allCountries

  }))
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } } = props

  let inputTypes = {
    fields: [
      {
        key: 'country', label: 'Country', type: 'select', required: true, span: 8,
        options: allCountries,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.countryName}`,
        onChange: x => {
          props.form.setFieldsValue({
            country: x,
            stateName: undefined

          })
        }
      },
      { key: 'stateName', label: 'State Name', required: true, span: 8 }
    ]
  }

  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = async (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        await dispatch(updateState(valData, stateId))
        getStateData()
        form.resetFields()

        dispatch(goBack())
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  useEffect(() => {
    dispatch({ type: 'STATE_HIDELOADER' })
    dispatch(listAllCountries({ results: 1000000, sortField: 'countryName', sortOrder: 'ascend' }))
    getStateData()
  }, [])


  const getStateData = async () => {
    let searchParams = new URLSearchParams(window.location.search)
    let id = searchParams.get('id')
    setStateId(() => id)
    if (id) {
      let data = await dispatch(getState(id))
      if (data) {
        setState(() => data)
      }
    }
  }

  useEffect(() => {
    props.form.setFieldsValue({
      country: state.country ? state.country._id : undefined,
      stateName: state.stateName || undefined
    })

  }, [state])


  return (
    <div className="row">
      <div className="col-lg-12">
        <Form onSubmit={handleSubmit}>
          <div className="form-box mt-4">

            <div className="d-flex align-items-center mb-3 heading-form">
              <h5>Update State</h5>
            </div>

            <div className="card unizportal">
              <Row gutter={24}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <Col span={8} key={key}>
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout}/>
                    </Col>
                  )
                })}
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="btn">
                  SAVE
                </Button>
              </Form.Item>

            </div>
          </div>

        </Form>
      </div>
    </div>
  )
}

const EditState = Form.create()(Edit)
export default EditState
