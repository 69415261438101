import React from 'react'

const ButtonGrid = (props) => {
  let { children, width = 150, grid = 3, gap = 10 } = props
  let styles = {
    display: 'grid',
    gridTemplateColumns: `repeat(${grid}, 1fr)`,
    gridColumnGap: gap,
    width: width
  }
  return (
    <div style={styles}>
      {children}
    </div>
  )
}
export default ButtonGrid
