import { apiUrl } from '../../../settings'

export const addContractUrl = () => {
  return apiUrl + '/api/contract'
}

export const getAllContracts = () => {
  return apiUrl + '/api/contract'
}

export const getContractWithUrl = () => {
  return apiUrl + '/api/group-by-contract-with'
}

export const getsingleContractUrl = () => {
  return apiUrl + '/api/single-contract'
}

export const updateContractUrl = () => {
  return apiUrl + '/api/update-contract'
}
