import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Popconfirm,
  Tooltip, Tag, Icon, Row, Col, Select
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { notification } from 'antd'
import {
  listAllCourse,
  exportCourseList,
  CourseIsFeatured,
  CourseIsSearchAble,
  deleteCourse,
  topCourseUpdateFxn
} from '../actions/courseAction'
import { getUrlPushWrapper } from '../../../routes'
import {
  ConditionOptions,
  Intakes,
  filterOption
} from '../../../components/_utils/appUtils'
import { listAllUniversities } from '../../university/actions/university'
import { listAllCountries } from '../../countries/actions/countries'
import { push } from 'connected-react-router'
import { listAllCampus } from '../../campus/actions/campus'
import { CheckUserRight } from '../../dashboard/views/DashboardUserWise'
import CourseStructure from '../drawers/courseStructure'

const Option = Select.Option

/*
const featureOptions = [
  { name: 'Featured', value: true }, { name: 'Non-Featured', value: false }
]
const searchOptions = [
  { name: 'Searchable', value: true }, { name: 'Non-Searchable', value: false }
]
*/

const ListAllCourse = (props) => {
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  let [allUniversity, setAllUniversity] = useState([])
  let [allCountry, setAllCountry] = useState([])
  let [allCampus, setAllCampus] = useState([])
  let [isSearchAble, setIsSearchAble] = useState()
  let [isFeatured, setIsFeatured] = useState()
  let [courseName, setCourseName] = useState('')
  let [countryId, setCountryId] = useState('')
  let [universityId, setUniversityId] = useState('')
  let [campusId, setCampusId] = useState('')
  let [tableSearch, setTableSearch] = useState(false)
  const { totalCourses, allCourses } = useSelector((state) => ({
    totalCourses: state.addCourse.totalCourses,
    allCourses: state.addCourse.allCourses
  }))

  let [courseStructureObj, setCourseStructureObj] = useState({
    courseId: '',
    courseName: '',
    visible: false
  })

  let [filters, setFilters] = useState({})
  const tableRef = useRef()
  const dispatch = useDispatch()

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let resp = await getParamsForFilter()

      if (resp.countryId) {
        params.universityCountry = resp.countryId
      }
      if (resp.courseName) {
        params.courseName = resp.courseName
      }
      if (resp.universityId) {
        params.courseUniversity = resp.universityId
      }
      if (resp.campusId) {
        params.campusName = resp.campusId
      }

      if (!params.results) {
        params.results = 50
        params.count = 50
      }

      params.sortField = 'courseName'
      params.sortOrder = 'ascend'
      params.isFeatured = isFeatured ? isFeatured : undefined
      params.isSearchAble = isSearchAble ? isSearchAble : undefined
      if (tableSearch) {
        params.page = 1
      }
      setFilters(params)
      let resp1 = await dispatch(listAllCourse({
        ...params,
        regExFilters: ['courseName', 'courseDuration', 'courseLevel', 'tuitionFee']
      }))
      resolve(resp1)
      setTableSearch(false)
    })
  }

  useEffect(() => {
    tableRef.current.reload()
  }, [isFeatured, isSearchAble])

  const [itemNo, setItemNo] = useState(1)
  const [loading, setLoading] = useState(false)
  const confirmDelete = async (id) => {
    let resp = await dispatch(deleteCourse(id))
    if (resp && resp.success) {
      notification.warning({ message: resp.message })
      tableRef.current.reload()
    }
  }
  useEffect(() => {
    loadAllCountry()
    events.setFieldByParams()
    dispatch(listAllCourse()).then(d => {
    }).catch(err => {
    })
  }, [])

  useEffect(() => {
    loadAllCampus()
  }, [universityId])

  useEffect(() => {
    loadAllUniversity()
  }, [countryId])

  const loadAllUniversity = async () => {
    if (countryId) {
      let { data } = await dispatch(listAllUniversities({
        results: 1000,
        sortField: 'universityName',
        sortOrder: 'ascend',
        universityCountry: countryId,
        showUniversity: [true, false]
      }))
      setAllUniversity(data)
    } else {
      setAllUniversity([])
    }
  }
  const loadAllCountry = async () => {
    let { data } = await dispatch(listAllCountries({
      results: 1000,
      sortField: 'countryName',
      sortOrder: 'ascend',
      active: [true, false]
    }))
    setAllCountry(data)
  }
  const loadAllCampus = async () => {
    let { data } = await dispatch(listAllCampus({
      campusUniversity: universityId,
      results: 1000,
      sortField: 'campusName',
      sortOrder: 'ascend'
    }))
    setAllCampus(data)
  }

  const exportCourseFxn = async () => {
    if (!filters.universityCountry) {
      notification.warning({ message: 'Please choose country' })
      return
    }
    let params = { ...filters }
    let resp1 = await getParamsForFilter()

    if (resp1.countryId) {
      params.universityCountry = resp1.countryId
    }
    if (resp1.courseName) {
      params.courseName = resp1.courseName
    }
    if (resp1.universityId) {
      params.courseUniversity = resp1.universityId
    }
    if (resp1.campusId) {
      params.campusName = resp1.campusId
    }

    params.isFeatured = isFeatured ? isFeatured : undefined
    params.isSearchAble = isSearchAble ? isSearchAble : undefined
    params.regExFilters = ['courseName', 'courseDuration', 'courseLevel', 'tuitionFee']
    let resp = await dispatch(exportCourseList(params))
    if (resp && resp.filePath) {
      window.open(resp.filePath, '_blank')
    } else {
      notification.warning({ message: 'Error, please try again' })
      return
    }
  }

  const updateCourseFeatureStatus = async (id) => {
    let resp = await dispatch(CourseIsFeatured({ _id: id }))
    if (resp && resp.success) {
      tableRef.current.reload()
    }
  }
  const updateTopCourseFxn = async (id) => {
    let resp = await dispatch(topCourseUpdateFxn({ _id: id }))
    if (resp && resp.success) {
      tableRef.current.reload()
    }
  }
  const updateCourseSearchableStatus = async (id) => {
    let resp = await dispatch(CourseIsSearchAble({ _id: id }))
    if (resp && resp.success) {
      tableRef.current.reload()
    }
  }
  const getParamsForFilter = () => {
    return new Promise((resolve) => {
      let searchParams = new URLSearchParams(window.location.search)
      let countryId = searchParams.get('countryId')
      let courseName = searchParams.get('courseName')
      let universityId = searchParams.get('universityId')
      let campusId = searchParams.get('campusId')
      let obj = {}
      if (countryId) {
        obj.countryId = countryId
      }
      if (courseName) {
        obj.courseName = courseName
      }
      if (universityId) {
        obj.universityId = universityId
      }
      if (campusId) {
        obj.campusId = campusId
      }
      resolve(obj)
    })
  }
  const events = {
    searchData: async () => {
      let obj = {}
      if (countryId) {
        obj.countryId = countryId
      }
      if (courseName) {
        obj.courseName = courseName
      }
      if (universityId) {
        obj.universityId = universityId
      }
      if (campusId) {
        obj.campusId = campusId
      }

      dispatch(
        getUrlPushWrapper('masters.allCourse', {
          ...obj
        }))
      setTableSearch(true)
      setTimeout(() => {
        tableRef.current.reload()
      }, 200)
    },
    setFieldByParams: async () => {
      let resp = await getParamsForFilter()
      if (resp.courseName) {
        setCourseName(resp.courseName)
      }
      if (resp.countryId) {
        setCountryId(resp.countryId)
      }
      if (resp.universityId) {
        setUniversityId(resp.universityId)
      }
      if (resp.campusId) {
        setCampusId(resp.campusId)
      }
    },
    goBack: () => {
      dispatch(getUrlPushWrapper('dashboard'))
    },
    clearFxn: () => {
      setUniversityId('')
      setCourseName('')
      setCountryId('')
      dispatch(
        getUrlPushWrapper('masters.allCourse'))
      setTableSearch(true)
      setTimeout(() => {
        tableRef.current.reload()
      }, 200)

    },
    showCourseStructure: (record) => {
      setCourseStructureObj({
        courseId: record._id,
        courseName: record.courseName,
        visible: true
      })
    },
    hideCourseStructure: () => {
      setCourseStructureObj({
        courseId: '',
        courseName: '',
        visible: false
      })
    }

  }

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (item, record, index) => {
        return (
          <a className={'linkPointer'} onClick={() => {
            dispatch(
              getUrlPushWrapper('masters.editCourse', {
                id: record._id
              }))
          }}>
            {(itemNo - 1) * 10 + index + 1}
          </a>
        )
      }

    },
    {
      title: 'Name',
      dataIndex: 'courseName',
      width: 100,
      key: 'courseName',
      // searchTextName: 'countryName',
      render: (item, record) => {
        return (
          <a className={'linkPointer'} onClick={() => {
            dispatch(
              getUrlPushWrapper('masters.editCourse', {
                id: record._id
              }))
          }}>
            {item}
          </a>
        )
      }

    },
    {
      title: 'University',
      dataIndex: 'courseUniversity',
      key: 'courseUniversity',
      width: 100,
      // filters: allUniversity.map(x => ({ value: x._id, text: x.universityName })),
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.universityName ? item.universityName : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Category',
      dataIndex: 'categoryId',
      key: 'categoryId',
      width: 100,
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.name ? item.name : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Country',
      dataIndex: 'universityCountry',
      key: 'universityCountry',
      width: 100,
      // filters: allCountry.map(x => ({ value: x._id, text: x.countryName })),
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.countryName ? item.countryName : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Campus',
      dataIndex: 'campusName',
      width: 100,
      key: 'campusName',
      render: (value, record) => {
        return (
          <React.Fragment>

            {value && value.length ? <ul className={'countList'}>
              {
                value.map((item, key) => {
                  return (
                    <li key={key}>{item.campusName}</li>
                  )
                })
              }
            </ul> : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Duration',
      key: 'courseDuration',
      // width: 150,
      width: 100,
      dataIndex: 'courseDuration',
      searchTextName: 'courseDuration'
    },
    {
      title: 'Level',
      key: 'courseLevel',
      // width: 150,
      width: 100,
      dataIndex: 'courseLevel',
      searchTextName: 'courseLevel'
    },
    {
      title: 'Tuition Fee',
      dataIndex: 'tuitionFee',
      // width: 100,
      width: 100,
      key: 'tuitionFee',
      searchTextName: 'fundAcceptable'
    },
    {
      title: 'Intakes',
      dataIndex: 'intakes',
      width: 100,
      key: 'intakes',
      filterMultiple: false,
      filters: Intakes.map(x => ({ value: x, text: x })),
      render: (value, record) => {
        return (
          <React.Fragment>
            {value && value.length ? value.map((item, key) => {
              return (
                <Tag key={key}>{item}</Tag>
              )
            }) : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Course Intakes',
      dataIndex: 'courseIntakes',
      width: 150,
      key: 'courseIntakes',
      filterMultiple: false,
      // filters: Intakes.map(x => ({ value: x, text: x })),
      render: (value, record) => {
        return (
          <React.Fragment>
            {value && value.length ? value.map((item, key) => {
              return (
                <Tag key={key}>{item.month}, {item.year}</Tag>
              )
            }) : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Waiver',
      key: 'waiver',
      width: 100,
      dataIndex: 'waiver',
      render: (item) => {
        return (
          <React.Fragment>
            {item ? 'Yes' : 'No'}
          </React.Fragment>
        )
      }
    },

    {
      title: 'Waiver Type',
      key: 'waiverType',
      width: 100,
      dataIndex: 'waiverType',
      render: (item, record) => {
        return (
          <React.Fragment>
            {record.waiverPercent ? <Tag className={'mb5'}>Percent : {record.waiverPercent}%</Tag> : ''}
            <div>
              {record.waiverYear && record.waiverYear.length ? record.waiverYear.map((val, key) => {
                return (
                  <Tag>{val}</Tag>
                )
              }) : null}
            </div>
          </React.Fragment>
        )
      }
    },
    /*  {
          title: 'Top Course',
          dataIndex: 'topCourse',
          width: 130,
          key: 'topCourse',
          filters: ConditionOptions.map(x => ({value: x.value, text: x.name})),
          render: (item, record) => {
              return (
                  <div>
                      <div>
                          <Popconfirm
                              title={`Are your sure, you want to ${item ? 'Remove from' : 'Add in'} top course?`}
                              onConfirm={() => {
                                  updateTopCourseFxn(record._id)
                              }}
                              okText='Yes' cancelText='No'>
                              {item ? <div className={'label label-success labelNew'}>Yes</div> :
                                  <div className={'label label-warning labelNew'}>No</div>}
                          </Popconfirm>
                      </div>
                  </div>
              )
          }
      },*/
    {
      title: 'Featured',
      dataIndex: 'isFeatured',
      width: 130,
      key: 'isFeatured',
      filters: ConditionOptions.map(x => ({ value: x.value, text: x.name })),
      render: (item, record) => {
        return (
          <div>
            <div>
              <Popconfirm
                title={`Are your sure, you want to ${item ? 'Non-Featured' : 'Featured'} this course?`}
                onConfirm={() => {
                  updateCourseFeatureStatus(record._id)
                }}
                okText='Yes' cancelText='No'>
                {item ? <div className={'label label-success labelNew'}>Featured</div> :
                  <div className={'label label-warning labelNew'}>Non-Featured</div>}
              </Popconfirm>
            </div>

            {/*<div style={{ marginTop: 10 }}>
              <div onClick={() => {
                updateCourseSearchableStatus(record._id)
              }}>
                {record && record.isSearchAble ? <div className={'label label-success labelNew'}>Searchable</div> :
                  <div className={'label label-warning labelNew'}>Non-Searchable</div>}
              </div>
            </div>*/}

          </div>
        )
      }
    },
    /*{
        title: 'Searchable',
        dataIndex: 'isSearchAble',
        width: 100,
        key: 'isSearchAble',
        filters: ConditionOptions.map(x => ({value: x.value, text: x.name})),
        render: (item, record) => {
            return (
                <div>
                    <div onClick={() => {
                        updateCourseSearchableStatus(record._id)
                    }}>
                        {item ? <div className={'label label-success labelNew'}>Yes</div> :
                            <div className={'label label-warning labelNew'}>No</div>}
                    </div>
                </div>
            )
        }
    },*/

    {
      key: 'actions',
      title: 'Actions',
      // fixed: 'right',
      width: 140,
      render: (text, record) => {
        return <div className={'pa-0'}>
          <Tooltip title='Edit'>
            <button
              className={'btn'}
              style={{ marginRight: 6 }}
              onClick={() => {
                dispatch(
                  getUrlPushWrapper('masters.editCourse', {
                    id: record._id
                  }))
              }}
            >
              <Icon type={'edit'} />
            </button>
          </Tooltip>

          <Tooltip title='Delete'>
            <Popconfirm title={'Are your sure, you want to delete course?'}
                        onConfirm={() => {
                          confirmDelete(record._id)
                        }}
                        okText='Yes' cancelText='No'>
              <button
                className={'btn'}
                style={{ marginRight: 6 }}>
                <Icon type={'delete'} />
              </button>
            </Popconfirm>
          </Tooltip>

          <CheckUserRight user={user} rightUserType={['admin']}>
            <Tooltip title={'View Application'}>
              <button className='btn' onClick={() => {
                dispatch(
                  getUrlPushWrapper('allApplicationsList', {
                    courseId: record._id
                  }))
              }}><img src='/dist/img/view.svg' alt='' className={'eyeBtn'} /></button>

            </Tooltip>
          </CheckUserRight>
          <Tooltip title={'Course Structure'}>
            <button className='btn' onClick={() => {
              events.showCourseStructure(record)
            }}><img src='/dist/img/structure.png' alt='' className={'eyeBtn'} /></button>

          </Tooltip>
        </div>

      }
    }
  ]


  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center'>
            <Button onClick={() => events.goBack()}
                    className={'roundGoBackBtn'} icon={'arrow-left'}>
              Back
            </Button>

            <h5>All Courses: {totalCourses || 0}</h5>

            <div className='sort-box-table mark-btn mark-btn40'>
              <div>
                <a className='btn' onClick={() => dispatch(push('/masters/add-course'))}>
                  <img src={'../dist/icons/plus.png'} className={'plus'} />
                  Add Course</a>
                {user && user.userType == 'userManager' ? null :
                  <a className='btn' onClick={() => exportCourseFxn()}> Export</a>}
              </div>
            </div>

          </div>

          <div className='card-body table-responsive'>
            {/*<Row gutter={16}>
              <Col span={6}>
                <div className="sort-box-table custom-sort-box-new">
                  <InputBox title={'Featured'} labelCls={'thinLabel'}>
                    <select placeholder={'Search by feature'} value={isFeatured}
                            onChange={(x) => setIsFeatured(x.target.value)}>
                      <option value={''}>Choose Featured</option>
                      {featureOptions.map((item, key) => {
                        return (
                          <option key={key} value={item.value}>{item.name}</option>
                        )
                      })}
                    </select>
                  </InputBox>
                </div>
              </Col>
              <Col span={6}>
                <div className="sort-box-table custom-sort-box-new">
                  <InputBox title={'Searchable'} labelCls={'thinLabel'}>
                    <select placeholder={'Search by searchable'} value={isSearchAble}
                            onChange={(x) => setIsSearchAble(x.target.value)}>
                      <option value={''}>Choose Searchable</option>

                      {searchOptions.map((item, key) => {
                        return (
                          <option key={key} value={item.value}>{item.name}</option>
                        )
                      })}
                    </select>
                  </InputBox>
                </div>
              </Col>
            </Row>*/}
            <Row gutter={16}>
              <Col span={4}>
                <div className='sort-box-table custom-sort-box-new'>
                  <Select placeholder={'Country'}
                          filterOption={filterOption}
                          className={'antSelect'}
                          allowClear={true}
                          showSearch={true}
                          onChange={(x) => setCountryId(x)}
                          valvalue={countryId || undefined}
                  >

                    {allCountry.map((item, key) => {
                      return (
                        <Option key={key} value={item._id}>{item.countryName}</Option>
                      )
                    })}
                  </Select>
                </div>
              </Col>
              <Col span={4}>
                <div className='sort-box-table custom-sort-box-new'>
                  <Select placeholder={'university'}
                          filterOption={filterOption}
                          className={'antSelect'}
                          allowClear={true}
                          showSearch={true}
                          value={universityId || undefined}
                          onChange={(x) => {
                            setUniversityId(x)
                            setCampusId('')
                            loadAllCampus()
                          }}>
                    {allUniversity && allUniversity.length ? allUniversity.map((item, key) => {
                      return (
                        <Option key={key} value={item._id}>{item.universityName}</Option>
                      )
                    }) : null}
                  </Select>
                </div>
              </Col>
              <Col span={4}>
                <div className='sort-box-table custom-sort-box-new'>
                  <Select placeholder={'Campus'}
                          value={campusId || undefined}
                          filterOption={filterOption}
                          className={'antSelect'}
                          allowClear={true}
                          showSearch={true}
                          onChange={(x) => setCampusId(x)}>
                    {allCampus && allCampus.length ? allCampus.map((item, key) => {
                      return (
                        <Option key={key} value={item._id}>{item.campusName}</Option>
                      )
                    }) : null}
                  </Select>
                </div>
              </Col>
              <Col span={5}>
                <div className='sort-box-table custom-sort-box-new'>
                  <div className='search-box-table round'>
                    <input className='form-control form-control' type='search'
                           placeholder='course name...' value={courseName} onChange={(e) => {
                      setCourseName(e.target.value)
                    }} />
                    <img src='/dist/img/search.png' alt='' />
                  </div>
                </div>
              </Col>

              <Col span={6}>
                <Button onClick={() => events.searchData()} className={'roundBtn'}>Search</Button>
                <Button onClick={() => events.clearFxn()} className={'roundBtn'}>
                  Clear
                </Button>
              </Col>

            </Row>
            <TableComp columns={columns}
                       apiRequest={apiRequest}
                       ref={tableRef}
                       extraProps={{ scroll: { x: 600 } }}
                       pagination={{
                         position: 'top',
                         pageSizeOptions: ['50', '100', '200', '500'],
                         defaultPageSize: 50,
                         current: filters.page ? filters.page : 1
                       }}

            />
          </div>
        </div>
      </div>

      {courseStructureObj.visible ?
        <CourseStructure
          {...courseStructureObj}
          onClose={() => {
            events.hideCourseStructure()
          }}
        />
        : null}

    </div>
  )
}

export default ListAllCourse
