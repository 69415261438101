import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { Avatar, Button, Col, Popconfirm, Row, Select, Tooltip } from 'antd'
import {
  agentCommissionReferToManagerFxn,
  allAgentCommissionListFxn,
  approvedAgentInvoiceFxn
} from '../../universityCommission/actions'
import {
  countryIds,
  displayDate,
  filterOption, InputBox, longDisplayDate
} from '../../../components/_utils/appUtils'
import { listAllCountries } from '../../countries/actions/countries'
import AgentCommStudentDrawer from '../../universityCommission/drawers/agentCommStudent'
import EditAgentInvoiceComponent from '../../universityCommission/RaiseAgentInvoice/EditAgentInvoiceDrawer'
import ApproveAgentCommission from '../drawers/approveAgentCommission'
import { listAllUsers } from '../../users/actions/user'

const { Option } = Select

let initListState = {
  countryList: [],
  agentList: []
}

let initFilter = {
  countryId: '',
  status: 'Pending',
  agentId: '',
  invoiceNo: ''
}
let agentCommissionInit = {
  visible: false,
  record: {}
}

let raiseInvoiceInit = {
  visible: false,
  record: {}
}
let statusList = [
  'Pending',
  'Approved'
]

const AllAgentCommissionListComponent = (props) => {
  let [totalRecord, setTotalRecord] = useState(0)
  const [filterState, setFilterState] = useState(initFilter)
  const [listState, setListState] = useState(initListState)
  const [agentList, setAgentList] = useState([])
  const [agentCommissionState, setAgentCommissionState] = useState(agentCommissionInit)
  const [raiseInvoiceState, setRaiseInvoiceState] = useState(raiseInvoiceInit)
  const [commissionObj, setCommissionObj] = useState({
    commission: {},
    visible: false
  })

  const tableRef = useRef()
  const dispatch = useDispatch()
  useEffect(() => {
    loadData.loadCountryList()
    loadData.loadAgentList()
  }, [])
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let resp = events.getParamsForApplicationList()
      params = { ...params, ...resp }
      params.transactionType = 'Credit'
      params.sortField = '_id'
      params.sortOrder = 'descend'
      params.countryId = { $ne: countryIds.canadaOnshore }
      let respData = await dispatch(allAgentCommissionListFxn({
        ...params, regExFilters: ['invoiceNo']
      }))
      setTotalRecord(respData.total)
      resolve(respData)
    })
  }
  const reload = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.reload()
    }
  }

  const events = {
    showRaiseInvoiceState: (record) => {
      setRaiseInvoiceState({
        visible: true,
        record: record
      })
    },
    hideRaiseInvoiceState: () => {
      setRaiseInvoiceState({
        visible: false,
        record: {}
      })
      reload()
    },
    updateFilterState: (data) => {
      setFilterState({
        ...filterState,
        ...data
      })
    },
    searchFxn: async () => {
      reload()
    },
    clearFxn: async () => {
      events.updateFilterState({
        countryId: '',
        status: '',
        agentId: '',
        invoiceNo: ''
      })
      setTimeout(() => {
        reload()
      }, 200)
    },
    showAgentCommissionDrawer: (record) => {
      record.agentCommissionId = record._id
      setAgentCommissionState({
        visible: true,
        record: record
      })
    },
    hideAgentCommissionDrawer: () => {
      setAgentCommissionState({
        visible: false,
        record: {}
      })
    },
    approvedAgentCommission: async (record) => {
      let resp = await dispatch(approvedAgentInvoiceFxn({ agentCommissionId: record._id }))
      if (resp && resp.success) {
        reload()
      }
    },
    commissionReferToManger: async (commissionId) => {
      let { data, success } = await dispatch(agentCommissionReferToManagerFxn({ commissionId }))
      if (success) {
        reload()
      }
    },
    getParamsForApplicationList: () => {
      let { agentId, status, countryId, invoiceNo } = filterState
      let obj = {}
      if (agentId) obj.agentId = agentId
      if (status) obj.invoiceStatus = status
      if (countryId) obj.countryId = countryId
      if (invoiceNo) obj.invoiceNo = invoiceNo
      return obj
    },
    showApproveDrawer: (data) => {
      setCommissionObj({
        commission: data,
        visible: true
      })
    },
    hideApproveDrawer: () => {
      setCommissionObj({
        commission: {},
        visible: false
      })
    }
  }

  const loadData = {
    updateList: (data) => {
      setListState((prevState) => {
        return {
          ...prevState,
          ...data
        }
      })
    },
    loadCountryList: async () => {
      let params = {
        results: 5000,
        select: ['countryName'],
        sortField: 'countryName',
        sortOrder: 'ascend'
      }
      let { data } = await dispatch(listAllCountries(params))
      loadData.updateList({ countryList: data })
    },
    loadAgentList: async () => {
      let obj = {
        userType: 'agent',
        results: 5000,
        count: 5000,
        select: ['name', 'companyName']
      }
      let { data } = await dispatch(listAllUsers(obj))
      setAgentList(data)
    }

  }

  const filterBlock = (
    <Row gutter={16} className={'mt10'}>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select
            name='Search By Country'
            onChange={item => {
              events.updateFilterState({ countryId: item })
            }}
            filterOption={filterOption}
            className={'antSelect'}
            placeholder='Country'
            allowClear={true}
            showSearch={true}
            value={filterState.countryId || undefined}>
            {listState.countryList && listState.countryList.length
              ? listState.countryList.map((item, key) => {
                return (
                  <Option value={item._id} key={key}>
                    {item.countryName}
                  </Option>
                )
              })
              : null}
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select
            name='Status'
            onChange={item => {
              events.updateFilterState({ status: item })
            }}
            filterOption={filterOption}
            className={'antSelect'}
            placeholder={'Status'}
            allowClear={true}
            showSearch={true}
            value={filterState.status || undefined}>
            {statusList && statusList.length
              ? statusList.map((item, key) => {
                return (
                  <Option value={item} key={key}>
                    {item}
                  </Option>
                )
              })
              : null}
          </Select>
        </div>
      </Col>

      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select
            name='Agent User'
            filterOption={filterOption}
            allowClear={true}
            className={'antSelect'}
            placeholder={'Agent'}
            showSearch={true}
            onChange={item => {
              events.updateFilterState({ agentId: item })
            }}
            value={filterState.agentId || undefined}>
            {agentList && agentList.length
              ? agentList.map((item, key) => {
                return (
                  <Option value={item._id} key={key}>
                    {item.companyName}
                  </Option>
                )
              })
              : null}
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='search-box-table round ml0'>
          <input
            className='form-control form-control'
            type='search'
            placeholder='Invoice No...'
            value={filterState.invoiceNo}
            onChange={e => {
              events.updateFilterState({ invoiceNo: e.target.value })
            }}
          />
          <img src='/dist/img/search.png' alt='' />
        </div>
      </Col>


      <Col md={16} sm={16} xs={16} lg={16}>
        <Button onClick={() => events.searchFxn()} className={'roundBtn'}>
          Search
        </Button>
        <Button onClick={() => events.clearFxn()} className={'roundBtn'}>
          Clear
        </Button>
      </Col>
    </Row>
  )

  const columns = [
    {
      title: 'Sr .no',
      key: '_id',
      dataIndex: '_id',
      width: 50,
      render: (item, record, index) => {
        return (
          <div style={{ width: 50 }}>
            {index + 1}
          </div>
        )
      }
    },
    {
      title: 'Date',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      width: 80,
      render: (item, record) => {
        return (
          <div style={{ width: 100 }}>
            {item ? displayDate(item) : ''}
          </div>
        )
      }
    },
    {
      title: 'Agent',
      key: 'agentId',
      dataIndex: 'agentId',
      width: 220,
      render: (item, record) => {
        return (
          <div style={{ width: 180 }}>
            {item && item.name ? item.name : null}
            {item && item.companyName ? `(${item.companyName})` : null}
          </div>
        )
      }
    },
    {
      title: 'Invoice No',
      key: 'invoiceNo',
      dataIndex: 'invoiceNo',
      width: 130,
      render: (item, record) => {
        return (
          <div style={{ width: 110 }}>
            {item}
          </div>
        )
      }
    },
    /* {
         title: 'Invoice Address',
         key: 'invoiceAddress',
         dataIndex: 'invoiceAddress',
         width: 130,
         render: (item, record) => {
             return (
                 <div style={{width: 110}}>
                     {item}
                 </div>
             )
         }
     },*/

    {
      title: 'Agent Share',
      dataIndex: 'totalCommission',
      key: 'totalCommission',
      width: 150,
      render: (item, record) => {
        let { totalApprovedByAdminCommission, countryId, bonus, totalCommissionBonus } = record
        return (
          <div style={{ width: 140 }}>
            {countryId && countryId.currency ? countryId.currency : ''}
            {totalApprovedByAdminCommission ? totalApprovedByAdminCommission : item} &nbsp;
            {countryId && countryId.currencyName ? countryId.currencyName : ''}
            {bonus && totalCommissionBonus ? <div>
              Bonus :
              {countryId && countryId.currency ? countryId.currency : ''}
              {totalCommissionBonus ? totalCommissionBonus : ''}&nbsp;
              {countryId && countryId.currencyName ? countryId.currencyName : ''}
            </div> : null}
          </div>
        )
      }
    },
    {
      title: 'Refer to Manager',
      dataIndex: 'referToManager',
      key: 'referToManager',
      width: 120,
      render: (item, record) => {
        let { invoiceStatus } = record
        return (
          <div className={'view-btn-box'}>
            {!item && invoiceStatus == 'Pending' ?
              <>
                <Tooltip title={'Refer to Manager'}>
                  <Popconfirm
                    title={'Are you sure, you want to refer to manager?'}
                    onConfirm={() => {
                      events.commissionReferToManger(record._id)
                    }}>
                    <a className='btn-view'>
                      Refer to Manager
                    </a>
                  </Popconfirm>
                </Tooltip>
              </>
              : null}

            {item == 'Pending' && <>
              <label className={'label label-warning'}>Approval Pending</label>
            </>}
            {item == 'Verified' && <>
              <label className={'label label-success'}>Verified</label>
            </>}

          </div>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'invoiceStatus',
      key: 'invoiceStatus',
      width: 100,
      render: (item, record) => {
        return (
          <div style={{ width: 100 }}>
            {item == 'Approved' ?
              <label className={'label label-success'}>Approved</label> :
              <label className={'label label-danger'}>Pending</label>}
          </div>
        )
      }
    },

    {
      title: 'Added By',
      key: 'userId',
      dataIndex: 'userId',
      width: 160,
      render: (item, record) => {
        return (
          <div style={{ width: 160 }}>
            <div className={'colorPrimary font12 textCap'}>
              {item && item.name ? item.name : null}
            </div>
            <div>{longDisplayDate(record.createdAt)}</div>
          </div>
        )
      }
    },

    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 160,
      render: (item, record) => {
        let { referToManager, invoiceStatus, transactionType } = record
        return (
          <div className={'view-btn-box'}>

            {transactionType == 'Credit' ? <>
              <Tooltip title={'View Student List'}>
                <a className='btn-view' onClick={() => {
                  events.showAgentCommissionDrawer(record)
                }}>
                  <img src='../uniBtn/eye.png' alt='' className={'eyeBtn'} /> View
                </a>
              </Tooltip>
            </> : ''}

            {(invoiceStatus == 'Pending' && transactionType == 'Credit') ?
              <>
                <a className='btn-view'
                   onClick={() => {
                     events.showRaiseInvoiceState(record)
                   }}><img src='/dist/img/edit.svg' alt='edit' style={{ height: 12 }} /> Edit
                </a>

                {referToManager == 'Verified' ?
                  <Tooltip title={'Pay Commission'}>
                    <a className='btn-view' onClick={() => events.showApproveDrawer(record)}>
                      <img src='../uniBtn/rupee.png' alt='' className={'eyeBtn'} /> Approved
                    </a>
                  </Tooltip> : null}

              </> : ''}


          </div>
        )
      }
    }

  ]
  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center'>
            <h5>All Agent Commission: {totalRecord || 0}</h5>

            <div className='sort-box-table mark-btn mark-btn40'>

            </div>
          </div>
          <div className='card-body table-responsive'>
            {filterBlock}
            <TableComp columns={columns}
                       apiRequest={apiRequest}
                       pagination={{
                         position: 'top',
                         pageSizeOptions: ['50', '100', '200', '500'],
                         defaultPageSize: 50
                       }}
                       ref={tableRef}
            />
          </div>
        </div>
      </div>
      {raiseInvoiceState.visible ?
        <EditAgentInvoiceComponent
          visible={raiseInvoiceState.visible}
          onClose={events.hideRaiseInvoiceState}
          onSubmit={reload}
          record={raiseInvoiceState.record}
        /> : null}

      {agentCommissionState.visible ?
        <AgentCommStudentDrawer
          visible={agentCommissionState.visible}
          onClose={events.hideAgentCommissionDrawer}
          onSubmit={reload}
          record={agentCommissionState.record}
        /> : null}
      {commissionObj.visible ?
        <ApproveAgentCommission
          onClose={events.hideApproveDrawer}
          events={events}
          onSubmit={() => {
            events.hideApproveDrawer()
            reload()
          }}
          {...commissionObj}
        /> : null}
    </div>
  )
}

export default AllAgentCommissionListComponent
