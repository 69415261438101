import React, { useState, useEffect, useRef } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Button,
  Card, Icon,
  Popconfirm,
  Tooltip
} from 'antd'
import _ from 'lodash'
import { connect, useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { notification } from 'antd'
import { getUrlPushWrapper } from '../../../routes'
// import { listAllUniversities } from '../actions/university';
import moment from 'moment'
import { listAllStates } from '../actions/state'
import { DefaultTablePagination } from '../../../components/_utils/appUtils'
import { push } from 'connected-react-router'
import { deleteState } from '../actions/state'
import { listAllCountries } from '../../countries/actions/countries'

const ListAllStates = (props) => {
  let [allCountries, setAllCountries] = useState([])
  const { totalStates } = useSelector((state) => ({
    totalStates: state.stateReducers.totalStates
  }))
  useEffect(() => {
    loadCountryList()
  }, [])
  const loadCountryList = async () => {
    let filters = {
      sortField: 'countryName', sortOrder: 'ascend'
    }
    let { data } = await dispatch(listAllCountries(filters))
    setAllCountries(data)
  }

  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.sortField = 'stateName'
      params.sortOrder = 'ascend'
      resolve(dispatch(listAllStates({ ...params, regExFilters: ['stateName'] })))
    })
  }
  const [itemNo, setItemNo] = useState(1)
  const confirmDelete = async (id) => {
    let resp = await dispatch(deleteState(id))
    if (resp && resp.success) {
      notification.warning({ message: resp.message })
      tableRef.current.reload()
    }
  }


  const columns = [

    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (item, record, index) => {
        return (
          <a className={'linkPointer'} onClick={() => {
            dispatch(
              getUrlPushWrapper('masters.editState', {
                id: record._id
              }))
          }}>
            {(itemNo - 1) * 10 + index + 1}
          </a>
        )
      }
    },
    {
      title: 'Name',
      dataIndex: 'stateName',
      width: 150,
      key: 'stateName',
      searchTextName: 'stateName',
      render: (item, record) => {
        return (
          <a className={'linkPointer'} onClick={() => {
            dispatch(
              getUrlPushWrapper('masters.editState', {
                id: record._id
              }))
          }}>
            {item}
          </a>
        )
      }

    },
    {
      title: 'Country',
      key: 'country',
      width: 150,
      dataIndex: 'country.countryName',
      filters: allCountries.map(x => ({ value: x._id, text: x.countryName }))
      // searchTextName: 'country',
    },

    {
      key: 'actions',
      title: 'Actions',
      // fixed: 'right',
      width: 80,
      render: (text, record) => {
        return (
          <div className={'pa-0'} style={{ width: 80 }}>
            <Tooltip title='Edit'>
              <button
                className={'btn'}
                style={{ marginRight: 6 }}
                onClick={() => {
                  dispatch(
                    getUrlPushWrapper('masters.editState', {
                      id: record._id
                    }))
                }}>
                <Icon type={'edit'} />
              </button>
            </Tooltip>
            <Tooltip title='Delete'>
              <Popconfirm title={'Are your sure, you want to delete city?'}
                          onConfirm={() => {
                            confirmDelete(record._id)
                          }}
                          okText='Yes' cancelText='No'>
                <button
                  className={'btn'}
                  style={{ marginRight: 6 }}>
                  <Icon type={'delete'} />
                </button>
              </Popconfirm>
            </Tooltip>
          </div>
        )

      }
    }
  ]


  return (
    <div className='row  mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center'>
            <h5>All States: {totalStates || 0}</h5>
            <div className='search-box-table'>

            </div>
            <div className='sort-box-table mark-btn'>
              <a className='btn' onClick={() => dispatch(push('/masters/add-state'))}>
                <img src={'../dist/icons/plus.png'} className={'plus'} />
                Add State</a>
            </div>
          </div>
          <div className='card-body table-responsive'>
            <TableComp columns={columns}
                       apiRequest={apiRequest}
                       pagination={DefaultTablePagination()}
                       ref={tableRef}
            />
          </div>
        </div>
      </div>
    </div>
  )
}


export default ListAllStates
