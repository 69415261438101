import React, {useState, useEffect} from "react"
import {Button, Col, Drawer, Form, Input, notification, Row, Card, Table} from "antd";
import {connect, useDispatch} from "react-redux";
import {
    addStudentCommissionFxn,
    getUniversityAndCourseFxn, universityCommissionListFxn,
    updateUniversityCommissionFxn
} from "../actions";
import GetEachFormFields from "../../../components/_utils/appFormUtils";
import {
    DefaultTablePagination,
    displayDate,
    fcmtUniversity,
    InvoiceMonthlyCycle
} from "../../../components/_utils/appUtils";
import moment from "moment";
import lodash from "lodash"
import {CustomRowTable} from "../../../components/_utils/RowTable";
import {loadCourseIntakesFxn} from "../../course/actions/courseAction";
import {
    getSingleSelectedCourse,
} from "../../applications/actions/chooseStudent";
import InputBox from "../../../components/_utils/InputBox";
import {TableComp} from 'sz-react-utils-lite'
import {getUrlPushWrapper} from "../../../routes";
import {CheckUserRight} from "../../dashboard/views/DashboardUserWise";

@Form.create()
class EditCommissionDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            universityDoc: {},
            courseDoc: {},
            currencyValue: "",
            form1disabled: false,
            currencyArr: [],
            intakeList: [],
            studentObj: {},

        }
        this.tableAppRef = React.createRef()
    }

    componentDidMount() {
        this.loadStudentData();
        this.getUniversityInfo();
        this.loadIntakes();
    }

    loadIntakes = async () => {
        let {studentData, dispatch} = this.props;
        let {applications} = studentData;
        let resp = await dispatch(loadCourseIntakesFxn({courseId: applications.courseId._id}))
        let intakeList = []
        lodash.each(resp, (item) => {
            if (item.intake) {
                intakeList.push({
                    value: `${item.intake}-${item.year}`,
                    name: `${item.intake}, ${item.year}`
                })
            }
        })
        this.setState({
            intakeList
        })
    }
    loadStudentData = async () => {
        let {studentData, dispatch, form: {setFieldsValue}} = this.props;
        let {applications} = studentData;
        let obj = {
            studentId: studentData._id,
            appId: applications._id,
        }
        let studentObj = await dispatch(getSingleSelectedCourse(obj))
        if (studentObj && studentObj._id) {
            let intakeValue = ''
            let {commissionDetails, tuitionFee, intake} = studentObj.applications;
            let obj = {
                courseStartDate: commissionDetails.courseStartDate ? moment(commissionDetails.courseStartDate) : undefined,
                commissionCurrency: commissionDetails.commissionCurrency ? commissionDetails.commissionCurrency : undefined,
                tuitionFee: tuitionFee,
                offerLetterStudentId: commissionDetails.offerLetterStudentId
            }
            if (intake && intake.month) {
                intakeValue = `${intake.month}-${intake.year}`
            }
            obj.intake = intakeValue;

            setTimeout(() => {
                setFieldsValue({...obj})
            }, 500)
        }

        this.setState({
            studentObj
        })
    }
    getUniversityInfo = async () => {
        let {studentData, dispatch} = this.props;
        let {applications} = studentData;
        let obj = {
            universityId: applications.courseUniversity._id,
            courseId: applications.courseId._id,
        }
        let resp = await dispatch(getUniversityAndCourseFxn(obj))
        let {success, course, university} = resp;
        if (success) {
            let {commissionDetails, universityCountry} = university;
            let currencyArr = ['INR']
            if (universityCountry && universityCountry.currencyName) {
                currencyArr.push(universityCountry.currencyName)
            }
            let objB = {
                courseDoc: course,
                universityDoc: university,
                currency: university.universityCountry,
                currencyArr: currencyArr
            }
            this.setState({...objB})
        }
    }


    handleSubmitCommissionDetails = (e) => {
        const {form, studentData, dispatch, onSubmit} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                valData.studentId = studentData._id
                valData.applicationId = studentData.applications._id;
                let intakeVal = valData.intake.split('-')
                let intake = {
                    month: intakeVal[0],
                    year: intakeVal[1]
                }
                valData.intake = intake;
                let resp = await dispatch(updateUniversityCommissionFxn(valData));
                if (resp && resp.success) {
                    onSubmit()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }


    render() {
        let {onClose, visible, form: {getFieldDecorator, setFieldsValue}} = this.props;
        let {
            form1disabled,
            currencyArr,
            universityDoc,
            courseDoc,
            intakeList,
            studentObj,
        } = this.state;
        let inputTypes = {
            fields: [
                {
                    key: 'courseStartDate',
                    label: 'Course Start Date',
                    placeholder: 'Course Start Date',
                    type: 'date',
                    required: true,
                    disabled: form1disabled
                },
                {
                    required: true,
                    type: 'select',
                    options: currencyArr,
                    name: 'Commission Currency',
                    label: 'Commission Currency',
                    placeholder: 'Commission Currency',
                    key: 'commissionCurrency',
                    disabled: form1disabled,
                    onChange: (e) => {
                        setFieldsValue({commissionCurrency: e})
                    }
                },
                {
                    key: 'tuitionFee',
                    label: 'Tuition Fee',
                    placeholder: 'Tuition Fee',
                    type: 'number',
                    required: true,
                    disabled: form1disabled
                },
                {
                    key: 'offerLetterStudentId',
                    label: 'Student ID',
                    placeholder: 'Student ID',
                    required: true,
                    disabled: form1disabled
                },
                {
                    required: true,
                    type: 'select',
                    options: intakeList,
                    name: 'Intake',
                    label: 'Intake',
                    placeholder: 'Intake',
                    keyAccessor: x => x.value,
                    valueAccessor: x => `${x.name}`,
                    key: 'intake',
                    onChange: (e) => {
                        setFieldsValue({intake: e})
                    }
                },
            ]
        }


        const formItemLayout = {
            labelCol: {
                xs: {span: 0},
                sm: {span: 0},
                md: {span: 0}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            }
        }
        return (
            <>
                <Drawer
                    visible={visible}
                    onClose={onClose}
                    title={'Updates Commission'}
                    width={'50%'}>
                    <div className="form-box commissionBox">
                        <div className="card unizportal">
                            {studentObj && studentObj._id ? <>
                                {universityDoc && universityDoc.universityName ?
                                    <Card className={'commissionUniInfo'}>
                                        <Row gutter={24}>
                                            {universityDoc && universityDoc.logo && universityDoc.logo.url ?
                                                <Col span={8} className={'logoBox'}>
                                                    <div className={'uniInfoLogo'}>
                                                        <img src={universityDoc.logo.url}/>
                                                    </div>
                                                </Col> : null}
                                            <Col span={16} className={'contantBox'}>
                                                <div className={'uniName'}>{universityDoc.universityName}</div>
                                                <div className={'courseInfo'}>
                                                    {courseDoc.courseName}
                                                </div>
                                                <div className={'commissionInfo'}>
                                                    (Commission :&nbsp;
                                                    {universityDoc.universityCommissionType == 'Amount' ?
                                                        <>{universityDoc.commissionCurrency} {universityDoc.universityCommission}</> :
                                                        <>
                                                            {universityDoc.universityCommission}%
                                                        </>
                                                    }
                                                    <span> | {universityDoc.noOfUniversityCommission} Times</span>)
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                    : null}

                                <Card className={'mt-4'}>
                                    <Form onSubmit={this.handleSubmitCommissionDetails}>
                                        <Row gutter={16} className={'wrapBox'}>
                                            {inputTypes.fields.map((item, key) => {
                                                return (
                                                    <React.Fragment key={key}>
                                                        <Col span={12} key={key}>
                                                            <GetEachFormFields
                                                                item={item}
                                                                getFieldDecorator={getFieldDecorator}
                                                                formItemLayout={formItemLayout}/>
                                                        </Col>
                                                    </React.Fragment>
                                                )
                                            })}
                                            <Col span={24}>
                                                <Form.Item>
                                                    <Button
                                                        type="primary" htmlType="submit" className={'btn mt40'}>
                                                        Update Details
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </Form>
                                </Card>

                            </> : null}

                        </div>
                    </div>
                </Drawer>
            </>
        )
    }
}

const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditCommissionDetails)
