import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Input, notification, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import {singleAgentMarginFxn, singleReeudoAgentFxn, updateAgentMarginFxn, updateExchangeMarginFxn} from "../actions";
import { InputBox, numberToWords } from "../../../components/_utils/appUtils";
import moment from "moment";

const AgentMarginModal = (props) => {
    const { agentId, visible, onClose } = props;
    const [agentMargin, setAgentMargin] = useState(0.00);
    const [agent, setAgent] = useState({})
    const dispatch = useDispatch();


    const getSingleAgentFxn = async () => {
        let resp = await dispatch(singleReeudoAgentFxn({userId: agentId}))
        setAgent(resp)
    }
    const singleAgent =  async () => {
        let data = await dispatch(singleAgentMarginFxn({userId: agentId}));
        if (data && data.agentMargin) {
            setAgentMargin(data.agentMargin);
        } else {
            setAgentMargin(0.00);
        }

    }
    const handleUpdate = async () => {
        if (!agentMargin) {
            notification.error({ message: 'Enter forex margin.' });
            return;
        }

        const payload = {
            userId: agentId,
            agentMargin,
        };

        const resp = await dispatch(updateAgentMarginFxn(payload));
        if (resp.success) {
            notification.success({
                message: 'Agent Margin Updated'
            });
            onClose()
        }
    };


    useEffect(() => {
        if (visible) {
            getSingleAgentFxn()
            singleAgent()
        }
    }, [agentId, visible])
    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            <div className="row">
                <div className="col-lg-12">
                    <Form>
                        <div className="form-box mt-4">
                            <div className="d-flex align-items-center mb-3">
                                <p>Agent : <strong>{agent.companyName}({agent.name})</strong></p>
                            </div>

                            <div className="card unizportal">
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <InputBox title="Agent Margin">
                                            <Input
                                                type="text"
                                                value={agentMargin}
                                                className="form-control"
                                                placeholder="Enter agent margin"
                                                onChange={(e) => setAgentMargin(e.target.value)}
                                            />
                                        </InputBox>
                                        <div className="margin-in-words">
                                            <p><b>Amount In Words:</b> {numberToWords(parseFloat(agentMargin))}</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="btn"
                                            onClick={handleUpdate}
                                    >
                                        Update
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Modal>
    );
};

export default AgentMarginModal;
