import React, {useState} from 'react'


import AdvancePayDrawer from './AdvancePayDrawer'
import CommissionDrawer from './commissionDrawer'
import UploadReceiptDrawer from './UploadReceiptDrawer'
import UploadEcoeDrawer from './UploadEcoeDrawer'
import UploadOfferDrawer from './uploadOfferDrawer'
import {Icon, Popconfirm, Tooltip} from 'antd'


const StatusComponent = (props) => {
    const {appStatus, visible, onClose, studentData, studentId, applicationId, reloadTable} = props
    // const [visible, setVisible] = useState(false)


    const showDrawerStatusWise = (status) => {

        switch (status) {
            // case 'Waiting To Apply':
            //   return <UploadOfferDrawer
            //     reloadTable={reloadTable}
            //     applicationId={studentData.applications._id}
            //     studentId={studentData._id}
            //     studentData={studentData}
            //     visible={visible} onClose={onClose} />

            // case 'Offer Letter':
            //   return <AdvancePayDrawer reloadTable={reloadTable}
            //                            applicationId={studentData.applications._id}
            //                            studentId={studentData._id}
            //                            studentData={studentData}
            //                            visible={visible} onClose={onClose} />

            case 'Offer Received':
                return <UploadReceiptDrawer reloadTable={reloadTable}
                                            applicationId={studentData.applications._id}
                                            studentId={studentData._id}
                                            studentData={studentData}
                                            visible={visible} onClose={onClose}/>

            case 'Fee Payment / Receipt':
                return <UploadEcoeDrawer reloadTable={reloadTable}
                                         applicationId={studentData.applications._id}
                                         studentId={studentData._id}
                                         studentData={studentData}
                                         visible={visible} onClose={onClose}/>
            /* case 'ECOE Uploaded':
               return <CommissionDrawer reloadTable={reloadTable}
                                        applicationId={studentData.applications._id}
                                        studentId={studentData._id}
                                        studentData={studentData}
                                        visible={visible} onClose={onClose} />*/

        }

    }

    return (
        <div>
            <Tooltip title='Move Application'>
                {/*<button*/}
                {/*  className={'btn'}*/}
                {/*  onClick={() => {*/}
                {/*    setVisible(true)*/}
                {/*  }}>move*/}
                {/*  <Icon type={'move'} />*/}
                {/*</button>*/}
                {visible && showDrawerStatusWise(appStatus)}
            </Tooltip>


        </div>
    )
}

export default StatusComponent
