import React, { useEffect, useRef, useState } from 'react'
import { Avatar, Button, Col, Drawer, Icon, Input, List, Modal, Row, Typography } from 'antd'
import { DefaultTablePagination, displayDate, nameAvatar, taskUpdateRights } from '../../../components/_utils/appUtils'
import { singleTask, taskActivityList, updateTask, updateTaskFxn } from '../actions/taskManager'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { addTaskComment, handleStatusChange, taskCommentList } from '../actions/taskManager'
import { displayTime } from '../../../components/_utils/appUtils'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'

let { TextArea } = Input

const SingleComment = (props) => {
  let { comments } = props
  return (
    <>
      <div className='comment' key={comments._id}>
        <Avatar className={'textUpp avatar_text'} size='large'>
          {nameAvatar(comments.createdByUser.name)}
        </Avatar>

        <div className='comment-text'>
          <p className='comment-author'>
            <span className={'name'}>{comments.createdByUser.name}</span>
            <span className={'date'}>{displayDate(comments.date)}</span>
          </p>
          <div className={'mb5'}>
            {comments.comment}
          </div>
          <div className={'time-span'}>
            {displayTime(comments.createdByUser.time)}
          </div>
        </div>

      </div>
    </>
  )
}

const ComponentListDrawer = (props) => {
  let { data } = props
  return (
    <>
      <div id={'dd'} ref={props.ref}>
        {data && data.length ? data.map((comments, index) => {
          return (
            <SingleComment comments={comments} />
          )
        }) : null}
      </div>
    </>
  )
}


const CommentDrawer = (props) => {
  let dispatch = useDispatch()
  let actTableRef = useRef()
  let { onClose, visible, currentUser, taskId, onSubmit } = props
  let [taskDetails, setTaskDetails] = useState({})
  let [state, setState] = useState({
    _id: '',
    text: '',
    deadline: null,
    date: null,
    priority: '',
    status: ''
  })
  let [recordings, setRecordings] = useState({
    audioRecord: {},
    screenRecord: {},
    addedByUserId: ''
  })
  let [isStatusModalVisible, setIsStatusModalVisible] = useState(false)
  let [priority, setPriority] = useState('')
  let [commentText, setCommentText] = useState('')
  let [activeTab, setActiveTab] = useState('comments')

  let [comments, setComments] = useState('')
  let [priorityVisible, setPriorityVisible] = useState(false)
  let [commentState, setCommentState] = useState({
    data: [],
    total: 0,
    page: 1,
    count: 50
  })


  useEffect(() => {
    if (taskId) {
      events.loadTask()
    }
    events.loadComments()
  }, [])

  let events = {
    handleStatusSelection: async (status) => {
      const result = await handleStatusChange(taskDetails._id, status)
      if (result) {
        setTaskDetails(prevState => ({
          ...prevState,
          status: status
        }))

      }
      setIsStatusModalVisible(false)
    },
    handleOpenPriorityModal: (taskId = '') => {
      setPriorityVisible(true)
    },
    handleClosePriorityModal: () => {
      setPriorityVisible(false)
    },
    reloadComment: () => {
      events.loadComments()
    },
    handleCommentSave: async () => {
      let resp = await dispatch(addTaskComment({ comment: comments, taskId: taskId }))
      if (resp && resp.success) {
        setComments('')
      }
      events.reloadComment()
    },
    handleUpdateTask: async () => {
      let obj = _.clone(state)
      delete obj['_id']
      const result = await dispatch(updateTaskFxn({ ...obj, taskId }))
      onSubmit()
    },
    loadTask: async () => {
      let { data, success } = await dispatch(singleTask({ taskId }))
      if (success) {
        setState({
          _id: data._id,
          text: data.text,
          deadline: data.deadline ? moment(data.deadline) : null,
          date: data.date ? moment(data.date) : null,
          priority: data.priority,
          status: data.status
        })
        setRecordings({
          audioRecord: data.audioRecord,
          screenRecord: data.screenRecord,
          addedByUserId: data.addedByUserId
        })
      }
    },
    loadComments: async () => {
      let params = {}
      params.results = commentState.count
      params.count = commentState.count
      params.page = commentState.page
      params.taskId = taskId
      const resp = await dispatch(taskCommentList(params))
      setCommentState((prevData) => {
        return {
          ...prevData,
          data: resp.data,
          total: resp.total
        }
      })
    },
    _updateState: (data) => {
      setState((prevState) => {
        return {
          ...prevState,
          ...data
        }
      })
    },
    apiRequest: (params) => {
      return new Promise(async (resolve) => {
        params.taskId = taskId
        let { data } = await dispatch(
          taskActivityList({
            ...params,
            regExFilters: ['name', 'companyId', 'count']
          })
        )
        resolve(data)
      })
    },
    handleSelectPriority: async (priority) => {
      events.handleClosePriorityModal()
      const result = await dispatch(updateTask(taskId, { priority }))
      if (!result.error) {
        events._updateState({ priority })
      }
    }
  }
  const priorityOptions = [
    {
      key: 'Urgent',
      label: 'Urgent',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: '#b13a41', fontSize: '16px' }} />
    },
    {
      key: 'High',
      label: 'High',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: '#eca500', fontSize: '16px' }} />
    },
    {
      key: 'Normal',
      label: 'Normal',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: '#4466ff', fontSize: '16px' }} />
    },
    {
      key: 'Low',
      label: 'Low',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: 'gray', fontSize: '16px' }} />
    },
    { key: 'Clear', label: 'Clear', icon: <Icon type={'stop'} style={{ color: 'gray', fontSize: '16px' }} /> }
  ]

  const getPriorityColor = (priority) => {
    const priorityOption = priorityOptions.find((option) => option.key === priority)
    return priorityOption ? priorityOption.icon.props.style.color : ''
  }

  let columns = [
    {
      key: '#',
      dataIndex: '#',
      title: '#',
      render: (item, record, index) => {
        return index + 1
      }
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Date',
      render: (item) => {
        return item ? displayDate(item) : ''
      }
    },
    {
      key: 'createdByUser',
      dataIndex: 'createdByUser',
      title: 'Created By',
      render: (item) => {
        return item && item.name ? item.name : ''
      }
    },
    {
      key: 'title',
      dataIndex: 'title',
      title: 'Title'
    },
    {
      key: 'message',
      dataIndex: 'message',
      title: 'Message'
    }
  ]

  return (
    <Drawer
      title='Task Details'
      placement='right'
      closable={true}
      onClose={onClose}
      visible={visible}
      width='80%'
      style={{ transition: 'all 0.3s' }}
    >
      <div style={{ backgroundColor: '#EEF2F4', padding: '10px' }}>
        {state._id ?
          <div className='contents'>
            <div className='left-side' style={{
              backgroundColor: '#FFFFFF',
              padding: '20px',
              borderRadius: '10px',
              minHeight: '200px'

            }}>
              <div className='left-row jcsb' style={{ marginBottom: '10px' }}>
                <div>
                  <Typography.Text
                    style={{ color: 'white', fontSize: '14px', cursor: 'pointer' }}
                    onClick={() => {
                      if (taskUpdateRights(currentUser)) {
                        setIsStatusModalVisible(true)
                      }
                    }}>
                    Status - {state.status}
                  </Typography.Text>
                </div>
                <div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                    <Typography.Text style={{
                      color: 'white',
                      fontSize: '14px',
                      marginRight: '5px'
                    }}>Priority - {state.priority}</Typography.Text>
                    <Icon type='flag' theme={'filled'} style={{ color: getPriorityColor(state.priority) }}
                          onClick={() => {
                            if (taskUpdateRights(currentUser)) {
                              events.handleOpenPriorityModal()
                            }
                          }}
                    />
                  </div>
                </div>
              </div>
              <div className={'mt10 mb20'}>
                {taskUpdateRights(currentUser) ? <div>
                  <TextArea
                    style={{ minHeight: 140 }}
                    className='task-text'
                    value={state.text}
                    onChange={({ target }) => {
                      events._updateState({ text: target.value })
                    }}
                  />

                  <hr />
                  <div>
                    <Button className={'btn btn-success'}
                            onClick={() => events.handleUpdateTask()}>
                      <Icon type='save' /> Update
                    </Button>

                  </div>

                </div> : <div className={'mt10 ml10'}>
                  {state.text}
                </div>}

              </div>
            </div>

            <div className='right-side'>
              <div style={{
                backgroundColor: '#56D1E0',
                padding: '10px',
                borderRadius: '5px',
                margin: '10px',
                marginBottom: '20px'
              }}>

                <Typography.Text style={{
                  color: 'white',
                  fontSize: '12px',
                  marginBottom: '5px'
                }}>Created At - {state.date ? displayDate(state.date) : null}</Typography.Text>
              </div>
              <div className='right-text'>
                {state.deadline ?
                  <>
                    <p>Deadline: <span
                      style={{ float: 'right', color: 'black' }}> {displayDate(state.deadline)}</span></p>
                    <hr />

                  </>
                  : null}
                {recordings.addedByUserId && recordings.addedByUserId.name ?
                  <>
                    <p>Added By: <span
                      style={{ float: 'right', color: 'black' }}>{recordings.addedByUserId.name}</span></p>
                    <hr />

                  </>
                  : null}
              </div>

              <div className='recordings' style={{ backgroundColor: 'white', margin: '0 18px' }}>
                <Row gutter={16}>

                  {recordings && recordings.audioRecord && recordings.audioRecord.url ?
                    <Col span={12}>
                      <div style={{
                        border: '1px solid gray',
                        padding: '10px',
                        borderRadius: '8px'
                      }}>
                        <Icon type='audio' theme='filled'
                              style={{ color: '#1890ff', marginRight: '8px', fontSize: '17px' }} />
                        <span>
                      {(recordings.audioRecord.url.split('/').pop().slice(0, 9) + (recordings.audioRecord.url.split('/').pop().length > 9 ? '...' : ''))}
                  </span>
                        <Icon type='play-circle'
                              style={{ fontSize: '16px', float: 'right', color: 'green', paddingTop: '5px' }} />
                      </div>

                    </Col> : null}

                  {recordings && recordings.screenRecord && recordings.screenRecord.url ?
                    <Col span={12}>
                      <div style={{
                        border: '1px solid gray',
                        padding: '10px',
                        borderRadius: '8px'
                      }}>
                        <Icon type='video-camera' theme='filled'
                              style={{ color: '#FF5733', marginRight: '8px', fontSize: '17px' }} />
                        <span>
                      {(recordings.screenRecord.url.split('/').pop().slice(0, 9) + (recordings.screenRecord.url.split('/').pop().length > 9 ? '...' : ''))}
                  </span>
                        <Icon type='play-circle'
                              style={{ fontSize: '16px', float: 'right', color: 'blue', paddingTop: '5px' }} />
                      </div>

                    </Col> : null}
                </Row>


              </div>


            </div>
          </div> : null}

        <div className='comment-section'>
          <div className='tab-container'>
            <div className='custom-tabs'>
              <div className='tab'>
                <button
                  className={`tab-menu ${activeTab === 'comments' ? 'active' : ''}`}
                  onClick={() => setActiveTab('comments')}>
                  Comments
                </button>
                <button className={`tab-menu ${activeTab === 'history' ? 'active' : ''}`}
                        onClick={() => setActiveTab('history')}
                        style={{ marginLeft: '1px' }}>
                  History
                </button>
              </div>
            </div>
          </div>
          <div className='comments-box' style={{ display: activeTab === 'comments' ? 'block' : 'none' }}>
            {commentState.data && commentState.data.length ? <ComponentListDrawer data={commentState.data} /> : null}
          </div>
          <div style={{
            display: activeTab === 'comments' ? 'flex' : 'none'
          }} className={'activityTab'}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar className={'textUpp avatar_text'} size='large'>
                {nameAvatar(currentUser.name)}
              </Avatar>
              <Input
                placeholder='Add Comment' className='input-comment'
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              />

              <Button
                className={'sendBtn'}
                onClick={() => {
                  events.handleCommentSave()
                }}>Send</Button>
            </div>

          </div>


          <div style={{ display: activeTab === 'history' ? 'block' : 'none' }} className='history-box'>
            <div>
              <div style={{ marginBottom: '20px', boxShadow: ' 0 4px 8px rgba(0, 0, 0, 0.2)', borderRadius: '5px' }}>

                <TableComp
                  columns={columns}
                  apiRequest={events.apiRequest}
                  pagination={DefaultTablePagination()}
                  ref={actTableRef}
                />

              </div>
            </div>
          </div>


          <hr />
        </div>
      </div>


      {isStatusModalVisible ? <Modal
        title='Select Status'
        visible={isStatusModalVisible}
        onCancel={() => setIsStatusModalVisible(false)}
        footer={null}
        style={{ display: 'flex', flexDirection: 'column' }}
        width={200}>
        <Button onClick={() => events.handleStatusSelection('pending')}
                style={{ marginBottom: '4px', width: '100%' }}>
          <Icon type='check' style={{ color: 'gray', fontSize: '13px', float: 'right' }} />
          Pending</Button>
        <Button onClick={() => events.handleStatusSelection('inprogress')}
                style={{ marginBottom: '4px', width: '100%' }}>
          <Icon type='check' style={{ color: 'red', fontSize: '13px', float: 'right' }} />In
          Progress</Button>
        <Button onClick={() => events.handleStatusSelection('in-review')}
                style={{ marginBottom: '4px', width: '100%' }}>
          <Icon type='check' style={{ color: 'blue', fontSize: '13px', float: 'right' }} />
          In Review</Button>
        <Button onClick={() => events.handleStatusSelection('complete')}
                style={{ marginBottom: '4px', width: '100%' }}>
          <Icon type='check' style={{ color: 'green', fontSize: '13px', float: 'right' }} />
          Complete</Button>
      </Modal> : null}

      {priorityVisible ? <Modal
        title='Priority'
        visible={priorityVisible}
        onCancel={events.handleClosePriorityModal}
        footer={null}
        width={200}>
        <List dataSource={priorityOptions}
              renderItem={(item, index) => (
                <List.Item
                  onClick={() => events.handleSelectPriority(item.key)}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 0',
                    borderBottom: index === 3 ? '1px solid #d9d9d9' : 'none',
                    backgroundColor: priority === item.key ? '#e6f7ff' : 'inherit'
                  }}>
                  {item.icon && <span style={{ marginRight: '8px' }}>{item.icon}</span>}
                  <div style={{
                    textAlign: 'center',
                    fontSize: '14px',
                    color: '#000'
                  }}>{item.label}</div>
                </List.Item>
              )}
        />

      </Modal> : null}


    </Drawer>
  )
}
export default CommentDrawer
