import React from 'react'
import { Drawer, Form } from 'antd'
import { connect } from 'react-redux'
import { getEvaluateFeaturedCourse, getEvaluateOtherUniversity } from './action'
import FeaturedCourseDrawer from './featuredCourse'
import { SingleUniBlock } from './singleBlocks'

class OtherUniversities extends React.Component {
  events = {
    showFeaturedDrawer: (item) => {
      this.setState({
        visibleFeaturedCourse: true,
        selectedUniversity: item
      })
    },
    hideFeaturedDrawer: () => {
      this.setState({
        visibleFeaturedCourse: false,
        selectedUniversity: {}
      })
    }
  }

  constructor() {
    super()
    this.state = {
      universityList: []
    }
  }

  componentDidMount() {
    this.loadOtherUniversity()
  }

  loadOtherUniversity = async () => {
    let { dispatch, selectedCountry: { countryId } } = this.props
    let data = await dispatch(getEvaluateOtherUniversity({ countryId }))
    this.setState({ universityList: data })
  }

  render() {
    let { visible, onClose, selectedCountry } = this.props
    let { universityList, visibleFeaturedCourse, selectedUniversity } = this.state
    let { events } = this
    return (
      <Drawer
        visible={visible}
        onClose={onClose}
        width={'60%'}>
        <div className="evaluate-section">
          <div className="academy-box featureUniversityDiv">
            <div className="heading-form d-flex align-items-center">
              <h5>Educators in {selectedCountry.countryName}</h5>
            </div>
            <div className="card cardRelative">
              <div className="row uniBox courseBox">
                {universityList && universityList.length ? universityList.map((item, key) => {
                  return (
                    <SingleUniBlock key={key} item={item} callBack={() => {
                      events.showFeaturedDrawer(item)
                    }}/>
                  )
                }) : null}

              </div>
            </div>
          </div>
        </div>
        {visibleFeaturedCourse ?
          <FeaturedCourseDrawer onClose={events.hideFeaturedDrawer} visible={visibleFeaturedCourse}
                                featured={true}
                                item={selectedUniversity}/>
          : null}
      </Drawer>
    )
  }
}


const WrappedEvaluateIndex = Form.create()(OtherUniversities)

const mapStateToProps = ({ global, router }) => ({
  pageLoading: global.pageLoading,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedEvaluateIndex)
