import React, {useState} from "react"
import {Button, Drawer, notification} from "antd";
import InputBox from "../../../components/_utils/InputBox";
import {useDispatch} from "react-redux";
import {addCallInWithdrawalFxn} from "../actions";

let verifiedOptions = ['Verified', "Not Verified"]

let initState = {
    note: "",
    callRecording: "",
    verified: ""
}
const AddCallDrawer = (props) => {
    let dispatch = useDispatch()
    let [state, setState] = useState(initState);
    let {visible, onClose, withdrawalId} = props;

    let events = {
        updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        submitAddCall: async () => {
            let {note, verified, callRecording} = state;
            if (!note) {
                notification.warning({message: "Please enter note."})
                return
            }
            if (!verified) {
                notification.warning({message: "Please choose verified status."})
                return
            }
            let fd = new FormData()
            let obj = {note, verified, withdrawalId}
            fd.append('obj', JSON.stringify(obj))
            if (callRecording && callRecording.name) {
                fd.append('callRecording', callRecording)
            }
            let {data, success} = await dispatch(addCallInWithdrawalFxn(fd))
            if (success) {
                events.updateState(initState);
                onClose()
            }
        }
    }


    return (
        <Drawer
            visible={visible}
            title={`Add Call`}
            onClose={onClose}
            width={'50%'}
        >
            <div className="form-box commissionBox">
                <div className="card unizportal">
                    <InputBox title={'Note'}>
                        <textarea
                            className={'form-control'}
                            placeholder={'Enter note'}
                            onChange={({target}) => {
                                events.updateState({note: target.value})
                            }}
                            value={state.note}>
                        </textarea>
                    </InputBox>
                    <InputBox title={'Call Recording'}>
                        <input
                            className={'form-control'}
                            type={'file'}
                            onChange={({target}) => {
                                if (target.files && target.files.length) {
                                    events.updateState({callRecording: target.files[0]})
                                } else {
                                    events.updateState({callRecording: ""})
                                }
                            }}/>
                    </InputBox>
                    <InputBox title={'Verified Status'}>
                        <select className={'form-control'} value={state.verified} onChange={({target}) => {
                            events.updateState({verified: target.value})
                        }}>
                            <option value={''}>Choose Verified Status</option>
                            {verifiedOptions.map((item) => {
                                return (
                                    <option value={item} key={item}>{item}</option>
                                )
                            })}
                        </select>
                    </InputBox>
                    <Button
                        onClick={events.submitAddCall}
                        type="primary"
                        className={`btn btn-default mt10`}>
                        Submit Add Call
                    </Button>
                </div>
            </div>
        </Drawer>
    )
}
export default AddCallDrawer
