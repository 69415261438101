import {notification} from 'antd'
import {hidePageLoad, showPageLoad} from '../../../modules/actions'
import {customAxios as axios, getToken} from '../../../request'
import {addAgentReportUrl, listAgentReportsUrl} from '../api/agentReport'

export const addAgentReport = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addAgentReportUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
export const listAgentReports = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...await getToken()
    }
    let {data} = await axios.get(listAgentReportsUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}
