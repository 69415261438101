import React, { useState, useEffect, useRef } from 'react'
import {
  Tabs
} from 'antd'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { getUrlPushWrapper } from '../../../routes'
import { DefaultTablePagination } from '../../../components/_utils/appUtils'
import { agentCommissionCount } from '../actions'
import CommissionDrawer from './commissionDrawer'
import PayCommission from './payCommission'

const { TabPane } = Tabs

const AllCommissions = () => {
  let [user, setUser] = useState({})
  let [allCommission, setAllCommission] = useState([])
  const [itemNo, setItemNo] = useState(1)
  const [visible, setVisible] = useState(false)
  const [visiblePayCommission, setVisiblePayCommission] = useState(false)
  const [agentId, setAgentId] = useState('')
  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let resp = await dispatch(agentCommissionCount({ ...params, regExFilters: [] }))
      resolve(resp)
    })
  }


  useEffect(() => {
    loadUser()
  }, [])

  const loadUser = async () => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    setUser(user)
  }

  const events = {
    closeDrawer: () => {
      setAgentId('')
      setVisible(false)
    },
    showDrawer: (agentId) => {
      setAgentId(agentId)
      setVisible(true)
    },
    showPayDrawer: (agentId) => {
      setAgentId(agentId)
      setVisiblePayCommission(true)
    },
    closePayDrawer: () => {
      setAgentId('')
      setVisiblePayCommission(false)
    },
    closeAndRefreshPayDrawer: () => {
      setAgentId('')
      setVisiblePayCommission(false)
      if (tableRef.current) {
        tableRef.current.reload()
      }
    }
  }

  const openApp = (record) => {
    let studentId = record.studentId._id
    if (user && user.userType && user.userType == 'admin') {
      window.open(`/student/application?studentId=${studentId}&appId=${record.application_id}`, '_blank')
    } else {
      dispatch(
        getUrlPushWrapper('application.singleApplication', {
          appId: record.applications_id,
          studentId: studentId
        }))
    }
  }


  const callback = (key) => {
    // console.log(key)
  }


  const columns = [

    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (value, item, index) => (itemNo - 1) * 10 + index + 1
    },
    {
      title: 'Agent',
      dataIndex: '_id',
      key: '_id',
      render: (item, record) => {
        return (
          <div>
            {item && item.name ? item.name : ''}
          </div>
        )
      }
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: (item, record) => {
        return (
          <div>
            {record && record.canadaBalance ?
                <label className={'label label-success-green sm'}>Canada : ${record.canadaBalance}</label> : ''}
            {' '}
            {record && record.ukBalance ?
                <label className={'label label-success sm'}>UK : £{record.ukBalance}</label> : ''}
          </div>
        )
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (item, record) => {
        return (
          <div>
            {record && record._id && record._id.email ? record._id.email : ''}
          </div>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (item, record) => {
        return (
          <div className={'actionBtnGroup'}>
            <button className="btn" onClick={() => {
              events.showDrawer(record._id._id)
            }}><img src="/dist/img/view.svg" alt="" className={'eyeBtn'}/></button>

            <button className="btn" onClick={() => {
              events.showPayDrawer(record._id._id)
            }}><img src="/dist/img/payNow.png" alt="" className={'viewBtn'}/></button>

          </div>
        )
      }
    }

  ]


  return (
    <div className="row  mt-4">
      <div className="col-lg-12">
        <div className="card">
          <div className="table-head d-flex align-items-center">
            <h5>Agents Commission</h5>
            <div className="search-box-table">

            </div>
            <div className="sort-box-table mark-btn">

            </div>
          </div>
          <div className="card-body table-responsive">
            <TableComp columns={columns}
                       apiRequest={apiRequest}
                       pagination={DefaultTablePagination()}
                       ref={tableRef}/>

          </div>
        </div>
      </div>
      {visible ? <CommissionDrawer visible={visible} agentId={agentId} onClose={events.closeDrawer}/> : null}
      {visiblePayCommission ?
        <PayCommission visible={visiblePayCommission}
                       agentId={agentId}
                       onClose={events.closePayDrawer}
                       closeAndRefresh={events.closeAndRefreshPayDrawer}
        /> : null}
    </div>
  )
}

export default AllCommissions
