import {Avatar, Card, Drawer, Table} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {RowTable} from '../../../components/_utils/RowTable'
import {
    displayDate,
    newFormatDisplayDate
} from '../../../components/_utils/appUtils'

const SingleExpense = props => {
    let dispatch = useDispatch()
    let {visible, onClose, expense} = props;
    useEffect(() => {

    }, [])
    return (
        <>
            <Drawer
                title={'Single Expense Details'}
                visible={visible}
                width={'50%'}
                onClose={onClose}>
                <Card>
                    <div className={'tableBox striped'}>
                        <RowTable
                            title={'Total Amount'}
                            value={
                                <>
                                    {expense.expenseType === 'Car' ?
                                        <>
                                            {expense && expense.netAmount ? expense.netAmount : null}
                                        </> :
                                        <>
                                            {expense && expense.amount ? expense.amount : null}
                                        </>}
                                </>
                            }
                        />
                        <RowTable
                            title={'Approved Amount'}
                            value={
                                expense && expense.approvedAmount
                                    ? expense.approvedAmount
                                    : null
                            }
                        />
                        <RowTable
                            title={'Date'}
                            value={expense && expense.date ? displayDate(expense.date) : null}
                        />
                        {expense.expenseType === 'Hotel' ? (
                            <RowTable
                                title={'Hotel Name'}
                                value={expense.name ? expense.name : null}
                            />
                        ) : expense.expenseType === 'Food' ? (
                            <RowTable
                                title={'Restraunt Name'}
                                value={expense.name ? expense.name : null}
                            />
                        ) : null}
                        {expense.expenseType === 'Car' ? (
                            <>
                                <RowTable
                                    title={'From Place'}
                                    value={expense.fromPlace ? expense.fromPlace : null}
                                />
                                <RowTable
                                    title={'To Place'}
                                    value={expense.toPlace ? expense.toPlace : null}
                                />
                            </>
                        ) : null}
                    </div>
                </Card>
            </Drawer>
        </>
    )
}
export default SingleExpense
