import React, {useState} from "react"
import {Drawer, Form, Card, notification, Row, Col, Button} from "antd";
import {InputBox} from "../../../components/_utils/appUtils";
import {useDispatch} from "react-redux";
import {updateTuitionFeesDirectUniversityFxn} from "../actions/fcmtAction";

const UploadTravelDocument = (props) => {
    let {studentId, applicationId, visible, onClose, onSubmit} = props;
    let [enrolmentLetter, setEnrolmentLetter] = useState({})
    let [academicSchedule, setAcademicSchedule] = useState({})
    let dispatch = useDispatch()
    const events = {
        chooseEnrolmentLetter: (e) => {
            let {files} = e.target
            if (files && files.length) {
                setEnrolmentLetter(files[0])
            }
        },
        chooseAcademicSchedule: (e) => {
            let {name, files} = e.target
            if (files && files.length) {
                setAcademicSchedule(files[0])
            }
        },
        handleSubmit: async () => {
            if (!enrolmentLetter || (enrolmentLetter && !enrolmentLetter.name)) {
                return notification.error({
                    message: 'Please choose Enrolment Letter'
                })
            }
            if (!academicSchedule || (academicSchedule && !academicSchedule.name)) {
                return notification.error({
                    message: 'Please choose Academic Schedule'
                })
            }
            let obj = {
                applicationId: applicationId,
                studentId: studentId,
                status: "Approved"
            }

            let fd = new FormData()
            fd.append('obj', JSON.stringify(obj))
            if (enrolmentLetter && enrolmentLetter.name) {
                fd.append("enrolmentLetter", enrolmentLetter);
            }
            if (academicSchedule && academicSchedule.name) {
                fd.append("academicSchedule", academicSchedule);
            }
            let resp = await dispatch(updateTuitionFeesDirectUniversityFxn(fd));
            if (resp && resp.success) {
                onSubmit()
            }
        }
    }
    let inputTypes = {
        fields: [
            {
                key: 'enrolmentLetter',
                customField: (
                    <>
                        <InputBox title={'Choose Enrolment Letter'}>
                            <input type={'file'} className={'form-control'}
                                   onChange={(e) => {
                                       events.chooseEnrolmentLetter(e)
                                   }}
                            />
                        </InputBox>
                    </>
                )
            },
            {
                key: 'academicSchedule',
                customField: (
                    <>
                        <InputBox title={'Choose Academic Schedule'}>
                            <input type={'file'} className={'form-control'}
                                   onChange={(e) => {
                                       events.chooseAcademicSchedule(e)
                                   }}
                            />
                        </InputBox>
                    </>
                )
            },

        ]
    }

    return (
        <Drawer
            width={"45%"}
            title={'Travel documents'}
            visible={visible}
            onClose={onClose}>
            <Card bordered={false}>
                <Row gutter={16}>
                    {inputTypes.fields.map((item) => {
                        return (
                            <Col span={24} key={item.key}>
                                {item.customField}
                            </Col>
                        )
                    })}
                    <Col span={24}>
                        <Button type="primary" htmlType="submit" onClick={events.handleSubmit}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Card>
        </Drawer>
    )
}
export default UploadTravelDocument
