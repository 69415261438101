import React, {useEffect, useState} from "react"
import {Button, Card, Drawer, Form, Input, notification} from "antd";
import {InputBox} from "../../../components/_utils/appUtils";
import {updateUkApplicationTuitionFeesFxn} from "../../student/actions/student";
import {useDispatch} from "react-redux";

const AddTuitionFeeDrawer = (props) => {
    let dispatch = useDispatch()
    let {application, visible, studentId, onClose, reloadTable} = props
    let [ukTuitionFee, setUkTuitionFee] = useState("")
    useEffect(() => {
        setUkTuitionFee(application.ukTuitionFee)
    }, [application.ukTuitionFee])
    const updateApplicationTuitionFeesFxn = async () => {
        if (!ukTuitionFee) {
            notification.warning({message: "Enter Tuition Fees"})
            return
        }
        let obj = {
            studentId: studentId,
            applicationId: application._id,
            ukTuitionFee
        }
        let {success, message} = await dispatch(updateUkApplicationTuitionFeesFxn(obj))
        if (success) {
            onClose()
            reloadTable()
        }
    }
    return (
        <Drawer
            title={'Tuition Fee'}
            visible={visible}
            onClose={onClose}
            width={'30%'}
            placement="right">
            <Card bordered={false}>
                <Form>
                    <InputBox title={'Tuition Fee'}>
                        <Input
                            onChange={(e) => {
                                let {value} = e.target;
                                setUkTuitionFee(value)
                            }}
                            name={'tuitionFee'}
                            className={'form-control'}
                            placeholder={'Tuition Fee'} value={ukTuitionFee}
                        />
                    </InputBox>
                    <Form.Item className={'mt20 alignRight'}>
                        <Button type='primary' onClick={updateApplicationTuitionFeesFxn}>
                            SUBMIT
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Drawer>
    )
}
const ShowTuitionFeesCondition = (props) => {
    let statusList = ['Conditional Offer Received', 'Unconditional Offer Received']
    let {applications, children} = props;
    let {universityCountry, status} = applications;
    let countryName = universityCountry && universityCountry.countryName ? universityCountry.countryName.toLowerCase() : ""
    return (
        countryName == 'uk' && statusList.includes(status) ? <>
            {children}
        </> : ""
    )
}
export {AddTuitionFeeDrawer, ShowTuitionFeesCondition} ;
