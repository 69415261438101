import React from 'react'
import PendingForSubmissionAppList from '../privateCollege/pendingForSubmissionAppList'
import { CornerStoneCollege } from '../../../components/_utils/appUtils'

const CornerStonePendingForSubmission = () => {
  return (
    <>
      <PendingForSubmissionAppList
        appName={'Cornerstone College'}
        universityId={CornerStoneCollege}
        redirectKey={'cornerstone.cornerstoneFilePendingForSubmissions'} />
    </>
  )
}
export default CornerStonePendingForSubmission
