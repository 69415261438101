import React from 'react'
import {
    Form, Input, Button, notification,
} from 'antd'

import {hideLoader, hidePageLoad} from '../../modules/actions'
import {push} from 'connected-react-router'
import {connect} from 'react-redux'
import {RegisterButton} from './registerButton'
import {resetPassword, checkRecoverCodeFxn} from './action'

class RecoverPassword extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            password: '',
            confirmPassword: '',
            email: '',
        }
        props.dispatch(hidePageLoad())
        props.dispatch(hideLoader())
        this.checkIsLoggedIn()
    }

    checkIsLoggedIn = async () => {
        let resp = localStorage.getItem('token')
        if (resp) {
            let {dispatch} = this.props
            dispatch(push('/dashboard'))
        } else {
            let {pathname} = window.location
            let token = pathname.split('/').pop()
            let resp1 = await checkRecoverCodeFxn({token})
            if (resp1 && resp1.success) {
                this.setState({
                    email: resp1.data
                })
            } else {
                let {dispatch} = this.props
                dispatch(push('/dashboard'))
            }
        }
    }


    openLoginPage = () => {
        let {dispatch} = this.props
        dispatch(push('/login'))
    }


    submitFinal = async () => {
        const {dispatch} = this.props
        let {pathname} = window.location
        let token = pathname.split('/').pop()

        let {password, confirmPassword} = this.state
        if (!password) {
            notification.warn({message: 'Enter new password.'})
            return
        }
        if (password && password.length < 6) {
            notification.warn({message: 'Enter minimum 6 digit password.'})
            return
        }
        if (!confirmPassword) {
            notification.warn({message: 'Enter confirm password.'})
            return
        }
        if (password !== confirmPassword) {
            notification.warn({message: 'Confirm Password not match, enter correct password.'})
            return
        }
        let resp = await dispatch(resetPassword({password, confirmPassword, token}))
        if (resp && resp.success) {
            alert(resp.message)
            this.setState({
                password: '',
                confirmPassword: ''
            })
            this.openLoginPage()
        } else {
            alert(resp.message)
        }

    }

    render() {
        const {loading, dispatch} = this.props
        let {email} = this.state;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            }
        }
        return (
            <React.Fragment>
                <div id={'loginForm'} className={'hold-transition login-main'}>
                    <header className=" w-100 navbar-fix">

                        <div className="container">
                            <div className="d-flex flex-column flex-md-row align-items-center pt-5">
                                <h5 className="my-0 mr-md-auto font-weight-normal">
                                    <a onClick={() => dispatch(push('/home'))}>
                                        <img src="dist/img/AdminLTELogo.png" alt=""/>
                                    </a>
                                </h5>
                                <nav className="my-2 my-md-0 d-flex align-items-center">

                                    <a className="p-2 text-dark" onClick={() => this.openLoginPage()}>Login</a>
                                    <RegisterButton customClass={'lite'}/>
                                </nav>
                            </div>
                        </div>

                    </header>
                    <div className="login-form w-100">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5 mr-auto">
                                    <div className="login-box w-100">
                                        <div>
                                            <h5>Set a new password
                                                <div className={'forgetEmailText'}>
                                                    For Your Unizportal Account - <strong>{email}</strong>
                                                </div>
                                            </h5>

                                            <Form autocomplete="off" noValidate={true}>
                                                <div className="form-group">
                                                    <Form.Item>
                                                        <Input placeholder="Enter new password" type={'password'}
                                                               id={'new-password'}
                                                               name={'new-password'} value={this.state.password}
                                                               onChange={(e) => {
                                                                   this.setState({password: e.target.value})
                                                               }}
                                                               autoComplete="new-password"/>
                                                    </Form.Item>
                                                    <img src="dist/img/key.png" alt=""/>
                                                </div>
                                                <div className="form-group">
                                                    <Form.Item>
                                                        <Input placeholder="Enter confirm password" type={'password'}
                                                               id={'confirm-password'}
                                                               name={'confirm-password'}
                                                               value={this.state.confirmPassword}
                                                               onChange={(e) => {
                                                                   this.setState({confirmPassword: e.target.value})
                                                               }}
                                                               autoComplete="confirm-password"/>
                                                    </Form.Item>
                                                    <img src="dist/img/key.png" alt=""/>
                                                </div>


                                                <div className="d-flex align-items-center">
                          <span>
                            Already have an account? <br/>
                            <a onClick={() => this.openLoginPage()} style={{textDecoration: 'underline'}}
                               className={'hyperLink'}>Login Here</a>
                          </span>

                                                    <Button className="btn ml-auto" type="button" loading={loading}
                                                            onClick={() => this.submitFinal()}
                                                            style={{marginTop: 0}}
                                                            htmlType="submit">Submit</Button>
                                                </div>

                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const WrappedRecoverPassword = Form.create()(RecoverPassword)


const mapStateToProps = ({counter, global}) => ({
    count: counter.count,
    loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedRecoverPassword)
