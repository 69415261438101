import React, { useState, useEffect } from 'react'
import { Card, Col, Form, Row, notification, Input, Button } from 'antd'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { listAllCountries } from '../../countries/actions/countries'
import { listAllUniversities } from '../../university/actions/university'
import { listAllUsers } from '../../users/actions/user'
import { useDispatch } from 'react-redux'
import { qualificationOptions, InputBox, DocumentTypes } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import _ from 'lodash'
import { quickAddStudent } from '../actions/student'
import moment from 'moment'

let initialState = {
  document: [],
  uploadKey: moment()
}

const AddQuickStudent = (props) => {
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } } = props
  const dispatch = useDispatch()
  const [state, setState] = useState(initialState)
  const [allCountry, setAllCountry] = useState([])
  const [allUniversity, setAllUniversity] = useState([])
  const [allUser, setAllUser] = useState([])

  useEffect(() => {
    loadCountry()
  }, [])

  const loadCountry = async () => {
    let { data } = await dispatch(listAllCountries({ results: 1000000 }))
    setAllCountry(data)
  }


  const loadUser = async (countryId) => {
    let { data } = await dispatch(listAllUsers({ results: 1000000, userType: 'branchManager', countryId }))
    if (data && data.length) {
      setFieldsValue({
        branchManagerId: data[0]._id
      })
    }
    setAllUser(data)
  }


  const loadUniversity = async () => {
    let countryIds = getFieldValue('countryId')
    let { data } = await dispatch(listAllUniversities({ results: 1000000, universityCountry: countryIds }))
    if (countryIds && countryIds.length) {
      setAllUniversity(data)
    } else {
      setAllUniversity([])
    }
  }

  const chooseUniversity = (data) => {
    let allData = []
    let findCountry = []
    /*    _.each(data, (item) => {
          let aa = _.find(allUniversity, (val) => {
            return val._id == item
          })
          if (aa) {
            allData.push(aa)
          }
        })
        let groupedData = _.groupBy(allData, (item) => {
          return item.universityCountry._id
        })
        let checkA = _.find(groupedData, (item) => {
          return item.length > 3
        })*/

    if (data.length > 3) {
      notification.warning({
        message: 'You can choose maximum 3 university from each country. '
      })
    } else {
      setFieldsValue({
        universityId: data
      })
    }

  }

  const chooseDocument = (e) => {
    let { name, files } = e.target
    if (files && files.length) {
      setState({
        ...state,
        'document': files
      })
    }
  }

  const handleSubmit = (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let fd = new FormData()
        fd.append('obj', JSON.stringify(valData))
        _.each(state.document, (item) => {
          fd.append('document', item)
        })

        let data = await dispatch(quickAddStudent(fd))
        if (data && !data.error) {
          form.resetFields()
          setState({
            uploadKey: moment(),
            document: []
          })
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }


  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  let formFields = {
    generateInputs: [
      {
        key: 'countryId',
        label: 'Country',
        type: 'select',
        showSearch: true,
        options: allCountry,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.countryName}`,
        onChange: x => {
          setFieldsValue({
            countryId: x,
            universityId: [],
            branchManagerId: ''
          })
          loadUser(x)
          loadUniversity(x)
        }
      },
      {
        key: 'universityId',
        label: 'University',
        type: 'select',
        mode: 'multiple',
        showSearch: true,
        options: allUniversity,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.universityName}`,
        onChange: x => {
          /* setFieldsValue({
             universityId: x
           })*/
          chooseUniversity(x)
        }
      },

      { key: 'name', label: 'Student Name', required: true, placeholder: 'Enter Name' },
      {
        key: 'dateOfBirth', label: 'Date Of Birth',
        required: true, type: 'date',
        placeholder: 'Choose Date of Birth'
      },
      {
        key: 'branchManagerId',
        label: 'Branch Manager',
        required: true,
        type: 'select',
        showSearch: true,
        options: allUser,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          setFieldsValue({
            branchManagerId: x
          })
        }
      },
      { key: 'mobile', label: 'Phone', required: true, type: 'number', placeholder: 'Enter Phone No' },


      {
        key: 'comment', label: 'Comments', placeholder: 'Enter Comment', span: 24, type: 'textArea',
        rows: 3
      }

    ],
    backgroundInfoInput: [
      {
        key: 'refusedVisa',
        label: 'Have you been refused a visa from Canada, the USA, the United Kingdom, New Zealand or Australia?',
        type: 'radioGroup',
        options: ['Yes', 'No'],
        span: 24,
        onChange: x => {
          props.form.setFieldsValue({
            refusedVisa: x.target.value
          })
        }
      },
      {
        key: 'details',
        label: 'If you answered "Yes" to any of the following questions above, please provide more details below:',
        placeholder: 'Provide details... ',
        type: 'textArea',
        span: 24,
        rows: 3
      }
    ]
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        <Form onSubmit={handleSubmit} className={'vertical-form'}>
          <div className="form-box mt-4">

            <div className="d-flex align-items-center mb-3 heading-form">
              <h5>Add Quick Student</h5>
            </div>

            <div className="card unizportal">
              <Row gutter={16}>
                {formFields.generateInputs.map((item, key) => {
                  return (
                    <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8} sm={12} xs={24} key={key}>
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout}/>
                    </Col>
                  )
                })}

                <Col span={8} md={8} sm={12} xs={24}>
                  <InputBox title={'Documents'}>
                    <Input type={'file'} key={state.uploadKey} className={'form-control'}
                           name={'document'} multiple={true} id={'document'} onChange={(e) => {
                      chooseDocument(e)
                    }}/>
                  </InputBox>
                </Col>
                <Col span={24}>
                  <Form.Item className={'mt20'}>
                    <Button type="primary" onClick={handleSubmit}>
                      SUBMIT
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>

        </Form>
      </div>
    </div>
  )
}


const WrappedAddQuickStudent = Form.create()(AddQuickStudent)
export default WrappedAddQuickStudent

