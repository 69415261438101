import React, { useState, useEffect } from 'react'
import { Button, Card, Drawer, Form, Input, notification } from 'antd'
import { InputBox } from '../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'

import { updateInsuranceStatus } from './actions/actions'

const { TextArea } = Input

const InsuranceApproveDrawer = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose } = props
  let [givenName, setGivenName] = useState('')
  let [comment, setComment] = useState('')


  useEffect(() => {


  }, [])


  const updateStatus = () => {
    return new Promise(async (resolve) => {
      let { user } = props
      let obj = {
        id: user._id,
        status: 'Approved',
        comment: comment
      }
      let resp = await dispatch(updateInsuranceStatus(obj))
      if (resp.success) {
        resolve(resp)
        onClose()
      }
    })
  }
  return (
    <Drawer visible={visible}
            title={`Approve Student Insurance`}
            width={600}
            closable={true}
            maskClosable={true}
            onClose={onClose}>
      <Card>
        <Form className={'vertical-form'} autoComplete='off'>
          <Form.Item>
            <InputBox title={'Add Comment'}>
              <TextArea rows={4} className={'form-control'}
                        value={comment}
                        onChange={(e) => {
                          setComment(e.target.value)
                        }}>
              </TextArea>
            </InputBox>
          </Form.Item>
          <Form.Item style={{ marginTop: 25 }}>
            <Button type='primary' onClick={updateStatus}>
              Approve
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  )
}
export default InsuranceApproveDrawer
