import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Form, Select,
  Spin, Row, Col, Input, notification
} from 'antd'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import moment from 'moment'
import { InputBox } from '../../../components/_utils/appUtils'
import { searchedUser } from '../../users/actions/user'
import debounce from 'lodash/debounce'
import { addPrivateMessage } from '../actions/privateMessage'
import { useDispatch } from 'react-redux'
import GetEachFormFields from '../../city/views/add'

const { Option } = Select
const { TextArea } = Input
let initialState = {
  currentStep: 0,
  fileList: [],
  uploadKey: moment()
}

const AddStudent = (props) => {
  const dispatch = useDispatch()
  let [userList, setUserList] = useState([])
  let [userId, setUserId] = useState('')
  let [content, setContent] = useState('')

  useEffect(() => {
    fetchUser()
  }, [])
  const fetchUser = async (value) => {
    let userList = await searchedUser({ name: value })
    setUserList(userList)
  }


  const chooseUserFxn = (value) => {
    setUserId(value)
  }

  const handleSubmit = async () => {
    if (!userId) {
      return notification.warn({ message: 'Choose user' })
    }
    if (!content) {
      return notification.warn({ message: 'Enter Content' })
    }
    let resp = await dispatch(addPrivateMessage({ userId, content }))
    setUserId('')
    setContent('')
  }

  const filterOption = (input, options) => {
    return options.props.label.match(new RegExp(`^${input}`, 'ig'))
  }


  return (
    <div
    >

      <div className="row">
        <div className="col-lg-12">
          <div className="form-box mt-4">

            <div className="d-flex align-items-center mb-3 heading-form">
              <h5>Send Message</h5>
            </div>

            <div className="card unizportal">
              <Row>
                <Col span={8}>
                  <InputBox title={'Select User'}>
                    <Select
                      showSearch={true}
                      // onSearch={debounce(fetchUser, 500)}
                      filterOption={filterOption}
                      value={userId}
                      autoClearSearchValue={true}
                      allowClear={true}
                      style={{ width: '100%' }}
                      showArrow={true}
                      onChange={chooseUserFxn}
                      optionLabelProp="label"
                      optionFilterProp="label"
                      placeholder="Choose User">
                      {userList && userList.length && userList.map((item, index) => {
                        return (
                          <Option key={item._id} value={item._id} label={item.name}>
                            <div>
                              {item.name} ( {item.email})
                            </div>
                          </Option>
                        )
                      })}
                    </Select>
                  </InputBox>
                </Col>
                <Col span={24}>
                  <InputBox title={'Content'}>
                    <TextArea rows={4} className={'form-control'} onChange={(e) => setContent(e.target.value)}
                              value={content}/>
                  </InputBox>
                </Col>
                <Col span={24}>
                  <InputBox title={''}>
                    <Button type={'primary'} onClick={handleSubmit}>Submit</Button>
                  </InputBox>
                </Col>
              </Row>

            </div>
          </div>

        </div>
      </div>

    </div>
  )
}


const WrappedAddStudent = Form.create()(AddStudent)
export default WrappedAddStudent


