import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  Icon,
  Popconfirm,
  Row,
  Select,
  Tag,
  Tooltip,
  notification
} from 'antd'
import lodash from 'lodash'
import moment from 'moment'
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { AgentBranchManagerComponent } from '../../../components/_utils/AgentBranchManagerComponent'
import {
  ConditionOptions,
  Intakes,
  countryIds,
  defaultLogoList,
  departmentObj,
  filterOption,
  longDisplayDate,
  newFormatDisplayDate,
  showEnrollmentAction,
  statusColors,
  udaanArr,
  currentUdaan, agentTeamTypes
} from '../../../components/_utils/appUtils'
import { getPendingPendency } from '../../../components/_utils/utils'
import { getPushPathWrapper, getUrlPushWrapper } from '../../../routes'
import {
  AllowComponentRightsWise,
  AllowComponentUserWise,
  CheckBranchUserFxn,
  CheckCaseCloseRight,
  ShowEnrollmentComponent
} from '../../WebComponent/allowComponentRightsWise'
import { CheckUserRight, ShowExportOption } from '../../dashboard/views/DashboardUserWise'
import {
  applicationCloseDirect,
  reCalculateCommission,
  updateApplicationTuitionFees
} from '../../student/actions/student'
import ApplyCourse from '../../student/views/applyForApplicationDrawer'
import {
  listAllUniversities,
  userWiseUniversityList
} from '../../university/actions/university'
import AddCommissionDrawer from '../../universityCommission/drawers/addCommission'
import { listAllUsers } from '../../users/actions/user'
import EditUser from '../../users/views/edit'
import { OshcDrawer, OshcFieldLabel, ShowOshcDrawer } from '../OSHC/oshcDrawer'
import {
  exportApplicationsList,
  getSelectedCountryApplicationsApi
} from '../actions/chooseStudent'
import { AddTuitionFeeDrawer } from '../drawers/AddTuitionFeeDrawer'
import AddNote from '../drawers/applicationAddNoteComponent'
import ChangeUniversityComponent from '../drawers/changeUniversity'
import Enrollment from '../drawers/enrollment'
import ReActivateApplication from '../drawers/reActivateApplicationDrawer'
import UpdateUniversityComponent from '../drawers/updateUniversity'
import TransferApplicationDrawer from '../views/transferApplication'
import StudentOtherApp from '../views/StudentOtherApplication'
import DraftButtonComponent from '../views/draftButtonComponent'
import SelectPendency from '../views/selectPendency'

const { RangePicker } = DatePicker

const managerViewRight = ['admin', 'branchUser']

const { Option } = Select
const initialState = {
  applicationList: [],
  applicationObj: {}
}
const commissionObj = {
  commissionAmount: 0,
  tuitionFee: 0,
  studentId: '',
  applicationId: ''
}

const stringDate = date => {
  return date ? moment(date).format('YYYY-MM-DD') : null
}

const RenderComment = props => {
  let { item } = props
  let [hideV, setHideV] = useState(true)
  return (
    item && (
      <div>
        {item.addedByUserId && item.addedByUserId.name ? (
          <div className={'alignRight colorPrimary font12'}>
            (Added By : {item.addedByUserId.name})
          </div>
        ) : null}

        <div>{longDisplayDate(item.time)}</div>

        <div className={hideV ? 'appCommentDotsReplace' : ''}>
          {item && item.text ? item.text : ''}
        </div>
        {item && item.text.length && item.text.length > 50 ? (
          <div className={'alignRight'}>
            {hideV ? (
              <a
                onClick={() => {
                  setHideV(false)
                }}>
                Show More
              </a>
            ) : (
              <a
                onClick={() => {
                  setHideV(true)
                }}>
                Show Less
              </a>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  )
}
const CountryWiseApplicationList = props => {
  let { onClose, studentObj = {}, reloadTable, currentUser } = props
  const [state, setState] = useState(initialState)
  const [transferAppVisible, setTransferAppVisible] = useState(false)
  const [studentData, setStudentData] = useState({})
  const [visibleAddCourseDrawer, setVisibleAddCourseDrawer] = useState(false)
  const [addPendencyDrawer, setAddPendencyDrawer] = useState(false)
  const [name, setName] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [allAgent, setAllAgent] = useState([])
  const [agentId, setAgentId] = useState('')
  const [universityList, setUniversityList] = useState([])
  const [appUniversityId, setAppUniversityId] = useState('')
  const [visibleOtherAppDrawer, setVisibleOtherAppDrawer] = useState(false)
  const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
  const [totalApplication, setTotalApplication] = useState(0)
  const [visibleFeesModal, setVisibleFeesModal] = useState(false)
  const [feesObj, setFeesObj] = useState(commissionObj)
  const [currentStatus, setCurrentStatus] = useState('')
  let [intake, setIntake] = useState('')
  let { applicationObj } = state
  let [tableSearch, setTableSearch] = useState(false)
  let [filters, setFilters] = useState({})
  let [branchManagerList, setBranchManagerList] = useState([])
  let [studentManagerList, setStudentManagerList] = useState([])
  let [branchManagerId, setBranchManagerId] = useState('')
  let [studentManagerId, setStudentManagerId] = useState('')
  const [branchUserList, setBranchUserList] = useState([])
  const [branchUserId, setBranchUserId] = useState('')
  const [draftApplication, setDraftApplication] = useState('')
  const [visibleUpdateUser, setVisibleUpdateUser] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})
  const [defaultCountryId, setDefaultCountryId] = useState('')
  const [visibleTuitionFee, setVisibleTuitionFee] = useState(false)
  const [visibleOshcDrawer, setVisibleOshcDrawer] = useState(false)
  const [
    visibleUniversityCommission,
    setVisibleUniversityCommission
  ] = useState(false)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [udaan, setUdaan] = useState('')

  let viewOnlyRight = CheckBranchUserFxn() // check branch user right

  let [selectedApplication, setSelectedApplication] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })
  let [enrollmentObj, setEnrollmentObj] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })
  let [changeUniversity, setChangeUniversity] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })
  let [updateUniversity, setUpdateUniversity] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })

  const dispatch = useDispatch()
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  const tableAppRef = useRef()

  const agentEvents = {
    closeEditUser: () => {
      setSelectedUser({})
      setVisibleUpdateUser(false)
    },
    editAgent: agent => {
      setSelectedUser(agent)
      setVisibleUpdateUser(true)
    }
  }

  const loadBranchManager = async () => {
    let { countryId } = await getParamsForApplicationList()
    let obj = {
      userType: 'branchManager',
      customQuery: {
        // 'agents.0': { $exists: true }
        'branchManagerType': 'agentWise'
      },
      results: 100,
      select: ['name', 'email', 'mobile', 'agents', 'countryId']
    }
    if (countryId) {
      obj.countryId = countryId
    }
    let { data } = await dispatch(listAllUsers(obj))
    setBranchManagerList(data)
  }
  const loadStudentManager = async () => {
    let obj = {
      userType: 'branchManager',
      branchManagerType: 'studentWise',
      results: 100,
      select: ['name', 'email', 'mobile', 'agents', 'countryId']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setStudentManagerList(data)
  }
  const loadCurrentUserData = async () => {
    let { countryId } = await getParamsForApplicationList()
    if (countryId) {
      let params = {
        results: 1000,
        userType: 'branchUser',
        sortField: 'name',
        sortOrder: 'ascend',
        select: ['name', 'userType', 'mobile', 'email'],
        customQuery: {
          $or: [
            { countryId: countryId },
            { department: 'Visa Approved Department' }
          ]
        }
      }
      let { data } = await dispatch(listAllUsers(params))
      if (data && data.length) {
        setBranchUserList(data)
      }
    } else {
      setBranchUserList([])
    }
  }

  let apiRequest = params => {
    return new Promise(async resolve => {
      let resp = await getParamsForApplicationList()
      params = { ...params, ...resp }
      if (!params.countryId) {
        params.countryId = ''
      }
      if (!params.results) {
        params.results = 50
        params.count = 50
      }

      if (tableSearch) {
        params.page = 1
      }
      try {
        setFilters(params)
        let resp = await dispatch(
          getSelectedCountryApplicationsApi({
            ...params,
            regExFilters: ['name']
          })
        )

        setTotalApplication(resp.total)
        setTableSearch(false)
        resolve(resp)
      } catch (e) {
      }
    })
  }

  let exportExcel = async () => {
    let params = {}
    let resp = await getParamsForApplicationList()
    params = { ...params, ...resp }
    if (name) {
      params.name = name
    }
    if (dateOfBirth) {
      params.dateOfBirth = moment(dateOfBirth)
    }

    params.count = totalApplication
    params.results = totalApplication
    let { success, filePath, message } = await dispatch(
      exportApplicationsList(params)
    )
    if (success) {
      notification.success({ message: message })
      // window.open(`${apiUrl}${filePath}`, 'Download')
    } else {
      notification.error({ message: message })
    }
  }

  useEffect(() => {
    loadAgentData()
    // loadUniversityNameList()
    LoadUserWiseUniversity()
    setFieldByParams()
    loadBranchManager()
    loadStudentManager()
    loadCurrentUserData()
  }, [])

  const setFieldByParams = async () => {
    let resp = await getParamsForApplicationList()
    setCurrentStatus(resp.status)
    if (resp.status == 'Visa Approved' || resp.status == 'Case Approved') {
      setUdaan(currentUdaan)
    }
    if (resp.countryId) {
      setDefaultCountryId(resp.countryId)
    }
    if (resp.name) {
      setName(resp.name)
    }
    if (resp.intake) {
      setIntake(resp.intake)
    }
    if (resp.agentId) {
      setAgentId(resp.agentId)
    }
    if (resp.appUniversityId) {
      setAppUniversityId(resp.appUniversityId)
    }
    if (resp.branchManagerId) {
      setBranchManagerId(resp.branchManagerId)
    }
    if (resp.draftApplication) {
      setDraftApplication(resp.draftApplication)
    }
    if (resp.studentManagerId) {
      setStudentManagerId(resp.studentManagerId)
    }
    if (resp.branchUserId) {
      setBranchUserId(resp.branchUserId)
    }
    if (resp.fromDate) {
      setFromDate(moment(resp.fromDate))
    }
    if (resp.toDate) {
      setToDate(moment(resp.toDate))
    }
  }

  const loadAgentData = async () => {
    let params = {
      results: 15000,
      userType: 'agent',
      sortField: 'companyName',
      sortOrder: 'ascend',
      select: ['name', 'companyName']
    }
    let { data } = await dispatch(listAllUsers(params))
    if (data && data.length) {
      setAllAgent(data)
    }
  }
  const loadUniversityNameList = async () => {
    let params = {
      results: 5000,
      select: ['universityName'],
      sortField: 'universityName',
      sortOrder: 'ascend',
      regExFilters: ['universityName'],
      showUniversity: [true, false]
    }
    let resp = await getParamsForApplicationList()

    params.universityCountry = resp.countryId

    let { data } = await dispatch(listAllUniversities(params))
    if (data && data.length) {
      setUniversityList(data)
    }
  }

  const LoadUserWiseUniversity = async () => {
    let { countryId } = await getParamsForApplicationList()
    let data = await dispatch(userWiseUniversityList({ countryId }))
    setAppUniversityId('')
    if (data && data.length) {
      setUniversityList(data)
    }
  }
  const events = {
    openAddPendencyDrawer: value => {
      setState({
        ...state,
        applicationObj: value
      })
      setAddPendencyDrawer(true)
    },
    reloadAndClose: () => {
      tableAppRef.current.reload()
      setAddPendencyDrawer(false)
    },
    closeAddPendencyDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setAddPendencyDrawer(false)
    },
    reloadFxn: () => {
      tableAppRef.current.reload()
    },
    showTransferApp: () => {
      setTransferAppVisible(true)
    },
    hideTransferApp: () => {
      setTransferAppVisible(false)
      tableAppRef.current.reload()
    },
    showAddCourseDrawer: () => {
      setVisibleAddCourseDrawer(true)
    },
    hideAddCourseDrawer: () => {
      setVisibleAddCourseDrawer(false)
      tableAppRef.current.reload()
    },
    refreshApplication: () => {
      tableAppRef.current.reload()
    },

    closeDirectApp: async record => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(applicationCloseDirect(obj))
      tableAppRef.current.reload()
    },

    enterName: value => {
      setName(value)
    },
    openOtherAppDrawer: value => {
      setState({
        ...state,
        applicationObj: value
      })
      setVisibleOtherAppDrawer(true)
    },
    closeOtherAppDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setVisibleOtherAppDrawer(false)
    },
    openAddCommentDrawer: value => {
      setState({
        ...state,
        applicationObj: value
      })
      setVisibleAddCommentDrawer(true)
    },
    closeAddCommentDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setVisibleAddCommentDrawer(false)
    },
    hideVisibleFeesModal: () => {
      setVisibleFeesModal(false)
    },
    setCommissionLocal: data => {
      setFeesObj({
        ...feesObj,
        ...data
      })
    },
    reCalculateCommissionFxn: async () => {
      let { commissionAmount } = await dispatch(reCalculateCommission(feesObj))
      if (commissionAmount) {
        events.setCommissionLocal({ commissionAmount })
      }
    },
    updateApplicationTuitionFeesFxn: async () => {
      let { success, message } = await dispatch(
        updateApplicationTuitionFees(feesObj)
      )
      if (success) {
        setFeesObj(commissionObj)
        setVisibleFeesModal(false)
        events.reloadFxn()
      }
    },
    showTuitionFeeDrawer: () => {
      setVisibleTuitionFee(true)
    },
    hideTuitionFeeDrawer: () => {
      setVisibleTuitionFee(false)
      tableAppRef.current.reload()
    },
    showOshcDrawer: () => {
      setVisibleOshcDrawer(true)
    },
    hideOshcDrawer: () => {
      setVisibleOshcDrawer(false)
      tableAppRef.current.reload()
    },
    showUniversityCommission: () => {
      setVisibleUniversityCommission(true)
    },
    hideUniversityCommission: () => {
      setVisibleUniversityCommission(false)
      tableAppRef.current.reload()
    },
    chooseDate: data => {
      if (data && data.length) {
        setFromDate(stringDate(data[0]))
        setToDate(stringDate(data[1]))
      }
    }
  }

  const reActivateApp = record => {
    setSelectedApplication({
      visible: true,
      studentId: record._id,
      studentData: record,
      application: record.applications
    })
  }
  const onCloseReActivate = () => {
    setSelectedApplication({
      visible: false,
      studentId: '',
      studentData: {},
      application: {}
    })
    tableAppRef.current.reload()
  }

  const enrollmentApp = record => {
    setEnrollmentObj({
      visible: true,
      studentId: record._id,
      studentData: record,
      application: record.applications
    })
  }
  const onCloseEnrollmentApp = () => {
    setEnrollmentObj({
      visible: false,
      studentId: '',
      studentData: {},
      application: {}
    })
    tableAppRef.current.reload()
  }

  const changeUniversityFxn = record => {
    setChangeUniversity({
      visible: true,
      studentId: record._id,
      studentData: record,
      application: record.applications
    })
  }
  const onCloseChangeUniversityFxn = () => {
    setChangeUniversity({
      visible: false,
      studentId: '',
      studentData: {},
      application: {}
    })
    tableAppRef.current.reload()
  }

  const updateUniversityEvent = {
    openDrawer: record => {
      setUpdateUniversity({
        visible: true,
        studentId: record._id,
        studentData: record,
        application: record.applications
      })
    },
    closeDrawer: () => {
      setUpdateUniversity({
        visible: false,
        studentId: '',
        studentData: {},
        application: {}
      })
      tableAppRef.current.reload()
    }
  }

  const enrollmentBtn = record => {
    /*let application = record.applications
    return !application.enrollment ? (
      showEnrollmentAction(application.statusList) ? (
        <button className="btn" onClick={() => enrollmentApp(record)}>
          <img src={'/assets/enrollment.png'} />
        </button>
      ) : null
    ) : null*/
    return null
  }
  const showEnrollmentBtn = record => {
    let application = record.applications
    return showEnrollmentAction(application.statusList) ? true : false
  }

  const getParamsForApplicationList = () => {
    return new Promise(resolve => {
      let searchParams = new URLSearchParams(window.location.search)
      let countryId = searchParams.get('countryId')
      let status = searchParams.get('status')
      let agentId = searchParams.get('agentId')
      let appUniversityId = searchParams.get('appUniversityId')
      let name = searchParams.get('name')
      let intake = searchParams.get('intake')
      let branchUserId = searchParams.get('branchUserId')

      let branchManagerId = searchParams.get('branchManagerId')
      let draftApplication = searchParams.get('draftApplication')

      let studentManagerId = searchParams.get('studentManagerId')
      let fromDate = searchParams.get('fromDate')
      let toDate = searchParams.get('toDate')
      let udaan = searchParams.get('udaan')
      let obj = {
        countryId,
        status
      }
      if (agentId) {
        obj.agentId = agentId
      }
      if (appUniversityId) {
        obj.appUniversityId = appUniversityId
      }
      if (name) {
        obj.name = name
      }
      if (intake) {
        obj.intake = intake
      }
      if (branchUserId) {
        obj.branchUserId = branchUserId
      }
      if (branchManagerId) {
        obj.branchManagerId = branchManagerId
      }
      if (studentManagerId) {
        obj.studentManagerId = studentManagerId
      }
      if (draftApplication) {
        obj.draftApplication = draftApplication
      }
      if (fromDate) {
        obj.fromDate = fromDate
      }
      if (toDate) {
        obj.toDate = toDate
      }
      if (udaan) {
        obj.udaan = udaan
      }
      resolve(obj)
    })
  }

  const searchFxn = async () => {
    let resp = await getParamsForApplicationList()
    let obj = {
      countryId: resp.countryId,
      status: resp.status
    }
    if (name) {
      obj.name = name
    }
    if (agentId) {
      obj.agentId = agentId
    }
    if (appUniversityId) {
      obj.appUniversityId = appUniversityId
    }
    if (intake) {
      obj.intake = intake
    }
    if (branchUserId) {
      obj.branchUserId = branchUserId
    }
    if (branchManagerId) {
      obj.branchManagerId = branchManagerId
    }
    if (studentManagerId) {
      obj.studentManagerId = studentManagerId
    }
    if (draftApplication) {
      obj.draftApplication = draftApplication
    }
    if (fromDate) {
      obj.fromDate = fromDate
    }
    if (toDate) {
      obj.toDate = toDate
    }
    if (udaan) {
      obj.udaan = udaan
    }
    dispatch(
      getUrlPushWrapper('application.applicationsList', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      tableAppRef.current.reload()
    }, 300)
  }
  const clearFxn = async () => {
    let resp = await getParamsForApplicationList()
    let obj = {
      countryId: resp.countryId,
      status: resp.status
    }
    if (resp.draftApplication) {
      obj.draftApplication = resp.draftApplication
    }
    setAppUniversityId('')
    setAgentId('')
    setName('')
    setIntake('')
    setBranchManagerId('')
    setStudentManagerId('')
    setDraftApplication('')
    setBranchUserId('')
    setFromDate(null)
    setToDate(null)
    setUdaan(null)
    dispatch(
      getUrlPushWrapper('application.applicationsList', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      tableAppRef.current.reload()
    }, 200)
  }

  const commissionRight = () => {
    if (user.userType === 'admin') {
      return false
    }
    if (user.userType === 'agent') {
      return false
    }
    if (user.userType === 'branchUser') {
      if (user.department === 'Visa Approved Department') return false
    }
    return true
  }

  const columns = [
    /*  {
              title: '#',
              key: 'index',
              dataIndex: 'index',
              searchTextName: 'index',
              width: 100,
              render: (item, record, index) => {
                  return (
                      <div style={{width: 60}}>
                          {index+1}
                      </div>
                  )
              }
          },*/
    {
      title: 'App#',
      key: 'applications.id',
      dataIndex: 'applications.id',
      searchTextName: 'applications.id',
      width: 100,
      render: item => {
        return <div style={{ width: 60 }}>{item}</div>
      }
    },
    {
      title: 'S.Id',
      key: 'studentId',
      dataIndex: 'studentId',
      width: 100,
      render: (item, record) => {
        return (
          <div>
            <label className={'label label-tag'}>ID : {item}</label><br />
            {/*  <label className={'label label-tag'}>
                            {record.studentShore ? `(${record.studentShore})` : null}
                        </label>*/}

          </div>
        )
      }
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 150,
      render: (item, record) => {
        let { applications, createdAt } = record
        return (
          <>
            <Tooltip title={'Edit Student'}>
              {record && record.countryISOCode == 'CA_+1' ? (
                <img
                  src={'/assets/starEnable.png'}
                  width={14}
                  style={{ marginRight: 3 }}
                />
              ) : null}
              <a
                className={'linkAA'}
                onClick={() =>
                  viewOnlyRight
                    ? dispatch(
                      getPushPathWrapper('student.editStudent', {
                        id: record._id
                      })
                    )
                    : ''
                }>
                {item} {viewOnlyRight ? <Icon type={'edit'} /> : ''}
              </a>
            </Tooltip>
            <br />

            {record && record.countryISOCode == 'CA_+1' ? (
              <span>
                <Tag style={{ marginTop: 3, marginBottom: 3 }} size={'sm'}>
                  Docs:{' '}
                  {applications && applications.paymentPending == true ? (
                    <Icon type={'close'} style={{ color: 'red' }} />
                  ) : (
                    <Icon type={'check'} style={{ color: 'limegreen' }} />
                  )}
                </Tag>
                <br />
                <Tag>
                  Fees:{' '}
                  {record && record.documentsPending == true ? (
                    <Icon type={'close'} style={{ color: 'red' }} />
                  ) : (
                    <Icon type={'check'} style={{ color: 'limegreen' }} />
                  )}
                </Tag>
              </span>
            ) : null}


            {createdAt ? <label className={'label label-tag'}>
              Join : {newFormatDisplayDate(createdAt)}
            </label> : null}
          </>
        )
      }
    },
    {
      title: 'DOB',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      width: 90,
      render: val => {
        return <div style={{ width: 90 }}>{newFormatDisplayDate(val)}</div>
      }
    },
    {
      title: 'Status',
      key: 'applications.status',
      dataIndex: 'applications.status',
      width: 150,
      render: (item, record) => {
        let { statusList } = record.applications
        return (
          <React.Fragment>
            <div
              className={'label label1'}
              style={{ background: statusColors(item), width: 80, padding: 3 }}>
              {item}
            </div>
            {/*{statusList && statusList.date ?
                            <div>
                                {newFormatDisplayDate(statusList.date)}
                            </div> : null}*/}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Draft',
      key: 'applications.draftApplication',
      dataIndex: 'applications.draftApplication',
      width: 100,
      render: (item, record) => {
        return item ? <Tag color='#f50'>Draft</Tag> : null
      }
    },
    {
      title: 'University',
      key: 'applications.courseUniversity',
      dataIndex: 'applications.courseUniversity',
      width: 180,
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.logo && item.logo.url ? (
              <Avatar src={item.logo.url} size={20} />
            ) : (
              <Avatar src={defaultLogoList.defaultUniversityLogo} size={20} />
            )}
            {item && item.universityName ? `  ${item.universityName}` : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Country',
      key: 'applications.universityCountry',
      dataIndex: 'applications.universityCountry',
      width: 150,
      render: (item, record) => {
        let { applications } = record
        return (
          <React.Fragment>
            {item && item.countryName ? item.countryName : null},{' '}
            {record && record.stateName ? record.stateName : null}
            <OshcFieldLabel countryId={item._id} applications={applications} />
          </React.Fragment>
        )
      }
    },
    {
      title: 'Program',
      key: 'applications.courseName',
      dataIndex: 'applications.courseName',
      width: 180,
      render: (item, record) => {
        return <div style={{ width: 150 }}>{item}</div>
      }
    },
    {
      title: 'Intake',
      key: 'applications.intake',
      dataIndex: 'applications.intake',
      width: 150,
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.month && item.year
              ? `${item.month}, ${item.year}`
              : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Pendency',
      key: 'applications.pendencyArr',
      dataIndex: 'applications.pendencyArr',
      // width: 100,
      width: 100,
      render: (item, record) => {
        return (
          <React.Fragment>
            <Tooltip title={'Add Pendency'}>
              <span
                onClick={() =>
                  viewOnlyRight ? events.openAddPendencyDrawer(record) : ''
                }
                className='badge'>
                {item && item.length ? getPendingPendency(item) : 0}
              </span>
            </Tooltip>{' '}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Verification',
      key: 'applications.verificationCertificate',
      hidden: agentTeamTypes.includes(user.userType),
      dataIndex: 'applications.verificationCertificate',
      width: 150,
      render: (item, record) => {
        return (
          <React.Fragment>
            {item ? (
              <label className={'label label-warning label-sm'}>{item}</label>
            ) : (
              ''
            )}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Current User',
      key: 'applications.assignedUserId',
      dataIndex: 'applications.assignedUserId',
      width: 180,
      render: (val, record) => {
        let {
          agentId,
          statusList,
          status,
          addByUserId,
          enrollment
        } = record.applications
        return (
          <React.Fragment>
            {val && val.name ? val.name : ''}
            <br />

            {agentId && agentId.companyName ? (
              <div>
                {user.department == departmentObj.assDepartment &&
                user.managerViewOnly
                  ? null
                  : `Agent : ${agentId.companyName}`}
                {!enrollment && showEnrollmentAction(statusList) ? (
                  <ShowEnrollmentComponent
                    rightUserType={['admin', 'branchUser']}>
                    <a onClick={() => agentEvents.editAgent(agentId)}>
                      <Icon type={'edit'} />
                    </a>
                  </ShowEnrollmentComponent>
                ) : null}
              </div>
            ) : (
              ''
            )}

            {addByUserId && addByUserId.name ? (
              <div className={'mt5 colorPrimary font12'}>
                (Added By :{' '}
                {addByUserId && addByUserId.name ? addByUserId.name : ''})
              </div>
            ) : (
              ''
            )}

            <CheckUserRight
              user={user}
              rightUserType={['admin', 'branchManager', 'branchUser']}>
              {agentId && agentId.mobile ? (
                <div>Mob : {agentId.mobile}</div>
              ) : (
                ''
              )}
            </CheckUserRight>
            <div>{longDisplayDate(statusList.date)}</div>
          </React.Fragment>
        )
      }
    },
    /* {
         title: 'Student Manager',
         dataIndex: 'branchManagerId',
         hidden: !managerViewRight.includes(user.userType),
         width: 125,
         key: 'branchManagerId',
         render: (item, record) => {
             let {branchManagerId} = record
             return (
                 <React.Fragment>
                     {branchManagerId ? branchManagerId.name : null}
                 </React.Fragment>
             )
         }
     }, */
    {
      title: 'Marketing',
      dataIndex: 'marketingUserId',
      hidden: !managerViewRight.includes(user.userType),
      width: 125,
      key: 'branchManagerId',
      render: (item, record) => {
        let { marketingUserId } = record.applications
        return (
          <React.Fragment>
            {marketingUserId && marketingUserId.name ? marketingUserId.name : null}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Manager',
      dataIndex: 'managers',
      hidden: !managerViewRight.includes(user.userType),
      width: 120,
      key: 'managers',
      render: (item, record) => {
        let { agentId, universityCountry } = record.applications
        return (
          <React.Fragment>
            {branchManagerList && branchManagerList.length && agentId ? (
              <AgentBranchManagerComponent
                userId={agentId._id}
                countryId={universityCountry._id}
                branchManagers={branchManagerList}
              />
            ) : null}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Commission',
      key: 'commission',
      dataIndex: 'applications.commission',
      hidden: commissionRight(),
      width: 180,
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.commissionAmount ? item.commissionAmount : null}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Latest Note',
      key: 'applications.latestComment',
      dataIndex: 'applications.latestComment',
      width: 250,
      render: val => {
        return (
          <div style={{ width: 200 }}>
            {val && val.text ? <RenderComment item={val} /> : null}
          </div>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 100,
      fixed: 'right',
      render: (val, record) => {
        let { applications } = record
        return (
          <div className={'actionBtnGroup'}>
            {!record.applications.directClosed && (
              <AllowComponentRightsWise rightUserType={['agent', 'subAgent', 'student', 'team']}>
                <Tooltip title={'View Application'}>
                  <a
                    className='btn'
                    href={`/application/view?appId=${
                      record.applications._id
                    }&studentId=${record._id}`}>
                    <img src='/dist/img/view.svg' alt='' className={'eyeBtn'} />
                  </a>
                </Tooltip>{' '}
              </AllowComponentRightsWise>
            )}

            <div>
              <AllowComponentRightsWise
                rightUserType={[
                  'admin',
                  'branchUser',
                  'branchManager',
                  'userManager',
                  'student'
                ]}>
                <Tooltip title={'View Application'}>
                  <a
                    className='btn'
                    href={`/student/application?studentId=${record._id}&appId=${
                      record.applications._id
                    }`}>
                    <img src='/dist/img/view.svg' alt='' />
                  </a>
                </Tooltip>
                <DraftButtonComponent
                  record={record}
                  callback={() => tableAppRef.current.reload()}
                />
                <ShowOshcDrawer
                  record={record}
                  callback={() => tableAppRef.current.reload()}>
                  <Tooltip title={'OSHC'}>
                    <button
                      className='btn'
                      onClick={() => {
                        events.showOshcDrawer()
                        setStudentData(record)
                      }}>
                      <img src='../dist/img/healthcare_dark.png' alt='' />
                    </button>
                  </Tooltip>
                </ShowOshcDrawer>
                {/* <ShowTuitionFeesCondition applications={applications}>
                                    <Tooltip title={'Update Tuition Fees'}>
                                        <button className='btn' onClick={() => {
                                            events.showTuitionFeeDrawer()
                                            setStudentData(record)
                                        }}>
                                            <img src={'../dist/img/tuition.png'}/>
                                        </button>
                                    </Tooltip>
                                </ShowTuitionFeesCondition>*/}
              </AllowComponentRightsWise>
              <CheckCaseCloseRight>
                {record.applications.status !== 'Case Close' && (
                  <Tooltip title={'Case Closed'}>
                    <Popconfirm
                      title={'Are your sure, you want to Close Case?'}
                      onConfirm={() => {
                        events.closeDirectApp(record)
                      }}
                      okText='Yes'
                      cancelText='No'>
                      <button className='btn'>
                        <img
                          src='dist/img/cross.svg'
                          alt=''
                          className={'crossBtn'}
                        />
                      </button>
                    </Popconfirm>
                  </Tooltip>
                )}
              </CheckCaseCloseRight>

              <AllowComponentRightsWise
                rightUserType={[
                  'admin',
                  'branchUser',
                  'branchManager',
                  'userManager'
                ]}>
                <Tooltip title={'View Other Application'}>
                  <button
                    className='btn'
                    onClick={() => {
                      events.openOtherAppDrawer(record)
                    }}>
                    <Icon type={'ordered-list'} />
                  </button>
                </Tooltip>
                <Tooltip title={'Add Note'}>
                  <button
                    className='btn'
                    onClick={() => {
                      events.openAddCommentDrawer(record)
                    }}>
                    <Icon type={'plus'} />
                  </button>
                </Tooltip>
              </AllowComponentRightsWise>
            </div>

            <AllowComponentUserWise
              rightUserType={[
                'admin',
                'branchUser',
                'branchManager',
                'userManager'
              ]}>
              <Tooltip title={'Transfer'}>
                <button
                  className='btn'
                  onClick={() => {
                    setStudentData(record)
                    events.showTransferApp()
                  }}>
                  <img src={'../dist/icons/forwardArrow.png'} />
                </button>
              </Tooltip>{' '}
            </AllowComponentUserWise>

            <AllowComponentUserWise
              rightUserType={['admin', 'branchManager', 'userManager']}>
              {record.applications.status == 'Case Close' ? (
                <Tooltip title={'Re-Open Case'}>
                  <button className='btn' onClick={() => reActivateApp(record)}>
                    <img src={'/assets/power-button.png'} />
                  </button>
                </Tooltip>
              ) : null}
            </AllowComponentUserWise>

            <ShowEnrollmentComponent
              rightUserType={['admin', 'branchUser', 'userManager']}>
              {enrollmentBtn(record)}
            </ShowEnrollmentComponent>

            <ShowEnrollmentComponent
              rightUserType={['admin', 'branchUser']}>
              {showEnrollmentBtn(record) ? (
                <Tooltip title={'Create Commission'}>
                  <button
                    className='btn'
                    onClick={() => {
                      setStudentData(record)
                      events.showUniversityCommission()
                    }}>
                    <img src={'../btnImgs/percentage.png'} />
                  </button>
                </Tooltip>
              ) : null}
            </ShowEnrollmentComponent>

            <AllowComponentUserWise
              rightUserType={['admin', 'branchManager', 'userManager']}>
              {record.applications.status !== 'Case Close' ? (
                <Tooltip title={'Change University'}>
                  <button
                    className='btn'
                    onClick={() => changeUniversityFxn(record)}>
                    <img src={'/assets/shuffle-arrows.png'} />
                  </button>
                </Tooltip>
              ) : null}
            </AllowComponentUserWise>
          </div>
        )
      }
    }
  ]

  const resp = useSelector(state => {
    let { loadNoteRedux } = state.chooseApplication
    if (loadNoteRedux) {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
      dispatch({ type: 'END_LOAD_NOTE' })
    }
  })

  let loadColumns = () => {
    let columnArr = []
    lodash.each(columns, item => {
      if (item.hidden == undefined) {
        columnArr.push(item)
      } else {
        if (!item.hidden) {
          columnArr.push(item)
        }
      }
    })
    return columnArr
  }

  const extra = (
    <Row gutter={12} className={'filter-row'}>
      <CheckUserRight
        user={user}
        rightUserType={['admin', 'branchManager', 'branchUser', 'userManager']}>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='Current User'
              onChange={item => setBranchUserId(item)}
              filterOption={filterOption}
              className={'antSelect'}
              allowClear={true}
              showSearch={true}
              placeholder={'Current User'}
              value={branchUserId || undefined}>
              {branchUserList && branchUserList.length
                ? branchUserList.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>
                      {item.name}
                    </Option>
                  )
                })
                : null}
            </Select>
          </div>
        </Col>
      </CheckUserRight>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select
            name='Search By University'
            onChange={item => setAppUniversityId(item)}
            filterOption={filterOption}
            className={'antSelect'}
            placeholder={'University'}
            allowClear={true}
            showSearch={true}
            value={appUniversityId || undefined}>
            {universityList && universityList.length
              ? universityList.map((item, key) => {
                return (
                  <Option value={item._id} key={key}>
                    {item.universityName}
                  </Option>
                )
              })
              : null}
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select
            name='Intake'
            onChange={item => setIntake(item)}
            filterOption={filterOption}
            className={'antSelect'}
            allowClear={true}
            showSearch={true}
            placeholder={'Intake'}
            value={intake || undefined}>
            {Intakes && Intakes.length
              ? Intakes.map((item, key) => {
                return (
                  <Option value={item} key={key}>
                    {item}
                  </Option>
                )
              })
              : null}
          </Select>
        </div>
      </Col>

      <CheckUserRight
        user={user}
        rightUserType={['admin', 'branchManager', 'branchUser', 'userManager']}>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='Search By Agent'
              onChange={item => setAgentId(item)}
              value={agentId || undefined}
              placeholder={'Agent'}
              filterOption={filterOption}
              className={'antSelect'}
              allowClear={true}
              showSearch={true}>
              {allAgent && allAgent.length
                ? allAgent.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>
                      {item.companyName}
                    </Option>
                  )
                })
                : null}
            </Select>
          </div>
        </Col>
      </CheckUserRight>

      <CheckUserRight
        user={user}
        rightUserType={['admin', 'branchUser', 'userManager']}>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='Search By Branch Manager'
              filterOption={filterOption}
              className={'antSelect'}
              allowClear={true}
              placeholder={'Branch Manager'}
              showSearch={true}
              onChange={item => setBranchManagerId(item)}
              value={branchManagerId || undefined}>
              {branchManagerList && branchManagerList.length
                ? branchManagerList.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>
                      {item.name}
                    </Option>
                  )
                })
                : null}
            </Select>
          </div>
        </Col>
      </CheckUserRight>
      <CheckUserRight
        user={user}
        rightUserType={['admin', 'branchUser', 'userManager']}>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='Search By Student Manager'
              filterOption={filterOption}
              className={'antSelect'}
              allowClear={true}
              placeholder={'Student Manager'}
              showSearch={true}
              onChange={item => setStudentManagerId(item)}
              value={studentManagerId || undefined}>
              {studentManagerList && studentManagerList.length
                ? studentManagerList.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>
                      {item.name}
                    </Option>
                  )
                })
                : null}
            </Select>
          </div>
        </Col>
      </CheckUserRight>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select
            name='Search By Draft'
            filterOption={filterOption}
            className={'antSelect'}
            allowClear={true}
            placeholder={'Draft Application'}
            onChange={item => setDraftApplication(item)}
            value={draftApplication || undefined}>
            {ConditionOptions && ConditionOptions.length
              ? ConditionOptions.map((item, key) => {
                return (
                  <Option value={item.name} key={key}>
                    {item.name}
                  </Option>
                )
              })
              : null}
          </Select>
        </div>
      </Col>


      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='search-box-table round ml0'>
          <input
            className='form-control'
            type='search'
            placeholder='Search by name...'
            value={name}
            onChange={e => {
              events.enterName(e.target.value)
            }}
          />
          <img src='/dist/img/search.png' alt='' />
        </div>
      </Col>
      {currentStatus &&
      (currentStatus == 'Visa Approved' || currentStatus == 'Case Approved') ? (
        <>
          <Col md={4} sm={4} xs={8} lg={4}>
            <RangePicker
              value={[
                fromDate ? moment(fromDate, 'YYYY-MM-DD') : null,
                toDate ? moment(toDate, 'YYYY-MM-DD') : null
              ]}
              className={'round-date-picker'}
              onChange={events.chooseDate}
            />
          </Col>
        </>
      ) : null}
      {currentStatus &&
      (currentStatus == 'Visa Approved' || currentStatus == 'Case Approved') &&
      (currentUser.userType !== 'agent' &&
        currentUser.userType !== 'subAgent') ? (
        <>
          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='sort-box-table custom-sort-box-new'>
              <Select
                name='Choose Udaan'
                onChange={item => {
                  setUdaan(item)
                }}
                value={udaan || undefined}
                placeholder={'Udaan'}
                className={'antSelect'}
                allowClear={true}>
                {udaanArr && udaanArr.length
                  ? udaanArr.map((item, key) => {
                    return (
                      <Option value={item.value} key={item.value}>
                        {item.name}
                      </Option>
                    )
                  })
                  : null}
              </Select>
            </div>
          </Col>
        </>
      ) : null}
      <Col md={24} sm={24} xs={24} lg={24} className={'alignRight'}>
        <Button onClick={() => searchFxn()} className={'roundBtn'}>
          Search
        </Button>
        <Button onClick={() => clearFxn()} className={'roundBtn'}>
          Clear
        </Button>
      </Col>
    </Row>
  )

  return (
    <React.Fragment>
      <div>
        <div className='row mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div
                className='table-head d-flex align-items-center all-student-search'
                style={{ height: 60 }}>
                <Button
                  onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                  className={'roundGoBackBtn'}
                  icon={'arrow-left'}>
                  Back
                </Button>
                <h5>All Application </h5>
                <div
                  className='search-box-table'
                  style={{ backgroundColor: 'transparent' }}>
                  {currentStatus} (Total - {totalApplication})
                </div>
                <div className='sort-box-table mark-btn'>
                  {/*'branchManager'*/}
                  <ShowExportOption
                    user={user}
                    rightUserType={['admin']}>
                    <span className={'sort-box-table-right'}>
                      <Tooltip title={'Export Excel'}>
                        {' '}
                        <Button
                          onClick={() => exportExcel()}
                          className={'roundBtn'}>
                          Export Excel
                        </Button>
                      </Tooltip>
                    </span>
                  </ShowExportOption>
                </div>
              </div>
              <div className='card-body table-responsive'>
                {extra}
                <TableComp
                  columns={loadColumns()}
                  rowKey={(item, index) => {
                    return index
                  }}
                  apiRequest={apiRequest}
                  pagination={{
                    position: 'top',
                    showSizeChanger: true,
                    pageSizeOptions: ['50', '100', '200', '500'],
                    defaultPageSize: 50,
                    current: filters.page ? filters.page : 1
                  }}
                  extraProps={{ scroll: { x: 2500 } }}
                  ref={tableAppRef}
                />
              </div>
            </div>
          </div>
        </div>

        {addPendencyDrawer ? (
          <SelectPendency
            visible={addPendencyDrawer}
            applicationObj={state.applicationObj}
            onAddPendencyClose={events.closeAddPendencyDrawer}
            onClose={events.reloadAndClose}
            reloadFxn={events.reloadFxn}
          />
        ) : null}
      </div>
      {transferAppVisible ? (
        <TransferApplicationDrawer
          visible={transferAppVisible}
          closeTransferDrawer={() => events.hideTransferApp()}
          studentId={studentData._id}
          studentData={studentData}
          application={studentData.applications}
        />
      ) : null}

      {visibleAddCourseDrawer ? (
        <ApplyCourse
          visible={visibleAddCourseDrawer}
          onClose={() => events.hideAddCourseDrawer()}
          refreshApplication={() => events.refreshApplication()}
          studentId={studentObj._id}
        />
      ) : null}

      {visibleOtherAppDrawer ? (
        <StudentOtherApp
          visible={visibleOtherAppDrawer}
          applicationObj={state.applicationObj}
          onStudentOtherAppClose={() => events.closeOtherAppDrawer()}
          reloadFxn={events.reloadFxn}
        />
      ) : null}

      {selectedApplication.visible ? (
        <ReActivateApplication
          {...selectedApplication}
          onClose={onCloseReActivate}
        />
      ) : null}

      {enrollmentObj.visible ? (
        <Enrollment {...enrollmentObj} onClose={onCloseEnrollmentApp} />
      ) : null}

      {changeUniversity.visible ? (
        <ChangeUniversityComponent
          {...changeUniversity}
          onClose={onCloseChangeUniversityFxn}
        />
      ) : null}

      {visibleAddCommentDrawer && applicationObj && applicationObj._id ? (
        <AddNote
          visible={visibleAddCommentDrawer}
          studentData={applicationObj}
          addedType={'direct'}
          reloadFxn={events.reloadFxn}
          onClose={() => events.closeAddCommentDrawer()}
          applicationId={applicationObj.applications._id}
        />
      ) : null}

      {updateUniversity.visible ? (
        <UpdateUniversityComponent
          {...updateUniversity}
          onClose={updateUniversityEvent.closeDrawer}
        />
      ) : null}

      {visibleUpdateUser ? (
        <EditUser
          visible={visibleUpdateUser}
          reloadTable={() => {
            setTimeout(() => {
              tableAppRef.current.reload()
            }, 1000)
          }}
          onClose={() => agentEvents.closeEditUser()}
          userData={selectedUser}
        />
      ) : (
        ''
      )}

      {visibleTuitionFee ? (
        <AddTuitionFeeDrawer
          visible={visibleTuitionFee}
          studentId={studentData._id}
          studentData={studentData}
          application={studentData.applications}
          reloadTable={() => {
            setTimeout(() => {
              tableAppRef.current.reload()
            }, 1000)
          }}
          onClose={() => events.hideTuitionFeeDrawer()}
        />
      ) : (
        ''
      )}

      {visibleOshcDrawer ? (
        <OshcDrawer
          visible={visibleOshcDrawer}
          studentData={studentData}
          onClose={() => events.hideOshcDrawer()}
        />
      ) : (
        ''
      )}

      {visibleUniversityCommission ? (
        <AddCommissionDrawer
          visible={visibleUniversityCommission}
          studentData={studentData}
          onClose={() => events.hideUniversityCommission()}
        />
      ) : (
        ''
      )}
    </React.Fragment>
  )
}
const mapStateToProps = ({ global, router, chooseApplication }) => ({
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryWiseApplicationList)
