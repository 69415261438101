import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
  Button,
  Popconfirm,
  Tooltip, Tag, Icon, Row, Col, Select
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { notification } from 'antd'
import {
  listAllCourse,
  exportCourseList,
  CourseIsFeatured,
  CourseIsSearchAble,
  deleteCourse,
  topCourseUpdateFxn
} from '../actions/courseAction'
import { getPushPathWrapper, getUrlPushWrapper } from '../../../routes'
import {
  ConditionOptions,
  Intakes,
  filterOption, FcmtCollegeOnshore
} from '../../../components/_utils/appUtils'
import { listAllUniversities } from '../../university/actions/university'
import { listAllCountries } from '../../countries/actions/countries'
import { push } from 'connected-react-router'
import { listAllCampus } from '../../campus/actions/campus'
import { CheckUserRight } from '../../dashboard/views/DashboardUserWise'
import CourseStructure from '../drawers/courseStructure'

const Option = Select.Option

const ListAllCourse = (props) => {
  let [allCampus, setAllCampus] = useState([])
  let [courseName, setCourseName] = useState('')
  let [universityId, setUniversityId] = useState(FcmtCollegeOnshore)
  let [campusId, setCampusId] = useState('')
  let [tableSearch, setTableSearch] = useState(false)
  let [total, setTotal] = useState(0)

  let [filters, setFilters] = useState({})
  const tableRef = useRef()
  const dispatch = useDispatch()

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let resp = await getParamsForFilter()

      if (resp.courseName) {
        params.courseName = resp.courseName
      }
      params.courseUniversity = FcmtCollegeOnshore
      if (resp.campusId) {
        params.campusName = resp.campusId
      }

      if (!params.results) {
        params.results = 50
        params.count = 50
      }

      params.sortField = 'courseName'
      params.sortOrder = 'ascend'
      if (tableSearch) {
        params.page = 1
      }
      setFilters(params)
      let resp1 = await dispatch(listAllCourse({
        ...params,
        regExFilters: ['courseName', 'courseDuration', 'courseLevel', 'tuitionFee']
      }))
      setTotal(resp1.total)
      resolve(resp1)
      setTableSearch(false)
    })
  }

  const [itemNo, setItemNo] = useState(1)
  useEffect(() => {
    events.setFieldByParams()
    dispatch(listAllCourse()).then(d => {
    }).catch(err => {
    })
  }, [])

  useEffect(() => {
    loadAllCampus()
  }, [universityId])


  const loadAllCampus = async () => {
    let { data } = await dispatch(listAllCampus({
      campusUniversity: universityId,
      results: 1000,
      sortField: 'campusName',
      sortOrder: 'ascend'
    }))
    setAllCampus(data)
  }

  const getParamsForFilter = () => {
    return new Promise((resolve) => {
      let searchParams = new URLSearchParams(window.location.search)
      let countryId = searchParams.get('countryId')
      let courseName = searchParams.get('courseName')
      let universityId = searchParams.get('universityId')
      let campusId = searchParams.get('campusId')
      let obj = {}
      if (countryId) {
        obj.countryId = countryId
      }
      if (courseName) {
        obj.courseName = courseName
      }
      if (universityId) {
        obj.universityId = universityId
      }
      if (campusId) {
        obj.campusId = campusId
      }
      resolve(obj)
    })
  }
  const events = {
    searchData: async () => {
      let obj = {}
      if (courseName) {
        obj.courseName = courseName
      }
      if (universityId) {
        obj.universityId = universityId
      }
      if (campusId) {
        obj.campusId = campusId
      }

      dispatch(
        getUrlPushWrapper('masters.allCourse', {
          ...obj
        }))
      setTableSearch(true)
      setTimeout(() => {
        tableRef.current.reload()
      }, 200)
    },
    setFieldByParams: async () => {
      let resp = await getParamsForFilter()
      if (resp.courseName) {
        setCourseName(resp.courseName)
      }
      if (resp.universityId) {
        setUniversityId(resp.universityId)
      }
      if (resp.campusId) {
        setCampusId(resp.campusId)
      }
    },
    goBack: () => {
      dispatch(getUrlPushWrapper('dashboard'))
    },
    clearFxn: () => {
      setUniversityId('')
      setCourseName('')
      dispatch(
        getUrlPushWrapper('masters.allCourse'))
      setTableSearch(true)
      setTimeout(() => {
        tableRef.current.reload()
      }, 200)

    }

  }

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (item, record, index) => {
        return (
          <a className={'linkPointer'} onClick={() => {
            dispatch(
              getPushPathWrapper('masters.editCourse', { id: record._id }))
          }}>
            {(itemNo - 1) * 10 + index + 1}
          </a>
        )
      }

    },
    {
      title: 'Name',
      dataIndex: 'courseName',
      width: 200,
      key: 'courseName',
      render: (item, record) => {
        return (
          <a className={'linkPointer'} onClick={() => {
            dispatch(
              getPushPathWrapper('masters.editCourse', { id: record._id }))
          }}>
            {item}
          </a>
        )
      }

    },

    {
      title: 'Category',
      dataIndex: 'categoryId',
      key: 'categoryId',
      width: 150,
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.name ? item.name : ''}
          </React.Fragment>
        )
      }
    },

    {
      title: 'Campus',
      dataIndex: 'campusName',
      width: 150,
      key: 'campusName',
      render: (value, record) => {
        return (
          <React.Fragment>

            {value && value.length ? <ul className={'countList'}>
              {
                value.map((item, key) => {
                  return (
                    <li key={key}>{item.campusName}</li>
                  )
                })
              }
            </ul> : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Duration',
      key: 'courseDuration',
      // width: 150,
      width: 150,
      dataIndex: 'courseDuration',
      searchTextName: 'courseDuration'
    },
    {
      title: 'Level',
      key: 'courseLevel',
      // width: 150,
      width: 150,
      dataIndex: 'courseLevel',
      searchTextName: 'courseLevel'
    },
    {
      title: 'Tuition Fee',
      dataIndex: 'tuitionFee',
      // width: 100,
      width: 150,
      key: 'tuitionFee',
      searchTextName: 'fundAcceptable'
    },
    {
      title: 'Intakes',
      dataIndex: 'intakes',
      width: 150,
      key: 'intakes',
      filterMultiple: false,
      filters: Intakes.map(x => ({ value: x, text: x })),
      render: (value, record) => {
        return (
          <React.Fragment>
            {value && value.length ? value.map((item, key) => {
              return (
                <Tag key={key}>{item}</Tag>
              )
            }) : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Course Intakes',
      dataIndex: 'courseIntakes',
      width: 150,
      key: 'courseIntakes',
      filterMultiple: false,
      // filters: Intakes.map(x => ({ value: x, text: x })),
      render: (value, record) => {
        return (
          <React.Fragment>
            {value && value.length ? value.map((item, key) => {
              return (
                <Tag key={key}>{item.month}, {item.year}</Tag>
              )
            }) : ''}
          </React.Fragment>
        )
      }
    },


    {
      key: 'actions',
      title: 'Actions',
      width: 80,
      render: (text, record) => {
        return (
          <div className={'pa-0'}>
            <Tooltip title='Edit'>
              <button className={'btn'}
                      onClick={() => {
                        dispatch(
                          getPushPathWrapper('masters.editCourse', { id: record._id }))
                      }}
              >
                <Icon type={'edit'} />
              </button>
            </Tooltip>
          </div>
        )
      }
    }
  ]


  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center'>
            <Button onClick={() => events.goBack()}
                    className={'roundGoBackBtn'} icon={'arrow-left'}>
              Back
            </Button>

            <h5>All Courses: {total || 0}</h5>

            <div className='sort-box-table mark-btn mark-btn40'>

            </div>

          </div>

          <div className='card-body table-responsive'>
            <Row gutter={16}>
              <Col span={4}>
                <div className='sort-box-table custom-sort-box-new'>
                  <Select placeholder={'Campus'}
                          value={campusId || undefined}
                          filterOption={filterOption}
                          className={'antSelect'}
                          allowClear={true}
                          showSearch={true}
                          onChange={(x) => setCampusId(x)}>
                    {allCampus && allCampus.length ? allCampus.map((item, key) => {
                      return (
                        <Option key={key} value={item._id}>{item.campusName}</Option>
                      )
                    }) : null}
                  </Select>
                </div>
              </Col>
              <Col span={5}>
                <div className='sort-box-table custom-sort-box-new'>
                  <div className='search-box-table round'>
                    <input className='form-control form-control' type='search'
                           placeholder='course name...' value={courseName} onChange={(e) => {
                      setCourseName(e.target.value)
                    }} />
                    <img src='/dist/img/search.png' alt='' />
                  </div>
                </div>
              </Col>

              <Col span={6}>
                <Button onClick={() => events.searchData()} className={'roundBtn'}>Search</Button>
                <Button onClick={() => events.clearFxn()} className={'roundBtn'}>
                  Clear
                </Button>
              </Col>

            </Row>
            <TableComp columns={columns}
                       apiRequest={apiRequest}
                       ref={tableRef}
                       extraProps={{ scroll: { x: 600 } }}
                       pagination={{
                         position: 'top',
                         pageSizeOptions: ['50', '100', '200', '500'],
                         defaultPageSize: 50,
                         current: filters.page ? filters.page : 1
                       }}

            />
          </div>
        </div>
      </div>

    </div>
  )
}

export default ListAllCourse
