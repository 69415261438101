import React from 'react'
import { Avatar, List } from 'antd'
import classNames from 'classnames'
import styles from './NoticeList.less'
import profileImg from '../../assets/profile-user.png'
import { displayDate } from '../_utils/appUtils'

export default function NoticeList({
                                     data = [],
                                     onClick,
                                     viewAll,
                                     onClear,
                                     title,
                                     locale,
                                     emptyText,
                                     emptyImage,
                                     showClear = true
                                   }) {
  if (data.length === 0) {
    return (
      <div className={styles.notFound}>
        {emptyImage ? <img src={emptyImage} alt="not found"/> : null}
        <div>{emptyText || locale.emptyText}</div>
      </div>
    )
  }
  return (
    <div>
      <List className={styles.list}>
        {data.map((item, i) => {
          const itemCls = classNames(styles.item, {
            [styles.read]: item.read
          })

          const leftIcon = <Avatar className={styles.avatar} src={profileImg}/>

          return (
            <List.Item className={itemCls} key={item.key || i} onClick={() => onClick(item)}>
              <List.Item.Meta
                className={styles.meta}
                avatar={<span className={styles.iconElement}>{leftIcon}</span>}
                title={
                  <div className={styles.title}>
                    <b>{item.studentName}</b>, &nbsp;
                    <label>(Id : {item.id})</label>
                    <div className={styles.appInfo}>
                      <span>
                        App Id : {item.applicationNo}
                      </span>
                      <span>
                        {displayDate(item.createdAt)}
                      </span>
                    </div>
                    {/*<div className={styles.extra}>{item.extra}</div>*/}
                  </div>
                }
                description={
                  <div>
                    <div className={styles.description} title={item.message}>
                      {item.message}
                    </div>
                    {/*<div className={styles.datetime}></div>*/}
                  </div>
                }
              />
            </List.Item>
          )
        })}
      </List>
      {showClear ? (
        <div className={styles.clear} onClick={viewAll}>
          {locale.clear} {title}
        </div>
      ) : null}
    </div>
  )
}
