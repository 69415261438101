import React, { useState, useEffect } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { Form, Select, Button, Card, notification, Spin, Row, Col, Input } from 'antd'
import _ from 'lodash'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { useDispatch, useSelector } from 'react-redux'
import { addCountry, listAllCountries } from '../actions/countries'
import { hideLoader, showLoader } from '../../../modules/actions'
import { DocumentTypes, InputBox, FileInput } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'


const AddCountry = (props) => {
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } } = props
  const dispatch = useDispatch()
  const [state, setState] = useState({
    flag: ''
  })
  const { loader } = useSelector(state => ({
    loader: state.countryReducers.loader
  }))

  useEffect(() => {
    dispatch({ type: 'COUNTRY_HIDELOADER' })
  }, [])

  const events = {
    chooseDocument: (e) => {
      let { name, files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          'flag': files[0]
        })
      }
    }
  }

  let fieldArr = [
    { key: 'countryName', label: 'Country Name', type: 'text', required: true },
    { key: 'currency', label: 'Currency', required: true },
    { key: 'livingCostMainApplicant', label: 'Living cost' },
    {
      key: 'flag', label: 'Flag', name: 'flag', type: 'file', onChange: (e) => {
        events.chooseDocument(e)
      }
    },
    {
      label: 'Interview',
      key: 'interview',
      type: 'select',
      options: ['Yes', 'No', 'Maybe'],
      onChange: x => {
        props.form.setFieldsValue({
          interview: x
        })
      }
    },
    {
      label: 'Required Document',
      key: 'documents',
      type: 'select',
      mode: 'multiple',
      options: DocumentTypes,
      onChange: x => {
        props.form.setFieldsValue({
          documents: x
        })
      }
    }
  ]

  let inputTypes = {
    fields: [
      { key: 'countryName', label: 'Country Name', type: 'text', required: true },
      { key: 'enrollmentBasic', label: 'Enrollment Basic' },
      { key: 'enrollmentProcedure', label: 'Enrollment Procedure' },
      { key: 'fundAcceptable', label: 'Fund Acceptable' },
      { key: 'fundSponsors', label: 'Fund Sponsors' },
      { key: 'fundEducation', label: 'Fund Education' },
      { key: 'fundVisaCheckList', label: 'Fund visa checkList' },
      { key: 'visaCategory', label: 'Visa Category' },
      {
        label: 'Interview',
        key: 'interview',
        type: 'select',
        options: ['Yes', 'No', 'Maybe'],
        onChange: x => {
          props.form.setFieldsValue({
            interview: x
          })
        }
      },
      { key: 'visaFillingBy', label: 'Visa Filling By' }
    ]
  }
  let rightInputTypes = {
    fields: [
      { key: 'currency', label: 'Currency', required: true },
      { key: 'tutionFee', label: 'Tution Fee', type: 'number' },
      { key: 'singleOHSC', label: 'Single OHSC' },
      { key: 'dualOHSC', label: 'Dual OHSC' },
      { key: 'visaFeeMainApplicant', label: 'Visa fee for main applicant', type: 'number' },
      { key: 'visaFeeSpouse', label: 'Visa fee for spouse', type: 'number' },
      { key: 'visaFeeChild', label: 'Visa fee for child', type: 'number' },
      { key: 'livingCostMainApplicant', label: 'Living cost for main applicant' },
      { key: 'livingCostSpouse', label: 'Living cost for spouse' },
      {
        label: 'Required Document',
        key: 'documents',
        type: 'select',
        mode: 'multiple',
        options: DocumentTypes,
        onChange: x => {
          props.form.setFieldsValue({
            documents: x
          })
        }
      }

    ]
  }

  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = e => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let fd = new FormData()
        fd.append('obj', JSON.stringify(valData))
        fd.append('flag', state.flag)
        let data = await dispatch(addCountry(fd))
        if (data && !data.error) {
          form.resetFields()
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }
  return (
    <div className="row">
      <div className="col-lg-12">
        <Form onSubmit={handleSubmit}>
          <div className="form-box mt-4">

            <div className="d-flex align-items-center mb-3 heading-form">
              <h5>Add New Country</h5>
            </div>

            <div className="card unizportal">
              <Row gutter={24}>
                {fieldArr.map((item, key) => {
                  return (
                    <Col span={8} key={key}>
                      {item.type == 'file' ?
                        <FileInput name={item.name}
                                   key={state.flag}
                                   label={item.label}
                                   chooseDocument={item.onChange}/>
                        : <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout}/>
                      }
                    </Col>
                  )
                })}
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="btn">
                  SAVE
                  </Button>
                </Form.Item>

              </div>
          </div>

        </Form>
      </div>
    </div>
  )
}
const WrappedCountry = Form.create()(AddCountry)
export default WrappedCountry
