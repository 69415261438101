import {apiUrl} from '../../../settings'

export const addBankAccountUrl = () => {
    return apiUrl + '/addBankAccount'
}

export const addAccountSendOtpUrl = () => {
    return apiUrl + '/addAccountSendOtp'
}

export const listAccountDetailsUrl = () => {
    return apiUrl + '/bankAccount'
}

export const deleteBankAccountUrl = () => {
    return apiUrl + '/deleteBankAccount'
}

export const updateBankAccountUrl = () => {
    return apiUrl + '/updateBankAccount'
}
export const emailAccountUrl = () => {
    return apiUrl + '/emailAccount'
}

export const addEmailAccountUrl = () => {
    return apiUrl + '/addEmailAccount'
}

export const deleteEmailAccountUrl = () => {
    return apiUrl + '/deleteEmailAccount'
}


export const agentAddBankAccountUrl = () => {
    return apiUrl + '/agent/addBankAccount'
}


export const agentUpdateBankAccountUrl = () => {
    return apiUrl + '/agent/updateBankAccount'
}
