import React, { useState, useEffect, useRef } from 'react'
import { Button, Tooltip, Icon, Col, Row, Select } from 'antd'
import { useDispatch } from 'react-redux'
import { getStudentByCounsellorFxn } from './actions/counselling'
import { TableComp } from 'sz-react-utils-lite'
import { departmentObj, filterOption, InputBox } from '../../components/_utils/appUtils'
import { DefaultTablePagination } from '../../components/_utils/appUtils'
import { displayDate } from '../../components/_utils/appUtils'
import StudentCounselling from './addStudentCounsellingDrawer'
import { listAllUsers } from '../users/actions/user'
import AppListForCounsellor from '../applications/view2/AppListForCounsellor'

let { Option } = Select
const StudentCounsellingList = (props) => {
  let { user } = props
  const [state, setState] = useState({
    studentObj: {},
    viewCourseDrawer: false
  })

  let [instituteList, setInstituteList] = useState([])
  let [total, setTotal] = useState(0)
  const [searchData, setSearchData] = useState({
    studentName: '',
    instituteId: ''

  })
  const [showCounsellingDrawer, setShowCounsellingDrawer] = useState({
    visible: false,
    studentName: '',
    studentId: ''
  })
  const dispatch = useDispatch()
  const tableRef = useRef()


  const apiRequest = (params) => {
    return new Promise(async (resolve) => {

      if (searchData.studentName) {
        params.studentName = searchData.studentName
      }
      if (searchData.instituteId) {
        params.instituteId = searchData.instituteId
      }
      if (user) {
        params.counsellorId = user._id
      }
      let resp = await dispatch(getStudentByCounsellorFxn({
        ...params, regExFilters: ['studentName']
      }))
      setTotal(resp.total)
      resolve(resp)
    })
  }

  useEffect(() => {
    events.loadInstituteList()
  }, [])
  const events = {
    openCounsellingDrawer: (studentId, studentName) => {
      setShowCounsellingDrawer({
        visible: true,
        studentId: studentId,
        studentName: studentName
      })
    },
    closeCounsellingDrawer: () => {
      setShowCounsellingDrawer({
        visible: false,
        studentId: '',
        studentName: ''
      })
    },
    updateFilter: (data) => {
      setSearchData(prevSearchData => ({
        ...prevSearchData,
        ...data
      }))
    },
    searchFxn: async () => {
      tableRef.current.reload()
    },
    clearFxn: async () => {
      setSearchData({
        studentName: '',
        instituteId: ''
      })
      setTimeout(() => {
        tableRef.current.reload()
      }, 500)
    },
    loadInstituteList: async () => {
      let obj = {
        userType: 'institute',
        results: 1000,
        count: 1000,
        select: ['companyName', 'name']
      }
      let { data } = await dispatch(listAllUsers(obj))
      setInstituteList(data)
    },
    viewCourse: (record) => {
      let { _id } = record
      setState({
        ...state,
        viewCourseDrawer: true,
        studentObj: { _id: _id.studentId, name: record.studentName }
      })
    },
    viewCourseClose: () => {
      setState({
        ...state,
        viewCourseDrawer: false,
        studentObj: {}
      })
    },
    reloadTable: () => {
      if (tableRef && tableRef.current) {
        tableRef.current.reload()
      }
    }
  }

  const columns = [
    {
      title: 'Student Name',
      dataIndex: 'studentName',
      key: 'studentName',
      searchTextName: 'studentName'
    },
    {
      title: 'Email',
      dataIndex: '_id.email',
      key: '_id.email'
    },
    {
      title: 'Date Of Birth',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      render: (val, record) => {
        return (
          <>
            {record._id && record._id.dateOfBirth ?
              displayDate(record._id.dateOfBirth) : null}
          </>
        )
      }
    },
    {
      title: 'State',
      dataIndex: '_id.stateName',
      key: '_id.stateName'
    },
    {
      title: 'Mobile No',
      dataIndex: 'mobile',
      key: 'mobile',
      render: (val, record) => {
        return (
          <>
            {record._id.countryCode} {record._id.mobile}
          </>
        )
      }
    },
    {
      title: 'Added On',
      dataIndex: '_id.createdAt',
      key: '_id.createdAt',
      render: (item) => {
        return item ? displayDate(item) : null
      }
    },
    {
      title: 'Institute Name',
      dataIndex: 'instituteId.companyName',
      key: 'instituteId.companyName',
      width: 350,
      render: (val, record) => {
        let { instituteId } = record
        return (
          <>
            {val}
            {instituteId ? <>
              {instituteId.email ? <div>{instituteId.email}</div> : null}
              {instituteId.mobile ? <div>Mobile : {instituteId.mobile}</div> : null}
            </> : null}
          </>
        )
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <Tooltip title='View Student Details'>
            <a className={'btn btn-default xs roundNew'}
               onClick={() => events.openCounsellingDrawer(record._id, record.studentName)}>
              View Counsellings
            </a>
          </Tooltip>
          <br />
          <Tooltip title={'View Application'}>
            <a className={'btn btn-default xs roundNew'} onClick={() => events.viewCourse(record)}>
              View Applications
            </a>
          </Tooltip>
        </>
      )
    }
  ]

  return (
    <div className='row'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head  align-items-center all-student-search'>
            <h6>Student Counselling List <small>(Total : {total})</small>: </h6>
          </div>

          <div className='card-body table-responsive'>
            <Row gutter={12} className={'filter_box'}>
              <Col span={6}>
                <InputBox title={'Search by name'}>
                  <input
                    value={searchData.studentName}
                    placeholder={'Student Name'}
                    onChange={({ target }) => {
                      events.updateFilter({ studentName: target.value })
                    }}
                  />
                </InputBox>
              </Col>

              {user.userType !== 'institute' ? <Col span={6}>
                <InputBox title={'Search by Institute'}>
                  <Select
                    name='Search by Institute'
                    filterOption={filterOption}
                    allowClear={true}
                    showSearch={true}
                    placeholder={'Search by Institute'}
                    onChange={item => {
                      events.updateFilter({ instituteId: item })
                    }}
                    value={searchData.instituteId || undefined}>
                    {instituteList && instituteList.length
                      ? instituteList.map((item, key) => {
                        let newName = `${item.companyName} (${item.name})`
                        return (
                          <Option value={item._id} key={item._id}>
                            {newName}
                          </Option>
                        )
                      })
                      : null}
                  </Select>
                </InputBox>
              </Col> : null}

              <Col span={24}>
                <Button className='clearCounselling' onClick={events.searchFxn}>
                  Search
                </Button>
                <Button className='clearCounselling ml10' onClick={events.clearFxn}>
                  Clear
                </Button>
              </Col>
            </Row>

            <TableComp
              columns={columns}
              apiRequest={apiRequest}
              ref={tableRef}
              pagination={DefaultTablePagination()}
            />
          </div>
        </div>
      </div>
      {showCounsellingDrawer.visible ? (
        <StudentCounselling
          user={props.user}
          {...showCounsellingDrawer}
          onClose={events.closeCounsellingDrawer}
          visible={showCounsellingDrawer.visible}
          studentId={showCounsellingDrawer.studentId}
          studentname={showCounsellingDrawer.studentName}
        />
      ) : null}


      {state.viewCourseDrawer ? <AppListForCounsellor
        visible={state.viewCourseDrawer}
        pageType={'student'}
        reloadTable={() => events.reloadTable()}
        onClose={() => events.viewCourseClose()}
        studentObj={state.studentObj} /> : null}


    </div>
  )
}

export default StudentCounsellingList
