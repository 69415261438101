import React, {useEffect, useState} from "react"
import {Drawer, notification, Popconfirm, Modal} from "antd";
import {TableComp} from "sz-react-utils-lite";
import lodash from "lodash"
import {RowTable} from "../../../components/_utils/RowTable";

const {confirm} = Modal;
const ApproveAgentCommission = (props) => {
    let {commission, visible, onClose, events, onSubmit} = props;
    let [commissionValue, setCommissionValue] = useState(0)
    useEffect(() => {
        calculateCommission()
    }, [commission])
    let calculateCommission = () => {
        let commissionAmount = 0;
        lodash.each(commission.commissionList, (item) => {
            if (item) {
                if (commission.bonus) {
                    commissionAmount = parseFloat(commissionAmount) + parseFloat(item.approvedByAdminCommission)
                } else {
                    commissionAmount = parseFloat(commissionAmount) + parseFloat(item.commission)
                }
            }
        })
        setCommissionValue(commissionAmount);
    }

    let columns = [
        {
            title: "Student", key: "name", dataIndex: "name"
        },
        {
            title: "Commission", key: "commission", dataIndex: "commission", render: (item) => {
                return `$${item} ${commission.currency}`
            }
        },
        {title: "Currency Rate", key: "currencyRate", dataIndex: "currencyRate"},
        {
            title: "Approved By Admin",
            key: "approvedByAdminCommission",
            dataIndex: "approvedByAdminCommission",
            hidden: !commission.bonus,
            render: (item) => {
                return `$${item} ${commission.currency}`
            }
        },
    ]

    const loadColumns = () => {
        let columnArr = []
        lodash.each(columns, (item) => {
            if (item.hidden == undefined) {
                columnArr.push(item)
            } else {
                if (!item.hidden) {
                    columnArr.push(item)
                }
            }
        })
        return columnArr
    }

    const approveCommissionFxn = () => {
        confirm({
            width: "35%",
            title: 'Are you sure, you want to approve commission?',
            // content: 'Some descriptions',
            okText: "Approve Commission",
            onOk() {
                if (commissionValue == commission.finalCommissionInCurrency) {
                    events.approvedAgentCommission(commission)
                    setTimeout(() => {
                        onSubmit()
                    }, 100)
                } else {
                    notification.error({message: "Commission mismatch"})
                }
            },
            onCancel() {
            },
        });

    }
    return (
        <>
            <Drawer visible={visible} onClose={onClose} title={'Approve Commission'} width={'45%'}>
                <div className={'card unizportal'}>
                    <TableComp dataSource={commission.commissionList} bordered={true} size={'small'}
                               columns={loadColumns()}/>

                    {commissionValue !== commission.finalCommissionInCurrency ?
                        <p className={'mismatchNote'}>
                            Note :- Commission amount mismatch
                        </p> : null}

                    <div className={'tableBox striped mt20'}>
                        <RowTable title={'Total Commission'}
                                  value={`$${commission.finalCommissionInCurrency} ${commission.currency}`}/>
                    </div>
                    <button
                        onClick={approveCommissionFxn}
                        className='btn btn-success btn-sm'>Approve Commission
                    </button>
                </div>

            </Drawer>
        </>
    )
}
export default ApproveAgentCommission
