import React, {useState, useEffect, useRef} from 'react'
import {
    Tag, Tooltip,
} from 'antd'
import {useDispatch} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {allMeetingListFxn} from '../actions/scheduleMeeting'
import {
    DefaultTablePagination,
    departmentObj,
    displayDate,
    displayTime,
    ScheduleTypes
} from '../../../components/_utils/appUtils'

import moment from 'moment'
import {listAllUsers} from "../../users/actions/user";

let statusList = ['Pending', 'Approved', 'Rejected']
const ListAllScheduleMarketingMeeting = () => {
    const tableRef = useRef()
    const dispatch = useDispatch()
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let resp = await dispatch(allMeetingListFxn({
                ...params,
                regExFilters: ['userIds', 'studentIds', 'guestUsers', 'topic']
            }))
            setTotalScheduleMeeting(resp.total)
            resolve(resp)
        })
    }
    const [totalScheduleMeeting, setTotalScheduleMeeting] = useState(0)
    const [userList, setUserList] = useState([])
    const loadUser = async () => {
        let params = {
            results: 100,
            count: 100,
            block: false,
            department: departmentObj.marketing,
            select: ['name', 'email', 'userType']
        }
        let {data} = await dispatch(listAllUsers(params))

        setUserList(data)
    }


    useEffect(() => {
        loadUser()
    }, [])


    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 60,
            render: (item, record, index) => {
                return index + 1;
            }
        },
        {
            title: 'Worked Today',
            dataIndex: 'workedToday',
            key: 'workedToday',
            width: 200,
            render: val => {
                return (
                    <>
                        {val == 'No' && 'Didn\'t worked today'}
                        {val == 'Yes' && 'Worked today'}
                    </>
                )
            }
        },

        {
            title: 'Minutes of meeting',
            dataIndex: 'meetingDiscussion',
            key: 'meetingDiscussion'
        },

        {
            title: 'Agent',
            dataIndex: 'agent',
            key: 'agent',
            render: (item, record) => {
                return item && item.companyName ? (
                    <>
                        {item.name} ({item.companyName})<br/>
                        <strong>Mobile No: </strong> {item.contactNo}
                        <br/>
                        <strong>Email: </strong>
                        {item.email}
                        <br/>
                        {item.countryName && (
                            <span>
                                {item.countryName}
                            </span>
                        )}
                        {item.stateName && (
                            <span>,&nbsp;
                                {item.stateName}
                            </span>
                        )}
                        {item.cityName && (
                            <span>,&nbsp;
                                {item.cityName}
                            </span>
                        )}
                    </>
                ) : null
            }
        },
        {
            title: 'Date/Time',
            dataIndex: 'fromTime',
            key: 'fromTime',
            width: 150,
            render: (item, record) => {
                return (
                    <>
                        {record && record.date ? displayDate(record.date) : null}
                        <br/>
                        {item ? `Time : ${displayTime(item)}` : null}
                    </>
                )
            }
        },
        {
            title: 'Photos',
            dataIndex: 'photos',
            key: 'photos',
            render: value => {
                return (
                    <>
                        {value && value.length ? (
                            <>
                                <ul>
                                    {value.map((item, key) => {
                                        return (
                                            <li key={key}>
                                                <a href={item.url} target={'_blank'}
                                                   className={'btn btn-default roundNew xs'}>
                                                    {item.name}
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </>
                        ) : null}
                    </>
                )
            }
        },
        {
            title: 'Reason',
            dataIndex: 'notes',
            key: 'notes',
            width: 200,
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: 100,
            render: (item, record) => {
                return (
                    <div className={'actionBtnGroup'}>
                        <div>


                        </div>
                    </div>
                )
            }
        }
    ]


    return (
        <div>

            <div className="row mt-4">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="table-head d-flex align-items-center">
                            <h5>All Meetings: {totalScheduleMeeting || 0}</h5>
                            <div className="search-box-table">

                            </div>

                            <div className="sort-box-table mark-btn">

                            </div>
                        </div>
                        <div className="card-body table-responsive">
                            <TableComp columns={columns}
                                       apiRequest={apiRequest}
                                       ref={tableRef}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ListAllScheduleMarketingMeeting
