import React, { useEffect, useState } from 'react'
import { Drawer, Form, Button, Select, Input, Row, Col, notification } from 'antd'
import { useDispatch } from 'react-redux'
import { InputBox } from '../../../components/_utils/appUtils'
import { cambieOnshoreLoaLetterFxn, uploadAgentInvoiceFxn } from './actions'
import TextArea from 'antd/es/input/TextArea'
import { cambieAgentListFxn } from '../../CambieApp/actions'

const UploadAgentInvoice = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, onSubmit, studentId, applicationId } = props
  let [file, setFile] = useState('')

  const handleSubmit = async () => {
    let obj = {
      applicationId: applicationId, studentId: studentId
    }
    if (!file || (file && !file.name)) {
      notification.warning({ message: 'Please choose Letterhead Invoice.' })
      return
    }
    let fd = new FormData()
    fd.append('obj', JSON.stringify(obj))
    if (file && file.name) {
      fd.append("letterhead", file);
    }
    let resp = await dispatch(uploadAgentInvoiceFxn(fd))
    if (resp && resp.success) {
      onSubmit()
    }
  }


  return (
    <>
      <Drawer
        title={'Uploaded Letterhead Invoice'}
        visible={visible} width={'45%'}
        onClose={onClose}
      >

        <div className='form-box'>
          <div className='card unizportal'>
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                  <InputBox title={'Choose Letterhead Invoice'}>
                    <input
                      className={'form-control'}
                      type={'file'}
                      onChange={({ target }) => {
                        if (target.files && target.files.length) {
                          setFile(target.files[0])
                        } else {
                          setFile('')
                        }
                      }} />
                  </InputBox>
                </Col>
              </Row>
              <Button
                type='primary'
                htmlType='submit'
                className='btn'
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  )
}
export default UploadAgentInvoice
