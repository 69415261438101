import React, { Component } from 'react'
import {
  Card,
  Input, Button, Icon, Drawer, Tag
} from 'antd'
import { connect } from 'react-redux'
import { getPushPathWrapper } from '../../../routes'
import { ColumnWidth } from '../../WebComponent/columnWidth'
import { getUserLogs } from '../actions/user'
import { TableComp } from 'sz-react-utils-lite'
import { DefaultTablePagination } from '../../../components/_utils/appUtils'
import { displayDate, displayTime } from '../../../components/_utils/appUtils'
import moment from 'moment'

class UserLogs extends Component {

  constructor(props) {
    super(props)
    this.state = {}
    this.tableRef = React.createRef()
  }

  componentDidMount() {
  }

  apiRequest = (params) => {
    let { dispatch, user } = this.props
    return new Promise(async (resolve) => {
      params.sortField = 'name'
      params.sortOrder = 'ascend'
      params.userId = user._id
      resolve(getUserLogs({
        ...params,
        regExFilters: ['name', 'address', 'mobile', 'email']
      }))
    })
  }

  render() {
    let {} = this.state
    let { dispatch, visible, onClose, user } = this.props
    const columns = [
      {
        title: '#',
        key: '_id',
        dataIndex: '_id',
        width: 50,
        render: (ite, record, index) => {
          return (
            <React.Fragment>
              {index + 1}
            </React.Fragment>
          )
        }
      },
      {
        title: 'Type',
        key: 'logsFrom',
        dataIndex: 'logsFrom',
        width: 100,
        render: (item) => {
          return (
            <ColumnWidth width={100}>{item}</ColumnWidth>
          )
        }
      },
      {
        title: 'Login Time',
        dataIndex: 'loginTime',
        key: 'loginTime',
        width: 120,
        render: (item, record) => {
          return (
            <div>
              {displayDate(item)} <strong>{displayTime(item)}</strong>
            </div>
          )
        }
      },
      {
        title: 'Logout Time',
        dataIndex: 'logoutTime',
        key: 'logoutTime',
        width: 120,
        render: (item) => {
          return (
            item ? <div>
              {displayDate(item)} <strong>{displayTime(item)}</strong>
            </div> : null
          )
        }
      }
    ]
    let title = `Logs - ${user.name} ${user.email ? ` (${user.email})` : null}`
    return (
      <Drawer visible={visible}
              title={title}
              width={'50%'}
              onClose={onClose}>
        <div className='card'>
          <div className='card-body table-responsive'>
            <TableComp columns={columns}
                       ref={this.tableRef}
                       pagination={DefaultTablePagination()}
                       apiRequest={this.apiRequest}
            />
          </div>
        </div>
      </Drawer>
    )

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserLogs)
