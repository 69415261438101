import {
  addAgentRightFxn,
  blockAgent,
  listAllUsers,
  updateInsuranceRight
} from '../../users/actions/user'

import { Button, Col, Row, Select, Tag, Tooltip, notification, Popconfirm } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  dealUpdateByUser,
  DefaultTablePagination,

  departmentObj,
  filterOption,
  longDisplayDate
} from '../../../components/_utils/appUtils'
import { ColumnWidth } from '../../WebComponent/columnWidth'
import CheckActiveUser from '../../users/list/activeCheck'

import _ from 'lodash'
import moment from 'moment'
import {
  LoadAllCountry,
  LoadCity,
  LoadState
} from '../../../components/_utils/countryUtil'
import MobileEmailInfo from '../../../components/commonComponents/mobileEmailInfo'
import { getUrlPushWrapper } from '../../../routes'
import { listAllCountries } from '../../countries/actions/countries'
import {
  getUniversityFoDealCloseFxn,
  listAllMarketingAgents
} from '../actions/closeDeal'
import CloseDeal from '../drawer/closeDeal'
import UpdateDealClose from '../drawer/updateDealClose'
import RequestForConsent from '../drawer/requestForConsent'
import DirectUniversityRights from "../../users/components/directUniversityRights";


const { Option } = Select

class AllMarketingAgents extends Component {
  events = {
    hideApplicationDrawer: () => {
      this.setState({
        visibleApplicationDrawer: false
      })
    },
    showApplicationDrawer: data => {
      this.setState({
        visibleApplicationDrawer: true,
        queryData: {
          agentId: data._id
        }
      })
    },

    hideUserRightDrawer: () => {
      this.setState({
        visibleUserRightDrawer: false,
        selectedUser: {}
      })
    },

    chooseCountry: name => {
      let countryName = _.find(this.state.allCountries, item => {
        return item.name == name
      })
      if (countryName) {
        this.setState({
          countryName: countryName.name ? countryName.name : '',
          stateName: undefined,
          cityName: undefined,
          pincode: undefined,
          allStates: countryName.id ? LoadState(countryName.id) : []
        })
      }
    },
    chooseState: name => {
      let stateName = _.find(this.state.allStates, item => {
        return item.name == name
      })
      if (stateName) {
        this.setState({
          stateName: stateName.name ? stateName.name : '',
          cityName: undefined,
          pincode: undefined,
          allCities: stateName.id ? LoadCity(stateName.id) : []
        })
      }
    },
    chooseCity: name => {
      this.setState({
        cityName: name ? name : ''
      })
    },
    showDealClose: data => {
      this.setState({
        dealCloseState: {
          visible: true,
          agentId: data._id,
          dealClose: data.dealClose
        }
      })
    },

    hideDealClose: () => {
      this.setState({
        dealCloseState: {
          visible: false,
          agentId: '',
          dealClose: []
        }
      })
    },
    showCloseDealUpdate: data => {
      this.setState({
        dealUpdateState: {
          visible: true,
          agentId: data._id,
          dealClose: data.dealClose
        }
      })
    },
    hideCloseDealUpdate: () => {
      this.setState({
        dealUpdateState: {
          visible: false,
          agentId: '',
          dealClose: []
        }
      })
    },

    showRequestConsentDeal: data => {
      this.setState({
        requestState: {
          visible: true,
          agentId: data._id,
          dealClose: data.dealClose
        }
      })
    },
    hideRequestConsentDeal: () => {
      this.setState({
        requestState: {
          visible: false,
          agentId: '',
          dealClose: []
        }
      })
    },
    showRightDrawer: (data) => {
      this.setState({
        rightState: {
          visible: true,
          agentData: data
        }
      })
    },
    hideRightDrawer: () => {
      this.setState({
        rightState: {
          visible: false,
          agentData: {}
        }
      })
    },

  }

  constructor(props) {
    super(props)
    this.state = {
      viewInfoDrawer: false,
      selectedRow: {},
      allAgents: [],
      branchManager: [],
      selectedUser: {},
      visibleUpdatePassword: false,
      visibleUpdateUser: false,
      visibleUpdateSubAgent: false,
      visibleApplicationDrawer: false,
      queryData: {},
      visibleUserRightDrawer: false,
      approvedAgentRightsUser: [],
      universityFoDealClose: [],
      zeroRecordAgent: false,
      filters: {},
      total: 0,
      countryName: '',
      stateName: '',
      cityName: '',
      pincode: '',
      allCountries: LoadAllCountry(),
      allStates: [],
      allCities: [],
      allMarketingUser: [],
      keyMoment: moment(),
      countryList: [],
      armUserData: [],

      dealCloseState: {
        visible: false,
        agentId: '',
        dealClose: []
      },
      dealUpdateState: {
        visible: false,
        agentId: '',
        dealClose: []
      },
      marketingState: {
        visible: false,
        agentId: '',
        agentMarketing: {}
      },
      requestState: {
        visible: false,
        agentId: '',
        dealClose: []
      },
      rightState: {
        visible: false,
        agentData: {}
      },
    }
    this.tableRef = React.createRef()
  }

  componentDidMount() {
    this.loadRightUser()
    this.loadBranchManager()
    this.loadMarketingUser()
    this.setFieldByParams()
    this.loadCountry()
    this.getUniversityFoDealClose()
  }

  loadCountry = async () => {
    let { dispatch } = this.props
    let countryFilter = {
      results: 10,
      sortField: 'countryName',
      sortOrder: 'ascend'
    }
    let { data } = await dispatch(listAllCountries(countryFilter))
    this.setState({
      countryList: data
    })
  }

  async loadBranchManager() {
    let { dispatch } = this.props
    let obj = {
      userType: 'branchManager',
      customQuery: {
        'agents.0': { $exists: true }
      },
      results: 100,
      select: ['name', 'email', 'mobile']
    }
    let { data } = await dispatch(listAllUsers(obj))
    this.setState({ branchManager: data })
  }

  async loadAllAgent() {
    let { dispatch } = this.props
    let { data } = await dispatch(listAllUsers({ userType: 'agent' }))
    this.setState({ allAgents: data })
  }

  async loadMarketingUser() {
    let { dispatch } = this.props
    let obj = {
      userType: 'branchUser',
      select: ['name', 'mobile'],
      customQuery: {
        department: departmentObj.marketing
      },
      results: 100
    }
    let { data } = await dispatch(listAllUsers(obj))
    this.setState({ allMarketingUser: data })
  }

  loadRightUser = async () => {
    let params = {
      results: 200,
      approvedAgent: true,
      select: ['name', 'userType']
    }
    let { data } = await this.props.dispatch(listAllUsers(params))
    this.setState({ approvedAgentRightsUser: data })
  }
  getUniversityFoDealClose = async () => {
    let { data } = await this.props.dispatch(getUniversityFoDealCloseFxn({}))
    this.setState({ universityFoDealClose: data })
  }

  viewInfo(record) {
    this.setState({
      selectedRow: record,
      viewInfoDrawer: true
    })
  }

  onClose = () => {
    this.setState({
      selectedRow: {},
      viewInfoDrawer: false
    })
  }

  closeUpdatePassword = () => {
    this.setState(
      {
        selectedUser: {},
        visibleUpdatePassword: false
      },
      () => {
        this.tableRef.current.reload()
      }
    )
  }

  closeEditUser = () => {
    this.setState(
      {
        selectedUser: {},
        visibleUpdateUser: false
      },
      () => {
        this.tableRef.current.reload()
      }
    )
  }

  closeEditSubAgent = () => {
    this.setState(
      {
        selectedUser: {},
        visibleUpdateSubAgent: false
      },
      () => {
        this.tableRef.current.reload()
      }
    )
  }
  updateAgentBlockStatus = async id => {
    let { dispatch } = this.props
    let resp = await dispatch(blockAgent({ agentId: id }))
    if (resp && resp.success) {
      this.tableRef.current.reload()
    }
  }
  updateAgentRight =  async (userId, valData) => {
    let {dispatch} = this.props;
  let resp = await dispatch(addAgentRightFxn({ userId: userId, ...valData }))
    if (resp && resp.success) {
      this.tableRef.current.reload()
    }
}

  apiRequest = params => {
    let { dispatch } = this.props
    return new Promise(async resolve => {
      params.sortField = 'name'
      params.sortOrder = 'ascend'
      let resp1 = await this.getParamsForApplicationList()

      params = { ...params, ...resp1 }
      let finalFilter = {
        ...params,
        regExFilters: [
          'name',
          'address',
          'mobile',
          'email',
          'cityName',
          'companyName',
          'pincode'
        ]
      }
      this.setState({ filters: finalFilter })
      let resp = await dispatch(listAllMarketingAgents({ ...finalFilter }))
      this.setState({ total: resp.total })
      resolve(resp)
    })
  }

  getParamsForApplicationList = () => {
    return new Promise(resolve => {
      let searchParams = new URLSearchParams(window.location.search)
      let countryName = searchParams.get('countryName')
      let stateName = searchParams.get('stateName')
      let cityName = searchParams.get('cityName')
      let pincode = searchParams.get('pincode')
      let agentWiseManagerId = searchParams.get('agentWiseManagerId')
      let name = searchParams.get('name')
      let email = searchParams.get('email')
      let companyName = searchParams.get('companyName')
      let mobile = searchParams.get('mobile')
      let marketingUserId = searchParams.get('marketingUserId')

      let obj = {}
      if (countryName) {
        obj.countryName = countryName
      }
      if (stateName) {
        obj.stateName = stateName
      }
      if (cityName) {
        obj.cityName = cityName
      }
      if (pincode) {
        obj.pincode = pincode
      }
      if (agentWiseManagerId) {
        obj.agentWiseManagerId = agentWiseManagerId
      }
      if (marketingUserId) {
        obj.marketingUserId = marketingUserId
      }
      if (name) {
        obj.name = name
      }
      if (companyName) {
        obj.companyName = companyName
      }
      if (mobile) {
        obj.mobile = mobile
      }
      if (email) {
        obj.email = email
      }
      resolve(obj)
    })
  }

  setFieldByParams = async () => {
    let resp = await this.getParamsForApplicationList()
    let obj = {}
    if (resp.countryName) {
      obj.countryName = resp.countryName
    }
    if (resp.stateName) {
      obj.stateName = resp.stateName
    }
    if (resp.cityName) {
      obj.cityName = resp.cityName
    }
    if (resp.pincode) {
      obj.pincode = resp.pincode
    }
    if (resp.agentWiseManagerId) {
      obj.agentWiseManagerId = resp.agentWiseManagerId
    }
    if (resp.marketingUserId) {
      obj.marketingUserId = resp.marketingUserId
    }
    if (resp.name) {
      obj.name = resp.name
    }
    if (resp.email) {
      obj.email = resp.email
    }
    if (resp.companyName) {
      obj.companyName = resp.companyName
    }
    if (resp.mobile) {
      obj.mobile = resp.mobile
    }
    this.setState({ ...obj })
  }

  searchFxn = async () => {
    let { dispatch } = this.props
    let obj = {}
    if (this.state.countryName) {
      obj.countryName = this.state.countryName
    }
    if (this.state.stateName) {
      obj.stateName = this.state.stateName
    }
    if (this.state.cityName) {
      obj.cityName = this.state.cityName
    }
    if (this.state.pincode) {
      obj.pincode = this.state.pincode
    }
    if (this.state.agentWiseManagerId) {
      obj.agentWiseManagerId = this.state.agentWiseManagerId
    }
    if (this.state.marketingUserId) {
      obj.marketingUserId = this.state.marketingUserId
    }
    if (this.state.name) {
      obj.name = this.state.name
    }
    if (this.state.email) {
      obj.email = this.state.email
    }
    if (this.state.companyName) {
      obj.companyName = this.state.companyName
    }
    if (this.state.mobile) {
      obj.mobile = this.state.mobile
    }
    dispatch(
      getUrlPushWrapper('marketingAgentsList', {
        ...obj
      })
    )
    setTimeout(() => {
      if (this.tableRef && this.tableRef.current) {
        this.tableRef.current.reload()
      }
    }, 200)
  }
  clearFxn = async () => {
    let { dispatch } = this.props
    let obj = {}
    this.setState({
      countryName: '',
      stateName: '',
      cityName: '',
      pincode: '',
      agentWiseManagerId: '',
      marketingUserId: '',
      name: '',
      email: '',
      companyName: '',
      mobile: ''
    })
    dispatch(
      getUrlPushWrapper('marketingAgentsList', {
        ...obj
      })
    )
    setTimeout(() => {
      this.tableRef.current.reload()
    }, 200)
  }

  UpdateInsuranceRight = async id => {
    let { dispatch } = this.props
    let resp = await dispatch(updateInsuranceRight({ userId: id }))
    if (resp && resp.success) {
      this.tableRef.current.reload()
    }
  }

  showDeals = (universityId) => {
    let findDoc = _.find(this.state.universityFoDealClose, (item) => {
      return item.universityId && item.universityId._id == universityId
    })
    if (findDoc) {
      return true
    } else {
      return false
    }
  }

  render() {
    let {
      countryList,
      approvedAgentRightsUser,
      countryName,
      dealCloseState,
      dealUpdateState,
      requestState,
      rightState
    } = this.state

    let { currentUser } = this.props
    const columns = [
      {
        title: '#',
        key: '_id',
        dataIndex: '_id',
        width: 50,
        render: (ite, record, index) => {
          return <React.Fragment>{index + 1}</React.Fragment>
        }
      },
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        // searchTextName: 'name',
        width: 100,
        render: (item, record) => {
          return (
            <ColumnWidth width={100}>
              {item}
              <CheckActiveUser userId={record._id} {...this.props} />
            </ColumnWidth>
          )
        }
      },
      {
        title: 'Company Name',
        key: 'companyName',
        sorter: true,
        dataIndex: 'companyName',
        // searchTextName: 'companyName',
        width: 150
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        // searchTextName: 'email',
        width: 120,
        render: (item, record) => {
          return <MobileEmailInfo data={record} type={'email'} value={item} />
        }
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobile',
        key: 'mobile',
        // searchTextName: 'mobile',
        width: 80,
        render: (item, record) => {
          return <MobileEmailInfo data={record} type={'mobile'} value={item} />
        }
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        searchTextName: 'address',
        width: 150,
        render: item => {
          return <ColumnWidth width={150}>{item}</ColumnWidth>
        }
      },

      {
        title: 'City Name',
        dataIndex: 'cityName',
        key: 'cityName',
        // searchTextName: 'address',
        // filters: CityJson.map(x => ({ value: x.name, text: x.name })),
        width: 100,
        render: item => {
          return <ColumnWidth width={80}>{item}</ColumnWidth>
        }
      },

      {
        title: 'State Name',
        dataIndex: 'stateName',
        key: 'stateName',
        width: 100,
        // filters: StateJson.map(x => ({ value: x.name, text: x.name })),
        render: item => {
          return <ColumnWidth width={80}>{item}</ColumnWidth>
        }
      },
      {
        title: 'Country Name',
        dataIndex: 'countryName',
        key: 'countryName',
        width: 100,
        // filters: CountryJson.map(x => ({ value: x.name, text: x.name })),
        render: item => {
          return <ColumnWidth width={80}>{item}</ColumnWidth>
        }
      },
      {
        title: 'Pincode',
        dataIndex: 'pincode',
        key: 'pincode',
        // searchTextName: 'address',
        width: 80,
        render: item => {
          return <ColumnWidth width={80}>{item}</ColumnWidth>
        }
      },
      {
        title: 'DealClose Information',
        key: 'dealClose',
        width: 250,
        render: (dealClose, record) => (
          <ColumnWidth width={230}>
            <ul className={'countList'}>
              {record.dealClose && record.dealClose ? record.dealClose.map((deal, key) => {
                return (
                  this.showDeals(deal.universityId._id) ? <li>
                    {deal && deal.universityId ? deal.universityId.universityName : ''}
                    {deal && deal.amount ? <>&nbsp; <label
                      className={'label label-success-green sm'}><b> Deal : (
                      CAD {deal.amount} )</b></label></> : null}
                  </li> : null
                )
              }) : null}
            </ul>


          </ColumnWidth>
        )
      },


      {
        title: 'Approved By',
        dataIndex: 'createdByUser.userId',
        key: 'createdByUser.userId',
        width: 180,
        filters: approvedAgentRightsUser.map(x => ({
          value: x._id,
          text: x.name
        })),
        render: (item, record) => {
          let val = record.createdByUser
          return (
            <ColumnWidth width={170}>
              {val && val.userId ? (
                <div>
                  User : {val.name}
                  {val.time ? (
                    <div>Date : {longDisplayDate(val.time)}</div>
                  ) : null}
                </div>
              ) : null}
            </ColumnWidth>
          )
        }
      },

      {
        title: 'Action',
        key: '_id',
        dataIndex: '_id',
        fixed: 'right',
        width: 80,
        render: (val, record) => {
          return (
            <div>
              <React.Fragment>
                {<Tooltip title={'Close Deal'}>
                  <button className='btn' onClick={() => {
                    this.events.showDealClose(record)
                  }}>
                    <img
                      src='../../dist/img/plus.svg'
                      alt=''
                      className={'crossBtn'}
                    />
                  </button>
                </Tooltip>}

              </React.Fragment>
              {dealUpdateByUser(currentUser) && record.dealClose && record.dealClose.length ? <React.Fragment>
                {<Tooltip title={'Deal Update'}>
                  <button className='btn' onClick={() => {
                    this.events.showCloseDealUpdate(record)
                  }}>
                    <img
                      src='../../dist/img/refresh.png'
                      alt=''
                      className={'crossBtn'}
                    />
                  </button>
                </Tooltip>
                }
              </React.Fragment> : null}


              {record.dealClose && record.dealClose.length ? <React.Fragment>
                {<Tooltip title={'Close Deal'}>
                  <button className='btn' onClick={() => {
                    this.events.showRequestConsentDeal(record)
                  }}>
                    <img style={{ height: 28 }}
                         src='../../dist/img/consentRequest.png'
                    />
                  </button>
                </Tooltip>}
              </React.Fragment> : null}

            </div>
          )
        }
      }
    ]

    const filterBlock = (
      <Row gutter={16} className={'mt10'}>
        {/*<AllowComponentRightsWise rightUserType={['admin']}>*/}
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='Search By Country'
              onChange={item => this.events.chooseCountry(item)}
              filterOption={filterOption}
              className={'antSelect'}
              placeholder='Country'
              allowClear={true}
              showSearch={true}
              value={this.state.countryName || undefined}>
              {this.state.allCountries && this.state.allCountries.length
                ? this.state.allCountries.map((item, key) => {
                  return (
                    <Option value={item.name} key={key}>
                      {item.name}
                    </Option>
                  )
                })
                : null}
            </Select>
          </div>
        </Col>
        {/*</AllowComponentRightsWise>*/}
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='Search By State'
              onChange={item => this.events.chooseState(item)}
              filterOption={filterOption}
              className={'antSelect'}
              placeholder='State'
              allowClear={true}
              showSearch={true}
              value={this.state.stateName || undefined}>
              {this.state.allStates && this.state.allStates.length
                ? this.state.allStates.map((item, key) => {
                  return (
                    <Option value={item.name} key={key}>
                      {item.name}
                    </Option>
                  )
                })
                : null}
            </Select>
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          {!countryName || countryName == 'India' ? (
            <div className='sort-box-table custom-sort-box-new'>
              <Select
                name='Search By City'
                onChange={item => this.events.chooseCity(item)}
                filterOption={filterOption}
                className={'antSelect'}
                placeholder='City'
                allowClear={true}
                showSearch={true}
                value={this.state.cityName || undefined}>
                {this.state.allCities && this.state.allCities.length
                  ? this.state.allCities.map((item, key) => {
                    return (
                      <Option value={item.name} key={key}>
                        {item.name}
                      </Option>
                    )
                  })
                  : null}
              </Select>
            </div>
          ) : (
            <div className='search-box-table round ml0'>
              <input
                name='Search By City'
                className='form-control'
                onChange={e => {
                  this.setState({ cityName: e.target.value })
                }}
                placeholder='City'
                value={this.state.cityName}
              />
              <img src='/dist/img/search.png' alt='' />
            </div>
          )}
        </Col>
        {currentUser.userType == 'marketingManager' ?
          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='sort-box-table custom-sort-box-new'>
              <Select
                name='Search By Marketing User'
                onChange={item => {
                  this.setState({ marketingUserId: item })
                }}
                filterOption={filterOption}
                className={'antSelect'}
                placeholder='Marketing Manager'
                allowClear={true}
                showSearch={false}
                value={this.state.marketingUserId || undefined}>
                {this.state.allMarketingUser && this.state.allMarketingUser.length
                  ? this.state.allMarketingUser.map((item, key) => {
                    return (
                      <Option value={item._id} key={key}>
                        {item.name}
                      </Option>
                    )
                  })
                  : null}
              </Select>
            </div>
          </Col> : null}

        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input
              className='form-control form-control'
              type='search'
              placeholder='Pincode'
              value={this.state.pincode}
              onChange={e => {
                this.setState({ pincode: e.target.value })
              }}
            />
            <img src='/dist/img/search.png' alt='' />
          </div>
        </Col>

        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input
              className='form-control form-control'
              type='search'
              placeholder='Name'
              value={this.state.name}
              onChange={e => {
                this.setState({ name: e.target.value })
              }}
            />
            <img src='/dist/img/search.png' alt='' />
          </div>
        </Col>

        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input
              className='form-control form-control'
              type='search'
              placeholder='Company Name'
              value={this.state.companyName}
              onChange={e => {
                this.setState({ companyName: e.target.value })
              }}
            />
            <img src='/dist/img/search.png' alt='' />
          </div>
        </Col>

        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input
              className='form-control form-control'
              type='search'
              placeholder='Email'
              value={this.state.email}
              onChange={e => {
                this.setState({ email: e.target.value })
              }}
            />
            <img src='/dist/img/search.png' alt='' />
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input
              className='form-control form-control'
              type='search'
              placeholder='Mobile No'
              value={this.state.mobile}
              onChange={e => {
                this.setState({ mobile: e.target.value })
              }}
            />
            <img src='/dist/img/search.png' alt='' />
          </div>
        </Col>

        <Col md={8} sm={8} xs={12} lg={4}>
          <Button onClick={() => this.searchFxn()} className={'roundBtn'}>
            Search
          </Button>
          <Button onClick={() => this.clearFxn()} className={'roundBtn'}>
            Clear
          </Button>
        </Col>
      </Row>
    )
    return (
      <div>
        <div className='row  mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='table-head d-flex align-items-center'>
                <h5>All MarketingAgents ({this.state.total})</h5>
                <div className='search-box-table' />

                <div className='sort-box-table mark-btn'>

                </div>

              </div>

              <div className='card-body table-responsive'>
                {filterBlock}
                <TableComp
                  columns={columns}
                  ref={this.tableRef}
                  pagination={DefaultTablePagination()}
                  apiRequest={this.apiRequest}
                  extraProps={{ scroll: { x: 1800 } }}
                />
              </div>
            </div>
          </div>
        </div>


        {dealCloseState.visible ? (
          <CloseDeal
            {...dealCloseState}
            countryList={countryList}
            onClose={() => this.events.hideDealClose()}
            onSubmit={() => {
              this.events.hideDealClose()
              this.tableRef.current.reload()
            }}
          />
        ) : null}

        {dealUpdateState.visible ? (
          <UpdateDealClose
            {...dealUpdateState}
            countryList={countryList}
            onClose={() => this.events.hideCloseDealUpdate()}
            onSubmit={() => {
              this.events.hideCloseDealUpdate()
              this.tableRef.current.reload()
            }}
          />
        ) : null}

        {requestState.visible ? (
          <RequestForConsent
            {...requestState}
            onClose={() => this.events.hideRequestConsentDeal()}
            onSubmit={() => {
              this.events.hideRequestConsentDeal()
              this.tableRef.current.reload()
            }}
          />
        ) : null}
        {rightState.visible ? (
            <DirectUniversityRights
                {...rightState}
                onClose={() => this.events.hideRightDrawer()}
                onSubmit={() => {
                  this.events.hideRightDrawer()
                  this.tableRef.current.reload()
                }}
            />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(AllMarketingAgents)
