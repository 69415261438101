import {apiUrl} from "../../../settings";

export const requestForCallUrl = () => {
  return apiUrl + '/gic/requestForCall';
};

export const addCallInWithdrawalUrl = () => {
  return apiUrl + '/gic/addCallInWithdrawal';
};

export const generateInvoiceUrl = () => {
  return apiUrl + '/gic/generateInvoice';
};

export const addReceiptInWithdrawalUrl = () => {
  return apiUrl + '/gic/addReceiptInWithdrawal';
};

export const listWithdrawalUrl = () => {
  return apiUrl + '/gic/withdrawal'
}

export const reGenerateInvoiceUrl = () => {
  return apiUrl + '/gic/reGenerateInvoice'
}
export const updateWithdrawalUrl = () => {
  return apiUrl + '/gic/updateWithdrawal'
}
