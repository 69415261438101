import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { countryIds, DefaultTablePagination, filterOption, fixed2Digit } from '../../components/_utils/appUtils'

import {
  loadDistinctStateOfOnlineAgentsFxn
} from '../users/actions/user'
import { Button, Col, Popconfirm, Row, Select, Tag } from 'antd'
import { loadAllAgentCommissions, reCalculateAgentCountryBalanceFxn } from './actions'
import { getUrlPushWrapper } from '../../routes'
import { agentCurrentCommissionFxn } from '../universityCommission/actions'
import TransactionListForAdmin from '../dashboard/agentCommission/transactionListForAdmin'
import AssignComponent from '../dashboard/agentCommission/assignComponent'
import moment from 'moment'
import _ from 'lodash'
import { countryList } from '../login/action'

const ColumnDiv = ({ children }) => {
  return (
    <a className={'btn btn-link md'}>
      {children}
    </a>
  )
}

const AgentCurrentWallet = props => {
  let { user } = props
  let dispatch = useDispatch()
  let tableRef = useRef()
  let [total, setTotal] = useState(0)
  let [totalCommissionCountryWise, setTotalCommissionCountryWise] = useState({})
  let [stateWiseCount, setStateWiseCount] = useState([])
  let [selectedState, setSelectedState] = useState([])
  let [filterState, setFilterState] = useState([{
    name: '',
    mobile: '',
    email: '',
    companyName: ''
  }])
  let initState = {
    visible: false,
    countryId: '',
    agentId: ''
  }
  const [state, setState] = useState(initState)
  const [currentCommissionObj, setCurrentCommissionObj] = useState({})
  let [withdrawalState, setWithdrawalState] = useState({
    visible: false,
    countryId: '',
    agentId: '',
    company: ''
  })
  const [refreshKey, setRefreshKey] = useState(moment())
  let [activeTab, setActiveTab] = useState('1')
  let [selectedCountry, setSelectedCountry] = useState('')
  const [countryIdArr, setCountryIdArr] = useState([])
  const [countryData, setCountryData] = useState([])
  const [countryId, setcountryId] = useState('')
  const [agentId, setAgentId] = useState('')

  useEffect(() => {
    loadCurrentCommission()
  }, [])
  let loadCurrentCommission = async () => {
    let { data } = await dispatch(agentCurrentCommissionFxn())
    setCurrentCommissionObj(data)
  }

  useEffect(() => {
    loadAllCountry()
  }, [countryId])
  const loadAllCountry = async () => {
    let { data } = await (countryList({
      results: 10,
      active: true,
      countryName: ['Canada', 'UK', 'Australia', 'Germany', 'Canada Onshore'],
      select: ['countryName', 'flag'],
      sortField: '_id',
      sortOrder: 'ascend'
    }))
    let defaultCountry = countryId ? countryId : data[0]._id.toString()
    let countryIdArr = []
    _.each(data, (item) => {
      if (item && item._id) {
        countryIdArr.push(item._id.toString())
      }
    })
    setCountryIdArr(countryIdArr)
    let indexVal = countryIdArr.indexOf(defaultCountry)
    setActiveTab((indexVal + 1).toString())
    setCountryData(data)
    setSelectedCountry(defaultCountry)
  }

  useEffect(() => {
    loadDistinctState()
    setFieldByParams()
  }, [])


  useEffect(() => {
    tableRef.current.reload()
  }, [selectedState])
  const handleBoxClick = state => {
    const isSelected = selectedState.includes(state._id)
    if (isSelected) {
      setSelectedState(selectedState.filter(selected => selected !== state._id))
    } else {
      setSelectedState([...selectedState, state._id])
    }
  }
  const loadDistinctState = async () => {
    let { data, success } = await dispatch(loadDistinctStateOfOnlineAgentsFxn({}))
    if (success) {
      setStateWiseCount(data)
    }
  }

  const getParamsForApplicationList = () => {
    return new Promise(resolve => {
      let searchParams = new URLSearchParams(window.location.search)
      let name = searchParams.get('name')
      let email = searchParams.get('email')
      let companyName = searchParams.get('companyName')
      let mobile = searchParams.get('mobile')
      let obj = {}
      if (name) {
        obj.name = name
      }
      if (companyName) {
        obj.companyName = companyName
      }
      if (mobile) {
        obj.mobile = mobile
      }
      if (email) {
        obj.email = email
      }
      resolve(obj)
    })
  }
  let apiRequest = async params => {
    return new Promise(async resolve => {
      let resp1 = await getParamsForApplicationList()
      if (resp1.mobile) {
        params.mobile = resp1.mobile
      }
      if (resp1.email) {
        params.email = resp1.email
      }
      if (resp1.name) {
        params.name = resp1.name
      }
      if (resp1.companyName) {
        params.companyName = resp1.companyName
      }
      let resp = await dispatch(
        loadAllAgentCommissions({
          ...params,
          regExFilters: ['name', 'companyName', 'mobile', 'email']
        })
      )
      setTotal(resp.count)
      setTotalCommissionCountryWise(resp.totals)
      resolve(resp.data)
    })
  }

  const events = {
    showTransactions: (countryId, agentId) => {
      setState((preState) => {
        return {
          ...preState,
          countryId: countryId,
          agentId: agentId,
          visible: true
        }
      })
    },
    hideTransactions: () => {
      setState((preState) => {
        return {
          ...preState,
          countryId: '',
          agentId: '',
          visible: false
        }
      })
    },
    showWithdrawal: (data) => {
      console.log(data)
      setWithdrawalState({
        visible: true,
        countryId: data.countryId,
        agentId: data.agentId,
        company: data.companyName
      })
    },
    hideWithdrawal: () => {
      setWithdrawalState({
        visible: false,
        countryId: '',
        agentId: '',
        company: ''
      })
      setRefreshKey(moment())
    },
    reCalculateCountryBalance: async (data) => {
      let { agentId } = data
      let resp = await dispatch(reCalculateAgentCountryBalanceFxn({ agentId }))
      tableRef.current.reload()
    }
  }

  const assignButton = (data) => {
    let { countryId, agentId } = data
    return (
      user && user.userType == 'itTeam' ?
        <div className={'mt5'}>
          <a className={'xs btn btn-default roundNew mr5'}
             onClick={() => {
               events.showWithdrawal(data)
             }}>Assign
          </a>
        </div>
        : null
    )
  }

  const columns = [
    {
      title: '#',
      key: '_id',
      dataIndex: '_id',
      width: 50,
      render: (ite, record, index) => {
        return <React.Fragment>{index + 1}</React.Fragment>
      }
    },
    {
      title: 'Name',
      key: 'name',
      sorter: true,
      dataIndex: 'name',
      searchTextName: 'name',
      width: 120,
      render: (item, record) => {
        return (
          <>
            {item}

            {user && user.userType == 'itTeam' ?
              <Popconfirm
                title={'Are you sure, you want to re-calculate Balance?'}
                onConfirm={() => {
                  events.reCalculateCountryBalance({ agentId: record._id })
                }}>
                <a className={'xs btn btn-default roundNew mt10'}>Re-Calc Bal.
                </a>
              </Popconfirm> : null}

          </>
        )
      }
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
      searchTextName: 'companyName',
      render: item => {
        return <span>{item}</span>
      },
      width: 200
    },
    {
      title: 'Email/Mobile',
      dataIndex: 'email',
      key: 'email',
      searchTextName: 'email',
      width: 200,
      render: (record, item) => {
        return <div>{item.email} <br /> {item.mobile}</div>
      }
    },
    {
      title: 'Canada',
      dataIndex: 'canadaCommission',
      key: 'canadaCommission',
      render: (text, item) => {
        return (
          <div>
            <div onClick={() => events.showTransactions(countryIds.canada, item._id)}>
              <ColumnDiv width={80}>{fixed2Digit(item.canadaCommission)}</ColumnDiv>
            </div>
            {/*<button className={'xs btn btn-default'}
                    onClick={() => events.showTransactions(countryIds.canada, item._id)}>view
            </button>*/}

            {assignButton({ countryId: countryIds.canada, agentId: item._id, companyName: item.companyName })}

          </div>
        )
      }
    },
    {
      title: 'United Kingdom',
      key: 'ukCommission',
      dataIndex: 'ukCommission',
      render: (record, item) => {
        return (
          <div>
            <div onClick={() => events.showTransactions(countryIds.uk, item._id)}>
              <ColumnDiv width={80}>{fixed2Digit(item.ukCommission)}</ColumnDiv>
            </div>
            {/*<button className={'xs btn btn-default'}
                    onClick={() => events.showTransactions(countryIds.uk, item._id)}>view
            </button>*/}
            {assignButton({ countryId: countryIds.uk, agentId: item._id, companyName: item.companyName })}

          </div>
        )
      }
    },
    {
      title: 'Australia',
      key: 'australiaCommission',
      dataIndex: 'australiaCommission',
      render: (record, item) => {
        return (
          <div>
            <div onClick={() => events.showTransactions(countryIds.australia, item._id)}>
              <ColumnDiv width={80}>{fixed2Digit(item.australiaCommission)}</ColumnDiv>
            </div>
            {/*  <button className={'xs btn btn-default'}
                    onClick={() => events.showTransactions(countryIds.australia, item._id)}>view
            </button>*/}

            {assignButton({ countryId: countryIds.australia, agentId: item._id, companyName: item.companyName })}

          </div>
        )
      }
    },
    {
      title: 'Germany',
      key: 'germanyCommission',
      dataIndex: 'germanyCommission',
      render: (record, item) => {
        return (
          <div>
            <div onClick={() => events.showTransactions(countryIds.germany, item._id)}>
              <ColumnDiv width={80}>{fixed2Digit(item.germanyCommission)}</ColumnDiv>
            </div>
            {/* <button className={'xs btn btn-default'}
                    onClick={() => events.showTransactions(countryIds.germany, item._id)}>view
            </button>*/}

            {assignButton({ countryId: countryIds.germany, agentId: item._id, companyName: item.companyName })}

          </div>
        )
      }
    },
    {
      title: 'Canada Onshore',
      key: 'onshoreCanadaCommission',
      dataIndex: 'onshoreCanadaCommission',
      render: (record, item) => {
        return (
          <div>
            <div onClick={() => events.showTransactions(countryIds.canadaOnshore, item._id)}>
              <ColumnDiv width={80}>{fixed2Digit(item.onshoreCanadaCommission)}</ColumnDiv>
            </div>
            {/*  <button className={'xs btn btn-default'}
                    onClick={() => events.showTransactions(countryIds.canadaOnshore, item._id)}>view
            </button>*/}

            {assignButton({ countryId: countryIds.canadaOnshore, agentId: item._id, companyName: item.companyName })}
          </div>
        )
      }
    }
  ]

  const setFieldByParams = async () => {
    let resp = await getParamsForApplicationList()
    let obj = {}
    if (resp.name) {
      obj.name = resp.name
    }
    if (resp.email) {
      obj.email = resp.email
    }
    if (resp.companyName) {
      obj.companyName = resp.companyName
    }
    if (resp.mobile) {
      obj.mobile = resp.mobile
    }
    setFilterState({ ...obj })
  }

  const searchFxn = async () => {
    let obj = {}
    if (filterState.name) {
      obj.name = filterState.name
    }
    if (filterState.email) {
      obj.email = filterState.email
    }
    if (filterState.companyName) {
      obj.companyName = filterState.companyName
    }
    if (filterState.mobile) {
      obj.mobile = filterState.mobile
    }
    dispatch(
      getUrlPushWrapper('agentCurrentWallet', {
        ...obj
      })
    )
    setTimeout(() => {
      if (tableRef && tableRef.current) {
        tableRef.current.reload()
      }
    }, 200)
  }

  const clearFxn = async () => {
    let obj = {}
    setFilterState({
      name: '',
      email: '',
      companyName: '',
      mobile: ''
    })
    dispatch(
      getUrlPushWrapper('agentCurrentWallet', {
        ...obj
      })
    )
    setTimeout(() => {
      tableRef.current.reload()
    }, 200)
  }


  const filterBlock = (
    <Row gutter={16} className={'mt10'}>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='search-box-table round ml0'>
          <input
            className='form-control form-control'
            type='search'
            placeholder='Name'
            value={filterState.name}
            onChange={e => {
              setFilterState({ name: e.target.value })
            }}
          />
          <img src='/dist/img/search.png' alt='' />
        </div>
      </Col>

      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='search-box-table round ml0'>
          <input
            className='form-control form-control'
            type='search'
            placeholder='Company Name'
            value={filterState.companyName}
            onChange={e => {
              setFilterState({ companyName: e.target.value })
            }}
          />
          <img src='/dist/img/search.png' alt='' />
        </div>
      </Col>

      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='search-box-table round ml0'>
          <input
            className='form-control form-control'
            type='search'
            placeholder='Email'
            value={filterState.email}
            onChange={e => {
              setFilterState({ email: e.target.value })
            }}
          />
          <img src='/dist/img/search.png' alt='' />
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='search-box-table round ml0'>
          <input
            className='form-control form-control'
            type='search'
            placeholder='Mobile No'
            value={filterState.mobile}
            onChange={e => {
              setFilterState({ mobile: e.target.value })
            }}
          />
          <img src='/dist/img/search.png' alt='' />
        </div>
      </Col>

      <Col md={8} sm={8} xs={12} lg={4}>
        <Button onClick={() => searchFxn()} className={'roundBtn'}>
          Search
        </Button>
        <Button onClick={() => clearFxn()} className={'roundBtn'}>
          Clear
        </Button>
      </Col>
    </Row>
  )

  return (
    <>

      <div className='row  mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>All Agents Current Wallet( Total : {total})</h5>
              <div className='search-box-table' />
            </div>
            <div className='card-body table-responsive'>
              {filterBlock}
              <div className={'row'}>
                <div className={'col-md-12 mt10'}>
                  <div className='input-group input-group-sm'>
                    <Tag className={'tag-gic'}>
                      <img className={'commission-flag'} src='./../dist/img/canadaFlag.png' />
                      <span
                        className='box-text'><b>$</b> {fixed2Digit(totalCommissionCountryWise.totalCanadaCommission)} CAD</span>
                    </Tag>
                    <Tag className={'tag-gic'}>
                      <img className={'commission-flag'} src='./../dist/img/united-kingdomFlag.png' />
                      <span
                        className='box-text'><b>£</b> {fixed2Digit(totalCommissionCountryWise.totalUkCommission)} GBP</span>
                    </Tag>
                    <Tag className={'tag-gic'}>
                      <img className={'commission-flag'} src='./../dist/img/australiaFlag.png' />
                      <span
                        className='box-text'><b>$</b> {fixed2Digit(totalCommissionCountryWise.totalAustraliaCommission)} AUD</span>
                    </Tag>
                    <Tag className={'tag-gic'}>
                      <img className={'commission-flag'} src='./../dist/img/germanyFlag.png' />
                      <span
                        className='box-text'><b>€</b> {fixed2Digit(totalCommissionCountryWise.totalGermanyCommission)} EUR</span>
                    </Tag>
                    <Tag className={'tag-gic'}>
                      <img className={'commission-flag'} src='./../dist/img/canadaFlag.png' />
                      <span
                        className='box-text'><b>$</b> {fixed2Digit(totalCommissionCountryWise.totalOnshoreCanadaCommission)} CAD</span>
                    </Tag>
                  </div>
                </div>
              </div>
              <TableComp
                columns={columns}
                ref={tableRef}
                pagination={DefaultTablePagination()}
                apiRequest={apiRequest}
                extraProps={{ scroll: { x: 1000 } }}
              />
            </div>
          </div>
        </div>
      </div>
      {state.visible ?
        <TransactionListForAdmin
          visible={state.visible}
          countryId={state.countryId ? state.countryId : ''}
          agentId={state.agentId ? state.agentId : ''}
          currentCommissionObj={currentCommissionObj}
          onClose={events.hideTransactions}
          refreshPage={events.hideTransactions}
        /> : null}

      {withdrawalState.visible ?
        <AssignComponent
          {...withdrawalState}
          onClose={events.hideWithdrawal}
        /> : null}
    </>
  )
}
export default AgentCurrentWallet
