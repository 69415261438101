export default (
  state = {
    loader: false,
    company: {},
    allCompany: [],
    totalCompany: 0,
    addedCompany: {}
  },
  action
) => {
  switch (action.type) {
    case 'COMPANY_SHOW_LOADER':
      return { ...state, loader: true }
    case 'COMPANY_HIDE_LOADER':
      return { ...state, loader: false }
    case 'LIST_COMPANY':
      return { ...state, company: action.payload.data }
    case 'ADD_COMPANY':
      return { ...state, addedCity: action.payload }
    case 'LIST_ALL_COMPANY':
      return { ...state, allCompany: action.payload.data, totalCompany: action.payload.total }
    case 'EMPTY_COMPANY_LIST':
      return { ...state, allCompany: [], totalCompany: 0 }
    default:
      return state
  }
}
