import React, {Component} from 'react'
import {TableComp} from 'sz-react-utils-lite'
import {
    notification,
    Switch, Button, Tooltip, Row, Col, Select
} from 'antd'
import {connect} from 'react-redux'
import {
    blockAgent,
    getAgentAppCount,
    getExportAgent, getMarketingAgentAppCountFxn
} from '../actions/user'
import {DefaultTablePagination, filterOption} from '../../../components/_utils/appUtils'

import {ColumnWidth} from '../../WebComponent/columnWidth'
import ViewApplication from '../../applications/views/viewApplication'
import {getUrlPushWrapper} from "../../../routes";
import {LoadAllCountry, LoadCity, LoadState} from '../../../components/_utils/countryUtil'
import _ from "lodash"
import MobileEmailInfo from "../../../components/commonComponents/mobileEmailInfo";

const {Option} = Select

const userTypeOptions = [
    {value: 'agent', text: 'Agent'},
    {value: 'subAgent', text: 'Sub Agent'}
]
const commissionType = [
    {text: 'Silver', value: 'silver'},
    {text: 'Gold', value: 'gold'},
    {text: 'Platinum', value: 'platinum'}
]

class MarketingAgentList extends Component {

    events = {
        loadApplications: (data) => {
            data.marketingUserId = this.state.marketingUserId
            this.setState({visibleApplicationDrawer: true, queryData: data})
        },
        hideApplicationDrawer: () => {
            this.setState({visibleApplicationDrawer: false})
        },
        chooseCountry: (name) => {
            let countryName = _.find(this.state.allCountries, (item) => {
                return item.name == name
            })
            if (countryName) {
                this.setState({
                    countryName: countryName.name ? countryName.name : '',
                    stateName: undefined,
                    cityName: undefined,
                    pincode: undefined,
                    allStates: countryName.id ? LoadState(countryName.id) : []
                })
            }
        },
        chooseState: (name) => {
            let stateName = _.find(this.state.allStates, (item) => {
                return item.name == name
            })
            if (stateName) {
                this.setState({
                    stateName: stateName.name ? stateName.name : '',
                    cityName: undefined,
                    pincode: undefined,
                    allCities: stateName.id ? LoadCity(stateName.id) : []
                })
            }
        },
        chooseCity: (name) => {
            this.setState({
                cityName: name ? name : ''
            })
        }
    }


    constructor(props) {
        super(props)
        this.state = {
            data: [],
            viewInfoDrawer: false,
            selectedRow: {},
            allAgents: [],
            allBranchManager: [],
            allCountry: [],
            selectedUser: {},
            visibleUpdatePassword: false,
            visibleUpdateUser: false,
            visibleUpdateSubAgent: false,
            visibleApplicationDrawer: false,
            queryData: {},
            total: '',
            marketingUserId: '',
            showZeroCase: false,
            allCountries: LoadAllCountry(),
            allStates: [],
            allCities: [],
        }
        this.tableRef = React.createRef()
    }

    componentDidMount() {
        // this.loadAllAgent()
        // this.loadAllBranchManager()
        // this.loadAllCountry()
        this.setFieldByParams()
    }

    setFieldByParams = async () => {
        let {
            countryName,
            stateName,
            cityName,
            pincode,
            name,
            companyName,
            mobile,
            email
        } = await this.getParamsForApplicationList()
        let obj = {}
        if (countryName) {
            obj.countryName = countryName
        }
        if (stateName) {
            obj.stateName = stateName
        }
        if (cityName) {
            obj.cityName = cityName
        }
        if (pincode) {
            obj.pincode = pincode
        }
        if (name) {
            obj.name = name
        }
        if (companyName) {
            obj.companyName = companyName
        }
        if (mobile) {
            obj.mobile = mobile
        }
        if (email) {
            obj.email = email
        }
        this.setState(obj)
    }

    getParamsForApplicationList = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let countryName = searchParams.get('countryName')
            let stateName = searchParams.get('stateName')
            let cityName = searchParams.get('cityName')
            let pincode = searchParams.get('pincode')
            let name = searchParams.get('name')
            let email = searchParams.get('email')
            let mobile = searchParams.get('mobile')
            let companyName = searchParams.get('companyName')

            let obj = {}
            if (countryName) {
                obj.countryName = countryName
            }
            if (stateName) {
                obj.stateName = stateName
            }
            if (cityName) {
                obj.cityName = cityName
            }
            if (pincode) {
                obj.pincode = pincode
            }
            if (name) {
                obj.name = name
            }
            if (mobile) {
                obj.mobile = mobile
            }
            if (companyName) {
                obj.companyName = companyName
            }
            if (email) {
                obj.email = email
            }
            resolve(obj)
        })
    }

    searchFxn = async () => {
        let {dispatch} = this.props
        let obj = {}
        if (this.state.countryName) {
            obj.countryName = this.state.countryName
        }
        if (this.state.stateName) {
            obj.stateName = this.state.stateName
        }
        if (this.state.cityName) {
            obj.cityName = this.state.cityName
        }
        if (this.state.pincode) {
            obj.pincode = this.state.pincode
        }
        if (this.state.name) {
            obj.name = this.state.name
        }
        if (this.state.mobile) {
            obj.mobile = this.state.mobile
        }
        if (this.state.companyName) {
            obj.companyName = this.state.companyName
        }
        if (this.state.email) {
            obj.email = this.state.email
        }
        dispatch(
            getUrlPushWrapper('marketingUserAssignAgentList', {
                ...obj
            }))
        setTimeout(() => {
            if (this.tableRef && this.tableRef.current) {
                this.tableRef.current.reload()
            }
        }, 200)
    }
    clearFxn = async () => {
        let {dispatch} = this.props
        // let resp = await getParamsForApplicationList()
        let obj = {}
        this.setState({
            countryName: '',
            stateName: '',
            cityName: '',
            pincode: '',
            name: '',
            email: '',
            mobile: '',
            companyName: '',
        })
        dispatch(
            getUrlPushWrapper('marketingUserAssignAgentList', {
                ...obj
            }))
        setTimeout(() => {
            this.tableRef.current.reload()
        }, 200)
    }

    viewInfo(record) {
        this.setState({
            selectedRow: record,
            viewInfoDrawer: true
        })
    }

    onClose = () => {
        this.setState({
            selectedRow: {},
            viewInfoDrawer: false
        })
    }

    closeUpdatePassword = () => {
        this.setState({
            selectedUser: {},
            visibleUpdatePassword: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    closeEditUser = () => {
        this.setState({
            selectedUser: {},
            visibleUpdateUser: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    closeEditSubAgent = () => {
        this.setState({
            selectedUser: {},
            visibleUpdateSubAgent: false
        }, () => {
            this.tableRef.current.reload()
        })
    }
    updateAgentBlockStatus = async (id) => {
        let {dispatch} = this.props
        let resp = await dispatch(blockAgent({agentId: id}))
        if (resp && resp.success) {
            this.tableRef.current.reload()
        }
    }

    apiRequest = async (params) => {
        let {dispatch} = this.props
        let searchParams = new URLSearchParams(window.location.search)
        let id = searchParams.get('id')
        return new Promise(async (resolve) => {
            let obj = {}
            if (id) {
                obj.id = id
            }
            let resp1 = await this.getParamsForApplicationList()

            if (resp1.countryName) {
                params.countryName = resp1.countryName
            }
            if (resp1.stateName) {
                params.stateName = resp1.stateName
            }
            if (resp1.cityName) {
                params.cityName = resp1.cityName
            }
            if (resp1.pincode) {
                params.pincode = resp1.pincode
            }
            if (resp1.name) {
                params.name = resp1.name
            }
            if (resp1.email) {
                params.email = resp1.email
            }
            if (resp1.mobile) {
                params.mobile = resp1.mobile
            }
            if (resp1.companyName) {
                params.companyName = resp1.companyName
            }

            let finalFilter = {
                ...obj,
                ...params,
                showZeroCase: this.state.showZeroCase,
                regExFilters: ['name', 'address', 'mobile', 'email']
            }
            this.setState({filters: finalFilter})
            const data = await getMarketingAgentAppCountFxn(finalFilter)
            this.setState({total: data.total, marketingUserId: id})
            resolve(data)
        })
    }
    exportAgentsFxn = async () => {
        let {filters, total} = this.state
        let {dispatch} = this.props
        let {success, message} = await dispatch(getExportAgent({...filters, results: total}))
        if (success) {
            notification.success({message: message})
        } else {
            notification.warning({message: 'Error, please try again'})
            return
        }
    }

    zeroCaseFxn = (showZeroCase) => {
        this.setState({
            showZeroCase
        }, () => {
            this.tableRef.current.reload()
        })
    }


    render() {
        let {
            showZeroCase,
            visibleApplicationDrawer,
            countryName
        } = this.state
        const columns = [
            {
                title: '#',
                key: '_id',
                dataIndex: '_id',
                width: 50,
                render: (ite, record, index) => {
                    return (
                        <React.Fragment>
                            {index + 1}
                        </React.Fragment>
                    )
                }
            },
            {
                title: 'Name',
                key: 'agent.name',
                sorter: true,
                dataIndex: 'agent.name',
                width: 100,
                render: (item, record) => {
                    let {_id} = record
                    return (
                        <ColumnWidth width={100}>
                            {item && item ? item : ""}
                        </ColumnWidth>
                    )
                }
            },
            {
                title: 'Email',
                dataIndex: 'agent.email',
                key: 'agent.email',
                width: 120,
                render: (item, record) => {
                    let {_id} = record
                    return (
                        <ColumnWidth width={120}>
                            <MobileEmailInfo data={record.agent} type={'email'} value={item}/>
                        </ColumnWidth>
                    )
                }
            },
            {
                title: 'Mobile No',
                dataIndex: 'agent.mobile',
                key: 'agent.mobile',
                // searchTextName: 'mobile',
                width: 80,
                render: (item, record) => {
                    return (
                        <ColumnWidth width={80}>
                            <MobileEmailInfo data={record.agent} type={'mobile'} value={item}/>
                        </ColumnWidth>
                    )
                }
            },
            {
                title: 'Company Name',
                dataIndex: 'agent.companyName',
                key: 'agent.companyName',
                // searchTextName: 'companyName',
                width: 80,
                render: (item, record) => {
                    let {_id} = record
                    return (
                        <ColumnWidth width={80}>
                            {item && item ? item : ""}

                        </ColumnWidth>
                    )
                }
            },

            {
                title: 'Address',
                dataIndex: 'agent.address',
                key: 'agent.address',
                // searchTextName: 'address',
                width: 100,
                render: (item, record) => {
                    let {agent} = record
                    return (
                        <ColumnWidth width={100}>
                            {item && item ? item : ""}
                            {agent.cityName ? <span>, {agent.cityName}</span> : ""}
                            {agent.stateName ? <span>, {agent.stateName}</span> : ""}
                            {agent.countryName ? <span>, {agent.countryName}</span> : ""}
                            {agent.pincode ? <span> ({agent.pincode})</span> : ""}
                        </ColumnWidth>
                    )
                }
            },
            {
                title: 'Count',
                dataIndex: 'count',
                key: 'count',
                width: 50,
                render: (item, record) => {
                    return (
                        <React.Fragment>
                            {item ? <a onClick={() => this.events.loadApplications({agentId: record._id})}
                                       className={'countLink'}><u>{item}</u></a> : null}
                        </React.Fragment>
                    )
                }
            }

            // {
            //   title: 'Logo',
            //   dataIndex: 'logo',
            //   key: 'logo',
            //   width: 100,
            //   render: (val) => {
            //     return (
            //       <ColumnWidth width={100}>  {val && val.url ? <img src={val.url} className={'imgSm'} /> : null}</ColumnWidth>
            //     )
            //   }
            // }


        ]

        const filterBlock = (
            <Row gutter={16} className={'mt10'}>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By Country'
                                onChange={(item) => this.events.chooseCountry(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                placeholder='Country'
                                allowClear={true}
                                showSearch={true}
                                value={this.state.countryName || undefined}>
                            {this.state.allCountries && this.state.allCountries.length ? this.state.allCountries.map((item, key) => {
                                return (
                                    <Option value={item.name} key={key}>{item.name}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By State'
                                onChange={(item) => this.events.chooseState(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                placeholder='State'
                                allowClear={true}
                                showSearch={true}
                                value={this.state.stateName || undefined}>
                            {this.state.allStates && this.state.allStates.length ? this.state.allStates.map((item, key) => {
                                return (
                                    <Option value={item.name} key={key}>{item.name}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    {!countryName || countryName == 'India' ?
                        <div className='sort-box-table custom-sort-box-new'>
                            <Select name='Search By City'
                                    onChange={(item) => this.events.chooseCity(item)}
                                    filterOption={filterOption}
                                    className={'antSelect'}
                                    placeholder='City'
                                    allowClear={true}
                                    showSearch={true}
                                    value={this.state.cityName || undefined}>
                                {this.state.allCities && this.state.allCities.length ? this.state.allCities.map((item, key) => {
                                    return (
                                        <Option value={item.name} key={key}>{item.name}</Option>
                                    )
                                }) : null}
                            </Select>
                        </div> :
                        <div className='search-box-table round ml0'>
                            <input name='Search By City' className='form-control'
                                   onChange={(e) => {
                                       this.setState({cityName: e.target.value})
                                   }}
                                   placeholder='City'
                                   value={this.state.cityName}/>
                            <img src='/dist/img/search.png' alt=''/>

                        </div>}
                </Col>


                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Pincode' value={this.state.pincode} onChange={(e) => {
                            this.setState({pincode: e.target.value})
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>


                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Name' value={this.state.name} onChange={(e) => {
                            this.setState({name: e.target.value})
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>


                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Company Name' value={this.state.companyName} onChange={(e) => {
                            this.setState({companyName: e.target.value})
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>


                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Email' value={this.state.email} onChange={(e) => {
                            this.setState({email: e.target.value})
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Mobile No' value={this.state.mobile} onChange={(e) => {
                            this.setState({mobile: e.target.value})
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>


                <Col md={8} sm={8} xs={12} lg={4}>
                    <Button onClick={() => this.searchFxn()} className={'roundBtn'}>Search</Button>
                    <Button onClick={() => this.clearFxn()} className={'roundBtn'}>
                        Clear
                    </Button>
                </Col>


            </Row>
        )
        return (
            <div>

                <div className='row  mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head d-flex align-items-center'>
                                <h5>Agents ({this.state.total})</h5>
                                <div className='search-box-table'>
                                </div>

                                <div className='sort-box-table mark-btn'>
                                    <Switch checked={showZeroCase} onClick={(e) => {
                                        this.zeroCaseFxn(e)
                                    }}/>
                                    <span className={'ml10'}>Show Zero Case Agents</span>

                                    {/*<span className={'sort-box-table-right ml10'}>
                                        <Tooltip title={'Export Excel'}>
                                          {' '} <Button onClick={() => this.exportAgentsFxn()}
                                                        className={'roundBtn'}>Export Excel</Button>
                                        </Tooltip>
                                      </span>*/}
                                </div>
                            </div>
                            <div className='card-body table-responsive'>
                                {filterBlock}
                                <TableComp columns={columns}
                                           ref={this.tableRef}
                                           pagination={{
                                               defaultPageSize: 10,
                                               pageSizeOptions: ['10', '20', '50'],
                                           }}
                                           apiRequest={this.apiRequest}
                                           extraProps={{scroll: {x: 1000}}}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {visibleApplicationDrawer ? <ViewApplication
                    visible={visibleApplicationDrawer}
                    pageType={'allApplication'}
                    onClose={() => this.events.hideApplicationDrawer()}
                    queryData={this.state.queryData}
                /> : null}

            </div>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    activeUser: global.activeUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MarketingAgentList)
