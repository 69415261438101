import React, {useState, useEffect} from 'react'
import {Col, Drawer, Form, Row, Card, Input, Button, notification} from 'antd'
import {listAllCountries} from '../../countries/actions/countries'
import {useDispatch} from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {countryIds, InputBox} from '../../../components/_utils/appUtils'
import {payAgentCommissionFxn} from "../actions";
import moment from "moment";

let paymentModeList = [
    'Credit Card',
    'Cheque',
    'BHIM UPI',
    'PayTm',
    'NEFT',
    'Cash'
]
const PayCommission = (props) => {
    let {visible, onClose, onSubmit, record} = props
    const {form: {getFieldDecorator, setFieldsValue}} = props
    useEffect(() => {
        setTimeout(() => {
            setFieldsValue({
                invoiceDate: moment()
            })
        }, 400)
    }, [])
    let dispatch = useDispatch()

    const handleSubmit = (e) => {
        const {form} = props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let {totalCommission, totalCommissionInInr, agentId} = record;
                valData.commissionId = record._id;
                valData.totalCommission = totalCommission;
                valData.totalCommissionInInr = totalCommissionInInr;
                valData.agentId = agentId;
                let data = await dispatch(payAgentCommissionFxn(valData))
                if (data && data.success) {
                    form.resetFields()
                    onSubmit()
                    onClose()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }

    let fields = [

        {
            key: 'totalCommission',
            customField: (
                <InputBox title={'Total Commission'} className={'rowFlex'}>
                    <div className={'disbaledField'}>
                        INR {record.totalCommissionInInr}
                    </div>
                </InputBox>
            )
        },
        {
            key: 'invoiceDate',
            label: 'Invoice Date',
            required: true,
            type: 'date'
        },
        {
            key: 'paymentMode',
            label: 'Payment Mode',
            required: true,
            type: 'select',
            options: paymentModeList,
            keyAccessor: x => x,
            valueAccessor: x => x,
            onChange: x => {
                setFieldsValue({
                    paymentMode: x
                })
            }
        },
        {
            key: 'details',
            label: 'Details',
            type: 'textArea',
            rows: 4
        }
    ]
    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }
    return (
        <Drawer visible={visible} onClose={onClose} width={'40%'} title={'Pay Commission'}>
            <Card>
                <Form onSubmit={handleSubmit}>
                    <Row gutter={24}>
                        {fields.map((item, key) => {
                            return (
                                item.customField ?
                                    <Col span={24} key={key}>
                                        {item.customField}
                                    </Col> :
                                    <Col span={24} key={key}>
                                        <GetEachFormFields
                                            item={item}
                                            getFieldDecorator={getFieldDecorator}
                                            formItemLayout={formItemLayout}/>
                                    </Col>
                            )
                        })}
                        <Col span={24}>
                            <Form.Item className={'alignRight'}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Drawer>
    )

}

const WrappedApplyForPayCommission = Form.create()(PayCommission)
export default WrappedApplyForPayCommission
