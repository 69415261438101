export default (
  state = {
    loader: false,
    pendency: {},
    allPendency: [],
    totalPendency: 0
  },
  action
) => {
  switch (action.type) {
    case 'LIST_PENDENCY':
      return { ...state, pendency: action.payload.data }
    case 'ADD_PENDENCY':
      return { ...state, addedPendency: action.payload }
    case 'LIST_ALL_PENDENCY':
      return { ...state, allPendency: action.payload.data, totalPendency: action.payload.total }
    default:
      return state
  }
}
