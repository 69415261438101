import React, { useState } from 'react'
import { Form, Button, Drawer, Row, Col, Input, notification, DatePicker } from 'antd'
import { useDispatch } from 'react-redux'
import { uploadScreenshotsFxn } from '../actions/cornerStone'
import { FileInput } from '../../../components/_utils/appUtils'
import moment from 'moment'

const UploadScreenshots = (props) => {
  const {
    form: { getFieldDecorator, validateFieldsAndScroll, resetFields },
    visible,
    onClose,
    studentId,
    applicationId
  } = props
  const dispatch = useDispatch()
  let [state, setState] = useState({
    screenshots: {},
    details: '',
    backDate: undefined
  })

  const events = {
    _updateState: (data) => {
      setState((prevState) => {
        return {
          ...prevState,
          ...data
        }
      })
    }
  }

  const handleFileChange = (e, index) => {
    const { files: selectedFiles } = e.target
    if (selectedFiles && selectedFiles.length) {
      events._updateState({ screenshots: selectedFiles[0] })
    }
  }

  const handleDetailsChange = (e, index) => {
    events._updateState({ details: e.target.value })
  }

  const handleDateChange = (date, dateString) => {
    events._updateState({ backDate: dateString })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    validateFieldsAndScroll(async (err) => {
      if (!err) {
        const fd = new FormData()
        if (!state.screenshots || (state.screenshots && !state.screenshots.name)) {
          notification.warning({ message: 'Choose screenshot' })
          return
        }
        let obj = {
          studentId, applicationId, details: state.details, backDate: state.backDate
        }
        fd.append('screenshot', state.screenshots)
        fd.append('obj', JSON.stringify(obj))

        try {
          const data = await dispatch(uploadScreenshotsFxn(fd))
          if (data && data.success) {
            resetFields()
            onClose()
          }
        } catch (error) {
          notification.error({ message: 'Error occurred during upload.' })
        }
      }
    })
  }

  return (
      <Drawer
          visible={visible}
          width={'40%'}
          placement='right'
          onClose={onClose}
          title={'Upload Screenshots'}
      >
        <div className='form-box'>
          <div className='card unizportal'>
            <Form onSubmit={handleSubmit}>
              <Row gutter={24}>
                <Col span={24}>
                  <FileInput
                      name={`Screenshots`}
                      label={`Screenshot`}
                      chooseDocument={(e) => handleFileChange(e)}
                  />
                  <Form.Item label='Details'>
                    {getFieldDecorator(`Details`, {
                      rules: [{ required: true, message: 'Please input details!' }],
                      initialValue: state.details
                    })(<Input placeholder='Details' className={'form-control'}
                              onChange={(e) => handleDetailsChange(e)} />)}
                  </Form.Item>
                  <Form.Item label='Choose Enroll Date'>
                    {getFieldDecorator(`Date`, {
                      rules: [{ required: true, message: 'Please choose date!' }],
                      initialValue: state.backDate ? moment(state.backDate) : null
                    })(<DatePicker placeholder='Choose Enroll Date' className={'form-control'}
                                   onChange={handleDateChange} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <button className='btn' type='submit'>Submit
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Drawer>
  )
}

const WrappedUploadScreenshots = Form.create()(UploadScreenshots)
export default WrappedUploadScreenshots
