import React from 'react'
import { Drawer, Form, Button, Icon } from 'antd'
import { connect } from 'react-redux'
import { getEvaluateFeaturedCourse } from './action'
import AddApplications from '../applications/views/add'
import { SingleCourseBlock } from './singleBlocks'
import _ from 'lodash'
import { EligibilityIcon } from '../university/drawers/universityEligibility'
import AddStudentContent from '../student/views/addStuContent'

class FeaturedCourse extends React.Component {
  events = {
    hideStudentDrawer: () => {
      this.setState({
        visibleStudentDrawer: false,
        selectedCourse: {}
      })
    },
    showStudentDrawer: (course) => {
      let echCou = _.clone(course)
      echCou.applicationFee = echCou.courseUniversity.applicationFee
      echCou.universityName = echCou.courseUniversity.universityName
      echCou.courseUniversity = echCou.courseUniversity._id
      echCou.countryName = echCou.universityCountry.countryName
      echCou.universityCountry = echCou.universityCountry._id
      echCou.universityState = echCou.universityState._id
      echCou.universityCity = echCou.universityCity._id
      echCou.courseId = echCou._id
        console.log(echCou, '===================demo')
        this.setState({
            visibleStudentDrawer: true,
            selectedCourse: echCou
        })
    }
  }

  componentDidMount() {
    this.loadFeaturedCourse()
  }

  constructor() {
    super()
    this.state = {
      courseList: [],
      skip: 0,
      loadMore: true,
      visibleStudentDrawer: false,
      selectedCourse: {}
    }
  }

  loadMore = () => {
    this.loadFeaturedCourse()
  }

  loadFeaturedCourse = async () => {
    let { dispatch, item, featured, filters } = this.props
      // console.log(filters, 'filters')
    let { courseList } = this.state
    let skip = courseList.length
    let params = {
      ...filters,
      universityId: item._id,
      limit: 9,
      skip,
      featured
    }
    let { data, totalCount } = await dispatch(getEvaluateFeaturedCourse(params))
    let obj = {
      courseList: [...courseList, ...data],
      loadMore: [...courseList, ...data].length == totalCount ? false : true,
      totalCount
    }
    this.setState(obj)
  }

  render() {
    let { item, visible, onClose, showCommission } = this.props
    let { courseList, totalCount, visibleStudentDrawer, selectedCourse } = this.state
    let { events } = this

    return (
      <Drawer
        visible={visible}
        onClose={onClose}
        width={'60%'}>
        <div className='academy-box featureCourseDiv'>
          <div className='heading-form d-flex align-items-center mb-3'>
            <span className={'logo'}>
          {item.logo && item.logo.url ?
            <img src={item.logo.url} alt='' /> :
            <img src='/dist/img/academy.jpg' alt='' />}
        </span>
            <div className={'infoBox'}>
              <div className={'featureCourseHeader'}>
                <h5>{item.universityName},
                  {item.universityCountry && item.universityCountry.countryName ? item.universityCountry.countryName : ''}
                </h5>
                {item.universityCity && item.universityCity.cityName ? item.universityCity.cityName : ''}
                {item.universityState && item.universityState.stateName ? `(${item.universityState.stateName})` : ''}
              </div>

              <div className={'totalInfo'}>
                <div className={'totalCourseCount'}>
                  Total courses {totalCount}
                </div>
                <EligibilityIcon btn={'eligiBtn'}
                                 universityId={item._id}
                                 countryId={item.universityCountry._id} />
              </div>

            </div>

          </div>

          <div className='card cardRelative'>
            <div className={'row courseBox'}>
              {courseList && courseList.length ? courseList.map((item, key) => {
                return (
                  <SingleCourseBlock
                    showCommission={showCommission}
                    item={item} key={key} callback={() => {
                    events.showStudentDrawer(item)
                  }} />
                )
              }) : null}
            </div>
            <div className={'row'}>
              <div className={'col-md-12 alignCenter'}>
                {totalCount > courseList.length ?
                  <button className='btn'
                          onClick={() => this.loadMore()}>Show {totalCount - courseList.length} more courses <Icon
                    type='down' />
                  </button> : null}
              </div>
            </div>

          </div>

          <br />
          <br />
          <br />
          {visibleStudentDrawer ? <AddApplications
            visible={visibleStudentDrawer}
            selectedCourse={selectedCourse}
            closeStudentDrawer={events.hideStudentDrawer} /> : null}
        </div>

      </Drawer>
    )
  }
}


const WrappedEvaluateIndex = Form.create()(FeaturedCourse)

const mapStateToProps = ({ global, router }) => ({
  pageLoading: global.pageLoading,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedEvaluateIndex)
