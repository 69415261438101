import React, { useState, useEffect } from 'react'
import { Drawer, Form, Input, Button, Row, Col, Select, AutoComplete, Icon } from 'antd'
import { connect, useDispatch } from 'react-redux'
import {
  singleParticipantFxn, addIcefMeetingFxn,
  icefCountryListFxn,
  icefUniversityListFxn,
  icefEventListFxn,
  icefCompanyListFxn,
  icefAddParticipantFxn,
  icefEditParticipantFxn
} from '../../actions'
import { InputBox } from '../../../../components/_utils/appUtils'
import _ from 'lodash'
import { LoadAllCountry, LoadState } from '../../../../components/_utils/countryUtil'
import { notification } from 'antd'

const { Option } = Select
let initState = {
  countryId: undefined,
  universityId: undefined,
  universityName: undefined,
  website: '',
  contactPersonName: '',
  email: '',
  eventId: '',
  eventName: '',
  meetingType: undefined,
  companyId: undefined,
  companyName: undefined,
  countryName: '',
  contract: undefined,
  stateName: '',
  cityName: '',
  pincode: '',
  mobile: '',
  description: '',
  department: '',
  position: '',
  salutation: undefined

}
const meetingTypeList = [
  'Agent',
  'Educator',
  'Service Provider'
]

let timeout

function fetch(companyName, dispatch, callback) {
  if (timeout) {
    clearTimeout(timeout)
    timeout = null
  }

  async function fake() {
    let obj = {
      results: 20,
      count: 20,
      companyName: companyName,
      regExFilters: ['companyName']
    }
    let { data } = await dispatch(icefCompanyListFxn(obj))
    callback(data.data)
  }

  timeout = setTimeout(fake, 1000)
}


const EditParticipantDrawer = (props) => {
  const { visible, onClose, participantId, form } = props
  let [state, setState] = useState(initState)
  let [countryList, setCountryList] = useState([])
  let [universityList, setUniversityList] = useState([])
  let [companyList, setCompanyList] = useState([])
  let [eventList, setEventList] = useState([])
  let [visibleAddEvent, setVisibleAddEvent] = useState(false)
  let [visibleAddCountry, setVisibleAddCountry] = useState(false)
  let [visibleAddUniversity, setVisibleAddUniversity] = useState(false)
  let [selectedCompanyDetails, setSelectedCompanyDetails] = useState(null)
  let [listState, setListState] = useState(initListState)

  let initListState = {
    allCountries: LoadAllCountry(),
    allStates: [],
    allCities: []
  }
  const salutationList = [
    'Mr.',
    'Mrs.',
    'Ms',
    'Dr.',
    'Prof.'
  ]

  let timeout

function fetch(companyName, dispatch, callback) {
  if (timeout) {
    clearTimeout(timeout)
    timeout = null
  }

  async function fake() {
    let obj = {
      results: 20,
      count: 20,
      companyName: companyName,
      regExFilters: ['companyName']
    }
    let { data } = await dispatch(icefCompanyListFxn(obj))
    callback(data.data)
  }

  timeout = setTimeout(fake, 1000)
}

  const dispatch = useDispatch()

  const fetchParticipantData = async () => {
    if (participantId) {
      try {
        const { data } = await dispatch(singleParticipantFxn(props))
        if (data) {
          setState({
            salutation: data.salutation || undefined,
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            position: data.position || '',
            email: data.email || '',
            mobile: data.mobile || '',
            department: data.department || '',
            description: data.description || '',
            universityId: data.universityId ? data.universityId._id : undefined,
            universityName: data.universityName || '',
            companyId: data.companyId ? data.companyId._id : undefined || '',
            companyName: data.companyName || '',
            street: data.street || '',
            cityName: data.cityName || '',
            stateName: data.stateName || '',
            countryId: data.countryId ? data.countryId._id : undefined,
            countryName: data.countryName || '',
            pincode: data.pincode || '',
            website: data.website || '',
            eventId: data.eventId ? data.eventId._id : '',
            meetingType: data.meetingType || undefined
          })
        }
      } catch (error) {
        console.error('Error fetching participant data:', error)
      }
    }
  }


  const fetchEventData = async () => {
    try {
      const { data } = await dispatch(icefEventListFxn({
        sortField: '_id',
        sortOrder: 'descend',
        results: 1000,
        count: 1000
      }))
      setEventList(data) // Populate eventList with eventId and eventName
    } catch (error) {
      console.error('Error fetching event data:', error)
    }
  }

  const fetchCountryList = async () => {
    try {
      const { data } = await dispatch(icefCountryListFxn({ results: 1000, count: 1000 }))
      setCountryList(data) // Update countryList with fetched data
    } catch (error) {
      console.error('Error fetching country data:', error)
    }
  }


  useEffect(() => {
    fetchParticipantData()
    fetchEventData()
    fetchCountryList()

  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    let resp = await dispatch(icefEditParticipantFxn({ ...state, participantId: participantId , companyName: state.companyName}))

    if (resp.success) {
      notification.success({ message: 'Participant updated successfully' })
      onClose()
    } else {
      notification.error({ message: 'not updated' })
    }
  }


  const handleEventSelect = (eventId, eventName) => {
    setState({
      ...state,
      eventId,
      eventName
    })
  }

  const events = {
    loadCountry: async () => {
      let obj = {
        results: 1000,
        count: 1000
      }
      let { data } = await dispatch(icefCountryListFxn(obj))
      setCountryList(data)
    },
    loadUniversity: async () => {
      let obj = {
        results: 1000,
        count: 1000,
        countryId: state.countryId
      }
      let { data } = await dispatch(icefUniversityListFxn(obj))
      setUniversityList(data)
    },

    _updateState: (data) => {
      setState((prevState) => {
        return {
          ...prevState,
          ...data
        }
      })
    },
    eventList: async () => {
      let searchParams = new URLSearchParams(window.location.search)
      let id = searchParams.get('id')
      let obj = {
        sortField: '_id',
        sortOrder: 'descend',
        results: 1000,
        count: 1000
      }
      let { data } = await dispatch(icefEventListFxn({
        ...obj
      }))
      setEventList(data)
      if (id) {
        events._updateState({ eventId: id })
      }
    },
    // setDefaultState: () => {
    //   if (selectedEventId) {
    //     events._updateState({ eventId: selectedEventId })
    //   }
    // },
    hideAddCountry: () => {
      setVisibleAddCountry(false)
    },
    hideAddUniversity: () => {
      setVisibleAddUniversity(false)
    },
    hideAddEvent: () => {
      setVisibleAddEvent(false)
    },
    refreshAddCountry: () => {
      setVisibleAddCountry(false)
      events.loadCountry()
    },
    refreshAddUniversity: () => {
      setVisibleAddUniversity(false)
      events.loadUniversity()
    },
    refreshAddEvent: () => {
      setVisibleAddEvent(false)
      events.eventList()
    },
    chooseCountry: (name) => {
      if (name || name === '') {
        const selectedCountry = Array.isArray(name) ? name[0] : name
        const countryExists = countryList.find(country => country.countryName === selectedCountry)
        if (countryExists) {
          setState(prevState => ({
            ...prevState,
            countryId: countryExists._id,
            countryName: countryExists.countryName,
            stateName: '',
            cityName: ''
          }))
          setListState(prevState => ({
            ...prevState,
            allStates: countryExists._id ? LoadState(countryExists._id) : []
          }))
        } else {
          const newCountryObject = {
            countryName: selectedCountry,
            countryId: null,
            _id: null
          }
          setState(prevState => ({
            ...prevState,
            ...newCountryObject,
            stateName: '',
            cityName: ''
          }))
          setListState(prevState => ({
            ...prevState,
            allStates: []
          }))
        }
      } else {
        setState(prevState => ({
          ...prevState,
          countryId: null,
          countryName: '',
          stateName: '',
          cityName: ''
        }))
        setListState(prevState => ({
          ...prevState,
          allStates: []
        }))
      }
    },
    chooseCompany: (name) => {
      if (name || name === '') {
        const selectedCompany = Array.isArray(name) ? name[0] : name;
        const companyExists = companyList.find((company) => company.companyName === selectedCompany);

        if (companyExists) {
          setState((prevState) => ({
            ...prevState,
            companyId: companyExists._id,
            companyName: companyExists.companyName,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            companyId: null,
            companyName: selectedCompany, // This assumes the new company name is directly used
          }));
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          companyId: null,
          companyName: '',
        }));
      }
    },

    chooseState: (name) => {
      let stateName = _.find(listState.allStates, (item) => {
        return item.name == name
      })
      if (stateName) {
        setState((prevState) => {
          return {
            ...prevState,
            stateName: stateName.name ? stateName.name : '',
            cityName: ''
          }
        })
      }
    },

    chooseUniversity: (name) => {
      if (name || name === '') {

        const selectedUniversity = Array.isArray(name) ? name[0] : name

        const universityExists = universityList.find(university => university.universityName === selectedUniversity)

        if (universityExists) {
          setState(prevState => ({
            ...prevState,
            universityId: universityExists._id,
            universityName: universityExists.universityName
          }))
        } else {
          const newUniversityObject = {
            universityName: selectedUniversity,
            universityId: null
          }
          setState(prevState => ({
            ...prevState,
            ...newUniversityObject
          }))
        }
      } else {
        setState(prevState => ({
          ...prevState,
          universityId: null,
          universityName: ''
        }))
      }
    },


    chooseDocument: (e) => {
      let { name, files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          [name]: files[0]
        })
      }
    }
  }

  let handleSearch = value => {
    if (value) {
      fetch(value, dispatch, data => {
        setCompanyList(data)
      })
    } else {
      setCompanyList([])
    }
  }


  return (
    <Drawer
      title='Edit Participant'
      width={'75%'}
      visible={visible}
      onClose={onClose}
      className='edit-participant-drawer'
    >
      <div className='form-box'>
        <div className='card unizportal'>
          <Form
            form={form}
            layout='vertical'
            onSubmit={handleSubmit}
            initialValues={state}
            scrollToFirstError
          >
            <Row gutter={16}>
              <Col span={12}>
                <InputBox title={'Choose Event *'}>
                  <Select
                    placeholder={'Choose Event'}
                    value={state.eventId}
                    onChange={(eventId) => {
                      const selectedEvent = eventList.find(event => event._id === eventId)
                      if (selectedEvent) {
                        handleEventSelect(selectedEvent._id, selectedEvent.eventName)
                      }
                    }}
                  >
                    {eventList.map((event) => (
                      <Option key={event._id} value={event._id}>
                        {event.eventName}
                      </Option>
                    ))}
                  </Select>
                </InputBox>
              </Col>

              <Col span={12}>
                <InputBox title={'Choose Type *'}>
                  <Select
                    placeholder={'Choose Type'}
                    value={state.meetingType}
                    onChange={(value) => {
                      events._updateState({ meetingType: value })
                    }}>
                    {meetingTypeList && meetingTypeList.length ? meetingTypeList.map((item) => {
                      return (
                        <Option value={item}>{item}</Option>
                      )
                    }) : null}
                  </Select>
                </InputBox>
              </Col>
              {state.meetingType ? <>
                <Col span={8}>
                  <InputBox
                    title={state.meetingType === 'Educator' ? 'Institute Name *' : state.meetingType === 'Service Provider' ? 'University Name *' : 'Company Name *'}>
                    <AutoComplete
                      placeholder='Enter Company Name'
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                      onSearch={handleSearch}
                      value={state.companyName}
                      className={'autoCompleted'}
                      allowClear
                      onChange={(value) => {
                        events.chooseCompany(value);
                      }}
                    >
                      { companyList.map((item) => (
                        <Option key={item._id} value={item.companyName}>{item.companyName}</Option>
                      )) }
                    </AutoComplete>

                  </InputBox>
                </Col>

                <Col span={8}>
                  <Form.Item>
                    <InputBox title={'Salutation'}>
                      <Select
                        placeholder={'Select Salutation'}
                        value={state.salutation}
                        onChange={(value) => {
                          events._updateState({ salutation: value })
                        }}>
                        {salutationList.map((salutation) => (
                          <Option key={salutation} value={salutation}>
                            {salutation}
                          </Option>
                        ))}
                      </Select>
                    </InputBox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item>
                    <InputBox title='First Name'>
                      <Input
                        value={state.firstName}
                        onChange={({ target }) => {
                          events._updateState({ firstName: target.value })
                        }}
                        placeholder='First Name'
                        className='form-control'
                      />
                    </InputBox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item>
                    <InputBox title='Last Name'>
                      <Input
                        value={state.lastName}
                        onChange={({ target }) => {
                          events._updateState({ lastName: target.value })
                        }}
                        placeholder='Last Name'
                        className='form-control'
                      />
                    </InputBox>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item>
                    <InputBox title='Position'>
                      <Input
                        value={state.position}
                        onChange={({ target }) => {
                          events._updateState({ position: target.value })
                        }}
                        placeholder='Position'
                        className='form-control'
                      />
                    </InputBox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item>
                    <InputBox title='Contact Person Email'>
                      <Input
                        value={state.email}
                        onChange={({ target }) => {
                          events._updateState({ email: target.value })
                        }}
                        placeholder='Email'
                        className='form-control'
                      />
                    </InputBox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <InputBox title='Enter Mobile No'>
                      <Input
                        value={state.mobile}
                        onChange={({ target }) => {
                          events._updateState({ mobile: target.value })
                        }}
                        placeholder='Mobile'
                        className='form-control'
                      />
                    </InputBox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <InputBox title='Department'>
                      <Input
                        value={state.department}
                        onChange={({ target }) => {
                          events._updateState({ department: target.value })
                        }}
                        placeholder='Department'
                        className='form-control'
                      />
                    </InputBox>
                  </Form.Item>
                </Col>


                <Col span={12}>
                  <Form.Item>
                    <InputBox title='Enter Street'>
                      <Input
                        value={state.street}
                        onChange={({ target }) => {
                          events._updateState({ street: target.value })
                        }}
                        placeholder='Street'
                        className='form-control'
                      />
                    </InputBox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <InputBox title='Enter City/Town'>
                      <Input
                        value={state.cityName}
                        onChange={({ target }) => {
                          events._updateState({ cityName: target.value })
                        }}
                        placeholder='City/Town'
                        className='form-control'
                      />
                    </InputBox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <InputBox title='Province/State Name'>
                      <Input
                        value={state.stateName}
                        onChange={({ target }) => {
                          events._updateState({ stateName: target.value })
                        }}
                        placeholder='Province/State Name'
                        className='form-control'
                      />
                    </InputBox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <InputBox title='Country'>
                      <Select
                        placeholder='Select Country'
                        value={state.countryName}
                        onChange={(countryId) => {
                          const selectedCountry = countryList.find((country) => country._id === countryId)
                          if (selectedCountry) {
                            setState({
                              ...state,
                              countryId,
                              countryName: selectedCountry.countryName
                            })
                          }
                        }}>
                        {countryList.map((country) => (
                          <Option key={country._id} value={country._id}>
                            {country.countryName}
                          </Option>
                        ))}
                      </Select>

                    </InputBox>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item>
                    <InputBox title='Enter Postal code'>
                      <Input
                        value={state.pincode}
                        onChange={({ target }) => {
                          events._updateState({ pincode: target.value })
                        }}
                        placeholder='Postal code'
                        className='form-control'
                      />
                    </InputBox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <InputBox title='Website'>
                      <Input
                        value={state.website}
                        onChange={({ target }) => {
                          events._updateState({ website: target.value })
                        }}
                        placeholder='Website'
                        className='form-control'
                      />
                    </InputBox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <InputBox title='Description'>
                      <Input.TextArea
                        onChange={({ target }) => {
                          events._updateState({ description: target.value })
                        }}
                        placeholder='Description'
                        className='form-control textareaField'
                        style={{ minHeight: 120 }}
                        rows='4'
                        value={state.description}

                      />
                    </InputBox>
                  </Form.Item>
                </Col>


              </> : null}

            </Row>
            <Form.Item>
              <Button type='primary' htmlType='submit' className='btn'>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Drawer>
  )
}

export default connect()(EditParticipantDrawer)
