import {notification} from 'antd'
import {hidePageLoad, showPageLoad} from '../../../modules/actions'
import {customAxios as axios, getToken} from '../../../request'
import {
  addExpenseWithdrawalCanadaUrl, agentListUrl,
  canadaAddExpenseUrl,
  expenseAmountRecordCanadaUrl,
  loadExpenseTransactionsCanadaUrl,
  sendExpenseWithdrawalOtpCanadaUrl, updateCanadaExpenseUrl, updateCanadaExpenseWithdrawalUrl
} from '../api'


export const addCanadaExpense = valData => async dispatch => {
  dispatch(showPageLoad())
  let {data} = await axios.post(canadaAddExpenseUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const listCanadaDailyExpense = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: {...filters},
    ...(await getToken())
  }
  let {data} = await axios.get(canadaAddExpenseUrl(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const expenseAmountRecordCanadaFxn = async (valData = {}) => {
  let {data} = await axios.post(expenseAmountRecordCanadaUrl(), valData, getToken())
  return data
}

export const loadExpenseTransactionsCanadaFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...(await getToken())
  }
  dispatch(hidePageLoad())
  let { data } = await axios.get(loadExpenseTransactionsCanadaUrl(), config)
  return data.data
}

export const sendExpenseWithdrawalCanadaOtp = valData => async dispatch => {
  dispatch(showPageLoad())
  let {data} = await axios.post(sendExpenseWithdrawalOtpCanadaUrl(), valData, getToken())
  dispatch(hidePageLoad())

  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const addExpenseWithdrawalCanadaFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let {data} = await axios.post(
    addExpenseWithdrawalCanadaUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())

  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const agentListFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(agentListUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}


export const updateCanadaStatusExpenseFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateCanadaExpenseUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const updateCanadaExpenseWithdrawalFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    updateCanadaExpenseWithdrawalUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

