import React, { useState, useEffect, useRef } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Avatar,
  Button,
  Card,
  Popconfirm,
  Tooltip,
  Drawer, Icon
} from 'antd'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { notification } from 'antd'
import { getPushPathWrapper, getUrlPushWrapper } from '../../../routes'
import { listPrivateMessages } from '../actions/privateMessage'
import moment from 'moment'
import { DefaultTablePagination, displayDate, nameAvatar, longDisplayDate } from '../../../components/_utils/appUtils'
import privateMessageReducers from '../reducers/privateMessage'
import Styles from './styles.less'
import { privateMessageReadyFxn } from '../../users/actions/user'

const initialState = {
  studentObj: '',
  viewCourseDrawer: false
}

const ListAllPrivateMessage = (props) => {
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  const { totalPrivateMessage } = useSelector((state) => ({
    totalPrivateMessage: state.privateMessageReducers.totalPrivateMessage
  }))
  const [state, setState] = useState(initialState)
  const [visible, setVisible] = useState(false)
  const [content, setContent] = useState('')
  const [itemNo, setItemNo] = useState(1)

  const tableRef = useRef()
  const dispatch = useDispatch()

  const apiRequest = async (params) => {
    return new Promise(async (resolve) => {
      let resp = await dispatch(listPrivateMessages({
        ...params,
        regExFilters: ['name']
      }))
      resolve(resp)
    })
  }


  const events = {
    viewMessage: async (record) => {
      let resp = await privateMessageReadyFxn(record._id)
      setContent(record.content)
      setVisible(true)
    }
  }
  let subStringFxn = (item) => {
    let totalLength = item.length
    if (totalLength > 200) {
      item = item.substring(0, 200) + ' ...'
      return item
    } else {
      return item
    }
  }


  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (value, item, index) => (itemNo - 1) * 10 + index + 1
    },
    {
      title: 'Send By',
      dataIndex: 'addedByUserId',
      key: 'addedByUserId',
      width: 120,
      render: (item, record) => {
        return (
          <div className={Styles.statusBox}>
            <div style={{ width: 20 }}>
              {record && record.status == 'unread' && <div className={Styles.unReadIcon}/>}
            </div>
            <span className={Styles.nameLabel}>{item && item.name ? item.name : ''}</span>
          </div>
        )
      }
    },
    {
      title: 'Send to',
      dataIndex: 'userId',
      key: 'userId',
      hidden: !(user.userType == 'admin'),
      width: 120,
      render: (item, record) => {
        return (
          <div className={Styles.userNameBox}>
            <div>
              {item && item.name ? item.name : ''}
            </div>
          </div>
        )
      }
    },
    {
      title: 'Content',
      dataIndex: 'content',
      width: 700,
      render: (item) => {
        return (
          subStringFxn(item)
        )
      }
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item ? longDisplayDate(item) : ''}
          </React.Fragment>
        )
      }
    },
    {
      key: 'actions',
      title: 'View',
      width: 100,
      height: 100,
      render: (text, record) => {
        return (
          <React.Fragment>
            <Tooltip title={'View Application'}>
              <button className="btn" onClick={() => events.viewMessage(record)}>
                <Icon type={'eye'}/>
              </button>
            </Tooltip>
          </React.Fragment>

        )
      }
    }
  ]

  const loadColumns = () => {
    let columnArr = []
    _.each(columns, (item) => {
      if (item.hidden == undefined) {
        columnArr.push(item)
      } else {
        if (!item.hidden) {
          columnArr.push(item)
        }
      }
    })
    return columnArr
  }


  return (
    <div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="table-head d-flex align-items-center">
              <h5>All Message: {totalPrivateMessage || 0}</h5>
              <div className="search-box-table">
                {/*<input className="form-control form-control" type="search" placeholder="Search"/>
                <img src="/dist/img/search.png" alt=""/>*/}
              </div>

              <div className="sort-box-table">
                {/*<select name="Sort By" id="">
                  <option>Sort By</option>
                  <option value="1">Date</option>
                  <option value="2">Time</option>
                </select>*/}
              </div>
            </div>
            <div className="card-body table-responsive">
              <TableComp columns={loadColumns()}
                         apiRequest={apiRequest}
                         pagination={DefaultTablePagination()}
                         ref={tableRef}
              />
            </div>
          </div>
        </div>
      </div>


      {visible ?
        <Drawer
          visible={visible}
          title={'Private Message'}
          width={700}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setVisible(false)
            tableRef.current.reload()
          }}>
          <Card>
            {content}
          </Card>
        </Drawer> : ''}

    </div>
  )
}

export default ListAllPrivateMessage
