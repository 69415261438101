import React, { useEffect, useState } from 'react'
import { studentCommissionsListFxn } from '../actions/chooseStudent'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { Tag } from 'antd'
import UniversityCommissionActivityComponent from '../searchApplication/universityCommissionActivities'

const CommissionTableSemWise = (props) => {
  let { withdrawalId } = props
  const dispatch = useDispatch()
  const [universityCommissions, setUniversityCommissions] = useState([])
  const { appId } = props
  let [activityState, setActivityState] = useState({
    visible: false,
    commissionId: '',
    commission: {}
  })

  const getUniversityCommission = async (params = {}) => {
    if (appId) {
      params.appId = parseInt(appId, 10)
    }
    try {
      const resp = await dispatch(studentCommissionsListFxn({ ...params }))
      setUniversityCommissions(resp.data)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    getUniversityCommission()
  }, [appId])

  if (!universityCommissions || universityCommissions.length === 0) {
    return null
  }

  const events = {
    showActivity: (data) => {
      setActivityState({
        visible: true,
        commissionId: data._id,
        commission: data
      })
    },
    hideActivity: () => {
      setActivityState({
        visible: false,
        commissionId: '',
        commission: {}
      })
    }
  }


  return (
    <div className='col-12 container'>
      <div className='card'>
        <div className='table-head d-flex align-items-center'>
          <h5>Commissions</h5>
        </div>
        <div className='card-body table-responsive mt-0'>
          <table className='table table-hover'>
            <thead>
            <tr>
              <th>Semester</th>
              <th>Commission Claimed</th>
              <th>Commission Received</th>
              <th>Agent Commission</th>
              <th>Profit</th>
              <th>Activity</th>
            </tr>
            </thead>
            <tbody>
            {universityCommissions.map((item) => {
              let id = item.withdrawalId
              return (
                <tr key={item.semesterIndex} className={id == withdrawalId ? 'warning_tr' : ''}>
                  <td width={160}>
                    {item.semesterIndex}

                  </td>
                  <td>
                    {item.amount ? <>
                      <Tag className={'successTag2'} style={{ fontWeight: 'bold' }}>
                        {item.currency} {item.countryId.currency}{item.amount}
                      </Tag>
                      <br />
                      <span className={'ml-2 mt-1 searchApplicationTableDate'}>
                      {moment(item.claimDate).format('DD/MM/YYYY')}
                    </span>
                    </> : <>Pending</>}
                  </td>
                  <td>
                    {item.studentCommission ? <>
                      <Tag className={'successTag2'} style={{ fontWeight: 'bold' }}>
                        {item.currency} {item.countryId.currency}{item.studentCommission}
                      </Tag>
                      <br />
                      <span className={'ml-2 mt-1 searchApplicationTableDate'}>
                {moment(item.claimReceivedDate).format('DD/MM/YYYY')}
              </span>
                    </> : <>Pending</>}
                  </td>
                  <td>
                    {(item.approvedByAdminCommission || item.commission) ?
                      <>
                        <Tag className={'successTag2'} style={{ fontWeight: 'bold' }}>
                          {item.currency} {item.countryId.currency}{item.commissionBonus
                          ? item.approvedByAdminCommission
                          : item.commission}
                        </Tag>
                        <br />
                        <span
                          className={'ml-2 mt-1 searchApplicationTableDate'}>{moment(item.commissionRaisedDate).format('DD/MM/YYYY')}</span>
                      </> : <>Pending</>}
                  </td>
                  <td>
                    {(item.studentCommission && (item.approvedByAdminCommission || item.commission)) ?
                      <Tag className={'successTag2'} style={{ fontWeight: 'bold' }}>
                        {item.currency} {item.countryId.currency}
                        {item.commissionBonus ? parseInt(item.studentCommission, 10) - parseInt(item.approvedByAdminCommission, 10)
                          : parseInt(item.studentCommission, 10) - parseInt(item.commission, 10)}
                      </Tag> : <></>}
                  </td>
                  <td>
                    {id == withdrawalId ? <div>
                      <a className={'btn btn-success roundNew sm'} onClick={() => events.showActivity(item)}>In Current
                        Withdrawal</a>
                    </div> : <>
                      <a className={'btn btn-default roundNew sm'} onClick={() => events.showActivity(item)}>View</a>
                    </>}
                  </td>
                </tr>
              )
            })}
            </tbody>
          </table>
        </div>
      </div>
      {activityState.visible ?
        <UniversityCommissionActivityComponent
          {...activityState}
          onClose={events.hideActivity}
        /> : null}
    </div>
  )
}

export default CommissionTableSemWise
