import React, {useEffect, useState} from "react";
import {Button, Col, Drawer, Form, Icon, notification, Row, Tooltip, Card, Input} from "antd";
import {connect} from "react-redux";
import GetEachFormFields from "../../../components/_utils/appFormUtils";
import {commissionClaimFxn} from "../actions";
import {useDispatch} from "react-redux";
import moment from "moment";
import lodash from "lodash";
import ClaimStudentRow from "./claimStudentRow";
import CommissionListDrawer from "./pendingCommissionListDrawer";
import {CustomInput, InputBox} from "../../../components/_utils/appUtils";
import UniversityInfoComponent from "../Components/universityInfoComponent"

let initState = {
    visible: false,
    selectedCommissions: []
}
const ClaimReceivedDrawerComponent = (props) => {
    let dispatch = useDispatch()
    let {visible, onClose, onSubmit, status, form: {getFieldDecorator, getFieldValue, setFieldsValue}, record} = props;
    let {currency, universityId, countryId} = record
    let commissionId = record._id;
    let [commissionList, setCommissionList] = useState([])
    let [universityInvoice, setUniversityInvoice] = useState({})
    let [refreshKey, setRefreshKey] = useState(moment())
    let [state, setState] = useState(initState)

    useEffect(() => {
        setTimeout(() => {
            setFieldsValue({claimReceivedDate: moment()})
        }, 500)
        setCommissionList([...commissionList, record])
    }, [])

    let chooseDocument = (e) => {
        let {files} = e.target;
        if (files && files.length) {
            setUniversityInvoice(files[0])
        } else {
            setUniversityInvoice(null)
        }
    }

    let inputTypes = {
        fields: [
            {
                key: 'claimDate',
                label: 'Claim Date',
                placeholder: 'Claim Date',
                type: 'date',
                required: true,
                span: 8
            },
            {
                key: 'universityInvoice',
                label: 'University Invoice',
                placeholder: 'University Invoice',
                type: 'file',
                required: true,
                span: 8,
                customField: (
                    <InputBox title={`University Invoice`} customStyle={{marginTop: 5}}>
                        <Form.Item>
                            <Input type={'file'} name={'universityInvoice'} required={true}
                                   key={refreshKey}
                                   id={'universityInvoice'} className={'form-control'}
                                   onChange={(e) => {
                                       chooseDocument(e)
                                   }}/>
                        </Form.Item>
                    </InputBox>
                )
            },
            {
                key: 'details',
                label: 'Details',
                placeholder: 'Details',
                span: 24
            }
        ]
    }
    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    const handleStatusUpdate = (e) => {
        const {form} = props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let commissions = [];
                let totalCommission = 0
                let studentCommissions = 0
                let findNull = lodash.find(commissionList, (item) => {
                    return !item.amount
                })
                if (findNull) {
                    notification.warning({
                        message: 'Please Enter Student Commission'
                    })
                    return
                }
                lodash.each(commissionList, (item) => {
                    totalCommission = parseFloat(totalCommission) + parseFloat(item.amount)
                    commissions.push({commissionId: item._id, amount: item.amount})
                })
                valData.commissions = commissions;
                valData.currency = currency;
                valData.universityId = universityId._id

                let fd = new FormData()
                fd.append('obj', JSON.stringify(valData))
                if (universityInvoice && universityInvoice.name) {
                    fd.append('universityInvoice', universityInvoice)
                }
                let resp = await dispatch(commissionClaimFxn(fd));
                if (resp && resp.success) {
                    onSubmit()
                    onClose()
                    setRefreshKey(moment())
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }


    const events = {
        showVisibleCommission: () => {
            let {agentId} = record;
            let selectedCommissions = []
            lodash.each(commissionList, (item) => {
                if (item && item._id) {
                    selectedCommissions.push(item._id)
                }
            })
            setState({
                ...state,
                visible: true,
                selectedCommissions
            })
        },
        hideVisibleCommission: () => {
            setState({
                ...state,
                visible: false
            })
        },
        chooseCommission: (data) => {
            let commissionListTemp = lodash.clone(commissionList);
            let findCom = lodash.find(commissionListTemp, (item) => {
                return item._id == data._id
            })
            if (findCom) {
                notification.warning({message: "Commission already exists."})
            } else {
                commissionListTemp.push(data)
                setCommissionList(commissionListTemp);

            }
        },
        removeStu: (commissionId) => {
            let commissionListTemp = lodash.clone(commissionList);
            commissionListTemp = lodash.reject(commissionListTemp, (item) => {
                return item._id == commissionId
            })
            setCommissionList(commissionListTemp);
        },
        updateRecord: (data) => {
            let commissionListTemp = lodash.clone(commissionList);
            let findCom = lodash.find(commissionListTemp, (item) => {
                return item._id == data._id
            })
            if (findCom) {
                findCom.amount = parseFloat(data.amount);
                setCommissionList(commissionListTemp);
            }
        }
    }

    return (
        <>
            <Drawer
                visible={visible}
                onClose={onClose}
                title={`Claim Commission`}
                width={'80%'}>
                <div className="form-box commissionBox">
                    <div className="card unizportal">
                        <Form onSubmit={handleStatusUpdate}>
                            <Row gutter={16} className={'wrapBox'}>
                                <Col span={6}>
                                    <UniversityInfoComponent universityId={universityId} countryId={countryId}/>
                                </Col>
                                <Col span={18}>
                                    <Card>
                                        {inputTypes.fields.map((item, key) => {
                                            return (
                                                !item.hidden &&
                                                <Col span={item.span} key={key}>
                                                    {item.customField ? item.customField :
                                                        <GetEachFormFields
                                                            item={item}
                                                            getFieldDecorator={getFieldDecorator}
                                                            formItemLayout={formItemLayout}/>}
                                                </Col>
                                            )
                                        })}
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <div className={'commissionListBox'}>
                                        {commissionList && commissionList.length ? commissionList.map((item, key) => {
                                            return <>
                                                <ClaimStudentRow item={item} key={key} index={key}
                                                                 removeStu={events.removeStu}
                                                                 updateRecord={events.updateRecord}
                                                />
                                            </>
                                        }) : null}
                                        <Tooltip title={'Add Commission'}>
                                            <a className={'btn btn-default btn-sm addMoreCommissionBtn'}
                                               onClick={() => {
                                                   events.showVisibleCommission()
                                               }}>
                                                <Icon type={'plus'}/>
                                            </a>
                                        </Tooltip>
                                    </div>
                                </Col>
                                <Col span={4}>
                                    <Form.Item>
                                        <Button
                                            type="primary" htmlType="submit" className={'btn mt40'}>
                                            Claim Commission
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>

                    </div>
                </div>
                {state.visible ?
                    <CommissionListDrawer
                        record={record}
                        commissionId={commissionId}
                        selectedCommissions={state.selectedCommissions}
                        onClose={events.hideVisibleCommission}
                        onSubmit={events.chooseCommission}
                        status={'Pending'}
                        visible={state.visible}/>
                    : null}
            </Drawer>
        </>
    )
}

const ClaimReceivedDrawer = Form.create()(ClaimReceivedDrawerComponent)
const mapStateToProps = ({global, router}) => ({
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClaimReceivedDrawer)
