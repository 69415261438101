import React from "react"
import {Select, Spin} from "antd"
import {fetchUserListFxn, reeudoAgentListFxn} from "../../users/actions/user";
import debounce from 'lodash/debounce'
import _ from "lodash"

const {Option} = Select;

class ReeudoAgentAutoComplete extends React.Component {
    state = {
        arrList: [],
        value: [],
        fetching: false,
        companyName: "",
        agentId: "",
    };

    constructor(props) {
        super(props);
    }

    _getUserId = () => {
        let {agentId} = this.props;
        if (agentId) {
            this.setState({
                agentId
            }, () => {
                this.fetchUser()
            })
        }
    }

    componentDidMount() {
        this._getUserId()
    }

    fetchUser = async (companyName) => {
        let {agentId} = this.state;
        this.setState({arrList: [], fetching: true});
        let obj = {
            companyName,
        }
        if (agentId && !companyName) {
            obj._id = agentId;
        }

        let {data} = await reeudoAgentListFxn({...obj});
        let dataNew = []
        if (data && data.length) {
            _.each(data, (user) => {
                dataNew.push({
                    text: `${user.companyName} (${user.name})`,
                    value: user._id,
                })
            })
        }
        let newObj = {
            arrList: dataNew
        }
        if (agentId && !companyName && dataNew && dataNew.length) {
            let currentAgent = dataNew[0]
            newObj.companyName = {
                key: currentAgent.value,
                label: currentAgent.text,
            };
        }
        this.setState(newObj)
    };

    handleChange = value => {
        let {onSelect} = this.props;
        this.setState({
            companyName: value,
            agentId: value && value.key ? value.key : "",
            data: [],
            fetching: false,
        });
        onSelect((value && value.key) || "")
    };

    render() {
        const {fetching, arrList, companyName, required = false} = this.state
        let {customStyle = 'antSelect'} = this.props
        return (
            <Select
                labelInValue
                value={companyName || undefined}
                className={customStyle}
                placeholder='Select agent'
                allowClear={true}
                notFoundContent={fetching ? <Spin size="small"/> : null}
                filterOption={false}
                showSearch={true}
                onSearch={debounce(this.fetchUser, 500)}
                onChange={this.handleChange}>
                {arrList.map(d => (
                    <Option key={d.value} value={d.value}>{d.text}</Option>
                ))}
            </Select>
        );
    }
}

export default ReeudoAgentAutoComplete
