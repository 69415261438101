import React, { useState } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { Form, Select, Button, Card, notification, Spin, Row, Col, Drawer } from 'antd'
import _ from 'lodash'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { useDispatch, useSelector } from 'react-redux'
import { addSampleDocument } from '../actions/sampleDocument'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { FileInput } from '../../../components/_utils/appUtils'

const AddSampleDocument = (props) => {
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields }, visible, events } = props
  const dispatch = useDispatch()
  const [document, setDocument] = useState({})
  let inputTypes = [
    { key: 'name', label: 'Name', type: 'text', required: true },
    {
      key: 'document', label: 'Document', name: 'Document', type: 'file', onChange: (e) => {
        chooseDocument(e)
      }
    }
  ]


  const chooseDocument = (e) => {
    let { name, files } = e.target
    if (files && files.length) {
      setDocument(files[0])
    }
  }


  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = e => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        if (!document || (document && !document.name)) {
          return notification.warning({
            message: 'Please choose document.'
          })
        }
        let fd = new FormData()
        fd.append('obj', JSON.stringify(valData))
        fd.append('document', document)
        let data = await dispatch(addSampleDocument(fd))
        if (data && !data.error) {
          form.resetFields()
        }
        events.hideDrawer()
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }
  return (
    <Drawer
      visible={visible}
      width={600}
      placement="right"
      onClose={events.hideDrawer}
      title={'Add Sample Document'}>
      <div className="form-box">
        <div className="card unizportal">
          <Form onSubmit={handleSubmit} style={{ marginTop: 8, textAlign: 'left' }}>
            <Row gutter={24}>

              {inputTypes.map((item, key) => {
                return (
                  <Col span={24} key={key}>
                    {item.type == 'file' ?
                      <FileInput name={item.name}
                                 label={item.label}
                                 chooseDocument={item.onChange}/>
                      : <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout}/>
                    }
                  </Col>
                )
              })}
            </Row>


            <Form.Item>
              <Button type="primary" htmlType="submit" className={'btn'}>
                SUBMIT
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Drawer>
  )
}
const WrappedCountry = Form.create()(AddSampleDocument)
export default WrappedCountry
