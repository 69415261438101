import { apiUrl } from '../../../settings'

export const addCountryUrl = () => {
  return apiUrl + '/country'
}

export const listCountriesUrl = () => {
  return apiUrl + '/country'
}

export const listCountryUrl = (id) => {
  return apiUrl + '/country/' + id
}
