import React, { useEffect, useState } from 'react'
import { Drawer, Form, Button, Select, Input, Row, Col, notification, DatePicker } from 'antd'
import { useDispatch } from 'react-redux'
import { InputBox } from '../../../components/_utils/appUtils'
import TextArea from 'antd/es/input/TextArea'
import { payCommissionFxn } from './actions'

const PayCommission = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, onSubmit, studentId, applicationId } = props
  let [details, setDetails] = useState('')
  let [date, setDate] = useState()
  let [file, setFile] = useState('')

  const handleSubmit = async () => {
    let obj = {
      applicationId: applicationId, studentId: studentId, details: details, commissionDate: date
    }
    if (!file || (file && !file.name)) {
      notification.warning({ message: 'Please choose Receipt.' })
      return
    }
    if (!date) {
      notification.warning({ message: 'Please choose Date.' })
      return
    }
    let fd = new FormData()
    fd.append('obj', JSON.stringify(obj))
    if (file && file.name) {
      fd.append('receipt', file)
    }
    let resp = await dispatch(payCommissionFxn(fd))
    if (resp && resp.success) {
      onSubmit()
    }
  }

  return (
    <>
      <Drawer title={'Pay Commission'}
              visible={visible} width={'45%'}
              onClose={onClose}
      >
        <div className='form-box'>
          <div className='card unizportal'>
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                  <InputBox title={'Choose Receipt *'}>
                    <input
                      className={'form-control'}
                      type={'file'}
                      onChange={({ target }) => {
                        if (target.files && target.files.length) {
                          setFile(target.files[0])
                        } else {
                          setFile('')
                        }
                      }} />
                  </InputBox>
                </Col>
                <Col span={12}>
                  <InputBox title={'Date *'}>
                    <DatePicker
                      value={date}
                      className={'form-control'}
                      onChange={(e) => {
                        setDate(e)
                      }}
                    />
                  </InputBox>
                </Col>
                <Col span={24}>
                  <InputBox title={'Details '}>
                    <TextArea
                      value={details}
                      className={'form-control'}
                      onChange={(e) => {
                        setDetails(e.target.value)
                      }}
                      placeholder='Enter Details'
                      autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                  </InputBox>
                </Col>
              </Row>
              <Button
                type='primary'
                htmlType='submit'
                className='btn'
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  )
}
export default PayCommission
