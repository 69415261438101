import {notification} from 'antd'
import {getToken, customAxios as axios} from '../../../request'
import {showPageLoad, hidePageLoad} from '../../../modules/actions'
import {updateMarketingUserManuallyUrl} from "../api/marketingApi";


export const updateMarketingUserManuallyFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateMarketingUserManuallyUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (valData.showNotification) {
        if (!data.error) {
            notification.success({
                message: data.message || 'Success'
            })
        } else {
            notification.error({
                message: data.message || 'Error'
            })
        }
    }
    return data
}
