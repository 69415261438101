import React, {useEffect, useState} from 'react';
import {Form, Button, Row, Col, Input, notification, Modal} from 'antd';
import {useDispatch} from 'react-redux';
import {agentUnizMarginFxn, updateAgentUnizMarginFxn} from "../actions";
import {InputBox, numberToWords} from "../../../components/_utils/appUtils";

const AgentUnizMarginModal = (props) => {
    const {agentId, visible, onClose, unizMargin, defaultExchangeMargin} = props;
    ;
    const dispatch = useDispatch();
    const [agentUnizMargin, setAgentUnizMargin] = useState(unizMargin);

    const getAgentUnizMargin = async () => {
        const data = await dispatch(agentUnizMarginFxn({agentId: agentId}));
        if (data) {
            setAgentUnizMargin(data.agentUnizMargin || unizMargin);
        } else {
            setAgentUnizMargin(unizMargin)
        }
    };

    const handleUpdate = async () => {
        if (agentUnizMargin < defaultExchangeMargin) {
            notification.warning({message: `Exchange margin should not be less the ${defaultExchangeMargin}`})
            return
        }
        const resp = await dispatch(updateAgentUnizMarginFxn({agentId: agentId, agentUnizMargin: agentUnizMargin}));
        if (resp.success) {
            notification.success({
                message: 'Agent Uniz Margin Updated'
            });
            onClose();
        }
    };

    useEffect(() => {
        getAgentUnizMargin();
    }, []);

    // const displayUnizMargin = agentUnizMargin || unizMargin || 0.00;

    return (
        <Modal
            title="Agent Uniz Margin"
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            <div className="row">
                <div className="col-lg-12">
                    <Form>
                        <div className="form-box mt-4">
                            <div className="card unizportal">
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <InputBox title="Agent Uniz Margin">
                                            <Input
                                                type="text"
                                                value={agentUnizMargin}
                                                className="form-control"
                                                placeholder="Enter Uniz agent margin"
                                                onChange={(e) => setAgentUnizMargin(e.target.value)}
                                            />
                                        </InputBox>
                                        <div className="margin-in-words">
                                            <p><b>Amount In Words:</b> {numberToWords(parseFloat(agentUnizMargin))}</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="btn"
                                            onClick={handleUpdate}
                                    >
                                        Update
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Modal>
    );
};

export default AgentUnizMarginModal;
