import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import { allCommissionListUrl, agentCommissionCountUrl, getCurrentBalanceUrl, payCommissionUrl } from '../apis'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'


export const listAllCommission = (filters) => async (dispatch) => {
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let resp = await axios.get(allCommissionListUrl(), config)
  if (resp && resp.error) {
    notification.error({
      message: resp.message || 'Error'
    })
  }
  return resp.data
}

export const agentCommissionCount = (filters) => async (dispatch) => {
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let resp = await axios.get(agentCommissionCountUrl(), config)
  if (resp && resp.error) {
    notification.error({
      message: resp.message || 'Error'
    })
  }
  return resp.data
}

export const payCommission = (valdata) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(payCommissionUrl(), valdata, getToken())
  dispatch(hidePageLoad())
  if (data && data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getCurrentBalance = (valdata) => async (dispatch) => {
  let resp = await axios.post(getCurrentBalanceUrl(), valdata, getToken())
  if (resp && resp.error) {
    notification.error({
      message: resp.message || 'Error'
    })
  }
  return resp.data
}
