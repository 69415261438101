import React, { useEffect, useState } from 'react'
import { Card, Icon, Tooltip } from 'antd'
import { CustomInput, displayDate, longStringDate } from '../../../components/_utils/appUtils'

const StudentRow = (props) => {
  let { item, index, removeStu, updateRecord, bonus, updateApprovedByAdminRecord } = props
  let { universityId, studentId, countryId, agentId, commissionType, commissionValue, currency } = item
  let [commission, setCommission] = useState(item.commission)
  let [commissionInInr, setCommissionInInr] = useState(null)
  let [approvedByAdminCommission, setApprovedByAdminCommission] = useState(item.approvedByAdminCommission)
  let [approvedByAdminCommissionInInr, setApprovedByAdminCommissionInInr] = useState(null)
  let updateStudentCommission = (value) => {
    setCommission(value)
  }
  useEffect(() => {
    updateCommissionInRecord()
  }, [commission])

  useEffect(() => {
    approvedByAdminConvertInInr()
  }, [approvedByAdminCommission])

  const approvedByAdminConvertInInr = () => {
    let approvedByAdminCommissionInInr = approvedByAdminCommission
    if (currency !== 'INR') {
      approvedByAdminCommissionInInr = approvedByAdminCommission * item.currencyRate
    }
    if (approvedByAdminCommissionInInr) {
      approvedByAdminCommissionInInr = parseFloat(approvedByAdminCommissionInInr).toFixed(2)
      approvedByAdminCommissionInInr = Math.round(approvedByAdminCommissionInInr)
    }
    setApprovedByAdminCommissionInInr(approvedByAdminCommissionInInr)
    updateApprovedByAdminRecord({ _id: item._id, approvedByAdminCommission, approvedByAdminCommissionInInr })
  }

  const updateCommissionInRecord = () => {
    let commissionInInr = commission
    if (currency !== 'INR') {
      commissionInInr = commission * item.currencyRate
    }
    if (commissionInInr) {
      commissionInInr = parseFloat(commissionInInr).toFixed(2)
      commissionInInr = Math.round(commissionInInr)
    }
    setCommissionInInr(commissionInInr)
    updateRecord({ _id: item._id, commission, commissionInInr })
  }


  return (
    <>
      {universityId && universityId.universityName ?
        <div className={`commissionParentBox`}>
          <Card className={'commissionUniInfo'}>
            {universityId && universityId.universityName ?
              <div className={'row'}>
                <div className={'studentBox flexStartNew'}>
                  {removeStu && index > 0 ?
                    <Tooltip title={'Remove Commission'}>
                      <a className={'btn btn-danger btn-sm dangerPos'}
                         onClick={() => {
                           removeStu(item._id)
                         }}>
                        <Icon type={'delete'} />
                      </a> </Tooltip> : ''}
                  {universityId && universityId.smallLogo && universityId.smallLogo.url ?
                    <div className={'uniLogo'}>
                      <img src={universityId.smallLogo.url} />
                    </div> : null}
                  <div className={'studentInfo'} style={{ paddingLeft: 10 }}>
                    <div className={'uniName'}>
                      {universityId.universityName}
                      {countryId && countryId.countryName ? `, ${countryId.countryName}` : null}
                    </div>
                    <div className={'courseInfo'}>
                      {item.courseName}
                    </div>
                    {item && item.intake && item.intake.month ?
                      <div className={'courseInfo sm'}>
                        Intake : {item.intake.month}, {item.intake.year}
                      </div> : null}
                    {item && item.invoiceDate ?
                      <div className={'courseInfo sm'}>
                        Invoice Date : {displayDate(item.invoiceDate)}
                      </div> : null}
                  </div>
                </div>
                {studentId && studentId._id ?
                  <div className={'studentBox flexStartNew'}>
                    <div className={'studentInfo'}>
                      <div className={'uniName'}>{studentId.name}
                      </div>
                      <div className={'courseInfo'}>
                        Student ID : {studentId.studentId}
                      </div>
                      <div className={'courseInfo'}>
                        Application ID : {item.appId}
                      </div>
                      {agentId && agentId._id ? <div className={'courseInfo sm'}>
                        {agentId.name} <br />({agentId.companyName})
                      </div> : null}
                    </div>
                  </div> : null}

                <div className={'studentBox flexStartNew'}>
                  <div className={'studentInfo'}>
                    <div className={'uniName'}> {item.semester}
                    </div>
                    <div className={'courseInfo'}>
                      Semester Fees : {item.semesterFee} {item.currency}
                    </div>
                    <div className={'courseInfo'}>
                      Offer S. ID : {item.commissionDetails.offerLetterStudentId}
                    </div>
                    <div className={'courseInfo sm'}>
                      {item.currency == 'INR' ? <div>
                        Commission : {item.studentCommission} INR
                      </div> : <div>
                        Received Comm. : {item.studentCommission} {item.currency} (Rate
                        : {item.currencyRate})
                        {/*  Comm. in INR
                                                : {(item.studentCommission * item.currencyRate).toFixed(2)} INR*/}
                      </div>}
                    </div>
                    {item.claimReceivedDate ? <div className={'courseInfo sm'}>
                      Comm. Rec. Date : {longStringDate(item.claimReceivedDate)}
                    </div> : null}
                  </div>
                </div>
                <div className={'studentBox inputFlex'}>
                  <div className='form-group student-group'>
                    <CustomInput
                      title={'Agent Commission'}
                      leftContent={item.currency}
                      rightContent={commissionType == 'Percentage' ? `${commissionValue}%` : null}
                      value={commission}
                      onChange={(value) => {
                        updateStudentCommission(value)
                      }}
                    />
                    {item.currency !== 'INR' ? <div className={'courseInfo sm mt10'}>
                      Comm. in INR : {commissionInInr}
                    </div> : ''}
                  </div>
                </div>
                {bonus ?
                  <div className={'studentBox inputFlex'} key={bonus}>
                    <div className='form-group student-group'>
                      <CustomInput
                        title={'Comm. Approved By Admin'}
                        leftContent={item.currency}
                        rightContent={commissionType == 'Percentage' ? `${commissionValue}%` : null}
                        value={approvedByAdminCommission}
                        onChange={(value) => {
                          setApprovedByAdminCommission(value)
                        }}
                      />
                      {item.currency !== 'INR' ? <div className={'courseInfo sm mt10'}>
                        Comm. in INR : {approvedByAdminCommissionInInr ? approvedByAdminCommissionInInr : ''}
                      </div> : ''}
                      {/* {approvedByAdminCommissionInInr && approvedByAdminCommissionInInr > commissionInInr ?
                                                <div className={'courseInfo sm'}>
                                                    Bonus in INR : {approvedByAdminCommissionInInr - commissionInInr}
                                                </div> : null}*/}
                    </div>
                  </div> : null}

              </div> : null}
          </Card>
        </div>
        : null}

    </>
  )
}
export default StudentRow
