import React, {useEffect, useState} from "react"
import {Col, Drawer, notification, Row, Card, Icon, Tooltip, Popconfirm} from "antd";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {uploadEventGalleryFxn, SingleEventsFxn, removeSingleImageFxn} from "./action";
import {useDispatch} from "react-redux";
import _ from "lodash"
import moment from "moment";

const EventGallery = (props) => {
    let dispatch = useDispatch()
    let {eventId, visible, onClose, onSubmit, title} = props;
    const [document, setDocument] = useState([])
    const [momentKey, setMomentKey] = useState(moment())
    let [gallery, setGallery] = useState([])
    const loadSingleEvent = async () => {
        if (eventId) {
            let resp = await dispatch(SingleEventsFxn(eventId));
            if (resp && resp.gallery && resp.gallery.length) {
                setGallery(resp.gallery)
            }
        }
    }
    useEffect(() => {
        loadSingleEvent()
    }, [eventId])
    const chooseDocument = (e) => {
        let {name, files} = e.target
        if (files && files.length) {
            setDocument(files)
        }
    }
    const handleSubmit = async (e) => {
        if (!document || (document && !document.length)) {
            return notification.warning({
                message: 'Please choose document.'
            })
        }
        let fd = new FormData()
        fd.append('obj', JSON.stringify({eventId}))
        _.each(document, (item) => {
            fd.append('gallery', item)
        })
        let data = await dispatch(uploadEventGalleryFxn(fd));
        if (data && data.success) {
            setDocument([])
            setMomentKey(moment())
            loadSingleEvent();
        }
    }

    const deleteEventImage = async (galleryId, index) => {
        let resp = await dispatch(removeSingleImageFxn({eventId, galleryId}));
        if (resp && resp.success) {
            let cloneGallery = _.clone(gallery);
            cloneGallery.splice(index, 1)
            setGallery(cloneGallery)
        }
    }


    return (
        <Drawer title={'Gallery'} visible={visible} onClose={onClose} width={'60%'}>
            <div className="form-box">
                <div className="card unizportal">
                    <Card>
                        <Row gutter={24}>
                            <Col span={12}>
                                <div className={'labelRow'}>
                                    <label>
                                        Choose Image <small>(Choose maximum 10 files)</small>
                                    </label>
                                </div>
                                <div className={'innerBox rowFlex'} key={momentKey}>
                                    <input
                                        multiple={true}
                                        name={'document'}
                                        type={'file'}
                                        className={'form-control'}
                                        onChange={chooseDocument}/>
                                </div>
                            </Col>
                            <Col span={24} className={'mt10'}>
                                <a className={'btn btn-primary'} onClick={() => {
                                    handleSubmit()
                                }}>
                                    SUBMIT
                                </a>
                            </Col>
                        </Row>
                    </Card>
                    <Card title={'Gallery'} className={'mt10'}>
                        <div className={'event_gallery_list'}>
                            {gallery && gallery.length ? gallery.map((item, index) => {
                                return (
                                    item._id ? <>
                                        <div key={item._id} className={'single_img'}>
                                            <Popconfirm title={'Are you sure, you want to delete this image?'}
                                                        onConfirm={() => {
                                                            deleteEventImage(item._id, index)
                                                        }}>
                                                <a className={'cross_btn'}>
                                                    <Tooltip title={'Delete'}>
                                                        <Icon type={'close'}/>
                                                    </Tooltip>
                                                </a>
                                            </Popconfirm>
                                            <a href={item.path} target={'_blank'}>
                                                <LazyLoadImage src={item.path}/>
                                            </a>
                                        </div>
                                    </> : null
                                )
                            }) : null}
                        </div>
                    </Card>
                </div>
            </div>
        </Drawer>
    )
}
export default EventGallery
