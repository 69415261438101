import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import { LeadsListURL } from '../api'


export const addLeads = (valData) => async (dispatch) => {
  let { data } = await axios.post(LeadsListURL(), valData, getToken())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const getAllLeads = (filters) => async (dispatch) => {
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(LeadsListURL(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data

}
