import React, {useEffect, useState} from "react"
import {Button, Card, Drawer, notification} from "antd";
import {InputBox} from "../../../components/_utils/appUtils";
import {useDispatch} from "react-redux";
import {verificationCertificateAustraliaFxn} from "../actions/chooseStudent";

let statusList = [
    'Genuine', 'Not Genuine', 'Not Verified'
]
const StudentVerificationDrawerAust = (props) => {
    let dispatch = useDispatch()
    let {studentId, application, visible, onClose, reloadApp} = props;
    let [verificationCertificate, setVerificationCertificate] = useState("")
    useEffect(() => {
        if (application.verificationCertificate) {
            setVerificationCertificate(application.verificationCertificate)
        }
    }, [application])
    const submitVerification = async () => {
        if (!verificationCertificate) {
            notification.warning({message: "Please choose status."})
            return
        }
        let obj = {
            studentId,
            applicationId: application._id,
            verificationCertificate
        }
        let resp = await dispatch(verificationCertificateAustraliaFxn(obj));
        if (resp && resp.success) {
            onClose()
            reloadApp()
        }
    }

    return (
        <Drawer
            visible={visible}
            onClose={onClose}
            width={'35%'}
            title={'Student Verification Certificate'}
        >
            <Card>
                <InputBox title={'Status'}>
                    <select
                        className={'form-control'}
                        value={verificationCertificate}
                        onChange={({target}) => {
                            setVerificationCertificate(target.value)
                        }}>
                        <option value={""}>Choose Status</option>
                        {statusList.map((item, key) => {
                            return (
                                <option key={key} value={item}>{item}</option>
                            )
                        })}
                    </select>
                </InputBox>
                <div className={'alignRight mt20'}>
                    <Button type="primary" onClick={() => submitVerification()}>
                        Verify Certificate
                    </Button>
                </div>
            </Card>
        </Drawer>
    )
}
export default StudentVerificationDrawerAust
