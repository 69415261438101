import {customAxios as axios, getToken} from "../../../request";
import {notification} from "antd";
import {cambieAgentListUrl, updateCambieAgentStatusUrl} from "../apis";
import {hidePageLoad, showPageLoad} from "../../../modules/actions";

export const cambieAgentListFxn = (filters) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...await getToken()
    }
    let {data} = await axios.get(cambieAgentListUrl(), config);
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data.data;

}

export const updateCambieAgentStatusFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateCambieAgentStatusUrl(), valData, getToken());
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data;

}
