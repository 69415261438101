import {
    Button,
    Card,
    Col,
    Form,
    Icon,
    Input,
    Row,
    Select,
    Table,
    Tooltip,
    notification
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {InputBox} from '../../../components/_utils/appUtils'
import {LoadState} from '../../../components/_utils/countryUtil'
import {hideLoader, hidePageLoad} from '../../../modules/actions'
import {listAllCountries} from '../../countries/actions/countries'
import {listAllUniversities} from '../../university/actions/university'
import {getUser, listAllUsers, updateUser} from '../actions/user'

const FormItem = Form.Item
const {Option} = Select
const {TextArea} = Input

@Form.create()
class UserProfile extends PureComponent {
    agentEvents = {
        fetchAgent: async name => {
            let {dispatch} = this.props
            let obj = {
                results: 100,
                userType: 'agent',
                sortField: 'name',
                sortOrder: 'ascend',
                select: ['name', 'email', 'companyName'],
                name: name,
                block: false
            }
            let {data} = await dispatch(listAllUsers(obj))
            this.setState({
                agentList: data
            })
        },
        removeAgent: index => {
            let cloneAgent = _.clone(this.state.agents)
            cloneAgent.splice(index, 1)
            this.setState({
                agents: cloneAgent
            })
        },
        chooseAgent: data => {
            let {agents, agentList} = this.state
            let findUser = _.find(agents, item => {
                return item._id && item._id.toString() == data
            })
            if (findUser) {
                this.setState({
                    agentName: '',
                    agentList: []
                })
                return notification.warning({
                    message: 'Agent already exists.'
                })
            } else {
                let findAgent = _.find(agentList, item => {
                    return item._id && item._id.toString() == data
                })
                if (findAgent) {
                    let cloneAgents = _.clone(agents)
                    cloneAgents.push(findAgent)
                    this.setState({
                        agentName: '',
                        agentList: [],
                        agents: cloneAgents
                    })
                }
            }
        }
    }
    events = {
        selectAllUni: () => {
            const {
                form: {setFieldsValue}
            } = this.props
            let {universityList} = this.state
            let universities = []
            _.each(universityList, item => {
                if (item && item._id) {
                    universities.push(item._id)
                }
            })
            setFieldsValue({
                universities: universities
            })
        },
        deSelectAllUni: () => {
            const {
                form: {setFieldsValue}
            } = this.props
            let universities = []
            setFieldsValue({
                universities: universities
            })
        },
        removeStateCountry: index => {
            let cloneArr = _.clone(this.state.assignStateCountry)
            cloneArr.splice(index, 1)
            this.setState({
                assignStateCountry: cloneArr
            })
        },
        countryName: id => {
            let country = _.find(this.state.countryList, item => {
                return item._id === id
            })
            return country && country.countryName ? country.countryName : null
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            userId: '',
            allBranch: [],
            universityList: [],
            countryList: [],
            stateList: [],
            mainBranchArr: [],
            logo: {},
            olgLogo: {},
            uploadKey: moment(),
            agentList: [],
            agents: [],
            assignStateCountry: []
        }
    }

    componentDidMount() {
        this.getUserData()
        this.loadCountry()
        this.LoadStateFxn()
        this.loadManagers()
        this.loadCountryUniversity()
        let {dispatch} = this.props
        dispatch({type: 'USER_HIDELOADER'})
        dispatch(hideLoader())
        dispatch(hidePageLoad())
    }

    async loadCountry() {
        let {dispatch} = this.props
        let countryFilter = {
            results: 10,
            sortField: 'countryName',
            sortOrder: 'ascend'
        }
        let {data} = await listAllCountries(countryFilter)(dispatch)
        this.setState({
            countryList: data
        })
    }

    async LoadStateFxn() {
        let {dispatch} = this.props
        let data = await LoadState('101')
        this.setState({
            stateList: data
        })
    }

    async loadManagers() {
        let {dispatch} = this.props
        let {data} = await listAllUsers({
            userType: 'branchManager',
            customQuery: {
                countryId: {$exists: false}
            }
        })(dispatch)
        this.setState({
            mainBranchArr: data
        })
    }

    async loadCountryUniversity() {
        let {dispatch} = this.props
        let filters = {
            sortField: 'universityName',
            sortOrder: 'ascend',
            results: 1000000
            // universityCountry: '',
        }

        let {data} = await dispatch(listAllUniversities(filters))
        this.setState({
            universityList: data
        })
    }

    async getUserData() {
        // let { dispatch, form: { setFieldsValue } } = this.props
        // let { pathname } = window.location
        // let params = pathname.split('/').pop()
        let {
            dispatch,
            form: {setFieldsValue}
        } = this.props
        let userData = localStorage.getItem('user')
            ? JSON.parse(localStorage.getItem('user'))
            : null
        console.log('pushpeet', userData)
        if (userData && userData._id) {
            let resp = await dispatch(getUser(userData._id))
            console.log('resp userrr', resp)
            if (resp) {
                this.setState({
                    userId: resp._id,
                    oldLogo: resp.logo,
                    address: resp.address,
                    email: resp.email
                })
                setFieldsValue({
                    name: resp.name,
                    mobile: resp.mobile,
                    email: resp.email,
                    address: resp.address,
                    postName: resp.postName
                })
            }
        }
    }

    async AddStateCountry() {
        const {
            form: {getFieldValue, setFieldsValue}
        } = this.props
        let state = getFieldValue('state') ? getFieldValue('state') : undefined
        let countryId = getFieldValue('countryId')
            ? getFieldValue('countryId')
            : undefined

        let obj = {
            stateName: state,
            countryId: countryId
        }
        let cloneD = _.clone(this.state.assignStateCountry)
        let findA = _.find(cloneD, item => {
            return item.stateName == obj.stateName && item.countryId == obj.countryId
        })
        if (findA) {
            notification.warning({message: 'Already exists'})
        } else {
            this.setState({assignStateCountry: [...cloneD, obj]})
            setFieldsValue({
                state: undefined,
                countryId: undefined
            })
        }
    }

    handleSubmit = e => {
        const {dispatch, form} = this.props
        let {getFieldValue} = form
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let {address, logo, email} = this.state
                // valData.address = address
                let obj = {
                    name: valData.name,
                    mobile: valData.mobile,
                    address: address,
                    email: email
                }
                let fd = new FormData()
                fd.append('obj', JSON.stringify(obj))
                if (logo && logo.name) {
                    fd.append('logo', logo)
                }
                let {data} = await dispatch(updateUser(fd, this.state.userId))
                if (data && !data.error) {
                    let userData = JSON.parse(localStorage.getItem('user'))
                    userData.logo = data.logo
                    localStorage.setItem('user', JSON.stringify(userData))
                    this.setState({
                        uploadKey: moment()
                    })
                }
            }
        })
    }

    render() {
        const {submitting, dispatch} = this.props
        const {
            form: {getFieldDecorator, getFieldValue, setFieldsValue}
        } = this.props

        const {editorState, agentList, agentName, agents, oldLogo} = this.state

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            }
        }

        const assignStateColumns = [
            {
                title: 'State',
                key: 'stateName',
                dataIndex: 'stateName'
            },
            {
                title: 'Country',
                key: 'countryId',
                dataIndex: 'countryId',
                render: item => {
                    return this.events.countryName(item)
                }
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: 'action',
                render: (item, record, index) => {
                    return (
                        <Icon
                            type={'close'}
                            style={{color: 'red'}}
                            onClick={() => {
                                this.events.removeStateCountry(index)
                            }}
                        />
                    )
                }
            }
        ]

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }

        let inputTypes = {
            fields: [
                {
                    label: 'Contact Person Name',
                    key: 'name',
                    required: true
                },
                {
                    label: 'Mobile no',
                    key: 'mobile',
                    required: true
                },
                {
                    label: 'Email',
                    key: 'email',
                    disabled: true
                },
                {
                    label: 'Post Name',
                    key: 'postName',
                    disabled: true
                },

                {
                    key: 'logo',
                    customField: (
                        <InputBox
                            customClass={'mt10'}
                            title={'Profile Picture (250px * 250px)'}
                            className={'logoCls rowFlex'}>
                            <Input
                                type={'file'}
                                className={'form-control'}
                                key={this.state.uploadKey}
                                name={'logo'}
                                id={'logo'}
                                onChange={e => {
                                    this.setState({
                                        logo:
                                            e.target.files && e.target.files.length
                                                ? e.target.files[0]
                                                : null
                                    })
                                }}
                            />
                            {oldLogo && oldLogo.url ? (
                                <Tooltip title={oldLogo.name}>
                                    {' '}
                                    <a
                                        className={'linkBtn'}
                                        style={{backgroundColor: 'white'}}
                                        target={'_blank'}
                                        href={oldLogo.url}>
                                        <Icon type={'eye'}/>
                                    </a>{' '}
                                </Tooltip>
                            ) : (
                                ''
                            )}
                        </InputBox>
                    )
                },
                {
                    key: 'address',
                    span: 24,
                    customField: (
                        <InputBox title={'Address'}>
                            <TextArea
                                rows={4}
                                className={'form-control'}
                                value={this.state.address}
                                onChange={e => {
                                    this.setState({address: e.target.value})
                                }}
                            />
                        </InputBox>
                    )
                }
            ]
        }

        return (
            <div className="row">
                <div className="col-lg-12">
                    <Form onSubmit={this.handleSubmit}>
                        <div className="form-box mt-4">
                            <div className="d-flex align-items-center mb-3 heading-form">
                                <h5>My Profile</h5>
                            </div>

                            <div className="card unizportal">
                                <Row gutter={16} className={'rowWrap'}>
                                    {inputTypes.fields.map((item, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                {item.customField
                                                    ? !item.hidden && (
                                                    <Col span={item.span ? item.span : 8}>
                                                        {item.customField}
                                                    </Col>
                                                )
                                                    : !item.hidden && (
                                                    <Col
                                                        span={item.span ? item.span : 8}
                                                        key={key}
                                                        style={
                                                            item.extraBox1 ? {paddingBottom: 1} : {}
                                                        }>
                                                        <InputBox
                                                            title={item.label1 ? item.label1 : ''}
                                                            extra={item.extraBox1}>
                                                            <GetEachFormFields
                                                                item={item}
                                                                getFieldDecorator={getFieldDecorator}
                                                                formItemLayout={formItemLayout}
                                                            />
                                                        </InputBox>
                                                    </Col>
                                                )}
                                            </React.Fragment>
                                        )
                                    })}
                                </Row>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={this.props.loading}
                                        className={'btn'}>
                                        SAVE
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserProfile)
