import React, {useState, useRef, useEffect} from 'react'
import {Button, Col, Icon, Row, Drawer, Tooltip, Select} from 'antd'
import {TableComp} from 'sz-react-utils-lite'
import {DefaultTablePagination} from '../../../../components/_utils/appUtils'
import {icefParticipantListFxn, icefEventListFxn} from '../../actions'
import {useDispatch, connect} from 'react-redux'
import AddFollowup from './addFollowupDrawer'
import UpdateMeetingStatus from '../updateMeetingStatus'
import ParticipantDetailDrawer from './participantDetailDrawer'
import _ from 'lodash'
import {getPushPathWrapper} from '../../../../routes'
import EditParticipantDrawer from './editParticipantDrawer'

let typesListArr = [
    {name: 'Agent', key: 'agent', color: '#cd2033'},
    {name: 'Educator', key: 'educator', color: '#3767af'},
    {name: 'Service Provider', key: 'serviceProvider', color: '#95348d'},
    {name: 'Others', key: 'others', color: '#3ea635'}
]
let getColor = (name) => {
    let findName = _.find(typesListArr, (item) => {
        return item.name == name
    })
    if (findName) {
        return findName.color
    }
}
let initTypes = [
    'Agent', 'Educator', 'Service Provider', 'Others'
]
let {Option} = Select


const RenderComment = (props) => {
    let {item} = props
    let [hideV, setHideV] = useState(true)
    return (
        item && <div>
            <div className={hideV ? 'newsContentDotsReplace' : ''}>
                {item && item.description ? item.description : ''}
            </div>
            {item && item.description.length && item.description.length > 150 ? <div className={'alignRight'}>
                {hideV ? <a onClick={() => {
                    setHideV(false)
                }}>Show More</a> : <a onClick={() => {
                    setHideV(true)
                }}>Show Less</a>}
            </div> : ''}

        </div>
    )
}

const ParticipantsList = (props) => {
    let {currentUser} = props
    const [addFollowupVisible, setAddFollowupVisible] = useState(false)
    const [selectedEventId, setSelectedEventId] = useState('')
    const [eventList, setEventList] = useState([])
    let [eventId, setEventId] = useState('')
    const [updateStatusState, setUpdateStatusState] = useState({
        visible: false,
        eventId: ''
    })
    const [meetingDetailsState, setMeetingDetailsState] = useState({
        visible: false,
        eventId: '',
        meetingType: ''
    })
    let [state, setState] = useState({
        visible: false,
        participantId: ''
    })
    let [editParticipantState, setEditParticipantState] = useState({
        visible: false,
        participantId: ''
    })
    let [meetingTypes, setMeetingTypes] = useState(initTypes)

    let getEventId = () => {
        let {pathname} = window.location
        let eventId = pathname.split('/').pop()
        setEventId(eventId)
    }
    let getEventIdString = () => {
        let {pathname} = window.location
        let eventId = pathname.split('/').pop()
        return eventId
    }

    let statusListFilter = [
        'Pending',
        'Approved',
        'Denied',
        'Deferred'
    ]

    const tableRef = useRef()
    const dispatch = useDispatch()

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.results = 50
            params.count = 50
            params.eventId = getEventIdString()
            if (meetingTypes) {
                params.meetingType = meetingTypes
            }
            let {data} = await dispatch(icefParticipantListFxn(params))
            resolve(data)
        })
    }

    let loadEventList = async () => {
        let params = {
            results: 500,
            count: 500,
            select: ['eventName']
        }
        let {data} = await dispatch(icefEventListFxn(params))
        setEventList(data)
    }
    useEffect(() => {
        tableRef.current.reload()
    }, [meetingTypes, window.location.href])

    useEffect(() => {
        loadEventList()
        getEventId()
    }, [])

    const columns = [
        {
            title: 'Event Name',
            dataIndex: 'eventId.eventName',
            render: text => (
                <div className='custom-cell'>
                    {text}
                </div>
            ),
            width: 150
        },
        {
            title: 'Organisation Name',
            dataIndex: 'organisationName',
            render: (_, record) => (
                <>
                    <div>{record.companyName}</div>
                    <br/><br/>
                    <div
                        style={{backgroundColor: getColor(record.meetingType)}}
                        className={`custom-cell record-type`}>
                        {record.meetingType}</div>
                </>
            ),
            width: 150
        },
        {
            title: 'Representative',
            dataIndex: 'representative',
            render: (_, record) => (
                <>
                    <div>
                        {record.salutation} {record.firstName} {record.lastName} {' '}
                    </div>
                    <div className='recordEmail'>{record.email}</div>
                </>
            ),
            width: 150
        },
        {
            title: 'Telephone',
            dataIndex: 'mobile',
            render: (_, record) => <div>{record.mobile}</div>,
            width: 120
        },
        {
            title: 'Address',
            dataIndex: 'address',
            render: (_, record) => (
                <>
                    <div>{record.address}</div>
                    <div>{record.cityName}</div>
                    {' '}
                    <div> {record.stateName}</div>
                    <div>{record.pincode} </div>
                    <div>{record.countryName}</div>
                </>
            ),
            width: 180
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: 350,
            render: (_, record) => <div>
                {record && record.description ? <RenderComment item={record}/> : null}
            </div>
        },
        {
            title: 'Department',
            dataIndex: 'department',
            render: (_, record) => (
                <>
                    <div>{record.department}</div>
                    {' '}
                    <div> {record.position} </div>

                </>
            ),
            width: 150
        },
        // {
        //   title: 'Status',
        //   dataIndex: 'status',
        //   key: 'status',
        //   filters: statusListFilter.map(x => ({ value: x, text: x })),
        //   render: (item) => {
        //     return (
        //       <>
        //         {item == 'Pending' ? <label className={'label label-warning'}>Pending</label> : ''}
        //         {item == 'Approved' ? <label className={'label label-success-green'}>Approved</label> : ''}
        //         {item == 'Denied' ? <label className={'label label-danger'}>Denied</label> : ''}
        //         {item == 'Deferred' ? <label className={'label label-success'}>Deferred</label> : ''}
        //       </>
        //     )
        //   },
        //   width: 150
        // },
        // {
        //   title: 'Deferred',
        //   dataIndex: 'deferred',
        //   width: 100
        // },
        {
            title: 'FollowUps',
            dataIndex: 'followUps',
            render: () => (
                <>
                    <Tooltip title='Add Followup'>
                        <Icon type='file' className='custom-icon' onClick={events.handleAddFollowupClick}/>

                    </Tooltip>
                </>
            ),
            width: 100
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            fixed: 'right',
            render: (_, record) => (
                <>
                    <Tooltip title='View Details'>
                        <Icon type='eye' className='custom-icon'
                              onClick={() => events.showParticipantDrawer(record._id)}/>
                    </Tooltip>

                    <Tooltip title='Update Status' onClick={() => events.showUpdateState(record._id)}>
                        <Icon type='retweet' className='custom-icon'/>
                    </Tooltip>

                    <Tooltip title='Edit Participants' onClick={() => events.showEditDrawer(record._id)}>
                        <Icon type='edit' className='custom-icon'/>
                    </Tooltip>
                </>
            ),
            width: 150
        }
    ]

    let events = {
        showUpdateState: (eventId) => {
            setUpdateStatusState({
                visible: true,
                eventId: eventId
            })
        },
        hideUpdateState: () => {
            setUpdateStatusState({
                visible: false,
                eventId: ''
            })
        },
        submitUpdateState: () => {
            setUpdateStatusState({
                visible: false,
                eventId: ''
            })
            setTimeout(() => {
                tableRef.current.reload()
            }, 500)
        },
        handleDetailsClick: (eventId, meetingType, companyId) => {
            setSelectedEventId(eventId)
            setMeetingDetailsState({
                visible: true,
                eventId: eventId,
                meetingType: meetingType,
                companyId: companyId
            })
        },
        handleAddFollowupClick: () => {
            setAddFollowupVisible(true)
        },

        handleCloseAddFollowup: () => {
            setAddFollowupVisible(false)
        },

        handleSubmitFollowup: (values) => {
            events.handleCloseAddFollowup()
        },

        showParticipantDrawer: (participantId) => {
            setState({
                participantId,
                visible: true
            })
        },
        hideParticipantDrawer: () => {
            setState({
                participantId: '',
                visible: false
            })
        },

        chooseMeetingType: (data) => {
            let cloneType = _.clone(meetingTypes)
            if (cloneType.includes(data)) {
                cloneType = _.reject(cloneType, (item) => {
                    return item == data
                })
            } else {
                cloneType.push(data)
            }
            setMeetingTypes(cloneType)
        },
        chooseMeeting: (meetingId) => {
            dispatch(getPushPathWrapper('participantsList', {id: meetingId}))
            setMeetingTypes([])
        },
        showEditDrawer: (participantId) => {
            setEditParticipantState({
                visible: true,
                participantId: participantId
            })
        },
        hideEditDrawer: async () => {
            setEditParticipantState({
                visible: false,
                participantId: ''
            })
            tableRef.current.reload()
        }
    }

    return (
        <>
            <div className='row mt-4'>
                <div className='col-lg-12'>
                    <div className='card'>
                        <div className='table-head d-flex align-items-center all-student-search'>
                            <h5>All Participants</h5>
                            <div className='search-box-table round'></div>
                            <div className='sort-box-table mark-btn'></div>
                        </div>
                        <div className='card-body table-responsive'>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Select
                                        value={eventId}
                                        onChange={(value) => {
                                            setEventId(value)
                                            events.chooseMeeting(value)
                                        }}>
                                        {eventList && eventList.length ? eventList.map((item) => {
                                            return (
                                                <Option key={item._id} value={item._id}>
                                                    {item.eventName}
                                                </Option>
                                            )
                                        }) : null}
                                    </Select>
                                </Col>
                                <Col span={16}>
                                    <div className={'select_btn_group'}>
                                        {typesListArr.map((item) => {
                                            return (
                                                <a className={`default_btn_lg ${meetingTypes.includes(item.name) ? 'selected' : null}`}
                                                   style={{backgroundColor: meetingTypes.includes(item.name) ? item.color : ''}}
                                                   key={item.key}
                                                   onClick={() => events.chooseMeetingType(item.name)}>
                                                    {meetingTypes.includes(item.name) ?
                                                        <Icon type={'check'}/> : null} &nbsp;
                                                    {item.name}
                                                </a>
                                            )
                                        })}
                                    </div>
                                </Col>
                            </Row>
                            <TableComp
                                columns={columns}
                                apiRequest={apiRequest}
                                ref={tableRef}
                                pagination={DefaultTablePagination()}
                                extraProps={{scroll: {x: 1000}}}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Drawer
                visible={addFollowupVisible}
                onClose={events.handleCloseAddFollowup}
                width={800}>
                <div style={{padding: 20}}>
                    <h4>Add Follow Up</h4>
                    <AddFollowup onSubmit={events.handleSubmitFollowup}/>
                </div>
            </Drawer>

            {updateStatusState.visible ?
                <UpdateMeetingStatus
                    {...updateStatusState}
                    dispatch={dispatch}
                    onSubmit={events.submitUpdateState}
                    onClose={() => {
                        events.hideUpdateState()
                    }}/> : ''}

            {state.visible ? (
                <ParticipantDetailDrawer
                    {...state}
                    currentUser={props.currentUser}
                    onClose={() => events.hideParticipantDrawer()}
                />
            ) : null}

            {editParticipantState.visible && (
                <EditParticipantDrawer
                    visible={editParticipantState.visible}
                    participantId={editParticipantState.participantId}
                    onClose={events.hideEditDrawer}
                    onSubmit={(values) => {
                        events.hideEditDrawer()
                    }}
                />
            )}
        </>
    )
}

const mapStateToProps = ({global}) => ({
    currentUser: global.currentUser
})
export default connect(
    mapStateToProps,
    null
)(ParticipantsList)

