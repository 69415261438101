import {apiUrl} from "../../../settings";

export const requestForCallUrl = () => {
    return apiUrl + '/requestForCall';
};

export const addCallInWithdrawalUrl = () => {
    return apiUrl + '/addCallInWithdrawal';
};

export const generateInvoiceUrl = () => {
    return apiUrl + '/generateInvoice';
};

export const addReceiptInWithdrawalUrl = () => {
    return apiUrl + '/addReceiptInWithdrawal';
};
