import { Button, Col, Icon, Input, Modal, notification, Select, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { departmentObj, filterOption } from '../../components/_utils/appUtils'
import {
  addDiwaliGiftPhotos,
  getAllAgentOfMarketingUserFxn
} from './actions'
import _ from 'lodash'
import { listAllUsers } from '../users/actions/user'
import UploadPhotos from './choosePhotosModal'

let { Option } = Select

const DiwaliGiftDistribution = props => {
  let { user } = props
  let tableRef = useRef()
  const dispatch = useDispatch()
  const [diwaliDistributionPhotos, setDiwaliDistributionPhotos] = useState([])
  let [marketingUserList, setMarketingUserList] = useState([])
  let [marketingUserId, setMarketingUserId] = useState('')

  const loadMarketingUsers = async () => {
    let obj = {
      userType: 'branchUser',
      department: departmentObj.marketing,
      results: 100,
      select: ['name', 'email', 'mobile', 'agents', 'assignStateCountry']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setMarketingUserList(data)
  }

  useEffect(() => {
    loadMarketingUsers()
  }, [])

  const initialState = {
    visible: false,
    agentId: ''
  }
  const [detailsModal, setDetailsModal] = useState(initialState)
  const [total, setTotal] = useState('')
  const [distributedCount, setDistributedCount] = useState('')

  let apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let obj = {}
      params = { ...params, ...obj }
      if (marketingUserId) {
        params.giftByMarketingUserId = marketingUserId
      }
      let data = await dispatch(getAllAgentOfMarketingUserFxn({ ...params }))
      setTotal(data.total)
      setDistributedCount(data.distributedCount)
      console.log(data, 'data')
      resolve(data)
    })
  }

  const addDiwaliGiftPhotosFxn = async (agentId) => {
    let fd = new FormData()
    let obj = {}
    if (agentId) {
      obj.agentId = agentId
      fd.append('obj', JSON.stringify(obj))
      if (diwaliDistributionPhotos.length > 9) {
        notification.warning({ message: 'Cannot Upload More Than 10 Photos' })
      }
      if (diwaliDistributionPhotos && diwaliDistributionPhotos.length > 0) {
        _.each(diwaliDistributionPhotos, (item, key) => {
          fd.append('diwaliDistributionPhotos', item)
        })
      } else {
        notification.warning({ message: 'Please select photos' })
        return
      }

      let data = await dispatch(addDiwaliGiftPhotos(fd))
      if (data) {
        setDetailsModal({ visible: false, agentId: '' })
        tableRef.current.reload()
      }
    } else {
      notification.warning({ message: 'Agent not found' })
    }
  }

  const chooseFiles = (files) => {
    setDiwaliDistributionPhotos(files)
  }

  const columns = [
    {
      title: 'Sr. no.',
      key: '_id',
      dataIndex: '_id',
      render: (item, record, index) => {
        return <div style={{ width: 50 }}>{index + 1}</div>
      }
    },
    {
      title: 'Agent',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Marketing User',
      dataIndex: 'giftByMarketingUserId',
      key: 'giftByMarketingUserId',
      hidden: user.userType !== 'admin',
      render: (item) => {
        return (
          <>
            {item && item.name ? item.name : ''}
            <div>
              {item && item.email ? ` (${item.email})` : ''}
            </div>
          </>
        )
      }
    },
    {
      title: 'Photos',
      dataIndex: 'diwaliPhotosDistributeId',
      key: 'diwaliPhotosDistributeId',
      render: (item, record, index) => {
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '100%', overflow: 'hidden' }}>
            {item &&
            item.diwaliDistributionPhotos &&
            item.diwaliDistributionPhotos.length > 0 &&
            item.diwaliDistributionPhotos.map((photoItem, key) => (
              <a href={photoItem.path} target='_blank' rel='noopener noreferrer' key={key}>
                <img
                  src={photoItem.path}
                  width='50'
                  height='50'
                  alt={`Diwali distribution ${key}`}
                  style={{ margin: '2px' }}
                />
              </a>
            ))}
          </div>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'status',
      key: 'status',
      hidden: user.userType == 'admin',
      render: (item, record) => {
        return (
          <>
            <div className={'btn_group'} style={{ width: 190 }}>
              <>
                {
                  record.diwaliPhotosDistribute == false && user.userType != 'admin' &&
                  <Tooltip title='Add Photos'>
                    <button className='btn'
                            onClick={() => {
                              setDetailsModal({
                                visible: true,
                                agentId: record._id
                              })
                            }}
                    >
                      <Icon type={'plus'} />
                    </button>
                  </Tooltip>
                }
              </>
            </div>
          </>
        )
      }
    }
  ]

  const searchFxn = async () => {
    tableRef.current.reload()
  }

  const getColumn = () => {
    let newCol = []
    _.each(columns, (item) => {
      if (!item.hidden) {
        newCol.push(item)
      }
    })
    return newCol
  }


  return (
    <>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>Diwali Gift Distribution List</h5>
              <div className='search-box-table' />
            </div>

            <div className='card-body table-responsive mt10'>
              <div className={'d-flex'}>
                <h6>Total: {total || 0}</h6> &nbsp;&nbsp;
                <h6>Distributed: {distributedCount || 0}</h6>&nbsp;&nbsp;
                <h6>Remaining: {Number(total - distributedCount) || 0}</h6>
              </div>
              {
                user.userType == 'admin' ?
                  <div className='sort-box-table custom-sort-box-new'>
                    <div className={'row'}>
                      <div className={'col-md-3'}>
                        <Select
                          name='Search By Marketing User'
                          filterOption={filterOption}
                          className={'antSelect'}
                          allowClear={true}
                          placeholder={'Marketing User'}
                          onChange={item => setMarketingUserId(item)}
                          value={marketingUserId || undefined}>
                          {marketingUserList && marketingUserList.length
                            ? marketingUserList.map((item, key) => {
                              return (
                                <Option value={item._id} key={key}>
                                  {item.name}
                                </Option>
                              )
                            })
                            : null}
                        </Select>
                      </div>
                      <div className={'col-md-3'}>
                        <Button onClick={() => searchFxn()} className={'roundBtn ml10'}>
                          Search
                        </Button>
                      </div>
                    </div>
                  </div> : null

              }
              <TableComp
                columns={getColumn()}
                apiRequest={apiRequest}
                ref={tableRef}
                pagination={{
                  position: 'top',
                  pageSizeOptions: ['10', '20', '50', '100'],
                  defaultPageSize: 20
                }}
              />
            </div>
            {
              detailsModal.visible ? (
                <UploadPhotos
                  visible={detailsModal.visible}
                  addDiwaliGiftPhotosFxn={addDiwaliGiftPhotosFxn}
                  setDetailsModal={setDetailsModal}
                  detailsModal={detailsModal}
                  chooseFiles={chooseFiles}
                  diwaliDistributionPhotos={diwaliDistributionPhotos}
                  setDiwaliDistributionPhotos={setDiwaliDistributionPhotos}
                />
              ) : null
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default DiwaliGiftDistribution
