import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { DefaultTablePagination, displayDate } from '../../components/_utils/appUtils'
import { InstituteStudentListFxn } from './action'
import { Button, Col, Icon, Row, Tooltip } from 'antd'
import StudentCounselling from '../counselling/addStudentCounsellingDrawer'
import _ from 'lodash'

class InstituteStudentList extends Component {
  events = {
    openCounsellingDrawer: (studentId, studentName) => {
      this.setState({
        showCounsellingDrawer: {
          visible: true,
          studentId: studentId,
          studentName: studentName
        }
      })

    },
    closeCounsellingDrawer: () => {
      this.setState({
        showCounsellingDrawer: {
          visible: false,
          studentId: '',
          studentName: ''
        }
      })
    }
  }

  componentDidMount() {
  }

  constructor(props) {
    super(props)
    this.state = {
      userList: [],
      userTypeArr: [],
      totalUsers: 0,
      selectedUser: {},
      visibleUpdatePassword: false,
      visibleEditUser: false,
      visibleBlockConfirm: false,
      filters: {
        name: '',
        email: '',
        mobile: '',
        country: '',
        stateName: '',
        cityName: '',
        userType: '',
        address: '',
        pincode: ''
      },
      name: '',
      email: '',
      phone: '',
      state: '',
      showCounsellingDrawer: {
        visible: false,
        studentName: '',
        studentId: ''
      }
    }
    this.tableRef = React.createRef()
  }

  reloadTable = () => {
    this.tableRef.current.reload()
  }

  apiRequest = (params = {}) => {
    return new Promise(async (resolve) => {
      if (this.state.name) {
        params.name = this.state.name
      }
      if (this.state.email) {
        params.email = this.state.email
      }
      if (this.state.phone) {
        params.phone = this.state.phone
      }
      if (this.state.state) {
        params.state = this.state.state
      }
      const { data } = await this.props.dispatch(InstituteStudentListFxn({
        ...params,
        regExFilters: ['name', 'email', 'phone', 'state']
      }))
      this.setState({
        totalUsers: data.total || 0
      })
      resolve(data)
    })

  }

  render() {
    let { showCounsellingDrawer } = this.state
    let { currentUser } = this.props
    const columns = [
      {
        title: '#',
        dataIndex: '_id',
        key: '_id',
        width: 50,
        render: (text, record, index) => index + 1
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        searchTextName: 'name',
        width: 150,
        render: text => <span>{text}</span>
      },
      /* {
         title: 'DOB',
         dataIndex: 'dateOfBirth',
         key: 'dateOfBirth',
         width: 150,
         render: (item, record) => {
           console.log(record)
           return item ? displayDate(item) : null
         }
       },
 */
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        searchTextName: 'email',
        width: 180
      },
      {
        title: 'Mobile',
        dataIndex: 'mobile',
        key: 'mobile',
        searchTextName: 'mobile',
        width: 120,
        render: (item, record) => {
          let { countryCode } = record
          return item ? <>
            {countryCode} {item}
          </> : null
        }
      },

      {
        title: 'State Name',
        dataIndex: 'stateName',
        key: 'stateName',
        searchTextName: 'stateName',
        width: 180
      },

      {
        title: 'Institute',
        dataIndex: 'instituteId',
        key: 'instituteId',
        searchTextName: 'instituteId',
        width: 180,
        hidden: currentUser.userType == 'institute',
        render: (item, record) => {
          return (
            <>
              {item && item.name ? item.name : ''}
              {item && item.companyName ? ` (${item.companyName})` : ''}
            </>
          )
        }
      }

      /*  {
          title: 'Actions',
          key: 'actions',
          width:100,
          render: (text, record) => (
            <Tooltip title='View Student Details'>
              <a className={'linkAA'}>
                <Icon type='eye' theme='filled'
                      onClick={() => this.events.openCounsellingDrawer(record._id, record.name)}
                      style={{ fontSize: '16px' }}
                />
              </a>
            </Tooltip>
          )
        }*/

    ]

    const getColumns = () => {
      let newCol = _.filter(columns, (item) => {
        return !item.hidden
      })
      return newCol
    }

    return (
      <div>
        <div className='row  mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='table-head d-flex align-items-center'>
                <h5>All Institute Students ({this.state.totalUsers})</h5>
              </div>
              <div className='card-body table-responsive'>
                <Row gutter={16} className={'filter_box'}>
                  <Col span={4}>
                    <div className='search-box-table round'>
                      <input className='form-control form-control' type='search'
                             placeholder='Search by name...' value={this.state.name} onChange={(e) => {
                        this.setState({ name: e.target.value })
                      }} />
                      <img src='/dist/img/search.png' alt='' />
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className='search-box-table round'>
                      <input className='form-control form-control' type='search'
                             placeholder='Search by email...' value={this.state.email} onChange={(e) => {
                        this.setState({ email: e.target.value })
                      }} />
                      <img src='/dist/img/search.png' alt='' />
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className='search-box-table round'>
                      <input className='form-control form-control' type='search'
                             placeholder='Search by Number...' value={this.state.phone} onChange={(e) => {
                        this.setState({ phone: e.target.value })
                      }} />
                      <img src='/dist/img/search.png' alt='' />
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className='search-box-table round'>
                      <input className='form-control form-control' type='search'
                             placeholder='Search by state...' value={this.state.state} onChange={(e) => {
                        this.setState({ state: e.target.value })
                      }} />
                      <img src='/dist/img/search.png' alt='' />
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button onClick={() => this.reloadTable()} className={'roundBtn mt-2 ml15'}>Search</Button>
                    {/*<Button onClick={() => events.clearFxn()} className={'roundBtn'}>
                      Clear
                    </Button>*/}
                  </Col>
                </Row>

                <TableComp
                  columns={getColumns()}
                  ref={this.tableRef}
                  pagination={DefaultTablePagination()}
                  apiRequest={this.apiRequest}
                  extraProps={{ scroll: { x: 1000 } }}
                />
              </div>
            </div>
          </div>
        </div>

        {showCounsellingDrawer.visible ? (
          <StudentCounselling
            onClose={this.events.closeCounsellingDrawer}
            {...showCounsellingDrawer}
          />
        ) : null}

      </div>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  currentUser: global.currentUser
})

export default connect(mapStateToProps)(InstituteStudentList)
