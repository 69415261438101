import { notification } from 'antd'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import { advanceCommissionListUrl, addAdvanceCommissionUrl, updateAdvanceCommissionUrl } from '../api/advanceCommission'

export const addAdvanceCommissionFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addAdvanceCommissionUrl(), { ...valData }, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Submitted Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error '
    })
  }
  return data
}
export const updateAdvanceCommissionFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateAdvanceCommissionUrl(), { ...valData }, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error '
    })
  }
  return data
}
export const advanceCommissionListFxn = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(advanceCommissionListUrl(), config)
  dispatch(hidePageLoad())
  return data
}
