import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { Popconfirm, Select, Tooltip } from 'antd'
import {
  agentCommissionApprovedByManagerFxn,
  allAgentCommissionListFxn
} from '../actions'
import {
  displayDate, getSemesterList, longDisplayDate
} from '../../../components/_utils/appUtils'
import { listAllCountries } from '../../countries/actions/countries'
import { listAllUniversities } from '../../university/actions/university'
import { getUrlPushWrapper } from '../../../routes'
import { getBranchManagerAgentList } from '../../users/actions/user'
import AgentCommStudentDrawer from '../drawers/agentCommStudent'
import EditAgentInvoiceComponent from '../RaiseAgentInvoice/EditAgentInvoiceDrawer'

const { Option } = Select

let initState = {
  status: '',
  visible: false,
  commissionId: ''
}
let initFilter = {
  countryId: '',
  status: '',
  universityId: '',
  intake: '',
  countryList: [],
  universityList: [],
  agentList: [],
  courseName: '',
  semester: ''
}
let agentCommissionInit = {
  visible: false,
  record: {}
}
let raiseInvoiceInit = {
  visible: false,
  record: {}
}
const AgentCommissionList = (props) => {
  let { currentUser } = props
  let [totalRecord, setTotalRecord] = useState(0)
  const [state, setState] = useState(initState)
  const [filterState, setFilterState] = useState(initFilter)
  const [tableSearch, setTableSearch] = useState(false)
  const [agentCommissionState, setAgentCommissionState] = useState(agentCommissionInit)
  const [raiseInvoiceState, setRaiseInvoiceState] = useState(raiseInvoiceInit)

  const tableRef = useRef()
  const dispatch = useDispatch()
  useEffect(() => {
    loadData.loadCountryList()
  }, [])
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let resp = await events.getParamsForApplicationList()
      if (resp.intake) {
        resp['intake.month'] = resp.intake
        delete resp['intake']
      }
      if (resp.intakeYear) {
        resp['intake.year'] = resp.intakeYear
        delete resp['intakeYear']
      }
      params = { ...params, ...resp }
      params.transactionType = 'Credit'
      params.sortField = '_id'
      params.sortOrder = 'descend'
      params.countryId = currentUser.countryId
      params.referToManager = 'Pending'

      let respData = await dispatch(allAgentCommissionListFxn({
        ...params,
        regExFilters: ['semester', 'courseName', 'name']
      }))
      setTotalRecord(respData.total)
      resolve(respData)
    })
  }
  const reload = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.reload()
    }
  }

  const events = {

    updateLocalFilter: (data) => {
      try {
        setFilterState({
          ...filterState,
          ...data
        })
      } catch (e) {
      }
    },
    closeDrawer: () => {
      setState({
        ...initState
      })
    },
    getParamsForApplicationList: () => {
      return new Promise((resolve) => {
        let searchParams = new URLSearchParams(window.location.search)
        let countryId = searchParams.get('countryId')
        let agentId = searchParams.get('agentId')
        let universityId = searchParams.get('universityId')
        let name = searchParams.get('name')
        let courseName = searchParams.get('courseName')
        let intake = searchParams.get('intake')
        let semester = searchParams.get('semester')
        let status = searchParams.get('status')
        let intakeYear = searchParams.get('intakeYear')
        let obj = {}
        if (agentId) {
          obj.agentId = agentId
        }
        if (countryId) {
          obj.countryId = countryId
        }
        if (universityId) {
          obj.universityId = universityId
        }
        if (name) {
          obj.name = name
        }
        if (courseName) {
          obj.courseName = courseName
        }
        if (intake) {
          obj.intake = intake
        }
        if (intakeYear) {
          obj.intakeYear = intakeYear
        }
        if (status) {
          obj.status = status
        }
        if (semester) {
          obj.semester = semester
        }
        resolve(obj)
      })
    },
    searchFxn: async () => {
      let obj = {}
      let {
        countryId,
        universityId,
        name,
        courseName,
        agentId,
        intake,
        intakeYear,
        semester,
        status
      } = filterState
      if (countryId) {
        obj.countryId = countryId
      }

      if (name) {
        obj.name = name
      }
      if (courseName) {
        obj.courseName = courseName
      }
      if (universityId) {
        obj.universityId = universityId
      }
      if (agentId) {
        obj.agentId = agentId
      }
      if (semester) {
        obj.semester = semester
      }
      if (status) {
        obj.status = status
      }
      if (intake) {
        obj.intake = intake
      }
      if (intakeYear) {
        obj.intakeYear = intakeYear
      }
      dispatch(
        getUrlPushWrapper('referredAgentCommission', {
          ...obj
        }))
      setTableSearch(true)
      setTimeout(() => {
        reload()
      }, 200)
    },
    clearFxn: async () => {
      let obj = {}
      events.updateLocalFilter({
        countryId: '',
        universityId: '',
        name: '',
        intake: '',
        agentId: '',
        courseName: '',
        semester: '',
        intakeYear: '',
        status: ''
      })
      dispatch(
        getUrlPushWrapper('referredAgentCommission', {
          ...obj
        }))
      setTableSearch(true)
      setTimeout(() => {
        reload()
      }, 200)
    },
    showAgentCommissionDrawer: (record) => {
      record.agentCommissionId = record._id
      setAgentCommissionState({
        visible: true,
        record: record
      })
    },
    hideAgentCommissionDrawer: () => {
      setAgentCommissionState({
        visible: false,
        record: {}
      })
    },

    commissionVerified: async (commissionId) => {
      let { data, success } = await dispatch(agentCommissionApprovedByManagerFxn({ commissionId }))
      if (success) {
        reload()
      }
    },
    showRaiseInvoiceState: (record) => {
      setRaiseInvoiceState({
        visible: true,
        record: record
      })
    },
    hideRaiseInvoiceState: () => {
      setRaiseInvoiceState({
        visible: false,
        record: {}
      })
      reload()
    }
  }

  const loadData = {
    loadCountryList: async () => {
      let params = {
        results: 5000,
        select: ['countryName'],
        sortField: 'countryName',
        sortOrder: 'ascend'
      }
      let { data } = await dispatch(listAllCountries(params))
      let agentResp = await dispatch(getBranchManagerAgentList())
      let obj = {}
      if (data && data.length) {
        obj.countryList = data
      }
      if (agentResp && agentResp.data && agentResp.data.length) {
        obj.agentList = agentResp.data
      }
      events.updateLocalFilter({ ...obj })
    },
    loadUniversityNameList: async (countryId) => {
      let params = {
        results: 5000,
        select: ['universityName'],
        sortField: 'universityName',
        sortOrder: 'ascend',
        regExFilters: ['universityName'],
        showUniversity: [true, false],
        universityCountry: countryId
      }
      let { data } = await dispatch(listAllUniversities(params))
      let obj = {
        universityId: ''
      }
      if (data && data.length) {
        obj.universityList = data
      }
      events.updateLocalFilter({ ...obj, countryId })
    }
  }


  const columns = [
    {
      title: 'Sr .no',
      key: '_id',
      dataIndex: '_id',
      width: 50,
      render: (item, record, index) => {
        return (
          <div style={{ width: 50 }}>
            {index + 1}
          </div>
        )
      }
    },
    {
      title: 'Date',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      width: 80,
      render: (item, record) => {
        return (
          <div style={{ width: 100 }}>
            {item ? displayDate(item) : ''}
          </div>
        )
      }
    },
    {
      title: 'Agent',
      key: 'agentId',
      dataIndex: 'agentId',
      width: 220,
      render: (item, record) => {
        return (
          <div style={{ width: 180 }}>
            {item && item.name ? item.name : null}
            {item && item.companyName ? `(${item.companyName})` : null}
          </div>
        )
      }
    },


    {
      title: 'Agent Share',
      dataIndex: 'totalCommission',
      key: 'totalCommission',
      width: 100,
      render: (item, record) => {
        let { totalApprovedByAdminCommission, countryId } = record
        return (
          <div style={{ width: 90 }}>
            {countryId && countryId.currency ? countryId.currency : ''}
            {totalApprovedByAdminCommission ? totalApprovedByAdminCommission : item} &nbsp;
            {countryId && countryId.currencyName ? countryId.currencyName : ''}
          </div>
        )
      }
    },

    {
      title: 'Status',
      dataIndex: 'invoiceStatus',
      key: 'invoiceStatus',
      width: 100,
      render: (item, record) => {
        return (
          <div style={{ width: 100 }}>
            {item == 'Approved' ?
              <label className={'label label-success'}>Approved</label> :
              <label className={'label label-danger'}>Pending</label>}
          </div>
        )
      }
    },

    {
      title: 'Added By',
      key: 'userId',
      dataIndex: 'userId',
      width: 160,
      render: (item, record) => {
        return (
          <div style={{ width: 160 }}>
            <div className={'colorPrimary font12 textCap'}>
              {item && item.name ? item.name : null}
            </div>
            <div>{longDisplayDate(record.createdAt)}</div>
          </div>
        )
      }
    },

    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 160,
      render: (item, record) => {
        let { referToManager, invoiceStatus, transactionType } = record
        return (
          <div className={'view-btn-box'}>

            <Tooltip title={'View Student List'}>
              <a className='btn-view' onClick={() => {
                events.showAgentCommissionDrawer(record)
              }}>
                <img src='../uniBtn/eye.png' alt='' className={'eyeBtn'} /> View
              </a>
            </Tooltip>


            {(invoiceStatus == 'Pending' && transactionType == 'Credit') ?
              <>
                <a className='btn-view'
                   onClick={() => {
                     events.showRaiseInvoiceState(record)
                   }}><img src='/dist/img/edit.svg' alt='edit' style={{ height: 12 }} /> Edit
                </a>
              </> : ''}


            {(referToManager == 'Pending') ?
              <>
                <Popconfirm title={'Are your sure, commission verified?'}
                            onConfirm={() => events.commissionVerified(record)}
                            okText='Yes' cancelText='No'>
                  <a className='btn-view'>
                    Verify Now
                  </a>
                </Popconfirm>
              </> : ''}
          </div>
        )
      }
    }

  ]
  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center'>
            <h5>All Refer Commission: {totalRecord || 0}</h5>
            <div className='sort-box-table mark-btn mark-btn40'>

            </div>
          </div>
          <div className='card-body table-responsive'>

            <div className='card-body table-responsive'>
              <TableComp columns={columns}
                         apiRequest={apiRequest}
                         pagination={{
                           position: 'top',
                           pageSizeOptions: ['50', '100', '200', '500'],
                           defaultPageSize: 50
                         }}
                         ref={tableRef}
              />
            </div>
          </div>
        </div>
      </div>


      {agentCommissionState.visible ?
        <AgentCommStudentDrawer
          visible={agentCommissionState.visible}
          onClose={events.hideAgentCommissionDrawer}
          onSubmit={reload}
          record={agentCommissionState.record}
        /> : null}

      {raiseInvoiceState.visible ?
        <EditAgentInvoiceComponent
          visible={raiseInvoiceState.visible}
          onClose={events.hideRaiseInvoiceState}
          onSubmit={reload}
          record={raiseInvoiceState.record}
        /> : null}


    </div>
  )
}

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentCommissionList)
