import React, {useState, useEffect, useRef} from 'react'
import {useDispatch, connect} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {Avatar, Button, Col, Popconfirm, Row, Select, Tooltip} from "antd";
import {agentCommissionListFxn} from "../actions";
import {
    displayDate,
    filterOption, Intakes, getSemesterList, CommissionStatusList, YearList, longDisplayDate
} from "../../../components/_utils/appUtils";
import {listAllCountries} from "../../countries/actions/countries";
import {listAllUniversities} from "../../university/actions/university";
import {getUrlPushWrapper} from "../../../routes";
import {getBranchManagerAgentList} from "../../users/actions/user";
import AgentCommStudentDrawer from "../drawers/agentCommStudent";
import PayCommission from '../drawers/payCommission'
import EditAgentInvoiceComponent from '../RaiseAgentInvoice/EditAgentInvoiceDrawer';

const {Option} = Select

let initState = {
    status: "",
    visible: false,
    commissionId: ""
}
let initFilter = {
    countryId: "",
    status: "",
    universityId: "",
    intake: "",
    countryList: [],
    universityList: [],
    agentList: [],
    courseName: "",
    semester: "",
}
let agentCommissionInit = {
    visible: false,
    record: {}
}
let payCommissionInit = {
    visible: false,
    record: {}
}

let raiseInvoiceInit = {
    visible: false,
    record: {},
}

const AgentCommissionList = (props) => {
    let semesterList = getSemesterList()
    let [totalRecord, setTotalRecord] = useState(0)
    const [itemNo, setItemNo] = useState(1)
    const [state, setState] = useState(initState)
    const [filterState, setFilterState] = useState(initFilter)
    const [tableSearch, setTableSearch] = useState(false);
    const [agentCommissionState, setAgentCommissionState] = useState(agentCommissionInit)
    const [payCommissionState, setPayCommissionState] = useState(payCommissionInit)

    const [raiseInvoiceState, setRaiseInvoiceState] = useState(raiseInvoiceInit)

    const tableRef = useRef()
    const dispatch = useDispatch()
    useEffect(() => {
        loadData.loadCountryList()
    }, [])
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let resp = await events.getParamsForApplicationList()
            if (resp.intake) {
                resp['intake.month'] = resp.intake;
                delete resp['intake']
            }
            if (resp.intakeYear) {
                resp['intake.year'] = resp.intakeYear;
                delete resp['intakeYear']
            }
            params = {...params, ...resp}
            params.transactionType = "Credit"
            params.sortField = '_id'
            params.sortOrder = 'descend'

            let respData = await dispatch(agentCommissionListFxn({
                ...params,
                regExFilters: ['semester', 'courseName', 'name']
            }))
            setTotalRecord(respData.total)
            resolve(respData)
        })
    }
    const reload = () => {
        if (tableRef && tableRef.current) {
            tableRef.current.reload()
        }
    }

    const events = {
        showRaiseInvoiceState: (record) => {
            setRaiseInvoiceState({
                visible: true,
                record: record
            })
        },
        hideRaiseInvoiceState: () => {
            setRaiseInvoiceState({
                visible: false,
                record: {}
            })
            reload()
        },

        updateLocalFilter: (data) => {
            try {
                setFilterState({
                    ...filterState,
                    ...data
                })
            } catch (e) {
            }
        },
        closeDrawer: () => {
            setState({
                ...initState
            })
        },
        getParamsForApplicationList: () => {
            return new Promise((resolve) => {
                let searchParams = new URLSearchParams(window.location.search)
                let countryId = searchParams.get('countryId')
                let agentId = searchParams.get('agentId')
                let universityId = searchParams.get('universityId')
                let name = searchParams.get('name')
                let courseName = searchParams.get('courseName')
                let intake = searchParams.get('intake')
                let semester = searchParams.get('semester')
                let status = searchParams.get('status')
                let intakeYear = searchParams.get('intakeYear')
                let obj = {}
                if (agentId) {
                    obj.agentId = agentId
                }
                if (countryId) {
                    obj.countryId = countryId
                }
                if (universityId) {
                    obj.universityId = universityId
                }
                if (name) {
                    obj.name = name
                }
                if (courseName) {
                    obj.courseName = courseName
                }
                if (intake) {
                    obj.intake = intake
                }
                if (intakeYear) {
                    obj.intakeYear = intakeYear
                }
                if (status) {
                    obj.status = status
                }
                if (semester) {
                    obj.semester = semester
                }
                resolve(obj)
            })
        },
        searchFxn: async () => {
            let obj = {}
            let {
                countryId,
                universityId,
                name,
                courseName,
                agentId,
                intake,
                intakeYear,
                semester,
                status
            } = filterState;
            if (countryId) {
                obj.countryId = countryId
            }

            if (name) {
                obj.name = name
            }
            if (courseName) {
                obj.courseName = courseName
            }
            if (universityId) {
                obj.universityId = universityId
            }
            if (agentId) {
                obj.agentId = agentId
            }
            if (semester) {
                obj.semester = semester
            }
            if (status) {
                obj.status = status
            }
            if (intake) {
                obj.intake = intake
            }
            if (intakeYear) {
                obj.intakeYear = intakeYear
            }
            dispatch(
                getUrlPushWrapper('allUniversityCommission', {
                    ...obj
                }))
            setTableSearch(true)
            setTimeout(() => {
                reload()
            }, 200)
        },
        clearFxn: async () => {
            let obj = {}
            events.updateLocalFilter({
                countryId: "",
                universityId: "",
                name: "",
                intake: "",
                agentId: "",
                courseName: "",
                semester: "",
                intakeYear: "",
                status: ""
            })
            dispatch(
                getUrlPushWrapper('allUniversityCommission', {
                    ...obj
                }))
            setTableSearch(true)
            setTimeout(() => {
                reload()
            }, 200)
        },
        showAgentCommissionDrawer: (record) => {
            record.agentCommissionId = record._id;
            setAgentCommissionState({
                visible: true,
                record: record
            })
        },
        hideAgentCommissionDrawer: () => {
            setAgentCommissionState({
                visible: false,
                record: {}
            })
        },
        showPayCommissionDrawer: (record) => {
            record.agentCommissionId = record._id;
            setPayCommissionState({
                visible: true,
                record: record
            })
        },
        hidePayCommissionDrawer: () => {
            setPayCommissionState({
                visible: false,
                record: {}
            })
        },
    }

    const loadData = {
        loadCountryList: async () => {
            let params = {
                results: 5000,
                select: ['countryName'],
                sortField: 'countryName',
                sortOrder: 'ascend'
            }
            let {data} = await dispatch(listAllCountries(params))
            let agentResp = await dispatch(getBranchManagerAgentList())
            let obj = {}
            if (data && data.length) {
                obj.countryList = data;
            }
            if (agentResp && agentResp.data && agentResp.data.length) {
                obj.agentList = agentResp.data;
            }
            events.updateLocalFilter({...obj})
        },
        loadUniversityNameList: async (countryId) => {
            let params = {
                results: 5000,
                select: ['universityName'],
                sortField: 'universityName',
                sortOrder: 'ascend',
                regExFilters: ['universityName'],
                showUniversity: [true, false],
                universityCountry: countryId,
            }
            let {data} = await dispatch(listAllUniversities(params));
            let obj = {
                universityId: ""
            }
            if (data && data.length) {
                obj.universityList = data;
            }
            events.updateLocalFilter({...obj, countryId})
        },

    }


    const columns = [

        /*{
            title: 'S.No',
            dataIndex: 'sno',
            width: 50,
            key: 'sno',
            render: (item, record, index) => {
                return (
                    <a>
                        {(itemNo - 1) * 10 + index + 1}
                    </a>
                )
            }
        },*/
        {
            title: 'Invoice No',
            key: 'invoiceNo',
            dataIndex: 'invoiceNo',
            width: 80,
            render: (item) => {
                return (
                    <div style={{width: 80}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoiceDate',
            key: 'invoiceDate',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 150}}>
                        {item ? displayDate(item) : ""}
                    </div>
                )
            }
        },
        {
            title: 'Agent',
            key: 'agentId',
            dataIndex: 'agentId',
            width: 220,
            render: (item, record) => {
                return (
                    <div style={{width: 180}}>
                        {item && item.name ? item.name : null}
                        {item && item.companyName ? `(${item.companyName})` : null}
                    </div>
                )
            }
        },
        {
            title: 'Invoice Address',
            key: 'invoiceAddress',
            dataIndex: 'invoiceAddress',
            width: 220,
            render: (item, record) => {
                return (
                    <div style={{width: 180}}>
                        {item}
                    </div>
                )
            }
        },

        {
            title: 'Agent Share',
            dataIndex: 'totalCommissionInInr',
            key: 'totalCommissionInInr',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 150}}>
                        {item} INR
                    </div>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: (item, record) => {
                return (
                    <div style={{width: 150}}>
                        {item == 'Paid' ?
                            <label className={'label label-success'}>Paid</label> :
                            <label className={'label label-danger'}>Pending</label>}
                    </div>
                )
            }
        },

        {
            title: 'Added By',
            key: 'userId',
            dataIndex: 'userId',
            width: 160,
            render: (item, record) => {
                return (
                    <div style={{width: 160}}>
                        <div className={'colorPrimary font12 textCap'}>
                            {item && item.name ? item.name : null}
                        </div>
                        <div>{longDisplayDate(record.createdAt)}</div>
                    </div>
                )
            }
        },

        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: 160,
            render: (item, record) => {
                let {status, transactionType} = record
                return (
                    <div className={'view-btn-box'}>

                        {transactionType == 'Credit' ? <>


                            <Tooltip title={'View Student List'}>
                                <a className='btn-view' onClick={() => {
                                    events.showAgentCommissionDrawer(record)
                                }}>
                                    <img src='../uniBtn/eye.png' alt='' className={'eyeBtn'}/> View
                                </a>
                            </Tooltip>
                        </> : ""}

                        {(status == 'Pending' && transactionType == 'Credit') ?
                            <>
                                <a className='btn-view'
                                   onClick={() => {
                                       events.showRaiseInvoiceState(record)
                                   }}><img src='/dist/img/edit.svg' alt='edit' style={{height: 12}}/> Edit
                                </a>

                                <Tooltip title={'Pay Commission'}>
                                    <a className='btn-view' onClick={() => {
                                        events.showPayCommissionDrawer(record)
                                    }}>
                                        <img src='../uniBtn/rupee.png' alt='' className={'eyeBtn'}/> Pay
                                    </a>
                                </Tooltip>

                            </> : ''}


                    </div>
                )
            }
        },

    ]
    return (
        <div className='row mt-4'>
            <div className='col-lg-12'>
                <div className='card'>
                    <div className='table-head d-flex align-items-center'>
                        <h5>All Agent Commission: {totalRecord || 0}</h5>

                        <div className='sort-box-table mark-btn mark-btn40'>

                        </div>
                    </div>
                    <div className='card-body table-responsive'>
                        {/*{FilterComponent}*/}
                        <div className='card-body table-responsive'>
                            <TableComp columns={columns}
                                       apiRequest={apiRequest}
                                       pagination={{
                                           position: 'top',
                                           pageSizeOptions: ['50', '100', '200', '500'],
                                           defaultPageSize: 50
                                       }}
                                       ref={tableRef}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {raiseInvoiceState.visible ?
                <EditAgentInvoiceComponent
                    visible={raiseInvoiceState.visible}
                    onClose={events.hideRaiseInvoiceState}
                    onSubmit={reload}
                    record={raiseInvoiceState.record}
                /> : null}

            {agentCommissionState.visible ?
                <AgentCommStudentDrawer
                    visible={agentCommissionState.visible}
                    onClose={events.hideAgentCommissionDrawer}
                    onSubmit={reload}
                    record={agentCommissionState.record}
                /> : null}
            {payCommissionState.visible ?
                <PayCommission
                    visible={payCommissionState.visible}
                    onClose={events.hidePayCommissionDrawer}
                    onSubmit={reload}
                    record={payCommissionState.record}
                /> : null}
        </div>
    )
}

const mapStateToProps = ({global, router}) => ({
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgentCommissionList)
