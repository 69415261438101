import React, { useEffect, useState } from 'react'
import { Card, Row, Col, Button, Tooltip } from 'antd'
import './styles.css'
import { getSlotByCounsellorFxn } from './actions/counselling'
import { useDispatch } from 'react-redux'
import UpdateCounsellingComponent from './components/updateCounselling'

const CounsellorProfile = () => {
  const dispatch = useDispatch()
  const [bookedSlots, setBookedSlots] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedSlot, setSelectedSlot] = useState(null)
  const apiRequest = async () => {
    let params = {
      date: new Date().toISOString()
    }
    let resp = await dispatch(getSlotByCounsellorFxn(params))
    if (resp.data) {
      const bookedData = resp.data.filter(slot => slot.booked)
      setBookedSlots(bookedData)
    }
  }

  useEffect(() => {
    apiRequest()
  }, [])
  const openModal = (slot) => {
    setSelectedSlot(slot)
    setModalVisible(true)
  }

  const closeModal = () => {
    setModalVisible(false)
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const options = { month: 'long', day: 'numeric', year: 'numeric' }
    return date.toLocaleDateString('en-US', options)
  }

  const openMeetingLink = (meetingLink) => {
    window.open(meetingLink, '_blank')
  }

  return (
    <div>
      <div>
        <div className='row mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='table-head d-flex align-items-center all-student-search'
                   style={{ height: 60 }}>
                <h5>Your Daily Counselling Schedules</h5>
                <div className='search-box-table' style={{ backgroundColor: 'transparent' }}>

                </div>
                <div className='sort-box-table mark-btn'>

                </div>
              </div>
              <div className='card-body table-responsive'>
                <Row gutter={16} className='card-container-slot'>
                  {bookedSlots.map((item, index) => (
                    <Col span={24} key={index}>
                      <Card className='card-slot'>
                        <Row gutter={24}>
                          <Col span={24}>
                            <Tooltip title='Change Status'>
                              {item.data.status !== 'Done' &&
                              <Button className='change-status-btn' shape='circle' icon='edit'
                                      style={{ float: 'right', color: 'black' }}
                                      onClick={() => openModal(item)} />

                              }
                            </Tooltip>
                          </Col></Row>
                        <Row gutter={16}>
                          <Col span={4} md={4} xs={4} sm={4}>
                            <p className='time-container'>{item.slot}</p>
                            <p className='date-container'>{formatDate(item.data.date)}</p>
                          </Col>
                          <Col span={1}></Col>
                          <Col span={4} md={4} xs={4} sm={4}>
                            <h6>{item.data.studentId.name}</h6>
                            <hr />
                            <p style={{ fontWeight: 'normal' }}>{item.data.studentId.email}</p>
                            <p>{item.data.studentId.mobile}</p>
                            <p>{item.data.studentId.address}</p>
                          </Col>
                          <Col span={4} md={4} xs={4} sm={4} style={{ marginTop: '30px', textAlign: 'right' }}>
                            <p>{item.data.countryId && item.data.countryId.countryName}</p>
                            <p>{item.data.marketingUser}</p>
                          </Col>
                          <Col span={1}></Col>
                          <Col span={5} md={5} xs={4} sm={4} style={{ marginTop: '12px' }}>
                            {item.data.meeting && item.data.meeting.meetingLink ? (
                              <Row span={24}>
                                <Col span={24}>

                                  <img src='/assets/image/zoom.png' height='51px'
                                       width='53px' alt='Zoom Logo' />
                                  <p>Id - <span
                                    style={{ fontWeight: 'normal' }}>{item.data.meeting.id}</span>
                                  </p>
                                  <p>Password - <span
                                    style={{ fontWeight: 'normal' }}>{item.data.meeting.password}</span>
                                  </p>
                                </Col>

                              </Row>
                            ) : (
                              <p style={{
                                fontWeight: 'normal',
                                fontSize: '11px',
                                marginTop: '18px'
                              }}>Waiting for Zoom...</p>
                            )}

                          </Col>

                          <Col span={4} md={4} xs={4} sm={4} className='button-container'>
                            {item.data.status === 'Done' ? (
                              <label className='label label-success-green'>Meeting Done</label>
                            ) : item.data.status === 'Not Done' ? (
                              <>
                                <label className='label label-danger'>Meeting Not Done</label>
                                <p style={{
                                  fontWeight: 'normal',
                                  fontSize: '12px'
                                }}>
                                  <span
                                    style={{ fontWeight: '600', fontSize: '12px' }}>Reason:</span> {item.data.reason}
                                </p>
                              </>
                            ) : item.data.status !== 'Not Done' && item.data.meeting && item.data.meeting.meetingLink ? (
                              <Button
                                className='start-meeting-btn'
                                shape='round'
                                onClick={() => openMeetingLink(item.data.meeting.meetingLink)}
                              >
                                Start Meeting
                              </Button>
                            ) : (
                              <p style={{ fontWeight: 'normal', fontSize: '11px', marginTop: '18px' }}>
                                Waiting for Zoom...
                              </p>
                            )}
                          </Col>


                        </Row>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>
        </div>


      </div>

      {modalVisible && selectedSlot ?
        <UpdateCounsellingComponent
          visible={modalVisible}
          onClose={closeModal}
          counsellingData={selectedSlot.data}
          onSubmit={() => {
            closeModal()
            apiRequest()
          }}
        /> : null}
    </div>
  )
}

export default CounsellorProfile
