import { apiUrl } from '../../../settings'

export const addPrivateMessageUrl = () => {
  return apiUrl + '/api/addPrivateMessage'
}

export const listPrivateMessagesUrl = () => {
  return apiUrl + '/api/allPrivateMessage'
}

