import React, {useEffect, useState} from "react"
import {Card, Icon, Tooltip} from "antd";
import {CustomInput, displayDate} from "../../../components/_utils/appUtils";

const SingleStudentRow = (props) => {
    let {item, index, removeStu, updateRecord, currency} = props;
    let {universityId, studentId, countryId, agentId,studentCommission,amount} = item;
    let [studentCommissionState, setStudentCommission] = useState(studentCommission?studentCommission:amount)
    let updateStudentCommission = (value) => {
        setStudentCommission(value)
    }
    useEffect(() => {
        updateRecord({_id: item._id, studentCommissionState})
    }, [studentCommissionState])
    return (
        <>
            {universityId && universityId.universityName ?
                <div className={`commissionParentBox`}>
                    <Card className={'commissionUniInfo'}>
                        {universityId && universityId.universityName ?
                            <div className={'row'}>
                                <div className={'studentBox flexStart'}>
                                    {universityId && universityId.smallLogo && universityId.smallLogo.url ?
                                        <div className={'uniLogo'}>
                                            <img src={universityId.smallLogo.url}/>
                                        </div> : null}
                                    <div className={'studentInfo'} style={{paddingLeft: 10}}>
                                        <div className={'uniName'}>
                                            {universityId.universityName}
                                            {countryId && countryId.countryName ? `, ${countryId.countryName}` : null}
                                        </div>
                                        <div className={'courseInfo'}>
                                            {item.courseName}
                                        </div>
                                        {item && item.intake && item.intake.month ?
                                            <div className={'courseInfo sm'}>
                                                Intake : {item.intake.month}, {item.intake.year}
                                            </div> : null}
                                        {item && item.invoiceDate ?
                                            <div className={'courseInfo sm'}>
                                                Invoice Date : {displayDate(item.invoiceDate)}
                                            </div> : null}
                                    </div>
                                </div>
                                {studentId && studentId._id ?
                                    <div className={'studentBox flexStart'}>
                                        <div className={'studentInfo'}>
                                            <div className={'uniName'}>{studentId.name}
                                            </div>
                                            <div className={'courseInfo'}>
                                                Student ID : {studentId.studentId}
                                            </div>
                                            <div className={'courseInfo'}>
                                                Application ID : {item.appId}
                                            </div>
                                            {agentId && agentId._id ? <div className={'courseInfo sm'}>
                                                {agentId.name} ({agentId.companyName})
                                            </div> : null}
                                        </div>
                                    </div> : null}

                                <div className={'studentBox flexStart'}>
                                    <div className={'studentInfo'}>
                                        <div className={'uniName'}> {item.semester}
                                        </div>
                                        <div className={'courseInfo'}>
                                            Semester Fees : {item.semesterFee} {currency}
                                        </div>
                                        <div className={'courseInfo'}>
                                            Offer S. ID : {item.commissionDetails.offerLetterStudentId}
                                        </div>
                                        <div className={'courseInfo sm'}>
                                            Commission : {item.amount} {currency}
                                            {item.universityCommissionType == 'Percentage' ? ` (${item.universityCommission}%)` : null}
                                        </div>
                                    </div>
                                </div>
                                <div className={'studentBox inputFlex'}>
                                    <div className="form-group student-group">
                                        <CustomInput
                                            title={'Commission'}
                                            type={'text'}
                                            leftContent={currency}
                                            value={studentCommissionState}
                                            onChange={(value) => {
                                                updateStudentCommission(value)
                                            }}
                                        />
                                    </div>
                                    {/* <div className="form-group student-group">
                                        <label>Commission</label>
                                        <input type="number" className="form-control"
                                               placeholder={'Commission'}
                                               value={studentCommissionState}
                                               onChange={({target}) => {
                                                   updateStudentCommission(target)
                                               }}
                                        />
                                    </div>*/}
                                </div>

                            </div> : null}
                    </Card>
                </div>
                : null}

        </>
    )
}
export default SingleStudentRow;
