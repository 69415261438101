import React, {useEffect, useState} from "react"
import {Button, Card, Col, Drawer, Form, Input, notification, Row, Select} from "antd";
import {addCounsellingFxn} from "../../counselling/actions/counselling";
import {getStudent, searchedStudent} from "../../student/actions/student";
import {useDispatch} from "react-redux";
import _ from "lodash"
import debounce from 'lodash/debounce'
import {listAllCountries} from "../../countries/actions/countries";
import {InputBox, getYearList} from "../../../components/_utils/appUtils";

const {Option} = Select

let initState = {
    email: '',
    studentType: '',
    name: '',
    mobile: '',
    countryId: '',
    ielts: '',
    yearOfApply: '',
    studentId: undefined
}
const AddCounselling = (props) => {
    let {onClose, visible, onSubmit} = props;
    const [countryList, setCountryList] = useState([])

    let dispatch = useDispatch()
    useEffect(() => {
        loadCountry()
    }, [])
    const [formValues, setFormValues] = useState(initState)
    const [state, setState] = useState({
        studentList: [],
        studentId: ''
    })
    const handleChange = data => {
        setFormValues(prevData => {
            return {
                ...prevData,
                ...data
            }
        })
    }
    const handleSubmit = async e => {
        e.preventDefault()
        if (!formValues.studentType) {
            return notification.warning({message: "Choose student type"})
        }
        if (formValues.studentType == 'Already Enrolled') {
            if (!formValues.studentId) {
                return notification.warning({message: "Please choose student."})
            }
        }
        if (!formValues.name) {
            return notification.warning({message: "Enter name"})
        }
        if (!formValues.mobile) {
            return notification.warning({message: "Enter mobile no"})
        }
       /* if (!formValues.email) {
            return notification.warning({message: "Enter email address"})
        }*/
        if (!formValues.countryId) {
            return notification.warning({message: "Choose country"})
        }
        if (!formValues.studentId) {
            if (!formValues.ielts) {
                return notification.warning({message: "Choose IELTS/PTE"})
            }
            if (!formValues.yearOfApply) {
                return notification.warning({message: "Choose year of apply"})
            }
        }
        if (formValues.studentId == "") {
            delete formValues['studentId']
        }
        let resp = await dispatch(addCounsellingFxn(formValues))
        if (resp && resp.success) {
            dispatch({
                type: "REFRESH_COUNTRY_RECORD",
                countryId: formValues.countryId,
                counsellorId: resp.data.assignedUserId,
                waiting: resp.data.waiting,
                value: true
            })
            handleChange(initState)
            onSubmit()
        }
    }

    const fetchStudent = async value => {
        let studentList = await searchedStudent({name: value})
        setState({
            ...state,
            studentList
        })
    }

    const loadCountry = async () => {
        let countryFilter = {
            results: 10,
            sortField: 'countryName',
            sortOrder: 'ascend',
            active: [true, false]
            // countryName: ['Canada', 'UK', 'Australia']
        }
        let {data} = await listAllCountries(countryFilter)(dispatch)
        setCountryList(data)
    }
    const chooseStudentFxn = async id => {
        if (id) {
            let data = await dispatch(getStudent(id))
            setTimeout(() => {
                let obj = {
                    email: data && data.email ? data.email : null,
                    name: data && data.name ? data.name : null,
                    mobile: data && data.mobile ? data.mobile : null,
                    studentId: data._id,
                    countryId: data.applications && data.applications.length ? data.applications[0].universityCountry : null,
                    ielts: null,
                    yearOfApply: null,
                }
                handleChange(obj)
            }, 500)
        } else {
            let obj = {
                email: "",
                name: "",
                mobile: "",
                studentId: undefined,
                countryId: "",
                ielts: "",
                yearOfApply: "",
            }
            handleChange(obj)
        }
    }

    return (
        <Drawer
            placement="right"
            onClose={onClose}
            visible={visible}
            width={'50%'}>
            <Card className=" card unizportal drawerCard">
                <div className="login-account">
                    <div className="align-items-center">
                        <div className="text-center">
                            <img
                                src="../../assets/unizportal-logonew.png"
                                alt="unizportal-logo"
                                className="footer-logo"
                            />
                            <Form
                                autoComplete="off"
                                labelCol={{span: 0}}
                                wrapperCol={{span: 24}}
                                onSubmit={handleSubmit}>
                                <div>


                                    <div className="form-group ">
                                        <div className="box newStyle arrowStyle ">
                                            <label>Student Type:</label>
                                            <Select className="selectOption arrowInCenter "
                                                    disabled={formValues.studentId}
                                                    onChange={e => handleChange({studentType: e})}
                                                    value={formValues.studentType}>
                                                <Option value={''}>Choose student type</Option>
                                                <Option value="Direct">Direct</Option>
                                                <Option value="Already Enrolled">
                                                    Already Enrolled
                                                </Option>
                                            </Select>
                                        </div>
                                    </div>
                                    {formValues.studentType == "Already Enrolled" ?
                                        <div className="form-group ">
                                            <div className="box newStyle arrowStyle ">
                                                <label>Search Student:</label>
                                                <Select className="selectOption arrowInCenter "
                                                        showSearch={true}
                                                        onSearch={debounce(fetchStudent, 500)}
                                                        filterOption={false}
                                                        autoClearSearchValue={true}
                                                        allowClear={true}
                                                        showArrow={true}
                                                        onChange={chooseStudentFxn}
                                                        optionLabelProp="label"
                                                        placeholder="Search Student">
                                                    {state.studentList &&
                                                    state.studentList.length &&
                                                    state.studentList.map((item, index) => {
                                                        return (
                                                            <Option key={item._id} value={item._id} label={item.name}>
                                                                <div>
                                                                    <div>
                                                                        {item.studentId} : {item.name}
                                                                    </div>
                                                                    <div>{item.email}</div>
                                                                </div>
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div> : null}
                                    <div className="form-group">
                                        <div className="box">
                                            <label>Full Name:</label>
                                            <input
                                                type="text"
                                                id="name"
                                                placeholder="Enter your Full Name"
                                                value={formValues.name}
                                                disabled={formValues.studentId}
                                                onChange={({target}) => {
                                                    handleChange({name: target.value});
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="box">
                                            <label>Contact:</label>
                                            <input
                                                type="text"
                                                id="mobile"
                                                placeholder="Enter your Mobile Number"
                                                disabled={formValues.studentId}
                                                value={formValues.mobile}
                                                onChange={({target}) => {
                                                    handleChange({mobile: target.value});
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="box">
                                            <label>Email ID:</label>
                                            <input
                                                type="text"
                                                id="email"
                                                placeholder="Enter your Email ID"
                                                value={formValues.email}
                                                onChange={({target}) => {
                                                    handleChange({email: target.value});
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="box newStyle arrowStyle">
                                            <label>Country:</label>
                                            <Select className="selectOption arrowInCenter"
                                                    size="small"
                                                    disabled={formValues.studentId}
                                                    onChange={e =>
                                                        handleChange({countryId: e.toString()})
                                                    }
                                                    name="country"
                                                    value={formValues.countryId}>
                                                <Option value={''}>Choose country</Option>
                                                {countryList && countryList.map(item => {
                                                    return (
                                                        <Option value={item._id}>
                                                            {item.countryName}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    </div>

                                    <>
                                        <div className="form-group">
                                            <div className="box newStyle arrowStyle">
                                                <label>IELTS/PTE:</label>
                                                <Select className="selectOption arrowInCenter"
                                                        size="small"
                                                        disabled={formValues.studentId}
                                                        onChange={e => handleChange({ielts: e})}
                                                        value={formValues.ielts}>
                                                    <Option value={''}>Choose IELTS/PTE</Option>
                                                    <Option value="Yes">YES</Option>
                                                    <Option value="No">NO</Option>
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="box newStyle arrowStyle">
                                                <label>Year to Apply:</label>
                                                <Select className="selectOption arrowInCenter"
                                                        disabled={formValues.studentId}
                                                        onChange={e => handleChange({yearOfApply: e})}
                                                        value={formValues.yearOfApply}>
                                                    <Option value={''}>Choose year of apply</Option>
                                                    {getYearList().map((item, key) => {
                                                        return (
                                                            <Option value={item}
                                                                    key={item}>{item}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </>
                                    <Button type='primary' htmlType='submit' className='btn'>
                                        Add Student
                                    </Button>

                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </Card>
        </Drawer>
    )
}
export default AddCounselling
