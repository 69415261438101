import { Button, Card, Drawer, Form, Icon, Input, notification } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import InputBox from '../../../components/_utils/InputBox'
import { updateRequestStatusFxn } from '../actions/closeDeal'

let statusList = [
  { name: 'Approved', value: 'Approved' },
  { name: 'Cancel', value: 'Cancel' }
]
let initState = {
  status: '',
  transactionDetails: '',
  reason: ''
}
const { TextArea } = Input

const UpdateRequestStatusComponent = props => {
  let dispatch = useDispatch()
  let { onClose, visible, requestId, onSubmit } = props

  let [state, setState] = useState(initState)
  let events = {
    setLocalState: data => {
      setState(prevState => {
        return {
          ...prevState,
          ...data
        }
      })
    },
    handleSubmit: async e => {
      e.preventDefault()
      if (!state.status) {
        notification.warn({ message: 'Please choose status.' })
        return
      }
      if (state.status == 'Rejected') {
        if (!state.reason) {
          notification.warn({ message: 'Enter Reason' })
          return
        }
      }
      let resp = await dispatch(
        updateRequestStatusFxn({ ...state, requestId })
      )
      if (resp && resp.success) {
        onSubmit()
      }
    }
  }

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      title={'Verify Your Deal Consent'}
      width={'45%'}>
      <Card bordered={false}>
        <Form>
          <Form.Item>
            <InputBox title={'Status*'}>
              <select className={'form-control'} value={state.status} onChange={({ target }) => {
                events.setLocalState({ status: target.value })
              }}>
                <option value={''}>Choose Status</option>
                <option value={'Approved'}>Approved</option>
                <option value={'Rejected'}>Rejected</option>
              </select>
            </InputBox>
          </Form.Item>
          {state.status == 'Rejected' ?
            <Form.Item>
              <InputBox title={'Reason*'}>
                <TextArea
                  rows={7}
                  className={'form-control customTextArea'}
                  placeholder={'Reason'}
                  onChange={({ target }) => {
                    events.setLocalState({ reason: target.value })
                  }}
                  value={state.reason}
                />
              </InputBox>
            </Form.Item> : null}
          <Form.Item className={'mt20 alignRight'}>
            <Button type='primary' onClick={events.handleSubmit}>
              SUBMIT
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  )
}
export default UpdateRequestStatusComponent
