import React, { Component } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import {
  Table,
  Tooltip,
  notification,
  Input, Button, Icon, Popconfirm, Tag, Modal
} from 'antd'
import { connect } from 'react-redux'
import { DefaultTablePagination, displayDate } from '../../../components/_utils/appUtils'
import { getPushPathWrapper } from '../../../routes'
import { push } from 'connected-react-router'
import { getAllLeads } from '../actions'
import { ColumnWidth } from '../../WebComponent/columnWidth'

class AllLeads extends Component {
  events = {
    showAttachmentModal: (attachments) => {
      this.setState({
        attachments: attachments,
        visibleAttachmentModal: true
      })
    },
    hideAttachmentModal: (attachments) => {
      this.setState({
        attachments: [],
        visibleAttachmentModal: false
      })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      attachments: [],
      visibleAttachmentModal: false
    }
    this.tableRef = React.createRef()
  }

  apiRequest = (params) => {
    let { dispatch } = this.props
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    return new Promise(async (resolve) => {
      params.sortField = 'name'
      params.sortOrder = 'ascend'
      resolve(dispatch(getAllLeads({
        ...params, userId: user._id,
        regExFilters: ['name', 'address', 'mobile', 'email']
      })))
    })
  }

  render() {

    let { dispatch } = this.props
    let { attachments, visibleAttachmentModal } = this.state
    const columns = [
      {
        title: '#',
        key: '_id',
        dataIndex: '_id',
        width: 50,
        render: (ite, record, index) => {
          return (
            <React.Fragment>
              {index + 1}
            </React.Fragment>
          )
        }
      },
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        searchTextName: 'name',
        width: 100,
        render: (item) => {
          return (
            <ColumnWidth width={100}>{item}</ColumnWidth>
          )
        }
      },
      {
        title: 'Company Name',
        key: 'companyName',
        sorter: true,
        dataIndex: 'companyName',
        searchTextName: 'companyName',
        width: 100,
        render: (item) => {
          return (
            <ColumnWidth width={100}>{item}</ColumnWidth>
          )
        }
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        searchTextName: 'email',
        width: 120,
        render: (item) => {
          return (
            <ColumnWidth width={120}>{item}</ColumnWidth>
          )
        }
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobile',
        key: 'mobile',
        searchTextName: 'mobile',
        width: 80,
        render: (item) => {
          return (
            <ColumnWidth width={80}>{item}</ColumnWidth>
          )
        }
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        searchTextName: 'address',
        width: 150,
        render: (item) => {
          return (
            <ColumnWidth width={150}>{item}</ColumnWidth>
          )
        }
      },

      {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
        searchTextName: 'message',
        width: 150,
        render: (item) => {
          return (
            <ColumnWidth width={150}>{item}</ColumnWidth>
          )
        }
      },

      {
        title: 'Date',
        dataIndex: 'createdAt',
        width: 100,
        key: 'createdAt',
        render: (item) => {
          return item ? displayDate(item) : null
        }
      },

      {
        title: 'Attachments',
        dataIndex: 'attachments',
        key: 'attachments',
        width: 100,
        render: (val) => {
          return (
            <div className={'paddingHz'}>
              {val && val.length ?
                <a className={'moreBtn pointerCls'} style={{ fontSize: 12 }}
                   onClick={() => this.events.showAttachmentModal(val)}>
                  {val.length} Attachments</a> : null}
            </div>
          )
        }
      }
    ]
    return (
      <div>

        <div className="row  mt-4">
          <div className="col-lg-12">
            <div className="card">
              <div className="table-head d-flex align-items-center">
                <h5>All Daily Leads</h5>
                <div className="search-box-table">

                </div>

                <div className="sort-box-table mark-btn">
                  <div>
                    {/* <a className="btn" onClick={() => dispatch(push('/user/add-user'))}>
                      <img src={'../dist/icons/plus.png'} className={'plus'}/> Add Leads</a>*/}

                  </div>
                </div>
              </div>
              <div className="card-body table-responsive">
                <TableComp columns={columns}
                           ref={this.tableRef}
                           pagination={DefaultTablePagination()}
                           apiRequest={this.apiRequest}
                           extraProps={{ scroll: { x: 1000 } }}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal
          title={`Attachments List`}
          style={{ top: 20 }}
          okText={''}
          cancelText={''}
          visible={visibleAttachmentModal}
          footer={null}
          onOk={() => this.events.hideAttachmentModal()}
          onCancel={() => this.events.hideAttachmentModal()}>
          <div className={'paddingHz'}>
            <ul>
              {attachments && attachments.length ? attachments.map((item, key) => {
                return (
                  <li key={key} style={{ marginBottom: 5 }}>
                    <a href={item.url}>
                      <Tag>  {item.name}</Tag>
                    </a>
                  </li>
                )
              }) : null}
            </ul>
          </div>
        </Modal>

      </div>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllLeads)
