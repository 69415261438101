import React, { useState, useEffect, useRef } from 'react'
import { Form, Button, Row, Col, notification, Input, Select, Tooltip, Drawer, Popconfirm } from 'antd'
import { useDispatch } from 'react-redux'
import { DefaultTablePagination, filterOption, InputBox } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { TableComp } from 'sz-react-utils-lite'
import { listAllUsers } from '../../users/actions/user'
import {
  addComplaintDepartmentFxn,
  complaintDepartmentListFxn,
  deleteComplaintDepartmentFxn, updateComplaintDepartmentFxn
} from '../actions'

const { Option } = Select

const initialState = {
  department: '',
  user: ''
}

const AddComplaintDepartment = (props) => {

  const tableRef = useRef()
  const { form: { getFieldDecorator, setFieldsValue } } = props
  const dispatch = useDispatch()
  let [users, setUsers] = useState([])
  const [state, setState] = useState(initialState)
  let [editDrawer, setEditDrawer] = useState({
    visible: false,
    id: ''
  })
  const handleSubmit = (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let resp = await dispatch(addComplaintDepartmentFxn(valData))
        if (resp && resp.success) {
          form.resetFields()
          setState([])
          tableRef.current.reload()
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }
  const handleUpdate = async () => {
    if (!state.department) {
      notification.warning({ message: 'Please Enter Department Name' })
      return
    }
    if (!state.user) {
      notification.warning({ message: 'Please Choose User' })
      return
    }
    const resp = await dispatch(updateComplaintDepartmentFxn({ ...state, id: editDrawer.id }))
    if (resp.success) {
      tableRef.current.reload()
      setEditDrawer({
        visible: false,
        id: ''
      })
    } else {
      return
    }
  }
  const handleDelete = async (_id) => {
    await dispatch(deleteComplaintDepartmentFxn(_id))
    tableRef.current.reload()
  }
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.sortField = '_id'
      params.sortOrder = 'descend'
      let resp = await dispatch(complaintDepartmentListFxn({ ...params, regExFilters: ['name'] }))
      resolve(resp)
    })
  }
  const Users = async (params = {}) => {
    params.sortField = 'name'
    params.sortOrder = 'ascend'
    params.userType = ['branchUser', 'branchManager', 'marketingManager']
    params.block = false
    params.results = 1000
    params.count = 1000
    try {
      let resp = await dispatch(listAllUsers({
        ...params,
        select: ['name', 'userType', 'email']
      }))
      setUsers(resp.data)
    } catch (error) {
      console.error('Error fetching users:', error)
    }
  }
  useEffect(() => {
    Users()
  }, [])
  const formFields = [
    {
      key: 'department',
      label: 'Department',
      required: true
    },
    {
      key: 'user',
      label: 'Select User',
      required: true,
      type: 'select',
      showSearch: true,
      options: users,
      keyAccessor: x => x._id,
      valueAccessor: x => `${x.name} (${x.email})`,
      onChange: x => {
        setFieldsValue({
          user: x
        })
      }
    }
  ]
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  let columns = [
    {
      title: '#',
      key: '_id',
      dataIndex: '_id',
      width: 30,
      render: (ite, record, index) => {
        return (
          <React.Fragment>
            {index + 1}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Department',
      key: 'department',
      dataIndex: 'department'
    },
    {
      title: 'User',
      key: 'user',
      dataIndex: 'user',
      render: (item) => {
        return item && item.name ?
          <>
            {item.name}
          </> : ''
      }
    },
    {
      title: 'Action',
      key: '_id',
      dataIndex: '_id',
      render: (val, record) => {
        return (
          <React.Fragment>
            <Tooltip title={'Edit'}>
              <a
                className={'btn btn-sm btn-default'}
                onClick={() => {
                  setEditDrawer({
                    visible: true,
                    id: record._id
                  })
                  setState({
                    department: record.department,
                    user: record.user ? record.user._id : ''
                  })
                }}
              >
                <img src='/dist/img/edit.svg' alt='' />
              </a>
            </Tooltip>
            <Tooltip title='Delete'>
              <Popconfirm
                title={'Are your sure, you want to delete?'}
                onConfirm={() => {
                  handleDelete(val)
                }}
                okText='Yes' cancelText='No'>
                <Button shape='circle' icon='delete' />
              </Popconfirm>
            </Tooltip>

          </React.Fragment>
        )
      }
    }
  ]

  return (
    <div className={'mt-4'}>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='form-box'>
            <div className='d-flex align-items-center mb-3 heading-form'>
              <h5>Complaint Department</h5>
            </div>
            <div className='card unizportal'>
              <Form onSubmit={handleSubmit} className='vertical-form'>
                <Row gutter={24}>
                  {formFields.map((item, key) => {
                    return (
                      <React.Fragment>
                        {!item.hidden && item.customField ? <Col span={item.span ? item.span : 8} key={key}>
                            {item.customField}
                          </Col> :
                          !item.hidden && <Col span={item.span ? item.span : 8} key={key}>
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout} />
                          </Col>}
                      </React.Fragment>
                    )
                  })}
                </Row>
                <Row gutter={24}>
                  <Col span={18}>
                    <Form.Item>
                      <Button type='primary' htmlType='submit' className='btn'>
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className='card'>
              <div className='table-head d-flex align-items-center'>
                <h5>Complaint Departments List</h5>
                <div className='search-box-table'>
                </div>
                <div className='sort-box-table mark-btn'>
                </div>
              </div>
              <div className='card-body table-responsive'>
                <TableComp columns={columns}
                           pagination={DefaultTablePagination()}
                           apiRequest={apiRequest}
                           ref={tableRef}
                           extraProps={{ scroll: { x: 1800 } }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>


      {editDrawer.visible ?
        <Drawer
          title={'Edit Complaint Drawer'}
          visible={editDrawer.visible}
          placement='right'
          closable={true}
          onClose={() => {
            setEditDrawer({
              visible: false,
              id: ''
            })
          }}
          width={'30%'}>
          <div className='form-box'>
            <div className='card unizportal'>
              <Form className={'vertical-form'}>
                <InputBox title={'Department'}>
                  <Form.Item name='details'>
                    <Input
                      className={'form-control'}
                      placeholder='Enter Department'
                      value={state.department}
                      onChange={(e) => setState({ ...state, department: e.target.value })}
                    />
                  </Form.Item>
                </InputBox>
                <InputBox title={'User'}>
                  <Form.Item name='User'>
                    <Select
                      showSearch
                      placeholder='Select A User'
                      optionFilterProp='children'
                      value={state.user}
                      onChange={(value) => setState({ ...state, user: value })}
                      filterOption={filterOption}>
                      {users.map(user => {
                        let newLabel = `${user.name} (${user.email})`
                        return (
                          <Option key={user._id} value={user._id}>
                            {newLabel}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </InputBox>
                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className={'btn'}
                    onClick={handleUpdate}>
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Drawer> : null
      }
    </div>
  )
}
export default Form.create()(AddComplaintDepartment)
