import {Avatar, Button, Card, Popconfirm, Tooltip, notification} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import defaultLogo from '../../../assets/profile-user.png'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    DefaultTablePagination,
    displayDate,
    nameAvatar
} from '../../../components/_utils/appUtils'
import {getPushPathWrapper, getUrlPushWrapper} from '../../../routes'
import ViewApplication from '../../applications/views/viewApplication'
import {listAllStudents} from '../actions/student'
import studentReducers from '../reducers/student'
import ApplyCourse from './applyForApplicationDrawer'
import Styles from './styles.less'

const initialState = {
    studentObj: '',
    viewCourseDrawer: false
}

const WithdrawalStudentList = props => {
    const {totalStudents} = useSelector(state => ({
        totalStudents: state.studentReducers.totalStudents
    }))
    const [state, setState] = useState(initialState)

    const tableRef = useRef()
    const dispatch = useDispatch()
    const apiRequest = params => {
        return new Promise(async resolve => {
            params.status = 'Withdrawal'
            resolve(
                dispatch(
                    listAllStudents({
                        ...params,
                        regExFilters: ['name', 'address', 'mobile', 'email', 'studentId']
                    })
                )
            )
        })
  }
  const [itemNo, setItemNo] = useState(1)
    const [loading, setLoading] = useState(false)
    const confirmDelete = async id => {
    }

  const events = {
      viewCourse: record => {
          setState({
              ...state,
              viewCourseDrawer: true,
              studentObj: record
          })
      },
    viewCourseClose: () => {
      setState({
        ...state,
        viewCourseDrawer: false,
        studentObj: {}
      })
      tableRef.current.reload()
    }
  }

  const columns = [
    {
      title: 'Serial No.',
      dataIndex: 'serialNo',
        render: (val, record, index) => <div>{index + 1}</div>
    },
    {
      title: 'Student Id',
      dataIndex: 'studentId',
      searchTextName: 'studentId',
      width: 100
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      searchTextName: 'name',
      width: 100,
      render: (item, record) => {
        let { profileImage } = record
        return (
          <div className={Styles.userNameBox}>
            {/* {profileImage && profileImage.url ?
              <Avatar size={30} src={profileImage.url}></Avatar> :
              <Avatar size={30} src={defaultLogo}></Avatar>}*/}
              <div>{item}</div>
          </div>
        )
      }
    },

    {
      title: 'Email',
      dataIndex: 'email',
      searchTextName: 'email',
      width: 100,
      render: (item, record) => {
          return <div>{record.email}</div>
      }
    },

    {
      title: 'Mobile',
      dataIndex: 'mobile',
      width: 100,
      searchTextName: 'mobile',
      render: (item, record) => {
          return <div>{record.mobile}</div>
      }
    },
      {
          title: 'Date Of Birth',
          dataIndex: 'dateOfBirth',
          key: 'dateOfBirth',
          width: 120,
          render: val => {
              return <span>{displayDate(val)}</span>
          }
      },
    {
      title: 'Address',
      key: 'address',
      searchTextName: 'address',
      width: 150,
      dataIndex: 'address',
      render: (item, record) => {
          return item ? (
              <React.Fragment>
                  {item}, {record.cityName}, {record.stateName}, {record.countryName}
              </React.Fragment>
          ) : (
              ''
          )
      }
    }
  ]

  return (
    <div>
      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="card">
            <div className="table-head d-flex align-items-center">
              <h5>All Withdrawal Students: {totalStudents || 0}</h5>
              <div className="search-box-table">
                {/*<input className="form-control form-control" type="search" placeholder="Search Student"/>
                <img src="/dist/img/search.png" alt=""/>*/}
              </div>

                <div className="sort-box-table">
                    {/*<select name="Sort By" id="">
                  <option>Sort By</option>
                  <option value="1">Date</option>
                  <option value="2">Time</option>
                </select>*/}
                </div>
            </div>
              <div className="card-body table-responsive">
                  <TableComp
                      columns={columns}
                      apiRequest={apiRequest}
                      pagination={DefaultTablePagination()}
                      ref={tableRef}
                  />
              </div>
          </div>
        </div>
      </div>

        {state.viewCourseDrawer ? (
            <ViewApplication
                visible={state.viewCourseDrawer}
                pageType={'student'}
                onClose={() => events.viewCourseClose()}
                studentObj={state.studentObj}
            />
        ) : null}
    </div>
  )
}

export default WithdrawalStudentList
