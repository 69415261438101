import React, { useState, useEffect } from 'react'
import { Drawer, Form, Button, notification, Card, Row, Col } from 'antd'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { useDispatch, useSelector } from 'react-redux'
import { getSampleDocument, updateSampleDocument } from '../actions/sampleDocument'
import { createMatchSelector, goBack } from 'connected-react-router'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { getCountry } from '../../countries/actions/countries'

const Edit = (props) => {
  const dispatch = useDispatch()
  let { visible, setVisible } = props
  const [pendency, setPendency] = useState({})
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields }, events, id } = props

  let inputTypes = {
    fields: [
      { key: 'name', label: 'Pendency', type: 'text', required: true }
    ]
  }


  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  useEffect(() => {
    getSampleDocumentFxn()
  }, [])

  const getSampleDocumentFxn = async () => {
    if (id) {
      let data = await dispatch(getSampleDocument(id))
      if (data) {
        setPendency(() => data)
      }
    }
  }

  const handleSubmit = async (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let resp = await dispatch(updateSampleDocument(valData, id))
        if (resp && !resp.error) {
          events.hideEditDrawer()
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }


  useEffect(() => {
    props.form.setFieldsValue({
      name: `${pendency.name || ''}`

    })

  }, [pendency])


  return <div>
    <Drawer
      visible={visible}
      width={600}
      placement="right"
      onClose={events.hideEditDrawer}
      title={'Edit Pendency'}>
      <Card bordered={true}>
        <Form onSubmit={handleSubmit} style={{ marginTop: 8 }}>
          <GetAllFormFields
            inputSchema={inputTypes}
            formItemLayout={formItemLayout}
            getFieldDecorator={getFieldDecorator}
          />
          <Form.Item style={{ marginTop: 25 }}>
            <Button type="primary" htmlType="submit">
              SAVE
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  </div>
}

const EditCountry = Form.create()(Edit)
export default EditCountry
