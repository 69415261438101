import React, {useEffect, useState} from 'react'
import {countryIds, InputBox} from '../../../components/_utils/appUtils'
import {Button, Card, Drawer, Input, notification, DatePicker, Row, Col, Select} from 'antd'
import {useDispatch} from 'react-redux'
import {calculateCommission, submitCommission} from '../../applications/actions/chooseStudent'
import {RowTable} from '../../../components/_utils/RowTable'
import moment from 'moment'
import CountryCodeFlagJson from '../../../assets/jsonFiles/countryCodeFlag.json'
import {editCommission} from '../../applications/actions/chooseStudent'

const dateFormat = 'DD/MM/YYYY'

let {TextArea} = Input
const {Option} = Select
const EditCommission = (props) => {
    const dispatch = useDispatch()
    let {visible, onClose, commissionData, application, studentId} = props

    let [commissionAmount, setCommissionAmount] = useState(commissionData && commissionData.commission ? commissionData.commission : null)
    let [commissionCurrencyAmount, setCommissionCurrencyAmount] = useState(null)
    let [courseStartDate, setCourseStartDate] = useState(null)
    let [invoiceDate, setInvoiceDate] = useState(null)
    let [commission, setCommission] = useState(commissionData ? commissionData : {})
    let [invoiceAddress, setInvoiceAddress] = useState('')
    let [semester, setSemester] = useState('')
    // let { agentId } = application

    useEffect(() => {
        // getCommission()
        setDefault()
    }, [])

    const setDefault = () => {
        if (commissionData) {
            let {
                invoiceDate,
                commissionInCurrency,
                commissionAmount,
                invoiceAddress,
                semester,
                courseStartDate
            } = commissionData
            if (courseStartDate) {
                setCourseStartDate(moment(courseStartDate))
            }
            if (invoiceDate) {
                setInvoiceDate(moment(invoiceDate))
            }
            if (invoiceAddress) {
                setInvoiceAddress(invoiceAddress)
            }
            if (semester) {
                setSemester(semester)
            }
            if (commissionAmount) {
                setCommissionAmount(commissionAmount)
            }
            if (commissionInCurrency) {
                setCommissionCurrencyAmount(commissionInCurrency)
            }
        }
    }

    // const getCommission = async () => {
    //   let obj = {
    //     agentId: application.agentId._id,
    //     courseId: application.courseId,
    //     countryId: application.universityCountry._id
    //   }
    //   let { success, data } = await dispatch(calculateCommission(obj))
    //   if (data) {
    //     setCommissionAmount(data.commissionAmount)
    //     setCommission(data)
    //   }
    // }

    const handleSubmit = async () => {
        if (!invoiceDate) {
            notification.warning({message: 'Choose Invoice Date.'})
            return
        }
        if (!commissionAmount) {
            notification.warning({message: 'Please enter commission.'})
            return
        }
        if (!commissionCurrencyAmount) {
            notification.warning({message: 'Enter Commission with Currency'})
            return
        }

        if (!courseStartDate) {
            notification.warning({message: 'Choose Course Start Date'})
            return
        }
        if (!invoiceAddress) {
            notification.warning({message: 'Choose Invoice Address'})
            return
        }

        let obj = {
            commission: {
                commissionId: commissionData._id,
                agentId: commissionData.agentId._id,
                commission: commissionAmount,
                invoiceDate,
                courseStartDate,
                semester,
                invoiceAddress,
                commissionInCurrency: commissionCurrencyAmount
            }
        }
        let {success, data, message} = await dispatch(editCommission(obj))
        if (success) {
            notification.success({message: message})
            onClose()
        } else {
            notification.error({message: message})
        }

    }
    return (
        <Drawer visible={visible}
                onClose={onClose}
                width={'50%'}
                closable={true}
                maskClosable={true}
                title={'Edit Commission'}>
            <Card>

                <Row gutter={16} className={'rowWrap'}>
                    <Col span={12}>
                        <InputBox title={'Invoice Date'}>
                            <DatePicker
                                defaultValue={invoiceDate} format={dateFormat} placeholder={'Invoice Date'}
                                onChange={(value) => {
                                    setInvoiceDate(value)
                                }}/>
                        </InputBox>
                    </Col>
                    <Col span={12}>
                        <InputBox title={'Commission in INR'}>
                            <Input type={'number'} value={commissionAmount} onChange={(e) => {
                                setCommissionAmount(e.target.value)
                            }} className={'form-control'}/>
                        </InputBox>
                    </Col>
                    <Col span={12}>
                        <InputBox title={'Commission In Currency'}>
                            <Input value={commissionCurrencyAmount} onChange={(e) => {
                                setCommissionCurrencyAmount(e.target.value)
                            }} className={'form-control'}/>
                        </InputBox>
                    </Col>
                    <Col span={12}>
                        <InputBox title={'Course start Date'}>
                            <DatePicker
                                defaultValue={courseStartDate} format={dateFormat} placeholder={'Course Start Date'}
                                onChange={(value) => {
                                    setCourseStartDate(value)
                                }}/>
                        </InputBox>
                    </Col>
                    <Col span={12}>
                        <InputBox title={'Invoice Address'} className={'rowWrap'}>
                            <Select
                                showSearch={true}
                                placeholder='Invoice Address'
                                value={invoiceAddress}
                                onChange={(e) => {
                                    setInvoiceAddress(e)
                                }}>
                                <Option value={'Ryan Consultants'} label={'Ryan Consultants'}>Ryan Consultants</Option>
                                <Option value={'Unizportal'}>Unizportal</Option>
                            </Select>
                        </InputBox>
                    </Col>
                    {commission && commission.countryId && commission.countryId._id == countryIds.canada ?
                        <Col span={12}>
                            <InputBox title={'Semester'} className={'rowWrap'}>
                                <Input value={semester} onChange={(e) => {
                                    setSemester(e.target.value)
                                }} className={'form-control'}/>

                            </InputBox>
                        </Col> : null}
                    <Col span={24} style={{marginTop: 20}}>
                        <Button type='primary' onClick={handleSubmit}>
                            SUBMIT
                        </Button>
                    </Col>
                </Row>
            </Card>
        </Drawer>
    )
}

export default EditCommission
