import {customAxios as axios, getToken} from "../../../../../request";
import { getGicBalanceUrl, withdrawalUrl } from '../apis'
import { hideLoader, showLoader } from '../../../../../modules/actions'

export const withdrawalFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(withdrawalUrl(), config);
    dispatch(hideLoader());
    return data;
};

export const getGicBalance = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(getGicBalanceUrl(), valData, getToken());
    dispatch(hideLoader());
    return data;
};
