import React, { useEffect, useState } from 'react'
import {
  Card,
  Form,
  Spin,
  Steps
} from 'antd'
import moment from 'moment'
import DirectAddStudent from './directAddStudent'
import {connect} from "react-redux";

const { Step } = Steps

let initialState = {
  currentStep: 0,
  fileList: [],
  uploadKey: moment()
}

const AddStudent = (props) => {
  const studentCallback = (studentData) => {

  }
  /* return (
     <PageHeaderWrapper
       title={'Add Student'}>
       <AddStudentContent callBackFxn={studentCallback} pageType={'direct'}/>
     </PageHeaderWrapper>
   )*/
  return (
    <DirectAddStudent callBackFxn={studentCallback} pageType={'direct'}/>
  )
}

export default AddStudent

