import React, { useEffect, useState } from 'react'
import {
  Card,
  Form,
  Spin,
  Steps,
  Radio,
  Row,
  Col,
  Button,
  Input,
  Select,
  notification,
  Popover,
  Upload,
  Icon,
  Table
} from 'antd'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { connect, useDispatch, useSelector } from 'react-redux'
import { ProgramLevel, qualificationOptions, DocumentTypes } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { listAllCountries } from '../../countries/actions/countries'
import { listAllStates } from '../../state/actions/state'
import { addCity, listAllCities } from '../../city/actions/city'
import { addCompany, getCompany, updateCompany } from '../actions/company'
import { apiUrl } from '../../../settings'
import Request from '../../../request'
import _ from 'lodash'
import moment from 'moment'
import { InputBox } from '../../../components/_utils/appUtils'
import { LoadAllCountry, LoadState, LoadCity } from '../../../components/_utils/countryUtil'
import { getUrlParams } from '../../../routes'

const { Step } = Steps

let initialState = {
  currentStep: 0,
  fileList: [],
  uploadKey: moment(),
  allCountries: LoadAllCountry(),
  allStates: [],
  allCities: [],
  countryName: '',
  stateName: '',
  cityName: '',
  companyId: ''
}

const EditCompany = (props) => {
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } } = props
  const dispatch = useDispatch()
  const paramsData = getUrlParams('company.editCompany', props.pathname)
  const [state, setState] = useState(initialState)

  const { allCountries, allStates, allCities } = state

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  let formFields = {
    generateInputs: [
      { key: 'name', label: 'Company Name', required: true, placeholder: 'Enter Company Name' },
      { key: 'contactPerson', label: 'Contact Person Name', required: true, placeholder: 'Enter Contact Person Name' },
      { key: 'mobile', label: 'Phone', required: true, type: 'number', placeholder: 'Enter Phone No' },
      {
        key: 'countryName',
        label: 'Country',
        required: true,
        type: 'select',
        showSearch: true,
        options: allCountries,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            countryName: x,
            stateName: '',
            cityName: ''
          })
          events.chooseCountry(x)
        }
      },
      {
        key: 'stateName',
        label: 'Province/State',
        required: true,
        type: 'select',
        showSearch: true,
        options: allStates,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            stateName: x,
            cityName: ''
          })
          events.chooseState(x)
        }
      },
      {
        key: 'cityName',
        label: 'City/Town',
        required: true,
        type: 'select',
        showSearch: true,
        options: allCities,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            cityName: x
          })
          events.chooseCity(x)
        }
      },
      { key: 'postalCode', label: 'Postal/Zip Code', type: 'number', placeholder: 'Postal/Zip Code', required: true },

      { key: 'address', label: 'Address', required: true, span: 16, placeholder: 'Enter Address' }
    ]
  }

  useEffect(() => {
    dispatch(listAllCountries({ results: 1000000 }))
    document.body.className = ''
    loadCompanyData(paramsData)

  }, [])

  const loadCompanyData = async (params) => {
    if (params && params.id) {
      let data = await dispatch(getCompany(params.id))
      setTimeout(() => {
        let obj = {
          name: data.name,
          contactPerson: data.contactPerson,
          mobile: data.mobile,
          stateName: data.stateName,
          countryName: data.countryName,
          cityName: data.cityName,
          address: data.address,
          postalCode: data.postalCode
        }
        setFieldsValue({ ...obj })
        setState({
          ...state,
          companyId: data._id,
          countryName: obj.countryName,
          stateName: obj.stateName,
          cityName: obj.cityName
        })
      }, 1000)
    }
  }


  const events = {
    handleSubmit: (e) => {
      const { form } = props
      e.preventDefault()
      form.validateFieldsAndScroll(async (err, valData) => {
        if (!err) {
          let { countryName, stateName, cityName, companyId } = state
          valData = { ...valData, countryName, stateName, cityName }

          let data = await dispatch(updateCompany(valData, companyId))
          if (data && !data.error) {
            setState({
              ...initialState,
              uploadKey: moment()
            })
          }
        } else {
          notification.warning({
            message: 'Fill All Required Fields'
          })
        }
      })
    },
    chooseCountry: (name) => {
      let countryName = _.find(allCountries, (item) => {
        return item.name == name
      })
      if (countryName) {
        setState({
          ...state,
          countryName: countryName.name ? countryName.name : '',
          allStates: countryName.id ? LoadState(countryName.id) : []
        })
      } else {
        setState({
          ...state,
          countryName: '',
          allStates: []
        })
      }
    },
    chooseState: (name) => {
      let stateName = _.find(allStates, (item) => {
        return item.name == name
      })
      if (stateName) {
        setState({
          ...state,
          stateName: stateName.name ? stateName.name : '',
          allCities: stateName.id ? LoadCity(stateName.id) : []
        })
      } else {
        setState({
          ...state,
          stateName: '',
          allCities: []
        })
      }
    },
    chooseCity: (name) => {
      setState({
        ...state,
        cityName: name ? name : ''
      })
    }
  }


  return (
    <PageHeaderWrapper
      title={'Update Company'}>
      <Card bordered={true}>
        <Form className={'vertical-form'} autoComplete="off" key={state.companyId}>
          <Card bordered={true}>
            <Row gutter={16}>
              {formFields.generateInputs.map((item, key) => {
                return (
                  <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8} sm={12} xs={24} key={key}
                       style={{ height: 80 }}>
                    <GetEachFormFields
                      item={item}
                      getFieldDecorator={getFieldDecorator}
                      formItemLayout={formItemLayout}/>
                  </Col>
                )
              })}

              <Col span={24}>
                <Form.Item className={'mt20'}>
                  <Button type="primary" onClick={events.handleSubmit}>
                    SUBMIT
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Card>

        </Form>
      </Card>
    </PageHeaderWrapper>
  )
}


const mapStateToProps = ({ global, router }) => ({
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedEditCompany = Form.create()(EditCompany)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedEditCompany)


