import { Button, Drawer, InputNumber, Select, Form, Row, Col, notification } from 'antd'
import style from '../views/styles.less'
import React, { useEffect, useState } from 'react'
import { qualificationOptions } from '../../../components/_utils/appUtils'

const Option = Select
let initaleState = {
  qualification: null,
  passingYear: null,
  percentage: null,
  maths: null,
  english: null
}
const EnglishRequirement = (props) => {
  let { onClose, visible, addQualificationReq, data } = props
  let [state, setState] = useState(initaleState)

  let setExamState = (data) => {
    setState({ ...state, ...data })
  }
  useEffect(() => {
    setDefaultExamData()
  }, [])

  const setDefaultExamData = () => {
    setState({ ...data })
  }

  let addQualification = () => {
    let {
      qualification
    } = state
    if (!qualification) {
      return notification.warning({ message: 'Choose Qualification Type' })
    }
    addQualificationReq(state)
  }

  return (
    <Drawer width={800}
            title={'Qualification'} placement="right"
            visible={visible}
            onClose={onClose}>

      <div className="row">
        <div className="col-lg-12">
          <Form noValidate>
            <div className="form-box mt-4">

              <div className="card unizportal">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label={'Choose Qualification'}>
                      <Select
                        placeholder={'Choose Qualification'}
                        value={state.qualification}
                        onChange={ei => {
                          setState({
                            ...state,
                            qualification: ei,
                            passingYear: '',
                            maths: '',
                            english: '',
                            percentage: '',
                            degreeType: '',
                            major: [],
                            stream: [],
                            backlogs: ''
                          })
                        }}>
                        {qualificationOptions.map((item, index) => {
                          return (
                            <Option value={item} key={index}>{item}</Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <React.Fragment>
                    <Col span={12}>
                      <Form.Item label={'Passing year'}>
                        <InputNumber
                          placeholder={'Passing year'}
                          type={'number'}
                          maxLength={4}
                          value={state.passingYear}
                          onChange={ei => {
                            setExamState({ passingYear: ei })
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={'Percentage'}>
                        <InputNumber
                          placeholder={'Percentage'}
                          type={'number'}
                          value={state.percentage}
                          maxLength={2}
                          onChange={ei => {
                            setExamState({ percentage: ei })
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {state.qualification == 'XII' ? <React.Fragment>
                      <Col span={12}>
                        <Form.Item label={'Math Score'}>
                          <InputNumber
                            placeholder={'Math Score'}
                            type={'number'}
                            maxLength={2}

                            value={state.maths}
                            onChange={ei => {
                              setExamState({ maths: ei })
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label={'English Score'}>
                          <InputNumber
                            placeholder={'English Score'}
                            type={'number'}
                            maxLength={2}

                            value={state.english}
                            onChange={ei => {
                              setExamState({ english: ei })
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </React.Fragment> : null}
                  </React.Fragment>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        type="primary" htmlType="submit" className="btn"
                        onClick={() => addQualification()}>
                        Add
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      </div>

    </Drawer>
  )
}

export default EnglishRequirement
