import React from 'react'
import AddGicComponent from './components/addGicComponent'
import { Card } from 'antd'

const AddGicPage = () => {
  return (
    <>
      <div className='row  mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>Add GIC Request</h5>
            </div>
            <AddGicComponent />
          </div>
        </div>
      </div>
    </>
  )
}
export default AddGicPage
