import React from 'react'
import {
  Form, Button, Modal
} from 'antd'

import { showLoader, hideLoader, showPageLoad, hidePageLoad } from '../../../modules/actions'

import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import lodash from 'lodash'
import moment from 'moment'
import { updateMeetingStatus, checkMeetingToken } from '../actions/scheduleMeeting'
import { capitalize, displayDate, longDisplayDate } from '../../../components/_utils/appUtils'


const { confirm } = Modal


const timeInString = (time) => {
  return moment(time).format('h:mm a')
}

class ConfirmMeeting extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      certificate: {},
      token: '',
      profileKey: moment(),
      meeting: {}
    }
    props.dispatch(hidePageLoad())
    props.dispatch(hideLoader())
    this.checkIsLoggedIn()
  }


  async checkIsLoggedIn() {
    const { dispatch } = this.props
    let { pathname } = window.location
    let token = pathname.split('/').pop()
    let resp = await dispatch(checkMeetingToken({ token }))
    if (resp && resp.success) {
      this.setState({ token: token, meeting: resp.data })
    } else {
      alert(resp.message)
      dispatch(push('/login'))
    }
  }

  handleSubmit = async (status) => {
    let { token, meeting } = this.state
    const { dispatch } = this.props
    let resp = await dispatch(updateMeetingStatus({ meetingId: meeting._id, status }))
    if (resp && resp.success) {
      alert(resp.message)
      this.setState({
        profileKey: moment(),
        certificate: '',
        token: ''
      })
      dispatch(push('/login'))
    }
  }

  submitApproval = (status) => {
    let statusValue = status == 'Approved' ? 'Approve' : 'Reject'
    confirm({
      title: `Are you sure,  you want to ${statusValue} this Meeting?`,
      content: '',
      onOk: () => {
        this.handleSubmit(status)
      },
      onCancel() {

      }
    })
  }


  chooseProfileDocument = (e) => {
    let { name, files } = e.target
    if (files && files.length) {
      this.setState({ certificate: files[0] })
    }
  }


  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form
    const { loading } = this.props
    const { meeting } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }
    return (
      <React.Fragment>
        <div id={'loginForm'} className={'hold-transition login-main bgNone'}>
          <header className=" w-100 navbar-fix">

            <div className="container">
              <div className="d-flex flex-column flex-md-row align-items-center pt-5">
                <h5 className="my-0 mr-md-auto font-weight-normal">
                  <a href={'/home'}>
                    <img src="../dist/img/AdminLTELogo.png" alt=""/>
                  </a>
                </h5>

              </div>
            </div>

          </header>
          <div className="login-form w-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mr-auto">
                  <div className="login-box w-100">
                    <div>
                      <h5>
                        {meeting ? <div className={'meetingInfo'}>
                          {meeting.addedByUserId && meeting.addedByUserId.name ?
                            <span>{capitalize(meeting.addedByUserId.name)} </span> : null}
                          is inviting you to a scheduled a meeting.
                          <br/>
                          <div>
                            <strong>Topic : </strong> {meeting.topic}
                          </div>
                          <div>
                            <strong>Date : </strong> {displayDate(meeting.date)} {timeInString(meeting.fromTime)}
                          </div>
                          <div>
                            <strong>Duration : </strong> {meeting.duration} minutes
                          </div>

                          {meeting.types && meeting.types.length && meeting.types.includes('Physical') ?
                            <div><strong>Place : </strong>{meeting.place}</div> : null}

                        </div> : null}
                      </h5>
                      <Form>

                        <Form.Item className={'mt20'}>
                          <Button type="primary" onClick={() => this.submitApproval('Approved')}
                                  style={{ marginRight: 10 }}>
                            Approve
                          </Button>
                          <Button onClick={() => this.submitApproval('Rejected')}>
                            Reject
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const WrappedUploadCertificateForm = Form.create()(ConfirmMeeting)


const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading,
  search: global.search,
  pathname: global.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedUploadCertificateForm)
