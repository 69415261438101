export default (
  state = {
    loader: false,
    feedback: {},
    allFeedback: [],
    totalFeedback: 0
  },
  action
) => {
  switch (action.type) {
    case 'LIST_FEEDBACK':
      return { ...state, user: action.payload.data }
    case 'ADD_FEEDBACK':
      return { ...state, feedback: action.payload }
    case 'LIST_ALL_FEEDBACK':
      return { ...state, allFeedback: action.payload.data, totalFeedback: action.payload.total }
    case 'EMPTY_FEEDBACK':
      return { ...state, allFeedback: [], totalFeedback: 0 }
    default:
      return state
  }
}
