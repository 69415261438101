import {Button, Card, Col, Drawer, Form, Row, notification} from 'antd'
import React, {useEffect, useState} from 'react'
// import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import {createMatchSelector, goBack} from 'connected-react-router'
import {useDispatch, useSelector} from 'react-redux'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import GetAllFormFields from '../../../components/_utils/formUtils'

import moment from 'moment'
import {addNoteFxn, notesList} from './actions'

const AddNoteReqstTraing = props => {
    const dispatch = useDispatch()
    const [noteData, setNotes] = useState([])
    const {
        form: {getFieldDecorator},
        onClose,
        trainingRequestId,
        visible
    } = props

    useEffect(() => {
        loadNotes()
    }, [])

    const loadNotes = async (params = {}) => {
        if (trainingRequestId) {
            params.trainingRequestId = trainingRequestId
        }
        let data = await dispatch(notesList({...params}))
        setNotes(data)
    }
    let inputTypes = {
        fields: [
            {
                key: 'note',
                label: 'Enter Note',
                placeholder: 'Enter Note',
                type: 'textarea',
                span: 22,
                rows: 8
            }
        ]
    }

    const submitFormLayout = {
        wrapperCol: {
            xs: {span: 24, offset: 0},
            sm: {span: 10, offset: 7},
            md: {span: 12, offset: 8}
        }
    }
    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    const handleSubmit = async e => {
        const {form} = props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                valData.trainingRequestId = trainingRequestId
                let resp = await dispatch(addNoteFxn(valData))
                if (resp && !resp.error) {
                    onClose()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }

    return (
        <div>
            <Drawer
                visible={visible}
                onClose={onClose}
                width={'70%'}
                title={' Notes'}>
                <Card bordered={false}>
                    <Form
                        onSubmit={handleSubmit}
                        hideRequiredMark
                        className={'inputForm'}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <GetAllFormFields
                                    inputSchema={inputTypes}
                                    formItemLayout={formItemLayout}
                                    getFieldDecorator={getFieldDecorator}
                                />
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col span={24} className={'mt20'}>
                                {noteData && noteData.map((item, i) => {
                                    let {addedByUserId, note, time} = item.notes;
                                    return (
                                        <>
                                            <div key={i} className="custom_card mb10">
                                                <Card size={'small'}
                                                      bordered={true}
                                                      title={addedByUserId && addedByUserId.name ? addedByUserId.name : null}
                                                      extra={
                                                          <>
                                                              {time ? moment(time).format('DD.MM.YYYY,h:mm A') : null}
                                                          </>
                                                      }>
                                                    {note}
                                                </Card>
                                            </div>
                                        </>
                                    )
                                })}
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Drawer>
        </div>
    )
}

const UpdateStatus = Form.create()(AddNoteReqstTraing)
export default UpdateStatus
