import React, {useState} from "react"
import {Button, Drawer, notification} from "antd";
import InputBox from "../../../components/_utils/InputBox";
import {useDispatch} from "react-redux";
import {updateWithdrawalFxn} from "../actions";

const UpdateWithdrawalComponent = (props) => {
    let dispatch = useDispatch()
    let {onClose, visible, withdrawalId, status} = props;
    let [reason, setReason] = useState("");
    let submitReason = async () => {
        if (!reason) {
            notification.warning({message: "Please enter reason"})
            return
        }
        let resp = await dispatch(updateWithdrawalFxn({withdrawalId, reason, status}));
        if (resp && resp.success) {
            onClose()
        }
    }
    return (
        <Drawer
            visible={visible}
            title={`${status} withdrawal`}
            onClose={onClose}
            width={'50%'}
        >
            <div className="form-box commissionBox">
                <div className="card unizportal">
                    <InputBox title={'Reason'}>
                        <textarea
                            className={'form-control'}
                            onChange={({target}) => {
                                setReason(target.value)
                            }}
                            value={reason}>
                        </textarea>
                    </InputBox>
                    <Button
                        onClick={() => {
                            submitReason()
                        }}
                        type="primary"
                        className={`btn ${status == 'Approved' ? 'btn-success' : "btn-danger"} mt10`}>
                        {status} Commission
                    </Button>
                </div>
            </div>
        </Drawer>
    )
}
export default UpdateWithdrawalComponent
