import React, { useEffect, useState } from 'react'
import CourseListComponent from './courseListComponent'
import { getUser } from '../../users/actions/user'
import { connect, useDispatch } from 'react-redux'
import { getEvaluateLoadMoreCourse, getEvaluateOtherUniversity, getEvaluateSearchCourse } from '../action'
import { Drawer, Row, Col, Select } from 'antd'
import _ from 'lodash'
import { companySmsActivities } from '../../company/actions/company'
import { fcmtUniversity } from '../../../components/_utils/appUtils'
import FcmtAddStudent from '../../student/views/fcmtAddStudent'
import { InputBox } from '../../../components/_utils/appUtils'
import { listAllCourse } from '../../course/actions/courseAction'

const { Option } = Select

const FlairUniversity = (props) => {
  let { visible, onClose, dispatch, selectedUniversity } = props
  let [currentUser, setCurrentUser] = useState({})
  let [coursesList, setCoursesList] = useState([])
  let [courseCount, setCourseCount] = useState(0)
  let [courseId, setCourseId] = useState('')


  let events = {
    loadCourse: async () => {
      let params = {
        courseUniversity: fcmtUniversity,
        results: 1000
      }
      let { data } = await dispatch(listAllCourse(params))
      setCoursesList(data)
      // setCourseCount(courseCount)
    },
    loadMoreSearchCourse: async () => {
      let skip = coursesList.length
      let params = {
        universityId: fcmtUniversity,
        limit: 9,
        skip
      }
      let data = await dispatch(getEvaluateLoadMoreCourse(params))
      let cloneCourseList = _.clone(coursesList)
      cloneCourseList = [...cloneCourseList, ...data]
      setCoursesList(cloneCourseList)
    }
  }

  const chooseStudentFxn = (value) => {
    console.log(value, '-----------')
  }

  useEffect(() => {
    loadCurrentUser()
    // events.loadCourse()
  }, [])

  const loadCurrentUser = async () => {
    let user = (localStorage.getItem('user') != 'undefined') ? JSON.parse(localStorage.getItem('user')) : null
    let resp = await dispatch(getUser(user._id))
    setCurrentUser(resp)
  }
  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      width={'85%'}
    >
      <FcmtAddStudent
        selectedUniversity={selectedUniversity}
        // coursesList={coursesList}
        onClose={onClose} />

      {/*<CourseListComponent coursesList={coursesList} courseCount={courseCount} events={events}
                           currentUser={currentUser}/>*/}
    </Drawer>
  )

}


const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlairUniversity)