import React, { useState, useEffect, useRef } from 'react'
import { CustomRowTable } from '../../components/_utils/RowTable'
import { Drawer, Card, Skeleton, Tooltip, Icon, Table } from 'antd'
import { useDispatch } from 'react-redux'
import { countryIds, DefaultTablePagination, displayDate } from '../../components/_utils/appUtils'
import { TableComp } from 'sz-react-utils-lite'
import { ColumnWidth } from '../WebComponent/columnWidth'

const getNotes = (notes) => {
  if (notes && notes.length) {
    return (
      <ul style={{ listStyle: 'disc' }}>
        {notes.map((item, index) => {
          return (
            <li key={index}>{item}</li>
          )
        })}
      </ul>
    )
  } else {
    return null
  }
}
const getIntakes = (intakes) => {
  if (intakes && intakes.length) {
    return (
      <>
        {intakes.map((item, index) => {
          return (
            <span key={index} style={{ color: 'black' }}>{item} {index < intakes.length - 1 ? <i>/ </i> : null}</span>
          )
        })}
      </>
    )
  } else {
    return null
  }
}
const getCampus = (campusList) => {
  if (campusList && campusList.length) {
    return (
      <>
        {campusList.map((item, index) => {
          return (
            <span key={index} style={{ color: 'black' }}>{item.campusName}{index < campusList.length - 1 ?
              <i>, </i> : null}</span>
          )
        })}
      </>
    )
  } else {
    return null
  }
}
const StudentInsuranceInfoDrawer = (props) => {
  let dispatch = useDispatch()

  let { user, visible, onClose } = props

  console.log('ooooooo', user)

  let [universityData, setUniversityData] = useState({})

  let [countryId, setCountryId] = useState('')
  let [loading, setLoading] = useState(true)
  let [campusList, setCampusList] = useState([])
  let [dependentList, setDependentList] = useState([])

  let tableRef = useRef()

  useEffect(() => {
    let { user } = props
    if (user && user.dependentList) {
      setDependentList(user.dependentList)
    }
  }, [])


  let columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Middle Name',
      key: 'middleName',
      dataIndex: 'middleName'
    },
    {
      title: 'Last Name',
      key: 'lastName',
      dataIndex: 'lastName'
    },
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title'
    },
    {
      title: 'Gender',
      key: 'gender',
      dataIndex: 'gender'
    },
    {
      title: 'Date Of Birth',
      key: 'dateOfBirth',
      dataIndex: 'dateOfBirth',
      render: (item) => {
        return (
          <>{displayDate(item)}</>
        )
      }
    }

  ]

  return (
    <Drawer visible={visible}
            title={'Student Insurance Details'} width={'70%'}
            onClose={onClose}>
      <Card>

        <div className={'infoUni tableBox striped'}>
          <CustomRowTable title={'Name'} value={(
            <div>
              {user.name ? user.name : null}
            </div>
          )} />
          <CustomRowTable title={'Middle Name'} value={(
            <div>
              {user.middleName ? user.middleName : null}
            </div>
          )} />
          <CustomRowTable title={'Last Name'} value={(
            <div>
              {user.lastName ? user.lastName : null}
            </div>
          )} />
          <CustomRowTable title={'Title'} value={(
            <div>
              {user.title ? user.title : null}
            </div>
          )} />
          <CustomRowTable title={'Gender'} value={(
            <div>
              {user.gender ? user.gender : null}
            </div>
          )} />
          <CustomRowTable title={'Date Of Birth'} value={(
            <div>
              {user.dateOfBirth ? displayDate(user.dateOfBirth) : null}
            </div>
          )} />


          {user && user.partnerName && user.partnerDateOfBirth ? <>
            <CustomRowTable title={'Partner Name'} value={(
              <div>
                {user.partnerName ? user.partnerName : null}
              </div>
            )} />
            <CustomRowTable title={' Partner Middle Name'} value={(
              <div>
                {user.partnerMiddleName ? user.partnerMiddleName : null}
              </div>
            )} />
            <CustomRowTable title={'Partner Last Name'} value={(
              <div>
                {user.partnerLastName ? user.partnerLastName : null}
              </div>
            )} />
            <CustomRowTable title={'Partner Title'} value={(
              <div>
                {user.partnerTitle ? user.partnerTitle : null}
              </div>
            )} />
            <CustomRowTable title={'Partner Gender'} value={(
              <div>
                {user.partnerGender ? user.partnerGender : null}
              </div>
            )} />
            <CustomRowTable title={' Partner Date Of Birth'} value={(
              <div>
                {user.partnerDateOfBirth ? displayDate(user.partnerDateOfBirth) : null}
              </div>
            )} /> </> : null}


          <CustomRowTable title={'Product'} value={(
            <div>
              {user && user.product ? user.product : null}
            </div>
          )} />
          <CustomRowTable title={'Insurance Cover For'} value={(
            <div>
              {user && user.insuranceCoverFor ? user.insuranceCoverFor : null}
            </div>
          )} />
          <CustomRowTable title={'Preferred Language'} value={(
            <div>
              {user && user.preferredLanguage ? user.preferredLanguage : null}
            </div>
          )} />
          <CustomRowTable title={'Student Email'} value={(
            <div>
              {user && user.studentEmail ? user.studentEmail : null}
            </div>
          )} />
          <CustomRowTable title={'Mobile'} value={(
            <div>
              {user && user.mobile ? user.mobile : null}
            </div>
          )} />
          <CustomRowTable title={'Currently In Australia'} value={(
            <div>
              {user && user.currentlyInAustralia ? user.currentlyInAustralia : null}
            </div>
          )} />
          <CustomRowTable title={'Suburb'} value={(
            <div>
              {user && user.suburb ? user.suburb : null}
            </div>
          )} />
          <CustomRowTable title={'PostCode'} value={(
            <div>
              {user && user.postCode ? user.postCode : null}
            </div>
          )} />
          <CustomRowTable title={'State'} value={(
            <div>
              {user && user.state ? user.state : null}
            </div>
          )} />
          <CustomRowTable title={'Visa Start Date'} value={(
            <div>
              {user && user.visaStartDate ? displayDate(user.visaStartDate) : null}
            </div>
          )} />
          <CustomRowTable title={'Visa End Date'} value={(
            <div>
              {user && user.visaEndDate ? displayDate(user.visaEndDate) : null}
            </div>
          )} />
          <CustomRowTable title={'Passport Number'} value={(
            <div>
              {user && user.passportNumber ? user.passportNumber : null}
            </div>
          )} />
          <CustomRowTable title={'visa Type'} value={(
            <div>
              {user && user.visaType ? user.visaType : null}
            </div>
          )} />
          {user.dependentList && user.dependentList.length ?
            <CustomRowTable title={'Depended List'} value={(
              <div>
                <Table columns={columns}
                       ref={tableRef}
                       bordered={true}
                       pagination={false}
                       dataSource={user.dependentList}
                  // extraProps={{ scroll: { x: 1000 } }}
                />
              </div>
            )} /> : null}

        </div>
      </Card>
    </Drawer>
  )
}


export { StudentInsuranceInfoDrawer }
