import React, { useState, useEffect } from 'react'
import { Tooltip, Popconfirm, notification, Modal, Input, Button } from 'antd'
import '../../../less/contract.less'
import {
  advanceOtpVerificationFxn,
  getUser2,
  sendAdvanceCommissionOtpFxn, singleUserRightFxn
} from '../actions/user'
import { connect, useDispatch } from 'react-redux'
import { InputBox, displayDate } from '../../../components/_utils/appUtils'
import moment from 'moment'
import AdvanceCommissionRequest from './components/advanceCommissionRequest'

const AdvanceCommissionContract = props => {
  let { currentUserRights } = props
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  let [userData, setUserData] = useState({})
  let [rights, setRights] = useState({})
  let [otpModalVisible, setOtpModalVisible] = useState(false)
  let [otp, setOtp] = useState('')
  let [reqState, setReqState] = useState({ visible: false })
  let dispatch = useDispatch()
  useEffect(() => {
    events.loadData()
    events.singleUser()
  }, [])

  const events = {
    loadData: async () => {
      let { data } = await dispatch(singleUserRightFxn({ userId: user._id }))
      setRights(data)
    },
    singleUser: async () => {
      let resp = await dispatch(getUser2({ userId: user._id }))
      setUserData(resp)
    },
    showReqMessage: () => {
      setReqState({
        visible: true
      })
    },
    hideReqMessage: () => {
      setReqState({
        visible: false
      })
    },
    downloadContract: () => {
      if (userData && userData.advanceCommissionContract && userData.advanceCommissionContract.url) {
        window.open(userData.advanceCommissionContract.url, 'download')
      }
    }
  }

  const eventFxn = {
    handleSignClick: async () => {
      if (userData.contractSigned) {
        notification.info({ message: 'Contract already signed' })
        return
      }
      if (rights && rights.showAdvanceContract) {
        const response = await dispatch(sendAdvanceCommissionOtpFxn({ userId: user._id }))
        if (response.success) {
          setOtpModalVisible(true)
        }
      } else {
        events.showReqMessage()
      }
    },
    handleOtpVerification: async () => {
      const { success } = await dispatch(advanceOtpVerificationFxn({ otp: otp }))
      if (success) {
        setOtp('')
        events.singleUser()
        events.loadData()

        setOtpModalVisible(false)
      } else {
        setOtp('')
      }
    }
  }


  const currentDate = moment()
  return (
    <div>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='card-body table-responsive aggreementCard'>
              <h5 className='header1 mt50'>Advance Commission Contract </h5>
              <p className='mt50'>Effective
                Date: {userData && userData.contractDate ? displayDate(userData.contractDate) : displayDate(currentDate)}</p>
              <p>Scope: Worldwide</p>
              <p>
                Greetings from the UnizPortal. Before using the UnizPortal
                Platform, which includes the UnizPortal URL www.UnizPortal.com
                and any other URL’s or links owned and/or operated by UnizPortal
                (herein collectively referred as "Platform"), please read the
                terms and conditions (the "Terms and Conditions") and the
                privacy policy (the "Privacy Policy") accessible via the
                following URL https://www.UnizPortal.com/privacy-policy. These
                terms and conditions govern your use of the UnizPortal Platform
                (as defined above), whether it be on your own behalf or the
                behalf of any Entity, Student, School, Agent, or Organisation
                that you may represent.
              </p>
              <p>
                By accessing or login the UnizPortal Platform, clicking
                “Accept”, or otherwise signing an order form or admission form
                or agent form and/or agreement that incorporates these Terms and
                Conditions by reference, acknowledge that you are entering into
                an agreement with UnizPortal (“UnizPortal”, “us”, “we”, “our”,
                together with you, the “Parties” and each of you or us, a
                “Party”), under which you, on your own behalf or on behalf of
                the entity, student, school, agent or organization that you may
                represent, agree to be bound by, and comply with, these Terms
                and Conditions, including, but not limited to, as applicable to
                the user (as defined herein), any Recruitment
                Partner/Agent/Consultant/Counsellor Services Agreement (as
                defined herein), UnizPortal Partner Agreement (as defined
                herein), or other agreement between you and us, whether verbal
                or in writing, as well as the policies, terms and other
                documents referenced herein (collectively, the “Agreement”), as
                may be updated from time to time. You must stop using or
                accessing the UnizPortal Platform if you do not agree to be
                bound by these Terms and Conditions or any other agreements or
                paperwork included in them.
              </p>
              <p>
                Any Recruitment Partner/Counsellor Services Agreement or
                UnizPortal Partner Agreement that conflicts with these Terms and
                Conditions shall, to the extent of any such conflict, be
                superseded by the Recruitment Partner/Counsellor Services
                Agreement or UnizPortal Partner Agreement. If there is any
                inconsistency between these Terms and Conditions and any other
                agreement or understanding between you and us, this Agreement
                shall take precedence to the extent of any conflict.{' '}
              </p>
              <p>
                By accepting this Agreement, you certify that you are of legal
                age in your jurisdiction or country, have the mental capacity to
                engage into binding contracts, and that all of the information
                you have provided to us is true, accurate, current, and
                comprehensive. You warrant that you are authorised to bind that
                person, student, school or entity to these Terms and Conditions
                if you are using the UnizPortal Platform on their behalf.{' '}
              </p>

              <h5>
                <strong>1. DEFINITIONS</strong>
              </h5>
              <p>In these Terms and Conditions:</p>
              <p className='agreement-list'>
                <li>
                  "Applicable Law" refers to any rule, statute, subordinate
                  legislation, regulation, by-law, order, ordinance, protocol,
                  code, guideline, treaty, policy, notice, direction or
                  judicial, arbitral, administrative, ministerial or
                  departmental judgement, award, decree, treaty, directive, or
                  other requirement or guideline published or in force at any
                  time during the term of this Agreement that applies to or is
                  otherwise intended to govern or regulate any party hereto.
                </li>
                <br />
                <li>
                  "Application" refers to a formal request made by a student,
                  agent, or authorised representative to a school they wish to
                  enrol in through UnizPortal, together with the necessary
                  papers and supporting materials.
                </li>
                <br />
                <li>
                  "UnizPortal Partner" refers to any school, business,
                  association, or person that has partnered with UnizPortal in
                  order to use or promote the UnizPortal Platform or to make it
                  possible for their staff or students to do so.
                </li>
                <br />
                "UnizPortal Partner Agreement" refers to the executed contract
                between a UnizPortal Partner, Agent, or Authorised
                Representative and UnizPortal, including any schedules,
                exhibits, or terms and conditions attached thereto, under which
                UnizPortal will offer the UnizPortal Partner
                national/international student recruitment, admission, or
                education services
                <li>
                  "Platform" refers to an online user interface, which might be
                  software, a website/URL or a section of a website/URL, or
                  applications, including mobile applications.
                </li><br />
                <li>
                  "UnizPortal Platform" refers to the UnizPortal Student
                  Recruitment Platform, as well as all Services offered
                  therefrom, and accessible by you (whether you are a
                  Recruitment Partner, Counsellor,Agent, UnizPortal Partner,
                  Student, Third-Party Provider, or other user). It is designed
                  to make the process of finding and applying for study abroad
                  programmes easier by bringing together international students,
                  Recruitment Partners, Counsellors, and UnizPortal Partners.
                </li>
                <br />
                <li>
                  "Confidential Information" refers to information that
                  UnizPortal deems to be confidential or proprietary, such as
                  the UnizPortal Platform, its business plan and strategy, any
                  design, prototype, compilation of information, data,
                  programme, method, invention, licence, technique, or process,
                  information relating to any UnizPortal service, software,
                  UnizPortal Website and web platform, client and user
                  information, financial information, marketing information,
                  intellectual property, business opportunities, or research and
                  development.
                </li>
                <br />
                <li>
                  "Content" refers to all information, materials, and other
                  content on or made available to you by UnizPortal through the
                  UnizPortal Platform, including but not limited to all designs,
                  graphics, pictures, illustrations, software, systems,
                  workflows, artwork, videos, music, sounds, names, words,
                  titles, and phrases, logos, trade names, and service marks.
                </li>
                <br />
                <li>
                  "Counsellor" refers to a person who is independent of a
                  business and assists prospective students with their
                  international school applications and immigration (depending
                  on their country of residence). They offer guidance,
                  assistance, and logistical support to students who want to
                  study abroad. They must sign a Recruitment Partner/Counsellor
                  Services Agreement before working with us.
                </li>
                <br />
                <li>
                  "Governmental or Regulatory Authority" refers to any national,
                  provincial, territorial, state, country, municipal,
                  quasi-governmental or self-regulatory department, authority,
                  organisation, agency, commission, board, tribunal, dispute
                  settlement panel or body, bureau, official, minister, Crown
                  corporation, or court, as well as any other law-making body
                  with or claiming to have jurisdiction over UnizPortal, you, or
                  any other person, thing, activity, transaction, or thing of
                  value.
                </li>
                <br />
                <li>
                  “Party” means UnizPortal or you, and “Parties” means
                  UnizPortal and you collectively.
                </li>
                <br />
                <li>
                  "Personal Information" refers to data that can be used alone
                  or in conjunction with other data to uniquely identify a
                  person.
                </li>
                <br />
                <li>
                  “Program or Programme" refers to any academic programme or
                  programme specifically created to help international students
                  transition (sometimes referred to as a pathway programme),
                  made available by a School or Third-Party Provider. It also
                  includes any study programme, language programme, summer camp,
                  or other similar offering.
                </li>
                <br />
                <li>
                  "Recruitment Partner" refers to a member of a staff at a
                  company that assists prospective students with their
                  international school applications and immigration (based on
                  their country of residence). For students who want to study
                  abroad, they offer guidance, assistance, and logistical
                  support. They must consent to our terms of engagement by
                  signing a Recruitment Partner/Counsellor Services Agreement.
                </li>
                <br />
                <li>
                  "Recruitment Partner/Counsellor Services Agreement" refers to
                  the executed agreement, including terms and conditions,
                  between a Recruitment Partner or Counsellor and UnizPortal,
                  including any terms and conditions, schedules, or exhibits
                  attached thereto, by which the Recruitment Partner or
                  Counsellor will provide international-student recruitment
                  services for UnizPortal and the UnizPortal Partners.
                </li>
                <br />
                <li>
                  “School/College/University” means any institution which
                  educates attendees.
                </li>
                <br />
                <li>
                  "Services" refers to all of our web-based tools, platforms,
                  functionality, and/or offerings that we make available to you
                  via the UnizPortal Platform or that you have subscribed to
                  through a written contract with us, and which are created,
                  maintained, and made available to you via
                  http://UnizPortal.com or another designated URL, as well as
                  any supplemental goods and services that we offer to you.
                </li>
                <br />
                <li>
                  “Student” means a person searching for additional educational
                  opportunities using the UnizPortal Platform directly to
                  research and apply, or represented by a Recruitment Partner or
                  Counsellor using the UnizPortal Platform to submit
                  Applications.
                </li>
                <br />
                <li>
                  “Third-Party Provider” means a company contracted by a School
                  to provide language and sometimes other educational modules.
                </li>
                <br />
                <li>
                  “User” or “user” means the user of the UnizPortal Platform,
                  including, but not limited to, all employees, representatives,
                  students, consultants, contractors or agents who are
                  authorized to use the UnizPortal Platform.
                </li>
                <br />
                <li>
                  “You” or “your” means the person or entity accessing the
                  UnizPortal Platform and/or receiving the Services.
                </li>
                <br />
              </p>
              <h5>
                <strong>2. APPLICATION OF THE TERMS AND CONDITIONS </strong>
              </h5>
              <p>
                The UnizPortal Platform and the Services made available through
                it are accessible to you under the terms and conditions set
                forth below. The UnizPortal Platform shall be made accessible
                for your use in accordance with any documentation or
                instructions we make available to you, subject to your
                compliance with these Terms and Conditions.
              </p>

              <h5>
                <strong>
                  3. CHANGES TO THE TERMS AND CONDITIONS AND THE UNIZPORTAL
                  PLATFORM{' '}
                </strong>
              </h5>
              <p>
                The UnizPortal Platform will be updated whenever these terms and
                conditions are modified, unless forbidden by applicable law. You
                are in charge of keeping an eye out for and reviewing any
                updates to the UnizPortal Platform. After any modifications to
                these Terms and Conditions are made, your continuing access to
                or use of the UnizPortal Platform will be deemed your acceptance
                of those modifications. In a similar vein, we reserve the right
                to alter, suspend, or terminate the UnizPortal Platform at any
                time, including the accessibility of any Content, without prior
                notice, including (i) for scheduled maintenance; (ii) in the
                event that you disobey any provision of these Terms and
                Conditions; or (iii) to address any urgent security issues.
              </p>

              <h5>
                <strong> 4. REGISTRATION AND ACCOUNT INFORMATION </strong>
              </h5>
              <p>
                You might need to sign up for a user account using the
                UnizPortal Platform, receive an access key, or receive special
                permission from the UnizPortal Platform as outlined in a written
                agreement between the parties in order to access or use the
                UnizPortal Platform. You consent to filling out this
                registration form completely, truthfully, and up to date, and to
                amend it as needed. We reserve the right to accept or reject
                your registration, request for access, or authorization for the
                UnizPortal Platform and may suspend or terminate your account
                for any reason. We also reserve the right to suspend or
                terminate your account, access, or authorization if we have
                reason to believe your information is inaccurate, out-of-date,
                or incomplete. You are in charge of keeping your password,
                account, and access key private (collectively, "Access
                Information"), and we are not liable for any acts that third
                parties may take using your Access Information.{' '}
              </p>

              <h5>
                <strong>5. LICENSE TO USE THE UNIZPORTAL PLATFORM</strong>
              </h5>
              <p>
                We hereby grant you a personal, non-exclusive, revocable,
                non-transferable licence to access the UnizPortal Platform
                solely for the purposes of accessing or using the Services and
                Content made available to you, subject to all terms and
                conditions in the Agreement and any limitations contained in the
                Content.{' '}
              </p>

              <h5>
                <strong>6. DATA, ITS TERM, HANDLING AND STORING </strong>{' '}
              </h5>
              <p>
                You acknowledge that by accessing or using the Platform or
                Services of UnizPortal, your data may be gathered for a variety
                of purposes, and that any information you entrust to UnizPortal
                will be handled in line with its Privacy Policy. At all times,
                UnizPortal retains the right to share any personal data as it
                believes necessary to comply with any laws, court orders, or
                governmental or regulatory authority regulations. UnizPortal may
                gather the information you share with it in a variety of ways.
                Depending on how you use UnizPortal's platform and services, any
                time UnizPortal may request to collect, use, disclose, keep, or
                destroy your personal information. Prior to gathering your
                personal information, UnizPortal will inform you of why we
                require that information from you and obtain your permission.
                The terms of this Agreement and UnizPortal's Privacy Policy
                cover the uses for which your Personal Information will be
                collected. When UnizPortal obtains information about you from a
                third party, UnizPortal relies on the third party to clearly
                inform you of the purposes for which UnizPortal will use your
                data and to get all necessary consents and permissions before
                obtaining your information and disclosing it to UnizPortal.
              </p>
              <p className='terms-list'>
                <strong>Terms for Specific Cases</strong> <br />
                <p>
                  <li>
                    If you are a visitor to UnizPortal's Platform, UnizPortal
                    may ask for your permission to collect cookies and other
                    metadata for account management, analytics, and third-party
                    monitoring.
                  </li>
                  <br />
                  <li>
                    If you are a student using the UnizPortal platform to
                    process an application, UnizPortal may, with your consent,
                    ask for additional personal information to complete your
                    application, such as, but not limited to, your name, contact
                    information, citizenship and legal status, gender, date of
                    birth, home and mailing addresses, marital status, emergency
                    contact information, financial situation, educational
                    background, employment history, and transcripts.
                  </li>
                  <br />
                  <li>
                    If you are a Recruitment Partner, a member of a School, or a
                    UnizPortal Partner using the UnizPortal Platform and/or
                    Services to manage Students and Student Applications,
                    UnizPortal may, with your consent, request additional
                    Personal Information for the purposes of research analytics
                    and account management.
                  </li>
                  <br />
                </p>
              </p>
              <p>
                <strong> Keeping data complete and accurate </strong> <br />
                <p>
                  <li>
                    {' '}
                    As a Student, Recruitment Partner, member of a School,
                    UnizPortal Partner, or as another user of the UnizPortal
                    Platform and/or Services, you may be asked to submit
                    pertinent Personal Information required for you to access
                    the UnizPortal Platform and the Services provided.
                  </li>
                  <br />{' '}
                  <li>
                    {' '}
                    You promise, guarantee, and represent that all Personal
                    Information and other data you supply to the UnizPortal
                    Platform or otherwise provide to UnizPortal at the time of
                    provision/submission is true, accurate, current, and
                    complete.{' '}
                  </li>
                  <br />
                  <li>
                    {' '}
                    By using the Platform and/or Services offered by UnizPortal,
                    you agree that you are responsible for providing and
                    maintaining current, true, complete, and accurate
                    information, including but not limited to that which is
                    required by UnizPortal to: - Process a Student Application
                    to an UnizPortal Partner or a School, - Support the
                    management of Students as a Recruitment Partner, or - To
                    manage Student Applications as a member of an UnizPortal
                    Partner or a School.
                  </li>
                  <br />{' '}
                  <li>
                    {' '}
                    The UnizPortal Platform and Services include several
                    sections where money is required. You must supply current,
                    comprehensive, and accurate payment information whenever
                    UnizPortal or UnizPortal's payment processor requests it.
                  </li>
                  <br />
                </p>
              </p>

              <p>
                <strong> Owning your data </strong> <br />
                <p>
                  <li>
                    {' '}
                    Any data that UnizPortal gathers about you remains your
                    property. If necessary, you can ask UnizPortal to update,
                    grant access to, or remove your data. For more details and
                    directions on how to do this, please refer to UnizPortal's
                    Privacy Policy.{' '}
                  </li>
                  <br />
                  <li>
                    {' '}
                    You acknowledge that your information may be examined and
                    shared with UnizPortal personnel, UnizPortal Partners, or
                    other third parties by visiting or using the UnizPortal
                    Platform or Services. In specific circumstances, and with
                    your permission when necessary, UnizPortal may share your
                    data with regulatory authorities, government agencies,
                    organisations that administer international language tests,
                    professional bodies, service providers to UnizPortal
                    (including but not limited to our cloud service providers
                    and/or third party contractors), and visa agencies.{' '}
                  </li>
                  <br />
                </p>
              </p>
              <p>
                <strong>Storing your data</strong>
                <br />
                <li>
                  {' '}
                  You acknowledge that any information we may gather about you
                  while you are accessing or using the UnizPortal Platform and
                  Services may be stored on servers located outside of Canada.
                  Therefore, any information kept on such servers during your
                  use of UnizPortal's Platform and Services may be subject to
                  applicable laws of the country where those servers are located
                  or to the terms and conditions governing the hosting of data
                  using third-party cloud service providers.
                </li>
                <br />
              </p>

              <h5>
                {' '}
                <strong>7. DE-IDENTIFIED DATA </strong>
              </h5>
              <p>
                Subject to UnizPortal's Privacy Policy, UnizPortal may also use
                your data and any information regarding your use of the
                UnizPortal Platform for UnizPortal's business purposes, such as
                enhancing and fixing the UnizPortal Platform, performing
                analytics, and marketing the UnizPortal Platform to third
                parties ("De-Identified Data"). Any and all intellectual
                property rights in the results of UnizPortal's usage of any such
                De-identified Data shall be owned by UnizPortal.
              </p>

              <h5>
                {' '}
                <strong>8. ADVANCE COMMISSIONS/COMMISSIONS</strong>{' '}
              </h5>
              <p>
                UnizPortal is introducing the Advance Commission Structure for
                its Partners/Agents subject to the release of PPR of Students
                introduced by the Partner/Agent. UnizPortal announces 50%
                Advance Commission to its Agents on PPR Release & Balance 50%
                Payment upon the Receipt of Commission from the
                University/College to UnizPortal.
              </p>
              <p>
                However, UnizPortal reserves the right to Adjust/Recover the
                Advance Commission paid to its Agents/Partners if Student does
                not Enroll/ Attend the Classes/Runner or any of the case
                UnizPortal does not receive the Commission from
                University/College.
              </p>

              <h5>
                <strong>9. REPRESENTATIONS AND WARRANTIES </strong>
              </h5>
              <p>
                You covenant, represent and warrant that:
                <p>
                  <li>
                    you promise not to let anyone else use your UnizPortal
                    account, your Access Information, or any other method to
                    access the UnizPortal Platform unless they are you or an
                    authorised representative;
                  </li>
                  <br />
                  <li>
                    you may only utilise the UnizPortal Platform in a manner
                    consistent with this Agreement and all Applicable Laws;
                  </li>
                  <br />
                  <li>
                    {' '}
                    you shall abide by all applicable intellectual property laws
                    when using the UnizPortal Platform, and will not violate
                    anybody else's intellectual property rights in any way;
                  </li>
                  <br />
                  <li>
                    {' '}
                    your information is genuine, accurate, current, and
                    complete, and you promise to update it on the UnizPortal
                    Platform or tell us immediately if it changes;
                  </li>
                  <br />
                  <li>
                    you alone will be in charge of any actions involving the
                    UnizPortal Platform taken by you or someone you designate;
                  </li>
                  <br />
                  <li>
                    you won't utilise the UnizPortal Platform to benefit from or
                    offer commercial services to anybody else;
                  </li>
                  <br />
                  <li>
                    you are authorised to use the UnizPortal Platform, enter
                    into this Agreement, grant the rights and licences referred
                    to in this Agreement, post or upload any Content there, and
                    otherwise give us access to that Content and your data;
                  </li>
                  <br />
                  <li>
                    you possess the required legal authorizations to use the
                    UnizPortal Platform, such as the authority to access the
                    Content and submit data;
                  </li>
                  <br />
                  <li>
                    you are not included on any list of forbidden or restricted
                    parties maintained by a governmental or regulatory
                    authority;
                  </li>
                  <br />
                  <li>
                    you acknowledge that using any third-party data in
                    combination with the UnizPortal Platform requires obtaining
                    the applicable permissions from all relevant third parties;
                  </li>
                  <br />
                  <li>
                    you agree to take all necessary precautions to ensure that
                    your use of the UnizPortal Platform does not interfere with,
                    negatively affect, or degrade any software, system, network,
                    or data used by anyone, including UnizPortal and other users
                    of the UnizPortal Platform. These precautions include taking
                    care to avoid uploading any viruses or other harmful code
                    while using the UnizPortal Platform or placing an excessive
                    burden on the CPUs, servers, or other resources used to
                    provide the UnizPortal Platform;
                  </li>
                  <br />
                  <li>
                    you will not in any way make use of the UnizPortal Platform
                    to engage in illegal activity, attempt to engage in illegal
                    activity, or assist in the commission of any illegal
                    activity or other unlawful or tortious act, including any
                    infringement of another party's intellectual property,
                    fraud, impersonation, or violation of their privacy;
                  </li>
                  <br />
                  <li>
                    you will not interfere with or in any manner compromise any
                    of our security measures;
                  </li>
                  <br />
                  <li>
                    you will not change, modify, remove, interfere with, or
                    compromise in any way any features or content available
                    through the UnizPortal Platform, including the forms,
                    content delivery, or display functions of the UnizPortal
                    Website;
                  </li>
                  <br />
                  <li>
                    you will assist us and any governmental or regulatory
                    authorities in investigating possible violations of this
                    Agreement or Applicable Law by cooperating with us and
                    providing any information we deem necessary; and
                  </li>
                  <br />
                  <li>
                    you agree to abide by the terms and conditions of any
                    agreements you have with any third party (including the
                    UnizPortal Partners, the service providers for any Internet
                    services you use in conjunction with the UnizPortal
                    Platform, and the hardware providers for any mobile device
                    or other computing equipment through which you use the
                    UnizPortal Platform), as well as any additional limitations
                    imposed by such agreements regarding your use of the
                    UnizPortal.
                  </li>
                  <br />
                </p>
              </p>
              <h5>
                <strong>
                  10. RESTRICTIONS AND LIMITATIONS ON USE OF THE UNIZPORTAL
                  PLATFORM
                </strong>
              </h5>
              <p>
                <strong>Usage Policies</strong>
                <br />
                <p>
                  <li>
                    The usage guidelines that we occasionally communicate to
                    you, including in electronic form by posting on the
                    UnizPortal Platform, will apply to the UnizPortal Platform.
                    Such rules may specify equipment and/or software
                    specifications, as well as restrictions on data storage
                    space. It is exclusively your responsibility to ensure that
                    these policies are followed.
                  </li>
                  <br />
                </p>
                <p>
                  <strong>Equipment</strong>
                  <br />
                  <p>
                    <li>
                      Except for our computer systems and networks, you are
                      solely responsible for the selection, implementation,
                      installation, maintenance, and operation of all hardware,
                      software, and services used in conjunction with the
                      UnizPortal Platform, including the selection and use of
                      your Internet service provider.{' '}
                    </li>
                    <br />
                  </p>
                </p>
                <p>
                  <strong>Data Integrity and Back-up</strong>
                  <br />
                  <p>
                    <li>
                      {' '}
                      You understand and accept that we are unable to guarantee
                      the accuracy of any data, and that it is your exclusive
                      duty to back up any data you use in combination with the
                      UnizPortal Platform.{' '}
                    </li>
                    <br />
                  </p>
                </p>
                <p>
                  <strong>Not for Time Sensitive Applications</strong>
                  <br />
                  <p>
                    <li>
                      {' '}
                      The UnizPortal Platform was not created or granted a
                      licence to be used in any way that is innately risky,
                      time-sensitive, or mission-critical.{' '}
                    </li>
                    <br />
                  </p>
                </p>
              </p>
              <p>
                <strong>Restrictions on use of the UnizPortal Platform</strong>
                <br />
                <p>
                  <li>
                    You will not, and will not authorize, permit or otherwise
                    acquiesce anyone else to:
                    <p>
                      <li>
                        duplicate, reproduce, edit, improve, alter, reverse
                        engineer, break down into component parts, translate,
                        decrypt, reverse compile, or otherwise make the
                        UnizPortal Platform or any portion thereof human
                        readable;
                      </li>
                      <br />
                      <li>
                        offer access to the UnizPortal Platform, in whole or in
                        part, temporarily or permanently, to any third party by
                        distribution, assignment, licence, sublicense, leasing,
                        renting, transfer, sale, or other means.;
                      </li>
                      <br />
                      <li>
                        eliminate any copyright notices, deface, conceal, or
                        otherwise obfuscate any proprietary rights notice or
                        identification on the content;
                      </li>
                      <br />
                      <li>
                        disrupt, disable, overburden, degrade, or otherwise
                        damage our servers or networks;
                      </li>
                      <br />
                      <li>
                        attempting to get access to any UnizPortal Platform
                        component without authorization;{' '}
                      </li>
                      <br />
                      <li>
                        share, transfer or otherwise provide access to your
                        Access Information to another person (other than, in the
                        case where you are an organization, personnel using your
                        Access Information on your behalf);{' '}
                      </li>
                      <br />
                      <li>
                        {' '}
                        use the UnizPortal Platform in the development of a
                        comparable or rival commodity or service;
                      </li>
                      <br />
                      <li>
                        utilise the UnizPortal Platform in any way that is
                        against the UnizPortal Platform's use guidelines;
                      </li>
                      <br />
                      <li>
                        {' '}
                        integrate any portion of the UnizPortal Platform or the
                        Content in a "frame," "mirror," or another manner;
                      </li>
                      <br />
                      <li>
                        make an attempt to breach any security measures put in
                        place to protect the UnizPortal Platform, any
                        communication made or initiated through it, or any data,
                        programming, or content transmitted, processed, or
                        stored by UnizPortal or another user of the UnizPortal
                        Platform;
                      </li>
                      <br />
                      <li>
                        gather any data or communications regarding UnizPortal
                        Platform users by tracking, thwarting, or intercepting
                        any communication or process started by the UnizPortal
                        Platform, or by creating or utilising any software or
                        other process or method that engages in or aids in any
                        of the aforementioned;
                      </li>
                      <br />
                      <li>
                        upload or otherwise communicate on the UnizPortal
                        Platform any data, information, Content or other
                        materials that: (a) contain any computer viruses, worms,
                        malicious code, or any software intended to damage or
                        alter a computer system or data; (b) you do not have the
                        lawful right to upload, transfer or otherwise
                        communicate; (c) is false, intentionally misleading, or
                        impersonates any other person; (d) is bullying,
                        harassing, abusive, or otherwise offensive, or that
                        contains pornography, nudity, or graphic or gratuitous
                        violence, or that promotes violence, racism,
                        discrimination, bigotry, hatred, or physical harm of any
                        kind against any group or individual; (e) infringes,
                        violates or otherwise misappropriates the intellectual
                        property or other rights of any third party; or (f)
                        encourages any conduct that may violate any Applicable
                        Laws or would give rise to civil or criminal liability.{' '}
                      </li>
                      <br />
                      <li>
                        employ any bot, spider, virus, timer, counter, worm,
                        software lock, drop dead device, packetsniffer,
                        Trojan-horse routing, trap door, time bomb, or other
                        codes or instructions that are intended to be used to
                        provide a means of covert or unauthorised access to the
                        UnizPortal Platform or any computer system or that are
                        intended to monitor, distort, delete, damage, or
                        disassemble the UnizPortal Platform or its capacity for
                        communication and performing the Services;{' '}
                      </li>
                      <br />
                      <li>
                        act in any way that could, in our opinion, place an
                        excessive or disproportionately high strain on the
                        UnizPortal Platform (including the CPUs, servers, and
                        other resources utilised to deliver the UnizPortal
                        Platform);
                      </li>
                      <br />
                      <li>
                        employ any data mining, robotic data collection or
                        extraction techniques, or duplicate, modify, reverse
                        engineer, reverse assemble, disassemble, or decompile
                        the UnizPortal Platform or any component thereof, or
                        seek to find any source code in any other way;{' '}
                      </li>
                      <br />
                      <li>
                        deep-link to any portion of the UnizPortal Platform for
                        any purpose;{' '}
                      </li>
                      <br />
                      <li>
                        alter the UnizPortal Platform or the Content in any way
                        that would remove, cover, deface, or otherwise hide any
                        watermarks, labels, or other proprietary or legal
                        notices.;{' '}
                      </li>
                      <br />
                      <li>
                        make an effort to change, edit, delete, or otherwise
                        compromise any of the features or Content available
                        through the UnizPortal Platform, including its forms and
                        content delivery and display functionality;
                      </li>
                      <br />
                      <li>
                        produce derivative works based in whole or in part on
                        the UnizPortal Platform or the Content;{' '}
                      </li>
                      <br />
                      <li>
                        use the UnizPortal Platform in a manner that violates
                        any third party's rights, including intellectual
                        property rights, or to commit, attempt to commit, or
                        assist in the commission of any crime (including money
                        laundering), other illegal or tortious act, fraud, or
                        deceptive impersonation;
                      </li>
                      <br />
                      <li>
                        upload to or transmit over the UnizPortal Platform any
                        Content or submission that we consider in our sole
                        discretion to be offensive, hateful, obscene,
                        defamatory, or in violation of any relevant laws; or
                      </li>
                      <br />
                      <li>
                        make an effort to tamper with, avoid, shut down, defeat,
                        or otherwise compromise any of our security features or
                        components, including those that guard the UnizPortal
                        Platform, the Content, the code, or the communications
                        sent, processed, or saved by us or other UnizPortal
                        Platform users
                      </li>
                      <br />

                    </p>
                  </li>
                  <br />
                </p>
              </p>
              <h5>
                <strong>
                  11. RIGHT TO EDIT AND REMOVE DATA; SUSPENSION OR TERMINATION;
                  INVESTIGATIONS
                </strong>
              </h5>
              <p>
                <strong>Right to Edit and Remove Your Data</strong>
                <br />
                Despite anything to the contrary in this Agreement, we reserve
                the right to examine your data kept in files or programmes on
                our systems to ensure that you are abiding by it. Any of your
                information that we deem to be illegal, offensive, abusive, or
                otherwise objectionable may be edited or removed at our sole
                discretion.
              </p>
              <p>
                <strong>Suspension or Termination</strong>
                <br />
                Without incurring any liability, we have the right to place
                restrictions on the UnizPortal Platform, as well as to suspend
                or restrict your access to some or all of it. Where possible, we
                will give you advance notice of any such modifications,
                restrictions, or discontinuance; nevertheless, you realise that
                this may not always be possible, and we will not be held
                responsible for our failure to do so. </p><p>
              In the event that you engage in any abusive behaviour or
              fraudulent use of the UnizPortal Platform, we reserve the right
              to terminate your access to the platform temporarily or
              permanently. We may also do so if we determine that your use of
              the platform poses a risk to our systems, networks, files,
              materials, or other data, or that it violates this Agreement.{' '}
            </p><p>
              If you violate this Agreement or the documents it incorporates
              by reference, if we are unable to verify or authenticate any
              information you provide, or if we believe your actions may put
              you, other users, or us in danger of financial loss or legal
              liability, we may restrict your activity, issue a warning,
              temporarily suspend, permanently suspend, or terminate your
              account and refuse to provide UnizPortal Platform to you. The
              aforementioned activities are not our only options, and we are
              free to pursue any alternative legal, equitable, or technical
              course of action that seems sensible given the situation.
              Regarding the termination of this Agreement for any reason, we
              won't be liable to you or any other parties.
            </p>
              <p>
                <strong>Investigations</strong>
                <br />
                We retain the right to look into alleged infractions of this
                Agreement or to examine Your Data in order to confirm your
                adherence to this Agreement. </p><p>
              In order to help us and/or any Governmental or Regulatory
              Authority in any inquiry or assessment of whether there has been
              a breach of this Agreement or Applicable Law, you undertake to
              work with us and supply the information that we seek. </p><p>
              In order for us to be able to enforce these Terms and
              Conditions, you agree to cooperate with the following parties:
              (A) any Governmental or Regulatory Authorities in the
              investigation of suspected criminal violations; (B) as a Student
              user, UnizPortal Partners investigating academic fraud or other
              wrongdoings, related to your Application or otherwise; and (C)
              system administrators at Internet service providers, networks or
              computing facilities, and other content providers.
            </p>
              <h5>
                <b>12. RESPONSIBILITIES </b>
              </h5>
              <p>
                You agree that while using the UnizPortal Platform, you will:
                (i) promptly notify us if you learn of or have a good faith
                suspicion of any illegal or unauthorised activity or a security
                breach involving your account; (ii) make a good faith effort to
                prevent unauthorised access to or use of the UnizPortal
                Platform; (iii) adhere to all applicable laws and regulations,
                including all intellectual property, data, privacy, and export
                control laws; and (iv) promptly notify us of any security
                breaches/incidents in institutional systems related directly or
                indirectly to the UnizPortal Platform. </p><p>
              You also agree to give us feedback regarding your use of the
              UnizPortal Platform, including promptly and accurately notifying
              us of any actual or suspected errors, problems, bugs, or
              difficulties with the UnizPortal Platform as well as any other
              information we may reasonably request to help us address such
              errors, problems, bugs, or difficulties with the UnizPortal
              Platform (such information, "Feedback"). You agree that we are
              free to use this feedback, at our discretion, to enhance the
              UnizPortal Platform or any of our other goods or services.
            </p>
              <h5>
                <b>
                  13. SUBMISSIONS REGARDING THE FUNCTIONALITY OF THE UNIZPORTAL
                  PLATFORM{' '}
                </b>
              </h5>
              <p>
                Any feedback regarding the functionality of the UnizPortal
                Platform that you send to us via any method (each, a
                "Submission") is regarded as non-confidential and may be used by
                us or any third party for any purpose, whether for inclusion as
                part of the UnizPortal Platform or otherwise, without payment or
                liability to you. </p><p>
              By using any submission on, through or in connection with the
              service, including for advertising, promoting, improving, and
              developing the UnizPortal Platform, you hereby grant us, our
              affiliates, and successors a perpetual, worldwide,
              non-exclusive, royalty-free, sublicensable, and transferable
              licence to do so. Personal Information covered by our privacy
              policy is not subject to this clause.
            </p>
              <h5>
                <b>14. CONFIDENTIAL INFORMATION</b>
              </h5>
              <p>
                Except as required by law, you must maintain the confidentiality
                of all Content and other information we supply to you, whether
                through the UnizPortal Platform or another method.
                <b>
                  During the Term and following termination of this Agreement:
                </b>{' '}
                <br />
                <p>
                  <li>
                    {' '}
                    you agree not to directly or indirectly disclose, grant
                    access to, transmit, or otherwise transfer any confidential
                    information to a third party without first getting our
                    written consent;{' '}
                  </li>
                  <br />
                  <li>
                    you agree to treat all confidential information with the
                    utmost care and to take all reasonable precautions to
                    prevent unauthorised disclosure of the information;
                  </li>
                  <br />
                  <li>
                    you won't use, disclose, or reproduce the confidential
                    information unless it is reasonably necessary to receive the
                    services, with our knowledge and previous, explicit written
                    authorization; and
                  </li>
                  <br />
                  <li>
                    you will notify us in writing right away, if you become
                    aware of any unauthorised use or disclosure of any
                    Confidential Information by anyone.{' '}
                  </li>
                  <br />
                </p>
                While your data is under our control, we will use reasonable
                commercial efforts to safeguard it and keep it confidential,
                subject to our ability to disclose your data as permitted by
                this Agreement. This includes putting organisational and
                technical safeguards in place to protect your Personal
                Information. We may also keep your data and disclose it to: (i)
                governmental or regulatory authorities as necessary to comply
                with applicable law; (ii) its professional advisors as necessary
                to: (A) enable us to comply with applicable law's requirements;
                or (B) defend against or pursue a claim for a breach of this
                Agreement.
              </p>
              <p>
                The non-disclosure obligations under this Agreement will not
                apply to information which is readily available to the public
                other than through a breach of this Agreement; was disclosed,
                lawfully and without breach of any contractual or other legal
                obligation; was lawfully known to a Party without any
                confidentiality obligation prior to receipt of the information;
                or was independently developed or discovered by a Party outside
                of the course of such Party’s performance of their obligations
                under this Agreement, without any reference to any Confidential
                Information obtained directly or indirectly from the disclosing
                Party. Upon our request or the earlier of this Agreement's
                termination (whether voluntary or involuntary), you will return
                to us all Confidential Information that is in your custody or
                control right away. </p><p>
              You agree that in addition to any and all other remedies
              available, UnizPortal will be entitled to seek relief by way of
              a temporary or permanent injunction to enforce your obligations
              since damages may not be sufficient to make up for any breach of
              your obligations under this agreement.
            </p>
              <h5>
                <b>15. INTELLECTUAL PROPERTY</b>
              </h5>
              <p>
                The access to UnizPortal Platform is the subject of this
                agreement, which does not provide you a licence to use any other
                software. We or our third-party licensors, who maintain full
                ownership of the UnizPortal Platform and the Content, are the
                exclusive owners of those items. By copyright and trademark
                laws, the UnizPortal Platform and the Content are protected.{' '}
              </p><p>
              Under this Agreement, you are not granted any intellectual
              property rights or other proprietary rights, including any
              right, title, or interest in or to patents, copyrights, trade
              names, trademarks, industrial designs, confidential information,
              or trade secrets relating to the UnizPortal Platform or any of
              its components, whether registered or unregistered. We or our
              third-party licensors reserve any rights under this Agreement
              that are not expressly given to us. </p><p>
              All rights in the UnizPortal Platform, including but not limited
              to the Content, that are not expressly granted to you are
              expressly reserved by us. You agree that we (or our third-party
              suppliers, as appropriate) will retain all right, title, and
              interest in and to the UnizPortal Platform, including without
              limitation, the Content, all other derivative works thereof, and
              all intellectual property rights therein. </p><p>
              Except as expressly permitted in writing by us, you agree not to
              copy, rent, lease, sell, distribute, or make derivative works
              based on the UnizPortal Platform or the Services, in whole or in
              part, by any means. Further, you acknowledge that all comments,
              suggestions, and submissions made regarding the functionality of
              the UnizPortal Platform or the Services are nonconfidential and
              that we are free to utilise them in the UnizPortal Platform or
              the Services without asking for payment or giving you credit.
            </p>
              <h5>
                <b> 16. TERM AND TERMINATION </b>
              </h5>
              <p>
                These terms and conditions shall be in effect until they are
                terminated by either party in accordance with the terms. The
                term of these terms and conditions will begin on the earlier of
                the dates when you: (1) accepted these terms and conditions; or
                (2) first access or use the UnizPortal Platform. We reserve the
                right to cancel these terms and conditions at any time with
                written notice to you.
              </p>
              <h5>
                <b>17. EFFECT OF TERMINATION OR EXPIRY</b>
              </h5>
              <p>
                You must stop using the UnizPortal Platform immediately upon any
                termination or expiration of this Agreement for any reason
                (whether initiated by you or by us). No matter why this
                Agreement is terminated or expires, you are still obligated to
                pay any fees that have accumulated under it or any payments that
                are due before the date of termination or expiration. Any
                accrued rights or remedies of either Party are unaffected by the
                termination of this Agreement by any Party. Any clause in this
                agreement that specifically indicates that it will remain in
                effect after its termination or expiration or that would by its
                very nature survive such events will do so.{' '}
              </p>
              <h5>
                <b>18. DISCLAIMING LIABILITIES</b>
              </h5>
              <p>
                <b>Related to Implied Warranties</b>
                <br />
                Without any warranties, guarantees, or terms of any kind, the
                UnizPortal platform is provided "as is," "where is," and "as
                available" basis. We expressly disclaim all implied warranties
                of merchantability, title, non-infringement, security,
                dependability, completeness, quiet enjoyment, accuracy,
                currency, timeliness, quality, integration, or fitness for a
                particular purpose as well as any other implied warranties,
                representations, or conditions, whether in writing or verbally.
                We make no guarantees for the availability or error-free
                operation of the offering. We make no claims on the likelihood
                of any application or result succeeding as a result of using the
                UnizPortal platform.
              </p>
              <p>
                <b>Related to Content </b>
                <br />
                You acknowledge that we have no special relationship with you or
                a fiduciary duty to you and that we are not responsible for any
                acts or omissions made by you or any other user of the
                UnizPortal platform, including how you or any other user may use
                or interpret content developed or accessed through the
                UnizPortal platform or what actions you may take as a result of
                having been exposed to content obtained through the UnizPortal
                platform. </p><p>
              You also agree and recognise that the UnizPortal platform
              contains information, data, documents, pages, photos, links,
              content, and other materials that were made accessible by third
              parties (referred to as "third party content"). The content of
              such third parties is not our responsibility. You acknowledge
              that it is your responsibility to study and use any third-party
              content with caution and attention. </p><p>
              Links to websites belonging to third parties, including but not
              limited to those of educational institutions or recruitment
              partners, may be provided through the UnizPortal platform. These
              links are just available for your convenience and that for other
              UnizPortal platform users. We don't recommend or guarantee the
              quality, accuracy, reliability, completeness, timeliness,
              noninfringement, merchantability, or suitability for any purpose
              of the information, goods, or services mentioned on those
              websites. If you choose to access any such website, you do so
              fully at your own risk because the content there is not under
              our control and is not a part of the UnizPortal platform. </p><p>
              We disclaim all liability for any mistakes, omissions,
              inaccuracies, effectiveness, standards compliance, copyright
              compliance, legality, morality, or other features of the content
              made available through the UnizPortal platform. </p><p>
              Any loss or harm resulting from your reliance on information
              gained through the UnizPortal platform, including any
              third-party connected website (including any opinions, ideas,
              statements, or advice), shall not be our responsibility under
              any circumstances. </p><p>
              Use of the UnizPortal platform is totally at your own risk, and
              it is your obligation to determine the correctness,
              completeness, and utility of any offered content. In relation to
              your use of the UnizPortal platform and our use of your data, we
              will not be held responsible for any claims made by you or any
              third parties whose content is collected from the UnizPortal,
              whether those claims are linked to privacy or not.
            </p>
              <p>
                <b>Related to Service Providers</b>
                <br />
                You recognise that, while our reasonable efforts to ensure that
                any contracts with service providers are reasonably protective
                of your data, we are not responsible for any actions or
                inactions of third parties related to such servers and the data
                kept on them. You acknowledge that you retain sole
                responsibility for making backup copies of such data and that we
                are hereby released from all liability for any governmental or
                third-party action taken in such jurisdictions with regard to
                such information and data or the servers on which Your Data
                resides.
              </p>
              <p>
                <b>Related to Unauthorized use of Access Information </b>
                <br />
                You accept full responsibility for such use of the UnizPortal
                Platform and any consequences resulting from such use. We are
                not responsible or liable in any way for any use of the
                UnizPortal Platform (whether authorised or unauthorised) by any
                third party accessing the UnizPortal Platform using your Access
                Information.
              </p>
              <p>
                <b>Related to Applicable Territorial Law</b>
                <br />
                All jurisdictions do not allow the enforcement of certain
                warranties or liability, so any action will be taken based on
                applicable territorial laws.
              </p>
              <p>
                <b>Related to Viruses</b>
                <br />
                You use the Content at your own risk when downloading and
                viewing it. We cannot and do not guarantee or warrant that the
                UnizPortal Platform or the Content is compatible with your
                computer system or that the UnizPortal Platform, or any links
                from the UnizPortal Platform or the Content, will be free of
                viruses, worms, trojan horses, or other disabling bugs or other
                code that manifests contaminating or destructive properties. You
                are in charge of putting security measures in place to protect
                your computer system's security and integrity, and you are also
                in charge of paying the full cost of any servicing, repairs, or
                connections of and to your computer system that could be
                required as a result of your use of the UnizPortal Platform.
              </p>
              <p>
                <b>Related to Communications Not Confidential or Secure</b>
                <br />
                We cannot ensure the privacy of any messages you send us over
                the UnizPortal Platform, the internet, or the phone. You
                understand, agree, and acknowledge that while we generally
                follow industry best practises to secure the transmission of
                data to, from, and through the UnizPortal Platform, that
                security cannot and is not guaranteed by us in connection with
                your use of the UnizPortal Platform.
              </p>
              <h5>
                <b>19. INDEMNITIES </b>
              </h5>
              <br />
              <p>
                To the fullest extent permitted by Law, you agree to release,
                hold harmless and indemnify Uniz Portal from and against any
                claims, suits, liabilities, damages, injuries, costs, expenses
                (including indirect and consequential loss) whatsoever and
                however arising out of or in connection with:
                <p>
                  <li>
                    your use or access to, or misuse of unizportal.com or
                    Services;
                  </li>
                  <br />
                  <li>
                    your breach or non-compliance with any terms of this
                    Agreement;
                  </li>
                  <br />
                  <li>
                    your violation, infringement or contravention of the
                    Facebook/Instagram/Twitter Terms;
                  </li>
                  <br />
                  <li>
                    your violation, infringement or contravention of the Other
                    Social Media Terms;
                  </li>
                  <br />
                  <li>
                    your violation, infringement or contravention of any third
                    party rights; and
                  </li>
                  <br />
                  <li>
                    your infringement or noncompliance with any applicable Law.
                  </li>
                  <br />
                </p>
              </p>
              <h5>
                <b>20. LIMITATION OF LIABILITY</b>
              </h5>
              <p>
                No matter whether we were informed of the possibility of any
                damages, we will never be liable, whether based on warranty,
                contract, tort, negligence, strict liability or any other legal
                theory, for any direct, indirect, incidental, consequential,
                special, exemplary, or punitive damages; lost profits, loss of
                use, loss of data, personal injury; fines, fees, penalties; or
                other liabilities resulting from or related to the UnizPortal
                platform or these terms and conditions, to the extent that the
                foregoing clauses are applicable. For greater certainty, the
                existence of one or more claims under these terms of service
                will not increase our total aggregate liability in connection
                with or under these terms and conditions, or your use of, or
                inability to use, the Unizportal platform, to an amount greater
                than INR 20,000 or equivalent in your local currency. </p><p>
              Nothing in this Agreement restricts our responsibility for
              fraud, wrongful death, or bodily injuries to the extent that
              such harm was brought on by our willful wrongdoing or gross
              negligence. The limits in this Section reflect how the Parties
              have distributed risk. Even if it is determined that any limited
              remedy provided for in this Agreement has been ineffective for
              whatever reason, the limits outlined in this Section will still
              apply.
            </p>
              <h5>
                <b>21. OTHER GENERAL DISCLAIMERS</b>
              </h5>
              <p>
                <b>Independent Service Provider </b>
                <br />
                We operate as separate service providers in our interactions
                with one another. Nothing in this document should be interpreted
                as establishing or implying a principal-agent, joint venture,
                partnership, or employment relationship between you and us. You
                will not, unless specifically permitted by UnizPortal in
                writing, represent yourself as UnizPortal's legal
                representative, engage into any agreements on its behalf, or
                otherwise obligate or represent yourself as obliging UnizPortal
                in any way.
              </p>
              <p>
                <b>Force Majeure</b>
                <br />
                Neither party will be liable to the other for any failure to
                meet its obligations under these Terms and Conditions where such
                failure is caused by events beyond its reasonable control such
                as failure of communications networks, inability to timely
                obtain instructions or information from the other party,
                governmental action, fire, storms, epidemics, pandemic-related
                delays and/or outcomes, floods or acts of God, provided that the
                party seeking to rely on such circumstances gives written notice
                of such circumstances to the other party hereto and uses
                reasonable efforts to overcome such circumstances. Non-payment
                of amounts due under this Agreement will not be excused by this
                provision.{' '}
              </p>
              <p>
                <b>Survival</b>
                <br />
                Without prejudice, after these Terms and Conditions expire or
                are terminated, any rights or liabilities that have accrued or
                developed under them will continue to exist. All provisions of
                these Terms and Conditions, including ownership, confidentiality
                covenants, warranties, indemnities, and liability limitations,
                which are intended to survive termination or expiration by their
                very nature, shall do so.
              </p>
              <p>
                <b>Assignment</b>
                <br />
                Without our prior written consent, you may not assign these
                Terms and Conditions to any third person. Without your
                permission, we may transfer these terms and conditions or any
                rights under them to any third party. Any assignment that is in
                breach of this Section is void. All allowed assignees shall be
                bound by these Terms and Conditions. The parties, their
                authorised successors, and authorised assignees are all parties
                to and are bound by these terms and conditions.
              </p>
              <p>
                <b>Notices </b>
                <br />
                Any written notices, reports, or other communications called for
                or allowed under this Agreement must be addressed to you or
                UnizPortal at the respective addresses specified in writing and
                must be given to you or UnizPortal in person, by registered
                mail, courier, or facsimile. Any such notices, reports, or other
                communications will be deemed to have been received by the Party
                to whom they were addressed when delivered by hand, sent by
                registered mail five business days later, delivered by courier
                and acknowledged by the courier receipt, sent by facsimile with
                a successful receipt confirmation report, or sent by email and
                acknowledged by the sender upon receipt.
              </p>
              <p>
                <b>Non-Waiver </b>
                <br />A waiver by one Party of another's default, breach, act,
                or failure to comply with these terms and conditions must be
                made in writing and signed by the party waiving the violation. A
                waiver of any enforcement rights of the same or different sort
                at any point in the future will not result from our failure to
                enforce any of these Terms and Conditions or any applicable law.
              </p>
              <p>
                <b>Severability </b>
                <br />
                Any provision that is determined to be invalid, unlawful, or
                unenforceable in any way shall be deemed severable from this
                Agreement and shall not be considered as part of this Agreement
                and shall be treated as if it had never been included.
              </p>
              <p>
                <b>Governing Law </b>
                <br />
                Without regard to any choice-of-law provisions that may call for
                the application of the laws of another jurisdiction, these Terms
                and Conditions shall be governed by the local laws of our head
                office or any of its branches. Our corporate headquarters will
                only have jurisdiction over disputes arising out of or related
                to these terms and conditions.
              </p>
              <p>
                <b>Entire Agreement/Modification </b>
                <br />
                Subject to the precedence clause on these Terms and Conditions
                or as otherwise stated in another agreement with us, this
                Agreement supersedes all prior agreements and understandings
                between the Parties and constitutes the entire agreement between
                them regarding the subject matter hereof.
              </p>
              <p>
                <b>Electronic Agreement </b>
                <br />
                You understand and agree to a legally binding contract when you
                click the "I AGREE" button (or any other similar buttons or
                links as may be identified by us to indicate your acceptance of
                this consent and/or your consent to use the UnizPortal
                Platform). By signing/using this document/portal, you accept
                that contracts, orders, and other records will be created using
                electronic means, and that notices, policies, and records of
                transactions started or finished using the UnizPortal Platform
                will be delivered electronically. Additionally, to the extent
                permitted by applicable law, you expressly renounce any rights
                or obligations you may have under any applicable laws in any
                jurisdiction that call for a physical signature or the delivery
                or storage of paper records.
              </p>
              <p>
                <b>English language </b>
                <br />
                This Agreement shall only be produced in the English language.
                If English is not your first language, it is your responsibility
                to have this Agreement translated by a professional translator.
                Uniz Portal shall not be held responsible for any errors,
                omissions or misunderstandings arising from or as a result of
                any translation of this Agreement.
              </p>
              <h5>
                <b>22. SPECIFIC PROVISIONS FOR STUDENT USERS</b>
              </h5>
              <p>
                The provisions in this Section apply to you only if you are a
                Student user of the UnizPortal Platform. <br />
                <b>Representations and Warranties for Student Users: </b>
                <br />
                You covenant, represent and warrant that:
                <p>
                  <li>
                    you will comply with any UnizPortal Partner policies and
                    procedures applicable to your Application;
                  </li>
                  <br />
                  <li>
                    your data is true, accurate, current and complete, and if it
                    becomes inaccurate, that you will promptly notify us or
                    update your data on the UnizPortal Platform;
                  </li>
                  <br />
                  <li>
                    at any stage of a visa application or proceeding (including
                    before a visa application is made), you will refrain from
                    asking a recruitment partner or counsellor for immigration
                    or visa-related advice unless you are certain that they
                    adhere to the applicable laws of the country to which you
                    are applying and they are registered in accordance with
                    those laws;
                  </li>
                  <br />
                  <li>
                    {' '}
                    you will not provide any unauthorised Recruitment Partner or
                    Counsellor with a related fee or other consideration for any
                    immigration- or visa-related advice unless they have first
                    assured you that they are qualified pursuant to Applicable
                    Law;
                  </li>
                  <br />
                  <li>
                    you are fully authorised to use the UnizPortal Platform,
                    both in your country of residence and in the country of the
                    UnizPortal Partner to which you are submitting an
                    application. This includes your ability to access the
                    Content, to submit the application, and to provide Your
                    Data; and
                  </li>
                  <br />
                  <li>
                    you are not residing in a nation that is subject to
                    sanctions or an embargo imposed by the government or
                    regulatory body to whom you are submitting an application
                    that would prevent you from enrolling as a student at the
                    UnizPortal Partners.
                  </li>
                  <br />
                </p>
                <b>
                  Restrictions and Limitations on use of the UnizPortal Platform
                  – Access to UnizPortal Partner Programs
                </b>
                <br />
                <p>
                  <li>
                    You understand that not all educational study programmes
                    offered by UnizPortal Partners may be made available to you
                    using the UnizPortal Platform, and that only a limited
                    number of UnizPortal Partners have agreements with us that
                    allow you to submit an application to such UnizPortal
                    Partners through the UnizPortal Platform.
                  </li>
                  <br />
                  <li>
                    You agree that when using the UnizPortal Platform, analysis
                    of the data you have provided, your inputs and/or
                    interactions with the UnizPortal Platform (including but not
                    limited to website and/or applications) and other supporting
                    information, including but not limited to anonymized
                    historical data, may be performed in order to tailor the
                    service, application, and/or recommendations offered in
                    order to maximise success for students and other third-party
                    partners.
                  </li>
                  <br />
                </p>
                <b>Disclaimers </b> <br />
                <b> Control of Third Parties </b>
                <br />
                You acknowledge that the success of your Application(s) is
                solely under the control of such third parties, not us, and that
                we are therefore not liable in relation to your Application(s)
                as we have no control over UnizPortal Partners or any other
                entity granting visas, admissions, permits, or other
                authorizations in connection with your Application(s). The
                likelihood of success and/or the possibility of acceptance by
                any third party and/or UnizPortal Partner are expressly
                disclaimed by UnizPortal.
                <br />
                <b> Rejection of Applications </b>
                <br />
                Your Application(s), any errors or counterfeiting in your data,
                or your failure to be accepted into your desired Program(s) or
                UnizPortal Partner(s) are not in any way our responsibility.
                <br />
                <b>Issues with UnizPortal Partners </b>
                <br />
                We have no responsibility or liability for any difficulties
                relating to your Application(s), Program(s), or your failure to
                adhere to UnizPortal Partner policies; these are strictly
                between you and the relevant UnizPortal Partner.Inability to
                Access Country <br />
                In accordance with applicable law and other requirements of
                governmental or regulatory authorities, you are solely
                responsible for meeting any entrance criteria required to be
                admitted into the nation where your Programme is based.
                <br />
                <b>Fees, Payment and Taxes </b>
                <br />
                <p>
                  <li>
                    {' '}
                    Fees
                    <p>
                      <li>
                        You are responsible for paying all fees associated with
                        the UnizPortal Platform that are included in our
                        standard pricing list, on the UnizPortal Platform in the
                        standard price list, or that are otherwise communicated
                        to you.{' '}
                      </li>
                      <br />
                      <li>
                        Depending on your place of origin, other applicant
                        related criteria, and the chance that your application
                        will be approved for a visa by a governmental or
                        regulatory authority, we might ask you to pay a
                        processing deposit. On the UnizPortal Platform, you may
                        examine the specifics of the Application processing
                        deposit and the nations to which it will apply. We
                        reserve the right to make changes at any moment. The
                        UnizPortal Platform specifies the circumstances under
                        which an Application processing deposit is refundable
                        and the circumstances under which an Application
                        processing deposit will NOT be returned to you. <br />
                        The following situations will result in the forfeiture
                        of your application processing deposit: (a) you fail to
                        submit the necessary paperwork for processing the
                        application; (b) you submit forged paperwork for
                        processing the application; (c) your visa application is
                        denied; or (d) you withdraw your application before
                        receiving an admissions decision.
                      </li>
                      <br />
                      <li>
                        {' '}
                        We may charge you fees under this Agreement, but some of
                        those fees are set by UnizPortal Partners or other third
                        parties; we have no control over the amount of those
                        fees; instead, we merely remit those fees to the
                        relevant UnizPortal Partner or third party. As a result,
                        those payment obligations are non-cancelable, and any
                        fees you have already paid are nonrefundable.{' '}
                      </li>
                      <br />
                      <li>
                        We reserve the right to change these fees with
                        appropriate notice, which we can do by amending the
                        UnizPortal Platform's standard price list.{' '}
                      </li>
                      <br />
                      <li>
                        We understand that any new or increased fees are
                        accepted if you continue to use the UnizPortal Platform
                        after such changes.
                      </li>
                      <br />
                    </p>
                  </li>
                  <br />

                  <li>
                    {' '}
                    Fee Payment
                    <p>
                      <li>
                        {' '}
                        You authorise us or our third-party payment processors
                        to charge your credit/debit card or bank cheque for all
                        fees incurred while using the UnizPortal Platform.
                        Regardless of the reason for a credit card or cheque
                        refusal, you are responsible for all fees and penalties.
                      </li>
                      <br />
                      <li>
                        The aforementioned does not limit our ability to
                        exercise any legal or equitable remedies regarding the
                        recovery of any amounts due under this Agreement, and
                        you agree to reimburse us for all reasonable fees and
                        costs, including attorney fees, incurred in pursuing any
                        overdue amounts or enforcing any provision of this
                        Agreement.
                      </li>
                      <br />
                      <li>
                        {' '}
                        Without limiting the aforementioned, we reserve the
                        right to withhold processing of any Application or a
                        portion thereof until you have paid all payments in
                        full.{' '}
                      </li>
                      <br />
                    </p>
                  </li>
                  <br />

                  <li>
                    {' '}
                    Interest on Late Payments
                    <p>
                      <li>
                        {' '}
                        In addition to any other rights or remedies we may have,
                        we will be authorised to impose interest on all unpaid
                        balances at a rate that is either 24% per year or the
                        highest rate permissible by applicable law, starting
                        from the payment due date.
                      </li>
                      <br />
                    </p>
                  </li>
                  <br />

                  <li>
                    <p>
                      Taxes
                      <li>
                        All taxes connected to this Agreement and based on our
                        services provided, excluding those based on our net
                        revenue, are your responsibility and will be paid by
                        you. All payments made by you under this Agreement,
                        unless otherwise stated, are exclusive of any taxes,
                        duties, levies, or other government fees that may be
                        imposed by any jurisdiction, whether they are based on
                        gross revenue, the delivery, possession, or use of the
                        UnizPortal Platform, the signing of this Agreement, or
                        something else entirely.
                      </li><br />
                      <li>
                        The amount of payment due will automatically increase to
                        account for any taxes that must be withheld from
                        payments made under this Agreement, bringing the total
                        actually sent to us into compliance with the amount
                        owed.{' '}
                      </li>
                      <br />
                      <li>
                        You agree to promptly give us copies of all official
                        receipts that show you have paid any taxes owed to the
                        relevant taxing authority under or in connection with
                        this Agreement.
                      </li>
                      <br />
                    </p>
                  </li>
                  <br />
                </p>
              </p>
              <h5>
                <b>
                  23. SPECIFIC PROVISIONS FOR RECRUITMENT PARTNER OR COUNSELLOR
                  USERS
                </b>
              </h5>
              <p>
                By using the UnizPortal Platform or by entering into a formal
                agreement, you, as a Recruitment Partner or Counsellor user,
                agree to be bound by and comply with all additional terms and
                conditions that are applicable to you. </p><p>
              You agree that when using the UnizPortal Platform, analysis of
              the data you have provided, your inputs and/or interactions with
              the UnizPortal Platform (including but not limited to website
              and/or applications) and other supporting information, including
              but not limited to anonymized historical data, may be performed
              in order to tailor the service, application, and/or
              recommendations offered in order to maximise success for
              students and other third-party partners. </p><p>
              The Recruitment Partner is entitled to receive Commission on
              successful placement of a Student with an Institution or
              University Partner where Commission is made available by a
              participating Institution or University Partner (Participating
              Institution or University Partner). UnizPortal shall publish a
              list of Participating Institution or University Partners, which
              may be amended from time to time. For the avoidance of any
              doubt, no Commission will be payable by an Institution or
              University Partner not listed as a Participating Institution or
              University Partner. </p><p>
              The commission paid to you for each accepted application will be
              displayed on the dashboard of the UnizPortal Platform and may
              wind up being higher or lower than the estimate owing to
              currency rates and other variables. </p><p>
              Recruitment Partner understand that any bank fees or exchange
              rate conversion costs incurred by the bank are not UnizPortal's
              responsibility. Recruitment Partner has given permission to
              UnizPortal to deduct any such fees from the Commission. </p><p>
              You understand that if your corporated business's GST
              registration is revoked or it receives a new GST number, you
              must notify UnizPortal in writing. </p><p>
              If a student does not enrol, does not pay the entire amount due,
              or if the university holds the applicant's documents for any
              reason, Uniz Portal will not be obligated to pay the recruiter
              any compensation or commission. </p><p>
              The contract with the Recruitment Partner or Agent will be
              cancelled immediately, and any pay-out or commission or
              compensation will be forfeited, if the University or Institute
              has found fraudulent profiles or papers on file.
            </p>
              <b>Recruitment Partner Responsibilities</b>
              <br />
              <p>
                <li>
                  {' '}
                  must act in good faith at all times towards Uniz Portal;
                </li>
                <br />
                <li>
                  must keep up to date, be aware of, and comply with all
                  applicable Laws or conditions that affect the jurisdiction you
                  reside or conduct business, in your use of UnizPortal and its
                  services, and all other services you provide to Students;
                </li>
                <br />
                <li>
                  must perform an initial assessment on all Students using the
                  UnizPortal guidelines, as amended from time to time, to
                  determine whether the Student qualifies as a suitable
                  candidate and meets the Eligibility Criteria;
                </li>
                <br />
                <li>
                  must maintain and keep current all Authorizations to enable
                  you to lawfully provide recruitment or education advisory
                  services to Students, including any immigration or visa
                  related advice;
                </li>
                <br />
                <li>
                  {' '}
                  must promptly inform Uniz Portal of any information, matter,
                  situation, circumstance or thing that may cause or will cause
                  an impact on or in any way change your use of Uniz Portal and
                  the Services; and
                </li>
                <br />
                <li>
                  {' '}
                  not engage in a direct relationship with the Student and keep
                  UnizPortal informed of the entire process.
                </li>
                <br />
              </p>
              <h5>
                <b>24. SPECIFIC PROVISIONS FOR UNIZPORTAL PARTNER USERS</b>
              </h5>
              <p>
                As a UnizPortal Partner user, you agree to be bound by, and
                comply with, all other terms and conditions applicable to you,
                whether by formal agreement or use of the UnizPortal Platform.
              </p>
              <h5>
                <b>25. CONTACT </b>
              </h5>
              <p>
                Any questions regarding this Agreement, or any questions,
                complaints, claims or other legal concerns relating to
                UnizPortal or its business, should be directed to us at: </p><p>
              info@UnizPortal.com </p><p>
              Or </p><p>
              Phone: +91-90537-00336 </p><p>
              Disclosure under Consumer Protection (E-Commerce) Rules, 2020
              (India) </p><p>
              If you have any complaint or concern is not resolved by
              info@UnizPortal.com, then you may write to the Compliant Section
              introduced on Portal that is Handled under the observation of
              our Chief Executive Officer: Mr. Rahul Sachdeva
            </p>

              <div className='footer-contract'>
                <div>

                  {userData && userData.contractSigned ? <div className={'alignCenter'}>
                    <h5>
                      Otp Verified Signature
                    </h5>
                    <br />
                    <div style={{ lineHeight: 1.5 }}>
                      <div>
                        Signed on <span className={'themeColor'}>{displayDate(userData.contractDate)}</span>
                      </div>
                      {userData && userData.userId ? <div>
                        <div>
                          Signed by <span
                          className={'themeColor'}>{userData.userId && userData.userId.name ? userData.userId.name : ''}</span>
                        </div>
                        <div>
                          Contact No <span className={'themeColor'}>
                          {userData.userId && userData.userId.countryCode ? userData.userId.countryCode : ''}&nbsp;
                          {userData.userId && userData.userId.mobile ? userData.userId.mobile : ''}
                        </span>
                        </div>
                        <div>
                          Email <span className={'themeColor'}>
                          {userData.userId && userData.userId.email ? userData.userId.email : ''}
                        </span>
                        </div>
                      </div> : null}
                    </div>
                    <br />
                  </div> : null}

                  {userData && userData.contractSigned ? <>
                      <label className='label label-success-green mt-5'>Signed</label>
                      <div className={'mt15'}>
                        <a className={'btn btn-success roundNew md'}
                           onClick={events.downloadContract}
                        >Download Advance Contract</a>
                      </div>
                    </> :
                    <>
                      <Popconfirm title={`Are your sure, you want to Sign Agreement?`}
                                  onConfirm={eventFxn.handleSignClick}
                                  okText='Yes' cancelText='No'>
                        <a className={'btn btn-success-green roundNew lg'}>Sign Now</a>
                      </Popconfirm>
                    </>}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {otpModalVisible ? <Modal
        title='Enter OTP'
        visible={otpModalVisible}
        onCancel={() => setOtpModalVisible(false)}
        footer={[
          <Button key='cancel' onClick={() => setOtpModalVisible(false)}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' onClick={eventFxn.handleOtpVerification}>
            Submit
          </Button>
        ]}>
        <InputBox>
          <Input type='password'
                 required={true}
                 value={otp}
                 maxLength={4}
                 minLength={4}
                 className={'form-control'}
                 onChange={e => setOtp(e.target.value)} placeholder='Enter OTP' />
        </InputBox>
      </Modal> : null}

      {reqState.visible ?
        <AdvanceCommissionRequest {...reqState} onClose={events.hideReqMessage} />
        : null}

    </div>
  )
}

const mapStateToProps = ({ global }) => ({
  currentUserRights: global.currentUserRights
})

export default connect(
  mapStateToProps,
  null
)(AdvanceCommissionContract)

