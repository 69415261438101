import React, { useState, useEffect } from 'react'
import { Select, Form, Button, Col, Row, notification, Icon, AutoComplete, Input } from 'antd'
import { useDispatch } from 'react-redux'
import { branchManagerAgentListBySearch, getBranchManagerAgentList, listAllUsers } from '../../users/actions/user'
import { addBroadcastMail } from '../actions'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import _ from 'lodash'
import InputBox from '../../../components/_utils/InputBox'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { countryIds, departmentList, departmentObj, filterOption } from '../../../components/_utils/appUtils'
import { listAllCountries } from '../../countries/actions/countries'

const { Option } = AutoComplete

let htmlContent = (
  ` <p><strong>Dear</strong>&nbsp;Partner,</p>

    <p>Greetings from UnizPortal!</p>
    
    

    <p>For further action, you can logon to our online application portal at&nbsp;<a href='https://unizportal.com/login' target='_blank'>https://unizportal.com/login</a>&nbsp;or download the mobile application from&nbsp;<a href='https://play.google.com/store/apps/details?id=com.unizportal' target='_blank'>Google</a>&nbsp;and&nbsp;<a href='https://apps.apple.com/in/app/unizportal/id1569018281' target='_blank'>Apple</a>&nbsp;store.</p>

    <p style='color: #ba0307; font-size: 12px;'>Note- This is an autogenerated email,please do not respond to this email for us to serve you better. Any communication further to this application please make a note in student portal by login at:&nbsp;<a href='https://unizportal.com/login' target='_blank'>https://unizportal.com/login</a>.</p>

    <p>Yours truly,</p>

    <div><strong>UnizPortal Team</strong></div>`
)

let broadCastList = [
  { name: 'All', value: 'all' },
  { name: 'Agents', value: 'agents' },
  { name: 'Users (Unizportal members) ', value: 'users' }
]
let broadCastListForBM = [
  { name: 'Agents', value: 'agents' },
  { name: 'Users (Unizportal members) ', value: 'users' }
]
let broadCastListForMarketing = [
  { name: 'Agents', value: 'agents' }
]

const BroadcastMailBroadcastMail = (props) => {
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } } = props
  const dispatch = useDispatch()
  let [allAgents, setAllAgents] = useState([])
  let [users, setUsers] = useState([])
  let [userData, setUserData] = useState({})
  let [dataSource, setDataSource] = useState([])
  let [agentName, setAgentName] = useState('')
  let [userSelectType, setUserSelectType] = useState('all')
  let [userOption, setUserOption] = useState('Selected')
  let [attachments, setAttachments] = useState([])
  let [uploadKey, setUploadKey] = useState(moment())
  let [editorKey, setEditorKey] = useState(moment())
  let [disableField, setDisableField] = useState(false)
  let [disableUserField, setDisableUserField] = useState(false)
  let [allCountry, setAllCountry] = useState([])
  useEffect(() => {
    setDefaultMessage()
    loadUserData()
  }, [])

  useEffect(() => {
    events.loadCountry()
  }, [userData])

  const setDefaultMessage = () => {
    setTimeout(() => {
      setFieldsValue({ message: htmlContent })
      setEditorKey(moment())
    }, 1000)
  }

  const loadUserData = async () => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    setUserData(user)
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 24, offset: 0 },
      md: { span: 24, offset: 0 }
    }
  }

  const events = {
    checkUserOption: (name = undefined) => {
      if (getFieldValue('broadcastType') === 'agents') {
        events.loadAgent(name)
      }
      if (getFieldValue('broadcastType') === 'users') {
        events.loadUsers(name)
      }
    },
    loadCountry: async () => {
      if (userData && (userData.userType == 'admin' || userData.userType == 'userManager')) {
        let filters = {
          sortField: 'countryName', sortOrder: 'ascend'
        }
        let { data } = await dispatch(listAllCountries(filters))
        setAllCountry(data)
      }
    },
    loadAgent: async (name) => {
      let obj = {
        results: 50,
        userType: ['agent'],
        sortField: 'name',
        sortOrder: 'ascend',
        select: ['name', 'email', 'companyName'],
        name: name ? name : undefined,
        block: false
      }
      let { data } = await dispatch(branchManagerAgentListBySearch(obj))
      setAllAgents(data)
    },
    loadUsers: async (name) => {
      let obj = {
        results: 50,
        userType: ['branchUser', 'branchManager'],
        sortField: 'name',
        select: ['name', 'email'],
        sortOrder: 'ascend',
        name: name ? name : undefined,
        department: getFieldValue('department') ? getFieldValue('department') : undefined,
        countryId: getFieldValue('countryId') ? getFieldValue('countryId') : undefined,
        customQuery: {
          _id: { $ne: userData._id }
        }
      }
      if (userData.userType === 'branchManager') {
        obj.countryId = userData.countryId
      }
      let { data } = await dispatch(listAllUsers(obj))
      setAllAgents(data)
    },
    handleSubmit: async (e) => {
      const { form } = props
      e.preventDefault()
      form.validateFieldsAndScroll(async (err, valData) => {
        if (!err) {
          if (!valData.subject) {
            return notification.warning({
              message: 'Please enter subject.'
            })
          }
          if (!valData.message) {
            return notification.warning({
              message: 'Please enter message.'
            })
          }
          if (!valData.broadcastType) {
            return notification.warning({
              message: 'Please choose Broadcast type.'
            })
          }
          if (valData.broadcastType !== 'all') {
            if (userOption == 'Selected') {
              if (!users || (users && !users.length)) {
                return notification.warning({
                  message: 'Please choose atleast 1 agent'
                })
              }
            }
          }
          let userId = []
          _.each(users, (item) => {
            userId.push(item._id)
          })
          valData.userId = userId
          valData.userOption = userOption
          if (userData.userType === 'branchManager') {
            valData.countryId = userData.countryId
          }
          let fd = new FormData()
          fd.append('obj', JSON.stringify(valData))
          if (attachments && attachments.length) {
            _.each(attachments, (item, key) => {
              fd.append('attachments', item)
            })
          }
          let data = await dispatch(addBroadcastMail(fd))
          if (data && !data.error) {
            setUploadKey(moment())
            setAttachments([])
            setUserOption('Selected')
            setUsers([])
            setFieldsValue({
              message: htmlContent,
              subject: '',
              department: '',
              countryId: '',
              broadcastType: '',
              alternateEmails: ''
            })
            setEditorKey(moment())
          }
        } else {
          notification.warning({
            message: 'Fill All Required Fields'
          })
        }
      })
    }
  }
  const removeAgentEmail = (index) => {
    let cloneEmail = _.clone(users)
    cloneEmail.splice(index, 1)
    setUsers(cloneEmail)
  }

  const addAgentEmail = (data) => {
    let findUser = _.find(users, (item) => {
      return item._id && item._id.toString() == data
    })
    if (findUser) {
      setAgentName('')
      // setAllAgents([])
      return notification.warning({
        message: 'User already exists.'
      })
    } else {
      let findAgent = _.find(allAgents, (item) => {
        return item._id && item._id.toString() == data
      })
      if (findAgent) {
        let cloneEmail = _.clone(users)
        cloneEmail.push(findAgent)
        setUsers(cloneEmail)
        setAgentName('')
        // setAllAgents([])
      }
    }
  }

  const onSearch = searchText => {
    setDataSource({
      dataSource: !searchText ? [] : [searchText, searchText.repeat(2), searchText.repeat(3)]
    })
  }
  const renderOption = (item) => {
    return (
      item ? <AutoComplete.Option key={JSON.stringify(item)}>
        {item.name} ({item.companyName})<br/>
        Email : {item.email}
      </AutoComplete.Option> : null
    )
  }


  const formFields = {
    fields: [
      {
        key: 'subject',
        label: 'Subject',
        required: true,
        placeholder: 'Enter Subject',
        span: 24
      },
      {
        key: 'message',
        label: 'Message',
        placeholder: 'Enter Message',
        type: 'ckeditor',
        editorKey: editorKey,
        required: true,
        span: 24
      },
      {
        key: 'broadcastType',
        label: 'Broadcast Type',
        type: 'select',
        keyAccessor: x => x.value,
        valueAccessor: x => x.name,
        // options: userData.userType == 'admin' ? broadCastList : broadCastListForBM,
        options: userData.userType === 'admin' || userData.userType === 'userManager' ? broadCastList : userData.userType === 'branchManager' ? broadCastListForBM : broadCastListForMarketing,
        required: true,
        span: 8,
        onChange: (broadcastType) => {
          setFieldsValue({ broadcastType }, () => {
            events.checkUserOption()
          })
        }
      },
      {
        key: 'countryId',
        label: 'Country',
        type: 'select',
        allowClear: true,
        keyAccessor: x => x._id,
        valueAccessor: x => x.countryName,
        options: allCountry,
        hidden: userData.userType == 'branchManager' || userData.userType == 'branchUser' || ((userData.userType == 'admin' || userData.userType == 'userManager') && getFieldValue('broadcastType') !== 'users'),
        span: 8,
        onChange: (countryId) => {
          setFieldsValue({ countryId }, () => {
            events.checkUserOption()
          })
        }
      },
      {
        key: 'department',
        label: 'Department',
        type: 'select',
        allowClear: true,
        keyAccessor: x => x,
        valueAccessor: x => x,
        options: departmentList,
        hidden: (getFieldValue('broadcastType') !== 'users'),
        span: 8,
        onChange: (department) => {
          setFieldsValue({ department }, () => {
            events.checkUserOption()
          })
        }
      },
      {
        key: 'alternateEmails',
        label: 'Alternate Emails',
        type: 'text',
        // span: 8,
        span: 16
      }
    ]
  }

  const extraBtn = (
    <div className={'mailerOption'}>
      <label>
        <input type="radio" id="agentType" name="agentType"
               checked={userOption == 'Selected'}
               value={'Selected'}
               onChange={(e) => {
                 setUserOption(e.target.value)
               }}/>
        <i>Selected</i></label>
      <label>
        <input type="radio" id="agentType" name="agentType"
               checked={userOption == 'All'}
               value={'All'}
               onChange={(e) => {
                 setAllAgents([])
                 setAgentName('')
                 setUsers([])
                 setUserOption(e.target.value)
               }}/>
        <i>All</i></label>
    </div>
  )


  return (
    <div className="row">
      <div className="col-lg-12">
        <Form onSubmit={events.handleSubmit}>
          <div className="form-box mt-4">

            <div className="d-flex align-items-center mb-3 heading-form">
              <h5>Broadcast Mail</h5>
            </div>

            <div className="card unizportal">
              <Row gutter={24} className={'wrapBox'}>
                {formFields.fields.map((item, key) => {
                  return (
                    !item.hidden && <Col span={item.span ? item.span : 8} key={key}>
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout}/>
                    </Col>
                  )
                })}

                {getFieldValue('broadcastType') && getFieldValue('broadcastType') !== 'all' ? <Col span={8}>
                  <InputBox title={`Select ${getFieldValue('broadcastType')}`} extra={extraBtn}>
                    <Select
                      showSearch={true}
                      onSearch={debounce(events.checkUserOption, 500)}
                      filterOption={false}
                      disabled={userOption === 'All'}
                      autoClearSearchValue={true}
                      allowClear={true}
                      value={agentName}
                      style={{ width: '100%' }}
                      showArrow={true}
                      onChange={(e) => {
                        setAgentName(e)
                      }}
                      onSelect={(e) => {
                        addAgentEmail(e)
                      }}
                      optionLabelProp="label"
                      placeholder={`Select ${getFieldValue('broadcastType')}`}>
                      {allAgents && allAgents.length && allAgents.map((item, index) => {
                        return (
                          <Option key={item._id} value={item._id} label={item.name}>
                            <div>
                              <div>
                                {item.name} {item.companyName ? `(${item.companyName})` : null}
                              </div>
                              <div>
                                {item.email}
                              </div>
                            </div>
                          </Option>
                        )
                      })}
                    </Select>
                  </InputBox>
                </Col> : null}

                <Col span={8}>
                  <InputBox title={'Attachments'} className={'logoCls'}>
                    <Input type={'file'} className={'form-control'} key={uploadKey} name={'attachment'}
                           id={'attachment'}
                           multiple={true}
                           onChange={(e) => {
                             setAttachments(e.target.files && e.target.files ? e.target.files : [])
                           }}/>
                  </InputBox>
                </Col>

                {users && users.length ? <Col span={24}>
                  <div className={'guestEmailBox'}>
                    <ul>
                      {users.map((item, index) => {
                        return (
                          <li className={'emailTag'} key={index}>
                            {item.name} ({item.companyName ? item.companyName : item.email})
                            <Icon type={'close'}
                                  onClick={() => removeAgentEmail(index)}
                                  className={'emailIcon'}/>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </Col> : null}
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="btn">
                  Submit
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}


const BroadcastMailWrapped = Form.create()(BroadcastMailBroadcastMail)
export default BroadcastMailWrapped
