import {apiUrl} from '../../../settings'

export const addCounsellingUrl = () => {
    return apiUrl + '/addCounselling'
}

export const receptionDashboardUrl = () => {
    return apiUrl + '/receptionDashboard'
}

export const getDashboardCounsellorUrl = () => {
    return apiUrl + '/getDashboardCounsellor'
}

export const getEnquiryByUserUrl = () => {
    return apiUrl + '/getEnquiryByUser'
}

export const updateCounsellorAvailabilityUrl = () => {
    return apiUrl + '/updateCounsellorAvailability'
}

export const counsellingHistoryUrl = () => {
    return apiUrl + '/counsellingHistory'
}

export const updateEnquiryUrl = () => {
    return apiUrl + '/updateEnquiry'
}


export const stopCounsellorWaitingUrl = () => {
    return apiUrl + '/stopCounsellorWaiting'
}


export const counsellorCountryWiseUrl = () => {
    return apiUrl + '/counsellorCountryWise'
}

export const receptionDataCountryWiseUrl = () => {
    return apiUrl + '/receptionDataCountryWise'
}

export const counsellingListUrl = () => {
    return apiUrl + '/counsellingList'
}

export const allCounsellingListUrl = () => {
    return apiUrl + '/api/allCounsellingList'
}

export const counsellingSlotUrl = () => {
    return apiUrl + '/counselling/slot'
}

export const deleteCounsellingSlotUrl = () => {
    return apiUrl + '/counselling/deleteSlot'
}
export const addStudentCounsellingUrl = () => {
    return apiUrl + '/counselling/addCounselling'   //-- post  - fields [countryId, date, slot]
}

export const getSlotbyDateUrl = () => {
    return apiUrl + '/counselling/availableSlotList' //-- post - fields [countryId, date, slot] 
}

export const getSlotByCounsellorUrl = () => {
    return apiUrl + '/counselling/slotByCounsellor' // -post method - fields [counsellorId, date]
}

export const updateCounsellingSlotUrl = () => {
    return apiUrl + '/counselling/updateCounselling'  //post method - fields [counsellingId, status, reason]
}

export const changeCounsellingDateUrl = () => {
    return apiUrl + '/counselling/counsellingDateChange'  //post method - fields [counsellingId, date, slot]
}
export const getStudentCounsellingUrl = () => {
    return apiUrl + '/counselling/booked'         //get method - fields [studentId]
}
export const bookedCounsellingUrl = () => {
    return apiUrl + '/counselling/booked'
}
export const counsellingSlotListUrl = () => {
    return apiUrl + '/counselling/slotList'
}
export const getSingleCounsellingUrl = () => {
    return apiUrl + '/counselling/getSingleCounselling'
}

export const getStudentByCounsellorUrl = () => {
    return apiUrl + '/counselling/list'            //get method - fields [counsellorId]
}

export const addCounsellingByCounsellorUrl = () => {
    return apiUrl + '/counselling/addCounsellingByCounsellor'    //post method -- fields [counsellorId, studentId, slot, date]
}

export const addCounsellingFollowuprUrl = () => {
    return apiUrl + '/api/counselling-followups'    
}