import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getSingleSelectedCourse, updateGicStatusInStudentFxn } from '../actions/chooseStudent'
import { Card, Tag, Button, Tooltip, Drawer, Avatar, Form, Input, Modal, notification } from 'antd'
import {
  displayDate,
  InputBox,
  nameAvatar,
  longDisplayDate, departmentObj, cambieOnshoreUserList, countryIds, agentTeamTypes
} from '../../../components/_utils/appUtils'
import ApplicationStatusComponent from './statusComponent'
import NotesBlock from './agent/notesBlock'
import SingleAppDocumentComponent from './singleAppDocumentComponent'
import PendencyComponent from './pendencyComponent'
import SampleList from '../../sampleDocument/views/sampleContent'
import { push } from 'connected-react-router'
import { moveToNextDesk, submitNote, uploadTuitionFeesByAgentFxn } from '../../student/actions/student'
import moment from 'moment'
import { getUser } from '../../users/actions/user'
import { getPendingPendency } from '../../../components/_utils/utils'
import SelectPendency from './selectPendency'
import { DirectUniversityCheck } from '../../../components/_utils/appUtils'
import AddGicDraftDrawer from '../draftApplicationList/addGicDrawer'

const { TextArea } = Input

const SingleApplication = (props) => {
  let { user } = props
  const dispatch = useDispatch()
  const [state, setState] = useState({
    studentData: {},
    description: ''
  })
  const [appState, setAppState] = useState({
    applicationObj: {}
  })
  const [pendencyVisible, setPendencyVisible] = useState(false)
  let { studentData } = state
  let { createdAt, applications, englishRequirments, education } = studentData
  let [singlePendency, setSinglePendency] = useState({})
  let [visibleSampleDrawer, setVisibleSampleDrawer] = useState(false)
  let [profileImage, setProfileImage] = useState({})
  const [addPendencyDrawer, setAddPendencyDrawer] = useState(false)
  const [refreshKey, setRefreshKey] = useState(moment())
  let [visibleGicDrawer, setVisibleGicDrawer] = useState(false)
  let [gicStatus, setGicStatus] = useState('')
  let [gicConfirmModal, setGicConfirmModal] = useState(false)

  const [moveState, setMoveState] = useState({
    visible: false
  })

  const getSingleApplication = async () => {
    let searchParams = new URLSearchParams(window.location.search)
    let studentId = searchParams.get('studentId')
    let appId = searchParams.get('appId')

    if (appId && studentId) {
      let userResp = await dispatch(getUser(user._id))
      let resp = await dispatch(getSingleSelectedCourse({ appId, studentId }))
      if (resp && resp.status === 'Active') {
        let { applications: appData } = resp
        if (userResp.showAgent) {
          setState({
            ...state,
            studentData: resp
          })
          if (resp && resp.profile && resp.profile.url) {
            setProfileImage(resp.profile)
          }
        } else if (
          resp.studentId === userResp._id || userResp.userType === 'student'
        ) {
          setState({
            ...state,
            studentData: resp
          })
          if (resp && resp.profile && resp.profile.url) {
            setProfileImage(resp.profile)
          }

        } else {
          if (userResp.department === departmentObj.assDepartment ||
              userResp.department === departmentObj.offerDepartment ||
              userResp.userType === 'marketingManager'
              || user.userType == 'cambieMarketingManager'
              || user.userType == 'directHead'
            || cambieOnshoreUserList.includes(user.userType)
            || (userResp.userType == 'branchManager' && (user.showOnShoreFcmt || user.showOnShoreCambie))) {
            setState({
              ...state,
              studentData: resp
            })
          } else {
            if (resp.agentId && resp.agentId._id === userResp._id ||
              (resp.applications.addByUserId && resp.applications.addByUserId._id === userResp._id) ||
              (resp.applications.teamId && resp.applications.teamId === userResp._id) ||
              (resp.addByUserId && resp.addByUserId === userResp._id) ||
              userResp.showAllFcmtList) {

              setState({
                ...state,
                studentData: resp
              })

              if (resp && resp.profile && resp.profile.url) {
                setProfileImage(resp.profile)
              }

              if (appData.status == 'LOA/Fee Receipt Issued' && appData.universityCountry._id == countryIds.canada && agentTeamTypes.includes(userResp.userType)) {
                if (!appData.gicAccountOpened && !appData.gicStatus) {
                  setGicConfirmModal(true)
                }
              }

            } else {
              dispatch(push('/dashboard'))
            }
          }
        }
      } else {
        // Instead of redirecting, you can handle it differently for students
        // For example, display a message or handle it in a different way
        console.log('Student status is not active')
      }
    }
  }


  useEffect(() => {

      getSingleApplication()
  }, [])

  const events = {
    showPendencyDrawer: (pendency) => {
      setSinglePendency(pendency)
      setPendencyVisible(true)
    },
    hidePendencyDrawer: (pendencyArr) => {
      setPendencyVisible(false)
      let { studentData } = state
      if (pendencyArr && pendencyArr.length) {
        studentData.applications.pendencyArr = pendencyArr
        setState({
          ...state,
          studentData
        })
      }
    },
    openAddPendencyDrawer: (value) => {
      setAppState({
        ...appState,
        applicationObj: value
      })
      setAddPendencyDrawer(true)
    },
    reloadAndClose: () => {
      getSingleApplication()
      setAddPendencyDrawer(false)
    },
    closeAddPendencyDrawer: () => {
      setAppState({
        ...appState,
        applicationObj: {}
      })
      setAddPendencyDrawer(false)
    },
    reloadFxn: () => {
      getSingleApplication()
    },

    showSampleDrawer: () => {
      setVisibleSampleDrawer(true)
    },
    hideSampleDrawer: () => {
      setVisibleSampleDrawer(false)
    },
    showDrawer: () => {
      setState({ ...state, noteDrawerVisible: true })
    },
    hideDrawer: () => {
      setState({ ...state, noteDrawerVisible: false })
    },
    setLocalState: (e) => {
      let { name, value } = e.target
      setState({
        ...state,
        [name]: value
      })
    },
    chooseDocument: (e) => {
      let { name, files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          document: files[0]
        })
      }
    },
    handleSubmit: async () => {
      let obj = {
        studentId: studentData._id,
        note: state.note,
        applicationId: applications._id
      }
      let fd = new FormData()
      fd.append('obj', JSON.stringify(obj))
      fd.append('document', state.document)
      let data = await dispatch(submitNote(fd))
      if (data && !data.error) {
        dispatch({ type: 'START_PUSH_NOTE_BY_REDUX', noteId: data.noteId })
        setState({
          ...state,
          note: '',
          document: '',
          documentKey: moment(),
          noteDrawerVisible: false
        })

      }
    },
    showMoveButton: () => {
      if (applications.status == 'Tuition Fee Paid & TT Received' &&
        applications.universityCountry && applications.universityCountry._id == countryIds.canada &&
        user.userType === 'agent'
      ) {
        return true
      } else {
        return false
      }
    },

    moveDrawerShow: async () => {
      setMoveState((prevState) => {
        return {
          ...prevState,
          visible: true
        }
      })
    },

    moveDrawerHide: async () => {
      setMoveState((prevState) => {
        return {
          ...prevState,
          visible: false
        }
      })
    },

    gicModalFxn: () => {
      /* return new Promise((resolve) => {
         Modal.confirm({
           title: 'GIC Request',
           content: 'Open GIC with us & Get $175 CAD?',
           width: '30%',
           okText: 'Yes',
           cancelText: 'Skip',
           footer: false,
           onOk: () => {
             // events.showGicDrawer()
           },
           onCancel: async () => {
             // await events.updateGicStatusInStudentFxn()
           }
         })
       })*/
    },
    onGicSkip: async (status) => {
      let resp = await dispatch(updateGicStatusInStudentFxn({
        studentId: studentData._id,
        applicationId: applications._id,
        gicStatus: status
      }))
      if (resp && resp.success) {
        setGicConfirmModal(false)
        getSingleApplication()
      }
    },
    showGicDrawer: () => {
      setVisibleGicDrawer(true)
    },
    hideGicDrawer: () => {
      setVisibleGicDrawer(false)
    },
    submitGicDrawer: () => {
      setVisibleGicDrawer(false)
      setGicStatus('Yes')
      setState({
        ...state,
        moveToNextDrawerVisible: true
      })
    },
    moveToNextDeskFxn: async () => {

      let { document, description } = state
      if (!document || (document && !document.name)) {
        return notification.error({
          message: 'Please choose document.'
        })
      }
      if (!description) {
        return notification.error({
          message: 'Please enter description.'
        })
      }
      let obj = {
        studentId: studentData._id,
        description: state.description,
        applicationId: applications._id,
        universityId: applications.courseUniversity._id,
        countryId: applications.universityCountry._id,
        status: 'LOA/Fee Receipt Issued'
      }


      let fd = new FormData()
      fd.append('obj', JSON.stringify(obj))
      fd.append('document', state.document)

      let data = await dispatch(uploadTuitionFeesByAgentFxn(fd))

      if (data && data.success) {
        events.moveDrawerHide()

        if (!applications.gicAccountOpened) {
          setGicConfirmModal(true)
        }

        if (data.status) {
          applications.status = data.status
          applications.statusList = data.statusList
        }
        setState((prevState) => {
          return {
            ...prevState,
            description: '',
            document: {}
          }
        })
        setRefreshKey(moment())
      }
    }

  }


  return (
    <React.Fragment>
      {studentData && studentData._id ?
        <div className='student-application-detail mt-4'>
          <div className='row'>

            <div className='col-lg-8 col-12'>
              <div className='detail-list w-100 card'>
                <h5>student application details</h5>
                <ul>
                  <li>
                    <strong>student ID</strong>
                    <div> {studentData.studentId ?
                      <Tag className={'successTag'}>{studentData.studentId}</Tag> : null}</div>
                  </li>
                  <li>
                    <strong>student name</strong>
                    <div> {studentData.name ? studentData.name : null}</div>
                  </li>
                  <li>
                    <strong>application ID</strong>
                    <div>{applications && applications.id ? applications.id : null}</div>
                  </li>
                  <li>
                    <strong>email</strong>
                    <div>{studentData.email && studentData.email ? studentData.email : null}</div>
                  </li>
                  <li>
                    <strong>program title</strong>
                    <div>{applications && applications.courseName ? applications.courseName : ''}</div>
                  </li>
                  <li>
                    <strong>fees</strong>
                    <div>{applications && applications.tuitionFee ? applications.tuitionFee : null}</div>
                  </li>

                  <li>
                    <strong>intake</strong>
                    <div> {applications && applications.intake && applications.intake.month ? `${applications.intake.month}, ${applications.intake.year}` : ''}</div>
                  </li>
                  <li>
                    <strong>english test</strong>
                    <div>
                      {englishRequirments && englishRequirments.examType ? <React.Fragment>
                        <b>{englishRequirments.examType}</b>
                        {englishRequirments.examType !== 'I don\'t have this' &&
                        <span className='test d-flex flex-row align-items-center'>
                                                        <p>O: {englishRequirments.overall}</p>
                                                        <p>L: {englishRequirments.listening}</p>
                                                        <p>S: {englishRequirments.speaking}</p>
                                                        <p>W: {englishRequirments.writing}</p>
                                                        <p>R: {englishRequirments.reading}</p>
                                                    </span>}
                      </React.Fragment> : null}
                    </div>
                  </li>
                  {englishRequirments && englishRequirments.examType && englishRequirments.examType == 'I don\'t have this' ?
                    <li>
                      <strong>English 12Th Marks</strong>
                      <div>
                        <b>{englishRequirments.english12ThMarks}</b>
                      </div>
                    </li> : null}
                  {englishRequirments && englishRequirments.examinationDate ? <li>
                    <strong>Examination Date</strong>
                    <div>
                      <b>{displayDate(englishRequirments.examinationDate)}</b>
                    </div>
                  </li> : null}
                  <li>
                    <strong>education</strong>
                    <div className='d-flex flex-column'>
                      {education && education.qualification ? <React.Fragment>
                        <div className='mb-3'>
                          <b>{education.qualification}</b>
                          <span className='test d-flex flex-row align-items-center'>
                                                        <p>Year {education.passingYear}</p>
                                                        <p>{education.percentage} %</p>
                                                    </span>
                        </div>
                        <div>
                          <b></b>
                          <span className='test d-flex flex-row align-items-center'>
                                                        {education.maths ? <p>M {education.maths}</p> : null}
                            {education.english ? <p>E {education.english}</p> : null}
                                                    </span>
                        </div>
                      </React.Fragment> : null}
                    </div>
                  </li>
                  {education && education.board ? <li>
                    <strong>Board/University</strong>
                    <div className='d-flex flex-column'>
                      <div>
                        <b>{education.board}</b>
                      </div>
                    </div>
                  </li> : null}
                  <li>
                    <strong>applied on</strong>
                    <div>{displayDate(studentData.createdAt)}</div>
                  </li>
                  <li>
                    <strong>application level</strong>
                    <div>{applications && applications.courseId && applications.courseId.courseLevel ? applications.courseId.courseLevel : null}</div>
                  </li>
                  <li>
                    <strong>institute</strong>
                    <div>{applications && applications.courseUniversity && applications.courseUniversity.universityName ? applications.courseUniversity.universityName : null}</div>
                  </li>
                  <li>
                    <strong>duration</strong>
                    <div> {applications && applications.courseId && applications.courseId.courseDuration ? applications.courseId.courseDuration : null}</div>
                  </li>
                  <li>
                    <strong>pendency</strong>
                    <div> {applications && applications.pendencyArr && applications.pendencyArr.length ?
                      <Tooltip title={'Pendency'}>
                                                <span onClick={() => events.openAddPendencyDrawer(studentData)}
                                                      className={'label label_sm label-success'}>{getPendingPendency(applications.pendencyArr)}</span>
                      </Tooltip>

                      : null}</div>
                  </li>


                </ul>
              </div>
            </div>
            <div className='col-lg-4 col-12'>
              <div className='profile-main'>
                <div className='profile-box-main'>
                  {profileImage && profileImage.url ?
                    <Avatar size={150} src={profileImage.url}></Avatar> :
                    <Avatar size={150}>{nameAvatar(studentData.name)}</Avatar>}
                  {/*<span><img src="/dist/img/user.png" alt=""/></span>
                <a><img src="/dist/img/upload-plus.svg" alt=""/><input type="file"/></a>*/}
                </div>
                <div className='profile-box-inner'>
                  <h5>{studentData.name ? studentData.name : null}</h5>
                  <p>{applications && applications.courseName ? applications.courseName : ''}</p>
                  <p>{applications && applications.courseId && applications.courseId.courseDuration ? applications.courseId.courseDuration : null}</p>
                  <p>{applications && applications.courseUniversity && applications.courseUniversity.universityName ? applications.courseUniversity.universityName : null}</p>
                </div>
                {applications.courseUniversity && applications.courseUniversity._id && !DirectUniversityCheck(applications.courseUniversity._id) ?
                  <div>

                    {events.showMoveButton() ?
                      <div className={'alignCenter mb10'}>
                        <a className={'btn btn-primary roundNew sm'} onClick={() => {
                          events.moveDrawerShow()
                        }}>Upload Tuition Fees Receipt</a>
                      </div> : null}


                    {applications && applications.status ?
                      <ApplicationStatusComponent applications={applications}
                                                  createdAt={createdAt} /> : null}
                  </div> : null}
                {applications.courseUniversity && applications.courseUniversity._id && DirectUniversityCheck(applications.courseUniversity._id) ?
                  <div>
                    <div className='application-current-status'>
                      <h5>application current status</h5>

                      <ul>
                        {applications && applications.status && applications.statusList && applications.statusList.length ? applications.statusList.map((item, key) => {
                          return (
                            <Tooltip title={longDisplayDate(item.date)} key={key}>
                              <li className={`statusBox ${item.name === applications.status ? 'bgTheme' : ''}`}
                                  key={key}>
                                <React.Fragment>
                                  {item.status}
                                  {item.status == applications.status ?
                                    <img src='/dist/img/check.svg' alt='' /> : null}
                                </React.Fragment>
                              </li>
                            </Tooltip>
                          )
                        }) : null}
                      </ul>
                    </div>
                  </div> : null}

              </div>
            </div>

          </div>

          <div className='row mt-4' key={refreshKey}>
            <div className='col-lg-12'>
              <div className={'appProfile'}>
                <div className={'align-self-stretch'}>
                  {applications && applications._id ?
                    <NotesBlock applicationId={applications && applications._id}
                                studentId={studentData._id}
                                showNoteDrawer={events.showDrawer} /> : null}
                </div>
              </div>

            </div>
          </div>

          <div className='row mt-4 documents-section' key={refreshKey}>
            <div className='col-lg-12'>
              {applications && applications._id ?
                <SingleAppDocumentComponent
                  user={user}
                  studentId={studentData._id}
                  applicationId={applications._id}
                  courseUniversityId={applications.courseUniversity && applications.courseUniversity._id ? applications.courseUniversity._id : ''}
                  userType={user.userType} /> : null}
            </div>
          </div>

        </div> : null}

      <Drawer width={700}
              title={'Create Note'}
              placement='left'
              visible={state.noteDrawerVisible}
              onClose={events.hideDrawer}>
        <Card bordered={false}>
          <Form>
            <InputBox title={'Note'}>
              <TextArea rows={7} onChange={events.setLocalState} name={'note'}
                        className={'form-control'}
                        placeholder={'Note'} value={state.note} />
            </InputBox>
            <InputBox title={'Document'}>
              <Input type={'file'} name={'document'} id={'document'} key={state.documentKey}
                     className={'form-control'}
                     onChange={(e) => {
                       events.chooseDocument(e)
                     }} />
            </InputBox>
            <Form.Item className={'mt20 alignRight'}>
              <Button type='primary' onClick={events.handleSubmit}>
                SUBMIT
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Drawer>

      {addPendencyDrawer ?
        <SelectPendency visible={addPendencyDrawer}
                        applicationObj={appState.applicationObj}
                        onAddPendencyClose={events.closeAddPendencyDrawer}
                        onClose={events.reloadAndClose}
                        reloadFxn={events.reloadFxn}
        /> : null}


      {pendencyVisible ? <PendencyComponent
        visible={pendencyVisible}
        singlePendency={singlePendency}
        studentData={studentData}
        closePendencyDrawer={events.hidePendencyDrawer}
        pendencyArr={applications.pendencyArr} /> : ''}

      {visibleSampleDrawer ?
        <Drawer title={'Sample'}
                width={800}
                onClose={() => events.hideSampleDrawer()}
                visible={visibleSampleDrawer}>
          <SampleList pageType={''} />
        </Drawer> : ''}


      {moveState.visible ?
        <Modal
          title={`Tuition Fee Receipt`}
          style={{ top: 20 }}
          okText={'Submit'}
          cancelText={'Cancel'}
          visible={moveState.visible}
          onOk={() => events.moveToNextDeskFxn()}
          onCancel={() => events.moveDrawerHide()}>
          <Form>
            <InputBox title={'*Tuition Fee Receipt'}>
              <Input type={'file'} name={'document'} id={'document'} key={state.documentKey}
                     className={'form-control'}
                     onChange={(e) => {
                       events.chooseDocument(e)
                     }} />
            </InputBox>


            <InputBox title={`*Description`}>
              <TextArea rows={7} onChange={events.setLocalState} name={'description'}
                        placeholder={'Description'}
                        className={'form-control'}
                        value={state.description} />
            </InputBox>


          </Form>
        </Modal> : ''}

      {visibleGicDrawer ? (
        <AddGicDraftDrawer
          visible={visibleGicDrawer}
          studentData={studentData}
          onClose={() => events.hideGicDrawer()}
          onSubmit={() => events.submitGicDrawer()}
        />
      ) : null}

      {gicConfirmModal ?
        <Modal
          title={<>
            <h5>GIC Request</h5>
            <h6 className={'colorDarkPrimary mt10'}>
              Open GIC with us & Get $175 CAD?
            </h6>
          </>}
          okText={'Submit'}
          closable={false}
          cancelText={'Cancel'}
          footer={false}
          size={'small'}
          width={'35%'}
          visible={gicConfirmModal}>
          <div className={'alignRight'}>
            <a className={'btn btn-danger roundNew md '} onClick={() => {
              events.onGicSkip('No')
            }}>Skip</a>
            <a className={'btn btn-success roundNew md ml10'} onClick={() => {
              setGicConfirmModal(false)
              events.showGicDrawer()
            }}>Continue</a>
            <a className={'btn btn-default roundNew md ml10'} onClick={() => {
              events.onGicSkip('Continue')
            }}>Later</a>
          </div>
        </Modal> : null}


    </React.Fragment>
  )
}

export default SingleApplication
