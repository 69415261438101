import {customAxios as axios, getToken} from "../../../request";
import {requestForCallUrl, addCallInWithdrawalUrl, addReceiptInWithdrawalUrl, generateInvoiceUrl} from "../apis";
import {hidePageLoad, showPageLoad} from "../../../modules/actions";
import {notification} from "antd";

export const requestForCallFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(requestForCallUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({message: data.message || "Error"})
    } else {
        notification.success({message: data.message || "Success"})
    }
    return data;
}

export const addCallInWithdrawalFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addCallInWithdrawalUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({message: data.message || "Error"})
    } else {
        notification.success({message: data.message || "Success"})
    }
    return data;
}

export const generateInvoiceFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(generateInvoiceUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({message: data.message || "Error"})
    } else {
        notification.success({message: data.message || "Success"})
    }
    return data;
}


export const addReceiptInWithdrawalFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addReceiptInWithdrawalUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({message: data.message || "Error"})
    } else {
        notification.success({message: data.message || "Success"})
    }
    return data;
}
