import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { marketingUserForAgentsFxn } from '../actions/dashboardAction'
import _ from 'lodash'
import { Avatar, Icon, Row, Col } from 'antd'
import { getDirectUniversityNameForSupportingTeam } from '../../../components/_utils/appUtils'
import RequestCallback from '../drawers/requestCallback'

let countryColors = {
  UK: '#ff0066',
  USA: '#ff0066',
  Australia: '#003399',
  'Australia Onshore': '#003399',
  Canada: '#ad2125',
  'Canada Onshore': '#ad2125',
  Germany: '#ad2125',
  'Cyprus': '#ad2125',
  'Europe': '#ad2125',
  'New Zealand': '#ad2125',
  'Singapore': '#ad2125',
  'France': '#ad2125',
  'Netherlands': '#ad2125',
  'Switzerland': '#ad2125'
}
const HelpComponent = (props) => {
  let dispatch = useDispatch()
  let [marketingUsers, setMarketingUsers] = useState([])
  let [requestACallVisible, setRequestACallModal] = useState({
    visible: false,
    assignedTo: '',
    countryId: '',
    universityId: '',
    requestType: ''
  })
  useEffect(() => {
    loadMarketingUsers()
  }, [])
  let loadMarketingUsers = async () => {
    let { data } = await dispatch(marketingUserForAgentsFxn({}))
    setMarketingUsers(data)
  }

  const events = {
    showRequestACall: async (data) => {
      let { marketingUserId, countryId, universityId } = data
      setRequestACallModal({
        visible: true,
        assignedTo: marketingUserId._id,
        countryId: countryId._id,
        universityId: universityId,
        requestType: 'supportingCallback'
      })
    },
    hideRequestACall: async () => {
      setRequestACallModal({
        visible: false,
        assignedTo: '',
        countryId: '',
        universityId: '',
        requestType: 'supportingCallback'
      })
    }
  }
  return (
    <>
      <div className='row  mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>Supporting Team</h5>
            </div>
            <div>
              <div className='card-pane-right' style={{ padding: 20 }}>
                <div className='nav flex-column nav-pills w-100'>
                  <Row gutter={50} className={'flexWrap'}>
                    {marketingUsers && marketingUsers.length ? marketingUsers.map((eachData, key) => {
                      let { marketingUserId: eachUser, countryId, university } = eachData
                      let { countryName } = countryId
                      return (
                        <Col sm={8} xs={8} md={12} lg={12} xl={8} xxl={8}>
                          <div className={'marketingUserBox'}>
                            <div className={'managerHead'}
                                 style={{
                                   backgroundColor: countryColors[countryName]
                                 }}>
                                                                              <span style={{
                                                                                marginLeft: 'auto',
                                                                                fontSize: 14
                                                                              }}>
                                                                                DESIGNATED MANAGER
                                                                              </span>
                            </div>

                            <div className={'managerBody ais'}>
                              <div className={'center'}
                                   style={{ paddingRight: 10 }}>
                                <Avatar
                                  size={60}
                                  style={{
                                    border: `3px solid ${countryColors[countryName]}`,
                                    marginTop: 10,
                                    marginLeft: 15,
                                    marginRight: 15
                                  }}
                                  src={eachUser && eachUser.logo && eachUser.logo.url ? eachUser.logo.url : '../dist/img/user.png'}
                                />
                              </div>
                              <div className={'row'}>
                                <div className={'col-md-12'}
                                     style={{
                                       textAlign: 'left',
                                       padding: 5,
                                       fontSize: 12,
                                       color: '#585858'
                                     }}>
                                  <div>
                                    <div style={{ fontWeight: 'bold' }}>
                                      {countryId && countryId.flag && countryId.flag.url ?
                                        <>
                                          <img src={countryId.flag.url}
                                               style={{ height: 20 }} />
                                        </> : null}
                                      &nbsp; {getDirectUniversityNameForSupportingTeam(university)} ({countryName})
                                    </div>
                                    <div className={'mt5'}>
                                      <Icon type={'user'} />&nbsp;
                                      {eachUser.name}
                                    </div>
                                    <Icon
                                      type={'mail'} />&nbsp; {eachUser.email}
                                    <br />
                                    <Icon type={'phone'} />&nbsp;
                                    {eachUser.countryCode} {eachUser.mobile}


                                  </div>
                                </div>
                                <div className={'col-md-12'}>
                                  <a className={'btn btn-default roundNew2 sm'}
                                     onClick={() => events.showRequestACall(eachData)}><Icon
                                    type={'phone'} /> &nbsp; Request A Call Back</a>
                                </div>

                              </div>
                            </div>


                          </div>

                        </Col>
                      )
                    }) : null}

                  </Row>
                </div>

              </div>
            </div>
          </div>
        </div>
        {requestACallVisible.visible ?
          <RequestCallback
            {...requestACallVisible}
            onClose={events.hideRequestACall}
          /> : null}

      </div>
    </>
  )
}
export default HelpComponent
