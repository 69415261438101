import { apiUrl } from "../../../settings";

export const addTaskUrl =() => {
    return apiUrl + '/task/addNew'
}

export const updateTaskUrl =() => {
    return apiUrl + "/task/update";
}

export const singleTaskUrl =() => {
    return apiUrl + "/task/singleTask";
}

export const deleteTaskUrl =() => {
    return apiUrl + "/task/delete";
}

export const taskListUrl =() => {
    return apiUrl + "/task/list";
}

export const priorityUpdateUrl =() => {
    return apiUrl + "/task/priorityUpdate";
}

export const assignedUserListUrl =() => {
    return apiUrl + '/task/assignedUserList'
}

export const taskGroupedUrl =() => {
    return apiUrl + "/task/grouped";
}

export const addFutureTaskUrl = () => {
    return apiUrl + "/futureTask/add";
}

export const updateFutureTaskUrl = () => {
    return apiUrl + "/futureTask/update";
}

export const deleteFutureTaskUrl = () => {
  return apiUrl + '/futureTask/delete'
}

export const futureTaskListUrl = () => {
  return apiUrl + '/futureTask/list'
}
export const futureTaskGroupedUrl = () => {
  return apiUrl + '/futureTask/grouped'
}
export const futureTaskSingleUrl = () => {
  return apiUrl + '/futureTask/singleTask'
}
export const addTaskCommentUrl = () => {
  return apiUrl + '/task-comment/add'
}

export const taskCommentListUrl = () => {
  return apiUrl + '/task-comment/list'
}

export const taskActivityUrl = () => {
  return apiUrl + '/task-activity/list'
}
export const taskUsersUrl = () => {
  return apiUrl + '/task/users'
}
