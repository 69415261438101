import { apiUrl } from '../../../../settings'

export const addTrainingRequestUrl = () => {
  return apiUrl + '/addTrainingRequest'
}

export const trainingRequestListUrl = () => {
  return apiUrl + '/trainingRequest'
}
export const updateStatusUrl = () => {
  return apiUrl + '/updateTrainingRequest'
}
export const addNoteUrl = () => {
  return apiUrl + '/addNoteReqstTrng'
}
export const notesListUrl = () => {
  return apiUrl + '/listNotesReqstTrng'
}
