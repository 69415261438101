import { customAxios as axios, getToken } from '../../request'
import { getUniversityWiseReportUrl } from './apis'
import { notification } from 'antd'
import { hideLoader, hidePageLoad, showLoader, showPageLoad } from '../../modules/actions'

export const getUniversityWiseReportFxn = (filters) => async (dispatch) => {
  let config = {
    params: { ...filters },
    ...getToken()
  }
  dispatch(showPageLoad())
  let resp = await axios.get(getUniversityWiseReportUrl(), config)
  dispatch(hidePageLoad())
  if (resp && resp.error) {
    notification.error({
      message: resp.message || 'Error'
    })
  }
  return resp.data
}
