import React, { useState, useEffect } from 'react'
import { CustomRowTable } from '../../../components/_utils/RowTable'
import { Drawer, Card, Skeleton, Tooltip, Icon } from 'antd'
import { useDispatch } from 'react-redux'
import { countryIds } from '../../../components/_utils/appUtils'
import { getReferralSetting } from '../actions/index'
import { getUser } from '../../users/actions/user'

const getNotes = (notes) => {
  if (notes && notes.length) {
    return (
      <ul style={{ listStyle: 'disc' }}>
        {notes.map((item, index) => {
          return (
            <li key={index}>{item}</li>
          )
        })}
      </ul>
    )
  } else {
    return null
  }
}

const ConditionDrawer = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose } = props
  let [loading, setLoading] = useState(true)
  let [currentUser, setCurrentUser] = useState(true)
  let [referralSetting, setReferralSetting] = useState({})

  const loadCurrentUser = async () => {
    let user = (localStorage.getItem('user') != 'undefined') ? JSON.parse(localStorage.getItem('user')) : null
    // let resp = await dispatch(getUser(user._id))
    setCurrentUser(user)
  }

  useEffect(() => {
    loadReferralSetting()
    // loadCurrentUser()
  }, [])


  const loadReferralSetting = async () => {
    let { data } = await dispatch(getReferralSetting())
    setReferralSetting(data)
    setLoading(false)
  }


  return (
    <Drawer visible={visible}
            title={'Conditions'} width={'50%'}
            onClose={onClose}>
      <Card>
        {loading ? <Skeleton loading={loading} /> :
          <div className={'infoUni tableBox striped'}>
            <CustomRowTable title={'Terms & Conditions'} value={(
              <div>
                {referralSetting && referralSetting.conditions && referralSetting.conditions.length ?
                  <div className={'conditionList'}>
                    {referralSetting.conditions.map((item, index) => {
                      return (
                        <div key={index}>
                          <p style={{ marginRight: 10, fontWeight: 'bold' }}>
                            {index + 1}.
                          </p>
                          <p>
                            {item}
                          </p>
                        </div>
                      )
                    })}
                  </div>
                  : null}
              </div>
            )} />
          </div>
        }
      </Card>
    </Drawer>
  )
}


export { ConditionDrawer }
