import React, {useEffect, useRef, useState} from "react"
import {universityCommissionListFxn} from "../actions";
import {Card, Drawer, Row, Col, Select, Button} from "antd";
import {useDispatch} from "react-redux";
import AgentInfoComponent from "../Components/agentInfoComponent";
import {
    displayDate,
    filterOption,
    Intakes,
    YearList,
    getSemesterList, CapText,
} from "../../../components/_utils/appUtils";
import {TableComp} from "sz-react-utils-lite";
import {getBranchManagerAgentList} from "../../users/actions/user";
import moment from "moment";

let initFilter = {
    intake: "",
    intakeYear: "",
    courseName: "",
    semester: "",
    name: "",
    agentId: "",
}
const {Option} = Select

const CommissionListComponent = (props) => {
    let tableRef = useRef()
    let dispatch = useDispatch()
    let {onSubmit, selectedCommissions, onClose, record} = props;
    const [filterState, setFilterState] = useState(initFilter)
    let semesterList = getSemesterList()
    let columns = [
        {
            title: '#',
            dataIndex: '_id',
            key: '_id',
            width: 60,
            render: (item, record) => {
                let disabled = selectedCommissions.includes(item)
                return (
                    <>
                        <div className={'customCheckbox'}
                             onClick={() => {
                                 if (!disabled) {
                                     onSubmit(record)
                                     onClose()
                                 }
                             }}>
                            {disabled ?
                                <img src={'../uniBtn/checked.png'}/>
                                :
                                <img src={'../uniBtn/unChecked.png'}/>
                            }
                        </div>
                    </>
                )
            }
        },
        {
            title: 'Student',
            dataIndex: 'name',
            key: 'name',
            render: (item, record) => {
                let {studentId, commissionDetails, appId} = record;
                return (
                    <>
                        <CapText>{item}</CapText>
                        {studentId.studentId ? <span>( Student ID : {studentId.studentId})</span> : ""}
                        {commissionDetails && commissionDetails.offerLetterStudentId ? <div>
                            Offer S. ID : {commissionDetails.offerLetterStudentId}
                        </div> : null}
                        {appId ? <div>
                            App ID : {appId}
                        </div> : null}
                    </>
                )
            }
        },
        {
            title: 'Semester',
            dataIndex: 'semester',
            key: 'semester',
        },
        {
            title: 'Semester Fees',
            dataIndex: 'semesterFee',
            key: 'semesterFee',
            render: (item, record) => {
                let {currency} = record;
                return (
                    <>
                        {currency} {item}
                    </>
                )
            }
        },
        {
            title: 'Commission',
            dataIndex: 'amount',
            key: 'amount',
            render: (item, record) => {
                let {currency} = record;
                return (
                    <>
                        {currency} {item}
                    </>
                )
            }
        },
        {
            title: 'Program',
            dataIndex: 'courseName',
            key: 'courseName',
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoiceDate',
            key: 'invoiceDate',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 150}}>
                        {item ? displayDate(item) : ""}
                    </div>
                )
            }
        },
        {
            title: 'Intake',
            key: 'intake',
            dataIndex: 'intake',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 150}}>
                        {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
                    </div>
                )
            }
        },


        {
            title: 'Agent',
            key: 'agentId',
            dataIndex: 'agentId',
            width: 220,
            render: (item, record) => {
                return (
                    <div style={{width: 180}}>
                        {item && item.name ? item.name : null}<br/>
                        {item && item.companyName ? `(${item.companyName})` : null}
                    </div>
                )
            }
        },
    ]


    let events = {
        updateLocalFilter: (data) => {
            setFilterState({
                ...filterState,
                ...data
            })
        },

        reload: () => {
            if (tableRef && tableRef.current) {
                tableRef.current.reload()
            }
        },
        searchFxn: () => {
            events.reload()
        },
        clearFxn: () => {
            setFilterState({
                ...filterState,
                ...initFilter
            })
            setTimeout(() => {
                events.reload()
            }, 500)
        }

    }

    const FilterComponent = (
        <Row gutter={16} className={'mt10'}>
            <Col md={6} sm={6} xs={8} lg={6}>
                <div className='sort-box-table custom-sort-box-new'>
                    <Select name='Intake' onChange={(item) => {
                        events.updateLocalFilter({intake: item})
                    }}
                            filterOption={filterOption}
                            className={'antSelect'}
                            allowClear={true}
                            showSearch={true}
                            placeholder={'Intake Month'}
                            value={filterState.intake || undefined}>
                        {Intakes && Intakes.length ? Intakes.map((item, key) => {
                            return (
                                <Option value={item} key={key}>{item}</Option>
                            )
                        }) : null}
                    </Select>
                </div>
            </Col>
            <Col md={6} sm={6} xs={8} lg={6}>
                <div className='sort-box-table custom-sort-box-new'>
                    <Select name='Intake' onChange={(item) => {
                        events.updateLocalFilter({intakeYear: item})
                    }}
                            filterOption={filterOption}
                            className={'antSelect'}
                            allowClear={true}
                            showSearch={true}
                            placeholder={'Intake Year'}
                            value={filterState.intakeYear || undefined}>
                        {YearList && YearList.length ? YearList.map((item, key) => {
                            return (
                                <Option value={item} key={key}>{item}</Option>
                            )
                        }) : null}
                    </Select>
                </div>
            </Col>
            <Col md={6} sm={6} xs={8} lg={6}>
                <div className='search-box-table custom-sort-box-new'>
                    <Select name='Search By Semester' onChange={(item) => {
                        events.updateLocalFilter({semester: item})
                    }}
                            filterOption={filterOption}
                            className={'antSelect'}
                            allowClear={true}
                            placeholder={'Semester'}
                            value={filterState.semester || undefined}>
                        {semesterList && semesterList.length ? semesterList.map((item, key) => {
                            return (
                                <Option value={item} key={key}>{item}</Option>
                            )
                        }) : null}
                    </Select>
                </div>
            </Col>

            <Col md={6} sm={6} xs={8} lg={6}>
                <div className='search-box-table round ml0'>
                    <input className='form-control form-control' type='search'
                           placeholder='Program Name...' value={filterState.courseName} onChange={(e) => {
                        events.updateLocalFilter({courseName: e.target.value})
                    }}/>
                    <img src='/dist/img/search.png' alt=''/>
                </div>
            </Col>
            <Col md={6} sm={6} xs={8} lg={6}>
                <div className='search-box-table round ml0'>
                    <input className='form-control form-control' type='search'
                           placeholder='Name...' value={filterState.name} onChange={(e) => {
                        events.updateLocalFilter({name: e.target.value})
                    }}/>
                    <img src='/dist/img/search.png' alt=''/>
                </div>
            </Col>


            <Col md={10} sm={10} xs={10} lg={10}>
                <Button onClick={() => events.searchFxn()} className={'roundBtn'}>Search</Button>
                <Button onClick={() => events.clearFxn()} className={'roundBtn'}>
                    Clear
                </Button>
            </Col>
        </Row>
    )


    const apiRequest = async (params) => {
        return new Promise(async (resolve) => {
            let {currency, agentId} = record
            if (filterState.intake) {
                params['intake.month'] = filterState.intake;
            }
            if (filterState.intakeYear) {
                params['intake.year'] = filterState.intakeYear;
            }
            if (filterState.courseName) {
                params['courseName'] = filterState.courseName;
            }
            if (filterState.name) {
                params['name'] = filterState.name;
            }
            if (filterState.semester) {
                params['semester'] = filterState.semester;
            }
            params = {
                ...params,
                agentId: agentId._id,
                currency: currency,
                status: "Commission Received",
                invoiceDate: {$lte: moment().endOf('day')}
            }
            let resp = await dispatch(universityCommissionListFxn({
                ...params,
                regExFilters: ['semester', 'courseName', 'name']
            }))
            resolve(resp)
        })

    }

    return (
        <>
            <Row gutter={16} className={'wrapBox'}>
                <Col span={6}>
                    <AgentInfoComponent agentId={record.agentId}/>
                </Col>
                <Col span={18}>
                    <Card className={'filterCard'}>
                        {FilterComponent}
                    </Card>
                </Col>
                <Col span={24}>
                    <div className={'mt20'}>
                        <div className='card-body table-responsive'>
                            <TableComp className={'table table-bordered'}
                                       apiRequest={apiRequest}
                                       columns={columns}
                                       ref={tableRef}
                                       bordered={true}/>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

const CommissionListDrawer = (props) => {
    let {visible, onClose, record} = props;
    return (
        <>
            <Drawer
                title={'University Student List'}
                visible={visible}
                width={'80%'}
                onClose={onClose}>
                <div className="form-box commissionBox">
                    <div className="card unizportal">
                        <CommissionListComponent {...props}/>
                    </div>
                </div>
            </Drawer>
        </>
    )
}
export default CommissionListDrawer
