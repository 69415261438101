import {
    Button,
    Col,
    Drawer,
    Form,
    Row,
    notification, Tooltip, Popconfirm
} from 'antd'
import React, {useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {displayDate, FileInput, InputBox} from '../../../components/_utils/appUtils'
import {
    addWhatsNewFxn, deleteWhatsNewFxn,
    listWhatsNewFxn, whatsNewStatusUpdateFxn
} from '../actions/whatsNew'
import SingleWhatsNewDrawer from "./singleWhatsNewDrawer";

let initState = {
    title: '',
    content: '',
    image: []
}

const WhatsNew = props => {
    const {
        form: {getFieldDecorator}
    } = props

    let tableRef = useRef()
    const [document, setDocument] = useState({})
    const [open, setOpen] = useState(false)
    const [formValues, setFormValues] = useState(initState)
    const [viewObj, setViewObj] = useState({
        visible: false,
        item: {}
    })
    const handleChange = data => {
        setFormValues(prevData => {
            return {
                ...prevData,
                ...data
            }
        })
    }

    const chooseDocument = e => {
        let {name, files} = e.target
        if (files && files.length) {
            setDocument(files[0])
        }
    }

    const addWhatsNew = () => {
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }
    const events = {
        showViewDrawer: (item) => {
            setViewObj({
                visible: true,
                item
            })
        },
        hideViewDrawer: () => {
            setViewObj({
                visible: false,
                item: {}
            })
        },
        updateStatus: async (_id) => {
            let resp = await dispatch(whatsNewStatusUpdateFxn({_id}))
            if (resp && resp.success) {
                tableRef.current.reload()
            }
        },
        deleteWhatsNewEvent: async (id) => {
            let resp = await dispatch(deleteWhatsNewFxn({whatsNewId: id}))
            if (resp && resp.success) {
                tableRef.current.reload()
            }
        },
    }


    const dispatch = useDispatch()
    let inputFields = [
        {key: 'title', label: 'Title', type: 'text', required: true},
        {key: 'content', label: 'Content', type: 'ckeditor', required: true},
        {
            key: 'image',
            label: 'Image',
            type: 'file',
            onChange: e => {
                chooseDocument(e)
            }
        }
    ]
    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            searchTextName: 'title',
        },
        {
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            width: 150,
            render: (item, record) => {
                return (
                    <>
                        <Popconfirm
                            title={'Are you sure, You want to Update Active Status?'}
                            onConfirm={() => {
                                events.updateStatus(record._id)
                            }}
                        >
                            {item ? <label className={'label label-success label-sm'}>Active</label> :
                                <label className={'label label-danger label-sm'}>Un-Active</label>}
                        </Popconfirm>
                    </>
                )
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 150,
            render: (item) => {
                return item ? displayDate(item) : ""
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: 100,
            render: (item, record) => {
                return (
                    <>
                        <Tooltip title={'View'}>
                            <a className='btn' onClick={() => {
                                events.showViewDrawer(record)
                            }}>
                                <img src='/dist/img/view.svg' alt=''/>
                            </a>
                        </Tooltip>
                        <Tooltip title={'Delete'}>
                            <Popconfirm title={'Are your sure, you want to delete this record?'} onConfirm={() => {
                                events.deleteWhatsNewEvent(record._id)
                            }}>
                                <a className='btn'>
                                    <img src='/dist/img/cross.svg' alt=''/>
                                </a>
                            </Popconfirm>
                        </Tooltip>
                    </>
                )
            }
        },
        /*{
          title: 'Content',
          dataIndex: 'content',
          key: 'content',
          width:400,
          searchTextName: 'content',
          render: (item, record) => {
            return renderHTML(item)
          }
        }*/

    ]

    const apiRequest = params => {
        return new Promise(async resolve => {
            let resp = await dispatch(
                listWhatsNewFxn({...params, regExFilters: ['title', 'content']})
            )
            resolve(resp)
        })
    }

    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    const handleSubmit = e => {
        const {form} = props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                if (!document || (document && !document.name)) {
                    return notification.warning({
                        message: 'Please choose document.'
                    })
                }
                let fd = new FormData()
                fd.append('obj', JSON.stringify(valData))
                fd.append('document', document)
                let data = await dispatch(addWhatsNewFxn(fd))
                if (data && !data.error) {
                    onClose()
                    tableRef.current.reload()
                    // form.resetFields()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }
    return (
        <>
            <div className="row mt-4">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="table-head d-flex align-items-center">
                            <h5>Whats New</h5>
                            <div className="search-box-table"/>
                            <div className="sort-box-table mark-btn">
                                <button className="btn" onClick={() => addWhatsNew(true)}>
                                    <img src={'../dist/icons/plus.png'} className={'plus'}/>
                                    Add Whats New
                                </button>
                            </div>
                        </div>

                        <div className="card-body table-responsive ">
                            <TableComp
                                columns={columns}
                                apiRequest={apiRequest}
                                ref={tableRef}
                                // extraProps={{ scroll: { x: 600 } }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {open ? (
                <Drawer
                    title={`What's New`}
                    onClose={onClose}
                    visible={open}
                    width={'45%'}>
                    <div className="form-box">
                        <div className="card unizportal">
                            <Form
                                onSubmit={handleSubmit}
                                style={{marginTop: 8, textAlign: 'left'}}>
                                <Row gutter={24}>
                                    {inputFields.map((item, key) => {
                                        return (
                                            <Col span={24} key={key}>
                                                {item.type == 'file' ? (
                                                    <FileInput
                                                        name={item.name}
                                                        label={item.label}
                                                        chooseDocument={item.onChange}
                                                    />
                                                ) : (
                                                    <GetEachFormFields
                                                        item={item}
                                                        getFieldDecorator={getFieldDecorator}
                                                        formItemLayout={formItemLayout}
                                                    />
                                                )}
                                            </Col>
                                        )
                                    })}
                                </Row>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className={'btn'}>
                                        SUBMIT
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </Drawer>
            ) : null}


            {viewObj && viewObj.visible ? <SingleWhatsNewDrawer visible={viewObj.visible} item={viewObj.item}
                                                                onClose={events.hideViewDrawer}/> : ""}
        </>
    )
}

const WrappedWhatsNew = Form.create()(WhatsNew)
export default WrappedWhatsNew
