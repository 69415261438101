import {notification} from 'antd'
import {getToken, customAxios as axios} from '../../../request'
import {
    addFcmtNotesUrl,
    updateTuitionFeesDirectUniversityUrl,
    singleAppFcmtNotesUrl,
    uploadTuitionFeesDocumentUrl,
    uploadMultiTuitionFeesDocumentUrl,
    verifyTuitionFeesDocumentUrl,
    updateAgentDealCloseUrl, closeTuitionFeesDocumentUrl, disRegardTuitionFeesDocumentUrl
} from '../api/fcmtApi'
import {showPageLoad, hidePageLoad} from '../../../modules/actions'

export const addFcmtNote = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addFcmtNotesUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (valData.showNotification) {
        if (!data.error) {
            notification.success({
                message: data.message || 'Success'
            })
        } else {
            notification.error({
                message: data.message || 'Error'
            })
        }
    }
    return data
}

export const getSingleAppFcmtNotes = async (filters) => {
    let config = {
        params: {...filters},
        ...await getToken()
    }
    let {data} = await axios.get(singleAppFcmtNotesUrl(), config)
    return data
}

export const uploadTuitionFeesDocumentFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(uploadTuitionFeesDocumentUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (valData.showNotification) {
        if (!data.error) {
            notification.success({
                message: data.message || 'Success'
            })
        } else {
            notification.error({
                message: data.message || 'Error'
            })
        }
    }
    return data
}


export const updateTuitionFeesDirectUniversityFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateTuitionFeesDirectUniversityUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (valData.showNotification) {
        if (!data.error) {
            notification.success({
                message: data.message || 'Success'
            })
        } else {
            notification.error({
                message: data.message || 'Error'
            })
        }
    }
    return data
}

export const uploadMultiTuitionFeesDocumentFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(uploadMultiTuitionFeesDocumentUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
export const verifyTuitionFeesDocumentFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(verifyTuitionFeesDocumentUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
export const updateAgentDealCloseFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateAgentDealCloseUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
export const closeTuitionFeesDocumentFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(closeTuitionFeesDocumentUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
export const disRegardTuitionFeesDocumentFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(disRegardTuitionFeesDocumentUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
