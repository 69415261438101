import React, {useState, useEffect, useRef} from 'react'
import {
    Button,
    Col,
    Progress,
    Row, DatePicker, Avatar
} from 'antd'
import {connect, useDispatch} from 'react-redux'
import {getUrlPushWrapper} from '../../routes'
import {
    countryIds,
    dateFilterRange
} from '../../components/_utils/appUtils'
import moment from 'moment'
import {
    getMarketingUserApplicationRecordFxn,
    getPublicUniversityApplicationsData
} from './actions/action'
import {getAgentTargetFxn, getSingleUser} from "../users/actions/user";
import _ from "lodash"

const {RangePicker} = DatePicker


const ColumnComponent = (props) => {
    let {current, previous, percentage} = props
    return (
        <>
            Prev. : {previous} <br/>
            Curr. : {current} <br/>
            <div className={'mt5 font-weight-600 '}>
                ({percentage}%
                <span className={`${percentage >= 0 ? 'arrow-up greenColor' : 'arrow-down redColor'}`}>
        {percentage >= 0 ? '▲' : '▼'}
      </span>)
            </div>

        </>
    )
}


const MainTargetBarComponent = ({targetState}) => {
    return (
        <div className={'liveReportTarget'}>
            <Progress
                percent={targetState.percent}
            />
            <div className={'target-box'}>
                <div className={'colorPrimary'}>
                    Total
                    <div className={'value-box'}>
                        <img src={'../targets/goal.png'}/>
                        <span>{targetState.totalTarget}</span>
                    </div>
                </div>
                <div className={'value-box'}>
                    <img src={'../targets/pie-chart.png'}/>
                    <span>{targetState.percent}%</span>
                </div>
                <div className={'colorSuccess'}>
                    Achieved
                    <div className={'value-box'}>
                        <img src={'../targets/target-achieve.png'}/>
                        <span>
                            {targetState.totalAchieved}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}


const TargetBarComponent = (props) => {

    let {userId, records} = props;
    let [targetState, setTargetState] = useState({
        target: 0,
        achieved: 0,
        percent: 0
    });
    let checkTarget = () => {
        let findD = _.find(records, (item) => {
            return item.userId == userId
        });
        if (findD) {
            setTargetState((prevState) => {
                return {
                    ...prevState,
                    ...findD
                }
            })
        }
    }
    useEffect(() => {
        checkTarget()
    }, [])
    return (
        <div className={'liveReportTarget'}>
            <Progress
                // type="circle"
                /*  strokeColor={{
                      '0%': '#108ee9',
                      '100%': '#87d068',
                  }}*/
                percent={targetState.percent}
            />
            <div className={'target-box'}>
                <div className={'colorPrimary'}>
                    Total
                    <div className={'value-box'}>
                        <img src={'../targets/goal.png'}/>
                        <span>{targetState.target}</span>
                    </div>
                </div>
                <div className={'value-box'}>
                    <img src={'../targets/pie-chart.png'}/>
                    <span>{targetState.percent}%</span>
                </div>
                <div className={'colorSuccess'}>
                    Achieved
                    <div className={'value-box'}>
                        <img src={'../targets/target-achieve.png'}/>
                        <span>
                            {targetState.achieved}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}


const LiveReportingList = (props) => {
    const [selectUser, setSelectUser] = useState('')
    const [toDate, setToDate] = useState(moment().startOf('day'))
    const [fromDate, setFromDate] = useState(moment().subtract(7, 'days').startOf('day'))
    const [marketingUserApplicationRecord, setMarketingUserApplicationRecord] = useState([])
    const [countryName, setCountryName] = useState('')
    const [countryId, setCountryId] = useState('')
    const [countryStatusArr, setCountryStatusArr] = useState([])
    const [agentTarget, setAgentTarget] = useState(null)
    const [targetState, setTargetState] = useState({})
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    const dispatch = useDispatch()

    useEffect(() => {
        reloadData()
    }, [])

    let reloadData = async () => {
        let resp = await getParamsForApplicationList()
        if (resp && resp.universityId) {
            setCountryId(countryIds.canada)
            fetchLiveReportingList(resp.universityId)
        }
        if (resp && resp.countryId) {
            setCountryId(resp.countryId)
            fetchPublicUniversityData(resp.countryId)
        }
        if (resp && resp.countryName) {
            setCountryName(resp.countryName)
        }
    }


    const loadUserReport = async () => {
        let searchParams = new URLSearchParams(window.location.search)
        let universityId = searchParams.get('universityId')
        let countryName = searchParams.get('countryName')
        let universityName = searchParams.get('universityName')
        let countryId = searchParams.get('countryId')
        let obj = {}
        if (universityId) {
            obj.universityId = universityId
        }
        if (countryName) {
            obj.countryName = countryName
        }
        if (universityName) {
            obj.universityName = universityName
        }
        if (countryId) {
            obj.countryId = countryId
        }
        dispatch(getUrlPushWrapper('liveReportingList', obj))
        if (universityId) {
            fetchLiveReportingList(universityId)
        } else {
            fetchPublicUniversityData(countryId)
        }
    }

    const getAgentTarget = async () => {
        let {data} = await getAgentTargetFxn();
        setTargetState(data)
    }

    useEffect(() => {
        getAgentTarget()
    }, [])

    const getParamsForApplicationList = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let universityId = searchParams.get('universityId')
            let countryName = searchParams.get('countryName')
            let universityName = searchParams.get('universityName')
            let countryId = searchParams.get('countryId')
            let obj = {}
            if (universityId) {
                obj.universityId = universityId
            }
            if (countryName) {
                obj.countryName = countryName
            }
            if (universityName) {
                obj.universityName = universityName
            }
            if (countryId) {
                obj.countryId = countryId
            }
            resolve(obj)
        })
    }

    const fetchLiveReportingList = async (universityId) => {
        let params = {}
        if (universityId) {
            params.universityId = universityId
        }
        if (toDate) {
            params.toDate = moment(toDate).format('DD-MM-YYYY')
        }
        if (fromDate) {
            params.fromDate = moment(fromDate).format('DD-MM-YYYY')
        }
        const {data} = await dispatch(getMarketingUserApplicationRecordFxn(params))
        if (data) {
            setMarketingUserApplicationRecord(data.data)
            setCountryStatusArr(data.countryStatusArr)
        }
    }
    const fetchPublicUniversityData = async (countryId) => {
        let params = {}
        if (countryId) {
            params.countryId = countryId
        }

        if (toDate) {
            params.toDate = moment(toDate).format('DD-MM-YYYY')
        }
        if (fromDate) {
            params.fromDate = moment(fromDate).format('DD-MM-YYYY')
        }
        const {data} = await dispatch(getPublicUniversityApplicationsData(params))
        if (data) {
            setMarketingUserApplicationRecord(data.data)
            setCountryStatusArr(data.countryStatusArr)
        }
    }

    return (
        <div className='row  mt-4'>
            <div className='col-lg-12'>
                <div className='card'>

                    <div className={'filter_box p-3'}>
                        <Row gutter={16}>
                            <Col span={18}>
                                <div className='mt-2 align-items-center'>
                                    <div className={'mt-2'}>
                                        <div className={'d-flex'}>
                                            <h6>Live Reporting List For - <b
                                                className={'colorPrimaryDark'}>{countryName ? countryName : null}</b>
                                            </h6>
                                            <div className={'ml10'}>
                                                - <b className={'colorPrimaryDark'}>
                                                {marketingUserApplicationRecord.length > 0 && marketingUserApplicationRecord[0].currentWeekOfMonth}th </b> Week
                                                Of The Month
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        From: <b
                                        className={'colorPrimaryDark'}>{moment(fromDate).format('DD-MM-YYYY')}</b> -
                                        To: <b
                                        className={'colorPrimaryDark'}>{moment(toDate).format('DD-MM-YYYY')}</b>
                                    </div>
                                </div>
                                <Row className={'mt5'} gutter={16}>
                                    <Col span={8}>
                                        <RangePicker
                                            defaultValue={[toDate, fromDate]}
                                            onChange={(val) => {
                                                setFromDate(val[0])
                                                setToDate(val[1])
                                            }}
                                            ranges={dateFilterRange}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <Button onClick={() => loadUserReport(selectUser)}
                                                className={'roundBtn'}>Search</Button>
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={6}>
                                {targetState.totalTarget ? (
                                    <h6 className='linkUl mb-3'>
                                        Agent Target - <b
                                        className='colorPrimaryDark'>{targetState.totalTarget} / {targetState.totalAchieved || 0}</b>
                                    </h6>
                                ) : null}
                                <MainTargetBarComponent targetState={targetState}/>
                            </Col>
                        </Row>

                    </div>
                    <table className={'liveReportingTable w-100 table table-bordered table-striped'} border='1'
                           style={{textAlign: 'left'}}>
                        <thead>
                        <tr>
                            <th className={'liveReportingTableHead'}>Name</th>
                            <th className={'liveReportingTableHead'}>Visits</th>
                            <th className={'liveReportingTableHead'}>Expense</th>
                            <th className={'liveReportingTableHead'}>Exp./visit</th>
                            <th className={'liveReportingTableHead'}>Applications</th>
                            {countryStatusArr && countryStatusArr.length ? countryStatusArr.map((item) => {
                                return (
                                    <th className={'liveReportingTableHead'} key={item}>{item}</th>
                                )
                            }) : null}

                            <th className={'liveReportingTableHead'}>Visa</th>
                            <th className={'liveReportingTableHead'}>Monthly Visits till Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {marketingUserApplicationRecord.map((item) => (
                            <tr>
                                <td className={'liveReportingTableData'}>
                                    <div className={'d-flex center'}
                                         style={{marginLeft: 0}}>
                                        <div>
                                            <Avatar
                                                size={30}
                                                style={{
                                                    marginTop: 10,
                                                    marginLeft: 15,
                                                    marginRight: 15
                                                }}
                                                src={item && item.UserImage ? item.UserImage : '../dist/img/user.png'}
                                            />
                                        </div>
                                        <b className={'mt-3'}>
                                            {item.userName}
                                        </b>
                                    </div>
                                    <TargetBarComponent records={targetState.users} userId={item.userId}/>

                                </td>

                                <td className={'liveReportingTableData'}>
                                    <ColumnComponent
                                        current={item.currentWeekCountOfMeetings}
                                        previous={item.previousWeekCountOfMeetings}
                                        percentage={item.percentageChangeOfMeetingsInWeek}
                                    />
                                </td>

                                <td className={'liveReportingTableData'}>
                                    <ColumnComponent
                                        current={item.currentWeekCountOfExpense.toFixed(2)}
                                        previous={item.previousWeekCountOfExpense.toFixed(2)}
                                        percentage={item.percentageChangeOfExpenseInWeek}
                                    />
                                </td>

                                <td className={'liveReportingTableData'}>
                                    {item.currentWeekCountOfMeetings != 0
                                        ? (item.currentWeekCountOfExpense / item.currentWeekCountOfMeetings).toFixed(2)
                                        : 0}
                                </td>


                                <td className={'liveReportingTableData'}>
                                    <ColumnComponent
                                        current={item.offerReceivedCount}
                                        previous={item.previousOfferReceivedCount}
                                        percentage={item.percentageChangeOfOfferReceived}
                                    />
                                </td>
                                {countryStatusArr && countryStatusArr.length ? countryStatusArr.map((statusField) => {
                                    let obj = item[statusField]
                                    return (
                                        <td className={'liveReportingTableData'}>
                                            <ColumnComponent
                                                current={obj.current}
                                                previous={obj.previous}
                                                percentage={obj.percentage}
                                            />
                                        </td>
                                    )
                                }) : null}
                                <td className={'liveReportingTableData'}>
                                    <ColumnComponent
                                        current={item.visaApproved}
                                        previous={item.previousVisaApproved}
                                        percentage={item.percentageChangeOfVisaApproved}
                                    />
                                </td>
                                <td className={'liveReportingTableData'}>
                                    <ColumnComponent
                                        current={item.currentMonthCountOfMeetings}
                                        previous={item.previousMonthCountOfMeetings}
                                        percentage={item.percentageChangeOfMeetingsInMonth}
                                    />
                                </td>
                            </tr>

                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (
    {
        global
    }
) => (
    {
        categories: global.categories,
        activeUser: global.activeUser
}
)
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LiveReportingList)
