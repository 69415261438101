import {apiUrl, scholarUrl} from '../../../settings'

export const addCityUrl = () => {
  return apiUrl + '/city'
}

export const listCitiesUrl = () => {
  return apiUrl + '/city'
}

export const listCityUrl = id => {
  return apiUrl + '/city/' + id
}
export const enrollCompanyUrl = () => {
  return scholarUrl + '/enrollCompany'
}
export const enrolledCompaniesListUrl = () => {
  return scholarUrl + '/enrolledCompanies'
}
