import React, {useEffect, useState} from 'react'
import {Form, Steps, Row, Col, Button, Input} from 'antd'
import {connect, useDispatch} from 'react-redux'
import moment from 'moment'
import {addStoryAction} from '../actions'
import {InputBox, StatusNameObj} from '../../../components/_utils/appUtils'

let init = {
    poster: '',
    video: ''
}
const AddTarget = (props) => {
    const [state, setState] = useState(init)
    const [uploadKey, setUploadKey] = useState(moment())
    const {
        form: {getFieldDecorator, setFieldsValue, getFieldValue}, currentUser
    } = props
    const dispatch = useDispatch()


    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }


    const submitTrustedStory = async (e) => {
        e.preventDefault()
        if (state && state.poster) {
            let fd = new FormData()
            fd.append('image', state.poster)
            fd.append('video', state.video)
            let data = await dispatch(addStoryAction(fd))
            if (data && !data.error) {
                setUploadKey(moment())
            }
        }
    }


    return (
        <div className={'mt-4'}>
            <div className='row'>
                <div className='col-lg-12'>
                    <Form className={'vertical-form'} autoComplete='off' onSubmit={submitTrustedStory}>
                        <div className='form-box'>

                            <div className='heading-form stuHeader  d-flex align-items-center mb-3'
                                 id={'generalInformation'}>
                                <h5><span className='img'><img src='/dist/img/check.svg' alt=''/></span> Add
                                    Story</h5>
                            </div>
                            <div className='card unizportal'>
                                <Row gutter={24} className={'rowWrap'}>
                                    <Col span={12} md={12} sm={12} xs={24}>
                                        <InputBox title={` Poster Image`}>
                                            <Input type={'file'} name={'poster'}
                                                   id={'poster'}
                                                   key={uploadKey}
                                                   className={'form-control'}
                                                   onChange={(e) => {
                                                       setState({
                                                           ...state,
                                                           ['poster']: e.target.files && e.target.files.length ? e.target.files[0] : null
                                                       })
                                                   }}
                                            /></InputBox></Col>
                                    <Col span={12} md={12} sm={12} xs={24}>

                                        <InputBox title={`Video`}>
                                            <Input type={'file'} name={'video'} placeholder={'Video Link'}
                                                   className={'form-control'}
                                                   key={uploadKey}
                                                   onChange={(e) => {
                                                       setState({
                                                           ...state,
                                                           ['video']: e.target.files && e.target.files.length ? e.target.files[0] : null
                                                       })
                                                   }}
                                            />
                                        </InputBox>
                                    </Col>
                                </Row>

                                <button className='btn' type='submit'>save
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

        </div>
    )
}


const WrappedAddTarget = Form.create()(AddTarget);

const mapStateToProps = ({global, router}) => ({
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    null
)(WrappedAddTarget)


