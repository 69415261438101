import React, { Component } from 'react'
import { Drawer } from 'antd'
import { connect } from 'react-redux'
import { ColumnWidth } from '../../WebComponent/columnWidth'
import { webActivityFxn } from '../actions/user'
import { TableComp } from 'sz-react-utils-lite'
import { DefaultTablePagination } from '../../../components/_utils/appUtils'
import { displayDate, displayTime } from '../../../components/_utils/appUtils'

const searchComponent = (search) => {

}


class UserWebActivity extends Component {

  constructor(props) {
    super(props)
    this.state = {}
    this.tableRef = React.createRef()
  }

  componentDidMount() {
  }

  apiRequest = (params) => {
    let { user } = this.props
    return new Promise(async (resolve) => {
      params.sortField = '_id'
      params.sortOrder = 'descend'
      params.userId = user._id
      resolve(webActivityFxn({
        ...params,
        regExFilters: ['name', 'address', 'mobile', 'email']
      }))
    })
  }

  render() {
    let {} = this.state
    let { visible, onClose, user } = this.props
    const columns = [
      {
        title: '#',
        key: '_id',
        dataIndex: '_id',
        width: 50,
        render: (ite, record, index) => {
          return (
            <React.Fragment>
              {index + 1}
            </React.Fragment>
          )
        }
      },

      {
        title: 'Time',
        dataIndex: 'date',
        key: 'date',
        width: 120,
        render: (item, record) => {
          let { time } = record
          return (
            <div>
              {displayDate(item)} <strong>{displayTime(time)}</strong>
            </div>
          )
        }
      },
      {
        title: 'Location',
        dataIndex: 'pathname',
        key: 'pathname',
        width: 120,
        render: (item, record) => {
          let { origin } = record
          return (
            item ? <div>{origin}{item}</div> : null
          )
        }
      }
    ]
    let title = `Activities - ${user.name} ${user.email ? ` (${user.email})` : null}`
    return (
      <Drawer visible={visible}
              title={title}
              width={'50%'}
              onClose={onClose}>
        <div className='card'>
          <div className='card-body table-responsive'>
            <TableComp columns={columns}
                       ref={this.tableRef}
                       pagination={DefaultTablePagination()}
                       apiRequest={this.apiRequest}
            />
          </div>
        </div>
      </Drawer>
    )

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserWebActivity)
