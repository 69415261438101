import React, { useState, useEffect, useRef } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Tooltip, Tabs, Button
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { getUrlPushWrapper } from '../../../routes'
import { DefaultTablePagination } from '../../../components/_utils/appUtils'
import { listAllCommission } from '../actions'
import { displayDate } from '../../../components/_utils/appUtils'
import CommissionInnerComponet from './commissionInner'
import { push } from 'connected-react-router'

const { TabPane } = Tabs

const AllCommissions = () => {
  let [user, setUser] = useState({})
  let [allCommission, setAllCommission] = useState([])
  const [itemNo, setItemNo] = useState(1)
  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiUkRequest = (params) => {
    return new Promise(async (resolve) => {
      params.country = 'uk'
      let resp = await dispatch(listAllCommission({ ...params, regExFilters: [] }))
      resolve(resp)
    })
  }

  const apiCanadaRequest = (params) => {
    return new Promise(async (resolve) => {
      params.country = 'canada'
      let resp = await dispatch(listAllCommission({ ...params, regExFilters: [] }))
      resolve(resp)
    })
  }

  useEffect(() => {
    loadUser()
  }, [])

  const loadUser = async () => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    console.log('userddddd', user)
    setUser(user)
  }

  const openApp = (record) => {
    let studentId = record.studentId._id
    if (user && user.userType && user.userType == 'admin') {
      window.open(`/student/application?studentId=${studentId}&appId=${record.application_id}`, '_blank')
    } else {
      dispatch(
        getUrlPushWrapper('application.singleApplication', {
          appId: record.applications_id,
          studentId: studentId
        }))
    }
  }


  const callback = (key) => {
    console.log(key)
  }


  const columns = [

    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (value, item, index) => (itemNo - 1) * 10 + index + 1
    },
    {
      title: 'Name',
      dataIndex: 'studentId',
      key: 'studentId',
      render: (item, record) => {
        return (
          <a onClick={() => openApp(record)} className={'hyperLink'}>
            {item && item.name ? item.name : ''} <br />{item && item.studentId ? `( ID : ${item.studentId})` : ''}
          </a>
        )
      }
    },
    {
      title: 'App ID',
      dataIndex: 'applicationId',
      width: 100,
      key: 'applicationId',
      render: (item, record) => {
        return <a className={'hyperLink'} onClick={() => openApp(record)}>{item}</a>
      }
    },
    {
      title: 'University',
      dataIndex: 'universityId',
      key: 'universityId',
      render: (item) => {
        return (
          <div>
            {item && item.universityName ? item.universityName : ''}
          </div>
        )
      }
    },
    {
      title: 'Course',
      dataIndex: 'courseId',
      key: 'courseId',
      render: (item) => {
        return (
          <div>
            {item && item.courseName ? item.courseName : ''}
          </div>
        )
      }
    },

    {
      title: 'Credit',
      dataIndex: 'credit',
      key: 'credit',
      render: (item, record) => {
        return (
          <div>
            {record.type == 'Credit' ?
              <label className={'label label-success-green sm'}>
                {record && record.countryId && record.countryId.currency ? record.countryId.currency : ''}{record.commission}
              </label> : null}
          </div>
        )
      }
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      key: 'debit',
      render: (item, record) => {
        return (
          <div>
            {record.type == 'Debit' ?
              <label className={'label label-danger sm'}>
                {record && record.countryId && record.countryId.currency ? record.countryId.currency : ''}{record.commission}
              </label> : null}
          </div>
        )
      }
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: (item, record) => {
        return (
          <label className={'label label-success sm'}>
            {record && record.countryId && record.countryId.currency ? record.countryId.currency : ''}{item}
          </label>
        )
      }
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 100,
      key: 'createdAt',
      render: (item) => {
        return item ? displayDate(item) : null
      }
    }

  ]


  return (
    <div className='row  mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center'>
            <h5>Commission</h5>
            <div className='search-box-table'>

            </div>
            <div className='sort-box-table mark-btn'>
              {/*<h5>Commission Structure</h5>*/}
            </div>
            {user && user.showCommission && user.showCommission == true ?
              <span className={'sort-box-table-right'}>
                 <Tooltip title={'Export Excel'}>
              <Button className='roundBtn' onClick={() => dispatch(push('/commission-structure'))}>
                Commission Structure</Button>
                 </Tooltip>
            </span> : null}
          </div>
          <CommissionInnerComponet />
        </div>
      </div>
    </div>
  )
}

export default AllCommissions
