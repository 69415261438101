import React, {useEffect, useState} from 'react'
import {Form, Steps, Row, Col, Button, Input, Select,} from 'antd'
import {connect, useDispatch} from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {addBanners} from '../actions'
import {InputBox, StatusNameObj} from '../../../components/_utils/appUtils'
import moment from "moment";

const {Option} = Select
let init = {
    poster: '',
    video: ''
}
const Banners = (props) => {
    const [state, setState] = useState(init)
    const [updateKey, setUpdateKey] = useState(moment())
    const {
        form: {getFieldDecorator, setFieldsValue, getFieldValue}, currentUser
    } = props
    const dispatch = useDispatch()


    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    const submitBanner = (e) => {
        let {form} = props;
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (valData && valData.bannerType) {
                let fd = new FormData()
                fd.append('obj', JSON.stringify(valData))
                fd.append('image', state.poster)
                if (valData.bannerType === 'video') {
                    fd.append('video', state.video)
                }
                let data = await dispatch(addBanners(fd))
                if (data && !data.error) {
                    form.resetFields()
                    setState(init)
                    setUpdateKey(moment())
                }
            }
        })
    }

    let inputField = [
        {
            key: 'bannerType',
            label: 'Banner Type',
            type: 'select',
            required: true,
            options: [
                {name: 'Image', value: 'image'},
                {name: 'Video', value: "video"}
            ],
            keyAccessor: x => x.value,
            valueAccessor: x => `${x.name}`,
            onChange: x => {
                props.form.setFieldsValue({
                    bannerType: x,
                })
            },
            span: 8
        },
        {
            key: 'image',
            span: 8,
            hidden: !getFieldValue('bannerType'),
            customField: (
                <InputBox title={` Poster Image`}>
                    <Input type={'file'} name={'poster'} id={'poster'}
                           className={'form-control'}
                           key={updateKey}
                           onChange={(e) => {
                               setState({
                                   ...state,
                                   ['poster']: e.target.files && e.target.files.length ? e.target.files[0] : null
                               })
                           }}
                    />
                </InputBox>
            )
        },
        {
            key: 'video',
            span: 8,
            hidden: getFieldValue('bannerType') !== 'video',
            customField: (
                <InputBox title={`Video`}>
                    <Input type={'file'}
                           name={'video'} placeholder={'Video'}
                           className={'form-control'}
                           key={updateKey}
                           onChange={(e) => {
                               setState({
                                   ...state,
                                   ['video']: e.target.files && e.target.files.length ? e.target.files[0] : null
                               })
                           }}
                    />
                </InputBox>
            )
        },
    ]


    return (
        <div className={'mt-4'}>
            <div className='row'>
                <div className='col-lg-12'>
                    <Form className={'vertical-form'} autoComplete='off' onSubmit={submitBanner}>
                        <div className='form-box'>

                            <div className='heading-form stuHeader  d-flex align-items-center mb-3'
                                 id={'generalInformation'}>
                                <h5><span className='img'><img src='../dist/img/check.svg' alt=''/></span> Add
                                    Banner</h5>
                            </div>
                            <div className='card unizportal'>
                                <Row gutter={24} className={'rowWrap pt-3'}>
                                    {inputField.map((item, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                {item.customField ? !item.hidden &&
                                                    <Col span={item.span ? item.span : 8}>
                                                        {item.customField}
                                                    </Col> : !item.hidden &&
                                                    <Col span={item.span ? item.span : 8} key={key}
                                                         className={'mb10'}>
                                                        <InputBox title={item.label1 ? item.label1 : ''}>
                                                            <GetEachFormFields
                                                                item={item}
                                                                getFieldDecorator={getFieldDecorator}
                                                                formItemLayout={formItemLayout}/>
                                                        </InputBox>
                                                    </Col>}
                                            </React.Fragment>
                                        )
                                    })}
                                </Row>

                                <button className='btn' type='submit'>save
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

        </div>
    )
}


const WrappedAddTarget = Form.create()(Banners);

const mapStateToProps = ({global, router}) => ({
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    null
)(WrappedAddTarget)


