import React, { useEffect, useRef, useState } from 'react'
import { Button, Input, DatePicker, Popconfirm, Upload, Icon, Row, Col } from 'antd'
import { useHistory } from 'react-router-dom'
import { TableComp } from 'sz-react-utils-lite'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { addFutureTask, futureTaskList, deleteFutureTask, updateFutureTask } from '../actions/taskManager'
import moment from 'moment'
import { notification } from 'antd'
import { InputBox } from '../../../components/_utils/appUtils'
import FutureTaskComponent from './futureTaskComponent'

let { TextArea } = Input
let initState = {
  task: '',
  date: moment(),
  queryBy: '',
  file: {}
}
const Card = styled.div`
  max-width: 90%;
  width: 90%;
  margin: 20px 30px;
  font-family: 'Roboto', sans-serif;
  word-wrap: break-word;
  overflow-wrap: break-word;
`

const UploadContainer = styled.div`
  margin-right: 8px;
  flex: 1 1 30%;
  display: flex;
  flex-direction: column;
`

const FileUploadInput = styled.input`
  display: none;
`

const FileUploadContainer = styled.div`
  margin-right: 8px;
  flex: 1 1 10%;
  display: flex;
  flex-direction: column;
`

const StyledUploadButton = styled(Button)`
  height: 40px;

  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledFileName = styled.span`
  margin-left: 8px;
`
const FutureTasks = () => {
  let tableRef = useRef()
  const history = useHistory()
  const dispatch = useDispatch()
  const [newTask, setNewTask] = useState(initState)

  const defaultDate = moment()

  const futureTasksColumns = [
    {
      title: 'Sr No.',
      dataIndex: 'serialNo',
      width: 70,
      render: (val, record, index) => <div>{index + 1}</div>
    },

    {
      title: 'Task',
      dataIndex: 'task',
      key: 'task',
      render: (text, record) => {
        return (
          <FutureTaskComponent text={text} data={record} refreshTable={events.reload} />
        )
      }
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 100,
      render: (date, record) => (
        <span>
          {record.groupDate && (
            <strong>
              {moment(record.groupDate).format('Do MMM YYYY')}
            </strong>
          )}
          {!record.groupDate && moment(date).format('Do MMM YYYY')}
        </span>
      )
    },
    {
      title: 'Added By',
      dataIndex: 'addedBy',
      key: 'addedBy',
      width: 100,
      render: (item, record) => {
        return item && item.name ? item.name : ''
      }
    },
    {
      title: 'Query By',
      dataIndex: 'queryBy',
      key: 'queryBy',
      width: 100
    },
    {
      title: 'File',
      dataIndex: 'file',
      key: 'file',
      type: 'file',
      width: 100,
      render: (file, record) => {
        return (
          <>


          </>
        )
      }
    },


    {

      key: 'actions',
      width: 50,
      render: (record) => (
        <div>
          <Popconfirm
            title='Are you sure you want to delete this task?'
            onConfirm={() => handleDeleteTask(record._id)}
            okText='Yes'
            cancelText='No'
            placement='topRight'
          >
            <Button type='link' style={{ color: '#b13a41', marginLeft: '8px' }}>
              <Icon type='close' />
            </Button>
          </Popconfirm>
        </div>
      )
    }
  ]

  const handleBack = () => {
    history.push('/tasks-list')
  }

  const handleInputChange = (field, value) => {
    setNewTask({ ...newTask, [field]: value })
  }


  const handleAddTask = async () => {
    if (!newTask.task) {
      notification.warning({
        message: 'Enter task'
      })
      return
    }
    const formData = new FormData()
    let obj = {
      task: newTask.task,
      date: defaultDate,
      queryBy: newTask.queryBy
    }
    formData.append('obj', JSON.stringify(obj))

    if (newTask.file && newTask.file.name) {
      formData.append('file', newTask.file)
    }
    const response = await dispatch(addFutureTask(formData))
    if (!response.error) {
      events.reload()
      setNewTask((prevState) => {
        return {
          ...prevState,
          ...initState
        }
      })
    }
  }


  const handleDeleteTask = async (taskId) => {
    const response = await dispatch(deleteFutureTask(taskId))
    if (!response.error) {
      events.reload()
    }
  }

  const apiRequest = async (params) => {
    return new Promise(async (resolve) => {
      const response = await dispatch(futureTaskList(params))
      resolve(response)
    })
  }
  const events = {
    reload: () => {
      tableRef.current.reload()
    }
  }

  return (
    <div>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='form-box mt-4'>
            <div className='d-flex align-items-center mb-3 heading-form'>
              <Button onClick={handleBack} style={{ border: 'none' }}>
                <Icon type='arrow-left' /> Back to Tasks List
              </Button>
              <h5>Future Task</h5>
            </div>
            <div className='card unizportal'>
              <Row gutter={'8'} className={'mt10'}>
                <Col span={24}>
                  <InputBox>
                    <TextArea
                      placeholder='Task Name'
                      style={{ minHeight: 115 }}
                      value={newTask.task}
                      onChange={(e) => handleInputChange('task', e.target.value)}
                    />
                  </InputBox>
                </Col>
                <Col span={7}>
                  <DatePicker
                    placeholder='Select Date'
                    className='date-picker input-hover dateH35'
                    value={newTask.date || defaultDate}
                    onChange={(date) => handleInputChange('date', date)}
                    defaultValue={defaultDate}
                    disabled
                  />
                </Col>
                <Col span={7}>
                  <Input
                    placeholder='Query By'
                    value={newTask.queryBy}
                    onChange={(e) => handleInputChange('queryBy', e.target.value)}
                  />
                </Col>
                <Col span={7}>
                  <Input
                    type={'file'}
                    placeholder='File'
                    onChange={({ target }) => {
                      handleInputChange('file', target.files[0])
                    }}
                  />
                </Col>
                <Col span={3}>
                  <Button type='primary' onClick={handleAddTask}>
                    Add Task
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className='col-lg-12'>
          <div className='form-box'>
            <div className='card unizportal'>
              <TableComp
                columns={futureTasksColumns}
                apiRequest={apiRequest}
                pagination={false}
                ref={tableRef}
                rowKey='_id'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FutureTasks
