import React, { useEffect, useState } from 'react'
import { Drawer, Form, Button, Select, Input, Row, Col, notification } from 'antd'
import { useDispatch } from 'react-redux'
import { ApplicationDeskList, InputBox, RollBackDeskList } from '../../../components/_utils/appUtils'
import { deferLoaFxn, generateAgentInvoiceFxn } from './actions'
import { loadCourseIntakesFxn } from '../../course/actions/courseAction'
import lodash from 'lodash'

const DeferLoa = (props) => {
  const initialState = {
    intake: {
      month: '',
      year: ''
    },
  }
  let dispatch = useDispatch()
  let { Option } = Select
  let { visible, onClose, onSubmit, studentId, applicationId, record } = props
  let { applications } = record
  let [state, setState] = useState(initialState)
  let [intake, setIntake] = useState('')
  let [newIntake, setNewIntake] = useState('')

  const handleSubmit = async () => {
    let obj = {
      applicationId: applicationId,
      studentId: studentId,
    }
    let resp = await dispatch(generateAgentInvoiceFxn(obj))
    if (resp && resp.success) {
      onSubmit()
    }
  }

  useEffect(() => {
    loadIntakes()
  }, [])

  const loadIntakes = async () => {
    let resp = await loadCourseIntakesFxn({courseId: applications.courseId._id})(dispatch)
    let intakeList = []
    lodash.each(resp, (item) => {
      if (item.intake) {
        intakeList.push({
          value: `${item.intake}-${item.year}`,
          name: `${item.intake}, ${item.year}`
        })
      }
    })
    setNextStatus(applications, (resp1) => {
      setState({
        ...state,
        intake: intakeList
      })
      if (applications.intake && applications.intake.month) {
        let {intake} = applications
        setIntake(`${intake.month}-${intake.year}`)
      }
    })
  }

  const setNextStatus = async (applications, callback) => {
    let {status} = applications
    let obj = {
      statusList: [],
      currentStatusIndex: 0,
      newStatus: status,
      moveToNextDrawerVisible: false
    }
    if (applications && applications.universityCountry && applications.universityCountry.countryName) {
      let {countryName} = applications.universityCountry
      obj.statusList = ApplicationDeskList(countryName, applications.statusList)
      obj.rollbackStatusList = RollBackDeskList(countryName, obj.newStatus)
      obj.currentStatusIndex = lodash.findIndex(obj.statusList, (item) => {
        return item.name == status
      })
      obj.nextStatusIndex = obj.currentStatusIndex + 1
    }
    return callback(obj)
  }

  const chooseIntake=  (value, key) => {
    let intakeVal = value.split('-')
    let intake = {
      month: intakeVal[0],
      year: intakeVal[1]
    }
    setIntake(value)
    setNewIntake(intake)
  }

   const updateIntakes = async (newIntake) => {
    if (!newIntake || (newIntake && !newIntake.month)) {
      return notification.warning({
        message: 'Please Choose Another Intake'
      })
    }
    let resp = await dispatch(deferLoaFxn({
      studentId: studentId,
      applicationId: applicationId,
      intake: newIntake
    }))
    if (resp.success) {
      onSubmit()
      applications.intake = resp.intake
      setState({
        ...state,
        applications
      })
      dispatch({type: 'START_LOAD_ACTIVITY'})
    }
  }

  return (
    <>
      <Drawer title={'Defer Loa'}
              visible={visible} width={'45%'}
              onClose={onClose}
      >
        <div className='form-box'>
          <div className='card unizportal'>
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                  <InputBox title={'Choose Intake *'}>
                    <Select
                      name='intake'
                      id='intake'
                      value={intake}
                      onChange={(e) => {
                        chooseIntake(e)
                      }}>
                      {state.intake && state.intake.length ? state.intake.map((item, key) => {
                        return (
                          <Option key={key}
                                  value={item.value}>{item.name}</Option>
                        )
                      }) : null }
                    </Select>
                  </InputBox>
                </Col>
              </Row>
              <Button
                type='primary'
                htmlType='submit'
                className='btn'
                onClick={()=>{updateIntakes(newIntake)}}>
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  )
}
export default DeferLoa
