import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    Row,
    Select,
    Table,
    notification
} from 'antd'
import React, {Suspense, useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {InputBox} from '../../../components/_utils/appUtils'
import {addAgentReport, listAgentReports} from '../actions/agentReport'
import {listAllBranch} from "../../branch/actions/branchAction";

const {Option} = Select
let initState = {
    name: '',
    companyName: '',
    mobileNo: '',
    note: '',
    address: ''
}
const DailyAgentReports = props => {
    let tableRef = useRef()
    const {visible, events} = props;
    const [open, setOpen] = useState(false)
    const [formValues, setFormValues] = useState(initState)
    const [agentReportList, setAgentReportList] = useState([])
    const handleChange = data => {
        setFormValues(prevData => {
            return {
                ...prevData,
                ...data
            }
        })
    }
    useEffect(() => {

    }, [])
    const addAgentReportDrawer = () => {
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }
    const dispatch = useDispatch()
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            searchTextName: 'name',
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
            searchTextName: 'companyName'
        },
        {
            title: 'Mobile No.',
            dataIndex: 'mobileNo',
            key: 'mobileNo',
            searchTextName: 'mobileNo'
        },
        {
            title: 'Notes',
            dataIndex: 'note',
            key: 'note',
            searchTextName: 'note'
        }
    ]

    const apiRequest = async (params) => {
        return new Promise(async (resolve) => {
            resolve(dispatch(listAgentReports({
                ...params,
                regExFilters: ['name', 'companyName', 'mobileNo', 'note']
            })))
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        if (!formValues.name) {
            notification.warning({message: 'Enter Name'})
            return
        }

        if (!formValues.companyName) {
            notification.warning({message: 'Enter Company Name'})
            return
        }

        if (!formValues.mobileNo) {
            notification.warning({message: 'Enter Mobile Number'})
            return
        }

        if (!formValues.note) {
            notification.warning({message: 'Enter Note'})
            return
        }

        let resp = await dispatch(addAgentReport(formValues))
        if (resp && resp.success) {
            handleChange(initState)
            onClose()
            tableRef.current.reload()
        }
    }

    return (
        <>
            <div className="row mt-4">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="table-head d-flex align-items-center">
                            <h5>Daily Agent Reports</h5>
                            <div className="search-box-table"/>
                            <div className="sort-box-table mark-btn">
                                <button
                                    className="btn"
                                    onClick={() => addAgentReportDrawer(true)}>
                                    <img src={'../dist/icons/plus.png'} className={'plus'}/>
                                    Add Agent Report
                                </button>
                            </div>
                        </div>

                        <div className="card-body table-responsive ">
                            <TableComp columns={columns}
                                       apiRequest={apiRequest}
                                       ref={tableRef}
                                       extraProps={{scroll: {x: 600}}}

                            />
                        </div>
                    </div>
                </div>
            </div>
            <Drawer
                title={'Add Report'}
                onClose={onClose}
                visible={open}
                width={'45%'}>
                <div className="card unizportal">
                    <Form
                        className={'vertical-form'}
                        autoComplete="off"
                        onSubmit={handleSubmit}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item>
                                    <InputBox title={' Name'}>
                                        <Input
                                            onChange={({target}) => {
                                                handleChange({name: target.value})
                                            }}
                                            value={formValues.name}
                                            className={'form-control'}
                                            name="name"
                                            placeholder="Please enter your Name"
                                        />
                                    </InputBox>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item>
                                    <InputBox title={'Company Name '}>
                                        <Input
                                            onChange={({target}) => {
                                                handleChange({companyName: target.value})
                                            }}
                                            value={formValues.companyName}
                                            className={'form-control'}
                                            name="companyName"
                                            placeholder="Please enter Company Name"
                                        />
                                    </InputBox>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item>
                                    <InputBox title={'Mobile No.'}>
                                        <Input
                                            onChange={({target}) => {
                                                handleChange({mobileNo: target.value})
                                            }}
                                            value={formValues.mobileNo}
                                            placeholder="Please enter Mobile No."
                                            name="mobileNo"
                                            className={'form-control'}
                                        />
                                    </InputBox>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <InputBox title={'Note'} customStyle={{height: 100}}>
                                        <textarea
                                            onChange={({target}) => {
                                                handleChange({note: target.value})
                                            }}
                                            rows={5}
                                            style={{height: 100}}
                                            value={formValues.note}
                                            placeholder="Please enter Note"
                                            name="note"
                                            className={'form-control'}
                                        />
                                    </InputBox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Button type="primary" htmlType="submit" className={'btn'}>
                            Add
                        </Button>
                    </Form>
                </div>
            </Drawer>
        </>
    )
}

export default DailyAgentReports
