export default (
  state = {
    loader: false,
    university: {},
    allUniversities: [],
    totalUniversities: 0,
    quickSearchUniversities: []
  },
  action
) => {
  switch (action.type) {
    case 'UNIVERSITY_SHOW_LOADER':
      return { ...state, loader: true }
    case 'UNIVERSITY_HIDE_LOADER':
      return { ...state, loader: false }
    case 'LIST_UNIVERSITY':
      return { ...state, university: action.payload.data }
    case 'ADD_UNIVERSITY':
      return { ...state, addedUniversity: action.payload }
    case 'LIST_ALL_UNIVERSITY':
      return { ...state, allUniversities: action.payload.data, totalUniversities: action.payload.total }
    case 'LIST_QUICK_SEARCH_UNIVERSITY':
      return { ...state, quickSearchUniversities: action.payload }
    default:
      return state
  }
}
