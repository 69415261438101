import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button, Col,
  Form,
  notification, Row, Card, Icon
} from 'antd'
import { gicQuestionList } from '../../../components/_utils/utils'
import { AddGicCommissionFxn } from '../../dashboard/gic/gicCommission/action'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import FileInput from '../../../components/_utils/fileInput'
import moment from 'moment'
import styles from '../../dashboard/gic/components/styles.less'
import { CountryCodeWithFlag } from '../../../components/_utils/countryUtil'
import { InputBox } from '../../../components/_utils/appUtils'

const gicInIt = {
  name: '',
  phoneNo: '',
  country: 'Canada',
  gicId: '',
  gicPassword: '',
  status: 'Pending'
}
const AddGicComponentDraft = (props) => {
  let {
    form: { getFieldValue, getFieldDecorator }, onSubmit = () => {
    }, studentData
  } = props
  const [passportCopy, setPassportCopy] = useState({})
  const [panCard, setPanCard] = useState({})
  const [bank, setBank] = useState('iciciBank')
  const [refreshKey, setRefreshKey] = useState(moment())
  let [countryCode, setCountryCode] = useState('')
  let dispatch = useDispatch()
  useEffect(() => {
    props.form.setFieldsValue({
      firstName: studentData.name,
      phoneNo: studentData.mobile,
      email: studentData.email,
      city: studentData.cityName,
      countryCode: studentData.countryISOCode,
      contactName: studentData.applications.agentId.name,
      contactNo: studentData.applications.agentId.mobile,
      collegeName: studentData.applications.courseUniversity.universityName
    })
    setCountryCode(studentData.countryISOCode)
  }, [studentData])


  let inputTypes = {
    fields: [
      {
        key: 'firstName',
        label: 'First Name',
        required: false,
        placeholder: 'First Name',
        type: 'text',
        showStar: true
      },
      {
        key: 'lastName',
        label: 'Last Name',
        required: false,
        placeholder: 'Last Name',
        type: 'text',
        showStar: true
      },

      {
        key: 'email',
        label: 'Email',
        required: false,
        placeholder: 'Email',
        type: 'email',
        showStar: true
      },


      {
        key: 'securityQuestion',
        label: 'Security Question',
        required: false,
        placeholder: 'Security Question',
        type: 'select',
        options: gicQuestionList,
        showStar: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            securityQuestion: x
          })
        }
      },

      {
        key: 'securityAnswer',
        label: 'Answer to Security Question',
        required: false,
        placeholder: 'Answer to Security Question',
        type: 'text',
        disabled: !getFieldValue('securityQuestion'),
        showStar: true
      },
      {
        key: 'countryCode',
        customField: (
          <div style={{ marginTop: -1 }}>
            <CountryCodeWithFlag
              countryCode={countryCode}
              chooseCode={(val) => {
                setCountryCode(val)
              }} />
          </div>
        )
      },
      {
        key: 'phoneNo',
        label: 'Contact No',
        required: false,
        placeholder: 'Contact No',
        type: 'text',
        showStar: true
      },

      {
        key: 'collegeName',
        label: 'College Name',
        required: false,
        placeholder: 'College Name',
        type: 'text',
        showStar: true
      },

      {
        key: 'city',
        label: 'City',
        required: false,
        placeholder: 'City',
        type: 'text',
        showStar: true
      },

      {
        key: 'expectedDateOfArrival',
        label: 'Expected Date of Arrival',
        required: false,
        placeholder: 'Expected Date of Arrival',
        type: 'date',
        showStar: true,
        onChange: (x) => {
          props.form.setFieldsValue({
            expectedDateOfArrival: x
          })
        }
      },
      {
        key: 'passportCopy',
        label: 'Passport Copy',
        required: false,
        placeholder: 'Passport Copy',
        type: 'file',
        onChange: ({ target }) => {
          handleFileChange(target, 'passportCopy')
        }
      },
      {
        key: 'panCard',
        label: 'Pan Card',
        required: false,
        placeholder: 'Pan Card',
        type: 'file',
        onChange: ({ target }) => {
          handleFileChange(target, 'panCard')
        }
      }

    ]
  }
  let inputTypes1 = {
    fields: [

      {
        key: 'contactName',
        label: 'Your Contact Name',
        required: false,
        placeholder: 'Your Contact Name',
        type: 'text'
      },
      {
        key: 'contactNo',
        label: 'Your Contact No',
        required: false,
        placeholder: 'Your Contact No',
        type: 'text'
      }

    ]
  }

  const handleFileChange = (e, name) => {
    let { files } = e
    if (name == 'panCard') {
      setPanCard(files[0])
    }
    if (name == 'passportCopy') {
      setPassportCopy(files[0])
    }
  }

  const [gicCommission, setGicCommission] = useState(gicInIt)

  const handleSubmit = async (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFields(async (err, valData) => {
      if (!bank) {
        notification.warning({
          message: 'Please choose Bank'
        })
        return
      }
      if (!valData.firstName) {
        notification.warning({
          message: 'Please enter first name'
        })
        return
      }
      if (!valData.lastName) {
        notification.warning({
          message: 'Please enter last name'
        })
        return
      }

      if (!valData.email) {
        notification.warning({
          message: 'Please enter email address'
        })
        return
      }


      if (!valData.securityQuestion) {
        notification.warning({
          message: 'Choose security question'
        })
        return
      }
      if (!valData.securityAnswer) {
        notification.warning({
          message: 'Enter security answer'
        })
        return
      }
      if (!valData.phoneNo) {
        notification.warning({
          message: 'Enter contact no'
        })
        return
      }
      if (!valData.collegeName) {
        notification.warning({
          message: 'Enter college name'
        })
        return
      }
      if (!valData.city) {
        notification.warning({
          message: 'Enter city'
        })
        return
      }
      if (!valData.expectedDateOfArrival) {
        notification.warning({
          message: 'Choose expected date of arrival'
        })
        return
      }
      if (!(passportCopy && passportCopy.name)) {
        notification.warning({
          message: 'Choose select passport Copy'
        })
        return
      }

      if (!valData.contactName) {
        notification.warning({
          message: 'Enter your contact name'
        })
        return
      }

      if (!countryCode) {
        notification.warning({
          message: 'Enter your country Code'
        })
        return
      }

      if (!valData.contactNo) {
        notification.warning({
          message: 'Enter your contact no'
        })
        return
      }

      let fd = new FormData()
      valData.bank = bank
      valData.countryCode = countryCode
      if (studentData.applications._id){
        valData.applicationsId = studentData.applications._id
      }
      if (studentData._id){
        valData.studentId = studentData._id
      }
      fd.append('obj', JSON.stringify(valData))

      if (passportCopy && passportCopy.name) {
        fd.append('passportCopy', passportCopy)
      }
      if (panCard && panCard.name) {
        fd.append('panCard', panCard)
      }
      let x = await dispatch(AddGicCommissionFxn(fd))
      if (x && x.success) {
        setGicCommission(gicInIt)
        form.setFieldsValue({
          firstName: '',
          lastName: '',
          email: '',
          userName: '',
          phoneNo: '',
          securityQuestion: '',
          securityAnswer: '',
          password: '',
          confirmationPassword: '',
          collegeName: '',
          city: '',
          expectedDateOfArrival: '',
          contactName: '',
          contactNo: ''
        })
        setPassportCopy({})
        setBank('iciciBank')
        setRefreshKey(moment())
        if (onSubmit) {
          onSubmit()
        }
      }
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  return (
    <div className='card unizportal'>
      <Form onSubmit={handleSubmit}>
        <Row gutter={24}>
          <Col span={8}>
            <div style={{ marginTop: 8 }}>
              <strong>
                GIC Bank:
              </strong>
              <div className={`${styles.bankBox} row`}>
                <div className={`col-md-6 col-sm-12 ${styles.imgBox1}`}>
                  <img src={'../dist/banks/iciciBank.jpeg'}
                       className={bank == 'iciciBank' ? styles.selected : ''}
                       onClick={() => setBank('iciciBank')}
                  />
                  {bank == 'iciciBank' ? <div className={styles.checkMark}>
                    <Icon type={'check'} className={styles.checkIcon} />
                  </div> : null}
                </div>
                <div className={`col-md-6 col-sm-12 ${styles.imgBox1}`}>
                  <img src={'../dist/banks/cibcBank.jpeg'}
                       className={bank == 'cibcBank' ? styles.selected : ''}
                       onClick={() => setBank('cibcBank')}
                  />
                  {bank == 'cibcBank' ? <div className={styles.checkMark}>
                    <Icon type={'check'} className={styles.checkIcon} />
                  </div> : null}
                </div>
              </div>
            </div>
          </Col>
          {inputTypes.fields.map((item, key) => {
            return (
              <>
                {item.customField ? (
                  <Col span={item.span ? item.span : 8}>
                    {item.customField}
                  </Col>
                ) : (
                  !item.hidden && (
                    <Col
                      span={item.span ? item.span : 8}
                      md={item.span ? item.span : 8}
                      sm={24}
                      xs={24}
                      key={key}
                      className={'mb10'}
                    >
                      {item.type === 'file' ? (
                        <FileInput
                          className={'mt10'}
                          key={refreshKey}
                          name={item.name}
                          label={item.label}
                          chooseDocument={item.onChange}
                        />
                      ) : (
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout}
                        />
                      )}
                    </Col>
                  )
                )}
              </>
            );
          })}
        </Row>
        <Card>
          <strong>
            The UnizPortal team may contact you for more information about the above GIC account processing. Please
            provide your contact information below.
          </strong><br />

          <Row gutter={24} className={'mt10'}>
            {inputTypes1.fields.map((item, key) => {
              return (
                <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8} sm={24} xs={24}
                     key={key}
                     className={'mb10'}>
                  <GetEachFormFields
                    item={item}
                    getFieldDecorator={getFieldDecorator}
                    formItemLayout={formItemLayout} />
                </Col>)
            })}
          </Row>
        </Card>
        <Button type={'primary'} htmlType='submit' className='btn'>
          Submit
        </Button>
      </Form>


    </div>
  )
}
export default Form.create()(AddGicComponentDraft)
