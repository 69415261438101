import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import {
  addCompanyUrl,
  listCompanyUrl,
  singleCompanyUrl,
  getCompanyNotesUrl,
  getCompanySingleNotesUrl,
  getCompanyDocumentsUrl,
  companyEmailActivitiesUrl,
  companySmsActivitiesUrl,
  submitCompanyNoteUrl,
  sendEmailToCompanyUrl,
  sendSmsToCompanyUrl,
  uploadCompanyDocumentUrl,
  uploadCompanyProfileImageUrl
} from '../api/company'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'

export const addCompany = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addCompanyUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const listAllCompany = (filters) => async (dispatch) => {
  dispatch({ type: 'COMPANY_SHOW_LOADER' })
  dispatch({ type: 'EMPTY_COMPANY_LIST' })
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(listCompanyUrl(), config)
  dispatch({ type: 'COMPANY_HIDE_LOADER' })
  dispatch({ type: 'LIST_ALL_COMPANY', payload: data.data })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data

}


export const getCompany = (id) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(singleCompanyUrl(id), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    dispatch({ type: 'LIST_COMPANY', payload: data })
  }
  return data.data
}


export const updateCompany = (valData, id) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(singleCompanyUrl(id), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}


export const getCompanyNotes = (valData) => async (dispatch) => {
  let { data } = await axios.post(getCompanyNotesUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const getCompanySingleNotes = (valData) => async (dispatch) => {
  // dispatch(showPageLoad())
  let { data } = await axios.post(getCompanySingleNotesUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}


export const loadCompanyDocuments = (valData) => async (dispatch) => {
  let { data } = await axios.post(getCompanyDocumentsUrl(), valData, getToken())
  return data
}

export const companyEmailActivities = (valData) => async (dispatch) => {
  let { data } = await axios.post(companyEmailActivitiesUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
export const companySmsActivities = (valData) => async (dispatch) => {
  let { data } = await axios.post(companySmsActivitiesUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const submitCompanyNote = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(submitCompanyNoteUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const sendSmsToCompany = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(sendSmsToCompanyUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const sendEmailToCompany = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(sendEmailToCompanyUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const uploadCompanyDocument = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(uploadCompanyDocumentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const uploadCompanyProfileImage = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(uploadCompanyProfileImageUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}
