import {apiUrl, apiUrl2} from '../../../settings'
import {UserRating} from '../actions/user'

export const addUserUrl = () => {
    return apiUrl + '/user'
}

export const listUserUrl = () => {
    return apiUrl + '/user'
}
export const listEmployeeUrl = () => {
    return apiUrl + '/employee'
}
export const addEmployeeUrl = () => {
    return apiUrl + '/employee'
}
export const branchManagerAgentListBySearchUrl = () => {
    return apiUrl + '/branchManagerAgentListBySearch'
}
export const branchManagerAgentListUrl = () => {
    return apiUrl + '/branchManagerAgentList'
}
export const marketingUserAgentListUrl = () => {
    return apiUrl + '/marketingUserAgentList'
}

export const singleUserUrl = id => {
    return apiUrl + '/user/' + id
}
export const singleEmployeeUrl = id => {
    return apiUrl + '/employee/' + id
}

export const updateUserExtraInfoUrl = id => {
    return apiUrl + '/updateUserExtraInfo/' + id
}
export const updateEmployeeExtraInfoUrl = id => {
    return apiUrl + '/updateEmployeeExtraInfo/' + id
}

export const userTypeWiseUrl = () => {
    return apiUrl + '/userTypeWise'
}

export const agentAppCountUrl = () => {
    return apiUrl + '/getAgentAppCount'
}
export const getAgentAppCountByParamsIdUrl = () => {
    return apiUrl + '/getAgentAppCountByParamsId'
}

export const subAgentAppCountByAgentUrl = () => {
    return apiUrl + '/getSubAgentAppCountByAgent'
}

export const updateUserRightsUrl = () => {
    return apiUrl + '/api/updateUserRights'
    // return apiUrl2 + '/api/updateUserRights'
}

export const getBranchUserForAssignApplicationUrl = () => {
    return apiUrl + '/api/getBranchUserForAssignApplication'
}

export const getBranchUserForReActivateApplicationUrl = () => {
    return apiUrl + '/api/getBranchUserForReActivateApplication'
}

export const loadNotificationCountUrl = () => {
    return apiUrl + '/api/loadNotificationCount'
}

export const resetNotificationCountUrl = () => {
    return apiUrl + '/api/resetNotificationCount'
}
export const resetPrivateMessageCountUrl = () => {
    return apiUrl + '/api/resetPrivateMessagesCount'
}

export const notificationReadyUrl = () => {
    return apiUrl + '/api/notificationReady'
}

export const privateMessageReadyUrl = () => {
    return apiUrl + '/api/privateMessageReady'
}

export const searchUser = () => {
    return apiUrl + '/search/user'
}

export const updatePasswordUrl = () => {
    return apiUrl + '/api/updatePassword'
}

export const UserRatingUrl = () => {
    return apiUrl + '/api/userRating'
}

export const allNotificationUrl = () => {
    return apiUrl + '/api/allNotification'
}
export const blockAgentUrl = () => {
    return apiUrl + '/api/blockAgent'
}
export const checkCertificationTokenUrl = () => {
    return apiUrl + '/checkCertificationToken'
}
export const uploadCertificateUrl = () => {
    return apiUrl + '/api/uploadCertificate'
}

export const contactUsUrl = () => {
    return apiUrl + '/api/contactUs'
}

export const UserLogsUrl = () => {
    return apiUrl + '/api/logs'
}

export const getAssignedUserForAccessmentDepartmentUrl = () => {
    return apiUrl + '/api/assignedUserForAccessmentDepartment'
}

export const exportAgentUrl = () => {
    return apiUrl + '/api/export_agent'
}
export const signeAgreementUrl = () => {
    return apiUrl + '/api/signeAgreement'
}

export const enquiryUrl = () => {
    return apiUrl + '/api/enquiry'
}
export const agentListUrl = () => {
    return apiUrl + '/agentsWithoutAuth'
}

export const educatorFeedbackUrl = () => {
    return apiUrl + '/api/educatorFeedback'
}

export const checkEnquiryFeedbackUrl = () => {
    return apiUrl + '/api/checkEnquiryFeedback'
}
export const enquiryFeedbackUrl = () => {
    return apiUrl + '/api/enquiryFeedback'
}

export const getAgentCertificateUrl = () => {
    return apiUrl + '/agentCertificate'
}

export const updateShareReferralAllowUrl = () => {
    return apiUrl + '/api/updateShareReferralAllow'
}
export const updateDefaultReferralUrl = () => {
    return apiUrl + '/api/updateDefaultReferral'
}
export const updateInsuranceRightUrl = () => {
    return apiUrl + '/api/updateInsuranceRight'
}
export const getRatingLogsUrl = () => {
    return apiUrl + '/ratingLogs'
}
export const getUserBirthdayListUrl = () => {
    return apiUrl + '/userBirthdayList'
}
export const broadcastingUrl = () => {
    return apiUrl + '/send-broadcast'
}
export const checkBroadcastingStatusUrl = () => {
    return apiUrl + '/checkBroadcastingStatus'
}

export const downloadCertificateUrl = () => {
    return apiUrl + '/api/download-certificate'
}

export const getOnlineAgentCountUrl = () => {
    return apiUrl + '/getOnlineAgentCount'
}
export const footFallUrl = () => {
    return apiUrl + '/footfall'
}
export const loadDistinctStateOfFootFallUrl = () => {
    return apiUrl + '/distinctStateOfFootFall'
}
export const getDistinctStateOfOnlineAgentsUrl = () => {
    return apiUrl + '/getDistinctStateOfOnlineAgents'
}

export const getMarketingUserByUniversityUrl = () => {
    return apiUrl + '/getMarketingUserByUniversity'
}

export const updateUserUrl = () => {
    return apiUrl + '/updateUser'
}

export const singleUserProfileUrl = () => {
    return apiUrl + '/singleUserProfile'
}

export const getNotAssignedMarketingUserInAgentsListUrl = () => {
    return apiUrl + '/getNotAssignedMarketingUserInAgentsList'
}

export const updateMarketingInAgentsUrl = () => {
    return apiUrl + '/updateMarketingInAgents'
}

export const singleAgentForMarketingUrl = () => {
    return apiUrl + '/singleAgentForMarketing'
}

export const updateMarketingUserInAgentUrl = () => {
    return apiUrl + '/updateMarketingUserInAgentFxn'
}

export const hideRegistrationMessageUrl = () => {
    return apiUrl + '/hideRegistrationMessage'
}
export const getMarketingAgentAppCountUrl = () => {
    return apiUrl + '/getMarketingAgentAppCount'
}
export const allUsersUrl = () => {
    return apiUrl + '/allUsers'
}

export const getBranchUserForDirectOnshoreUrl = () => {
    return apiUrl + '/api/getBranchUserForDirectOnshore'
}
export const closeAgentDealUrl = () => {
    return apiUrl + '/api/closeAgentDeal'
}

export const loadUserMobileUrl = () => {
    return apiUrl + '/api/loadUserMobile'
}
export const singleUserRightUrl = () => {
    return apiUrl2 + '/rights/single-user-right'
}

export const addAgentRightUrl = () => {
    return apiUrl + '/rights/add-right'
}

export const addUpdateAgentRightUrl = () => {
    return apiUrl + '/rights/add-update-right'
}

export const otherUsersUrl = () => {
    return apiUrl + '/other-users'
}

export const addTeamUrl = () => {
    return apiUrl + '/api/add-team'
}

export const addSubAgentUrl = () => {
    return apiUrl + '/api/add-sub-agent'
}

export const addSubAgentOtpUrl = () => {
    return apiUrl + '/api/add-user-send-otp'
}
export const uploadRegCertUrl = () => {
    return apiUrl + '/api/upload-certificate'
}

export const getUserCanadaWallerUrl = id => {
    return apiUrl2 + '/getCanadaWalletBalance'
}

export const sendAdvanceCommissionOtpUrl = () => {
    return apiUrl2 + '/sendAdvanceCommissionOtp'
}

export const advanceOtpVerificationUrl = () => {
    return apiUrl2 + '/incrementAdvanceDate'
}

export const getSingleUserUrl = () => {
    return apiUrl2 + '/getSingleUser'
}

export const getUserApi = () => {
    return apiUrl + '/api/get-single-user'
}

export const getTeamApi = () => {
    return apiUrl + '/api/get-agent-team'
}

export const webActivityUrl = () => {
    return apiUrl + '/api/web-activity'
}

export const regAgentUrl = () => {
    return apiUrl + '/reg-agents'
}
export const activeAgentsUrl = () => {
    return apiUrl + '/api/activeAgent'
}

export const activeCertificateUrl = () => {
    return apiUrl + '/api/active-certificate'
}

export const unverifiedAgentUrl = () => {
    return apiUrl + '/api/unverified-agents'
}
export const verifyAgentUrl = () => {
    return apiUrl + '/api/verify-agents'
}

export const autoAssignMarketingUserUrl = () => {
    return apiUrl + '/api/assign-auto-marketing-users'
}
export const getAllMarketingUsersUrl = () => {
    return apiUrl2 + '/api/getAllMarketingUsers'
}

export const getCallbackCountUrl = () => {
    return apiUrl2 + '/api/getCallbackCounts'
}
export const getCallbackListUrl = () => {
    return apiUrl2 + '/api/getAllCallbackLists'
}
export const getDistinctUserUrl = () => {
    return apiUrl2 + '/api/getDistinctUser'
}
export const reGenerateCertificateAndUpdateUrl = () => {
    return apiUrl + '/re-generate-certificate-and-update'
}
export const getAgentTargetUrl = () => {
    return apiUrl2 + '/api/get-agent-target'
}
export const getCountryDataUrl = () => {
    return apiUrl2 + '/api/getSingleCountry'
}

export const getAllMarketingTeamUrl = () => {
    return apiUrl2 + '/api/getMarketingTeam'
}

export const updateGiftByUserUrl = () => {
    return apiUrl2 + '/api/updateGiftByUser'
}
export const agentCountUrl = () => {
    return apiUrl + '/api/agent-count'
}

export const reeudoAgentListUrl = () => {
    return apiUrl2 + '/api/getReeudoAgentList'
}
