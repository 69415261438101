import React, {Component} from 'react'
import {TableComp} from 'sz-react-utils-lite'
import {DefaultTablePagination, displayDate} from '../../components/_utils/appUtils'
import {
    Tooltip, Icon,
    Popconfirm
} from 'antd'
import {connect} from 'react-redux'
import {reactivationListFxn, unblockUserFxn} from "./action";

class AllReactivationRequest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedUser: {},
            total: 0
        }
        this.tableRef = React.createRef()
    }

    componentDidMount() {
    }

    apiRequest = async (params) => {
        let {dispatch} = this.props
        return new Promise(async (resolve) => {
            let resp = await reactivationListFxn(params);
            this.setState({total: resp.total})
            resolve(resp)
        })
    }

    handleUnblock = async (userId) => {
        let {dispatch} = this.props
        let resp = await dispatch(
            unblockUserFxn({userId: userId})
        )
        if (resp && resp.success) {
            this.tableRef.current.reload()
        }
    };

    render() {
        let {total} = this.state;
        const columns = [
            {
                title: '#',
                key: '_id',
                dataIndex: '_id',
                width: 50,
                render: (ite, record, index) => {
                    return <React.Fragment>{index + 1}</React.Fragment>
                }
            },
            {
                title: 'Agent Name',
                key: 'name',
                dataIndex: 'name',
                width: 250,
                render: (item, record) => {
                    return (
                        <>
                            {item}<br/>
                            {record && record.companyName ? `(${record.companyName})` : ""}
                        </>
                    )
                }
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                width: 150,
            },
            {
                title: 'Requested Date ',
                dataIndex: 'createdAt',
                key: 'createdAt',
                width: 150,
                render: (item) => {
                    return (
                        <React.Fragment>
                            {item ? displayDate(item) : ''}
                        </React.Fragment>
                    )
                }
            },
            {
                title: 'Contact Person',
                key: 'contactPerson',
                dataIndex: 'name'
            },

            {
                title: 'Contact No',
                dataIndex: 'mobile',
                key: 'mobile'
            },

            {
                title: 'Designation',
                dataIndex: 'designation',
                key: 'designation'
            },
            {
                title: 'Marketing User Name',
                key: 'marketingUserName',
                dataIndex: 'marketingUserId',
                render: (item, record) => {
                    let {referralCode} = record;
                    return (
                        <>
                            {item && item.name ? item.name : ""}<br/> {referralCode ? `(Refer Code : ${referralCode})` : ""}
                        </>
                    )
                }
            },
            {
                title: 'Details',
                dataIndex: 'details',
                key: 'details',
                width: 250
            }
        ]
        return (
            <div className='row  mt-4'>
                <div className='col-lg-12'>
                    <div className='card'>
                        <div className='table-head d-flex align-items-center'>
                            <h5>All Reactivation Request <small> (Total : {total})</small></h5>
                        </div>
                        <div className='card-body table-responsive'>
                            <TableComp columns={columns}
                                       ref={this.tableRef}
                                       pagination={DefaultTablePagination()}
                                       apiRequest={this.apiRequest}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllReactivationRequest)
