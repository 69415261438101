import React, { useEffect } from 'react'
import { checkReportExists } from '../student/actions/student'
import { Form, Modal } from 'antd'
import { connect } from 'react-redux'
import { getUrlParams, getUrlPushWrapper } from '../../routes'
import { useDispatch } from 'react-redux'

const CheckReportExistsComponent = (props) => {
  const dispatch = useDispatch()
  const paramsData = getUrlParams('downloadReport', window.location.pathname)

  useEffect(() => {
    console.log('--------run download--------')
    loadDownloadReportPath()
  }, [])

  const loadDownloadReportPath = async () => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    if (user && user._id) {
      let { data, success, message } = await dispatch(checkReportExists({ uid: paramsData.id }))
      if (success) {
        if (data && data.filePath) {
          window.open(data.filePath, 'Download')
          dispatch(getUrlPushWrapper('dashboard'))
        } else {
          dispatch(getUrlPushWrapper('dashboard'))
        }
      } else {
        loadModal(message)
      }
    } else {
      loadModal('Login to download report')
    }
  }

  const loadModal = (message) => {
    Modal.error({
      title: 'Download Report',
      content: message,
      onOk: () => {
        dispatch(getUrlPushWrapper('dashboard'))
      }
    })
  }

  return null
}


const mapStateToProps = ({ global, router }) => ({
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckReportExistsComponent)

