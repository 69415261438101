import React, { useEffect, useState } from 'react'
import { Button, Drawer, Input, notification } from 'antd'
import renderHTML from 'react-render-html'
import { InputBox } from '../../../components/_utils/appUtils'
import { updateTaskFxn } from '../actions/taskManager'
import { useDispatch } from 'react-redux'

let { TextArea } = Input
const EditTaskDrawer = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, onSubmit, data } = props
  let [text, setText] = useState('')
  useEffect(() => {
    if (data.text) {
      setText(data.text)
    }
  }, [data])

  const handleUpdateTask = async () => {
    if (!text) {
      notification.warning({ message: 'Enter task' })
      return
    }
    const result = await dispatch(updateTaskFxn({ taskId: data._id, text: text }))
    if (!result.error) {
      onSubmit()
    }
  }


  return (
    <>
      <Drawer visible={visible} onClose={onClose} title={'Edit Task'} width={'45%'}>
        <div className='form-box'>
          <div className='card unizportal'>
            <InputBox>
              <TextArea
                value={text}
                style={{ 'minHeight': 200 }}
                onChange={({ target }) => {
                  setText(target.value)
                }}>
              </TextArea>
            </InputBox>
            {data.status == 'pending' ?
              <Button className='btn btn-primary' onClick={() => {
                handleUpdateTask()
              }}>
                Update Task
              </Button> : null}
          </div>
        </div>
      </Drawer>
    </>
  )
}
const RenderTask = props => {
  let { text, data, refreshTable } = props
  let [hideV, setHideV] = useState(true)
  let [visible, setVisible] = useState(false)
  let events = {
    showDrawer: () => {
      setVisible(true)
    },
    hideDrawer: () => {
      setVisible(false)
    }
  }
  return (
    <div>
      {text && (
        <div className={'display-linebreak'}>
          <div className={`pCursor ${hideV ? 'appTextReplace' : ''} ${data.status == 'complete' ? 'completeTask' : ''}`}
               onDoubleClick={events.showDrawer}>
            {renderHTML(text)}
          </div>

          <div className={'task_box'}>
            <div>
              {text && text.length && text.length > 130 ? (
                <div className={'alignRight colorPrimary'}>
                  {hideV ? (
                    <a
                      onClick={() => {
                        setHideV(false)
                      }}>
                      Show More
                    </a>
                  ) : (
                    <a
                      onClick={() => {
                        setHideV(true)
                      }}>
                      Show Less
                    </a>
                  )}
                </div>
              ) : null}
            </div>
          </div>

        </div>
      )}
      {visible ?
        <EditTaskDrawer
          visible={visible}
          data={data}
          onClose={events.hideDrawer}
          onSubmit={() => {
            events.hideDrawer()
            refreshTable()
          }} /> : null}
    </div>
  )
}
export default RenderTask
