import { Api_Url } from '../../../api'

export const pendingForVisaAppUrl = () => {
  return Api_Url + '/api/pendingForVisaAppList'
}
export const pendingForGcKeyAppUrl = () => {
  return Api_Url + '/api/pendingForGcKeyAppList'
}

export const canadaFcmtVisaApprovedAppListUrl = () => {
  return Api_Url + '/api/canadaFcmtVisaApprovedAppList'
}

export const cambieOnShoreApplicationListUrl = () => {
  return Api_Url + '/api/cambieOnShoreApplicationList'
}

export const fcmtOnShoreApplicationListUrl = () => {
  return Api_Url + '/api/fcmtOnShoreApplicationList'
}

export const cambieOnShoreApplicationExportApi = () => {
  return Api_Url + '/api/cambieOnShoreApplicationExport'
}
