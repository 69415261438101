import {hidePageLoad, showPageLoad} from "../../../modules/actions";
import {customAxios as axios, getToken} from "../../../request";
import {notification} from "antd";
import {
    addUnizhomeContractUrl,
    unizhomeContractListUrl,
    unizhomeContractNoteUrl,
    updateUnizhomeContractUrl,
    unizhomeNoteListUrl,
    unizhomeActivityUrl,
    getSingleContractUrl
} from "../api";


export const addUnizhomeContractFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addUnizhomeContractUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data
}
export const updateUnizhomeContractFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateUnizhomeContractUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data
}

export const unizhomeContractListFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(unizhomeContractListUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const unizhomeContractNoteFxn = (valData) => async (dispatch) => {
    console.log(valData, "valdata0000")
    dispatch(showPageLoad())
    let {data} = await axios.post(unizhomeContractNoteUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data
}

export const unizhomeNoteListFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(unizhomeNoteListUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const unizhomeActivityFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(unizhomeActivityUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const getSingleContractFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(getSingleContractUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}