import React from 'react'
import { Button, Checkbox, Form, Input } from 'antd'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import Footer from '../footer'
import { Opportunity } from './opportunityComponent'

const PrivacyPolicy = () => {
  let dispatch = useDispatch()

  return (
    <React.Fragment>

      <section className="banner blog-banner blog-inner">

        <div className="container">
          <div className="head">
            <h2>Privacy Policy</h2>
          </div>
        </div>

      </section>

      <section className="gap-62 policy blog-inner pt-0">
        <div className="container">

          <div className="box">
            <p>Last Updated: June 13, 2021</p>

            <h4>This Privacy Policy applies to UnizPortal.com</h4>
            <p><i>We recommend that you read this Policy carefully in order to understand what UnizPortal.com and any
              affiliates and/or subsidiaries do with your personal data. Your use of our Website and Services and any
              dispute over privacy, is subject to this Policy and any of our applicable Terms and Conditions for use of
              our Services, including their applicable limitations on damages and the resolution of disputes. </i></p>

            <p>UnizPortal.com (“UnizPortal,” “UnizPortal.com”, “we,” “us,” or “our”) provides users with the ability to
              investigate the process of becoming a student at certain schools around the world, including information
              respecting visa applications and program information. We also provide the ability to apply for admission
              to certain programs of such institutions through our recruitment partners, counsellors and our website and
              to access ancillary services and products related to the international student journey (collectively, the
              “Services”).</p>

            <div className="content">
              <h5>General</h5>
              <p>UnizPortal.com recognises the importance of maintaining your privacy. We value your privacy and
                appreciate your trust in us. This Policy describes how we treat user information we collect on
                https://unizportal.com/ and other offline sources. This Privacy Policy applies to current and former
                visitors to our website and to our online customers. By visiting and/or using our website, you agree to
                this Privacy Policy.</p>
              <p>UnizPortal.com is a property of UNIZ PORTAL Private Limited, an Indian Company registered under the
                Companies Act, 2013 having its registered office at Flat Number 814, 8th Floor, Chiranjiv Tower,
                Building No. 43, Nehru Place, New Delhi, 110019
                IN. </p>
            </div>

            <div className="content">
              <h5>Information we collect</h5>
              <p><strong>Contact information. </strong>We might collect your name, email, mobile number, phone number,
                street, city, state, pincode, country, IP address and other relevant information for providing the best
                of our services to you.</p>

              <p><strong>Payment and billing information. </strong> We might collect your billing name, billing address
                and payment method when we offer you our services. We NEVER collect your credit card number or credit
                card expiry date or other details pertaining to your credit card on our website. Credit card information
                will be obtained and processed by our online payment partner. </p>

              <p><strong>Information you post. </strong> We collect information you post in a public space on our
                website or on a third-party site belonging to UnizPortal.com.</p>

              <p><strong>Demographic information. </strong> We may collect demographic information about you, your
                interests related to the services provided by us, or any other information provided by you during the
                use of our website. We might collect this as a part of a survey also.</p>

              <p><strong>Professional or Employment information. </strong> We may collect your name and contact
                information, professional credentials and skills, educational and work history, salary, evaluations,
                references, interviews, certifications, disciplinary matters and other information of the type included
                on a resume, curriculum vitae or cover letter.</p>

              <p><strong>Other information. </strong> If you use our website, we may collect information about your IP
                address and the browser you're using. We might look at what site you came from, duration of time spent
                on our website, pages accessed or what site you visit when you leave us. We might also collect the type
                of mobile device you are using, or the version of the operating system your computer or device is
                running. </p>

            </div>

            <div className="content">
              <h5>We collect information in different ways.</h5>

              <p><strong>We collect information directly from you. </strong> We collect information directly from you
                when you register for a service(s) or explore the various services provided by us. We also collect
                information if you post a comment on our websites or ask us a question through phone or email.</p>

              <p><strong>We collect information from you passively. </strong> We use tracking tools like Google
                Analytics, Google Webmaster, browser cookies and web beacons for collecting information about your usage
                of our website. </p>
              We use tracking tools like Google Analytics, Google Webmaster, browser cookies and web beacons for
              collecting information about your usage of our website.
              <p><strong>We get information about you from third parties. </strong> For example, if you use an
                integrated social media feature on our websites. The third-party social media site will give us certain
                information about you. This could include your name and email address.</p>

              <p><strong>We get information when you use our services for Visa Processing. </strong> We offer students
                the option of processing their student visas through Uniz Portal. If you choose to use this service, we
                may collect personal data for the purposes of processing your visa application (if necessary), including
                passport details and information included on a visa application form.</p>

              <p><strong>We collect information when you register to take any Courses. </strong> You will have the
                ability to access our course content and provide voluntary personal data so we can register you as an
                individual taking the course. As a certificate of completion may be issued, we will keep a record of you
                having registered and/or completed the course offering.</p>
            </div>

            <div className="content">
              <h5>Use of your personal information</h5>

              <p><strong>We use information to contact you: </strong> We might use the information you provide to
                contact you for confirmation of a purchase of a service on our website, if we may require any additional
                information from your end or for other promotional purposes.</p>

              <p><strong>We use information to respond to your requests or questions. </strong> We might use your
                information to confirm your registration for a service that you have chosen for yourself. </p>

              <p><strong>We use information to improve our products and services. </strong> We might use your
                information to customize your experience with us. This could include displaying content based upon your
                preferences or customizing the services based on your requirements.</p>

              <p><strong>We use information to look at site trends and interests. </strong> We may use your information
                to make our website and products better. We may combine information we get from you with information
                about you that we get from third parties.</p>

              <p><strong>We use information for security purposes. </strong> We may use information to protect our
                company, our customers, or our websites.</p>

              <p><strong>We use information for marketing purposes. </strong> We might send you information about
                special promotions or offers. We might also tell you about new features or products. These might be our
                own offers or products, or third-party offers or products we think you might find interesting. Or, for
                example, if you apply visa through us, we will save your record in our database and contact you at the
                time of the renewal.</p>

              <p><strong>We use information to send you transactional communications. </strong> We might send you emails
                or SMS about your purchase of a service from us.</p>

              <p><strong>We provide information to third parties with whom we work. </strong> We may obtain personal
                data about you through your legal representative, or a recruitment partner(s) or counsellor(s) who you
                are working with and who are using our services to process your application to schools. Occasionally,
                Uniz Portal will obtain information about you from third parties, such as mailing list providers and
                third-party service providers. Third-party providers are responsible for obtaining consent to provide
                personal data to us.</p>

              <p>We use information as otherwise permitted by law.</p>
            </div>

            <div className="content">
              <h5>Sharing of information with third-parties</h5>

              <p><strong>We will share information with third parties who perform services on our behalf. </strong> We
                share information with vendors who help us manage our online registration process or payment processors
                or transactional message processors. Some vendors may be located outside of India.</p>

              <p><strong>We will share information with the event organizers. </strong> We share your information with
                institutions, counsellors and other parties responsible for fulfilling your requirements. The
                institutions and other parties may use the information we give them as described in their privacy
                policies. </p>

              <p><strong>We will share information with our business partners. </strong> This includes a third party who
                provides or sponsors an event, or who operates a venue where we hold events. Our partners use the
                information we give them as described in their privacy policies. </p>

              <p><strong>We may share information if we think we have to in order to comply with the law or to protect
                ourselves. </strong> We will share information to respond to a court order or subpoena. We may also
                share it if a government agency or investigatory body requests. Or, we might also share information when
                we are investigating potential fraud. </p>

              <p><strong>We will share information to respond to a court order or subpoena. We may also share it if a
                government agency or investigatory body requests. Or, we might also share information when we are
                investigating potential fraud. </strong> We will tell you before we do this.</p>

            </div>

            <div className="content">
              <h5>Safeguards</h5>
              <p>UnizPortal takes the safeguarding of your information very seriously. We have implemented reasonable
                administrative, technical and physical safeguards in an effort to protect against unauthorized access,
                use, modification and disclosure of personal data in our custody and control, including limiting access
                to our database to legitimate users and employing device encryption and version tracking.</p>

              <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we
                will do our best to protect your personal data, we cannot guarantee the security of your data
                transmitted to our website; any transmission is at your own risk. Once we have received your
                information, we will use strict procedures and security features to try to prevent unauthorized
                access.</p>
            </div>

            <div className="content">
              <h5>Use of Cookies and Other Tracking Mechanisms</h5>

              <p>We and our third-party service providers use cookies and other tracking mechanisms (including tracking
                technologies designed for mobile applications) to track information about your use of our Website and
                Services. We may combine this information with other personal data we collect from you (and our
                third-party service providers may do so on our behalf).</p>

              <p><strong>Cookies. </strong> Cookies are alphanumeric identifiers that we transfer to your computer’s
                hard drive through your web browser for record-keeping purposes. Some cookies allow us to make it easier
                for you to navigate our website and services, while others are used to enable a faster log-in process or
                to allow us to track your activities at our website and service. </p>

              <p>Cookies may involve the transmission of information from us to you and from you directly to us, to
                another party on our behalf, or to another party in accordance with its privacy policy. We may use
                cookies to bring together information we collect about you. You can choose to have your device warn you
                each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your
                browser settings. If you turn cookies off, you won’t have access to many features that make your guest
                experience more efficient and some of our services will not function properly. </p>
            </div>

            <div className="content">
              <h5>Disabling/Enabling Cookies Via Your Browser</h5>
              <p>There are a number of ways for you to manage cookies and other tracking technologies. Through your
                browser settings, you can accept or decline cookies or set your browser to prompt you before accepting a
                cookie from the websites you visit. You should be aware that you may not be able to use all our
                interactive features if you set your browser to disable cookies entirely.</p>

              <p>If you use different computers in different locations, you will need to ensure that each browser is
                adjusted to suit your preferences.</p>

              <p>You can delete or disable any cookies that have been installed in the cookie folder of your browser.
                The various browsers provide different procedures to manage your settings</p>

              <p>You should also be aware that disabling cookies does not disable other types of analytics products
                which we may use to collect generic information about how you and other visitors use our website.</p>
            </div>

            <div className="content">
              <h5>Data Retention</h5>
              <p>Uniz Portal will only retain your personal data while it is needed to fulfil the intended purpose for
                which it was collected or as otherwise permitted or required by law or contract (which may extend beyond
                the termination of our relationship with you). </p>

              <p>Typically, personal data is maintained during the time you are engaged in the institutional program you
                applied to, or for up to a year following our last contact with you. We may retain certain data as
                necessary for: </p>

              <ul className="mb-3">
                <li>(i) legitimate business purposes, such as auditing, account recovery;</li>
                <li>(ii) if required by applicable laws;</li>
                <li>(iii) to prevent fraud or future abuse, or</li>
                <li>(iv) by contract with a partner and/or institution.</li>
              </ul>

              <p>All retained personal data is treated in accordance with this privacy policy.</p>

              <p>If you request that your personal data be removed from our databases and/or systems, it may not be
                possible to completely delete all your personal data due to technological and legal constraints.
                However, we will take such steps as are reasonable to securely destroy the information or to ensure that
                the information is de‑identified and/or anonymized as necessary. </p>

              <p>Should you request additional services from Uniz Portal which are subject to this Privacy Policy, you
                will be authorizing the extension of the data collection and/or processing purpose as a result. </p>

              <p>Your document(s) will be retained for as long as they are needed for the intended purpose, or as
                required by law or contract (which may extend beyond the termination of our relationship with you). </p>

              <p>The entity, student or institutional partner which has uploaded the document(s) for verification may
                request premature deletion of uploaded files. This is most likely to occur in the case where a content
                correction or update is required.</p>


            </div>

            <div className="content">
              <h5>Email Opt-Out</h5>
              <p><strong>You can opt out of receiving our marketing emails. </strong> To stop receiving our promotional
                emails related to our services, please email <b><i>info@unizportal.com.</i></b> It may take about ten
                days to process your request. Even if you opt out of getting promotional messages, we will still be
                sending you transactional messages through email and SMS about your purchase of services from us. </p>
            </div>

            <div className="content">
              <h5>Third party sites </h5>
              <p>If you click on one of the links to third party websites, you may be taken to websites we do not
                control. This policy does not apply to the privacy practices of those websites. Read the privacy policy
                of other websites carefully. We are not responsible for these third party sites.</p>
            </div>

            {/* <div className="content">
              <h5>Grievance Officers</h5>
              <p>In accordance with Information Technology Act 2000 and rules made there under, the name and contact
                details of the Grievance Officer are provided below:</p>

              <p>Address: 3080 Yonge St. Suite 6060, Toronto, ON, M4N 3N1.
                Phone: +1 647 255 8857
                Email: info@unizportal.com</p>

              <p>If you have any questions about this policy or other privacy concerns, you can also email us at
                info@unizportal.com</p>
            </div>*/}

            <div className="content">
              <h5>If you have any questions about this policy or other privacy concerns, you can also email us at
                info@unizportal.com</h5>
              <p>This Privacy Policy was last updated on <b>09.06.2021.</b> From time to time we may change our privacy
                practices. We will notify you of any material changes to this policy as required by law. We will also
                post an updated copy on our website. </p>

              <p>We strongly encourage you to check back frequently to see any updates or changes to our Policy.</p>
            </div>

            <div className="content">
              <h5>Jurisdiction</h5>
              <p>If you choose to visit the website, your visit and any dispute over privacy is subject to this policy
                and the website's terms of use. In addition to the foregoing, any disputes arising under this policy
                shall be governed by the laws of India. </p>
            </div>

          </div>

        </div>
      </section>
      <Opportunity/>
    </React.Fragment>
  )
}

export default PrivacyPolicy
