import React, { useState, useEffect } from 'react'
import { Button, Card, Drawer, Form, Input, notification } from 'antd'
import { RowTable } from '../../../components/_utils/RowTable'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { InputBox } from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import {
  eduBoardAndUniversity,
  getStudent,
  updateApplicationFamilyName,
  updateStudent
} from '../../student/actions/student'
import { uploadAppDocumentAndStatus } from '../actions'

const AdvancePayDrawer = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, studentId, applicationId, reloadTable, studentData } = props
  let [advancePay, setAdvancePay] = useState('')

  let handleSubmit = async () => {


    if (!advancePay) {
      return notification.warn({ message: 'Advance Fee' })
    }


    let fd = new FormData()

    let obj = {
      _id: studentId,
      status: 'Advance Fee',
      studentId,
      advancePay,
      applicationId
    }

    fd.append('obj', JSON.stringify(obj))

    let data = await dispatch(uploadAppDocumentAndStatus(fd))

    if (data && data.success) {
      onClose()
      reloadTable()
    }
  }


  return (
    <Drawer visible={visible}
            title={`Add Advance Fee`}
            width={600}
            closable={true}
            maskClosable={true}
            onClose={onClose}>
      <Card>
        <Form className={'vertical-form'} autoComplete='off'>
          <Form.Item>
            <InputBox title={'Advance Fee'}>
              <Input type={'number'} onChange={(e) => setAdvancePay(e.target.value)}
                     value={advancePay} className={'form-control'} />
            </InputBox>
          </Form.Item>
          {/*  <Form.Item>
            <InputBox title={'Student ID'}>
              <Input onChange={(e) => setStudentIdNo(e.target.value)}
                     value={studentIdNo} className={'form-control'}/>
            </InputBox>
          </Form.Item>*/}
          <Form.Item style={{ marginTop: 25 }}>
            <Button type='primary' onClick={handleSubmit}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  )
}
export default AdvancePayDrawer
