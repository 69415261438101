import { customAxios as axios, getToken } from '../../../../request'
import { notification } from 'antd'
import {
  AddBankAcountUrl,
  AddGicCommissionUrl,
  AddTransactionUrl,
  BankAcountListUrl,
  UpdateGicCommissionUrl,
  UpdateGicDocumentsUrl,
  addWithdrawalUrl,
  gicCommissionCountUrl,
  gicCommissionListMonthWiseUrl,
  gicCommissionListUrl,
  sendWithdrawalOtpUrl,
  transactionListUrl,
  updateWithdrawalUrl, getNextWithdrawalDateUrl, reUploadDocumentsUrl
} from './api'
import { showPageLoad, hidePageLoad } from '../../../../modules/actions'

export const gicCommissionListFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...(await getToken())
  }
  let { data } = await axios.get(gicCommissionListUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const AddBankAccountFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(AddBankAcountUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
export const BankAccountListFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...(await getToken())
  }
  let { data } = await axios.get(BankAcountListUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const AddGicCommissionFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(AddGicCommissionUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
export const AddTransactionFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(AddTransactionUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
export const UpdateGicCommissionFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    UpdateGicCommissionUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
export const transactionListFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...(await getToken())
  }
  let { data } = await axios.get(transactionListUrl(), config)
  dispatch(hidePageLoad())
  return data
}
export const gicCommissionCountFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...(await getToken())
  }
  let { data } = await axios.get(gicCommissionCountUrl(), config)
  dispatch(hidePageLoad())
  return data
}
export const gicCommissionListMonthWiseFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...(await getToken())
  }
  let { data } = await axios.get(gicCommissionListMonthWiseUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const addWithdrawalFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addWithdrawalUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const sendWithdrawalOtpFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(sendWithdrawalOtpUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const updateWithdrawalFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateWithdrawalUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
export const UpdateGicDocumentsFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(UpdateGicDocumentsUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const getNextWithdrawalDateFxn = async () => {
  let { data } = await axios.get(getNextWithdrawalDateUrl(), null)
  return data
}


export const reUploadDocumentsFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(reUploadDocumentsUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}