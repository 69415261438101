import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  notification
} from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { InputBox } from '../../../components/_utils/appUtils'
import {
  addAccountSendOtpFxn,
  addBankAccount,
  listAllAccount
} from '../../bankAccount/actions/bankAccount'

let initState = {
  bankName: '',
  accountNo: '',
  ifscCode: '',
  swiftCode: '',
  address: ''
}
const AddBankAccount = props => {
  let [state, setState] = useState(initState)
  let [otp, setOtp] = useState('')
  let [visibleOtpScreen, setVisibleOtpScreen] = useState(false)
  let [bankList, setBankList] = useState([])
  let { onClose, visible } = props
  let dispatch = useDispatch()
  useEffect(() => {
    loadBankList()
  }, [])
  const loadBankList = async () => {
    let resp = await dispatch(listAllAccount({}))
    let { data, success } = resp
    if (success) {
      setBankList(data)
    }
  }
  const handleChange = data => {
    setState(prevData => {
      return {
        ...prevData,
        ...data
      }
    })
  }

  let columns = [
    {
      title: 'Bank Name',
      key: 'bankName',
      dataIndex: 'bankName',
      render: (item, record) => {
        let { accounts } = record
        return accounts.bankName
      }
    },
    {
      title: 'Account No',
      key: 'accountNo',
      dataIndex: 'accountNo',
      render: (item, record) => {
        let { accounts } = record
        return accounts.accountNo
      }
    },
    {
      title: 'IFSC',
      key: 'ifscCode',
      dataIndex: 'ifscCode',
      render: (item, record) => {
        let { accounts } = record
        return accounts.ifscCode
      }
    },
    {
      title: 'Swift Code',
      key: 'swiftCode',
      dataIndex: 'swiftCode',
      render: (item, record) => {
        let { accounts } = record
        return accounts.swiftCode
      }
    },
    {
      title: 'Address',
      key: 'address',
      dataIndex: 'address',
      render: (item, record) => {
        let { accounts } = record
        return accounts.address
      }
    }
  ]

  const handleSubmit = async e => {
    e.preventDefault()
    if (!state.bankName) {
      notification.warning({ message: 'Enter bank name' })
      return
    }
    if (!state.accountNo) {
      notification.warning({ message: 'Enter account number' })
      return
    }
    if (!state.ifscCode) {
      notification.warning({ message: 'Enter IFSC code' })
      return
    }
    // if (!state.swiftCode) {
    //   notification.warning({ message: 'Enter Swift code' })
    //   return
    // }
    // if (!state.address) {
    //   notification.warning({ message: 'Enter bank address' })
    //   return
    // }
    let resp = await dispatch(addAccountSendOtpFxn(state))
    if (resp && resp.success) {
      setVisibleOtpScreen(true)
    }
  }
  const handleSubmitAddAccount = async e => {
    e.preventDefault()
    if (!state.bankName) {
      notification.warning({ message: 'Enter bank name' })
      return
    }
    if (!state.accountNo) {
      notification.warning({ message: 'Enter account number' })
      return
    }
    if (!state.ifscCode) {
      notification.warning({ message: 'Enter IFSC code' })
      return
    }
    // if (!state.swiftCode) {
    //     notification.warning({message: "Enter Swift code"})
    //     return
    // }
    // if (!state.address) {
    //     notification.warning({message: "Enter bank address"})
    //     return
    // }
    if (!otp) {
      notification.warning({ message: 'Please enter otp' })
      return
    }
    let resp = await dispatch(addBankAccount({ ...state, otp }))
    if (resp && resp.success) {
      handleChange(initState)
      setVisibleOtpScreen(false)
      setOtp('')
      loadBankList()
    }
  }
  return (
    <Drawer
      visible={visible}
      title={'Add Bank Account'}
      onClose={onClose}
      width={'60%'}>
      <div className="card unizportal">
        <Form
          className={'vertical-form'}
          autoComplete="off"
          onSubmit={handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                <InputBox title={'Bank Name'}>
                  <Input
                    onChange={({ target }) => {
                      handleChange({ bankName: target.value })
                    }}
                    value={state.bankName}
                    className={'form-control'}
                    name="bankName"
                    placeholder="Please enter bank name"
                  />
                </InputBox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <InputBox title={'Account Number'}>
                  <Input
                    onChange={({ target }) => {
                      handleChange({ accountNo: target.value })
                    }}
                    value={state.accountNo}
                    className={'form-control'}
                    name="accountNo"
                    placeholder="Please enter Account Number"
                  />
                </InputBox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <InputBox title={'IFSC Code'}>
                  <Input
                    onChange={({ target }) => {
                      handleChange({ ifscCode: target.value })
                    }}
                    value={state.ifscCode}
                    placeholder="Please enter IFSC Code"
                    name="ifscCode"
                    className={'form-control'}
                  />
                </InputBox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <InputBox title={'Swift Code'}>
                  <Input
                    onChange={({ target }) => {
                      handleChange({ swiftCode: target.value })
                    }}
                    value={state.swiftCode}
                    placeholder="Please enter Swift Code"
                    name="swiftCode"
                    className={'form-control'}
                  />
                </InputBox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <InputBox title={'Address'}>
                  <Input.TextArea
                    rows={4}
                    onChange={({ target }) => {
                      handleChange({ address: target.value })
                    }}
                    value={state.address}
                    placeholder="please enter address"
                    name="address"
                    className={'form-control'}
                  />
                </InputBox>
              </Form.Item>
            </Col>
          </Row>
          <Button type="primary" htmlType="submit" className={'btn'}>
            Add
          </Button>
        </Form>
      </div>
      <div className="card unizportal">
        <TableComp dataSource={bankList} columns={columns} pagination={false} />
      </div>
      <Modal
        visible={visibleOtpScreen}
        closable={false}
        title={'Enter OTP'}
        footer={false}>
        <div style={{ textAlign: 'center', marginBottom: 10 }}>
          <h6>
            {' '}
            A text message with a 4-digit verification code has been sent to
            your phone number.
          </h6>
          <InputBox title={'Enter OTP'}>
            <input
              className={'form-control mt10'}
              type={'password'}
              minLength={4}
              maxLength={4}
              value={otp}
              onChange={({ target }) => {
                setOtp(target.value)
              }}
            />
          </InputBox>
          <Button
            className={'btn btn-success mt10'}
            onClick={handleSubmitAddAccount}>
            Submit OTP
          </Button>
        </div>
      </Modal>
    </Drawer>
  )
}
export default AddBankAccount
