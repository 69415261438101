import React, { PureComponent } from 'react'
import { Icon } from 'antd'
import { debounce } from 'lodash-decorators'
import styles from './index.less'
import RightContent from './RightContent'
import {LazyLoadImage} from 'react-lazy-load-image-component'

export default class GlobalHeader extends PureComponent {
  componentWillUnmount() {
    this.triggerResizeEvent.cancel()
  }

  @debounce(600)

  triggerResizeEvent() {
    // eslint-disable-line
    const event = document.createEvent('HTMLEvents')
    event.initEvent('resize', true, false)
    window.dispatchEvent(event)
  }

  toggle = () => {
    const { collapsed, onCollapse } = this.props
    onCollapse(!collapsed)
    this.triggerResizeEvent()
  }

  render() {
    const { collapsed, logo } = this.props
    return (
      <div className={styles.header}>
        <span className={`${styles.trigger} mobileMenu`} onClick={this.toggle}>
          <Icon type={collapsed ? 'menu' : 'menu'}/>
        </span>

        <div className={'imgDiv'}>
          <a className={'logo-link'} href={'/dashboard'}>
            <LazyLoadImage src={logo} alt="logo" className={'logoImg'}/>
          </a>

          <form className="input-group-box ml-3">
            <div className="input-group input-group-sm">
            </div>
          </form>
        </div>


        <RightContent {...this.props} />
      </div>
    )
  }
}
