import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "antd";
import { useDispatch } from "react-redux";
import { allTransactionFxn } from "../actions"; // Adjust this import according to your structure

const AgentTransactionModal = (props) => {
    const {
        visible,
        onClose,
        rappId
    } = props;

    const dispatch = useDispatch();
    const [recentTransactionId, setRecentTransactionId] = useState(null);

    useEffect(() => {
        if (visible) {
            // fetchRecentTransaction();
        }
    }, [visible]);

    const fetchRecentTransaction = async () => {
        let params = {
            results: 100,
            count: 100,
        };

        const response = await dispatch(allTransactionFxn(params));

        /*   if (response && response.data) {
               let sortedData = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
               setRecentTransactionId(sortedData[0].rappId);
           }*/
    };

    return (
        <Modal
            visible={visible}
            title={"Transaction Added"}
            onCancel={onClose}
            width={"35%"}
            footer={null}
        >
            <div className="card transaction-modal">
                <Row gutter={24}>
                    <Col span={24}>
                        {rappId ? (
                            <>
                                <p>A transaction with the ID <strong>{rappId}</strong> has been recently added.</p>
                                <p>Click the link below to see the full transaction:</p>
                                <a href={'/forex-transactions'} target="_blank" rel="noopener noreferrer">
                                    View Full Transaction
                                </a>
                            </>
                        ) : (
                            <p>Loading recent transaction...</p>
                        )}
                    </Col>
                </Row>
                <div style={{ marginTop: "20px" }}>
                    <Button onClick={onClose} className={"roundBtn"}>
                        Close
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default AgentTransactionModal;
