import React, { useState, useRef } from 'react'
import { Icon, Tooltip } from 'antd'
import { TableComp } from 'sz-react-utils-lite'
import { interestedRequestListFxn } from '../../actions/cornerStone'
import { useDispatch } from 'react-redux'
import UpdateInterestedList from './updateInterestedList'

const InterestedRequestList = () => {
  const [total, setTotal] = useState(0)
  let [updateState, setUpdateState] = useState({
    requestedId: '',
    visible: false
  })

  const dispatch = useDispatch()
  const tableRef = useRef()

  const apiRequest = params => {
    return new Promise(async resolve => {

      let data = await dispatch(
        interestedRequestListFxn({
          ...params,
          regExFilters: ['name', 'collegeName', 'mobile', 'email', 'status']
        })
      )
      setTotal(data.total)
      resolve(data)
    })
  }

  const events = {
    showDrawer: (requestedId, status) => {
      setUpdateState({
        requestedId,
        visible: true
      })
    },
    hideDrawer: () => {
      setUpdateState({
        requestedId: '',
        status: '',
        visible: false
      })
    },
    hideAndUpdateDrawer: () => {
      setUpdateState({
        requestedId: '',
        visible: false
      })
      tableRef.current.reload()
    }
  }

  const columns = [
    {
      title: 'S.No',
      dataIndex: '_id',
      width: 50,
      key: '_id',
      render: (item, record, index) => {
        return <a className={'linkPointer'}>{index + 1}</a>
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      searchTextName: 'name'
    },
    {
      title: 'College Name',
      dataIndex: 'collegeName',
      key: 'collegeName',
      searchTextName: 'collegeName'
    },
    {
      title: 'Mobile no',
      dataIndex: ['userId', 'mobile'],
      key: 'mobile',
      searchTextName: 'mobile'
    },
    {
      title: 'Email',
      dataIndex: ['userId', 'email'],
      key: 'email',
      searchTextName: 'email'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      searchTextName: 'status',
      render: status => {
        let labelClass = ''
        switch (status) {
          case 'Pending':
            labelClass = 'label label-warning label-sm'
            break
          case 'Approved':
            labelClass = 'label label-success label-sm'
            break
          case 'Rejected':
            labelClass = 'label label-danger label-sm'
            break
          default:
            labelClass = ''
            break
        }
        return <label className={labelClass}>{status}</label>
      }
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      searchTextName: 'reason'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 150,
      render: (item, record) => {
        let { status } = record
        return (
          <>
            {status == 'Pending' ? <div className={'btn_group'} style={{ width: 200 }}>
              <>
                <Tooltip title={'Update Status'}>
                  <a className={'btn btn-default roundNew xs'} onClick={() => {
                    events.showDrawer(record._id)
                  }}>
                    Update Status
                  </a>
                </Tooltip>
              </>
            </div> : null}
          </>
        )
      }
    }
  ]

  return (
    <div>
      <div className='row  mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>All Interested Request List ({total})</h5>
              <div className='search-box-table' />

              <div className='sort-box-table mark-btn' />
            </div>

            <div className='card-body table-responsive'>
              <TableComp
                columns={columns}
                apiRequest={apiRequest}
                ref={tableRef}
                className={'table table-striped table-valign-middle'}
              />
            </div>
          </div>
        </div>
      </div>
      {updateState.visible ? (
        <UpdateInterestedList
          {...updateState}
          onSubmit={events.hideAndUpdateDrawer}
          onClose={events.hideDrawer}
        />
      ) : null}
    </div>
  )
}

export default InterestedRequestList
