import { apiUrl } from '../../../settings'

export const enrollmentLetterUrl = () => {
  return apiUrl + '/api-cambie-onshore/createEnrollmentLetter'
}
export const paymentReceiptUrl = () => {
  return apiUrl + '/api-cambie-onshore/createPaymentReceipt'
}

export const createLoaLetterUrl = () => {
  return apiUrl + '/api-cambie-onshore/createLoaLetter'
}

export const updateStudentUrl = () => {
  return apiUrl + '/updateStudent'
}
export const updateChargeAmountUrl = () => {
  return apiUrl + '/api/update-charge-amount'
}
export const bankVerifyUrl = () => {
  return apiUrl + '/api/cambie-bank-verify'
}

export const createSupportingLetterUrl = () => {
  return apiUrl + '/api-cambie-onshore/createSupportingLetter'
}

export const addTranscriptOfMarksUrl = () => {
  return apiUrl + '/cambie/update-marksheet'
}
export const generateEslCertificateUrl = () => {
  return apiUrl + '/cambie/generate-esl-certificate'
}

export const deferLoaUrl = () => {
  return apiUrl + '/api/defer-loa'
}
export const getAllDocumentsUrl = () => {
  return apiUrl + '/api/app-documents'
}
