import React, {useEffect, useRef, useState} from "react"
import {singleAgentCommissionFxn,} from "../actions";
import {Avatar, Card, Drawer, Table} from "antd";
import {useDispatch} from "react-redux";
import {
    defaultLogoList,
    displayDate, longStringDate, CapText
} from "../../../components/_utils/appUtils";

const AgentCommissionStudentList = (props) => {
    let dispatch = useDispatch()
    let {visible, onClose, record} = props;
    let [commissionData, setCommissionData] = useState({})

    let [studentList, setStudentList] = useState([])
    let columns = [
        {
            title: 'Student',
            dataIndex: 'name',
            key: 'name',
            width: 250,
            render: (item, record) => {
                let {studentId, commissionDetails, appId, commissionId} = record;
                return (
                    <div style={{width: 150}}>
                        <CapText>{item}</CapText>
                        {commissionId && commissionId.commissionDetails && commissionId.commissionDetails.offerLetterStudentId ?
                            <div>
                                Offer S. ID : {commissionId.commissionDetails.offerLetterStudentId}
                            </div> : null}
                        {appId ? <div>
                            App ID : {appId}
                        </div> : null}
                    </div>
                )
            }
        },
        {
            title: 'Semester',
            dataIndex: 'semester',
            key: 'semester',
            width: 100,
            render: (item, record) => {
                let {commissionDetails} = record;
                let {semesterFee} = record;
                return (
                    <div style={{width: 100}}>
                        {item}
                        <div>
                            ({commissionDetails.commissionCurrency} {semesterFee})
                        </div>
                    </div>
                )
            }
        },
        /*
                {
                    title: 'Comm. Rec.',
                    dataIndex: 'claimReceivedDate',
                    key: 'claimReceivedDate',
                    width: 100,
                    render: (item, record) => {
                        let {commissionId, amount, commissionDetails} = record;
                        return (
                            <div style={{width: 100}}>
                                {commissionDetails.commissionCurrency} {amount}
                                <br/>
                                {commissionId && commissionId.claimReceivedDate ? displayDate(commissionId.claimReceivedDate) : ""}
                            </div>
                        )
                    }
                },*/
        {
            title: 'Course Date',
            dataIndex: 'courseStartDate',
            key: 'courseStartDate',
            width: 100,
            render: (item, record) => {
                let {commissionDetails} = record
                return (
                    <div style={{width: 100}}>
                        {commissionDetails &&
                        commissionDetails.courseStartDate ?
                            displayDate(commissionDetails.courseStartDate) : ""}
                    </div>
                )
            }
        },

        {
            title: 'Intake',
            dataIndex: 'intake',
            key: 'intake',
            width: 100,
            render: (item, record) => {
                let {commissionId} = record
                return (
                    commissionId && commissionId.intake && commissionId.intake.month ? <div style={{width: 100}}>
                        {commissionId.intake.month}-{commissionId.intake.year}
                    </div> : null
                )
            }
        },

        {
            title: 'Agent Share',
            dataIndex: 'commission',
            key: 'commission',
            render: (item, record) => {
                let {commissionInInr, currencyRate, commissionId, commissionDetails} = record;

                return (
                    <>
                        {commissionDetails && commissionDetails.commissionCurrency == 'INR' ?
                            <>
                                {commissionDetails.commissionCurrency ? commissionDetails.commissionCurrency : ""} {commissionInInr}
                            </> :
                            <>
                                {commissionDetails.commissionCurrency ? commissionDetails.commissionCurrency : ""} {item}
                            </>
                        }
                    </>
                )
            }
        },

        {
            title: 'University',
            key: 'universityId',
            dataIndex: 'universityId',
            width: 150,
            render: (item, record) => {
                return (
                    <div style={{width: 150}}>
                        {item && item.logo && item.logo.url ?
                            <Avatar src={item.logo.url} size={20}/>
                            : <Avatar src={defaultLogoList.defaultUniversityLogo} size={20}/>}
                        {item.universityName ? `  ${item.universityName}` : ''}
                    </div>
                )
            }
        },
        {
            title: 'Program',
            dataIndex: 'courseName',
            key: 'courseName',
        },


    ]

    useEffect(() => {
        loadData()
    }, [])
    let loadData = async () => {
        let resp = await dispatch(singleAgentCommissionFxn({commissionId: record.agentCommissionId}));
        if (resp && resp.success) {
            setStudentList(resp.data.commissionList)
            setCommissionData(resp.data)
        }
    }

    return (
        <>
            <Drawer
                title={(
                    <>
                        Agent Commission Student
                        List {commissionData.invoiceDate ? ` - (Raised Commission Date : ${displayDate(commissionData.invoiceDate)})` : ""}
                    </>
                )}

                visible={visible}
                width={'80%'}
                onClose={onClose}>
                <div className="form-box commissionBox">
                    <div className="card unizportal">
                        <div className='card-body table-responsive'>
                            <Table className={'table table-bordered'}
                                   dataSource={studentList}
                                   columns={columns}
                                   pagination={false}
                                   bordered={true}/>
                        </div>
                    </div>
                </div>
            </Drawer>
        </>
    )
}
export default AgentCommissionStudentList
