import React from "react"
import {Drawer} from "antd";
import renderHTML from 'react-render-html'
import {LazyLoadImage} from 'react-lazy-load-image-component'

const SingleWhatsNewDrawer = (props) => {
    let {item, onClose, visible} = props;
    return (
        <Drawer
            title={'Whats New'}
            visible={visible}
            width={'45%'}
            onClose={onClose}>
            <div className="form-box">
                <div className="card unizportal">
                    {renderHTML(item.content)}
                    {item.attachment && item.attachment.url ?
                        <LazyLoadImage src={item.attachment.url} style={{width: "100%"}}/> : null}
                </div>
            </div>
        </Drawer>
    )
}
export default SingleWhatsNewDrawer;
