import React, { useState, useEffect, useRef } from 'react'
import {
  Input,
  Button,
  Select,
  List,
  Popconfirm,
  Icon,
  DatePicker,
  Modal,
  Table,
  notification
} from 'antd'
import { TableComp } from 'sz-react-utils-lite'
import moment from 'moment'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'antd/dist/antd.css'
import '../style.css'
import { taskLists, addTask, updateTask, deleteTask, getTaskGrouped } from '../actions/taskManager'
import { Collapse } from 'antd'
import { DefaultTablePagination, displayDate, taskUpdateRights } from '../../../components/_utils/appUtils'
import RenderTask from './taskComponent'

let { TextArea } = Input

const TaskManager = (props) => {
  let { currentUser } = props
  const { Option } = Select
  let conRef = useRef()
  const [newTask, setNewTask] = useState('')
  const [deadline, setDeadline] = useState(null)
  const [assignee, setAssignee] = useState('')
  const [priority, setPriority] = useState('Normal')
  const [taskList, setTaskList] = useState([])
  const [showCompleteTasks, setShowCompleteTasks] = useState(undefined)
  const [audioStream, setAudioStream] = useState(null)
  const [videoStream, setVideoStream] = useState(null)
  const [screenStream, setScreenStream] = useState(null)
  const [audioRecording, setAudioRecording] = useState(false)
  const [screenRecording, setScreenRecording] = useState(false)
  const [audioFile, setAudioFile] = useState(null)
  const [screenRecordingFile, setScreenRecordingFile] = useState(null)
  const [videoOn, setVideoOn] = useState(true)
  const [audioOn, setAudioOn] = useState(true)
  const [showScreenRecordingModal, setShowScreenRecordingModal] = useState(false)
  const [screenRecordingDataUrl, setScreenRecordingDataUrl] = useState(null)
  const [showAudioRecordingModal, setShowAudioRecordingModal] = useState(false)
  const [audioRecordingDataUrl, setAudioRecordingDataUrl] = useState(null)
  let [screenChunkArr, setScreenChunkArr] = useState([])
  let [audioChunkArr, setAudioChunkArr] = useState([])
  let [screenPlayState, setScreenPlayState] = useState({
    screenUrl: '',
    visible: false
  })
  let [audioPlayState, setAudioPlayState] = useState({
    audioUrl: '',
    visible: false
  })
  let [pageState, setPageState] = useState({ count: 10, page: 1, groupCount: 10, groupPage: 1 })


  const audioRecorder = useRef(null)
  const screenRecorder = useRef(null)
  const liveVideoRef = useRef(null)


  let [deadLineState, setDeadLineState] = useState({
    visible: false,
    taskId: '',
    deadline: ''
  })
  let [priorityState, setPriorityState] = useState({
    visible: false,
    taskId: ''
  })
  let [showGroupTask, setShowGroupTask] = useState(undefined)
  let [groupedTask, setGroupedTask] = useState([])

  const dispatch = useDispatch()
  const history = useHistory()
  const tableRef = useRef()
  const { Panel } = Collapse
  const tableStyles = {
    boxShadow: '0 8px 12px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px'
  }

  useEffect(() => {
    if (screenRecording) {
      liveStreamEvents.startLiveVideo()
    } else {
      liveStreamEvents.stopLiveVideo()
    }
  }, [screenRecording])

  useEffect(() => {
    if (!videoOn) {
      if (liveVideoRef.current) {
        liveVideoRef.current.srcObject = null
      }
    } else {
      if (liveVideoRef.current) {
        liveVideoRef.current.srcObject = videoStream
      }
    }
  }, [videoOn, videoStream])

  useEffect(() => {
    const toggleSystemAudio = () => {
      if (audioStream && audioStream.getAudioTracks) {
        audioStream.getAudioTracks().forEach(track => {
          track.enabled = audioOn
        })
      }
    }
    toggleSystemAudio()
  }, [audioOn, audioStream])

  useEffect(() => {
    screenEvents.stopScreenRecordingFxn()
  }, [screenChunkArr])
  useEffect(() => {
    audioEvents.setAudioRecordingFxn()
  }, [audioChunkArr])

  const liveStreamEvents = {
    startLiveVideo: async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true })
        setVideoStream(stream)
        const videoElement = liveVideoRef.current
        if (videoElement) {
          videoElement.srcObject = stream
        }
      } catch (error) {
        console.error('Error accessing video media devices:', error)
      }
    },
    stopLiveVideo: () => {
      const videoElement = liveVideoRef.current
      if (videoElement) {
        videoElement.srcObject = null
      }
      if (videoStream) {
        videoStream.getTracks().forEach(track => track.stop())
        setVideoStream(null)
      }
    }
  }
  const screenEvents = {
    startScreenRecording: async () => {
      let videoTrack, audioTrack, stream
      try {
        let audioConstraint = audioOn ? true : false
        const screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: { exact: true, suppressLocalAudioPlayback: true },
          selfBrowserSurface: 'include'
        })
        setScreenStream(screenStream)
        setScreenRecording(true)
        let chunks = []
        let startTime = Date.now()

        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        [videoTrack] = screenStream.getVideoTracks();
        [audioTrack] = audioStream.getAudioTracks()
        stream = new MediaStream([videoTrack, audioTrack]) // do stuff
        const recorder = new MediaRecorder(stream)
        screenRecorder.current = recorder
        recorder.ondataavailable = (event) => {
          console.log(event.data)
          if (event.data.size > 0) {
            chunks.push(event.data)
          }
        }

        recorder.onstop = () => {
          let endTime = Date.now()
          let duration = (endTime - startTime) / 1000
          if (duration >= 120) {
            console.log('Screen recording time limit (2 minutes) reached.')
            return
          }
          setScreenChunkArr((prevChunk) => {
            return [
              ...prevChunk,
              ...chunks
            ]
          })
        }


        recorder.start()
        setVideoStream(videoStream)
        setVideoOn(true)
      } catch (error) {
        console.error('Error accessing screen media devices:', error)
      }
    },
    stopScreenRecording: () => {
      if (screenRecorder.current && screenRecording) {
        screenRecorder.current.stop()
      }
    },
    stopScreenRecordingFxn: () => {
      if (screenRecording && screenChunkArr && screenChunkArr.length) {
        setScreenRecording(false)
        const screenBlob = new Blob(screenChunkArr, { type: 'video/mp4' })
        setScreenRecordingFile(screenBlob)
      }
    },
    downloadScreenRecording: () => {
      if (screenChunkArr && screenChunkArr.length > 0) {
        const blob = new Blob(screenChunkArr, { type: screenChunkArr[0].type })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'screen_recording.mp4'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)
      }
    },
    playScreenRecording: () => {
      if (screenChunkArr && screenChunkArr.length > 0) {
        const blob = new Blob(screenChunkArr, { type: 'video/mp4' })
        const dataUrl = URL.createObjectURL(blob)
        setScreenRecordingDataUrl(dataUrl)
        screenEvents.toggleScreenRecordingModal()
      }
    },
    deleteScreenRecording: () => {
      setScreenChunkArr([])
      setScreenRecordingFile(null)
      setScreenRecordingDataUrl(null)
      setShowScreenRecordingModal(false)
    },
    toggleVideo: () => {
      setVideoOn(!videoOn)
    },
    toggleScreenRecordingModal: () => {
      setShowScreenRecordingModal(!showScreenRecordingModal)
    }
  }
  const tableEvents = {
    openScreenModal: (url) => {
      setScreenPlayState({
        visible: true,
        screenUrl: url
      })
    },
    closeScreenModal: () => {
      setScreenPlayState({
        visible: false,
        screenUrl: ''
      })
    },
    openAudioModal: (url) => {
      setAudioPlayState({
        audioUrl: url,
        visible: true
      })
    },
    closeAudioModal: () => {
      setAudioPlayState({
        audioUrl: '',
        visible: false
      })
    }
  }
  const audioEvents = {
    startAudioRecording: async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        setAudioStream(stream)
        const recorder = new MediaRecorder(stream)
        audioRecorder.current = recorder
        let chunk = ''
        recorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunk = event.data
          }
        }

        recorder.onstop = () => {
          setAudioChunkArr((prevChunk) => {
            return [
              ...prevChunk,
              chunk
            ]
          })
        }

        recorder.start()
        setAudioRecording(true)
      } catch (error) {
        console.error('Error accessing audio media devices:', error)
      }
    },
    stopAudioRecording: () => {
      if (audioRecorder.current && audioRecording) {
        audioRecorder.current.stop()
      }
    },
    deleteAudioRecording: () => {
      setAudioChunkArr([])
      setAudioRecordingDataUrl(null)
      setAudioFile(null)
      setShowAudioRecordingModal(false)
    },
    toggleAudio: () => {
      setAudioOn(!audioOn)
    },
    toggleAudioRecordingModal: () => {
      setShowAudioRecordingModal(!showAudioRecordingModal)
    },
    setAudioRecordingFxn: () => {
      if (audioRecording && audioChunkArr && audioChunkArr.length > 0) {
        const audioBlob = new Blob(audioChunkArr, { type: 'audio/mp3' })
        const audioUrl = URL.createObjectURL(audioBlob)
        setAudioRecordingDataUrl(audioUrl)
        setAudioFile(audioBlob)
        audioEvents.toggleAudioRecordingModal()
      }
    },
    playAudioRecording: () => {
      if (audioRecordingDataUrl) {
        audioEvents.toggleAudioRecordingModal()
      }
    }
  }

  const recordingFxn = () => {
    let audioTrack, videoTrack, stream // define initial variable here for ability to call stop() on each track
    navigator.mediaDevices.getDisplayMedia({ video: true, selfBrowserSurface: 'include' })
      .then(async displayStream => {
        console.log(displayStream, 'displayStream')
          [videoTrack] = displayStream.getVideoTracks()
        console.log(videoTrack, 'videoTrack')
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
        console.log(audioStream, 'audioStream')
          [audioTrack] = audioStream.getAudioTracks()
        // displayStream.addTrack(audioTrack); // do stuff
        // or
        stream = new MediaStream([videoTrack, audioTrack]) // do stuff
        console.log(stream)
        const recorder = new MediaRecorder(stream)
        screenRecorder.current = recorder
        let chunks = []

        recorder.ondataavailable = (event) => {
          console.log(event)
          if (event.data.size > 0) {
            chunks.push(event.data)
          }
        }
        console.log(recorder, 'recorder')
        recorder.onstop = () => {
          console.log('run')
        }

        recorder.start()

      })
      .catch(console.error)
  }


  const priorityOptions = [
    {
      key: 'Urgent',
      label: 'Urgent',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: '#b13a41', fontSize: '16px' }} />
    },
    {
      key: 'High',
      label: 'High',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: '#eca500', fontSize: '16px' }} />
    },
    {
      key: 'Normal',
      label: 'Normal',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: '#4466ff', fontSize: '16px' }} />
    },
    {
      key: 'Low',
      label: 'Low',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: 'gray', fontSize: '16px' }} />
    },
    { key: 'Clear', label: 'Clear', icon: <Icon type={'stop'} style={{ color: 'gray', fontSize: '16px' }} /> }
  ]

  const handleCreateTask = async () => {
    if (!newTask) {
      notification.warning({ message: 'Enter task.' })
      return
    }
    const formData = new FormData()
    const newTaskObject = {
      text: newTask,
      status: 'pending',
      deadline,
      priority: priority
      // screenRecord: audioFile,
      // audioRecord: screenRecordingFile
    }
    formData.append('obj', JSON.stringify(newTaskObject))
    if (audioFile) {
      formData.append('audioRecord', audioFile)
    }
    if (screenRecordingFile) {
      formData.append('screenRecord', screenRecordingFile)

    }

    try {
      const result = await dispatch(addTask(formData))

      if (!result.error) {
        setTaskList((prevData) => {
          return [
            result.data,
            ...prevData
          ]
        })
        setNewTask('')
        setDeadline(null)
        setAssignee('')
        setScreenRecordingDataUrl(null)
        setAudioRecordingDataUrl(null)
        setScreenChunkArr([])
        setAudioChunkArr([])
        setScreenRecordingFile(null)
        setAudioFile(null)
        events.reloadGroup()
      } else {
        // console.error(result.message);
      }
    } catch (error) {
      console.error('Error adding task:', error)
    }
  }

  useEffect(() => {
    if (showCompleteTasks !== undefined || showGroupTask !== undefined) {
      events.reloadGroup()
    }
  }, [showCompleteTasks, showGroupTask])

  const handleShowCompleteTasks = () => {
    setShowCompleteTasks(prevShowCompleteTasks => !prevShowCompleteTasks)
  }

  const deadlineEvents = {
    chooseDate: (date) => {
      setDeadLineState((prevState) => {
        return {
          ...prevState,
          ...date
        }
      })
    },
    showDeadlineModal: (data) => {
      setDeadLineState({
        visible: true,
        deadline: data.deadline,
        taskId: data._id
      })
    },
    hideDeadlineModal: () => {
      setDeadLineState({
        visible: false,
        deadline: '',
        taskId: ''
      })
    },
    handleDeadlineChange: async (data) => {
      if (data.taskId) {
        const result = await dispatch(updateTask(data.taskId, data))
        if (!result.error) {
          events.reloadGroup()
          deadlineEvents.hideDeadlineModal()
        } else {
          console.error(result.message)
        }
      }
    }
  }

  const events = {
    reloadGroup: () => {
      if (conRef.current && conRef.current.props && conRef.current.props.reload) {
        conRef.current.props.reload()
      }
      if (tableRef.current && tableRef.current.reload) {
        tableRef.current.reload()
      }
    },
    handleDeleteTask: async (taskId) => {
      const response = await dispatch(deleteTask(taskId))
      if (!response.error) {
        events.reloadGroup()
      }
    },
    handleSaveEdit: (taskId, newText) => {
      const updatedTaskList = taskList.map((task) =>
        task.id === taskId ? { ...task, text: newText, isEditing: false } : task
      )
      setTaskList(updatedTaskList)
    },
    handleOpenPriorityModal: (taskId = '') => {
      setPriorityState({
        taskId: taskId,
        visible: true
      })
    },
    handleClosePriorityModal: () => {
      setPriorityState({
        taskId: '',
        visible: false
      })
    },
    getPriorityColor: (priority) => {
      const priorityOption = priorityOptions.find((option) => option.key === priority)
      return priorityOption ? priorityOption.icon.props.style.color : ''
    },
    handleShowFutureTasks: () => {
      history.push('/future-tasks')
    },
    handleSelectPriority: async (priority) => {
      events.handleClosePriorityModal()
      if (priorityState.taskId) {
        try {
          const result = await dispatch(updateTask(priorityState.taskId, { priority }))
          if (!result.error) {
            events.reloadGroup()
          } else {
          }
        } catch (error) {
          console.error('Error updating task priority:', error)
        }
      } else {
        setPriority(priority)
      }
    },
    handleStatusChange: async (taskId, status) => {
      const result = await dispatch(updateTask(taskId, { status }))
      if (!result.error) {
        events.reloadGroup()
      }
    },
    apiRequest: async (params) => {
      return new Promise(async (resolve) => {
        if (!showCompleteTasks) {
          params['customQuery'] = { status: { $ne: 'complete' } }
        }
        const { data } = await dispatch(taskLists(params))
        resolve(data)
      })
    },
    apiRequestGrouped: async (params = {}) => {
      if (!showCompleteTasks) {
        params['customQuery'] = { status: { $ne: 'complete' } }
      }
      const { data } = await dispatch(getTaskGrouped(params))
      setGroupedTask(data.data)
    }
  }

  const taskColumns = [
    {
      key: '_id',
      title: '#',
      dataIndex: '_id',
      render: (item, record, index) => {
        return (
          <>
            {pageState.count * (pageState.page - 1) + (index + 1)}
          </>
        )
      }
    },
    {
      title: 'Task',
      dataIndex: 'text',
      key: 'text',
      render: (text, record) => {
        let background = 'transparent'
        if (record.status === 'inprogress') {
          background = 'conic-gradient(red 0 75%, transparent 25%)'
        }
        if (record.status === 'in-review') {
          background = '#4466ff'
        }
        if (record.status === 'complete') {
          background = 'green'
        }
        return (
          <div style={{ display: 'flex', alignItems: 'flex-start', maxWidth: '100%' }}>
            <div
              style={{
                width: '16px',
                height: '16px',
                borderRadius: '50%',
                border: record.status === 'pending' ? '1px solid black' : 'none',
                background: background,
                marginRight: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                flexShrink: 0
              }}>
              {record.status === 'complete' && <Icon type='check' style={{ color: 'white' }} />}
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                maxWidth: '100%',
                flex: 1
              }}>
              <div style={{ textDecoration: record.status === 'complete' ? 'line-through' : 'none', width: '100%' }}>
                <RenderTask text={text} data={record} refreshTable={events.reloadGroup} />
              </div>
            </div>
          </div>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status, record) => {
        return (
          <>
            {taskUpdateRights(currentUser) ? <>
              <Select defaultValue={status} className={'small-select'}
                      onChange={(value) => events.handleStatusChange(record._id, value)}>
                <Option value='pending'>Pending</Option>
                <Option value='inprogress'>In Progress</Option>
                <Option value='in-review'>In Review</Option>
                <Option value='complete'>Complete</Option>
              </Select>
            </> : <>
              {status == 'pending' ? 'Pending' : ''}
              {status == 'inprogress' ? 'In Progress' : ''}
              {status == 'in-review' ? 'In Review' : ''}
              {status == 'complete' ? 'Complete' : ''}
            </>}
          </>
        )
      }
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      width: 90,
      render: (taskPriority, record) => {
        const priorityColor = events.getPriorityColor(taskPriority)
        return (
          <Button type='link' onClick={() => {
            if (taskUpdateRights(currentUser)) {
              events.handleOpenPriorityModal(record._id)
            }
          }}>
            <Icon type={'flag'} theme={'filled'}
                  style={{ color: priorityColor, fontSize: '14px' }} />
            {taskPriority && <span style={{ marginLeft: '8px', fontSize: '13px' }}>{taskPriority}</span>}
          </Button>
        )
      }
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      width: 90,
      render: (deadline, record) => (
        <div style={{ width: 120 }}>
          <Button type='link' className={'font14'} onClick={() => {
            if (taskUpdateRights(currentUser)) {
              deadlineEvents.showDeadlineModal(record)
            }
          }}>
            <Icon type='calendar' style={{ fontSize: '13px' }} />
            {deadline ? displayDate(deadline) : null}
          </Button>
        </div>
      )
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 100,
      render: (date) => moment(date).format('DD MMM YY')
    },
    {
      title: 'Audio',
      dataIndex: 'audioRecord',
      key: 'audioRecord',
      width: 50,
      render: (audioRecord) => (
        <div>
          {audioRecord && (
            <Icon
              type='play-circle'
              style={{ color: 'blue', cursor: 'pointer', fontSize: '14px' }}
              onClick={() => tableEvents.openAudioModal(audioRecord.url)}
            />
          )}
        </div>
      )
    },
    {
      title: 'Screen',
      dataIndex: 'screenRecord',
      key: 'screenRecord',
      width: 50,
      render: (screenRecord) => (
        <div>
          {screenRecord && (
            <Icon
              type='play-circle'
              style={{ color: 'green', cursor: 'pointer', fontSize: '14px' }}
              onClick={() => tableEvents.openScreenModal(screenRecord.url)}
            />
          )}
        </div>
      )
    },
    {
      key: 'actions',
      width: 30,
      render: (record) => (
        <div>
          {taskUpdateRights(currentUser) ?
            <>
              <Popconfirm
                title='Are you sure you want to delete this task?'
                onConfirm={() => events.handleDeleteTask(record._id)}
                okText='Yes'
                cancelText='No'
                placement='topRight'>
                <Button type={'link'}>
                  <Icon type={'close'} />
                </Button>
              </Popconfirm>
            </> : null}
        </div>
      )
    }
  ]
  return (
    <>

      {screenRecording && (
        <div className='mini-screen-container'>
          <div className='mini-screen'>
            {screenRecording && (
              <video
                ref={liveVideoRef}
                controls
                autoPlay
                className='live-video'
                srcObject={videoOn ? videoStream : null}
              />
            )}
            {screenRecording && (
              <div className='stop-icon'>
                <Icon type='close' onClick={screenEvents.stopScreenRecording} style={{
                  color: 'white',
                  fontSize: '18px',
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '10px',
                  right: '10px'
                }} />
                {audioOn ? (
                  <div className='audio-icon' onClick={audioEvents.toggleAudio}>
                    <Icon type={'audio'} style={{
                      color: 'white',
                      fontSize: '18px',
                      cursor: 'pointer',
                      position: 'absolute',
                      top: '10px',
                      right: '40px'
                    }} />
                  </div>
                ) : (
                  <Icon type={'audio'} style={{
                    color: 'red',
                    fontSize: '18px',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '10px',
                    right: '40px'
                  }} onClick={audioEvents.toggleAudio} />
                )}
                {videoOn ? (
                  <div className='video-icon' onClick={screenEvents.toggleVideo}>
                    <Icon type={'video-camera'} style={{
                      color: 'white',
                      fontSize: '18px',
                      cursor: 'pointer',
                      position: 'absolute',
                      top: '10px',
                      right: '70px'
                    }} />
                  </div>
                ) : (
                  <Icon type={'video-camera'} style={{
                    color: 'red',
                    fontSize: '18px',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '10px',
                    right: '70px'
                  }} onClick={screenEvents.toggleVideo} />
                )}
              </div>
            )}
          </div>
        </div>
      )}

      <div className='row'>

        <div className='col-lg-12'>
          <div className='form-box mt-4'>
            <div className='d-flex align-items-center mb-3 heading-form'>
              <h5>Task Manager</h5>
            </div>
            <div className='card unizportal'>
              <div className={'row'}>
                <div className={'col-md-10'}>
                  <TextArea
                    placeholder='Enter new task'
                    value={newTask}
                    style={{ minHeight: 170 }}
                    onChange={(e) => setNewTask(e.target.value)}
                    className='input-hover'
                  />

                </div>

                <div className={'col-md-2'}>
                  <div className={'row'}>
                    <div className={'col-md-12'}>
                      <DatePicker
                        className='date-picker input-hover dateH35'
                        value={deadline ? moment(deadline) : null}
                        placeholder='Deadline'
                        onChange={(date) => setDeadline(date)}
                      />
                    </div>

                    <div className={'col-md-12 mt5'}>
                      <Input
                        placeholder='Priority'
                        value={priority}
                        disabled={true}
                        onChange={(e) => setPriority(e.target.value)}
                        suffix={<Icon type={'flag'} style={{ color: '#eca500', fontSize: '16px' }}
                                      onClick={() => {
                                        events.handleOpenPriorityModal()
                                      }} />}
                      />

                    </div>
                    {audioFile && audioFile.type ? <div className={'col-md-12 mt5'}>
                      <Input
                        accept='audio/*'
                        value={audioFile ? audioFile.type : ''}
                        disabled={true}
                        suffix={<Icon type={'play-circle'} onClick={audioEvents.playAudioRecording}
                                      style={{ color: 'blue', cursor: 'pointer' }} />}
                      />
                    </div> : null}
                    {screenRecordingFile && screenRecordingFile.type ?
                      <div className={'col-md-12 mt5'}>
                        <Input accept='video/*' value={screenRecordingFile ? screenRecordingFile.type : ''}
                          // disabled={true}
                               suffix={<Icon type={'play-circle'} onClick={screenEvents.playScreenRecording}
                                             style={{ color: 'green', cursor: 'pointer' }} />}
                        />
                      </div> : null}
                  </div>

                </div>

              </div>
              <div className={'row mt10'}>
                <div className={'col-md-6 '}>
                  <div className='recorder-buttons'>
                    <>
                      {screenRecording ? (
                        <Button className='btn btn-danger' onClick={screenEvents.stopScreenRecording}
                                style={{ marginRight: '10px', fontSize: '12px' }}>Stop Screen Recording
                          <Icon type={'stop'} onClick={screenEvents.stopScreenRecording} className='btn-record'
                                disabled={screenRecording} />
                        </Button>
                      ) : (
                        <>
                          {!audioRecording && (
                            <Button className='btn btn-primary me3' onClick={screenEvents.startScreenRecording}
                                    style={{ marginRight: '10px', fontSize: '12px' }}>Start Screen Recording
                              <Icon type={'video-camera'} onClick={screenEvents.startScreenRecording}
                                    className='btn-record'
                                    disabled={audioRecording} />
                            </Button>
                          )}
                        </>
                      )}
                    </>

                    <>
                      {audioRecording ? (
                        <>
                          <Button className='btn btn-danger me3' onClick={audioEvents.stopAudioRecording}
                                  style={{ marginRight: '10px', fontSize: '12px' }}>Stop Audio
                            <Icon
                              type={'stop'}
                              onClick={audioEvents.stopAudioRecording}
                              className='btn-record'
                            />
                          </Button>

                          <Button className='audio-recording-icon' style={{
                            padding: '0',
                            border: 'none',
                            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
                            background: 'white',
                            textDecoration: 'underline'
                          }}>

                            <Icon type='audio' theme='twoTone' style={{ fontSize: '16px', color: 'darkBlue' }} />
                          </Button>

                        </>
                      ) : (
                        <>
                          {!screenRecording && (

                            <Button className='btn btn-primary me3' onClick={audioEvents.startAudioRecording}
                                    style={{ marginRight: '10px', fontSize: '12px' }}>Start Audio
                              <Icon
                                type={'audio'}
                                onClick={audioEvents.startAudioRecording}
                                className='btn-record'
                              />
                            </Button>
                          )}

                        </>
                      )}
                    </>


                  </div>
                </div>
                <div className={'col-md-6 alignRight'}>
                  <Button
                    type='primary'
                    style={{ backgroundColor: '#0f52ba', border: 'none', marginRight: 5, borderRadius: 5 }}
                    onClick={handleCreateTask}
                    className='primary-button-hover'>
                    Add Task
                  </Button>
                  <Button className='cancel-button-hover'>
                    Cancel
                  </Button>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className='col-lg-12'>
          <div className='form-box'>
            <div className='card unizportal'>
              <div className={'mt5'}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: 10
                }}>
                  <Button
                    className='group-by-button-hover mr10'
                    onClick={() => {
                      setShowGroupTask(!showGroupTask)
                    }}>
                    {showGroupTask ? 'Un - Group By' : 'Group By'}
                  </Button>

                  <Button
                    className='show-complete-tasks-button-hover'
                    onClick={handleShowCompleteTasks}>
                    {showCompleteTasks ? 'Hide Complete Tasks' : 'Show Complete Tasks'}
                  </Button>
                  <Button className='show-complete-tasks-button-hover'
                          onClick={events.handleShowFutureTasks}>
                    Future Task
                  </Button>


                </div>
              </div>
              {showGroupTask ? (
                <div>
                  <Collapse ref={conRef} reload={() => {
                    events.apiRequestGrouped()
                  }}>
                    {groupedTask && groupedTask.length ? groupedTask.map((item) => {
                      const formattedDate = moment(item.date).format('Do MMM YYYY')
                      return (
                        <Panel key={item._id} header={formattedDate}>
                          <Table
                            // style={tableStyles}
                            columns={taskColumns}
                            dataSource={item.tasks}
                            pagination={false}
                            size={'small'} bordered={true} />
                        </Panel>
                      )
                    }) : null}
                  </Collapse>
                </div>
              ) : (
                <TableComp columns={taskColumns} style={tableStyles}
                           apiRequest={events.apiRequest}
                           pagination={DefaultTablePagination()}
                           ref={tableRef}
                           rowKey='_id' />
              )}
            </div>
          </div>
        </div>
      </div>


      {priorityState.visible ? <Modal
        title='Priority'
        visible={priorityState.visible}
        onCancel={events.handleClosePriorityModal}
        footer={null}
        width={200}>
        <List dataSource={priorityOptions}
              renderItem={(item, index) => (
                <List.Item
                  onClick={() => events.handleSelectPriority(item.key)}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 0',
                    borderBottom: index === 3 ? '1px solid #d9d9d9' : 'none',
                    backgroundColor: priority === item.key ? '#e6f7ff' : 'inherit'
                  }}>
                  {item.icon && <span style={{ marginRight: '8px' }}>{item.icon}</span>}
                  <div style={{
                    textAlign: 'center',
                    fontSize: '14px',
                    color: '#000'
                  }}>{item.label}</div>
                </List.Item>
              )}
        />

      </Modal> : null}

      {deadLineState.visible ?
        <Modal
          title='Select Deadline'
          visible={deadLineState.visible}
          onCancel={deadlineEvents.hideDeadlineModal}
          onOk={() => deadlineEvents.handleDeadlineChange(deadLineState)}>
          <DatePicker
            value={deadLineState.deadline ? moment(deadLineState.deadline) : null}
            onChange={(date) => deadlineEvents.chooseDate({ deadline: date })}
          />
        </Modal> : null}
      <Modal
        title='Screen Recording'
        visible={showScreenRecordingModal}
        onCancel={screenEvents.toggleScreenRecordingModal}

        width={'60%'}
        centered
        style={{ margin: '50px' }}
        footer={null}
      >
        <video
          src={screenRecordingDataUrl}
          controls
          style={{ maxWidth: '100%', maxHeight: '80vh' }}
        />
        <div style={{ marginTop: '10px' }}>
          <Icon type={'delete'} onClick={screenEvents.deleteScreenRecording}
                style={{ marginRight: '10px', textAlign: 'end' }} />
          <Icon type={'download'} onClick={screenEvents.downloadScreenRecording}
                style={{ marginRight: '10px', textAlign: 'end' }} />
        </div>
      </Modal>
      {showAudioRecordingModal ? <Modal
        title='Audio Recording'
        visible={showAudioRecordingModal}
        onCancel={audioEvents.toggleAudioRecordingModal}
        width={'80%'}
        centered
        style={{ margin: '50px' }}
        footer={null}
        className='audio-recording-modal'
      >
        <div>
          <audio
            src={audioRecordingDataUrl}
            controls
            style={{ width: '100%', maxHeight: '80vh' }}
          />
          {audioRecordingDataUrl && (
            <Icon type='delete' onClick={audioEvents.deleteAudioRecording}
                  className='delete-audio-btn' />
          )}
        </div>
      </Modal> : null}


      {audioPlayState.visible ? <Modal
        title='Audio Player'
        visible={audioPlayState.visible}
        onCancel={tableEvents.closeAudioModal}
        footer={null}
        width={'50%'}
        centered
        style={{ margin: '50px' }}>
        <audio controls style={{ width: '100%' }} autoPlay={true}>
          <source src={audioPlayState.audioUrl} />
          Your browser does not support the audio element.
        </audio>
      </Modal> : null}
      {screenPlayState.visible ? <Modal
        title='Screen Recording Player'
        visible={screenPlayState.visible}
        onCancel={tableEvents.closeScreenModal}
        width={'60%'}
        centered
        style={{ margin: '50px' }}
        footer={null}>
        <video controls style={{ width: '100%' }} autoPlay={true}>
          <source src={screenPlayState.screenUrl} />
          Your browser does not support the video element.
        </video>
      </Modal> : null}
    </>
  )

}


const mapStateToProps = ({ global }) => ({
  currentUser: global.currentUser
})
export default connect(
  mapStateToProps,
  null
)(TaskManager)

