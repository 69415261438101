import React, {useEffect, useState} from 'react'
import {Drawer, Card, Input, Select, Button, notification} from 'antd'
import {InputBox} from '../../../components/_utils/appUtils'
import {getBranchUserForAssignApplication, assignedUserForAccessmentDepartment} from '../../users/actions/user'
import {useDispatch} from 'react-redux'
import {transferApplication} from '../actions/chooseStudent'
import {RowTable} from '../../../components/_utils/RowTable'
import {departmentUserByStatus} from "../actions/chooseStudent";

const {Option} = Select
const {TextArea} = Input
const initialState = {
  description: '',
  userId: '',
  userList: []
}
const TransferAustraliaApplication = (props) => {
  let {application, visible, closeTransferDrawer, studentId, studentData, departmentWise = false} = props
  let [state, setState] = useState(initialState)
  const dispatch = useDispatch()

  const events = {
    setDescription: (e) => {
      let {value, name} = e.target
      setState({...state, [name]: value})
    },
    setUserId: (value) => {
      setState({...state, userId: value})
    },
    transferApplication: async () => {
      if (!state.userId) {
        return notification.warn({
          message: 'Please select branch user.'
        })
      }
      if (!departmentWise) {
        if (!state.description) {
          return notification.warn({
            message: 'Please enter description.'
          })
        }
      }
      let obj = {
        studentId,
        applicationId: application._id,
        description: state.description,
        userId: state.userId
      }
      let {data, type} = await dispatch(transferApplication(obj))
      if (type == 'success') {
        closeTransferDrawer()
      }
    }
  }

  useEffect(() => {
    loadUserForAssign()
  }, [])

  const loadUserForAssign = async () => {
    let {assignedUserId, universityCountry, courseUniversity, status, agentId} = application;
    let obj = {
      universityId: application.courseUniversity._id,
      countryId: application.universityCountry._id,
      status: application.status,
      agentId: studentData.agentId
    }
    let {success, data} = await dispatch(departmentUserByStatus(obj))
    if (success) {
      setState({...state, userList: data})
    }

  }

  return (
      <Drawer
          title={`Transfer student to other branch user`}
          visible={visible}
          onClose={closeTransferDrawer}
          width={'35%'}>
        <Card>
          <div className={'tableBox striped'}>
            <RowTable title={'Student Name'} value={studentData.name}/>
            <RowTable title={'Student Id'} value={studentData.studentId}/>
            {application.courseUniversity && application.courseUniversity.universityName ?
                <RowTable title={'University Name'} value={application.courseUniversity.universityName}/> : null}
            <RowTable title={'Course Name'} value={application.courseName}/>
            {application.assignedUserId && application.assignedUserId.name ?
                <RowTable title={'Current User'} value={application.assignedUserId.name}/> : null}
          </div>

          <InputBox title={'Branch User'}>
            <Select onChange={(e) => {
              events.setUserId(e)
            }}>
              {state && state.userList && state.userList.length ? state.userList.map((item, key) => {
                return (
                    <Option value={item._id} key={item._id}>{item.name}</Option>
                )
              }) : null}
            </Select>
          </InputBox>
          <InputBox title={'Description'}>
            <TextArea placeholder={'Description'} value={state.description} name={'description'} rows={10}
                      className={'form-control'}
                      onChange={events.setDescription}/>
          </InputBox>
          <InputBox title={''}>
            <Button onClick={() => events.transferApplication()}>Transfer Application</Button>
          </InputBox>
        </Card>
      </Drawer>
  )

}

export default TransferAustraliaApplication
