import React, { useState, useEffect } from 'react'
import { Card, notification, Drawer, Select, Button } from 'antd'
import { InputBox } from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import { userTypeWiseFxn } from '../../users/actions/user'
import _ from 'lodash'
import { transferToOtherAgent } from '../actions/student'
import { filterOption } from '../../../components/_utils/appUtils'

const { Option } = Select
const TransferToOtherAgent = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, studentObj, reloadTable } = props
  let [allAgent, setAllAgent] = useState([])
  let [agentId, setAgentId] = useState('')
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

  useEffect(() => {
    loadAgent()
  }, [])
  const loadAgent = async () => {
    let { agentId } = studentObj
    let data = await userTypeWiseFxn('agent')
    if (data && data.length) {
      let newData = _.reject(data, (item) => {
        return item._id.toString() == agentId
      })
      setAllAgent(newData)
    }
  }

  const handleSubmit = async () => {
    if (!agentId) {
      return notification.warn({ message: 'Please choose agent' })
    }
    let { success } = await dispatch(transferToOtherAgent({ studentId: studentObj._id, agentId: agentId }))
    if (success) {
      onClose()
      reloadTable()
    }
  }

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      width={'40%'}
      closable={true}
      maskClosable={true}
      title={'Transfer to other agent'}>
      <Card bordered={true}>
        <InputBox title={'Choose Agent'}>
          <Select value={agentId} onChange={e => setAgentId(e)}
                  showSearch={true}
                  filterOption={(input, options) => {
                    if (options.props && options.props.children && options.props.children.length) {
                      let value = options.props.children.join(' ')
                      return value.match(new RegExp(`^${input}`, 'ig'))
                    }

                  }}
                  optionFilterProp="children">
            {allAgent && allAgent.length ? allAgent.map((item, key) => {
              return (
                <Option key={key} value={item._id}>{item.companyName} ({item.name})</Option>
              )
            }) : null}
          </Select>
        </InputBox>
        <Button type="primary" onClick={handleSubmit}>
          SUBMIT
        </Button>
      </Card>
    </Drawer>
  )
}

export default TransferToOtherAgent
