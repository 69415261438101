import React, {useEffect, useState} from "react"
import {Drawer} from "antd";
import {displayDate} from "../../../../../components/_utils/appUtils";
import {getSingleSelectedCourse} from "../../../actions/chooseStudent";
import {useDispatch} from "react-redux";

const CustomRowTable = (props) => {
    let {title, value} = props
    return (
        <div className={'studentInfoTableRow'}>
            <div className={'title'}>
                {title} -
            </div>
            <div className={'details'}>
                {value}
            </div>
        </div>
    )
}

const StudentInfoDrawer = (props) => {
    let dispatch = useDispatch()
    let {visible, onClose, student} = props;
    let [studentData, setStudentData] = useState({})
    let [applications, setApplications] = useState({})
    useEffect(() => {
        loadStudentData()
    }, [student])
    let loadStudentData = async () => {
        let obj = {
            studentId: student._id,
            appId: student.applications._id,
        }
        let resp = await dispatch(getSingleSelectedCourse(obj))
        if (resp && resp._id) {
            setStudentData(resp);
            setApplications(resp.applications);
        }
    }

    return (
        <Drawer
            visible={visible}
            onClose={onClose}
            title={'Student Details'}
            width={'65%'}>
            <div className="card unizportal">
                {studentData && studentData._id ? <div className={'tableBox striped'}>
                    <CustomRowTable title={'Student ID'} value={applications.studentIdNo}/>
                    <CustomRowTable title={'Full Name'} value={studentData.name}/>
                    <CustomRowTable title={'Passport Number'} value={studentData.passportNumber}/>
                    <CustomRowTable title={'Permanent Address'} value={studentData.address}/>
                    <CustomRowTable title={'Present Address'} value={studentData.presentAddress}/>
                    <CustomRowTable title={'Email'} value={studentData.email}/>
                    <CustomRowTable title={'Land line Telephone No'} value={studentData.landlineNo}/>
                    <CustomRowTable title={'Mobile'} value={studentData.studentPhoneNumber}/>
                    <CustomRowTable title={'WhatsApp Number'} value={studentData.whatsappNumber}/>
                    <CustomRowTable title={'Skype Id'} value={studentData.skypeId}/>
                    <CustomRowTable title={'Facebook'} value={studentData.facebookId}/>
                    <CustomRowTable title={'LinkedIn'} value={studentData.linkedinId}/>
                    <CustomRowTable title={'Instagram'} value={studentData.instaId}/>
                    {applications && applications._id ?
                        <>
                            <CustomRowTable title={'Course name'} value={applications.courseName}/>
                            <CustomRowTable title={'Course start date'} value={
                                <>
                                    {applications && applications.courseDetails && applications.courseDetails.startDate ? displayDate(applications.courseDetails.startDate) : null}
                                </>
                            }/>
                            <CustomRowTable title={'Course finish date'} value={
                                <>
                                    {applications && applications.courseDetails && applications.courseDetails.endDate ? displayDate(applications.courseDetails.endDate) : null}
                                </>
                            }/>
                            <CustomRowTable title={'Course fees'} value={
                                <>
                                    {applications && applications.tuitionFee ? `£${applications.tuitionFee}` : null}
                                </>
                            }/>
                            <CustomRowTable title={'Fees paid'} value={
                                <>

                                    {applications && applications.tuitionFee ? `£${applications.tuitionFee} to Unizportal` : null}
                                </>
                            }/>
                            <CustomRowTable title={'Fees due'} value={0}/>
                        </> : null}


                    {/*     <CustomRowTable title={'Company name'} value={studentData.name}/>
                    <CustomRowTable title={'Company registration number and Address'} value={studentData.name}/>
                    <CustomRowTable title={'Email'} value={studentData.name}/>
                    <CustomRowTable title={'Land line Telephone No'} value={studentData.name}/>
                    <CustomRowTable title={'Mobile'} value={studentData.name}/>
                    <CustomRowTable title={'WhatsApp Number'} value={studentData.name}/>
                    <CustomRowTable title={'Website'} value={studentData.name}/>*/}
                </div> : null}
            </div>
        </Drawer>
    )
}
export default StudentInfoDrawer
