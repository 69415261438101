import { apiUrl } from '../../../settings'

export const addBroadcastMailUrl = () => {
  return apiUrl + '/broadcastMail'
}

export const listBroadcastMailUrl = () => {
  return apiUrl + '/broadcastMail'
}

export const singleBroadcastMailUrl = (id) => {
  return apiUrl + '/broadcastMail/' + id
}
