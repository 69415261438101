import React, {useState} from "react"
import AddFcmtNote from "./addFcmtNote";
import FcmtNoteList from "./fcmtNoteList";

const FcmtNotesComponent = (props) => {
    let {application, studentId, user, reloadTable, universityName = 'FCMT'} = props;
    let [visibleAddNote, setVisibleAddNote] = useState(false);
    let [visibleNoteList, setVisibleNoteList] = useState(false);
    let events = {
        showAddNote: () => {
            setVisibleAddNote(true)
        },
        hideAddNote: () => {
            setVisibleAddNote(false)
        },
        showNoteList: () => {
            setVisibleNoteList(true)
        },
        hideNoteList: () => {
            setVisibleNoteList(false)
        }
    }
    return (
        <>
            {application && application.sendToNextUser ? <div>
                <a className={'btn btn-success btn-xs'} onClick={events.showAddNote}>Add Note</a> {' '}
                <a className={'btn btn-info btn-xs'} onClick={events.showNoteList}>Note List</a>
            </div> : ""}
            {visibleAddNote ?
                <AddFcmtNote
                    universityName={universityName}
                    studentId={studentId} applicationId={application._id} visible={visibleAddNote}
                    reloadTable={reloadTable}
                    onClose={events.hideAddNote}/> : ""}
            <div className={'noteMain'}>
                {visibleNoteList ?
                    <FcmtNoteList studentId={studentId}
                                  universityName={universityName}
                                  applicationId={application._id} visible={visibleNoteList}
                                  user={user}
                                  onClose={events.hideNoteList}/> : ""}
            </div>
        </>
    )
}

const ShowFcmtNote = (email) => {
    let emailsArr = ['marketing@fcmtcollege.com', 'sandeep@unizportal.com'];
    return emailsArr.includes(email) ? false : true
}
const ShowCambieNote = (email) => {
    let emailsArr = ['cambieoffer@unizportal.com', 'cambieloa@unizportal.com'];
    return emailsArr.includes(email) ? false : true
}

const ShowRoyalArtsNote = (email) => {
    let emailsArr = ['royalartsoffer@unizportal.com', 'royalartsloa@unizportal.com'];
    return emailsArr.includes(email) ? false : true
}

export {FcmtNotesComponent, ShowFcmtNote, ShowCambieNote, ShowRoyalArtsNote};
