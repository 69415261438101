import { Button, Drawer, InputNumber, Select, Form, Row, Col, notification, DatePicker } from 'antd'
import style from '../views/styles.less'
import React, { useEffect, useState } from 'react'
import moment from 'moment'

const Option = Select
let initaleState = {
  studyPeriod: '',
  dueDate: {},
  applicationFee: '',
  materialFee: '',
  tuitionFee: ''
}
const InstallmentSchedule2 = (props) => {
  let { onClose, visible, addInstallment2Data, data } = props
  let [state, setState] = useState(initaleState)

  let setLocalState = (data) => {
    setState({ ...state, ...data })
  }
  useEffect(() => {
    setDefaultExamData()
  }, [])

  const setDefaultExamData = () => {
    setState({ ...data })
  }

  let addFee = () => {
    addInstallment2Data(state)
  }

  return (
    <Drawer width={800}
            title={'Installments Schedule for first Course'} placement='right'
            visible={visible}
            onClose={onClose}>

      <div className='row'>
        <div className='col-lg-12'>
          <Form noValidate>
            <div className='form-box mt-4'>

              <div className='card unizportal'>
                <Row gutter={16} className={'rowWrap'}>
                  <Col span={12}>
                    <Form.Item label={'Study Period'}>
                      <InputNumber
                        placeholder={'Study Period'}
                        type={'number'}
                        value={state.studyPeriod}
                        onChange={ei => {
                          setLocalState({ studyPeriod: ei })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={'Due Date'}>
                      <DatePicker
                        placeholder={'Due Date'}
                        value={state.dueDate ? moment(state.dueDate._d) : undefined}
                        onChange={e => {
                          setLocalState({ dueDate: e })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={'Application Fee'}>
                      <InputNumber
                        placeholder={'Application Fee'}
                        type={'number'}
                        value={state.applicationFee}
                        onChange={ei => {
                          setLocalState({ applicationFee: ei })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={'Material Fee'}>
                      <InputNumber
                        placeholder={'Material Fee'}
                        type={'number'}
                        value={state.materialFee}
                        onChange={ei => {
                          setLocalState({ materialFee: ei })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={'Tuition Fee'}>
                      <InputNumber
                        placeholder={'Tuition Fee'}
                        type={'number'}
                        value={state.tuitionFee}
                        onChange={ei => {
                          setLocalState({ tuitionFee: ei })
                        }}
                      />
                    </Form.Item>
                  </Col>

                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        type='primary' htmlType='submit' className='btn'
                        onClick={() => addFee()}>
                        Add
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      </div>

    </Drawer>
  )
}

export default InstallmentSchedule2
