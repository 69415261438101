import { apiUrl } from '../../../settings'

export const addStudentInsuranceUrl = () => {
  return apiUrl + '/studentInsurance'
}
export const getStudentInsuranceUrl = () => {
  return apiUrl + '/getStudentInsurance'
}
export const updateInsuranceStatusUrl = () => {
  return apiUrl + '/updateInsuranceStatus'
}
export const uploadInsuranceUrl = () => {
  return apiUrl + '/uploadInsurance'
}
