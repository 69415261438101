import React, { useEffect, useState } from 'react'
import {
  Form,
  Steps,
  Row,
  Col,
  Button,
  notification
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { listAllCountries } from '../../countries/actions/countries'
import { addCompany } from '../actions/company'
import _ from 'lodash'
import moment from 'moment'
import { LoadAllCountry, LoadState, LoadCity } from '../../../components/_utils/countryUtil'

const { Step } = Steps

let initialState = {
  currentStep: 0,
  fileList: [],
  uploadKey: moment(),
  allCountries: LoadAllCountry(),
  allStates: [],
  allCities: [],
  countryName: '',
  stateName: '',
  cityName: ''
}

const AddCompany = (props) => {
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } } = props
  const dispatch = useDispatch()
  const [state, setState] = useState(initialState)

  const { allCountries, allStates, allCities } = state

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  let formFields = {
    generateInputs: [
      { key: 'name', label: 'Company Name', required: true, placeholder: 'Enter Company Name' },
      { key: 'contactPerson', label: 'Contact Person Name', required: true, placeholder: 'Enter Contact Person Name' },
      { key: 'email', label: 'Email', required: true, type: 'email', placeholder: 'Enter Email' },
      { key: 'mobile', label: 'Phone', required: true, type: 'number', placeholder: 'Enter Phone No' },
      {
        key: 'countryName',
        label: 'Country',
        required: true,
        type: 'select',
        showSearch: true,
        options: allCountries,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            countryName: x
          })
          events.chooseCountry(x)
        }
      },
      {
        key: 'stateName',
        label: 'Province/State',
        required: true,
        type: 'select',
        showSearch: true,
        options: allStates,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            stateName: x
          })
          events.chooseState(x)
        }
      },
      {
        key: 'cityName',
        label: 'City/Town',
        required: true,
        type: 'select',
        showSearch: true,
        options: allCities,
        hidden: getFieldValue('countryName') !== 'India',

        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            cityName: x
          })
          events.chooseCity(x)
        }
      },

      {
        key: 'cityName',
        label: 'City/Town',
        hidden: getFieldValue('countryName') == 'India',
        onChange: (e) => {
          let {value} = e.target
          this.props.form.setFieldsValue({
            cityName: value
          })
          events.chooseCity(value)
        }
      },

      {key: 'postalCode', label: 'Postal/Zip Code', type: 'number', placeholder: 'Postal/Zip Code', required: true},

      {key: 'address', label: 'Address', required: true, span: 16, placeholder: 'Enter Address'}
    ]
  }

  useEffect(() => {
    dispatch(listAllCountries({ results: 1000000 }))
  }, [])

  const events = {
    handleSubmit: (e) => {
      const { form } = props
      e.preventDefault()
      form.validateFieldsAndScroll(async (err, valData) => {
        if (!err) {
          let { countryName, stateName, cityName } = state
          valData = { ...valData, countryName, stateName, cityName }
          let fd = new FormData()
          fd.append('obj', JSON.stringify(valData))
          let data = await dispatch(addCompany({ obj: valData }))
          if (data && !data.error) {
            form.resetFields()
            setState({
              ...initialState,
              uploadKey: moment()
            })
          }
        } else {
          notification.warning({
            message: 'Fill All Required Fields'
          })
        }
      })
    },
    chooseCountry: (name) => {
      let countryName = _.find(allCountries, (item) => {
        return item.name == name
      })
      if (countryName) {
        setState({
          ...state,
          countryName: countryName.name ? countryName.name : '',
          allStates: countryName.id ? LoadState(countryName.id) : []
        })
      }
    },
    chooseState: (name) => {
      let stateName = _.find(allStates, (item) => {
        return item.name == name
      })
      if (stateName) {
        setState({
          ...state,
          stateName: stateName.name ? stateName.name : '',
          allCities: stateName.id ? LoadCity(stateName.id) : []
        })
      }
    },
    chooseCity: (name) => {
      setState({
        ...state,
        cityName: name ? name : ''
      })
    }
  }


  return (
    <div className="row">
      <div className="col-lg-12">
        <Form>
          <div className="form-box mt-4">

            <div className="d-flex align-items-center mb-3 heading-form">
              <h5>Add Company</h5>
            </div>

            <div className="card unizportal">
              <Row gutter={16}>
                {formFields.generateInputs.map((item, key) => {
                  return (
                    <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8} sm={12} xs={24} key={key}>
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout}/>
                    </Col>
                  )
                })}

                <Col span={24}>
                  <Form.Item className={'mt20'}>
                    <Button type="primary" onClick={events.handleSubmit} className="btn">
                      SUBMIT
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

            </div>
          </div>

        </Form>
      </div>
    </div>
  )
}


const WrappedAddCompany = Form.create()(AddCompany)
export default WrappedAddCompany


