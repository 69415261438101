import React, { useEffect, useState } from 'react'
import { Button, Drawer, Input, notification } from 'antd'
import renderHTML from 'react-render-html'
import { InputBox } from '../../../components/_utils/appUtils'
import { updateFutureTask } from '../actions/taskManager'
import { useDispatch } from 'react-redux'

let { TextArea } = Input
const EditTaskDrawer = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, onSubmit, data } = props
  let [task, setTask] = useState('')
  let [queryBy, setQueryBy] = useState('')
  useEffect(() => {
    if (data.task) {
      setTask(data.task)
    }
    if (data.queryBy) {
      setQueryBy(data.queryBy)
    }
  }, [data])

  const handleUpdateTask = async () => {
    if (!task) {
      notification.warning({ message: 'Enter task' })
      return
    }
    const result = await dispatch(updateFutureTask({ taskId: data._id, task: task, queryBy: queryBy }))
    if (!result.error) {
      onSubmit()
    }
  }


  return (
    <>
      <Drawer visible={visible} onClose={onClose} title={'Edit Task'} width={'45%'}>
        <div className='form-box'>
          <div className='card unizportal'>
            <InputBox title={'Task'}>
              <TextArea
                value={task}
                style={{ 'minHeight': 200 }}
                onChange={({ target }) => {
                  setTask(target.value)
                }}>
              </TextArea>
            </InputBox>
            <InputBox title={'Query By'}>
              <Input
                value={queryBy}
                onChange={({ target }) => {
                  setQueryBy(target.value)
                }}>
              </Input>
            </InputBox>
            <Button className='btn btn-primary' onClick={() => {
              handleUpdateTask()
            }}>
              Update Task
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  )
}
const RenderFutureTask = props => {
  let { text, data, refreshTable } = props
  let [hideV, setHideV] = useState(true)
  let [visible, setVisible] = useState(false)
  let events = {
    showDrawer: () => {
      setVisible(true)
    },
    hideDrawer: () => {
      setVisible(false)
    }
  }
  return (
    <div>
      {text && (
        <div className={'display-linebreak'}>
          <div className={`pCursor ${hideV ? 'appTextReplace' : ''}`} onDoubleClick={events.showDrawer}>
            {renderHTML(text)}
          </div>
          {text && text.length && text.length > 150 ? (
            <div className={'alignRight colorPrimary'}>
              {hideV ? (
                <a
                  onClick={() => {
                    setHideV(false)
                  }}>
                  Show More
                </a>
              ) : (
                <a
                  onClick={() => {
                    setHideV(true)
                  }}>
                  Show Less
                </a>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      )}
      {visible ?
        <EditTaskDrawer
          visible={visible}
          data={data}
          onClose={events.hideDrawer}
          onSubmit={() => {
            events.hideDrawer()
            refreshTable()
          }} /> : null}
    </div>
  )
}
export default RenderFutureTask
