import { chatRoomsUrl, previousChatUrl } from '../apis'
import { getToken, customAxios as axios } from '../../../request'

export const getChatRoomList = (valData) => async (dispatch) => {
  let { data } = await axios.post(chatRoomsUrl(), valData, getToken())
  return data
}

export const getPreviousChat = (filters) => async (dispatch) => {
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(previousChatUrl(), config)
  return data
}
