import React, { useEffect, useState } from 'react'
import styles from './styles.less'
import WhatsNew from '../../containers/WhatsNew/view/whatsNewDrawer'
import { resetWhatsNewCountFxn, getWhatsNewCountFxn } from '../../containers/WhatsNew/actions/whatsNew'
import { Badge } from 'antd'
import { resetLatestUpdateCountFxn } from '../../containers/latestUpdate/actions'
import { push } from 'connected-react-router'

const LatestUpdateIcon = (props) => {
  let { unreadLatestUpdate, dispatch } = props
  let events = {
    showWhatsNew: () => {
      resetWhatsCount()
    }
  }

  const resetWhatsCount = async () => {
    resetLatestUpdateCountFxn().then((resp) => {
      if (resp && resp.success) {
        dispatch({ type: 'UNREAD_LATEST_UPDATE', unreadUpdates: resp.unreadUpdates || 0 })
      }
    })
    dispatch(push('/latest-updates'))
  }


  return (
    <>
           <span
             onClick={() => {
               events.showWhatsNew()
             }}>
             <span
               className={'antd-pro-components-global-header-index-action'}>
                 <Badge count={unreadLatestUpdate} style={{ boxShadow: 'none' }} className={styles.badge}>
                  <img src={'../dist/icons/latestUpdates.png'}
                       className={styles.announcementIcon} />
                </Badge>
               </span>
           </span>
    </>
  )
}
export default LatestUpdateIcon
