import React, {useState, useEffect} from 'react'
import {Button, Card, Drawer, Form, Input, notification} from 'antd'
import {InputBox} from '../../../components/_utils/appUtils'
import {useDispatch} from 'react-redux'
import {addFcmtNote} from "../actions/fcmtAction";

const {TextArea} = Input
const AddFcmtNote = (props) => {
    let {studentId, applicationId, visible, onClose, reloadTable, universityName} = props
    let dispatch = useDispatch()
    let [note, setNote] = useState("")
    const events = {
        setLocalState: (e) => {
            let {name, value} = e.target
            setNote(value)
        },
        handleSubmit: async () => {
            if (!note) {
                notification.warning({message: "Please enter note."})
                return
            }
            let obj = {
                studentId: studentId,
                note: note,
                applicationId: applicationId,
            }

            let data = await dispatch(addFcmtNote(obj))
            if (data && !data.error) {
                onClose()
                reloadTable()
            }
        }
    }

    return (
        <Drawer width={700}
                title={`Add ${universityName} Note`}
                placement="right"
                visible={visible}
                onClose={() => {
                    onClose()
                }}>
            <Card bordered={false}>
                <Form>
                    <InputBox title={'Note'} className={'textAreaCls'}>
                        <TextArea rows={7} onChange={events.setLocalState} name={'note'}
                                  className={'form-control'}
                                  placeholder={'Note'} value={note}/>
                    </InputBox>

                    <Form.Item className={'mt20 alignRight'}>
                        <Button type='primary' onClick={events.handleSubmit}>
                            SUBMIT
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Drawer>
    )
}
export default AddFcmtNote
