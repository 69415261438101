import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {
    createTransactionFxn,
    getFinalChangesInInrFxn,
    reeudoRatesFxn,
    singleAgentMarginFxn,
    singleQuotationFxn,
    updateTransactionFxn,
    getTxnCounsellorFxn,
    updateQuotationFxn
} from "../actions";
import {Button, Checkbox, Col, Form, Icon, Input, Modal, notification, Row, AutoComplete} from "antd";
import UnizLogo from "../../../assets/Unizportal-Logo.png";
import {displayDate, InputBox} from "../../../components/_utils/appUtils";
import _ from "lodash"
import {stringToFixed} from "../reeudoUtils";

const UpdatedQuotationModal = (props) => {
    const {
        onClose,
        visible,
        onSkip,
        form: {getFieldDecorator, setFieldsValue},
        isForexOff,
        quotation,
        exchangeMargin,
        agentMargin
    } = props;
    let [updatedAmount, setUpdatedAmount] = useState(0)
    let [quotationData, setQuotationData] = useState(null);
    let [isCounselorBonusChecked, setIsCounselorBonusChecked] = useState(false);
    let [counselorName, setCounselorName] = useState('');
    let [counselorDesignation, setCounselorDesignation] = useState('');
    const [counselorOptions, setCounselorOptions] = useState([]);
    const [rateDirection, setRateDirection] = useState(null);
    // const [agentMargin, setAgentMargin] = useState(0.00);
    // const [agentId, setAgentId] = useState(null);
    let [changeValue, setChangeValue] = useState(0)
    let [customerRate, setCustomerRate] = useState(0)
    let [finalChangesState, setFinalChangesState] = useState({})
    let dispatch = useDispatch();

    const events = {
        loadRates: async () => {
            let {data, success} = await dispatch(reeudoRatesFxn());
            if (success) {
                let {currencyData} = data;
                let findCurr = _.find(currencyData, (item) => {
                    return item.currencyCode === quotation.fromCurrency;
                });
                if (findCurr) {
                    let uAmt = (quotation.yourRate + quotation.exchangeMargin) - (findCurr.yourRate + quotation.exchangeMargin);
                    if (uAmt) {
                        uAmt = parseFloat(uAmt.toFixed(2));
                    }
                    setUpdatedAmount(uAmt);

                    /*if (uAmt < 0) {
                        setCustomerRate(quotation.fxRate - uAmt);
                    } else {
                        setCustomerRate(quotation.fxRate);
                    }*/
                    if (uAmt < 0) {
                        setCustomerRate(quotation.fxRate - uAmt);
                    } else {
                        setCustomerRate(quotation.fxRate);
                    }
                }
            }
        },
        getFinalChangesFxn: async () => {
            let obj = {
                currency: quotation.fromCurrency,
                amount: quotation.amount,
                customerRate: parseFloat(customerRate.toFixed(2)),
                sourceId: quotation.sourceId,
            };
            if (obj.currency && obj.amount && obj.customerRate && obj.sourceId) {
                let {data, success} = await dispatch(getFinalChangesInInrFxn(obj));
                if (success && data && data.charges) {
                    console.log(data.charges, "data chargess")
                    setFinalChangesState(data.charges);
                }
            }
        },
        getCounselors: async () => {
            if (quotation.agentId && quotation.agentId._id) {
                const resp = await dispatch(getTxnCounsellorFxn({agentId: quotation.agentId._id}));
                if (resp) {
                    setCounselorOptions(
                        resp.map((c) => ({
                            value: c.counselorName,
                            label: c.counselorName,
                            designation: c.counselorDesignation
                        }))
                    );
                }
            }
        },
        updateQuotation: async () => {
            let finalChanges = finalChangesState || {};
            let customerRate = customerRate
            let obj = {
                amount: parseFloat(finalChanges.fromCurrencyAmount || quotation.amount).toFixed(2),
                customerRate: customerRate || quotation.customerRate,
                bankFees: parseFloat(finalChanges.bank || 0).toFixed(2),
                yourRate: parseFloat(finalChanges.yourRate || quotation.yourRate).toFixed(2),
                ibrRate: parseFloat(finalChanges.ibrRate || quotation.ibrRate).toFixed(2),
                currentRate: customerRate || quotation.fxRate,
                fxRate: customerRate || quotation.fxRate,
                grossAmount: parseFloat(finalChanges.base || 0).toFixed(2),
                totalAmount: parseFloat(finalChanges.total || 0).toFixed(2),
            };

            try {
                let resp = await dispatch(updateQuotationFxn(quotation.quotationId, obj));
                if (resp.success) {
                    notification.success({ message: "Quotation updated successfully" });
                    onClose(resp.data);
                } else {
                    notification.error({ message: resp.message || "Failed to update quotation" });
                }
            } catch (error) {
                console.error("Error updating quotation:", error);
                notification.error({ message: "An error occurred while updating the quotation" });
            }
        },

    };
    const handleCounselorChange = (field, value) => {
        if (field === "counselorName") {
            const selectedCounselor = counselorOptions.find((c) => c.value === value);
            setCounselorName(value);
            setCounselorDesignation(selectedCounselor ? selectedCounselor.designation : "");
            setFieldsValue({
                counselorName: value,
                counselorDesignation: selectedCounselor ? selectedCounselor.designation : ""
            });
        } else if (field === "counselorDesignation") {
            setCounselorDesignation(value);
            setFieldsValue({counselorDesignation: value});
        }
    };
    useEffect(() => {
        if (customerRate) {
            events.getFinalChangesFxn()
        }
    }, [customerRate])


    useEffect(() => {
        events.loadRates()
        events.getCounselors()
    }, [])


    const forexOffModal = () => {
        Modal.warning({
            title: 'Forex is closed',
            content: (
                <div>
                    <p>Forex is Closed, Please try again between 8 a.m. and 4 p.m.</p>
                </div>
            ),
            onOk() {
            },
        });
    }
    const handleSubmit = async e => {
        e.preventDefault();
        if (isForexOff) {
            forexOffModal();
        } else {
            const {form} = props;
            form.validateFields(async (err, valData) => {
                let defaultAgentMargin = agentMargin || 0;
                const newAgentMargin = updatedAmount > 0 ? defaultAgentMargin + updatedAmount : defaultAgentMargin;

                if (!err) {
                    const transactionData = {
                        ...valData,
                        name: quotation.name,
                        emailAddress: quotation.emailId,
                        phoneNumber: quotation.phoneNo,
                        fxRate: finalChangesState.fxRate,
                        ibrRate: finalChangesState.ibrRate,
                        yourRate: finalChangesState.yourRate,
                        amount: quotation.amount,
                        currency: quotation.fromCurrency,
                        sourceId: quotation.sourceId,
                        agentId: quotation.agentId,
                        counselorBonusApplied: isCounselorBonusChecked,
                        exchangeMargin: quotation.exchangeMargin || 0.00,
                        agentMargin: stringToFixed(newAgentMargin),
                        quotationId: quotation.quotationId
                    };


                    if (isCounselorBonusChecked) {
                        transactionData.counselorName = counselorName;
                        transactionData.counselorDesignation = counselorDesignation;
                    }

                    let resp = await dispatch(updateTransactionFxn(transactionData));
                    if (resp.success && resp.data) {
                        notification.success({
                            message: resp.message
                        });
                        form.resetFields();
                        onClose(resp.data);
                    } else {
                        notification.error({
                            message: resp.message
                        });
                    }
                } else {
                    notification.warning({
                        message: 'Fill All Required Fields'
                    });
                }
            });
        }
    };

    const handleCheckboxChange = (e) => {
        setIsCounselorBonusChecked(e.target.checked);
    };

    return (
        <Modal
            visible={visible}
            title={"Book Transaction Details"}
            onCancel={onSkip}
            width={"45%"}
            footer={null}
        >

            {quotation && quotation._id && (
                <>
                    <div className="unizportal">
                        <div className={'jcsb'}>
                            <div>
                                <img src={UnizLogo} alt={'logo'} style={{height: 50}}/>
                            </div>
                            <div>
                                <div>Date : <b>{displayDate(quotation.createdAt)}</b></div>
                                <div>Quotation Id : <b>#{quotation.quotationId}</b></div>
                            </div>
                        </div>
                        <div className="rate-change-display mt10">
                            <div className={`rate-change ${rateDirection}`}>
                                {updatedAmount !== 0 && (
                                    <div className={`rate-change ${rateDirection}`}>
                                        <label
                                            className={`rate-change-text ${updatedAmount < 0 ? 'label label-success-green' : 'label label-danger'}`}>
                                            <Icon type={updatedAmount < 0 ? 'arrow-up' : 'arrow-down'}/>&nbsp;
                                            {updatedAmount < 0 ? 'Rate Increased' : 'Rate Decreased'} by {-updatedAmount} INR
                                        </label> <br/>
                                        {updatedAmount > 0 && (
                                            <div className="additional-text mt5 align-center">
                                                This extra margin will be added in agent margin
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={'description-section mt10'}>

                            <table className="table table-bordered">
                                <tr>
                                    <th colSpan={2}>Description</th>
                                </tr>
                                <tbody>
                                <tr>
                                    <td>Currency Type</td>
                                    <td>{quotation.fromCurrency}</td>
                                </tr>
                                <tr>
                                    <td>Foreign Currency Amount</td>
                                    <td>{quotation.amount}</td>
                                </tr>
                                <tr>
                                    <td>Booking Rate</td>
                                    <td>{finalChangesState.fxRate} INR</td>
                                </tr>
                                <tr>
                                    <td>Your Rate</td>
                                    <td>{stringToFixed(finalChangesState.yourRate + quotation.exchangeMargin)} INR</td>
                                </tr>
                                <tr>
                                    <td>Gross Amount</td>
                                    <td>{stringToFixed(finalChangesState.base)} INR</td>
                                </tr>
                                <tr>
                                    <td>GST (Foreign Conversion)</td>
                                    <td>{stringToFixed(finalChangesState.gst)} INR</td>
                                </tr>
                                <tr>
                                    <td>Bank Processing Fee</td>
                                    <td>{stringToFixed(finalChangesState.bank)} INR</td>
                                </tr>
                                <tr>
                                    <td>Nostro Charges</td>
                                    <td>{stringToFixed(finalChangesState.nostro)} INR</td>
                                </tr>
                                <tr>
                                    <td>TCS Charges</td>
                                    <td>{stringToFixed(finalChangesState.tcs)} INR</td>
                                </tr>
                                <tr>
                                    <td>Total Amount</td>
                                    <td className="total">{stringToFixed(finalChangesState.total)} INR</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/*<div className={'row'}>*/}
                    {/*    <a className={'btn roundNew xs btn-primary mt5 ml10'}*/}
                    {/*       onClick={() => events.updateQuotation()}*/}
                    {/*    >*/}
                    {/*        Update Quotation*/}
                    {/*    </a>*/}
                    {/*</div>*/}

                    <hr className={'hr1'}/>
                    <div className=" row">
                        <div className={'col-md-6'}>
                            <div className={'font14'}><b>Contact Details</b></div>
                            <div>{quotation.agentId.name}</div>
                            <div className={'aic'}>
                                <div className={'aic'}><Icon type={'mobile'}/> &nbsp; {quotation.agentId.mobile}
                                </div>
                                {quotation.agentId.mobile && quotation.agentId.email ?
                                    <div>&nbsp; | &nbsp;</div> : null}
                                <div className={'aic'}><Icon type={'mail'}/> &nbsp;{quotation.agentId.email}
                                </div>
                            </div>
                        </div>
                        <div className={'col-md-6'}>
                            <div className={'font14'}><b>Student Details</b></div>
                            <div>{quotation.name}</div>
                            <div className={'aic'}>
                                <div className={'aic'}><Icon type={'mobile'}/> &nbsp; {quotation.phoneNo} </div>
                                &nbsp; | &nbsp;
                                <div className={'aic'}><Icon type={'mail'}/> &nbsp;{quotation.emailId}</div>
                            </div>
                        </div>

                    </div>
                    <hr className={'hr1'}/>

                    <Checkbox onChange={handleCheckboxChange}>
                        Do you want to add counsellor bonus?
                    </Checkbox>
                    {isCounselorBonusChecked && (
                        <Form layout="vertical" className="mt20">
                            <Row gutter={16}>
                                <Col span={12}>
                                    <InputBox title="Counselor Name">
                                        {getFieldDecorator("counselorName", {
                                            rules: [
                                                {
                                                    required: isCounselorBonusChecked,
                                                    message: "Please enter counselor name",
                                                },
                                            ],
                                            initialValue: counselorName,
                                        })(
                                            <AutoComplete
                                                placeholder="Select or type counselor name"
                                                className={'autoCompleted'}

                                                filterOption={(inputValue, option) =>
                                                    option.props.children
                                                        .toUpperCase()
                                                        .indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onSelect={(value) => handleCounselorChange("counselorName", value)}
                                                onChange={(value) => handleCounselorChange("counselorName", value)}
                                                allowClear
                                            >
                                                {counselorOptions.map((counselor) => (
                                                    <AutoComplete.Option key={counselor.value} value={counselor.value}>
                                                        {counselor.label}
                                                    </AutoComplete.Option>
                                                ))}
                                            </AutoComplete>
                                        )}
                                    </InputBox>
                                </Col>
                                <Col span={12}>
                                    <InputBox title="Counselor Designation">
                                        {getFieldDecorator("counselorDesignation", {
                                            rules: [
                                                {
                                                    required: isCounselorBonusChecked,
                                                    message: "Please enter counselor designation",
                                                },
                                            ],
                                            initialValue: counselorDesignation,
                                        })(
                                            <Input
                                                className="form-control"
                                                placeholder="Designation"
                                                onChange={(e) => handleCounselorChange("counselorDesignation", e.target.value)}
                                                value={counselorDesignation}
                                            />
                                        )}
                                    </InputBox>
                                </Col>
                            </Row>
                        </Form>
                    )}
                    <div>
                        <Button htmlType="submit" className={"roundBtn"} onClick={handleSubmit}>
                            Confirm & Book Transaction
                        </Button> &nbsp;
                        <Button className={"roundBtn"} onClick={onSkip}>
                            Skip
                        </Button>
                    </div>
                </>
            )}

        </Modal>
    );
}
export default Form.create()(UpdatedQuotationModal)

