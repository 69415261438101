import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Button,
  Card,
  Col,
  Drawer,
  Form, Icon,
  Input,
  Modal,
  notification,
  Popconfirm,
  Row,
  Tabs,
  Typography
} from 'antd'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import { displayDate, displayTime, nameAvatar } from '../../../components/_utils/appUtils'
import {
  activityListFxn,
  addInstituteCommentFxn,
  instituteCommentsFxn, instituteEventListFxn,
  singleInstituteEventFxn,
  slotListFxn,
  updateInstituteEventDateFxn,
  updateInstituteEventStatusFxn
} from '../action'
import '../style.css'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import styles from '../../IcefEvent/styles.less'
import '../../../../src/common.less'
import renderHTML from 'react-render-html'

const { Text } = Typography
const { TabPane } = Tabs

const SingleEventComment = (props) => {
  let { comment } = props

  return (
    <>
      <div className='comment' key={comment._id}>
        <Avatar className={'textUpp avatar_text1'} size='large'>
          {nameAvatar(comment.createdByUser.name)}
        </Avatar>

        <div className='comment-text'>
          <p className='comment-author'>
            <span className={'name'}>{comment.createdByUser.name}</span>
            <span className={'date'}>{displayDate(comment.date)}</span>
          </p>
          <div className={'mb5'}>{comment.comment}</div>
          <div className={'time-span'}>{displayTime(comment.createdByUser.time)}</div>
        </div>
        <br />
      </div>
    </>

  )
}


const EventListDrawer = (props) => {
  let { data } = props
  return (
    <>
      <div id={'dd'} ref={props.ref}>
        {data && data.length ? data.map((comment, index) => {
          return (
            <div key={comment._id} className='comment-container'>
              <SingleEventComment comment={comment} />
            </div>
          )
        }) : null}
      </div>
    </>
  )
}

const ChangeDateDrawer = (props) => {
  const { onClose, visible, eventId, getFieldDecorator, setFieldsValue, onSubmit, form, disabledDate, approvedEvents, slotList } = props
  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { date, reason, time } = values
        if (eventId && date && reason) {
          let resp = dispatch(updateInstituteEventDateFxn(eventId, date, reason, time))
          form.resetFields()
          onSubmit()
          onClose()
        } else {
          console.error('Please fill in all fields')
        }
      }
    })
  }
  const updatedDisabledDate = (current) => {
    const currentDate = moment().endOf('day');
    const format = 'YYYY-MM-DD';
  
    const isDisabledInSlotList = slotList && slotList.some(item => moment(item).format(format) === moment(current).format(format));
  
    const isDisabledInApprovedEvents = approvedEvents &&  approvedEvents.some(event => moment(event.date).format(format) === moment(current).format(format));
    if (isDisabledInSlotList || isDisabledInApprovedEvents || moment(current).isBefore(currentDate, 'day')) {
      return true;
    } else {
      return false;
    }
  };
  
  

  const formFields = [
    {
      key: 'date',
      label: 'Date',
      type: 'date',
      required: true,
      disabledDate: updatedDisabledDate
    },
    {
      key: 'time',
      label: 'Time',
      type: 'time',
      required: true,
      format: 'HH:mm',
      showSeconds: false
  },
    {
      key: 'reason',
      label: 'Reason',
      type: 'text',
      required: true
    }
  ]

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  return (
    <Drawer
      visible={visible}
      title='Change Date'
      width={600}
      closable={true}
      maskClosable={true}
      onClose={onClose}
    >
      <Card>
        <Form onSubmit={handleSubmit} className='vertical-form'>
          <Row gutter={24}>
            {formFields.map((field) => (
              <Col span={field.span ? field.span : 24} key={field.key} className={'mb10'}>
                <GetEachFormFields
                  item={field}
                  getFieldDecorator={getFieldDecorator}
                  formItemLayout={formItemLayout}
                />
              </Col>
            ))}
          </Row>
          <Row gutter={24}>
            <Col span={18}>
              <Form.Item>
                <Button type='primary' htmlType='submit' className='btn'>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Drawer>
  )
}

const InstituteEventDrawer = (props) => {
  let { onClose, visible, currentUser, eventId, onSubmit, approvedEvents } = props
  const form = props.form
  const [comments, setComments] = useState('')
  const [slotList, setSlotList] = useState([])
  const [slotData, setSlotData] = useState([])
  const [cancelModalVisible, setCancelModalVisible] = useState(false)
  const [cancelReason, setCancelReason] = useState('')
  const [rejectModalVisible, setRejectModalVisible] = useState(false)
  const [rejectReason, setRejectReason] = useState('')
  const [dateDrawerVisible, setDateDrawerVisible] = useState(false)
  let [commentState, setCommentState] = useState({
    data: [],
    total: 0,
    page: 1,
    count: 50
  })
  let [eventData, setEventData] = useState({})
  let [activity, setActivity] = useState([])
  let [eventDates, setEventDates] = useState([])
  let [instituteEvents, setInstituteEvents] = useState([])
  let dispatch = useDispatch()

  const loadSlotList = async () => {
    const params = {
      results: 100,
      count: 100

    }
    let resp = await dispatch(slotListFxn(params))

    setSlotData(resp.data)
    const dates = resp.data.map(item => moment(item.date).format('YYYY-MM-DD'))
    setSlotList(dates)
  }
  const loadInstituteEvent = async () => {


    const params = {
      results: 100,
      count: 100,
      status: 'Approved',
      // date: { $gte: startDate, $lte: endDate }
    };

    let { data } = await dispatch(instituteEventListFxn(params));

    setInstituteEvents(data);
  };

  const disabledDate = (current) => {
    let currentDate = moment().endOf('day');
    let format = 'DD-MM-YYYY';
    let findInSlotList = _.find(slotList, (item) => {
        return moment(item.date).format(format) === moment(current).format(format);
    });
    let findInEventDates = _.find(eventDates, (date) => {
        return moment(date).format(format) === moment(current).format(format);
    });
    if (findInSlotList || findInEventDates || moment(current).isBefore(currentDate)) {
        return true;
    } else {
        return false;
    }
};

  const handleSubmitComment = async (eventId, comments) => {
    if (!comments) {
      console.log('Comment cannot be empty.')
      return
    }

    await dispatch(addInstituteCommentFxn(eventId, comments))
    await loadComments()
    fetchActivityList()
    setComments('')
  }
  const fetchInstituteEventData = async () => {
    try {
      const { data } = await dispatch(singleInstituteEventFxn({ eventId }))
      
      setEventData(data)

      const dates = data.map(item => moment(item.date).format('YYYY-MM-DD'));
      setEventDates(dates);
    } catch (error) {
      console.error('Error fetching institute event data:', error)
    }
  }
  const loadComments = async () => {
    let params = {}
    params.results = commentState.count
    params.count = commentState.count
    params.page = commentState.page
    params.instituteEventId = eventId
    const resp = await dispatch(instituteCommentsFxn(params))
    setCommentState((prevData) => {
      return {
        ...prevData,
        data: resp.data,
        total: resp.total
      }
    })
  }

  useEffect(() => {
    fetchInstituteEventData()
    loadComments()
    loadSlotList()
    fetchActivityList()
    loadInstituteEvent()
  }, [])

  const handleOpenDateDrawer = () => {
    const currentTime = new Date()
    const targetDate = new Date(eventData.date)
    const timeDifference = targetDate.getTime() - currentTime.getTime()

    const hoursDifference = timeDifference / (1000 * 60 * 60)

    if (hoursDifference > 48) {
      setDateDrawerVisible(true)
    } else {
      Modal.error({
        title: 'Date Change Not Allowed',
        content: 'Date can only be changed 48 hours before the scheduled date.'
      })
    }
  }

  const fetchActivityList = async () => {
    let params = {}
    params.eventId = eventId
    const { data } = await dispatch(activityListFxn(params))
    setActivity(data)

  }


  const handleCloseDateDrawer = () => {

    fetchInstituteEventData()
    setDateDrawerVisible(false)

  }

  const handleSubmitDate = async (newDate) => {

    fetchInstituteEventData()
    handleCloseDateDrawer()
    onSubmit()
    onClose()
    const currentTime = new Date()
    const createdAtTime = new Date(eventData.createdAt)
    const timeDifference = Math.abs(currentTime - createdAtTime)
    const hoursDifference = timeDifference / (1000 * 60 * 60)


    /*if (hoursDifference <= 48) {
      await fetchInstituteEventData()
    } else {
      Modal.error({
        title: 'Date Change Not Allowed',
        content: 'Date cannot be changed after 48 hours of creation.'
      })
    }*/
  }

  const handleCancel = () => {
    const currentTime = new Date()
    const eventTime = new Date(eventData.date)

    const timeDifference = eventTime.getTime() - currentTime.getTime()

    const hoursDifference = timeDifference / (1000 * 60 * 60)

    if (hoursDifference > 48) {
      setCancelModalVisible(true)
    } else {
      Modal.error({
        title: 'Cancellation Not Allowed',
        content: 'Events can only be cancelled before 48 hours of the scheduled event.'
      })
    }
  }
  const handleReject = () => {

    setRejectModalVisible(true)

  }
  const handleRejectSubmit = async (eventId, status, reason) => {
    if (!rejectReason) {
      notification.warning({ message: 'Please enter valid reason' })
      return
    }
    let resp = await dispatch(updateInstituteEventStatusFxn(eventId, status, reason))
    await fetchInstituteEventData()
    await loadComments()
    fetchActivityList()
    onSubmit()
    // onClose()
    setCancelModalVisible(false)
  }


  const handleCancelSubmit = async (eventId, status, reason) => {
    if (!cancelReason) {
      notification.warning({ message: 'Please enter valid reason' })
      return
    }
    let resp = await dispatch(updateInstituteEventStatusFxn(eventId, status, reason))
    await fetchInstituteEventData()
    await loadComments()
    fetchActivityList()
    onSubmit()
    // onClose()
    setCancelModalVisible(false)
  }


  const handleApproveClick = async (eventId, status) => {
    let resp = await dispatch(updateInstituteEventStatusFxn(eventId, status))
    await fetchInstituteEventData()
    onSubmit()
    // onClose()
    loadComments()
    fetchActivityList()
  }

  const events = {
    shareLink: () => {
      let url = `https://unizportal.com/event-and-student-registration?eventId=${eventId}`
      let message = encodeURIComponent(url)
      let whatsapp_url = 'whatsapp://send?text=' + message
      window.open(whatsapp_url, '_blank')
    },
    copyFxn: () => {
      let url = `https://unizportal.com/event-and-student-registration?eventId=${eventId}`
      navigator.clipboard.writeText(url)
      notification.success({ message: 'Event share link copied successfully.' })
    }
  }

  return (
    <Drawer
      title={'Event Details'}
      placement='right'
      closable={true}
      onClose={onClose}
      visible={visible}
      width='65%'>
      <>
        <div className={styles.participant_card}>
          {eventData && eventData._id ?
            <Row gutter={16}>
              <Col span={24}>
                <div className={styles.info_card}>
                  <div className={styles.information}>
                    <div style={{ flex: 1 }}>
                      <div className={'jcsb'}>
                        <h4>{eventData.venue}</h4>
                        {moment(eventData.date).isAfter() ? <div>
                          <div className={'ml20 d-flex'}>
                            {eventData.status == 'Pending' && currentUser.userType == 'eventManager' && (
                              <a className={'btn btn-danger round sm'} onClick={handleReject}>Reject</a>
                            )}
                            {eventData.status == 'Pending' && currentUser.userType == 'eventManager' && (
                              <Popconfirm
                                title='Are you sure you want to approve this event?'
                                onConfirm={() => handleApproveClick(eventData._id, 'Approved')}
                                okText='Yes'
                                cancelText='No'
                                placement='bottom'
                              >
                                <a className={'btn btn-success-green round sm'} style={{ marginLeft: 6 }}>Approve
                                  Now</a>
                              </Popconfirm>
                            )}
                            {eventData.status === 'Approved' && (
                              <>
                                <a className={'btn btn-danger round sm'} onClick={handleCancel}>Cancel</a>
                                {currentUser.userType !== 'institute' &&
                                <a className={'btn btn-warning round sm ml5'} onClick={handleOpenDateDrawer}>Change Date</a>

                                }
                                <a className={'btn btn-view round sm ml5'} onClick={() => {
                                  events.shareLink()
                                }}>
                                  <Icon type='share-alt' />
                                </a>

                                <a className={'btn btn-view round sm ml5'} onClick={() => {
                                  events.copyFxn()
                                }}>
                                  <Icon type='copy' />
                                </a>


                              </>
                            )}
                          </div>
                        </div> : null}
                      </div>

                      <div className={'align_center'} key={eventData.date}>
                        Date/Time : &nbsp; {displayDate(eventData.date)}, {displayTime(eventData.time)}
                      </div>
                      <div className={styles.alignCenter}>
                        State/City : &nbsp; {eventData.state}, {eventData.city}
                      </div>
                      <div className={'aic'}>
                        Status : &nbsp;
                        {eventData.status === 'Pending' && (
                          <label className='label label-success'>{eventData.status}</label>
                        )}
                        {eventData.status === 'Approved' && (
                          <label className='label label-success-green label-sm'>{eventData.status}</label>
                        )}
                        {eventData.status === 'Cancel' && (
                          <label className='label label-danger'>{eventData.status}</label>
                        )}
                      </div>


                    </div>
                  </div>

                  <div>
                    <Tabs defaultActiveKey='1'>
                      <TabPane tab='Comments' key='1' className={styles.comments}>
                        <div className='comment-section'>
                          <div className='comment-wrap-new' style={{ overflowY: 'auto' }}>
                            {commentState.data && commentState.data.length ? commentState.data.map(comment => (
                              <>
                                <div className='comment' key={comment._id}>
                                  <Avatar className={'textUpp avatar_text1'} size='large'>
                                    {nameAvatar(comment.createdByUser.name)}
                                  </Avatar>
                                  <div className={styles.comment_text}>
                                    <p className='comment-author'>
                                      <span className={'name'}><strong>{comment.createdByUser.name}</strong>
                                        <span
                                          className='date1'>{displayDate(comment.createdAt)} | {displayTime(comment.createdByUser.time)}</span>
                                      </span>
                                    </p>
                                    <div className={'mb5'}>
                                      {comment.comment}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )) : null}
                          </div>

                          {eventData.status !== 'Cancel' && eventData.status !== 'Reject' && (
                            <div className={styles.input_box}>
                              <Avatar className={'textUpp avatar_text1'} size='large'>
                                {nameAvatar(currentUser.name)}
                              </Avatar>
                              <Input
                                placeholder='Add Comment' className={styles.input_comment1}
                                value={comments}
                                onChange={(e) => setComments(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    handleSubmitComment(eventId, comments)
                                  }
                                }}
                              />
                              <i className='fa fa-paper-plane fa-lg ml-2'
                                 aria-hidden='true'
                                 style={{ cursor: 'pointer' }}
                                 onClick={() => {
                                   handleSubmitComment(eventId, comments)
                                 }}
                              />

                            </div>
                          )}
                        </div>
                      </TabPane>
                      <TabPane tab='Activity' key='2' className={styles.activity}>
                        <div className='act-container'>
                          <div className='act-card'>
                            <div className='act-card-body'>
                              <div className={'scrollView'} style={{ height: '62vh' }}>

                                <div className='application-activity profile-activity'>
                                  <div className='card-body'>
                                    <ul>
                                      {activity && activity.length ? activity.map((item, key) => {
                                        return (
                                          <li key={key}>
                                            <div className='date'>
                                              <span> {displayDate(item.createdAt)}<br />{moment(item.createdAt).format('hh:mm a')}</span>
                                            </div>
                                            <div className='content'>
                                              <label style={{ color: 'black' }}>
                                                {item.title}
                                              </label>
                                              <div className={'ml10'}>
                                                <div className={'actBy'}> by {item.createdByUser.name}</div>
                                                <div
                                                  style={{
                                                    marginLeft: 25,
                                                    color: 'black'
                                                  }}>{item.message ? renderHTML(item.message) : ''}</div>
                                              </div>
                                            </div>
                                          </li>
                                        )

                                      }) : null}
                                    </ul>
                                  </div>
                                </div>
                              </div>


                              {/*    <ul className='act-list'>
                                {activity && activity.length ? activity.map((item, key) => (
                                  <li key={key} className='act-list-item'>
                                    <div className='act-date'>
                                      <span>{moment(item.createdAt).format('MMM DD')}<br />{moment(item.createdAt).format('hh:mm a')}</span>
                                    </div>
                                    <div className='act-content'>
                                      <label className='act-label'>
                                        <span className='act-dot'>&#8226;</span>{item.title}
                                      </label>
                                      <div className='act-info'>
                                        by {item.createdByUser.name}
                                      </div>
                                      <ul className='act-message'>
                                        {item.message.split('\n').map((msg, index) => (
                                          <li key={index} className='act-message-item'>{msg}</li>
                                        ))}
                                      </ul>

                                      {item.subject &&
                                      <p className='act-subject'>
                                        <strong>Subject</strong> : {item.subject}
                                      </p>}
                                    </div>
                                  </li>
                                )) : <li>No activity to display</li>}
                              </ul>*/}
                            </div>
                          </div>
                        </div>

                      </TabPane>
                    </Tabs>
                  </div>

                </div>


              </Col>
              <Col span={8}>


              </Col>
            </Row> : null}

        </div>

      </>

      <Modal
        title='Cancel Event'
        visible={cancelModalVisible}
        onCancel={() => setCancelModalVisible(false)}
        footer={[
          <Button key='submit' type='primary' onClick={() => handleCancelSubmit(eventId, 'Cancel', cancelReason)}>
            Submit
          </Button>
        ]}>
        <Input.TextArea
          placeholder='Enter reason for cancellation'
          value={cancelReason}
          onChange={(e) => setCancelReason(e.target.value)}
          autoSize={{ minRows: 3, maxRows: 6 }}
        />
      </Modal>
      <Modal
        title='Reject Event'
        visible={rejectModalVisible}
        onCancel={() => setRejectModalVisible(false)}
        footer={[
          <Button key='submit' type='primary' onClick={() => handleRejectSubmit(eventId, 'Reject', rejectReason)}>
            Submit
          </Button>
        ]}>
        <Input.TextArea
          placeholder='Enter reason for rejection'
          value={rejectReason}
          onChange={(e) => setRejectReason(e.target.value)}
          autoSize={{ minRows: 3, maxRows: 6 }}
        />
      </Modal>
      <ChangeDateDrawer
      onClose={handleCloseDateDrawer}
      visible={dateDrawerVisible}
      disabledDate={disabledDate}
      onSubmit={handleSubmitDate}
      eventId={eventId}
      approvedEvents={instituteEvents}
      slotList={slotList}
      getFieldDecorator={form.getFieldDecorator}
      form={form}
    />
    
    </Drawer>


  )
}

const WrappedInstituteEventDrawer = Form.create()(InstituteEventDrawer)
export default WrappedInstituteEventDrawer
