import { Button, Drawer, InputNumber, Select, Form, Row, Col, notification } from 'antd'
import style from '../views/styles.less'
import React, { useEffect, useState } from 'react'
import { boardList } from '../../../components/_utils/appUtils'

const Option = Select
let initialState = {
  board: null,
  year: null,
  percentage: null
}
const Waivers = (props) => {
  let { onClose, visible, addWaiverReq, data } = props
  let [state, setState] = useState(initialState)

  let setLocalState = (data) => {
    setState({ ...state, ...data })
  }
  useEffect(() => {
    setDefaultData()
  }, [])

  const setDefaultData = () => {
    setState({ ...data })
  }

  let addWaivers = () => {
    let {
      board
    } = state
    if (!board) {
      return notification.warning({ message: 'Choose board' })
    }
    addWaiverReq(state)
  }

  return (
    <Drawer width={800}
            title={'Qualification'} placement="right"
            visible={visible}
            onClose={onClose}>

      <div className="row">
        <div className="col-lg-12">
          <Form noValidate>
            <div className="form-box mt-4">

              <div className="card unizportal">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label={'Choose Board'}>
                      <Select
                        placeholder={'Choose Board'}
                        value={state.board}
                        onChange={ei => {
                          setState({
                            ...state,
                            board: ei,
                            year: '',
                            percentage: ''
                          })
                        }}>
                        {boardList.map((item, index) => {
                          return (
                            <Option value={item.value} key={index}>{item.name}</Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <React.Fragment>
                    <Col span={12}>
                      <Form.Item label={'Year'}>
                        <InputNumber
                          placeholder={'Year'}
                          type={'number'}
                          maxLength={4}
                          value={state.year}
                          onChange={ei => {
                            setLocalState({ year: ei })
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={'Percentage'}>
                        <InputNumber
                          placeholder={'Percentage'}
                          type={'number'}
                          value={state.percentage}
                          maxLength={2}
                          onChange={ei => {
                            setLocalState({ percentage: ei })
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </React.Fragment>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        type="primary" htmlType="submit" className="btn"
                        onClick={() => addWaivers()}>
                        Add
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      </div>

    </Drawer>
  )
}

export default Waivers
