import {apiUrl} from '../../../settings'

export const oxfordMoveToInterviewDepartmentUrl = () => {
    return apiUrl + '/api/oxfordMoveToInterviewDepartment'
}

export const updateInterviewScheduleUrl = () => {
    return apiUrl + '/updateInterviewSchedule'
}

export const updateInterviewStatusUrl = () => {
    return apiUrl + '/updateInterviewStatus'
}

export const interviewScheduleDateUrl = () => {
    return apiUrl + '/interviewScheduleDate'
}

export const oxfordInterviewApplicationsUrl = () => {
    return apiUrl + '/oxfordInterviewApplications'
}

export const exportOxfordInterviewListUrl = () => {
    return apiUrl + '/api/exportOxfordInterviewList'
}
