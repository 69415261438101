import React from "react"
import {countryIds} from "../../../components/_utils/appUtils";
import ProgramListComponent from "./ProgramListComponent";

const USAProgramList = (props) => {
    return (
        <>
            <ProgramListComponent countryId={countryIds.usa} countryName={'USA'}/>
        </>
    )
}
export default USAProgramList
