import { customAxios as axios, getToken } from '../../request'
import { getAgentListByDirectUniversityUrl } from './api'
import { notification } from 'antd'

export const getAgentListByDirectUniversityFxn = async (filters) => {
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let resp = await axios.get(getAgentListByDirectUniversityUrl(), config)
  if (resp && resp.error) {
    notification.error({
      message: resp.message || 'Error'
    })
  }
  return resp.data
}