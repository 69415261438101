import { customAxios as axios, getToken } from '../../../../request'
import { notification } from 'antd'
import { getOnShoreApplicationListForCommissionUrl } from '../apis'

export const getOnShoreApplicationListForCommissionFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getOnShoreApplicationListForCommissionUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}