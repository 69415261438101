import React, {Component} from 'react'
import {TableComp} from 'sz-react-utils-lite'
import {notification, Switch, Button, Tooltip, Row, Col} from 'antd'
import {connect} from 'react-redux'
import {
    blockAgent,
    getAgentAppCount,
    getExportAgent
} from '../actions/user'
import {DefaultTablePagination} from '../../../components/_utils/appUtils'
import {ColumnWidth} from '../../WebComponent/columnWidth'
import ViewApplication from '../../applications/views/viewApplication'
import {getUrlPushWrapper} from "../../../routes";
import {LoadAllCountry, LoadCity, LoadState} from '../../../components/_utils/countryUtil'
import _ from "lodash"
import MobileEmailInfo from "../../../components/commonComponents/mobileEmailInfo";

class MarketingAgentList extends Component {
    events = {
        loadApplications: (data) => {
            data.marketingUserId = this.state.marketingUserId
            this.setState({visibleApplicationDrawer: true, queryData: data})
        },
        hideApplicationDrawer: () => {
            this.setState({visibleApplicationDrawer: false})
        },
        chooseCountry: (name) => {
            let countryName = _.find(this.state.allCountries, (item) => {
                return item.name == name
            })
            if (countryName) {
                this.setState({
                    countryName: countryName.name ? countryName.name : '',
                    stateName: undefined,
                    cityName: undefined,
                    pincode: undefined,
                    allStates: countryName.id ? LoadState(countryName.id) : []
                })
            }
        },
        chooseState: (name) => {
            let stateName = _.find(this.state.allStates, (item) => {
                return item.name == name
            })
            if (stateName) {
                this.setState({
                    stateName: stateName.name ? stateName.name : '',
                    cityName: undefined,
                    pincode: undefined,
                    allCities: stateName.id ? LoadCity(stateName.id) : []
                })
            }
        },
        chooseCity: (name) => {
            this.setState({
                cityName: name ? name : ''
            })
        }
    }


    constructor(props) {
        super(props)
        this.state = {
            data: [],
            viewInfoDrawer: false,
            selectedRow: {},
            allAgents: [],
            allBranchManager: [],
            allCountry: [],
            selectedUser: {},
            visibleUpdatePassword: false,
            visibleUpdateUser: false,
            visibleUpdateSubAgent: false,
            visibleApplicationDrawer: false,
            queryData: {},
            total: '',
            marketingUserId: '',
            showZeroCase: false,
            allCountries: LoadAllCountry(),
            allStates: [],
            allCities: [],
        }
        this.tableRef = React.createRef()
    }

    componentDidMount() {
        this.setFieldByParams()
    }

    setFieldByParams = async () => {
        let {countryName, stateName, cityName, pincode, agent} = await this.getParamsForApplicationList()
        let obj = {}
        if (countryName) {
            obj.countryName = countryName
        }
        if (stateName) {
            obj.stateName = stateName
        }
        if (cityName) {
            obj.cityName = cityName
        }
        if (pincode) {
            obj.pincode = pincode
        }
        if (agent) {
            obj.agent = agent
        }
        this.setState(obj)
    }

    getParamsForApplicationList = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let countryName = searchParams.get('countryName')
            let stateName = searchParams.get('stateName')
            let cityName = searchParams.get('cityName')
            let pincode = searchParams.get('pincode')
            let agent = searchParams.get('agent')

            let obj = {}
            if (countryName) {
                obj.countryName = countryName
            }
            if (stateName) {
                obj.stateName = stateName
            }
            if (cityName) {
                obj.cityName = cityName
            }
            if (pincode) {
                obj.pincode = pincode
            }
            if (agent) {
                obj.agent = agent
            }
            resolve(obj)
        })
    }

    searchFxn = async () => {
        let {dispatch} = this.props
        let obj = {}
        if (this.state.countryName) {
            obj.countryName = this.state.countryName
        }
        if (this.state.stateName) {
            obj.stateName = this.state.stateName
        }
        if (this.state.cityName) {
            obj.cityName = this.state.cityName
        }
        if (this.state.pincode) {
            obj.pincode = this.state.pincode
        }
        if (this.state.agent) {
            obj.agent = this.state.agent
        }
        dispatch(
            getUrlPushWrapper('marketingAgent', {
                ...obj
            }))
        setTimeout(() => {
            if (this.tableRef && this.tableRef.current) {
                this.tableRef.current.reload()
            }
        }, 200)
    }
    clearFxn = async () => {
        let {dispatch} = this.props
        let obj = {}
        this.setState({
            countryName: '',
            stateName: '',
            cityName: '',
            pincode: '',
            agent: ''
        })
        dispatch(
            getUrlPushWrapper('marketingAgent', {
                ...obj
            }))
        setTimeout(() => {
            this.tableRef.current.reload()
        }, 200)
    }

    viewInfo(record) {
        this.setState({
            selectedRow: record,
            viewInfoDrawer: true
        })
    }

    onClose = () => {
        this.setState({
            selectedRow: {},
            viewInfoDrawer: false
        })
    }

    closeUpdatePassword = () => {
        this.setState({
            selectedUser: {},
            visibleUpdatePassword: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    closeEditUser = () => {
        this.setState({
            selectedUser: {},
            visibleUpdateUser: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    closeEditSubAgent = () => {
        this.setState({
            selectedUser: {},
            visibleUpdateSubAgent: false
        }, () => {
            this.tableRef.current.reload()
        })
    }
    updateAgentBlockStatus = async (id) => {
        let {dispatch} = this.props
        let resp = await dispatch(blockAgent({agentId: id}))
        if (resp && resp.success) {
            this.tableRef.current.reload()
        }
    }

    apiRequest = async (params) => {
        let searchParams = new URLSearchParams(window.location.search);
        let {showZeroCase} = this.state;
        let id = searchParams.get('id')
        return new Promise(async (resolve) => {
            let obj = {}
            if (id) {
                obj.id = id
            }
            let resp1 = await this.getParamsForApplicationList()
            if (resp1.countryName) {
                params.countryName = resp1.countryName
            }
            if (resp1.stateName) {
                params.stateName = resp1.stateName
            }
            if (resp1.cityName) {
                params.cityName = resp1.cityName
            }
            if (resp1.pincode) {
                params.pincode = resp1.pincode
            }
            if (resp1.agent) {
                params.name = resp1.agent
            }

            let finalFilter = {
                ...obj,
                ...params,
                showZeroCase: showZeroCase,
                regExFilters: ['name', 'address', 'mobile', 'email']
            }
            this.setState({filters: finalFilter})
            const data = await getAgentAppCount(finalFilter);
            this.setState({total: data.total, marketingUserId: id})
            resolve(data)
        })
    }
    exportAgentsFxn = async () => {
        let {filters, total} = this.state
        let {dispatch} = this.props
        let {success, message} = await dispatch(getExportAgent({...filters, results: total}))
        if (success) {
            notification.success({message: message})
        } else {
            notification.warning({message: 'Error, please try again'})
            return
        }
    }

    zeroCaseFxn = (showZeroCase) => {
        this.setState({
            showZeroCase
        }, () => {
            setTimeout(() => {
                this.tableRef.current.reload()
            }, 500)
        })
    }


    render() {
        let {
            showZeroCase,
            visibleApplicationDrawer,
        } = this.state
        const columns = [
            {
                title: '#',
                key: '_id',
                dataIndex: '_id',
                width: 50,
                render: (ite, record, index) => {
                    return (
                        <React.Fragment>
                            {index + 1}
                        </React.Fragment>
                    )
                }
            },
            {
                title: 'Name',
                key: 'name',
                sorter: true,
                dataIndex: 'name',
                width: 100,
                render: (item, record) => {
                    let {_id} = record
                    return (
                        <ColumnWidth width={100}>
                            {item && item ? item : ""}

                        </ColumnWidth>
                    )
                }
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                width: 120,
                render: (item, record) => {
                    let {_id} = record
                    return (
                        <ColumnWidth width={120}>
                            {/*{item && item ? item : ""}*/}
                            <MobileEmailInfo data={record} type={'email'} value={item}/>

                        </ColumnWidth>
                    )
                }
            },
            {
                title: 'Mobile No',
                dataIndex: 'mobile',
                key: 'mobile',
                width: 80,
                render: (item, record) => {
                    return (
                        <ColumnWidth width={80}>
                            <MobileEmailInfo data={record} type={'mobile'} value={item}/>
                        </ColumnWidth>
                    )
                }
            },
            {
                title: 'Company Name',
                dataIndex: 'companyName',
                key: 'companyName',
                width: 80,
                render: (item, record) => {
                    let {_id} = record
                    return (
                        <ColumnWidth width={80}>
                            {item && item ? item : ""}

                        </ColumnWidth>
                    )
                }
            },

            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                width: 100,
                render: (item, record) => {
                    let {_id} = record

                    return (
                        <ColumnWidth width={100}>
                            {item && item ? item : ""}

                        </ColumnWidth>
                    )
                }
            },
            {
                title: 'Count',
                dataIndex: 'count',
                key: 'count',
                width: 50,
                render: (item, record) => {
                    return (
                        <React.Fragment>
                            {item ? <a onClick={() => this.events.loadApplications({agentId: record._id})}
                                       className={'countLink'}><u>{item}</u></a> : null}
                        </React.Fragment>
                    )
                }
            }


        ]

        const filterBlock = (
            <Row gutter={16} className={'mt10'}>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Name/Company Name' value={this.state.agent} onChange={(e) => {
                            this.setState({agent: e.target.value})
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>


                <Col md={4} sm={4} xs={12} lg={4}>
                    <Button onClick={() => this.searchFxn()} className={'roundBtn'}>Search</Button>
                    <Button onClick={() => this.clearFxn()} className={'roundBtn'}>
                        Clear
                    </Button>
                </Col>


            </Row>
        )
        return (
            <div>

                <div className='row  mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head d-flex align-items-center'>
                                <h5>Agents ({this.state.total})</h5>
                                <div className='search-box-table'>
                                </div>

                                <div className='sort-box-table mark-btn'>
                                    <Switch checked={showZeroCase} onClick={(e) => {
                                        this.zeroCaseFxn(e)
                                    }}/>
                                    <span className={'ml10'}>Show Zero Case Agents</span>

                                    <span className={'sort-box-table-right ml10'}>
                                        {/*<Tooltip title={'Export Excel'}>
                                           <Button onClick={() => this.exportAgentsFxn()}
                                                        className={'roundBtn'}>Export Excel</Button>
                                        </Tooltip>*/}
                                      </span>
                                </div>
                            </div>
                            <div className='card-body table-responsive'>
                                {filterBlock}
                                <TableComp
                                    columns={columns}
                                    ref={this.tableRef}
                                    pagination={{
                                        position: 'top',
                                        pageSizeOptions: ['10', '20', '50'],
                                        defaultPageSize: 10,
                                    }}
                                    apiRequest={this.apiRequest}
                                    extraProps={{scroll: {x: 1000}}}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {visibleApplicationDrawer ? <ViewApplication
                    visible={visibleApplicationDrawer}
                    pageType={'allApplication'}
                    onClose={() => this.events.hideApplicationDrawer()}
                    queryData={this.state.queryData}
                /> : null}

            </div>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    activeUser: global.activeUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MarketingAgentList)
