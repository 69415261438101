import React, {Suspense, useEffect, useState} from 'react'
import {Avatar, Icon} from 'antd'
import _ from 'lodash'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import {useDispatch} from 'react-redux'
import {
    australiaApplicationStatusSteps,
    canadaApplicationStatusSteps,
    singaporeApplicationSteps,
    ukApplicationStatusSteps,
    usaApplicationStatusSteps,
    australiaApplicationL1StatusSteps,
    canadaOnShoreApplicationStatusSteps,
    australiaOnshoreApplicationStatusSteps
} from '../../../components/_utils/ApplicationStatusSteps'
import {
    cambieUniversity,
    countryIds,
    fcmtUniversity,
    currentUdaan, europeCountries, agentTeamTypes
} from '../../../components/_utils/appUtils'
import {getUrlPushWrapper} from '../../../routes'
import {
    getStudentFcmtCourseFxn,
    oshcApplicationsFxn,
    ukTuitionFeeApplicationFxn
} from '../../applications/actions/chooseStudent'
import {
    AustraliaCountryCount,
    AustraliaInnerBlock,
    CanadaCountryCount,
    CanadaInnerBlock,
    InnerBlockNew,
    UkCountryCount,
    UkInnerBlock,
    CanadaOnShoreInnerBlock,
    CyprusInnerBlock
} from './countryCount'
import {EuropeInnerBlock} from '../countryApplications/europeCountryBlocks'
import {GetCountryCountRight} from "./DashboardUserWise";
import {listAllCountries} from "../../countries/actions/countries";
/*const GetCountryCountRight = React.lazy(() =>
  import('./DashboardUserWise').then(module => ({
    default: module.GetCountryCountRight
  }))
)*/
const ShowCountryOnDashboard = React.lazy(() =>
    import('./DashboardUserWise').then(module => ({
        default: module.ShowCountryOnDashboard
    }))
)

const ShowOnshoreAustraliaToUsers = React.lazy(() =>
    import('./DashboardUserWise').then(module => ({
        default: module.ShowOnshoreAustraliaToUsers
    }))
)
const AdminDashboard = props => {
    let {events, userCountryName, userData, state} = props
    return (
        <div className='d-md-flex master-admin'>
            <>
                <GetCountryCountRight
                    user={userData}
                    userCountryName={userCountryName} userDetails
                    countryName={'uk'}>
                    <UkCountryCount
                        data={state.ukData}
                        callback={(countryId, status) => {
                            events.loadCountryApplications({countryId, status})
                        }}
                    />
                </GetCountryCountRight>
                <GetCountryCountRight
                    user={userData}
                    userCountryName={userCountryName}
                    countryName={'australia'}>
                    <AustraliaCountryCount
                        data={state.australiaData}
                        callback={(countryId, status) => {
                            events.loadCountryApplications({countryId, status})
                        }}
                    />
                </GetCountryCountRight>
                <GetCountryCountRight
                    user={userData}
                    userCountryName={userCountryName}
                    countryName={'canada'}>
                    <CanadaCountryCount
                        data={state.canadaData}
                        userCountryName={userCountryName}
                        userData={userData}
                        callback={(countryId, status) => {
                            events.loadCountryApplications({countryId, status})
                        }}
                    />
                </GetCountryCountRight>
            </>
        </div>
    )
}

const AgentDashboard = props => {
    let {
        userCountryName,
        userCountryId,
        userData,
        state,
        countryManager,
        allCountryRecords,
        currentUserRights,
        allDirectUniversityRecordData
    } = props

    let [country, setCountry] = useState('Canada');
    let [europeCountryList, setEuropeCountryList] = useState([])
    let [originalCountry, setOriginalCountry] = useState('')
    let countryName = country.toLowerCase()
    let mapCountryName = country.toLowerCase()
    let dispatch = useDispatch()
    let countryColors = {
        UK: '#ff0066',
        USA: '#ff0066',
        Australia: '#003399',
        Canada: '#ad2125',
        Germany: '#ad2125'
    }
    let chooseCountry = value => {
        setCountry(value)
    }
    useEffect(
        () => {
            if (userData.userType == 'canadaOnshoreAdmin') {
                if (userData.countryId && userData.countryId.countryName) {
                    chooseCountry(userData.countryId.countryName)
                }
            } else if (userData.userType == 'branchManager' && userData.branchManagerType !== 'marketingManager'
                && userData.branchManagerType !== 'studentWise') {
                if (userData.directCanadaOnShore) {
                    chooseCountry('Onshore_Direct_Canada')
                } else if (userData.countryId && userData.countryId.countryName) {
                    let countryName = userData.countryId.countryName.toLowerCase()
                    if (europeCountries.includes(countryName)) {
                        setOriginalCountry(userData.countryId.countryName)
                        chooseCountry('Europe')
                    } else {
                        chooseCountry(userData.countryId.countryName)
                    }
                }
            } else if (userData.userType == 'cambieManager') {
                chooseCountry(userData.countryId.countryName)
            } else if (userData.userType == 'branchUser') {
                if (userData.department !== 'Visa Approved Department') {
                    if (userData.showOnShoreAustralia) {
                        chooseCountry('Australia_onShore')
                    } else if (userData.showOnShoreCanada) {
                        chooseCountry('Canada_onShore')
                    } else if (userData.directCanadaOnShore) {
                        chooseCountry('Onshore_Direct_Canada')
                    } else {
                        if (userData.countryId && userData.countryId.countryName) {
                            let countryName = userData.countryId.countryName.toLowerCase()
                            if (europeCountries.includes(countryName)) {
                                setOriginalCountry(userData.countryId.countryName)
                                chooseCountry('Europe')
                            } else {
                                chooseCountry(userData.countryId.countryName)
                            }
                        }
                    }
                }
            }
        },
        [userData.countryId]
    )
    useEffect(() => {
        loadEuropeCountry()
    }, [])
    let openApplicationList = data => {
        let newObj = {
            countryId: data.countryId,
            status:
                data.status == 'Draft Applications' ? 'Waiting To Apply' : data.status,
            draftApplication: data.status == 'Draft Applications' ? 'Yes' : 'No'
        }
        if (
            data.status == 'Visa Approved' &&
            (!agentTeamTypes.includes(userData.userType))
        ) {
            newObj.udaan = currentUdaan
        }
        if (data.countryId == countryIds.uk) {
            dispatch(
                getUrlPushWrapper('application.applicationsStudentList', {
                    ...newObj
                })
            )
        } else if (data.countryId == countryIds.australia) {
            dispatch(
                getUrlPushWrapper('application.applicationsList', {
                    ...newObj
                })
            )
        } else {
            dispatch(
                getUrlPushWrapper('application.applicationsList', {
                    ...newObj
                })
            )
        }
    }
    if (countryName == 'uk') {
        countryName = 'kingdom'
        mapCountryName = 'kingdom'
    } else if (countryName == 'australia_l1' ||
        countryName == 'australia_onshore' || countryName == 'australia onshore') {
        mapCountryName = 'australia'
    } else if (countryName == 'onshore_direct_canada' ||
        countryName == 'canada_onshore' ||
        countryName == 'canada onshore') {
        mapCountryName = 'canada'
    }


    let loadEuropeCountry = async () => {
        let {data: countryData} = await dispatch(listAllCountries({
            countryGroup: 'Europe', results: 10, count: 10, active: [true], select: ['countryName']
        }))
        if (countryData && countryData.length) {
            setEuropeCountryList(countryData)
        }
    }


    return (
        <React.Fragment>
            <div className='d-md-flex pattern-img align-items-center'>
                <div className='flex-fill map-section' style={{overflow: 'hidden'}}>
                    <div className='tab-content' id='v-pills-tabContent'>
                        <div
                            className='tab-pane fade show active'
                            id='v-pills-canada'
                            role='tabpanel'
                            aria-labelledby='v-pills-canada-tab'>
                            <div
                                className='p-1 flex-fill map-section'
                                style={{overflow: 'hidden'}}>
                                <LazyLoadImage
                                    src={`/dist/img/${mapCountryName}-map.png`}
                                    alt=''
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className='card-pane-right listing-section'
                    style={{width: '35%'}}>
                    <div
                        className='nav flex-column nav-pills w-100'
                        id='v-pills-tab'
                        role='tablist'
                        aria-orientation='vertical'>
                        {countryManager && countryManager[country] && userData &&
                        userData.userType == 'agent' ? (
                            <>
                                <div
                                    style={{
                                        marginLeft: 'auto',
                                        backgroundColor: countryColors[country],
                                        color: 'white',
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        marginBottom: 20
                                    }}>
                                      <span style={{marginLeft: 'auto', fontSize: 14}}>
                                        DESIGNATED MANAGER
                                      </span>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end'
                                    }}>
                                    <div className={'row'}>
                                        <div
                                            style={{
                                                textAlign: 'right',
                                                padding: 5,
                                                fontSize: 12,
                                                color: '#585858'
                                            }}>
                                            <div>
                        <span style={{fontWeight: 'bold'}}>
                          {countryManager[country].name} <Icon type={'user'}/>
                        </span>
                                                <br/>
                                                {countryManager[country].email} <Icon type={'mail'}/>
                                                <br/>
                                                {countryManager[country].mobile} <Icon type={'phone'}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'center'} style={{paddingLeft: 5}}>
                                        <Avatar
                                            size={50}
                                            style={{
                                                border: `3px solid ${countryColors[country]}`,
                                                marginLeft: 10
                                            }}
                                            src={
                                                countryManager[country] &&
                                                countryManager[country].logo &&
                                                countryManager[country].logo.url
                                                    ? countryManager[country].logo.url
                                                    : '../dist/img/user.png'
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {/*</div>*/}
                        <a
                            className={`nav-link ${countryName == 'canada' ? 'active' : ''}`}
                            id='v-pills-canada-tab'
                            data-toggle='pill'
                            href='#v-pills-canada'
                            role='tab'
                            onClick={() => chooseCountry('Canada')}
                            aria-controls='v-pills-canada'
                            aria-selected='true'>
                            canada
                        </a>

                        {/*              <ShowCountryOnDashboard
                            user={userData}
                            country={country}
                            userCountryName={userCountryName}
                            countryName={'australia'}>
                            <a
                                className={`nav-link ${
                                    countryName == 'australia' ? 'active' : ''
                                }`}
                                id='v-pills-australia-tab'
                                data-toggle='pill'
                                href='#v-pills-australia'
                                role='tab'
                                onClick={() => chooseCountry('Australia')}
                                aria-controls='v-pills-australia'
                                aria-selected='false'>
                                australia
                            </a>
                        </ShowCountryOnDashboard>

                        <ShowOnshoreAustraliaToUsers user={userData}>
                            <a
                                className={`nav-link ${
                                    countryName == 'australia onshore' ? 'active' : ''
                                }`}
                                id='v-pills-australia_onshore-tab'
                                data-toggle='pill'
                                href='#v-pills-australia_onshore'
                                role='tab'
                                onClick={() => chooseCountry('Australia Onshore')}
                                aria-controls='v-pills-australia'
                                aria-selected='false'>
                                australia onshore
                            </a>
                        </ShowOnshoreAustraliaToUsers>*/}


                        <a
                            className={`nav-link ${countryName == 'kingdom' ? 'active' : ''}`}
                            id='v-pills-kingdom-tab'
                            data-toggle='pill'
                            href='#v-pills-kingdom'
                            role='tab'
                            onClick={() => chooseCountry('UK')}
                            aria-controls='v-pills-kingdom'
                            aria-selected='false'>
                            united kingdom
                        </a>

                        <a
                            className={`nav-link ${countryName == 'usa' ? 'active' : ''}`}
                            id='v-pills-kingdom-tab'
                            data-toggle='pill'
                            href='#v-pills-kingdom'
                            role='tab'
                            onClick={() => chooseCountry('USA')}
                            aria-controls='v-pills-kingdom'
                            aria-selected='false'>
                            USA
                        </a>

                        <a
                            className={`nav-link ${countryName == 'canada onshore' ? 'active' : ''}`}
                            id='v-pills-canada-onshore-tab'
                            data-toggle='pill'
                            href='#v-pills-canada-onshore'
                            role='tab'
                            onClick={() => chooseCountry('Canada Onshore')}
                            aria-controls='v-pills-canada-onshore'
                            aria-selected='false'>
                            Canada Onshore
                        </a>

                        <a
                            className={`nav-link ${
                                countryName == 'singapore' ? 'active' : ''
                            }`}
                            id='v-pills-singapore-tab'
                            data-toggle='pill'
                            href='#v-pills-singapore'
                            role='tab'
                            onClick={() => chooseCountry('Singapore')}
                            aria-controls='v-pills-singapore'
                            aria-selected='false'>
                            Singapore
                        </a>

                        <a
                            className={`nav-link ${countryName == 'europe' ? 'active' : ''}`}
                            id='v-pills-europe-tab'
                            data-toggle='pill'
                            href='#v-pills-europe'
                            role='tab'
                            onClick={() => chooseCountry('Europe')}
                            aria-controls='v-pills-europe'
                            aria-selected='false'>
                            Europe
                        </a>

                        <a
                            className={`nav-link ${countryName == 'cyprus' ? 'active' : ''}`}
                            id='v-pills-cyprus-tab'
                            data-toggle='pill'
                            href='#v-pills-cyprus'
                            role='tab'
                            onClick={() => chooseCountry('Cyprus')}
                            aria-controls='v-pills-cyprus'
                            aria-selected='false'>
                            cyprus
                        </a>


                    </div>
                </div>
            </div>
            {!_.isEmpty(state) ? (
                <div>
                    {country == 'UK' ? (
                        <>
                            <GetCountryCountRight
                                user={userData}
                                country={country}
                                userCountryName={userCountryName}
                                countryName={'uk'}>
                                <UkInnerBlock
                                    data={state.ukData}
                                    statusList={ukApplicationStatusSteps}
                                    currentUserRights={currentUserRights}
                                    directUniversityRecords={allDirectUniversityRecordData && (allDirectUniversityRecordData.ukDirectUniCount || {})}
                                    userData={userData}
                                    userCountryName={country}
                                    callback={(countryId, status) => {
                                        openApplicationList({
                                            countryId: state.ukData.countryId,
                                            status
                                        })
                                    }}
                                />
                            </GetCountryCountRight>
                        </>
                    ) : (
                        ''
                    )}

                    {country == 'USA' ? (
                        <>
                            <GetCountryCountRight
                                user={userData}
                                country={country}
                                userCountryName={userCountryName}
                                countryName={'usa'}>
                                <InnerBlockNew
                                    data={state.usaData}
                                    statusList={usaApplicationStatusSteps}
                                    callback={(countryId, status) => {
                                        openApplicationList({
                                            countryId: state.usaData.countryId,
                                            status
                                        })
                                    }}
                                />
                            </GetCountryCountRight>
                        </>
                    ) : (
                        ''
                    )}

                    {country == 'Singapore' ? (
                        <>
                            <GetCountryCountRight
                                user={userData}
                                country={country}
                                userCountryName={userCountryName}
                                countryName={'singapore'}>
                                <InnerBlockNew
                                    data={state.singaporeData}
                                    statusList={singaporeApplicationSteps}
                                    callback={(countryId, status) => {
                                        openApplicationList({
                                            countryId: state.singaporeData.countryId,
                                            status
                                        })
                                    }}
                                />
                            </GetCountryCountRight>
                        </>
                    ) : (
                        ''
                    )}

                    {country == 'Canada' ? (
                        <>
                            <GetCountryCountRight
                                user={userData}
                                country={country}
                                userCountryName={userCountryName}
                                countryName={'canada'}>
                                <CanadaInnerBlock
                                    data={state.canadaData}
                                    statusList={canadaApplicationStatusSteps}
                                    currentUserRights={currentUserRights}
                                    directUniversityRecords={allDirectUniversityRecordData && (allDirectUniversityRecordData.canadaDirectUniCount || {})}
                                    userData={{
                                        ...userData,
                                        ...allDirectUniversityRecordData.user
                                    }}
                                    userCountryName={country}
                                    callback={(countryId, status) => {
                                        openApplicationList({
                                            countryId: state.canadaData.countryId,
                                            status
                                        })
                                    }}
                                />
                            </GetCountryCountRight>
                        </>
                    ) : (
                        ''
                    )}

                    {/*       {country == 'Australia' ? (
                        <>
                            <GetCountryCountRight
                                user={userData}
                                country={country}
                                userCountryName={userCountryName}
                                countryName={'australia'}>
                                <AustraliaInnerBlock
                                    data={state.australiaData}
                                    statusList={australiaApplicationStatusSteps}
                                    userData={userData}
                                    callback={(countryId, status) => {
                                        openApplicationList({
                                            countryId: state.australiaData.countryId,
                                            status
                                        })
                                    }}
                                />
                            </GetCountryCountRight>
                        </>
                    ) : (
                        ''
                    )}*/}
                    {/*

                    {country == 'Australia Onshore' ? (
                        <ShowOnshoreAustraliaToUsers user={userData}>
                            <>
                                <AustraliaInnerBlock
                                    data={state.australiaOnshoreData}
                                    statusList={australiaOnshoreApplicationStatusSteps}
                                    userData={userData}
                                    callback={(countryId, status) => {
                                        openApplicationList({
                                            countryId: state.australiaOnshoreData.countryId,
                                            status
                                        })
                                    }}
                                />
                            </>
                        </ShowOnshoreAustraliaToUsers>
                    ) : null}

                    {country == 'Australia_L1' ?
                        <>
                            <GetCountryCountRight user={userData} country={country}
                                                  userCountryName={userCountryName}
                                                  countryName={'australia_l1'}>
                                <AustraliaInnerBlock data={state.australiaL1Data}
                                                     statusList={australiaApplicationL1StatusSteps}
                                                     userData={userData}
                                                     callback={(countryId, status) => {
                                                         openApplicationList({
                                                             countryId: state.australiaL1Data.countryId,
                                                             status
                                                         })
                                                     }}/>
                            </GetCountryCountRight>
                        </> : ''}
*/}


                    {country == 'Canada Onshore' ? (
                        <>
                            <CanadaOnShoreInnerBlock
                                data={state.canadaOnshoreData}
                                statusList={canadaOnShoreApplicationStatusSteps}
                                userData={userData}
                                callback={(countryId, status) => {
                                    openApplicationList({
                                        countryId: state.canadaOnshoreData.countryId,
                                        status
                                    })
                                }}
                            />
                        </>
                    ) : (
                        ''
                    )}
                    {country == 'Europe' ? (
                        <>
                            <GetCountryCountRight
                                user={userData}
                                country={country}
                                userCountryName={userCountryName}
                                countryName={originalCountry}>

                                <EuropeInnerBlock
                                    dispatch={dispatch}
                                    userCountryId={userCountryId}
                                    europeCountryList={europeCountryList}
                                    data={allCountryRecords}
                                    currentUserRights={currentUserRights}
                                    userData={userData}
                                    userCountryName={country}
                                    callback={(countryId, status) => {
                                        openApplicationList({
                                            countryId: countryId,
                                            status
                                        })
                                    }}
                                />
                            </GetCountryCountRight>
                        </>
                    ) : null}

                    {country == 'Cyprus' ? (
                        <>
                            <GetCountryCountRight
                                user={userData}
                                country={country}
                                userCountryName={userCountryName}
                                countryName={'cyprus'}>
                                <CyprusInnerBlock
                                    data={[]}
                                    statusList={[]}
                                    directUniversityRecords={allDirectUniversityRecordData && (allDirectUniversityRecordData.cyprusDirectUniCount || {})}
                                    currentUserRights={currentUserRights}
                                    userData={userData}
                                    userCountryName={country}
                                    callback={(countryId, status) => {
                                        openApplicationList({
                                            countryId: state.canadaData.countryId,
                                            status
                                        })
                                    }}
                                />
                            </GetCountryCountRight>
                        </>
                    ) : null}
                </div>
            ) : null}

        </React.Fragment>
    )
}

const FcmtUniversity = props => {
    let {userCountryName, userData} = props
    let [totalApplication, setTotalApplication] = useState(0)
    let dispatch = useDispatch()
    useEffect(() => {
        loadCount()
    }, [])
    let loadCount = async () => {
        let resp = await dispatch(
            getStudentFcmtCourseFxn({
                appUniversityId: fcmtUniversity,
                count: 0,
                results: 0
            })
        )
        setTotalApplication(resp.total)
    }

    let searchData = () => {
        if (userData && userData.showAllFcmtList) {
            dispatch(
                getUrlPushWrapper('allFcmtApplicationsList', {
                    appUniversityId: fcmtUniversity
                })
            )
        } else {
            dispatch(
                getUrlPushWrapper('application.fcmtApplicationsList', {
                    appUniversityId: fcmtUniversity
                })
            )
        }
    }

    let btnA = (
        <>
            <li onClick={() => searchData()} className={'pointerCls pointerCls1'}>
                <LazyLoadImage
                    src={'/assets/flairCollage.png'}
                    style={{height: 30}}
                />
                <h5>{totalApplication}</h5>
            </li>
        </>
    )

    let branchUserCheck = () => {
        let checkUniversityExists = _.find(userData.universities, item => {
            return item.toString() == fcmtUniversity
        })
        return userData.department == 'Visa Approved Department'
            ? btnA
            : checkUniversityExists
                ? btnA
                : null
    }
    return (
        <>
            {userCountryName == 'Canada' ? (
                <>
                    {userData.userType == 'admin' || agentTeamTypes.includes(userData.userType)
                        ? btnA
                        : null}
                    {userData.userType == 'branchManager' && userCountryName == 'Canada'
                        ? btnA
                        : null}
                    {userData.userType == 'branchUser' ? branchUserCheck() : null}
                </>
            ) : null}
        </>
    )
}

const CambieUniversity = props => {
    let {userCountryName, userData} = props
    let [totalApplication, setTotalApplication] = useState(0)
    let dispatch = useDispatch()
    useEffect(() => {
        loadCount()
    }, [])
    let loadCount = async () => {
        let resp = await dispatch(
            getStudentFcmtCourseFxn({
                appUniversityId: cambieUniversity,
                count: 0,
                results: 0
            })
        )
        setTotalApplication(resp.total)
    }

    let searchData = () => {
        if (userData && userData.showAllFcmtList) {
            dispatch(
                getUrlPushWrapper('allFcmtApplicationsList', {
                    appUniversityId: cambieUniversity
                })
            )
        } else {
            dispatch(
                getUrlPushWrapper('application.fcmtApplicationsList', {
                    appUniversityId: cambieUniversity
                })
            )
        }
    }

    let btnA = (
        <>
            <li onClick={() => searchData()} className={'pointerCls pointerCls1'}>
                <LazyLoadImage
                    src={'/assets/flairCollage.png'}
                    style={{height: 30}}
                />
                <h5>{totalApplication}</h5>
            </li>
        </>
    )

    let branchUserCheck = () => {
        let checkUniversityExists = _.find(userData.universities, item => {
            return item.toString() == cambieUniversity
        })
        return userData.department == 'Visa Approved Department'
            ? btnA
            : checkUniversityExists
                ? btnA
                : null
    }
    return (
        <>
            {userCountryName == 'Canada' ? (
                <>
                    {userData.userType == 'admin' || agentTeamTypes.includes(userData.userType)
                        ? btnA
                        : null}
                    {userData.userType == 'branchManager' && userCountryName == 'Canada'
                        ? btnA
                        : null}
                    {userData.userType == 'branchUser' ? branchUserCheck() : null}
                </>
            ) : null}
        </>
    )
}

const OSHCInsuranceApplicationsBtn = props => {
    let {userData} = props
    let [totalApplication, setTotalApplication] = useState(0)
    let dispatch = useDispatch()
    useEffect(() => {
        loadCount()
    }, [])
    let loadCount = async () => {
        let resp = await dispatch(
            oshcApplicationsFxn({
                count: 0,
                results: 0
            })
        )
        setTotalApplication(resp.total)
    }

    let searchData = () => {
        dispatch(getUrlPushWrapper('oshcApplications'))
    }

    let btnA = (
        <>
            <li onClick={() => searchData()} className={'pointerCls pointerCls1'}>
                OSHC
                <h5>{totalApplication}</h5>
            </li>
        </>
    )

    return (
        <>
            {userData.userType == 'admin'
                ? btnA
                : userData.email == 'accounts@ryanconsultants.com'
                    ? btnA
                    : null}
        </>
    )
}
const UkTuitionFeesApplicationBtn = props => {
    let [totalApplication, setTotalApplication] = useState(0)
    let dispatch = useDispatch()
    useEffect(() => {
        loadCount()
    }, [])
    let loadCount = async () => {
        let resp = await dispatch(
            ukTuitionFeeApplicationFxn({
                count: 0,
                results: 0
            })
        )
        setTotalApplication(resp.total)
    }

    let searchData = () => {
        dispatch(getUrlPushWrapper('ukTuitionFeeApplications'))
    }
    let btnA = (
        <>
            <li onClick={() => searchData()} className={'pointerCls pointerCls1'}>
                Tuition Fees
                <h5>{totalApplication}</h5>
            </li>
        </>
    )

    return (
        <>
            <li onClick={() => searchData()} className={'pointerCls pointerCls1'}>
                Tuition Fees
                <h5>{totalApplication}</h5>
            </li>
        </>
    )
}

export {
    AdminDashboard,
    AgentDashboard,
    FcmtUniversity,
    CambieUniversity,
    OSHCInsuranceApplicationsBtn,
    UkTuitionFeesApplicationBtn
}
