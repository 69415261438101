import React, { useState } from 'react'
import { Modal, notification, Select } from 'antd'
import InputBox from '../../../components/_utils/InputBox'
import { bankVerifyFxn } from './actions'
import { useDispatch } from 'react-redux'

const BankVerifyModal = (props) => {
  const { visible, onClose, onSubmit, chargeAmount, applications, student } = props
  const { Option } = Select
  let [payCommission, setPayCommission] = useState('pending')
  let [commissionAmount, setCommissionAmount] = useState('')
  let dispatch = useDispatch()

  const handleSubmit = async () => {
    try {
      let obj = {
        applicationId: applications._id,
        studentId: student._id
      }

      if (payCommission) {
        obj.cambieCommissionStatus = payCommission
      }

      if (commissionAmount) {
        obj.cambieCommissionAmount = commissionAmount
      }

      if (!payCommission) {
        notification.warning({
          message: 'Please Choose Pay Commission'
        })
        return
      }

      if (payCommission === 'pending' && !commissionAmount) {
        notification.warning({
          message: 'Please Choose Commission Amount'
        })
        return
      }

      if (commissionAmount > chargeAmount) {
        notification.warning({
          message: 'Commission Amount Cannot Be Greater Than Charge Amount'
        })
        return
      }

      let resp = await dispatch(bankVerifyFxn({ ...obj }))

      if (resp && resp.success) {
        onSubmit()
      }
    } catch (error) {
      console.log(error,'error')
      notification.error({
        message: 'An error occurred while submitting the form',
        description: error.message
      })
    }
  }


  return (
    <>
      <Modal
        visible={visible}
        title='Confirm Bank Payments'
        onCancel={onClose}
        onOk={() => {
          handleSubmit()
        }}
      >

        <InputBox
          title={'Pay Commission *'}
        >
          <Select
            placeholder='Pay Commissions'
            value={payCommission}
            onChange={(value) => {
              setPayCommission(value)
            }}
          >
            <Option key={'notApplicable'} value={'notApplicable'}>Not Applicable</Option>
            <Option key={'pending'} value={'pending'}>Pending</Option>
          </Select>
        </InputBox>
        {payCommission == 'pending' ?
          <InputBox
            title={'Commission Amount (In CAD) *'}
          >
            <input
              value={commissionAmount}
              type={'number'}
              onChange={(e) => {
                setCommissionAmount(e.target.value)
              }}
              className={'form-control'}
            />
          </InputBox> : null
        }

      </Modal>
    </>
  )
}
export default BankVerifyModal
