import React, { useState, useEffect } from 'react';
import { Modal, Select, Button, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { listAllUsers } from "../../users/actions/user";
import { transferAssignUserFxn, getReeudoManagerFxn } from "../actions";

const { Option } = Select;

const AssignReeudoManager = (props) => {
    let { visible, onClose, transactionId } = props;
    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([]);
    const dispatch = useDispatch();

    let getUserList = async () => {
        let resp = await dispatch(getReeudoManagerFxn());
        setUsers(resp);
    }

    useEffect(() => {
        getUserList();
    }, []);

    const handleSubmit = async () => {
        console.log(selectedUser, "selectt agent")
        if (selectedUser) {
            let resp = await dispatch(transferAssignUserFxn(transactionId, {assignedUserId: selectedUser}));
            console.log(resp, "Transfer response");
            if (resp.success) {
                notification.success({message: resp.message})
                onClose();
            } else {
                notification.error({message: resp.message})
            }
        } else {

        }
    }

    return (
        <Modal
            title="Assign Other User"
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            <Select
                placeholder="Select a user"
                onChange={setSelectedUser}
                style={{ width: '100%', marginBottom: 16 }}
            >
                {users && users.map(user => (
                    <Option key={user._id} value={user._id}>
                        {user.name}
                    </Option>
                ))}
            </Select>
            <Button type="primary" onClick={handleSubmit}>
                Submit
            </Button>
        </Modal>
    );
};

export default AssignReeudoManager;
