export default (
  state = {
    loader: false,
    scheduleMeeting: {},
    allScheduleMeeting: [],
    totalScheduleMeeting: 0
  },
  action
) => {
  switch (action.type) {
    case 'LIST_SCHEDULE_MEETING':
      return { ...state, scheduleMeeting: action.payload.data }
    case 'ADD_SCHEDULE_MEETING':
      return { ...state, addedScheduleMeeting: action.payload }
    case 'LIST_ALL_SCHEDULE_MEETING':
      return { ...state, allScheduleMeeting: action.payload.data, totalScheduleMeeting: action.payload.total }
    default:
      return state
  }
}
