import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import {
  addCounsellingUrl,
  receptionDashboardUrl,
  getDashboardCounsellorUrl,
  getEnquiryByUserUrl,
  updateCounsellorAvailabilityUrl,
  counsellingHistoryUrl,
  updateEnquiryUrl,
  stopCounsellorWaitingUrl,
  counsellorCountryWiseUrl,
  receptionDataCountryWiseUrl,
  counsellingListUrl,
  allCounsellingListUrl,
  deleteCounsellingSlotUrl,
  counsellingSlotUrl,
  addStudentCounsellingUrl,
  getSlotbyDateUrl,
  getSlotByCounsellorUrl,
  updateCounsellingSlotUrl,
  changeCounsellingDateUrl,
  getStudentCounsellingUrl,
  bookedCounsellingUrl,
  counsellingSlotListUrl, getSingleCounsellingUrl,
  getStudentByCounsellorUrl,
  addCounsellingByCounsellorUrl,
  addCounsellingFollowuprUrl
} from '../api/counselling'
import { hideLoader, hidePageLoad, showLoader, showPageLoad } from '../../../modules/actions'


export const addCounsellingFxn = (valData) => async (dispatch) => {
  let { data } = await axios.post(addCounsellingUrl(), valData, getToken())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const receptionDashboard = (valData) => async (dispatch) => {
  let { data } = await axios.post(receptionDashboardUrl(), valData, getToken())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getDashboardCounsellor = (valData) => async (dispatch) => {
  let { data } = await axios.post(getDashboardCounsellorUrl(), valData, getToken())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getEnquiryByUser = (valData) => async (dispatch) => {
  let { data } = await axios.post(getEnquiryByUserUrl(), valData, getToken())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const updateCounsellorAvailability = (valData) => async (dispatch) => {
  let { data } = await axios.post(updateCounsellorAvailabilityUrl(), {}, getToken())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

// History
export const counsellingHistory = (valData) => async (dispatch) => {
  let { data } = await axios.post(counsellingHistoryUrl(), valData, getToken())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const updateEnquiry = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateEnquiryUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const stopCounsellorWaitingFxn = () => async (dispatch) => {
  dispatch(showLoader())
  let { data } = await axios.post(stopCounsellorWaitingUrl(), {}, getToken())
  dispatch(hideLoader())

  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const counsellorCountryWiseFxn = async (valData) => {
  let { data } = await axios.post(counsellorCountryWiseUrl(), valData, getToken())
  return data.data
}

export const receptionDataCountryWiseFxn = async (valData) => {
  let { data } = await axios.post(receptionDataCountryWiseUrl(), valData, getToken())
  return data
}
export const counsellingListFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...getToken()
  }
  let { data } = await axios.get(counsellingListUrl(), config)
  dispatch(hidePageLoad())
  return data
}
export const allCounsellingListFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...getToken()
  }
  let { data } = await axios.get(allCounsellingListUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}

export const counsellingSlotFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...getToken()
  }
  let { data } = await axios.get(counsellingSlotUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}


export const addCounsellingSlotFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(counsellingSlotUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const deleteCounsellingSlotFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(deleteCounsellingSlotUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}


export const counsellorListFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())

  let { data } = await axios.post(counsellorCountryWiseUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const addStudentCounsellingFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())

  let { data } = await axios.post(addStudentCounsellingUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const getSlotbyDatefxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())

  let { data } = await axios.post(getSlotbyDateUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const getSlotByCounsellorFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())

  let { data } = await axios.post(getSlotByCounsellorUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const updateCounsellingSlotFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateCounsellingSlotUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const changeCounsellingDateFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())

  let { data } = await axios.post(changeCounsellingDateUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const getStudentCounsellingFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...getToken()
  }
  let { data } = await axios.get(getStudentCounsellingUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const bookedCounsellingFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  let { data } = await axios.get(bookedCounsellingUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const counsellingSlotListFxn = (valData) => async (dispatch) => {
  let { data } = await axios.post(counsellingSlotListUrl(), valData, getToken())
  return data
}
export const getSingleCounsellingFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(getSingleCounsellingUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const getStudentByCounsellorFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...getToken()
  }
  let { data } = await axios.get(getStudentByCounsellorUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}

export const addCounsellingByCounsellorFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addCounsellingByCounsellorUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
export const addCounsellingFollowupFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addCounsellingFollowuprUrl(), valData, await getToken())

  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
export const CounsellingFollowupListFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  let { data } = await axios.get(addCounsellingFollowuprUrl(), config)
  dispatch(hidePageLoad())
  return data
}
