import React, { useState, useEffect } from 'react'
import { Tooltip, Popconfirm } from 'antd'
import '../../../less/aggrement.less'
import { getUser, signeAgreementFxn } from '../actions/user'
import { useDispatch } from 'react-redux'
import { displayDate } from '../../../components/_utils/appUtils'

let path = `https://dataapi.unizportal.com/files/unizPortal_agreement.pdf`
const Agreement = (props) => {
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  let [isLoaded, setIsLoaded] = useState(false)
  let [userData, setUserData] = useState({})
  let dispatch = useDispatch()
  useEffect(() => {
    loadLoginUser()
  }, [])

  const loadLoginUser = async () => {
    let resp = await dispatch(getUser(user._id))
    setUserData(resp)
  }

  const signAgreementNow = async () => {
    let resp = await dispatch(signeAgreementFxn({ userId: user._id }))
    if (resp && resp.success) {
      loadLoginUser()
    }
  }


  return (
    <div>
      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="card">
            <div className="table-head d-flex align-items-center all-student-search">

              <div>
                <h4 className={'header1'}>Recruiter Terms and Conditions</h4>
                <h6>Last Updated: June 28, 2021</h6>
              </div>

              <div className="search-box-table round">

              </div>

              <div className="sort-box-table mark-btn">
                <a className="btn" href={path} download target="_blank">Download</a>
              </div>
            </div>

            <div className="card-body table-responsive aggreementCard">
              {/*<h4 className={'header1'}>Recruiter Terms and Conditions</h4>
              <h6>Last Updated: June 28, 2021</h6>*/}
              <p className={'mt20'}>
                These “Terms and Conditions/Agreement” govern your use of our services, in addition to the General Terms
                and
                Conditions available at <a
                href={'https://unizportal.com/terms-condition'}>https://unizportal.com/terms-condition</a>. By using our
                services, you accept these
                terms and conditions in full.
              </p>
              <p>Uniz Portal reserves the right to alter these terms and conditions. Except where prohibited by
                applicable law, we
                may change the Terms and Conditions at any time by posting a new version to the Uniz Portal Platform.
                You are
                responsible for monitoring the Uniz Portal Platform for any such changes and for reviewing such changes.
                Unless otherwise agreed in writing by a Director of Uniz Portal, these Terms and Conditions shall
                prevail over any
                other terms of business or conditions put forward by you. No variation or alteration of these Terms and
                Conditions provided by you shall be valid unless approved in writing mutually by you and Uniz
                Portal.</p>
              <p>These terms and conditions between us for the supply of services and consultancy to the client are
                deemed to
                be accepted by Recruiters by virtue of signing these Terms and Conditions, or by acting upon it.</p>
              <p>These Terms and Conditions and (any documents referred to in it) constitutes the entire agreement
                between the
                parties and supersedes and extinguishes all previous drafts, arrangements, understandings or agreements
                between them, whether written or oral, relating to the subject matter of this Agreement.</p>
              <p>If any provision of this Agreement is, or becomes, to any extent illegal, invalid or unenforceable,
                then it is the
                intention of the parties that this Agreement shall be deemed amended in a reasonable manner to the
                extent
                necessary to render it enforceable without losing its intent, or at the discretion of the parties or the
                determination of the Court, such provisions shall, to the extent of the unenforceability, be deemed not
                to form
                part of the Terms and Conditions. In either case, such provisions will not affect the legality, validity
                or
                enforceability of any other provision of these Terms and Conditions, which shall continue in full force
                and effect.</p>
              <p>These Terms and Conditions are available on our website <a
                href={'https://unizportal.com'}>https://unizportal.com</a> and have the same effect
                as if
                each party had signed these Terms and Conditions. </p>
              <p>Each party acknowledges that, by agreeing to these Terms and Conditions and the documents referred to
                in it, it
                does not rely on, and shall have no remedies in respect of, any representation or warranty (whether made
                innocently or negligently) that is not set out in these Terms and Conditions or those documents. Each
                party
                agrees that its only liability in respect of those representations and warranties that are set out in
                these Terms
                and Conditions or those documents (whether made innocently or negligently) shall be for breach of
                contract.
                Nothing in this Clause shall limit or exclude any liability for fraud.</p>
              <p>These Terms and Conditions and any dispute, claim, payment or obligation (whether contractual or
                noncontractual) arising out of or in connection with it, its subject matter or formation shall be
                governed by Indian
                Law. Both parties submit to the exclusive jurisdiction of the Indian Courts. </p>
              <p>
                <ol>
                  <li>
                    <b>Access to unizportal.com and Services</b>
                    <br/>
                    You must subscribe with Uniz Portal to access unizportal.com and Services.
                    <p className={'mt20'}>
                      You acknowledge and agree that:
                    </p>
                    <ul className={'alpha'}>
                      <li>your use and access to unizportal.com and the Services is subject to your successful
                        registration
                        with Uniz Portal, Recruiter Approval by Uniz Portal, and your compliance with this Agreement;
                      </li>
                      <li>
                        to Subscribe and maintain your Subscription with Uniz Portal, you must:
                        <br/>
                        <p className={'mt20'}>
                          <ul className={'roman'}>
                            <li>provide true, accurate, complete and up to date information as required by Uniz Portal
                              from time to time; and
                            </li>
                            <li>
                              your failure to provide true, accurate, complete and up to date information will result in
                              your inability to Subscribe, Restricted Access or the cancellation, termination or
                              suspension of your Subscription;
                            </li>
                          </ul>
                        </p>
                      </li>
                      <li>
                        you must comply with all reasonable requests by Uniz Portal to verify your identity;
                      </li>
                      <li>
                        in order for Uniz Portal to ensure the integrity of unizportal.com and Services, Uniz Portal
                        reserves
                        all its rights, in its sole and absolute discretion, to reject or refuse a Subscription, or
                        otherwise
                        suspend, cancel and deregister any person from unizportal.com for any reason whatsoever. Uniz
                        Portal is not required to provide any reason, explanation or clarification as to your rejection
                        or
                        refusal of any Subscription, or the suspension, cancellation or termination of any person.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>Student Consent</b> - You are responsible for obtaining signed personal information consents from
                    the
                    students allowing you to share information with us and the Uniz Portal Partners and authorizing you
                    to
                    deal with the Uniz Portal Partners on behalf of the Students.
                  </li>
                  <li>
                    <b>Non-Solicitation and Non-Compete </b>- During the Term and for 18 months after these Terms and
                    Conditions are terminated or expire, in the countries in which you have performed the
                    Recruitment/Counseling Services, you must not create a platform to compete with the Uniz Portal
                    Platform or solicit any Uniz Portal Partner to use products or services that directly compete with
                    the
                    Uniz Portal Services, without Uniz Portal’s prior written consent (unless you had a prior agreement
                    in
                    writing with an Uniz Portal Partner that was signed and brought to our attention prior to signing
                    this
                    agreement).
                  </li>
                  <li>
                    <b>Confidentiality</b> – Confidentiality duty is absolute. To the extent authorized by the law, the
                    parties may
                    wish, from time to time, in connection with work contemplated under these Terms and Conditions, to
                    disclose confidential information to each other (“Confidential Information”). Each party will use
                    reasonable efforts to prevent the disclosure of any of the other party’s Confidential Information or
                    Confidential Information that was shared by the other party to third parties for a period of three
                    (3) years
                    after the termination of this Agreement, provided that the recipient party’s obligation shall not
                    apply to
                    information that:
                    <ul className={'alpha mt20'}>
                      <li>is already in the recipient party’s possession at the time of disclosure thereof;</li>
                      <li>is or later becomes part of the public domain through no fault of the recipient party;</li>
                      <li>is received from a third party having no obligations of confidentiality to the disclosing
                        party;
                      </li>
                      <li>is independently developed by the recipient party; or</li>
                      <li>is required by law or regulation to be disclosed.</li>
                    </ul>
                    <p className={'mt20'}>
                      In the event that information is required to be disclosed pursuant to subsection e. and to the
                      extent
                      authorized by the law, the party required to make disclosure shall notify the other to allow that
                      party to
                      assert whatever exclusions or exemptions may be available to it under such law or regulation.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Limitation of Liability</b> - In our recruitment partner relationship, we will generally not
                      have any liability
                      to you. Our maximum liability to you, if any, will not exceed INR 20,000/-
                    </p>
                    <p>
                      Neither party will be liable for breach-of-contract damages that are remote or speculative, or
                      that the
                      breaching party could not reasonably have foreseen on agreeing upon these Terms and Conditions.
                    </p>
                  </li>
                  <li>
                    <b>Term</b> - The period of this Agreement shall be deemed to have commenced as of the date of
                    signing and
                    shall continue for a period of twelve (12) full calendar months thereafter (“initial period”).
                    Commencing
                    on the first anniversary date of this Agreement and continuing at each anniversary date thereafter,
                    the
                    Agreement will automatically renew for subsequent 12-month terms, unless terminated or agreed
                    otherwise. The Parties may review the Agreement and performance annually for purposes of
                    determining, within its sole discretion, whether to extend this Agreement.
                  </li>
                  <li>
                    <b>Termination</b> - - Either party will have the right to terminate the contract by giving written
                    notice to the
                    other party at least 3 months before the end of the initial period of the contract or at least 30
                    days at any
                    point after the end of the initial period.

                    <p className={'mt20'}>
                      Either party may terminate the Agreement by written notice to the other at any time if the other
                      party:
                    </p>
                    <ul className={'alpha'}>
                      <li>commits a breach of this Agreement and, in the case of a breach capable of remedy, fails to
                        remedy the breach within 14 days of being required to do so in writing; or
                      </li>
                      <li>becomes insolvent, or has a liquidator, receiver, manager or administrative receiver
                        appointed.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p><b>Privacy and Terms of Use</b> – By entering into this Agreement, it is your responsibility and
                      we assume
                      that you have carefully gone through our Privacy Policy, Terms of Use and the General Terms and
                      Conditions available on our website <a
                        href={'https://unizportal.com/home'}>https://unizportal.com/home</a>. </p>
                    <p>
                      By providing us with any required registration and/or personal information, you agree that we may
                      perform verification and screening services and will hold your information in accordance with our
                      privacy policy (found at https://unizportal.com/privacy-policy) and data governance practices as a
                      data
                      controller.
                    </p>
                  </li>
                  <li>
                    <b>Force Majeure</b> - Neither party will be liable to the other for any failure to meet its
                    obligations under
                    these Terms and Conditions where such failure is caused by events beyond its reasonable control such
                    as failure of communications networks, inability to timely obtain instructions or information from
                    the
                    other party, governmental action, fire, storms, epidemics, pandemics, floods or other acts of God,
                    provided that the party seeking to rely on such circumstances gives written notice of such
                    circumstances to the other party hereto and uses reasonable efforts to overcome such circumstances
                  </li>
                  <li>
                    <b>Notices</b> - Notices will be only validly served pursuant to these Terms and Conditions where
                    the notice
                    has been sent by tracked delivery and/or by email. Any notice provided to Us pursuant to these Terms
                    and Conditions shall be sent to our address at Uniz Portal Private Limited, SCO 387, MUGAL CANAL
                    KARNAL, Karnal HR 132001 IN Attn: Chief Executive Officer, copy: Head of Compliance, email at
                    &nbsp;<a href={'mailto:info@unizportal.com'}>info@unizportal.com</a>
                  </li>
                  <li>
                    <b>Recruiter responsibilities:</b>
                    <ul className={'alpha mt20'}>
                      <li>you must act in good faith at all times towards Uniz Portal;</li>
                      <li>you must keep up to date, be aware of, and comply with, all applicable Laws in or that affect
                        the
                        jurisdiction you reside or conduct business, in your use of https://unizportal.com and the
                        Services,
                        and all other services you provide to Students;
                      </li>
                      <li>you must perform an initial assessment on all Students using the Uniz Portal guidelines, as
                        amended from time to time, to determine whether the Student qualifies as a suitable candidate
                        and meets the Eligibility Criteria;
                      </li>
                      <li>you must maintain and keep current all Authorizations to enable you to lawfully provide
                        recruitment or education advisory services to Students, including any immigration or visa
                        related
                        advice; and
                      </li>
                      <li>promptly inform Uniz Portal of any information, matter, situation, circumstance or thing that
                        may
                        cause or will cause an impact on or in any way change your use of Uniz Portal and the Services.
                      </li>
                      <li>not engage in a direct relationship with the student and keep Uniz Portal informed and part of
                        the
                        entire process.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      <b>Intellectual Property Rights</b> – unizportal.com is owned by Uniz Portal, its related bodies
                      corporate
                      and/or its third party licensors. You acknowledge Uniz Portal’s title to and interest in
                      unizportal.com.
                    </p>
                    <p>
                      All Intellectual Property Rights arising out of or in connection with unizportal.com and services
                      are
                      owned by Uniz Portal, its related bodies corporate and/or its third party licensors. You
                      acknowledge
                      Uniz Portal’s title to and interest in the Intellectual Property Rights.
                    </p>
                  </li>
                  <li>
                    <b>Indemnity</b> - To the fullest extent permitted by Law, you agree to release, hold harmless and
                    indemnify
                    Uniz Portal from and against any claims, suits, liabilities, damages, injuries, costs, expenses
                    (including
                    indirect and consequential loss) whatsoever and however arising out of or in connection with:
                    <ul className={'alpha mt20'}>
                      <li>your use or access to, or misuse of unizportal.com or Services;</li>
                      <li>your breach or non-compliance with any terms of this Agreement;</li>
                      <li>your violation, infringement or contravention of the Facebook Terms;</li>
                      <li>your violation, infringement or contravention of the Other Social Media Terms;</li>
                      <li>your violation, infringement or contravention of any third party rights; and</li>
                      <li>your infringement or noncompliance with any applicable Law.</li>
                    </ul>
                  </li>
                  <li>
                    <b>Language</b> - This Agreement shall only be produced in the English language. If English is not
                    your first
                    language, it is your responsibility to have this Agreement translated by a professional translator.
                    Uniz
                    Portal shall not be held responsible for any errors, omissions or misunderstandings arising from or
                    as a
                    result of any translation of this Agreement.
                  </li>
                  <li>
                    <b>Suspension</b> - Notwithstanding your acknowledgement of the Agreement, you will be subject to
                    Recruiter Approval. When Uniz Portal is undertaking Recruiter Approval, Uniz Portal will grant you a
                    nonexclusive, non-transferable, revocable and personal licence to obtain Restricted Access. Failure
                    to:
                    <ul className={'alpha mt20'}>
                      <li>meet the Recruiter Criteria;</li>
                      <li>provide true, accurate, complete and up to date information;</li>
                      <li>comply with any lawful and reasonable request by Uniz Portal for information,</li>
                    </ul>
                    <p className={'mt20'}>
                      may result in the suspension, cancellation or termination of your Restricted Access to
                      unizportal.com.
                    </p>
                  </li>
                  <li>
                    <b>Commission</b>
                    <p>The Recruiter is entitled to receive Commission on successful placement of a Student with an
                      Institution Partner where Commission is made available by a participating Institution Partner
                      (Participating Institution Partner). Uniz Portal shall publish a list of Participating Institution
                      Partners,
                      which may be amended from time to time. For the avoidance of any doubt, no Commission will be
                      payable by an Institution Partner not listed as a Participating Institution Partner.</p>
                    <p>For every successful application, the commission paid to you will be shown on the dashboard of
                      the
                      Uniz Portal Platform and due to exchange rates and other factors could end up being higher or
                      lower
                      than the estimate shown on the dashboard. </p>
                    <p>You acknowledge Uniz Portal will not be responsible for any bank fees or currency conversion fees
                      incurred by your bank. Should Uniz Portal be charged any such fee, you authorise Uniz Portal to
                      deduct
                      this from the Commission or the Inactive Commission.</p>
                    <p>You acknowledge that if you are a company incorporated in Singapore, you must notify Uniz Portal
                      in writing immediately if:
                      <ul className={'alpha mt20'}>
                        <li>your GST registration is cancelled; or</li>
                        <li>you are issued with a new GST number.</li>
                      </ul>
                    </p>
                    <p>
                      <ul>
                        <li>In case student does not enroll, does not pay the full fees, documents submitted are found
                          fake
                          or held
                          by the university for any reason whatsoever, Uniz Portal will not be responsible to pay any
                          commission
                          to the Recruiter
                        </li>
                      </ul>
                    </p>
                  </li>
                  <li>
                    <b>Contact</b>
                    <p>
                      Any questions, complaints or other concerns in relation to this Agreement should be directed to
                      Uniz
                      Portal:
                    </p>
                    <p>
                      telephone: <a href={'tel:74088-74088'}>74088-74088</a><br/>
                      email: <a href={'mailto:info@unizportal.com'}>info@unizportal.com</a>
                    </p>
                  </li>
                </ol>
              </p>

              {/* <p>
                <b>Your acceptance to these Terms and Conditions is confirmed by your signature in the box below. </b>
              </p>*/}

            </div>
          </div>
          {userData && userData.agreementSigned ?
            <div className={'alignCenter'}>
              <div>
                Signed on <span className={'themeColor'}>{displayDate(userData.agreementSignedDate)}</span>
              </div>
              <div>
                Signed by <span className={'themeColor'}>{userData.name}</span>
              </div>
              <div>
                Contact No <span className={'themeColor'}>{userData.mobile}</span>
              </div>
              <div>
                Email <span className={'themeColor'}>{userData.email}</span>
              </div>
              <br/>
              <Tooltip title={'Agreement Signed'}>
                <a className={'bs_btn bs-success'}>Signed</a>
              </Tooltip>
            </div> :
            <div className={'alignCenter'}>
              <Popconfirm title={`Are your sure, you want to Sign Agreement?`}
                          onConfirm={() => {
                            signAgreementNow()
                          }}
                          okText="Yes" cancelText="No">
                <a className={'bs_btn bs-success'}>Sign Now</a>
              </Popconfirm>
            </div>}
        </div>
      </div>


    </div>
  )
}
export default Agreement