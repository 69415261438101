import React, {useState} from "react"
import {Button, Drawer, notification} from "antd";
import InputBox from "../../../components/_utils/InputBox";
import {useDispatch} from "react-redux";
import {addCallInWithdrawalFxn, addReceiptInWithdrawalFxn, generateInvoiceFxn} from "../actions";

let invoiceAddressList = ["Unizportal Pvt. Ltd.", "UNIZPORTAL - FZCO"]
let verifiedOptions = ['Verified', "Not Verified"]

let initState = {
  currencyRate: "",
  receipt: "",
  invoiceAddress: "UNIZPORTAL - FZCO"
}
const GenerateInvoiceDrawer = (props) => {
  let dispatch = useDispatch()
  let [state, setState] = useState(initState);
  let {visible, onClose, withdrawalId} = props;

  let events = {
    updateState: (data) => {
      setState((prevState) => {
        return {
          ...prevState,
          ...data
        }
      })
    },
    submitCurrencyRate: async () => {
      let {currencyRate, invoiceAddress} = state;
      if (!invoiceAddress) {
        notification.warning({message: "Please choose invoice address."})
        return
      }
      if (invoiceAddress == "Unizportal Pvt. Ltd." && !currencyRate) {
        notification.warning({message: "Please enter currency rate."})
        return
      }
      let {data, success} = await dispatch(generateInvoiceFxn({currencyRate, withdrawalId, invoiceAddress}))
      if (success) {
        events.updateState(initState);
        onClose()
      }
    }
  }


  return (
    <Drawer
      visible={visible}
      title={`Generate Invoice`}
      onClose={onClose}
      width={'50%'}
    >
      <div className="form-box commissionBox">
        <div className="card unizportal">
          <InputBox title={'Invoice Address'}>
            <select className={'form-control'}
                    placeholder={'Choose Invoice Address'}
                    onChange={({target}) => {
                      events.updateState({invoiceAddress: target.value})
                    }}
                    value={state.invoiceAddress}
                    disabled={true}>
              <option value={""}>Choose Invoice Address</option>
              {invoiceAddressList.map((item) => {
                return (
                  <option value={item} key={item}>{item}</option>
                )
              })}
            </select>
          </InputBox>
          {state.invoiceAddress == "Unizportal Pvt. Ltd." ?
            <InputBox title={'Currency Rate'}>
              <input
                type={'number'}
                className={'form-control'}
                placeholder={'Enter Currency Rate'}
                onChange={({target}) => {
                  events.updateState({currencyRate: target.value})
                }}
                value={state.currencyRate}>
              </input>
            </InputBox> : null}
          <Button
            onClick={events.submitCurrencyRate}
            type="primary"
            className={`btn btn-default mt10`}>
            Generate Invoice
          </Button>
        </div>
      </div>
    </Drawer>
  )
}
export default GenerateInvoiceDrawer
