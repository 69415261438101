import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Drawer,
  Form,
  notification,
  Row,
  Select
} from 'antd'
import { useDispatch } from 'react-redux'
import '../style.css'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import '../../../../src/common.less'
import {
  addFeedback,
  singleFeedbackFxn,
  updateFeedbackFxn
} from '../actions/feedbackActions'
import { ConditionOptions, ConditionOptionsForRaiseAComplaint, InputBox } from '../../../components/_utils/appUtils'
import { complaintDepartmentListFxn } from '../../ComplaintDepartement/actions'
import moment from 'moment'
import debounce from 'lodash/debounce'
import { searchedStudent } from '../../student/actions/student'
import { updateTravelPlanFxn } from '../../travelPlan/actions'
import _ from 'lodash'

const { Option } = Select
const initialState = {
  studentList: [],
  studentId: '',
  dateOfBirth: '',
  addStuDrawer: false,
  details: '',
  studentName: '',
}

const EditFeedback = (props) => {
  let {
    onClose,
    visible,
    feedbackId,
    onSubmit,
    form: { getFieldDecorator, getFieldValue, setFieldsValue }
  } = props

  let [allComplaintDepartment, setAllComplaintDepartment] = useState([])
  const [state, setState] = useState(initialState)
  console.log(state,"state")
  const [studentData, setStudentData] = useState(initialState)
  const dispatch = useDispatch()

  useEffect(() => {
    if (state.studentId) {
      const filteredStudent = state.studentList.find(student => student._id === state.studentId)
      setStudentData(filteredStudent)
    }
  }, [state.studentId])

  const loadComplaintDepartment = async () => {
    let { data } = await dispatch(complaintDepartmentListFxn())
    setAllComplaintDepartment(data)
  }

  useEffect(() => {
    loadComplaintDepartment()
  }, [])

  const fetchFeedbackData = async () => {
    try {
      const { data } = await dispatch(singleFeedbackFxn({ feedbackId }))
      setFieldsValue({
        name: data.name,
        designation: data.designation,
        contactNo: data.contactNo,
        relatedTo: data.relatedTo,
        feedback: data.feedback,
        department: data.department
      })
      if (data.relatedTo === 'student' && data.studentId && data.studentId._id) {
        setFieldsValue({
          studentId: data.studentId._id
        })
        fetchStudent(data.studentId.name)
        setTimeout(() => {
          setState((prevState) => {
            console.log(prevState,"previous state")
            return {
              ...prevState,
              studentName: data.studentId.name,
              studentId: data.studentId._id
            }
          })
        }, 1000)
      } else {
        setTimeout(() => {
          setFieldsValue({
            addSpecificPerson: data.addSpecificPerson
          })
          if (data.personName) {
            setFieldsValue({
              personName: data.personName
            })
          }
        }, 500)
      }

    } catch (error) {
      console.error('Error fetching institute event data:', error)
    }
  }

  const fetchStudent = async (value) => {
    let studentList = await searchedStudent({ name: value })
    setState({
      ...state,
      studentList
    })
  }

  useEffect(() => {
    fetchFeedbackData()
  }, [feedbackId])

  const chooseStudentFxn = (value) => {
    let findStudent = _.find(state.studentList, (item) => {
      return item._id == value
    })
    let obj = {
      ...state,
      studentId: value
    }
    if (findStudent) {
      obj.studentName = findStudent.name
    }
    setState(obj)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    props.form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        if (state.studentId) {
          valData.studentId = state.studentId
        }
        if (valData.relatedTo === 'student' && !valData.studentId) {
          notification.warning({ message: 'Please Choose Student!' })
          return
        }
        valData.feedbackId = feedbackId
        let data = await dispatch(updateFeedbackFxn(valData))
        if (data && !data.error) {
          onSubmit()
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  const formFields = [
    {
      key: 'department',
      label: 'Department',
      required: true,
      type: 'select',
      showSearch: true,
      options: allComplaintDepartment,
      keyAccessor: x => x._id,
      valueAccessor: x => `${x.department}`,
      onChange: x => {
        setFieldsValue({
          department: x
        })
      }
    },
    {
      key: 'relatedTo',
      label: 'Is it related to?',
      required: true,
      type: 'select',
      showSearch: true,
      options: ConditionOptionsForRaiseAComplaint,
      keyAccessor: x => x.value,
      valueAccessor: x => `${x.name}`,
      onChange: x => {
        // setState(initialState)
        setFieldsValue({
          relatedTo: x
        })
      }
    },
    {
      key: 'studentId',
      span: 8,
      hidden: getFieldValue('relatedTo') !== 'student',
      customField: (
        <InputBox title={'* Select Student'}>
          <Select
            showSearch={true}
            onSearch={debounce(fetchStudent, 500)}
            filterOption={false}
            autoClearSearchValue={true}
            allowClear={true}
            showArrow={true}
            value={state.studentName}
            onChange={chooseStudentFxn}
            optionLabelProp='label'
            placeholder='Choose Student'
          >
            {state.studentList && state.studentList.length && state.studentList.map((item, index) => {
              return (
                <Option key={item._id} value={item._id} label={item.name}>
                  <div>
                    <div>
                      {item.studentId} : {item.name}
                    </div>
                    <div>
                      {item.email}
                    </div>
                  </div>
                </Option>
              )
            })}
          </Select>
        </InputBox>
      )
    },
    {
      key: 'addSpecificPerson',
      hidden: getFieldValue('relatedTo') === 'student' || getFieldValue('relatedTo') === undefined,
      required: getFieldValue('relatedTo') === 'complain_a_misbehaviour' || getFieldValue('relatedTo') === 'others',
      label: 'Do you want to add a specific person\'s name?',
      type: 'select',
      showSearch: true,
      options: ConditionOptions,
      keyAccessor: x => x.value,
      valueAccessor: x => `${x.name}`,
      onChange: x => {
        setFieldsValue({
          addSpecificPerson: x
        })
      }
    },
    {
      key: 'personName',
      label: 'Person Name',
      hidden: !getFieldValue('addSpecificPerson'),
      required: getFieldValue('addSpecificPerson')
    },
    {
      key: 'name',
      label: 'Your Name',
      required: true
    },
    {
      key: 'designation',
      label: 'Your Designation',
      required: true
    },
    {
      key: 'contactNo',
      label: 'Your Contact',
      required: true
    },
    {
      key: 'feedback',
      label: 'Complaint',
      type: 'textarea',
      required: true,
      span: 24
    }
  ]

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  return (
    <Drawer
      visible={visible}
      title={`Edit Feedback`}
      onClose={onClose}
      width={'80%'}
    >
      <Form onSubmit={handleSubmit} className='vertical-form'>
        <div className='form-box'>
          <div className='card unizportal'>
            <Row gutter={24}>
              {formFields.map((item, key) => (
                <React.Fragment key={key}>
                  {!item.hidden && item.customField ? (
                    <Col span={item.span ? item.span : 8}>
                      {item.customField}
                    </Col>
                  ) : (
                    !item.hidden && (
                      <Col span={item.span ? item.span : 8}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout}
                        />
                      </Col>
                    )
                  )}
                </React.Fragment>
              ))}
            </Row>
            {studentData && studentData._id && (
              <Row gutter={24}>
                <Col span={18}>
                  <Form.Item>
                    <div className={'student-details'} style={{ lineHeight: '1' }}>
                      <h4>Student Data</h4>
                      <p className={'student-info'}>
                        <b>Name :</b> {studentData.name}
                      </p>
                      <p className={'student-info'}>
                        <b>Student Id :</b> {studentData.studentId}
                      </p>
                      <p className={'student-info'}>
                        <b>Date Of Birth :</b> {moment(studentData.dateOfBirth).format('DD/MM/YYYY')}
                      </p>
                      <p className={'student-info'}>
                        <b>Number :</b> {studentData.mobile}
                      </p>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row gutter={24}>
              <Col span={18}>
                <Form.Item>
                  <Button type='primary' htmlType='submit' className='btn'>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </Drawer>
  )
}

const WrappedEditComplaintDrawer = Form.create()(EditFeedback)
export default WrappedEditComplaintDrawer
