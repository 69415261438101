import {apiUrl} from '../../../settings'

export const addCountryUrl = () => {
    return apiUrl + '/icef/addCountry'
}
export const countryListUrl = () => {
    return apiUrl + '/icef/country'
}
export const addUniversityUrl = () => {
    return apiUrl + '/icef/addUniversity'
}
export const universityListUrl = () => {
    return apiUrl + '/icef/university'
}
export const addIcefEventUrl = () => {
    return apiUrl + '/icef/addEvent'
}
export const icefEventListUrl = () => {
    return apiUrl + '/icef/events'
}
export const updateIcefEventUrl = () => {
    return apiUrl + '/icef/updateEvent'
}
export const addIcefMeetingUrl = () => {
    return apiUrl + '/icef/addMeeting'
}
export const icefMeetingListUrl = () => {
    return apiUrl + '/icef/meetings'
}
export const updateIcefMeetingUrl = () => {
    return apiUrl + '/icef/updateMeeting'
}

export const icefMeetingGroupUrl = () => {
  return apiUrl + '/icef/icefMeetingList'
}

export const updateIcefMeetingStatusUrl = () => {
  return apiUrl + '/icef/updateIcefMeetingStatus'
}

export const addIcefEventNewUrl = () => {
  return apiUrl + '/icef/addEventNew'
}

export const icefCompanyListUrl = () => {
  return apiUrl + '/icef/company-list'
}

export const icefAddParticipantUrl = () => {
  return apiUrl + '/icef/add-participant'
}

export const icefAddCommentUrl = () => {
  return apiUrl + '/participant-comment/add'
}

export const icefCommentListUrl = () => {
  return apiUrl + '/participant-comment/list'
}

export const icefParticipantHistoryUrl = () => {
  return apiUrl + '/participant-history/list'
}

export const icefParticipantListUrl = () => {
  return apiUrl + '/participant/list'
}
export const singleParticipantUrl = () => {
  return apiUrl + '/single-participant'
}

export const icefEditParticipantUrl = () => {
  return apiUrl + '/icef/update-participant'
}