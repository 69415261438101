import React, { useEffect, useState } from 'react'
import { Button, Drawer, Select, Form, notification, Row, Col, Input, Icon, AutoComplete } from 'antd'
import { InputBox } from '../../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import {
  addIcefMeetingFxn,
  icefCountryListFxn,
  icefUniversityListFxn,
  icefEventListFxn,
  icefCompanyListFxn,
  icefAddParticipantFxn
} from '../../actions'
import AddCountryDrawer from '../../country/addCountryDrawer'
import AddUniversityDrawer from '../../university/addUniversityDrawer'
import AddEventDrawer from '../../events/addEventDrawer'
import { LoadAllCountry, LoadCity, LoadState } from '../../../../components/_utils/countryUtil'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import './style.css'

const meetingTypeList = [
  'Agent',
  'Educator',
  'Service Provider'
]
let { Option } = Select
let initState = {
  countryId: undefined,
  universityId: undefined,
  universityName: undefined,
  website: '',
  firstName: '',
  lastName: '',
  email: '',
  eventId: '',
  meetingType: undefined,
  companyId: undefined,
  companyName: undefined,
  countryName: '',
  contract: undefined,
  stateName: '',
  cityName: '',
  pincode: '',
  mobile: '',
  description: '',
  department: '',
  position: ''
}

let initListState = {
  allCountries: LoadAllCountry(),
  allStates: [],
  allCities: []
}
const salutationList = [
  'Mr.',
  'Mrs.',
  'Ms',
  'Dr.',
  'Prof.'
]

let timeout

function fetch(companyName, dispatch, callback) {
  if (timeout) {
    clearTimeout(timeout)
    timeout = null
  }

  async function fake() {
    let obj = {
      results: 20,
      count: 20,
      companyName: companyName,
      regExFilters: ['companyName']
    }
    let { data } = await dispatch(icefCompanyListFxn(obj))
    callback(data.data)
  }

  timeout = setTimeout(fake, 1000)
}


const AddParticipants = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, onSubmit, selectedEventId = '' } = props
  let [state, setState] = useState(initState)
  let [countryList, setCountryList] = useState([])
  let [universityList, setUniversityList] = useState([])
  let [companyList, setCompanyList] = useState([])
  let [eventList, setEventList] = useState([])
  let [visibleAddEvent, setVisibleAddEvent] = useState(false)
  let [visibleAddCountry, setVisibleAddCountry] = useState(false)
  let [visibleAddUniversity, setVisibleAddUniversity] = useState(false)
  let [listState, setListState] = useState(initListState)
  const [selectedCompanyDetails, setSelectedCompanyDetails] = useState(null)
  const history = useHistory()

  useEffect(() => {
    events.loadCountry()
    events.eventList()
    events.setDefaultState()
  }, [])

  useEffect(() => {
    events.loadUniversity()
  }, [state.countryId])

  const handleEventClick = () => {
    history.push('/icef/addIcefEvents')
  }
  const events = {
    loadCountry: async () => {
      let obj = {
        results: 1000,
        count: 1000
      }
      let { data } = await dispatch(icefCountryListFxn(obj))
      setCountryList(data)
    },
    loadUniversity: async () => {
      let obj = {
        results: 1000,
        count: 1000,
        countryId: state.countryId
      }
      let { data } = await dispatch(icefUniversityListFxn(obj))
      setUniversityList(data)
    },

    _updateState: (data) => {
      setState((prevState) => {
        return {
          ...prevState,
          ...data
        }
      })
    },
    eventList: async () => {
      let searchParams = new URLSearchParams(window.location.search)
      let id = searchParams.get('id')
      let obj = {
        sortField: '_id',
        sortOrder: 'descend',
        results: 1000,
        count: 1000
      }
      let { data } = await dispatch(icefEventListFxn({
        ...obj
      }))
      setEventList(data)
      if (id) {
        events._updateState({ eventId: id })
      }
    },
    setDefaultState: () => {
      if (selectedEventId) {
        events._updateState({ eventId: selectedEventId })
      }
    },
    hideAddCountry: () => {
      setVisibleAddCountry(false)
    },
    hideAddUniversity: () => {
      setVisibleAddUniversity(false)
    },
    hideAddEvent: () => {
      setVisibleAddEvent(false)
    },
    refreshAddCountry: () => {
      setVisibleAddCountry(false)
      events.loadCountry()
    },
    refreshAddUniversity: () => {
      setVisibleAddUniversity(false)
      events.loadUniversity()
    },
    refreshAddEvent: () => {
      setVisibleAddEvent(false)
      events.eventList()
    },
    chooseCountry: (name) => {
      if (name || name === '') {
        const selectedCountry = Array.isArray(name) ? name[0] : name
        const countryExists = countryList.find(country => country.countryName === selectedCountry)
        if (countryExists) {
          setState(prevState => ({
            ...prevState,
            countryId: countryExists._id,
            countryName: countryExists.countryName,
            stateName: '',
            cityName: ''
          }))
          setListState(prevState => ({
            ...prevState,
            allStates: countryExists._id ? LoadState(countryExists._id) : []
          }))
        } else {
          const newCountryObject = {
            countryName: selectedCountry,
            countryId: null,
            _id: null
          }
          setState(prevState => ({
            ...prevState,
            ...newCountryObject,
            stateName: '',
            cityName: ''
          }))
          setListState(prevState => ({
            ...prevState,
            allStates: []
          }))
        }
      } else {
        setState(prevState => ({
          ...prevState,
          countryId: null,
          countryName: '',
          stateName: '',
          cityName: ''
        }))
        setListState(prevState => ({
          ...prevState,
          allStates: []
        }))
      }
    },
    chooseCompany: (name) => {
      if (name || name === '') {

        const selectedCompany = Array.isArray(name) ? name[0] : name
        // console.log('Selected Company (Single):', selectedCompany)

        const companyExists = companyList.find(company => company.companyName === selectedCompany)
        // console.log('Company Exists:', companyExists)

        if (companyExists) {
          setSelectedCompanyDetails(companyExists)
          setState(prevState => ({
            ...prevState,
            companyId: companyExists._id,
            companyName: companyExists.companyName

          }))
        } else {
          setSelectedCompanyDetails(null)

          const newCompanyObject = {
            companyName: selectedCompany,
            companyId: null

          }
          setSelectedCompanyDetails(null)

          setState(prevState => ({
            ...prevState,
            ...newCompanyObject
          }))
        }
      } else {
        setSelectedCompanyDetails(null)

        setState(prevState => ({
          ...prevState,
          companyId: null,
          companyName: ''

        }))
      }
    },


    chooseState: (name) => {
      let stateName = _.find(listState.allStates, (item) => {
        return item.name == name
      })
      if (stateName) {
        setState((prevState) => {
          return {
            ...prevState,
            stateName: stateName.name ? stateName.name : '',
            cityName: ''
          }
        })
      }
    },

    chooseUniversity: (name) => {
      if (name || name === '') {

        const selectedUniversity = Array.isArray(name) ? name[0] : name

        const universityExists = universityList.find(university => university.universityName === selectedUniversity)

        if (universityExists) {
          setState(prevState => ({
            ...prevState,
            universityId: universityExists._id,
            universityName: universityExists.universityName
          }))
        } else {
          const newUniversityObject = {
            universityName: selectedUniversity,
            universityId: null
          }
          setState(prevState => ({
            ...prevState,
            ...newUniversityObject
          }))
        }
      } else {
        setState(prevState => ({
          ...prevState,
          universityId: null,
          universityName: ''
        }))
      }
    },


    chooseDocument: (e) => {
      let { name, files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          [name]: files[0]
        })
      }
    }
  }


  const handleSubmit = async () => {
    if (!state.eventId) {
      notification.warning({ message: 'Choose Event' })
      return
    }

    if (!state.meetingType) {
      notification.warning({ message: 'Choose Event type' })
      return
    }
    if (!state.companyName) {
      if (state.meetingType === 'Educator') {
        notification.warning({ message: 'Enter Institute name' })
        return
      }
      if (state.meetingType === 'Service Provider') {
        notification.warning({ message: 'Enter University name' })
        return
      }
      if (state.meetingType === 'Agent') {
        notification.warning({ message: 'Enter Agent name' })
        return
      }
    }

    if (!state.firstName) {
      notification.warning({ message: 'Enter contact person name' })
      return
    }

    let resp = await dispatch(icefAddParticipantFxn({ ...state, companyName: state.companyName }))

    if (resp && resp.success) {
      setState((prevState) => {
        return { ...initState, eventId: selectedEventId }
      })
    }
  }

  let handleSearch = value => {
    if (value) {
      fetch(value, dispatch, data => {
        setCompanyList(data)
      })
    } else {
      setCompanyList([])
    }
  }

  return (
    <>

      <div className='form-box mt-4'>
        <div className='card unizportal'>
          <Form>
            <Row gutter={10}>
              <Col span={12}>
                <InputBox title={'Choose Event *'} extra={(
                  <a onClick={handleEventClick}><Icon type='plus' /></a>
                )}>
                  <Select
                    placeholder={'Choose Event'}
                    value={state.eventId}
                    showSearch={true}
                    allowClear={true}
                    onChange={(value) => {
                      events._updateState({ eventId: value })
                    }}>
                    {eventList && eventList.length ? eventList.map((item) => {
                      return (
                        <Option value={item._id}>{item.eventName}</Option>
                      )
                    }) : null}
                  </Select>
                </InputBox>
              </Col>
              <Col span={12}>
                <InputBox title={'Choose Type *'}>
                  <Select
                    placeholder={'Choose Type'}
                    value={state.meetingType}
                    onChange={(value) => {
                      events._updateState({ meetingType: value })
                    }}>
                    {meetingTypeList && meetingTypeList.length ? meetingTypeList.map((item) => {
                      return (
                        <Option value={item}>{item}</Option>
                      )
                    }) : null}
                  </Select>
                </InputBox>
              </Col>

              {state.meetingType ? <>
                <Col span={8}>
                  <InputBox
                    title={state.meetingType === 'Educator' ? 'Institute Name *' : state.meetingType === 'Service Provider' ? 'University Name *' : 'Company Name *'}>
                    <AutoComplete
                      placeholder={state.meetingType === 'Educator' ? 'Enter Institute Name' : state.meetingType === 'Service Provider' ? 'Enter University Name' : 'Enter Company Name'}
                      filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                      onSearch={handleSearch}
                      value={state.companyName}
                      allowClear
                      onChange={(value) => {
                        events.chooseCompany(value)
                      }}
                    >
                      {companyList && companyList.length ? companyList.map((item) => (
                        <Option key={item._id} value={item.companyName}>{item.companyName}</Option>
                      )) : null}
                    </AutoComplete>

                  </InputBox>
                </Col>


                <Col span={8}>
                  <InputBox title={'Salutation'}>
                    <Select
                      placeholder={'Select Salutation'}
                      value={state.salutation}
                      onChange={(value) => events._updateState({ salutation: value })}>
                      {salutationList.map((salutation) => (
                        <Option key={salutation} value={salutation}>
                          {salutation}
                        </Option>
                      ))}
                    </Select>
                  </InputBox>
                </Col>

                <Col span={8}>
                  <InputBox title={'First Name *'}>
                    <input className={'form-control'}
                           placeholder={'First Name'}
                           value={selectedCompanyDetails ? selectedCompanyDetails.firstName : state.firstName}
                           onChange={({ target }) => {
                             events._updateState({ firstName: target.value })
                           }} />
                  </InputBox>
                </Col>
                <Col span={8}>
                  <InputBox title={'Last Name'}>
                    <input className={'form-control'}
                           placeholder={'Last Name'}
                           value={selectedCompanyDetails ? selectedCompanyDetails.lastName : state.lastName}
                           onChange={({ target }) => {
                             events._updateState({ lastName: target.value })
                           }} />
                  </InputBox>
                </Col>
                <Col span={8}>
                  <InputBox title={'Position'}>
                    <input className={'form-control'}
                           placeholder={'Position'}
                           value={selectedCompanyDetails ? selectedCompanyDetails.position : state.position}
                           onChange={({ target }) => {
                             events._updateState({ position: target.value })
                           }} />
                  </InputBox>
                </Col>
                <Col span={8}>
                  <InputBox title={'Contact Person Email'}>
                    <input className={'form-control'}
                           placeholder={'Contact Person Email'}
                           value={selectedCompanyDetails ? selectedCompanyDetails.email : state.email}
                           onChange={({ target }) => {
                             events._updateState({ email: target.value })
                           }} />
                  </InputBox>
                </Col>

                <Col span={12}>
                  <InputBox title={'Enter Mobile no'}>
                    <input className={'form-control'}
                           placeholder={'Enter Mobile No'}
                           value={selectedCompanyDetails ? selectedCompanyDetails.mobile : state.mobile}
                           onChange={({ target }) => {
                             events._updateState({ mobile: target.value })
                           }} />
                  </InputBox>
                </Col>
                <Col span={12}>
                  <InputBox title={'Department'}>
                    <input className={'form-control'}
                           placeholder={'Department'}
                           value={selectedCompanyDetails ? selectedCompanyDetails.department : state.department}
                           onChange={({ target }) => {
                             events._updateState({ department: target.value })
                           }} />
                  </InputBox>
                </Col>

                <Col span={12}>
                  <InputBox title={'Description'}>
                    <input
                      className={'form-control'}
                      placeholder={'Description'}
                      value={selectedCompanyDetails ? selectedCompanyDetails.description : state.description}
                      onChange={({ target }) => {
                        events._updateState({ description: target.value })
                      }}
                    />
                  </InputBox>

                </Col>
                <Col span={12}>
                  <InputBox title={'Enter Street'}>
                    <input className={'form-control'}
                           placeholder={'Enter Street'}
                           value={selectedCompanyDetails ? selectedCompanyDetails.street : state.street}
                           onChange={({ target }) => {
                             events._updateState({ street: target.value })
                           }} />
                  </InputBox>
                </Col>
                <Col span={8}>
                  <InputBox title={'Enter City/Town'}>
                    <input className={'form-control'}
                           placeholder={'Enter City/Town'}
                           value={selectedCompanyDetails ? selectedCompanyDetails.cityName : state.cityName}
                           onChange={({ target }) => {
                             events._updateState({ cityName: target.value })
                           }} />
                  </InputBox>
                </Col>

                <Col span={8}>
                  <InputBox title={'Province/State Name'}>
                    <input className={'form-control'}
                           placeholder={'Enter State Name'}
                           value={selectedCompanyDetails ? selectedCompanyDetails.stateName : state.stateName}
                           onChange={({ target }) => {
                             events._updateState({ stateName: target.value })
                           }} />
                  </InputBox>
                </Col>
                <Col span={8}>
                  <InputBox title={'Country'}>
                    <input className={'form-control'}
                           placeholder={'Enter Country Name'}
                           value={selectedCompanyDetails ? selectedCompanyDetails.countryName : state.countryName}
                           onChange={({ target }) => {
                             events._updateState({ countryName: target.value })
                           }} />
                  </InputBox>
                </Col>

                <Col span={12}>
                  <InputBox title={'Enter Postal code'}>
                    <input className={'form-control'}
                           placeholder={'Enter Postal code'}
                           value={selectedCompanyDetails ? selectedCompanyDetails.pincode : state.pincode}
                           onChange={({ target }) => {
                             events._updateState({ pincode: target.value })
                           }} />
                  </InputBox>
                </Col>
                <Col span={12}>
                  <InputBox title={'Website'}>
                    <input className={'form-control'}
                           placeholder={'Website'}
                           value={selectedCompanyDetails ? selectedCompanyDetails.website : state.website}
                           onChange={({ target }) => {
                             events._updateState({ website: target.value })
                           }} />
                  </InputBox>
                </Col>


              </> : null}


            </Row>


            <Button type='primary' htmlType='submit' className='btn' onClick={handleSubmit}>
              Submit
            </Button>
          </Form>
        </div>
      </div>
      {visibleAddCountry ?
        <AddCountryDrawer
          visible={visibleAddCountry}
          onClose={events.hideAddCountry}
          onSubmit={events.refreshAddCountry} /> : null}
      {visibleAddUniversity ?
        <AddUniversityDrawer
          visible={visibleAddUniversity}
          onClose={events.hideAddUniversity}
          onSubmit={events.refreshAddUniversity}
          selectedCountryId={state.countryId} /> : null}
      {visibleAddEvent ?
        <AddEventDrawer
          visible={visibleAddEvent}
          onSubmit={events.refreshAddEvent}
          onClose={events.hideAddEvent} /> : null}

    </>
  )
}
export default AddParticipants
