import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button, Col,
  Card,
  Drawer,
  Form,
  Modal,
  notification, Row, Tooltip, Checkbox
} from 'antd'
import {
  addWithdrawalFxn,
  sendWithdrawalOtpFxn
} from './gicCommission/action'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import InputBox from '../../../components/_utils/InputBox'
import AddBankAccount from '../agentCommission/addBankAccounts'
import { listAllAccount } from '../../bankAccount/actions/bankAccount'
import { getGicBalance } from './Withdrawals/actions'

let initState = {
  bankId: '',
  balance: 0,
  amount: 0
}
const WithdrawalComponent = (props) => {
  const {
    onClose,
    visible,
    onSubmit,
    form: { setFieldsValue, getFieldDecorator }
  } = props
  let [state, setState] = useState(initState)
  let [bankList, setBankList] = useState([])
  let [visibleBankDrawer, setVisibleBankDrawer] = useState(false)
  let [visibleOtpScreen, setVisibleOtpScreen] = useState(false)
  let [otp, setOtp] = useState('')
  let [acknowledgeChecked, setAcknowledgeChecked] = useState(false)
  let [selectedAccount, setSelectedAccount] = useState({})
  let [notificationState, setNotificationState] = useState({
    visible: false,
    message: ''
  })

  let dispatch = useDispatch()
  const loadBankList = async () => {
    let { data } = await dispatch(listAllAccount({}))
    setBankList(data)
  }
  useEffect(() => {
    loadBankList()
  }, [])
  useEffect(() => {
    let user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null
    if (user._id) {
      loadCurrentCommission(user._id)
    }
  }, [])

  const events = {
    showBankDrawer: () => {
      setVisibleBankDrawer(true)
    },
    hideBankDrawer: () => {
      setVisibleBankDrawer(false)
      loadBankList()
    },
    hideNotification: () => {
      setNotificationState({
        visible: false,
        message: ''
      })
    }
  }

  let loadCurrentCommission = async (id) => {
    let data = await dispatch(getGicBalance(id))
    if (data) {
      setFieldsValue({ balance: data.balance.toFixed(2) })
    }
  }

  const handleSubmit = (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFields(async (err, valData) => {
      if (!err) {

        if (!valData.bankId) {
          notification.warning({ message: 'Please choose bank' })
          return
        }
        if (!selectedAccount.cancelledCheque || (selectedAccount.cancelledCheque && !selectedAccount.cancelledCheque.name)) {
          notification.warning({ message: 'Please upload the cancelled cheque in the selected bank account first!' })
          return
        }

        if (!valData.amount || valData.amount < 0) {
          notification.warning({ message: 'Please enter amount' })
          return
        }
        if (valData.amount > valData.balance) {
          notification.warning({ message: 'Please enter valid amount' })
          return
        }

        if (!acknowledgeChecked) {
          notification.warning({ message: 'Please acknowledge the terms stated above.' })
          return
        }

        valData = {
          ...valData,
          ...selectedAccount
        }
        delete valData['_id']
        let x = await dispatch(sendWithdrawalOtpFxn(valData))
        if (x.success) {
          setState(valData)
          setVisibleOtpScreen(true)
        } else {
          if (x.popupNotification) {
            setNotificationState({
              visible: true,
              message: x.message
            })
          }
        }
      } else {
      }
    })
  }

  const submitHandleOtp = async (e) => {
    if (!otp) {
      notification.warning({
        message: 'Please enter Otp'
      })
      return
    }
    let x = await dispatch(addWithdrawalFxn({ ...state, otp }))
    if (x.success) {
      setState({})
      setVisibleOtpScreen(false)
      onSubmit()
    } else {
      if (x.popupNotification) {
        setNotificationState({
          visible: true,
          message: x.message
        })
      }
    }
  }

  let inputTypes = {
    fields: [
      {
        key: 'bankId',
        label: 'Bank',
        required: false,
        placeholder: 'Bank',
        type: 'select',
        options: bankList,
        extraBox: (<>
          <Tooltip title={'Add Bank'}>
            <a className='btn btn-default ml10'
               onClick={() => {
                 events.showBankDrawer()
               }}> <i className='fa fa-plus' />
            </a>
          </Tooltip>
        </>),
        keyAccessor: (x) => x.accounts._id,
        valueAccessor: (x) => `${x.accounts.bankName} (${x.accounts.accountNo})`,
        onChange: (x) => {
          props.form.setFieldsValue({
            bankId: x
          })
          let findBank = _.find(bankList, (item) => {
            return item.accounts._id == x
          })
          if (findBank) {
            setSelectedAccount(findBank.accounts)
          }
        },
        span: 'col-md-24'
      },
      {
        key: 'balance',
        label: 'Balance',
        required: true,
        placeholder: 'balance',
        type: 'text',
        disabled: true,
        span: 'col-md-24'
      },

      {
        key: 'amount',
        label: 'Amount',
        required: false,
        placeholder: 'Amount',
        type: 'number',
        span: 'col-md-24'
      }
    ]
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  return (
    <>
      <Drawer
        visible={visible}
        title={'GIC Withdrawal'}
        onClose={onClose}
        width={'40%'}
      >
        <div>
          <Card shadow={false} padding={false}>

            <Form onSubmit={handleSubmit}>
              <Row gutter={24}>

                {inputTypes.fields.map((item, key) => {
                  return (
                    <Col span={item.span ? item.span : 12} md={item.span ? item.span : 12} sm={24} xs={24}
                         key={key}>
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout} />
                    </Col>
                  )
                })}
                <Col span={24}>
                  <div className={'mt20'}>
                    <label className={'font15'} style={{ fontWeight: 600 }}>
                      <Checkbox
                        checked={acknowledgeChecked}
                        onChange={({ target }) => {
                          setAcknowledgeChecked(target.checked)
                        }} />
                      &nbsp;
                      I acknowledge that CAD $18 will be deducted from the withdrawal amount as transaction
                      fees.
                    </label>
                  </div>

                  <Button
                    type={'primary'}
                    htmlType='submit'
                    className='btn'>
                    submit
                  </Button>
                </Col>

              </Row>


            </Form>
          </Card>

        </div>

        {visibleBankDrawer ? (
          <AddBankAccount
            onClose={events.hideBankDrawer}
            visible={visibleBankDrawer}
          />
        ) : null}

        {visibleOtpScreen ? (
          <Modal
            visible={visibleOtpScreen}
            onCancel={() => {
              setVisibleOtpScreen(false)
            }}
            title={'Enter OTP'}
            footer={false}
            centered={true}
          >
            <div style={{ textAlign: 'center', marginBottom: 10 }}>
              <h5>
                A text message with a 4-digit verification code has been sent to
                your phone number.
              </h5>
              <InputBox title={'Enter OTP'}>
                <input
                  className={'form-control mt10'}
                  type={'password'}
                  minLength={4}
                  maxLength={4}
                  value={otp}
                  onChange={({ target }) => {
                    setOtp(target.value)
                  }}
                />
              </InputBox>
              <Button
                className={'btn btn-success mt10'}
                onClick={submitHandleOtp}
              >
                Submit OTP
              </Button>
            </div>
          </Modal>
        ) : null}

        {notificationState.visible ?
          <Modal
            title=''
            visible={notificationState.visible}
            onOk={events.hideNotification}
            onCancel={events.hideNotification}
            width={'35%'}
            footer={false}>
            <div style={{ fontSize: 16 }} className={'alignCenter'}>
              <img src={'../assets/Unizportal-Logo.png'} style={{ height: 120 }} />
              <div className={'mt20'}>
                <strong>{notificationState.message}</strong>
              </div>
              <div className={'mt20'}>
                <a className={'btn btn-default roundNew'} onClick={events.hideNotification}>Close</a>
              </div>
            </div>
          </Modal> : null}

      </Drawer>
    </>
  )
}

export default Form.create()(WithdrawalComponent)
