import React, {useState} from 'react'
import {Button, Card, Drawer, Form, Input, notification} from 'antd'
import {RowTable} from '../../../components/_utils/RowTable'
import {FormUtils as GetAllFormFields} from 'sz-react-utils-lite'
import {InputBox, displayDate} from '../../../components/_utils/appUtils'
import {RequestLoaApproved} from '../actions/chooseStudent'
import {useDispatch} from 'react-redux'

const ApprovedLoaRequest = (props) => {
    let dispatch = useDispatch()
    let {visible, onClose, studentId, applicationId, reloadTable, studentData, title} = props
    let [familyName, setFamilyName] = useState('')
    let [givenName, setGivenName] = useState('')
    // let [studentIdNo, setStudentIdNo] = useState('')
    let handleSubmit = async () => {
        // studentIdNo = studentIdNo.trim()
        familyName = familyName.trim()
        givenName = givenName.trim()

        let obj = {
            studentId, familyName, applicationId, givenName
        }

        let resp = await dispatch(RequestLoaApproved(obj))
        if (resp && resp.success) {
            onClose()
            reloadTable()
            // setStudentIdNo('')
            setFamilyName('')
            setGivenName('')
        }
    }
    return (
        <Drawer visible={visible}
                title={title ? title : `Approved LOA Rqst`}
                width={600}
                closable={true}
                maskClosable={true}
                onClose={onClose}>
            <Card>
                {studentData && studentData.applications && studentData.applications.id ?
                    <div className={'tableBox striped'}>
                        <RowTable title={'Name (ID)'}
                                  value={<span>{studentData.name} ({studentData.studentId})</span>}/>
                        <RowTable title={'Date Of Birth'} value={<span>{displayDate(studentData.dateOfBirth)}</span>}/>
                        <RowTable title={'Phone'} value={<span>{studentData.countryCode} {studentData.mobile}</span>}/>
                        <RowTable title={'Address'} value={<span>{studentData.address}</span>}/>
                        <RowTable title={'Country'} value={<span>{studentData.countryName}</span>}/>
                        <RowTable title={'State'} value={<span>{studentData.stateName}</span>}/>
                        <RowTable title={'City'} value={<span>{studentData.cityName}</span>}/>
                        <RowTable title={'Postal Code'} value={<span>{studentData.postalCode}</span>}/>
                        <RowTable title={'Email'} value={<span>{studentData.email}</span>}/>
                        <RowTable title={'Gender'} value={<span>{studentData.gender}</span>}/>
                        <RowTable title={'Application ID'} value={<span>{studentData.applications.id}</span>}/>
                        <RowTable title={'Application'} value={<span>{studentData.applications.courseName}</span>}/>
                    </div> : null}
                <br/>
                <Form className={'vertical-form'} autoComplete='off'>
                    <Form.Item>
                        <InputBox title={'Family name'}>
                            <Input onChange={(e) => setFamilyName(e.target.value)}
                                   value={familyName} className={'form-control'}/>
                        </InputBox>
                    </Form.Item>
                    <Form.Item>
                        <InputBox title={'Given name'}>
                            <Input onChange={(e) => setGivenName(e.target.value)}
                                   value={givenName} className={'form-control'}/>
                        </InputBox>
                    </Form.Item>
                    {/*  <Form.Item>
            <InputBox title={'Student ID'}>
              <Input onChange={(e) => setStudentIdNo(e.target.value)}
                     value={studentIdNo} className={'form-control'}/>
            </InputBox>
          </Form.Item>*/}
                    <Form.Item style={{marginTop: 25}}>
                        <Button type='primary' onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Drawer>
    )
}
export default ApprovedLoaRequest
