import { apiUrl } from '../../../settings'

export const addStoryUrl = () => {
  return apiUrl + '/trustedStory'
}

export const deleteStoryUrl = (id) => {
  return apiUrl + '/trustedStory/' + id
}

