import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Col, Drawer, Empty, Form, Modal, notification, Row } from 'antd'
import {
  countryIds,
  InputBox
} from '../../../components/_utils/appUtils'
import { listAllAccount } from '../../bankAccount/actions/bankAccount'
import { useDispatch } from 'react-redux'
import { agentCurrentCommissionFxn, getAgentCurrentCommissionFxn } from '../../universityCommission/actions'
import AddBankAccount from './addBankAccounts'
import {
  addWithdrawalFxn,
  addWithdrawalSendOtpFxn,
  updateUniversityCommissionsFxn
} from '../../withdrawal/actions/withdrawalAction'
import _ from 'lodash'
import {
  getAgentUniversityCommissionFxn
} from '../../applications/actions/chooseStudent'
import moment from 'moment'
import TransactionListForAdmin from './transactionListForAdmin'

let initState = {
  bank: '',
  balance: 0,
  amount: 0
}
const AssignComponent = (props) => {
  const { onClose, visible, countryId, agentId, company } = props
  let [state, setState] = useState(initState)
  let [bankList, setBankList] = useState([])
  let [visibleBankDrawer, setVisibleBankDrawer] = useState(false)
  let [visibleOtpScreen, setVisibleOtpScreen] = useState(false)
  let [otp, setOtp] = useState('')
  let [selectedAccount, setSelectedAccount] = useState({})
  let [universityCommission, setUniversityCommissions] = useState([])
  let [universityCommissionIds, setUniversityCommissionIds] = useState([])
  let [universityCommissionAmount, setUniversityCommissionAmount] = useState([])
  const [currentCommissionObj, setCurrentCommissionObj] = useState({})
  useEffect(() => {
    loadCurrentCommission2()
  }, [])
  let loadCurrentCommission2 = async () => {
    let { data } = await dispatch(agentCurrentCommissionFxn())
    setCurrentCommissionObj(data)
  }

  let initState1 = {
    visible: false,
    countryId: '',
    agentId: ''
  }
  const [transactionListView, setTransactionListView] = useState(initState1)

  let dispatch = useDispatch()
  const loadBankList = async () => {
    let resp = await dispatch(listAllAccount({}))
    setBankList(resp.data)
  }
  const getUniversityCommission = async (params = {}) => {
    params.countryId = countryId
    params.agentId = agentId

    params.status = 'Commission Approved'
    try {
      const resp = await dispatch(getAgentUniversityCommissionFxn({ ...params, results: 200, count: 200 }))
      const commissions = resp.data
      setUniversityCommissions(commissions)
      const pendingCommissions = commissions.filter(item => item.withdrawalStatus == 'Pending')
      const commissionIds = pendingCommissions.map(item => item._id)
      const commissionAmounts = pendingCommissions.map(item => ({
        id: item._id,
        amount: item.approvedByAdminCommission ? item.approvedByAdminCommission : item.commission
      }))
      setUniversityCommissionIds(commissionIds)
      setUniversityCommissionAmount(commissionAmounts)
      const totalAmount = commissionAmounts.reduce((sum, item) => sum + item.amount, 0)
      updateState({ amount: totalAmount })
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    loadBankList()
  }, [])

  useEffect(() => {
    loadCurrentCommission()
    getUniversityCommission()
  }, [props])

  const updateState = (data) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...data
      }
    })
  }

  const events = {
    showBankDrawer: () => {
      setVisibleBankDrawer(true)
    },
    hideBankDrawer: () => {
      setVisibleBankDrawer(false)
      loadBankList()
    },
    chooseBankAccount: (bank) => {
      updateState({ bank: bank })
      let findBank = _.find(bankList, (item) => {
        return item.accounts._id == bank
      })
      if (findBank) {
        setSelectedAccount(findBank.accounts)
      }
    }
  }

  let loadCurrentCommission = async () => {
    let params = {}
    params.agentId = agentId
    let { data } = await dispatch(getAgentCurrentCommissionFxn(params))
    if (countryId) {
      if (countryId == countryIds.uk) {
        updateState({ balance: data.ukCommission })
      }
      if (countryId == countryIds.canada) {
        updateState({ balance: data.canadaCommission })
      }
      if (countryId == countryIds.australia) {
        updateState({ balance: data.australiaCommission })
      }
      if (countryId == countryIds.germany) {
        updateState({ balance: data.germanyCommission })
      }
      if (countryId == countryIds.canadaOnshore) {
        updateState({ balance: data.onshoreCanadaCommission })
      }
    }
  }

  const handleChange = (data) => {
    setState(prevData => {
      return {
        ...prevData,
        ...data
      }
    })
  }

  const handleSubmitAddWithdrawal = async (e) => {
    e.preventDefault()
    if (universityCommissionIds.length == 0) {
      notification.warning({ message: 'Please choose atleast one student to withdraw commission' })
      return
    }
    if (state.amount > state.balance) {
      notification.warning({ message: 'Sorry Balance Does Not Match' })
      return
    }
    /* if (state.amount < state.balance) {
       notification.warning({ message: 'Sorry Balance Does Not Match' })
       return
     }*/
    let obj = {}
    obj.universityCommissionIds = universityCommissionIds
    obj.countryId = countryId
    if (agentId) {
      obj.agentId = agentId
    }
    let resp = await dispatch(updateUniversityCommissionsFxn(obj))
    if (resp && resp.success) {
      handleChange({
        bank: '',
        amount: null
      })
      onClose()
    }
  }

  useEffect(() => {
    const totalAmount = universityCommissionAmount.reduce((sum, item) => sum + item.amount, 0)
    updateState({ amount: totalAmount })
  }, [universityCommissionAmount.length])

  return (
    <>
      <Drawer
        visible={visible}
        title={
          <div>
            {company} - Assign Students (Balance - {state.balance ? state.balance : null})
            <a className='btn btn-default sm ml10 roundNew'
               onClick={() => {
                 setTransactionListView({ visible: true, countryId: countryId, agentId: agentId })
               }}> View Transaction</a>
          </div>
        }
        onClose={() => {
          onClose()
          setUniversityCommissions([])
        }}
        width={'70%'}>
        {universityCommission && universityCommission.length ? <div className='card unizportal'>
          <Form className={'vertical-form'} autoComplete='off' onSubmit={handleSubmitAddWithdrawal}>
            <Row gutter={24} className={'rowWrap'}>
              <Col span={24}>
                <div className='col-12 container'>
                  <InputBox title='Choose Students' />
                  <table className='table table-hover'>
                    <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Application Id</th>
                      <th>University Logo</th>
                      <th>University Name</th>
                      <th>Semester</th>
                      <th>Amount</th>
                      <th>Date</th>
                    </tr>
                    </thead>
                    {universityCommission.length ? (
                      <tbody>
                      {universityCommission.map((item) => (
                        <tr key={item.semesterIndex}>
                          <td>
                            <Checkbox
                              checked={universityCommissionIds.includes(item._id)}
                              onChange={(e) => {
                                const checked = e.target.checked
                                const amount = item.approvedByAdminCommission ? item.approvedByAdminCommission : item.commission

                                if (checked) {
                                  setUniversityCommissionIds([...universityCommissionIds, item._id])
                                  setUniversityCommissionAmount(prevAmount => [
                                    ...prevAmount,
                                    { id: item._id, amount }
                                  ])
                                } else {
                                  setUniversityCommissionIds(universityCommissionIds.filter(id => id !== item._id))
                                  setUniversityCommissionAmount(prevAmount => prevAmount.filter(entry => entry.id !== item._id))
                                }
                              }}
                            />
                          </td>
                          <td>{item.studentId.name}</td>
                          <td>{item.appId}</td>
                          <td>
                            <img className={'smallUniversityLogo'} src={item.universityId.smallLogo.url} alt='avatar' />
                          </td>
                          <td>{item.universityId.universityName}</td>
                          <td>{item.semesterIndex}</td>
                          <td>
                            {item.commissionBonus ? <>
                              <div>
                                Comm. : <strong className={'font-weight-600'}>{item.currency} {item.commission}</strong>
                              </div>
                              <div>
                                Bonus : <strong
                                className={'font-weight-600'}>{item.currency} {item.commissionBonus}</strong>
                              </div>
                            </> : null}
                            <div>
                              {item.commissionBonus ? 'Total : ' : ''}
                              <strong className={'font-weight-600'}>
                                {item.currency}&nbsp;
                                {item && item.approvedByAdminCommission ? item.approvedByAdminCommission : item.commission}
                              </strong>
                            </div>

                          </td>
                          <td>{item && item.approvedCommissionDate ? moment(item.approvedCommissionDate).format('DD-MM-YYYY') : null}</td>
                        </tr>
                      ))}
                      </tbody>
                    ) : (
                      <tbody>
                      <tr>
                        <td colSpan='8' className='text-center'>
                          <Empty className='mt-2' description='No data found' />
                        </td>
                      </tr>
                      </tbody>
                    )}
                  </table>
                  <div className={'d-flex'}>
                    <InputBox title='Balance' />
                    <div className={' mr-2 mt-1'}>
                      {state.balance ? state.balance : null}
                    </div>
                    <div className={'ml-2'}>
                      <InputBox title='Amount' />
                    </div>

                    <div className={' mr-2 mt-1'}>
                      {state.amount ? state.amount : 0}
                    </div>
                  </div>

                </div>
              </Col>
            </Row>
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                className='btn'
              >
                SAVE
              </Button>
            </Form.Item>
          </Form>
        </div> : null}

        {visibleBankDrawer ?
          <AddBankAccount
            onClose={events.hideBankDrawer}
            visible={visibleBankDrawer}
          /> : null}

        {visibleOtpScreen ? <Modal
          visible={visibleOtpScreen}
          closable={true}
          title={'Enter OTP'}
          onCancel={() => {
            setVisibleOtpScreen(false)
          }}
          footer={false}>
          <div style={{ textAlign: 'center', marginBottom: 10 }}>
            <h6> A text message with a
              4-digit verification code has been sent to your phone number.
            </h6>
            <InputBox title={'Enter OTP'}>
              <input
                className={'form-control mt10'}
                type={'password'}
                minLength={4}
                maxLength={4}
                value={otp} onChange={({ target }) => {
                setOtp(target.value)
              }} />
            </InputBox>
            <Button className={'btn btn-success mt10'} onClick={handleSubmitAddWithdrawal}>
              Submit OTP
            </Button>

          </div>
        </Modal> : null}

      </Drawer>
      {transactionListView.visible ?
        <TransactionListForAdmin
          visible={transactionListView.visible}
          countryId={transactionListView.countryId ? transactionListView.countryId : ''}
          agentId={transactionListView.agentId ? transactionListView.agentId : ''}
          onClose={() => {
            setTransactionListView(initState1)
          }}
          currentCommissionObj={currentCommissionObj}
          refreshPage={() => {
            setTransactionListView({ visible: false, countryId: '', agentId: '' })
          }}
        /> : null}
    </>
  )
}
const WrappedComponent = Form.create()(AssignComponent)
export default WrappedComponent
