import { apiUrl } from '../../../settings'

export const allCommissionListUrl = () => {
  return apiUrl + '/api/commissionList'
}

export const agentCommissionCountUrl = () => {
  return apiUrl + '/api/agentCommissionCount'
}

export const getCurrentBalanceUrl = () => {
  return apiUrl + '/api/getCurrentBalance'
}
export const payCommissionUrl = () => {
  return apiUrl + '/api/payCommission'
}
