import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, notification, Select, DatePicker } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment'
import _ from 'lodash'
import { LoadState, LoadCity } from '../../../components/_utils/countryUtil'
import GetEachFormFields from '../../../components/_utils/appFormUtils';
import {addInstituteEvent, approvedEventListFxn, instituteEventListFxn, slotListFxn} from "../action";

const EventRegistration = (props) => {
    const { form: { getFieldDecorator, getFieldValue, setFieldsValue } } = props;
    const dispatch = useDispatch();
    const [allStates, setAllStates] = useState([]);
    const [allCities, setAllCities] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [slotList, setSlotList] = useState([]);
    const [eventDates, setEventDates] = useState([])

    const apiRequest = () => {
        return new Promise(async (resolve) => {
            const params = {
                results: 1000,
                count: 1000,
            }
            let resp = await dispatch(slotListFxn({
                ...params,
            }));
            const dates = resp.data.map(item => moment(item.date).format('YYYY-MM-DD'));
            setSlotList(dates);
            resolve(resp);
        })
    };
    const apiRequest2 = () => {
        return new Promise(async (resolve) => {
            const params = {
                results: 1000,
                count: 1000,
            }
            let resp = await dispatch(instituteEventListFxn({
                ...params,
            }));
            // const approvedEvents = resp.data.filter(item => item.status === "Approved");
        
            // const dates = approvedEvents.map(item => moment(item.date).format('YYYY-MM-DD'));
            //
            // setEventDates(dates);
            resolve(resp);
        })
    };

    const apiRequest3 = async () => {
        let resp = await dispatch(approvedEventListFxn());
        const approvedEventDates = resp.data.map(item => moment(item._id, 'DD-MM-YYYY').format('YYYY-MM-DD'));
        setEventDates(approvedEventDates);
    };



    useEffect(() => {
        loadStates()
        loadSlotList()
        apiRequest2()
        apiRequest3()
    }, []);



    const loadStates = async () => {
        const states = await LoadState('101');
        setAllStates(states)
    };

    const handleStateChange = async (value) => {
        const cities = await LoadCity(value)
        setAllCities(cities)
    }
    const handleDateChange = (date) => {
        setSelectedDate(date)
    }
    const loadSlotList = async () => {
        let startDate = moment().subtract(1, 'day')._d
        const params = {
            results: 1000,
            count: 1000,
            date: { $gte: startDate }
        }
        let { data } = await dispatch(slotListFxn(params))
        setSlotList(data)
    }

    const disabledDate = (current) => {
        let currentDate = moment().endOf('day');
        let format = 'DD-MM-YYYY';
        let findInSlotList = _.find(slotList, (item) => {
            return moment(item.date).format(format) === moment(current).format(format);
        });
        let findInEventDates = _.find(eventDates, (date) => {
            return moment(date).format(format) === moment(current).format(format);
        });
        if (findInSlotList || findInEventDates || moment(current).isBefore(currentDate)) {
            return true;
        } else {
            return false;
        }
    };


    const handleSubmit = (e) => {
        const { form } = props;
        e.preventDefault();
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                const stateId = valData.state;
                const state = allStates.find(state => state.id === stateId);
                let cityName = '';
                if (valData.city) {
                    const city = allCities.find(city => city.id === valData.city);
                    if (city) {
                        cityName = city.name;
                    }
                }
                const formData = {...valData, state: state.name, city: cityName};
                let resp = await dispatch(addInstituteEvent(formData));
                if (!resp.error) {
                    form.resetFields();
                }
            }
        });
    };


    const formFields = [
        {
            key: 'venue',
            label: 'Venue',
            required: true,
            type: 'input',
            span: 24,
        },
        {
            key: 'date',
            label: 'Date',
            type: 'date',
            required: true,
            disabledDate: disabledDate
        },
        {
            key: 'time',
            label: 'Time',
            type: 'time',
            required: true,
            format: 'HH:mm',
            showSeconds: false
        },
        {
            key: 'state',
            label: 'State',
            required: true,
            type: 'select',
            showSearch: true,
            options: allStates,
            keyAccessor: x => x.id,
            valueAccessor: x => `${x.name}`,
            onChange: x => {
                setFieldsValue({
                    state: x
                })
                handleStateChange(x)
            }
        },
        {
            key: 'city',
            label: 'City',
            //   required: true,
            type: 'select',
            showSearch: true,
            options: allCities,
            keyAccessor: x => x.id,
            valueAccessor: x => `${x.name}`,
            onChange: x => {
                setFieldsValue({
                    city: x
                })
            }
        },

        {
            key: 'details',
            label: 'Details',
            type: 'textarea',
            span: 24,
        }
    ];


    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
        }
    };

    return (
        <div className={'mt-4'}>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='form-box'>
                        <div className='d-flex align-items-center mb-3 heading-form'>
                            <h5>Add Institute Event</h5>
                        </div>
                        <div className='card unizportal'>
                            <Form onSubmit={handleSubmit} className='vertical-form'>
                                <Row gutter={24}>
                                    {formFields.map((field) => (
                                        <Col span={field.span ? field.span : 8} key={field.key} className={'mb10'}>
                                            <GetEachFormFields
                                                item={field}
                                                getFieldDecorator={getFieldDecorator}
                                                formItemLayout={formItemLayout} />
                                        </Col>
                                    ))}
                                </Row>
                                <Row gutter={24}>
                                    <Col span={18}>
                                        <Form.Item>
                                            <Button type='primary' htmlType='submit' className='btn'>
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Form.create()(EventRegistration);
