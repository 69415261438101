import { apiUrl } from '../../../settings'

export const addAdvanceCommissionUrl = () => {
  return apiUrl + '/advance-commission-request/add'
}
export const updateAdvanceCommissionUrl = () => {
  return apiUrl + '/advance-commission-request/update'
}
export const advanceCommissionListUrl = () => {
  return apiUrl + '/advance-commission-request/list'
}
