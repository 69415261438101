export default (
  state = {
    loader: false,
    campus: {},
    allCampus: [],
    totalCampus: 0,
  },
  action
) => {
  switch (action.type) {

    case 'CAMPUS_SHOW_LOADER':
      return { ...state, loader: true };
    case 'CAMPUS_HIDE_LOADER':
      return { ...state, loader: false };
    case 'LIST_CAMPUS':
      return { ...state, campus: action.payload.data };
    case 'ADD_CAMPUS':
      return { ...state, addedCampus: action.payload };
    case 'LIST_ALL_CAMPUS':
      return { ...state, allCampus: action.payload.data, totalCampus: action.payload.total }
    case 'EMPTY_CAMPUS_LIST':
      return { ...state, allCampus: [], totalCampus: 0 }
    default:
      return state
  }
}
