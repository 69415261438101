import React, {useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {DefaultTablePagination} from '../../../components/_utils/appUtils'
import {ColumnWidth} from '../../WebComponent/columnWidth'
import {
    blockAgent, getDistinctUserFxn, loadCallbackListFxn
} from '../../users/actions/user'
import ViewApplication from '../../applications/views/viewApplication'
import moment from 'moment'
import { Tag } from 'antd'

const CallbackList = () => {
    let dispatch = useDispatch()
    let tableRef = useRef()
    let [total, setTotal] = useState(0)
    let [selectedUsers, setSelectedUsers] = useState([])
    let [distinctUsers, setDistinctUsers] = useState([])
    let [applicationState, setApplicationState] = useState({
        visible: false,
        queryData: {}
    })


    useEffect(() => {
        tableRef.current.reload()
    }, [selectedUsers])

    const handleGetDistinctUser = async () => {
        let {data} = await dispatch(getDistinctUserFxn())
        setDistinctUsers(data)
    }

    useEffect(() => {
        handleGetDistinctUser()
    }, [])

    const handleBoxClick = state => {
        const isSelected = selectedUsers.includes(state.userId)

        if (isSelected) {
            setSelectedUsers(selectedUsers.filter(selected => selected != state.userId))
        } else {
            setSelectedUsers([...selectedUsers, state.userId])
        }
    }

    let apiRequest = async params => {
        return new Promise(async resolve => {
            params.status = 'Pending'
            params.results = 50
            params.count = 50
            if (selectedUsers && selectedUsers.length > 0) {
                params.assignedTo = { $in: selectedUsers };
            }
            let {data} = await dispatch(
                loadCallbackListFxn({
                    ...params,
                    regExFilters: ['name', 'address', 'mobile', 'email']
                })
            )
            setTotal(data.total);
            resolve(data)
        })
    }

    const events = {
        hideApplicationDrawer: () => {
            setApplicationState({
                visible: false,
                queryData: {}
            })
        },
        showApplicationDrawer: data => {
            setApplicationState({
                visible: true,
                queryData: {
                    agentId: data._id
                }
            })
        },
        updateAgentBlockStatus: async id => {
            let resp = await dispatch(blockAgent({agentId: id, sendMail: true}))
            if (resp && resp.success) {
                if (tableRef && tableRef.current) {
                    tableRef.current.reload()
                }
            }
        }
    }

    const columns = [
        {
            title: '#',
            key: '_id',
            dataIndex: '_id',
            width: 50,
            render: (ite, record, index) => {
                return <React.Fragment>{index + 1}</React.Fragment>
            }
        },
        {
            title: 'Name',
            key: 'name',
            sorter: true,
            dataIndex: 'name',
            searchTextName: 'name',
            width: 100,
            render: (item, record) => {
                return (
                    <ColumnWidth width={100}>
                        <div className={'agentName'}>
                            {item}
                        </div>
                    </ColumnWidth>
                )
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            searchTextName: 'email',
            width: 120,
            render: item => {
                return <ColumnWidth width={120}>{item}</ColumnWidth>
            }
        },
        {
            title: 'Mobile No',
            dataIndex: 'phone',
            key: 'phone',
            searchTextName: 'phone',
            width: 80,
            render: item => {
                return <ColumnWidth width={80}>{item}</ColumnWidth>
            }
        },
        {
            title: 'Assigned To',
            dataIndex: 'assignedTo',
            key: 'assignedTo',
            searchTextName: 'assignedTo',
            width: 100,
            render: item => {
                return <ColumnWidth width={150}>{item.name}</ColumnWidth>
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            searchTextName: 'date',
            width: 250,
            render: item => {
                return <ColumnWidth width={150}>{moment(item).format('DD-MM-YYYY')}</ColumnWidth>
            }
        },
    ]

    return (
        <>

            <div className='row  mt-4'>
                <div className='col-lg-12'>
                    <div className='card'>
                        <div className='table-head d-flex align-items-center'>
                            <h5>All Callback ( Total : {total})</h5>
                            <div className='search-box-table'/>
                        </div>
                        <div className='card-body table-responsive'>
                            <div className={'row'}>
                                <div className={'col-md-12 mt10'}>
                                    <label style={{ fontWeight: 500 }}>Filter by User</label>
                                      <div className='input-group input-group-sm'>
                                        <div>
                                          {distinctUsers &&
                                          distinctUsers.length ?
                                            distinctUsers.map((state, index) =>
                                              state.userId ? (
                                                  <Tag
                                                      key={index}
                                                      className={`selectable-box ${
                                                        selectedUsers.includes(state.userId) ? 'selected' : ''
                                                      }`}
                                                      onClick={() => handleBoxClick(state)}>
                                                      <span className='box-text'>{state.name}</span>
                                                      <div className="countCircle">{state.count}</div>
                                                  </Tag>
                                              ) : null
                                            ) : null}
                                        </div>
                                      </div>
                                </div>
                            </div>

                            <TableComp
                                columns={columns}
                                ref={tableRef}
                                pagination={DefaultTablePagination()}
                                apiRequest={apiRequest}
                                extraProps={{scroll: {x: 1000}}}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {applicationState.visible ? (
                <ViewApplication
                    {...applicationState}
                    pageType={'allApplication'}
                    onClose={() => events.hideApplicationDrawer()}
                />
            ) : null}
        </>
    )
}
export default CallbackList