import React, {useEffect, useState} from 'react'
import {Button, Drawer, Select, Form, notification, Row, Col, DatePicker} from 'antd'
import {InputBox} from '../../../../components/_utils/appUtils'
import {LoadAllCountry, LoadState} from '../../../../components/_utils/countryUtil'
import {useDispatch} from 'react-redux'
import {addIcefEventNewFxn, icefCountryListFxn} from '../../actions'
import _ from 'lodash'
import AddCountryDrawer from "../../country/addCountryDrawer";

const {Option} = Select;
const dateFormat = 'DD/MM/YYYY'

let initState = {
    eventName: '',
    startDate: '',
    endDate: '',
    country: '',
    state: '',
    city: '',
    person: ''
}
const AddIcefEvents = (props) => {
    let dispatch = useDispatch()
    let [state, setState] = useState(initState)
    let [countryPhoto, setCountryPhoto] = useState('')
    let [countryList, setCountryList] = useState([])
    let [stateList, setStateList] = useState([])
    let [visible, setVisible] = useState(false)

    let events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        loadCountry: async () => {
            let obj = {
                results: 1000,
                count: 1000
            }
            let {data} = await dispatch(icefCountryListFxn(obj))
            setCountryList(data)
        },
        chooseCountry: async (countryName) => {
            const selectedCountry = countryList.find((country) => country.countryName === countryName);
            if (selectedCountry) {
                let states = await LoadState(selectedCountry._id);
                setStateList(states);
                setState((prevState) => ({
                    ...prevState,
                    country: selectedCountry.countryName,
                    state: ''
                }));
            }
        },
        chooseState: (stateName) => {
            setState((prevState) => ({
                ...prevState,
                state: stateName
            }));
        },
        handleSubmit: async () => {
            if (!state.eventName) {
                notification.warning({message: 'Enter event'})
                return
            }
            if (!state.startDate) {
                notification.warning({message: 'Choose Start Date'})
                return
            }
            if (!state.endDate) {
                notification.warning({message: 'Choose End Date'})
                return
            }
            if (!state.country) {
                notification.warning({message: 'Enter Country'})
                return
            }
            if (!state.state) {
                notification.warning({message: 'Enter State'})
                return
            }
            if (!state.city) {
                notification.warning({message: 'Enter City'})
                return
            }
            if (!state.person) {
                notification.warning({message: 'Enter Contact Person Name'})
                return
            }
            if (!countryPhoto || (countryPhoto && !countryPhoto.name)) {
                notification.warning({message: 'Choose Banner'})
                return
            }

            const formData = new FormData()
            let obj = _.clone(state)
            formData.append('obj', JSON.stringify(obj))

            if (countryPhoto && countryPhoto.name) {
                formData.append('countryPhoto', countryPhoto)
            }

            let resp = await dispatch(addIcefEventNewFxn(formData))
            if (resp && resp.success) {
                setCountryPhoto('')
                events._updateState(initState)
            }
        },
        showAddCountry: () => {
            setVisible(true)
        },
        hideAddCountry: () => {
            setVisible(false)
        },
        onSubmit: () => {
            setVisible(false)
            events._updateState({country: null})
            events.loadCountry()
        }
    }

    useEffect(() => {
        events.loadCountry()
    }, [])

    return (
        <>
            <div>
                <div className='form-box mt-4'>
                    <div className='d-flex align-items-center mb-3 heading-form'>
                        <h5>Add Event</h5>
                    </div>
                    <div className='card unizportal'>
                        <Form>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <InputBox title={'Event Name*'}>
                                        <input
                                            className={'form-control'}
                                            placeholder={'Event Name'}
                                            value={state.eventName}
                                            onChange={({target}) => {
                                                events._updateState({eventName: target.value})
                                            }}
                                        />
                                    </InputBox>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={8}>
                                    <InputBox title={'Person*'}>
                                        <input
                                            className={'form-control'}
                                            placeholder={'Person'}
                                            value={state.person}
                                            onChange={({target}) => {
                                                events._updateState({person: target.value})
                                            }}
                                        />
                                    </InputBox>
                                </Col>
                                <Col span={8}>
                                    <InputBox title={'Start Date*'}>
                                        <DatePicker
                                            format={dateFormat}
                                            className={'form-control'}
                                            value={state.startDate}
                                            onChange={(value) => {
                                                events._updateState({startDate: value})
                                            }}
                                        />
                                    </InputBox>
                                </Col>
                                <Col span={8}>
                                    <InputBox title={'End Date*'}>
                                        <DatePicker
                                            format={dateFormat}
                                            className={'form-control'}
                                            value={state.endDate}
                                            onChange={(value) => {
                                                events._updateState({endDate: value})
                                            }}
                                        />
                                    </InputBox>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={8}>
                                    <InputBox title={'Country Name*'} extra={(
                                        <a onClick={events.showAddCountry}>Add Country</a>
                                    )}>
                                        <Select
                                            placeholder={'Country Name'}
                                            value={state.country}
                                            onChange={(value) => {
                                                events.chooseCountry(value);
                                            }}
                                        >
                                            {countryList.map((country) => (
                                                <Option key={country.id} value={country.countryName}>
                                                    {country.countryName}
                                                </Option>
                                            ))}
                                        </Select>
                                    </InputBox>
                                </Col>
                                <Col span={8}>
                                    <InputBox title={'State*'}>
                                        <input
                                            className={'form-control'}
                                            placeholder={'State'}
                                            value={state.state}
                                            onChange={({target}) => {
                                                events._updateState({state: target.value})
                                            }}
                                        />
                                    </InputBox>
                                </Col>
                                <Col span={8}>
                                    <InputBox title={'City*'}>
                                        <input
                                            className={'form-control'}
                                            placeholder={'City'}
                                            value={state.city}
                                            onChange={({target}) => {
                                                events._updateState({city: target.value});
                                            }}
                                        />
                                    </InputBox>
                                </Col>
                            </Row>


                            <Row gutter={16}>

                                <Col span={8}>
                                    <InputBox title={'Country Photo*'}>
                                        <input
                                            type='file'
                                            className={'form-control'}
                                            onChange={({target}) => {
                                                setCountryPhoto(target.files[0])
                                            }}
                                        />
                                    </InputBox>
                                </Col>
                            </Row>

                            <Button type='primary' htmlType='submit' className='btn' onClick={events.handleSubmit}>
                                Submit
                            </Button>
                        </Form>
                    </div>
                </div>

                {visible ?
                    <AddCountryDrawer
                        visible={visible}
                        onClose={events.hideAddCountry}
                        onSubmit={events.onSubmit}
                    /> : ""}
            </div>
        </>
    )
}
export default AddIcefEvents
