import React, { useEffect, useRef, useState } from 'react'
import { Form, Row, Col, Tooltip, Popconfirm, Icon, notification } from 'antd'
import { useDispatch } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { addIndianCity, listAllIndianCity, deleteIndianCity } from '../actions'
import { TableComp } from 'sz-react-utils-lite'
import EditIndianCity from '../drawers/editIndianCity'
import { getCitiesFxn, getStatesFxn } from '../../dailyExpense/actions/dailyExpense'

const IndianCityComponent = (props) => {
  const { form: { getFieldValue } } = props
  let tableRef = useRef()
  let [pageState, setPageState] = useState({
    page: 1,
    results: 0
  })
  const { form: { getFieldDecorator } } = props
  const dispatch = useDispatch()
  let [cityObj, setCityObj] = useState({
    visible: false,
    data: {}
  })

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const submitIndianCity = (e) => {
    let { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let data = await dispatch(addIndianCity(valData))
        if (data && !data.error) {
          form.resetFields()
          events.reload()
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.sortField = '_id'
      params.sortOrder = 'descend'
      params.results = 50
      params.count = 50
      let resp = await listAllIndianCity({ ...params, regExFilters: ['stateName', 'name', 'area', 'pinCode'] })
      setPageState({
        page: params.page ? params.page : 1,
        results: params.results
      })
      resolve(resp)
    })
  }

  const confirmDelete = async (id) => {
    let data = await dispatch(deleteIndianCity(id))
    if (data) {
      events.reload()
    }
  }

  const events = {
    showEditDrawer: (data) => {
      setCityObj({
        visible: true, data
      })
    },
    hideEditDrawer: () => {
      setCityObj({
        visible: false,
        data: {}
      })
    },
    reload: () => {
      tableRef.current.reload()
    }
  }

  const [indianStates, setIndianStates] = useState([])
  const [indianCities, setIndianCities] = useState([])

  const getIndianStates = async () => {
    let data = await dispatch(getStatesFxn({ results: 1000 }))
    setIndianStates(data)
  }
  const getIndianCities = async (params = {}) => {
    if (getFieldValue('stateName')) {
      params.state = getFieldValue('stateName')
    }
    let data = await dispatch(getCitiesFxn({ results: 1000, ...params }))
    setIndianCities(data)
  }

  useEffect(() => {
    getIndianStates()
  }, [])

  useEffect(() => {
    if (getFieldValue('stateName')) {
      getIndianCities()
    }
  }, [getFieldValue('stateName')])


  let inputField = [
    {
      key: 'stateName',
      label: 'State',
      required: true,
      placeholder: 'State Name',
      type: 'select',
      showSearch: true,
      allowClear: true,
      options: indianStates,
      keyAccessor: x => x.name,
      valueAccessor: x => `${x.name}`,
      onChange: x => {
        props.form.setFieldsValue({
          name: '',
          cityType: '',
          stateName: x
        })
      },
      span: 5
    },
    {
      key: 'cityType',
      label: 'City Type',
      required: true,
      placeholder: 'City Name',
      showSearch: true,
      type: 'select',
      options: ['Select', 'New'],
      keyAccessor: x => x,
      valueAccessor: x => `${x}`,
      onChange: x => {
        props.form.setFieldsValue({
          cityType: x,
          name: ''
        })
      },
      span: 3
    },
    {
      key: 'name',
      allowClear: true,
      label: 'Choose City',
      required: true,
      placeholder: 'City Name',
      showSearch: true,
      type: 'select',
      hidden: !(getFieldValue('cityType') && getFieldValue('cityType') == 'Select'),
      options: indianCities,
      keyAccessor: x => x.name,
      valueAccessor: x => `${x.name}`,
      onChange: x => {
        props.form.setFieldsValue({
          name: x
        })
      },
      span: 5
    },
    {
      key: 'name',
      label: 'Enter City',
      required: true,
      placeholder: 'City Name',
      type: 'text',
      hidden: !(getFieldValue('cityType') && getFieldValue('cityType') == 'New'),
      span: 5
    },
    {
      key: 'area',
      type: 'text',
      label: 'Area',
      span: 4,
      required: true
    },
    {
      key: 'pinCode',
      type: 'text',
      label: 'City Pin Code',
      span: 4,
      required: true
    }
  ]

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 80,
      key: 'sno',
      render: (text, record, index) => {
        let currentIndex = parseInt(pageState.page - 1) * parseInt(pageState.results)
        return (
          <>
            {currentIndex + index + 1}
          </>
        )
      }
    },
    {
      title: 'State Name',
      dataIndex: 'stateName',
      key: 'stateName',
      searchTextName: 'state'
    },
    {
      title: 'City Name',
      dataIndex: 'name',
      key: 'name',
      searchTextName: 'city'
    },
    {
      title: 'Area',
      dataIndex: 'area',
      key: 'area',
      searchTextName: 'area'
    },
    {
      title: 'Pin Code',
      dataIndex: 'pinCode',
      key: 'pinCode',
      searchTextName: 'pinCode'
    },


    {
      key: 'actions',
      title: 'Actions',
      height: 100,
      render: (text, record) => {
        return (
          <div className={'btn_group'}>
            <Tooltip title='Edit'>
              <a onClick={() => {
                events.showEditDrawer(record)
              }}
                 className={'btn'}
                 style={{ marginRight: 6 }}>
                <Icon type={'edit'} />
              </a>
            </Tooltip>
            <Tooltip title='Delete City'>
              <Popconfirm title={'Are your sure, you want to delete this city?'}
                          onConfirm={() => {
                            confirmDelete(record._id)
                          }}
                          okText='Yes' cancelText='No'>
                <button
                  className={'btn'}
                  style={{ marginRight: 6 }}>
                  <Icon type={'delete'} />
                </button>
              </Popconfirm>
            </Tooltip>
          </div>

        )
      }
    }
  ]

  return (
    <div className={'mt-4'}>
      <div className='row'>
        <div className='col-lg-12'>

          <div className='form-box'>
            <div className='d-flex align-items-center mb-3 heading-form'>
              <h5>Add Indian City</h5>
            </div>
            <div className='card unizportal'>
              <Form className={'vertical-form'} autoComplete='off' onSubmit={submitIndianCity}>
                <Row gutter={24} className={'rowWrap pt-3'}>
                  {inputField.map((item, key) => {
                    return (
                      !item.hidden ? <React.Fragment key={key}>
                        <Col span={item.span ? item.span : 4} key={key}>
                          <GetEachFormFields
                            item={item}
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout} />
                        </Col>
                      </React.Fragment> : null
                    )
                  })}
                  <Col span={8}>
                    <button className='btn mt10' type='submit'>save
                    </button>
                  </Col>
                </Row>
              </Form>
              <div className='card-body table-responsive pa-0'>
                <TableComp columns={columns}
                           className={'table table-striped table-valign-middle'}
                           apiRequest={apiRequest}
                           pagination={{
                             position: 'top',
                             pageSizeOptions: ['50', '100', '200', '500'],
                             defaultPageSize: 50,
                             current: pageState.page ? pageState.page : 1
                           }}
                           ref={tableRef}
                />
              </div>

            </div>
          </div>
        </div>
      </div>
      {cityObj.visible ?
        <EditIndianCity
          {...cityObj}
          onClose={events.hideEditDrawer}
          onSubmit={() => {
            events.hideEditDrawer()
            events.reload()
          }} /> : null}
    </div>
  )
}


const WrappedAddTarget = Form.create()(IndianCityComponent)
export default WrappedAddTarget


