import {notification} from 'antd'
import {getToken, customAxios as axios} from '../../../request'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'
import { eventListUrl, eventListExportUrl, approveLeadUrl, eventLeadExportUrl, cancelLeadUrl } from '../api/event'

export const eventList = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}
    }
    let {data} = await axios.get(eventListUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const eventListExport = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(eventListExportUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}
export const approveLeadFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(approveLeadUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    } else {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data
}
export const eventLeadExportFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let { data } = await axios.post(eventLeadExportUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}
export const cancelLeadFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let { data } = await axios.post(cancelLeadUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    } else {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data
}
