import { Col, Drawer, Form, Input, notification, Row } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { InputBox } from '../../../../components/_utils/appUtils'
import { RowTable } from '../../../../components/_utils/RowTable'
import moment from 'moment'
import { uploadNationalCollegeDocumentFxn } from '../../actions/nationalCollege'

const UploadCornerStoneDocument = props => {
  let dispatch = useDispatch()
  let {
    visible,
    onClose,
    applicationId,
    studentId,
    reloadTable,
    studentData,
    documentName,
    name
  } = props
  let [document, setDocument] = useState({})
  let [uploadKey, setUploadKey] = useState(moment())
  const events = {
    chooseDocument: e => {
      let { name, files } = e.target
      if (files && files.length) {
        setDocument(files[0])
      }
    },
    handleSubmit: async () => {
      let fd = new FormData()
      let obj = {
        studentId,
        applicationId
      }

      if (!document || (document && !document.name)) {
        return notification.warn({ message: `Please choose ${name}` })
      }
      fd.append('obj', JSON.stringify(obj))

      if (document && document.name) {
        fd.append(documentName, document)
      }

      let data = await dispatch(uploadNationalCollegeDocumentFxn(fd))
      if (data && data.success) {
        onClose()
        reloadTable()
        setUploadKey(moment())
      }
    }
  }
  return (
    <Drawer
      visible={visible}
      title={`Upload ${name}`}
      onClose={onClose}
      width={'40%'}>
      <div className='row'>
        <div className='col-lg-12'>
          <Form className={'vertical-form'} autoComplete='off'>
            <div className='form-box'>
              <div className='card unizportal'>
                {studentData &&
                studentData.applications &&
                studentData.applications.id ? (
                  <div className={'tableBox striped'}>
                    <RowTable
                      title={'Name (ID)'}
                      value={
                        <span>
                          {studentData.name} ({studentData.studentId})
                        </span>
                      }
                    />
                    <RowTable
                      title={'Application ID'}
                      value={<span>{studentData.applications.id}</span>}
                    />
                    <RowTable
                      title={'Application'}
                      value={<span>{studentData.applications.courseName}</span>}
                    />
                  </div>
                ) : null}
                <br />

                <div className='inner-form'>
                  <Row gutter={24} key={uploadKey}>
                    <Col span={24}>
                      <InputBox title={`${name} Document`}>
                        <Input
                          type={'file'}
                          name={documentName}
                          id={documentName}
                          className={'form-control'}
                          onChange={e => {
                            events.chooseDocument(e)
                          }}
                        />
                      </InputBox>
                    </Col>
                  </Row>

                  <button
                    className='btn'
                    type='submit'
                    onClick={events.handleSubmit}>
                    save
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Drawer>
  )
}
export default UploadCornerStoneDocument
