import { apiUrl } from "../../../settings";

export const addCityUrl = () => {
    return apiUrl + '/city';
};

export const listCitiesUrl = () => {
    return apiUrl + '/city';
};

export const listCityUrl = (id) => {
      return apiUrl + '/city/' + id;
};