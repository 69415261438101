import { Button, Card, Drawer, Input, Form, notification } from 'antd'
import { InputBox } from '../../../components/_utils/appUtils'
import React, { useState } from 'react'
import moment from 'moment'
import { connect, useDispatch } from 'react-redux'
import { uploadCompanyDocument } from '../actions/company'

const initialState = {
  documentDrawerVisible: false,
  description: '',
  document: {},
  documentKey: moment()
}
const { TextArea } = Input
const UploadDocumentComponent = (props) => {
  const dispatch = useDispatch()
  let [state, setState] = useState(initialState)
  let [documents, setDocuments] = useState([])
  let [title, setTitle] = useState('')
  let { companyId, visible, onClose } = props

  const events = {
    chooseDocument: (e) => {
      let { name, files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          document: files[0]
        })
      }
    },
    handleSubmit: async () => {
      let { document } = state
      if (!title) {
        return notification.error({
          message: 'Please enter name'
        })
      }
      if (!document || (document && !document.name)) {
        return notification.error({
          message: 'Please choose document'
        })
      }
      let obj = {
        _id: companyId,
        description: state.description,
        documentType: title
      }
      let fd = new FormData()
      fd.append('obj', JSON.stringify(obj))
      fd.append('document', state.document)
      let data = await dispatch(uploadCompanyDocument(fd))
      if (data && !data.error) {
        if (state.description && data.noteId) {
          dispatch({ type: 'START_PUSH_NOTE_BY_REDUX', noteId: data.noteId })
        }
        setState({
          ...state,
          description: '',
          document: '',
          documentKey: moment(),
          documentDrawerVisible: false
        })
        onClose()
        dispatch({ type: 'START_LOAD_DOCUMENT' })
        dispatch({ type: 'START_LOAD_ACTIVITY' })

      }
    }
  }

  return (
    <Drawer width={500}
            title={'Upload Documents'}
            placement="right"
            visible={visible}
            onClose={onClose}>
      <Card>
        <Form>
          <InputBox title={`Name`}>
            <Input name={'title'} id={'title'} value={title}
                   onChange={(e) => {
                     setTitle(e.target.value)
                   }}/>
          </InputBox>
          <InputBox title={`Document`}>
            <Input type={'file'} name={'document'} id={'document'} key={state.documentKey}
                   onChange={(e) => {
                     events.chooseDocument(e)
                   }}/>
          </InputBox>
          <InputBox title={'Description'}>
              <TextArea rows={4} name={'description'} id={'description'} placeholder={'Description'}
                        value={state.description}
                        onChange={(e) => {
                          setState({
                            ...state,
                            description: e.target.value
                          })
                        }}/>
          </InputBox>
          <Form.Item className={'mt20'}>
            <Button type="primary" onClick={events.handleSubmit}>
              SUBMIT
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  )
}


const mapStateToProps = ({ global, router, chooseApplication }) => ({})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(UploadDocumentComponent)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedComponent)

