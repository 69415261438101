import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Button, Icon, Popconfirm, Pagination } from 'antd'
import { addAppliedEventFxn, appliedEventListFxn } from '../actions/student'
import { instituteEventListFxn } from '../../institutes/action'
import { displayTime } from '../../../components/_utils/appUtils'

const RegisteredEvents = ({ currentUser, dispatch }) => {
  const [appliedEvents, setAppliedEvents] = useState([])
  const [eventList, setEventList] = useState([])
  const [totalInstitutes, setTotalInstitutes] = useState(0)
  const eventDateColors = ['#e8f4f8', '#e3e2c9', '#e1cfe3', '#edf4e0']

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventsResponse = await dispatch(instituteEventListFxn({ page: 1, count: 20, status: 'Approved' }))
        setEventList(eventsResponse.data)
        setTotalInstitutes(eventsResponse.total)

        const appliedResponse = await dispatch(appliedEventListFxn({
          results: 50,
          count: 50,
          studentId: currentUser._id
        }))
        setAppliedEvents(appliedResponse.data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [currentUser._id, dispatch])


  const getRegistrationInfo = (eventId) => {
    const appliedEvent = appliedEvents.find((event) => event.eventId === eventId)
    if (appliedEvent) {
      return moment(appliedEvent.createdAt).format('Do MMM YY, h:mm A')
    }
    return null
  }

  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center all-student-search'>
            <h5>My Registered Events</h5>
          </div>
          <div className='card-body table-responsive'>
            <section className='blog-innovation webinar-info'>
              <div className='row'>
                {appliedEvents.map((appliedEvent) => {
                  const eventDetails = eventList.find((event) => event._id === appliedEvent.eventId)
                  if (!eventDetails) return null

                  const eventColor = eventDateColors[eventList.findIndex((event) => event._id === appliedEvent.eventId) % eventDateColors.length]

                  return (
                    <div key={appliedEvent._id} className='col-lg-12 col-md-4'>
                      <div className='product-teaser event-teaser event-teaser1'>
                        <div className='event-content-wrap1'>
                          <div className='event-content-container'>
                            <div className='event-content'>
                              <div className='event-meta'>
                                <div className='event-date-wrap1'>
                                  <div className='event-date1'>
                                <span
                                  className='event-date-day1'
                                  style={{
                                    backgroundColor: eventColor

                                  }}
                                >
                                  {moment(eventDetails.date).format('Do MMM YY')}
                                  <br />
                                  <p className={'event-date-time1'}>{displayTime(eventDetails.time)}</p>
                                </span>
                                  </div>
                                </div>
                                <div className='event-venue-wrap1'>
                                  <div className='event-title event-title1 fontLg'>{eventDetails.venue}</div>
                                  <div className='event-location mt5'>
                                    <div className='event-details-text'>
                                      <i style={{ fontSize: '12px' }}>{eventDetails.details}</i>
                                    </div>
                                    <div className='font14 mb5'>
                                      {eventDetails.city}, {eventDetails.state}
                                    </div>
                                  </div>
                                </div>
                                <div className='ml20 aic event-button'>
                                  <div className='registered-info'>
                                    <label className={'label label-success-green'}>
                                      <Icon type={'check'} style={{ marginRight: '8px', paddingTop: '5px' }} /> Already
                                      Registered
                                    </label>
                                    <p style={{ fontSize: '10px' }} className='mt10'>
                                      Reg. Date: {getRegistrationInfo(eventDetails._id)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className='col-md-12'>
                <div className='mt20 alignRight'>
                  <Pagination
                    defaultCurrent={1}
                    total={totalInstitutes}
                    onChange={(page) => {
                      dispatch(instituteEventListFxn({ page, count: 20, status: 'Approved' }))
                    }}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ global }) => ({
  currentUser: global.currentUser
})

export default connect(mapStateToProps)(RegisteredEvents)
