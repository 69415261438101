import React from 'react'
import { SingleUniBlock } from './singleBlocks'
import { Icon, Empty } from 'antd'

class DefaultUniversity extends React.Component {
  constructor() {
    super()
    this.state = {
      emptyList: false
    }
  }


  render() {
    let { featuredUniversity, uniCountObj, events, isEmpty } = this.props
    return (
      <React.Fragment>
        {
          featuredUniversity.ukUni && featuredUniversity.ukUni.length ?
            <div className='academy-box featureUniversityDiv'>

              <div className='heading-form d-flex align-items-center'>
                <h5>Educators in United Kingdom</h5>

              </div>

              <div className='card cardRelative pb-0'>
                <div className='row uniBox courseBox'>
                  {featuredUniversity.ukUni.map((item, key) => {
                    return (
                      <SingleUniBlock key={key} item={item} callBack={() => {
                        events.showFeaturedDrawer(item)
                      }} />
                    )
                  })}

                </div>
              </div>
            </div> : null
        }
        {
          featuredUniversity.canadaUni && featuredUniversity.canadaUni.length ?
            <div className='academy-box featureUniversityDiv'>

              <div className='heading-form d-flex align-items-center'>
                <h5>Educators in Canada</h5>

              </div>

              <div className='card cardRelative pb-0'>
                <div className='row uniBox courseBox'>
                  {featuredUniversity.canadaUni.map((item, key) => {
                    return (
                      <SingleUniBlock key={key} item={item} callBack={() => {
                        events.showFeaturedDrawer(item)
                      }} />
                    )
                  })}

                </div>
              </div>
            </div> : null
        }
        {
          featuredUniversity.australiaUni && featuredUniversity.australiaUni.length ?
            <div className='academy-box featureUniversityDiv'>

              <div className='heading-form d-flex align-items-center'>
                <h5>Educators in Australia</h5>

              </div>

              <div className='card cardRelative pb-0'>
                <div className='row uniBox courseBox'>
                  {featuredUniversity.australiaUni.map((item, key) => {
                    return (
                      <SingleUniBlock key={key} item={item} callBack={() => {
                        events.showFeaturedDrawer(item)
                      }} />
                    )
                  })}

                </div>
              </div>
            </div> : null
        }
        {
          featuredUniversity.usaUni && featuredUniversity.usaUni.length ?
            <div className='academy-box featureUniversityDiv'>

              <div className='heading-form d-flex align-items-center'>
                <h5>Educators in USA</h5>

              </div>

              <div className='card cardRelative pb-0'>
                <div className='row uniBox courseBox'>
                  {featuredUniversity.usaUni.map((item, key) => {
                    return (
                      <SingleUniBlock key={key} item={item} callBack={() => {
                        events.showFeaturedDrawer(item)
                      }} />
                    )
                  })}

                </div>
              </div>
            </div> : null
        }

        {isEmpty ?
          <div className={'mt20'}>
            <Empty description={'No record found.'} />
          </div>
          : null}

      </React.Fragment>
    )
  }
}

export default DefaultUniversity
