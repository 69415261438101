import { customAxios as axios, getToken } from '../../../request'
import {
  getAllCountriesUrl,
  getMarketingUserApplicationRecordUrl,
  getPublicUniversityApplicationRecordUrl
} from '../apis/api'
import { hideLoader, hidePageLoad, showLoader, showPageLoad } from '../../../modules/actions'

export const getAllCountriesFxn =  (valData) => async dispatch => {
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  dispatch(showPageLoad())
  const data =  axios.get(getAllCountriesUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const getMarketingUserApplicationRecordFxn =  (valData) => async dispatch => {
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  dispatch(showLoader())
  const data =  axios.get(getMarketingUserApplicationRecordUrl(), config)
  dispatch(hideLoader())
  return data
}
export const getPublicUniversityApplicationsData =  (valData) => async dispatch => {
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  dispatch(showLoader())
  const data =  axios.get(getPublicUniversityApplicationRecordUrl(), config)
  dispatch(hideLoader())
  return data
}