import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Button,
  Card,
  Col,
  Drawer,
  Form, Icon,
  Input,
  Modal,
  notification,
  Row,
  Tabs
} from 'antd'
import { useDispatch } from 'react-redux'
import { displayDate, displayTime, nameAvatar } from '../../../components/_utils/appUtils'
import {
  activityListFxn,
  singleInstituteEventFxn,
  slotListFxn,
  updateInstituteEventDateFxn,
  updateInstituteEventStatusFxn
} from '../../institutes/action'
import '../style.css'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import moment from 'moment'
import styles from '../../IcefEvent/styles.less'
import '../../../../src/common.less'
import {
  addComplaintFeedbackFxn,
  addFeedbackCommentFxn,
  getFeedbackCommentsFxn,
  singleFeedbackFxn
} from '../actions/feedbackActions'

const { TabPane } = Tabs

const AddCommentDrawer = (props) => {
  let {
    onClose,
    visible,
    currentUser,
    feedbackId,
    setDrawerOpen,
    setState,
    setUpdateEventSlot,
    setComplaintId
  } = props
  const form = props.form
  const [comments, setComments] = useState('')
  let [commentState, setCommentState] = useState({
    data: [],
    total: 0,
    page: 1,
    count: 50
  })
  let [singleFeedback, setSingleFeedback] = useState({})
  let dispatch = useDispatch()

  const loadComments = async () => {
    let params = {}
    params.results = commentState.count
    params.count = commentState.count
    params.page = commentState.page
    params.feedbackId = feedbackId
    const resp = await dispatch(getFeedbackCommentsFxn(params))
    setCommentState((prevData) => {
      return {
        ...prevData,
        data: resp.data,
        total: resp.total
      }
    })
  }

  const handleSubmitComment = async (feedbackId, comments) => {
    if (!comments) {
      console.log('Comment cannot be empty.')
      return
    }
    await dispatch(addFeedbackCommentFxn(feedbackId, comments))
    await loadComments()
    setComments('')
  }


  const handleComplaintFeedback = async (feedbackId, agentFeedbackStatus) => {
    await dispatch(addComplaintFeedbackFxn(feedbackId, agentFeedbackStatus))
    await loadComments()
    setComments('')
    setUpdateEventSlot(
      {
        visible: false,
        feedbackId: ''
      }
    )
    setComplaintId('')
  }

  const fetchFeedbackData = async () => {
    try {
      const { data } = await dispatch(singleFeedbackFxn({ feedbackId }))
      setSingleFeedback(data)
    } catch (error) {
      console.error('Error fetching institute event data:', error)
    }
  }


  useEffect(() => {
    fetchFeedbackData()
    loadComments()
  }, [feedbackId])

  return (
    <Drawer
      title={'Complaint Details'}
      placement='right'
      closable={true}
      onClose={onClose}
      visible={visible}
      width='65%'>
      <>
        <div className={styles.complaint_card}>
          {singleFeedback && singleFeedback._id ?
            <Row gutter={16}>
              <Col span={24}>
                <div className={styles.info_card}>
                  <div className={styles.information}>
                    <div style={{ flex: 1 }}>
                      <div className={'jcsb'}>
                        <div className={styles.complaintBox}>
                          <h5 style={{ fontSize: 13 }}>{singleFeedback.feedback}</h5>
                        </div>
                      </div>

                      <div className={styles.intoBox}>
                        <div className={'row'}>
                          <div className={'col-md-8'}>
                            <div className={'align_center'} key={singleFeedback.createdAt}>
                              Date/Time
                              : &nbsp; {displayDate(singleFeedback.createdAt)}, {displayTime(singleFeedback.createdAt)}
                            </div>
                            <div className={styles.alignCenter}>
                              Added By : &nbsp; {singleFeedback.userId.name}
                            </div>
                            <div className={styles.alignCenter}>
                              Email : &nbsp; {singleFeedback.userId.email}
                            </div>
                            <div className={styles.alignCenter}>
                              Contact : &nbsp; {singleFeedback.userId.mobile}
                            </div>
                            <div className={'aic'}>

                            </div>
                          </div>
                          <div className={'col-md-4 alignRight'}>
                            <div className={'ml20'}>
                              {
                                (singleFeedback.status !== 'Resolve' && singleFeedback.assignedTo && singleFeedback.assignedTo._id === currentUser._id) ||
                                (singleFeedback.status !== 'Resolve' && currentUser.userType === 'admin') &&
                                (
                                  <a className={'btn btn-success btn-sm'}
                                     onClick={() => {
                                       setState({ feedbackId: feedbackId })
                                       setDrawerOpen(true)
                                     }}
                                  >Resolve</a>
                                )}
                              {singleFeedback && singleFeedback.agentFeedbackStatus && (
                                <>
                                  {singleFeedback.agentFeedbackStatus === 'satisfied' ? (
                                    <div className={'d-flex row'}>
                                      <strong>Feedback : &nbsp;</strong> <p>Agent is Satisfied</p>
                                    </div>
                                  ) : (
                                    <div className={'d-flex row'}>
                                      <strong>Feedback : &nbsp;</strong> <p>Agent is Not Satisfied</p>
                                    </div>
                                  )}

                                  {/*   <button className={'btn btn-success-green border round lg ml-2'} onClick={() => {
                                    handleComplaintFeedback(feedbackId, 'satisfied')
                                  }}>Satisfied 🙂
                                  </button>
                                  <button className={'btn btn-danger border round lg ml-2'} onClick={() => {
                                    handleComplaintFeedback(feedbackId, 'unsatisfied')
                                  }}>Not Satisfied ☹️
                                  </button>*/}

                                </>
                              )}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div>
                    <Tabs defaultActiveKey='1'>
                      <TabPane tab='Comments' key='1' className={styles.comments}>
                        <div className='comment-section'>
                          <div className='comment-wrap-new' style={{ overflowY: 'auto' }}>
                            {commentState.data && commentState.data.length ? commentState.data.map(comment => (
                              <>
                                <div className='comment' key={comment._id}>
                                  <Avatar className={'textUpp avatar_text1'} size='large'>
                                    {nameAvatar(comment.createdByUser.name)}
                                  </Avatar>
                                  <div className={styles.comment_text}>
                                    <p className='comment-author'>
                                      <span className={'name'}><strong>{comment.createdByUser.name}</strong>
                                        <span
                                          className='date1'>{displayDate(comment.createdAt)} | {displayTime(comment.createdByUser.time)}</span>
                                      </span>
                                    </p>
                                    <div className={'mb5'}>
                                      {comment.comment}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )) : null}
                          </div>

                          {singleFeedback.status !== 'Resolve' && (
                            <div className={styles.input_box}>
                              <Avatar className={'textUpp avatar_text1'} size='large'>
                                {nameAvatar(currentUser.name)}
                              </Avatar>
                              <Input
                                placeholder='Add Comment' className={styles.input_comment1}
                                value={comments}
                                onChange={(e) => setComments(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    handleSubmitComment(feedbackId, comments)
                                  }
                                }}
                              />
                              <i className='fa fa-paper-plane fa-lg ml-2'
                                 aria-hidden='true'
                                 style={{ cursor: 'pointer' }}
                                 onClick={() => {
                                   handleSubmitComment(feedbackId, comments)
                                 }}
                              />
                            </div>
                          )}
                          {singleFeedback.status == 'Resolve' && !singleFeedback.agentFeedbackStatus &&
                          singleFeedback.userId && (singleFeedback.userId._id == currentUser._id) ? (
                            <>
                              <div className={styles.footer}>
                                <div className='d-flex gap-2 aic'>
                                  <strong className={'fs-2'}>Give Feedback : </strong>
                                  <button className={'btn btn-success-green border round lg ml-2'} onClick={() => {
                                    handleComplaintFeedback(feedbackId, 'satisfied')
                                  }}>Satisfied 🙂
                                  </button>
                                  <button className={'btn btn-danger border round lg ml-2'} onClick={() => {
                                    handleComplaintFeedback(feedbackId, 'unsatisfied')
                                  }}>Not Satisfied ☹️
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </TabPane>
                    </Tabs>
                  </div>
                </div>
              </Col>
              <Col span={8}>
              </Col>
            </Row>
            : null}
        </div>
      </>


    </Drawer>
  )
}

const WrappedAddEventDrawer = Form.create()(AddCommentDrawer)
export default WrappedAddEventDrawer
