import React, { useState, useEffect } from 'react'
import {
  ukApplicationSteps,
  canadaApplicationSteps,
  displayDate,
  longDisplayDate,
  ApplicationDeskList
} from '../../../components/_utils/appUtils'
import { Button, Tooltip } from 'antd'
import lodash from 'lodash'
import moment from 'moment'

const statusComponent = (props) => {
  let [statusList, setStatusList] = useState([])
  let [currentStatusIndex, setCurrentStatusIndex] = useState(0)
  let { applications, createdAt, completeStatusList } = props
  useEffect(() => {
    statusListFxn()
  }, [])

  const matchStatusAndPushDate = (statusList, applications) => {
    statusList.forEach(status => {
      applications.statusList.forEach(appStatus => {
        if (status.name == appStatus.status) {
          status.date = appStatus.date;
        }
      });
    });
    return statusList;
  }

  const statusListFxn = () => {
    if (applications && applications.universityCountry && applications.universityCountry.countryName) {
      let { countryName } = applications.universityCountry
      let statusList = ApplicationDeskList(countryName, applications.statusList)
      const finalStatusList = matchStatusAndPushDate(statusList, applications)
      let currentStatusIndex = statusList.map(x => x.name).indexOf(applications.status)
      setStatusList(finalStatusList)
      setCurrentStatusIndex(currentStatusIndex)
    }
  }


  const getStatusDate = (status) => {
    let { statusList } = applications
    if (status == 'New Student') {
      return longDisplayDate(createdAt)
    } else {
      let findedDate = lodash.find(statusList, (item) => {
        return item.status == status
      })
      if (findedDate && findedDate.date) {
        return longDisplayDate(findedDate.date)
      } else {
        return null
      }
    }
  }

  return (
    <React.Fragment>
      {/*<div className={'statusDiv'}>
        {applications && applications.status && statusList && statusList.length ? statusList.map((item, key) => {
          return (
            <Tooltip title={getStatusDate(item.name) ? getStatusDate(item.name) : ''} key={key}>
              <div className={`statusBox ${item.name === applications.status ? 'bgTheme' : ''}`}
                   key={key}>
                <div>
                  {item.name}
                  {currentStatusIndex > key ?
                    <span className={'icon'}>
                <img src="/dist/img/check.svg" alt=""/>
              </span> : null}
                </div>
              </div>
            </Tooltip>
          )
        }) : null}
      </div>*/}

      <div className="application-current-status">
        <h5>application current status</h5>


        <ul>
          {applications && applications.status && statusList && statusList.length ? statusList.map((item, key) => {
            return (
              <Tooltip title={getStatusDate(item.name) ? getStatusDate(item.name) : ''} key={key}>
                <li className={`statusBox ${item.name == applications.status ? 'bgTheme' : ''}`}
                    key={key}>
                  <React.Fragment>
                    <div>
                      {item.name}
                      <b className={'application-date'}>
                        {item && item.date && ` - ${moment(item.date).format('DD/MM/YYYY')}`}
                      </b>
                    </div>
                    {currentStatusIndex > key ?
                      <img src="/dist/img/check.svg" alt=""/> : null}
                  </React.Fragment>
                </li>
              </Tooltip>
            )
          }) : null}
        </ul>
      </div>
    </React.Fragment>
  )
}

export default statusComponent

