import {apiUrl} from "../../../../settings";

export const AddGicCommissionUrl = () => {
    return `${apiUrl}/addGicCommission`;
};
export const AddTransactionUrl = () => {
    return `${apiUrl}/addTransaction`;
};
export const AddBankAcountUrl = () => {
    return `${apiUrl}/addBankAcount`;
};
export const UpdateGicCommissionUrl = () => {
    return `${apiUrl}/updateGicStatus`;
};

export const gicCommissionListUrl = () => {
    return `${apiUrl}/gicCommissionList`;
};
export const transactionListUrl = () => {
    return `${apiUrl}/gic/transactionList`;
};
export const BankAcountListUrl = () => {
    return `${apiUrl}/bankAcountList`;
};
export const gicCommissionCountUrl = () => {
    return `${apiUrl}/gicCommissionCount`;
};
export const gicCommissionListMonthWiseUrl = () => {
    return `${apiUrl}/gicCommissionListMonthWise`;
};
export const sendWithdrawalOtpUrl = () => {
    return `${apiUrl}/gic/sendWithdrawalOtp`;
};

export const addWithdrawalUrl = () => {
    return `${apiUrl}/gic/addWithdrawal`
}

export const updateWithdrawalUrl = () => {
    return `${apiUrl}/updateWithdrawal`
}
export const UpdateGicDocumentsUrl = () => {
    return `${apiUrl}/updateGicDocuments`
}

export const getNextWithdrawalDateUrl = () => {
    return `${apiUrl}/gic/getNextWithdrawalDate`
}

export const reUploadDocumentsUrl = () => {
    return `${apiUrl}/gic/re-upload-document`
}
