import React, { useRef, useState, useEffect } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { listAllUpdates, deleteUpdates} from '../actions'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { CheckMyRights } from '../../WebComponent/CheckUserRights'
import { listAllCountries } from '../../countries/actions/countries'
import {Icon, notification, Popconfirm, Tooltip} from 'antd'
import {Link} from "react-router-dom";


const TargetList = () => {
    const tableRef = useRef()
    const dispatch = useDispatch()
    let [allCountry, setAllCountry] = useState([])

    useEffect(() => {
        loadCountry()
    }, [])

    const loadCountry = async () => {
        let {data} = await dispatch(listAllCountries())
        setAllCountry(data)
    }

    const columns = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            width: 80,
            key: 'sno',
            render: (value, item, index) => index + 1
        },
        {
            title: 'Country',
            dataIndex: 'countryId',
            key: 'countryId',
            filters: allCountry.map(x => ({ value: x._id, text: x.countryName })),
            render: (item) => {
                return item.countryName
            }
        },
        {
            title: 'University',
            dataIndex: 'universityId',
            key: 'universityId',
            render: (item) => {
                return item.universityName
            }
        },
        {
            title: 'Date',
            dataIndex: 'fromDate',
            key: 'fromDate',
            render: (item, record) => {
                return `${moment(record.fromDate).format("D MMM  YY")}`
            }
        },

        {
            title: 'Content',
            dataIndex: 'content',
            //   width: 200,

        },

        {
            key: 'actions',
            title: 'Actions',
            // width: 70,
            height: 100,
            render: (text, record) => {
                return (
                    <div>
                         <Tooltip title='Delete'>
                            <Popconfirm title={'Are your sure, you want to delete this?'}
                                onConfirm={() => {
                                    confirmDelete(record._id)
                                }}
                                okText='Yes' cancelText='No'>
                                <button
                                    className={'btn'}
                                    style={{ marginRight: 6 }}>
                                    <Icon type={'delete'} />
                                </button>
                            </Popconfirm>
                        </Tooltip>
                    </div>

                )
            }
        }
    ]

    const confirmDelete = async (id) => {
        let resp = await dispatch(deleteUpdates(id))
        if (resp && resp.success) {
          notification.warning({ message: resp.message })
          tableRef.current.reload()
        }
      }

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.sortField = '_id'
            params.sortOrder = 'descend'
            let resp = await dispatch(listAllUpdates({...params}))
            resolve(resp)
        })
    }

    return (
        <>
            <div className='row mt-4'>
                <div className='col-lg-12'>
                    <div className='card'>
                        {/* <TargetBanner /> */}
                        <div className='table-head d-flex justify-content-between all-student-search'>
                            <h5>Updates </h5>
                            <div className='sort-box-table mark-btn text-right'>
                                <CheckMyRights rightList={['admin', 'branchManager']}>
                                    <Link className='btn' to={'add-updates'}><img src={'../dist/icons/plus.png'}
                                                                                  className={'plus'}/>Add Updates</Link>
                                </CheckMyRights>
                            </div>
                        </div>

                        <div className='card-body table-responsive'>
                            <TableComp columns={columns}
                                className={'table table-striped table-valign-middle'}
                                apiRequest={apiRequest}
                                pagination={{
                                    position: 'top',
                                    pageSizeOptions: ['50', '100', '200', '500'],
                                    defaultPageSize: 50,
                                    current: 1
                                }}
                                ref={tableRef}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TargetList
