import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'antd';
import { getStudentCounsellingFxn } from '../../counselling/actions/counselling';
import { useDispatch, connect } from 'react-redux';

const CounsellingSessions = ({ currentUser }) => {
    const dispatch = useDispatch();
    const [bookedSlots, setBookedSlots] = useState([]);

    const apiRequest = async () => {
        let params = {
            studentId: currentUser._id,
          
        }
        let resp = await dispatch(getStudentCounsellingFxn(params));
        if (resp.data) {
            const sortedSlots = resp.data.sort((a, b) => new Date(b.date) - new Date(a.date));
            setBookedSlots(sortedSlots);
        }
    }

    useEffect(() => {
        if (currentUser._id) {
            apiRequest();
        }
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }
    const isPastDate = (dateString) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const date = new Date(dateString);
        date.setHours(0, 0, 0, 0); 
        return date < today;
    }
    
    const openMeetingLink = (meetingLink) => {
        window.open(meetingLink, '_blank');
    }


    return (
        <div>
            <h5 className={'title-calendar mt5'}>Your Booked Counselling Sessions</h5>

            <Row gutter={16} className="card-container-slot">
                {bookedSlots.map((item, index) => (
                    <Col span={24} key={index}>
                        <Card className="card-slot">
                            <Row gutter={16}>
                                <Col span={4} md={4} sm={8} xs={8}>
                                    <p className="time-container">{item.slot}</p>
                                    <p className='date-container'>{formatDate(item.date)}</p>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={4} md={4} sm={8} xs={8}>
                                <h6>{item.studentName}</h6>
                                <p style={{ fontWeight: 'normal' }}>{item.studentId ? item.studentId.email : ''}</p>
                                <p>{item.studentId ? item.studentId.mobile : ''}</p>
                                <p>{item.studentId ? item.studentId.address : ''}</p>
                            </Col>
                                <Col span={4} md={4} sm={8} xs={8} style={{ marginTop: '30px', textAlign: 'right' }}>
                                    <p>{item.countryId && item.countryId.countryName}</p>
                                    <p>{item.marketingUser}</p>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={5} md={5} sm={8} xs={8} style={{ marginTop: '12px' }}>
                                {item.status === 'Reject' ? (
                                    <label className='label label-danger'>Counselling Rejected</label>
                                ) : (
                                    <>
                                        {item.meeting && item.meeting.meetingLink && !isPastDate(item.date) ? (
                                            <>
                                                <Row span={24}>
                                                    <Col span={7} md={7} sm={12} xs={12}>
                                                        
                                                            <img src='/assets/image/zoom.png' height='51px' width='53px' alt="Zoom Logo" />
                                                        
                                                    </Col>
                                                </Row>
                                                <p>Id - <span style={{ fontWeight: 'normal' }}>{item.meeting.id}</span></p>
                                                <p>Password - <span style={{ fontWeight: 'normal' }}>{item.meeting.password}</span></p>
                                            </>
                                        ) : (
                                            <p style={{ fontWeight: 'normal', fontSize: '12px', marginTop: '18px', color: 'red' }}>
                                                {isPastDate(item.date) ? 'Meeting expired...' : 'Waiting for Zoom...'}
                                            </p>
                                        )}
                                    </>
                                )}
                            </Col>
                            <Col span={4} md={4} sm={8} xs={8} className="button-container">
                                {item.status !== 'Reject' && item.meeting && item.meeting.meetingLink && !isPastDate(item.date) ? (
                                    <Button className="start-meeting-btn" shape="round" onClick={() => openMeetingLink(item.meeting.meetingLink)}>Join Meeting</Button>
                                ) : (
                                    <Button className="start-meeting-btn" shape="round" disabled>Join Meeting</Button>
                                )}
                            </Col>

                            </Row>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

const mapStateToProps = ({ global }) => ({
    currentUser: global.currentUser
});

export default connect(mapStateToProps)(CounsellingSessions);
