import {
  Button,
  Tooltip
} from 'antd'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  DefaultTablePagination,
  displayDate
} from '../../../components/_utils/appUtils'
import {
  getStudentWithdrawal,
  updateWithdrawal
} from '../actions/student'
import UpdateStudentCommission from './updateStudentCommission'


const MainWithdrawalList = props => {
  let [total, setTotal] = useState([])
  let [commissionState, setCommissionState] = useState({
    withdrawalId: '',
    visible: false
  })

  const tableRef = useRef()
  const dispatch = useDispatch()

  const apiRequest = params => {
    return new Promise(async resolve => {
      let resp = await dispatch(
        getStudentWithdrawal({ ...params, regExFilters: ['name'] })
      )
      setTotal(resp.total)
      resolve(resp)
    })
  }


  const events = {

    updateWithdrawalStatus: async updateData => {
      try {
        await dispatch(updateWithdrawal(updateData))
      } catch (error) {
        console.error('Error updating withdrawal status:', error)
      }
    },
    showDrawer: (withdrawalId, status) => {
      setCommissionState({
        withdrawalId,
        visible: true
      })
    },
    hideDrawer: () => {
      setCommissionState({
        withdrawalId: '',
        status: '',
        visible: false
      })
    },
    hideAndUpdateDrawer: () => {
      setCommissionState({
        withdrawalId: '',
        visible: false
      })
      tableRef.current.reload()
    }
  }

  const columns = [
    /* {
                   title: 'Withdrawal ID',
                   key: 'studentId',
                   render: val => {
                       return <div>{val.studentId ? val.studentId.name : ''}</div>
                   }
               },*/
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      render: val => {
        return <div>{displayDate(val)}</div>
      }
    },
    {
      title: 'Student',
      key: 'studentId',
      dataIndex: 'studentId',
      render: val => {
        return <div className={'textCap'}>{val && val.name ? val.name : ''}</div>
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: value => {
        return <p>{value}</p>
      }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (val) => {
        return (
          <div>
            {val == 'Pending' ?
              <label className={'label label-xs label-warning'}>Pending</label> : null}
            {val == 'Cancel' ?
              <label className={'label label-xs label-danger'}>Cancel</label> : null}
            {val == 'Approved' ?
              <label className={'label label-xs label-success-green'}>Approved</label> : null}
          </div>
        )
      }
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      render: item => {
        return (
          <React.Fragment>
            <span>${item}</span>
          </React.Fragment>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'status',
      key: 'status',
      width: 220,
      render: (item, record) => {
        return (
          <>
            <div className={'btn_group'} style={{ width: 200 }}>
              {item == 'Pending' ? (
                <>
                  <Tooltip title={'Update Status'}>
                    <a className={'bs_btn bs-xs bs-default'}
                       onClick={() => {
                         events.showDrawer(record._id)
                       }}>
                      Update Status
                    </a>
                  </Tooltip>
                </>
              ) : null}
            </div>
          </>
        )
      }
    }
  ]

  return (
    <div>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center all-student-search'>
              <Button onClick={() => events.goBack()}
                      className={'roundGoBackBtn'} icon={'arrow-left'}>
                Back
              </Button>
              <h5>All Withdrawals (<small>Total {total}</small>)</h5>
              <div className='search-box-table round'>
              </div>
              <div className='sort-box-table mark-btn'>
              </div>
            </div>
            <div className='card-body table-responsive'>
              <TableComp
                columns={columns}
                apiRequest={apiRequest}
                pagination={DefaultTablePagination()}
                ref={tableRef}
              />
            </div>
          </div>

        </div>
      </div>

      {commissionState.visible ? (
        <UpdateStudentCommission
          {...commissionState}
          onSubmit={events.hideAndUpdateDrawer}
          onClose={events.hideDrawer}
        />
      ) : null}
    </div>
  )
}

export default MainWithdrawalList
