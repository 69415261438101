import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { getAssessmentWarningList, setWarningMailApi, getWaitingToApplyWarningList } from '../api'

export const AssessmentWarningList = (filters) => async (dispatch) => {
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(getAssessmentWarningList(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}
export const WaitingToApplyWarningList = (filters) => async (dispatch) => {
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(getWaitingToApplyWarningList(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}
export const SendWarningMail = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(setWarningMailApi(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}