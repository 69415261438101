import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import {
  addComplaintDepartmentUrl,
  ComplaintDepartmentListUrl,
  deleteComplaintDepartmentUrl,
  updateComplaintDepartmentUrl
} from '../apis'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'

export const addComplaintDepartmentFxn = (valData) => async (dispatch) => {
  dispatch({ type: 'CITY_SHOW_LOADER' })
  let { data } = await axios.post(addComplaintDepartmentUrl(), valData, getToken())
  dispatch({ type: 'CITY_HIDE_LOADER' })
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const complaintDepartmentListFxn = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...await getToken()
  }
  let { data } = await axios.get(ComplaintDepartmentListUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}

export const deleteComplaintDepartmentFxn = (id) => async (dispatch) => {
  const valData = { _id: id }
  dispatch(showPageLoad())
  let { data } = await axios.post(deleteComplaintDepartmentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Deleted Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}

export const updateComplaintDepartmentFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateComplaintDepartmentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
