import React, { useState, useEffect } from 'react'
import { Input, Select, Form, Button, Card, Col, Row, notification } from 'antd'
import { FileInput, InputBox } from '../../../components/_utils/appUtils'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import GetAllFormFields from '../../../components/_utils/formUtils'
import { useDispatch } from 'react-redux'
import { listAllUsers } from '../../users/actions/user'
import { addFeedback } from '../actions/feedbackActions'
import GetEachFormFields from '../../../components/_utils/appFormUtils'

const FeedbackComponent = (props) => {
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } } = props
  const dispatch = useDispatch()
  let [allAgents, setAllAgents] = useState([])
  let [allSubAgents, setAllSubAgents] = useState([])
  let [feedback, setFeedback] = useState('')
  useEffect(() => {
    // events.loadAgent()
    // events.loadSubAgent()
  }, [])
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 24, offset: 0 },
      md: { span: 24, offset: 0 }
    }
  }

  const events = {
    loadAgent: async () => {
      let obj = {
        results: 100,
        userType: 'agent',
        sortField: 'name',
        sortOrder: 'ascend'
      }
      let { data } = await dispatch(listAllUsers(obj))
      setAllAgents(data)
    },
    loadSubAgent: async () => {
      let obj = {
        results: 100,
        userType: 'subAgent',
        sortField: 'name',
        sortOrder: 'ascend'
      }
      if (getFieldValue('agentId')) {
        obj.agentId = getFieldValue('agentId')
      }
      let { data } = await dispatch(listAllUsers(obj))
      setAllSubAgents(data)
    },
    handleSubmit: async (e) => {
      const { form } = props
      e.preventDefault()
      form.validateFieldsAndScroll(async (err, valData) => {
        if (!err) {
          /*if (!valData.agentId && !valData.subAgentId) {
            return notification.warning({
              message: 'Please choose atleast 1 user.'
            })
          }*/
          if (!valData.feedback) {
            return notification.warning({
              message: 'Please enter feedback.'
            })
          }
          let data = await dispatch(addFeedback(valData))
          if (data && !data.error) {
            form.resetFields()
          }
        } else {
          notification.warning({
            message: 'Fill All Required Fields'
          })
        }
      })
    }
  }

  const formFields = {
    fields: [
      /*  {
          key: 'agentId',
          label: 'Agent',
          type: 'select',
          showSearch: true,
          options: allAgents,
          keyAccessor: x => x._id,
          valueAccessor: x => `${x.name}`,
          onChange: x => {
            props.form.setFieldsValue({
              agentId: x,
              subAgentId: undefined
            })
            events.loadSubAgent()
          }
        },
        {
          key: 'subAgentId',
          label: 'Sub Agent',
          type: 'select',
          showSearch: true,
          options: allSubAgents,
          keyAccessor: x => x._id,
          valueAccessor: x => `${x.name}`,
          onChange: x => {
            props.form.setFieldsValue({
              subAgentId: x
            })
          }
        },*/
      {
        key: 'feedback',
        label: 'Feedback & Complaints',
        placeholder: 'Enter Feedback & Complaints',
        type: 'textarea',
        rows: 10,
        span: 24
      }
    ]
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        <Form onSubmit={events.handleSubmit}>
          <div className="form-box mt-4">

            <div className="d-flex align-items-center mb-3 heading-form">
              <h5>Add Feedback & Complaints</h5>
            </div>

            <div className="card unizportal">
              <Row gutter={24}>
                {formFields.fields.map((item, key) => {
                  return (
                    <Col span={item.span ? item.span : 8} key={key}>
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout}/>
                    </Col>
                  )
                })}
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="btn">
                  Submit
                </Button>
              </Form.Item>

            </div>
          </div>

        </Form>
      </div>
    </div>
  )
}


const FeedBackWrapped = Form.create()(FeedbackComponent)
export default FeedBackWrapped
