import React, {useState} from "react"
import AddCounselling from "../drawers/addCounselling";

const AddCounsellingComponent = (props) => {
    const [visibleAddCounselling, setVisibleAddCounselling] = useState(false)

    const events = {
        showDrawer: () => {
            setVisibleAddCounselling(true)
        },
        hideDrawer: () => {
            setVisibleAddCounselling(false)
        },
        refreshCounselling: () => {
            setVisibleAddCounselling(false)
        }
    }
    return (
        <>
            <div className={'add_counselling_box'}>
                <a className={'add_counselling_btn'}
                   onClick={events.showDrawer}>
                    <img src={'../btnImgs/mortarboard.png'}/>
                    Add Counselling
                </a>
            </div>
            {visibleAddCounselling ? <AddCounselling
                visible={visibleAddCounselling}
                onClose={events.hideDrawer}
                onSubmit={events.refreshCounselling}
            /> : null}
        </>
    )
}
export default AddCounsellingComponent
