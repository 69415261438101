import React, {useState} from 'react'
import {Form, Select, Button, Card, notification, Spin, Row, Col, Drawer, Input} from 'antd'
import _ from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import {addCourseCategory} from '../actions'
import GetAllFormFields from '../../../components/_utils/formUtils'
import {InputBox} from "../../../components/_utils/appUtils";
import moment from "moment";
import GetEachFormFields from "../../../components/_utils/appFormUtils";


const AddCourseCategoryComponent = (props) => {
    const {form: {getFieldDecorator}, visible, events} = props;
    let [mediaKey, setMediaKey] = useState(moment())
    let [logo, setLogo] = useState({})
    const dispatch = useDispatch()
    const chooseFiles = (files) => {
        setLogo(files)
    }

    let inputTypes = {
        fields: [
            {key: 'name', label: 'Category Name', type: 'text', required: true},
            {
                label: 'Logo',
                span: 8,
                customField: (
                    <InputBox title={'Logo'} className={'rowFlex'}>
                        <Input type={'file'} name={'logo'} id={'logo'} key={mediaKey}
                               className={'form-control'}
                               onChange={(e) => {
                                   chooseFiles(e.target.files && e.target.files.length ? e.target.files[0] : null)
                               }}/>
                    </InputBox>
                )
            },
        ]
    }


    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    const handleSubmit = e => {
        const {form} = props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let fd = new FormData()
                fd.append('obj', JSON.stringify(valData))
                if (logo && logo.name) {
                    fd.append('logo', logo)
                }
                let data = await dispatch(addCourseCategory(fd))
                if (data && !data.error) {
                    form.resetFields()
                    setMediaKey(moment())
                }
                if (data && data.success) {
                    events.hideDrawer()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }
    return (
        <Drawer
            visible={visible}
            width={600}
            placement="right"
            onClose={events.hideDrawer}
            title={'Add New Category'}>
            <div className="form-box">
                <div className="card unizportal">
                    <Form onSubmit={handleSubmit}>
                        <Row gutter={16} className={'wrapBox'}>
                            {inputTypes.fields.map((item, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        {item.customField ?
                                            <Col span={24}>
                                                {item.customField}
                                            </Col>
                                            :
                                            <Col span={24} key={key}
                                                 style={item.extraBox ? {paddingBottom: 1} : {}}>
                                                <InputBox title={item.label1 ? item.label1 : ''}
                                                          extra={item.extraBox}>
                                                    <GetEachFormFields
                                                        item={item}
                                                        getFieldDecorator={getFieldDecorator}
                                                        formItemLayout={formItemLayout}/>
                                                </InputBox>
                                            </Col>}
                                    </React.Fragment>
                                )
                            })}
                        </Row>
                        <Form.Item style={{marginTop: 25}}>
                            <Button type="primary" htmlType="submit" className={'btn'}>
                                ADD
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Drawer>
    )
}
const WrappedCountry = Form.create()(AddCourseCategoryComponent)
export default WrappedCountry
