import { apiUrl, apiUrl2 } from '../../../settings'

export const canadaAddExpenseUrl = () => {
  return apiUrl2 + '/addCanadaExpense'
}

export const expenseAmountRecordCanadaUrl = () => {
  return apiUrl2 + '/expenseAmountRecordCanada'
}

export const loadExpenseTransactionsCanadaUrl = () => {
  return apiUrl2 + '/expenseTransactionsCanada'
}

export const sendExpenseWithdrawalOtpCanadaUrl = () => {
  return apiUrl2 + '/sendExpenseWithdrawalOtpCanada'
}

export const addExpenseWithdrawalCanadaUrl = () => {
  return apiUrl2 + '/addExpenseWithdrawalCanada'
}
export const agentListUrl = () => {
  return apiUrl2 + '/api/agentList'
}

export const updateCanadaExpenseWithdrawalUrl = () => {
  return apiUrl2 + '/canada/updateExpenseWithdrawal'
}
export const updateCanadaExpenseUrl = () => {
  return apiUrl2 + '/canada/updateExpenseStatus'
}
