import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import {
  evaluateStateUrl,
  evaluateCourseLevelUrl,
  evaluateIntakesUrl,
  evaluateUniversityUrl,
  evaluateCountryUrl,
  evaluateFeaturedUniversityUrl,
  evaluateFeaturedCourseUrl,
  evaluateOtherUniversityUrl,
  evaluateSearchCourseUrl,
  evaluateLoadMoreCourseUrl,
  evaluateEducationUrl,
  evaluateEnglishRequirementUrl,
  evaluateEnglishRequirementMaxUrl,
  evaluateExamPercentageMaxUrl,
  tuitionFeeMaxUrl,
  evaluateSearchUniversityUrl, evaluateFeaturedUniversityUrl2, evaluateSearchUniversityUrl2
} from '../api'
import { hidePageLoad } from '../../../modules/actions'

export const getEvaluateCountry = (valData) => async (dispatch) => {
  let { data } = await axios.post(evaluateCountryUrl(), valData, getToken())
  return data
}
export const getEvaluateState = (valData) => async (dispatch) => {
  let { data } = await axios.post(evaluateStateUrl(), valData, getToken())
  return data
}
export const getEvaluateCourseLevel = (valData) => async (dispatch) => {
  let { data } = await axios.post(evaluateCourseLevelUrl(), valData, getToken())
  return data
}
export const getEvaluateIntakes = (valData) => async (dispatch) => {
  let { data } = await axios.post(evaluateIntakesUrl(), valData, getToken())
  return data
}
export const getEvaluateUniversity = (valData) => async (dispatch) => {
  let { data } = await axios.post(evaluateUniversityUrl(), valData, getToken())
  return data
}
export const getEvaluateFeaturedUniversity = () => async (dispatch) => {
  let { data } = await axios.post(evaluateFeaturedUniversityUrl(), {}, getToken())
  return data
}

export const getEvaluateFeaturedCourse = (valData) => async (dispatch) => {
  let { data } = await axios.post(evaluateFeaturedCourseUrl(), valData, getToken())
  return data
}

export const getEvaluateOtherUniversity = (valData) => async (dispatch) => {
  let { data } = await axios.post(evaluateOtherUniversityUrl(), valData, getToken())
  return data
}
export const getEvaluateSearchCourse = (valData) => async (dispatch) => {
  let { data } = await axios.post(evaluateSearchCourseUrl(), valData, getToken())
  return data
}

export const getEvaluateLoadMoreCourse = (valData) => async (dispatch) => {
  let { data } = await axios.post(evaluateLoadMoreCourseUrl(), valData, getToken())
  return data
}
export const getEvaluateEnglishRequirement = (valData) => async (dispatch) => {
  let { data } = await axios.post(evaluateEnglishRequirementUrl(), valData, getToken())
  return data
}
export const getEvaluateEducation = (valData) => async (dispatch) => {
  let { data } = await axios.post(evaluateEducationUrl(), valData, getToken())
  return data
}
export const getEvaluateEnglishRequirementMax = (valData) => async (dispatch) => {
  let { data } = await axios.post(evaluateEnglishRequirementMaxUrl(), valData, getToken())
  return data
}
export const getEvaluateExamPercentageMax = (valData) => async (dispatch) => {
  let { data } = await axios.post(evaluateExamPercentageMaxUrl(), valData, getToken())
  return data
}
export const getTuitionFeeMax = (valData) => async (dispatch) => {
  let { data } = await axios.post(tuitionFeeMaxUrl(), valData, getToken())
  return data
}
export const getEvaluateSearchUniversity = (valData) => async (dispatch) => {
  let { data } = await axios.post(evaluateSearchUniversityUrl(), valData, getToken())
  return data
}
export const getEvaluateFeaturedUniversity2 = () => async () => {
  let { data } = await axios.post(evaluateFeaturedUniversityUrl2(), {}, getToken())
  return data
}
export const getEvaluateSearchUniversity2 = (valData) => async (dispatch) => {
  let { data } = await axios.post(evaluateSearchUniversityUrl2(), valData, getToken())
  return data
}