import React, { useState, useEffect } from 'react'
import { Button, Card, Drawer, Form, Row, Col, notification } from 'antd'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import moment from 'moment'
import { SendWarningMail } from '../actions'
import { useDispatch } from 'react-redux'

let htmlContent = (
  ` <p><strong>Dear</strong>&nbsp;Partner,</p>

    <p>Greetings from UnizPortal!</p>
    
    

    <p>For further action, you can logon to our online application portal at&nbsp;<a href='https://unizportal.com/login' target='_blank'>https://unizportal.com/login</a>&nbsp;or download the mobile application from&nbsp;<a href='https://play.google.com/store/apps/details?id=com.unizportal' target='_blank'>Google</a>&nbsp;and&nbsp;<a href='https://apps.apple.com/in/app/unizportal/id1569018281' target='_blank'>Apple</a>&nbsp;store.</p>

    <p style='color: #ba0307; font-size: 12px;'>Note- This is an autogenerated email,please do not respond to this email for us to serve you better. Any communication further to this application please make a note in student portal by login at:&nbsp;<a href='https://unizportal.com/login' target='_blank'>https://unizportal.com/login</a>.</p>

    <p>Yours truly,</p>

    <div><strong>UnizPortal Team</strong></div>`
)
const WarningEmail = (props) => {
  let dispatch = useDispatch()
  const { form: { getFieldDecorator, resetFields, setFieldsValue }, data, onClose, onCloseRefresh, emails } = props
  let { visible, studentId, applicationId, warningType, type } = data
  let [editorKey, setEditorKey] = useState(moment())
  useEffect(() => {
    setTimeout(() => {
      setFieldsValue({message: htmlContent})
      setFieldsValue({emails})
      setEditorKey(moment())
    }, 300)
  }, [])
  let emailFields = [
    {
      key: 'emails',
      label: 'To Email',
      required: true,
      placeholder: 'Emails',
      type: 'textarea'
    },
    {
      key: 'subject',
      label: 'Subject',
      required: true,
      placeholder: 'subject',
      type: 'textarea'
    },
    {
      key: 'message',
      label: 'Content',
      required: true,
      placeholder: 'Email',
      type: 'ckeditor',
      editorKey: editorKey
    }
  ]
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = (e) => {
    let { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        valData.studentId = studentId
        valData.applicationId = applicationId
        valData.warningType = warningType
        valData.type = type
        let { success, message } = await dispatch(SendWarningMail(valData))
        if (success) {
          notification.warning({
            message: message
          })
          setEditorKey(moment())
          resetFields()
          onCloseRefresh()
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  return (
    <Drawer width={'60%'}
            title={'Send Email'}
            visible={visible}
            onClose={onClose}>
      <Card bordered={false}>
        <Form className={'vertical-form'} onSubmit={handleSubmit}>
          <Row gutter={16}>
            {emailFields.map((item, key) => {
              return (
                <Col key={key}>
                  <GetEachFormFields
                    item={item}
                    getFieldDecorator={getFieldDecorator}
                    formItemLayout={formItemLayout}/>
                </Col>
              )
            })}

          </Row>
          <div className={'mt20 alignRight'}>
            <Button type={'success'} htmlType="submit">Send</Button>
          </div>
        </Form>
      </Card>
    </Drawer>
  )
}
const WrappedWarningEmail = Form.create()(WarningEmail)
export default WrappedWarningEmail
