import React, { useEffect, useState } from 'react'
import { Drawer, notification, Form, Button, DatePicker } from 'antd'
import { useDispatch } from 'react-redux'
import { InputBox } from '../../../components/_utils/appUtils'
import { cambieOnshorePaymentReceiptFxn } from './actions'
import moment from 'moment'

const GenerateCambieOnshoreReceipt = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, onSubmit, studentId, applicationId, receiptAmount } = props
  let [cambieOnshoreReceiptAmount, setCambieOnshoreReceiptAmount] = useState('')
  let [date, setDate] = useState(props.date)
  const handleSubmit = async () => {
    if (!cambieOnshoreReceiptAmount) {
      notification.warning({ message: 'Enter Receipt amount.' })
      return
    }
    if (!date) {
      notification.warning({ message: 'Choose Date.' })
      return
    }
    let resp = await dispatch(cambieOnshorePaymentReceiptFxn({ date, applicationId, studentId }))
    if (resp && resp.success) {
      onSubmit()
    }
  }

  useEffect(() => {
    setCambieOnshoreReceiptAmount(receiptAmount ? receiptAmount : '')
  }, [receiptAmount])

  return (
    <>
      <Drawer title={'Generate Receipt'} visible={visible} width={'45%'} onClose={onClose}>
        <div className='form-box'>
          <div className='card unizportal'>
            <Form>
              <InputBox title={'Date'}>
                <DatePicker
                  className={'form-control'}
                  value={date}
                  onChange={value => {
                    setDate(value)
                  }} />
              </InputBox>


              <Button type='primary' htmlType='submit' className='btn' onClick={handleSubmit}>
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  )
}
export default GenerateCambieOnshoreReceipt
