import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import { addSampleDocumentUrl, listSampleDocumentUrl, singleSampleDocumentUrl } from '../api/sampleDocument'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'

export const addSampleDocument = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addSampleDocumentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const listAllSampleDocument = (filters) => async (dispatch) => {
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(listSampleDocumentUrl(), config)
  dispatch({ type: 'LIST_ALL_PENDENCY', payload: data.data })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data

}


export const getSampleDocument = id => async (dispatch) => {
  let config = {
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(singleSampleDocumentUrl(id), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    dispatch({ type: 'LIST_PENDENCY', payload: data })
  }
  return data.data
}


export const updateSampleDocument = (valData, id) => async (dispatch) => {
  let { data } = await axios.put(singleSampleDocumentUrl(id), valData, getToken())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}


export const deleteSampleDocument = (id) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.delete(singleSampleDocumentUrl(id), getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Deleted Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}

