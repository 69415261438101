import React, { Component, useEffect, useState, useCallback } from 'react'
import { GiftedChat } from 'react-web-gifted-chat'
import { Socket } from '../../../socket'
import { connect, useDispatch } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import { getChatRoomList, getPreviousChat } from '../actions'
import { Row, Col, Card, List, Avatar } from 'antd'
import '../../../less/chat.less'

let chatId = ''

let defaultMessages = [
  {
    id: 1,
    text: 'Hello developer',
    createdAt: new Date(),
    user: {
      id: 2,
      name: 'React',
      avatar: 'https://facebook.github.io/react/img/logo_og.png'
    }
  },
  {
    id: 2,
    text: 'Hello rakesh',
    createdAt: new Date(),
    user: {
      id: 1,
      name: 'React',
      avatar: 'https://facebook.github.io/react/img/logo_og.png'
    }
  }
]

function generateMessage(text, index, additionalData) {
  return {
    id: Math.round(Math.random() * 1000000),
    text: text,
    createdAt: new Date(),
    user: {
      id: index % 3 === 0 ? 1 : 2,
      name: 'Johniak'
    },
    ...additionalData
  }
}

const ChatScreen = (props) => {
  let dispatch = useDispatch()
  let { currentUser } = props
  let [roomList, setRoomList] = useState([])
  let [messages, setMessages] = useState([])
  let [online, setOnline] = useState(true)
  let [lastTime, setLastTime] = useState(moment())
  let [onlineUsers, setOnlineUsers] = useState({})
  // let [chatId, setChatId] = useState('')
  let [chatUser, setChatUser] = useState({})
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

  const events = {
    loadRoomList: async () => {
      let { data } = await dispatch(getChatRoomList())
      setRoomList(data)
    },
    chooseChat: (data) => { //  join chat room
      chatId = data._id.toString()
      Socket.emit('join_chat_room', { roomId: data._id })
      setChatUser({
        userName: data.title,
        chatId: data._id.toString()
      })
    },
    loadPreviewsChat: async () => {
      let { chatId } = chatUser
      let params = {
        count: 50,
        page: 1,
        chatId
      }
      let { data } = await dispatch(getPreviousChat(params))
      setMessages(data)
    },
    setUserStatus: (data) => {
      let cloneOnlineUsers = Object.assign(onlineUsers, {})
      if (data && data.userId) {
        cloneOnlineUsers[data.userId] = 'Online'
      }
      setOnlineUsers(cloneOnlineUsers)
    },
    getCurrentStatus: (ids) => {
      _.each(ids, (item) => {

      })
    }
  }
  const renderLoading = () => {
    return (<div>Loading...</div>)
  }

  useEffect(() => {
    events.loadRoomList()
  }, [])


  useEffect(() => {
    events.loadPreviewsChat()
  }, [chatUser.chatId])


  /* useEffect(() => {
     setTimeout(() => {
       // Socket.emit('join_chat_room', { roomId: chatId })
       console.log('+++++++++join_chat_room')
     }, 1000)

     // setMessages([])
     /!* if (online) {
        setLastTime('Online')
      }
      return () => Socket.emit('leave_chat_room', { roomId: chatId })*!/
   }, [chatId])*/

  /* useEffect(() => {
     const backAction = () => {
       Socket.emit('leave_chat_room', { roomId: chatId })
       return true
     }
   }, [])*/

  useEffect(() => {
    Socket.on('send_chat_message', (payload) => {
      console.log(payload, 'refresh_chat_list')
      setMessages(previousMessages => {
        return GiftedChat.append(previousMessages, payload)
      })

    })
    return () => {
      /*Socket.off('send_chat_message', () => {
      })*/
    }

  }, [])


  useEffect(() => {
    Socket.on('user_comes_online', (resp) => {
      console.log(resp, '+++++++++++++++')
      events.setUserStatus(resp)
    })
    return () => {
      Socket.off('user_comes_online', () => {
      })
    }
  }, [])

  useEffect(() => {
    Socket.on('user_go_offline', (resp) => {
      if (user && user._id == resp.userId) {
        // setLastTime(msgTime);
      }
    })
    return () => {
      Socket.off('user_go_offline', () => {
      })
    }
  }, [])

  const onSend = useCallback(async (messages = []) => {
    let obj = messages[0] || {}
    let type = obj.type || 'text'
    let emitObj = {
      toId: user._id,
      time: new Date(),
      type: type,
      chatId: chatId,
      fileSize: obj.fileSize,
      videoDuration: obj.videoDuration

    }
    if (type == 'text')
      emitObj.value = obj.text
    else
      emitObj.media = obj.media

    console.log(emitObj, '+++++++++++emitObj')
    Socket.emit('save_chat_msg', emitObj)


  }, [])

  return (
    <div>
      <Card bordered={true} className="chatBox">
        <Row>
          <Col span={6} className={'list'}>
            <div className={'header'}>
              Conversations
            </div>
            <List
              itemLayout="horizontal"
              dataSource={roomList}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar src="../dist/img/chat_user.png"/>}
                    title={<a onClick={() => events.chooseChat(item)}>{item.title}</a>}
                    description={item.ids && item.ids.length ? events.getCurrentStatus(item.ids) : null}
                  />
                </List.Item>
              )}
            />
          </Col>
          <Col span={18}>
            {chatId ?
              <div>
                <div className={'header'}>
                  {chatUser.userName}
                </div>
                <div className={'chatScreen'}>
                  <GiftedChat user={{ id: user._id.toString() }}
                              messages={messages}
                              onSend={messages => onSend(messages)}
                  />
                </div>
              </div> :
              <div className={'emptyBox'}>
                <img src={'../dist/img/AdminLTELogo.png'}/>
              </div>
            }

          </Col>
        </Row>
      </Card>
    </div>
  )
}


const mapStateToProps = ({ counter, global }) => ({
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatScreen)
