import { customAxios as axios, getToken } from '../../../request'
import { notification } from 'antd'
import { getDraftApplicationsListUrl } from './apis'

export const draftApplicationListFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getDraftApplicationsListUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}