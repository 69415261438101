import React from 'react'
import { countryIds } from '../../../components/_utils/appUtils'
import OnshoreCanadaProgramsComponent from './OnshoreCanadaProgramsComponent'
import { connect } from 'react-redux'

const CanadaOnshoreProgramList = (props) => {
  let countryId = countryIds.canadaOnshore
  return (
    <>
      <OnshoreCanadaProgramsComponent countryId={countryId} countryName={'Canada Onshore'}
                                      currentUser={props.currentUser} />
    </>
  )
}

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})

export default connect(
  mapStateToProps,
  null
)(CanadaOnshoreProgramList)
