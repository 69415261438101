import React, {useEffect, useState} from 'react'
import {countryIds} from "../../../components/_utils/appUtils";
import CountryWiseApplicationList from "./countryWiseApplications";
import OnshoreApplications from "./onshoreApplications";

const ApplicationList = props => {
    let [countryId, setCountryId] = useState("")
    const getCountryId = () => {
        let searchParams = new URLSearchParams(window.location.search)
        let countryId = searchParams.get('countryId');
        setCountryId(countryId)
    }

    useEffect(() => {
        getCountryId()
    }, [window.location])

    return (
        <React.Fragment>
            {countryId ? <>
                {countryId == countryIds.canadaOnshore ? <OnshoreApplications/> : <CountryWiseApplicationList/>}
            </> : null}
        </React.Fragment>
    )
}


export default ApplicationList
