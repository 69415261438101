import React, { Component } from 'react'
import {
  Table,
  Badge,
  Card
} from 'antd'
import { connect } from 'react-redux'
import { CheckUserRight } from '../../dashboard/views/DashboardUserWise'

class CheckActiveUser extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    let { dispatch, activeUser, userId, currentUser } = this.props
    return (
      <CheckUserRight user={currentUser} rightUserType={['admin']}>
        {activeUser && activeUser.length && activeUser.includes(userId) ?
          <div>
            <label className={'label label-success'}>Active</label>
          </div> : null}
      </CheckUserRight>
    )

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  activeUser: global.activeUser,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckActiveUser)
