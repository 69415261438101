import React, { useEffect, useState } from 'react'
import {
  Form,
  Tabs,
  Collapse,
  Select
} from 'antd'
import { connect } from 'react-redux'
import {
  showCommissionCheck
} from '../../components/_utils/appUtils'
import _ from 'lodash'
import FiltersComponent from './filterUniversityRequirements'
import {
  getEvaluateFeaturedUniversity,
  getEvaluateOtherUniversity,
  getEvaluateLoadMoreCourse,
  getEvaluateSearchUniversity, getEvaluateFeaturedUniversity2, getEvaluateSearchUniversity2
} from './action'
import FeaturedCourseDrawer from './featuredCourse'
import OtherUniversities from './otherUniversities'
import DefaultUniversity from './defaultUniversity'
import { getUser } from '../users/actions/user'



class UniversityRequirements extends React.Component {

  constructor(props) {
    super()
    this.state = {
      featuredUniversity: {},
      visibleFeaturedCourse: false,
      visibleOtherUniversity: false,
      selectedUniversity: {},
      selectedCountry: {},
      uniCountObj: {},
      skip: 0,
      loadMore: true,
      total: 0,
      coursesList: {},
      courseCount: {},
      isFiltered: false,
      filters: {},
      search: '',
      currentUser: {},
      universityList: [],
      isEmpty: false,
      universityName: '',
      courseName: ''
    }
  }

  componentDidMount() {
    this.loadFeaturedUniversity()
    this.loadCurrentUser()
    this.props.dispatch({ type: 'STOP_LOAD_FILTER' })
  }

  applyFilterOnCourse = async (params1={}) => {
    let { dispatch } = this.props
    let { skip, search, filters } = this.state
    let params = {
      ...params1,
      courseName: search,
      limit: 9,
      skip
    }
    let total = 0
    let { data, countObj } = await dispatch(getEvaluateSearchUniversity2(params))
    if (data.ukUni && data.ukUni.length) {
      total = total + data.ukUni.length
    }
    if (data.canadaUni && data.canadaUni.length) {
      total = total + data.canadaUni.length
    }
    if (data.australiaUni && data.australiaUni.length) {
      total = total + data.australiaUni.length
    }
    if (data.usaUni && data.usaUni.length) {
      total = total + data.usaUni.length
    }
    this.setState({
      universityList: data,
      isFiltered: true,
      filters: filters,
      uniCountObj: countObj,
      isEmpty: total ? false : true
    })
  }

  loadFeaturedUniversity = async () => {
    let { data, countObj } = await this.props.dispatch(getEvaluateFeaturedUniversity2())
    this.setState({ featuredUniversity: data, uniCountObj: countObj })
  }


  loadCurrentUser = async () => {
    let { dispatch } = this.props
    let user = (localStorage.getItem('user') != 'undefined') ? JSON.parse(localStorage.getItem('user')) : null
    let resp = await dispatch(getUser(user._id))
    this.setState({ currentUser: resp })

  }
  events = {
    showFeaturedDrawer: (item) => {
      this.setState({
        visibleFeaturedCourse: true,
        selectedUniversity: item
      })
    },
    hideFeaturedDrawer: () => {
      this.setState({
        visibleFeaturedCourse: false,
        selectedUniversity: {}
      })
    },
    hideOtherUniversityDrawer: () => {
      this.setState({
        visibleOtherUniversity: false,
        selectedCountry: {}
      })
    },
    loadOtherUniversity: async (item, countryName) => {
      let { dispatch } = this.props
      let { featuredUniversity } = this.state
      if (item && item.universityCountry && item.universityCountry._id) {
        let data = await dispatch(getEvaluateOtherUniversity({ countryId: item.universityCountry._id }))
        featuredUniversity[countryName] = [...featuredUniversity[countryName], ...data]
        this.setState({ featuredUniversity })
      }
    },
    loadMoreSearchCourse: async (countryId, countryField) => {
      let { dispatch } = this.props
      let { coursesList, filters } = this.state
      let skip = coursesList[countryField].length
      let params = {
        ...filters,
        countryId,
        limit: 9,
        skip
      }
      let data = await dispatch(getEvaluateLoadMoreCourse(params))
      let cloneCourseList = _.clone(coursesList)
      cloneCourseList[countryField] = [...cloneCourseList[countryField], ...data]
      this.setState({ coursesList: cloneCourseList })
    },
    setFilter: (params) => {
      this.setState({ filters: params })
    },
    clearFilterCallback: () => {
      this.setState({ filters: {}, search: '', universityName: '', isFiltered: false }, () => {
        this.applyFilterOnCourse()
      })
    }
  }

  searchByName = async () => {
    let { dispatch } = this.props
    let { skip, search, universityName, filters } = this.state
    let params = {
      ...filters,
      courseName: search,
      universityName,
      limit: 9,
      skip
    }
    let { data } = await dispatch(getEvaluateSearchUniversity(params))
    this.setState({ universityList: data, isFiltered: true, filters: filters })
    dispatch({ type: 'START_LOAD_FILTER', courseName: search, universityName })
  }

  clearFilterFxn() {
    this.setState({ universityName: '', search: '' }, () => {
      this.searchByName()
    })
  }


  render() {
    let {
      featuredUniversity, visibleFeaturedCourse, selectedUniversity,
      uniCountObj, selectedCountry, visibleOtherUniversity, isFiltered, coursesList, universityList,
      courseCount, search, currentUser, filters, isEmpty, universityName
    } = this.state
    let { events } = this
    return (
      <React.Fragment>
        <div className='mt-4'>
          <div className='evaluate-section'>
            <div className='row mt-4 mb-4'>
              <div className='col-lg-3'>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-8'>
                {isFiltered ?
                  <DefaultUniversity featuredUniversity={universityList} events={events}
                                     currentUser={currentUser}
                                     isEmpty={isEmpty}
                                     showCommission={showCommissionCheck(currentUser)}
                                     uniCountObj={uniCountObj} /> :
                  <DefaultUniversity featuredUniversity={featuredUniversity} events={events}
                                     currentUser={currentUser}
                                     isEmpty={false}
                                     showCommission={showCommissionCheck(currentUser)}
                                     uniCountObj={uniCountObj} />}
              </div>
              <div className='col-lg-4 mt-5'>
                <FiltersComponent filterCallback={(params) => this.applyFilterOnCourse(params)} events={events} />
              </div>
            </div>

          </div>

        </div>
        {visibleFeaturedCourse ?
          <FeaturedCourseDrawer onClose={events.hideFeaturedDrawer} visible={visibleFeaturedCourse}
                                featured={true}
                                filters={filters}
                                showCommission={showCommissionCheck(currentUser)}
                                item={selectedUniversity} />
          : null}
        {visibleOtherUniversity ?
          <OtherUniversities onClose={events.hideOtherUniversityDrawer} visible={visibleOtherUniversity}
                             selectedCountry={selectedCountry} />
          : null}

      </React.Fragment>
    )
  }
}


const WrappedEvaluateIndex = Form.create()(UniversityRequirements)

const mapStateToProps = ({ global, router }) => ({
  pageLoading: global.pageLoading,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedEvaluateIndex)