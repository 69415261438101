import {
  Avatar,
  Button,
  Col,
  Icon,
  Row,
  Select,
  Tag,
  Tooltip
} from 'antd'
import lodash from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { ShowListOfAgentManager } from '../../../components/_utils/AgentBranchManagerComponent'
import {
  BloomsburyInstitute,
  ConditionOptions,
  defaultLogoList,
  departmentObj,
  filterOption,
  Intakes,
  longDisplayDate,
  newFormatDisplayDate,
  statusColors,
  ukApplicationSteps
} from '../../../components/_utils/appUtils'
import { getPushPathWrapper, getUrlPushWrapper } from '../../../routes'
import {
  CheckUserRight
} from '../../dashboard/views/DashboardUserWise'
import {
  applicationCloseDirect
} from '../../student/actions/student'
import {
  AllowComponentRightsWise,
  CheckBranchUserFxn
} from '../../WebComponent/allowComponentRightsWise'
import {
  getBloomsburyApplicationsFxn,
  getSelectedCountryApplicationsApi
} from '../actions/chooseStudent'
import AddNote from '../drawers/applicationAddNoteComponent'

const managerViewRight = ['admin', 'branchUser']
const managerNewViewRight = ['admin', 'branchUser', 'branchManager']

const { Option } = Select
const initialState = {
  applicationList: [],
  applicationObj: {}
}
const RenderComment = props => {
  let { item } = props
  let [hideV, setHideV] = useState(true)
  return (
    item && (
      <div>
        {item.addedByUserId && item.addedByUserId.name ? (
          <div className={'alignRight colorPrimary font12'}>
            (Added By : {item.addedByUserId.name} )
          </div>
        ) : null}

        <div>{longDisplayDate(item.time)}</div>

        <div className={hideV ? 'appCommentDotsReplace' : ''}>
          {item && item.text ? item.text : ''}
        </div>
        {item && item.text.length && item.text.length > 50 ? (
          <div className={'alignRight'}>
            {hideV ? (
              <a
                onClick={() => {
                  setHideV(false)
                }}>
                Show More
              </a>
            ) : (
              <a
                onClick={() => {
                  setHideV(true)
                }}>
                Show Less
              </a>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  )
}
const BloomsburyInstituteApplications = props => {
  const [state, setState] = useState(initialState)
  const [name, setName] = useState('')
  const [visibleOtherAppDrawer, setVisibleOtherAppDrawer] = useState(false)
  const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
  const [totalApplication, setTotalApplication] = useState(0)
  const [status, setStatus] = useState('')
  const [courseId, setCourseId] = useState('')
  const [countryId, setCountryId] = useState('')
  const [draftApplication, setDraftApplication] = useState('')
  let { applicationObj } = state
  let [tableSearch, setTableSearch] = useState(false)
  let [filters, setFilters] = useState({})
  let [currentNote, setCurrentNote] = useState('')
  let [intake, setIntake] = useState('')
  let viewOnlyRight = CheckBranchUserFxn() // check branch user right
  let [branchManagerList, setBranchManagerList] = useState([])


  const dispatch = useDispatch()
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  const tableAppRef = useRef()

  let apiRequest = params => {
    return new Promise(async resolve => {
      let resp = await getParamsForApplicationList()
      params = { ...params, ...resp }
      if (!params.results) {
        params.results = 50
        params.count = 50
      }
      if (tableSearch) {
        params.page = 1
      }
      try {
        setFilters(params)
        let resp = await getBloomsburyApplicationsFxn({
          ...params,
          regExFilters: ['name']
        })
        setTotalApplication(resp.total)
        setTableSearch(false)
        resolve(resp)
      } catch (e) {
      }
    })
  }

  useEffect(() => {
    setFieldByParams()
  }, [])

  const setFieldByParams = async () => {
    let resp = await getParamsForApplicationList()
    if (resp.name) {
      setName(resp.name)
    }
    if (resp.courseId) {
      setCourseId(resp.courseId)
    }
    if (resp.intake) {
      setIntake(resp.intake)
    }
    if (resp.status) {
      setStatus(resp.status)
    }
    if (resp.note) {
      setCurrentNote(resp.note)
    }

  }


  const events = {
    reloadFxn: () => {
      tableAppRef.current.reload()
    },
    refreshApplication: () => {
      tableAppRef.current.reload()
    },
    closeDirectApp: async record => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(applicationCloseDirect(obj))
      tableAppRef.current.reload()
    },
    enterName: value => {
      setName(value)
    },
    openOtherAppDrawer: value => {
      setState({
        ...state,
        applicationObj: value
      })
      setVisibleOtherAppDrawer(true)
    },
    openAddCommentDrawer: value => {
      setState({
        ...state,
        applicationObj: value
      })
      setVisibleAddCommentDrawer(true)
    },
    closeAddCommentDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setVisibleAddCommentDrawer(false)
    }
  }


  const getParamsForApplicationList = () => {
    return new Promise(resolve => {
      let searchParams = new URLSearchParams(window.location.search)
      let status = searchParams.get('status')
      let name = searchParams.get('name')
      let courseId = searchParams.get('courseId')
      let note = searchParams.get('note')
      let intake = searchParams.get('intake')
      let obj = {}
      if (status) {
        obj.status = status
      }
      if (courseId) {
        obj.courseId = courseId
      }
      if (name) {
        obj.name = name
      }
      if (intake) {
        obj.intake = intake
      }
      if (note) {
        obj.note = note
      }
      resolve(obj)
    })
  }

  const searchFxn = async () => {
    let obj = {}
    if (courseId) {
      obj.courseId = courseId
    }
    if (status) {
      obj.status = status
    }
    if (name) {
      obj.name = name
    }
    if (currentNote) {
      obj.note = currentNote
    }
    if (intake) {
      obj.intake = intake
    }
    dispatch(
      getUrlPushWrapper('bloomsburyInstituteApplications', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
    }, 200)
  }
  const clearFxn = async () => {
    let obj = {}
    setStatus('')
    setCurrentNote('')
    setName('')
    dispatch(
      getUrlPushWrapper('bloomsburyInstituteApplications', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      tableAppRef.current.reload()
    }, 200)
  }

  const columns = [
    {
      title: 'App#',
      key: 'applications.id',
      dataIndex: 'applications.id',
      searchTextName: 'applications.id',
      width: 80,
      render: (item, record) => {
        return <div style={{ width: 80 }}>{item}</div>
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 150,
      dataIndex: 'name',
      render: (item, record) => {
        let { applications } = record
        return viewOnlyRight ? (
          <Tooltip title={'Edit Student'}>
            <a
              className={'linkAA'}
              onClick={() => {
                dispatch(
                  getPushPathWrapper('student.editStudent', { id: record._id })
                )
              }}>
              {record && record.countryISOCode == 'CA_+1' ? (
                <img
                  src={'/assets/starEnable.png'}
                  width={14}
                  style={{ marginRight: 3 }}
                />
              ) : null}
              {item} <Icon type={'edit'} />
              <br />
              {record && record.countryISOCode == 'CA_+1' ? (
                <span>
                  <Tag style={{ marginTop: 3, marginBottom: 3 }} size={'sm'}>
                    Docs:{' '}
                    {applications && applications.paymentPending == true ? (
                      <Icon type={'close'} style={{ color: 'red' }} />
                    ) : (
                      <Icon type={'check'} style={{ color: 'limegreen' }} />
                    )}
                  </Tag>
                  <br />
                  <Tag>
                    Fees:{' '}
                    {record && record.documentsPending == true ? (
                      <Icon type={'close'} style={{ color: 'red' }} />
                    ) : (
                      <Icon type={'check'} style={{ color: 'limegreen' }} />
                    )}
                  </Tag>
                </span>
              ) : null}
            </a>
          </Tooltip>
        ) : (
          item
        )
      }
    },
    {
      title: 'DOB',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      width: 90,
      render: val => {
        return <div style={{ width: 90 }}>{newFormatDisplayDate(val)}</div>
      }
    },
    {
      title: 'Status',
      key: 'applications.status',
      dataIndex: 'applications.status',
      width: 150,
      render: (item, record) => {
        return (
          <React.Fragment>
            <div style={{ width: 150 }}>
              <div
                className={'label label1'}
                style={{
                  background: statusColors(item),
                  width: 80,
                  padding: 3
                }}>
                {item}
              </div>
            </div>
          </React.Fragment>
        )
      }
    },
    {
      title: 'University',
      key: 'applications.courseUniversity',
      dataIndex: 'applications.courseUniversity',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.logo && item.logo.url ? (
              <Avatar src={item.logo.url} size={20} />
            ) : (
              <Avatar src={defaultLogoList.defaultUniversityLogo} size={20} />
            )}
            {item.universityName ? `  ${item.universityName}` : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Country',
      key: 'applications.universityCountry',
      dataIndex: 'applications.universityCountry',
      width: 100,

      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.countryName ? item.countryName : null}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Program',
      key: 'applications.courseName',
      dataIndex: 'applications.courseName',
      width: 150,
      render: item => {
        return <div style={{ width: 150 }}>{item}</div>
      }
    },
    {
      title: 'Intake',
      key: 'applications.intake',
      dataIndex: 'applications.intake',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.month && item.year
              ? `${item.month}, ${item.year}`
              : ''}
          </React.Fragment>
        )
      }
    },

    {
      title: 'Current User',
      key: 'applications.assignedUserId',
      dataIndex: 'applications.assignedUserId',
      width: 150,
      render: (val, record) => {
        let { agentId, statusList, status, addByUserId } = record.applications
        return (
          <React.Fragment>
            {val && val.name ? val.name : ''}
            <br />
            <div>
              Agent :{' '}
              {agentId && agentId.companyName ? agentId.companyName : ''}
            </div>
            {addByUserId && addByUserId.name ? (
              <div className={'mt5 colorPrimary font12'}>
                (Added By :{' '}
                {addByUserId && addByUserId.name ? addByUserId.name : ''})
              </div>
            ) : (
              ''
            )}

            <CheckUserRight
              user={user}
              rightUserType={['admin', 'branchManager', 'branchUser']}>
              {agentId && agentId.mobile ? (
                <div>Mob : {agentId.mobile}</div>
              ) : (
                ''
              )}
            </CheckUserRight>
            <div>{longDisplayDate(statusList.date)}</div>
          </React.Fragment>
        )
      }
    },
    {
      title: 'Student Manager',
      dataIndex: 'branchManagerId',
      hidden: !managerViewRight.includes(user.userType),
      width: 125,
      key: 'branchManagerId',
      render: (item, record) => {
        let { branchManagerId } = record
        return (
          <React.Fragment>
            {branchManagerId ? branchManagerId.name : null}
          </React.Fragment>
        )
      }
    },
    /* {
       title: 'Managers',
       dataIndex: 'managers',
       hidden: !managerNewViewRight.includes(user.userType),
       width: 120,
       key: 'managers',
       render: (item, record) => {
         return (
           <React.Fragment>
             {branchManagerList && branchManagerList.length ? (
               <ShowListOfAgentManager
                 branchManagers={branchManagerList}
               />
             ) : null}

           </React.Fragment>
         )
       }
     },*/
    {
      title: 'Marketing',
      dataIndex: 'marketingUser',
      width: 120,
      key: 'marketingUser',
      render: (item, record) => {
        let { marketingUserId } = record.applications
        return (
          <React.Fragment>
            {marketingUserId && marketingUserId.name ? marketingUserId.name : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Latest Note',
      key: 'applications.latestComment',
      dataIndex: 'applications.latestComment',
      width: 220,
      render: val => {
        return (
          <div style={{ width: 180 }}>
            {val && val.text ? <RenderComment item={val} /> : null}
          </div>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      fixed: 'right',
      width: 120,
      render: (val, record) => {
        return (
          <div className={'actionBtnGroup'}>
            <div>

              {!record.applications.directClosed && (
                <AllowComponentRightsWise rightUserType={['agent', 'subAgent']}>
                  <Tooltip title={'View Application'}>
                    <a
                      className='btn'
                      href={`/application/view?appId=${
                        record.applications._id
                      }&studentId=${record._id}`}>
                      <img
                        src='../dist/img/view.svg'
                        alt=''
                        className={'eyeBtn'}
                      />
                    </a>
                  </Tooltip>{' '}
                </AllowComponentRightsWise>
              )}

              <AllowComponentRightsWise
                rightUserType={[
                  'admin',
                  'branchUser',
                  'branchManager',
                  'userManager'
                ]}>
                <Tooltip title={'View Application'}>
                  <a
                    className='btn'
                    href={`/student/application?studentId=${record._id}&appId=${
                      record.applications._id
                    }`}>
                    <img src='/dist/img/view.svg' alt='' />
                  </a>
                </Tooltip>


                <Tooltip title={'View Other Application'}>
                  <button
                    className='btn'
                    onClick={() => {
                      events.openOtherAppDrawer(record)
                    }}>
                    <Icon type={'ordered-list'} />
                  </button>
                </Tooltip>

                <Tooltip title={'Add Note'}>
                  <button
                    className='btn'
                    onClick={() => {
                      events.openAddCommentDrawer(record)
                    }}>
                    <Icon type={'plus'} />
                  </button>
                </Tooltip>
              </AllowComponentRightsWise>
            </div>


          </div>
        )
      }
    }
  ]


  const loadColumns = () => {
    let columnArr = []
    lodash.each(columns, item => {
      if (item.hidden == undefined) {
        columnArr.push(item)
      } else {
        if (!item.hidden) {
          columnArr.push(item)
        }
      }
    })
    return columnArr
  }

  const filterBlock = (
    <Row gutter={16}>

      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select
            name='Status'
            onChange={item => setStatus(item)}
            filterOption={filterOption}
            className={'antSelect'}
            placeholder={'Status'}
            allowClear={true}
            showSearch={true}
            value={status || undefined}>
            {ukApplicationSteps && ukApplicationSteps.length
              ? ukApplicationSteps.map((item, key) => {
                return (
                  <Option value={item.name} key={key}>
                    {item.name}
                  </Option>
                )
              })
              : null}
          </Select>
        </div>
      </Col>


      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select
            name='Intake'
            onChange={item => setIntake(item)}
            filterOption={filterOption}
            className={'antSelect'}
            allowClear={true}
            showSearch={true}
            placeholder={'Intake'}
            value={intake || undefined}>
            {Intakes && Intakes.length
              ? Intakes.map((item, key) => {
                return (
                  <Option value={item} key={key}>
                    {item}
                  </Option>
                )
              })
              : null}
          </Select>
        </div>
      </Col>


      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select
            name='Search By Draft'
            filterOption={filterOption}
            className={'antSelect'}
            allowClear={true}
            placeholder={'Draft Application'}
            onChange={item => setDraftApplication(item)}
            value={draftApplication || undefined}>
            {ConditionOptions && ConditionOptions.length
              ? ConditionOptions.map((item, key) => {
                return (
                  <Option value={item.name} key={key}>
                    {item.name}
                  </Option>
                )
              })
              : null}
          </Select>
        </div>
      </Col>

      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='search-box-table round ml0'>
          <input
            className='form-control form-control'
            type='search'
            placeholder='Name...'
            value={name}
            onChange={e => {
              events.enterName(e.target.value)
            }}
          />
          <img src='/dist/img/search.png' alt='' />
        </div>
      </Col>

      <Col md={4} sm={4} xs={8} lg={4}>
        <Button onClick={() => searchFxn()} className={'roundBtn'}>
          Search
        </Button>
        <Button onClick={() => clearFxn()} className={'roundBtn'}>
          Clear
        </Button>
      </Col>
    </Row>
  )

  return (
    <React.Fragment>
      <div>
        <div className='row mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div
                className='table-head d-flex align-items-center all-student-search'
                style={{ height: 60 }}>
                <Button
                  onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                  className={'roundGoBackBtn'}
                  icon={'arrow-left'}>
                  Back
                </Button>
                <h5>All Bloomsbury Applications </h5>
                <div
                  className='search-box-table'
                  style={{ backgroundColor: 'transparent' }}>
                  (Total - {totalApplication})
                </div>
                <div className='sort-box-table mark-btn'>


                </div>
              </div>
              <div className='card-body table-responsive'>
                {filterBlock}
                <TableComp
                  columns={loadColumns()}
                  rowKey={(item, index) => {
                    return index
                  }}
                  apiRequest={apiRequest}
                  pagination={{
                    position: 'top',
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '50'],
                    defaultPageSize: 10,
                    current: filters.page ? filters.page : 1
                  }}
                  extraProps={{ scroll: { x: 1800 } }}
                  ref={tableAppRef}
                />
              </div>
            </div>
          </div>
        </div>


      </div>


      {visibleAddCommentDrawer && applicationObj && applicationObj._id ? (
        <AddNote
          visible={visibleAddCommentDrawer}
          studentData={applicationObj}
          addedType={'direct'}
          reloadFxn={events.reloadFxn}
          onClose={() => events.closeAddCommentDrawer()}
          applicationId={applicationObj.applications._id}
        />
      ) : null}


    </React.Fragment>
  )
}

export default BloomsburyInstituteApplications
