import React, { useEffect, useState } from 'react';
import { notification, Pagination, Button, Popconfirm, Icon } from 'antd';
import moment from 'moment';
import { useDispatch, connect } from 'react-redux';
import { instituteEventListFxn } from '../../institutes/action';
import { addAppliedEventFxn, appliedEventListFxn } from '../actions/student';
import { displayTime } from '../../../components/_utils/appUtils';

const InstituteEvents = (props) => {
  const { currentUser } = props;
  const [totalInstitutes, setTotalInstitutes] = useState('');
  const [eventList, setEventList] = useState([]);
  const [appliedEvents, setAppliedEvents] = useState([]);
  const [filterOption, setFilterOption] = useState('myEvents');
  const [pageObj, setPageObj] = useState({
    page: 1,
    count: 20
  });

  const dispatch = useDispatch();

  useEffect(() => {
    apiRequest();
    apiRequest2();
  }, []);

  const apiRequest = async (page = 1) => {
    let params = { ...pageObj, page, results: pageObj.count, status: 'Approved' };
    const resp = await dispatch(instituteEventListFxn(params));
    setTotalInstitutes(resp.total);
    setEventList(resp.data);
    setPageObj((prevState) => ({ ...prevState, page }));
  };

  const apiRequest2 = async () => {
    try {
      const params = { results: 50, count: 50, studentId: currentUser._id };
      const resp = await dispatch(appliedEventListFxn(params));
      if (Array.isArray(resp.data)) {
        setAppliedEvents(resp.data);
      } else {
        console.error('Response data is not an array:', resp.data);
      }
    } catch (error) {
      console.error('Error fetching applied events:', error);
    }
  };

  const isUserRegistered = (eventId) => {
    return appliedEvents.some((appliedEvent) => appliedEvent.eventId === eventId);
  };

  const getRegistrationInfo = (eventId) => {
    const registeredEvent = appliedEvents.find((appliedEvent) => appliedEvent.eventId === eventId);
    
    if (registeredEvent) {
      return moment(registeredEvent.createdAt).format('Do MMM YY, h:mm A');
    }
  
    return null;
  };
  

  const applyForEvent = async (eventId, event) => {
    let resp = await dispatch(addAppliedEventFxn(eventId, event));
    apiRequest();
    apiRequest2();
  };
  const eventDateColors = ['#e8f4f8', '#e3e2c9', '#e1cfe3', '#edf4e0']
  const isPastDateEvent = (date) => {
    return moment(date).isBefore(moment(), 'day');
  };

  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center all-student-search'>
            <h5>Institute All Events</h5>
          </div>
          <div className='card-body table-responsive'>
            <section className='blog-innovation webinar-info'>
              <div className='row'>
                {eventList.map((event, index) => (
                  <div key={event._id} className='col-lg-12 col-md-4'>
                    <div className='product-teaser event-teaser event-teaser1'>
                      <div className='event-content-wrap1'>
                        <div className='event-content-container'>
                          <div className='event-content'>
                            <div className='event-meta'>
                              <div className='event-date-wrap1'>
                                <div className='event-date1'>
                                  <span
                                    className='event-date-day1'
                                    style={{
                                      backgroundColor:
                                        eventDateColors[index % eventDateColors.length]
                                    }}
                                  >
                                    {moment(event.date).format('Do MMM YY')}
                                    <br />
                                    <p className={'event-date-time1'}>{displayTime(event.time)}</p>
                                  </span>
                                </div>
                              </div>
                              <div className='event-venue-wrap1'>
                                <div className='event-title event-title1 fontLg'>
                                  <div
                                    className='field field-title field-label-hidden field-item mb5'
                                    style={{ color: 'black', textTransform: 'capitalize' }}
                                  >
                                    {event.venue}
                                  </div>
                                </div>
                                <div className='event-location mt5'>
                                  <div className='event-details-text'>
                                    <i style={{ fontSize: '12px' }}>{event.details}</i>
                                  </div>
                                  <div className='field field-field-event-location field-label-hidden field-item font14'>
                                    <div className={'mb5'}>
                                      {event.city}, {event.state}
                                    </div>
                                    <div>
                                      {event.status === 'Pending' && (
                                        <label className={'label label-success label-xs'}>
                                          Pending
                                        </label>
                                      )}
                                      {event.status === 'Cancel' && (
                                        <label className={'label label-danger label-xs'}>Cancel</label>
                                      )}
                                      {event.status === 'Approved' && (
                                        <label className={'label label-success-green label-xs'}>
                                          Active
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='ml20 aic event-button'>
                              {isPastDateEvent(event.date) ? (
                                <label className={'label label-closed'}>Closed</label>
                              ) : (
                                <>
                                  {isUserRegistered(event._id) ? (
                                    <div className='registered-info'>
                                    <label className={'label label-success-green'}>
                                      <Icon type={'check'} style={{ marginRight: '8px', paddingTop: '5px' }} /> Already Registered
                                    </label>
                                    <p style={{ fontSize: '10px'}} className='mt10'>
                                    Reg. Date: {getRegistrationInfo(event._id)}
                                  </p>
                                  </div>
                                  ) : (
                                    <Popconfirm
                                      title={'Are you sure you want to register for this event?'}
                                      onConfirm={() => {
                                        if (event.status === 'Approved') {
                                          applyForEvent(event._id, event);
                                        } else {
                                          notification.warning({ message: 'You cannot register for non-active events' });
                                        }
                                      }}
                                    >
                                      <Button className={'button mt-0'} style={{ minHeight: '34px' }} target={'_blank'}>
                                        <Icon type={'plus'} /> Register Now
                                      </Button>
                                    </Popconfirm>
                                  )}
                                </>
                              )}
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className={'col-md-12'}>
                  <div className={'mt20 alignRight'}>
                    <Pagination
                      defaultCurrent={1}
                      current={pageObj.page}
                      total={totalInstitutes}
                      onChange={(item) => {
                        apiRequest(item);
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ global }) => ({
  currentUser: global.currentUser
});

export default connect(mapStateToProps)(InstituteEvents);
