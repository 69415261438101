import { Card, Col, Drawer, Icon, Row, Tabs, Tooltip } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  currencyAmt,
  currencySymbols,
  longDisplayDate,
  newStringDate
} from '../../../components/_utils/appUtils'
import { listAllCountries } from '../../countries/actions/countries'
import { countryList } from '../../login/action'
import {
  agentCommissionTransactionFxn,
  loadExpenseTransactions
} from '../../universityCommission/actions'
import SingleExpense from '../../universityCommission/drawers/singleExpense'
import WithdrawalComponent from './withdrawalComponent'

const { TabPane } = Tabs

let agentCommissionInit = {
  visible: false,
  expenseId: {}
}
const TransactionListExpese = props => {
  let tableRef = useRef()
  let [total, setTotal] = useState(0)
  let [pageObj, setPageObj] = useState({
    page: 1,
    count: 50
  })

  let dispatch = useDispatch()
  let {
    visible,
    onClose,
    currentBalance,
    user
  } = props
  let [visibleWithdrawal, setVisibleWithdrawal] = useState(false)
  let [transactionData, setTransactionData] = useState([])

  const [singleExpenseState, setSingleExpenseState] = useState(
    agentCommissionInit
  )
  const [refreshKey, setRefreshKey] = useState(moment())

  useEffect(() => {
    loadExpenseTransactionsFxn()
  }, [])
  const loadExpenseTransactionsFxn = async () => {
    let params = _.clone(pageObj)
    params.results = params.count
    params.sortField = '_id'
    params.sortOrder = 'descend'

    let { data, total } = await dispatch(loadExpenseTransactions({ ...params, regExFilters: [] }))
    setTotal(total)
    setTransactionData((prevState) => {
      return [
        ...prevState,
        ...data
      ]
    })
    setPageObj((prevState) => {
      return {
        ...prevState,
        page: pageObj.page + 1
      }
    })
  }

  const loadMore = () => {
    loadExpenseTransactionsFxn()
  }

  const events = {
    showWithdrawal: () => {
      setVisibleWithdrawal(true)
    },
    hideWithdrawal: () => {
      setVisibleWithdrawal(false)
      setRefreshKey(moment())
    },
    showSingleExpense: record => {
      setSingleExpenseState({
        visible: true,
        expenseId: record
      })
    },
    hideSingleExpense: () => {
      setSingleExpenseState({
        visible: false,
        expenseId: ''
      })
    },
    reload: () => {
    }

  }

  return (
    <>
      <Drawer
        title={
          <div
            onClick={() => {
              events.showWithdrawal()
            }}>
            Transaction list
            <a className='btn btn-default btn-sm ml10'> Withdraw Commission</a>
          </div>
        }
        onClose={onClose}
        visible={visible}
        width={'75%'}>
        <div className={'ledgerBox'}>
          <Card bordered={true} key={refreshKey}>
            <>
              <Row className={'header'}>
                <Col span={4}>Date</Col>
                <Col span={12}>Transaction/Reference</Col>
                <Col span={4}>Debit</Col>
                <Col span={4}>Credit</Col>
              </Row>

              {transactionData && transactionData.length
                ? transactionData.map(item => {
                  let {
                    transactionType,
                    expenseId,
                    invoiceNo,
                    amount,
                    withdrawalId
                  } = item
                  return (
                    <Row key={item._id}>
                      <Col span={4} className={'dateBox'}>
                        {newStringDate(item.updatedAt)}
                      </Col>
                      <Col span={12}>
                        {item.transactionType === 'Credit' ? (
                          <>{invoiceNo}</>
                        ) : (
                          <>
                            {withdrawalId && withdrawalId.account ? (
                              <div>
                                Acc no : {withdrawalId.account.accountNo} (
                                {withdrawalId.account.bankName}) <br />
                              </div>
                            ) : null}
                          </>
                        )}

                        {item.transactionType == 'Credit' ? (
                          <>
                            {expenseId && expenseId.expenseType ? <div>
                                Expense
                                ({expenseId && expenseId.expenseType ? expenseId.expenseType
                                : null})
                                <Tooltip title={'View Details'}>
                                  <a className='font12'
                                     onClick={() => {
                                       events.showSingleExpense(item.expenseId)
                                     }}
                                     style={{ marginLeft: 6 }}>
                                    <img src='../uniBtn/eye.png'
                                         className={'eyeBtn'}
                                         style={{ height: 14 }}
                                    />
                                  </a>
                                </Tooltip>
                              </div> :
                              <div>
                                {item.note}
                              </div>}
                          </>
                        ) : (
                          ''
                        )}

                        {item.transactionType == 'Debit' ? (
                          <>
                            <div className={'font12'}>
                              {withdrawalId &&
                              withdrawalId.receipt &&
                              withdrawalId.receipt.url ? (
                                <>
                                  <a
                                    href={withdrawalId.receipt.url}
                                    target={'_blank'}>
                                    Download Receipt
                                  </a>

                                  {withdrawalId.invoice &&
                                  withdrawalId.invoice.path ? (
                                    <>
                                      <a
                                        href={withdrawalId.invoice.path}
                                        target={'_blank'}
                                        className={'ml20'}>
                                        Download Invoice
                                      </a>
                                    </>
                                  ) : null}
                                </>
                              ) : (
                                <>
                                  <div className={'font11'}>
                                    Withdrawal :{' '}
                                    {withdrawalId.status == 'Pending' ?
                                      <label
                                        className={'label label-warning xs1'}>
                                        {withdrawalId.status}
                                      </label> : null}
                                    {withdrawalId.status == 'Approved' ?
                                      <label
                                        className={'label label-success-green xs1'}>
                                        {withdrawalId.status}
                                      </label> : null}
                                    {withdrawalId.status == 'Cancel' ?
                                      <label className={'label label-danger xs1'}>
                                        {withdrawalId.status}
                                      </label> : null}
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                      </Col>

                      <Col span={4} className={'debit'}>
                        {transactionType === 'Debit' ? (
                          <span>{user && user.defaultCurrency ? user.defaultCurrency : ''} {amount}</span>
                        ) : (
                          ''
                        )}
                      </Col>
                      <Col span={4} className={'credit'}>
                        {transactionType === 'Credit' ? (
                          <span>{user && user.defaultCurrency ? user.defaultCurrency : ''} {amount}</span>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  )
                })
                : null}
            </>

            {total && transactionData.length < total ? <>
              <div className={'alignCenter p-2'}>
                <a className={'btn btn-outline-primary'}
                   onClick={() => {
                     loadMore()
                   }}>Load More</a>
              </div>

            </> : null}
          </Card>
        </div>

        {visibleWithdrawal ? (
          <WithdrawalComponent
            user={user}
            visible={visibleWithdrawal}
            onClose={events.hideWithdrawal}
            onSubmit={() => {
              events.hideWithdrawal()
              loadExpenseTransactionsFxn()
              props.onSubmit()
            }}
            currentBalance={currentBalance}
          />
        ) : null}

        {singleExpenseState.visible ? (
          <SingleExpense
            visible={singleExpenseState.visible}
            onClose={events.hideSingleExpense}
            onSubmit={events.reload}
            expense={singleExpenseState.expenseId}
          />
        ) : null}
      </Drawer>
    </>
  )
}
export default TransactionListExpese
