import {apiUrl} from '../../../settings'

export const eventListUrl = () => {
    return apiUrl + '/event/list'
}

export const eventListExportUrl = () => {
    return apiUrl + '/event/listExport'
}
export const eventLeadExportUrl = () => {
    return apiUrl + '/event/export-lead'
}
export const approveLeadUrl = () => {
    return apiUrl + '/event/approvedLead'
}

export const cancelLeadUrl = () => {
    return apiUrl + '/event/cancelLead'
}
