import { customAxios as axios, getToken } from '../../../request'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'
import { paymentReceiptUrl, enrollmentLetterUrl } from './apis'
import { notification } from 'antd'

export const fcmtOnshoreEnrollmentLetterFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(enrollmentLetterUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.success) {
    notification.success({ message: data.message })
  } else {
    notification.error({ message: data.message })
  }
  return data
}
export const fcmtOnshorePaymentReceiptFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(paymentReceiptUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.success) {
    notification.success({ message: data.message })
  } else {
    notification.error({ message: data.message })
  }
  return data
}

