import React from 'react'
import { countryIds } from '../../../components/_utils/appUtils'
import ProgramListComponent from './ProgramListComponent'
import { connect } from 'react-redux'

const CanadaProgramList = (props) => {
  let { currentUser } = props
  // let countryId = currentUser.countryId ? currentUser.countryId : currentUser.userType == 'student' ? countryIds.canadaOnshore : countryIds.canada
  let countryId = countryIds.canada
  return (
    <>
      <ProgramListComponent countryId={countryId} countryName={'Canada'} />
    </>
  )
}

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})
export default connect(
  mapStateToProps,
  null
)(CanadaProgramList)
