import React, { useState, useEffect } from 'react'
import { notification, Form, Button, Select } from 'antd'
import { Opportunity } from './opportunityComponent'
import { EducatorFeedbackFxn, listAllUsers } from '../users/actions/user'
import { useDispatch } from 'react-redux'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { AgentList } from '../users/actions/user'
import debounce from 'lodash/debounce'

const sourceList = [
  'Newspaper', 'Social Media', 'Website', 'Digital Advertising', 'Email'
]
const fields = {
  name: '',
  country: '',
  university: '',
  shareYourExperience: '',
  rating: null
}
const countryList = [
  'Canada',
  'United States',
  'Australia',
  'United Kingdom'
]
const ratingList = [
  { name: '1 Star', value: 1 },
  { name: '2 Star', value: 2 },
  { name: '3 Star', value: 3 },
  { name: '4 Star', value: 4 },
  { name: '5 Star', value: 5 }
]
const { Option } = Select
const ContactUs = (props) => {
  const dispatch = useDispatch()
  let [state, setState] = useState(fields)
  let [countries, setCountries] = useState([])
  let [agentList, setAgentList] = useState([])
  let [agentName, setAgentName] = useState('')
  let [agentId, setAgentId] = useState('')
  useEffect(() => {

  }, [])
  const setData = (e) => {
    let { value, name } = e.target
    setState({ ...state, [name]: value })
  }

  const loadCountry = async () => {
    let { data } = await countryList()
    setCountries(data)
  }
  const sendMessage = async () => {
    let { name, university, country, shareYourExperience, rating } = state
    if (!name) {
      notification.warning({ message: 'Enter educator name.' })
      return
    }
    if (!country) {
      notification.warning({ message: 'Choose country' })
      return
    }
    if (!university) {
      notification.warning({ message: 'Enter university' })
      return
    }
    if (!rating) {
      notification.warning({ message: 'Choose rating' })
      return
    }
    if (!shareYourExperience) {
      notification.warning({ message: 'Enter your experience' })
      return
    }
    let { success, message } = await dispatch(EducatorFeedbackFxn({ ...state }))
    if (success) {
      alert('Thank you for your feedback.')
      setState({ ...fields })
    } else {
      notification.warning({ message: message })
    }
  }
  return (
    <React.Fragment>
      <section className="banner blog-banner contact-banner">

        <div className="container">
          <div className="mr-auto">
            <div className="content text-left" style={{ marginTop: 30 }}>
              <LazyLoadImage src="../assets/Ryan_query.png" alt="" className="w-100"/>
            </div>
          </div>
        </div>

      </section>

      <section className="contact-form">
        <div className="container">

          <div className="row">

            <div className="col-lg-7">
              <div className={'alignRight'}>
                <label className={'feedbackLabel'}>
                  Representative feedback form
                </label>
              </div>
              <div className="box">


                <Form noValidate={true}>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputEmail4">Name of the Representative</label>
                      <input type="text" className="form-control" placeholder={'Name of the Representative'}
                             name={'name'} value={state.name} onChange={(e) => setData(e)}/>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputState">Country</label>
                      <select id="inputState" className="form-control"
                              name={'country'} value={state.country} onChange={setData}>
                        <option value={''}>Choose Country...</option>
                        {countryList.map((item, key) => {
                          return (
                            <option value={item} key={key}>{item}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputEmail4">Name of the Institution</label>
                      <input type={'text'} className="form-control"
                             placeholder={'Name of the Institution'}
                             name={'university'} value={state.university} onChange={(e) => setData(e)}/>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputEmail4">Overall Edu Fest Rating</label>
                      <br/>
                      <div className={'ratioBox'}>
                        {ratingList.map((item, key) => {
                          return (
                            <label key={key}>{item.name}
                              <input type="radio" name={'rating'} value={item.value}
                                     checked={item.value == state.rating}
                                     onChange={(e) => {
                                       setData(e)
                                     }}/></label>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputEmail4">Write Your Comments</label>
                      <textarea className="form-control customTextArea" placeholder={'Share Your Experience'}
                                name={'shareYourExperience'} value={state.shareYourExperience}
                                onChange={(e) => setData(e)}>
                      </textarea>
                    </div>
                  </div>

                  <div className={'alignRight'}>
                    <button type="submit" className="btn btn-primary" onClick={() => sendMessage()}>
                      Submit
                    </button>
                  </div>
                </Form>

              </div>
            </div>

            <div className="col-lg-5">
              <div className="content">
                <h3>For the users, <br/> You can download the app here:</h3>
                <div className="store">
                  <a href="https://play.google.com/store/apps/details?id=com.unizportal"><LazyLoadImage
                    src="/assets/image/google-play.png" alt=""/></a>
                  <a href="https://apps.apple.com/in/app/unizportal/id1569018281"><LazyLoadImage
                    src="/assets/image/app-store.png" alt=""/></a>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>
      <div style={{ marginTop: 200 }}>
        <Opportunity/>
      </div>


    </React.Fragment>
  )
}
export default ContactUs
