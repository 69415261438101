import { customAxios as axios, getToken } from '../../../request'
import { notification } from 'antd'
import {
  getNationalCollegeApplicationListUrl,
  getNationalCollegeOfferListUrl,
  getNationalCollegeLoaListUrl,
  nationalCollegeUploadDocumentUrl
} from '../apis/nationalCollege'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'

export const getNationalCollegeApplicationListFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getNationalCollegeApplicationListUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}

export const getNationalCollegeOfferListFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getNationalCollegeOfferListUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}

export const getNationalCollegeLoaListFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getNationalCollegeLoaListUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}

export const uploadNationalCollegeDocumentFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(nationalCollegeUploadDocumentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Uploaded Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error '
    })
  }
  return data
}
