import React, { useState, useEffect, useRef } from 'react'
import { Card, Button, Drawer, Form, Row, Col, Input } from 'antd'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { getReferralList } from '../../referrals/actions'
import {
  DefaultTablePagination,
  displayDate,
  InputBox
} from '../../../components/_utils/appUtils'
import ViewApplication from '../../applications/views/viewApplication'

const YourReferralsList = props => {
  const [totalReferrals, setTotalReferrals] = useState(0)
  let [referralList, setReferralList] = useState([])
  let [loading, setLoading] = useState(true)
  let [listData, setListData] = useState([])
  const [state, setState] = useState({
    viewCourseDrawer: false,
    studentObj: ''
  })
  const [searchData, setSearchData] = useState({
    name: '',
    email: '',
    joiningDate: '',
    dateOfBirth: '',
    status: '',
    mobile: ''
  })
  const [searchLoading, setSearchLoading] = useState(false)
  const filterData = searchData

  const tableRef = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    apiRequest()
  }, [])

  const apiRequest = async () => {
    try {
      let { data } = await dispatch(getReferralList())
      setListData(data)
      setTotalReferrals(data.length)
      setLoading(false)
      let filteredData = data.filter(item => {
        const formattedDate = displayDate(item.createdAt)
        return (
          item.name

            .includes(searchData.name) &&
          formattedDate.includes(searchData.joiningDate) &&
          item.dateOfBirth

            .includes(searchData.dateOfBirth) &&
          item.mobile.includes(searchData.mobile) &&
          item.email.includes(searchData.email) &&
          item.status.includes(searchData.status)
        )
      })

      setTotalReferrals(filteredData.length)
      setListData(filteredData)
    } catch (error) {
      console.error('Error fetching referral list:', error)
      setLoading(false)
    }
  }

  const events = {
    viewCourse: record => {
      setState({
        ...state,
        viewCourseDrawer: true,
        studentObj: record
      })
    },
    viewCourseClose: () => {
      setState({
        ...state,
        viewCourseDrawer: false,
        studentObj: {}
      })
      tableRef.current.reload()
    },
    searchFxn: () => {
      setSearchLoading(true)
      apiRequest()
      setSearchLoading(false)
    },

    clearFxn: () => {
      setSearchData({
        name: '',
        email: '',
        joiningDate: '',
        dateOfBirth: '',
        status: '',
        mobile: ''
      })
      apiRequest()
    }
  }

  const columnsReferrals = [
    {
      title: 'Serial No.',
      dataIndex: 'serialNo',
      render: (val, record, index) => <div>{index + 1}</div>
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: val => <div>{val}</div>
    },
    {
      title: 'Date of Birth',
      dataIndex: 'dateOfBirth',
      render: val => <div>{displayDate(val)}</div>
    },
    {
      title: 'Mobile No.',
      dataIndex: 'mobile',
      render: item => <span>{item}</span>
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: item => <span>{item}</span>
    },

    {
      title: 'Joining Date',
      dataIndex: 'updatedAt',
      render: val => <div>{displayDate(val)}</div>
    },
    {
      title: 'Status',
      dataIndex: 'studentId.feesStatus',
      render: item => <span>{item}</span>
    }
  ]


  return (
    <div>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <Card>
            <div className='table-head d-flex align-items-center'>
              <h5>Your Referrals List({totalReferrals}): </h5>
            </div>
            <Row gutter={12} className={'filter_box'}>
              <Col span={4}>
                <InputBox title={'Search by name'}>
                  <input
                    value={searchData.name}
                    placeholder={'Name'}
                    onChange={({ target }) => {
                      setSearchData(prevData => ({
                        ...prevData,
                        name: target.value
                      }))
                    }}
                  />
                </InputBox>
              </Col>
              <Col span={4}>
                <InputBox title={'Search by date'}>
                  <input
                    value={searchData.joiningDate}
                    placeholder={'Date'}
                    onChange={({ target }) => {
                      setSearchData(prevData => ({
                        ...prevData,
                        joiningDate: target.value
                      }))
                    }}
                  />
                </InputBox>
              </Col>

              <Col span={4}>
                <InputBox title={'Search by DOB'}>
                  <input
                    value={searchData.dateOfBirth}
                    placeholder={'Date of Birth'}
                    onChange={({ target }) => {
                      setSearchData(prevData => ({
                        ...prevData,
                        dateOfBirth: target.value
                      }))
                    }}
                  />
                </InputBox>
              </Col>
              <Col span={4}>
                <InputBox title={'Search by Mobile'}>
                  <input
                    value={searchData.mobile}
                    placeholder={'Mobile No.'}
                    onChange={({ target }) => {
                      setSearchData(prevData => ({
                        ...prevData,
                        mobile: target.value
                      }))
                    }}
                  />
                </InputBox>
              </Col>
              <Col span={4}>
                <InputBox title={'Search by Email'}>
                  <input
                    value={searchData.email}
                    placeholder={'Email'}
                    onChange={({ target }) => {
                      setSearchData(prevData => ({
                        ...prevData,
                        email: target.value
                      }))
                    }}
                  />
                </InputBox>
              </Col>
              <Col span={4}>
                <InputBox title={'Search by Status'}>
                  <input
                    value={searchData.status}
                    placeholder={'Status'}
                    onChange={({ target }) => {
                      setSearchData(prevData => ({
                        ...prevData,
                        status: target.value
                      }))
                    }}
                  />
                </InputBox>
              </Col>
              <Col span={24}>
                <div className={'btn_group'}>
                  <a className={'default_btn'} onClick={events.searchFxn}>
                    Search
                  </a>
                  <a className={'default_btn'} onClick={events.clearFxn}>
                    Clear
                  </a>
                </div>
              </Col>
            </Row>
            <div className='card-body table-responsive'>
              <TableComp
                columns={columnsReferrals}
                dataSource={listData}
                pagination={DefaultTablePagination()}
                ref={tableRef}
                loading={loading}
              />
            </div>
          </Card>
        </div>
      </div>

      {state.viewCourseDrawer ? (
        <ViewApplication
          visible={state.viewCourseDrawer}
          pageType={'student'}
          onClose={() => events.viewCourseClose()}
          studentObj={state.studentObj}
        />
      ) : null}
    </div>
  )
}

export default YourReferralsList
