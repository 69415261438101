import { notification } from 'antd'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import {
  closeAgentDealUrl,
  getUniversityFoDealCloseUrlUrl,
  listAllMarketingAgentsUrl,
  addRequestForUniversityAccessUrl,
  updateRequestStatusUrl,
  accessGrantedRequestUrl,
  accessRequestListUrl
} from '../api/closeDeal'

export const listAllMarketingAgents = filters => async dispatch => {
  let config = {
    params: { ...filters },
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(listAllMarketingAgentsUrl(), config)
  return data
}

export const closeAgentDealFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(closeAgentDealUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Deal Closed Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error '
    })
  }
  return data
}
export const getUniversityFoDealCloseFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let data = await axios.post(
    getUniversityFoDealCloseUrlUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())

  return data
}

export const addRequestForUniversityAccessFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addRequestForUniversityAccessUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Deal Closed Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error '
    })
  }
  return data
}
export const updateRequestStatusFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateRequestStatusUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Deal Closed Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error '
    })
  }
  return data
}
export const accessGrantedRequestFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(accessGrantedRequestUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Deal Closed Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error '
    })
  }
  return data
}
export const accessRequestListFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  let { data } = await axios.get(accessRequestListUrl(), config)
  console.log(data)
  dispatch(hidePageLoad())
  return data.data
}
