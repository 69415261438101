import React from 'react'
import styles from './login.less'
import {
  Form, Icon, Input, Button, Checkbox, notification, Col, Row
} from 'antd'

import Request from '../../request'
import { showLoader, hideLoader, showPageLoad, hidePageLoad } from '../../modules/actions'

import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { RegisterButton } from './registerButton'
import { recoverPassword } from './action'

class ForgotPassword extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      email: ''
    }
    props.dispatch(hidePageLoad())
    props.dispatch(hideLoader())
    this.checkIsLoggedIn()
  }

  checkIsLoggedIn() {
    let resp = localStorage.getItem('token')
    if (resp) {
      let { dispatch } = this.props
      dispatch(push('/dashboard'))
    }
  }


  openLoginPage = () => {
    let { dispatch } = this.props
    dispatch(push('/login'))
  }

  submitFinal = (e) => {
    const { getFieldDecorator, getFieldValue, resetFields } = this.props.form
    const { dispatch } = this.props
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let resp = await dispatch(recoverPassword(values))
        if (resp && resp.success) {
          alert(resp.message)
          resetFields()
        } else {
          alert(resp.message)
        }
      } else {
        /* notification.warning({
           message: 'Fill All Required Fields'
         })*/
      }
    })

  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form
    const { loading, dispatch } = this.props
    const { isUser, dontAskOtp, dontAskOtpForIds } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }
    return (
      <React.Fragment>
        <div id={'loginForm'} className={'hold-transition login-main'}>
          <header className=" w-100 navbar-fix">

            <div className="container">
              <div className="d-flex flex-column flex-md-row align-items-center pt-5">
                <h5 className="my-0 mr-md-auto font-weight-normal">
                  <a onClick={() => dispatch(push('/home'))}>
                    <img src="dist/img/AdminLTELogo.png" alt=""/>
                  </a>
                </h5>
                <nav className="my-2 my-md-0 d-flex align-items-center">

                  <a className="p-2 text-dark" onClick={() => this.openLoginPage()}>Login</a>
                  <RegisterButton customClass={'lite'}/>
                </nav>
              </div>
            </div>

          </header>
          <div className="login-form w-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 mr-auto">
                  <div className="login-box w-100">
                    <div>
                      <h5>Forgot Password</h5>
                      <Form onSubmit={this.submitFinal} autocomplete="off">
                        <div className="form-group">
                          <Form.Item>
                            {getFieldDecorator('email', {
                              rules: [{ required: true, message: 'Please input your email address!' }]
                            })(
                              <Input placeholder="Email address" type={'email'} id={'new-email'} name={'new-email'}
                                     autoComplete="new-email"/>
                            )}
                          </Form.Item>
                          <img src="dist/img/user-login.png" alt=""/>
                        </div>


                        <div className="d-flex align-items-center">
                          <span>
                            Already have an account? <br/>
                            <a onClick={() => this.openLoginPage()} style={{ textDecoration: 'underline' }}
                               className={'hyperLink'}>Login Here</a>
                          </span>

                          <Button className="btn ml-auto" type="button" loading={loading}
                                  style={{ marginTop: 0 }}
                                  htmlType="submit">Submit</Button>
                        </div>

                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const WrappedForgotPassword = Form.create()(ForgotPassword)


const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedForgotPassword)
