import { Col, Drawer, Form, Input, notification, Row, Select } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { InputBox } from '../../../components/_utils/appUtils'
import { RowTable } from '../../../components/_utils/RowTable'
import { FcmtStatusUpdate } from '../actions/chooseStudent'

const { Option } = Select
const statusList = [
  'CAQ Applied',
  'File pending for submission',
  'File Submitted',
  'Visa Approved',
  'Visa Rejected'
]
const LesterStatus = props => {
  let dispatch = useDispatch()
  let {
    visible,
    onClose,
    studentId,
    applicationId,
    reloadTable,
    studentData,
    university = 'Lester'
  } = props
  let [status, setStatus] = useState('')
  const handleSubmit = async () => {
    if (!status) {
      notification.warn({ message: 'Please choose status' })
      return
    }
    let obj = {
      studentId,
      applicationId,
      status
    }
    let resp = await dispatch(FcmtStatusUpdate(obj))
    if (resp && resp.success) {
      onClose()
      reloadTable()
    }
  }
  return (
    <Drawer
      visible={visible}
      title={`${university} Status`}
      onClose={onClose}
      width={'35%'}>
      <div className="row">
        <div className="col-lg-12">
          <Form className={'vertical-form'} autoComplete="off">
            <div className="form-box">
              <div className="card unizportal">
                {studentData &&
                studentData.applications &&
                studentData.applications.id ? (
                  <div className={'tableBox striped'}>
                    <RowTable
                      title={'Name (ID)'}
                      value={
                        <span>
                          {studentData.name} ({studentData.studentId})
                        </span>
                      }
                    />
                    <RowTable
                      title={'Application ID'}
                      value={<span>{studentData.applications.id}</span>}
                    />
                    <RowTable
                      title={'Application'}
                      value={<span>{studentData.applications.courseName}</span>}
                    />
                  </div>
                ) : null}
                <br />
                <div className="inner-form">
                  <Row gutter={24}>
                    <Col span={24}>
                      <InputBox title={`Status`}>
                        <Select
                          value={status}
                          onChange={item => setStatus(item)}>
                          {statusList && statusList.length
                            ? statusList.map((item, key) => {
                                return (
                                  <Option value={item} key={key}>
                                    {item}
                                  </Option>
                                )
                              })
                            : null}
                        </Select>
                      </InputBox>
                    </Col>
                  </Row>

                  <button className="btn" type="submit" onClick={handleSubmit}>
                    save
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Drawer>
  )
}
export default LesterStatus
