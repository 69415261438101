import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Drawer,
    Card,
    Form,
    Table,
    notification,
    Col,
    Row,
    Modal
} from 'antd';
import _ from 'lodash';
import GetEachFormFields from '../../../components/_utils/appFormUtils';
import { updateKycStatusFxn} from "../actions";
import { statusTypes } from '../reeudoUtils'


const UpdateKycStatus = (props) => {
    const dispatch = useDispatch()
    const {
        form: { getFieldDecorator, setFieldsValue },
        onClose,
        visible,
        appId
    } = props;

    const inputTypes = {
        fields: [
            {
                key: 'status',
                label: 'Update Kyc Status',
                required: true,
                type: 'select',
                options: statusTypes,
                keyAccessor: x => x.value,
                valueAccessor: x => `${x.name}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        status: x
                    })

                }
            },
        ],
    };

    const formItemLayout = {
        labelCol: {
            xs: { span: 0 },
            sm: { span: 0 },
            md: { span: 0 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        props.form.validateFields(async (err, values) => {
            if (!err) {
                const fd = new FormData();
                fd.append('status', values.status);

                const response = await dispatch(updateKycStatusFxn(appId, fd));

                if (response.success) {
                    notification.success({ message: response.message });
                    onClose();
                } else {
                    notification.error({ message: response.message });
                }
            }
        });
    };


    return (
        <Modal
            visible={visible}
            title={"Update KYC Status"}
            onCancel={onClose}
            width={"35%"}
            footer={null}
        >
            <>
                <Form onSubmit={handleSubmit}>
                    <Row gutter={24}>
                        <Card shadow={false} padding={false}>
                            {inputTypes.fields.map((item, key) => {
                                return (
                                    <Col span={item.span ? item.span : 24} md={item.span ? item.span : 24} sm={24} xs={24} key={key} className={'mb10'}>
                                        <GetEachFormFields
                                            item={item}
                                            className={'form-control'}
                                            getFieldDecorator={getFieldDecorator}
                                            formItemLayout={formItemLayout} />
                                    </Col>
                                );
                            })}
                        </Card>
                    </Row>

                    <Button type={"primary"} htmlType="submit" className={"mt-4 ml-4 round-bt"}>
                        Submit
                    </Button>

                </Form>
            </>
        </Modal>
    );
};

export default Form.create()(UpdateKycStatus);
