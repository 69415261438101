import React from 'react'
import { InputBox, testsForUk, indianStates } from '../../components/_utils/appUtils'
import { Checkbox, Col, Form, Select } from 'antd'
import _ from 'lodash'
import { connect } from 'react-redux'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import {
  getEvaluateUniversity,
  getEvaluateState,
  getEvaluateCourseLevel,
  getEvaluateIntakes,
  getEvaluateCountry
} from './action'

let { Option } = Select

const formItemLayout = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 }
  }
}
let fieldArr = [
  'countryId', 'universityId', 'courseLevel', 'stateId', 'education', 'percentage', 'examType', 'overall',
  'reading', 'listening', 'speaking', 'speaking', 'writing', 'intakes', 'entryRequirements', 'board', 'boardPercentage',
  'englishTest', 'stateBoardWaiverState', 'moiAcceptableState'
]

class EvaluateFiltersUniversityRequirement extends React.Component {

  constructor(props) {
    super()
    this.state = {
      countryList: [],
      stateList: [],
      universityList: [],
      intakeList: [],
      programLevelList: [],
      tuitionFee: [0, 100],
      tuitionFeeMin: 0,
      tuitionFeeMax: 100,
      applicationFee: [0, 500],
      educationList: [],
      englishRequirmentList: [],
      intakes: [],
      engRequirementMavValue: {},
      educationMavValue: '',
      currency: '$',
      moiAcceptable: false,
      moiAcceptableState: '',
      englishLanguage: false,
      englishLanguageTests: false,
      stateBoardWaiver: false,
      stateBoardWaiverState: '',
      initialBeforeInterview: false,
      noInitialBeforeInterview: false,
      ugOpenBoardAcceptable: false,
      distanceLearning: false
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps && nextProps.EvaluateReducers && nextProps.EvaluateReducers.reloadFilters) {
      this.setState({
        courseName: nextProps.EvaluateReducers.courseName,
        universityName: nextProps.EvaluateReducers.universityName
      }, () => {
        this.groupOfFunctions()
      })
      this.props.dispatch({ type: 'STOP_LOAD_FILTER' })
    }
  }

  getCommonFilters = () => {
    const { form: { getFieldValue } } = this.props
    let {
      tuitionFee,
      applicationFee,
      courseName,
      universityName,
      moiAcceptable,
      moiAcceptableState,
      englishLanguage,
      englishLanguageTests,
      stateBoardWaiver,
      stateBoardWaiverState,
      initialBeforeInterview,
      noInitialBeforeInterview,
      ugOpenBoardAcceptable,
      distanceLearning
    } = this.state

    let filters = {
      results: 1000
    }
    _.each(fieldArr, (item) => {
      filters[item] = getFieldValue(item)
    })

    if (englishLanguage == false){
      this.setState({englishLanguageTests: ''})
      englishLanguageTests = ''
    }
    if (moiAcceptable == false){
      this.setState({moiAcceptableState: ''})
      moiAcceptableState = ''
    }

    if (stateBoardWaiver == false){
      this.setState({stateBoardWaiverState: ''})
      stateBoardWaiverState = ''
    }

    filters.courseName = courseName
    filters.universityName = universityName
    filters.tuitionFee = tuitionFee
    filters.applicationFee = applicationFee
    filters.courseName = courseName
    filters.universityName = universityName
    filters.tuitionFee = tuitionFee
    filters.applicationFee = applicationFee
    filters.moiAcceptable = moiAcceptable
    filters.moiAcceptableState = moiAcceptableState
    filters.englishLanguage = englishLanguage
    filters.englishLanguageTests = englishLanguageTests
    filters.stateBoardWaiver = stateBoardWaiver
    filters.stateBoardWaiverState = stateBoardWaiverState
    filters.initialBeforeInterview = initialBeforeInterview
    filters.noInitialBeforeInterview = noInitialBeforeInterview
    filters.ugOpenBoardAcceptable = ugOpenBoardAcceptable
    filters.distanceLearning = distanceLearning
    return filters
  }

  events = {
    loadCountry: async () => {
      let params = {
        ...this.getCommonFilters()
      }
      let data = await this.props.dispatch(getEvaluateCountry(params))
      this.setState({ countryList: data })
    },
    loadStates: async () => {
      let params = {
        ...this.getCommonFilters()
      }
      let data = await this.props.dispatch(getEvaluateState(params))
      this.setState({ stateList: data })
    },
    loadUniversity: async () => {
      let params = {
        ...this.getCommonFilters()
      }
      let data = await this.props.dispatch(getEvaluateUniversity(params))
      this.setState({ universityList: data })
    },
    loadIntakes: async () => {
      let params = {
        ...this.getCommonFilters()
      }
      let data = await this.props.dispatch(getEvaluateIntakes(params))
      this.setState({
        intakeList: data
      })
    },
    loadCourseLevel: async () => {
      let params = {
        ...this.getCommonFilters()
      }
      let data = await this.props.dispatch(getEvaluateCourseLevel(params))
      this.setState({ programLevelList: data })
    },

    applyFilters: () => {
      let { form: { setFieldsValue } } = this.props
      let params = {
        ...this.getCommonFilters()
      }
      this.props.filterCallback(params)
    },

    clearFilters: () => {
      let { events } = this
      const { form: { setFieldsValue } } = this.props
      let filters = {}
      _.each(fieldArr, (item) => {
        filters[item] = null
      })
      filters.intakes = undefined
      setFieldsValue(filters, () => {
        events.loadCountry()
        events.loadStates()
        events.loadUniversity()
        events.loadIntakes()
        events.loadCourseLevel()
        this.props.events.clearFilterCallback()
      })
    }
  }

  groupOfFunctions = (type = '') => {
    let { events } = this

    let params = {
      ...this.getCommonFilters()
    }
    this.props.events.setFilter(params)
    if (type == 'englishTest' ||
        type == 'englishLanguageTests' ||
        type == 'stateBoardWaiverState' ||
        type == 'moiAcceptableState' ||
        type == 'moiAcceptable' ||
        type == 'englishLanguage' ||
        type == 'initialBeforeInterview' ||
        type == 'noInitialBeforeInterview' ||
        type == 'ugOpenBoardAcceptable' ||
        type == 'distanceLearning' ||
        type == 'stateBoardWaiver') {
      setTimeout(() => {
        events.applyFilters()
      }, 500)
    }
  }

  componentDidMount() {
    let { events } = this
    events.loadCountry()
    events.loadStates()
    events.loadUniversity()
    events.loadIntakes()
    events.loadCourseLevel()
  }

  checkEngRequiredCond = () => {
    const { form: { getFieldValue } } = this.props
    return getFieldValue('examType') && (getFieldValue('examType') == 'PTE' || getFieldValue('examType') == 'IELTS')
  }
  render() {
    const { form: { getFieldDecorator, setFieldsValue } } = this.props

    let programFilter = {
      fields: [
        {
          key: 'englishLanguage',
          label: 'English Language',
          hidden: false,
          customField: (
            <div className={'checkBoxUR2'}>
              <Checkbox
                checked={this.state.englishLanguage}
                onChange={(e) => {
                  this.setState({ englishLanguage: e.target.checked })
                  this.groupOfFunctions('englishLanguage')
                }}
              >
                English Language
              </Checkbox>
              <br/>
              {
                this.state.englishLanguage == true &&
                <div className={'InputFilterUR'}>
                  <InputBox  title={'Select Test'}>
                    <Select
                      className={'widthLabel'}
                      style={{ width: '200px' }}
                      value={this.state.englishLanguageTests}
                      onChange={(value) => {
                        this.setState({englishLanguageTests: value })
                        this.groupOfFunctions('englishLanguageTests')
                      }}
                      placeholder={'Select State'}
                    >
                      {testsForUk.map((option, index) => (
                        <Option key={index} value={option}>{option}</Option>
                      ))}
                    </Select>
                  </InputBox>
                </div>
              }
            </div>
          )
        },
        {
          key: 'moiAcceptable',
          label: 'MOI Acceptable',
          hidden: true,
          customField: (
            <div className={'checkBoxUR2'}>
              <Checkbox
                checked={this.state.moiAcceptable}
                onChange={(e) => {
                  this.setState({ moiAcceptable: e.target.checked })
                  this.groupOfFunctions('moiAcceptable')
                }}
              >
                MOI Acceptable
              </Checkbox>
              {/*<br/>
              {
                this.state.moiAcceptable == true &&
                <div className={'InputFilterUR'}>
                  <InputBox  title={'Select State'}>
                    <Select
                      className={'widthLabel'}
                      style={{ width: '200px' }}
                      value={this.state.moiAcceptableState}
                      onChange={(value) => {
                        this.setState({moiAcceptableState: value })
                        this.groupOfFunctions('moiAcceptableState')
                      }}
                      placeholder={'Select State'}
                      showSearch={true}
                    >
                      {indianStates.map((option, index) => (
                        <Option key={index} value={option.name}>{option.name}</Option>
                      ))}
                    </Select>
                  </InputBox>
                </div>
              }*/}
            </div>
          )
        },
        {
          key: 'stateBoardWaiver',
          label: 'State Boar Waiver',
          hidden: true,
          customField: (
            <div className={'checkBoxUR2'}>
              <Checkbox
                checked={this.state.stateBoardWaiver}
                onChange={(e) => {
                  this.setState({ stateBoardWaiver: e.target.checked })
                  this.groupOfFunctions('stateBoardWaiver')
                }}
              >
                State Board Waiver
              </Checkbox>
              {
                this.state.stateBoardWaiver == true &&
                <div className={'InputFilterUR'}>
                  <InputBox  title={'Select State'}>
                    <Select
                      className={'widthLabel'}
                      style={{ width: '200px' }}
                      value={this.state.stateBoardWaiverState}
                      onChange={(value) => {
                        this.setState({stateBoardWaiverState: value })
                        this.groupOfFunctions('stateBoardWaiverState')
                      }}
                      placeholder={'Select State'}
                      showSearch={true}
                    >
                      {indianStates.map((option, index) => (
                        <Option key={index} value={option.name}>{option.name}</Option>
                      ))}
                    </Select>
                  </InputBox>
                </div>
              }
            </div>
          )
        },
        {
          key: 'noInitialBeforeInterview',
          label: 'No Initial Before Interview',
          hidden: true,
          customField: (
            <div className={'checkBoxUR2'}>
              <Checkbox
                checked={this.state.noInitialBeforeInterview}
                onChange={(e) => {
                  this.setState({ noInitialBeforeInterview: e.target.checked })
                  this.groupOfFunctions('noInitialBeforeInterview')
                }}
              >
                No Initial Before Interview
              </Checkbox>
            </div>
          )
        },
        {
          key: 'initialBeforeInterview',
          label: 'Initial Before Interview',
          hidden: true,
          customField: (
            <div className={'checkBoxUR2'}>
              <Checkbox
                checked={this.state.initialBeforeInterview}
                onChange={(e) => {
                  this.setState({ initialBeforeInterview: e.target.checked })
                  this.groupOfFunctions('initialBeforeInterview')
                }}
              >
                Initial Before Interview
              </Checkbox>
            </div>
          )
        },
        {
          key: 'ugOpenBoardAcceptable',
          label: 'UG Open Board Acceptable',
          hidden: true,
          customField: (
            <div className={'checkBoxUR2'}>
              <Checkbox
                checked={this.state.ugOpenBoardAcceptable}
                onChange={(e) => {
                  this.setState({ ugOpenBoardAcceptable: e.target.checked })
                  this.groupOfFunctions('ugOpenBoardAcceptable')
                }}
              >
                Ug Open Board Acceptable
              </Checkbox>
            </div>
          )
        },
        {
          key: 'distanceLearning',
          label: 'Distance Learning',
          hidden: true,
          customField: (
            <div className={'checkBoxUR2'}>
              <Checkbox
                checked={this.state.distanceLearning}
                onChange={(e) => {
                  this.setState({ distanceLearning: e.target.checked })
                  this.groupOfFunctions('distanceLearning')
                }}
              >
                Distance Learning
              </Checkbox>
            </div>
          )
        }
      ]
    }


    return (
      <div className='filter-box'>
        <div className='accordion' id='accordionExample'>
          <div className='card'>
            <div className='card-header' id='headingOne'>
              <h2 className='mb-0'>
                <button className='btn btn-link d-flex' type='button' data-toggle='collapse'
                        data-target='#collapseOne' aria-expanded='true' aria-controls='collapseOne'>
                  Filters <img src='/dist/img/arrow-down-white.svg' alt=''
                                                className='ml-auto' />
                </button>
              </h2>
            </div>
            {programFilter.fields.map((item, key) => {
              return (
                <div
                  id='collapseOne'
                  className='collapse show'
                  aria-labelledby='headingOne'
                  data-parent='#accordionExample'
                >
                  {item.customField ? (
                    <Col
                      span={item.span}
                      md={item.span}
                      sm={item.span}
                      xs={24}
                    >
                      {item.customField}
                    </Col>
                  ) : (
                    <GetEachFormFields
                      item={item}
                      formItemLayout={formItemLayout}
                      getFieldDecorator={getFieldDecorator}
                    />
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }


}

const WrappedEvaluateFilters = Form.create()(EvaluateFiltersUniversityRequirement)

const mapStateToProps = ({ global, router, EvaluateReducers }) => ({
  pageLoading: global.pageLoading,
  currentUser: global.currentUser,
  EvaluateReducers: EvaluateReducers
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedEvaluateFilters)

