import { apiUrl } from '../../../settings'

export const addSampleDocumentUrl = () => {
  return apiUrl + '/sampleDocument'
}

export const listSampleDocumentUrl = () => {
  return apiUrl + '/sampleDocument'
}

export const singleSampleDocumentUrl = (id) => {
  return apiUrl + '/sampleDocument/' + id
}
