import { customAxios as axios, getToken } from '../../../request'
import {
  myReferralUrl,
  getReferralSettingUrl,
  getTransactionListUrl
} from '../apis/index'
import { notification } from 'antd'

export const getReferralList = (filters) => async (dispatch) => {
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(myReferralUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getReferralSetting = (filters) => async (dispatch) => {
  let config = {
    // params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(getReferralSettingUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getTransactionList = (filters) => async (dispatch) => {
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(getTransactionListUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


