import {apiUrl} from "../../../settings"


export const listAllWebinarUrl = () => {
    return apiUrl + '/eventList'
}

export const singleEventUrl = (id) => {
    return apiUrl + '/singleEvent/' + id
}

export const referenceMarketingUrl = () => {
    return apiUrl + '/event/marketingUsers'
}

export const addRegistraionUrl = () => {
    return apiUrl + '/event/addLead'
}

export const verifyLeadUrl = () => {
    return apiUrl + '/event/verifyLead';
};

export const webEventsListUrls = () => {
    return apiUrl + '/web/eventList'
}
