import React, { useEffect, useState } from 'react'
import { notification, Drawer, Col, Row, DatePicker, Input, Tooltip } from 'antd'
import { useDispatch } from 'react-redux'
import { addTranscriptOfMarks, updateStudentFxn } from './actions'
import InputBox from '../../../components/_utils/InputBox'
import moment from 'moment'
import { RowTable } from '../../../components/_utils/RowTable'

const { RangePicker } = DatePicker

let initialState = {
  studyPeriod: '',
  fromDate: '',
  toDate: '',
  dateOfIssue: '',
  markSheet: [
    {
      name: 'English Grammar',
      mark: '',
      grade: ''
    },
    {
      name: 'Listening',
      mark: '',
      grade: ''
    },
    {
      name: 'Reading',
      mark: '',
      grade: ''
    },
    {
      name: 'Writing',
      mark: '',
      grade: ''
    },
    {
      name: 'Speaking',
      mark: '',
      grade: ''
    },
    {
      name: 'Final Grade',
      mark: '',
      grade: ''
    }
  ]
}

const AddTranscriptOfMarks = (props) => {
  let dispatch = useDispatch()
  let {
    visible,
    onClose,
    onSubmit,
    studentId,
    applicationId,
    record
  } = props
  let { applications } = record
  const [state, setState] = useState(initialState)
  const [defaultRange, setDefaultRange] = useState([])


  useEffect(() => {
    if (applications.markSheet) {
      setState({ ...state,
        markSheet: applications.markSheet,
        studyPeriodFromDate: applications.studyPeriodFromDate,
        studyPeriodToDate: applications.studyPeriodToDate,
        dateOfIssue: moment(applications.dateOfIssue),
        studyPeriod: `${moment(applications.studyPeriodFromDate).format('MMMM DD, YYYY')} to ${moment(record.studyPeriodToDate).format('MMMM DD, YYYY')}`
      })
    }
    if (applications.studyPeriodFromDate && applications.studyPeriodToDate) {
      setDefaultRange([moment(applications.studyPeriodFromDate), moment(applications.studyPeriodToDate)])
    }
  }, [
    applications.markSheet
  ])
  const DateRangeSelector = (date, dateString) => {
    setState({
      ...state,
      studyPeriodFromDate: date[0],
      studyPeriodToDate: date[1],
      studyPeriod: `${moment(dateString[0]).format('MMMM DD, YYYY')} to ${moment(dateString[1]).format('MMMM DD, YYYY')}`
    })
  }
  const events = {
    handleSubmit: async (e) => {
      e.preventDefault()
      if (!state.dateOfIssue) {
        notification.warning({
          message: 'Please Choose Date of Issue'
        })
        return
      }

      if (!state.studyPeriod) {
        notification.warning({
          message: 'Please Choose Study Period'
        })
        return
      }

      if (!state.markSheet[0].mark) {
        notification.warning({
          message: 'Please Fill English Grammar Marks'
        })
        return
      }

      if (!state.markSheet[0].grade) {
        notification.warning({
          message: 'Please Fill English Grammar Grades'
        })
        return
      }

      if (!state.markSheet[1].mark) {
        notification.warning({
          message: 'Please Fill Listening Marks'
        })
        return
      }

      if (!state.markSheet[1].grade) {
        notification.warning({
          message: 'Please Fill Listening Grades'
        })
        return
      }

      if (!state.markSheet[2].mark) {
        notification.warning({
          message: 'Please Fill Reading Marks'
        })
        return
      }

      if (!state.markSheet[2].grade) {
        notification.warning({
          message: 'Please Fill Reading Grades'
        })
        return
      }

      if (!state.markSheet[3].mark) {
        notification.warning({
          message: 'Please Fill Writing Marks'
        })
        return
      }

      if (!state.markSheet[3].grade) {
        notification.warning({
          message: 'Please Fill Writing Grades'
        })
        return
      }

      if (!state.markSheet[4].mark) {
        notification.warning({
          message: 'Please Fill Speaking Marks'
        })
        return
      }

      if (!state.markSheet[4].grade) {
        notification.warning({
          message: 'Please Fill Speaking Grades'
        })
        return
      }

      if (!state.markSheet[5].mark) {
        notification.warning({
          message: 'Please Fill Final Marks Percentage'
        })
        return
      }

      if (!state.markSheet[5].grade) {
        notification.warning({
          message: 'Please Fill Final Grades'
        })
        return
      }

      try {
        let data = await dispatch(addTranscriptOfMarks({ ...state, studentId, applicationId }))
        if (data.success) {
          onSubmit()
        }
      } catch (error) {
        notification.error({
          message: 'An error occurred while submitting the form',
          description: error.message
        })
      }
    }
  }

  return (
    <>
      <Drawer
        title={
          <>
            Transcript of Marks (Name: &nbsp;<span style={{ fontWeight: 'normal' }}>{record.name}</span>, ID: &nbsp;
            <span
              style={{ fontWeight: 'normal' }}>{record.studentId}</span>)
          </>
        }
        visible={visible} width={'45%'}
        onClose={onClose}
      >
        <div className='form-box'>
          <div className='card unizportal'>
            {/*<div className={'tableBox striped'}>
              <RowTable title={'Student Name'} value={record.name} />
              <RowTable title={'Student Id'} value={record.studentId} />
            </div>*/}
            <Row gutter={24}>
              <Col md={12} sm={12} xs={12} lg={12}>
                <InputBox title={'Date Of Issue'}>
                  <DatePicker className={'form-control'}
                              type={'date'}
                              value={state.dateOfIssue}
                              onChange={value => {
                                setState({ ...state, dateOfIssue: value })
                              }} />
                </InputBox>
              </Col>
              <Col md={12} sm={12} xs={12} lg={12}>
                <InputBox title={'Study Period'}>
                  <RangePicker
                    defaultValue={defaultRange}
                    className={'form-control'}
                    onChange={DateRangeSelector} />
                </InputBox>
              </Col>
              <Col md={24} sm={24} xs={24} lg={24} className={'marks-div'}>
                <Col md={8} sm={8} xs={8} lg={8}>
                  <b className={'marks-heading'}>Module</b>
                </Col>
                <Col md={8} sm={8} xs={8} lg={8}>
                  <b className={'marks-heading'}>Mark (In %)</b>
                </Col>
                <Col md={8} sm={8} xs={8} lg={8}>
                  <b className={'marks-heading'}>Grade</b>
                </Col>
              </Col>

              <Col md={24} sm={24} xs={24} lg={24}>
                <Col
                  className={'markSheet-module-top'}
                  md={8} sm={8} xs={8} lg={8}>
                  <span> English Grammar : </span>
                </Col>
                <Col md={8} sm={8} xs={8} lg={8}>
                  <input
                    className={'form-control'}
                    value={state.markSheet[0].mark}
                    onChange={(e) => {
                      const updatedMarkSheet = [...state.markSheet]
                      updatedMarkSheet[0].mark = e.target.value
                      setState({ ...state, markSheet: updatedMarkSheet })
                    }}
                  />
                </Col>
                <Col md={8} sm={8} xs={8} lg={8}>
                  <input
                    className={'form-control'}
                    value={state.markSheet[0].grade}
                    onChange={(e) => {
                      const updatedMarkSheet = [...state.markSheet]
                      updatedMarkSheet[0].grade = e.target.value
                      setState({ ...state, markSheet: updatedMarkSheet })
                    }}
                  />
                </Col>
              </Col>

              <Col md={24} sm={24} xs={24} lg={24}>
                <Col
                  className={'markSheet-module'}

                  md={8} sm={8} xs={8} lg={8}>
                  <span> Listening : </span>
                </Col>
                <Col md={8} sm={8} xs={8} lg={8}>
                  <input
                    className={'form-control mt-4'}
                    value={state.markSheet[1].mark}
                    onChange={(e) => {
                      const updatedMarkSheet = [...state.markSheet]
                      updatedMarkSheet[1].mark = e.target.value
                      setState({ ...state, markSheet: updatedMarkSheet })
                    }}
                  />
                </Col>
                <Col md={8} sm={8} xs={8} lg={8}>
                  <input
                    className={'form-control mt-4'}
                    value={state.markSheet[1].grade}
                    onChange={(e) => {
                      const updatedMarkSheet = [...state.markSheet]
                      updatedMarkSheet[1].grade = e.target.value
                      setState({ ...state, markSheet: updatedMarkSheet })
                    }}
                  />
                </Col>
              </Col>

              <Col md={24} sm={24} xs={24} lg={24}>
                <Col
                  className={'markSheet-module'}
                  md={8} sm={8} xs={8} lg={8}>
                  <span> Reading : </span>
                </Col>
                <Col md={8} sm={8} xs={8} lg={8}>
                  <input
                    className={'form-control mt-4'}
                    value={state.markSheet[2].mark}
                    onChange={(e) => {
                      const updatedMarkSheet = [...state.markSheet]
                      updatedMarkSheet[2].mark = e.target.value
                      setState({ ...state, markSheet: updatedMarkSheet })
                    }}
                  />
                </Col>
                <Col md={8} sm={8} xs={8} lg={8}>
                  <input
                    className={'form-control mt-4'}
                    value={state.markSheet[2].grade}
                    onChange={(e) => {
                      const updatedMarkSheet = [...state.markSheet]
                      updatedMarkSheet[2].grade = e.target.value
                      setState({ ...state, markSheet: updatedMarkSheet })
                    }}
                  />
                </Col>
              </Col>

              <Col md={24} sm={24} xs={24} lg={24}>
                <Col
                  className={'markSheet-module'}
                  md={8} sm={8} xs={8} lg={8}>
                  <span> Writing : </span>
                </Col>
                <Col md={8} sm={8} xs={8} lg={8}>
                  <input
                    className={'form-control mt-4'}
                    value={state.markSheet[3].mark}
                    onChange={(e) => {
                      const updatedMarkSheet = [...state.markSheet]
                      updatedMarkSheet[3].mark = e.target.value
                      setState({ ...state, markSheet: updatedMarkSheet })
                    }}
                  />
                </Col>
                <Col md={8} sm={8} xs={8} lg={8}>
                  <input
                    className={'form-control mt-4'}
                    value={state.markSheet[3].grade}
                    onChange={(e) => {
                      const updatedMarkSheet = [...state.markSheet]
                      updatedMarkSheet[3].grade = e.target.value
                      setState({ ...state, markSheet: updatedMarkSheet })
                    }}
                  />
                </Col>
              </Col>

              <Col md={24} sm={24} xs={24} lg={24}>
                <Col
                  className={'markSheet-module'}
                  md={8} sm={8} xs={8} lg={8}>
                  <span> Speaking : </span>
                </Col>
                <Col md={8} sm={8} xs={8} lg={8}>
                  <input
                    className={'form-control mt-4'}
                    value={state.markSheet[4].mark}
                    onChange={(e) => {
                      const updatedMarkSheet = [...state.markSheet]
                      updatedMarkSheet[4].mark = e.target.value
                      setState({ ...state, markSheet: updatedMarkSheet })
                    }}
                  />
                </Col>
                <Col md={8} sm={8} xs={8} lg={8}>
                  <input
                    className={'form-control mt-4'}
                    value={state.markSheet[4].grade}
                    onChange={(e) => {
                      const updatedMarkSheet = [...state.markSheet]
                      updatedMarkSheet[4].grade = e.target.value
                      setState({ ...state, markSheet: updatedMarkSheet })
                    }}
                  />
                </Col>
              </Col>

              <Col md={24} sm={24} xs={24} lg={24}>
                <Col
                  className={'markSheet-module'}
                  md={8} sm={8} xs={8} lg={8}>
                  <b> Final Grade : </b>
                </Col>
                <Col md={8} sm={8} xs={8} lg={8}>
                  <input
                    className={'form-control mt-4'}
                    value={state.markSheet[5].mark}
                    onChange={(e) => {
                      const updatedMarkSheet = [...state.markSheet]
                      updatedMarkSheet[5].mark = e.target.value
                      setState({ ...state, markSheet: updatedMarkSheet })
                    }}
                  />
                </Col>
                <Col md={8} sm={8} xs={8} lg={8}>
                  <input
                    className={'form-control mt-4'}
                    value={state.markSheet[5].grade}
                    onChange={(e) => {
                      const updatedMarkSheet = [...state.markSheet]
                      updatedMarkSheet[5].grade = e.target.value
                      setState({ ...state, markSheet: updatedMarkSheet })
                    }}
                  />
                </Col>
              </Col>

            </Row>
            <Row>
              <Col span={24}>
                <button className='btn' type='submit' onClick={events.handleSubmit}>save</button>
              </Col>
            </Row>
          </div>
        </div>
      </Drawer>
    </>
  )
}
export default AddTranscriptOfMarks
