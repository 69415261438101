import {Col, Drawer, Form, Input, notification, Row} from 'antd'
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {InputBox} from '../../../components/_utils/appUtils'
import {RowTable} from '../../../components/_utils/RowTable'
import {uploadMozaikLetterFxn} from '../../student/actions/student'

const DocumentList = ['Mozaik Letter']
const UploadMozaikLetter = props => {
    let dispatch = useDispatch()
    let {
        visible,
        onClose,
        applicationId,
        studentId,
        reloadTable,
        studentData
    } = props
    let [state, setState] = useState([])
    const events = {
        chooseDocument: e => {
            let {name, files} = e.target
            if (files && files.length) {
                setState({
                    ...state,
                    [name]: files[0]
                })
            }
        },
        handleSubmit: async () => {
            let fd = new FormData()
            let obj = {
                studentId,
                applicationId
            }

            if (
                !state['mozaikLetter'] ||
                (state['mozaikLetter'] && !state['mozaikLetter'].name)
            ) {
                return notification.warn({message: 'Please choose Mozaik Letter'})
            }

            fd.append('obj', JSON.stringify(obj))

            if (state['mozaikLetter']) {
                fd.append('mozaikLetter', state['mozaikLetter'])
            }

            let data = await dispatch(uploadMozaikLetterFxn(fd))
            if (data && data.success) {
                onClose()
                reloadTable()
            }
        }
    }
    return (
        <Drawer
            visible={visible}
            title={'Mozaik Letter'}
            onClose={onClose}
            width={'40%'}>
            <div className="row">
                <div className="col-lg-12">
                    <Form className={'vertical-form'} autoComplete="off">
                        <div className="form-box">
                            <div className="card unizportal">
                                {studentData &&
                                studentData.applications &&
                                studentData.applications.id ? (
                                    <div className={'tableBox striped'}>
                                        <RowTable
                                            title={'Name (ID)'}
                                            value={
                                                <span>
                          {studentData.name} ({studentData.studentId})
                        </span>
                                            }
                                        />
                                        <RowTable
                                            title={'Application ID'}
                                            value={<span>{studentData.applications.id}</span>}
                                        />
                                        <RowTable
                                            title={'Application'}
                                            value={<span>{studentData.applications.courseName}</span>}
                                        />
                                    </div>
                                ) : null}
                                <br/>

                                <div className="inner-form">
                                    <Row gutter={24} key={state.uploadKey}>
                                        <Col span={24}>
                                            <InputBox title={`Mozaik Letter`}>
                                                <Input
                                                    type={'file'}
                                                    name={'mozaikLetter'}
                                                    id={'mozaikLetter'}
                                                    className={'form-control'}
                                                    onChange={e => {
                                                        events.chooseDocument(e)
                                                    }}
                                                />
                                            </InputBox>
                                        </Col>
                                    </Row>

                                    <button
                                        className="btn"
                                        type="submit"
                                        onClick={events.handleSubmit}>
                                        save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Drawer>
    )
}
export default UploadMozaikLetter
