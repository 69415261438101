import React, { useState, useEffect } from 'react'
import { Button, Card, Drawer, Form, Input, notification } from 'antd'
import { RowTable } from '../../../components/_utils/RowTable'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { InputBox } from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import {
  eduBoardAndUniversity,
  getStudent,
  updateApplicationFamilyName,
  updateStudent
} from '../../student/actions/student'
import { uploadAppDocumentAndStatus } from '../actions'
import moment from 'moment'

const AdvancePayDrawer = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, studentId, applicationId, reloadTable, studentData } = props
  let [commission, setCommission] = useState('')
  let [agentName, setAgentName] = useState('')
  let [date, setDate] = useState(moment())

  let handleSubmit = async () => {

    if (!commission || !agentName || !date) {
      return notification.warn({ message: 'Fill required fields' })
    }

    let fd = new FormData()

    let obj = {
      _id: studentId,
      status: 'Commission',
      studentId,
      commission,
      date,
      agentName,
      applicationId
    }
    fd.append('obj', JSON.stringify(obj))

    let data = await dispatch(uploadAppDocumentAndStatus(fd))

    if (data && data.success) {
      onClose()
      reloadTable()
    }
  }


  return (
    <Drawer visible={visible}
            title={`Add Commission`}
            width={600}
            closable={true}
            maskClosable={true}
            onClose={onClose}>
      <Card>
        <Form className={'vertical-form'} autoComplete='off'>
          <Form.Item>
            <InputBox title={'Agent Name'}>
              <Input
                onChange={(e) => setAgentName(e.target.value)}
                value={agentName}
                required={true}
                className={'form-control'} />
            </InputBox>
            <InputBox title={'Add Commission'}>
              <Input
                type={'number'}
                onChange={(e) => setCommission(e.target.value)}
                value={commission}
                required={true}
                className={'form-control'} />
            </InputBox>
            <InputBox title={'Select Date'}>
              <Input
                type={'date'}
                onChange={(e) => setDate(e.target.value)}
                value={date}
                required={true}
                className={'form-control'} />
            </InputBox>
          </Form.Item>
          {/*  <Form.Item>
            <InputBox title={'Student ID'}>
              <Input onChange={(e) => setStudentIdNo(e.target.value)}
                     value={studentIdNo} className={'form-control'}/>
            </InputBox>
          </Form.Item>*/}
          <Form.Item style={{ marginTop: 25 }}>
            <Button type='primary' onClick={handleSubmit}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  )
}
export default AdvancePayDrawer
