import React from 'react'

const Welcome = () => {
    return (
        <>
            <section className="banner blog-banner">


                <div className="row align-items-center">
                    <div className="col-lg-12">
                        {/* <div className="content text-center">
<h1>Meet the experts via <br />our regular webinars</h1>
<p>In fulfilling our promise to initiate and reinforce networking <br />among our student clientele,
immigration partners and premier <br />tertiary level institutions, we host webinars where you get the
chance to establish <br />direct discourse with our expert counselors, immigration consultant
partners <br /> and representativesfrom plethora of global post-secondary institutions.</p>
</div> */}
                    </div>
                    <div className="col-lg-12">
                        <div className="img text-center">
                        <img src="/assets/image/banner.png"/>
                        </div>
                    </div>
                </div>
            </section>

            <div>
                <h1> Welcomme</h1>
            </div>
        </>
    )
}

export default Welcome