import React, { useState, useEffect } from 'react';
import { Form, Button, notification, Row, Col, Input } from 'antd';
import { useDispatch } from 'react-redux';
import {singleExchangeMarginFxn, updateExchangeMarginFxn} from "../actions";
import {InputBox, numberToWords} from "../../../components/_utils/appUtils";

const UpdateExchangeMargin = (props) => {
    let { user } = props;
    const dispatch = useDispatch();
    const [exchangeMargin, setExchangeMargin] = useState(0.00);

    const fetchExchangeMargin = async () => {
        const data = await dispatch(singleExchangeMarginFxn({ userId: user._id }));
        if (data) {
            setExchangeMargin(data.exchangeMargin);
        }
    };

    const handleSubmit = async () => {
        if (!exchangeMargin) {
            notification.success({
                message: 'Enter exchange margin.',
            });
            return;
        }
        const payload = {
            userId: user._id,
            exchangeMargin: exchangeMargin,
        };
        const resp = await dispatch(updateExchangeMarginFxn(payload));
        if (resp.success) {
            notification.success({
                message: 'Exchange margin updated successfully!',
            });
        } else {
            notification.error({
                message: resp.message,
            });
        }
    };

    useEffect(() => {
        fetchExchangeMargin();
    }, []);

    const convertToWords = (num) => {
        const [rupees, paise] = num.toFixed(2).split('.');
        return `${rupees} rupee${rupees > 1 ? 's' : ''} and ${paise} paise`;
    };

    return (
        <div className="row">
            <div className="col-lg-12">
                <Form>
                    <div className="form-box mt-4">
                        <div className="d-flex align-items-center mb-3 heading-form">
                            <h5>Update Exchange Margin</h5>
                        </div>

                        <div className="card unizportal">
                            <Row gutter={24}>
                                <Col span={8}>
                                    <InputBox title={'Exchange Margin'}>
                                        <Input
                                            type={'text'}
                                            value={exchangeMargin}
                                            className={'form-control'}
                                            placeholder="Enter agent margin"
                                            onChange={(e) => setExchangeMargin(e.target.value)}
                                        />
                                    </InputBox>
                                    <div className="margin-in-words">
                                        {exchangeMargin !== undefined ? (
                                            <p><b>Amount In Words:</b> {numberToWords(parseFloat(exchangeMargin))}</p>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="btn"
                                    onClick={handleSubmit}
                                >
                                    Update
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default UpdateExchangeMargin;
