import React, { PureComponent } from 'react'
import {
  Form,
  Input,
  Button,
  Col,
  Row, Checkbox
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { notification } from 'antd/lib/index'
import { hideLoader, hidePageLoad } from '../../../modules/actions'
import { connect } from 'react-redux'
import { listAllBranch } from '../../branch/actions/branchAction'
import { countryIds, InputBox } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { addTeamAction, getTeamFxn } from '../actions/user'
import { LoadAllCountry, LoadState, LoadCity, CountryCodeWithFlag } from '../../../components/_utils/countryUtil'
import { listAllCountries } from '../../countries/actions/countries'
import AgentTeamModal from '../drawer/agentTeamModal'

const { TextArea } = Input

@Form.create()
class AddTeam extends PureComponent {

  events = {
    chooseCountry: (name) => {
      let countryName = _.find(this.state.allCountries, (item) => {
        return item.name == name
      })
      if (countryName) {
        this.setState({
          countryName: countryName.name ? countryName.name : '',
          allStates: countryName.id ? LoadState(countryName.id) : []
        })
      }
    },
    chooseState: (name) => {
      let stateName = _.find(this.state.allStates, (item) => {
        return item.name == name
      })
      if (stateName) {
        this.setState({
          stateName: stateName.name ? stateName.name : '',
          allCities: stateName.id ? LoadCity(stateName.id) : []
        })
      }
    },
    chooseCity: (name) => {
      this.setState({
        cityName: name ? name : ''
      })
    },
    loadCountry: async () => {
      let filters = {
        sortField: 'countryName',
        sortOrder: 'ascend',
        results: 100,
        count: 100,
        customQuery: {
          _id: { $ne: countryIds.canadaOnshore }
        }
      }
      let { dispatch } = this.props
      let { data } = await dispatch(listAllCountries(filters))
      this.setState({
        countryArr: data
      })
    },
    hideInfoModal: () => {
      this.setState({ visibleInfoModal: false })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      allBranch: [],
      logo: {},
      uploadKey: moment(),
      allCountries: LoadAllCountry(),
      allStates: [],
      allCities: [],
      countryName: 'India',
      stateName: '',
      cityName: '',
      countryCode: '',
      countryArr: [],
      transferApplication: false,
      teamState: {},
      visibleInfoModal: false,
      teamList: []
    }
    setTimeout(() => {
      props.form.setFieldsValue({
        countryName: 'India'
      })
    }, 200)
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    let { state } = this
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { countryCode } = state
        if (!countryCode) {
          notification.error({
            message: 'Please choose Country Code'
          })
          return
        }
        valData.userType = 'team'
        valData.address = state.address
        valData.transferApplication = state.transferApplication
        valData.countryISOCode = state.countryCode

        let { data, success } = await dispatch(getTeamFxn(valData))
        if (success) {
          this.setState({ teamState: valData, visibleInfoModal: true, teamList: data })
        } else {
          this.setState({ teamState: valData }, () => {
            this.handleFinalSubmit()
          })
        }
      }
    })
  }
  handleFinalSubmit = async () => {
    const { dispatch, form } = this.props
    let { state } = this
    let { teamState } = state
    let fd = new FormData()
    fd.append('obj', JSON.stringify(teamState))
    if (state.logo && state.logo.name) {
      fd.append('logo', state.logo)
    }
    let data = await dispatch(addTeamAction(fd))
    if (data && !data.error) {
      form.resetFields()
      this.setState({
        address: '',
        uploadKey: moment(),
        logo: {},
        countryCode: '',
        transferApplication: false,
        visibleInfoModal: false,
        teamList: []
      })
    }
  }


  componentDidMount() {
    let { dispatch } = this.props
    dispatch({ type: 'USER_HIDELOADER' })
    dispatch(hideLoader())
    dispatch(hidePageLoad())
    this.events.loadCountry()
  }

  async loadBranch() {
    let { dispatch } = this.props
    let { data } = await listAllBranch({})(dispatch)
    this.setState({
      allBranch: data
    })
  }


  render() {
    const { form: { getFieldDecorator, getFieldValue } } = this.props
    let { transferApplication, visibleInfoModal, teamList, teamState } = this.state
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    let inputTypes = {
      fields: [
        {
          label: 'Contact Person Name',
          key: 'name',
          required: true
        },
        {
          key: 'countryCode',
          customField: (
            <div style={{ marginTop: 0 }}>
              <CountryCodeWithFlag
                countryCode={this.state.countryCode}
                chooseCode={(val) => {
                  this.setState({
                    countryCode: val
                  })
                }} />
            </div>
          )
        },
        {
          label: 'Mobile no',
          key: 'mobile',
          type: 'number',
          required: true
        },
        {
          label: 'Email',
          key: 'email',
          required: true
        },
        {
          label: 'Password',
          key: 'password',
          type: 'text',
          required: true
        },
        {
          key: 'countryName',
          label: 'Country',
          type: 'select',
          showSearch: true,
          options: this.state.allCountries,
          keyAccessor: x => x.name,
          valueAccessor: x => `${x.name}`,
          onChange: x => {
            this.props.form.setFieldsValue({
              countryName: x
            })
            this.events.chooseCountry(x)
          }
        },
        {
          key: 'stateName',
          label: 'Province/State',
          type: 'select',
          showSearch: true,
          options: this.state.allStates,
          keyAccessor: x => x.name,
          valueAccessor: x => `${x.name}`,
          onChange: x => {
            this.props.form.setFieldsValue({
              stateName: x
            })
            this.events.chooseState(x)
          }
        },
        {
          key: 'cityName',
          label: 'City/Town',
          type: 'select',
          showSearch: true,
          hidden: getFieldValue('countryName') !== 'India',
          options: this.state.allCities,
          keyAccessor: x => x.name,
          valueAccessor: x => `${x.name}`,
          onChange: x => {
            this.props.form.setFieldsValue({
              cityName: x
            })
            this.events.chooseCity(x)
          }
        },
        {
          key: 'cityName',
          label: 'City/Town',
          hidden: getFieldValue('countryName') == 'India',
          onChange: (e) => {
            let { value } = e.target
            this.props.form.setFieldsValue({
              cityName: value
            })
            this.events.chooseCity(value)
          }
        },
        {
          key: 'countryArr',
          label: 'Select Countries',
          type: 'select',
          showSearch: true,
          mode: 'multiple',
          required: true,
          options: this.state.countryArr,
          keyAccessor: x => x._id,
          valueAccessor: x => `${x.countryName}`,
          onChange: x => {
            this.props.form.setFieldsValue({
              countryArr: x
            })
          }
        },
        {
          key: 'address',
          label: 'City/Town',
          span: 16,
          customField: (
            <InputBox title={'Address'}>
              <TextArea rows={4} className={'form-control'} value={this.state.address} onChange={(e) => {
                this.setState({ address: e.target.value })
              }}>
              </TextArea>
            </InputBox>
          )
        }
      ]
    }


    return (
      <div className='row'>
        <div className='col-lg-12'>
          <Form onSubmit={this.handleSubmit}>
            <div className='form-box mt-4'>
              <div className='d-flex align-items-center mb-3 heading-form'>
                <h5>Add Team</h5>
              </div>
              <div className='card unizportal'>
                <Row gutter={16} className={'rowWrap'}>
                  {inputTypes.fields.map((item, key) => {
                    return (
                      <React.Fragment key={key}>
                        {item.customField ? <Col span={item.span ? item.span : 8}
                                                 key={key}>{item.customField}</Col> :
                          !item.hidden && <Col span={item.span ? item.span : 8} key={key}>
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout} />
                          </Col>}
                      </React.Fragment>
                    )
                  })}
                </Row>
                <div style={{ margin: '20px 0' }}>
                  <Checkbox onChange={(e) => this.setState({ transferApplication: e.target.checked })}
                            checked={transferApplication}>
                    Are you sure you want to transfer all those country applications to this team user?
                  </Checkbox>
                </div>
                <Form.Item>
                  <Button type='primary' htmlType='submit' loading={this.props.loading}
                          className={'btn'}>
                    SAVE
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>

        {visibleInfoModal ?
          <AgentTeamModal
            visible={visibleInfoModal}
            onClose={this.events.hideInfoModal}
            onSubmit={this.handleFinalSubmit}
            teamState={teamState}
            teamList={teamList} /> : null}

      </div>
    )
  }

}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTeam)
