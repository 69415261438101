import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import { addCityUrl, listCitiesUrl, listCityUrl } from '../api/city'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'


export const addCity = (valData) => async (dispatch) => {
    dispatch({ type: 'CITY_SHOW_LOADER' });
    let { data } = await axios.post(addCityUrl(), valData, getToken());
    dispatch({ type: 'CITY_HIDE_LOADER' });
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data;
}


export const listAllCities = (filters) => async (dispatch) => {
    dispatch({ type: 'CITY_SHOW_LOADER' });
    let config = {
        params: { ...filters },
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') },
    }
    let { data } = await axios.get(listCitiesUrl(), config);

    // console.log("data from backend is all", data);
    dispatch({ type: 'CITY_HIDE_LOADER' });
    dispatch({ type: 'LIST_ALL_CITY', payload: data.data });
    if (data.error) {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data.data;

}


export const getCity = id => async (dispatch) => {
    dispatch({ type: 'CITY_SHOW_LOADER' })
    let config = {
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
    }
    let { data } = await axios.get(listCityUrl(id), config);
    dispatch({ type: 'CITY_HIDE_LOADER' });
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    } else { dispatch({ type: 'LIST_CITY', payload: data }) }
    return data.data;
}


export const updateCity = (valData, id) => async (dispatch) => {
    dispatch({ type: 'CITY_SHOW_LOADER' });
    let { data } = await axios.put(listCityUrl(id), valData, getToken());
    dispatch({ type: 'CITY_HIDE_LOADER' });
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data;
};

export const deleteCity = (id) => async (dispatch) => {
    dispatch(showPageLoad())
    let { data } = await axios.delete(listCityUrl(id), getToken())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    dispatch(hidePageLoad())
    return data
}
