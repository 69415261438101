import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Icon,
  Popconfirm, Row, Select,
  Tooltip
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { listWithdrawalFxn, reGenerateInvoiceFxn } from '../../withdrawal/actions/withdrawalAction'
import AddCallDrawer from '../drawers/addCallDrawer'
import CanadaOnshoreGenerateInvoice from '../drawers/canadaOnshoreGenerateInvoice'
import UploadReceiptDrawer from '../drawers/uploadReceiptDrawer'
import { countryIds } from '../../../components/_utils/appUtils'
import UpdateWithdrawal from '../drawers/updateWithdrawal'

const { Option } = Select
const initState = {
  withdrawalId: '',
  visible: false,
  status: ''
}
const initReceiptState = {
  withdrawalId: '',
  visible: false
}
const initInvoiceState = {
  withdrawalId: '',
  visible: false
}
const CanadaOnshoreWithdrawal = () => {
  let [total, setTotal] = useState(0)
  let [state, setState] = useState(initState)
  let [receiptState, setReceiptState] = useState(initReceiptState)
  let [invoiceState, setInvoiceState] = useState(initInvoiceState)
  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.sortField = '_id'
      params.sortOrder = 'descend'
      params.countryId = countryIds.canadaOnshore
      let resp = await dispatch(listWithdrawalFxn({
        ...params
      }))
      setTotal(resp.total)
      resolve(resp)
    })
  }

  const updateState = (data) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...data
      }
    })
  }

  const events = {
    showDrawer: (withdrawalId, status) => {
      updateState({
        withdrawalId,
        visible: true,
        status: status
      })
    },
    hideDrawer: () => {
      updateState({
        withdrawalId: '',
        visible: false,
        status: ''
      })
      tableRef.current.reload()
    },
    showReceiptDrawer: (withdrawalId) => {
      setReceiptState({
        withdrawalId,
        visible: true
      })
    },
    hideReceiptDrawer: () => {
      setReceiptState({
        withdrawalId: '',
        visible: false
      })
      tableRef.current.reload()
    },
    showInvoiceDrawer: (withdrawalId) => {
      setInvoiceState({
        withdrawalId,
        visible: true
      })
    },
    hideInvoiceDrawer: () => {
      setInvoiceState({
        withdrawalId: '',
        visible: false
      })
      tableRef.current.reload()
    },
    reGenerateInvoiceFunc: async (withdrawalId) => {
      let resp = await dispatch(reGenerateInvoiceFxn({ withdrawalId }))
      if (resp && resp.success) {
        tableRef.current.reload()
      }
    }

  }

  const columns = [

    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (item, record, index) => {
        return <a className={'linkPointer'}>
          {index + 1}
        </a>
      }
    },
    {
      title: 'Agent',
      dataIndex: 'agentId',
      width: 150,
      key: 'agentId',
      render: (item, record) => {
        return (
          <div>
            {item && item.name ? item.name : ''}
            {item && item.companyName ? `(${item.companyName})` : ''}
          </div>
        )
      }
    },
    {
      title: 'Country',
      dataIndex: 'countryId',
      width: 80,
      key: 'countryId',
      render: (item, record) => {
        return (
          <div>
            {item && item.countryName ? item.countryName : ''}
          </div>
        )
      }
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: 80,
      key: 'amount',
      render: (item, record) => {
        let { countryId } = record
        return (
          <div>
            {countryId && countryId.currency ? countryId.currency : ''} {item}
          </div>
        )
      }
    },
    {
      title: 'Details',
      dataIndex: 'account',
      width: 150,
      key: 'account',
      render: (item, record) => {
        return (
          <div>
            Acc no : {item.accountNo} ({item.bankName}) <br />
            IFSC: {item.ifsc} <br />
            Swift: {item.swiftCode} <br />
            Address: {item.address}<br />
            {item.cancelledCheque && item.cancelledCheque.path ? <>
              <Tooltip title={'Cancelled Cheque'}>
                <a className={'btn btn-success-green btn-xs'} href={item.cancelledCheque.path}
                   target={'_blank'}>
                  Cancelled Cheque
                </a>
              </Tooltip>
            </> : null}
          </div>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (item, record) => {
        return (
          <div>
            {item == 'Approved' ? <label className={'label label-success'}>Approved</label> : null}
            {item == 'Pending' ? <label className={'label label-default'}>Pending</label> : null}
            {item == 'Cancel' ? <label className={'label label-danger'}>Cancel</label> : null}
          </div>
        )
      }
    },
    {
      title: 'Invoice',
      dataIndex: 'invoice',
      key: 'invoice',
      width: 130,
      render: (item, record) => {
        let { currencyRate, invoice } = record
        return (
          <>
            {item && item.path ?
              <>
                {currencyRate ? <div>
                  Currency Rate : {currencyRate}<br />
                </div> : null}
                <a href={item.path} target={'_blank'}>Download Invoice</a>
              </>
              : null}

            <br />
            {invoice && invoice.path ?
              <>
                <div className={'mt10'}>
                  <Popconfirm
                    title={'Are you sure, you want to Re-Generate Invoice?'}
                    okText={'Yes'}
                    cancelText={'No'}
                    onConfirm={() => {
                      events.reGenerateInvoiceFunc(record._id)
                    }}>
                    <a className={'btn btn-info btn-xs'}>
                      Re-generate Invoice
                    </a>
                  </Popconfirm>
                </div>

              </>
              : null}

          </>
        )
      }
    },
    {
      title: 'Refer No',
      dataIndex: 'referralNo',
      width: 60,
      key: 'referralNo',
      render: (item, record) => {
        return (
          <div style={{ width: 60 }}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Invoice Add',
      dataIndex: 'invoiceAddress',
      width: 110,
      key: 'invoiceAddress',
      render: (item, record) => {
        return (
          <div>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Receipt',
      dataIndex: 'receipt',
      key: 'receipt',
      width: 100,
      render: (item, record) => {
        let { invoice } = record
        return (
          <>
            <div>
              {item && item.url ?
                <>
                  <a href={item.url} target={'_blank'}>Download Receipt</a>
                </>
                : null}
            </div>
          </>
        )
      }
    },
    {
      key: 'actions',
      title: 'Actions',
      width: 80,
      render: (text, record) => {
        let { status, receipt, invoice } = record
        return (
          <>
            {status == 'Pending' ?
              <div className={'view-btn-box ml10'}>
                <Tooltip title='Approve'>
                  <a className={'btn btn-success btn-sm mr10'}
                     onClick={() => {
                       events.showDrawer(record._id, 'Approved')
                     }}
                  >
                    Approve
                  </a>
                </Tooltip>
                <Tooltip title='Cancel'>
                  <a className={'btn btn-danger btn-sm'}
                     onClick={() => {
                       events.showDrawer(record._id, 'Cancel')
                     }}>
                    Cancel
                  </a>
                </Tooltip>
              </div> : null
            }

            {(status == 'Approved' && (!invoice || (invoice && !invoice.path))) ?
              <div className={'view-btn-box ml10'}>
                <Tooltip title='Approve'>
                  <a className={'btn btn-success btn-sm mr10'}
                     onClick={() => {
                       events.showInvoiceDrawer(record._id)
                     }}>
                    Generate Invoice
                  </a>
                </Tooltip>
              </div> : null}

            {(status == 'Approved' && (invoice && invoice.path) && (!receipt || (receipt && !receipt.url))) ?
              <div className={'view-btn-box ml10'}>
                <Tooltip title='Approve'>
                  <a className={'btn btn-success btn-sm mr10'}
                     onClick={() => {
                       events.showReceiptDrawer(record._id)
                     }}>
                    Upload Receipt
                  </a>
                </Tooltip>
              </div> : null}

          </>
        )
      }
    }
  ]


  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center'>
            <Button onClick={() => events.goBack()}
                    className={'roundGoBackBtn'} icon={'arrow-left'}>
              Back
            </Button>
            <h5>All Withdrawal: {total}</h5>
            <div className='search-box-table'>
            </div>
            <div className='sort-box-table mark-btn'>
            </div>
          </div>
          <div className='card-body table-responsive'>
            <TableComp columns={columns}
                       apiRequest={apiRequest}
                       ref={tableRef}
            />
          </div>
        </div>
      </div>
      {state.visible ?
        <AddCallDrawer
          visible={state.visible}
          withdrawalId={state.withdrawalId}
          onClose={events.hideDrawer}
        />
        : null}

      {invoiceState.visible ?
        <CanadaOnshoreGenerateInvoice
          visible={invoiceState.visible}
          withdrawalId={invoiceState.withdrawalId}
          onClose={events.hideInvoiceDrawer}
        />
        : null}
      {receiptState.visible ?
        <UploadReceiptDrawer
          visible={receiptState.visible}
          withdrawalId={receiptState.withdrawalId}
          onClose={events.hideReceiptDrawer}
        />
        : null}
      {state.visible ?
        <UpdateWithdrawal
          visible={state.visible}
          withdrawalId={state.withdrawalId}
          status={state.status}
          onClose={events.hideDrawer}
        /> : null}
    </div>
  )
}

export default CanadaOnshoreWithdrawal
