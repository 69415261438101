import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, notification, Row, Select } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { singleAgentMarginFxn, updateAgentMarginFxn } from "../actions";
import {filterOption, InputBox, numberToWords} from "../../../components/_utils/appUtils";

const { Option } = Select;

const UpdateAgentMargin = (props) => {
    const { user } = props;
    const dispatch = useDispatch();
    const [agentMargin, setAgentMargin] = useState(0.0);
    const [expiryOption, setExpiryOption] = useState(null);
    const [customExpiryDate, setCustomExpiryDate] = useState(null);

    const fetchAgentMargin = async () => {
        const data = await dispatch(singleAgentMarginFxn({ userId: user._id }));
        if (data) {
            setAgentMargin(data.agentMargin);
        }
    };

    const handleExpiryChange = (value) => {
        setExpiryOption(value);
        if (value !== 'custom') {
            const days = parseInt(value);
            setCustomExpiryDate(moment().add(days, 'days'));
        }
    };

    const handleSubmit = async () => {
        if (!agentMargin) {
            notification.error({ message: 'Enter forex margin.' });
            return;
        }

        const payload = {
            userId: user._id,
            agentMargin,
            // expiryDate: expiryOption === 'custom' ? customExpiryDate : moment().add(parseInt(expiryOption), 'days')
        };

        const resp = await dispatch(updateAgentMarginFxn(payload));
        if (resp.success) {
            notification.success({ message: 'Agent margin updated successfully!' });
        } else {
            notification.error({ message: resp.message });
        }
    };

    useEffect(() => {
        fetchAgentMargin();
    }, []);

    return (
        <div className="row">
            <div className="col-lg-12">
                <Form>
                    <div className="form-box mt-4">
                        <div className="d-flex align-items-center mb-3 heading-form">
                            <h5>Update Forex Margin</h5>
                        </div>

                        <div className="card unizportal">
                            <Row gutter={24}>
                                <Col span={8}>
                                    <InputBox title={'Forex Margin'}>
                                        <Input
                                            type={'text'}
                                            value={agentMargin}
                                            className={'form-control'}
                                            placeholder="Enter forex margin"
                                            onChange={(e) => setAgentMargin(e.target.value)}
                                        />
                                    </InputBox>
                                    <div className="margin-in-words ml10">
                                        {agentMargin !== undefined ? (
                                            <p><span>Amount In Words : </span>
                                                <b>{numberToWords(parseFloat(agentMargin))}</b></p>
                                        ) : null}
                                    </div>
                                </Col>
                                {/*       <Col span={8}>
                                    <InputBox title="Select Expiry">
                                        <Select onChange={handleExpiryChange}
                                                value={expiryOption} placeholder="Select expiry duration">
                                            <Option value="7">7 days</Option>
                                            <Option value="15">15 days</Option>
                                            <Option value="30">30 days</Option>
                                            <Option value="custom">Custom Date</Option>
                                        </Select>
                                    </InputBox>
                                    <p style={{ color: 'gray', marginTop: '8px' }}>
                                        Please note: Margin duration is limited to a maximum of 30 days.
                                    </p>
                                </Col>
                                <Col span={8}>
                                    {expiryOption === 'custom' && (
                                        <InputBox title="Custom Expiry Date">
                                            <DatePicker
                                                className={'form-control'}
                                                value={customExpiryDate}
                                                onChange={(date) => setCustomExpiryDate(date)}
                                                disabledDate={(date) => date.isAfter(moment().add(30, 'days')) || date.isBefore(moment())}
                                            />
                                        </InputBox>
                                    )}
                                </Col>*/}
                            </Row>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="btn" onClick={() => {
                                    handleSubmit()
                                }}>
                                    Update
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default UpdateAgentMargin;
