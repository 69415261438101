import React, { useEffect, useState } from 'react'
import CountryWiseApplicationForLiveReportingList from './countryWiseApplicationsForLiveReporting'

const ApplicationListForLiveReporting = props => {
  let [countryId, setCountryId] = useState('')
  const getCountryId = () => {
    let searchParams = new URLSearchParams(window.location.search)
    let countryId = searchParams.get('countryId')
    setCountryId(countryId)
  }

  useEffect(() => {
    getCountryId()
  }, [window.location])

  return (
    <React.Fragment>
      {countryId ? <CountryWiseApplicationForLiveReportingList /> : null}
    </React.Fragment>
  )
}


export default ApplicationListForLiveReporting
