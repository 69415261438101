import { apiUrl,apiUrl2 } from "../../../settings"

export const addInstituteEventUrl = () => {
    return apiUrl + '/api/institute-event';
}

export const singleInstituteEventUrl = () => {
    return apiUrl + '/api/single-institute-event';
}

export const instituteEventListUrl = () => {
    return apiUrl + '/api/institute-event';
}

export const updateInstituteEventUrl = () => {
    return apiUrl + '/api/update-institute-event';
}

export const deleteInstituteEventUrl = () => {
    return apiUrl + '/api/delete-institute-event';
}

export const instituteListUrl = () => {
    return apiUrl + '/api/institute';
}

export const activateInstituteUrl = () => {
    return apiUrl + '/api/activateInstitute';
}

export const addSlotUrl = () => {
    return apiUrl + '/api/slot';
}

export const addSingleSlotUrl = () => {
    return apiUrl + '/api/singleSlot';
}

export const slotListUrl = () => {
    return apiUrl + '/api/slot'
}
export const updateSlotUrl = () => {
    return apiUrl + '/api/updateSlot'
}

export const deleteSlotUrl = () => {
    return apiUrl + '/api/deleteSlot'
}
export const deleteSlotByDateUrl = () => {
    return apiUrl + '/api/deleteSlotByDate'
}

export const updateInstituteEventStatusUrl = () => {
    return apiUrl + '/api/update-institute-event-status'
}

export const updateInstituteEventDateUrl = () => {
    return apiUrl + '/api/change-institute-event-date'
}

export const addInstituteCommentUrl = () => {
    return apiUrl + '/institute-event-comment/add'
}
export const instituteEventsCommentsUrl = () => {
    return apiUrl + '/institute-event-comment/list'
}

export const appliedEventsListUrl = () => {
    return apiUrl + '/applied-event/list'
}

export const activityListUrl = () => {
    return apiUrl + '/api/institute-event-activity'
}
export const instituteStudentListUrl = () => {
    return apiUrl2 + '/api/institute-student-list'
}

export const approvedEventListUrl = () => {
    return apiUrl + '/institute-event/approved-events';
}

