import React, {PureComponent} from 'react'
import {
  Form,
  Input,
  Select,
  Button,
  Col, Row, Drawer
} from 'antd'
import _ from 'lodash'
import moment from 'moment'

import {hideLoader, hidePageLoad} from '../../../modules/actions'
import {connect} from 'react-redux'
import {listAllBranch} from '../../branch/actions/branchAction'
import { countryIds, InputBox } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {updateUser, getUser} from '../actions/user'
import {LoadAllCountry, LoadState, LoadCity, CountryCodeWithFlag} from '../../../components/_utils/countryUtil'
import { listAllCountries } from '../../countries/actions/countries'

const {TextArea} = Input

@Form.create()
class EditTeam extends PureComponent {

  events = {
    chooseCountry: (name) => {
      let countryName = _.find(this.state.allCountries, (item) => {
        return item.name == name
      })
      if (countryName) {
        this.setState({
          countryName: countryName.name ? countryName.name : '',
          stateName: '',
          cityName: '',
          allStates: countryName.id ? LoadState(countryName.id) : []
        })
      }
    },
    chooseState: (name) => {
      let stateName = _.find(this.state.allStates, (item) => {
        return item.name == name
      })
      if (stateName) {
        this.setState({
          stateName: stateName.name ? stateName.name : '',
          cityName: '',
          allCities: stateName.id ? LoadCity(stateName.id) : []
        })
      }
    },
    chooseCity: (name) => {
      this.setState({
        cityName: name ? name : ''
      })
    },
    loadCountry: async () => {
      let filters = {
        sortField: 'countryName',
        sortOrder: 'ascend',
        results: 100,
        count: 100,
        customQuery: {
          _id: { $ne: countryIds.canadaOnshore }
        }
      }
      let { dispatch } = this.props
      let { data } = await dispatch(listAllCountries(filters))
      this.setState({
        countryArr: data
      })
    }
  }

  async loadUserData() {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    this.setState({userData: user})
  }

  constructor(props) {
    super(props)
    this.state = {
      allBranch: [],
      logo: {},
      email: '',
      uploadKey: moment(),
      allCountries: LoadAllCountry(),
      allStates: [],
      allCities: [],
      countryName: '',
      stateName: '',
      cityName: '',
      countryCode: '',
      countryArr: [],
    }
  }

  async getUserData() {
    let {dispatch, form: {setFieldsValue}, userData} = this.props
    if (userData && userData._id) {
      let resp = await dispatch(getUser(userData._id))
      if (resp) {
        this.setState({
          userId: resp._id,
          oldLogo: resp.logo,
          address: resp.address,
          countryCode: resp.countryISOCode,
        })
        setFieldsValue({
          name: resp.name,
          mobile: resp.mobile,
          userType: resp.userType,
          address: resp.address,
          countryName: resp.countryName,
          stateName: resp.stateName,
          cityName: resp.cityName,
          countryArr: resp.countryArr
        })

        if (resp.countryName) {
          this.events.chooseCountry(resp.countryName)
        }
        if (resp.stateName) {
          this.events.chooseState(resp.stateName)
        }

      }
    }
  }


  handleSubmit = e => {
    const {dispatch, form, onClose} = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let agentId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

        valData.userType = 'subAgent'
        valData.countryISOCode = this.state.countryCode
        valData.address = this.state.address
        // valData.email = this.state.email
        let fd = new FormData()

        fd.append('obj', JSON.stringify(valData))
        if (this.state.logo && this.state.logo.name) {
          fd.append('logo', this.state.logo)
        }
        let data = await dispatch(updateUser(fd, this.state.userId))
        if (data && !data.error) {
          form.resetFields()
          this.setState({
            address: '',
            uploadKey: moment(),
            logo: {}
          })
          onClose()
        }
      }
    })
  }

  componentDidMount() {
    this.events.loadCountry()
    this.getUserData()
    let {dispatch} = this.props
    dispatch(hideLoader())
    dispatch(hidePageLoad())
    this.loadUserData()
    dispatch({type: 'USER_HIDELOADER'})
  }

  async loadBranch() {
    let {dispatch} = this.props
    let {data} = await listAllBranch({})(dispatch)
    this.setState({
      allBranch: data
    })
  }


  render() {

    const {visible, onClose, form: {getFieldDecorator, getFieldValue}} = this.props

    const {userData} = this.state

    const formItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 24},
        md: {span: 24}
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 24},
        md: {span: 24}
      }
    }

    let inputTypes = {
      fields: [
        {
          label: 'Contact Person Name',
          key: 'name',
          required: true
        },
        {
          key: 'countryCode',
          span: 3,
          customField: (
            <div style={{marginTop: -10}}>
              <CountryCodeWithFlag countryCode={this.state.countryCode} chooseCode={(val) => {
                this.setState({
                  countryCode: val
                })
              }}/>
            </div>
          )
        },
        {
          span: 5,
          label: 'Mobile no',
          key: 'mobile',
          type: "number",
          required: true
        },
        {
          label: 'Email',
          key: 'email',
          required: true,
          hidden: userData && (userData.userType === 'admin' || userData.userType === 'userManager' || userData.userType === 'branchUser') ? false : true
        },
        {
          key: 'countryName',
          label: 'Country',
          type: 'select',
          showSearch: true,
          options: this.state.allCountries,
          keyAccessor: x => x.name,
          valueAccessor: x => `${x.name}`,
          onChange: x => {
            this.props.form.setFieldsValue({
              countryName: x,
              stateName: '',
              cityName: ''
            })
            this.events.chooseCountry(x)
          }
        },
        {
          key: 'stateName',
          label: 'Province/State',
          type: 'select',
          showSearch: true,
          options: this.state.allStates,
          keyAccessor: x => x.name,
          valueAccessor: x => `${x.name}`,
          onChange: x => {
            this.props.form.setFieldsValue({
              stateName: x,
              cityName: ''
            })
            this.events.chooseState(x)
          }
        },
        {
          key: 'cityName',
          label: 'City/Town',
          type: 'select',
          showSearch: true,
          hidden: getFieldValue('countryName') !== 'India',
          options: this.state.allCities,
          keyAccessor: x => x.name,
          valueAccessor: x => `${x.name}`,
          onChange: x => {
            this.props.form.setFieldsValue({
              cityName: x
            })
            this.events.chooseCity(x)
          }
        },
        {
          key: 'cityName',
          label: 'City/Town',
          hidden: getFieldValue('countryName') == 'India',
          onChange: (e) => {
            let {value} = e.target
            this.props.form.setFieldsValue({
              cityName: value
            })
            this.events.chooseCity(value)
          }
        },
        {
          key: 'countryArr',
          label: 'Select Countries',
          type: 'select',
          mode: 'multiple',
          required: true,
          options: this.state.countryArr,
          disabled:true,
          keyAccessor: x => x._id,
          valueAccessor: x => `${x.countryName}`,
          onChange: x => {
            this.props.form.setFieldsValue({
              countryArr: x
            })
          }
        },
        {
          key: 'address',
          label: 'City/Town',
          span: 24,
          customField: (
            <InputBox title={'Address'}>
              <TextArea rows={4} className={'form-control'} value={this.state.address} onChange={(e) => {
                this.setState({address: e.target.value})
              }}>
              </TextArea>
            </InputBox>
          )
        }
        // {
        //   key: 'enableChanelPartner',
        //   label: 'Show Channel Partner',
        //   type: 'select',
        //   required: true,
        //   keyAccessor: x => x.value,
        //   valueAccessor: x => `${x.name}`,
        //   options: [{ name: 'Enable', value: true }, { name: 'Disable', value: false }],
        //   onChange: x => {
        //     setFieldsValue({
        //       enableChanelPartner: x
        //     })
        //   }
        // },
        // {
        //   key: 'showAustraliaUniversity',
        //   label: 'Show Australia University',
        //   type: 'select',
        //   required: true,
        //   keyAccessor: x => x.value,
        //   valueAccessor: x => `${x.name}`,
        //   options: [{ name: 'Enable', value: true }, { name: 'Disable', value: false }],
        //   onChange: x => {
        //     setFieldsValue({
        //       showAustraliaUniversity: x
        //     })
        //   }
        // }

      ]
    }


    return (
      <Drawer
        title={'Update Sub Agent'}
        visible={visible}
        placement="right"
        closable={true}
        onClose={onClose}
        width={'80%'}>

        <div className="form-box">
          <div className="card unizportal">
            <Form onSubmit={this.handleSubmit} className={'vertical-form'}>
              <Row gutter={16} className={'row1 rowWrap'}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <React.Fragment>
                      {item.customField ? <Col span={item.span ? item.span : 8} key={key}>
                          {item.customField}
                        </Col> :
                        !item.hidden && <Col span={item.span ? item.span : 8} key={key}>
                          <GetEachFormFields
                            item={item}
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout}/>
                        </Col>}
                    </React.Fragment>
                  )
                })}

              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={this.props.loading} className={'btn'}>
                  SAVE
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>

      </Drawer>
    )
  }
}

const mapStateToProps = ({global, router}) => ({
  loading: global.buttonLoading,
  pathname: router.location.pathname,
  search: router.location.search
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTeam)
