import React, {useEffect} from 'react';
import {Modal, Form, Button, Row, Col, notification} from 'antd';
import GetEachFormFields from '../../components/_utils/appFormUtils';
import {reactivationRequestFxn} from "./action";
import {useDispatch} from "react-redux";

const inputFields = [
    {
        key: 'name',
        label: 'Agent Name',
        placeholder: 'Agent Name',
        span: 12,
        disabled: true,
    },
    {
        key: 'companyName',
        label: 'Company Name',
        placeholder: 'Company Name',
        span: 12,
        disabled: true,
    },
    {
        key: 'email',
        label: 'Email',
        placeholder: 'Email',
        span: 12,
        disabled: true,
    },
    {
        key: 'contactPerson',
        label: 'Your Name',
        placeholder: 'Your Name',
        span: 12,
        required: true
    },

    {
        key: 'mobile',
        label: 'Your Contact No',
        placeholder: 'Your Contact No',
        span: 12,
        required: true
    },

    {
        key: 'designation',
        label: 'Your Designation',
        placeholder: 'Your Designation',
        span: 12,
        required: true
    },
    {
        key: 'referralCode',
        label: 'Referral Code',
        placeholder: 'Referral Code',
        span: 12,
    },
    {
        key: 'details',
        label: 'Details',
        placeholder: 'Details',
        type: 'textArea',
        span: 24,
        required: true
    },
];


const ReactivationForm = (props) => {
    const {visible, onClose, form, userData} = props
    const {getFieldDecorator, setFieldsValue} = props.form;
    const dispatch = useDispatch()

    useEffect(() => {

        if (userData) {
            setTimeout(() => {
                setFieldsValue({
                    name: userData && userData.name,
                    email: userData && userData.email,
                    companyName: userData && userData.companyName,
                });
            }, 500)
        }
    }, [userData, setFieldsValue]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        form.validateFields(async (err, values) => {
            if (!err) {
                const requestData = {
                    ...values,
                    userId: userData.userId,
                };
                let response = await dispatch(reactivationRequestFxn(requestData));
                console.log(response, '---------------------');
                if (response && response.success) {
                    onClose();
                }
            }
        });
    };

    const formItemLayout = {
        labelCol: {xs: {span: 0}, sm: {span: 0}, md: {span: 0}},
        wrapperCol: {xs: {span: 24}, sm: {span: 24}, md: {span: 24}},
    };

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            footer={null}
            title="Register Your Request"
            width="50%"
        >
            <Form layout="vertical" onSubmit={handleSubmit}>
                <Row gutter={24} className="wrapBox">
                    {inputFields.map((item, key) => (
                        <Col
                            span={item.span || 8}
                            md={item.span || 8}
                            sm={12}
                            xs={24}
                            key={key}
                            className="mb10"
                        >
                            <GetEachFormFields
                                item={item}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={formItemLayout}
                            />
                        </Col>
                    ))}
                </Row>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Form.create()(ReactivationForm);
