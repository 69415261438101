import React, { useEffect, useState } from 'react'
import { Button, Drawer, notification, Form, Card, Row, Col } from 'antd'
import { listAllUniversities, listQuickSearchUniversities } from '../../university/actions/university'
import { listAllCourse, loadCourseIntakesFxn } from '../../course/actions/courseAction'
import { useDispatch } from 'react-redux'
import { listAllCountries } from '../../countries/actions/countries'
import _ from 'lodash'
import { RowTable } from '../../../components/_utils/RowTable'
import { fcmtUniversity } from '../../../components/_utils/appUtils'
import { getBranchUserForReActivateApplication } from '../../users/actions/user'
import { updateUniversityInApplication } from '../../student/actions/student'
import { listCourseCampus } from '../../campus/actions/campus'
import GetEachFormFields from '../../../components/_utils/appFormUtils'

let status = 'Waiting To Apply'
const UpdateUniversityInApplicationComponent = (props) => {
  const {
    form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields },
    onClose, studentId, visible, application, studentData
  } = props
  const dispatch = useDispatch()
  let [allCountry, setAllCountry] = useState([])
  let [countryUniversity, setCountryUniversity] = useState([])
  let [universityCourse, setUniversityCourse] = useState([])
  let [selectedCourse, setSelectedCourse] = useState({})
  let [intakesList, setIntakesList] = useState([])
  let [intake, setIntake] = useState({})
  let [userList, setUserList] = useState([])
  let [countryId, setCountryId] = useState('')
  let [currentUniversity, setCurrentUniversity] = useState('')
  let [currentCourse, setCurrentCourse] = useState('')
  let [campusList, setCampusList] = useState([])
  let [universityName, setUniversityName] = useState('')

  useEffect(() => {
    setDefaultFields()
  }, [])

  const setDefaultFields = () => {
    if (application) {
      if (application.universityCountry && application.universityCountry._id) {
        events.loadUniversity(application.universityCountry._id)
        setCountryId(application.universityCountry._id)
      }
      if (application.courseUniversity && application.courseUniversity.universityName) {
        setCurrentUniversity(application.courseUniversity.universityName)
      }
      setCurrentCourse(application.courseName)

    }
  }

  const loadUserForAssign = async (obj) => {
    let { success, data } = await getBranchUserForReActivateApplication(obj)
    if (success) {
      setUserList(data)
    }
  }
  const loadUserOnChooseUniversity = async () => {
    let obj = {
      universityId: getFieldValue('universityId'),
      countryId: countryId,
      status: application.status,
      studentId,
      applicationId: application._id
    }
    loadUserForAssign(obj)
  }

  const events = {
    loadCountry: async () => {
      let filters = {
        sortField: 'countryName', sortOrder: 'ascend'
      }
      let { data } = await dispatch(listAllCountries(filters))
      setAllCountry(data)
    },
    loadUniversity: async (item) => {
      let obj = {
        results: 1000000,
        universityCountry: item,
        sortField: 'universityName',
        sortOrder: 'ascend'
      }
      let data = await dispatch(listQuickSearchUniversities(obj))
      //console.log(data)
      setCountryUniversity(data)
    },
    loadCourse: async (item) => {
      let obj = {
        results: 1000000,
        courseUniversity: item,
        sortField: 'courseName',
        sortOrder: 'ascend'
      }

      let { data } = await dispatch(listAllCourse(obj))
      setUniversityCourse(data)
    },
    loadCampus: async (item) => {
      let obj = {
        results: 1000000,
        courseId: item
      }
      let { data } = await dispatch(listCourseCampus(obj))
      setCampusList(data)
      if (getFieldValue('universityId') == fcmtUniversity && data && data.length) {
        setFieldsValue({
          campusId: data[0]._id
        })
      }
    },
    loadIntakes: async (courseId) => {
      let resp = await dispatch(loadCourseIntakesFxn({ courseId }))
      let data = []
      _.each(resp, (item) => {
        if (item) {
          item.value = `${item.intake}-${item.year}`
          item.label = `${item.intake}, ${item.year}`
          data.push(item)
        }
      })
      setIntakesList(data)
      if (getFieldValue('universityId') == fcmtUniversity && resp && resp.length) {
        setFieldsValue({
          intakeTemp: data[0].value
        })
        let intake = {
          month: resp[0].intake,
          year: resp[0].year
        }
        setIntake(intake)
      }
    }
  }
  let inputTypes = {
    fields: [

      {
        key: 'universityId',
        label: 'University',
        required: true,
        type: 'select',
        showSearch: true,
        options: countryUniversity,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.universityName}`,
        onChange: x => {
          setFieldsValue({
            universityId: x,
            courseId: '',
            intakeTemp: '',
            userId: '',
            campusId: ''
          })
          let findUniversity = _.find(countryUniversity, (item) => {
            return item._id == x
          })
          if (findUniversity) {
            setUniversityName(findUniversity.universityName)
          }
          events.loadCourse(x)
          loadUserOnChooseUniversity()
          setIntake({})
        }
      },
      {
        key: 'courseId',
        label: 'Course',
        required: true,
        type: 'select',
        showSearch: true,
        options: universityCourse,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.courseName}`,
        onChange: x => {
          setFieldsValue({
            courseId: x,
            intakeTemp: '',
            campusId: ''
          })
          events.loadCampus(x)
          let selectedCourse = _.find(universityCourse, (item) => {
            return item._id == x
          })
          setSelectedCourse(selectedCourse)
          events.loadIntakes(x)
          setIntake({})
        }
      },
      {
        key: 'campusId',
        label: 'Campus',
        type: 'select',
        span: 12,
        showSearch: true,
        required: getFieldValue('universityId') == fcmtUniversity,
        options: campusList,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.campusName}`,
        onChange: x => {
          setFieldsValue({
            campusId: x
          })
        }
      },
      {
        key: 'intakeTemp',
        label: 'Intake',
        type: 'select',
        required: getFieldValue('universityId') == fcmtUniversity,
        span: 12,
        showSearch: true,
        options: intakesList,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.label}`,
        onChange: (x) => {
          setFieldsValue({
            intakeTemp: x
          })
          if (x) {
            let intakeVal = x.split('-')
            let intake = {
              month: intakeVal[0],
              year: intakeVal[1]
            }
            setIntake(intake)

          } else {
            setIntake({})
          }
        }
      },
      {
        key: 'userId',
        label: 'Assign to',
        type: 'select',
        required: true,
        showSearch: true,
        options: userList,
        keyAccessor: x => x._id,
        valueAccessor: x => x.name,
        onChange: (x) => {
          setFieldsValue({
            userId: x
          })
        }
      }
    ]
  }

  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        valData.intake = intake
        valData.studentId = studentId
        valData.applicationId = application._id
        valData.status = status

        valData.courseUniversity = valData.universityId
        valData.currentUniversity = currentUniversity
        valData.currentCourse = currentCourse
        valData.universityName = universityName


        let university = _.find(countryUniversity, (item) => {
          return item._id == valData.universityId
        })

        if (university && university.universityName) {
          valData.applicationFee = university.applicationFee
          valData.universityState = university.universityState && university.universityState._id ? university.universityState._id : ''
          valData.universityCity = university.universityCity && university.universityCity._id ? university.universityCity._id : ''
          valData.universityName = university.universityName
        }

        let course = _.find(universityCourse, (item) => {
          return item._id == valData.courseId
        })

        if (course && course.courseName) {
          valData.tuitionFee = course.tuitionFee
          valData.courseName = course.courseName
        }


        let resp = await dispatch(updateUniversityInApplication(valData))
        if (resp && resp.success) {
          onClose()
          setIntake({})
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  return (
    <Drawer visible={visible}
            title={`Edit course without status change`}
            width={600}
            closable={true}
            maskClosable={true}
            onClose={onClose}>
      <Card>
        <div className={'tableBox striped'}>
          <RowTable title={'Student Name'} value={studentData.name}/>
          <RowTable title={'Student Id'} value={studentData.studentId}/>
          {application.assignedUserId && application.assignedUserId.name ?
            <RowTable title={'Current User'} value={application.assignedUserId.name}/> : null}
          {application.status ?
            <RowTable title={'Status'} value={application.status}/> : null}
          {application.universityCountry && application.universityCountry.countryName ?
            <RowTable title={'Country'} value={application.universityCountry.countryName}/> : null}
          {application.courseUniversity && application.courseUniversity.universityName ?
            <RowTable title={'University'} value={application.courseUniversity.universityName}/> : null}
          <RowTable title={'Course'} value={application.courseName}/>

          {application.intake && application.intake.year ?
            <RowTable title={'Intake'} value={`${application.intake.month}, ${application.intake.year}`}/> : null}

        </div>
        <Form className={'vertical-form'} autoComplete="off" onSubmit={handleSubmit}>
          <Row gutter={24}>

            {inputTypes.fields.map((item, key) => {
              return (
                <Col span={item.span ? item.span : 24} md={item.span ? item.span : 24} sm={24} xs={24} key={key}
                     className={'mb10'}>
                  <GetEachFormFields
                    item={item}
                    getFieldDecorator={getFieldDecorator}
                    formItemLayout={formItemLayout}/>
                </Col>
              )
            })}
          </Row>

          <Form.Item style={{ marginTop: 10 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  )
}
const WrappedUpdateUniversityInApplicationComponent = Form.create()(UpdateUniversityInApplicationComponent)
export default WrappedUpdateUniversityInApplicationComponent
