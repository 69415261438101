import React from 'react'
import { Drawer, Form, Icon } from 'antd'
import { connect } from 'react-redux'
import { SearchedCourseBlock } from '../singleBlocks'
import AddApplications from '../../applications/views/add'
import _ from 'lodash'
import { showCommissionCheck } from '../../../components/_utils/appUtils'

class CourseListComponent extends React.Component {
  searchEvents = {
    hideStudentDrawer: () => {
      this.setState({
        visibleStudentDrawer: false,
        selectedCourse: {}
      })
    },
    showStudentDrawer: (course) => {
      let echCou = _.clone(course)
      echCou.universityName = echCou.courseUniversity.universityName
      echCou.applicationFee = echCou.courseUniversity.applicationFee
      echCou.courseUniversity = echCou.courseUniversity._id
      echCou.countryName = echCou.universityCountry.countryName
      echCou.universityCountry = echCou.universityCountry._id
      echCou.universityState = echCou.universityState._id
      echCou.universityCity = echCou.universityCity._id
      echCou.courseId = echCou._id
      console.log(echCou)
      this.setState({
        visibleStudentDrawer: true,
        selectedCourse: echCou
      })
    }
  }

  constructor() {
    super()
    this.state = {
      loading: false,
      visibleStudentDrawer: false,
      selectedCourse: {}
    }
  }


  loadMoreCourseFxn = (item) => {
    let { events } = this.props
    events.loadMoreSearchCourse()
  }

  render() {
    let { coursesList, courseCount, events, currentUser } = this.props
    let { searchEvents } = this
    let { selectedCourse, visibleStudentDrawer } = this.state
    return (
      <div className={'searchAppDiv'}>
        {
          coursesList && coursesList.length ?
            <div className='academy-box featureUniversityDiv'>

              <div className='heading-form d-flex align-items-center'>
                <h5>Applications in Canada</h5>
                <button className='btn ml-auto'>Total {courseCount.canadaCount} courses
                </button>
              </div>

              <div className='card cardRelative pb-0'>
                <div className='row uniBox courseBox'>
                  {coursesList.map((item, key) => {
                    return (
                      <SearchedCourseBlock key={key} showCommission={showCommissionCheck(currentUser)} item={item}
                                           callback={() => {
                                             searchEvents.showStudentDrawer(item)
                                           }} />
                    )
                  })}

                </div>
                {coursesList.length < courseCount.canadaCount ? <div className={'loadMoreDiv'}>
                  <a onClick={() => this.loadMoreCourseFxn(coursesList)}>
                    load {courseCount.canadaCount - coursesList.length} more courses <Icon type='down' /></a>
                </div> : null}
              </div>
            </div> : null
        }

        {visibleStudentDrawer ? <AddApplications
          visible={visibleStudentDrawer}
          selectedCourse={selectedCourse}
          closeStudentDrawer={searchEvents.hideStudentDrawer} /> : null}

      </div>
    )
  }

}


const WrappedEvaluateIndex = Form.create()(CourseListComponent)

const mapStateToProps = ({ global, router }) => ({
  pageLoading: global.pageLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedEvaluateIndex)


