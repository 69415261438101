import {push} from 'connected-react-router'
import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {
    DefaultTablePagination,
    displayTime
} from '../../../components/_utils/appUtils'
import {enrolledCompaniesListFxn} from '../actions/enrollCompany'

const EnrolledCompaniesList = () => {
    const tableRef = useRef()
    const dispatch = useDispatch()
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null

    const apiRequest = params => {
        return new Promise(async resolve => {
            params.sortField = '_id'
            params.sortOrder = 'descend'
            params.referralBy = user.referCode

            let data = await dispatch(
                enrolledCompaniesListFxn({
                    ...params,
                    active: [true, false],
                    regExFilters: ['contactPerson', 'companyName', 'email']
                })
            )
            resolve(data)
        })
    }

    const columns = [
        {
            title: 'Serial No.',
            dataIndex: 'index',
            key: 'index',
            width: 70,
            render: (val, record, index) => {
                return index + 1
            }
        },
        {
            title: 'Name',
            dataIndex: 'contactPerson',
            key: 'contactPerson',
            searchTextName: 'contactPerson',
            filterRegex: true
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            searchTextName: 'email',
            filterRegex: true
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
            render: (item, record) => {
                return item
            }
        },
        {
            title: 'Country',
            dataIndex: 'countryName',
            key: 'countryName',
            render: (item, record) => {
                return item
            }
        },
        {
            title: 'State',
            dataIndex: 'stateName',
            key: 'stateName',
            render: (item, record) => {
                return item
            }
        },
        {
            title: 'Contact No',
            dataIndex: 'mobile',
            key: 'mobile',
            render: (item, record) => {
                let {countryCode} = record
                return `${countryCode} ${item}`
            }
        },
        {
            title: 'Last LoginTime',
            key: 'lastLoginTime',
            dataIndex: 'lastLoginTime',
            render: item => {
                return item ? displayTime(item) : ''
            }
        },
        {
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            render: (item, record) => {
                return (
                    <>
                        {item ? (
                            <span className="active">Active</span>
                        ) : (
                            <span className="inactive">Inactive</span>
                        )}
                    </>
                )
            }
        }
    ]

    return (
        <div className="row mt-4">
            <div className="col-lg-12">
                <div className="card">
                    <div className="table-head d-flex align-items-center">
                        <h5>EnrolledCompanie</h5>
                        <div className="search-box-table"/>
                        <div className="sort-box-table mark-btn">
                            <a
                                className="btn"
                                onClick={() => dispatch(push('/enroll/enroll-company'))}>
                                <img src={'../dist/icons/plus.png'} className={'plus'}/>
                                Enroll Company
                            </a>
                        </div>
                    </div>
                    <div className="card-body table-responsive">
                        <TableComp
                            columns={columns}
                            apiRequest={apiRequest}
                            ref={tableRef}
                            pagination={DefaultTablePagination()}
                            // extraProps={{ scroll: { x: 600 } }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnrolledCompaniesList
