import React, {useEffect, useState} from 'react'
import {countryIds, InputBox} from '../../../components/_utils/appUtils'
import {Button, Card, Drawer, Input, notification, DatePicker, Row, Col, Select} from 'antd'
import {useDispatch} from 'react-redux'
import {calculateCommission, submitCommission} from '../actions/chooseStudent'
import {RowTable} from '../../../components/_utils/RowTable'
import moment from 'moment'
import CountryCodeFlagJson from '../../../assets/jsonFiles/countryCodeFlag.json'

const dateFormat = 'DD/MM/YYYY'

let {TextArea} = Input
const {Option} = Select
const EnrollmentCourse = (props) => {
    const dispatch = useDispatch()
    let {visible, onClose, application, studentId, studentData} = props
    let [commissionAmount, setCommissionAmount] = useState(null)
    let [commissionCurrencyAmount, setCommissionCurrencyAmount] = useState(null)
    let [courseStartDate, setCourseStartDate] = useState(null)
    let [invoiceDate, setInvoiceDate] = useState(null)
    let [commission, setCommission] = useState({})
    let [invoiceAddress, setInvoiceAddress] = useState('')
    let [semester, setSemester] = useState('')
    let {agentId} = application
    useEffect(() => {
        getCommission()
    }, [])
    const getCommission = async () => {
        let obj = {
            agentId: application.agentId._id,
            courseId: application.courseId,
            countryId: application.universityCountry._id
        }
        let {success, data} = await dispatch(calculateCommission(obj))
        if (data) {
            setCommissionAmount(data.commissionAmount)
            setCommission(data)
        }
    }

    const handleSubmit = async () => {
        if (!invoiceDate) {
            notification.warning({message: 'Choose Invoice Date.'})
            return
        }
        if (!commissionAmount) {
            notification.warning({message: 'Please enter commission.'})
            return
        }
        if (!commissionCurrencyAmount) {
            notification.warning({message: 'Enter Commission with Currency'})
            return
        }

        if (!courseStartDate) {
            notification.warning({message: 'Choose Course Start Date'})
            return
        }
        if (!invoiceAddress) {
            notification.warning({message: 'Choose Invoice Address'})
            return
        }

        let obj = {
            studentId,
            applicationId: application._id,
            commission: {
                ...commission,
                commissionAmount,
                invoiceDate,
                courseStartDate,
                semester,
                invoiceAddress,
                commissionInCurrency: commissionCurrencyAmount
            }
        }
        let {success, data, message} = await dispatch(submitCommission(obj))
        if (success) {
            notification.success({message: message})
            onClose()
        } else {
            notification.error({message: message})
        }

    }
    return (
        <Drawer visible={visible}
                onClose={onClose}
                width={'70%'}
                closable={true}
                maskClosable={true}
                title={'Commission'}>
            <Card>
                <div className={'tableBox striped'}>
                    <Row>
                        <Col span={12}>
                            <RowTable title={'Student Name'} value={studentData.name}/>
                        </Col>
                        <Col span={12}>
                            <RowTable title={'Student Id'} value={studentData.studentId}/>
                        </Col>
                        {application.assignedUserId && application.assignedUserId.name ?
                            <Col span={12}><RowTable title={'Current User'}
                                                     value={application.assignedUserId.name}/></Col> : null}

                        <Col span={12}><RowTable title={'Transfer to Status'} value={application.status}/></Col>
                        {application.universityCountry && application.universityCountry.countryName ?
                            <Col span={12}> <RowTable title={'Country'}
                                                      value={application.universityCountry.countryName}/></Col> : null}
                        {application.courseUniversity && application.courseUniversity.universityName ?
                            <Col span={12}> <RowTable title={'University'}
                                                      value={application.courseUniversity.universityName}/>
                            </Col> : null}
                        <Col span={12}> <RowTable title={'Course'} value={application.courseName}/></Col>
                        {application.intake && application.intake.year ?
                            <Col span={12}> <RowTable title={'Intake'}
                                                      value={`${application.intake.month}, ${application.intake.year}`}/></Col> : null}
                        {application.tuitionFee ?
                            <Col span={12}><RowTable title={'Tuition Fee'} value={(
                                application.universityCountry && application.universityCountry.countryName ? <span>
                {application.universityCountry.currency}{application.tuitionFee}
              </span> : application.tuitionFee
                            )}/></Col> : null}
                        {application.applicationFee ?
                            <Col span={12}> <RowTable title={'Application Fee'} value={(
                                application.universityCountry && application.universityCountry.countryName ? <span>
                {application.universityCountry.currency}{application.applicationFee}
              </span> : application.applicationFee

                            )}/></Col> : null}

                        {agentId ?
                            <>
                                {agentId.name ?
                                    <Col span={12}> <RowTable title={'Agent Name (Company)'}
                                                              value={`${agentId.name} (${agentId.companyName})`}/></Col> : null}
                                {agentId.GSTINAvailable && agentId.GSTINAvailable == 'Yes' ?
                                    <Col span={12}> <RowTable title={'GSTIN'}
                                                              value={`${agentId.GSTIN}`}/></Col> :
                                    <Col span={12}> <RowTable title={'GSTIN Available'}
                                                              value={`No`}/></Col>}
                            </>
                            : null}
                    </Row>


                </div>
                <Row gutter={16} className={'rowWrap'}>
                    <Col span={12}>
                        <InputBox title={'Invoice Date'}>
                            <DatePicker
                                defaultValue={invoiceDate} format={dateFormat} placeholder={'Invoice Date'}
                                onChange={(value) => {
                                    setInvoiceDate(value)
                                }}/>
                        </InputBox>
                    </Col>
                    <Col span={12}>
                        <InputBox title={'Commission in INR'}>
                            <Input type={'number'} value={commissionAmount} onChange={(e) => {
                                setCommissionAmount(e.target.value)
                            }} className={'form-control'}/>
                        </InputBox>
                    </Col>
                    <Col span={12}>
                        <InputBox title={'Commission In Currency'}>
                            <Input value={commissionCurrencyAmount} onChange={(e) => {
                                setCommissionCurrencyAmount(e.target.value)
                            }} className={'form-control'}/>
                        </InputBox>
                    </Col>
                    <Col span={12}>
                        <InputBox title={'Course start Date'}>
                            <DatePicker
                                defaultValue={courseStartDate} format={dateFormat} placeholder={'Course Start Date'}
                                onChange={(value) => {
                                    setCourseStartDate(value)
                                }}/>
                        </InputBox>
                    </Col>
                    <Col span={12}>
                        <InputBox title={'Invoice Address'} className={'rowWrap'}>
                            <Select
                                showSearch={true}
                                placeholder='Invoice Address'
                                value={invoiceAddress}
                                onChange={(e) => {
                                    setInvoiceAddress(e)
                                }}>
                                <Option value={'Ryan Consultants'} label={'Ryan Consultants'}>Ryan Consultants</Option>
                                <Option value={'Unizportal'}>Unizportal</Option>
                            </Select>
                        </InputBox>
                    </Col>
                    {/*{application && application.universityCountry && application.universityCountry._id == countryIds.canada ?
                        : null}*/}

                    <Col span={12}>
                        <InputBox title={'Semester'} className={'rowWrap'}>
                            <Input value={semester} onChange={(e) => {
                                setSemester(e.target.value)
                            }} className={'form-control'}/>
                        </InputBox>
                    </Col>
                    <Col span={24} style={{marginTop: 20}}>
                        <Button type='primary' onClick={handleSubmit}>
                            SUBMIT
                        </Button>
                    </Col>
                </Row>
            </Card>
        </Drawer>
    )
}

export default EnrollmentCourse
