import React from 'react'
import {Form, Select, Button, Card, notification, Spin, Row, Col, Drawer, Input,} from 'antd'
import InputBox from '../../components/_utils/InputBox'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import {addEventAction} from './action'

let initalState = {
    topBanner: '',
    registrationBanner: '',
    chiefGuestProfile: ''
}

const AddEvent = (props) => {
    const {form: {getFieldDecorator, getFieldValue}, visible, events, onSubmit} = props
    const [additional, setAdditional] = useState({})
    const [state, setState] = useState(initalState)
    const dispatch = useDispatch()


    const chooseDocument = (e) => {
        let {name, files} = e.target
        if (files && files.length) {
            setState({
                ...state,
                [name]: files[0]
            })
        }
    }


    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                let fd = new FormData();

                if (!state.topBanner || (state.topBanner && !state.topBanner.name)) {
                    notification.warn({message: "Please choose top Banner."})
                    return
                }
                if (!state.chiefGuestProfile || (state.chiefGuestProfile && !state.chiefGuestProfile.name)) {
                    notification.warn({message: "Please choose chief guest profile."})
                    return
                }

                fd.append("obj", JSON.stringify(valData));
                if (state.topBanner && state.topBanner.name) {
                    fd.append("topBanner", state.topBanner);
                }
                if (state.registrationBanner && state.registrationBanner.name) {
                    fd.append("registrationBanner", state.registrationBanner)
                }
                if (state.chiefGuestProfile && state.chiefGuestProfile.name) {
                    fd.append("chiefGuestProfile", state.chiefGuestProfile)
                }
                let x = await dispatch(addEventAction(fd));
                if (x && x.success) {
                    onSubmit()
                    props.form.setFieldsValue({
                        name: "",
                        eventType: "",
                        currency: "",
                        documents: "",
                        flag: ""
                    });

                }
            }
        });
    }

    let inputTypes = {
        fields: [
            {key: 'name', label: 'Title', type: 'text', required: true},

            {
                key: 'eventType',
                label: 'Event Type',
                type: 'select',
                options: ['Zoom', 'Face To Face'],
                required: true,
                span: 12,
                onChange: x => {
                    props.form.setFieldsValue({
                        eventType: x
                    })
                }
            },
            {key: 'date', label: 'Date', type: 'date', required: true, span: 6},
            {key: 'time', label: 'Time', type: 'time', required: true, span: 6},
            {
                key: 'zoomMeetingDetails',
                label: 'Zoom Meeting Details',
                type: 'textArea',
                span: 24,
                rows: 7,
                required: true,
                className: "textAreaHeight",
                hidden: !(getFieldValue('eventType') && getFieldValue('eventType') !== 'Face To Face'),
            },
            {
                key: 'location', label: 'Location', type: 'text', required: true,
                hidden: !(getFieldValue('eventType') && getFieldValue('eventType') !== 'Zoom'),
            },

            {
                key: 'showReference',
                label: 'Show Marketing User Reference',
                required: true,
                hidden: !(getFieldValue('eventType') && getFieldValue('eventType') !== 'Zoom'),
                span: 12,
                type: 'select',
                options: [{name: 'Yes', value: true}, {name: 'No', value: false}],
                keyAccessor: x => x.value,
                valueAccessor: x => `${x.name}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        showReference: x
                    })
                    setAdditional({
                        ...additional,
                        showReference: x,
                    });
                }
            },
            {
                key: 'showAdditionalMembers',
                label: 'Show Additional Members accompanying ',
                required: true,
                hidden: !(getFieldValue('eventType') && getFieldValue('eventType') !== 'Zoom'),
                span: 12,
                type: 'select',
                options: [{name: 'Yes', value: true}, {name: 'No', value: false}],
                keyAccessor: x => x.value,
                valueAccessor: x => `${x.name}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        showAdditionalMembers: x
                    })
                    setAdditional({
                        ...additional,
                        showAdditionalMembers: x,
                    });
                }
            },
            {
                key: 'chiefGuest',
                label: 'Chief Guest Name',
                type: 'text',
                width: 20,
                required: true,
                span: 12,
            },
            {
                key: 'designation',
                label: 'Designation',
                required: true,
                span: 12,
                rows: 7
            },
            {
                key: 'details',
                label: 'Details',
                type: 'textArea',
                span: 24,
                rows: 7,
                className: "textAreaHeight",

            },

        ]
    }

    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }
    return (
        <>
            <Drawer
                visible={visible}
                width={'60%'}
                placement="right"
                onClose={events.hideDrawer}
                title={'Add Event'}>
                <div className="row">
                    <div className="col-lg-12">
                        <Form onSubmit={handleSubmit}>
                            <div className="form-box">
                                <div className="card unizportal">
                                    <Row gutter={24}>
                                        {inputTypes.fields.map((item, key) => {
                                            return (
                                                <Col span={item.span ? item.span : 24} key={key}>

                                                    <GetEachFormFields
                                                        item={item}
                                                        formItemLayout={formItemLayout}
                                                        getFieldDecorator={getFieldDecorator}
                                                    />

                                                </Col>
                                            )
                                        })}
                                        <Col span={12}>
                                            <InputBox title={'Top Banner'} extra={'(1920px × 1080px)'}>
                                                <Input type={'file'} name={'topBanner'}
                                                       required={true}
                                                       className={'form-control'}
                                                       onChange={(e) => {
                                                           chooseDocument(e)
                                                       }}/>
                                            </InputBox>
                                        </Col>
                                        <Col span={12}>
                                            <InputBox title={'Registration Banner'} extra={'(1048px × 1280px)'}>
                                                <Input type={'file'} name={'registrationBanner'}
                                                       className={'form-control'}
                                                       onChange={(e) => {
                                                           chooseDocument(e)
                                                       }}/>
                                            </InputBox>
                                        </Col>
                                        <Col span={12}>
                                            <InputBox title={'Chief Guest Profile'}>
                                                <Input type={'file'} name={'chiefGuestProfile'}
                                                       className={'form-control'}
                                                       required={true}
                                                       onChange={(e) => {
                                                           chooseDocument(e)
                                                       }}/>
                                            </InputBox>
                                        </Col>
                                    </Row>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className="btn">
                                            SAVE
                                        </Button>
                                    </Form.Item>

                                </div>
                            </div>

                        </Form>
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default Form.create()(AddEvent)
