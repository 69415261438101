import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import { notification } from 'antd'
import { getGicCommissionReportsUrl, getGicTotalPendingBlcUrl } from '../apis'


export const getCommissionReportTransactionsFxn = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...await getToken()
  }
  let { data } = await axios.get(getGicCommissionReportsUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}

export const getGicCommissionTotalPendingBalance = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...await getToken()
  }
  let { data } = await axios.get(getGicTotalPendingBlcUrl(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}
