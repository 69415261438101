import React from 'react'
import { Button, Checkbox, Form, Input } from 'antd'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import Footer from '../footer'
import { Opportunity } from './opportunityComponent'

const TermsUse = () => {
  let dispatch = useDispatch()

  return (
    <React.Fragment>

      <section className="banner blog-banner blog-inner">

        <div className="container">
          <div className="head">
            <h2>Terms of use</h2>
          </div>
        </div>

      </section>

      <section className="gap-62 policy blog-inner pt-0">
        <div className="container">

          <div className="box">
            <p>Last Updated: June 13, 2021</p>

            <p>These terms and conditions (the "Terms of Use") apply to all web sites and applications (collectively,
              the "Sites") owned and operated by Uniz Portal Pvt. Ltd. or one of our affiliated companies ("Uniz Portal"
              or "us" or "we"). By registering for or otherwise using any of our Sites or Services (as defined below),
              or accessing any content or material that we make available through the Services or Sites, you agree to
              these Terms of Use. You may also agree to these Terms of Use by signing or entering into an ordering
              document with Uniz Portal that incorporates these Terms of Use. These Terms of Use, our Privacy Policy,
              and any ordering document form a legally binding agreement between you and Uniz Portal (collectively, this
              "Agreement"). We reserve the right, at our discretion, to change these Terms of Use in the future.</p>

            <p>If you do not agree to this Agreement, you may not access or otherwise use the Sites or the Services.</p>

            <p>You may use the Services only if you have reached the age of majority or legal age in your jurisdiction
              (generally 18 or older) and can form legally binding contracts under applicable law. If you are under 18
              or the legal age of majority in your jurisdiction, your educational institution, parent, or guardian must
              agree to these Terms of Use on your behalf.</p>

            <div className="content">
              <h5>1. PROPRIETARY SERVICES FOR REGISTERED USERS.</h5>
              <p>Uniz Portal operates electronic platforms/systems including web and mobile applications that enable
                students, instructors, and administrators of educational institutions to access and use certain online
                products and services offered by Uniz Portal (collectively and including any subsequent versions of such
                platforms/systems and applications, the "Services") through our Sites. </p>
            </div>

            <div className="content">
              <h5>2. USER INFORMATION.</h5>
              <p>During the course of using the Services, we may ask you to share certain personal information in order
                for us to provide you with the Services. Our Privacy Policy describes our policies around collecting,
                using, and maintaining the privacy of your personal information. You acknowledge and agree that between
                you and Uniz Portal, only you are responsible for the accuracy and content of your personal information,
                and you agree to keep it up to date.</p>
            </div>

            <div className="content">
              <h5>3. INTELLECTUAL PROPERTY.</h5>
              <p>During the applicable term of your subscription for Services, subject to the payment of any fees in any
                applicable subscription agreement and subject to compliance with these Terms of Use and any other
                agreement governing your use of the Services, Uniz Portal grants you a limited, non-exclusive and
                non-transferable license to access and use the Services over the Internet via the Site provided for the
                Services for your own internal use for educational purposes only. Uniz Portal is the owner and/or
                authorized user of all trademarks, logos, service marks and trade names (collectively the "Trademarks")
                on our Sites and is the owner or licensee of the content and/or information on our Sites. Your use of
                our Sites does not grant to you a license to any content or materials you may access on our Sites,
                unless otherwise expressly provided in these Terms of Use or the Terms & Conditions. You may not use any
                Trademark displayed on our Sites without our prior written consent or prior written consent of the
                third-party that owns any other trademarks.</p>
            </div>

            <div className="content">
              <h5>4. ACCESS TO UNIZ PORTAL CONTENT.</h5>
              <p>We may provide you with access to content and information related to the Services (the "Uniz Portal
                Content"). Your use of Uniz Portal Content is subject to these Terms of Use as well as any additional
                terms you agree to in connection with such Uniz Portal Content and/or posted on the Site for the Uniz
                Portal Content, including, without limitation, any prohibitions on distribution of the Uniz Portal
                Content to students or third parties. You are responsible for maintaining the confidentiality of your
                password and account and are fully responsible for all activities that occur under your password or
                account. You may not share or distribute to a third party any screen name or password for accessing the
                Services or the Uniz Portal Content. You may access and use materials from the Services and/or the Uniz
                Portal Content only for your own research or study.</p>
            </div>

            <div className="content">
              <h5>5. RESTRICTIONS ON USE.</h5>
              <p>Only you are to access the Services and/or the Uniz Portal Content using your username or password. If
                we have reason to believe you have shared your password information or access to your account or have
                used the Services in violation of the provisions herein, we may suspend or terminate your account and
                refuse any and all current or future use of the Services. You agree that you will not otherwise copy,
                transmit, rent, forward, lend, sell, or modify any materials from the Services and/or the Uniz Portal
                Content or modify or remove any proprietary notices contained therein, or create or enable the creation
                of derivative works based thereon. You may not use any robot, spider, other automatic device or program
                or manual process to access, monitor, copy or reproduce the Services or the Uniz Portal Content. You may
                not engage in systematic retrieval of content from the Services or the Uniz Portal Content to create or
                compile, directly or indirectly, a collection, compilation, database or directory. You may not
                disseminate any portion of the Services or the Uniz Portal Content through electronic means, including
                mail lists or electronic bulletin boards. You may not reverse engineer the Services, nor circumvent,
                disable, or otherwise interfere with any technical and/or other limitations, including without
                limitation security-related features. You acquire no right, title or interest in any Uniz Portal content
                except for the limited access license granted to you, and Uniz Portal reserves all rights in the Uniz
                Portal Content.</p>
            </div>

            <div className="content">
              <h5>6. COMMUNICATIONS TO UNIZ PORTAL.</h5>
              <p>Although we encourage you to e-mail us, you should not e-mail us anything that contains confidential
                information or personally identifiable information. Please refer to the Privacy Notice with regard to
                how we handle your personal information. By e-mailing us or otherwise providing content to us, or
                posting content to the Sites or the Services including but not limited to feedback, questions, comments,
                suggestions, and the like (collectively, "User Content"), you agree to be bound by our Privacy Policy
                and we may use any non-personally identifiable ideas, concepts, know-how, or techniques contained in
                your communications for any purpose whatsoever, including but not limited to, the development,
                production and marketing of products and services that incorporate such information. You own the rights
                to anything you post to the Sites or the Services, including text and photographs. You do, however,
                grant us an irrevocable, non-exclusive, worldwide, perpetual, royalty-free license to use, modify, copy,
                distribute, publish, perform, sublicense, and create derivative works from all non-personally
                identifiable submissions you provide to us, in any media now known or hereafter devised.</p>

              <p>The Site or Services may permit you to upload third-party content for use with the Site or Services
                ("Service Uploads"). You represent and warrant that prior to providing any upload you will have all
                necessary rights to provide the Service Uploads, and that your Service Uploads will not infringe any
                third-party rights, including any intellectual property or proprietary rights. You grant Uniz Portal the
                right to make your Service Uploads available to licensed end users in the same manner and to the same
                extent as the Uniz Portal Content provided in the applicable Service. Additional terms and options for
                Service Uploads may be presented through the applicable upload interface available on the Site offering
                the Service which shall be in addition to, and not instead of, these Terms of Use.</p>
            </div>

            <div className="content">
              <h5>7. COMMUNITY GUIDELINES.</h5>
              <p>In using the Sites or Services, you agree you will not: (a) submit material that is unlawful, obscene,
                lewd, defamatory, libelous, threatening, pornographic, harassing, hateful, racially or ethnically
                offensive, excessively violent, or encourages conduct that would be considered a criminal offense, give
                rise to civil liability, violate any law, or is otherwise inappropriate or objectionable; (b) post
                advertisements or solicitations of business; (c) impersonate another person; (d) submit material that is
                copyrighted, protected by trade secret or otherwise subject to third party intellectual property or
                proprietary rights, including privacy and publicity rights, unless you are the owner of such rights or
                have permission from their rightful owner to post the material and to grant Uniz Portal all of the
                license rights granted herein; (e) submit material that is unsuitable for minors in any country; or (f)
                submit material that is malicious or destructive in nature, or contain a virus or other computer
                programming routine or engine that is intended to damage or otherwise interfere with any system, data,
                or information. Uniz Portal does not and cannot review all User Content or Service Uploads and is not in
                any manner responsible for such content. Uniz Portal reserves the right, but has no obligation, to
                refuse to post, block or remove communications or materials for any reason in its sole discretion. Uniz
                Portal may terminate your access to our Sites and/or the Services to prevent further posting or
                distribution of any communications or materials, and Uniz Portal may, but is not obligated to, report
                any of your communications or materials to your educational institution.</p>
            </div>

            <div className="content">
              <h5>8. NO WARRANTIES.</h5>
              <p>WE AND OUR LICENSORS AND SERVICE PROVIDERS MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE CONTENTS OF
                OUR SITES, UNIZ PORTAL CONTENT OR THE SERVICES. WE SHALL NOT BE SUBJECT TO LIABILITY FOR ANY DELAYS OR
                INTERRUPTIONS OF OUR SITES OR THE SERVICES FROM WHATEVER CAUSE. YOU AGREE THAT YOU USE THE SITES, THE
                UNIZ PORTAL CONTENT, AND THE SERVICES AT YOUR OWN RISK. OUR SITES, UNIZ PORTAL CONTENT AND THE SERVICES
                MAY CONTAIN TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS OR OMISSIONS. UNIZ PORTAL IS NOT RESPONSIBLE
                FOR ANY SUCH TYPOGRAPHICAL OR TECHNICAL ERRORS. UNIZ PORTAL RESERVES THE RIGHT TO MAKE CHANGES,
                CORRECTIONS, AND/OR IMPROVEMENTS TO OUR SITES, UNIZ PORTAL CONTENT AND THE SERVICES AT ANY TIME WITHOUT
                NOTICE.</p>
            </div>

            <div className="content">
              <h5>9. EXTERNAL WEBSITES.</h5>
              <p>Our Sites may contain links to third-party applications or websites ("External Websites"). These links
                are provided only as a convenience to you and not as an endorsement by us of the content on such
                External Websites. When you access an External Website, Uniz Portal has no control over its content,
                applications, or services. We do not make any representations regarding the accuracy, quality, or
                accessibility of any External Website or its content or materials. Uniz Portal disclaims all liability
                for any errors, omissions, violation of third-party rights or illegal conduct arising from such External
                Websites.</p>
            </div>

            <div className="content">
              <h5>10. INDEMNIFICATION.</h5>
              <p>You agree to defend, indemnify, and hold harmless Uniz Portal and our officers, directors, employees,
                successors, licensors, service providers, and assignees from and against any claims, actions, demands,
                liabilities, losses, damages, costs and expenses including, without limitation, reasonable legal and
                accounting fees, arising from or relating to your breach of this Agreement or your access to, use, or
                misuse of the Uniz Portal Content, our Sites, or the Services. We shall provide notice to you of any
                such claim, suit, or proceeding and shall assist you, at your expense, in defending any such claim,
                suit, or proceeding. We reserve the right to assume the exclusive defense and control of any matter that
                is subject to indemnification under this section. In such case, you agree to cooperate with any
                reasonable requests assisting our defense of such matter.</p>
            </div>

            <div className="content">
              <h5>11. LIABILITY.</h5>
              <p>IN NO EVENT WILL UNIZ PORTAL OR ITS LICENSORS OR SERVICE PROVIDERS BE LIABLE FOR ANY CONSEQUENTIAL,
                INCIDENTAL, SPECIAL, INDIRECT, PUNITIVE OR EXEMPLARY DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO,
                LOST TIME, LOST MONEY, LOST DATA, LOST PROFITS OR GOODWILL, WHETHER IN CONTRACT, TORT, STRICT LIABILITY,
                NEGLIGENCE OR OTHERWISE, AND WHETHER OR NOT SUCH DAMAGES ARE FORESEEN OR UNFORESEEN WITH RESPECT TO ANY
                USE OF THE UNIZ PORTAL CONTENT, SITES, THE SERVICES, OR OTHERWISE ARISING FROM OR RELATING TO THESE
                TERMS OF USE AND IN NO EVENT SHALL THE LIABILITY OF UNIZ PORTAL OR ITS LICENSORS FOR WHATEVER CAUSE
                EXCEED THE FEES PAID BY YOU IN CONNECTION WITH YOUR USE OF THE UNIZ PORTAL CONTENT, THE SITES, OR THE
                SERVICES, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT THE FOREGOING EXCLUSION OF
                LIABILITY IS NOT PERMITTED UNDER APPLICABLE LAW, UNIZ PORTAL’S LIABILITY IN SUCH CASE WILL BE LIMITED TO
                THE GREATEST EXTENT PERMITTED BY LAW.</p>
            </div>

            <div className="content">
              <h5>12. INFRINGEMENT NOTIFICATION.</h5>
              <p>We respect the intellectual property rights of others, and require that the people who use our Sites do
                the same. If you believe that your work has been copied in a way that constitutes copyright
                infringement, please send notifications of the claimed infringement to: Legal Department, Uniz Portal
                Private Limited, Flat Number 814, 8th Floor, Chiranjiv Tower, Building No. 43, Nehru Place, New Delhi,
                110019 IN. Notices of the claimed infringement
                should include the following information: (a) your address, telephone number, and email address; (b) a
                description of the copyrighted work that you claim has been infringed; (c) a description of where the
                alleged infringing material is located, with a link if possible; (d) a statement by you that you have a
                good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;
                (e) an electronic or physical signature of the person authorized to act on behalf of the owner of the
                copyright interest; (f) a statement by you, made under penalty of perjury, that the above information in
                your notice is accurate and that you are the copyright owner or authorized to act on the copyright
                owner's behalf.</p>
            </div>

            <div className="content">
              <h5>13. COMPLIANCE WITH APPLICABLE LAWS.</h5>
              <p>We control and operate our Sites from our offices in India and Canada. We do not represent that
                materials on our Sites are appropriate or available for use in other locations. Persons who choose to
                access our Sites from other locations do so on their own initiative, and are responsible for compliance
                with local laws, if and to the extent local laws are applicable. All parties to these terms and
                conditions waive their respective rights to a trial by jury.</p>
            </div>

            <div className="content">
              <h5>14. TERMINATION OF THE AGREEMENT.</h5>
              <p>We reserve the right in our sole discretion, to restrict, suspend, or terminate your use or access to
                any of the Services, Uniz Portal Content, and/or our Sites and/or discontinue any portion, feature, or
                content of the Services, Uniz Portal Content, our Sites, or this Agreement at any time and for any
                reason without prior notice or liability. If you violate these Terms of Use Uniz Portal may, but is not
                obligated to, report any such violation of these Terms of Use to your educational institution or any
                other forum we may deem appropriate. When reasonably feasible, we will make reasonable efforts to
                provide notice of significant changes to the Services to end users with active licenses to use the
                Services.</p>
            </div>

            <div className="content">
              <h5>15. MISCELLANEOUS.</h5>
              <p>If any provision of this Agreement is found to be invalid by any court having competent jurisdiction or
                terminated in accordance with the "Termination of the Agreement" provision above, the invalidity or
                termination of such provision shall not affect the validity of the following provisions of this
                Agreement, which shall remain in full force and effect: "Intellectual Property," "Communications to Uniz
                Portal," "No Warranties," "Indemnification," "Liability," "Compliance With Applicable Laws,"
                "Termination of the Agreement," and "Miscellaneous." Our failure to act on or enforce any provision of
                this Agreement shall not be construed as a waiver of that provision or any other provision in this
                Agreement. No waiver shall be effective against us unless made in writing, and no such waiver shall be
                construed as a waiver in any other or subsequent instance. Except as expressly agreed by us and you in
                writing, this Agreement constitutes the entire Agreement between you and us with respect to the subject
                matter, and supersedes all previous or contemporaneous agreements, whether written or oral, between the
                parties with respect to the subject matter. The section headings are provided merely for convenience and
                shall not be given any legal import. This Agreement will inure to the benefit of our successors,
                assigns, licensees, and sub-licensees.</p>
            </div>

          </div>

        </div>
      </section>

      <Opportunity/>
    </React.Fragment>
  )
}

export default TermsUse
