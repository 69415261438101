import { customAxios as axios, getToken } from '../../../request'
import { getAgentMeetingUrl, updateAgentMeetingUrl } from '../apis/api'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { notification } from 'antd'
import { approveAgentMeetingUrl } from '../../DirectColleges/apis/cornerStone'

export const getAllMeetingsFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getAgentMeetingUrl(), config)
  return data.data
}

export const updateMeetingStatus = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateAgentMeetingUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const approveAgentMeetingFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(approveAgentMeetingUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message
    })
  } else {
    notification.error({
      message: data.message || 'Error '
    })
  }
  return data
}