import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Icon, Col, Row, notification, Tooltip
} from 'antd'
import _ from 'lodash'
import moment from 'moment'

import {hideLoader, hidePageLoad} from '../../modules/actions'
import {connect} from 'react-redux'
import {listAllBranch} from '../branch/actions/branchAction'
import {BooleanOptions, departmentList, InputBox, UniversityOptions} from '../../components/_utils/appUtils'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import {updateUser, getUser, listAllUsers} from '../users/actions/user'
import {LoadAllCountry, LoadState, LoadCity} from '../../components/_utils/countryUtil'

const FormItem = Form.Item
const {TextArea} = Input
const Option = Select.Option
const Search = Input.Search

@Form.create()
class EditAgentProfile extends PureComponent {

    events = {
        chooseCountry: (name) => {
            let countryName = _.find(this.state.allCountries, (item) => {
                return item.name == name
            })
            if (countryName) {
                this.setState({
                    countryName: countryName.name ? countryName.name : '',
                    stateName: '',
                    cityName: '',
                    allStates: countryName.id ? LoadState(countryName.id) : []
                })
            }
        },
        chooseState: (name) => {
            let stateName = _.find(this.state.allStates, (item) => {
                return item.name == name
            })
            if (stateName) {
                this.setState({
                    stateName: stateName.name ? stateName.name : '',
                    cityName: '',
                    allCities: stateName.id ? LoadCity(stateName.id) : []
                })
            }
        },
        chooseCity: (name) => {
            this.setState({
                cityName: name ? name : ''
            })
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            allBranch: [],
            logo: {},
            uploadKey: moment(),
            allCountries: LoadAllCountry(),
            allStates: [],
            allCities: [],
            countryName: '',
            stateName: '',
            cityName: '',
            email: '',
            certificate: {},
            newCertificate: {}
        }
    }

    async getUserData() {
        let {pathname, dispatch, form: {setFieldsValue}} = this.props
        let userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        if (userData && userData._id) {
            let resp = await dispatch(getUser(userData._id))
            this.setState({email: resp.email})
            if (resp) {
                this.setState({
                    userId: resp._id,
                    oldLogo: resp.logo,
                    address: resp.address,
                    certificate: resp.certificate
                })
                setFieldsValue({
                    name: resp.name,
                    mobile: resp.mobile,
                    userType: resp.userType,
                    address: resp.address,
                    countryName: resp.countryName,
                    companyName: resp.companyName,
                    stateName: resp.stateName,
                    cityName: resp.cityName,
                    // accountName: resp.accountName || undefined,
                    // accountNumber: resp.accountNumber || undefined,
                    // bankName: resp.bankName || undefined,
                    // IFSC: resp.IFSC || undefined,
                    // confirmAccNumber: resp.accountNumber || undefined,
                    pincode: resp.pincode || undefined,
                    alternateEmails: resp.alternateEmails || undefined,
                    alternateMobileNo: resp.alternateMobileNo || undefined
                    // enableChanelPartner: resp.enableChanelPartner ? resp.enableChanelPartner : false,
                    // showAustraliaUniversity: resp.showAustraliaUniversity ? resp.showAustraliaUniversity : false
                })

                // if((resp.IFSC !== '' || undefined) || (resp.accountNumber !== '' || undefined) || (resp.accountName !== '' || undefined) || (resp.confirmAccNumber !== '' || undefined) ){
                //   this.setState({ accountFieldsRequired: true})
                // }

                if (resp.countryName) {
                    this.events.chooseCountry(resp.countryName)
                }
                if (resp.stateName) {
                    this.events.chooseState(resp.stateName)
                }

            }
        }
    }

    validateForm(data) {
        if (data.accountNumber !== '' || undefined) {
            if (data.accountNumber !== data.confirmAccNumber) {
                notification.warning({message: 'Please check your Account Number!'})
                return {checkError: true}
            }
        }
        return {checkError: false}
        // if()
    }

    handleSubmit = e => {
        const {dispatch, form, onClose} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                // let agentId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
                let {checkError} = this.validateForm(valData)
                if (checkError === false) {
                    let obj = {
                        userType: 'agent',
                        /*accountNumber: valData.accountNumber,
                        accountName: valData.accountName,*/
                        // account: valData.accountName,
                        address: valData.address,
                        countryName: valData.countryName,
                        stateName: valData.stateName,
                        cityName: valData.cityName,
                        // IFSC: valData.IFSC,
                        // bankName: valData.bankName,
                        pincode: valData.pincode,
                        alternateEmails: valData.alternateEmails,
                        alternateMobileNo: valData.alternateMobileNo,
                        email: this.state.email
                    }
                    let fd = new FormData()
                    fd.append('obj', JSON.stringify(obj))
                    if (this.state.logo && this.state.logo.name) {
                        fd.append('logo', this.state.logo)
                    }
                    if (this.state.newCertificate && this.state.newCertificate.name) {
                        fd.append('certificate', this.state.newCertificate)
                    }
                    let {data} = await dispatch(updateUser(fd, this.state.userId))
                    if (data && !data.error) {
                        let userData = JSON.parse(localStorage.getItem('user'))
                        userData.logo = data.logo
                        localStorage.setItem('user', JSON.stringify(userData))
                        this.setState({
                            uploadKey: moment()
                        })
                    }
                } else {
                    return
                }
            }
        })
    }

    componentDidMount() {
        // this.loadBranch()
        this.getUserData()
        let {dispatch} = this.props
        dispatch(hideLoader())
        dispatch(hidePageLoad())
        dispatch({type: 'USER_HIDELOADER'})
    }

    async loadBranch() {
        let {dispatch} = this.props
        let {data} = await listAllBranch({})(dispatch)
        this.setState({
            allBranch: data
        })
    }


    render() {

        const {
            form: {getFieldDecorator, getFieldValue, setFieldsValue}
        } = this.props

        const {editorState, allBranch, oldLogo} = this.state

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }


        let inputTypes = {
            fields: [
                {
                    label: 'Contact Person Name',
                    key: 'name',
                    disabled: true,
                    required: true
                },
                {
                    label: 'Mobile no',
                    key: 'mobile',
                    disabled: true,
                    required: true
                },
                {
                    key: 'companyName',
                    label: 'Company Name',
                    disabled: true,
                    // hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent'),
                    required: true
                },
                {
                    key: 'countryName',
                    label: 'Country',
                    type: 'select',
                    showSearch: true,
                    required: true,
                    options: this.state.allCountries,
                    keyAccessor: x => x.name,
                    valueAccessor: x => `${x.name}`,
                    onChange: x => {
                        setFieldsValue({
                            countryName: x,
                            stateName: undefined,
                            cityName: undefined
                        })
                        this.events.chooseCountry(x)
                    }
                },
                {
                    key: 'stateName',
                    label: 'Province/State',
                    type: 'select',
                    showSearch: true,
                    required: true,
                    options: this.state.allStates,
                    keyAccessor: x => x.name,
                    valueAccessor: x => `${x.name}`,
                    onChange: x => {
                        setFieldsValue({
                            stateName: x,
                            cityName: ''
                        })
                        this.events.chooseState(x)
                    }
                },
                {
                    key: 'cityName',
                    label: 'City/Town',
                    type: 'select',
                    showSearch: true,
                    required: true,
                    hidden: getFieldValue('countryName') !== 'India',
                    options: this.state.allCities,
                    keyAccessor: x => x.name,
                    valueAccessor: x => `${x.name}`,
                    onChange: x => {
                        setFieldsValue({
                            cityName: x
                        })
                        this.events.chooseCity(x)
                    }
                },
                {
                    key: 'cityName',
                    label: 'City/Town',
                    hidden: getFieldValue('countryName') == 'India',
                    onChange: (e) => {
                        let {value} = e.target
                        this.props.form.setFieldsValue({
                            cityName: value
                        })
                        this.events.chooseCity(value)
                    }
                },
                {
                    label: 'Postal code',
                    key: 'pincode',
                    // type: 'number',
                    required: true
                },
                {
                    key: 'certificate',
                    span: 16,
                    customField: (
                        this.state.certificate && this.state.certificate.url ?
                            <div>
                                <InputBox title={'Certificate'} className={'logoCls rowFlex'} style={{lineHeight: 20}}>
                                    <a className={'linkBtn'} target={'_blank'}
                                       href={this.state.certificate.url}
                                       style={{
                                           background: 'white',
                                           border: '1px solid black',
                                           width: '-webkit-fill-available'
                                       }}>
                                        {this.state.certificate.name} <Icon type={'eye'} style={{marginLeft: 10}}/>
                                    </a></InputBox>
                            </div>
                            :
                            <InputBox title={'Upload Registration Certificate'} className={'logoCls rowFlex'}>
                                <Input type={'file'} className={'form-control'}
                                       key={this.state.uploadKey} name={'logo'} id={'logo'}
                                       onChange={(e) => {
                                           this.setState({
                                               newCertificate: e.target.files && e.target.files.length ? e.target.files[0] : null
                                           })
                                       }}/></InputBox>
                    )
                },
                {
                    key: 'address',
                    span: 24,
                    customField: (
                        <InputBox title={'Address'}>
                            <TextArea rows={4} className={'form-control'} value={this.state.address} onChange={(e) => {
                                this.setState({address: e.target.value})
                            }}>
                            </TextArea>
                        </InputBox>
                    )
                },
                {
                    key: 'alternateEmails',
                    label: (
                        <span>
              Alternate Emails <small style={{color: 'black', fontWeight: 'bold'}}>( Note : Use comma for multiple emails )</small>
            </span>
                    ),
                    placeholder: 'Alternate Emails',
                    span: 24
                    // hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent')
                },
                {
                    key: 'alternateMobileNo',
                    label: (
                        <span>
              Staff Member Mobile No <small style={{color: 'black', fontWeight: 'bold'}}>( Note : Use comma for multiple mobiles no )</small>
            </span>
                    ),
                    placeholder: 'Staff Member Mobile No',
                    span: 24
                    // hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent')
                },
                /* {
                     label: 'Account Name',
                     key: 'accountName',
                     required: this.state.accountFieldsRequired
                 },
                 {
                     label: 'Account Number',
                     key: 'accountNumber',
                     // type: 'number',
                     required: this.state.accountFieldsRequired
                 },
                 {
                     label: 'Confirm Account Number',
                     key: 'confirmAccNumber',
                     // type: 'number',
                     required: this.state.accountFieldsRequired
                 },
                 {
                     label: 'Bank Name',
                     key: 'bankName',
                     required: this.state.accountFieldsRequired
                 },
                 {
                     label: 'Bank IFSC',
                     key: 'IFSC',
                     required: this.state.accountFieldsRequired
                 },*/
                {
                    key: 'logo',
                    span: 24,
                    customField: (
                        <InputBox title={'Logo'} className={'logoCls rowFlex'}>
                            <Input type={'file'} className={'form-control'}
                                   key={this.state.uploadKey} name={'logo'} id={'logo'}
                                   onChange={(e) => {
                                       this.setState({
                                           logo: e.target.files && e.target.files.length ? e.target.files[0] : null
                                       })
                                   }}/>
                            {oldLogo && oldLogo.url ?
                                <Tooltip title={oldLogo.name}> <a className={'linkBtn'} target={'_blank'}
                                                                  href={oldLogo.url}>
                                    <Icon type={'eye'}></Icon>
                                </a> </Tooltip> : ''}
                        </InputBox>
                    )
                }
            ]
        }

        return (
            <div className='form-box'>
                <div className='card unizportal'>
                    <h4>Profile Details</h4>
                    <Form onSubmit={this.handleSubmit} className={'vertical-form'}>
                        <Row gutter={16}>
                            {inputTypes.fields.map((item, key) => {
                                return (
                                    <React.Fragment>
                                        {item.customField ? <Col span={item.span ? item.span : 8} key={key}>
                                                {item.customField}
                                            </Col> :
                                            !item.hidden && <Col span={item.span ? item.span : 8} key={key}>
                                                <GetEachFormFields
                                                    item={item}
                                                    getFieldDecorator={getFieldDecorator}
                                                    formItemLayout={formItemLayout}/>
                                            </Col>}
                                    </React.Fragment>
                                )
                            })}
                        </Row>
                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={this.props.loading} className={'btn'}>
                                Update
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>

        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    pathname: router.location.pathname,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditAgentProfile)
