import React, { useEffect, useState } from 'react'
import {Drawer, Button, Form, Row, Col, notification, DatePicker} from 'antd'
import { useDispatch } from 'react-redux'
import { reuploadScreenshotsFxn } from '../actions/cornerStone' // Adjust import path as per your project structure
import { FileInput } from '../../../components/_utils/appUtils' // Ensure correct path to FileInput component
import TextArea from 'antd/es/input/TextArea'
import moment from "moment";

const ReUploadScreenshots = (props) => {
  const {
    form: { getFieldDecorator, validateFieldsAndScroll, resetFields },
    visible, onClose, onSubmit, details, screenshotId, screenshotObjId, backDate
  } = props
  const dispatch = useDispatch()
  const [screenshotFile, setScreenshotFile] = useState(null)
  let [state, setState] = useState({
    screenshots: {},
    details: '',
    backDate: undefined
  })

  useEffect(() => {
    events.setDefaultData()
  }, [props])

  const events = {
    _updateState: (data) => {
      setState((prevState) => {
        return {
          ...prevState,
          ...data
        }
      })
    },
    handleFileChange: (e) => {
      const { files } = e.target
      if (files && files.length) {
        events._updateState({ screenshots: files[0] })
      }
    },
    handleDetailsChange: (e, index) => {
      events._updateState({ details: e.target.value })
    },
    handleBackDateChange: (date) => {
      events._updateState({ backDate: date });
    },
    setDefaultData: () => {
      if (details) {
        events._updateState({ details: details, backDate: backDate });
      }
    }
  }


  const handleReuploadSubmit = async (e) => {
    e.preventDefault()
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const { details, backDate } = values

        if (!state.screenshots || (state.screenshots && !state.screenshots.name)) {
          notification.error({
            message: 'Error',
            description: 'Please upload a screenshot file.'
          })
          return
        }

        const formData = new FormData()
        formData.append('screenshot', state.screenshots)
        let obj = {
          screenshotId, screenshotObjId, details, backDate
        }
        formData.append('obj', JSON.stringify(obj)) // Append selected screenshot _id

        const resp = await dispatch(reuploadScreenshotsFxn(formData))

        if (resp && resp.success) {
          setScreenshotFile(null)
          resetFields()
          onSubmit()
        }
      }
    })
  }

  return (
    <Drawer
      visible={visible}
      width={'50%'}
      placement='right'
      onClose={onClose}
      title={'Re-Upload Screenshots'}
    >
      <div className='form-box'>
        <div className='card unizportal'>
          <Form style={{ marginTop: 8, textAlign: 'left' }} onSubmit={handleReuploadSubmit}>
            <Row gutter={24}>
              <Col span={24} className={'mb10'}>
                <FileInput
                  name='screenshot'
                  label='Upload Screenshot'
                  chooseDocument={events.handleFileChange}
                />
              </Col>
              <Col span={24} className={'mb10'}>
                <Form.Item label='Details'>
                  {getFieldDecorator('details', {
                    rules: [{ required: true, message: 'Please enter details' }],
                    initialValue: state.details
                  })(
                    <TextArea
                      style={{ width: '100%', minHeight: '100px' }}
                      value={state.details}
                      placeholder='Enter details'
                      onChange={(e) => events.handleDetailsChange(e)}
                      className={'form-control'}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={24} className={'mb10'}>
                <Form.Item label='Choose Date'>
                  {getFieldDecorator('backDate', {
                    rules: [{ required: true, message: 'Please choose date!' }],
                    initialValue: state.backDate ? moment(state.backDate) : null
                  })(
                      <DatePicker
                          placeholder='Choose Back Date'
                          className={'form-control'}
                          onChange={events.handleBackDateChange}
                      />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <button className='btn' type='submit'>Update
              </button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Drawer>
  )
}

export default Form.create()(ReUploadScreenshots)
