import React, {useState, useEffect} from 'react'
import GetAllFormFields from '../../components/_utils/formUtils'
import {
    qualificationOptions, boardList
} from '../../components/_utils/appUtils'
import {Checkbox, Col, Form, Input, Row, Select, Slider} from 'antd'
import _ from 'lodash'
import {connect} from 'react-redux'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import moment from 'moment'
import {Debounce} from '../../components/_utils/debounce'

import {
    getEvaluateUniversity,
    getEvaluateState,
    getEvaluateCourseLevel,
    getEvaluateIntakes,
    getEvaluateCountry
} from './action'

let indexGroup = {
    5: 0,
    6: 0,
    7: 0,
    8: 1,
    9: 1,
    10: 1,
    11: 2,
    12: 2,
    1: 2,
    2: 3,
    3: 3,
    4: 3
}
const CheckboxGroup = Checkbox.Group
const {Option} = Select

const formItemLayout = {
    labelCol: {
        xs: {span: 0},
        sm: {span: 0},
        md: {span: 0}
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 24},
        md: {span: 24}
    }
}
let fieldArr = [
    'countryId', 'universityId', 'courseLevel', 'stateId', 'education', 'percentage', 'examType', 'overall',
    'reading', 'listening', 'speaking', 'speaking', 'writing', 'intakes', 'entryRequirements', 'board', 'boardPercentage'
]
const englishRequirementList = [
    'I don\'t have this',
    'IELTS',
    'PTE',
    'I will provide this later'
]

class EvaluateFilters extends React.Component {

    constructor(props) {
        super()
        this.state = {
            countryList: [],
            stateList: [],
            universityList: [],
            intakeList: [],
            programLevelList: [],
            tuitionFee: [0, 100],
            tuitionFeeMin: 0,
            tuitionFeeMax: 100,
            applicationFee: [0, 500],
            educationList: [],
            englishRequirmentList: [],
            intakes: [],
            engRequirementMavValue: {},
            educationMavValue: '',
            currency: '$'

        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps && nextProps.EvaluateReducers && nextProps.EvaluateReducers.reloadFilters) {
            this.setState({
                courseName: nextProps.EvaluateReducers.courseName,
                universityName: nextProps.EvaluateReducers.universityName
            }, () => {
                this.groupOfFunctions()
            })
            this.props.dispatch({type: 'STOP_LOAD_FILTER'})
        }
    }

    getCommonFilters = () => {
        const {form: {getFieldValue}} = this.props
        let {tuitionFee, applicationFee, courseName, universityName} = this.state

        let filters = {
            results: 1000
        }
        _.each(fieldArr, (item) => {
            filters[item] = getFieldValue(item)
        })

        filters.courseName = courseName
        filters.universityName = universityName
        filters.tuitionFee = tuitionFee
        filters.applicationFee = applicationFee
        return filters
    }

    events = {
        loadCountry: async () => {
            let params = {
                ...this.getCommonFilters()
            }
            let data = await this.props.dispatch(getEvaluateCountry(params))
            this.setState({countryList: data})
        },
        loadStates: async () => {
            let params = {
                ...this.getCommonFilters()
            }
            let data = await this.props.dispatch(getEvaluateState(params))
            this.setState({stateList: data})
        },
        loadUniversity: async () => {
            let params = {
                ...this.getCommonFilters()
            }
            let data = await this.props.dispatch(getEvaluateUniversity(params))
            this.setState({universityList: data})
        },
        loadIntakes: async () => {
            let params = {
                ...this.getCommonFilters()
            }
            let data = await this.props.dispatch(getEvaluateIntakes(params))
            this.setState({
                intakeList: data
            })
        },
        loadCourseLevel: async () => {
            let params = {
                ...this.getCommonFilters()
            }
            let data = await this.props.dispatch(getEvaluateCourseLevel(params))
            this.setState({programLevelList: data})
        },
        applyFilters: () => {
            let {educationMavValue, engRequirementMavValue} = this.state
            let {form: {setFieldsValue}} = this.props
            let params = {
                ...this.getCommonFilters()
            }
            if (params.education) {
                if (!params.percentage) {
                    return alert(`Please enter percentage`)
                }
                if (!params.entryRequirements) {
                    return alert(`Please enter passing year`)
                }
            }
            if (params.examType) {
                if (params.examType == 'IELTS' || params.examType == 'PTE') {
                    if (!params.overall) {
                        return alert(`Please enter overall score.`)
                    }
                    if (!params.writing) {
                        return alert(`Please enter writing score.`)
                    }
                    if (!params.speaking) {
                        return alert(`Please enter speaking score.`)
                    }
                    if (!params.listening) {
                        return alert(`Please enter listening score.`)
                    }
                    if (!params.reading) {
                        return alert(`Please enter reading score.`)
                    }
                }
                if (params.examType == 'I don\'t have this') {
                    if (!params.board) {
                        return alert(`Please choose board.`)
                    }
                    if (!params.boardPercentage) {
                        return alert(`Please english marks.`)
                    }
                }
            }
            this.props.filterCallback()
        },
        clearFilters: () => {
            let {events} = this
            const {form: {setFieldsValue}} = this.props
            let filters = {}
            _.each(fieldArr, (item) => {
                filters[item] = null
            })
            filters.intakes = undefined
            setFieldsValue(filters, () => {
                events.loadCountry()
                events.loadStates()
                events.loadUniversity()
                events.loadIntakes()
                events.loadCourseLevel()
                this.props.events.clearFilterCallback()
            })
        },
        groupOfEligibility: () => {
            let {events} = this
            let {form: {setFieldsValue}} = this.props
            let params = {
                ...this.getCommonFilters()
            }
            if (params.education) {
                if (!params.percentage) {
                    return alert(`Please enter percentage`)
                }
                if (!params.entryRequirements) {
                    return alert(`Please enter passing year`)
                }
            }
            if (params.examType) {
                if (params.examType == 'IELTS' || params.examType == 'PTE') {
                    if (!params.overall) {
                        return alert(`Please enter overall score.`)
                    }
                    if (!params.writing) {
                        return alert(`Please enter writing score.`)
                    }
                    if (!params.speaking) {
                        return alert(`Please enter speaking score.`)
                    }
                    if (!params.listening) {
                        return alert(`Please enter listening score.`)
                    }
                    if (!params.reading) {
                        return alert(`Please enter reading score.`)
                    }
                } else if (params.examType == 'I don\'t have this') {
                    if (!params.board) {
                        return alert(`Please choose board.`)
                    }
                    if (!params.boardPercentage) {
                        return alert(`Please english marks.`)
                    }
                }
            }
            this.props.events.setFilter(params)
            events.loadCountry()
            events.loadStates()
            events.loadUniversity()
            events.loadIntakes()
            events.loadCourseLevel()
            setTimeout(() => {
                events.applyFilters()
            }, 500)
        }

    }

    countryCurrencySet = (countryId) => {
        let {countryList} = this.state
        let findCountry = _.find(countryList, (item) => {
            return item._id == countryId
        })
        if (findCountry && findCountry.countryName && findCountry.countryName.toLowerCase() == 'uk') {
            this.setState({currency: '£'})
        } else {
            this.setState({currency: '$'})
        }
    }

    groupOfFunctions = (type = '') => {
        let {events} = this
        if (type !== 'country') {
            events.loadCountry()
        }
        if (type !== 'university') {
            events.loadUniversity()
        }
        if (type !== 'state') {
            events.loadStates()
        }
        if (type !== 'courseLevel') {
            events.loadCourseLevel()
        }
        if (type !== 'intakes') {
            events.loadIntakes()
        }

        let params = {
            ...this.getCommonFilters()
        }
        this.props.events.setFilter(params)
        if (type == 'country' || type == 'state' || type == 'university' || type == 'courseLevel' || type == 'intakes') {
            setTimeout(() => {
                events.applyFilters()
            }, 500)
        }
    }

    componentDidMount() {
        let {events} = this
        events.loadCountry()
        events.loadStates()
        events.loadUniversity()
        events.loadIntakes()
        events.loadCourseLevel()
    }

    checkEngRequiredCond = () => {
        const {form: {getFieldValue}} = this.props
        return getFieldValue('examType') && (getFieldValue('examType') == 'PTE' || getFieldValue('examType') == 'IELTS')
    }
    hideEngRequiredCond = () => {
        const {form: {getFieldValue}} = this.props
        return !getFieldValue('examType') || (getFieldValue('examType') && (getFieldValue('examType') == 'PTE' || getFieldValue('examType') == 'IELTS'))
    }

    render() {
        const {form: {getFieldDecorator, setFieldsValue, getFieldValue, resetFields}} = this.props
        let {
            countryList, stateList, universityList, intakeList, programLevelList

        } = this.state
        let {events, props, checkEngRequiredCond, hideEngRequiredCond} = this


        let programFilter = {
            fields: [
                {
                    key: 'countryId',
                    label: 'Select Country',
                    type: 'select',
                    options: countryList,
                    allowClear: true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.countryName}`,
                    onChange: x => {
                        setFieldsValue({
                            countryId: x
                        }, () => {
                            this.groupOfFunctions('country')
                            this.countryCurrencySet(x)
                        })

                    }
                },
                {
                    key: 'stateId',
                    label: 'Province/State',
                    type: 'select',
                    showSearch: true,
                    options: stateList,
                    allowClear: true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.stateName}`,
                    onChange: x => {
                        setFieldsValue({
                            stateId: x
                        }, () => {
                            this.groupOfFunctions('state')
                        })
                    }
                },
                {
                    key: 'universityId',
                    label: 'University',
                    type: 'select',
                    showSearch: true,
                    options: universityList,
                    allowClear: true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.universityName}`,
                    onChange: x => {
                        setFieldsValue({
                            universityId: x
                        }, () => {
                            this.groupOfFunctions('university')
                        })
                    }
                },
                {
                    key: 'intakes',
                    label: 'Intakes',
                    type: 'select',
                    showSearch: true,
                    options: intakeList,
                    allowClear: true,
                    mode: 'multiple',
                    valueAccessor: x => `${x.month}-${x.year}`,
                    keyAccessor: x => `${x.month}-${x.year}`,
                    onChange: x => {
                        setFieldsValue({
                            intakes: x
                        }, () => {
                            this.groupOfFunctions('intakes')
                        })
                    }

                },

                {
                    key: 'courseLevel',
                    label: 'Program Level',
                    type: 'select',
                    showSearch: true,
                    options: programLevelList,
                    allowClear: true,
                    keyAccessor: x => x,
                    valueAccessor: x => x,
                    onChange: x => {
                        setFieldsValue({
                            courseLevel: x
                        }, () => {
                            this.groupOfFunctions('courseLevel')
                        })
                    }
                }
            ]
        }

        let eligibilityFilter = {
            fields: [
                {
                    key: 'education',
                    label: 'Education',
                    type: 'select',
                    options: qualificationOptions,
                    allowClear: true,
                    span: 24,
                    onChange: x => {
                        setFieldsValue({
                            education: x,
                            percentage: '',
                            entryRequirements: ''
                        })
                    }
                },
                {
                    key: 'percentage',
                    label: 'Percentage',
                    type: 'number',
                    allowClear: true,
                    span: 12,
                    disabled: !getFieldValue('education'),
                    onChange: x => {
                        setFieldsValue({
                            percentage: x
                        })

                    }
                },
                {
                    key: 'entryRequirements',
                    label: 'Passing Year',
                    type: 'number',
                    allowClear: true,
                    span: 12,
                    disabled: !getFieldValue('education'),
                    onChange: x => {
                        setFieldsValue({
                            entryRequirements: x
                        })
                    }
                },
                {
                    key: 'examType',
                    label: 'English Requirement',
                    type: 'select',
                    span: 24,
                    options: englishRequirementList,
                    allowClear: true,
                    onChange: x => {
                        setFieldsValue({
                            examType: x,
                            overall: '',
                            writing: '',
                            speaking: '',
                            listening: '',
                            reading: '',
                            board: '',
                            boardPercentage: null
                        })
                    }
                },
                {
                    key: 'overall',
                    label: 'Overall',
                    type: 'number',
                    allowClear: true,
                    span: 24,
                    disabled: !checkEngRequiredCond(),
                    hidden: !hideEngRequiredCond(),
                    onChange: x => {
                        setFieldsValue({
                            overall: x
                        })
                    }
                },
                {
                    key: 'reading',
                    label: 'Reading',
                    type: 'number',
                    allowClear: true,
                    span: 6,
                    disabled: !checkEngRequiredCond(),
                    hidden: !hideEngRequiredCond(),
                    onChange: x => {
                        setFieldsValue({
                            reading: x
                        })
                    }
                },
                {
                    key: 'listening',
                    label: 'Listening',
                    type: 'number',
                    span: 6,
                    allowClear: true,
                    disabled: !checkEngRequiredCond(),
                    hidden: !hideEngRequiredCond(),
                    onChange: x => {
                        setFieldsValue({
                            listening: x
                        })
                    }
                },
                {
                    key: 'speaking',
                    label: 'Speaking',
                    type: 'number',
                    span: 6,
                    allowClear: true,
                    disabled: !checkEngRequiredCond(),
                    hidden: !hideEngRequiredCond(),
                    onChange: x => {
                        setFieldsValue({
                            speaking: x
                        })
                    }
                },
                {
                    key: 'writing',
                    label: 'Writing',
                    type: 'number',
                    allowClear: true,
                    span: 6,
                    disabled: !checkEngRequiredCond(),
                    hidden: !hideEngRequiredCond(),
                    onChange: x => {
                        setFieldsValue({
                            writing: x
                        })
                    }
                },
                {
                    key: 'board',
                    label: 'Board',
                    type: 'select',
                    options: boardList,
                    allowClear: true,
                    span: 24,
                    disabled: getFieldValue('examType') !== 'I don\'t have this',
                    hidden: getFieldValue('examType') !== 'I don\'t have this',
                    keyAccessor: x => x.value,
                    valueAccessor: x => x.name,
                    onChange: x => {
                        setFieldsValue({
                            board: x,
                            boardPercentage: null
                        })
                    }
                },
                {
                    key: 'boardPercentage',
                    label: 'English Marks',
                    type: 'number',
                    allowClear: true,
                    span: 24,
                    disabled: !getFieldValue('board'),
                    hidden: getFieldValue('examType') !== 'I don\'t have this',
                    onChange: x => {
                        setFieldsValue({
                            boardPercentage: x
                        })

                    }
                }

            ]
        }

        const rangeEvents = {
            updateTuitionFees: (value) => {
                this.setState({tuitionFee: value}, () => {
                    this.groupOfFunctions('tuitionFee')
                })
            },
            updateAppRangeField: (value) => {
                this.setState({applicationFee: value})
            },
            tuitionFormatter: (value) => {
                return `${value}K`
            },
            AppFormatter: (value) => {
                return `${value}`
            },
            marks: {
                [this.state.tuitionFeeMin]: {
                    label: <strong>{this.state.tuitionFeeMin}{this.state.currency} </strong>
                },
                [this.state.tuitionFeeMax]: {
                    label: <strong>{this.state.tuitionFeeMax}{this.state.currency}  </strong>
                }
            }
        }

        return (
            <div className='filter-box'>
                <div className='accordion' id='accordionExample'>
                    <div className='card'>
                        <div className='card-header' id='headingOne'>
                            <h2 className='mb-0'>
                                <button className='btn btn-link d-flex' type='button' data-toggle='collapse'
                                        data-target='#collapseOne' aria-expanded='true' aria-controls='collapseOne'>
                                    Select Program & Country <img src='/dist/img/arrow-down-white.svg' alt=''
                                                                  className='ml-auto'/>
                                </button>
                            </h2>
                        </div>

                        <div id='collapseOne' className='collapse show' aria-labelledby='headingOne'
                             data-parent='#accordionExample'>
                            <div className='card card-body'>

                                <GetAllFormFields
                                    inputSchema={programFilter}
                                    formItemLayout={formItemLayout}
                                    getFieldDecorator={getFieldDecorator}
                                />


                            </div>
                        </div>


                        <div className='card-header' id='headingThree'>
                            <h2 className='mb-0'>
                                <button className='btn btn-link d-flex' type='button' data-toggle='collapse'
                                        data-target='#collapseThree' aria-expanded='true' aria-controls='collapseThree'>
                                    Student eligibility filter <img src='/dist/img/arrow-down-white.svg' alt=''
                                                                    className='ml-auto'/>
                                </button>
                            </h2>
                        </div>

                        <div id='collapseThree' className='collapse show' aria-labelledby='headingThree'
                             data-parent='#accordionExample'>
                            <div className='card card-body'>


                                <Row gutter={16}>

                                    {eligibilityFilter.fields.map((item, key) => {
                                        return (
                                            <Col span={item.span ? item.span : 24} key={key}>
                                                <GetEachFormFields key={key}
                                                                   item={item}
                                                                   getFieldDecorator={getFieldDecorator}
                                                                   formItemLayout={formItemLayout}/>
                                            </Col>
                                        )
                                    })}
                                </Row>

                                {/*<div className={'mt20'}>
                  <label>
                    Tuition Fee
                  </label>
                  <Slider range defaultValue={tuitionFee} value={tuitionFee}
                          tipFormatter={rangeEvents.tuitionFormatter} min={tuitionFeeMin} max={tuitionFeeMax}
                          marks={rangeEvents.marks}
                          onChange={(value) => {
                            rangeEvents.updateTuitionFees(value)
                          }} />
                </div>*/}


                                <div className='d-flex mt-4'>
                                    <div className='ml-auto'>
                                        <button className='btn' type='submit' onClick={events.clearFilters}>clear
                                        </button>
                                        {' '}
                                        <button className='btn apply' type='submit'
                                                onClick={events.groupOfEligibility}>apply
                                        </button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


}

const WrappedEvaluateFilters = Form.create()(EvaluateFilters)

const mapStateToProps = ({global, router, EvaluateReducers}) => ({
    pageLoading: global.pageLoading,
    currentUser: global.currentUser,
    EvaluateReducers: EvaluateReducers
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedEvaluateFilters)

