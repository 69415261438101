import React, { useEffect, useState } from 'react';
import { Button, Card, Drawer, Form, Input, Icon } from 'antd';
import { useDispatch } from 'react-redux';
import { cornerStoneNoteListFxn, cornerStoneAddNoteFxn } from '../../DirectColleges/actions/cornerStone';

const { TextArea } = Input;

const AddNotes = (props) => {
    const { studentData, applicationId, visible, onClose } = props;
    const dispatch = useDispatch();
    const [note, setNote] = useState('');
    const [noteHistory, setNoteHistory] = useState([]);

    useEffect(() => {
        apiRequest();
    }, []);

    const apiRequest = async () => {
        try {
            const resp = await dispatch(cornerStoneNoteListFxn({ studentId: studentData._id }));
            setNoteHistory(resp);
        } catch (error) {
            console.error('Error fetching note history:', error);
        }
    };

    const handleSubmit = async () => {
        try {
            const obj = {
                studentId: studentData._id,
                note: note,
                applicationId: applicationId,
            };
            await dispatch(cornerStoneAddNoteFxn(obj));

            setNote('');
            apiRequest();
        } catch (error) {
            console.error('Error submitting note:', error);
        }
    };

    return (
        <Drawer
            width={700}
            title="Add Notes"
            placement="right"
            visible={visible}
            onClose={onClose}
            
        >
            <Card bordered={false} style={{background: 'rgb(236 235 235)'}}>
                <Form>
                    <Form.Item>
                        <TextArea
                            rows={4}
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            placeholder="Enter your note here"
                        />
                    </Form.Item>

                    <Form.Item className="mt20">
                        <Button type="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                <div className='mt-5'>
                    <h6><b>NOTE HISTORY</b></h6>
                    {noteHistory.map((item, index) => (
                        <Card key={index} className="note-card mt0">
                            
                            <div className='header-note mb-0'>
                            <h6><b>NOTE</b></h6>
                            <p style={{ fontStyle: 'italic' }}>
                            <Icon type="user" /> By {item.createdByUser.name} 
                            </p>
                        </div>
                            <hr className='mt-0 mb-1'/>
                            <p className='mt-0 mb-0'>{item.note}</p>
                            <div className="timestamp mb-0 mt-0">
                                <p>{new Date(item.createdAt).toLocaleTimeString('en-US', {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true
                                })}</p>
                                <p>{new Date(item.createdAt).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}</p>
                            </div>
                        </Card>
                    ))}
                </div>
            </Card>
        </Drawer>
    );
};

export default AddNotes;
