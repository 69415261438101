import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom' // Import useParams
import { StatusNameObj, StatusLabel } from '../../../components/_utils/appUtils'
import { listAllCountries } from '../../countries/actions/countries'
import {
  franceApplicationStatusSteps,
  netherlandsApplicationStatusSteps,
  switzerlandApplicationStatusSteps,
  germanyApplicationStatusSteps
} from '../../../components/_utils/ApplicationStatusSteps'

const EuropeAllCountryBlocks = ({ callback, data, dispatch }) => {
  const [countryList, setCountryList] = useState([])


  useEffect(() => {
    const loadCountryData = async () => {
      try {
        const { data: countryData } = await dispatch(listAllCountries({
          countryGroup: 'Europe',
          results: 10,
          count: 10,
          active: [true]
        }))
        setCountryList(countryData)
      } catch (error) {
        console.error('Error loading country data:', error)
      }
    }

    loadCountryData()
  }, [dispatch])

  const getStatusList = (countryName) => {
    switch (countryName) {
      case 'Germany':
        return germanyApplicationStatusSteps
      case 'France':
        return franceApplicationStatusSteps
      case 'Netherlands':
        return netherlandsApplicationStatusSteps
      case 'Switzerland':
        return switzerlandApplicationStatusSteps
      default:
        return []
    }
  }


  return (
    <div className='card card-pdf'>
      <div className='card-pane-right listing-section w-100'
           style={{ border: 'none', flexDirection: 'column', padding: '30px 0', display: 'block' }}>
        {countryList.map((country, index) => {
          const statusList = getStatusList(country.countryName)
          const countArr = data[country.countryName] ? data[country.countryName].countArr : {}


          return (
            <div className='card card-pdf' key={index} style={{ marginBottom: '20px' }}>
              <h5>{country.countryName}</h5>
              <ul className={'count'}>
                {statusList.map((item, key) => (
                  item.name !== 'New Student' && (
                    <li
                      onClick={() => callback(data[country.countryName].countryId, item.name)} // Pass countryId from data prop
                      className={'pointerCls pointerCls-pdf'}
                      key={key}

                    >
                      <h5>{countArr[item.name] ? countArr[item.name] : 0}</h5>
                      <p>{StatusLabel[item.name] ? StatusLabel[item.name] : StatusNameObj[item.name] ? StatusNameObj[item.name] : item.name}</p>
                    </li>
                  )
                ))}
              </ul>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export { EuropeAllCountryBlocks }
