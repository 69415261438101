import React, {useState} from "react"
import {Button, Card, Drawer, Form, notification} from "antd";
import {InputBox} from "../../../components/_utils/appUtils";

let initState = {
    universityCommission: "",
    agentCommission: ""
}
const AddCommission = (props) => {
    let [state, setState] = useState(initState)
    let {visible, onSubmit, onClose} = props;
    const events = {
        handleSubmit: (e) => {
            e.preventDefault()
            if (!state.universityCommission) {
                notification.warning({message: "Enter university commission."})
                return
            }
            if (!state.agentCommission) {
                notification.warning({message: "Enter agent commission."})
                return
            }
            onSubmit(state);
            events.updateState(initState);
        },
        updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        }
    }
    return (
        <Drawer
            title={'Add Commission'}
            visible={visible}
            width={'35%s'}
            onClose={onClose}>
            <Card bordered={false}>
                <div className='form-box mt-2'>
                    <Form>
                        <InputBox title={'University Commission'}>
                            <input type={'number'}
                                   className={'form-control'}
                                   placeholder={'University Commission'}
                                   value={state.universityCommission} onChange={({target}) => {
                                events.updateState({universityCommission: target.value})
                            }}/>
                        </InputBox>
                        <InputBox title={'Agent Commission'}>
                            <input type={'number'}
                                   className={'form-control'}
                                   placeholder={'University Commission'}
                                   value={state.agentCommission} onChange={({target}) => {
                                events.updateState({agentCommission: target.value})
                            }}/>
                        </InputBox>
                        <Button onClick={events.handleSubmit} className='btn btn-success mt20'>
                            SAVE
                        </Button>
                    </Form>
                </div>
            </Card>
        </Drawer>
    )
}
export default AddCommission
