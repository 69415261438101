import React, { useEffect, useState } from 'react'
import { Drawer, Card, Input, Form, Select, Button, notification } from 'antd'
import { InputBox } from '../../../components/_utils/appUtils'
import { updateAppPendency } from '../actions/chooseStudent'
import { useDispatch } from 'react-redux'

const { Option } = Select
const { TextArea } = Input
const initialState = {
  pendency: '',
  description: ''
}
const PendencyComponent = (props) => {
  const dispatch = useDispatch()
  let [drawerVisible, setDrawerVisible] = useState(false)
  let [state, setState] = useState(initialState)
  let { pendencyArr, visible, closePendencyDrawer, studentData, singlePendency } = props
  useEffect(() => {

  }, [])

  const completePendency = async () => {
    if (!singlePendency._id) {
      return notification.warn({ message: 'Please choose pendency.' })
    }
    let obj = {
      studentId: studentData._id,
      applicationId: studentData.applications._id,
      // pendency: state.pendency,
      pendency: singlePendency._id,
      description: state.description
    }
    let resp = await dispatch(updateAppPendency(obj))
    if (state.description) {
      if (resp.noteId) {
        dispatch({ type: 'START_PUSH_NOTE_BY_REDUX', noteId: resp.noteId })
      }
    }
    setState({
      ...state,
      pendency: '',
      description: ''
    })
    closePendencyDrawer(resp.pendencyArr)
    dispatch({ type: 'START_LOAD_ACTIVITY' })


  }

  return (
    <Drawer title={`${singlePendency.pendency} Pendency`}
            visible={visible}
            onClose={closePendencyDrawer}
            width={600}>
      <Card>
        <Form>
          <InputBox title={'Description'}>
            <TextArea rows={4} value={state.description} onChange={(e) => {
              setState({
                ...state,
                description: e.target.value
              })
            }}/>
          </InputBox>
          <InputBox>
            <Button onClick={() => completePendency()}>Submit</Button>
          </InputBox>

        </Form>
      </Card>
    </Drawer>
  )
}

export default PendencyComponent
