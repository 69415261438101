import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Form,
    Modal,
    Input,
    Select,
    notification,
    Upload,
    Tabs,
    Card,
    Table,
    Row,
    Col,
    Tooltip,
    Icon,
} from 'antd';
import {
    getSingleTransactionFxn,
    updateStudentDataFxn,
    getDocumentDataFxn,
    editStudentDocFxn,
    getMetaInfoFxn, getFinalPaymentChangesFxn, paymentInstructionFxn, sendPaymentEmailFxn, createPaymentFxn
} from "../actions";
import ArtBoard from "../../../assets/Artboard–20.png";
import PassFront from "../../../assets/Passport-Front1.png";
import Pancard from "../../../assets/Pan-card-icon.png";
import OtherDoc from "../../../assets/otherdoc.png";
import {relationTypes, stringToFixed} from "../reeudoUtils";
import _ from "lodash"

const {Option} = Select;
const {TabPane} = Tabs;

const AddBasicDetails = (props) => {
    const {
        form: {getFieldDecorator, setFieldsValue},
        onClose,
        visible,
        appId,
        transactionId,
    } = props;

    const dispatch = useDispatch();
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [formData, setFormData] = useState(null);
    const [documentData, setDocumentData] = useState(null);
    const [chargeComponent, setChargeComponent] = useState({})
    const [isChargeModal, setIsChargeModal] = useState(false);
    const [amountData, setAmountData] = useState(null)
    const [quotationId, setQuotationId] = useState(null)
    const [kycStatus, setKycStatus] = useState('');
    const [txnStatus, setTxnStatus] = useState('');
    const [a2FormStatus, setA2FormStatus] = useState('');
    const [kycStatusConsultant, setKycStatusConsultant] = useState('');
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null;

    const apiRequest = async () => {
        let resp = await dispatch(getSingleTransactionFxn({transactionId}));
        if (resp) {
            setFormData(resp);
            setFieldsValue({
                name: resp.name,
                emailAddress: resp.emailAddress,
                phoneNumber: resp.phoneNumber,
            });
        }
    };


    const fetchStudentData = async () => {
        let resp = await dispatch(getDocumentDataFxn({rappId: appId}));
        let cloneForm = _.clone(formData);
        if (!cloneForm) {
            cloneForm = {}
        }

        if (resp && resp.rappId) {
            setDocumentData(resp);
            setFieldsValue({
                name: resp.name || cloneForm.name,
                emailAddress: resp.emailAddress || cloneForm.emailAddress,
                phoneNumber: resp.phoneNumber || cloneForm.phoneNumber,
                relationToStudent: resp.relationToStudent || undefined,
            });
            if (resp.documents) {
                setUploadedDocuments(resp.documents);
            }
        } else {
            apiRequest();
        }
    };

    const documents = [
        {label: 'Passport', imgSrc: PassFront, docName: 'PASSPORT'},
        {label: 'Aadhaar Card', imgSrc: OtherDoc, docName: 'AADHAAR'},
        {label: 'PAN', imgSrc: Pancard, docName: 'PAN'},
        {label: 'Offer Letter', imgSrc: ArtBoard, docName: 'OFFER_LETTER'},
        {label: 'Cheque Copy', imgSrc: ArtBoard, docName: 'CHEQUE'},
        {label: 'Other Document', imgSrc: OtherDoc, docName: 'OTHER'},
    ];

    const inputTypes = {
        fields: [
            {
                key: "relationToStudent",
                label: "Relation to Student",
                required: true,
                placeholder: "Enter your relation to the student",
                type: 'select',
                options: relationTypes,
                onChange: (x) => {
                    props.form.setFieldsValue({relationToStudent: x});
                },
            },
            {
                key: "name",
                label: "Name",
                required: true,
                placeholder: "Name",
                type: "text",
            },
            {
                key: "phoneNumber",
                label: "Phone",
                required: true,
                placeholder: "Phone",
                type: "text",
            },
            {
                key: "emailAddress",
                label: "Email",
                required: true,
                placeholder: "Email",
                type: "text",
            },
        ],
    };


    const uploadDocument = async (document) => {
        const formData = new FormData();
        formData.append('documents', document.file);
        formData.append('rappId', appId);
        formData.append('docName', document.docName);

        if (user && user._id) {
            formData.append('agentId', user._id);
        }

        let resp;
        if (documentData) {
            resp = await dispatch(editStudentDocFxn(formData));
        } else {
            resp = await dispatch(updateStudentDataFxn(formData));
        }

        if (resp.success) {
            const updatedDocs = (resp.documents || []).map((uploadedDoc) => ({
                _id: uploadedDoc._id,
                docName: uploadedDoc.docName,
                docType: uploadedDoc.docType,
                filePath: uploadedDoc.filePath,
                documentType: uploadedDoc.documentType,
            }));
            setUploadedDocuments(updatedDocs);
            notification.success({
                message: documentData ? 'Document updated successfully' : 'Document uploaded successfully',

            });
            fetchStudentData()
        } else {
            notification.error({
                message: documentData ? 'Error updating document' : 'Error uploading document',
            });
        }
    };


    const handleFileChange = (file, docName) => {
        const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        const maxSize = 5 * 1024 * 1024;

        if (!validFileTypes.includes(file.type)) {
            notification.error({
                message: 'Invalid file type',
                description: 'Only JPG, PNG, and PDF files are allowed.',
            });
            return;
        }

        if (file.size > maxSize) {
            notification.error({
                message: 'File too large',
                description: 'The maximum file size allowed is 5 MB.',
            });
            return;
        }

        const newDocument = {file, docName};
        const updatedDocuments = [...uploadedDocuments, newDocument];
        setUploadedDocuments(updatedDocuments);

        uploadDocument(newDocument);
    };

    const handleFileChange2 = (file, docName, index) => {
        const newDocument = {
            docName,
            docType: 'documents',
            filePath: URL.createObjectURL(file),
        };

        const updatedDocuments = [...documentData.documents];

        if (index < updatedDocuments.length) {
            updatedDocuments[index] = newDocument;
        } else {
            updatedDocuments.push(newDocument);
        }

        setDocumentData({
            ...documentData,
            documents: updatedDocuments,
        });

        uploadDocument(newDocument);
    };

    const apiRequest2 = async () => {
        let resp = await dispatch(getMetaInfoFxn({RappId: appId}));

        if (resp && resp.metaData && resp.metaData.START) {
            const {fromCurrency, amount, exchangeRate, ibrRate, quotationId} = resp.metaData.START;
            setQuotationId(quotationId)
            setAmountData(amount)
            const finalChangesObj = {
                currency: fromCurrency,
                amount: amount,
                fxRate: exchangeRate,
                ibrRate: ibrRate,
                appId: appId
            };
            console.log(finalChangesObj, "objjjjj--draweer")
            // await getFinalChangesFxn(finalChangesObj, appId);
        }

    };
    const getFinalChangesFxn = async () => {
        let resp = await dispatch(getMetaInfoFxn({RappId: appId}));
        console.log(resp, '================');
        if (resp) {
            const {kycStatus, txnStatus, a2FormStatus, kycStatusConsultant} = resp;

            setKycStatus(kycStatus);
            setTxnStatus(txnStatus);
            setA2FormStatus(a2FormStatus);
            setKycStatusConsultant(kycStatusConsultant);
        }
        if (resp && resp.metaData && resp.metaData.CONFIRMED) {
            const chargeComponent = resp.metaData.CONFIRMED.chargeComponent;
            setChargeComponent(chargeComponent);

        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let {form} = props;
        form.validateFields(async (err, values) => {
            if (!err) {
                const formData = new FormData();
                Object.keys(values).forEach(key => {
                    formData.append(key, values[key]);
                });

                if (user && user._id) {
                    formData.append('agentId', user._id);
                }
                formData.append('rappId', appId);

                let resp;
                if (documentData) {
                    resp = await dispatch(editStudentDocFxn(formData));
                } else {
                    resp = await dispatch(updateStudentDataFxn(formData));
                }

                if (resp.success) {
                    notification.success({message: documentData ? 'Details updated successfully' : 'Details saved successfully'});
                } else {
                    notification.error({message: documentData ? 'Error updating details' : 'Error saving details'});
                }

                onClose();
            }
        });
    };

    useEffect(() => {
        apiRequest();
        fetchStudentData();
        getFinalChangesFxn()

    }, []);

    const showChargeModal = () => {
        setIsChargeModal(true);
    };

    const handleCancel = () => {
        setIsChargeModal(false);
    };
    const columns = [
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
        },
        {
            title: 'Booking Rate',
            dataIndex: 'rate',
            key: 'rate',
            render: (item) => {
                return stringToFixed(item)
            }
        },
        {
            title: 'Amount',
            dataIndex: 'fromCurrencyAmount',
            key: 'fromCurrencyAmount',
            render: (item) => {
                return stringToFixed(item)
            }
        },
    ];

    const dataSource = [
        {
            key: '1',
            currency: chargeComponent.fromCurrency || 'N/A',
            rate: chargeComponent.fxRate || 'N/A',
            fromCurrencyAmount: chargeComponent.fromCurrencyAmount || 'N/A',
        },
    ];

    const downloadPayment = async (appId) => {
        const agentId = user._id;
        let resp = await dispatch(paymentInstructionFxn({applicationId: appId, agentId}))
        // console.log(resp, "diiii")
        if (resp) {
            window.open(resp.file.path, '_blank');
            const emailData = {
                agentId: agentId,
                appId: appId
            };

            // dispatch(sendPaymentEmailFxn(emailData));
        } else {
            notification.error({message: "Failed to retrieve payment instruction."});
        }
    }

    const renderPaymentDetails = () => (
        <div className="form-box payment-details-container">
            <div className="payment-details-row row">
                <div className="payment-details-col col-md-9">
                    <Card bordered={false} className="payment-details-card">
                        <h6>Payment Details</h6>
                        <Table
                            dataSource={dataSource}
                            columns={columns}
                            pagination={false}
                            bordered
                        />
                    </Card>
                    <div className={'mt20 ml20'}>
                        <h6>Amount in Words: <strong>{chargeComponent.inrAmountInWords}</strong></h6>
                        <h6>Amount: <strong>{chargeComponent.fcyAmountInWords}</strong></h6>
                    </div>
                    <Card className="status-information-card mt20">
                        <h6>Status Information</h6>
                        <Row gutter={16}>
                            <Col span={6}>
                                <div className="status-item">
                                    <strong>KYC Status:</strong>
                                    <label
                                        className={kycStatus === 'DONE' ? 'label label-success-green' : 'label label-success'}>
                                        {kycStatus}
                                    </label>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className="status-item">
                                    <strong>Transaction Status:</strong>
                                    <label
                                        className={txnStatus === 'DONE' ? 'label label-success-green' : 'label label-success'}>
                                        {txnStatus}
                                    </label>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className="status-item">
                                    <strong>A2 Form Status:</strong>
                                    <label
                                        className={a2FormStatus === 'DONE' ? 'label label-success-green' : 'label label-success'}>
                                        {a2FormStatus}
                                    </label>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className="status-item">
                                    <strong>KYC Consultant Status:</strong>
                                    <label
                                        className={kycStatusConsultant === 'DONE' ? 'label label-success-green' : 'label label-success'}>
                                        {kycStatusConsultant}
                                    </label>
                                </div>
                            </Col>
                        </Row>
                    </Card>


                </div>

                <div className="order-summary-col col-md-3">
                    <Card bordered={false} className="order-summary-card p-0">
                        <h6>Your Order Summary</h6>
                        <div className="order-summary-content">


                            <Row className="order-summary-row">
                                <Col span={12}>
                                    <p>TCS:</p>
                                </Col>
                                <Col span={12} className="text-right">
                                    <p>₹{stringToFixed(chargeComponent.tcs)}</p>
                                </Col>
                            </Row>
                            <Row className="order-summary-row">
                                <Col span={12}>
                                    <p>Nostro:</p>
                                </Col>
                                <Col span={12} className="text-right">
                                    <p>₹{stringToFixed(chargeComponent.nostro)}</p>
                                </Col>
                            </Row>
                            <Row className="order-summary-row">
                                <Col span={12}>
                                    <p>GST:</p>
                                </Col>
                                <Col span={12} className="text-right">
                                    <p>₹{stringToFixed(chargeComponent.gst)}</p>
                                </Col>
                            </Row>
                            <Row className="order-summary-row">
                                <Col span={12}>
                                    <p>Processing Fee:</p>
                                </Col>
                                <Col span={12} className="text-right">
                                    <p>₹{stringToFixed(chargeComponent.bank)}</p>
                                </Col>
                            </Row>
                            <hr/>
                            <Row className="order-summary-row" style={{color: 'green'}}>
                                <Col span={12}>
                                    <p>Discount:</p>
                                </Col>
                                <Col span={12} className="text-right">
                                    <strong>₹{chargeComponent.discount}</strong>
                                </Col>
                            </Row>
                            <hr/>
                            <Row className="order-summary-row">
                                <Col span={12}>
                                    <p>Total Amount:
                                        {/*<Tooltip title="Click to view details">*/}
                                        {/*    <Icon*/}
                                        {/*        type="info-circle"*/}
                                        {/*        style={{ marginLeft: 8, cursor: 'pointer' }}*/}
                                        {/*        onClick={showChargeModal}*/}
                                        {/*    />*/}
                                        {/*</Tooltip>*/}
                                    </p>
                                </Col>
                                <Col span={12} className="text-right">
                                    <p>{stringToFixed(chargeComponent.base)}</p>
                                </Col>
                            </Row>
                            <Row className="order-summary-row">
                                <Col span={12}>
                                    <strong>Total Amount Payable:</strong>
                                </Col>
                                <Col span={12} className="text-right">
                                    <strong>{stringToFixed(chargeComponent.total)}</strong>
                                </Col>
                            </Row>

                        </div>
                    </Card>

                    {/*<div className={'mt5 text-center'}>*/}
                    {/*    <a className={'mt5'} onClick={showModal}>Click here to update KYC status</a>*/}
                    {/*</div>*/}
                </div>
            </div>


            <div className={'mt20 text-center'}>
                <div className={'text-center'}>
                    <Button type={'primary'}
                            onClick={() => downloadPayment(appId)}
                    >Download Payment Instruction</Button>
                </div>

            </div>
        </div>
    );


    return (
        <Modal
            visible={visible}
            title={"Add Basic Details"}
            onCancel={onClose}
            width={"75%"}
            footer={null}>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Add Basic Details" key="1">
                    <Form>
                        <div>
                            <h6>Document Upload
                                <span style={{fontSize: '10px', color: 'red'}}>(Max File Size 5MB)</span>
                            </h6>
                            <div className={'row'}>
                                {documents.map((doc, index) => {
                                    const existingDoc = documentData && documentData.documents.find(d => d.documentType === doc.docName);
                                    return (
                                        <div key={index} className={'mt20 custom-exchange-col col-md-2'}>
                                            <div
                                                className='custom-exchange bordered'
                                                title={<span className='custom-exchange-title'>{doc.label}</span>}
                                                bordered={false}
                                                style={{textAlign: 'center', marginBottom: '16px'}}
                                            >
                                                <div className="custom-exchange-body">
                                                    <p className={'doc-label'}>{doc.label}</p>
                                                    <img src={doc.imgSrc} alt={doc.label}
                                                         className="custom-exchange-img"/>
                                                    {existingDoc ? (
                                                        <>
                                                            <Upload
                                                                beforeUpload={(file) => {
                                                                    handleFileChange2(file, doc.docName, doc.docName, index);
                                                                    return false;
                                                                }}
                                                                showUploadList={false}
                                                            >
                                                                <Button type='primary'
                                                                        className="custom-exchange-btn">ReUpload</Button>
                                                            </Upload>
                                                            <div className={'mt-1'}>
                                                                <a
                                                                    href={existingDoc.filePath}
                                                                    onClick={() => window.open(existingDoc.filePath, "_blank")}
                                                                    className="custom-exchange-link"
                                                                    target={'_blank'}
                                                                >
                                                                    View
                                                                </a>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <Upload
                                                            beforeUpload={(file) => {
                                                                handleFileChange(file, doc.docName, doc.docName);
                                                                return false;
                                                            }}
                                                            showUploadList={false}
                                                        >
                                                            <Button type='primary'
                                                                    className="custom-exchange-btn">Upload</Button>
                                                        </Upload>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className={'row'}>
                                {inputTypes.fields.map(field => (
                                    <div key={field.key} className={'col-md-6'}>
                                        <Form.Item label={field.label}>
                                            {getFieldDecorator(field.key, {
                                                rules: [{
                                                    required: field.required,
                                                    message: `${field.label} is required`
                                                }],
                                            })(field.type === 'select' ? (
                                                <Select
                                                    placeholder={field.placeholder}
                                                    onChange={field.onChange}
                                                >
                                                    {field.options.map(option => (
                                                        <Option key={option.value} value={option.value}>
                                                            {option.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            ) : (
                                                <Input placeholder={field.placeholder} className={'form-control'}/>
                                            ))}
                                        </Form.Item>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <Button type={"primary"} htmlType="submit" className={"mt-4 ml-4 round-bt text-center"}
                                onClick={handleSubmit}>
                            {documentData ? 'Update' : 'Submit'}
                        </Button>
                    </Form>
                </TabPane>
                <TabPane tab="Payment Details" key="2">
                    {renderPaymentDetails()}
                </TabPane>
            </Tabs>
        </Modal>
    );
};

export default Form.create()(AddBasicDetails);
