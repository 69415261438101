import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { addBroadcastMailUrl, listBroadcastMailUrl, singleBroadcastMailUrl } from '../../broadCastMail/api'


export const addBroadcastMail = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addBroadcastMailUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const listBroadcastMail = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(listBroadcastMailUrl(), config)
  dispatch(hidePageLoad())
  dispatch({ type: 'LIST_ALL_FEEDBACK', payload: data.data })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}


export const deleteBroadcastMail = (id) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.delete(singleBroadcastMailUrl(id), getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Deleted Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}


