import { customAxios as axios, getToken } from '../../../request'
import { deactivatedUsersListUrl } from '../apis'
import { notification } from 'antd'


export const getDeactivatedUsers = async (filters) => {
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let resp = await axios.get(deactivatedUsersListUrl(), config)
  if (resp && resp.error) {
    notification.error({
      message: resp.message || 'Error'
    })
  }
  return resp.data
}