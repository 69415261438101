import { apiUrl } from '../../../settings'

export const addComplaintDepartmentUrl = () => {
  return apiUrl + '/addComplaintDepartment'
}
export const ComplaintDepartmentListUrl = () => {
  return apiUrl + `/complaintDepartmentList`
}
export const updateComplaintDepartmentUrl = () => {
  return apiUrl + `/updateComplaintDepartment`
}
export const deleteComplaintDepartmentUrl = () => {
  return apiUrl + `/deleteComplaintDepartment`
}
