import React, { useEffect, useRef, useState } from 'react'
import { Drawer, Form, } from 'antd'
import { connect, useDispatch, useSelector } from 'react-redux'
import TargetBanner from '../../dashboard/views/targetBanner'
import moment from 'moment'


const ViewStudentApplication = (props) => {
    let { visible, onClose, targetObj } = props
    const [branchManagerId, setbranchManagerId] = useState(targetObj && targetObj.branchManagerId._id ? targetObj.branchManagerId._id : '')
    console.log(targetObj);
    const dispatch = useDispatch()

    return (
        <React.Fragment>
            <Drawer visible={visible}
                width={'60%'}
                closable={true}
                maskClosable={true}
                onClose={onClose}>

                <div className='col-lg-12 pd-0'>
                    <div className='card'>
                        <div className='table-head'>
                            <h4>{targetObj && targetObj.countryId.countryName ? targetObj.countryId.countryName : ''} - {targetObj && targetObj.branchManagerId.name ? targetObj.branchManagerId.name : ''}</h4>
                            <h6>{`${moment(targetObj.fromDate).format("D MMM  YY")} - ${moment(targetObj.toDate).format("D MMM  YY")}`}</h6>
                        </div>
                        <div className='card-body table-responsive top_P10 '>
                            <TargetBanner userId={branchManagerId} />
                        </div>
                    </div>
                </div>

            </Drawer>
        </React.Fragment>
    )
}

const mapStateToProps = ({ global, router }) => ({
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const WrappedComponent = Form.create()(ViewStudentApplication)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedComponent)
