import {
  Col,
  DatePicker,
  Form,
  Icon,
  Row,
  Select,
  Tooltip
} from 'antd'
import { push } from 'connected-react-router'
import moment from 'moment'
import React, {
  useEffect,
  useRef,
  useState
} from 'react'
import { connect, useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  DefaultTablePagination,
  InputBox,
  filterOption,
  newFormatDisplayDate, dateFilterRange, expenseTypes
} from '../../../components/_utils/appUtils'
import { getUser, getUserCanadaWallet } from '../../users/actions/user'
import TransactionListExpese from './transactionList'
import ExpenseInfo from '../drawers/expenseInfo'
import { getUrlPushWrapper } from '../../../routes'
import _ from 'lodash'
import { expenseAmountRecordCanadaFxn, listCanadaDailyExpense } from '../actions'

const { Option } = Select

let initFilters = {
  status: '',
  fromDate: '',
  toDate: '',
  expenseType: ''
}
const statusList = [
  'Pending',
  'Approved',
  'Cancel'
]
const { RangePicker } = DatePicker
const FilterComponent = (props) => {
  let { filterData, events, amtRecordObj, currentUser } = props
  let [data, setData] = useState({})
  useEffect(() => {
    setAmountData()
  }, [amtRecordObj])

  let setAmountData = () => {
    let dataV = {}
    let total = 0
    _.each(amtRecordObj, (item) => {
      dataV[item._id] = item.amount
      total = parseFloat(total) + parseFloat(item.amount)
    })
    dataV.total = total
    setData(dataV)
  }

  return (
    <Row gutter={12} className={'filter_box'}>
      <Col span={4}>
        <InputBox title={'Search by date'}>
          <RangePicker
            defaultValue={[filterData.fromDate, filterData.toDate]}
            onChange={val => {
              events.updateFilter({ fromDate: val[0], toDate: val[1] })
            }}
            ranges={dateFilterRange}
          />
        </InputBox>
      </Col>
      <Col span={4}>
        <InputBox title={'Search by expense type'}>
          <Select
            name='Expense type'
            filterOption={filterOption}
            allowClear={true}
            placeholder={'Expense type'}
            onChange={item => {
              events.updateFilter({ expenseType: item })
            }}
            value={filterData.expenseType || undefined}>
            {expenseTypes.map((item, key) => {
              return (
                <Option value={item} key={key}>
                  {item}
                </Option>
              )
            })}
          </Select>
        </InputBox>
      </Col>
      <Col span={4}>
        <InputBox title={'Search by status'}>
          <Select
            name='Status'
            filterOption={filterOption}
            allowClear={true}
            placeholder={'Status'}
            onChange={item => {
              events.updateFilter({ status: item })
            }}
            value={filterData.status || undefined}>
            {statusList.map((item, key) => {
              return (
                <Option value={item} key={key}>
                  {item}
                </Option>
              )
            })}
          </Select>
        </InputBox>
      </Col>
      <Col span={12} className={'alignRight'}>
        <div className={'expenseAmtBox'}>
          Total Amount : <span
          className={'totalAmt'}>{currentUser.defaultCurrency} {data.total ? (data.total).toFixed(2) : 0}</span><br />
          Approved Amount : <span
          className={'successAmt'}>{currentUser.defaultCurrency} {data.Approved ? (data.Approved).toFixed(2) : 0}</span><br />
          Pending Amount : <span
          className={'pendingAmt'}>{currentUser.defaultCurrency} {data.Pending ? (data.Pending).toFixed(2) : 0}</span>
        </div>
      </Col>
      <Col span={24}>
        <div className={'btn_group'}>
          <a className={'default_btn'} onClick={events.searchFxn}>Search</a>
          <a className={'default_btn'} onClick={events.clearFxn}>Clear</a>
        </div>
      </Col>
    </Row>
  )
}


const RenderNote = props => {
  let { item } = props
  let [hideV, setHideV] = useState(true)
  return (
    item && (
      <div>
        <div className={hideV ? 'appCommentDotsReplace' : ''}>
          {item}
        </div>
        {item && item.length && item.length > 50 ? (
          <div className={'alignRight'}>
            {hideV ? (
              <a className={'btn btn-link btn-xs'}
                 onClick={() => {
                   setHideV(false)
                 }}>
                Show More
              </a>
            ) : (
              <a className={'btn btn-link btn-xs'}
                 onClick={() => {
                   setHideV(true)
                 }}>
                Show Less
              </a>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  )
}
const DailyExpenseList = props => {
  let { currentUser } = props
  let tableRef = useRef()
  const dispatch = useDispatch()
  let [userData, setUserData] = useState({})
  let [total, setTotal] = useState(0)
  let [expenseInfoState, setExpenseInfoState] = useState({
    visible: false,
    data: {}
  })
  let [filterData, setFilterData] = useState(initFilters)
  let [amtRecordObj, setAmtRecordObj] = useState({})
  const events = {
    showInfoDrawer: (data) => {
      setExpenseInfoState({
        visible: true, data
      })
    },
    hideInfoDrawer: () => {
      setExpenseInfoState({
        visible: false, data: {}
      })
    }
  }
  useEffect(() => {
    getUserData()
    loadExpenseAmtRecord()
  }, [])

  const ranges = {
    Today: [moment().startOf('day'), moment().endOf('day')],
    'Last Week': [
      moment()
        .subtract(1, 'week')
        .startOf('week'),
      moment()
        .subtract(1, 'week')
        .endOf('week')
    ],
    'Last 15 Days': [moment().subtract(14, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')]
  }

  /*useEffect(() => {
          tableRef.current.reload()
      },
      [fromDate]
  )*/
  const addCurrentBalance = () => {
    setOpen(true)
  }

  let filterEvents = {
    getParams: (field) => {
      let searchParams = new URLSearchParams(window.location.search)
      return searchParams.get(field) ? searchParams.get(field) : ''
    },
    updateFilter: (data) => {
      setFilterData((prevState) => {
        return {
          ...prevState,
          ...data
        }
      })
    },
    setFieldByParams: async () => {
      let { getParams } = filterEvents
      let obj = {}
      _.each(initFilters, (item, key) => {
        if (getParams(key)) {
          obj[key] = getParams(key)
        }
      })
      setFilterData((prevState) => {
        return {
          ...prevState,
          ...obj
        }
      })
      setTimeout(() => {
        if (tableRef && tableRef.current) {
          tableRef.current.reload()
        }
      }, 100)
    },
    getParamsForApplicationList: () => {
      return new Promise(resolve => {
        let obj = {}
        _.each(initFilters, (item, key) => {
          if (filterData[key]) {
            obj[key] = filterData[key]
          }
        })
        resolve(obj)
      })
    },
    searchFxn: async () => {
      let obj = {}
      _.each(initFilters, (item, key) => {
        if (filterData[key]) {
          obj[key] = filterData[key]
        }
      })
      dispatch(getUrlPushWrapper('dailyExpenseListForMarketingUserCanada', { ...obj }))
      setTimeout(() => {
        if (tableRef && tableRef.current) {
          tableRef.current.reload()
        }
      }, 200)
    },
    clearFxn: () => {
      let obj = {}
      dispatch(getUrlPushWrapper('dailyExpenseListForMarketingUserCanada', { ...obj }))
      setFilterData((prevState) => {
        return {
          ...prevState,
          ...obj
        }
      })
      setTimeout(() => {
        tableRef.current.reload()
      }, 200)
    }
  }

  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null

  // const getUserData = async () => {
  //   let resp = await dispatch(getUser(user._id))
  //   setUserData(resp)
  // }
  const getUserData = async () => {
    let resp = await dispatch(getUserCanadaWallet(user._id))
    setUserData(resp)
  }
  const apiRequest = async params => {
    return new Promise(async resolve => {
      let filterResp = await filterEvents.getParamsForApplicationList()
      params = { ...params, ...filterResp }
      let { toDate, fromDate } = params
      if (fromDate) {
        let date = {}
        date.$gte = new Date(fromDate)
        date.$lte = new Date(toDate)
        params.date = date
        delete params['fromDate']
        delete params['toDate']
      }
      if (user) {
        params.userId = user._id
      }
      let respData = await dispatch(
        listCanadaDailyExpense({
          ...params,
          regExFilters: ['name', 'agentName', 'expenseType']
        })
      )
      setTotal(respData.total)
      resolve(respData)

    })
  }
  const [open, setOpen] = useState(false)

  const columns = [
    {
      title: 'Sr .No',
      key: '_id',
      dataIndex: '_id',
      width: 50,
      render: (item, record, index) => {
        return <div style={{ width: 50 }}>{index + 1}</div>
      }
    },
    {
      title: 'Generated on',
      dataIndex: 'date',
      key: 'date',
      // searchDateName: 'date',
      width: 130,
      render: val => {
        return (
          <div style={{ width: 130 }}>
            {newFormatDisplayDate(val)}
          </div>
        )
      }
    },
    {
      title: 'Expense Type',
      dataIndex: 'expenseType',
      key: 'expenseType',
      searchTextName: 'expenseType',
      width: 150,
      render: (val, record) => {
        return <p>{val}</p>
      }
    },

    {
      title: 'Hotel/Restaurant/Car/Other',
      dataIndex: 'name',
      key: 'name',
      searchTextName: 'name',
      render: (val, record) => {
        let { agentId, expenseType } = record
        return (
          <>


            {(expenseType == 'Hotel' || expenseType == 'Restaurant') ? <div>
              {val}
              <br />
              {record.city}({record.state})
            </div> : null}

            {(expenseType == 'Car') ? <div>
              {val}
            </div> : null}

            {expenseType == 'Other Expense' ? <div>
              {'Gift To Agent'} <br />
              {agentId && agentId.name ? agentId.name : ''}
              {agentId && agentId.companyName ? `(${agentId.companyName})` : ''}
            </div> : null}

          </>
        )
      }
    },
    {
      title: 'Receipt',
      dataIndex: 'receipt',
      key: 'receipt',
      width: 80,
      render: (val, record) => {
        return val && val.path ? (
          <Tooltip title={val.name}>
            <a className={'btn'} target={'_blank'} href={val.path}>
              <Icon type={'eye'} />
            </a>{' '}
          </Tooltip>
        ) : (
          ''
        )
      }
    },
    {
      title: 'Amount',
      dataIndex: '_id',
      key: '_id',
      width: 120,
      render: (val, record) => {
        let { userId } = record
        return val ? (
          <p>
            {userId && userId.defaultCurrency ? userId.defaultCurrency : ''}&nbsp;
            {record.amount
              ? (record.amount).toFixed(2)
              : record && record.netAmount
                ? (record.netAmount).toFixed(2)
                : null}
          </p>
        ) : null
      }
    },
    {
      title: 'Note',
      key: 'note',
      dataIndex: 'note',
      width: 220,
      render: val => {
        return (
          <div style={{ width: 180 }}>
            {val ? <RenderNote item={val} /> : null}
          </div>
        )
      }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 200,
      render: (val, record) => {
        let { reason } = record
        return (
          <React.Fragment>
            <label
              className={
                val == 'Cancel'
                  ? 'label label-danger label-sm'
                  : val == 'Pending'
                    ? 'label label-warning label-sm'
                    : val == 'Approved'
                      ? 'label label-success label-sm'
                      : 'label'
              }>
              {val}
            </label>
            <div className={'mt10'}>
              {reason}
            </div>
          </React.Fragment>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 120,
      render: (item, record) => {
        return (
          <div>
            <Tooltip title={'View Details'}>
              <a className={'btn btn-default roundNew sm'}
                 onClick={() => {
                   events.showInfoDrawer(record)
                 }}>
                <img src='../uniBtn/eye.png' /> &nbsp;View
              </a>
            </Tooltip>
          </div>
        )
      }
    }
  ]

  const loadExpenseAmtRecord = async () => {
    let { data } = await expenseAmountRecordCanadaFxn()
    setAmtRecordObj(data)
  }

  return (
    <>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>Daily Expense List (Total : {total})</h5>
              <div className='search-box-table' />
              <div className='sort-box-table mark-btn'>
                <a
                  className='btn'
                  onClick={() => dispatch(push('/add-Expense-Canada'))}>
                  <img src={'../dist/icons/plus.png'} className={'plus'} />
                  Add Expense
                </a>
              </div>
              <div className='sort-box-table mark-btn'>
                <a className='btn' onClick={() => addCurrentBalance()}>
                  Curr Balance : {currentUser.defaultCurrency} &nbsp;
                  {userData && userData.walletCanada ? userData.walletCanada.toFixed(2) : 0}
                </a>
              </div>
            </div>

            <div className='card-body table-responsive '>
              <FilterComponent
                amtRecordObj={amtRecordObj}
                filterData={filterData}
                currentUser={currentUser}
                events={filterEvents} />

              <TableComp
                columns={columns}
                apiRequest={apiRequest}
                ref={tableRef}
                pagination={DefaultTablePagination()}
              />
            </div>
          </div>
        </div>
        {open ? (
          <TransactionListExpese
            visible={open}
            user={user}
            onClose={() => {
              setOpen(false)
            }}
            onSubmit={() => {
              setOpen(false)
              tableRef.current.reload()
              getUserData()
            }}
          />
        ) : null}
      </div>

      {expenseInfoState.visible ?
        <ExpenseInfo {...expenseInfoState} onClose={events.hideInfoDrawer} /> : null}
    </>
  )
}

const DailyExpenseListForUserCanada = Form.create()(DailyExpenseList)

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})
export default connect(
  mapStateToProps,
  null
)(DailyExpenseListForUserCanada)
