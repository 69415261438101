import React from 'react'
import { Modal } from 'antd'
import { interestedRequestAddFxn } from '../../actions/cornerStone'
import { useDispatch } from 'react-redux'

const LoaReqMessage = (props) => {
  let { visible, onClose } = props
  let dispatch = useDispatch()
  let events = {
    submitRequest: async () => {
      let resp = await dispatch(interestedRequestAddFxn({ collegeName: 'Cornerstone' }))
      if (resp && resp.success) {
        onClose()
      }
    }
  }
  return (
    <Modal
      title='Cornerstone Account not activated.'
      visible={visible}
      onOk={onClose}
      footer={false}
      width={'40%'}
      onCancel={onClose}>
      <div style={{ fontSize: 16 }} className={'alignCenter'}>
        <img src={'../assets/directUniversities/cornerstone_college.png'} style={{ height: 100 }} />
        <div className={'mt10'}>
          Click this link to open your <strong>Cornerstone</strong> login.
        </div>
        <div className={'mt10'}>
          <a className={'btn btn-link'} onClick={events.submitRequest}>click here</a>
        </div>
      </div>
    </Modal>
  )
}
export default LoaReqMessage
