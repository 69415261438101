import React, { useEffect, useState, useRef } from 'react'
import { Button, Col, Row, Input, notification } from 'antd'
import { InputBox } from '../../../components/_utils/appUtils'
import { AddDocumentFxn, AddPalFxn } from '../actions'
import { useDispatch } from 'react-redux'
import { apiUrl2 } from '../../../settings'


const UploadPal = () => {
  const dispatch = useDispatch()
  const initialFields = {
    name: '',
    dob: '',
    loaDocument: null,
    loaDocumentPath: '',
    palDocument: null,
    palDocumentPath: '',
    invoiceLink: ''
  }
  const [fields, setFields] = useState([initialFields])
  const fileInputRefs = useRef([])
  const [inputKeys, setInputKeys] = useState([Date.now()])
  const [documentResponseData, setDocumentResponseData] = useState()

  useEffect(() => {
    if (documentResponseData) {
      let { document, index, documentName, path } = documentResponseData
      const newFields = fields.map((field, idx) => {
        if (index === idx) {
          return { ...field, [documentName]: document, [`${documentName}Path`]: `${apiUrl2}${path}` }
        }
        return field
      })
      setFields(newFields)
    }
  }, [documentResponseData])

  const MAX_FIELDS = 9

  const addFieldSet = () => {
    if (fields.length > MAX_FIELDS) {
      notification.warning({
        message: 'Cannot Add More Than 10 Records'
      })
      return
    }
    setFields([...fields, {
      name: '',
      dob: '',
      loaDocument: null,
      loaDocumentPath: '',
      palDocument: null,
      palDocumentPath: '',
      invoiceLink: ''
    }])
    setInputKeys([...inputKeys, Date.now()])
  }

  const handleInputChange = (index, event) => {
    const newFields = fields.map((field, idx) => {
      if (index === idx) {
        return { ...field, [event.target.name]: event.target.value }
      }
      return field
    })
    setFields(newFields)
  }

  const handleFileChange = (e, name, index) => {
    let { files } = e.target
    if (name === 'pal') {
      uploadDocument(files[0], 'pal', index)
    } else {
      uploadDocument(files[0], 'loa', index)
    }
  }

  const uploadDocument = async (file, fieldName, index) => {
    let fd = new FormData()

    fd.append('obj', JSON.stringify({ index }))

    if (fieldName === 'pal') {
      fd.append('pal', file)
    }
    if (fieldName === 'loa') {
      fd.append('loa', file)
    }
    let x = await dispatch(AddDocumentFxn(fd))
    if (x && x.success) {
      let docName
      if (x.document.documentName === 'LOA') {
        docName = 'loaDocument'
      } else {
        docName = 'palDocument'
      }
      setDocumentResponseData({
        ...x.document,
        index: x.index,
        fileName: x.document.document.fileName,
        path: x.document.document.path,
        documentName: docName
      })
    }
  }

  const validateFields = (field) => {
    const hasRequiredFields = field.name && field.dob
    const hasAtLeastOneDocument = field.invoiceLink || field.loaDocument || field.palDocument
    return hasRequiredFields && hasAtLeastOneDocument
  }

  const handleSubmitData = async () => {
    if (fields.some(field => !validateFields(field))) {
      notification.warning({
        message: 'Please Fill All The Fields'
      })
      return
    }

    let x = await dispatch(AddPalFxn(fields))

    if (x && x.success) {
      setInputKeys([Date.now()])
      setFields([initialFields])
      fileInputRefs.current.forEach(ref => {
        if (ref) {
          ref.value = ''
        }
      })
    }
  }

  const deleteFieldSet = (index) => {
    setFields(fields.filter((_, idx) => idx !== index))
    setInputKeys(inputKeys.filter((_, idx) => idx !== index)) // Update keys when a field set is deleted
  }

  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center justify-content-between'>
            <h5>Upload Pal</h5>
          </div>
          {fields.map((field, index) => (
            <div className={'bg-white mt-3 rounded'} key={index}>
              <Row gutter={24} style={{ marginBottom: '16px', marginTop: '16px' }}>
                <Col className={'mtop-5 ml-3'} span={1}>
                  <div>{index + 1}</div>
                </Col>
                <Col span={4}>
                  <InputBox title={'Student'}>
                    <Input
                      style={{ width: '100%' }}
                      value={field.name}
                      className={'form-control'}
                      name='name'
                      onChange={(e) => handleInputChange(index, e)}
                      placeholder='Enter Name..'
                    />
                  </InputBox>
                </Col>
                <Col span={4}>
                  <InputBox title={'Date Of Birth'}>
                    <Input
                      style={{ width: '100%' }}
                      value={field.dob}
                      type={'date'}
                      className={'form-control'}
                      name='dob'
                      onChange={(e) => handleInputChange(index, e)}
                    />
                  </InputBox>
                </Col>
                <Col span={4}>
                  <InputBox title={'LOA'}>
                    <Input
                      style={{ width: '100%' }}
                      className={'form-control'}
                      name='loa'
                      type={'file'}
                      ref={el => fileInputRefs.current[index * 2] = el} // Create ref for this file input
                      key={inputKeys[index * 2]} // Set key for LOA file input
                      onChange={(e) => handleFileChange(e, 'loa', index)}
                    />
                    {field.loaDocumentPath && (
                      <Button onClick={() => window.open(field.loaDocumentPath, '_blank')}>👁️</Button>
                    )}
                  </InputBox>
                </Col>
                <Col span={4}>
                  <InputBox title={'PAL'}>
                    <Input
                      style={{ width: '100%' }}
                      className={'form-control'}
                      name='pal'
                      type={'file'}
                      ref={el => fileInputRefs.current[index * 2 + 1] = el} // Create ref for this file input
                      key={inputKeys[index * 2]} // Set key for LOA file input
                      onChange={(e) => handleFileChange(e, 'pal', index)}
                    />
                    {field.palDocumentPath && (
                      <Button onClick={() => window.open(field.palDocumentPath, '_blank')}>👁️</Button>
                    )}
                  </InputBox>

                </Col>
                <Col span={4}>
                  <InputBox title={'Invoice Link'}>
                    <Input
                      style={{ width: '100%' }}
                      value={field.invoiceLink}
                      className={'form-control'}
                      name='invoiceLink'
                      onChange={(e) => handleInputChange(index, e)}
                      placeholder='Enter Link Here..'
                    />
                  </InputBox>
                </Col>
                <Col span={2}>
                  <Button className={'btn btn-danger mtop-5'} hidden={index === 0}
                          onClick={() => deleteFieldSet(index)}>
                    Delete
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          <div className={'d-flex justify-content-between'}>
            <Button htmlType='submit'
                    className='btn btn-primary'
                    onClick={handleSubmitData}
            >
              Submit
            </Button>
            <button className='btn btn-link' onClick={addFieldSet}>
              Add More
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadPal
