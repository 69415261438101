import { customAxios as axios, getToken } from '../../../request'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'
import {
  paymentReceiptUrl,
  enrollmentLetterUrl,
  createLoaLetterUrl,
  updateStudentUrl,
  updateChargeAmountUrl,
  bankVerifyUrl,
  createSupportingLetterUrl,
  addTranscriptOfMarksUrl,
  generateEslCertificateUrl,
  deferLoaUrl,
  allDocumentsUrl, getAllDocumentsUrl
} from './apis'
import { notification } from 'antd'
import { cambieOnShoreApplicationListUrl } from '../../fcmtApplications/apis'
import {
  cambieOnShoreCommissionListUrl,
  generateAgentInvoiceUrl,
  payCommissionUrl,
  uploadAgentInvoiceUrl
} from '../../latestUpdate/api'
import { studentDocumentsUrl, updateIntakeUrl } from '../api/chooseStudent'

export const cambieOnshoreEnrollmentLetterFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(enrollmentLetterUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.success) {
    notification.success({ message: data.message })
  } else {
    notification.error({ message: data.message })
  }
  return data
}
export const cambieOnshorePaymentReceiptFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(paymentReceiptUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.success) {
    notification.success({ message: data.message })
  } else {
    notification.error({ message: data.message })
  }
  return data
}

export const cambieOnshoreLoaLetterFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(createLoaLetterUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.success) {
    notification.success({ message: data.message })
  } else {
    notification.error({ message: data.message })
  }
  return data
}

export const updateStudentFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateStudentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.success) {
    notification.success({ message: data.message })
  } else {
    notification.error({ message: data.message })
  }
  return data
}


export const updateChargeAmountFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let {data} = await axios.post(updateChargeAmountUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (valData.showNotification) {
    if (!data.error) {
      notification.success({
        message: data.message || 'Success'
      })
    } else {
      notification.error({
        message: data.message || 'Error'
      })
    }
  }
  return data
}
export const bankVerifyFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let {data} = await axios.post(bankVerifyUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const cambieOnShoreCommissionListFxn = async (filters) => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(cambieOnShoreCommissionListUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const generateAgentInvoiceFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(generateAgentInvoiceUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.success) {
    notification.success({ message: data.message })
  } else {
    notification.error({ message: data.message })
  }
  return data
}
export const uploadAgentInvoiceFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(uploadAgentInvoiceUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.success) {
    notification.success({ message: data.message })
  } else {
    notification.error({ message: data.message })
  }
  return data
}
export const payCommissionFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(payCommissionUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.success) {
    notification.success({ message: data.message })
  } else {
    notification.error({ message: data.message })
  }
  return data
}
export const createSupportingLetterFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(createSupportingLetterUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.success) {
    notification.success({ message: data.message })
  } else {
    notification.error({ message: data.message })
  }
  return data
}


export const addTranscriptOfMarks = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addTranscriptOfMarksUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.success) {
    notification.success({ message: data.message })
  } else {
    notification.error({ message: data.message })
  }
  return data
}

export const generateEslCertificateFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(generateEslCertificateUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.success) {
    notification.success({ message: data.message })
  } else {
    notification.error({ message: data.message })
  }
  return data
}

export const deferLoaFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(deferLoaUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const loadAllDocuments = valData => async dispatch => {
  let { data } = await axios.post(getAllDocumentsUrl(), valData, getToken())
  return data
}