import {notification} from 'antd'
import {hidePageLoad, showPageLoad} from '../../../modules/actions'
import {customAxios as axios, getToken} from '../../../request'
import {
    addAccountSendOtpUrl,
    addBankAccountUrl,
    addEmailAccountUrl, agentAddBankAccountUrl, agentUpdateBankAccountUrl,
    deleteBankAccountUrl,
    deleteEmailAccountUrl,
    emailAccountUrl,
    listAccountDetailsUrl,
    updateBankAccountUrl
} from '../api/bankAccount'

export const addAccountSendOtpFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addAccountSendOtpUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
export const addBankAccount = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addBankAccountUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
export const listAllAccount = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(listAccountDetailsUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const deleteBankAccount = accountId => async dispatch => {
    dispatch(showPageLoad())
    try {
        let {data} = await axios.post(deleteBankAccountUrl(accountId), getToken())
        dispatch(hidePageLoad())
        if (!data.error) {
            notification.success({
                message: data.message || 'Success'
            })
        } else {
            notification.error({
                message: data.message || 'Error'
            })
        }
        return data
    } catch (error) {
        console.error('Error deleting bank account:', error)
        dispatch(hidePageLoad())
    }
}

export const updateBankAccount = (valData, accountId) => async dispatch => {
    dispatch(showPageLoad())
    try {
        let {data} = await axios.post(
            updateBankAccountUrl(accountId),
            valData,
            getToken()
        )
        console.log('data-edit', data)
        dispatch(hidePageLoad())
        if (!data.error) {
            notification.success({
                message: data.message || 'Success'
            })
        } else {
            notification.error({
                message: data.message || 'Error'
            })
        }
        console.log('data-edit-u', data)
        return data
    } catch (error) {
        console.error('Error updating bank account:', error)
        dispatch(hidePageLoad())
    }
}
export const addEmailAccount = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addEmailAccountUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
export const listEmailAccount = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(emailAccountUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const deleteEmailAccount = valData => async dispatch => {
    console.log(valData, 'dddddddd')
    dispatch(showPageLoad())
    try {
        let {data} = await axios.post(
            deleteEmailAccountUrl(),
            valData,
            getToken()
        )
        dispatch(hidePageLoad())
        if (!data.error) {
            notification.success({
                message: data.message || 'Success'
            })
        } else {
            notification.error({
                message: data.message || 'Error'
            })
        }
        return data
    } catch (error) {
        console.error('Error deleting bank account:', error)
        dispatch(hidePageLoad())
    }
}

export const agentAddBankAccountFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let { data } = await axios.post(agentAddBankAccountUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const agentUpdateBankAccountFxn = (valData) => async dispatch => {
    dispatch(showPageLoad())
    try {
        let { data } = await axios.post(agentUpdateBankAccountUrl(), valData, getToken())
        dispatch(hidePageLoad())
        if (!data.error) {
            notification.success({
                message: data.message || 'Success'
            })
        } else {
            notification.error({
                message: data.message || 'Error'
            })
        }
        return data
    } catch (error) {
        console.error('Error updating bank account:', error)
        dispatch(hidePageLoad())
    }
}
