import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getSingleSelectedCourse, searchSingleSelectedCourse } from '../actions/chooseStudent'
import { Card, Tag, Button, Tooltip, Drawer, Avatar, Form, Input, Empty } from 'antd'
import {
  displayDate,
  InputBox,
  nameAvatar,
  longDisplayDate, departmentObj, cambieOnshoreUserList
} from '../../../components/_utils/appUtils'
import NotesBlock from '../views/agent/notesBlock'
import SingleAppDocumentComponent from '../views/singleAppDocumentComponent'
import PendencyComponent from '../views/pendencyComponent'
import SampleList from '../../sampleDocument/views/sampleContent'
import { push } from 'connected-react-router'
import { submitNote } from '../../student/actions/student'
import moment from 'moment'
import { getUser } from '../../users/actions/user'
import { getPendingPendency } from '../../../components/_utils/utils'
import SelectPendency from '../views/selectPendency'
import { DirectUniversityCheck } from '../../../components/_utils/appUtils'
import { useHistory } from 'react-router-dom'
import CommissionTableSemWise from '../views/commissionSemWiseTableComponent'
import SearchApplicationStatusComponent from '../views/searchSingleApplicationStatusComponent'

const { TextArea } = Input

const SearchSingleApplication = (props) => {
  let { user } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const [state, setState] = useState({
    studentData: {}
  })
  const [appState, setAppState] = useState({
    applicationObj: {}
  })
  const [pendencyVisible, setPendencyVisible] = useState(false)
  let { studentData } = state
  let { createdAt, applications, englishRequirments, education } = studentData
  let [singlePendency, setSinglePendency] = useState({})
  let [visibleSampleDrawer, setVisibleSampleDrawer] = useState(false)
  let [profileImage, setProfileImage] = useState({})
  const [addPendencyDrawer, setAddPendencyDrawer] = useState(false)
  let searchParams = new URLSearchParams(window.location.search)
  const [applicationId, setApplicationId] = useState(searchParams.get('applicationId'))


  const getSingleApplication = async (appid) => {
    let searchParams = new URLSearchParams(window.location.search)
    let applicationId = searchParams.get('applicationId') || appid

    if (applicationId) {
      let userResp = await dispatch(getUser(user._id))
      let resp = await dispatch(searchSingleSelectedCourse({ applicationId }))
      if (resp && resp.status === 'Active') {
        if (userResp.showAgent) {
          setState({
            ...state,
            studentData: resp
          })
          if (resp && resp.profile && resp.profile.url) {
            setProfileImage(resp.profile)
          }
        } else if (
          resp.studentId === userResp._id || userResp.userType === 'student' || userResp.userType === 'itTeam' ||
          (resp.applications.addByUserId && resp.applications.addByUserId._id === userResp._id) ||
          (resp.applications.teamId && resp.applications.teamId === userResp._id) ||
          (resp.addByUserId && resp.addByUserId === userResp._id) ||
          userResp.showAllFcmtList
        ) {
          setState({
            ...state,
            studentData: resp
          })
          if (resp && resp.profile && resp.profile.url) {
            setProfileImage(resp.profile)
          }
        } else {
          if (
            userResp.department === departmentObj.assDepartment ||
            userResp.department === departmentObj.offerDepartment ||
            userResp.userType === 'marketingManager'
            || user.userType == 'cambieMarketingManager'
            || cambieOnshoreUserList.includes(user.userType)
            || (userResp.userType == 'branchManager' && (user.showOnShoreFcmt || user.showOnShoreCambie))) {
            setState({
              ...state,
              studentData: resp
            })
          } else {
            if (
              resp.agentId && resp.agentId._id === userResp._id ||
              (resp.applications.addByUserId && resp.applications.addByUserId._id === userResp._id) ||
              (resp.addByUserId && resp.addByUserId === userResp._id) ||
              userResp.showAllFcmtList
            ) {
              setState({
                ...state,
                studentData: resp
              })
              if (resp && resp.profile && resp.profile.url) {
                setProfileImage(resp.profile)
              }
            } else {
              dispatch(push('/dashboard'))
            }
          }
        }
      } else {
        // Instead of redirecting, you can handle it differently for students
        // For example, display a message or handle it in a different way
        console.log('Student status is not active')
        setState({ studentData: [] })
        history.push('/searchApplication/view')

      }
    } else {
      setState({ studentData: [] })
      history.push('/searchApplication/view')
    }
  }


  useEffect(() => {
    getSingleApplication()
  }, [])


  const events = {
    showPendencyDrawer: (pendency) => {
      setSinglePendency(pendency)
      setPendencyVisible(true)
    },
    hidePendencyDrawer: (pendencyArr) => {
      setPendencyVisible(false)
      let { studentData } = state
      if (pendencyArr && pendencyArr.length) {
        studentData.applications.pendencyArr = pendencyArr
        setState({
          ...state,
          studentData
        })
      }
    },
    openAddPendencyDrawer: (value) => {
      setAppState({
        ...appState,
        applicationObj: value
      })
      setAddPendencyDrawer(true)
    },
    reloadAndClose: () => {
      getSingleApplication()
      setAddPendencyDrawer(false)
    },
    closeAddPendencyDrawer: () => {
      setAppState({
        ...appState,
        applicationObj: {}
      })
      setAddPendencyDrawer(false)
    },
    reloadFxn: () => {
      getSingleApplication()
    },

    showSampleDrawer: () => {
      setVisibleSampleDrawer(true)
    },
    hideSampleDrawer: () => {
      setVisibleSampleDrawer(false)
    },
    showDrawer: () => {
      setState({ ...state, noteDrawerVisible: true })
    },
    hideDrawer: () => {
      setState({ ...state, noteDrawerVisible: false })
    },
    setLocalState: (e) => {
      let { name, value } = e.target
      setState({
        ...state,
        [name]: value
      })
    },
    chooseDocument: (e) => {
      let { name, files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          document: files[0]
        })
      }
    },
    handleSubmit: async () => {
      let obj = {
        studentId: studentData._id,
        note: state.note,
        applicationId: applications._id
      }
      let fd = new FormData()
      fd.append('obj', JSON.stringify(obj))
      fd.append('document', state.document)
      let data = await dispatch(submitNote(fd))
      if (data && !data.error) {
        dispatch({ type: 'START_PUSH_NOTE_BY_REDUX', noteId: data.noteId })
        setState({
          ...state,
          note: '',
          document: '',
          documentKey: moment(),
          noteDrawerVisible: false
        })

      }
    }
  }
  /* useEffect(() => {
     const handleKeyDown = (event) => {
       if (event.key === 'Enter') {
         document.getElementById('searchButton').click()
       }
     }
     document.addEventListener('keydown', handleKeyDown)
     return () => {
       document.removeEventListener('keydown', handleKeyDown)
     }
   }, [])*/

  return (
    <React.Fragment>
      <Card className={'mt-4'} title={'Search Commission Details By Application Id'}>
        <div className='d-flex'>
          <form className='input-group-box w-100' onSubmit={(e) => {
            e.preventDefault()
            history.push(`?applicationId=${applicationId}`)
            getSingleApplication(applicationId)
          }}>
            <div className='input-group input-group-sm' style={{ flexWrap: 'unset' }}>
              <div className='input-group-inner field1 field3 w-50'>
                <input className='form-control' type='search'
                       value={applicationId}
                       onChange={(e) => setApplicationId(e.target.value)}
                       placeholder='Enter Application Id' />
                <img src='/dist/img/search.png' alt='' />
              </div>

              <button className='btn searchNewBtn' type={'primary'}>search</button>
              <a className='btn clearNewBtn'
                 onClick={() => {
                   setState({
                     studentData: []
                   })
                   setApplicationId('')
                   history.push('/searchApplication/view')
                 }}>Clear</a>
            </div>
          </form>


          {/* <InputBox title={'Search Commission Details By Application Id'}>


            <Input
              type='text'
              style={{ height: '40px', width: '350px' }}
              placeholder={'Enter Application Id'}
              value={applicationId}
              onChange={(e) => setApplicationId(e.target.value)}
            />
            <button
              id='searchButton'
              className='btn btn-success ml-2 roundBtn'
              onClick={() => {
                history.push(`?applicationId=${applicationId}`)
                getSingleApplication(applicationId)
              }}
            >
              Search
            </button>
            <button
              className='btn btn-danger ml-2 roundBtn'
              onClick={() => {
                setState({
                  studentData: []
                })
                setApplicationId('')
                history.push('/searchApplication/view')
              }}
            >
              Clear
            </button>
          </InputBox>*/}
        </div>
      </Card>
      <div className={'mt20'}>
        {studentData && studentData._id ?
          <Card>
            <div className='student-application-detail mt-4' key={state}>
              <div className='row'>
                <div className='col-lg-8 col-12'>
                  <div className='detail-list w-100 card'>
                    <h5>student application details</h5>
                    <ul>
                      <li>
                        <strong>student ID</strong>
                        <div> {studentData.studentId ?
                          <Tag className={'successTag'}>{studentData.studentId}</Tag> : null}</div>
                      </li>
                      <li>
                        <strong>student name</strong>
                        <div> {studentData.name ? studentData.name : null}</div>
                      </li>
                      <li>
                        <strong>application ID</strong>
                        <div>{applications && applications.id ? applications.id : null}</div>
                      </li>
                      <li>
                        <strong>email</strong>
                        <div>{studentData.email && studentData.email ? studentData.email : null}</div>
                      </li>
                      <li>
                        <strong>program title</strong>
                        <div>{applications && applications.courseName ? applications.courseName : ''}</div>
                      </li>
                      <li>
                        <strong>fees</strong>
                        <div>{applications && applications.tuitionFee ? applications.tuitionFee : null}</div>
                      </li>

                      <li>
                        <strong>intake</strong>
                        <div> {applications && applications.intake && applications.intake.month ? `${applications.intake.month}, ${applications.intake.year}` : ''}</div>
                      </li>
                      <li>
                        <strong>english test</strong>
                        <div>
                          {englishRequirments && englishRequirments.examType ? <React.Fragment>
                            <b>{englishRequirments.examType}</b>
                            {englishRequirments.examType !== 'I don\'t have this' &&
                            <span className='test d-flex flex-row align-items-center'>
                                                        <p>O: {englishRequirments.overall}</p>
                                                        <p>L: {englishRequirments.listening}</p>
                                                        <p>S: {englishRequirments.speaking}</p>
                                                        <p>W: {englishRequirments.writing}</p>
                                                        <p>R: {englishRequirments.reading}</p>
                                                    </span>}
                          </React.Fragment> : null}
                        </div>
                      </li>
                      {englishRequirments && englishRequirments.examType && englishRequirments.examType == 'I don\'t have this' ?
                        <li>
                          <strong>English 12Th Marks</strong>
                          <div>
                            <b>{englishRequirments.english12ThMarks}</b>
                          </div>
                        </li> : null}
                      {englishRequirments && englishRequirments.examinationDate ? <li>
                        <strong>Examination Date</strong>
                        <div>
                          <b>{displayDate(englishRequirments.examinationDate)}</b>
                        </div>
                      </li> : null}
                      <li>
                        <strong>education</strong>
                        <div className='d-flex flex-column'>
                          {education && education.qualification ? <React.Fragment>
                            <div className='mb-3'>
                              <b>{education.qualification}</b>
                              <span className='test d-flex flex-row align-items-center'>
                                                        <p>Year {education.passingYear}</p>
                                                        <p>{education.percentage} %</p>
                                                    </span>
                            </div>
                            <div>
                          <span className='test d-flex flex-row align-items-center'>
                                                        {education.maths ? <p>M {education.maths}</p> : null}
                            {education.english ? <p>E {education.english}</p> : null}
                                                    </span>
                            </div>
                          </React.Fragment> : null}
                        </div>
                      </li>
                      {education && education.board ? <li>
                        <strong>Board/University</strong>
                        <div className='d-flex flex-column'>
                          <div>
                            <b>{education.board}</b>
                          </div>
                        </div>
                      </li> : null}
                      <li>
                        <strong>applied on</strong>
                        <div>{displayDate(studentData.createdAt)}</div>
                      </li>
                      <li>
                        <strong>application level</strong>
                        <div>{applications && applications.courseId && applications.courseId.courseLevel ? applications.courseId.courseLevel : null}</div>
                      </li>
                      <li>
                        <strong>institute</strong>
                        <div>{applications && applications.courseUniversity && applications.courseUniversity.universityName ? applications.courseUniversity.universityName : null}</div>
                      </li>
                      <li>
                        <strong>duration</strong>
                        <div> {applications && applications.courseId && applications.courseId.courseDuration ? applications.courseId.courseDuration : null}</div>
                      </li>
                      <li>
                        <strong>pendency</strong>
                        <div> {applications && applications.pendencyArr && applications.pendencyArr.length ?
                          <Tooltip title={'Pendency'}>
                                                <span onClick={() => events.openAddPendencyDrawer(studentData)}
                                                      className={'label label_sm label-success'}>{getPendingPendency(applications.pendencyArr)}</span>
                          </Tooltip>

                          : null}</div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-4 col-12'>
                  <div className='search-profile-main'>
                    <div className='profile-box-main'>
                      {profileImage && profileImage.url ?
                        <Avatar size={70} src={profileImage.url}></Avatar> :
                        <Avatar size={70}>{nameAvatar(studentData.name)}</Avatar>}
                    </div>
                    <div className='profile-box-inner2'>
                      <h5>{studentData.name ? studentData.name : null}</h5>
                      <p>{applications && applications.courseName ? applications.courseName : ''}<br />
                        {applications && applications.courseId && applications.courseId.courseDuration ? applications.courseId.courseDuration : null}<br />
                        {applications && applications.courseUniversity && applications.courseUniversity.universityName ? applications.courseUniversity.universityName : null}
                      </p>
                    </div>
                    {applications.courseUniversity && applications.courseUniversity._id && !DirectUniversityCheck(applications.courseUniversity._id) ?
                      <div>
                        {applications && applications.status ?
                          <SearchApplicationStatusComponent applications={applications}
                                                            createdAt={createdAt} /> : null}
                      </div> : null}
                    {applications.courseUniversity && applications.courseUniversity._id && DirectUniversityCheck(applications.courseUniversity._id) ?
                      <div>
                        <div className='application-current-status'>
                          <h5>application current status</h5>
                          <ul>
                            {applications && applications.status && applications.statusList && applications.statusList.length ? applications.statusList.map((item, key) => {
                              return (
                                <Tooltip title={longDisplayDate(item.date)} key={key}>
                                  <li className={`statusBox ${item.name === applications.status ? 'bgTheme' : ''}`}
                                      key={key}>
                                    <React.Fragment>
                                      {item.status}
                                      {item.status == applications.status ?
                                        <img src='/dist/img/check.svg' alt='' /> : null}
                                    </React.Fragment>
                                  </li>
                                </Tooltip>
                              )
                            }) : null}
                          </ul>
                        </div>
                      </div> : null}

                  </div>
                </div>
                {applicationId &&
                <CommissionTableSemWise
                  withdrawalId={searchParams.get('withdrawalId')}
                  appId={applicationId}
                />
                }
              </div>
              <div className='row mt-4'>
                <div className='col-lg-12'>
                  <div className={'appProfile'}>
                    <div className={'align-self-stretch'}>
                      {applications && applications._id ?
                        <NotesBlock applicationId={applications && applications._id}
                                    studentId={studentData._id}
                                    showNoteDrawer={events.showDrawer} /> : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mt-4 documents-section'>
                <div className='col-lg-12'>
                  {applications && applications._id ?
                    <SingleAppDocumentComponent
                      user={user}
                      studentId={studentData._id}
                      applicationId={applications._id}
                      courseUniversityId={applications.courseUniversity && applications.courseUniversity._id ? applications.courseUniversity._id : ''}
                      userType={user.userType} /> : null}
                </div>
              </div>
            </div>
          </Card> :
          <Card>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} imageStyle={{ height: '20px', padding: 0 }} />
          </Card>
        }
      </div>
      <Drawer width={700}
              title={'Create Note'}
              placement='left'
              visible={state.noteDrawerVisible}
              onClose={events.hideDrawer}>
        <Card bordered={false}>
          <Form>
            <InputBox title={'Note'}>
              <TextArea rows={7} onChange={events.setLocalState} name={'note'}
                        className={'form-control'}
                        placeholder={'Note'} value={state.note} />
            </InputBox>
            <InputBox title={'Document'}>
              <Input type={'file'} name={'document'} id={'document'} key={state.documentKey}
                     className={'form-control'}
                     onChange={(e) => {
                       events.chooseDocument(e)
                     }} />
            </InputBox>
            <Form.Item className={'mt20 alignRight'}>
              <Button type='primary' onClick={events.handleSubmit}>
                SUBMIT
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Drawer>
      {addPendencyDrawer ?
        <SelectPendency visible={addPendencyDrawer}
                        applicationObj={appState.applicationObj}
                        onAddPendencyClose={events.closeAddPendencyDrawer}
                        onClose={events.reloadAndClose}
                        reloadFxn={events.reloadFxn}
        /> : null}
      {pendencyVisible ? <PendencyComponent
        visible={pendencyVisible}
        singlePendency={singlePendency}
        studentData={studentData}
        closePendencyDrawer={events.hidePendencyDrawer}
        pendencyArr={applications.pendencyArr} /> : ''}
      {visibleSampleDrawer ?
        <Drawer title={'Sample'}
                width={800}
                onClose={() => events.hideSampleDrawer()}
                visible={visibleSampleDrawer}>
          <SampleList pageType={''} />
        </Drawer> : ''}
    </React.Fragment>
  )
}

export default SearchSingleApplication
