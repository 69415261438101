import {notification} from 'antd'
import {hidePageLoad, showPageLoad} from '../../../modules/actions'
import {customAxios as axios, getToken} from '../../../request'
import {
    addWhatsNewUrl, deleteWhatsNewUrl,
    getWhatsNewCountUrl,
    resetWhatsNewCountUrl,
    whatsNewListUrl,
    whatsNewStatusUpdateUrl
} from '../api/whatsNew'

export const addWhatsNewFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addWhatsNewUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
export const listWhatsNewFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(whatsNewListUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const whatsNewStatusUpdateFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(whatsNewStatusUpdateUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const resetWhatsNewCountFxn = async (valData) => {
    let {data} = await axios.post(resetWhatsNewCountUrl(), valData, getToken())
    return data
}

export const getWhatsNewCountFxn = async (valData) => {
    let {data} = await axios.post(getWhatsNewCountUrl(), valData, getToken())
    return data
}

export const deleteWhatsNewFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(deleteWhatsNewUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
