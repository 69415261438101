import {DatePicker, Tag} from 'antd'
import moment from 'moment'
import React, {useEffect, useRef, useState} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {
    DefaultTablePagination,
    displayDate
} from '../../../components/_utils/appUtils'
import {ColumnWidth} from '../../WebComponent/columnWidth'
import FootFallDetaisGraph from '../../dashboard/components/footFallGraph'
import {LoadFootFallFxn, loadDistinctStateFxn} from '../../users/actions/user'
// import InputBox from "../../../components/_utils/InputBox";

const FootFall = props => {
    let dispatch = useDispatch()
    let tableRef = useRef()
    let [total, setTotal] = useState(0)
    let [footFallGraph, setFootFallGraph] = useState(false)
    const [stateWiseCount, setStateWiseCount] = useState([])
    const [selectedState, setSelectedState] = useState([])
    let {RangePicker} = DatePicker
    let apiRequest = async params => {
        return new Promise(async resolve => {
            params = {
                ...params,
                userType: ['agent'],
                sortField: 'date',
                sortOrder: 'descend'
            }
            if (fromDate) {
                params.fromDate = new Date(fromDate)
                params.toDate = new Date(toDate)
            }
            params.results = 50
            params.count = 50
            let {data} = await dispatch(
                LoadFootFallFxn({
                    ...params,
                    // fromDate: fromDate,
                    // toDate: toDate,
                    userType: 'agent',
                    stateName: selectedState,
                    regExFilters: ['name', 'companyId', 'count']
                })
            )
            setTotal(data.total)
            resolve(data)
        })
    }

    const [fromDate, setFromDate] = useState(moment().startOf('day'))
    const [toDate, setToDate] = useState(moment().endOf('day'))
    const ranges = {
        Today: [moment().startOf('day'), moment().endOf('day')],
        'Last Week': [
            moment()
                .subtract(1, 'week')
                .startOf('week'),
            moment()
                .subtract(1, 'week')
                .endOf('week')
        ],
        'Last 15 Days': [moment().subtract(14, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')]
    }
    useEffect(() => {
        // loadDistinctState()
    }, [])

    useEffect(
        () => {
            tableRef.current.reload()
            loadDistinctState()
        }, [selectedState, fromDate])

    const loadDistinctState = async () => {
        let params = {}
        if (fromDate) {
            params.fromDate = new Date(fromDate)
            params.toDate = new Date(toDate)
        }
        let resp = await dispatch(loadDistinctStateFxn(params))
        setStateWiseCount(resp.data)
    }

    const handleBoxClick = state => {
        const isSelected = selectedState.includes(state._id)

        if (isSelected) {
            setSelectedState(selectedState.filter(selected => selected !== state._id))
        } else {
            setSelectedState([...selectedState, state._id])
        }
    }
    const columns = [
        {
            title: '#',
            key: '_id',
            dataIndex: '_id',
            width: 50,
            render: (ite, record, index) => {
                return <React.Fragment>{index + 1}</React.Fragment>
            }
        },
        {
            title: 'Name',
            key: 'userId',
            dataIndex: 'userId',
            width: 100,
            render: item => {
                return (
                    <ColumnWidth width={120}>
                        {item && item.name ? item.name : ''}
                        {item && item.companyName ? ` (${item.companyName})` : ''}
                    </ColumnWidth>
                )
            }
        },
        {
            title: 'Date',
            key: 'date',
            dataIndex: 'date',
            width: 100,
            render: item => {
                return (
                    <ColumnWidth width={120}>{item ? displayDate(item) : ''}</ColumnWidth>
                )
            }
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            searchTextName: 'count',
            width: 120,
            render: item => {
                return <ColumnWidth width={120}>{item}</ColumnWidth>
            }
        }
    ]
    const hideFootFallGraph = () => {
        setFootFallGraph(false)
    }

    const showFootFallGraph = () => {
        setFootFallGraph(true)
    }
    return (
        <>
            <div className="row  mt-4">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="table-head d-flex align-items-center">
                            <h5>Agent FootFall List ( Total : {total})</h5>

                            <div className="search-box-table"/>
                            <div className="sort-box-table mark-btn">
                                <button className="btn" onClick={() => showFootFallGraph()}>
                                    View FootFall Graph
                                </button>
                            </div>
                        </div>

                        <div className="card-body table-responsive">

                            <div className="row">
                                <div className="col-md-3 mb-1 mt-1">
                                    <div className='input-group input-group-sm'>
                                        <label style={{fontWeight: 500}}>Filter by date</label>
                                        <RangePicker
                                            defaultValue={[fromDate, toDate]}
                                            onChange={val => {
                                                setFromDate(val[0])
                                                setToDate(val[1])
                                            }}
                                            ranges={ranges}
                                        />
                                    </div>
                                </div>
                                <div className={'col-md-12 mt10'}>
                                    <div className='input-group input-group-sm'>
                                        <label style={{fontWeight: 500}}>Filter by state</label>
                                        <div>
                                            {stateWiseCount &&
                                            stateWiseCount.length &&
                                            stateWiseCount.map((state, index) =>
                                                state._id ? (
                                                    <Tag
                                                        key={index}
                                                        className={`selectable-box ${
                                                            selectedState.includes(state._id) ? 'selected' : ''
                                                        }`}
                                                        onClick={() => handleBoxClick(state)}>
                                                        <span className="box-text">{state._id}</span>
                                                        <div className="countCircle">{state.count}</div>
                                                    </Tag>
                                                ) : null
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <TableComp
                                columns={columns}
                                ref={tableRef}
                                pagination={DefaultTablePagination()}
                                apiRequest={apiRequest}
                                extraProps={{scroll: {x: 1000}}}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {footFallGraph ? (
                <FootFallDetaisGraph
                    visible={footFallGraph}
                    onClose={hideFootFallGraph}
                />
            ) : null}
        </>
    )
}
export default FootFall
