import React, { useState } from 'react'
import { Drawer, notification, Button, Select } from 'antd'
import { useDispatch } from 'react-redux'
import { filterOption } from '../../../components/_utils/appUtils'
import TextArea from 'antd/es/input/TextArea'
import { gicCallStatusChangesFxn } from './actions'

const { Option } = Select

const InputBox = ({ title, children }) => (
  <div>
    <div>{title}</div>
    {children}
  </div>
)

const CallStatusDrawer = (props) => {
  const dispatch = useDispatch()
  const { visible, onClose, onSubmit, record } = props
  const [callStatus, setCallStatus] = useState()
  const [gicCallReason, setGicCallReason] = useState('')

  const handleChangeStatus = async (record) => {
    if (!callStatus) {
      notification.warning({ message: 'Choose Status.' })
      return
    }
    if (!gicCallReason) {
      notification.warning({ message: 'Enter Reason/Details.' })
      return
    }

    const valData = {
      gicCallStatus: callStatus,
      gicCallReason,
      applicationId: record.applications._id,
      studentId: record._id
    }

    const resp = await dispatch(gicCallStatusChangesFxn(valData))
    if (resp && resp.success) {
      onSubmit()
      setGicCallReason('')
      setCallStatus('')
    }
  }

  return (
    <Drawer
      title='Update Call Status'
      visible={visible}
      width='45%'
      onClose={onClose}
    >
      <div className='card unizportal'>
        <div className='col-md-12'>
          <InputBox title='Status'>
            <Select
              name='Status'
              onChange={setCallStatus}
              filterOption={filterOption}
              allowClear
              showSearch
              placeholder='Status'
              value={callStatus}>
              <Option value='Done'>Done</Option>
              <Option value='Not Done'>Not Done</Option>
            </Select>
          </InputBox>
        </div>
        <div className='col-md-12 mt10'>
          <InputBox title={'Reason/Details'}>
            <TextArea
              value={gicCallReason}
              onChange={(e) => setGicCallReason(e.target.value)}
              placeholder='Reason/Details...'
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </InputBox>
          <Button
            type='primary'
            htmlType='submit'
            className='btn'
            onClick={() => handleChangeStatus(record)}>
            Submit
          </Button>
        </div>
      </div>
    </Drawer>
  )
}

export default CallStatusDrawer
