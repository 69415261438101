import React from "react"
import {countryIds} from "../../../components/_utils/appUtils";
import ProgramListComponent from "./ProgramListComponent";

const UKProgramList = (props) => {
    return (
        <>
            <ProgramListComponent countryId={countryIds.uk} countryName={'United Kingdom'}/>
        </>
    )
}
export default UKProgramList
