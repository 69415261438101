export const OccupationTypes = [
    {name: 'Salaried', value: 'salaried'},
    {name: 'Self Employed Professional', value: 'selfEmployedProfessional'},
    {name: 'Self Employed Business', value: 'selfEmployedBusiness'}
]

export const SalariedTypes = [
    {name: 'Public', value: 'public'},
    {name: 'Private', value: 'private'},
    {name: 'Government', value: 'government'}
]

export const SelfProfessionalTypes = [
    {name: 'Doctor', value: 'doctor'},
    {name: 'CA', value: 'ca'},
    {name: 'Architect', value: 'architect'},
    {name: 'Lawyer', value: 'lawyer'},
    {name: 'Consultant', value: 'consultant'},
    {name: 'Entertainment', value: 'entertainment'},
    {name: 'Alternate Medical Practitioner', value: 'medicalPractitioner'},
    {name: 'Beautician', value: 'beautician'},

]
export const SelfBusinessTypes = [
    {name: 'Sole Proprietorship', value: 'soleProprietorship'},
    {name: 'Partnership/Company', value: 'company'},
    {name: 'Retired', value: 'retired'},
    {name: 'Politician', value: 'politician'},
    {name: 'Student', value: 'student'},
    {name: 'Farmer', value: 'farmer'},
    {name: 'Housewife', value: 'houseWife'},
]

export const SourceFundsTypes = [
    {name: 'Savings', value: 'savings'},
    {name: 'Salary', value: 'salary'},
    {name: 'Educational Loan', value: 'educationLoan'},
    {name: 'Agriculture', value: 'agriculture'},
    {name: 'Property Sale', value: 'propertySale'},
    {name: 'Investments', value: 'investment'},
]

export const SourceIncomeTypes = [
    {name: 'Salary', value: 'salary'},
    {name: 'ProfessionalFees', value: 'professionalFees'},
    {name: 'Investment', value: 'investment'},
    {name: 'Agriculture', value: 'agriculture'},
    {name: 'Family Income', value: 'familyIncome'},
]

export const addressProofTypes = [
    {name: 'Aadhaar Card', value: 'aadhaarCard'},
    {name: 'Voter Id', value: 'voterId'},
    {name: 'Passport', value: 'passport'},
    {name: 'Driving License', value: 'drivingLicense'},
]

export const designatedCountryTypes = [
    {name: 'AU-Australia', value: 'AU'},
    {name: 'CA-Canada', value: 'CA'},
    {name: 'DE-Germany', value: 'DE'},
    {name: 'IE-Ireland', value: 'IE'},
    {name: 'KZ-Kazakhstan', value: 'KZ'},
    {name: 'LT-Lithuania', value: 'LT'},
    {name: 'MT-MAlta', value: 'MT'},
    {name: 'NL-NetherLands', value: 'NL'},
    {name: 'NZ-Newzealand', value: 'NZ'},
    {name: 'PL-Poland', value: 'PL'},
    {name: 'CH-Switzerland', value: 'CH'},
    {name: 'UAE-United Arab Emirates', value: 'UAE'},
    {name: 'GB-United Kingdom Of Great Britain and Northern Irelan', value: 'GB'},
    {name: 'US-United State Of America', value: 'US'},
]

export const PaymentMethodTypes = [
    {name: 'Western Union', value: 'WesternUnion'},
    {name: 'Flywire', value: 'Flywire'},
    {name: 'Other', value: 'Other'}
]

export const SaluationTypes = [
    {name: "MR", value: "MR"},
    {name: "MS", value: "MS"}
]

export const GenderTypes = [
    {name: 'MALE', value: 'MALE'},
    {name: 'FEMALE', value: 'FEMALE'},
    {name: 'OTHER', value: 'OTHER'}
]
export const statusTypes = [
    {name: 'PENDING', value: 'PENDING'},
    {name: 'DONE', value: 'DONE'},
]

export const relationTypes = [
    {name: 'Father', value: 'Father'},
    {name: 'Mother', value: 'Mother'},
    {name: 'Brother', value: 'Brother'},
    {name: 'Sister', value: 'Sister'},
    {name: 'Self', value: 'Self'},
    {name: 'Spouse', value: 'Spouse'}
]

export const stringToFixed = (data) => {
    if (data && typeof data == 'string') {
        data = parseFloat(data)
    }

    return data && data.toFixed(2) || 0
}
