import React, { useEffect, useState } from 'react'
import { Button, Col, Drawer, Form, Input, notification, Row, Modal, Icon } from 'antd'
import { InputBox } from '../../../components/_utils/appUtils'
import { agentUpdateBankAccountFxn, listAllAccount, addAccountSendOtpFxn } from '../../bankAccount/actions/bankAccount'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import moment from 'moment'

let initState = {
  accountId: '',
  bankName: '',
  accountNo: '',
  ifscCode: '',
  swiftCode: '',
  address: ''
}
const EditAgentBankAccount = (props) => {
  let [state, setState] = useState(initState)
  let [otp, setOtp] = useState('')
  let [visibleOtpScreen, setVisibleOtpScreen] = useState(false)
  let [cancelledCheque, setCancelledCheque] = useState({})
  let [oldCancelledCheque, setOldCancelledCheque] = useState({})
  let [refreshKey, setRefreshKey] = useState(moment())

  let { onClose, visible, accountData, onSubmit } = props
  let dispatch = useDispatch()

  const handleChange = (data) => {
    setState(prevData => {
      return {
        ...prevData,
        ...data
      }
    })
  }
  useEffect(() => {
    if (accountData && accountData._id) {
      setDefaultFields()
    }
  }, [accountData])

  let setDefaultFields = () => {
    setState((prevState) => {
      return {
        accountId: accountData._id,
        bankName: accountData.bankName,
        accountNo: accountData.accountNo,
        ifscCode: accountData.ifscCode,
        swiftCode: accountData.swiftCode,
        address: accountData.address
      }
    })
    if (accountData && accountData.cancelledCheque && accountData.cancelledCheque.path) {
      setOldCancelledCheque(accountData.cancelledCheque)
    }
  }

  let chooseDocument = (e) => {
    let { files } = e.target
    if (files && files.length) {
      setCancelledCheque(files[0])
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!state.bankName) {
      notification.warning({ message: 'Enter bank name' })
      return
    }
    if (!state.accountNo) {
      notification.warning({ message: 'Enter account number' })
      return
    }
    if (!state.ifscCode) {
      notification.warning({ message: 'Enter IFSC code' })
      return
    }
    if (!state.swiftCode) {
      notification.warning({ message: 'Enter Swift code' })
      return
    }
    if (!cancelledCheque || (cancelledCheque && !cancelledCheque.name)) {
      notification.warning({ message: 'Choose Cancelled Cheque.' })
      return
    }
    if (!state.address) {
      notification.warning({ message: 'Enter bank address' })
      return
    }

    let resp = await dispatch(addAccountSendOtpFxn(state))
    if (resp && resp.success) {
      setVisibleOtpScreen(true)
    }
  }
  const handleSubmitAddAccount = async (e) => {
    e.preventDefault()
    if (!state.bankName) {
      notification.warning({ message: 'Enter bank name' })
      return
    }
    if (!state.accountNo) {
      notification.warning({ message: 'Enter account number' })
      return
    }
    if (!state.ifscCode) {
      notification.warning({ message: 'Enter IFSC code' })
      return
    }
    if (!state.swiftCode) {
      notification.warning({ message: 'Enter Swift code' })
      return
    }
    if (!cancelledCheque || (cancelledCheque && !cancelledCheque.name)) {
      notification.warning({ message: 'Choose Cancelled Cheque.' })
      return
    }
    if (!state.address) {
      notification.warning({ message: 'Enter bank address' })
      return
    }

    if (!otp) {
      notification.warning({ message: 'Please enter otp' })
      return
    }
    let fd = new FormData()
    fd.append('obj', JSON.stringify({ ...state, otp }))
    if (cancelledCheque && cancelledCheque.name) {
      fd.append('cancelledCheque', cancelledCheque)
    }

    let resp = await dispatch(agentUpdateBankAccountFxn(fd))
    if (resp && resp.success) {
      onSubmit()
    }
  }
  return (
    <Drawer
      visible={visible}
      title={'Edit Bank Account'}
      onClose={onClose} width={'60%'}>
      <div className='card unizportal'>
        <Form className={'vertical-form'} autoComplete='off' onSubmit={handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                <InputBox title={'Bank Name'}>
                  <Input onChange={({ target }) => {
                    handleChange({ bankName: target.value })
                  }} value={state.bankName} className={'form-control'} name='bankName'
                         placeholder='Please enter bank name' />
                </InputBox>

              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <InputBox title={'Account Number'}>
                  <Input onChange={({ target }) => {
                    handleChange({ accountNo: target.value })
                  }} value={state.accountNo} className={'form-control'}
                         name='accountNo' placeholder='Please enter Account Number' />
                </InputBox>

              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <InputBox title={'IFSC Code'}>
                  <Input onChange={({ target }) => {
                    handleChange({ ifscCode: target.value })
                  }} value={state.ifscCode} placeholder='Please enter IFSC Code'
                         name='ifscCode' className={'form-control'} />
                </InputBox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <InputBox title={'Swift Code'}>
                  <Input onChange={({ target }) => {
                    handleChange({ swiftCode: target.value })
                  }} value={state.swiftCode} placeholder='Please enter Swift Code'
                         name='swiftCode' className={'form-control'} />
                </InputBox>
              </Form.Item>

            </Col>
            <Col span={12} key={refreshKey}>
              <Form.Item>
                <InputBox title={'Cancelled Cheque'} className={'rowFlex'}>
                  <Input type={'file'}
                         onChange={(e) => {
                           chooseDocument(e)
                         }} placeholder='Please choose Cancelled Cheque'
                         name='cancelledCheque' className={'form-control'} />
                  {oldCancelledCheque && oldCancelledCheque.path ?
                    <a className={'linkBtn'} target={'_blank'}
                       href={oldCancelledCheque.path}>
                      <Icon type={'eye'}></Icon>
                    </a> : null}
                </InputBox>
              </Form.Item>

            </Col>
            <Col span={24}>
              <Form.Item><InputBox title={'Address'}>
                <Input.TextArea rows={4} onChange={({ target }) => {
                  handleChange({ address: target.value })
                }} value={state.address} placeholder='please enter address' name='address'
                                className={'form-control'} />
              </InputBox>
              </Form.Item>
            </Col>
          </Row>
          <Button type='primary' htmlType='submit' className={'btn'}>
            Update
          </Button>
        </Form>
      </div>
      <Modal
        visible={visibleOtpScreen}
        closable={false}
        title={'Enter OTP'}
        footer={false}
      >
        <div style={{ textAlign: 'center', marginBottom: 10 }}>
          <h6> A text message with a
            4-digit verification code has been sent to your phone number.
          </h6>
          <InputBox title={'Enter OTP'}>
            <input
              className={'form-control mt10'}
              type={'password'}
              minLength={4}
              maxLength={4}
              value={otp} onChange={({ target }) => {
              setOtp(target.value)
            }} />
          </InputBox>
          <Button className={'btn btn-success mt10'} onClick={handleSubmitAddAccount}>
            Submit OTP
          </Button>
        </div>
      </Modal>
    </Drawer>
  )
}
export default EditAgentBankAccount
