import {Drawer} from 'antd'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import {useDispatch} from 'react-redux'
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    ResponsiveContainer,
    Tooltip,
    YAxis
} from 'recharts'
import {displayDate} from '../../../components/_utils/appUtils'
import {
    footFallRecordForBarChartFxn,
    universityPieChartCount
} from '../actions/dashboardAction'

const $ = window.$
const COLORS = [
    '#49457B',
    '#7DB3FF',
    '#FFC107',
    '#FF7C78',
    '#FED3D0',
    '#6F76D9',
    '#9ADFB4',
    '#2E7987',
    '#138D75',
    '#2E4053',
    '#148F77',
    '#3498DB',
    '#239B56',
    '#212F3C',
    '#21618C',
    '#5B2C6F',
    '#512E5F'
]
const FootFallDetaisGraph = props => {
    const {visible, onClose} = props

    const dispatch = useDispatch()
    let [footFallData, setFootfallData] = useState([])
    useEffect(() => {
        loadUniversityWiseData()
    }, [])
    const loadUniversityWiseData = async () => {
        let resp = await dispatch(footFallRecordForBarChartFxn({}))
        setFootfallData(resp.data)
    }

    const getWidth = () => {
        setTimeout(() => {
            if ($('#universityChart')) {
                return $('#universityChart').width()
            }
        }, 500)
    }

    const sortName = text => {
        return text.substring(0, 15) + ' ...'
    }
    const RADIAN = Math.PI / 180
    const renderLabelContent = props => {
        const {count, percent, x, y, midAngle, name} = props
        return (
            <g
                transform={`translate(${x}, ${y})`}
                textAnchor={midAngle < -90 || midAngle >= 90 ? 'end' : 'start'}>
                <text x={0} y={0}>{`${sortName(name)}: ${count}`}</text>
                <text x={0} y={20}>{`(Per: ${(percent * 100).toFixed(2)}%)`}</text>
            </g>
        )
    }
    const CustomTooltip = ({active, payload, date}) => {
        if (active) {
            let count =
                payload && payload.length
                    ? payload[0] && payload[0].value
                        ? payload[0].value
                        : 0
                    : 0
            return (
                <div className="custom-tooltip">
                    <p className="label">{displayDate(date)}</p>
                    <br/>
                    <p className="value">{`Total Agents : ${count}`}</p>
                </div>
            )
        }

        return null
    }
    return (
        <Drawer
            visible={visible}
            width={'71%'}
            placement="right"
            onClose={onClose}
            title={'Agent Footfall Graph'}>
            {footFallData && footFallData.length ?
                <div className="card unizportal">
                    <div className="table-head d-flex align-items-center">
                        <h5>Count of Agents Date Wise</h5>
                        <div className="search-box-table"/>

                        <div className="sort-box-table"/>
                    </div>
                    <div
                        className="card-body table-responsive barChart"
                        id={'universityChart'}>
                        <ResponsiveContainer width={getWidth()} height={300}>
                            <BarChart width={500} height={170} data={footFallData}>
                                <CartesianGrid strokeDasharray="10 3"/>
                                {/*<XAxis dataKey="name"/>*/}
                                <YAxis/>
                                <Tooltip content={<CustomTooltip/>}/>
                                <Legend layout="vertical"/>

                                <Bar dataKey="count" barSize={15}>
                                    {footFallData.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}-${moment()}`}
                                            fill={COLORS[index]}
                                        />
                                    ))}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>

                        <ul className={'barLabel_ul'}>
                            {footFallData.map((entry, index) => (
                                <li key={`label-${index}`}>
                                    <div
                                        className={'colorBox'}
                                        style={{background: COLORS[index]}}
                                    />
                                    ({displayDate(entry.date)})
                                </li>
                            ))}
                        </ul>
                    </div>
                </div> : null}
        </Drawer>
    )
}
export default FootFallDetaisGraph
