import React, {useEffect, useState} from 'react'
import {notification, Form, Button} from 'antd'
import {useDispatch} from 'react-redux'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import {getUniversity} from "../../login/action";
import {
    fcmtUniversity,
    CasaHCollege,
    CambieHCollege,
    RoyalArtsCollege,
    KasaHighSchool
} from "../../../components/_utils/appUtils";
import Request from "../../../request"
import {apiUrl} from "../../../settings";
import {FcmtUniversity} from "../../dashboard/views/adminDashboard";

const fields = {
    courseUniversity: "",
    studentIdNo: "",
    dateOfBirth: "",
}
const studentState = {
    studentId: "",
    applicationId: "",
    url: ""
}
const VerifyOfferLetter = (props) => {
    const dispatch = useDispatch()
    let [universityList, setUniversityList] = useState([])
    let [state, setState] = useState(fields)
    let [url, setUrl] = useState("")
    const setData = (e) => {
        let {value, name} = e.target
        setState({...state, [name]: value})
    }
    const chooseUniversity = (e) => {
        let {value, name} = e.target
        setState((prev) => {
            return {
                ...prev,
                [name]: value,
                studentIdNo: "",
                dateOfBirth: ""
            }
        })
    }
    useEffect(() => {
        events.getUniversity()
    }, [])


    const events = {
        getUniversity: async () => {
            // fcmtUniversity
            // RoyalArtsCollege, CambieHCollege
            let {data} = await getUniversity({
                results: 10, sortField: 'universityName',
                customQuery: {
                    _id: {$in: [fcmtUniversity, CasaHCollege, KasaHighSchool]}
                },
                sortOrder: 'ascend', select: ['universityName', 'applicationFee']
            })
            setUniversityList(data);
        },
    }


    const searchStudentOfferLetter = async () => {
        let {courseUniversity, studentIdNo, name, dateOfBirth} = state
        if (!courseUniversity) {
            notification.warning({message: 'Choose course university.'})
            return
        }
        /*if (courseUniversity == CasaHCollege) {
            if (!studentIdNo) {
                notification.warning({message: 'Please Enter File No.'})
                return
            }
        }*/
        if (courseUniversity == fcmtUniversity) {
            if (!name) {
                notification.warning({message: 'Please Enter student name.'})
                return
            }
            if (!dateOfBirth) {
                notification.warning({message: 'Please Choose student date of birth.'})
                return
            }
        } else {
            if (!studentIdNo) {
                notification.warning({message: 'Please Enter File No.'})
                return
            }
        }

        let resp = await Request.getStudentOfferLetterFxn(state);
        let {success, message, data} = resp;
        if (success) {
            let {_id, applications} = data;
            let urlName = "fcmt_conditions"
            if (courseUniversity == CasaHCollege) {
                urlName = 'casa-letter-webview'
            } else if (courseUniversity == KasaHighSchool) {
                urlName = 'kasa-letter-webview'
            } else if (courseUniversity == RoyalArtsCollege) {
                urlName = 'royal_art_conditions'
            } else if (courseUniversity == CambieHCollege) {
                urlName = 'cambieOfferLetter'
            } else {

            }
            let url = `${apiUrl}/${urlName}/${_id}?courseId=${applications._id}`
            setUrl(url)
        } else {
            notification.warning({message: message})
        }
    }

    return (
        <React.Fragment>
            <section className="contact-form verifyOfferBox">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <h3>Verify Offer Letter</h3>
                            <div className="box">
                                <Form>
                                    <div className="form-group">
                                        <label>University</label>
                                        <select className="form-control" name={'courseUniversity'}
                                                onChange={(item) => {
                                                    chooseUniversity(item)
                                                    setUrl("")
                                                }}
                                                value={state.courseUniversity}>
                                            <option value={''}>Choose University</option>
                                            {universityList && universityList.length ? universityList.map((item, key) => {
                                                return (
                                                    <option key={key} value={item._id}>{item.universityName}</option>
                                                )
                                            }) : null}
                                        </select>
                                    </div>
                                    {/* {state.courseUniversity == CasaHCollege ?
                                        <div className="form-group">
                                            <label>File No.</label>
                                            <input type="text" className="form-control"
                                                   name={'studentIdNo'}
                                                   value={state.studentIdNo} onChange={setData}/>
                                        </div> : null}*/}
                                    {state.courseUniversity && <>
                                        {state.courseUniversity == fcmtUniversity ?
                                            <>
                                                <div className="form-group">
                                                    <label>Student Name</label>
                                                    <input type="text" className="form-control"
                                                           name={'name'}
                                                           value={state.name} onChange={setData}/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Date of birth</label>
                                                    <input type="date" className="form-control"
                                                           name={'dateOfBirth'}
                                                           value={state.dateOfBirth} onChange={setData}/>
                                                </div>

                                            </>
                                            : <div className="form-group">
                                                <label>File No.</label>
                                                <input type="text" className="form-control"
                                                       name={'studentIdNo'}
                                                       value={state.studentIdNo} onChange={setData}/>
                                            </div>}
                                    </>}

                                    <div className={'alignRight'}>
                                        <button type="submit" className="btn btn-primary"
                                                onClick={() => searchStudentOfferLetter()}>Verify
                                        </button>
                                    </div>
                                </Form>

                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="content">
                                <div className="store">
                                    <LazyLoadImage src={'../newImages/Verification-offer_letter.png'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {url ? <div className={'iframeBox'}>
                    <iframe
                        src={url}
                        frameBorder="0" style={{width: "100%", height: "120vh"}}/>
                </div> : null}
            </section>
        </React.Fragment>
    )
}
export default VerifyOfferLetter
