import React, {useState, useEffect, useRef} from 'react'
import AllStudentList from "../components/allStudentList";
import OnShoreStudentList from "../components/onShoreStudentList";

const ListAllStudents = (props) => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

    return (
        <>

            {/*{user && user._id == "64f5d170f9b37ef04115da51" ? <OnShoreStudentList user={user}/> :
                <AllStudentList user={user}/>}*/}

            <AllStudentList user={user}/>
        </>
    )
}

export default ListAllStudents
