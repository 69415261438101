import { Button, Drawer, InputNumber, Select, Form, Row, Col, notification } from 'antd'
import style from '../views/styles.less'
import React, { useEffect, useState } from 'react'

const Option = Select
let initaleState = {
  examType: '',
  qualification: '',
  overall: '',
  listening: '',
  speaking: '',
  writing: '',
  reading: ''
}
const EnglishRequirement = (props) => {
  let { onClose, visible, addEnglishReq, data } = props
  let [state, setState] = useState(initaleState)

  let setExamState = (data) => {
    setState({ ...state, ...data })
  }
  useEffect(() => {
    setDefaultExamData()
  }, [])

  const setDefaultExamData = () => {
    setState({ ...data })
  }

  let addExam = () => {
    let {
      examType,
      qualification,
      overall,
      listening,
      speaking,
      writing,
      reading
    } = state
    if (!examType) {
      return notification.warning({ message: 'Choose Exam Type' })
    }
    addEnglishReq(state)
  }

  return (
    <Drawer width={800}
            title={'English Requirement'} placement="right"
            visible={visible}
            onClose={onClose}>

      <div className="row">
        <div className="col-lg-12">
          <Form noValidate>
            <div className="form-box mt-4">

              <div className="card unizportal">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label={'Choose Exam'}>
                      <Select
                        placeholder={'Choose Exam'}
                        value={state.examType}
                        onChange={ei => {
                          setState({ ...state, examType: ei })
                        }}>
                        <Option value={'IELTS'}>IELTS</Option>
                        <Option value={'PTE'}>PTE</Option>
                        <Option value={'TOEFL'}>TOEFL</Option>
                        <Option value={'Duolingo English Test'}>Duolingo English Test</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {/*{state.examType == 'IELTS' || state.examType == 'PTE' ?
                    <Col span={12}>
                      <Form.Item label={'Choose Exam'}>
                        <Select
                          placeholder={'Qualification'}
                          value={state.qualification}
                          onChange={ei => {
                            setState({ ...state, qualification: ei })
                          }}>
                          <Option value={'Bachelor Degree'}>Bachelor Degree</Option>
                          <Option value={'Post Graduation'}>Post Graduation</Option>
                        </Select>
                      </Form.Item>
                    </Col> : null}*/}
                  <Col span={12}>
                    <Form.Item label={'Overall'}>
                      <InputNumber
                        placeholder={'Overall'}
                        type={'number'}
                        value={state.overall}
                        onChange={ei => {
                          setExamState({ overall: ei })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={'Listening'}>
                      <InputNumber
                        placeholder={'Listening'}
                        type={'number'}
                        value={state.listening}

                        onChange={ei => {
                          setExamState({ listening: ei })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={'Reading'}>
                      <InputNumber
                        placeholder={'Reading'}
                        type={'number'}
                        value={state.reading}
                        onChange={ei => {
                          setExamState({ reading: ei })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={'Writing'}>
                      <InputNumber
                        placeholder={'Writing'}
                        type={'number'}
                        value={state.writing}
                        onChange={ei => {
                          setExamState({ writing: ei })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={'Speaking'}>
                      <InputNumber
                        placeholder={'Speaking'}
                        type={'number'}
                        value={state.speaking}
                        onChange={ei => {
                          setExamState({ speaking: ei })
                        }}
                      />
                    </Form.Item>
                  </Col>

                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        type="primary" htmlType="submit" className="btn"
                        onClick={() => addExam()}>
                        Add
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      </div>

    </Drawer>
  )
}

export default EnglishRequirement
