import React, { useRef } from 'react'
import { Drawer } from 'antd'
import { TableComp } from 'sz-react-utils-lite'
import { DefaultTablePagination } from '../../../components/_utils/appUtils'
import { displayDate, displayTime } from '../../../components/_utils/appUtils'
import { getUniversityCommissionActivityFxn } from '../actions/chooseStudent'

const UniversityCommissionActivityComponent = (props) => {
  let { commissionId, visible, onClose, commission } = props
  let tableRef = useRef()
  let apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.sortField = '_id'
      params.sortOrder = 'ascend'
      params.commissionId = commissionId
      let resp = await getUniversityCommissionActivityFxn({
        ...params,
        regExFilters: ['name', 'address', 'mobile', 'email']
      })
      resolve(resp)
    })
  }
  const columns = [
    {
      title: '#',
      key: '_id',
      dataIndex: '_id',
      width: 50,
      render: (ite, record, index) => {
        return (
          <React.Fragment>
            {index + 1}
          </React.Fragment>
        )
      }
    },

    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 150,
      key: 'createdAt',
      render: (item) => {
        return item ? displayDate(item) : null
      }
    },
    {
      title: 'Activity',
      dataIndex: 'message',
      key: 'message'
    },
    {
      title: 'User',
      dataIndex: 'userId',
      key: 'userId',
      width: 160,
      render: (item) => {
        return (
          <>
            {item && item.name ? item.name : ''}
            {item && item.email ? ` (${item.email})` : ''}
          </>
        )
      }
    }
  ]

  let title = `Activities - ${commission.name} - (${commission.semester})`

  return (
    <Drawer visible={visible}
            title={title}
            width={'65%'}
            onClose={onClose}>
      <div className='card'>
        <div className='card-body table-responsive'>
          <TableComp columns={columns}
                     ref={tableRef}
                     pagination={DefaultTablePagination()}
                     apiRequest={apiRequest}
          />
        </div>
      </div>
    </Drawer>
  )
}


export default UniversityCommissionActivityComponent
