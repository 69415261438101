import React, { Component } from 'react'
import {
  Card,
  Input, Button, Icon, Drawer, Tag
} from 'antd'
import { connect } from 'react-redux'
import { getPushPathWrapper } from '../../../routes'
import { ColumnWidth } from '../../WebComponent/columnWidth'
import { getUserLogs, listAllUsers } from '../actions/user'
import { TableComp } from 'sz-react-utils-lite'
import { DefaultTablePagination, departmentObj } from '../../../components/_utils/appUtils'
import { displayDate, displayTime } from '../../../components/_utils/appUtils'

class ApprovedAgentManagerList extends Component {

  constructor(props) {
    super(props)
    this.state = {}
    this.tableRef = React.createRef()
  }

  componentDidMount() {
  }

  apiRequest = (params) => {
    let { dispatch, agentId } = this.props
    return new Promise(async (resolve) => {
      params.sortField = 'name'
      params.sortOrder = 'ascend'
      params.customQuery = {
        agents: { $in: agentId },
        department: departmentObj.marketing
      }
      resolve(dispatch(listAllUsers({
        ...params,
        regExFilters: ['name', 'address', 'mobile', 'email']
      })))
    })
  }

  render() {
    let {} = this.state
    let { dispatch, visible, onClose, user } = this.props
    const columns = [
      {
        title: '#',
        key: '_id',
        dataIndex: '_id',
        width: 50,
        render: (ite, record, index) => {
          return (
            <React.Fragment>
              {index + 1}
            </React.Fragment>
          )
        }
      },
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        width: 100,
        render: (item) => {
          return (
            <ColumnWidth width={100}>{item}</ColumnWidth>
          )
        }
      },
      {
        title: 'email',
        dataIndex: 'email',
        key: 'email',
        width: 120,
        render: (item) => {
          return (
            <div>
              {item}
            </div>
          )
        }
      }
    ]
    return (
      <Drawer visible={visible}
              title={'Approve Agent Manager List'}
              width={'70%'}
              onClose={onClose}>
        <Card>
          <div className='student-application-detail'>
            {/* <div className="detail-list w-100 card">
              <ul>
                <li>
                  <strong>Name</strong>
                  <div> {user.name ?
                    <Tag className={'successTag'}>{user.name}</Tag> : null}</div>
                </li>
                <li>
                  <strong>Email</strong>
                  <div> {user.email ?
                    <Tag className={'successTag'}>{user.email}</Tag> : null}</div>
                </li>
              </ul>
            </div>*/}
            <TableComp columns={columns}
                       ref={this.tableRef}
                       pagination={DefaultTablePagination()}
                       apiRequest={this.apiRequest}
                       extraProps={{ scroll: { x: 1000 } }}
            />
          </div>
        </Card>
      </Drawer>
    )

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApprovedAgentManagerList)
