import React from "react"
import {Icon, Tooltip} from "antd";

const DownloadLinkComponent = (props) => {
    let {file} = props
    return (
        <>
            {file && file.url ?
                <Tooltip title={file.name}>
                    <a className={'linkBtn'} target={'_blank'}
                       href={file.url}>
                        <Icon type={'eye'}></Icon>
                    </a> </Tooltip> : ''}
        </>
    )
}
export default DownloadLinkComponent
