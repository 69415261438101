import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, connect } from 'react-redux'
import AddContract from './add'
import EditContract from './edit'
import { TableComp } from 'sz-react-utils-lite'
import {
  contractWith,
  displayDate
} from '../../../components/_utils/appUtils'
import { getContractGroups, listAllContract } from '../actions/contract'
import { Col, Icon, Row, Tooltip } from 'antd'
import _ from 'lodash'
import moment from 'moment'

const universityTypeList = [
  { name: 'Already Added in Portal', value: 'alreadyAdded' },
  { name: 'Not Added in Portal', value: 'other' }
]

const ContractList = (props) => {
  const tableRef = useRef()
  const dispatch = useDispatch()
  let [totalRecord, setTotalRecord] = useState(0)
  const [itemNo, setItemNo] = useState(1)
  const [addContractVisible, setAddContractVisible] = useState(false)
  const [editContractVisible, setEditContractVisible] = useState(false)
  const [contractId, setContractId] = useState('')

  const [groupData, setGroupData] = useState(contractWith)

  const events = {
    hideDrawer: () => {
      setAddContractVisible(false)
      tableRef.current.reload()
    },
    hideEditDrawer: () => {
      setEditContractVisible(false)
      tableRef.current.reload()
    }
  }
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let respData = await dispatch(listAllContract({
        ...params
        // regExFilters: ['semester', 'courseName', 'name']
      }))
      setTotalRecord(respData.total)
      resolve(respData)
    })
  }
  const getContractWithGroups = async (params = {}) => {
    let respData = await dispatch(getContractGroups({
      ...params
    }))
    let cloneDoc = _.clone(contractWith)
    _.each(cloneDoc, (item) => {
      let findVal = _.find(respData.data, (doc) => {
        return doc._id == item.name
      })
      if (findVal) {
        item.value = findVal.count
      }
    })
    setGroupData(cloneDoc)
  }

  useEffect(() => {
    getContractWithGroups()
  }, [])


  const handleDownload = (item) => {
    const anchor = document.createElement('a')
    anchor.href = item.path
    anchor.download = item.fileName
    anchor.target = '_blank' // Open in a new tab
    anchor.click()
  }

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (item, record, index) => {
        return (
          <a className={'linkPointer'}>
            {(itemNo - 1) * 10 + index + 1}
          </a>
        )
      }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 120,
      render: (item, record) => {
        let { endDate } = record
        let noOfDays = moment(endDate).diff(moment(), 'days')
        return (
          <div className={'alignCenter'}>
            {noOfDays <= 15 && noOfDays > 0 ?
              <label className={'label label-warning label-sm'}>Expired
                in {(noOfDays)} {noOfDays == 1 ? 'Day' : 'Days'}</label> : ''}
            {noOfDays > 15 ? <label className={'label label-success  label-sm'}>Active</label> : ''}
            {noOfDays <= 0 ? <label className={'label label-danger  label-sm'}>Expired</label> : ''}
          </div>
        )
      }
    },
    {
      title: 'Contract With',
      key: 'contractWith',
      dataIndex: 'contractWith',
      width: 170
    },
    // {
    //   title: 'Status',
    //   render: (item, record) => {
    //     return (
    //       <div style={{ width: 150 }}>
    //         {item ? displayDate(item) : ''}
    //       </div>
    //     )
    //   }
    // },
    {
      title: 'Country',
      dataIndex: 'countryId',
      key: 'countryId',
      width: 150,
      render: (item) => {
        return (
          item && item.countryName ? item.countryName : ''
        )
      }
    },
    {
      title: 'University',
      dataIndex: 'universityType',
      key: 'universityType',
      width: 150,
      render: (item, record) => {
        let { universityId, universityName } = record
        return (
          <div>
            {item == 'alreadyAdded' && universityId && universityId.universityName ? universityId.universityName : null}
            {item == 'other' && universityName ? universityName : null}
          </div>
        )
      }
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 120,
      render: (item) => {
        return (
          item ? displayDate(item) : ''
        )
      }
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 120,
      render: (item) => {
        return (
          item ? displayDate(item) : ''
        )
      }
    },

    {
      title: 'Contract',
      dataIndex: 'contract',
      key: 'contract',
      width: 150,
      render: (item) => {
        return (
          <React.Fragment>
            <a className={'btn btn-link btn-xs'} onClick={() => handleDownload(item)}>Download</a>
          </React.Fragment>
        )
      }
    },
    {
      title: 'Details',
      key: 'details',
      dataIndex: 'details'
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: 100,
      render: (_, record) => (
        <>
          <Tooltip title='Update Contract'>
            <a className={'btn btn-default btn-sm'} onClick={() => {
              setEditContractVisible(true)
              setContractId(record._id)
            }}>
              <Icon type={'edit'} /> Edit
            </a>
          </Tooltip>
        </>
      )
    }
  ]

  const GroupingTags = (
    <>
      <div className={'reportBox'} style={{ marginTop: 0 }}>
        <div className={'custom_row'}>
          {groupData.map((item, key) => {
            return (
              <div className={'custom_col'}>
                <div className={'reportingBox'}>
                  <div className={'reportingInnerBox'}>
                    <img src={'../assets/userIcon.png'} alt={'No image'} />
                    <div className={'reportInfoBox'}>
                      {item.name} ({item.value})
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )

  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card reportContainer'>
          <div className='table-head d-flex align-items-center'>
            <h5>All Contract: ({totalRecord})</h5>
            <div className='search-box-table'>
            </div>
            <div className='sort-box-table mark-btn'>
              <button
                className='btn'
                onClick={() => setAddContractVisible(true)}
              >
                <img src={'../dist/icons/plus.png'} className={'plus'} />
                Add Contract
              </button>
            </div>
          </div>
          <div className='card-body table-responsive'>
            {/*{FilterComponent}*/}
            {GroupingTags}
            <TableComp columns={columns}
                       apiRequest={apiRequest}
                       ref={tableRef}
              // pagination={DefaultTablePagination()}
            />
          </div>
        </div>
      </div>
      {addContractVisible ?
        <AddContract
          visible={addContractVisible}
          universityTypeList={universityTypeList}
          events={events}
        /> : ''}
      {editContractVisible ?
        <EditContract
          visible={editContractVisible}
          universityTypeList={universityTypeList}
          events={events}
          id={contractId}
        /> : ''}
    </div>
  )
}
export default connect(
)(ContractList)
