import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import {
  addStudentInsuranceUrl,
  getStudentInsuranceUrl,
  updateInsuranceStatusUrl,
  uploadInsuranceUrl
} from '../apis/apis'
import { notification } from 'antd'


export const addStudentInsurance = (valData) => async (dispatch) => {
  dispatch(showPageLoad())

  let { data } = await axios.post(addStudentInsuranceUrl(), valData, getToken())
  console.log(valData)
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getInsuranceList = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(getStudentInsuranceUrl(), config)
  console.log('dattatat', data)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const updateInsuranceStatus = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateInsuranceStatusUrl(), valData, getToken())
  console.log(valData)
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const uploadInsurancefile = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(uploadInsuranceUrl(), valData, getToken())
  console.log(valData)
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


