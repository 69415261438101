import React, {useEffect, useState, Suspense} from "react"

const MeetingRecordGraph = React.lazy(() => import('../components/meetingRecordGraph'))
const ExpenseRecordGraph = React.lazy(() => import('../components/expenseRecordGraph'))
const TopAgentVisaRecordGraph = React.lazy(() => import('../components/topAgentVisaRecordGraph'))
const StateWiseRecords = React.lazy(() => import('../components/stateWiseRecords'))
const CountryWiseReportComponent = React.lazy(() => import('../marketingUserComponents/countryWiseReportComponent'))
const MarketingUserInfo = React.lazy(() => import('../components/marketingUserInfo'))


const MarketingUserDashboard = () => {
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    let [currentUserId, setCurrentUserId] = useState("")
    useEffect(() => {
        loadUrl()
    }, [])
    let loadUrl = () => {
        let searchParams = new URLSearchParams(window.location.search)
        let userId = searchParams.get('id');
        if (userId) {
            setCurrentUserId(userId)
        }
    }

    return (
        <>
            <div key={currentUserId}>
                <Suspense fallback={<></>}>
                    <MarketingUserInfo userId={currentUserId ? currentUserId : user._id}/>
                </Suspense>

                <Suspense fallback={<></>}>
                    <CountryWiseReportComponent currentUserId={currentUserId}/>
                </Suspense>

                <Suspense fallback={<></>}>
                    <MeetingRecordGraph currentUserId={currentUserId}/>
                </Suspense>

                <Suspense fallback={<></>}>
                    <ExpenseRecordGraph currentUserId={currentUserId}/>
                </Suspense>

                <Suspense fallback={<></>}>
                    <TopAgentVisaRecordGraph currentUserId={currentUserId}/>
                </Suspense>

                <Suspense fallback={<></>}>
                    <StateWiseRecords currentUserId={currentUserId}/>
                </Suspense>

            </div>
        </>
    )
}
export default MarketingUserDashboard
