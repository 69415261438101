import { Button, Card, Drawer, Input, Form, notification, Select } from 'antd'
import { InputBox, DocumentTypes } from '../../../components/_utils/appUtils'
import React, { useState } from 'react'
import { documentUpload, uploadApDocument } from '../../student/actions/student'
import moment from 'moment'
import { connect, useDispatch } from 'react-redux'

const initialState = {
  documentDrawerVisible: false,
  description: '',
  document: {},
  documentKey: moment(),
  documentName: ''
}
const { TextArea } = Input
const { Option } = Select
const UploadDocumentComponent = (props) => {
  const dispatch = useDispatch()
  let [state, setState] = useState(initialState)
  let [documents, setDocuments] = useState([])
  let { studentId, applicationId, visible, onClose, title, page } = props

  const events = {
    chooseDocument: (e) => {
      let { name, files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          document: files[0]
        })
      }
    },
    handleSubmit: async () => {
      let { document, documentName, documentType } = state
      if (!title) {
        if (!documentType) {
          return notification.error({
            message: 'Please choose document type'
          })
        }
        if (documentType == 'Other' && !documentName) {
          return notification.error({
            message: 'Please enter document name'
          })
        }
      }


      // documentType = title ? title : state.documentName.trim()


      if (!document || (document && !document.name)) {
        return notification.error({
          message: 'Please choose document'
        })
      }
      let obj = {
        _id: studentId,
        description: state.description,
        documentType: title ? title : documentType == 'Other' ? state.documentName.trim() : documentType,
        // documentName: state.documentName,
        applicationId: applicationId
      }

      let fd = new FormData()
      fd.append('obj', JSON.stringify(obj))
      let docFieldName = title
      if (title) {
        docFieldName = DocumentTypes.includes(title) ? title : 'Other'
      } else {
        if (documentType == 'Other') {
          docFieldName = 'Other'
        } else {
          docFieldName = DocumentTypes.includes(documentType) ? documentType : 'Other'
        }
      }
      console.log('==========docFieldName', docFieldName)
      fd.append([docFieldName], state.document)
      let data = await dispatch(documentUpload(fd))
      if (data && !data.error) {
        if (state.description && data.noteId) {
          dispatch({ type: 'START_PUSH_NOTE_BY_REDUX', noteId: data.noteId })
        }
        setState({
          ...state,
          documentName: '',
          description: '',
          document: '',
          documentKey: moment(),
          documentDrawerVisible: false
        })
        onClose()
        dispatch({ type: 'START_LOAD_DOCUMENT' })
        dispatch({ type: 'START_LOAD_ACTIVITY' })
        dispatch({ type: 'START_LOAD_APP_DOCUMENT' })

      }
    },
    handleAppSubmit: async () => {
      let { document } = state
      if (!document || (document && !document.name)) {
        return notification.error({
          message: 'Please choose document'
        })
      }
      let obj = {
        _id: studentId,
        description: state.description,
        status: title,
        applicationId: applicationId
      }
      let fd = new FormData()
      fd.append('obj', JSON.stringify(obj))
      fd.append('document', state.document)
      let data = await dispatch(uploadApDocument(fd))
      if (data && !data.error) {
        if (state.description && data.noteId) {
          dispatch({ type: 'START_PUSH_NOTE_BY_REDUX', noteId: data.noteId })
        }
        setState({
          ...state,
          description: '',
          document: '',
          documentKey: moment(),
          documentDrawerVisible: false
        })
        onClose()
        dispatch({ type: 'START_LOAD_APP_DOCUMENT' })
        dispatch({ type: 'START_LOAD_ACTIVITY' })

      }
    }
  }

  return (
    <Drawer width={700}
            title={'Upload Documents'}
            placement='right'
            visible={visible}
            onClose={onClose}>
      <Card>
        <Form>
          {!title ?
            <div>

              <InputBox title={`Document Type`}>
                <Select name={'documentType'} id={'documentType'} value={state.documentType}
                        onSelect={(value) => {
                          setState({
                            ...state,
                            documentType: value
                          })
                        }}>
                  {DocumentTypes.map((item, key) => {
                    return (
                      <Option key={key} value={item}>{item}</Option>
                    )
                  })}
                </Select>
              </InputBox>

              {state.documentType == 'Other' ?
                <InputBox title={`Document Name`}>
                  <Input name={'documentName'} id={'documentName'} value={state.documentName} className={'form-control'}
                         onChange={(e) => {
                           let { value } = e.target
                           setState({
                             ...state,
                             documentName: value ? value.toUpperCase() : ''
                           })
                         }} />
                </InputBox> : null}
            </div>
            : ''}

          <InputBox title={`Choose ${title ? title : ''} Documents`}>
            <Input type={'file'} name={title} id={title} key={state.documentKey}
                   className={'form-control'}
                   onChange={(e) => {
                     events.chooseDocument(e)
                   }} />
          </InputBox>


          <InputBox title={'Document Description'}>
            <TextArea rows={4} name={'description'} id={'description'} placeholder={'Description'}
                      value={state.description}
                      className={'form-control'}
                      onChange={(e) => {
                        setState({
                          ...state,
                          description: e.target.value
                        })
                      }} />
          </InputBox>
          <Form.Item className={'mt20'}>
            <Button type='primary' onClick={page == 'document' ? events.handleSubmit : events.handleAppSubmit}>
              SUBMIT
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  )
}


const mapStateToProps = ({ global, router, chooseApplication }) => ({})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(UploadDocumentComponent)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedComponent)

