import React from 'react'
import { Avatar, List, Icon } from 'antd'
import classNames from 'classnames'
import styles from './PrivateMessage.less'
import profileImg from '../../assets/profile-user.png'
import { displayDate } from '../_utils/appUtils'

export default function PrivateMessageList({
                                             data = [],
                                             onClick,
                                             viewAll,
                                             onClear,
                                             title,
                                             locale,
                                             emptyText,
                                             emptyImage,
                                             showClear = true
                                           }) {
  if (data.length === 0) {
    return (
      <div className={styles.notFound}>
        {emptyImage ? <img src={emptyImage} alt="not found"/> : null}
        <div>{emptyText || locale.emptyText}</div>
      </div>
    )
  }

  let subStringFxn = (item) => {
    let totalLength = item.length
    if (totalLength > 150) {
      item = item.substring(0, 150) + ' ...'
      return item
    } else {
      return item
    }
  }


  return (
    <div>
      <List className={styles.list}>
        {data.map((item, i) => {
          const itemCls = classNames(styles.item, {
            [styles.read]: item.read
          })

          const leftIcon = <Icon className={styles.avatar} type={'message'}/>

          return (
            <List.Item className={itemCls} key={item.key || i} onClick={() => onClick(item)}>
              <List.Item.Meta
                className={styles.meta}
                avatar={<span className={styles.iconElement}>{leftIcon}</span>}
                description={
                  <div>
                    <div className={styles.description} title={item.content}>
                      {subStringFxn(item.content)}
                    </div>
                    <div className={styles.flexRow}>
                      <span>
                        {item.addedByUserId && item.addedByUserId.name ? `${item.addedByUserId.name} (${item.addedByUserId.email})` : ''}
                      </span>
                      <span>
                        {displayDate(item.createdAt)}
                      </span>
                    </div>
                  </div>
                }
              />
            </List.Item>
          )
        })}
      </List>
      {showClear ? (
        <div className={styles.clear} onClick={viewAll}>
          {locale.clear} {title}
        </div>
      ) : null}
    </div>
  )
}
