import {
  Button,
  Col,
  Icon,
  Row,
  Select,
  Tooltip,
  DatePicker, Tag
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  Intakes,
  filterOption,
  newFormatDisplayDate, InputBox, CambieCollegeOnshore
} from '../../../components/_utils/appUtils'
import { getUrlPushWrapper } from '../../../routes'
import { listAllCampus } from '../../campus/actions/campus'
import { listAllUsers } from '../../users/actions/user'
import DocumentDrawer from '../drawers/studentDocumentDrawer'
import {
  cambieOnShoreCommissionListFxn,
  cambieOnshoreEnrollmentLetterFxn,
  cambieOnshorePaymentReceiptFxn
} from './actions'
import GenerateCambieOnshoreReceipt from './receiptDrawer'
import GenerateCambieOnshoreLoa from './loaDrawer'
import EditStudentDrawer from './editStudentDrawer'
import { ReCreateFcmtOfferLetter } from '../actions/chooseStudent'
import EnrollmentLetterDrawer from './enrollementLetterDrawer'
import GenerateAgentInvoice from './generateAgentInvoiceDrawer'
import UploadAgentInvoice from './uploadAgentInvoiceDrawer'
import PayCommission from './payCommissionDrawer'
import { RightComponent } from '../../../components/_utils/rightsUtils'

const { RangePicker } = DatePicker

const ttVerifiedStatusList = [
  { name: 'Pending', value: 'Pending' },
  { name: 'Verified by User', value: 'Verified' },
  { name: 'Disregard', value: 'Disregard' },
  { name: 'Bank Verified', value: 'Bank Verified' }
]
const { Option } = Select

const OnShoreCambieCommissionList = (props) => {
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  const [studentData, setStudentData] = useState({})
  const [name, setName] = useState('')
  const [allAgent, setAllAgent] = useState([])
  const [agentId, setAgentId] = useState('')
  const [totalApplication, setTotalApplication] = useState(0)
  let [tableSearch, setTableSearch] = useState(false)
  let [filters, setFilters] = useState({})
  let [campusList, setCampusList] = useState([])
  const [campusId, setCampusId] = useState('')
  const [intake, setIntake] = useState('')
  let [courseName, setCourseName] = useState('')
  let [visibleDocumentDrawer, setVisibleDocumentDrawer] = useState(false)
  let [marketingUserList, setMarketingUserList] = useState([])
  let [marketingUserId, setMarketingUserId] = useState('')
  let [ttVerifiedStatus, setTtVerifiedStatus] = useState('')
  let [receiptState, setReceiptState] = useState({
    visible: false,
    studentId: '',
    applicationId: ''
  })
  let [enrollementState, setEnrollementState] = useState({
    visible: false,
    studentId: '',
    applicationId: ''
  })
  let [studentState, setStudentState] = useState({
    visible: false,
    studentId: '',
    familyName: '',
    applicationId: '',
    courseId: '',
    application: {},
    email: ''
  })
  let [loaState, setLoaState] = useState({
    visible: false,
    studentId: '',
    applicationId: '',
    receiptAmount: '',
    feesPrepaid: undefined
  })
  let [agentInvoiceGenerateState, setAgentInvoiceGenerateState] = useState({
    visible: false,
    studentId: '',
    applicationId: ''
  })
  let [uploadAgentInvoice, setUploadAgentInvoiceState] = useState({
    visible: false,
    studentId: '',
    applicationId: ''
  })
  let [payCommissionDrawerState, setPayCommissionDrawerState] = useState({
    visible: false,
    studentId: '',
    applicationId: ''
  })

  const dispatch = useDispatch()

  useEffect(() => {
    loadAgentData()
    setFieldByParams()
    loadMarketingUsers()
    loadCampusList()
    // loadUniversity()
  }, [])
  const tableAppRef = useRef()


  let apiRequest = params => {
    return new Promise(async resolve => {
      let resp = await getParamsForApplicationList()
      params = { ...params, ...resp }
      if (!params.results) {
        params.results = 50
        params.count = 50
      }
      if (tableSearch) {
        params.page = 1
      }
      try {
        setFilters(params)
        let resp = await cambieOnShoreCommissionListFxn({ ...params })
        setTotalApplication(resp.total)
        setTableSearch(false)
        resolve(resp)
      } catch (e) {
      }
    })
  }

  const setFieldByParams = async () => {
    let resp = await getParamsForApplicationList()

    if (resp.campusId) {
      setCampusId(resp.campusId)
    }

    if (resp.agentId) {
      setAgentId(resp.agentId)
    }
    if (resp.intake) {
      setIntake(resp.intake)
    }
    if (resp.courseName) {
      setCourseName(resp.courseName)
    }
    if (resp.marketingUserId) {
      setMarketingUserId(resp.marketingUserId)
    }
    if (resp.ttVerifiedStatus) {
      resp.ttVerifiedStatus = resp.ttVerifiedStatus.split(',')
      setTtVerifiedStatus(resp.ttVerifiedStatus)
    }

    if (resp.name) {
      setName(resp.name)
    }

  }

  const loadAgentData = async () => {
    let params = {
      results: 10000,
      userType: 'agent',
      sortField: 'companyName',
      sortOrder: 'ascend',
      select: ['name', 'companyName']
    }
    let { data } = await dispatch(listAllUsers(params))
    if (data && data.length) {
      setAllAgent(data)
    }
  }

  const loadCampusList = async () => {
    let params = {}
    params.sortField = 'campusName'
    params.sortOrder = 'ascend'
    params.results = 50
    params.count = 50
    params.campusUniversity = CambieCollegeOnshore
    params.select = ['campusName']
    let { data } = await dispatch(
      listAllCampus({
        ...params,
        regExFilters: ['campusAddress', 'campusName']
      })
    )
    setCampusList(data)
  }

  const events = {
    reloadFxn: () => {
      tableAppRef.current.reload()
    },

    refreshApplication: () => {
      tableAppRef.current.reload()
    },

    openDocumentDrawer: async record => {
      setStudentData(record)
      setVisibleDocumentDrawer(true)
    },
    closeDocumentDrawer: async record => {
      setStudentData({})
      setVisibleDocumentDrawer(false)
    },
    generateEnrollment: async (record) => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(cambieOnshoreEnrollmentLetterFxn(obj))
      if (resp && resp.success) {

        if (tableAppRef && tableAppRef.current) {
          tableAppRef.current.reload()
        }
      }
    },
    reCreateOfferLetter: async record => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(ReCreateFcmtOfferLetter(obj))
      if (resp && resp.success) {
        tableAppRef.current.reload()
      }
    },
    showReceiptDrawer: async (record) => {
      setReceiptState({
        visible: true,
        studentId: record._id,
        applicationId: record.applications._id,
        receiptAmount: record.applications.cambieOnshoreReceiptAmount ? record.applications.cambieOnshoreReceiptAmount : ''
      })
    },
    hideReceiptDrawer: async () => {
      setReceiptState({
        visible: false,
        studentId: '',
        applicationId: '',
        receiptAmount: ''
      })
    },
    hideEnrollmentDrawer: async () => {
      setEnrollementState({
        visible: false,
        studentId: '',
        applicationId: ''
      })
    },
    showEnrollmentDrawer: async (record) => {
      setEnrollementState({
        visible: true,
        studentId: record._id,
        applicationId: record.applications._id
      })
    },
    showEditStudent: (record) => {
      let obj = {
        visible: true,
        studentId: record._id,
        familyName: record.applications && record.applications.familyName ? record.applications.familyName : '',
        applicationId: record.applications && record.applications._id ? record.applications._id : '',
        application: record.applications,
        email: record.email
      }
      if (record.applications && record.applications.courseId && record.applications.courseId._id) {
        obj.courseId = record.applications.courseId._id
      }
      setStudentState(obj)
    },
    hideEditStudent: () => {
      setStudentState({
        visible: false,
        studentId: '',
        familyName: '',
        applicationId: '',
        courseId: '',
        application: {},
        email: ''
      })
    },

    showLoaDrawer: async (record) => {
      setLoaState({
        visible: true,
        studentId: record._id,
        applicationId: record.applications._id,
        receiptAmount: record.applications.cambieOnshoreReceiptAmount ? record.applications.cambieOnshoreReceiptAmount : '',
        feesPrepaid: record.applications.feesPrepaid ? record.applications.feesPrepaid : undefined
      })
    },
    hideLoaDrawer: async () => {
      setLoaState({
        visible: false,
        studentId: '',
        applicationId: '',
        receiptAmount: '',
        feesPrepaid: undefined
      })
    },
    generateReceiptFxn: async (record) => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(cambieOnshorePaymentReceiptFxn(obj))
      if (resp && resp.success) {

        if (tableAppRef && tableAppRef.current) {
          tableAppRef.current.reload()
        }
      }
    },
    showAgentInvoiceDrawer: async (record) => {
      setAgentInvoiceGenerateState({
        visible: true,
        studentId: record._id,
        applicationId: record.applications._id
      })
    },
    hideAgentInvoiceDrawer: async () => {
      setAgentInvoiceGenerateState({
        visible: false,
        studentId: '',
        applicationId: ''
      })
    },
    showUploadInvoiceDrawer: async (record) => {
      setUploadAgentInvoiceState({
        visible: true,
        studentId: record._id,
        applicationId: record.applications._id
      })
    },
    hideUploadInvoiceDrawer: async () => {
      setUploadAgentInvoiceState({
        visible: false,
        studentId: '',
        applicationId: ''
      })
    },
    showPayCommissionDrawer: async (record) => {
      setPayCommissionDrawerState({
        visible: true,
        studentId: record._id,
        applicationId: record.applications._id
      })
    },
    hidePayCommissionDrawer: async () => {
      setPayCommissionDrawerState({
        visible: false,
        studentId: '',
        applicationId: ''
      })
    }
  }

  const getParamsForApplicationList = () => {
    return new Promise(resolve => {
      let searchParams = new URLSearchParams(window.location.search)
      let name = searchParams.get('name')
      let campusId = searchParams.get('campusId')
      let agentId = searchParams.get('agentId')
      let intake = searchParams.get('intake')
      let courseName = searchParams.get('courseName')
      let marketingUserId = searchParams.get('marketingUserId')
      let ttVerifiedStatus = searchParams.get('ttVerifiedStatus')
      let fromDate = searchParams.get('fromDate')
      let toDate = searchParams.get('toDate')
      let obj = {}
      if (name) {
        obj.name = name
      }
      if (campusId) {
        obj.campusId = campusId
      }
      if (agentId) {
        obj.agentId = agentId
      }
      if (intake) {
        obj.intake = intake
      }
      if (courseName) {
        obj.courseName = courseName
      }
      if (marketingUserId) {
        obj.marketingUserId = marketingUserId
      }
      if (ttVerifiedStatus) {
        obj.ttVerifiedStatus = ttVerifiedStatus
      }
      if (fromDate) {
        obj.fromDate = fromDate
      }
      if (toDate) {
        obj.toDate = toDate
      }
      resolve(obj)
    })
  }

  const searchFxn = async () => {
    let obj = {}
    if (name) {
      obj.name = name
    }
    if (campusId) {
      obj.campusId = campusId
    }
    if (agentId) {
      obj.agentId = agentId
    }
    if (intake) {
      obj.intake = intake
    }
    if (courseName) {
      obj.courseName = courseName
    }
    if (marketingUserId) {
      obj.marketingUserId = marketingUserId
    }
    if (ttVerifiedStatus) {
      obj.ttVerifiedStatus = ttVerifiedStatus
    }
    if (fromDate && toDate) {
      obj.fromDate = fromDate
      obj.toDate = toDate
    }
    dispatch(
      getUrlPushWrapper('OnshoreCambieCommissionList', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
    }, 200)
  }
  const clearFxn = async () => {
    let obj = {}
    setName('')
    setCampusId('')
    setAgentId('')
    setIntake('')
    setCourseName('')
    setMarketingUserId('')
    setTtVerifiedStatus('')
    dispatch(
      getUrlPushWrapper('OnshoreCambieCommissionList', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      tableAppRef.current.reload()
    }, 200)
  }

  const columns = [
    {
      title: 'App#',
      width: 100,
      key: 'applications.id',
      dataIndex: 'applications.id',
      searchTextName: 'applications.id',
      render: item => {
        return <div style={{ width: 60 }}>{item}</div>
      }
    },
    {
      title: 'Student ID',
      width: 100,
      key: 'applications.studentIdNo',
      dataIndex: 'applications.studentIdNo',
      render: (item, record) => {
        return <div style={{ width: 80 }}>{item}</div>
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 150,
      dataIndex: 'name',
      render: (item, record) => {
        return (
          <div>
            <Tooltip title={'Edit Student'}>
              <a className={'linkAA'}
                 onClick={() => {
                   events.showEditStudent(record)
                 }}>
                {item} <Icon type={'edit'} />
              </a>
            </Tooltip>
            <br />
            {newFormatDisplayDate(record.dateOfBirth)}
          </div>
        )
      }
    },
    {
      title: 'Email',
      width: 250,
      key: 'email',
      dataIndex: 'email'
    },
    {
      title: 'Program',
      width: 200,
      key: 'applications.courseName',
      dataIndex: 'applications.courseName',
      render: item => {
        return <div>{item}</div>
      }
    },
    {
      title: 'Intake',
      width: 120,
      key: 'applications.intake',
      dataIndex: 'applications.intake',
      render: (item) => {
        return (
          <div>
            {item && item.month && item.year
              ? `${item.month}, ${item.year}`
              : ''}
          </div>
        )
      }
    },
    {
      title: 'Campus',
      width: 100,
      key: 'applications.campusId',
      dataIndex: 'applications.campusId',
      render: (item) => {
        return (
          <div>
            {item && item.campusName ? item.campusName : ''}
          </div>
        )
      }
    },
    {
      title: 'Marketing User',
      width: 230,
      key: 'applications.marketingUserId',
      dataIndex: 'applications.marketingUserId',
      render: (item, record) => {
        return (
          <div className={'fcmtDiv'}>
            {item && item.name ? item.name : ''}
          </div>
        )
      }
    },
    {
      title: 'Commission Amount',
      width: 190,
      key: 'applications.cambieCommissionAmount',
      dataIndex: 'applications.cambieCommissionAmount',
      render: (item, record) => {
        return <div>CAD {item}</div>
      }
    },
    {
      title: 'Agent Invoice Generate',
      width: 200,
      render: (item, record) => {
        return (
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            {!record.applications.cambieInvoiceId ? (
              <button
                className={'btn btn-default sm'}
                onClick={() => {
                  events.showAgentInvoiceDrawer(record)
                }}
              >
                Generate
              </button>
            ) : (
              record &&
              record.applications &&
              record.applications.cambieInvoiceId &&
              record.applications.cambieInvoiceId.invoice &&
              record.applications.cambieInvoiceId.invoice.path ? (
                <>
                  <Tag>
                    <Icon type={'download'} /> &nbsp;
                    <a href={record.applications.cambieInvoiceId.invoice.path} target={'_blank'}>
                      Download
                    </a>
                  </Tag>
                  <br />
                </>
              ) : null
            )}
          </div>
        )
      }
    },
    {
      title: 'Uploaded Letterhead Invoice',
      width: 210,
      render: (item, record) => {
        return (
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            {record.applications.cambieInvoiceId &&
            !record.applications.letterhead ? (
              <button
                className={'btn btn-default sm'}
                onClick={() => {
                  events.showUploadInvoiceDrawer(record)
                }}
              >
                <Icon type='upload' /> Upload
              </button>
            ) : (
              record &&
              record.applications &&
              record.applications.letterhead &&
              record.applications.letterhead.path ? (
                <>
                  <Tag>
                    <Icon type={'download'} /> &nbsp;
                    <a href={record.applications.letterhead.path} target={'_blank'}>
                      Download
                    </a>
                  </Tag>
                  <br />
                </>
              ) : null
            )}
          </div>
        )
      }
    },
    {
      title: 'Actions',
      fixed: 'right',
      render: (item, record) => {
        return (
          <>

            {
              record &&
              record.applications &&
              record.applications.letterhead &&
              record.applications.cambieInvoiceId &&
              !record.applications.cambieInvoiceId.receipt ?
                <RightComponent user={user} userTypes={['cambieAccountant']}>
                  <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <button className={'btn btn-default roundNew xs'} onClick={() => {
                      events.showPayCommissionDrawer(record)
                    }}>Pay Commission
                    </button>
                  </div>
                </RightComponent>
                : (
                  record &&
                  record.applications &&
                  record.applications.cambieInvoiceId &&
                  record.applications.cambieInvoiceId.receipt &&
                  record.applications.cambieInvoiceId.receipt.path ? (
                    <>
                      <a className={'btn btn-default roundNew xs'} href={record.applications.cambieInvoiceId.receipt.path}
                         target={'_blank'}>
                        <Icon type={'download'} /> &nbsp;
                        Download
                      </a>
                      <br />
                    </>
                  ) : null
                )
            }


            <Tooltip title={'View Application'}>
              <a className={`btn btn-default roundNew xs`}
                 href={`/application/view?appId=${record.applications._id}&studentId=${record._id}`}>
                View Application
              </a>
            </Tooltip>

          </>
        )
      }
    }
  ]


  const loadMarketingUsers = async () => {
    let obj = {
      userType: 'cambieBranchUser',
      results: 100,
      select: ['name']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setMarketingUserList(data)
  }
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const DateRangeSelector = (date, dateString) => {
    setFromDate(dateString[0])
    setToDate(dateString[1])
  }

  const extra = (
    <div className={'mt10 filter_box'}>
      <Row gutter={12}>
        <Col md={5} sm={5} xs={8} lg={5}>
          <InputBox title={'Search by name'}>
            <input
              type='search'
              placeholder='Search by name...'
              value={name}
              onChange={e => {
                setName(e.target.value)
              }}
            />
          </InputBox>
        </Col>
        <Col md={5} sm={5} xs={8} lg={5}>
          <InputBox title={'Search by course name'}>
            <input
              type='search'
              placeholder='course name...'
              value={courseName}
              onChange={e => {
                setCourseName(e.target.value)
              }}
            />
          </InputBox>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <InputBox title={'Search by Campus'}>
            <Select
              name='Status'
              onChange={item => setCampusId(item)}
              placeholder={'Campus'}
              allowClear={true}
              value={campusId || undefined}>
              {campusList && campusList.length
                ? campusList.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>
                      {item.campusName}
                    </Option>
                  )
                })
                : null}
            </Select>
          </InputBox>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <InputBox title={'Search by Intake'}>
            <Select
              name='Intake'
              onChange={item => setIntake(item)}
              filterOption={filterOption}
              allowClear={true}
              showSearch={true}
              placeholder={'Intake'}
              value={intake || undefined}>
              {Intakes && Intakes.length
                ? Intakes.map((item, key) => {
                  return (
                    <Option value={item} key={key}>
                      {item}
                    </Option>
                  )
                })
                : null}
            </Select>
          </InputBox>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <InputBox title={'Search by Marketing User'}>
            <Select
              name='Search By Marketing User'
              filterOption={filterOption}
              allowClear={true}
              placeholder={'Marketing User'}
              onChange={item => setMarketingUserId(item)}
              value={marketingUserId || undefined}>
              {marketingUserList && marketingUserList.length
                ? marketingUserList.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>
                      {item.name}
                    </Option>
                  )
                })
                : null}
            </Select>
          </InputBox>
        </Col>
      </Row>
      <Row gutter={12}>
        {/*<Col md={5} sm={5} xs={24} lg={5}>
          <InputBox title={'Search By TT Status'}>
            <Select
              name='Search By TT Status'
              filterOption={filterOption}
              allowClear={true}
              className={'round-selector'}
              mode={'multiple'}
              placeholder={'TT Status'}
              onChange={item => setTtVerifiedStatus(item)}
              value={ttVerifiedStatus || undefined}>
              {ttVerifiedStatusList && ttVerifiedStatusList.length
                ? ttVerifiedStatusList.map((item, key) => {
                  return (
                    <Option value={item.value} key={item.value}>
                      {item.name}
                    </Option>
                  )
                })
                : null}
            </Select>
          </InputBox>
        </Col>*/}
        <Col md={5} sm={5} xs={12} lg={5}>
          <InputBox title={'Select Date'}>
            <RangePicker onChange={DateRangeSelector} />
          </InputBox>
        </Col>
        <Col md={14} sm={14} xs={24} lg={14}>
          <div className={'mt30'}>
            <Button onClick={() => searchFxn()} className={'roundBtn'}>
              Search
            </Button>
            <Button onClick={() => clearFxn()} className={'roundBtn'}>
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )

  return (
    <React.Fragment>
      <div>
        <div className='row mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div
                className='table-head d-flex align-items-center all-student-search'
                style={{ height: 60 }}>
                <Button
                  onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                  className={'roundGoBackBtn'}
                  icon={'arrow-left'}>
                  Back
                </Button>
                <h5>Application List {totalApplication && `(Total - ${totalApplication})`}</h5>
              </div>
              <div className='card-body table-responsive'>
                {extra}
                <TableComp
                  columns={columns}
                  rowKey={(item, index) => {
                    return index
                  }}
                  apiRequest={apiRequest}
                  pagination={{
                    position: 'top',
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    defaultPageSize: 10,
                    current: filters.page ? filters.page : 1
                  }}
                  extraProps={{ scroll: { x: 2000 } }}
                  ref={tableAppRef}
                />
              </div>
            </div>
          </div>
        </div>

        {visibleDocumentDrawer &&
        studentData.applications &&
        studentData.applications._id ? (
          <DocumentDrawer
            onClose={events.closeDocumentDrawer}
            reloadTable={events.reloadFxn}
            studentData={studentData}
            applicationId={studentData.applications._id}
            studentId={studentData._id}
            visible={visibleDocumentDrawer}
          />
        ) : null}
      </div>
      {receiptState.visible ?
        <GenerateCambieOnshoreReceipt
          {...receiptState}
          onClose={events.hideReceiptDrawer}
          onSubmit={() => {
            events.hideReceiptDrawer()
            events.reloadFxn()
          }}
        /> : null}

      {enrollementState.visible ?
        <EnrollmentLetterDrawer
          {...enrollementState}
          onClose={events.hideEnrollmentDrawer}
          onSubmit={() => {
            events.hideEnrollmentDrawer()
            events.reloadFxn()
          }}
        /> : null}

      {loaState.visible ?
        <GenerateCambieOnshoreLoa
          {...loaState}
          onClose={events.hideLoaDrawer}
          onSubmit={() => {
            events.hideLoaDrawer()
            events.reloadFxn()
          }}
        /> : null}

      {studentState.visible ?
        <EditStudentDrawer
          {...studentState}
          onClose={events.hideEditStudent}
          onSubmit={() => {
            events.hideEditStudent()
            events.reloadFxn()
          }}
        /> : ''}

      {agentInvoiceGenerateState.visible ?
        <GenerateAgentInvoice
          {...agentInvoiceGenerateState}
          onClose={() => {
            events.hideAgentInvoiceDrawer()
          }}
          onSubmit={() => {
            events.hideAgentInvoiceDrawer()
            events.reloadFxn()
          }}
        /> : ''}

      {uploadAgentInvoice.visible ?
        <UploadAgentInvoice
          {...uploadAgentInvoice}
          onClose={() => {
            events.hideUploadInvoiceDrawer()
          }}
          onSubmit={() => {
            events.hideUploadInvoiceDrawer()
            events.reloadFxn()
          }}
        /> : ''}

      {payCommissionDrawerState.visible ?
        <PayCommission
          {...payCommissionDrawerState}
          onClose={() => {
            events.hidePayCommissionDrawer()
          }}
          onSubmit={() => {
            events.hidePayCommissionDrawer()
            events.reloadFxn()
          }}
        /> : ''}
    </React.Fragment>
  )
}

export default OnShoreCambieCommissionList
