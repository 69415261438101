import React, { useRef } from 'react'
import { Form, Popconfirm, Tooltip } from 'antd'
import { useDispatch } from 'react-redux'
import {
  addOfficeVisitsFxn,
  getCityAndDateWiseTravelPlanFxn
} from './actions'
import { displayDate } from '../../components/_utils/appUtils'
import { TableComp } from 'sz-react-utils-lite'

const OfficeVisitsList = () => {
  let tableRef = useRef()
  const dispatch = useDispatch()
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

  const addOfficeVisits = async (data) => {
    if (data) {
      console.log(data,"data")
      // return
      await dispatch(addOfficeVisitsFxn({ ...data }))
    }
  }

  const columns = [
    {
      title: '#',
      key: '_id',
      dataIndex: '_id',
      width: 50,
      render: (ite, record, index) => {
        return (
          <React.Fragment>
            {index + 1}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Date',
      dataIndex: '_id',
      key: '_id',
      width: 150,
      render: item => {
        return item ? displayDate(item) : ''
      }
    },
    {
      title: 'Marketing Users',
      key: 'data',
      dataIndex: 'data',
      width: 220,
      render: item => {
        return (
          <div>
            {item && item.length > 0 && (
              <table className='table'>
                <tbody>
                {item.map((item1, itemIndex) => (
                  <React.Fragment key={itemIndex}>
                    <tr>
                      {/*<td style={{ width: 50 }}>
                        {item1 && item1.userId && item1.userId.logo && item1.userId.logo.url ?
                          <div className={'circle-logo-user'}>
                            <img
                              src={item1.userId.logo.url && item1.userId.logo.url}
                              alt="Logo"
                              style={{ maxWidth: '100%', height: 'auto', borderRadius: '50%' }}
                            />
                          </div> :
                          <Avatar size={30} src={profileImg}/>
                        }
                      </td>*/}
                      <td style={{ width: 200 }}>
                        {item1 && item1.userId && item1.userId.name && item1.userId.name}
                      </td>
                      <td>
                        <Tooltip title={'Add Office Visit'}>
                          <Popconfirm title={'Are your sure, you want to Request Office Visit?'}
                                      onConfirm={() => {
                                        addOfficeVisits(
                                          {
                                            userId: item1.userId._id,
                                            travelPlanId: item1._id,
                                            date: item1.date,
                                            email: item1.userId.email,
                                            number: item1.userId.mobile,
                                            userName: item1.userId.name,
                                          }
                                        )
                                      }}
                                      okText='Yes' cancelText='No'>
                            <button className='btn btn-default ml-2 xs'>Request Office Visit</button>
                          </Popconfirm>
                        </Tooltip>
                      </td>
                    </tr>
                    {item1 && item1.users && item1.users.length > 0 && item1.users.map((user, userIndex) => (
                      <tr key={userIndex}>
                        <td style={{ width: 200 }}>
                          {user && user.user && user.user.name && user.user.name}
                        </td>
                        <td>
                          <Tooltip title={'Add Office Visit'}>
                            <Popconfirm title={'Are your sure, you want to Request Office Visit?'}
                                        onConfirm={() => {
                                          addOfficeVisits(
                                            {
                                              userId: user.user._id,
                                              travelPlanId: item1._id,
                                              date: item1.date,
                                              email: user.user.email,
                                              number: user.user.mobile,
                                              userName: user.user.name,
                                            }
                                          )
                                        }}
                                        okText='Yes' cancelText='No'>
                              <button className='btn btn-default ml-2 xs'>Request Office Visit</button>
                            </Popconfirm>
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
                </tbody>
              </table>
            )}
          </div>

        )
      }
    }
  ]

  const apiRequest = params => {
    return new Promise(async resolve => {
      let resp = await dispatch(getCityAndDateWiseTravelPlanFxn({ ...params, userId: user._id }))
      console.log(resp, 'response')
      resolve(resp)
    })
  }


  return (
    <>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>Add Office Visits</h5>
            </div>
            <div className='card-body table-responsive'>
              <TableComp
                columns={columns}
                apiRequest={apiRequest}
                ref={tableRef}
                defaultSortOrder='descend'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const WrappedTravelPlan = Form.create()(OfficeVisitsList)
export default WrappedTravelPlan
