import React, { useState } from 'react'
import { Modal } from 'antd'
import { InputBox } from '../../components/_utils/appUtils'

const UploadPhotos = (props) => {
  const {
    visible,
    addDiwaliGiftPhotosFxn,
    setDetailsModal,
    detailsModal,
    setDiwaliDistributionPhotos
  } = props

  const [selectedFiles, setSelectedFiles] = useState([])

  const chooseFiles = (files) => {
    const fileArray = Array.from(files)
    setSelectedFiles(fileArray)
    setDiwaliDistributionPhotos(files)
  }

  return (
    <Modal
      title='Select Photos'
      visible={visible}
      onOk={() => {
        addDiwaliGiftPhotosFxn(detailsModal.agentId)
      }}
      onCancel={() => {
        setDetailsModal({ ...detailsModal, visible: false })
      }}
    >
      <div>
        <InputBox title={'Add Photos*'}>
          <input
            type={'file'}
            multiple={true}
            className={'form-control'}
            onChange={(e) => chooseFiles(e.target.files)}
          />
        </InputBox>
        <div>
          {selectedFiles.length > 0 && (
            <ul>
              {selectedFiles.map((file, index) => (
                <li key={index}>{file.name}</li> // You can display file names or thumbnails here
              ))}
            </ul>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default UploadPhotos
