import React, { useState, useEffect, useRef } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Button,
  Card,
  Popconfirm,
  Tooltip,
  Tag, Icon
} from 'antd'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { notification } from 'antd'
import { listAllScheduleMeeting, scheduleMeetingUsersList } from '../actions/scheduleMeeting'
import EditCountry from './edit'
import { getUrlPushWrapper } from '../../../routes'
import { DefaultTablePagination, displayDate, ScheduleTypes } from '../../../components/_utils/appUtils'
import scheduleMeetingReducers from '../reducers/scheduleMeeting'
import moment from 'moment'
import AddScheduleMeeting from './add'
import EditScheduleMeeting from './edit'
import { CheckUserRight } from '../../dashboard/views/DashboardUserWise'

let statusList = ['Pending', 'Approved', 'Rejected']
const ListAllScheduleMeetingComponent = () => {
  const { totalScheduleMeeting } = useSelector((state) => ({
    totalScheduleMeeting: state.scheduleMeetingReducers.totalScheduleMeeting
  }))


  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      resolve(dispatch(listAllScheduleMeeting({
        ...params,
        regExFilters: ['userIds', 'studentIds', 'guestUsers', 'topic']
      })))
    })
  }
  const [itemNo, setItemNo] = useState(1)
  const [id, setId] = useState(null)
  const [user, setUser] = useState({})
  const [addScheduleMeetingVisible, setAddScheduleMeetingVisible] = useState(false)
  const [editScheduleMeetingVisible, setEditScheduleMeetingVisible] = useState(false)
  const [userList, setUserList] = useState([])


  const loadUser = async () => {
    let { data } = await dispatch(scheduleMeetingUsersList({}))
    setUserList(data)
  }


  useEffect(() => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    setUser(user)
    loadUser()
  }, [])

  const confirmDelete = async (id) => {

  }
  const loadFilterCountry = async () => {
    let countryFilter = {
      results: 100
    }
    let resp = await dispatch(listAllScheduleMeeting(countryFilter))
  }

  const events = {
    hideDrawer: () => {
      setAddScheduleMeetingVisible(false)
      tableRef.current.reload()
    },
    hideEditDrawer: () => {
      setEditScheduleMeetingVisible(false)
      tableRef.current.reload()
    }
  }
  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 70,
      key: 'sno',
      fixed: true,
      render: (value, item, index) => (itemNo - 1) * 10 + index + 1
    },
    {
      title: 'Topic',
      dataIndex: 'topic',
      key: 'topic',
      searchTextName: 'topic'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      searchDateName: 'date',
      render: (value) => {
        return value ? displayDate(value) : null
      }
    },
    {
      title: 'Time',
      dataIndex: 'fromTime',
      key: 'fromTime',
      render: (value, record) => {
        return (
          <div>
            {value ? moment(value).format('hh:mm a') : null} -
            {record && record.toTime ? moment(record.toTime).format('hh:mm a') : null}
          </div>
        )
      }
    },
    {
      title: 'Type',
      dataIndex: 'types',
      key: 'types',
      filters: ScheduleTypes.map(x => ({ value: x, text: x })),
      render: (value, record) => {
        return (
          <div>
            {value && value.length ? value.map((item, key) => {
              return (
                <Tag key={key}>{item}</Tag>
              )
            }) : null}
          </div>
        )
      }
    },
    {
      title: 'Place',
      dataIndex: 'place',
      key: 'place',
      searchTextName: 'place'
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      width: 150,
      filters: userList.map(x => ({ value: x.email, text: x.label }))

    },
    {
      title: 'Guest Email',
      dataIndex: 'guestUsers',
      key: 'guestUsers',
      searchTextName: 'guestUsers',
      width: 150,
      render: (value, record) => {
        return (
          <ul>
            {value && value.length ? value.map((item, key) => {
              return (
                <li key={key}>{item ? item : null}</li>
              )
            }) : null}
          </ul>
        )
      }
    },
    {
      title: 'Added By',
      dataIndex: 'addedByUserId',
      key: 'addedByUserId',
      render: (value, record) => {
        return value && value._id ? `${value.name} (${value.email})` : null
      }
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: statusList.map(x => ({ value: x, text: x }))
    },
    {
      key: 'actions',
      title: 'Actions',
      fixed: 'right',
      width: 80,
      render: (text, record) => {
        return <React.Fragment>
          {record.status == 'Pending' ?
            user && user._id && record && record.addedByUserId && record.addedByUserId._id && user._id == record.addedByUserId._id ?
              <Tooltip title="Edit">
                <button
                  className="btn"
                  style={{ marginRight: 6 }}
                  onClick={() => {
                    setId(record._id)
                    setEditScheduleMeetingVisible(true)
                  }}>
                  <Icon type={'edit'}/>
                </button>
              </Tooltip> : null : null}
        </React.Fragment>

      }
    }
  ]


  return (
    <div>

      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="card">
            <div className="table-head d-flex align-items-center">
              <h5>All Schedule Meeting: {totalScheduleMeeting || 0}</h5>
              <div className="search-box-table">

              </div>

              <div className="sort-box-table mark-btn">
                <CheckUserRight rightUserType={['admin', 'branchManager', 'branchUser', 'agent']} user={user}>
                  <button className="btn" onClick={() => setAddScheduleMeetingVisible(true)}>
                    <img src={'../dist/icons/plus.png'} className={'plus'}/> Add Schedule Meeting
                  </button>
                </CheckUserRight>
              </div>
            </div>
            <div className="card-body table-responsive">
              <TableComp columns={columns}
                         apiRequest={apiRequest}
                         ref={tableRef}
                         pagination={DefaultTablePagination()}
                         extraProps={{ scroll: { x: 600 } }}

              />
            </div>
          </div>
        </div>
      </div>

      {addScheduleMeetingVisible ? <AddScheduleMeeting visible={addScheduleMeetingVisible} events={events}/> : ''}
      {editScheduleMeetingVisible ?
        <EditScheduleMeeting visible={editScheduleMeetingVisible} events={events} id={id}/> : ''}
    </div>
  )
}

export default ListAllScheduleMeetingComponent
