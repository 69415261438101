import React, { useState, useEffect, useRef } from 'react';
import { Tooltip, Drawer, Form, Row, Col, Input, Button, Select} from 'antd';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TableComp } from 'sz-react-utils-lite';
import { getStudent } from '../actions/student';
import lodash from 'lodash'
import { getTransactionList } from '../../referrals/actions';
import { DefaultTablePagination, displayDate, InputBox } from '../../../components/_utils/appUtils';
import ViewApplication from '../../applications/views/viewApplication';
import { listAllAccount } from '../../bankAccount/actions/bankAccount';
import { getStudentCurrentBalance } from '../actions/student';

const Card = styled.div``;

const TransactionList = (props) => {
  const [totalTransactions, setTotalTransactions] = useState(0);
  let [total, setTotal] = useState([])
  let [listData, setListData] = useState([])
  let [loading, setLoading] = useState(true)
  let [bankList, setBankList] = useState([]);
  let [balance, setBalance] = useState(0);

  const [state, setState] = useState({
    studentObj: '',
    viewCourseDrawer: false,
  });
  const [searchData, setSearchData] = useState({
    name: '',
    date: '',
  });
  const [searchLoading, setSearchLoading] = useState(false);
  const filterData = searchData;
  const tableRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();


  const loadBankList = async () => {
    let resp = await dispatch(listAllAccount({}));
    setBankList(resp.data);
    console.log(bankList, "banklist-----", resp, "resp------");
  };
   

//   useEffect(() => {
//     loadBankList()
//   }, [])

const studentCurrentBalance = async (balanceValue) => {
  console.log('balanceValue:', balanceValue);
  try {
      
      let balanceData = await dispatch(getStudentCurrentBalance(balanceValue));
      console.log('Balance Data:', balanceData);

          setBalance(balanceValue); 
          console.log(balance, 'balance-------')
  } catch (error) {
      console.error('Error fetching student balance:', error);
  }
};
useEffect(() => {
  console.log(balance, 'balance-------');
}, [balance]);

  const updateState = (data) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...data,
      };
    });
  };
  const filterAndSetData = async () => {
    let { data } = await dispatch(getTransactionList());
    console.log('transactions', data);
    setTotalTransactions(data.length);

    let filteredData = data.filter((item) => {
      const formattedDate = displayDate(item.createdAt).toLowerCase();
      return (
        item.studentId.name.toLowerCase().includes(filterData.name.toLowerCase()) &&
        formattedDate.includes(filterData.date.toLowerCase())
      );
    });

    setTotalTransactions(filteredData.length);
    setListData(filteredData);
  };

  useEffect(() => {
    apiRequest()
    // calculateBalance(listData);
  }, [])

 const handleWithdrawal= () => {
    history.push('/student-withdrawal');
  }
  const apiRequest = async () => {
    let params = {};
    let { data } = await dispatch(getTransactionList());
    console.log('transactions', data);
    setTotalTransactions(data.length);

   setListData(data);
   calculateBalance(data);
  };

  const calculateBalance = async (data) => {
    let total = 0;
    lodash.each(data, (item) => {
      total = total + parseFloat(item.amount);
    });
  
    let calculatedBalance = 0;
    lodash.each(data, (item) => {
      if (item.type === 'Credit') {
        calculatedBalance = total + balance;
      } else if (item.type === 'Debit') {
        calculatedBalance = balance - total;
      }
    });
  
    setTotal(total);
    setBalance(calculatedBalance);
  
    // Call studentCurrentBalance here
    await studentCurrentBalance(calculatedBalance);
  };
  
  
  const events = {
    viewCourse: (record) => {
      setState({
        ...state,
        viewCourseDrawer: true,
        studentObj: record,
      });
    },
    viewCourseClose: () => {
      setState({
        ...state,
        viewCourseDrawer: false,
        studentObj: {},
      });
      tableRef.current.reload();
    },
    searchFxn: () => {
      setSearchLoading(true);
      filterAndSetData()
      setSearchLoading(false);
    },

    clearFxn: () => {
      setSearchData({
        name: '',
        date: '',
      });
      filterAndSetData()
    },
   
  };

  const columns = [
    {
      title: 'Serial No.',
      dataIndex: 'serialNo',
      render: (val, record, index) => <div>{index + 1}</div>,
    },
    {
      title: 'Name',
      dataIndex: 'studentId.name', 
      key: 'name',
    },
    {
      title: 'Date',
      dataIndex: 'createdAt', 
      key: 'date',
      render: (date) => {
        return <span>{displayDate(date)}</span>;
      },
    },
    // {
    //   title: 'Description',
    //   dataIndex: 'description',
    //   key: 'description',
    // },
    {
      title: 'Debit',
      dataIndex: 'amount',
      key: 'debit',
      render: (amount, record) => {
        return <span>${record.type === 'Debit' ? amount : 0}</span>;
      },
    },
    {
      title: 'Credit',
      dataIndex: 'amount',
      key: 'credit',
      render: (amount, record) => {
        return <span>${record.type === 'Credit' ? amount : 0}</span>;
      },
    },
    {
      title: 'Balance',
      dataIndex: 'balance', 
      key: 'balance',
      render: () => {
      
    
        return <span>${balance}</span>;
      },
    },
  ];

  return (
    <div>
      <div className="row mt-4">
        <div className="col-lg-12">
          <Card>
          <div className="table-head d-flex align-items-center">
          <h5>Transaction List({totalTransactions}): </h5>
          <div style={{ marginLeft: 'auto' }}>
           {/* <Tooltip title={'Your Withdrawal'}>
              <Button
                style={{ marginRight: 10 }}
                onClick={handleWithdrawal}>
                Your Withdrawals
                  </Button>
  </Tooltip> */}
              </div>
            </div>
            <Row gutter={12} className={'filter_box'}>
              <Col span={4}>
                <InputBox title={'Search by name'}>
                  <input
                    value={searchData.name}
                    placeholder={'Name'}
                    onChange={({ target }) => {
                      setSearchData((prevData) => ({
                        ...prevData,
                        name: target.value,
                      }));
                    }}
                  />
                </InputBox>
              </Col>
              <Col span={4}>
                <InputBox title={'Search by date'}>
                  <input
                    value={searchData.date}
                    placeholder={'Date'}
                    onChange={({ target }) => {
                      setSearchData((prevData) => ({
                        ...prevData,
                        date: target.value,
                      }));
                    }}
                  />
                </InputBox>
              </Col>
             

              <Col span={24}>
                <div className={'btn_group'}>
                  <a className={'default_btn'} onClick={events.searchFxn}>
                    Search
                  </a>
                  <a className={'default_btn'} onClick={events.clearFxn}>
                    Clear
                  </a>
                </div>
              </Col>
            </Row>
            <div className="card-body table-responsive">
              <TableComp
                columns={columns}
                dataSource={listData}
                pagination={DefaultTablePagination()}
                ref={tableRef}
              />
            </div>
          </Card>
        </div>
      </div>

      {state.viewCourseDrawer ? (
        <ViewApplication
          visible={state.viewCourseDrawer}
          pageType={'student'}
          onClose={() => events.viewCourseClose()}
          studentObj={state.studentObj}
        />
      ) : null}
    </div>
  );
};

export default TransactionList;
