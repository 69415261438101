import React, { useState, useEffect } from 'react'
import { Drawer, Form, Button, notification, Card, Spin, Row, Col } from 'antd'
// import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { useDispatch, useSelector } from 'react-redux'
import { createMatchSelector, goBack } from 'connected-react-router'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { listAllStates } from '../../state/actions/state'
import { listAllCountries } from '../../countries/actions/countries'
import { getCampus, updateCampus } from '../actions/campus'
import { listAllCities } from '../../city/actions/city'
import { listAllUniversities } from '../../university/actions/university'
import GetAllFormFields from '../../../components/_utils/formUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'


const Edit = (props) => {
  const dispatch = useDispatch()
  const [campus, setCampus] = useState({})
  const [campusId, setCampusId] = useState(null)
  let [allUniversities, setAllUniversities] = useState([])
  let [allCountry, setAllCountry] = useState([])

  const { loader, allCountries, allCities, allStates } = useSelector(state => ({
    loader: state.campusReducers.loader,
    allCountries: state.countryReducers.allCountries,
    allStates: state.stateReducers.allStates,
    allCities: state.cityReducers.allCities
  }))

  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } } = props
  const handleCountrySelect = (x) => {
    dispatch(listAllStates({
      results: 1000000, customQuery: { country: x }, sortField: 'stateName',
      sortOrder: 'ascend'
    }))
  }

  const handleStateSelect = (x) => {
    dispatch(listAllCities({
      results: 1000000, customQuery: { state: x }, sortField: 'cityName',
      sortOrder: 'ascend'
    }))
  }

  useEffect(() => {
    loadCountry()
  }, [])
  const loadCountry = async () => {
    let obj = {
      results: 1000,
      regExFilters: ['countryName'],
      active: [true, false]
    }
    let { data } = await dispatch(listAllCountries(obj))
    setAllCountry(data)
  }
  const loadUniversity = async () => {
    let obj = {
      results: 1000,
      universityCountry: getFieldValue('campusCountry'),
      regExFilters: ['universityName'],
      showUniversity: [true, false]
    }
    let respData = await dispatch(listAllUniversities(obj))
    setAllUniversities(respData.data)
  }
  let inputTypes = {
    fields: [
      {
        key: 'campusCountry', label: 'Country', type: 'select',
        required: true,
        options: allCountry,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.countryName}`,
        onChange: x => {
          handleCountrySelect(x)
          props.form.setFieldsValue({
            campusCountry: x,
            campusState: undefined,
            campusCity: undefined
          })
          loadUniversity()

        }
      },
      {
        key: 'campusUniversity', label: 'University', type: 'select',
        required: true,
        options: allUniversities,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.universityName}`,
        onChange: x => {
          props.form.setFieldsValue({
            campusUniversity: x
          })
        }
      },
      {
        key: 'campusState', label: 'State', type: 'select',
        required: true,
        options: allStates,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.stateName}`,
        onChange: x => {
          handleStateSelect(x)
          props.form.setFieldsValue({
            campusState: x,
            campusCity: undefined
          })
        }
      },
      {
        key: 'campusCity', label: 'City', type: 'select',
        options: allCities,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.cityName}`,
        onChange: x => {
          props.form.setFieldsValue({
            campusCity: x
          })
        }
      },

      { key: 'campusName', label: 'Campus Name', required: true },
      { key: 'campusAddress', label: 'Address', type: 'textArea' }

      /*{ key: 'AdmissionPersonName', label: 'Admission Person Name' },
      { key: 'AdmissionPersonEmail', label: 'Admission Person Email' },
      { key: 'AdmissionPersonMobile', label: 'Admission Person Mobile', type: 'Number' },
      { key: 'additionalRequirments', label: 'Additional Requirments' },*/

    ]
  }


  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = async (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        await dispatch(updateCampus(valData, campusId))
        getCampusData()
        form.resetFields()
        dispatch(goBack())
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  useEffect(() => {
    dispatch({ type: 'CAMPUS_HIDE_LOADER' })
    dispatch(listAllCountries({ results: 1000000, sortField: 'countryName', sortOrder: 'ascend' }))
    dispatch(listAllStates({ results: 1000000, sortField: 'stateName', sortOrder: 'ascend' }))
    dispatch(listAllCities({ results: 1000000, sortField: 'cityName', sortOrder: 'ascend' }))
    dispatch(listAllUniversities({ results: 1000000, sortField: 'universityName', sortOrder: 'ascend' }))
    getCampusData()
  }, [])


  const getCampusData = async () => {
    let searchParams = new URLSearchParams(window.location.search)
    let id = searchParams.get('id')
    setCampusId(() => id)
    if (id) {
      let data = await dispatch(getCampus(id))
      if (data) {
        setCampus(() => data)
      }
    }
  }

  useEffect(() => {
    props.form.setFieldsValue({
      campusUniversity: campus.campusUniversity ? campus.campusUniversity._id : undefined,
      campusCountry: campus.campusCountry ? campus.campusCountry._id : undefined,
      campusState: campus.campusState ? campus.campusState._id : undefined,
      campusCity: campus.campusCity ? campus.campusCity._id : undefined,
      campusName: campus.campusName || '',
      campusAddress: campus.campusAddress || '',
      AdmissionPersonName: campus.AdmissionPersonName || '',
      AdmissionPersonEmail: campus.AdmissionPersonEmail || '',
      AdmissionPersonMobile: campus.AdmissionPersonMobile || '',
      additionalRequirments: campus.additionalRequirments || ''
    })

    setTimeout(() => {
      loadUniversity()
    }, [1000])

  }, [campus])


  return (
    <div className='row'>
      <div className='col-lg-12'>
        <Form onSubmit={handleSubmit}>
          <div className='form-box mt-4'>

            <div className='d-flex align-items-center mb-3 heading-form'>
              <h5>Update Campus</h5>
            </div>

            <div className='card unizportal'>
              <Row gutter={16}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <Col span={8} md={8} sm={12} xs={24} key={key} className={'mb10'}>
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout} />
                    </Col>
                  )
                })}
                <Col span={24}>
                  <Form.Item>
                    <Button type='primary' htmlType='submit' className={'btn'}>
                      SAVE
                    </Button>
                  </Form.Item>
                </Col>

              </Row>

            </div>
          </div>

        </Form>
      </div>
    </div>
  )
}

const EditCampus = Form.create()(Edit)
export default EditCampus
