import React, { useEffect, useState, Suspense } from 'react'
import {
  Form,
  Modal
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import { getSingleSelectedCourse, SendOpenApplicationMail } from '../../../applications/actions/chooseStudent'
// import DocumentBlock from './documentBlock'
// import NotesBlock from './notesBlock'
// import InfoBlock from './infoBlock'
import moment from 'moment'
import { submitNote } from '../../../student/actions/student'
import { push } from 'connected-react-router'
import AddNote from '../../drawers/createNoteComponent'
import { departmentObj } from '../../../../components/_utils/appUtils'

const DocumentBlock = React.lazy(() => import('./documentBlock'))
const NotesBlock = React.lazy(() => import('./notesBlock'))
const InfoBlock = React.lazy(() => import('./infoBlock'))

const initialState = {
  studentData: {},
  applicationId: '',
  momentKey: moment(),
  noteDrawerVisible: false,
  document: {},
  studentKeyMoment: null
}


const StudentProfile = (props) => {
  let { user } = props
  const dispatch = useDispatch()
  let [state, setState] = useState(initialState)
  useEffect(() => {
    loadSingleApplicationData()
    document.body.className = 'applicationProfile'

  }, [])

  const loadSingleApplicationData = async () => {
    let searchParams = new URLSearchParams(window.location.search)
    let studentId = searchParams.get('studentId')
    let appId = searchParams.get('appId')
    if (studentId && appId) {
      let obj = {}
      let resp = await dispatch(getSingleSelectedCourse({ studentId, appId }))
      if (resp && resp.status == 'Active') {
        if (!resp.applications.openApplicationMailSend) {
          if (user && user.department == departmentObj.assDepartment) {
            SendOpenApplicationMail({
              studentId: studentId,
              applicationId: appId
            }).then((resp) => {

            })
          }
        }
        obj.studentData = resp
        obj.newStatus = resp.applications.status
        checkAssignedUser(resp.applications.assignedUserId, (daaa1) => {
          if (daaa1 == 'done') {
            setState({
              ...state,
              ...obj,
              applicationId: appId,
              momentKey: moment(),
              studentKeyMoment: moment() + 1
            })
          }
        })
      } else {
        checkStudentStatus()
      }
    }
  }

  const checkStudentStatus = () => {
    Modal.error({
      title: 'Student Withdrawal',
      content: 'Student Withdrawal, You can\'t view student profile.',
      onOk: async () => {
        document.body.className = ''
        dispatch(push('/dashboard'))
      },
      onCancel: () => {
        document.body.className = ''
        dispatch(push('/dashboard'))
      }
    })

  }

  const checkAssignedUser = (assignedUserId, callback) => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    if (user.userType == 'branchUser') { // check if branch manager
      if (user._id.toString() == assignedUserId._id.toString()) { // check student application assigned to login user
        callback('done')
      } else {
        if (user.department == departmentObj.interviewer || user.department == departmentObj.marketing) { //  direct open for interview and marketing department
          callback('done')
        } else { // go back to dashboard if application current user not match with login user
          alert('Student Application has been transferred to other department. Click OK to go to Dashboard!')
          document.body.className = ''
          dispatch(push('/dashboard'))
        }
      }
    } else {
      callback('done')
    }
  }

  const reloadStudent = () => {
    loadSingleApplicationData(state.applicationId)
  }


  let { studentData } = state
  let { applications } = studentData

  const events = {
    showDrawer: () => {
      setState({ ...state, noteDrawerVisible: true })
    },
    hideDrawer: () => {
      setState({ ...state, noteDrawerVisible: false })
    },
    setLocalState: (e) => {
      let { name, value } = e.target
      setState({
        ...state,
        [name]: value
      })
    },
    chooseDocument: (e) => {
      let { name, files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          document: files[0]
        })
      }
    },

    handleSubmit: async () => {
      let obj = {
        studentId: studentData._id,
        note: state.note,
        applicationId: state.applicationId
      }
      let fd = new FormData()
      fd.append('obj', JSON.stringify(obj))
      fd.append('document', state.document)
      let data = await dispatch(submitNote(fd))
      if (data && !data.error) {
        dispatch({ type: 'START_PUSH_NOTE_BY_REDUX', noteId: data.noteId })
        setState({
          ...state,
          note: '',
          document: '',
          documentKey: moment(),
          noteDrawerVisible: false
        })

      }
    }
  }


  return (
    <React.Fragment>

      <div className={'appProfile'} key={state.studentKeyMoment}>
        <div className={'align-self-stretch'}>
          <Suspense fallback={<></>}>
            <InfoBlock studentData={studentData} reloadStudent={reloadStudent}
                       showNoteDrawer={events.showDrawer} />
          </Suspense>
          <div className='student-dashboard'>

            <div className='row m-0'>
              <div className='col-lg-8 p-0'>
                <Suspense fallback={<></>}>
                  <NotesBlock applicationId={applications && applications._id}
                              studentId={studentData._id}
                              showNoteDrawer={events.showDrawer} />
                </Suspense>
              </div>
              <Suspense fallback={<></>}>
                <div className='col-lg-4 p-0'>
                  {applications ? <DocumentBlock
                    user={user}
                    application={applications}
                    applicationId={applications && applications._id}
                    reloadStudent={reloadStudent}
                    studentId={studentData && studentData._id} /> : ''}
                </div>
              </Suspense>
            </div>
          </div>
        </div>
      </div>

      {state.noteDrawerVisible ? <AddNote visible={state.noteDrawerVisible} studentData={studentData}
                                          countryId={applications && applications.universityCountry && applications.universityCountry._id ? applications.universityCountry._id : ''}
                                          onClose={events.hideDrawer} {...props}
                                          applicationId={state.applicationId} /> : null}

    </React.Fragment>
  )
}

const mapStateToProps = ({ global, router }) => ({
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(StudentProfile)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedComponent)
