import { apiUrl } from '../../../settings'

export const getAssessmentWarningList = () => {
  return apiUrl + '/warning/assessments'
}
export const setWarningMailApi = () => {
  return apiUrl + '/warning/sendMail'
}

export const getWaitingToApplyWarningList = () => {
  return apiUrl + '/warning/waitingToApply'
}
