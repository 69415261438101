import { apiUrl,apiUrl2 } from '../../../settings'

export const listWithdrawalUrl = () => {
  return apiUrl + '/withdrawal'
}

export const addWithdrawalUrl = () => {
  return apiUrl2 + '/addWithdrawal'
}

export const updateWithdrawalUrl = () => {
  return apiUrl + '/updateWithdrawal'
}

export const addWithdrawalSendOtpUrl = () => {
    return apiUrl2 + '/sendWithdrawalOtp'
}
export const sendExpenseWithdrawalOtpUrl = () => {
    return apiUrl + '/sendExpenseWithdrawalOtp'
}
export const addExpenseWithdrawalUrl = () => {
    return apiUrl + '/addExpenseWithdrawal'
}

export const reGenerateInvoiceUrl = () => {
    return apiUrl + '/reGenerateInvoice'
}

export const updateUniversityCommissionsUrl = () => {
    return apiUrl2 + '/updateUniversityCommissions'
}
