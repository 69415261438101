import React, { useState } from 'react'
import { Input, Modal, notification, Radio } from 'antd'
import { FileInput, InputBox } from '../../../components/_utils/appUtils'
import { uploadOtherScreenshotFxn } from '../actions/cornerStone'
import { useDispatch } from 'react-redux'

let { TextArea } = Input
let initState = {
  details: '',
  screenshot: {},
  status: 'Attended'
}
const UploadScreenshot = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, studentDetails, index, screenshotId, screenshotObjId, onSubmit } = props
  let [state, setState] = useState(initState)
  let events = {
    _updateState: (data) => {
      setState((prevState) => {
        return {
          ...prevState,
          ...data
        }
      })
    },
    handleFileChange: (e) => {
      const { files } = e.target
      if (files && files.length) {
        events._updateState({ screenshots: files[0] })
      }
    },
    handleSubmit: async (e) => {
      if (!state.status) {
        notification.error({
          message: 'Please choose status'
        })
      }
      if (state.status == 'Attended') {
        if (!state.screenshots || (state.screenshots && !state.screenshots.name)) {
          notification.error({
            message: 'Error',
            description: 'Please upload a screenshot file.'
          })
          return
        }

      } else {
        if (!state.details) {
          notification.error({
            message: 'Please enter reason'
          })
        }
      }

      const formData = new FormData()
      if ((state.screenshots && state.screenshots.name)) {
        formData.append('screenshot', state.screenshots)
      }

      let obj = {
        screenshotId, screenshotObjId, details: state.details, status: state.status
      }
      formData.append('obj', JSON.stringify(obj))

      const resp = await dispatch(uploadOtherScreenshotFxn(formData))

      if (resp && resp.success) {
        onSubmit()
      }
    }
  }
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      width={700}
      footer={null}
      title={`${studentDetails.name} - Screenshot ${index + 1}`}>
      <>
        <div className='form-box'>
          <div className='card unizportal'>
            <InputBox>
              <Radio.Group
                className={'custom-radioBtn'}
                name={'status'}
                onChange={({ target }) => {
                  events._updateState({ status: target.value })
                }}>
                <Radio
                  value={'Attended'}
                  checked={state.status == 'Attended'}
                  className={'radio1 bg-success mt2'} style={{ fontWeight: 'normal' }}>
                  Attended
                </Radio>
                <Radio
                  value={'Not Available'}
                  checked={state.status == 'Not Attended'}
                  className={'radio2 bg-danger mt2'} style={{ fontWeight: 'normal' }}>
                  Not Available
                </Radio>
              </Radio.Group>

            </InputBox>
            {state.status == 'Attended' ? <InputBox>
              <FileInput
                name={`Screenshots`}
                label={`Screenshot`}
                chooseDocument={(e) => events.handleFileChange(e)}
              />
            </InputBox> : null}
            {state.status ? <InputBox title={state.status == 'Attended' ? 'Enter Details' : 'Enter Reason'}>
              <TextArea
                placeholder={state.status == 'Attended' ? 'Enter Details' : 'Enter Reason'} className={'form-control'}
                onChange={({ target }) => {
                  events._updateState({ details: target.value })
                }} />
            </InputBox> : null}
            <div className={'text-center mt10'}>
              <button className='btn' type='submit' onClick={events.handleSubmit}>Submit
              </button>
            </div>
          </div>
        </div>
      </>
    </Modal>

  )
}
export default UploadScreenshot
