import React, {useRef} from 'react'
import debounce from 'lodash/debounce'


export function Debounce(func, wait) {
  const debouncedSave = debounce(() => func.apply(), wait)
  return debouncedSave()

}

/*export function Debounce(func, wait, immediate) {
    let timeout;
    return function () {
        let context = this, args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        if (immediate && !timeout) func.apply(context, args);
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}*/
