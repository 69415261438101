import React, { Component, useEffect, useState } from 'react'
import { countryIds } from '../../components/_utils/appUtils'
import _ from 'lodash'

const CheckMyRights = (props) => {
  let [userType, setUserType] = useState('')

  useEffect(() => {
    setUserRight()
  }, [])

  const setUserRight = () => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    if (user && user.userType) {
      setUserType(user.userType)
    }

  }

  let { rightList, children } = props
  return (
    <React.Fragment>
      {rightList.includes(userType) ? <React.Fragment>{children}</React.Fragment> : null}
    </React.Fragment>
  )

}


const checkEnrollmentRight = (data) => {
  if (data.countryISOCode == 'CA_+1' && data.registerCountry && data.registerCountry.toString() == countryIds.canada && data.applications) {
    let findStatus = _.find(data.applications.statusList, (item) => {
      return item.status == 'LOA/Fee Receipt Issued'
    })
    if (findStatus) {
      if (data.applications && data.applications.onShoreEnrollment !== true) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }

  } else {
    return false
  }
}
export { CheckMyRights, checkEnrollmentRight }


