import {
  Avatar,
  Button,
  Col,
  Form,
  Row,
  Select
} from 'antd'
import lodash from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  countryIds,
  defaultLogoList,
  filterOption,
  Intakes,
  newFormatDisplayDate
} from '../../../components/_utils/appUtils'
import { getUrlPushWrapper } from '../../../routes'
import { listAllUniversities } from '../../university/actions/university'
import { canadaApplicationList } from './actions'
import CallStatusDrawer from './callStatusDrawer'

const { Option } = Select
const CanadaApplicationList = () => {
  const [name, setName] = useState('')
  const [universityList, setUniversityList] = useState([])
  const [appUniversityId, setAppUniversityId] = useState('')
  const [totalApplication, setTotalApplication] = useState(0)
  let [tableSearch, setTableSearch] = useState(false)
  let [filters, setFilters] = useState({})
  let [intake, setIntake] = useState('')
  let [gicCallStatus, setGicCallStatus] = useState('Pending')
  let [isCallStatusDrawerOpen, setIsCallStatusDrawerOpen] = useState({
    visible: false,
    record: ''
  })
  const dispatch = useDispatch()
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  const tableAppRef = useRef()

  let apiRequest = params => {
    return new Promise(async resolve => {
      let resp = await getParamsForApplicationList()
      params = { ...params, ...resp }
      if (!params.results) {
        params.results = 50
        params.count = 50
      }
      if (tableSearch) {
        params.page = 1
      }
      try {
        setFilters(params)
        let resp = await dispatch(canadaApplicationList({
          ...params, regExFilters: ['name']
        }))
        setTotalApplication(resp.total)
        setTableSearch(false)
        resolve(resp)
      } catch (e) {
      }
    })
  }


  useEffect(() => {
    setFieldByParams()
    loadUniversityNameList()
  }, [])

  const setFieldByParams = async () => {
    let resp = await getParamsForApplicationList()
    if (resp.name) {
      setName(resp.name)
    }
    if (resp.appUniversityId) {
      setAppUniversityId(resp.appUniversityId)
    }
    if (resp.intake) {
      setIntake(resp.intake)
    }
    if (resp.gicCallStatus) {
      setGicCallStatus(resp.gicCallStatus)
    }
  }

  const loadUniversityNameList = async () => {
    let params = {
      results: 5000,
      select: ['universityName'],
      sortField: 'universityName',
      sortOrder: 'ascend',
      regExFilters: ['universityName'],
      showUniversity: [true, false]
    }
    params.universityCountry = countryIds.canada
    let { data } = await dispatch(listAllUniversities(params))
    setAppUniversityId('')
    if (data && data.length) {
      setUniversityList(data)
    }
  }


  const events = {
    reloadFxn: () => {
      tableAppRef.current.reload()
    },
    showCallStatusModal: (record) => {
      setIsCallStatusDrawerOpen({
        visible: true,
        record: record
      })
    },
    hideCallStatusModal: () => {
      setIsCallStatusDrawerOpen({
        visible: false,
        record: ''
      })
      tableAppRef.current.reload()
    }
  }

  const getParamsForApplicationList = () => {
    return new Promise(resolve => {
      let searchParams = new URLSearchParams(window.location.search)
      let agentId = searchParams.get('agentId')
      let appUniversityId = searchParams.get('appUniversityId')
      let name = searchParams.get('name')
      let intake = searchParams.get('intake')
      let gicCallStatus = searchParams.get('gicCallStatus')
      let obj = {}
      if (agentId) {
        obj.agentId = agentId
      }
      if (appUniversityId) {
        obj.appUniversityId = appUniversityId
      }
      if (name) {
        obj.name = name
      }
      if (intake) {
        obj.intake = intake
      }
      if (gicCallStatus) {
        obj.gicCallStatus = gicCallStatus
      }
      resolve(obj)
    })
  }

  const searchFxn = async () => {
    let obj = {}

    if (name) {
      obj.name = name
    }
    if (appUniversityId) {
      obj.appUniversityId = appUniversityId
    }
    if (intake) {
      obj.intake = intake
    }
    if (gicCallStatus) {
      obj.gicCallStatus = gicCallStatus
    }
    dispatch(
      getUrlPushWrapper('canadaOfferList', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
    }, 200)
  }
  const clearFxn = async () => {
    let resp = await getParamsForApplicationList()
    let obj = {}
    setAppUniversityId('')
    setName('')
    dispatch(
      getUrlPushWrapper('canadaOfferList', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      tableAppRef.current.reload()
    }, 200)
  }

  const columns = [
    {
      title: 'App#',
      key: 'applications.id',
      dataIndex: 'applications.id',
      searchTextName: 'applications.id',
      render: (item, record) => {
        return <div style={{ width: 80 }}>{item}</div>
      },
      width: 80
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 150,
      render: (item, record) => {
        return (item)
      }
    },
    {
      title: 'DOB',
      width: 150,
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      render: val => {
        return <div style={{ width: 90 }}>{newFormatDisplayDate(val)}</div>
      }
    },
    {
      title: 'University',
      width: 240,
      key: 'applications.courseUniversity',
      dataIndex: 'applications.courseUniversity',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.logo && item.logo.url ? (
              <Avatar src={item.logo.url} size={20} />
            ) : (
              <Avatar src={defaultLogoList.defaultUniversityLogo} size={20} />
            )}
            {item.universityName ? `  ${item.universityName}` : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Program',
      key: 'applications.courseName',
      dataIndex: 'applications.courseName',
      render: item => {
        return <div>{item}</div>
      }
    },
    {
      title: 'Intake',
      key: 'applications.intake',
      dataIndex: 'applications.intake',
      width: 150,
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.month && item.year
              ? `${item.month}, ${item.year}`
              : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Agent Name',
      dataIndex: 'applications.agentId',
      key: 'applications.agentId',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.name
              ? item.name
              : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Agent Number',
      width: 150,
      dataIndex: 'applications.agentId',
      key: 'applications.agentId',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.mobile
              ? item.mobile
              : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'GIC Status',
      width: 150,
      dataIndex: 'applications.gicAccountOpened',
      key: 'applications.gicAccountOpened',
      render: (item) => {
        return (
          <React.Fragment>
            {item ? <label className={'label label-success sm'}>Opened</label> :
              <label className={'label label-warning sm'}>Pending</label>}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      fixed: 'right',
      width: 120,
      render: (val, record) => {
        let { applications } = record
        return (
          <div className={'actionBtnGroup'}>
            {applications.gicCallStatus == 'Pending' ?
              <a className={'btn btn-default roundNew sm'}
                 onClick={() => {
                   events.showCallStatusModal(record)
                 }}>Update Call Status</a> : null}
          </div>
        )
      }
    }
  ]


  const loadColumns = () => {
    let columnArr = []
    lodash.each(columns, item => {
      if (item.hidden == undefined) {
        columnArr.push(item)
      } else {
        if (!item.hidden) {
          columnArr.push(item)
        }
      }
    })
    return columnArr
  }

  const filterBlock = (
    <Row gutter={16}>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='search-box-table round ml0'>
          <input
            className='form-control form-control'
            type='search'
            placeholder='Name...'
            value={name}
            onChange={e => {
              events.enterName(e.target.value)
            }}
          />
          <img src='/dist/img/search.png' alt='' />
        </div>
      </Col>

      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select
            name='Search By University'
            onChange={item => setAppUniversityId(item)}
            filterOption={filterOption}
            className={'antSelect'}
            allowClear={true}
            showSearch={true}
            placeholder={'University'}
            value={appUniversityId || undefined}>
            {universityList && universityList.length
              ? universityList.map((item, key) => {
                return (
                  <Option value={item._id} key={key}>
                    {item.universityName}
                  </Option>
                )
              })
              : null}
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select
            name='Intake'
            onChange={item => setIntake(item)}
            filterOption={filterOption}
            className={'antSelect'}
            allowClear={true}
            showSearch={true}
            placeholder={'Intake'}
            value={intake || undefined}>
            {Intakes && Intakes.length
              ? Intakes.map((item, key) => {
                return (
                  <Option value={item} key={key}>
                    {item}
                  </Option>
                )
              })
              : null}
          </Select>
        </div>
      </Col>

      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select
            name='Status'
            onChange={setGicCallStatus}
            filterOption={filterOption}
            className='antSelect'
            allowClear={true}
            showSearch={true}
            placeholder='Status'
            value={gicCallStatus}
          >
            <Option value='Done'>Done</Option>
            <Option value='Not Done'>Not Done</Option>
            <Option value='Pending'>Pending</Option>
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <Button onClick={() => searchFxn()} className={'roundBtn'}>
          Search
        </Button>
        <Button onClick={() => clearFxn()} className={'roundBtn'}>
          Clear
        </Button>
      </Col>
    </Row>
  )
  return (
    <React.Fragment>
      <div>
        <div className='row mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div
                className='table-head d-flex align-items-center all-student-search'
                style={{ height: 60 }}>
                <Button
                  onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                  className={'roundGoBackBtn'}
                  icon={'arrow-left'}>
                  Back
                </Button>
                <h5>Canada Students - Offer List</h5>

                <div className='sort-box-table mark-btn'>
                  ( Total : {totalApplication} )
                </div>
              </div>
              <div className='card-body table-responsive'>
                {filterBlock}
                <TableComp
                  columns={loadColumns()}
                  rowKey={(item, index) => {
                    return index
                  }}
                  apiRequest={apiRequest}
                  pagination={{
                    position: 'top',
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '50'],
                    defaultPageSize: 10,
                    current: filters.page ? filters.page : 1
                  }}
                  ref={tableAppRef}
                />
              </div>
            </div>
          </div>
        </div>
      </div>


      {isCallStatusDrawerOpen.visible ? (
        <CallStatusDrawer
          visible={isCallStatusDrawerOpen.visible}
          onClose={() => {
            events.hideCallStatusModal()
          }}
          onSubmit={() => {
            events.hideCallStatusModal()
          }}
          {...isCallStatusDrawerOpen}
        />
      ) : null}

    </React.Fragment>
  )
}
const mapStateToProps = ({ global }) => ({
  currentUserRights: global.currentUserRights
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(CanadaApplicationList)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedComponent)
