import React, { useState, useEffect, useRef } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Avatar,
  Button,
  Card,
  Popconfirm,
  Tooltip, Icon
} from 'antd'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { notification } from 'antd'
import { getPushPathWrapper, getUrlPushWrapper } from '../../../routes'
import { listAllCompany } from '../actions/company'
import moment from 'moment'
import { DefaultTablePagination, displayDate, nameAvatar } from '../../../components/_utils/appUtils'
import companyReducers from '../reducers/company'
import ViewApplication from '../../applications/views/viewApplication'
import defaultLogo from '../../../assets/profile-user.png'
import Styles from './styles.less'
import { AllowComponentRightsWise } from '../../WebComponent/allowComponentRightsWise'
import { CheckMyRights } from '../../WebComponent/CheckUserRights'

const initialState = {
  studentObj: '',
  viewCourseDrawer: false
}

const ListAllCompany = (props) => {
  const { totalCompany } = useSelector((state) => ({
    totalCompany: state.companyReducers.totalCompany
  }))
  const [state, setState] = useState(initialState)

  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      resolve(dispatch(listAllCompany({ ...params, regExFilters: ['name'] })))
    })
  }
  const [itemNo, setItemNo] = useState(1)
  const [loading, setLoading] = useState(false)
  const confirmDelete = async (id) => {
  }

  const events = {
    viewCourse: (record) => {
      setState({
        ...state,
        viewCourseDrawer: true,
        studentObj: record
      })
    },
    viewCourseClose: () => {
      setState({
        ...state,
        viewCourseDrawer: false,
        studentObj: {}
      })
    }


  }


  const columns = [


    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (value, item, index) => (itemNo - 1) * 10 + index + 1
    },
   /* {
      title: 'Logo',
      dataIndex: 'profile',
      key: 'profile',
      width: 120,
      render: (item, record) => {
        let { profileImage } = record
        return (
          <div className={Styles.userNameBox}>
            {profileImage && profileImage.url ?
              <Avatar size={50} src={profileImage.url}></Avatar> :
              <Avatar size={50} src={defaultLogo}></Avatar>}
          </div>
        )
      }
    },*/
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      searchTextName: 'name',
      width: 120,
      render: (item, record) => {
        let { profileImage } = record
        return (
          <div className={Styles.userNameBox}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 150
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      width: 150
    },
    {
      title: 'Address',
      key: 'address',
      width: 150,
      dataIndex: 'address',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item}, {record.cityName}, {record.stateName}, {record.countryName}
          </React.Fragment>
        )
      }
    },
    {
      key: 'actions',
      title: 'Actions',
      width: 100,
      height: 100,
      render: (text, record) => {
        return (
          <React.Fragment>
            <Tooltip title={'View Application'}>
              <button
                className={'btn'}
                onClick={() => {
                  window.location.href = `/company/profile?id=${record._id}`
                }}>
                <Icon type={'eye'}/>
              </button>
            </Tooltip>
          </React.Fragment>

        )
      }
    }
  ]


  return (
    <div>

      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="card">
            <div className="table-head d-flex align-items-center">
              <h5>All Company: {totalCompany || 0}</h5>
              <div className="search-box-table">
                {/*<input className="form-control form-control" type="search" placeholder="Search Company"/>
                <img src="/dist/img/search.png" alt=""/>*/}
              </div>

              <div className="sort-box-table mark-btn">
                <CheckMyRights rightList={['admin']}>
                  <a className="btn" href={'/company/add'}>Add Company</a>
                </CheckMyRights>
              </div>
            </div>
            <div className="card-body table-responsive">
              <TableComp columns={columns}
                         apiRequest={apiRequest}
                         pagination={DefaultTablePagination()}
                         ref={tableRef}
              />
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default ListAllCompany
