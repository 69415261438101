import React from 'react'
import AddGicComponent from './addGicComponentDraft'
import { Modal } from 'antd'

const AddGicDraftDrawer = (props) => {
  let { visible, onClose, studentData } = props

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      width={"70%"}
      title={'Add Gic Request.'}
      placement="right"
      footer={null}
      centered={true}
    >
      <AddGicComponent {...props}/>
    </Modal>
  );
};
export default AddGicDraftDrawer;
