import {apiUrl} from '../../../settings'

export const addCourseCategoryUrl = () => {
    return apiUrl + '/courseCategory'
}

export const listCourseCategoryUrl = () => {
    return apiUrl + '/courseCategory'
}

export const singleCourseCategoryUrl = (id) => {
    return apiUrl + '/courseCategory/' + id
}
