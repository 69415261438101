import { Button, Col, Drawer, Form, notification, Row } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {
  addRequestForUniversityAccessFxn,
  closeAgentDealFxn,
  getUniversityFoDealCloseFxn
} from '../actions/closeDeal'
import { CornerStoneCollege, RoyalArtsCollege } from '../../../components/_utils/appUtils'

const RequestForConsentDrawer = props => {
  let dispatch = useDispatch()
  let {
    visible,
    onClose,
    onSubmit,
    agentId,
    form: { getFieldDecorator, setFieldsValue },
    dealClose
  } = props
  let [universityFoDealClose, setUniversityFoDealClose] = useState([])

  let inputTypes = {
    fields: [
      {
        key: 'universityId',
        label: 'University',
        type: 'select',
        span: 8,
        required: true,
        options: universityFoDealClose,
        showSearch: true,
        mode: 'multiple',
        keyAccessor: x => x.universityId._id,
        valueAccessor: x => `${x.universityId.universityName}`,
        onChange: x => {
          props.form.setFieldsValue({
            universityId: x
          })
        }
      }
    ]
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  useEffect(() => {
    events.getUniversityFoDealClose()
  }, [])


  const handleStatusUpdate = e => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let deals = []
        if (!valData || (valData && !valData.universityId)) {
          notification.warning({ message: 'Please choose university.' })
          return
        }
        _.each(valData.universityId, (uniId) => {
          let findE = _.find(dealClose, (dealItem) => {
            return (dealItem.universityId && dealItem.universityId._id) == (uniId)
          })
          if (findE) {
            deals.push({
              universityId: findE.universityId._id,
              amount: findE.amount
            })
          }
        })
        if (!deals || (deals && !deals.length)) {
          notification.warning({ message: 'Please choose university.' })
          return
        }
        valData.agentId = agentId
        let resp = await dispatch(addRequestForUniversityAccessFxn({ ...valData, deals }))
        if (resp && resp.success) {
          onSubmit()
          onClose()
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }
  let events = {
    getUniversityFoDealClose: async () => {
      let arrayList = []
      let uniList = [CornerStoneCollege]
      _.each(dealClose, (item) => {
        let universityId = item.universityId && item.universityId._id ? item.universityId._id : ''
        if (item && !item.status && uniList.includes(universityId)) {
          arrayList.push(item)
        }
      })
      if (arrayList) {
        setUniversityFoDealClose(arrayList)
      }
    }
  }
  return (
    <>
      <Drawer
        visible={visible}
        onClose={onClose}
        title={`Request for Agent Consent`}
        width={'40%'}>
        <div className='form-box commissionBox'>
          <div className='card unizportal'>
            <Form onSubmit={handleStatusUpdate}>
              <Row gutter={16} className={'wrapBox'}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      <Col span={24} key={key}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout}
                        />
                      </Col>
                    </React.Fragment>
                  )
                })}
                <Col span={4}>
                  <Form.Item>
                    <Button
                      type='primary'
                      htmlType='submit'
                      className={'btn mt40 btn-sm'}>
                      Submit Request
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  )
}

const RequestForConsentDrawerWrap = Form.create()(RequestForConsentDrawer)

export default RequestForConsentDrawerWrap
