import {
  Button, Col,
  Form, Row,
  notification, Input, Modal, Table, Select, Card
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { InputBox, expenseTypes2, DefaultTablePagination } from '../../../components/_utils/appUtils'
import { LoadCity, LoadState, LoadCanadaCitiesByName } from '../../../components/_utils/countryUtil'
import FileInput from '../../../components/_utils/fileInput'
import { addCanadaExpense, agentListFxn } from '../actions'
import { TableComp } from 'sz-react-utils-lite'

const { Option } = Select

let initialState = {
  uploadKey: moment(),
  allStates: [],
  allCities: []
}

let singleAgentInitialState = {
  companyName: '',
  name: '',
  email: '',
  phone: '',
  state: '',
  city: ''
}

const AddExpenseCanada = props => {
  const {
    form: { getFieldDecorator, getFieldValue }
  } = props
  const dispatch = useDispatch()

  const [document, setDocument] = useState({})
  const [photos, setPhotos] = useState([])
  const [detail, setDetails] = useState('')
  const [agentList, setAgentList] = useState([])
  const [state, setState] = useState(initialState)
  const { allStates, allCities } = state
  const [isAgentModalOpen, setIsAgentModalOpen] = useState(false)
  const [singleAgentData, setSingleAgentData] = useState(singleAgentInitialState)
  const [agentListArray, setAgentListArray] = useState([])

  let columns = [
    {
      title: 'Company name',
      dataIndex: 'companyName',
      key: 'companyName'
    },
    {
      title: 'Agent name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state'
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city'
    }
  ]


  useEffect(
    () => {
      setState({
        ...state,
        allStates: 101 ? LoadState(101) : []
      })
    },
    [getFieldValue('expenseType')]
  )
  useEffect(() => {
    loadAgentList()
  }, [])

  let loadAgentList = async () => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    let params = {
      'createdByUserId': user._id
    }
    let { data } = await dispatch(agentListFxn(params))
    setAgentList(data)
  }

  const events = {
    chooseState: name => {
      let stateName = _.find(allStates, item => {
        return item.name == name
      })
      if (stateName) {
        setState({
          ...state,
          allCities: stateName.id ? LoadCity(stateName.id) : []
        })
      }
    },
    chooseDocument: e => {
      let { name, files } = e.target
      if (files && files.length) {
        setDocument(files[0])
      }
    },
    choosePhotos: e => {
      let { name, files } = e.target
      if (files && files.length) {
        setPhotos(files)
      }
    }
  }
  const canadaStates = LoadState('38')
  const canadaCities = LoadCanadaCitiesByName(getFieldValue('state'))

  let inputTypes = {
    fields: [
      {
        key: 'expenseType',
        label: 'Expense Type',
        type: 'select',
        span: 6,
        required: true,
        showSearch: true,
        options: expenseTypes2,
        keyAccessor: x => x,
        valueAccessor: x => `${x}`,
        onChange: x => {
          let obj = {
            expenseType: x,
            date: '',
            note: '',
            amount: null
          }
          if ((x = 'Other Expense')) {
            setDetails('Gift To Agent')
          }
          if ((x = 'Car')) {
            props.form.setFieldsValue({ totalKlms: 0 })
          }
          props.form.setFieldsValue(obj)
          setAgentListArray([])
        }
      },
      {
        key: 'name',
        required: true,
        label:
          getFieldValue('expenseType') === 'Hotel'
            ? 'Hotel Name'
            : getFieldValue('expenseType') === 'Restaurant'
              ? 'Restaurant Name'
              : 'Hotel',

        hidden: !(
          getFieldValue('expenseType') === 'Hotel' ||
          getFieldValue('expenseType') === 'Restaurant'
        )
      },
      {
        key: 'detail',
        label: 'Other Expense',
        hidden: getFieldValue('expenseType') !== 'Other Expense',
        disabled: true,
        span: 8,
        customField: (
          <InputBox title={'Details'} className={'rowFlex mt10'}>
            <div className={'disbaledField'}>{detail}</div>
          </InputBox>
        )
      },
      {
        key: 'amount',
        label:
          getFieldValue('expenseType') === 'Car'
            ? 'Day Trip Amount'
            : 'Amount',
        required: true,
        span: 8
      },
      {
        key: 'address',
        label:
          getFieldValue('expenseType') === 'Hotel'
            ? 'Hotel Address'
            : getFieldValue('expenseType') === 'Restaurant'
              ? 'Restaurant Address'
              : 'Address',
        required: true,
        placeholder: 'Address',
        hidden: !(
          getFieldValue('expenseType') === 'Hotel' ||
          getFieldValue('expenseType') === 'Restaurant'
        ),

        span: 8
      },
      {
        key: 'state',
        label: (
          <>
            {getFieldValue('expenseType') === 'Hotel' ? 'Hotel State' : ''}
            {getFieldValue('expenseType') === 'Restaurant' ? 'Restaurant State' : ''}
            {getFieldValue('expenseType') === 'Car' ? 'From State' : ''}
            {getFieldValue('expenseType') === 'Other Expense' ? 'State' : ''}
          </>
        ),
        required: true,
        placeholder: 'State Name',
        type: 'select',
        showSearch: true,
        allowClear: true,
        hidden: !(
          getFieldValue('expenseType') === 'Hotel' ||
          getFieldValue('expenseType') === 'Restaurant'
        ),
        options: canadaStates,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            fromPlace: '',
            city: '',
            state: x
          })
        },
        span: 6
      },

      {
        key: 'city',
        allowClear: true,
        label: (
          <>
            {getFieldValue('expenseType') === 'Hotel' ? 'Hotel City' : ''}
            {getFieldValue('expenseType') === 'Restaurant' ? 'Restaurant City' : ''}
            {getFieldValue('expenseType') === 'Car' ? 'From City' : ''}
            {getFieldValue('expenseType') === 'Other Expense' ? 'City' : ''}
          </>
        ),
        required: true,
        placeholder: 'City Name',
        showSearch: true,
        type: 'select',
        hidden: !(
          getFieldValue('expenseType') === 'Hotel' ||
          getFieldValue('expenseType') === 'Restaurant'
        ),
        options: canadaCities,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            fromPlace: '',
            city: x
          })
        },
        span: 6
      },
      {
        key: 'note',
        label: 'Note',
        required: true,
        type: 'textArea',
        span: 24,
        rows: 4
      },
      {
        key: 'agentId',
        label: 'Agent Name',
        type: 'select',
        hidden: !(getFieldValue('expenseType') == 'Other Expense'),
        required: true,
        showSearch: true,
        options: agentList,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.name} (${x.companyName})`,
        onChange: x => {
          props.form.setFieldsValue({ agentId: x })
        }
      },
      {
        key: 'agentIds',
        label: 'Select Multiple agents you visit',
        type: 'select',
        hidden: !(getFieldValue('expenseType') == 'Car'),
        // required: true,
        showSearch: true,
        options: agentList,
        keyAccessor: x => x._id,
        mode: 'multiple',
        valueAccessor: x => `${x.name} (${x.companyName})`,
        onChange: x => {
          props.form.setFieldsValue({ agentIds: x })
        }
      },

      {
        key: 'receipt',
        label: 'Receipt',
        name: 'receipt',
        type: 'file',
        multiple: false,
        span: 4,
        value: document,
        hidden: getFieldValue('expenseType') === 'Car',
        onChange: e => {
          events.chooseDocument(e)
        }
      }
    ],
    fields2: [
      {
        key: 'photos',
        label: 'Photos',
        name: 'photos',
        type: 'file',
        multiple: true,
        span: 4,
        value: photos,
        hidden: getFieldValue('expenseType') !== 'Car',
        onChange: e => {
          events.choosePhotos(e)
        }
      },
      {
        key: 'date',
        label: 'Date',
        type: 'date',
        span: 6,
        placeholder: 'Choose Date'
      }
    ]
  }

  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = e => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        if ((valData.expenseType == 'Restaurant' || valData.expenseType == 'Other Expense') && valData.amount > 100) {
          notification.warning({
            message: 'Amount Cannot be Greater Than $100.'
          })
          return
        }
        if (valData.expenseType == 'Car' && valData.amount > 10) {
          notification.warning({
            message: 'Amount Cannot be Greater Than $10.'
          })
          return
        }
        if (valData.expenseType == 'Hotel' && valData.amount > 150) {
          notification.warning({
            message: 'Amount Cannot be Greater Than $150.'
          })
          return
        }
        if (valData.expenseType) {
          if (valData.expenseType == 'Car') {
            if (!photos || (photos && !photos.length)) {
              return notification.warning({
                message: 'Please choose Photos.'
              })
            }
          } else {
            if (!document || (document && !document.name)) {
              return notification.warning({
                message: 'Please choose Receipt.'
              })
            }
          }
        }

        if (!valData.date) {
          notification.warning({
            message: 'Please choose date.'
          })
          return
        }
        if (agentListArray.length > 0) {
          valData.agents = agentListArray
        }
        let fd = new FormData()
        fd.append('obj', JSON.stringify(valData))
        if (document && document.name) {
          fd.append('receipt', document)
        }
        if (photos && photos.length) {
          for (let x = 0; x < photos.length; x++) {
            fd.append('photos', photos[x])
          }
        }
        let data = await dispatch(addCanadaExpense(fd))
        if (data && !data.error) {
          form.resetFields()
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  const handleSubmitAgent = () => {
    if (!singleAgentData.companyName) {
      notification.warning({
        message: 'Please Enter Company Name'
      })
      return
    }
    if (!singleAgentData.name) {
      notification.warning({
        message: 'Please Enter Agent Name'
      })
      return
    }
    if (!singleAgentData.phone) {
      notification.warning({
        message: 'Please Enter Phone Number'
      })
      return
    }
    if (!singleAgentData.email) {
      notification.warning({
        message: 'Please Enter Email'
      })
      return
    }
    if (!singleAgentData.state) {
      notification.warning({
        message: 'Please Enter State'
      })
      return
    }
    if (!singleAgentData.city) {
      notification.warning({
        message: 'Please Enter City'
      })
      return
    }
    setAgentListArray([...agentListArray, singleAgentData])
    setSingleAgentData(singleAgentInitialState)
  }

  useEffect(() => {
    setAgentListArray([])
  }, [getFieldValue('expenseType')])

  return (
    <div className='row'>
      <div className='col-lg-12'>
        <Form onSubmit={handleSubmit}>
          <div className='form-box mt-4'>
            <div className='d-flex align-items-center mb-3 heading-form'>
              <h5>Add Daily Expense</h5>
            </div>
            <div className='card unizportal'>
              <Row gutter={24}>
                {inputTypes.fields.map((item, key) => {
                  return !item.hidden ? (
                    item.customField ? (
                      <Col span={item.span ? item.span : 6} key={key}>
                        {item.customField}
                      </Col>
                    ) : item.type == 'file' ? (
                      <Col span={6} key={key} className={'mt10'} key={item.key}>
                        <FileInput
                          name={item.name}
                          label={item.label}
                          chooseDocument={item.onChange}
                          multiple={item.multiple}
                        />
                      </Col>
                    ) : (
                      <Col
                        span={item.span ? item.span : 8}
                        md={item.span ? item.span : 8}
                        sm={12}
                        xs={24}
                        key={key}
                        className={'mb10'}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout}
                        />
                      </Col>
                    )
                  ) : null
                })}
                {
                  getFieldValue('expenseType') == 'Car' &&
                  <Col
                    span={2}
                    md={2}
                    sm={2}
                    xs={2}
                    style={{ marginTop: '42px' }}
                  >
                    <a
                      onClick={() => {
                        setIsAgentModalOpen(true)
                      }}
                      className={'btn btn-success'}
                    >+</a>
                  </Col>
                }
                {inputTypes.fields2.map((item, key) => {
                  return !item.hidden ? (
                    item.customField ? (
                      <Col span={item.span ? item.span : 6} key={key}>
                        {item.customField}
                      </Col>
                    ) : item.type == 'file' ? (
                      <Col span={6} key={key} className={'mt10'} key={item.key}>
                        <FileInput
                          name={item.name}
                          label={item.label}
                          chooseDocument={item.onChange}
                          multiple={item.multiple}
                        />
                      </Col>
                    ) : (
                      <Col
                        span={item.span ? item.span : 8}
                        md={item.span ? item.span : 8}
                        sm={12}
                        xs={24}
                        key={key}
                        className={'mb10'}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout}
                        />
                      </Col>
                    )
                  ) : null
                })}
              </Row>
              {(getFieldValue('expenseType') == 'Car' && agentListArray.length > 0) &&
              <>
                <Card title={'Agent List'} className={'bgWhite'}>
                  <Table
                    size={'small'}
                    bordered={true}
                    columns={columns}
                    rowKey={(item, index) => {
                      return index
                    }}
                    dataSource={agentListArray}
                    pagination={false}
                  />
                </Card>
              </>
              }
              <Form.Item>
                <Button type='primary' htmlType='submit' className='btn'>
                  SAVE
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
        <Modal
          title='Add Agent'
          visible={isAgentModalOpen}
          onOk={handleSubmitAgent}
          onCancel={() => {
            setIsAgentModalOpen(false)
          }}
          // confirmLoading={addingBankAccount}
        >
          <Row>
            <Col span={11}>
              <InputBox title={'Company Name'}>
                <Input
                  className={'form-control'}
                  value={singleAgentData.companyName}
                  onChange={(e) => setSingleAgentData({ ...singleAgentData, companyName: e.target.value })}
                  placeholder='Enter Company Name'
                />
              </InputBox>
            </Col>

            <Col span={11} style={{ marginLeft: '20px' }}>
              <InputBox title={'Name'}>
                <Input
                  className={'form-control'}
                  value={singleAgentData.name}
                  onChange={(e) => setSingleAgentData({ ...singleAgentData, name: e.target.value })}
                  placeholder='Enter Agent Name'
                />
              </InputBox>
            </Col>

            <Col span={11}>
              <InputBox title={'Contact Number'}>
                <Input
                  type={'number'}
                  className={'form-control'}
                  value={singleAgentData.phone}
                  onChange={(e) => setSingleAgentData({ ...singleAgentData, phone: e.target.value })}
                  placeholder='Enter Contact'
                />
              </InputBox>
            </Col>

            <Col span={11} style={{ marginLeft: '20px' }}>
              <InputBox title={'Email'}>
                <Input
                  type={'email'}
                  className={'form-control'}
                  value={singleAgentData.email}
                  onChange={(e) => setSingleAgentData({ ...singleAgentData, email: e.target.value })}
                  placeholder='Enter Email'
                />
              </InputBox>
            </Col>

            <Col span={11}>
              <InputBox title={'State'}>
                <Select
                  showSearch
                  placeholder='Select a person'
                  optionFilterProp='children'
                  value={singleAgentData.state}
                  onChange={(value) => {
                    setSingleAgentData({ ...singleAgentData, state: value })
                  }}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {canadaStates.map((option, index) => (
                    <Option key={index} value={option.name}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </InputBox>
            </Col>

            <Col span={11} style={{ marginLeft: '20px' }}>
              <InputBox title={'City'}>
                <Select
                  showSearch
                  placeholder='Select City'
                  optionFilterProp='children'
                  value={singleAgentData.city}
                  onChange={(value) => {
                    setSingleAgentData({ ...singleAgentData, city: value })
                  }}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {canadaCities.map((option, index) => (
                    <Option key={index} value={option.name}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </InputBox>
            </Col>
          </Row>
        </Modal>
      </div>
    </div>
  )
}
const WrappedCity = Form.create()(AddExpenseCanada)
export default WrappedCity
