import {apiUrl} from '../../../settings'

export const listAllMarketingAgentsUrl = () => {
  return apiUrl + '/api/marketingAgents'
}

export const closeAgentDealUrl = () => {
  return apiUrl + '/updateAgentDealClose'
}
export const getUniversityFoDealCloseUrlUrl = () => {
  return apiUrl + '/api/getUniversityFoDealClose'
}

export const addRequestForUniversityAccessUrl = () => {
  return apiUrl + '/request/add-request-for-university-access'
}

export const updateRequestStatusUrl = () => {
  return apiUrl + '/request/update-request-status'
}

export const accessRequestListUrl = () => {
  return apiUrl + '/request/request-list'
}

export const accessGrantedRequestUrl = () => {
  return apiUrl + '/request/access-granted-request'
}
