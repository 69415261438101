import { apiUrl } from '../../../settings'

export const myReferralUrl = () => {
  return apiUrl + '/api/myReferralList'
}

export const getReferralSettingUrl = () => {
  return apiUrl + '/api/referralSetting'
}

export const getTransactionListUrl = () => {
  return apiUrl + '/api/transactionList'
}
