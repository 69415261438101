import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import { listNewsUrl, singleNewsEnquiryUrl, singleNewsUrl, newsEnquiryListUrl } from '../api/news'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'


export const listAllNews = (filters) => async (dispatch) => {
  // dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(listNewsUrl(), config)
  dispatch(hidePageLoad())
  // dispatch({ type: 'LIST_ALL_NEWS', payload: data })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data

}

export const singleNewsEnquiry = (filters, id) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(singleNewsEnquiryUrl(id), config)
  dispatch(hidePageLoad())
  dispatch({ type: 'LIST_ALL_NEWS', payload: data.data })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data

}

export const deleteNews = (valData, id) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(singleNewsUrl(id), {}, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const deleteNewsEnquiry = (id) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(singleNewsEnquiryUrl(id), {}, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'success'
    })
  }
  return data
}


export const newsEnquiryList = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(newsEnquiryListUrl(), config)
  // console.log(data)
  dispatch(hidePageLoad())
  dispatch({ type: 'LIST_ALL_NEWS', payload: data.data })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data

}
