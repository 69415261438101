import React, {useEffect, useState} from 'react'

import {
    Button,
    Card,
    Col,
    DatePicker,
    Drawer,
    Form,
    Input,
    Row,
    Select,
    notification
} from 'antd'

import {useDispatch} from 'react-redux'
import {InputBox, departmentObj} from '../../../components/_utils/appUtils'
import {listAllUsers} from '../../users/actions/user'
import {getSingleTargetFxn, updateTargetFxn} from '../actions/userTarget'

const dateFormat = 'DD/MM/YYYY'

let {TextArea} = Input
const {Option} = Select
let initState = {
    userId: '',
    target: '',
    userType: ''
}
const EditUserTarget = props => {
    let {targetDataId} = props

    const dispatch = useDispatch()

    let {visible, onClose, commissionData, application, studentId} = props

    const [formValues, setFormValues] = useState(initState)
    let [userList, setUserList] = useState([])

    const handleChange = data => {
        let {userType} = data
        setFormValues(prevData => {
            return {
                ...prevData,
                ...data
            }
        })
        if (userType == 'Arm') {
            loadBranchManagers().then((resp) => {
                setUserList(resp)
            })
        } else if (userType == 'MarketingUser') {
            loadMarketingUsers().then((resp) => {
                setUserList(resp)
            })
        }
    }

    useEffect(() => {
        getSingleUserTarget()
    }, [])

    const loadBranchManagers = async () => {
        return new Promise(async (resolve) => {
            let obj = {
                userType: 'branchManager',
                customQuery: {
                    'agents.0': {$exists: true}
                },
                results: 100,
                select: ['name', 'email', 'mobile', 'agents', 'countryId']
            }
            let {data} = await dispatch(listAllUsers(obj))
            resolve(data)
        })
    }

    const loadMarketingUsers = async () => {
        return new Promise(async (resolve) => {
            let obj = {
                userType: 'branchUser',
                department: departmentObj.marketing,
                results: 100,
                select: ['name', 'email', 'mobile', 'agents', 'assignStateCountry']
            }
            let {data} = await dispatch(listAllUsers(obj))
            resolve(data)
        })
    }

    const getSingleUserTarget = async () => {
        let resp = await dispatch(getSingleTargetFxn(targetDataId))
        if (resp) {
            setFormValues({
                userId: resp.userId._id,
                target: resp.target,
                userType: resp.userType
            })
        }
        if (resp.userType == 'Arm') {
            loadBranchManagers().then((resp) => {
                setUserList(resp)
            })
        } else if (resp.userType == 'MarketingUser') {
            loadMarketingUsers().then((resp) => {
                setUserList(resp)
            })
        }
    }
    const handleSubmit = async e => {
        e.preventDefault()
        if (!formValues.userType) {
            notification.warning({message: "Please choose user type"})
            return
        }
        if (!formValues.userId) {
            notification.warning({message: "Please choose user"})
            return
        }
        if (!formValues.target) {
            notification.warning({message: "Please enter target"})
            return
        }
        let resp = await dispatch(updateTargetFxn(formValues, targetDataId))
        if (resp && !resp.error) {
            getSingleUserTarget()
        }
    }

    return (
        <Drawer
            visible={visible}
            onClose={onClose}
            width={'35%'}
            closable={true}
            maskClosable={true}
            title={'Edit Target'}>
            <div className={'card'}>
                <div className="card unizportal">
                    <Form
                        className={'horizontal-form'}
                        autoComplete="off"
                        onSubmit={handleSubmit}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item>
                                    <InputBox title={'User Type'}>
                                        <Select
                                            value={formValues.userType}
                                            onChange={target => {
                                                handleChange({userType: target})
                                            }}
                                            placeholder=" Select User Type"
                                            name="userType">
                                            <Option value="Arm">Arm (Agent Relationship Manager)</Option>
                                            <Option value="MarketingUser">Marketing User</Option>
                                        </Select>
                                    </InputBox>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <InputBox title={' User Id'}>
                                        <Select
                                            name="userId"
                                            allowClear={true}
                                            showSearch={true}
                                            onChange={item => {
                                                handleChange({userId: item})
                                            }}
                                            value={formValues.userId || undefined}>
                                            <option value={""}>Choose User</option>
                                            {
                                                userList && userList.length
                                                    ? userList.map((item, key) => {
                                                        return (
                                                            <Option value={item._id} key={key}>
                                                                {item.name}
                                                            </Option>
                                                        )
                                                    }) : null
                                            }
                                        </Select>
                                    </InputBox>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <InputBox title={' User Target'}>
                                        <Input
                                            onChange={({target}) => {
                                                handleChange({target: target.value})
                                            }}
                                            value={formValues.target}
                                            className={'form-control'}
                                            name="target"
                                            placeholder="Enter Target"
                                        />
                                    </InputBox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Button type="primary" htmlType="submit" className={'btn'}>
                            Save
                        </Button>
                    </Form>
                </div>
            </div>
        </Drawer>
    )
}

export default EditUserTarget
