import React, { useEffect, useState } from 'react'
import styles from './styles.less'
import WhatsNew from '../../containers/WhatsNew/view/whatsNewDrawer'
import { resetWhatsNewCountFxn, getWhatsNewCountFxn } from '../../containers/WhatsNew/actions/whatsNew'
import { Badge } from 'antd'

const PromotionIcon = () => {
  let [whatsNewVisible, setWhatsNewVisible] = useState(false)
  let [whatsNewCount, setWhatsNewCount] = useState(0)
  useEffect(() => {
    loadWhatsNewCount()
  }, [])
  let events = {
    showWhatsNew: () => {
      setWhatsNewVisible(true)
      resetWhatsCount()
    },
    hideWhatsNew: () => {
      setWhatsNewVisible(false)
    }
  }
  const loadWhatsNewCount = async () => {
    let { data } = await getWhatsNewCountFxn()
    if (data && data.whatsNewCount) {
      setWhatsNewCount(data.whatsNewCount)
    }
  }

  const resetWhatsCount = async () => {
    let { success } = await resetWhatsNewCountFxn()
    if (success) {
      setWhatsNewCount(0)
    }
  }


  return (
    <>
           <span
             onClick={() => {
               events.showWhatsNew()
             }}>
             <span
               className={'antd-pro-components-global-header-index-action'}>
                 <Badge count={whatsNewCount} style={{ boxShadow: 'none' }} className={styles.badge}>
                  <img src={'../dist/icons/promotion.png'}
                       className={styles.announcementIcon} />
                </Badge>
               </span>
           </span>

      {whatsNewVisible ? (
        <WhatsNew
          width={'45%'}
          visible={whatsNewVisible}
          onClose={() => events.hideWhatsNew()}
        />
      ) : null}
    </>
  )
}
export default PromotionIcon
