import { customAxios as axios, getToken } from '../../../request'
import {
  pendingForVisaAppUrl,
  pendingForGcKeyAppUrl,
  canadaFcmtVisaApprovedAppListUrl,
  cambieOnShoreApplicationListUrl, fcmtOnShoreApplicationListUrl, cambieOnShoreApplicationExportApi
} from '../apis'
import { notification } from 'antd'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'

export const pendingForVisaAppFxn = async (filters) => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(pendingForVisaAppUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const pendingForGcKeyAppFxn = async (filters) => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(pendingForGcKeyAppUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}


export const canadaFcmtVisaApprovedAppListFxn = async (filters) => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(canadaFcmtVisaApprovedAppListUrl(), config)
  /*if (data.error) {
      notification.error({
          message: data.message || 'Error'
      })
  }*/
  return data.data
}

export const cambieOnShoreApplicationListFxn = async (filters) => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(cambieOnShoreApplicationListUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const fcmtOnShoreApplicationListFxn = async (filters) => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(fcmtOnShoreApplicationListUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}
export const cambieOnShoreApplicationExportFxn = (vallData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(cambieOnShoreApplicationExportApi(), vallData, getToken())
  dispatch(hidePageLoad())
  return data
}
