import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Drawer,
  Form,
  Input,
  Icon,
  notification,
  DatePicker
} from 'antd'
import { useDispatch } from 'react-redux'

import { addFollowupFxn } from './actions/scheduleMeeting'
import InputBox from '../../components/_utils/InputBox'
import moment from 'moment'
const dateFormat = 'DD/MM/YYYY'
const { TextArea } = Input

const CloseFollowup = props => {
  const { meetingId, applicationId, visible, onClose } = props

  console.log(meetingId, 'shfjrewrwe')
  const dispatch = useDispatch()
  const [content, setContent] = useState('')
  const [date, setDate] = useState('')

  const handleSubmit = async e => {
    e.preventDefault()
    if (!content) {
      notification.warning({
        message: 'Content is required'
      })
      return
    } else {
      try {
        const obj = {
          meetingId: meetingId,
      
          content: content,
          type:'Closed'
        }

        await dispatch(addFollowupFxn(obj))

        setDate('')
        setContent('')
        
        if (onClose) {
          onClose()
        }
        notification.success({
          message: 'Followup closed successfully'
        })
      } catch (error) {
        notification.success({
          message: 'Followup not closed successfully'
        })
      }
    }
  }

  return (
    <Drawer
      width={700}
      title="Close Follow up"
      placement="right"
      visible={visible}
      onClose={onClose}>
      <Card bordered={false} style={{ background: 'rgb(236 235 235)' }}>
        <Form onSubmit={handleSubmit}>
          <Form.Item>
            <TextArea
              rows={4}
              value={content}
              onChange={e => setContent(e.target.value)}
              placeholder="Enter your followup here"
            />

           
          </Form.Item>

          <Form.Item className="mt7">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  )
}

export default CloseFollowup
