import React, { useEffect, useRef, useState } from 'react'
import {
  Drawer,
  Select,
  Button,
  Tooltip,
  Avatar,
  Icon,
  Modal,
  Popconfirm,
  Row,
  Col, notification, Form, Tag
} from 'antd'
import { connect, useDispatch, useSelector } from 'react-redux'
import { studentSelectedApplications, exportApplicationsList } from '../actions/chooseStudent'
import {
  statusColors,
  filterOption,
  newFormatDisplayDate,
  defaultLogoList,
  longDisplayDate,
  fcmtUniversity,
  ukApplicationSteps,
  canadaApplicationSteps,
  australiaApplicationSteps,
  usaApplicationSteps, Intakes, ConditionOptions, canadaOnShoreApplicationStatusSteps
} from '../../../components/_utils/appUtils'
import { getPushPathWrapper } from '../../../routes'
import { TableComp } from 'sz-react-utils-lite'
import SelectPendency from '../views/selectPendency'
import lodash from 'lodash'
import {
  AllowComponentRightsWise,
  CheckBranchUserFxn,
  AllowComponentUserWise,
  CheckBranchUserRight,
  ShowEnrollmentComponent, CheckReOpenRight, CheckCaseCloseRight
} from '../../WebComponent/allowComponentRightsWise'
import { getPendingPendency } from '../../../components/_utils/utils'
import ApplyCourse from '../../student/views/applyForApplicationDrawer'
import { CheckUserRight } from '../../dashboard/views/DashboardUserWise'
import {
  withdrawalStudent,
  applicationCloseDirect,
  reCalculateCommission, updateApplicationTuitionFees
} from '../../student/actions/student'
import moment from 'moment'
import StudentOtherApp from '../views/StudentOtherApplication'
import { listAllUniversities } from '../../university/actions/university'
import { listAllUsers } from '../../users/actions/user'
import { showEnrollmentAction } from '../../../components/_utils/appUtils'
import AddNote from '../drawers/applicationAddNoteComponent'
import { AgentArmComponent } from '../../../components/_utils/AgentBranchManagerComponent'
import { listAllCountries } from '../../countries/actions/countries'
import UpdateManuallyMarketingUserDrawer from '../drawers/updateManuallyMarketingUserDrawer'
import { australiaOnshoreApplicationStatusSteps } from '../../../components/_utils/ApplicationStatusSteps'
import ChangeUniversityComponent from '../drawers/changeUniversity'

const managerViewRight = ['admin', 'branchUser']
let { ShowUpdateButton } = UpdateManuallyMarketingUserDrawer

const { Option } = Select
const initialState = {
  applicationList: [],
  applicationObj: {}
}
const commissionObj = {
  commissionAmount: 0,
  tuitionFee: 0,
  studentId: '',
  applicationId: ''
}
const RenderComment = (props) => {
  let { item } = props
  let [hideV, setHideV] = useState(true)
  return (
    item && <div>
      {item.addedByUserId && item.addedByUserId.name ?
        <div className={'alignRight colorPrimary font12'}>
          (Added By : {item.addedByUserId.name} )
        </div> : null}
      <div>{longDisplayDate(item.time)}</div>

      <div className={hideV ? 'appCommentDotsReplace' : ''}>
        {item && item.text ? item.text : ''}
      </div>
      {item && item.text.length && item.text.length > 50 ? <div className={'alignRight'}>
        {hideV ? <a onClick={() => {
          setHideV(false)
        }}>Show More</a> : <a onClick={() => {
          setHideV(true)
        }}>Show Less</a>}
      </div> : ''}

    </div>
  )
}
const AppListForCounsellor = (props) => {
  let { visible, onClose, studentObj = {}, pageType = 'student', queryData, reloadTable, currentUser } = props
  const [state, setState] = useState(initialState)
  const [transferAppVisible, setTransferAppVisible] = useState(false)
  const [studentData, setStudentData] = useState({})
  const [visibleAddCourseDrawer, setVisibleAddCourseDrawer] = useState(false)
  const [addPendencyDrawer, setAddPendencyDrawer] = useState(false)
  const [name, setName] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [searchData, setSearchData] = useState(false)
  const [allAgent, setAllAgent] = useState([])
  const [agentId, setAgentId] = useState('')
  const [universityList, setUniversityList] = useState([])
  const [appUniversityId, setAppUniversityId] = useState('')
  const [visibleOtherAppDrawer, setVisibleOtherAppDrawer] = useState(false)
  const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
  const [totalApplication, setTotalApplication] = useState(0)
  const [visibleFeesModal, setVisibleFeesModal] = useState(false)
  const [transferToOtherDepartmentVisible, setTransferToOtherDepartmentVisible] = useState(false)
  const [branchManagerId, setBranchManagerId] = useState('')
  const [studentManagerId, setStudentManagerId] = useState('')
  const [feesObj, setFeesObj] = useState(commissionObj)
  let { applicationObj } = state
  let [countryList, setCountryList] = useState([])
  const [countryId, setCountryId] = useState('')
  let [statusList, setStatusList] = useState([])
  let [status, setStatus] = useState([])
  let viewOnlyRight = CheckBranchUserFxn() // check branch user right
  const [branchUserList, setBranchUserList] = useState([])
  const [branchUserId, setBranchUserId] = useState('')
  let [intake, setIntake] = useState('')
  let [draftApplication, setDraftApplication] = useState('')
  const [visibleTuitionFee, setVisibleTuitionFee] = useState(false)
  const [visibleOshcDrawer, setVisibleOshcDrawer] = useState(false)
  const [visibleUniversityCommission, setVisibleUniversityCommission] = useState(false)
  let [marketingManuallyObj, setMarketingManuallyObj] = useState({
    visible: false,
    studentId: '',
    application: {}
  })


  let [selectedApplication, setSelectedApplication] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })
  let [enrollmentObj, setEnrollmentObj] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })
  let [changeUniversity, setChangeUniversity] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })
  let [updateUniversity, setUpdateUniversity] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })
  let [branchManagerList, setBranchManagerList] = useState([])
  let [studentManagerList, setStudentManagerList] = useState([])

  let [agentApp, setAgentApp] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })

  const dispatch = useDispatch()
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

  const tableAppRef = useRef()

  let apiRequest = (params) => {
    return new Promise(async (resolve) => {
      if (pageType == 'student') {
        params['studentId'] = studentObj._id
        let {
          appAgentId,
          branchManagerId,
          studentManagerId,
          appUniversityId,
          countryId,
          branchUserId,
          intake,
          appStatus
        } = studentObj
        if (appAgentId) {
          params.agentId = appAgentId
        }
        if (branchManagerId) {
          params.branchManagerId = branchManagerId
        }
        if (studentManagerId) {
          params.studentManagerId = studentManagerId
        }
        if (appUniversityId) {
          params.appUniversityId = appUniversityId
        }
        if (countryId) {
          params.countryId = countryId
        }
        if (branchUserId) {
          params.branchUserId = branchUserId
        }
        if (intake) {
          params.intake = intake
        }
        if (appStatus) {
          params.status = appStatus
        }

      } else {
        params = { ...params, ...queryData }
      }
      if (name) {
        params.name = name
      }
      if (dateOfBirth) {
        params.dateOfBirth = moment(dateOfBirth)
      }

      if (agentId) {
        params.agentId = agentId
      }
      if (branchManagerId) {
        params.branchManagerId = branchManagerId
      }
      if (studentManagerId) {
        params.studentManagerId = studentManagerId
      }

      if (appUniversityId) {
        params.appUniversityId = appUniversityId
      }
      if (countryId) {
        params.countryId = countryId
      }
      if (branchUserId) {
        params.branchUserId = branchUserId
      }
      if (intake) {
        params.intake = intake
      }
      if (status) {
        params.status = status
      }
      if (draftApplication) {
        params.draftApplication = draftApplication
      }
      let page = params.page

      if (searchData) {
        setSearchData(false)
        if (name || dateOfBirth || agentId || appUniversityId || countryId || branchUserId || intake || draftApplication || status) {
          page = 1
        }
      }
      try {
        let resp = await dispatch(studentSelectedApplications({ ...params, regExFilters: ['name'], page: page }))
        setTotalApplication(resp.total)
        resolve(resp)
      } catch (e) {
        // console.log(e)
      }

    })
  }
  let exportExcel = async () => {
    let params = {}
    if (pageType == 'student') {
      params['studentId'] = studentObj._id
    } else {
      params = { ...params, ...queryData }
    }

    if (name) {
      params.name = name
    }
    if (dateOfBirth) {
      params.dateOfBirth = moment(dateOfBirth)
    }

    if (agentId) {
      params.agentId = agentId
    }
    if (branchManagerId) {
      params.branchManagerId = branchManagerId
    }

    if (studentManagerId) {
      params.studentManagerId = studentManagerId
    }

    if (appUniversityId) {
      params.appUniversityId = appUniversityId
    }
    params.results = totalApplication
    let { success, filePath, message } = await dispatch(exportApplicationsList(params))
    if (success) {
      notification.success({ message: message })
      // window.open(`${apiUrl}${filePath}`, 'Download')
    } else {
      notification.error({ message: message })
    }
  }


  useEffect(() => {
    loadBranchManager()
    loadStudentManager()
    loadCountryList()
    if (queryData && queryData.openFrom !== 'university') {
      loadUniversityNameList()
    }
    if (queryData && queryData.openFrom !== 'agent' && currentUser.userType !== 'agent' && currentUser.userType !== 'subAgent') {
      loadAgentData()
    }
    if (queryData && queryData.openFrom !== 'branchUser' && currentUser.userType !== 'branchUser') {
      loadCurrentUserData()
    }
  }, [])


  const loadAgentData = async () => {
    let params = {
      results: 15000,
      userType: 'agent',
      sortField: 'companyName',
      sortOrder: 'ascend',
      select: ['name', 'companyName']
    }
    let { data } = await dispatch(listAllUsers(params))
    if (data && data.length) {
      setAllAgent(data)
    }
  }
  const loadUniversityNameList = async () => {
    let params = {
      results: 5000,
      select: ['universityName'],
      sortField: 'universityName',
      sortOrder: 'ascend',
      showUniversity: [true, false]
    }
    if (queryData.countryId) {
      params.universityCountry = queryData.countryId
    }
    let { data } = await dispatch(listAllUniversities(params))
    if (data && data.length) {
      setUniversityList(data)
    }
  }
  const loadCurrentUserData = async () => {
    let params = {
      results: 1000,
      userType: 'branchUser',
      sortField: 'name',
      sortOrder: 'ascend',
      select: ['name', 'userType', 'mobile', 'email']
    }
    let { data } = await dispatch(listAllUsers(params))
    if (data && data.length) {
      setBranchUserList(data)
    }
  }
  const events = {
    openAddPendencyDrawer: (value) => {
      setState({
        ...state,
        applicationObj: value
      })
      setAddPendencyDrawer(true)
    },
    closeAddPendencyDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setAddPendencyDrawer(false)
    },
    reloadFxn: () => {

      tableAppRef.current.reload()
    },
    reloadAndClose: () => {
      tableAppRef.current.reload()
      setAddPendencyDrawer(false)
    },
    showTransferApp: () => {
      setTransferAppVisible(true)
    },
    hideTransferApp: () => {
      setTransferAppVisible(false)
      tableAppRef.current.reload()
    },
    showAddCourseDrawer: () => {
      setVisibleAddCourseDrawer(true)
    },
    hideAddCourseDrawer: () => {
      setVisibleAddCourseDrawer(false)
      tableAppRef.current.reload()
    },
    refreshApplication: () => {
      tableAppRef.current.reload()
    },
    closeDirectApp: async (record) => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(applicationCloseDirect(obj))
      tableAppRef.current.reload()
    },
    chooseDate: (date, stringDate) => {
      setDateOfBirth(date)

    },
    searchData: () => {
      setSearchData(true)
      setTimeout(() => {
        tableAppRef.current.reload()
      }, 300)

    },
    clearData: () => {
      // apiRequest()
      setSearchData(true)
      setAgentId('')
      setBranchManagerId('')
      setBranchUserId('')
      setStudentManagerId('')
      setAppUniversityId('')
      setIntake('')
      setName('')
      setDraftApplication('')

      setTimeout(() => {
        tableAppRef.current.reload()
      }, 300)

    },
    enterName: (value) => {
      setName(value)

    },
    openOtherAppDrawer: (value) => {
      setState({
        ...state,
        applicationObj: value
      })
      setVisibleOtherAppDrawer(true)
    },
    closeOtherAppDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setVisibleOtherAppDrawer(false)
    },
    openAddCommentDrawer: (value) => {
      setState({
        ...state,
        applicationObj: value
      })
      setVisibleAddCommentDrawer(true)
    },
    closeAddCommentDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setVisibleAddCommentDrawer(false)
    },
    hideVisibleFeesModal: () => {
      setVisibleFeesModal(false)
    },
    setCommissionLocal: (data) => {
      setFeesObj({
        ...feesObj,
        ...data
      })
    },


    hideTransferToOtherDepartment: () => {
      setTransferToOtherDepartmentVisible(false)
      tableAppRef.current.reload()
    }

  }
  const loadBranchManager = async () => {
    let obj = {
      userType: 'branchManager',
      customQuery: {
        'agents.0': { $exists: true }
      },
      results: 100,
      select: ['name', 'email', 'mobile', 'agents', 'countryId']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setBranchManagerList(data)
  }
  const loadStudentManager = async () => {
    let obj = {
      userType: 'branchManager',
      branchManagerType: 'studentWise',
      results: 100,
      select: ['name', 'email', 'mobile', 'agents', 'countryId']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setStudentManagerList(data)
  }

  const reActivateApp = (record) => {
    setSelectedApplication({
      visible: true,
      studentId: record._id,
      studentData: record,
      application: record.applications
    })
  }
  const onCloseReActivate = () => {
    setSelectedApplication({
      visible: false,
      studentId: '',
      studentData: {},
      application: {}
    })
    tableAppRef.current.reload()
  }

  const agentReactiveAppEvents = {
    openDrawer: (record) => {
      setAgentApp({
        visible: true,
        studentId: record._id,
        studentData: record,
        application: record.applications
      })
    },
    hideDrawer: () => {
      setAgentApp({
        visible: false,
        studentId: '',
        studentData: {},
        application: {}
      })
      tableAppRef.current.reload()
    }
  }

  const enrollmentApp = (record) => {
    setEnrollmentObj({
      visible: true,
      studentId: record._id,
      studentData: record,
      application: record.applications
    })
  }
  const onCloseEnrollmentApp = () => {
    setEnrollmentObj({
      visible: false,
      studentId: '',
      studentData: {},
      application: {}
    })
    tableAppRef.current.reload()
  }

  const changeUniversityFxn = (record) => {
    setChangeUniversity({
      visible: true,
      studentId: record._id,
      studentData: record,
      application: record.applications
    })
  }
  const onCloseChangeUniversityFxn = () => {
    setChangeUniversity({
      visible: false,
      studentId: '',
      studentData: {},
      application: {}
    })
    tableAppRef.current.reload()
  }


  const updateUniversityEvent = {
    openDrawer: (record) => {
      setUpdateUniversity({
        visible: true,
        studentId: record._id,
        studentData: record,
        application: record.applications
      })
    },
    closeDrawer: () => {
      setUpdateUniversity({
        visible: false,
        studentId: '',
        studentData: {},
        application: {}
      })
      tableAppRef.current.reload()
    }
  }

  const showEnrollmentBtn = (record) => {
    let application = record.applications
    return (showEnrollmentAction(application.statusList) ?
      true : false)
  }


  const columns = [
    {
      title: 'App#',
      key: 'applications.id',
      dataIndex: 'applications.id',
      searchTextName: 'applications.id',
      width: 60,
      render: (item) => {
        return (
          <div style={{ width: 60 }}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      hidden: pageType == 'student',
      render: (item, record) => {
        return (
          viewOnlyRight ? <Tooltip title={'Edit Student'}>
            <a className={'linkAA'}
               onClick={() => dispatch(
                 getPushPathWrapper('student.editStudent', { id: record._id }))
               }>
              {item} <Icon type={'edit'} />
            </a>
          </Tooltip> : item
        )
      }
    },
    {
      title: 'DOB',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      hidden: pageType == 'student',
      width: 90,
      render: (val) => {
        return (
          <div style={{ width: 90 }}>
            {newFormatDisplayDate(val)}
          </div>
        )
      }
    },
    {
      title: 'Status',
      key: 'applications.status',
      dataIndex: 'applications.status',
      width: 80,
      render: (item, record) => {
        return (
          <React.Fragment>
            <div className={'label label1'}
                 style={{ background: statusColors(item), width: 80, padding: 3 }}>{item}</div>
          </React.Fragment>
        )
      }
    },

    {
      title: 'University',
      key: 'applications.courseUniversity',
      dataIndex: 'applications.courseUniversity',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.smallLogo && item.smallLogo.url ?
              <Avatar src={item.smallLogo.url} size={20} />
              : <Avatar src={defaultLogoList.defaultUniversityLogo} size={20} />}
            {item.universityName ? `  ${item.universityName}` : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Country',
      key: 'applications.universityCountry',
      dataIndex: 'applications.universityCountry',
      render: (item, record) => {
        let { applications } = record
        return (
          <React.Fragment>
            {item && item.countryName ? item.countryName : null}, {' '}
            {record && record.stateName ? record.stateName : null}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Program',
      key: 'applications.courseName',
      dataIndex: 'applications.courseName',
      width: 150,
      render: (item, record) => {
        return (
          <div style={{ width: 150 }}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Intake',
      key: 'applications.intake',
      dataIndex: 'applications.intake',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
          </React.Fragment>
        )
      }
    },


    {
      title: 'Pendency',
      key: 'applications.pendencyArr',
      dataIndex: 'applications.pendencyArr',
      width: 100,
      render: (item, record) => {
        return (
          <React.Fragment>
            <Tooltip title={'Add Pendency'}>
              <span onClick={() => viewOnlyRight ? events.openAddPendencyDrawer(record) : ''}
                    className='badge'>{item && item.length ? getPendingPendency(item) : 0}</span>

            </Tooltip>{' '}


          </React.Fragment>
        )
      }
    },


    {
      title: 'Current User',
      key: 'applications.assignedUserId',
      dataIndex: 'applications.assignedUserId',
      width: 250,
      render: (val, record) => {
        let { agentId, statusList, addByUserId } = record.applications
        return (
          <React.Fragment>
            {val && val.name ? val.name : ''}<br />
            <div>Agent : {agentId && agentId.companyName ? agentId.companyName : ''}</div>
            {addByUserId && addByUserId.name ?
              <div className={'mt5 colorPrimary font12'}>(Added By
                : {addByUserId && addByUserId.name ? addByUserId.name : ''})</div> : ''}

            <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser']}>
              {agentId && agentId.mobile ? <div>Mob : {agentId.mobile}</div> : ''}
            </CheckUserRight>
            <div>{longDisplayDate(statusList.date)}</div>
          </React.Fragment>
        )
      }
    },
    {
      title: 'Student Manager',
      dataIndex: 'branchManagerId',
      hidden: !managerViewRight.includes(user.userType),
      width: 125,
      key: 'branchManagerId',
      render: (item, record) => {
        // console.log(record)
        let { branchManagerId } = record
        return (
          <React.Fragment>
            {branchManagerId ? branchManagerId.name : null}
          </React.Fragment>
        )
      }
    },


    {
      title: 'Marketing',
      dataIndex: 'marketingUserId',
      width: 125,
      key: 'branchManagerId',
      render: (item, record) => {
        let { marketingUserId } = record.applications
        return (
          <React.Fragment>
            {marketingUserId && marketingUserId.name ? marketingUserId.name : null}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Latest Note',
      key: 'applications.latestComment',
      dataIndex: 'applications.latestComment',
      width: 160,
      render: (val) => {
        return (
          <div style={{ width: 160 }}>
            {val && val.text ? <RenderComment item={val} /> : null}
          </div>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 100,
      render: (val, record) => {
        return (
          <div className={'actionBtnGroup'}>

            <div>
              <Tooltip title={'View Application'}>
                <a className='btn'
                   href={`/application/view?appId=${record.applications._id}&studentId=${record._id}`}
                ><img src='/dist/img/view.svg' alt='' className={'eyeBtn'} /></a>

              </Tooltip>
              <AllowComponentRightsWise rightUserType={['admin', 'branchUser', 'branchManager']}>
                <Tooltip title={'View Other Application'}>
                  <button className='btn' onClick={() => {
                    events.openOtherAppDrawer(record)
                  }}><Icon type={'ordered-list'} /></button>

                </Tooltip>
                <Tooltip title={'Add Note'}>
                  <button className='btn' onClick={() => {
                    events.openAddCommentDrawer(record)
                  }}><Icon type={'plus'} /></button>
                </Tooltip>
              </AllowComponentRightsWise>

            </div>


            {/*

            <AllowComponentUserWise rightUserType={['admin', 'branchManager']}>
              {record.applications.status !== 'Case Close' ?
                <Tooltip title={'Change University'}>
                  <button className='btn' onClick={() => changeUniversityFxn(record)}>
                    <img src={'/assets/shuffle-arrows.png'} />
                  </button>
                </Tooltip> : null}
            </AllowComponentUserWise>
*/}


          </div>
        )
      }
    }

  ]

  const loadCountryList = async () => {
    let params = {
      results: 5000,
      select: ['countryName'],
      sortField: 'countryName',
      sortOrder: 'ascend'
    }
    let { data } = await dispatch(listAllCountries(params))
    if (data && data.length) {
      setCountryList(data)
      // console.log('google.com', data)
    }
  }

  useEffect(() => {
    loadCountryStatus()
  }, [countryId, countryList])


  const loadCountryStatus = async () => {
    let findCountry = lodash.find(countryList, (item) => {
      return item._id == countryId
    })
    if (findCountry) {
      let statusListTemp = []
      if (findCountry.countryName.toLowerCase() == 'uk') {
        statusListTemp = ukApplicationSteps
      } else if (findCountry.countryName.toLowerCase() == 'canada') {
        statusListTemp = canadaApplicationSteps
      } else if (findCountry.countryName.toLowerCase() == 'australia') {
        statusListTemp = australiaApplicationSteps
      } else if (findCountry.countryName.toLowerCase() == 'usa') {
        statusListTemp = usaApplicationSteps
      } else if (findCountry.countryName.toLowerCase() == 'canada onshore') {
        statusListTemp = canadaOnShoreApplicationStatusSteps
      } else if (findCountry.countryName.toLowerCase() == 'australia onshore') {
        statusListTemp = australiaOnshoreApplicationStatusSteps
      }
      let temp = lodash.filter(statusListTemp, (item) => {
        return item.name !== 'New Student'
      })
      setStatusList(temp)
    } else {
      setStatusList([])
    }
  }


  const resp = useSelector(state => {
    let { loadNoteRedux } = state.chooseApplication
    if (loadNoteRedux) {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
      dispatch({ type: 'END_LOAD_NOTE' })
    }
  })


  const loadColumns = () => {
    let columnArr = []
    lodash.each(columns, (item) => {
      if (item.hidden == undefined) {
        columnArr.push(item)
      } else {
        if (!item.hidden) {
          columnArr.push(item)
        }
      }
    })
    return columnArr
  }

  const extra = (
    <>
      <div className={'d-flex jcsb'}>

        <div>
          <AllowComponentUserWise user={user} rightUserType={['admin', 'branchManager', 'branchUser']}>
            <Tooltip title={'Withdrawal Student'}>
              <a className={'btn btn-danger md roundNew'}
                 onClick={() => events.withdrawalStudent()}>
                <Icon type={'cross'} /> Withdrawal Student
              </a>
            </Tooltip>
          </AllowComponentUserWise>
          <CheckBranchUserRight>
            <Tooltip title={'Add New Application'}>
              <a className={'btn btn-default md roundNew ml5'}
                 onClick={() => events.showAddCourseDrawer()}>
                <Icon type={'plus'} /> Add New Application
              </a>
            </Tooltip>
          </CheckBranchUserRight>
        </div>

        <div>
          <AllowComponentUserWise rightUserType={['admin', 'branchManager', 'branchUser', 'agent']}>
            <Tooltip title={'Edit Student'}>
              <a className={'btn btn-default md roundNew'}
                 onClick={() => dispatch(
                   getPushPathWrapper('student.editStudent', { id: studentObj._id }))
                 }>
                <Icon type={'edit'} /> Edit Student
              </a>
            </Tooltip>
          </AllowComponentUserWise>
        </div>
      </div>


    </>
  )

  return (
    <React.Fragment>

      <Drawer visible={visible}
              width={'95%'}
              closable={true}
              maskClosable={true}
              onClose={onClose}>
        <div className='col-lg-12 pd-0'>

          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>{studentObj.name ? studentObj.name : 'All'} Applications ({totalApplication})</h5>

            </div>
            <div className='card-body table-responsive top_P10'>
              {extra}

              <TableComp columns={loadColumns()}
                         rowKey={(item, index) => {
                           return index
                         }}
                         apiRequest={apiRequest}
                         pagination={{
                           showSizeChanger: true,
                           pageSizeOptions: ['10', '20', '50', '100'],
                           defaultPageSize: 10
                         }}
                         ref={tableAppRef}
              />

            </div>
          </div>
        </div>


        {addPendencyDrawer ?
          <SelectPendency visible={addPendencyDrawer}
                          applicationObj={state.applicationObj}
                          onClose={events.reloadAndClose}
                          onAddPendencyClose={events.closeAddPendencyDrawer}
                          reloadFxn={events.reloadFxn}
          /> : null}
      </Drawer>


      {visibleAddCourseDrawer ? <>
        <ApplyCourse
          visible={visibleAddCourseDrawer}
          onClose={() => events.hideAddCourseDrawer()}
          refreshApplication={() => events.refreshApplication()}
          studentId={studentObj._id} />

      </> : null}


      {visibleOtherAppDrawer ?
        <StudentOtherApp visible={visibleOtherAppDrawer}
                         applicationObj={state.applicationObj}
                         onStudentOtherAppClose={() => events.closeOtherAppDrawer()}
                         reloadFxn={events.reloadFxn}
        /> : null}


      {visibleAddCommentDrawer && applicationObj && applicationObj._id ?
        <AddNote visible={visibleAddCommentDrawer} studentData={applicationObj}
                 addedType={'direct'}
                 reloadFxn={events.reloadFxn}
                 onClose={() => events.closeAddCommentDrawer()}
                 applicationId={applicationObj.applications._id} /> : null}

      {changeUniversity.visible ?
        <ChangeUniversityComponent {...changeUniversity} onClose={onCloseChangeUniversityFxn} /> : null}


    </React.Fragment>
  )
}

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedAppListForCounsellor = Form.create()(AppListForCounsellor)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedAppListForCounsellor)
