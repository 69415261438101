import { Button, Col, Drawer, Form, notification, Row } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {
  closeAgentDealFxn,
  getUniversityFoDealCloseFxn
} from '../actions/closeDeal'

const UpdateDealCloseDrawer = props => {
    let dispatch = useDispatch()
    let {
        visible,
        onClose,
        onSubmit,
        agentId,
        form: {getFieldDecorator, setFieldsValue},
        dealClose,
        universityId
    } = props;

    let [universityFoDealClose, setUniversityFoDealClose] = useState([])
    let chooseUni = (uniId) => {
        let foundUni = _.find(dealClose, (dealItem) => {
            return (dealItem.universityId && dealItem.universityId._id) == (uniId)
        })
        if (foundUni) {
            setFieldsValue({
                amount: foundUni.amount
            })
    } else {
      setFieldsValue({
        amount: ''
      })
    }
  }

  let inputTypes = {
    fields: [
        {
            key: 'universityId',
            label: 'University',
            type: 'select',
            span: 8,
            required: true,
            options: universityFoDealClose,
            showSearch: true,
            keyAccessor: x => x.universityId._id,
            valueAccessor: x => `${x.universityId.universityName}`,
            disabled: true,
            onChange: x => {
                props.form.setFieldsValue({
                    universityId: x
                })
                chooseUni(x)
            }
        },
      {
        key: 'amount',
        label: 'Amount (CAD)',
        placeholder: 'Amount (CAD)',
        type: 'number',
        required: true
      }
    ]
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
      wrapperCol: {
          xs: {span: 24},
          sm: {span: 24},
          md: {span: 24}
      }
  }
    useEffect(() => {
        events.getUniversityFoDealClose()
    }, [])

    useEffect(() => {
        setTimeout(() => {
            props.form.setFieldsValue({
                universityId: universityId
            })
            if (universityId) {
                chooseUni(universityId)
            }
        }, 1000)
    }, [universityId])


    const handleStatusUpdate = e => {
        const {form} = props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                valData.agentId = agentId
                let resp = await dispatch(closeAgentDealFxn(valData))
                if (resp && resp.success) {
          onSubmit()
          onClose()
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }
  let events = {
      getUniversityFoDealClose: async () => {
          let {data} = await dispatch(getUniversityFoDealCloseFxn({}))
          if (data) {
              setUniversityFoDealClose(data)
          }
      },
      /*getUniversityFoDealClose: async () => {
        let { data } = await dispatch(getUniversityFoDealCloseFxn({}))

        if (data) {
          if (dealClose && dealClose.length) {
            data = _.reject(data, item => {
              let foundUni = _.find(dealClose, dealItem => {
                return (dealItem.universityId && dealItem.universityId._id) == (item.universityId && item.universityId._id)
              })
              if (foundUni) {
                return true
              }
            })
            setUniversityFoDealClose(data)
          } else {
            setUniversityFoDealClose(data)
          }
        }
      }*/

  }
  return (
    <>
      <Drawer
        visible={visible}
        onClose={onClose}
        title={`Update Close Deal`}
        width={'40%'}>
        <div className='form-box commissionBox'>
          <div className='card unizportal'>
            <Form onSubmit={handleStatusUpdate}>
              <Row gutter={16} className={'wrapBox'}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      <Col span={24} key={key}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout}
                        />
                      </Col>
                    </React.Fragment>
                  )
                })}
                <Col span={4}>
                  <Form.Item>
                      <Button
                          type='primary'
                          htmlType='submit'
                          className={'btn mt40 sm'}>
                          Update Close Deal
                      </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  )
}

const CloseDealDrawer = Form.create()(UpdateDealCloseDrawer)

export default CloseDealDrawer
