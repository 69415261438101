import React from 'react'
import { Form, Input, DatePicker, Row, Col, Button } from 'antd'

const AddFollowUp = ({ form, onSubmit }) => {
  const handleSubmit = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(values)
      }
    })
  }

  const { getFieldDecorator } = form

  return (
    <div className='form-container-follow'>
      <Form onSubmit={handleSubmit}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item className='form-item-follow' label='Follow Up Notes'>
              {getFieldDecorator('notes', {
                rules: [{ required: true, message: 'Please enter follow up notes!' }]
              })(<Input.TextArea rows={12} placeholder='Enter follow up notes' />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className='form-item-follow' label='Date'>
              {getFieldDecorator('date', {
                rules: [{ required: true, message: 'Please select a date!' }]
              })(<DatePicker style={{ width: '100%' }} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row className='button-row-follow'>
          <Col span={12}>
            <Button style={{ marginRight: 8 }}>Next Follow Up</Button>
          </Col>
        </Row>
        <Row className='button-row-follow'>
          <Col span={24}>
            <Button type='primary' htmlType='submit' className='save-button-follow'>Save</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Form.create({ name: 'followup_form' })(AddFollowUp)
