import React, {useState} from "react"
import {Button, Drawer, Input, Form, notification} from "antd";
import {InputBox} from "../../../components/_utils/appUtils";
import {useDispatch} from "react-redux";
import {addIcefCountryFxn} from "../actions";

const AddCountryDrawer = (props) => {
    let dispatch = useDispatch()
    let {visible, onClose, onSubmit} = props;
    let [countryName, setCountryName] = useState("")
    const handleSubmit = async () => {
        if (!countryName) {
            notification.warning({message: "Enter country name."})
            return
        }
        let resp = await dispatch(addIcefCountryFxn({countryName}));
        if (resp && resp.success) {
            onSubmit()
        }

    }
    return (
        <>
            <Drawer title={'Add Country'} visible={visible} width={'45%'} onClose={onClose}>
                <div className="form-box">
                    <div className="card unizportal">
                        <Form>
                            <InputBox title={'Country Name'}>
                                <input className={'form-control'}
                                       placeholder={'Country Name'}
                                       onChange={({target}) => {
                                           setCountryName(target.value)
                                       }}/>
                            </InputBox>
                            <Button type="primary" htmlType="submit" className="btn" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Form>
                    </div>
                </div>
            </Drawer>
        </>
    )
}
export default AddCountryDrawer
