import React from 'react'
import PaidApplicationListComponent from '../privateCollege/privateCollegePaidApplicationList'
import { CornerStoneCollege } from '../../../components/_utils/appUtils'

const CornerStonePaidApplicationList = () => {
  return (
    <PaidApplicationListComponent
      appName={'Cornerstone College'}
      redirectKey={'cornerstone.cornerstonePaidTT'}
      universityId={CornerStoneCollege} />
  )
}
export default CornerStonePaidApplicationList
