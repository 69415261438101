import { notification } from 'antd'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import {
  addUserTargetUrl,
  getSingleTrgetUrl,
  listUsersUrl,
  updateTargetUrl
} from '../api/userTarget'

export const addUserTargetFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let {data} = await axios.post(addUserTargetUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const listUsers = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(listUsersUrl(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}

export const getSingleTargetFxn = id => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    ...(await getToken())
  }
  let { data } = await axios.get(getSingleTrgetUrl(id), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}

export const updateTargetFxn = (valData, id) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.put(updateTargetUrl(id), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}
