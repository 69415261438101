import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { DefaultTablePagination, newFormatDisplayDate } from '../../../components/_utils/appUtils'
import { icefEventListFxn } from '../actions'
import AddEventDrawer from './addEventDrawer'
import { useHistory } from 'react-router-dom'

const EventList = (props) => {
  const tableRef = useRef()
  const dispatch = useDispatch()
  let [visible, setVisible] = useState(false)
  const [itemNo, setItemNo] = useState(1)
  const [totalEvent, setTotalEvent] = useState(0)
  const history = useHistory()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let resp = await dispatch(icefEventListFxn({
        ...params,
        regExFilters: ['contactPersonName']
      }))
      setTotalEvent(resp.total)
      resolve(resp)
    })
  }

  const events = {
    showAddEvent: () => {
      setVisible(true)
    },
    hideAddEvent: () => {
      setVisible(false)
    },
    onSubmit: () => {
      tableRef.current.reload()
      setVisible(false)
    }
  }

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 150,
      key: 'sno',
      fixed: true,
      render: (item, record, index) => {
        return (
          <div>
            {(itemNo - 1) * 10 + index + 1}
          </div>
        )
      }
    },
    {
      title: 'Event Name',
      dataIndex: 'eventName',
      key: 'eventName'
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (val) => {
        return (
          <div style={{ width: 90 }}>
            {newFormatDisplayDate(val)}
          </div>
        )
      }
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (val) => {
        return (
          <div style={{ width: 90 }}>
            {newFormatDisplayDate(val)}
          </div>
        )
      }
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state'
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country'
    },
    {
      title: 'Person',
      dataIndex: 'person',
      key: 'person'
    }

  ]

  const handleEventClick = () => {
    history.push('/workshops/addEvent')
  }
  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center'>
            <h5>All Event: {totalEvent || 0}</h5>
            <div className='search-box-table'>

            </div>
            <div className='sort-box-table mark-btn'>
              <a className='btn' onClick={handleEventClick}>
                <img src={'../dist/icons/plus.png'} className={'plus'} />
                Add Event</a>
            </div>
          </div>
          <div className='card-body table-responsive'>
            <TableComp
              columns={columns}
              apiRequest={apiRequest}
              ref={tableRef}
              pagination={DefaultTablePagination()} />
          </div>
        </div>
      </div>
      {visible ?
        <AddEventDrawer
          visible={visible}
          onClose={events.hideAddEvent}
          onSubmit={events.onSubmit}
        /> : ''}
    </div>
  )
}
export default EventList
