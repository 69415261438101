import React, { useEffect, useState } from 'react'
import { Card, Drawer, Tooltip } from 'antd'
import { RowTable } from '../../../components/_utils/RowTable'
import { loadFcmtApplicationDocuments } from '../actions/chooseStudent'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import UploadDocumentComponent from '../views/uploadDocumentComponent'
import { loadAllDocuments } from './actions'

const initialState = {
  documentDrawerVisible: false,
  title: '',
  description: '',
  document: {},
  documentKey: moment(),
  page: ''
}
let documentsKey = [
  'enrollmentLetter',
  'eslCertificate',
  'invoice',
  'letterhead',
  'loaLetter',
  'markSheetLetter',
  'offerReceiveLetter',
  'palLetter',
  'receipt',
  'supportingLetter'
]
const AllDocumentDrawer = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, studentData } = props
  let { applications } = studentData
  let [state, setState] = useState(initialState)
  let [documents, setDocuments] = useState([])
  let [applicationDocument, setApplicationDocument] = useState([])
  let loginUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  useEffect(() => {
    events.loadDocuments()
    events.loadFcmtApplicationDocumentsFxn()
  }, [applications])

  const documentSubName = (name) => {
    let exp = name.split('.').pop()
    let nameVal = name.split('.')[0]
    if (nameVal.length > 10) {
      nameVal = `${nameVal.substring(0, 10)}[...].${exp}`
      return nameVal
    } else {
      return name
    }
  }
  const checkDepartment = () => { //check department not to be interview department
    return loginUser.approveRoyalArtsLoa || loginUser.approveFCMTLoa
  }
  const events = {
    showDrawer: (title, page) => {
      setState({ ...state, documentDrawerVisible: true, title, page })
    },
    hideDrawer: () => {
      setState({ ...state, documentDrawerVisible: false })
      events.loadDocuments()
      events.loadFcmtApplicationDocumentsFxn()
    },
    loadDocuments: async () => {
      let studentId = studentData._id
      let applicationId = applications._id
      let resp = await dispatch(loadAllDocuments({ studentId, applicationId }))
      if (resp && resp.data) {
        console.log(resp, 'response')
        setDocuments(resp.data)
      }
    },
    loadFcmtApplicationDocumentsFxn: async () => {
      let studentId = studentData._id
      let applicationId = applications._id
      let resp = await dispatch(loadFcmtApplicationDocuments({ studentId, applicationId }))
      if (resp && resp._id) {
        setApplicationDocument(resp.applications)
      }
    }
  }


  return (
    <Drawer visible={visible}
            title={'Student\'s All Documents'}
            width={'40%'}
            closable={true}
            maskClosable={true}
            onClose={onClose}>
      <Card>
        {studentData && studentData.applications && studentData.applications.id ?
          <div className={'tableBox striped'}>
            <RowTable title={'Name (ID)'} value={<span>{studentData.name} ({studentData.studentId})</span>} />
            <RowTable title={'Application ID'} value={<span>{studentData.applications.id}</span>} />
            <RowTable title={'Application'} value={<span>{studentData.applications.courseName}</span>} />
            {checkDepartment() ?
              <RowTable title={'Attachments'} value={
                <a className='btn btn-default btn-xs' onClick={() => {
                  events.showDrawer('', 'document')
                }}>Upload Documents
                </a>
              }>
              </RowTable>
              : null}
          </div> : null}
        <div className={'mt10'}>
          {documents ? (
            <div>
              {Object.entries(documents).map(([key, items]) => (
                documentsKey.includes(key) && items && items.length > 0 && (
                  <div key={key} className='box' style={{ borderBottom: '1px solid black', paddingBottom: 10 }}>
                    <h6 style={{ marginTop: 20 }}>{key}</h6>
                    {items.map((item1, index) => (
                      <Tooltip key={index} placement='topRight' title={item1.fileName} className={'mr-2'}>
                        <a href={item1.path} download={item1.path}>
                          <span style={{
                            backgroundColor: '#ddd',
                            borderRadius: 5,
                            padding: '5px 10px'
                          }}>
                            {documentSubName(item1.fileName)}
                          </span>
                        </a>
                      </Tooltip>
                    ))}
                  </div>
                )
              ))}
            </div>
          ) : null}



          {applicationDocument && applicationDocument.loaDocument && applicationDocument.loaDocument.length ? <div>
            {applicationDocument.loaDocument.map((item, key) => {
              return (
                <div className='box' key={key} style={{ borderBottom: '1px solid black', paddingBottom: 10 }}>
                  <h6 style={{ marginTop: 20 }}>
                    {item.status}
                  </h6>
                  <Tooltip placement='topRight' title={item.title} key={key}>
                    <a href={item.url} download={item.url}>
                    <span style={{
                      backgroundColor: '#ddd',
                      borderRadius: 5,
                      padding: 5
                    }}>  {documentSubName(item.name)}</span>
                    </a>
                  </Tooltip>
                </div>
              )
            })}
          </div> : null}
          {applicationDocument && applicationDocument.signedVisaDraft && applicationDocument.signedVisaDraft.path ?
            <div className='box' style={{ borderBottom: '1px solid black', paddingBottom: 10 }}>
              <h6 style={{ marginTop: 20 }}>
                Signed Visa Draft
              </h6>
              <Tooltip placement='topRight' title={' Signed Visa Draft'}>
                <a href={applicationDocument.signedVisaDraft.path} download={applicationDocument.signedVisaDraft.path}>
                    <span style={{
                      backgroundColor: '#ddd',
                      borderRadius: 5,
                      padding: 5
                    }}>  {documentSubName(applicationDocument.signedVisaDraft.fileName)}</span>
                </a>
              </Tooltip>
            </div> : null
          }
        </div>

        {
          state.documentDrawerVisible ?
            <UploadDocumentComponent
              visible={state.documentDrawerVisible}
              title={state.title}
              page={state.page}
              studentId={studentData._id}
              applicationId={applications._id}
              onClose={events.hideDrawer} /> : ''
        }
      </Card>
    </Drawer>
  )
}
export default AllDocumentDrawer
