import React, { useState } from 'react'
import { Tooltip, Icon, notification } from 'antd'
import GrKeyDrawer from '../../applications/drawers/gcKeyDrawer'
import renderHTML from 'react-render-html'

const showGcKeyColumn = (user, email) => {
  if (user) {
    if (user.userType == 'admin' || user.email == 'tarun@unizportal.com' || user.email == email) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
const GcKeyComponent = (props) => {
  let { record, refreshTable } = props
  let { applications } = record
  let [visibleGcKey, setVisibleGcKey] = useState(false)
  let [hideV, setHideV] = useState(true)
  const events = {
    copyGcKeyDrawer: () => {
      let value = applications.gcKey ? applications.gcKey : ''
      navigator.clipboard.writeText(value)
      notification.success({ message: 'GC key Copied!' })
    },
    showGcKeyDrawer: () => {
      setVisibleGcKey(true)
    },
    closeGcKeyDrawer: () => {
      setVisibleGcKey(false)
      refreshTable()
    },
    onSubmitGcKeyDrawer: () => {
      setVisibleGcKey(false)
      refreshTable()
    }
  }
  return (
    <>
      <div className={'mb10'}>
        {applications && (
          <div>
            <div className={hideV ? 'appCommentDotsReplace' : ''}>
              {applications.gcKey ? renderHTML(applications.gcKey) : ''}
            </div>
            {applications.gcKey && applications.gcKey.length && applications.gcKey.length > 50 ? (
              <div className={'alignRight mt5'}>
                {hideV ? (
                  <a
                    onClick={() => {
                      setHideV(false)
                    }}>
                    Show More
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      setHideV(true)
                    }}>
                    Show Less
                  </a>
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        )}
        {applications && applications.acknowledgementFile && applications.acknowledgementFile.path ? <div>
          <a href={applications.acknowledgementFile.path} target={'_blank'}>Download Acknowledgement</a>
        </div> : null}
      </div>

      <div style={{ display: 'flex' }}>
        <Tooltip title={'Add GCKey'}>
          <a onClick={() => events.showGcKeyDrawer(record)} className={'btn btn-default round-bt xss aic'}>
            <Icon type={'plus'} />&nbsp; GC Key
          </a>
        </Tooltip>
        <Tooltip title={'Copy GCKey'}>
          <a onClick={() => events.copyGcKeyDrawer(record)} className={'btn btn-default round-bt xss aic'}>
            <Icon type={'copy'} />&nbsp; GC Key
          </a>
        </Tooltip>
      </div>

      {visibleGcKey && record && record._id ? (
        <GrKeyDrawer
          visible={visibleGcKey}
          studentData={record}
          onClose={() => events.closeGcKeyDrawer()}
          applicationId={applications._id}
          onSubmit={events.onSubmitGcKeyDrawer}
        />
      ) : null}

    </>
  )
}
export default GcKeyComponent
GcKeyComponent.showGcKeyColumn = showGcKeyColumn
