import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { Select, Row, Col, Tag, Popconfirm, notification, Button, Tooltip } from 'antd'
import { InputBox } from '../../components/_utils/appUtils'
import {
  addCounsellingSlotFxn, counsellingSlotListFxn,
  deleteCounsellingSlotFxn,
  getSlotByCounsellorFxn
} from './actions/counselling'
import Calendar from 'rc-calendar'
import 'rc-calendar/assets/index.css'
import './styles.css'
import { listAllUsers } from '../users/actions/user'
import CounsellingUpdate from './CounsellingUpdateDrawer'
import _ from 'lodash'

const dateObj = {
  dateFrom: 7,
  dateTo: 12
}
const { Option } = Select
let dateFormat = 'DD/MM/YYYY'
const EventCalendar = () => {
  const [date, setDate] = useState(moment())
  let [dateSlotList, setDateSlotList] = useState([])
  const [selectedEvents, setSelectedEvents] = useState([])
  const [counsellorList, setCounsellorList] = useState([])
  const [counselorId, setCounselorId] = useState('')

  const [slotList, setSlotList] = useState([])
  const [showUpdateDrawer, setShowUpdateDrawer] = useState(false)

  const dispatch = useDispatch()
  const onChange = async (newDate) => {
    setDate(newDate)
  }

  const apiRequest = async () => {
    let obj = {
      userType: 'branchUser',
      counsellorType: 'Counsellor',
      block: false,
      select: ['name', 'email']
    }
    const resp = await dispatch(listAllUsers(obj))
    setCounsellorList(resp.data)
  }

  const bookedSlots = async () => {
    const resp = await dispatch(getSlotByCounsellorFxn({
      counsellorId: counselorId,
      date: date
    }))
    setSelectedEvents(resp.data)
  }

  useEffect(() => {
    apiRequest()
  }, [])

  useEffect(() => {
    if (counselorId || date) {
      events.refreshPage()
    }
  }, [counselorId, date])


  const dateCellRender = (currentDate) => {
    let findCounselling = _.find(dateSlotList, (item) => {
      return item.stringDate == moment(currentDate).format(dateFormat)
    })

    const isDisabled = findCounselling && findCounselling.disabled ? findCounselling.disabled : false
    const isBooked = findCounselling && findCounselling.booked ? findCounselling.booked : false
    const slotsForSelectedDate = slotList.filter(slot => moment(slot.date).isSame(date, 'day'))

    const allSlotsMatch = slotsForSelectedDate.length === selectedEvents.length && slotsForSelectedDate.every(slot => selectedEvents.some(event => event.slot === slot.slot))
    const allSlotsHaveSlotId = slotsForSelectedDate.length === selectedEvents.length &&
      slotsForSelectedDate.every(slot => slot.slotId)

    let backgroundColor = isDisabled ? 'rgb(230 120 97)' : (allSlotsMatch ? 'rgb(230 120 97)' : '#61d261')

    if (moment(currentDate).format(dateFormat) == moment(date).format(dateFormat)) {
      backgroundColor = '#0f52ba'
    } else if (isBooked) {
      backgroundColor = '#1890ff'
    } else if (isDisabled) {
      backgroundColor = '#d9534f'
    } else if (allSlotsHaveSlotId) {
      backgroundColor = 'rgb(230 120 97)'
    } else {
      backgroundColor = '#5cb85c'
    }

    const color = isDisabled || allSlotsMatch ? 'white' : 'white'
    const cursor = isDisabled ? 'not-allowed' : 'pointer'

    return (
      <div style={{
        backgroundColor: backgroundColor,
        padding: '5px',
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        textAlign: 'center',
        color: color,
        cursor: cursor
      }}>
        {currentDate.date()}
      </div>
    )
  }


  const events = {
    handleAddSlot: async (time) => {
      const resp = await dispatch(addCounsellingSlotFxn({
        counsellorId: counselorId,
        date: date,
        slot: time
      }))
      if (resp && resp.success) {
        events.refreshPage()
      }
    },
    handleRemoveSlot: async (slotId) => {
      let resp = await dispatch(deleteCounsellingSlotFxn({ slotId: slotId }))
      if (resp && resp.success) {
        events.refreshPage()
      }
    },
    openUpdateDrawer: (counsellingId, counsellingDetails) => {
      setShowUpdateDrawer({
        visible: true,
        counsellingId: counsellingId,
        counsellingDetails: counsellingDetails
      })
    },
    closeUpdateDrawer: async () => {
      setShowUpdateDrawer({
        visible: false,
        counsellingId: '',
        counsellingDetails: []
      })
      events.refreshPage()
    },
    slotDateSetup: async () => {
      let obj = {
        counsellorId: counselorId,
        date: date
      }
      let { data } = await dispatch(counsellingSlotListFxn(obj))
      setDateSlotList(data)
    },

    disabledDate: (current) => {
      const dateString = moment(current).format(dateFormat);
      const findCounselling = dateSlotList.find(item => item.stringDate === dateString);
      const isBooked = findCounselling && findCounselling.booked;
      const isPastDate = moment(current).isBefore(moment().startOf('day'));
      return isPastDate && !isBooked;
    },

    refreshPage: () => {
      events.slotDateSetup()
      bookedSlots()
    }
  }


  return (
    <div className='event-calendar-container mt-4'>
      <div className='card mb0'>
        <div className='table-head d-flex align-items-center all-student-search'
             style={{ height: 60 }}>
          <h5>Manage Slots</h5>
        </div>

        <div className='card-body table-responsive'>
          <Row gutter={24}>
            <Col span={8} md={8} xs={24} sm={24}>
              <InputBox title={'Choose Counsellor'}>
                <Select
                  placeholder='Select Counselor'
                  style={{ width: '100%' }}
                  value={counselorId}
                  onChange={(value) => {
                    setCounselorId(value)
                  }}>
                  {counsellorList.map((counsellor) => (
                    <Option key={counsellor._id} value={counsellor._id}>
                      {counsellor.name}
                    </Option>
                  ))}
                </Select>
              </InputBox>
            </Col>
          </Row>
        </div>
      </div>
      {counselorId ? (
        <div className='card bgWhite'>
          <div className='table-head d-flex align-items-center all-student-search'
               style={{ height: 60 }}>
            <h5>Select a Date & Time</h5>
            <div className='search-box-table' style={{ backgroundColor: 'transparent' }}>
            </div>
            <div className='sort-box-table mark-btn'>
              <strong>Date : {moment(date).format(dateFormat)}</strong>
            </div>
          </div>

          <div className='card-body table-responsive'>
            <Row gutter={24} className={'m5'}>
              <Col span={24}>
                <>
                  <div className='calendar-container-manager'>
                    <div className={'row'}>
                      <div className={'col-md-6 col-xs-12 col-sm-12'}>
                        <div className='calendar-wrapper' key={counselorId}>
                          <Calendar
                            onChange={onChange}
                            value={date}
                            className='custom-calendar-1'
                            disabledDate={events.disabledDate}
                            dateRender={dateCellRender}
                          />
                        </div>
                      </div>
                      <div className={'col-md-6 col-xs-12 col-sm-12'}>
                        <div className='event-cards'>
                          {/*<div className='selected-date'>{selectedDate}</div>*/}
                          <div className='event-card-container'>
                            <div className='event-card-container'>
                              {selectedEvents.map((event, index) => {
                                const booked = event.booked || false
                                const isRejectStatus = event.data && event.data.status === 'Reject'
                                const isDisabled = event.disabled || false

                                let color, borderColor
                                if (isRejectStatus && isDisabled) {
                                  color = 'red'
                                  borderColor = 'red'
                                } else if (isRejectStatus) {
                                  color = '#61d261' // Color for "Reject" status without disabled
                                  borderColor = '#61d261'
                                } else if (booked) {
                                  color = '#3a3aef' // Color for booked slots
                                  borderColor = '#3a3aef'
                                } else if (isDisabled) {
                                  color = 'red' // Color for disabled slots
                                  borderColor = 'red'
                                } else {
                                  color = '#61d261' // Default color for available slots
                                  borderColor = '#61d261'
                                }
                                const border = `2px solid ${borderColor}`
                                let title = event.disabled ? 'are available' : 'are not available'
                                let blockAction = date && date < moment().endOf('day')
                                let styleObj = {
                                  cursor: 'pointer',
                                  borderColor: borderColor,
                                  color: color,
                                  border: border,
                                  transition: 'transform 0.3s'
                                }
                                const renderEventCard = booked && event.data.status !== 'Reject' ? (
                                  <div
                                    key={index}
                                    className={`event-card`}
                                    onClick={() => events.openUpdateDrawer(event.data._id, event)}
                                    style={styleObj}>
                                    <h6>{event.slot}</h6>
                                  </div>
                                ) : (
                                  blockAction ? (
                                    <div
                                      key={index}
                                      className={`event-card`}
                                      style={styleObj}>
                                      <h6>{event.slot}</h6>
                                    </div>
                                  ) : (
                                    <Popconfirm
                                      key={index}
                                      title={`Are you sure you ${title} on this slot?`}
                                      onConfirm={() => {
                                        if (event.disabled) {
                                          events.handleRemoveSlot(event.slotId)
                                        } else {
                                          events.handleAddSlot(event.slot)
                                        }
                                      }}
                                      okText='Yes'
                                      cancelText='No'>
                                      <div className={`event-card`} style={styleObj}>
                                        <h6>{event.slot}</h6>
                                      </div>
                                    </Popconfirm>
                                  )
                                )
                                return renderEventCard
                              })}

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </Col>
            </Row>
          </div>
        </div>) : null}

      {showUpdateDrawer.visible ? (
        <CounsellingUpdate
          {...showUpdateDrawer}
          onClose={() => events.closeUpdateDrawer()}
          counsellingId={showUpdateDrawer.counsellingId}
          counsellingDetails={showUpdateDrawer.counsellingDetails}
          disabledDate={events.disabledDate}
          date={date}
          onChange={onChange}
          dateCellRender={dateCellRender}
          dateSlotList={dateSlotList}
        />
      ) : null}
    </div>
  )
}

export default EventCalendar
