import {Select, Drawer, Row, Col} from 'antd'
import React, {useEffect} from 'react';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {
    InputBox, marketingUniversity,
} from '../../../components/_utils/appUtils'
import _ from 'lodash'
import {
    getMarketingUserByUniversityFxn,
    getUser,
    listAllUsers,
    singleUserProfileFxn, updateMarketingInAgentsFxn,
    updateUserFxn
} from '../actions/user';

const {Option} = Select;

const marketingStateInit = {
    countryId: "",
    university: "",
    marketingUserId: "",
};

const MarketingComponent = (props) => {
    let {data, index, marketingEvents, countryList} = props;
    let [uniArr, setUniArr] = useState([])
    let [marketingUserList, setMarketingUserList] = useState([])
    let chooseCountry = () => {
        let findCountryName = _.find(countryList, (item) => {
            return item._id.toString() == data.countryId
        })
        if (findCountryName) {
            let countryName = findCountryName.countryName.toLowerCase();
            const universityLst = marketingUniversity[`${countryName}List`] ?
                marketingUniversity[`${countryName}List`] : marketingUniversity.others
            setUniArr(universityLst)
        }
    }
    let loadMarketingUserData = async () => {
        if (data.countryId && data.university) {
            let {data: respData} = await getMarketingUserByUniversityFxn(data);
            setMarketingUserList(respData)
        } else {
            setMarketingUserList([])
        }
    }
    useEffect(() => {
        chooseCountry()
    }, [data.countryId])

    useEffect(() => {
        loadMarketingUserData()
    }, [data])

    return (
        <>
            <div className="row new-border">
                <div className="col-md-12">
                    <div className={"row"} key={index}>
                        <div className={"col-md-4"}>
                            <InputBox title={"Country List"}>
                                <select
                                    disabled={true}
                                    className={"form-control"}
                                    value={data.countryId}
                                    onChange={({target}) => {
                                        let obj = {
                                            countryId: target.value
                                        }
                                        marketingEvents.updateState({...obj}, index);
                                    }}>
                                    <option value={""}>Choose Country</option>
                                    {countryList && countryList.length && countryList.map((item) => {
                                        return (
                                            <option key={item._id} value={item._id}>
                                                {item.countryName}
                                            </option>
                                        );
                                    })}
                                </select>

                            </InputBox>

                        </div>
                        <div className={"col-md-4"}>
                            <InputBox title={"University List"}>
                                <select
                                    className={"form-control"}
                                    disabled={true}
                                    value={data.university}
                                    onChange={({target}) => {
                                        marketingEvents.updateState({university: target.value}, index);
                                    }}>
                                    <option value={""}>Choose University</option>
                                    {data.countryId && uniArr && uniArr.length ? uniArr.map((item) => {
                                        return (
                                            <>
                                                <option value={item.value}>
                                                    {item.label}
                                                </option>
                                            </>
                                        )
                                    }) : null}
                                </select>
                            </InputBox>
                        </div>

                        <div className='col-md-4'>
                            <InputBox title={"Marketing List"}>
                                <select
                                    className={"form-control"}
                                    value={data.marketingUserId}
                                    onChange={({target}) => {
                                        marketingEvents.updateState({marketingUserId: target.value}, index);
                                    }}>
                                    <option value={""}>Choose Marketing User</option>
                                    {marketingUserList && marketingUserList.length && marketingUserList.map((item) => {
                                        return (
                                            <option key={item._id} value={item._id}>
                                                {item.name}
                                            </option>
                                        );
                                    })}
                                </select>

                            </InputBox>

                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

const UpdateMarketingUserComponent = (props) => {
    let {visible, onclose, agentId, onSubmit, countryList, email} = props;
    const [marketingState, setMarketingState] = useState([marketingStateInit]);
    const dispatch = useDispatch();
    let setDefaultValues = async (data) => {
        let user = localStorage.getItem('user')
            ? JSON.parse(localStorage.getItem('user'))
            : null
        let userData = await dispatch(getUser(user._id))
        let {marketingCountries} = userData;
        let agentMarketing = []
        _.each(marketingCountries, (eachCountry) => {
            _.each(eachCountry.universityList, (item) => {
                if (item.value) {
                    let findDoc = _.find(data.agentMarketing, (item2) => {
                        return item2.university == item.university && eachCountry.countryId._id == item2.countryId
                    })
                    if (!findDoc) {
                        agentMarketing.push({
                            countryId: eachCountry.countryId._id,
                            university: item.university,
                            status: item.status
                        })
                    }
                }
            })
        })
        if (agentMarketing && agentMarketing.length) {
            setMarketingState(agentMarketing)
        }
    }

    useEffect(() => {
        loadSingleUserData()
    }, [])

    let loadSingleUserData = async () => {
        let {data} = await dispatch(singleUserProfileFxn({userId: agentId}))
        setDefaultValues(data)
    }
    const marketingEvents = {
        updateState: (data, index) => {
            let cloneState = _.clone(marketingState);
            cloneState[index] = {...cloneState[index], ...data}
            setMarketingState(cloneState)
        },

        allData: () => {
            setMarketingState([...marketingState, marketingStateInit]);
        },

        addEdu: () => {
            setMarketingState((preState) => {
                return [...preState, marketingStateInit];
            });
        },
        updateAgent: async () => {
            let marketingStateNew = _.reject(marketingState, (item) => {
                return !item.countryId || !item.marketingUserId
            })
            let resp = await dispatch(updateMarketingInAgentsFxn({agentMarketing: marketingStateNew, _id: agentId}));
            if (resp && resp.success) {
                onSubmit()
            }
        }
    };

    return (
        <>
            <Drawer
                title={`Assign Marketing Users -  (${email})`}
                visible={visible}
                placement="right"
                onClose={onclose}
                width={'60%'}>
                <div className={'card unizportal'}>
                    <Row gutter={16}>
                        <Col span={24}>
                            {marketingState.map((data, index) => {
                                return (
                                    <MarketingComponent
                                        data={data}
                                        key={index}
                                        index={index}
                                        marketingState={marketingState}
                                        countryList={countryList}
                                        marketingEvents={marketingEvents}
                                    />
                                )
                            })}
                            {marketingState && marketingState.length ?
                                <a className={'btn btn-success mt20'} onClick={() => {
                                    marketingEvents.updateAgent()
                                }}>
                                    Submit
                                </a> : null}
                        </Col>
                    </Row>
                </div>
            </Drawer>
        </>
    )
}

export default UpdateMarketingUserComponent
