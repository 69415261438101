import React, {useEffect, useState} from 'react'
import {Tooltip, Tag} from 'antd'
import {EligibilityIcon} from '../university/drawers/universityEligibility'
import _ from "lodash"

const getCourseName = (item, length = 150) => {
    let totalLength = item.length
    if (totalLength > length) {
        item = item.substring(0, length) + ' ...'
        return item
    } else {
        return item
    }
}

const SingleCourseBlock = (props) => {
    let {item, callback, showCommission} = props
    let {courseUniversity} = item;
    let [activeIntake, setActiveIntake] = useState([])
    useEffect(() => {
        setIntakeArrFxn()
    }, [item])
    let setIntakeArrFxn = () => {
        if (item && item.courseIntakes && item.courseIntakes.length) {
            let courseIntakes = _.filter(item.courseIntakes, (eachI) => {
                return eachI.status == 'open'
            })
            setActiveIntake(courseIntakes)
        }
    }
    return (
        <div className='col-lg-4 newRow'>
            <div className='box'>
                <Tooltip title={item.courseName}>
        <span className={'nameBox featureCourseName'}>
          {getCourseName(item.courseName, 35)}
        </span>
                </Tooltip>
                <div className={'infoRow'}>

                    <h5>Tuition fee: {' '}

                        <i className={'amt'}>
                            {item.tuitionFee ? <>
                                    {item.universityCountry && item.universityCountry.currency ? item.universityCountry.currency : ''}{item.tuitionFee}
                                </>
                                : 'N/A'
                            }
                        </i>
                    </h5>
                    <h5>Course Duration: {' '}

                        <i className={'amt'}>
                            {item.courseDuration ? <>
                                    {item.courseDuration}
                                </>
                                : 'N/A'
                            }
                        </i>
                    </h5>
                    {/* {showCommission ?
            <div>Commission {' '}
              {item.universityCountry && item.universityCountry.currency ? item.universityCountry.currency : ''}
              <i className={'amt'}>
                {item.commission}
              </i>
            </div> : null}*/}
                </div>
                <div className='w-100'>
                    <div className={'intakeRow'}>
                        {activeIntake && activeIntake.length ?
                            activeIntake.map((item, index) => {
                                return (
                                    item.status == "open" ?
                                        <div key={item._id} className={`singleIntakeBox ${index == 0 && "selected"}`}>
                                            {index == 0 ? <img src='../newImages/check-intake.png'
                                                               className='singleIntakeBox-check'/> : null}
                                            <i>{item.month}, {item.year}</i>
                                        </div> : null
                                )
                            })
                            : null}
                    </div>
                </div>
                <div className='d-flex w-100'>
                    <button className='btn ml-auto' onClick={() => callback()}>
                        <img src={'../dist/icons/plus-white.png'} className={'plus'}/>
                        select student
                    </button>
                </div>

            </div>
        </div>
    )
}


const SingleUniBlock = (props) => {
    let {item, callBack} = props
    return (
        <div className='col-lg-4'>
            <div className='box'>
        <span className={'logo'}>
          {item.logo && item.logo.url ?
              <img src={item.logo.url} alt=''/> :
              <img src='/dist/img/academy.jpg' alt=''/>}
        </span>
                {/*<h5>{item.universityName}</h5>*/}
                <h5>
                    {item.universityCity && item.universityCity.cityName ? item.universityCity.cityName : ''}
                    {item.universityState && item.universityState.stateName ? `(${item.universityState.stateName})` : ''}
                </h5>
                <p>Application fee {' '}
                    {item.applicationFee ?
                        <>{item.universityCountry && item.universityCountry.currency ? item.universityCountry.currency : ''}{item.applicationFee}</>
                        : 'N/A'}
                </p>


                <EligibilityIcon btn={'eligiBtn'}
                                 universityId={item._id}
                                 countryId={item.universityCountry._id}/>
                <div className={'mt10'}>
                    <a onClick={callBack}>View Courses <img src='/dist/img/right-arrow.svg' alt=''/></a>
                </div>

            </div>
        </div>
    )
}

const SearchedCourseBlock = (props) => {
    let {item, callback, showCommission} = props
    let {courseUniversity} = item;
    let [activeIntake, setActiveIntake] = useState([])
    useEffect(() => {
        setIntakeArrFxn()
    }, [item])
    let setIntakeArrFxn = () => {
        if (item && item.courseIntakes && item.courseIntakes.length) {
            let courseIntakes = _.filter(item.courseIntakes, (eachI) => {
                return eachI.status == 'open'
            })
            setActiveIntake(courseIntakes)
        }
    }
    return (
        <div className='col-lg-4'>
            <div className='box'>
        <span className={'logo'}>
          {courseUniversity.logo && courseUniversity.logo.url ?
              <img src={courseUniversity.logo.url} alt=''/> :
              <img src='/dist/img/academy.jpg' alt=''/>}
        </span>
                <p className={'courseNameDotsReplace'}>
                    {item.courseName}
                </p>
                {/*<h5>{courseUniversity.universityName}</h5>*/}
                <h5>
                    {item.universityState && item.universityState.stateName ? `(${item.universityState.stateName})` : ''}
                </h5>
                <h5>Tuition fee {' '}
                    <i className={'amt'}>{item.tuitionFee ?
                        <>
                            {item.universityCountry && item.universityCountry.currency ? item.universityCountry.currency : ''}{item.tuitionFee}
                        </> : 'N/A'
                    }</i>
                </h5>
                <h5>Application fee {' '}
                    <i className={'amt'}>
                        {item.courseUniversity && item.courseUniversity.applicationFee ? <>
                            {item.universityCountry && item.universityCountry.currency ? item.universityCountry.currency : ''}
                            {item.courseUniversity.applicationFee}
                        </> : 'N/A'}
                    </i>
                </h5>
                {/* {showCommission ?
          <h5>Commission {' '}
            {item.universityCountry && item.universityCountry.currency ? item.universityCountry.currency : ''}
            <i className={'amt'}>
              {item.commission ? item.commission : 'N/A'}
            </i>
          </h5> : null}
*/}

                <div className='w-100'>
                    <div className={'intakeRow'}>
                        {activeIntake && activeIntake.length ?
                            activeIntake.map((item, index) => {
                                return (
                                    item.status == "open" ?
                                        <div key={item._id} className={`singleIntakeBox ${index == 0 && "selected"}`}>
                                            {index == 0 ?
                                                <img src='../newImages/check-intake.png'
                                                     className='singleIntakeBox-check'/> : null}
                                            <i>{item.month}, {item.year}</i>
                                        </div> : null
                                )
                            })
                            : null}
                    </div>
                </div>
                <div className='d-flex w-100'>
                    <EligibilityIcon btn={'eligiBtn btn ml-auto'}
                                     universityId={item.courseUniversity._id}
                                     countryId={item.universityCountry._id}/>
                    <button className='btn ml-auto' onClick={() => callback()}>
                        <img src={'../dist/icons/plus-white.png'} className={'plus'}/>
                        select student
                    </button>
                </div>

            </div>
        </div>
    )
}


export {SingleCourseBlock, SingleUniBlock, SearchedCourseBlock}
