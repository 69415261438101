import React, { useState, useEffect, useRef } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Button,
  Card, Icon,
  Popconfirm,
  Tooltip
} from 'antd'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import AllSamples from './sampleContent'

const ListAllSampleDocument = () => {
  const { totalSampleDocument } = useSelector((state) => ({
    totalSampleDocument: state.sampleDocumentReducers.totalSampleDocument
  }))

  return (
    <AllSamples pageType={'direct'} title={`All Sample Documents: ${totalSampleDocument || 0}`}/>
  )
}

export default ListAllSampleDocument
