import React, {useEffect, useState} from "react";
import {Button, Col, Drawer, Form, Icon, notification, Row, Tooltip, Card} from "antd";
import {connect} from "react-redux";
import GetEachFormFields from "../../../components/_utils/appFormUtils";
import {commissionClaimReceivedFxn, singleReceivedCommissionFxn,commissionEditReceivedFxn} from "../actions";
import {useDispatch} from "react-redux";
import moment from "moment";
import lodash from "lodash";
import SingleStudentRow from "./singleStudentRow";
import CommissionListDrawer from "./commissionListDrawer";
import {CustomInput} from "../../../components/_utils/appUtils";
import UniversityInfoComponent from "../Components/universityInfoComponent"

let initState = {
    visible: false,
    selectedCommissions: []
}
let amountInit = {
    receivedAmount: "",
    receivedAmountInInr: "",
}
const EditReceivedDrawerComponent = (props) => {
    let dispatch = useDispatch()
    let {visible, onClose, onSubmit, status, form: {getFieldDecorator, getFieldValue, setFieldsValue}, record} = props;
    let {currency, universityId, countryId} = record
    let commissionId = record._id;
    let [commissionList, setCommissionList] = useState([])
    let [amountState, setAmountState] = useState(amountInit)
    let [receivedCommissionId, setReceivedCommissionId] = useState("")
    let [universityInvoice, setUniversityInvoice] = useState(null)
    let [state, setState] = useState(initState)

    useEffect(() => {
        setTimeout(() => {
            setFieldsValue({claimReceivedDate: record.claimReceivedDate ? moment(record.claimReceivedDate):moment(),
                currencyRate: record.EditStatus ==='EditReceivedCommission' ? record.currencyRate:'',
                details: record.EditStatus ==='EditReceivedCommission' ? record.details:'',
            })

        }, 300)
        loadReceiveCommission()
    }, [])

    useEffect(() => {
        reCalculateAmount()
    }, [commissionList])

    const loadReceiveCommission = async () => {
        let {receivedCommissionId} = record;
        let {success, data} = await dispatch(singleReceivedCommissionFxn({commissionId: receivedCommissionId}))
        if (success) {
            setCommissionList(data.commissionList)
            setReceivedCommissionId(data._id)
            if (data && data.universityInvoice && data.universityInvoice.url) {
                setUniversityInvoice(data.universityInvoice)
            }
        }
    }

    let reCalculateAmount = () => {
        let totalAmount = 0;
        lodash.each(commissionList, (item) => {
            totalAmount = parseFloat(totalAmount) + (item.studentCommission ? parseFloat(item.studentCommission) : parseFloat(item.studentCommission))
        })
        if (record.currency == 'INR') {
            setAmountState({
                ...amountState,
                receivedAmountInInr: parseFloat(totalAmount)
            })
        } else {
            setAmountState({
                ...amountState,
                receivedAmount: parseFloat(totalAmount)
            })
        }
    }

    let inputTypes = {
        fields: [
            {
                key: 'claimReceivedDate',
                label: 'Date',
                placeholder: 'Date',
                type: 'date',
                required: true,
                span: 8
            },
            {
                key: 'receivedAmount',
                label: 'Total Commission',
                placeholder: 'Total Commission',
                type: 'number',
                hidden: currency == 'INR',
                span: 8,
                customField: (
                    <CustomInput
                        title={'Total Commission'}
                        leftContent={record.currency}
                        value={amountState.receivedAmount}
                        disabled={true}
                        onChange={(value) => {
                            setAmountState({
                                ...amountState,
                                receivedAmount: value
                            })
                        }}
                    />
                )
            },
            {
                key: 'currencyRate',
                label: 'Currency Rate',
                placeholder: 'Currency Rate',
                type: 'number',
                hidden: currency == 'INR',
                required: currency !== 'INR',
                span: 8
            },
            {
                key: 'receivedAmountInInr',
                label: 'Amount',
                placeholder: 'Amount',
                type: 'number',
                hidden: currency !== 'INR',
                span: 8,
                customField: (
                    <CustomInput
                        title={'Total Commission'}
                        leftContent={record.currency}
                        value={amountState.receivedAmountInInr}
                        disabled={true}
                        onChange={(value) => {
                            setAmountState({
                                ...amountState,
                                receivedAmountInInr: value
                            })
                        }}
                    />
                )
            },
            {
                key: 'details',
                label: 'Details',
                placeholder: 'Details',
                span: 24
            }
        ]
    }
    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    const handleStatusUpdate = (e) => {
        const {form} = props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let commissions = [];
                let totalCommission = 0
                let studentCommissions = 0
                let findNull = lodash.find(commissionList, (item) => {
                    return !item.studentCommission
                })
                if (findNull) {
                    notification.warning({
                        message: 'Please Enter Student Commission'
                    })
                    return
                }
                lodash.each(commissionList, (item) => {
                    totalCommission = parseFloat(totalCommission) + parseFloat(item.amount)
                    studentCommissions = parseFloat(studentCommissions) + parseFloat(item.studentCommission)
                    commissions.push({commissionId: item._id, studentCommission: item.studentCommission})
                })
                valData.commissions = commissions;
                valData.receivedAmount = amountState.receivedAmount;
                valData.currency = currency;
                if (record.currency == 'INR') {
                    valData.receivedAmountInInr = amountState.receivedAmountInInr;
                } else {
                    let receivedAmountInInr = parseFloat(amountState.receivedAmount) * parseFloat(valData.currencyRate);
                    valData.receivedAmountInInr = receivedAmountInInr;
                }
                if (totalCommission !== studentCommissions && !valData.details) {
                    notification.warning({
                        message: "Your Total Commission & individual Student Commission are mismatch. Please Enter reason in details column to proceed!"
                    })
                    return
                }
                valData.receivedCommissionId = receivedCommissionId

                let resp
                if(record.EditStatus ==='EditReceivedCommission'){
                 resp = await dispatch(commissionEditReceivedFxn(valData))
                }else{
                 resp = await dispatch(commissionClaimReceivedFxn(valData))
                }
                if (resp && resp.success) {
                    onSubmit()
                    onClose()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }


    const events = {
        showVisibleCommission: () => {
            let {agentId} = record;
            let selectedCommissions = []
            lodash.each(commissionList, (item) => {
                selectedCommissions.push(item._id)
            })
            setState({
                ...state,
                visible: true,
                selectedCommissions
            })
        },
        hideVisibleCommission: () => {
            setState({
                ...state,
                visible: false
            })
        },
        chooseCommission: (data) => {
            let commissionListTemp = lodash.clone(commissionList);
            let findCom = lodash.find(commissionListTemp, (item) => {
                return item._id == data._id
            })
            if (findCom) {
                notification.warning({message: "Commission already exists."})
            } else {
                commissionListTemp.push(data)
                setCommissionList(commissionListTemp);
            }
        },
        removeStu: (commissionId) => {
            let commissionListTemp = lodash.clone(commissionList);
            commissionListTemp = lodash.reject(commissionListTemp, (item) => {
                return item._id == commissionId
            })
            setCommissionList(commissionListTemp);
        },
        updateRecord: (data) => {
            let commissionListTemp = lodash.clone(commissionList);
            let findCom = lodash.find(commissionListTemp, (item) => {
                return item._id == data._id
            })
            if (findCom) {
                findCom.studentCommission = data.studentCommissionState ? parseFloat(data.studentCommissionState) : parseFloat(data.studentCommission);
                setCommissionList(commissionListTemp);
            }
        }
    }

    return (
        <>
            <Drawer
                visible={visible}
                onClose={onClose}
                title={`${record.EditStatus ==='EditReceivedCommission'? 'Edit':'Receive'} Commission`}
                width={'80%'}>
                <div className="form-box commissionBox">
                    <div className="card unizportal">
                        <Form onSubmit={handleStatusUpdate}>
                            <Row gutter={16} className={'wrapBox'}>
                                <Col span={6}>
                                    <UniversityInfoComponent universityId={universityId} countryId={countryId}/>
                                </Col>
                                <Col span={18}>
                                    <Card>
                                        {inputTypes.fields.map((item, key) => {
                                            return (
                                                !item.hidden &&
                                                <Col span={item.span} key={key}>
                                                    {item.customField ? item.customField :
                                                        <GetEachFormFields
                                                            item={item}
                                                            getFieldDecorator={getFieldDecorator}
                                                            formItemLayout={formItemLayout}/>}
                                                </Col>
                                            )
                                        })}
                                        <Col span={24} className={'mt10'}>
                                            {universityInvoice && universityInvoice.url ?
                                                <div className={'courseInfo sm contract'}>
                                                    Download :
                                                    <a href={universityInvoice.url}
                                                       target={'_blank'}> University Invoice
                                                    </a>
                                                </div> : null}
                                        </Col>
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <div className={'commissionListBox'}>
                                        {commissionList && commissionList.length ? commissionList.map((item, key) => {
                                            return <>
                                                <SingleStudentRow item={item} key={key} index={key}
                                                                  removeStu={events.removeStu}
                                                                  currency={currency}
                                                                  updateRecord={events.updateRecord}
                                                />
                                            </>
                                        }) : null}

                                    </div>
                                </Col>
                                <Col span={4}>
                                    <Form.Item>
                                        <Button
                                            type="primary" htmlType="submit" className={'btn mt40'}>
                                            {record.EditStatus ==='EditReceivedCommission'? 'Update':'Receive'} Commission
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>

                    </div>
                </div>
                {state.visible ?
                    <CommissionListDrawer
                        record={record}
                        commissionId={commissionId}
                        selectedCommissions={state.selectedCommissions}
                        onClose={events.hideVisibleCommission}
                        onSubmit={events.chooseCommission}
                        status={'Commission Claimed'}
                        visible={state.visible}/>
                    : null}
            </Drawer>
        </>
    )
}

const ClaimReceivedDrawer = Form.create()(EditReceivedDrawerComponent)
const mapStateToProps = ({global, router}) => ({
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClaimReceivedDrawer)
