import React, { useState } from 'react'
import { Button, Form, Input, Modal, notification, Radio, Select } from 'antd'
import { updateCounsellingSlotFxn } from '../actions/counselling'
import { useDispatch } from 'react-redux'
import InputBox from '../../../components/_utils/InputBox'

const { TextArea } = Input
const { Option } = Select

const UpdateCounsellingComponent = (props) => {
  let { visible, onClose, counsellingData, onSubmit } = props
  let dispatch = useDispatch()
  const [status, setStatus] = useState('')
  const [reason, setReason] = useState('')
  const handleStatusChange = (e) => {
    setStatus(e)
  }
  const handleReasonChange = (e) => {
    setReason(e.target.value)
  }

  const handleSubmit = async () => {
    if (!status) {
      notification.warning({ message: 'Choose status' })
      return
    }
    if (!reason) {
      notification.warning({ message: `Enter ${status == 'Done' ? 'Details ' : 'Reason '}.` })
      return
    }
    let resp = await dispatch(updateCounsellingSlotFxn({ counsellingId: counsellingData._id, status, reason }))
    if (resp.success) {
      setStatus('')
      setReason('')
    }
    onSubmit()
  }


  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      title={'Update Counselling Status'}
    >
      {counsellingData && (
        <div>
          <InputBox title={'Choose Status'}>
            <Select onChange={handleStatusChange}>
              <Option value={'Done'}>Done</Option>
              <Option value={'Not Done'}>Not Done</Option>
            </Select>
          </InputBox>

          {status ? (<InputBox title={`Choose ${status == 'Done' ? 'Details ' : 'Reason '}`}>
            <TextArea
              placeholder={`Enter ${status == 'Done' ? 'Details ' : 'Reason '}.`}
              autoSize={{ minRows: 4, maxRows: 5 }}
              onChange={handleReasonChange}
              value={reason}
              style={{ borderRadius: '1px' }}
            />
          </InputBox>) : null}

          <button className='btn btn-success roundNew lg' type='submit' onClick={handleSubmit}>Submit</button>
        </div>
      )}
    </Modal>
  )
}
export default UpdateCounsellingComponent
