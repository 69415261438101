import React, { useState, useEffect } from 'react'
import { countryIds, directUniversityList, filterOption } from '../../components/_utils/appUtils'
import { getUniversityWiseReportFxn } from './action'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Select, Tooltip } from 'antd'
import { useDispatch } from 'react-redux'

let { Option } = Select

const UniversityWiseReport = () => {
  let dispatch = useDispatch()
  let [data, setData] = useState([])
  let [pageNo, setPageNo] = useState(1)
  let [dataTotal, setDataTotal] = useState(0)
  let [dataRemainingTotal, setRemainingDataTotal] = useState(0)
  let [showLoadMore, setShowLoadMore] = useState(true)
  let [assignedCountries, setAssignedCountries] = useState([])
  let [countryId, setCountryId] = useState(countryIds.canada)

  useEffect(() => {
    getUniversityWiseReport({ pageNo })
  }, [pageNo, countryId])

  useEffect(() => {
    setRemainingDataTotal(dataTotal - data.length)
    setShowLoadMore(data.length < dataTotal)
  }, [data, dataTotal])

  let getUniversityWiseReport = async (params = {}) => {
    if (countryId) {
      params.countryId = countryId
    }
    params.page = pageNo

    let response = await dispatch(getUniversityWiseReportFxn(params))
    let newData = response.data.data

    if (pageNo > 1) {
      setData(prevData => [...prevData, ...newData])
    } else {
      if (newData.length > 0) {
        setData(newData)
      }
    }
    if (newData.length == response.data.total) {
      setShowLoadMore(false)
    }
    setDataTotal(response.data.total)
    setAssignedCountries(response.data.assignCountryAndUniversity)
  }


  return (
    <div className='row  mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center justify-content-between'>
            <h5>University Wise Report ({dataTotal ? dataTotal : 0})</h5>
            <div className='search-box-table'>
            </div>
            <div className='sort-box-table mark-btn'>
            </div>
            <div className='sort-box-table custom-sort-box-new'>
              <Select name='Search By University'
                      onChange={(e) => {
                        setCountryId(e)
                      }}
                      filterOption={filterOption}
                      className={'antSelect'}
                      placeholder='Country'
                      allowClear={true}
                      showSearch={true}
                      value={countryId || undefined}>
                {assignedCountries ? assignedCountries.map((item, key) => {
                  return (
                    <Option value={item.countryId} key={key}>{item.countryName}</Option>
                  )
                }) : null}
              </Select>
            </div>
          </div>
          <div
            className='card-pane-right card-pane-new listing-section w-100'
            style={{ border: 'none' }}>
            <ul className={'count'}>
              {data.map(item => {
                const waitingToApplyCount = item.records.filter(record => record && record.status && record.status == 'Waiting To Apply')
                const offerRecievedCount = item.records.filter(record => record && record.status && record.status == 'Offer Received')
                const tutionFeesCount = item.records.filter(record => record && record.status && record.status == 'Tuition Fee Paid & TT Received')
                const visaApprovedCount = item.records.filter(record => record && record.status && record.status == 'Visa Approved')
                return (
                  <li className='canadaDirectUniCount canadaDirectUniCount2 universityWiseBlock' key={item.id}>
                    <div className='innerBox'>
                      <div className='uniCountBox'>
                        <LazyLoadImage
                          src={
                            item &&
                            item.universityData &&
                            item.universityData.logo &&
                            item.universityData.logo.url &&
                            item.universityData.logo.url
                          }
                          style={{ height: '40px', width: 'auto' }}
                        />
                      </div>
                      <div className={'squareContainer'}>
                        <Tooltip title={'Waiting To Apply'}>
                          <div className={'squareStyle'}>
                            {waitingToApplyCount.length > 0 && waitingToApplyCount[0].total ? waitingToApplyCount[0].total : 0}
                          </div>
                        </Tooltip>
                        <Tooltip title={'Offer Received'}>
                          <div className={'squareStyle'}>
                            {offerRecievedCount.length > 0 && offerRecievedCount[0].total ? offerRecievedCount[0].total : 0}
                          </div>
                        </Tooltip>
                        <Tooltip title={'Tuition Fee Paid & TT Received'}>
                          <div className={'squareStyle'}>
                            {tutionFeesCount.length > 0 && tutionFeesCount[0].total ? tutionFeesCount[0].total : 0}
                          </div>
                        </Tooltip>
                        <Tooltip title={'Visa Approved'}>
                          <div className={'squareStyle'}>
                            {visaApprovedCount.length > 0 && visaApprovedCount[0].total ? visaApprovedCount[0].total : 0}
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className={'alignCenter p-2'}>
            {
              showLoadMore ?
                <a className={'btn btn-outline-primary sm'}
                   style={{ width: '150px' }}
                   onClick={() => {
                     setPageNo(prevPageNo => prevPageNo + 1)
                   }}>Load {dataRemainingTotal} More...</a>
                : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default UniversityWiseReport

