import React from 'react'
import AllCommission from './commissionInner'
import { Drawer } from 'antd'

const CommissionDrawer = (props) => {
  let { visible, onClose, agentId } = props

  return (
    <Drawer visible={true} onClose={onClose} width={'80%'} title={'Commission'}>
      <AllCommission agentId={agentId}/>
    </Drawer>
  )
}
export default CommissionDrawer
