import React, { useEffect, useState } from 'react'
import { Drawer, Card, Col, Row, Tooltip, Tabs } from 'antd'
import WithdrawalComponent from './withdrawalComponent'
import {
  agentCommissionTransactionForAdminFxn
} from '../../universityCommission/actions'
import { useDispatch } from 'react-redux'
import { currencySymbols, newStringDate } from '../../../components/_utils/appUtils'
import CommStudentForAgent from '../../universityCommission/drawers/commStudentForAgent'
import { countryList } from '../../login/action'
import _ from 'lodash'
import moment from 'moment'

const { TabPane } = Tabs

let agentCommissionInit = {
  visible: false,
  record: {}
}
const ListRecords = (props) => {
  let { countryId, events, agentId } = props
  let [transactionData, setTransactionData] = useState([])
  let dispatch = useDispatch()
  useEffect(() => {
    loadTransactionList()
  }, [countryId])

  const loadTransactionList = async () => {
    let params = {
      sortField: '_id',
      sortOrder: 'descend',
      countryId: countryId,
      agentId: agentId,
      results: 100000
    }
    let { data } = await dispatch(agentCommissionTransactionForAdminFxn({ ...params, regExFilters: [] }))
    setTransactionData(data)
  }

  return (
    <>
      <Row className={'header'}>
        <Col span={4}>Date</Col>
        <Col span={12}>Transaction/Reference</Col>
        <Col span={4}>Debit</Col>
        <Col span={4}>Credit</Col>
      </Row>
      {transactionData && transactionData.length ?
        transactionData.map((item) => {
          let {
            transactionType,
            totalCommission,
            totalApprovedByAdminCommission,
            countryId,
            withdrawalId,
            invoiceNo
          } = item
          return (
            <Row key={item._id}>
              <Col span={4} className={'dateBox'}>
                {newStringDate(item.date)}
              </Col>
              <Col span={12}>
                {item.transactionType === 'Credit' ?
                  <>
                    {invoiceNo}
                  </> :
                  <>
                    {
                      withdrawalId && withdrawalId.account ?
                        <div>
                          Acc no
                          : {withdrawalId.account.accountNo} ({withdrawalId.account.bankName}) <br />
                        </div> : null
                    }
                  </>
                }

                {item.transactionType == 'Credit' ? <>
                  <div>
                    <Tooltip title={'View Student List'}>
                      <a className='btn btn-default btn-xs font12' onClick={() => {
                        events.showAgentCommissionDrawer(item)
                      }}>
                        <img src='../uniBtn/eye.png' alt='' className={'eyeBtn'}
                             style={{ height: 14 }} /> View
                      </a>
                    </Tooltip>
                  </div>
                </> : ''}

                {item.transactionType == 'Debit' ? <>
                  <div className={'font12'}>
                    {
                      withdrawalId && withdrawalId.receipt && withdrawalId.receipt.url ?
                        <>
                          <a href={withdrawalId.receipt.url} target={'_blank'}>
                            Download Receipt</a>

                          {withdrawalId.invoice && withdrawalId.invoice.path ?
                            <>
                              <a href={withdrawalId.invoice.path} target={'_blank'}
                                 className={'ml20'}>Download
                                Invoice</a>
                            </>
                            : null}
                        </> :
                        <>
                          <div className={'font11'}> Withdrawal : <label
                            className={'label label-warning xs1'}>Pending</label></div>
                        </>
                    }
                  </div>
                </> : ''}


              </Col>

              <Col span={4} className={'debit'}>
                {transactionType === 'Debit' ?
                  <span>
                                                    {currencySymbols[countryId]}
                    {totalApprovedByAdminCommission ? totalApprovedByAdminCommission : totalCommission}
                                                </span> : ''}
              </Col>
              <Col span={4} className={'credit'}>
                {transactionType === 'Credit' ?
                  <>
                    {item.bonus ? <>
                      <div className={'font12 text-black-50'}>
                        Comm. : <span>{currencySymbols[countryId]} {item.totalCommission}</span>
                      </div>
                      <div className={'font12 text-black-50'}>
                        Bonus : <span>{currencySymbols[countryId]} {item.totalCommissionBonus}</span>
                      </div>
                    </> : null}
                    <div>

                      <strong className={'font-weight-600'}>
                        {currencySymbols[countryId]}&nbsp;
                        {totalApprovedByAdminCommission ? totalApprovedByAdminCommission : totalCommission}
                      </strong>
                    </div>


                    {/*  <span>
                                                    {currencySymbols[countryId]}
                    {totalApprovedByAdminCommission ? totalApprovedByAdminCommission : totalCommission}
                                                </span>*/}


                  </> : ''}
              </Col>

            </Row>
          )
        }) : null}
    </>
  )
}


const TransactionListForAdmin = (props) => {
  let { visible, onClose, countryId, currentCommissionObj, refreshPage, agentId } = props
  let [selectedCountry, setSelectedCountry] = useState('')
  let [visibleWithdrawal, setVisibleWithdrawal] = useState(false)
  let [activeTab, setActiveTab] = useState('1')
  const [agentCommissionState, setAgentCommissionState] = useState(agentCommissionInit)
  const [countryData, setCountryData] = useState([])
  const [countryIdArr, setCountryIdArr] = useState([])
  const [refreshKey, setRefreshKey] = useState(moment())
  useEffect(() => {
    loadAllCountry()
  }, [countryId])
  const loadAllCountry = async () => {
    let { data } = await (countryList({
      results: 10,
      active: true,
      countryName: ['Canada', 'UK', 'Australia', 'Germany', 'Canada Onshore'],
      select: ['countryName', 'flag'],
      sortField: '_id',
      sortOrder: 'ascend'
    }))
    let defaultCountry = countryId ? countryId : data[0]._id.toString()
    let countryIdArr = []
    _.each(data, (item) => {
      if (item && item._id) {
        countryIdArr.push(item._id.toString())
      }
    })
    setCountryIdArr(countryIdArr)
    let indexVal = countryIdArr.indexOf(defaultCountry)
    setActiveTab((indexVal + 1).toString())
    setCountryData(data)
    setSelectedCountry(defaultCountry)
  }

  const events = {
    showWithdrawal: () => {
      setVisibleWithdrawal(true)
    },
    hideWithdrawal: () => {
      setVisibleWithdrawal(false)
      setRefreshKey(moment())
      refreshPage()
      // tableRef.current.reload()
    },
    showAgentCommissionDrawer: (record) => {
      record.agentCommissionId = record._id
      setAgentCommissionState({
        visible: true,
        record: record
      })
    },
    hideAgentCommissionDrawer: () => {
      setAgentCommissionState({
        visible: false,
        record: {}
      })
    },
    reload: () => {
    },
    chooseTab: (index) => {
      index = index - 1
      let indexVal = countryIdArr[index]
      setSelectedCountry(indexVal)
    }
  }

  return (
    <>
      <Drawer
        title={
          <div onClick={() => {
            events.showWithdrawal()
          }}>
            Transaction list
            {/*<a className='btn btn-default btn-sm ml10'> Withdraw Commission</a>*/}
          </div>
        }
        onClose={() => {
          onClose()
          refreshPage()
        }}
        visible={visible}
        width={'90%'}>
        <div className={'ledgerBox'}>
          <Card bordered={true} key={refreshKey}>
            {countryData && countryData.length ?
              <Tabs defaultActiveKey={activeTab} onTabClick={(item) => {
                events.chooseTab(item)
              }}>
                {countryData.map((item, key) => {
                  let countryName = item.countryName.toLowerCase()
                  if (countryName == 'canada onshore') {
                    countryName = 'onshoreCanada'
                  }
                  return (
                    <TabPane
                      tab={(
                        <div>
                          <img src={item.flag.url} style={{ height: 20 }} />
                          <span style={{ marginLeft: 10 }}>{item.countryName} </span>
                          <b>(Balance
                            : {item.currency}{currentCommissionObj[`${countryName}Commission`] ? currentCommissionObj[`${countryName}Commission`] : 0})</b>
                        </div>
                      )} key={(key + 1).toString()}>
                      <ListRecords countryId={item._id} events={events} agentId={agentId} />
                    </TabPane>
                  )
                })}
              </Tabs>
              : null}
          </Card>
        </div>

        {visibleWithdrawal ?
          <WithdrawalComponent
            visible={visibleWithdrawal}
            countryId={selectedCountry}
            onClose={events.hideWithdrawal}
          /> : null}


        {agentCommissionState.visible ?
          <CommStudentForAgent
            visible={agentCommissionState.visible}
            onClose={events.hideAgentCommissionDrawer}
            onSubmit={events.reload}
            record={agentCommissionState.record}
          /> : null}

      </Drawer>
    </>
  )
}
export default TransactionListForAdmin
