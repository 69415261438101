import {apiUrl} from '../../../settings'

export const addFcmtNotesUrl = () => {
    return apiUrl + '/api/addFcmtNode'
}
export const singleAppFcmtNotesUrl = () => {
    return apiUrl + '/api/singleApplicationFcmtNotes'
}

export const uploadTuitionFeesDocumentUrl = () => {
    return apiUrl + '/api/uploadTuitionFeesDocument'
}

export const updateTuitionFeesDirectUniversityUrl = () => {
    return apiUrl + '/api/updateTuitionFeesDirectUniversity'
}

export const uploadMultiTuitionFeesDocumentUrl = () => {
    return apiUrl + '/api/uploadMultiTuitionFeesDocument'
}
export const verifyTuitionFeesDocumentUrl = () => {
    return apiUrl + '/api/verifyTuitionFeesDocument'
}

export const updateAgentDealCloseUrl = () => {
    return apiUrl + '/updateAgentDealClose'
}

export const closeTuitionFeesDocumentUrl = () => {
    return apiUrl + '/api/closeTuitionFeesDocument'
}
export const disRegardTuitionFeesDocumentUrl = () => {
    return apiUrl + '/api/disregard-tt'
}
