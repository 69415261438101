import React, {useState} from "react"
import {Button, Col, Drawer, Form, InputNumber, notification, Row, Select, Card} from "antd";
import {InputBox, departmentObj, directUniversityList} from "../../../components/_utils/appUtils";
import moment from "moment";
import _ from "lodash"
import {addUserTargetFxn} from "../actions/userTarget";
import {useDispatch} from "react-redux";
import {listAllUsers} from "../../users/actions/user";

let initState = {
    userId: '',
    userType: '',
    universityTarget: []
}
const {Option} = Select

const SingleTargetComponent = (props) => {
    let {item, events, index} = props;
    return (
        <>
            <tr>
                <td>
                    {events.universityNameFxn(item.university)}
                    <div>
                        ({item.countryName})
                    </div>
                </td>
                <td>
                    <InputNumber
                        value={item.target}
                        placeholder={'Enter target'}
                        className={'form-control'}
                        onChange={(value) => {
                            events.updateTarget({target: value}, index)
                        }}/>
                </td>
            </tr>

        </>
    )
}

const AddTarget = (props) => {
    let dispatch = useDispatch()
    let {visible, onSubmit, onClose} = props;
    let [state, setState] = useState(initState)
    let [userList, setUserList] = useState([])

    const events = {
        _updateState: (data) => {
            setState((prevData) => {
                return {
                    ...prevData,
                    ...data
                }
            })
        },
        chooseUser: (userId) => {
            let {userType} = state;
            let universityTarget = []
            let findUser = _.find(userList, (item) => {
                return item._id == userId
            })
            if (findUser) {
                let {marketingCountries} = findUser;
                _.each(marketingCountries, (item) => {
                    _.each(item.universityList, (eachUni) => {
                        universityTarget.push({
                            countryName: item.countryId.countryName,
                            countryId: item.countryId._id,
                            university: eachUni,
                            target: ""
                        })
                    })
                })
                events._updateState({universityTarget, userId})
            }
        },
        chooseUserType: data => {
            let {userType} = data
            events._updateState(data);
            if (userType == 'Arm') {
                userEvents.loadBranchManagers().then((resp) => {
                    setUserList(resp)
                })
            } else if (userType == 'MarketingUser') {
                userEvents.loadMarketingUsers().then((resp) => {
                    setUserList(resp)
                })
            }
        },
        universityNameFxn: (universityId) => {
            if (universityId == 'Public') {
                return "Public Colleges"
            } else {
                let find = _.find(directUniversityList, (item) => {
                    return item.id == universityId
                })
                return find && find.name ? find.name : ""
            }
        },
        updateTarget: (data, index) => {
            let cloneUni = _.clone(state.universityTarget);
            cloneUni[index] = {...cloneUni[index], ...data}
            events._updateState({universityTarget: cloneUni})
        }
    }

    const userEvents = {
        loadBranchManagers: async () => {
            return new Promise(async (resolve) => {
                let obj = {
                    userType: 'branchManager',
                    customQuery: {
                        'agents.0': {$exists: true}
                    },
                    block: false,
                    results: 100,
                    select: ['name', 'email', 'mobile', 'agents', 'countryId']
                }
                let {data} = await dispatch(listAllUsers(obj))
                resolve(data)
            })
        },
        loadMarketingUsers: async () => {
            return new Promise(async (resolve) => {
                let obj = {
                    userType: 'branchUser',
                    department: departmentObj.marketing,
                    results: 100,
                    block: false,
                    select: ['name', 'email', 'mobile', 'agents', 'marketingCountries']
                }
                let {data} = await dispatch(listAllUsers(obj))
                resolve(data)
            })
        }
    }

    const handleSubmit = async e => {
        e.preventDefault()
        if (!state.userType) {
            notification.warning({message: "Please choose user type"})
            return
        }
        if (!state.userId) {
            notification.warning({message: "Please choose user"})
            return
        }
        if (!state.universityTarget || (state.universityTarget && !state.universityTarget.length)) {
            notification.warning({message: "Please update university in marketing user."})
            return
        }
        let findTar = _.find(state.universityTarget, (item) => {
            return !item.target
        })
        if (findTar) {
            notification.warning({message: "Please enter target"})
            return
        }
        console.log(state)
        let resp = await dispatch(addUserTargetFxn(state))
        if (resp && resp.success) {
            events._updateState({
                userId: '',
                target: ''
            })
            onSubmit()
        }
    }

    return (
        <>
            <Drawer title={'Add Target'} onClose={onClose} width={'40%'} visible={visible}>
                <div className="card unizportal">
                    <Form
                        className={'horizontal-form'}
                        autoComplete="off"
                        onSubmit={handleSubmit}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item>
                                    <InputBox title={'User Type'}>
                                        <Select
                                            onChange={target => {
                                                events.chooseUserType({userType: target})
                                            }}
                                            placeholder=" Select User Type"
                                            name="userType">
                                            <Option value="Arm">Arm (Agent Relationship Manager)</Option>
                                            <Option value="MarketingUser">Marketing User</Option>
                                        </Select>
                                    </InputBox>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <InputBox title={`Choose User`}>
                                        <Select
                                            name="userId"
                                            allowClear={true}
                                            showSearch={true}
                                            placeholder={'Choose User'}
                                            onChange={item => {
                                                events.chooseUser(item)
                                            }}
                                            value={state.userId || undefined}>
                                            <option value={""}>Choose User</option>
                                            {userList && userList.length
                                                ? userList.map((item, key) => {
                                                    return (
                                                        <Option value={item._id} key={key}>
                                                            {item.name}
                                                        </Option>
                                                    )
                                                }) : null}
                                        </Select>
                                    </InputBox>
                                </Form.Item>
                            </Col>
                            {state.universityTarget && state.universityTarget.length ?
                                <Col span={24} className={'mt20'}>
                                    <Card bordered={true} size={'small'}>
                                        <table className={'table table-bordered'}>
                                            <tr>
                                                <th>University</th>
                                                <th width={'50%'}>Target</th>
                                            </tr>
                                            {state.universityTarget.map((item, index) => {
                                                return (
                                                    <SingleTargetComponent
                                                        item={item} index={index} events={events}
                                                        key={index}/>
                                                )
                                            })}
                                        </table>
                                    </Card>
                                </Col> : null}
                        </Row>

                        <Row gutter={16}>
                            <Col span={3}>
                                <Button type="primary" htmlType="submit" className={'btn'}
                                        style={{position: "relative", top: 18}}>
                                    Save
                                </Button>
                            </Col>
                        </Row>

                    </Form>
                </div>
            </Drawer>
        </>
    )
}
export default AddTarget
