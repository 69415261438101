import React, {useState, useEffect, useRef} from 'react';
import {
    Button,
    Col, DatePicker,
    Row, Tooltip, notification,
    Modal, Input
} from 'antd';
import _ from 'lodash';
import {useDispatch} from 'react-redux';
import {TableComp} from 'sz-react-utils-lite';
import {useHistory} from 'react-router-dom';
import {
    adminRightUser,
    dateFilterRange,
    fixed2Digit,
    InputBox,
    newFormatDisplayDate
} from "../../../components/_utils/appUtils";
import moment from "moment";
import {
    allTransactionFxn,
    currentForexStatusFxn,
    sendOtpFxn,
    verifyOtpAndDeleteFxn,
    transferAssignUserFxn,
    readTransactionFxn,
    agentTransactionsFxn
} from "../actions";
import AddBasicDetails from './agentDocs'
import {ColumnWidth} from "../../WebComponent/columnWidth";
import MobileEmailInfo from "../../../components/commonComponents/mobileEmailInfo";
import AgentTransactionModal from "./transactionModal";
import TransferAgentModal from "./transferAgentModal";
import UpdateTransactionStatus from "./transactionStatus";
import AssignReeudoManager from "./assignReeudoManager";
import ReeudoAgentAutoComplete from "../component/reeudoAgentAutoComplete";
const {TextArea} = Input
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const rupeeSymbol = "₹"

const initFilters = {
    startDate: null,
    endDate: null,
    rappId: '',
    studentName: '',
    agent: '',
    agentId: '',
    status: ''
};
let reeudoMaster = ['admin', 'reeudoManager', 'itTeam']
const TxnList = (props) => {
    let {user} = props;
    const [total, setTotal] = useState(0);
    const [filters, setFilters] = useState(initFilters);
    const [isModalVisible, setModalVisible] = useState({
        visible: false,
        appId: '',
        id: ''
    });
    const [isAgentModal, setIsAgentModal] = useState({
        visible: false,
    });
    const [isTransferModalVisible, setTransferModalVisible] = useState({
        visible: false,
        transactionId: ''
    });
    const [isForexOff, setIsForexOff] = useState(false);
    const [loading, setLoading] = useState(true)
    const [isDeleteModalVisible, setDeleteModalVisible] = useState({
        visible: false,
        transactionId: '',
        reason: ''
    });
    const [isOtpModalVisible, setOtpModalVisible] = useState({
        visible: false,
        transactionId: '',
        otp: ''
    });
    const [isStatusDrawerVisible, setStatusDrawerVisible] = useState({
        visible: false,
        transaction: {},
    });
    const [isAssignModalVisible, setAssignModalVisible] = useState({
        visible: false,
        transactionId: ''
    });
    const tableRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();

    const currentForexStatus = async () => {
        try {
            setLoading(true);
            const resp = await dispatch(currentForexStatusFxn());
            if (resp.status === 'off') {
                setIsForexOff(true);
            } else {
                setIsForexOff(false);
            }
        } catch (error) {

        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        currentForexStatus();
    }, []);
    const showModal = (appId, id) => {
        setModalVisible({
            visible: true,
            appId: appId,
            id: id
        });
    };

    const closeModal = () => {
        setModalVisible({
            visible: false,
            appId: '',
            id: ''
        });
    };

    const showStatusDrawer = (transaction) => {
        setStatusDrawerVisible({
            visible: true,
            transaction: transaction,
        });
    };

    const closeStatusDrawer = () => {
        setStatusDrawerVisible({
            visible: false,
            transaction: {}
        });
    };


    const showTransferModal = (transactionId) => {
        setTransferModalVisible({
            visible: true,
            transactionId: transactionId
        });
    };

    const closeTransferModal = () => {
        setTransferModalVisible({
            visible: false,
            transactionId: ''
        });
        tableRef.current.reload()
    };


    const showAssignUserModal = (transactionId) => {
        setAssignModalVisible({
            visible: true,
            transactionId: transactionId
        });
    };

    const closeAssignUserModal = () => {
        setAssignModalVisible({
            visible: false,
            transactionId: ''
        });
        tableRef.current.reload()
    };
    const showAgentModal = () => {
        setIsAgentModal({
            visible: true,
        });
    };

    const closeAgentModal = () => {
        setIsAgentModal({
            visible: false,
        });
    };
    const apiRequest = async (params) => {
        params.sortField = '_id';
        params.sortOrder = 'descend';
        if (filters.startDate) params.startDate = filters.startDate;
        if (filters.endDate) params.endDate = filters.endDate;
        if (filters.rappId) params.rappId = filters.rappId.trim();
        if (filters.status) params.status = filters.status.trim();
        if (filters.studentName) params.name = filters.studentName.trim();
        if (filters.agent) params.agent = filters.agent.trim();
        if (filters.agentId) params.agentId = filters.agentId;
        const transactionFxn = user.userType === "agent" ? agentTransactionsFxn : allTransactionFxn;
        const resp = await dispatch(transactionFxn({...params, regExFilters: ['name', 'rappId', 'status']}));
        setTotal(resp.total);
        return resp;
    };


    const updateFilter = (data) => {
        setFilters((prevState) => ({
            ...prevState,
            ...data
        }));
    };

    const events = {
        searchFxn: () => {
            tableRef.current.reload();
        },
        clearFxn: () => {
            setFilters(initFilters)
            setTimeout(() => {
                tableRef.current.reload();
            }, 500)
        }
    }


    const filterComponent = (
        <Row gutter={12} className="filter_box mt5">
            <Col span={6}>
                <div className="filter-box">
                    <RangePicker
                        format={dateFormat}
                        value={[
                            filters.startDate ? moment(filters.startDate, dateFormat) : null,
                            filters.endDate ? moment(filters.endDate, dateFormat) : null
                        ]}
                        onChange={(dates) => {
                            updateFilter({
                                startDate: dates && dates[0] ? dates[0].format(dateFormat) : null,
                                endDate: dates && dates[1] ? dates[1].format(dateFormat) : null,
                            });
                        }}
                        ranges={dateFilterRange}
                    />
                </div>
            </Col>
            <Col md={4} sm={4} xs={8} lg={4}>
                <div className="search-box-table round ml0">
                    <input
                        className="form-control form-control"
                        type="search"
                        placeholder="Search by RappId"
                        value={filters.rappId}
                        onChange={e => {
                            updateFilter({rappId: e.target.value});
                        }}
                    />
                    <img src="/dist/img/search.png" alt=""/>
                </div>
            </Col>
            <Col md={4} sm={4} xs={8} lg={4}>
                <div className="search-box-table round ml0">
                    <input
                        className="form-control form-control"
                        type="search"
                        placeholder="Search by Student"
                        value={filters.studentName}
                        onChange={e => {
                            updateFilter({studentName: e.target.value});
                        }}
                    />
                    <img src="/dist/img/search.png" alt=""/>
                </div>
            </Col>
            {[...adminRightUser, 'reeudoManager'].includes(user.userType) ?
                <Col md={4} sm={4} xs={8} lg={4}>
                    <ReeudoAgentAutoComplete
                        key={filters.agentId}
                        agentId={filters.agentId}
                        customStyle={''}
                        dispatch={dispatch}
                        onSelect={value => {
                            updateFilter({agentId: value});
                        }}
                    />
                </Col> : null}
            <Col md={4} sm={4} xs={8} lg={4}>
                <div className="search-box-table round ml0">
                    <input
                        className="form-control form-control"
                        type="search"
                        placeholder="Search by Status"
                        value={filters.status}
                        onChange={e => {
                            updateFilter({status: e.target.value});
                        }}
                    />
                    <img src="/dist/img/search.png" alt=""/>
                </div>
            </Col>
            <Col span={24}>
                <div className={'btn_group'}>
                    <a className={'default_btn'} onClick={events.searchFxn}>Search</a>
                    <a className={'default_btn'} onClick={events.clearFxn}>Clear</a>
                </div>
            </Col>
        </Row>

    )

    const getColumns = () => {
        let newCol = _.filter(columns, (item) => {
            return !item.hidden;
        });
        return newCol;
    };


    const handleEdit = (appId) => {
        history.push(`/editTxnStudent?appId=${appId}`);
    };


    const showDeleteModal = (transactionId) => {
        setDeleteModalVisible({
            visible: true,
            transactionId,
            reason: ''
        });
    };

    const closeDeleteModal = () => {
        setDeleteModalVisible({
            visible: false,
            transactionId: '',
            reason: ''
        });
    };

    const handleAssignUser = async (transactionId) => {
        let resp = await dispatch(transferAssignUserFxn(transactionId))
        if (resp.success) {
            notification.success({message: resp.message})
            tableRef.current.reload()
        } else {
            notification.error({message: resp.message})
        }
    }
    const handleDeleteSubmit = async () => {
        const {transactionId, reason} = isDeleteModalVisible;

        if (reason.trim()) {
            try {

                const otpResp = await dispatch(sendOtpFxn({transactionId, reason}));
                if (otpResp.success) {
                    notification.success({message: otpResp.message});

                    setOtpModalVisible({
                        visible: true,
                        transactionId: transactionId,
                        otp: ''
                    });
                    closeDeleteModal();
                } else {
                    notification.error({message: otpResp.message});
                }
            } catch (error) {
                notification.error({message: 'Error sending OTP'});
            }
        } else {
            notification.error({message: 'Please provide a reason for deletion'});
        }
    };

    const handleOtpSubmit = async () => {
        const {transactionId, otp} = isOtpModalVisible;

        if (otp.trim()) {
            try {
                const verifyResp = await dispatch(verifyOtpAndDeleteFxn({transactionId, otp}));
                if (verifyResp.success) {
                    notification.success({message: verifyResp.message});
                    tableRef.current.reload();
                    closeOtpModal();
                } else {
                    notification.error({message: verifyResp.message});
                }
            } catch (error) {
                notification.error({message: 'Error verifying OTP'});
            }
        } else {
            notification.error({message: 'Please enter the OTP'});
        }
    };


    const closeOtpModal = () => {
        setOtpModalVisible({
            visible: false,
            transactionId: '',
            otp: ''
        });
    };

    const handleReasonChange = (e) => {
        setDeleteModalVisible((prevState) => ({
            ...prevState,
            reason: e.target.value
        }));
    };

    const updateReadStatus = async (id, isReaded) => {
        if (isReaded) {
            // console.log('Already read, no need to update.');
            return;
        }
        const resp = await dispatch(readTransactionFxn({id: id}));
    };
    const ViewTxnFxn = (record) => {
        return (
            <div style={{paddingLeft: 10}}>
                <a className={'btn roundNew xs btn-default mt5'}
                   onClick={() => {
                       handleEdit(record.rappId)
                   }}>
                    View
                </a>
            </div>
        )
    }
    const columns = [
        {
            title: 'Sr No.',
            dataIndex: 'serialNo',
            width: 80,
            render: (val, record, index) => <div>{index + 1}</div>
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 100,
            render: (item) => <div>{newFormatDisplayDate(item)}</div>
        },
        {
            title: 'Txn Id',
            dataIndex: 'rappId',
            key: 'rappId',
            render: (text, record) => (
                <div>


                    <div>
                        {user && user.userType == "reeudoManager" ?
                            <>
                                <div style={{fontWeight: record.isReaded ? 'normal' : 'bold'}} className={'jcic'}>
                                    {record.isReaded ? null : <div className={'roundDot mr5'}></div>}
                                    {text}
                                </div>


                                {!record.isReaded ? (
                                    <a className={'btn roundNew xs btn-primary mt5 ml10'}
                                       onClick={() => {
                                           updateReadStatus(record._id, record.isReaded);
                                           handleEdit(record.rappId)
                                       }}>
                                        Read
                                    </a>
                                ) : ViewTxnFxn(record)}
                            </> :
                            <>

                                <div className={'jcic'}>
                                    {text}
                                </div>
                                {user && user.userType == 'reeudoManager' && (
                                    <>
                                        {ViewTxnFxn(record)}

                                    </>
                                )}

                            </>}

                    </div>

                </div>
            ),
            width: 180,
        },
        {
            title: 'Student Name',
            dataIndex: 'name',
            width: 200,
            render: (text, record) => (
                <div>
                    {<div>{text}</div>}
                    {record && record.phoneNumber ? <div><b>Mobile-</b>{record.phoneNumber}</div> : ""}
                    {record && record.emailAddress ? <div><b>Email-</b>{record.emailAddress}</div> : ""}
                </div>
            ),
        },

        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     key: 'status',
        // },
        {
            title: 'Agent',
            key: 'name',
            dataIndex: 'agentId',
            width: 210,
            hidden: !reeudoMaster.includes(user.userType),
            render: (item) => {
                return (
                    item ? <ColumnWidth width={150}>
                        {item && item.name ? item.name : ""}<br/>
                        ({item.companyName})
                    </ColumnWidth> : null
                )
            }
        },
        {
            title: 'Email / Mobile',
            dataIndex: 'email',
            key: 'email',
            width: 210,
            hidden: !reeudoMaster.includes(user.userType),
            render: (item, record) => {
                let {agentId} = record;
                return (
                    agentId ? <div>
                        <MobileEmailInfo data={agentId} type={'email'}
                                         value={agentId && agentId.email ? agentId.email : ""}/>
                    </div> : null
                )
            }
        },
        // {
        //      title: 'Mobile No',
        //      dataIndex: 'mobile',
        //
        //      key: 'mobile',
        //      hidden: !reeudoMaster.includes(user.userType),
        //      render: (item, record) => {
        //          let {agentId} = record;
        //          return (
        //              agentId ? <div>
        //                  <MobileEmailInfo data={agentId} type={'email'}
        //                                   value={agentId && agentId.mobile ? agentId.mobile : ""}/>
        //              < /div> : null
        //          )
        //      }
        //  },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            width: 120
        },
        {
            title: 'Your Rate',
            dataIndex: 'yourRate',
            width: 100,
            render: (text, record) => <span>{rupeeSymbol}{(parseFloat(record.exchangeMargin) + text).toFixed(2)}</span>,
        },

        {
            title: 'Booking Rate',
            dataIndex: 'fxRate',
            width: 100,
            render: (text) => <span>{rupeeSymbol} {text}</span>,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 100,

        },

        {
            title: 'Assigned User',
            key: 'assignedUserId',
            dataIndex: 'assignedUserId',
            width: 220,
            render: (item) => {
                return (
                    <div>
                        {item && item.name ? <div>{item.name}</div> : ""}
                        {item && item.mobile ? <div><b>Mobile-</b>{item.mobile}</div> : ""}
                        {item && item.email ? <div><b>Email-</b>{item.email}</div> : ""}
                    </div>
                );
            }
        },
        {
            title: 'Uniz Profit',
            dataIndex: 'unizProfit',
            key: 'unizProfit',
            width: 100,
            hidden: user && (user.userType == 'agent' || user.userType == 'subAgent'),

        },
        // {
        //     title: 'Nostro Profit',
        //     dataIndex: 'nostroProfit',
        //     key: 'nostroProfit',
        // },
        {
            title: 'Agent Profit',
            dataIndex: 'agentProfit',
            width: 100,
            key: 'agentProfit',
            render: (text, record) => {
                const agentBalance = record.agentBalance;
                const profitDifference = agentBalance - text;

                let balanceLabelStyle = 'label label-success';
                let balanceArrow = null;

                if (profitDifference > 0) {
                    balanceLabelStyle = 'label label-success-green';
                    balanceArrow = '↑';
                } else if (profitDifference < 0) {
                    balanceLabelStyle = 'label label-danger';
                    balanceArrow = '↓';
                }

                return (
                    <div>
                        <p>{text}</p>
                        {record && record.status == "Approved" ? <div>
                            <label className={balanceLabelStyle}>{fixed2Digit(agentBalance)} {balanceArrow}</label>
                        </div> : null}
                    </div>
                );
            }
        },

        {
            title: 'Total Profit',
            dataIndex: 'totalProfit',
            key: 'totalProfit',
            width: 100,
            hidden: user && (user.userType === 'agent' || user.userType === 'subAgent'),
            render: (text, record) => {
                const totalInrAmount = record.totalInrAmount;
                const profitDifference = totalInrAmount - text;

                let totalProfitLabelStyle = 'label label-success';
                let profitArrow = null;

                if (profitDifference > 0) {
                    totalProfitLabelStyle = 'label label-success-green';
                    profitArrow = '↑';
                } else if (profitDifference < 0) {
                    totalProfitLabelStyle = 'label label-danger';
                    profitArrow = '↓';
                }

                return (
                    <div>
                        <p>{text}</p>
                        {record && record.status == "Approved" ?
                            <label
                                className={totalProfitLabelStyle}>{fixed2Digit(totalInrAmount)} {profitArrow}</label> : null}
                    </div>
                );
            }
        },

        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 170,
            render: (item, record) => {
                let {status} = record
                return (
                    <div>
                        {item == 'Pending' ? (
                            <label className='label label-success sm'>Pending</label>
                        ) : null}
                        {item == 'Approved' ? (
                            <label className='label label-success-green sm'>Approved</label>
                        ) : null}
                        {item == 'Rejected' ? (
                            <label className='label label-danger sm'>Rejected</label>
                        ) : null}

                    </div>
                )
            }
        },
        {
            key: 'actions',
            title: 'Actions',
            fixed: 'right',
            width: 80,
            render: (text, record) => {
                return (
                    record.isHiddenForAgent !== true ? <React.Fragment>
                        <div>
                            {reeudoMaster.includes(user.userType) && (
                                <>
                                    <Tooltip title="Edit">
                                        <Button
                                            shape="circle"
                                            style={{marginRight: 6}}
                                            size="small"
                                            onClick={() => handleEdit(record.rappId)}
                                            icon="edit"
                                        />
                                    </Tooltip>

                                    {!['Approved', 'Rejected'].includes(record.status) ?
                                        <>
                                            <Tooltip title="Transfer Agent">
                                                <Button
                                                    shape="circle"
                                                    size="small"
                                                    onClick={() => showTransferModal(record._id)}
                                                    icon="swap"
                                                    style={{marginRight: 6}}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Assign User">
                                                <Button
                                                    shape="circle"
                                                    size="small"
                                                    onClick={() => showAssignUserModal(record._id)}
                                                    icon="user-add"
                                                    style={{marginRight: 6}}
                                                />
                                            </Tooltip>

                                            <Tooltip title="Change Status">
                                                <Button
                                                    shape="circle"
                                                    size="small"
                                                    onClick={() => showStatusDrawer(record)}
                                                    icon="check"
                                                />
                                            </Tooltip>

                                        </> : null}
                                </>
                            )}


                            {reeudoMaster.includes(user.userType) ? (
                                <Tooltip title="Delete">
                                    <Button
                                        shape="circle"
                                        size="small"
                                        onClick={() => showDeleteModal(record._id)}
                                        icon="delete"
                                        style={{marginRight: 6}}
                                    />
                                </Tooltip>
                            ) : null}


                            <Tooltip title="Update">
                                <Button
                                    shape="circle"
                                    size="small"
                                    onClick={() => showModal(record.rappId, record._id)}
                                    icon="sync"
                                />
                            </Tooltip>


                        </div>
                    </React.Fragment> : null
                );
            }
        }
    ];


    return (
        <>
            <div className='row mt-4'>
                <div className='col-lg-12'>
                    <div className='card'>
                        <div className='table-head d-flex align-items-center all-student-search'>
                            <h5>All Transactions (Total : {total || 0})</h5>


                        </div>
                        <div className='card-body table-responsive'>
                            {filterComponent}

                            {/*     {!isForexOff && !loading && (
                                <TableComp
                                    columns={getColumns()}
                                    apiRequest={apiRequest}
                                    ref={tableRef}
                                    pagination={{
                                        position: 'top',
                                        pageSizeOptions: ['10', '20', '25', '50'],
                                        defaultPageSize: 10
                                    }}
                                />
                            )}*/}

                            <TableComp
                                columns={getColumns()}
                                apiRequest={apiRequest}
                                ref={tableRef}
                                extraProps={{scroll: {x: 600}}}
                                pagination={{
                                    position: 'top',
                                    pageSizeOptions: ['10', '20', '25', '50'],
                                    defaultPageSize: 10
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {
                isModalVisible.visible ? (
                    <AddBasicDetails
                        visible={isModalVisible.visible}
                        onClose={closeModal}
                        appId={isModalVisible.appId}
                        transactionId={isModalVisible.id}
                    />
                ) : null
            }
            {
                isTransferModalVisible.visible ? (
                    <TransferAgentModal
                        visible={isTransferModalVisible.visible}
                        onClose={closeTransferModal}
                        transactionId={isTransferModalVisible.transactionId}
                    />
                ) : null
            }

            {
                isAgentModal.visible ? (
                    <AgentTransactionModal
                        visible={isAgentModal.visible}
                        onClose={closeAgentModal}

                    />
                ) : null
            }
            {isDeleteModalVisible.visible && (
                <Modal
                    title="Delete Transaction ?"
                    visible={isDeleteModalVisible.visible}
                    onCancel={closeDeleteModal}
                    okText={"Delete Now"}
                    onOk={handleDeleteSubmit}>
                    <InputBox title={'Enter Reason'}>
                        <TextArea
                            className={'form-control'}
                            style={{height: 100}}
                            value={isDeleteModalVisible.reason}
                            onChange={handleReasonChange}
                            placeholder="Enter reason for deletion"
                        />
                    </InputBox>
                </Modal>
            )}

            {isOtpModalVisible.visible && (
                <Modal
                    title="Enter OTP"
                    visible={isOtpModalVisible.visible}
                    onCancel={closeOtpModal}
                    onOk={handleOtpSubmit}
                    okText="Verify"
                >
                    <Input
                        type="text"
                        className={'form-control'}
                        value={isOtpModalVisible.otp}
                        onChange={(e) => setOtpModalVisible({...isOtpModalVisible, otp: e.target.value})}
                        placeholder="Enter OTP"
                    />
                </Modal>
            )}
            {
                isStatusDrawerVisible.visible && (
                    <UpdateTransactionStatus
                        visible={isStatusDrawerVisible.visible}
                        onClose={closeStatusDrawer}
                        transaction={isStatusDrawerVisible.transaction}
                        onSubmit={() => {
                            closeStatusDrawer();
                            tableRef.current.reload();
                        }}
                    />
                )
            }

            {
                isAssignModalVisible.visible ? (
                    <AssignReeudoManager
                        visible={isAssignModalVisible.visible}
                        onClose={closeAssignUserModal}
                        transactionId={isAssignModalVisible.transactionId}
                    />
                ) : null
            }
        </>


    );
};

export default TxnList;
