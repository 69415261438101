import React, {useState} from "react"
import {downloadAgentProfileFxn, downloadAgentProfileOtpFxn} from "../../containers/latestUpdate/actions";
import {Button, Modal, notification} from "antd";
import InputBox from "../_utils/InputBox";
import {useDispatch} from "react-redux";

const DownloadProfile = (props) => {
    let {children} = props
    let dispatch = useDispatch()
    let [visibleOtpScreen, setVisibleOtpScreen] = useState(false)
    let [otp, setOtp] = useState("")
    const downloadSelfProfile = async () => {
        if (!otp) {
            notification.warning({message: "Please enter OTP."})
            return
        }
        let resp = await dispatch(downloadAgentProfileFxn({otp}))
        if (resp && resp.data) {
            window.open(resp.data, 'Download')
            setVisibleOtpScreen(false)
        }
    }
    const handleSubmit = async () => {
        let resp = await dispatch(downloadAgentProfileOtpFxn())
        if (resp && resp.success) {
            setVisibleOtpScreen(true)
        }
    }
    return (
        <div>
            <div onClick={() => {
                handleSubmit()
            }}>
                {children}
            </div>

            {visibleOtpScreen ? <Modal
                visible={visibleOtpScreen}
                closable={true}
                onCancel={() => setVisibleOtpScreen(false)}
                title="Enter OTP"
                footer={false}
            >
                <div style={{textAlign: 'center', marginBottom: 10}}>
                    <h6>
                        A text message with a 4-digit verification code has been sent to
                        your email.
                    </h6>
                    <InputBox title={'Enter OTP'}>
                        <input
                            className={'form-control mt10'}
                            type={'password'}
                            minLength={4}
                            maxLength={4}
                            value={otp}
                            onChange={({target}) => {
                                setOtp(target.value)
                            }}
                        />
                    </InputBox>
                    <Button
                        className={'btn btn-success mt10'}
                        onClick={downloadSelfProfile}>
                        Submit OTP
                    </Button>
                </div>
            </Modal> : null}
        </div>
    )
}
export default DownloadProfile
