import React, { useState } from 'react'
import { Button, Form, Input, notification, Row, Col } from 'antd'
import axios from 'axios'
import './style.css'
import { getToken } from '../../request'
import { addLatestUpdateUrl } from './api'
import { useDispatch } from 'react-redux'
import { addLatestUpdateFxn } from './actions'
import moment from 'moment'

const { TextArea } = Input

const initState = {
  title: '',
  content: ''
}

const AddLatestUpdate = () => {
  const [state, setState] = useState(initState)
  const [imageFile, setImageFile] = useState(null)
  const [refreshKey, setRefreshKey] = useState(moment())
  const dispatch = useDispatch()
  const updateState = (data) => {
    setState((prevState) => ({
      ...prevState,
      ...data
    }))
  }

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0])
  }
  const handleSubmit = async () => {
    if (!state.title) {
      notification.warning({ message: 'Enter title' })
      return
    }

    const obj = {
      title: state.title,
      content: state.content
    }

    const formData = new FormData()
    formData.append('obj', JSON.stringify(obj))
    if (imageFile && imageFile.name) {
      formData.append('imageUrl', imageFile)
    }

    let resp = await dispatch(addLatestUpdateFxn(formData))
    if (resp.data) {
      setRefreshKey(moment())
      setState(initState)
      setImageFile(null)
    }
  }


  return (
    <div className='form-box mt-4'>
      <div className='d-flex align-items-center mb-3 heading-form'>
        <h5>Add Latest Update</h5>
      </div>
      <div className='card unizportal'>
        <Form layout='vertical'>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label='Title' required>
                <Input
                  placeholder='Title'
                  value={state.title}
                  onChange={(e) => updateState({ title: e.target.value })}
                  className='form-control'
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label='Content'>
                <TextArea
                  rows={4}
                  placeholder='Content'
                  style={{ minHeight: 120 }}
                  value={state.content}
                  onChange={(e) => updateState({ content: e.target.value })}
                  className='form-control'
                />
              </Form.Item>
            </Col>
            <Col span={8} key={refreshKey}>
              <Form.Item label='Image'>
                <Input
                  type='file'
                  onChange={handleImageChange}
                  className='form-control'
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <button className='btn' type='submit' onClick={handleSubmit}>save
              </button>
            </Col>
          </Row>


        </Form>
      </div>
    </div>
  )
}

export default AddLatestUpdate
