import React, {useEffect, useState} from "react"
import {Button, Drawer, Select, Form, notification, Row, Col} from "antd";
import {InputBox} from "../../../components/_utils/appUtils";
import {useDispatch} from "react-redux";
import {addIcefEventFxn} from "../actions";

const AddEventDrawer = (props) => {
    let dispatch = useDispatch()
    let {visible, onClose, onSubmit} = props;
    let [eventName, setEventName] = useState("")
    const handleSubmit = async () => {
        if (!eventName) {
            notification.warning({message: "Enter Event name."})
            return
        }
        let resp = await dispatch(addIcefEventFxn({eventName}));
        if (resp && resp.success) {
            onSubmit()
        }

    }
    return (
        <>
            <Drawer title={'Add ICEF Event'} visible={visible} width={'45%'} onClose={onClose}>
                <div className="form-box">
                    <div className="card unizportal">
                        <Form>
                            <InputBox title={'Event Name'}>
                                <input className={'form-control'}
                                       placeholder={'Event Name'}
                                       value={eventName}
                                       onChange={({target}) => {
                                           setEventName(target.value)
                                       }}/>
                            </InputBox>


                            <Button type="primary" htmlType="submit" className="btn" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Form>
                    </div>
                </div>
            </Drawer>
        </>
    )
}
export default AddEventDrawer
