import React, { useRef, useState, useEffect } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { listAllTargets } from '../actions'
import { useDispatch, useSelector } from 'react-redux'
import { displayDate } from '../../../components/_utils/appUtils'
import { Link } from 'react-router-dom'
import TargetBanner from '../../dashboard/views/targetBanner'
import moment from 'moment'
import { CheckMyRights } from '../../WebComponent/CheckUserRights'
import { listAllCountries } from '../../countries/actions/countries'
import { Tooltip } from 'antd'
import ViewTargets from './viewTargets'

const initialState = {
    targetObj: '',
    viewTargetDrawer: false
}

const TargetList = () => {
    const tableRef = useRef()
    const [state, setState] = useState(initialState)
    const dispatch = useDispatch()
    let [allCountry, setAllCountry] = useState([])

    useEffect(() => {
        loadCountry()
    }, [])

    const loadCountry = async () => {
        // let filters = {
        //     sortField: 'countryName', sortOrder: 'ascend'
        // }
        let { data } = await dispatch(listAllCountries())
        setAllCountry(data)
        console.log(data);
    }

    const columns = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            width: 80,
            key: 'sno',
            render: (value, item, index) => index + 1
        },
        {
            title: 'Country',
            dataIndex: 'countryId',
            key: 'countryId',
            filters: allCountry.map(x => ({ value: x._id, text: x.countryName })),
            render: (item) => {
                return item.countryName
            }
        },
        {
            title: 'Branch Manager',
            dataIndex: 'branchManagerId',
            key: 'branchManager',
            render: (item) => {
                return item.name
            }
        },
        {
            title: 'Date',
            dataIndex: 'fromDate',
            key: 'fromDate',
            render: (item, record) => {
                return `${moment(record.fromDate).format("D MMM  YY")} - ${moment(record.toDate).format("D MMM  YY")}`
            }
        },

        {
            title: 'Target',
            dataIndex: 'data',
            //   width: 200,
            render: (data) => {
                return (
                    <ul>
                        {data.map((ele) => {
                            return <li>{ele.name} : {ele.target}</li>
                        })}
                    </ul>
                )
            }
        },

        {
            key: 'actions',
            title: 'Actions',
            // width: 70,
            height: 100,
            render: (text, record) => {
                return (
                    <div>
                        <Link className='btn' to={`edit-target/${record._id}`}><img src='/dist/img/edit.svg' alt='edit' />
                        </Link>
                        <Tooltip title={'View Targets'}>
                            <button className='btn' onClick={() => viewTarget(record)}><img src='/dist/img/view.svg' alt='' />
                            </button>
                        </Tooltip>
                    </div>

                )
            }
        }
    ]


    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.sortField = 'name'
            params.sortOrder = 'ascend'
            let resp = await dispatch(listAllTargets({ ...params }))
            console.log('google.com....', resp)
            resolve(resp)
        })
    }

    const viewTarget = (record) => {
        setState({
            ...state,
            viewTargetDrawer: true,
            targetObj: record
        })
    }

    const viewTargetClose = () => {
        setState({
            ...state,
            viewTargetDrawer: false,
            targetObj: {}
        })
    }
    return (
        <>
            <div className='row mt-4'>
                <div className='col-lg-12'>
                    <div className='card'>
                        {/* <TargetBanner /> */}
                        <div className='table-head d-flex justify-content-between all-student-search'>
                            <h5>Targets </h5>
                            <div className='sort-box-table mark-btn text-right'>
                                <CheckMyRights rightList={['admin', 'branchManager']}>
                                    <Link className='btn' to={'add-target'}><img src={'../dist/icons/plus.png'}
                                                                                 className={'plus'}/>Add target</Link>
                                </CheckMyRights>
                            </div>
                        </div>

                        <div className='card-body table-responsive'>
                            <TableComp columns={columns}
                                className={'table table-striped table-valign-middle'}
                                apiRequest={apiRequest}
                                pagination={{
                                    position: 'top',
                                    pageSizeOptions: ['50', '100', '200', '500'],
                                    defaultPageSize: 50,
                                    current: 1
                                }}
                                ref={tableRef}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {state.viewTargetDrawer ? <ViewTargets
                visible={state.viewTargetDrawer}
                onClose={() => viewTargetClose()}
                targetObj={state.targetObj} /> : null}
        </>
    )
}

export default TargetList
