import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from "react-redux";
import { Select, Row, Col, Tag, Popconfirm, notification, Button, Icon} from 'antd';
import { InputBox } from "../../../components/_utils/appUtils";
import CounsellingImg from '../../../assets/counselling1.png'
import {
    counsellorCountryWiseFxn,
    counsellorListFxn,
    addCounsellingSlotFxn,
    counsellingSlotFxn,
    deleteCounsellingSlotFxn,
    getSlotbyDatefxn,
    addStudentCounsellingFxn
} from "../../counselling/actions/counselling";
import Calendar from 'rc-calendar';
import 'rc-calendar/assets/index.css';
import '../../counselling/styles.css';
import _ from "lodash";
import { countryList } from '../../login/action';

const { Option } = Select;
let dateFormat = 'DD/MM/YYYY'
const AddCounselling = () => {
    const [date, setDate] = useState(moment());
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(moment());
    const [countryId, setCountryId] = useState('');
    const [countrySelected, setCountrySelected] = useState(false)
    const [countries, setCountries] = useState([])

    const dispatch = useDispatch()
    const onChange = (newDate) => {
        setDate(newDate);
        setSelectedDate(moment(newDate)); 
    };

    const loadCountry = async () => {
        let { data } = await countryList()
        setCountries(data)
    }

    const fetchSlots = async () => {
        
        let params = {
            date : selectedDate,
            countryId: countryId,
            
        }
        let resp = await dispatch(getSlotbyDatefxn(params));
        setSelectedEvents(resp.data);
    }
   
    useEffect(() => {
        if (countrySelected) {
            fetchSlots()
        }
    }, [countrySelected, selectedDate])

    useEffect(() => {
        loadCountry()
    }, [])

    const handleCountryChange = (value) => {
      
        const selectedCountry = countries.find(country => country.countryName === value);
        setCountryId(selectedCountry._id);
        setCountrySelected(false); 
        setSelectedDate(moment()); 
    };
    const handleEventCardClick = async (index, status, slot) => {
   
            const resp = await dispatch(addStudentCounsellingFxn({
                countryId: countryId,
                date: date,
                slot: slot,
             
            }));
            console.log(resp, "respp0000");
            if (!resp.error) {
                notification.success({
                    message: 'Slot Booked Successfully'
                })
                fetchSlots();
            } else {
                notification.warning({
                    message: 'You have already booked a slot'
                })
                fetchSlots();
            }
        await fetchSlots();
    };

    const ViewAvailableSlots = async () => {
        if(countryId !== '') {
            setCountrySelected(true);

        }
    }

    const disabledDate = (current) => {
        return current && current < moment().startOf('day');
    };
    return (
        <>
        <div className='event-calendar-container mt-4'>
        <div className='card mb0'>
          <div className='table-head d-flex align-items-center all-student-search'
               style={{ height: 60 }}>
            <h5>Book Your Counselling</h5>
          </div>
       
            <Row gutter={24}>
                <Col span={8} className={'mt5'} style={{marginLeft : '20px'}}>
                    <InputBox title={'Select Your Interested Country'} style={{marginLeft : '10px'}}>
                        <Select
                            placeholder="Select Your Interested Country"
                            style={{ width: '100%', marginLeft: '12px' }}
                            onChange={handleCountryChange}
                        >
                            {countries.map((item, key) => {
                                return (
                                    <Option value={item.countryName} key={key}>{item.countryName}</Option>
                                )
                            })}
                        </Select>
                    </InputBox>

                    <Button type="primary" className='btn-saveslot' onClick={() => ViewAvailableSlots()} >
                     Save & View Available Slots    <Icon type="arrow-right" />
                    </Button>
                </Col>
                <Col span={14}>
                <img src={CounsellingImg} alt="Counselling Image" className="counselling-image" /> 
                </Col>
            </Row>
            </div>
            {countrySelected && (
                <div>
                <div className='card bgWhite'>
          <div className='table-head d-flex align-items-center all-student-search'
               style={{ height: 60 }}>
            <h5>Select a Date & Time</h5>
            <div className='search-box-table' style={{ backgroundColor: 'transparent' }}>
            </div>
            <div className='sort-box-table mark-btn'>
              <strong>Date : {moment(date).format(dateFormat)}</strong>
            </div>
          </div>
          <div className='card-body table-responsive'>
          <Row gutter={24} className={'m5'}>
              <Col span={24}>
              <>
              <div className='calendar-container-manager'>
                    <div className={'row'}>
                      <div className={'col-md-6'}>
                        <div className="calendar-wrapper">
                        <Calendar
                            onChange={onChange}
                            value={date}
                            className="custom-calendar-1"
                            disabledDate={disabledDate} 
                        />
                        </div>
                      </div>
                      <div className={'col-md-6'}>
                      <div className="event-cards">
                      <div className="selected-date">{selectedDate.format('dddd, Do MMMM YYYY')}</div>
                      <div className="event-card-container">
                      {selectedEvents.map((event, index) => (
                          <>
                          {event.disabled  ? (
                              <div
                                  key={index}
                                  className={`event-card ${event.status} disabled`}
                                  style={{ border: '2px solid #aca7a7', color: '#aca7a7', cursor: 'not-allowed', border: '2px solid gray' }}
                              >
                                  <h6>{event.slot}</h6>
                              </div>
                          ) : (
                              <Popconfirm
                                  key={index}
                                  title={`Are you sure you want to book this slot?`}
                                  onConfirm={() => handleEventCardClick(index, event.status, event.slot)}
                                  okText="Yes"
                                  cancelText="No"
                              >
                                  <div
                                      className={`event-card ${event.status}`}
                                      style={{ cursor: 'pointer', border: '2px solid #61d261', color: '#61d261',  transition: 'transform 0.3s'   }}
                                  >
                                      <h6>{event.slot}</h6>
                                  </div>
                              </Popconfirm>
                          )}
                          </>
                         
                      ))}
                      
                      
                  </div>
                  
                      </div>
                      </div>
                    </div>
                  </div>
              </>
              </Col>
              </Row>
               </div>
                   
                </div>
                </div>
            )}
            </div>
        </>
    );
};

export default AddCounselling;