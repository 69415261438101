import {apiUrl} from '../../../settings'

export const getStudentCambieCourseUrl = () => {
    return apiUrl + '/getStudentCambieCourse'
}
export const getPendingCambieApplicationsUrl = () => {
    return apiUrl + '/pendingLOACambieApplications'
}
export const getStudentRoyalArtsCourseUrl = () => {
    return apiUrl + '/getStudentRoyalArtsCourse'
}
export const getPendingRoyalArtsApplicationsUrl = () => {
    return apiUrl + '/pendingLOARoyalArtsApplications'
}
export const exportStudentRoyalArtsCourseUrl = () => {
    return apiUrl + '/exportStudentRoyalArtsCourse'
}

export const getCiraAgentsUrl = () => {
    return apiUrl + '/api/get-cira-agents'
}

export const getCiraHighSchoolApplicationListUrl = () => {
    return apiUrl + '/getCiraHighSchoolApplicationList'
}

export const pendingLOARoyalHighSchoolUrl = () => {
    return apiUrl + '/pendingLOARoyalHighSchool'
}

export const allRoyalHighSchoolApplicationUrl = () => {
    return apiUrl + '/allRoyalHighSchoolApplication'
}


export const getCornerstoneAgentsUrl = () => {
    return apiUrl + '/api/get-cornerstone-agents'
}

