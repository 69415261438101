import React, {useState, useEffect, useRef} from 'react'
import {
    Button, Col,
    Icon,
    Popconfirm, Row, Select,
    Tooltip
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { listWithdrawalFxn, reGenerateInvoiceFxn } from '../../withdrawal/actions/withdrawalAction'
import AddCallDrawer from '../drawers/addCallDrawer'
import GenerateInvoice from '../drawers/generateInvoice'
import UploadReceiptDrawer from '../drawers/uploadReceiptDrawer'
import {countryIds, displayDate, filterOption, InputBox} from '../../../components/_utils/appUtils'
import lodash from 'lodash'
import AgentTypeheadComponent from '../../../components/_utils/AgentTypeheadComponent'
import { listAllCountries } from '../../countries/actions/countries'

const { Option } = Select
const initState = {
    withdrawalId: '',
    visible: false,
    status: ''
}
const initReceiptState = {
    withdrawalId: '',
    visible: false
}
const initInvoiceState = {
    withdrawalId: '',
    withdrawalType:'',
    visible: false
}
let initFilter = {
    agentId: undefined,
    status: undefined,
    referralNo: '',
    countryId: undefined,
    invoiceAddress: ''
}

let statusOptions = [
    { name: 'Pending', value: 'Pending' },
    { name: 'Approved', value: 'Approved' },
    { name: 'Cancel', value: 'Cancel' }
]

const ListAllWithdrawal = () => {
    let [total, setTotal] = useState(0)
    let [filterState, setFilterState] = useState(initFilter)
    let [allCountry, setAllCountry] = useState([])

    let [state, setState] = useState(initState)
    let [receiptState, setReceiptState] = useState(initReceiptState)
    let [invoiceState, setInvoiceState] = useState(initInvoiceState)
    const tableRef = useRef()
    const dispatch = useDispatch()

    useEffect(() => {
        loadAllCountry()
    }, [])

    const loadAllCountry = async () => {
        let { data } = await dispatch(listAllCountries({ results: 100 }))
        setAllCountry(data)
    }


    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let filterResp = await events.getParamsForApplicationList()
            params = { ...params, ...filterResp }
            params.sortField = '_id'
            params.sortOrder = 'descend'
            if (!params.countryId) {
                params.countryId = { $ne: countryIds.canadaOnshore }
            }

            let resp = await dispatch(listWithdrawalFxn({
                ...params,
                requestForCall: true
            }))
            setTotal(resp.total)
            resolve(resp)
        })
    }

    const updateState = (data) => {
        setState((prevState) => {
            return {
                ...prevState,
                ...data
            }
        })
    }

    const events = {
        showDrawer: (withdrawalId, status) => {
            updateState({
                withdrawalId,
                visible: true,
                status: status,
            })
        },
        hideDrawer: () => {
            updateState({
                withdrawalId: "",
                visible: false,
                status: ""
            })
            tableRef.current.reload();
        },
        showReceiptDrawer: (withdrawalId) => {
            setReceiptState({
                withdrawalId,
                visible: true
            })
        },
        hideReceiptDrawer: () => {
            setReceiptState({
                withdrawalId: "",
                visible: false
            })
            tableRef.current.reload();
        },
        showInvoiceDrawer: (withdrawalId,withdrawalType) => {
            setInvoiceState({
                withdrawalId,
                withdrawalType,
                visible: true
            })
        },
        hideInvoiceDrawer: () => {
            setInvoiceState({
                withdrawalId: '',
                withdrawalType: '',
                visible: false
            })
            tableRef.current.reload()
        },
        reGenerateInvoiceFunc: async (withdrawalId) => {
            let resp = await dispatch(reGenerateInvoiceFxn({ withdrawalId }))
            if (resp && resp.success) {
                tableRef.current.reload()
            }
        },
        _updateFilter: (data) => {
            setFilterState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        searchFxn: () => {
            setTimeout(() => {
                tableRef.current.reload()
            }, 1000)
        },
        clearFxn: () => {
            events._updateFilter(initFilter)
            setTimeout(() => {
                tableRef.current.reload()
            }, 1000)
        },
        getParamsForApplicationList: () => {
            return new Promise(resolve => {
                let obj = {}
                lodash.each(initFilter, (item, key) => {
                    if (filterState[key]) {
                        obj[key] = filterState[key]
                    }
                })
                resolve(obj)
            })
        }

    }

    const columns = [

        {
            title: 'S.No',
            dataIndex: 'sno',
            width: 50,
            key: 'sno',
            render: (item, record, index) => {
                return <a className={'linkPointer'}>
                    {index + 1}
                </a>
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            width: 100,
            key: 'date',
            render: (item, record) => {
                let {withdrawalType} = record;
                return (
                    <div>
                        {item ? displayDate(item) : ''}
                        <br/>
                        <label className={'label label-primary label-xs mt10 round font11'}>
                            {withdrawalType ? withdrawalType : "Student Commission"}
                        </label>
                    </div>
                )
            }
        },
        {
            title: 'Agent',
            dataIndex: 'agentId',
            width: 150,
            key: 'agentId',
            render: (item, record) => {
                return (
                    <div>
                        {item && item.name ? item.name : ""}
                        {item && item.companyName ? `(${item.companyName})` : ""}
                    </div>
                )
            }
        },
        {
            title: 'Country',
            dataIndex: 'countryId',
            width: 80,
            key: 'countryId',
            render: (item, record) => {
                return (
                    <div>
                        {item && item.countryName ? item.countryName : ""}
                    </div>
                )
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            width: 80,
            key: 'amount',
            render: (item, record) => {
                let {countryId} = record;
                return (
                    <div>
                        {countryId && countryId.currency ? countryId.currency : ""} {item}
                    </div>
                )
            }
        },
        {
            title: 'Details',
            dataIndex: 'account',
            width: 150,
            key: 'account',
            render: (item, record) => {
                return (
                    <div>
                        Acc no : {item.accountNo} ({item.bankName}) <br />
                        IFSC: {item.ifsc} <br />
                        Swift: {item.swiftCode} <br />
                        Address: {item.address}<br />
                        {item.cancelledCheque && item.cancelledCheque.path ? <>
                            <Tooltip title={'Cancelled Cheque'}>
                                <a className={'btn btn-success-green btn-xs'} href={item.cancelledCheque.path}
                                   target={'_blank'}>
                                    Cancelled Cheque
                                </a>
                            </Tooltip>
                        </> : null}
                    </div>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (item, record) => {
                return (
                    <div>
                        {item == 'Approved' ? <label className={'label label-success'}>Approved</label> : null}
                        {item == 'Pending' ? <label className={'label label-default'}>Pending</label> : null}
                        {item == 'Cancel' ? <label className={'label label-danger'}>Cancel</label> : null}
                    </div>
                )
            }
        },
        {
            title: 'Call Verified',
            dataIndex: 'verified',
            key: 'verified',
            width: 100,
            render: (item, record) => {
                let {requestForCall} = record;
                return (
                    <div>
                        {requestForCall ?
                            <>
                                <div>
                                    {item == 'Verified' ?
                                        <label className={'label label-success-green'}>Verified</label> : null}
                                    {item == 'Pending' ?
                                        <label className={'label label-default'}>Pending</label> : null}
                                    {item == 'Not Verified' ?
                                        <label className={'label label-danger'}>Not Verified</label> : null}
                                </div>
                            </>
                            : null}
                    </div>
                )
            }
        },
        {
            title: 'Invoice',
            dataIndex: 'invoice',
            key: 'invoice',
            width: 130,
            render: (item, record) => {
                let {currencyRate, invoice} = record;
                return (
                    <>
                        {item && item.path ?
                            <>
                                {currencyRate ? <div>
                                    Currency Rate : {currencyRate}<br/>
                                </div> : null}
                                <a href={item.path} target={'_blank'}>Download Invoice</a>
                            </>
                            : null}

                        <br/>
                        {invoice && invoice.path ?
                            <>
                                <div className={'mt10'}>
                                    <Popconfirm
                                        title={'Are you sure, you want to Re-Generate Invoice?'}
                                        okText={'Yes'}
                                        cancelText={'No'}
                                        onConfirm={() => {
                                            events.reGenerateInvoiceFunc(record._id);
                                        }}>
                                        <a className={'btn btn-info btn-xs'}>
                                            Re-generate Invoice
                                        </a>
                                    </Popconfirm>
                                </div>

                            </>
                            : null}

                    </>
                )
            }
        },
        {
            title: 'Refer No',
            dataIndex: 'referralNo',
            width: 60,
            key: 'referralNo',
            render: (item, record) => {
                return (
                    <div style={{width: 60}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Invoice Add',
            dataIndex: 'invoiceAddress',
            width: 110,
            key: 'invoiceAddress',
            render: (item, record) => {
                return (
                    <div>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Receipt',
            dataIndex: 'receipt',
            key: 'receipt',
            width: 100,
            render: (item, record) => {
                let {invoice} = record;
                return (
                    <>
                        <div>
                            {item && item.url ?
                                <>
                                    <a href={item.url} target={'_blank'}>Download Receipt</a>
                                </>
                                : null}
                        </div>
                    </>
                )
            }
        },
        {
            key: 'actions',
            title: 'Actions',
            width: 80,
            render: (text, record) => {
                let {verified, status, currencyRate, receipt, invoiceAddress, invoice} = record;
                return (
                    <>
                        {verified == 'Pending' ?
                            <div className={'view-btn-box ml10'}>
                                <Tooltip title="Approve">
                                    <a className={'btn btn-success btn-sm mr10'}
                                       onClick={() => {
                                           events.showDrawer(record._id, 'Approved')
                                       }}>
                                        Add Call
                                    </a>
                                </Tooltip>
                            </div> : null}

                        {(verified == 'Verified' && (!invoice || (invoice && !invoice.path))) ?
                            <div className={'view-btn-box ml10'}>
                                <Tooltip title="Approve">
                                    <a className={'btn btn-success btn-sm mr10'}
                                       onClick={() => {
                                           events.showInvoiceDrawer(record._id,record.withdrawalType)
                                       }}>
                                        Generate Invoice
                                    </a>
                                </Tooltip>
                            </div> : null}

                        {(status == 'Approved' && (invoice && invoice.path) && (!receipt || (receipt && !receipt.url))) ?
                            <div className={'view-btn-box ml10'}>
                                <Tooltip title="Approve">
                                    <a className={'btn btn-success btn-sm mr10'}
                                       onClick={() => {
                                           events.showReceiptDrawer(record._id)
                                       }}>
                                        Upload Receipt
                                    </a>
                                </Tooltip>
                            </div> : null}

                    </>
                )
            }
        }
    ]

    const filterComponent = (
      <Row className={'filter_box'} gutter={16}>
          <Col md={4} sm={4} xs={8} lg={4}>
              <InputBox title={'Search By Country'}>
                  <Select name='Search By Country'
                          filterOption={filterOption}
                          allowClear={true}
                          placeholder={'Country'}
                          showSearch={true}
                          onChange={item => events._updateFilter({ countryId: item })}
                          value={filterState.countryId || undefined}>
                      {allCountry && allCountry.length
                        ? allCountry.map((item, key) => {
                            return (
                              <Option value={item._id} key={key}>
                                  {item.countryName}
                              </Option>
                            )
                        })
                        : null}
                  </Select>
              </InputBox>
          </Col>
          <Col md={4} sm={4} xs={8} lg={4}>
              <InputBox title={'Search By Agent'} labelCls={'labelDoc'}>
                  <AgentTypeheadComponent
                    key={filterState.agentId}
                    agentId={filterState.agentId}
                    dispatch={dispatch}
                    customStyle={''}
                    onSelect={item => {
                        events._updateFilter({ agentId: item })
                    }}
                  />
              </InputBox>
          </Col>

          <Col md={4} sm={4} xs={8} lg={4}>
              <InputBox title={'Search By Status'}>
                  <Select
                    name='Search By Status'
                    filterOption={filterOption}
                    allowClear={true}
                    placeholder={'Status'}
                    onChange={item => events._updateFilter({ status: item })}
                    value={filterState.status || undefined}>
                      {statusOptions && statusOptions.length
                        ? statusOptions.map((item, key) => {
                            return (
                              <Option value={item.value} key={key}>
                                  {item.name}
                              </Option>
                            )
                        })
                        : null}
                  </Select>
              </InputBox>
          </Col>

          <Col md={4} sm={4} xs={8} lg={4}>
              <InputBox title={'Search By Referral No'}>
                  <input type='search'
                         placeholder='Search by Referral No...'
                         value={filterState.referralNo}
                         onChange={e => {
                             events._updateFilter({ referralNo: e.target.value })
                         }}
                  />
              </InputBox>
          </Col>
          <Col span={24}>
              <div className={'btn_group'}>
                  <a className={'default_btn'} onClick={events.searchFxn}>Search</a>
                  <a className={'default_btn'} onClick={events.clearFxn}>Clear</a>
              </div>
          </Col>

      </Row>
    )

    return (
      <div className='row mt-4'>
          <div className='col-lg-12'>
              <div className='card'>
                  <div className='table-head d-flex align-items-center'>
                      <Button onClick={() => events.goBack()}
                              className={'roundGoBackBtn'} icon={'arrow-left'}>
                          Back
                      </Button>
                      <h5>All Withdrawal: {total}</h5>
                      <div className='search-box-table'>
                      </div>
                      <div className='sort-box-table mark-btn'>
                      </div>
                  </div>
                  <div className='card-body table-responsive'>
                      {filterComponent}
                      <br />

                      <TableComp columns={columns}
                                 apiRequest={apiRequest}
                                 ref={tableRef}
                      />
                  </div>
                </div>
            </div>
            {state.visible ?
                <AddCallDrawer
                    visible={state.visible}
                    withdrawalId={state.withdrawalId}
                    onClose={events.hideDrawer}
                />
                : null}

            {invoiceState.visible ?
                <GenerateInvoice
                    visible={invoiceState.visible}
                    withdrawalId={invoiceState.withdrawalId}
                    withdrawalType={invoiceState.withdrawalType}
                    onClose={events.hideInvoiceDrawer}
                />
                : null}
            {receiptState.visible ?
                <UploadReceiptDrawer
                    visible={receiptState.visible}
                    withdrawalId={receiptState.withdrawalId}
                    onClose={events.hideReceiptDrawer}
                />
                : null}
        </div>
    )
}

export default ListAllWithdrawal
