import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'

const logoutComponent = (props) => { // left side menu logout function
  const dispatch = useDispatch()
  useEffect(() => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    dispatch({
      type: 'LEAVE_SOCKET',
      leaveRoom: true,
      userId: user._id
    })
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('chatId')
      localStorage.removeItem('dontAskOtpForIds')
      document.body.className = ''
    setTimeout(() => {
      dispatch({ type: 'LOGOUT' })
    }, 500)


  }, [])

  return (
    null
  )
}


const mapStateToProps = ({ counter, global }) => ({
  count: counter.count
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(logoutComponent)
