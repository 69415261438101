import React, {useState, useEffect, useRef} from 'react'
import {
    Button,
    Card, Col, Icon,
    Popconfirm, Select,
    Tooltip,
    Row, Avatar, Tag, Input, DatePicker
} from 'antd'
import lodash from 'lodash'
import {connect, useDispatch, useSelector} from 'react-redux'
import {notification, Table} from 'antd'
import {getUrlParams, getUrlPushWrapper} from '../../../routes'
import {departmentObj, filterOption, StatusNameObj} from '../../../components/_utils/appUtils'
import {listAllUsers} from '../../users/actions/user'
import {getMeetingsCount, getUserReport} from '../actions'
import ReportDashboard from './reportingDashboard'
import moment from 'moment'
import MeetingListDrawer from './meetingListDrawer'
import MeetingsCountComponent from './meetingsCountComponent'

const {RangePicker} = DatePicker


const {Option} = Select
const ReportingList = (props) => {
    // const paramsData = getUrlParams('reportingList', props.pathname)

    let [countryId, setCountryId] = useState('')
    const [allUniversities, setAllUniversities] = useState([])
    const [selectUser, setSelectUser] = useState('')
    const [userId, setUserId] = useState('')
    const [userList, setUserList] = useState([])
    const [userData, setUserData] = useState({})
    const [userType, setUserType] = useState('')
    const [department, setDepartment] = useState('')
    const [managerType, setManagerType] = useState('')
    const [toDate, setToDate] = useState(moment().endOf('month').startOf('day'))
    const [fromDate, setFromDate] = useState(moment().startOf('month').endOf('day'))
    const [meetingDrawerVisible, setMeetingDrawerVisible] = useState(false)
    const [status, setStatus] = useState('')
    const [meetingData, setMeetingData] = useState({
        pendingCount: 0,
        notAttendedCount: 0,
        attendedCount: 0,
        totalMeetings: 0
    })

    const dispatch = useDispatch()

    useEffect(() => {
        loadUserList()
    }, [])


    const loadUserList = async () => {
        let searchParams = new URLSearchParams(window.location.search)
        let usertype = searchParams.get('userType')
        let department = searchParams.get('department') ? searchParams.get('department') : null
        let managerType = searchParams.get('managerType') ? searchParams.get('managerType') : null
        setUserType(usertype)
        setDepartment(department)
        setManagerType(managerType)
        if (usertype) {
            let obj

            if (!managerType) {
                obj = {
                    branchManagerType: {$exists: false}
                }
            } else {
                obj = {
                    branchManagerType: {$in: managerType}
                }
            }

            let params = {
                results: 1000,
                userType: usertype,
                department: department,
                // branchManagerType: managerType,
                sortField: 'name',
                sortOrder: 'ascend',
                select: ['name', 'userType', 'mobile', 'email'],
                customQuery: obj
                // customQuery: {
                //   $or: [{ countryId: countryId }, { 'department': 'Visa Approved Department' }]
                // }
            }
            let {data} = await dispatch(listAllUsers(params))
            if (data && data.length) {
                setUserList(data)
                getParamsForApplicationList()
            }
        } else {
            setUserList([])
        }
    }

    useEffect(() => {
        reloadData()
    }, [])

    let reloadData = async () => {
        let resp = await getParamsForApplicationList()
        if (resp && resp.userId) {
            setSelectUser(resp.userId)
            loadUserReport(resp.userId)
        }
    }

    const loadUserReport = async (userId = null) => {
        if (!userId) {
            notification.warning({
                message: 'Please choose user'
            })
            return
        }
        let obj = {
            userId: userId
        }
        if (userType) {
            obj.userType = userType
        }
        if (managerType) {
            obj.managerType = managerType
        }
        if (department) {
            obj.department = department
        }
        if (department == departmentObj.marketing) {
            getMeetings(selectUser)
        }
        dispatch(getUrlPushWrapper('reportingList', obj))
        let data = await dispatch(getUserReport({userId: selectUser, toDate, fromDate}))
        setUserData(data)
    }

    const getMeetings = async (userId) => {
        let obj1 = {
            userId: userId,
            fromDate: new Date(fromDate),
            toDate: new Date(toDate)
        }
        let data = await dispatch(getMeetingsCount(obj1))
        let total = 0
        let obj = {}
        if (data) {
            data.map((item) => {
                if (item._id == 'Pending') {
                    obj.pendingCount = item.count
                    total += item.count
                }
                if (item._id == 'notAttended') {
                    obj.notAttendedCount = item.count
                    total += item.count
                }
                if (item._id == 'attended') {
                    obj.attendedCount = item.count
                    total += item.count
                }
            })
        }
        setMeetingData({...obj, totalMeetings: total})
    }


    const getParamsForApplicationList = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)

            let userId = searchParams.get('userId')
            let toDate = searchParams.get('toDate')
            let fromDate = searchParams.get('fromDate')
            let userType = searchParams.get('userType')
            let obj = {}
            if (userId) {
                setSelectUser(userId)
                obj.userId = userId
            }
            if (toDate) {
                obj.toDate = toDate
            }
            if (fromDate) {
                obj.fromDate = fromDate
            }
            if (userType) {
                obj.userType = userType
            }
            resolve(obj)
        })
    }


    return (
        <div className='row  mt-4'>
            <div className='col-lg-12'>
                <div className='card'>
                    <div className='table-head d-flex align-items-center'>
                        <h5>Reporting List</h5>
                    </div>
                    <Row style={{paddingLeft: 10, paddingTop: 10}}>
                        <Col md={6} sm={6} xs={6} lg={6} offset={1}>
                            <RangePicker
                                defaultValue={[fromDate, toDate]}
                                onChange={(val) => {
                                    setFromDate(val[0])
                                    setToDate(val[1])
                                }}
                            />
                        </Col>

                        <Col md={6} sm={6} xs={8} lg={6} offset={1}>
                            <div className='sort-box-table custom-sort-box-new'>
                                <Select name='Select User'
                                        filterOption={filterOption}
                                        className={'antSelect'}
                                        allowClear={true}
                                        placeholder={'Select User'}
                                        showSearch={true}
                                        onChange={(item) => setSelectUser(item)} value={selectUser || undefined}>
                                    {userList && userList.length ? userList.map((item, key) => {
                                        let newName = `${item.name} (${item.email})`
                                        return (
                                            <Option value={item._id} key={key}>{newName}</Option>
                                        )
                                    }) : null}
                                </Select>
                            </div>
                        </Col>

                        <Col span={6} offset={1}>
                            <Button onClick={() => loadUserReport(selectUser)} className={'roundBtn'}>Search</Button>
                            {/*<Button onClick={() => console.log('')} className={'roundBtn'}>*/}
                            {/*  Clear*/}
                            {/*</Button>*/}
                        </Col>
                    </Row>
                    {userType && <ReportDashboard userData={userData}
                                                  userType={userType}
                                                  userId={selectUser}
                                                  department={department}
                                                  toDate={moment(toDate)}
                                                  fromDate={moment(fromDate)}
                                                  userCountryName={'canada'}
                        // events={events}
                    />}


                    {/* {department == departmentObj.marketing && meetingData && meetingData.length ?
            <div className='card-pane-right listing-section w-100' style={{ border: 'none', paddingTop: 0 }}>
              <ul className={'count'}>
                <li className={'pointerCls mb20'}
                    onClick={()=>{
                      setStatus('all')
                      setMeetingDrawerVisible(true)
                    }}>
                  <h4 className={'linkText'}>{totalMeetings ? totalMeetings : 0}</h4>
                  <p style={{ color: 'black', fontSize: 15 }}>Total Meetings</p>
                </li>
                <li className={'pointerCls mb20'}onClick={()=>{
                  setStatus('Pending')
                  setMeetingDrawerVisible(true)
                }}>
                  <h4 className={'linkText'}>{pendingCount ? pendingCount : 0}</h4>
                  <p style={{ color: 'black', fontSize: 15 }}>Meetings Pending</p>
                </li>
                <li className={'pointerCls mb20'} onClick={()=>{
                  setStatus('attended')
                  setMeetingDrawerVisible(true)
                }}>
                  <h4 className={'linkText'}>{attendedCount ? attendedCount : 0}</h4>
                  <p style={{ color: 'black', fontSize: 15 }}>Meetings Attended</p>
                </li>
                <li className={'pointerCls mb20'} onClick={()=>{
                  setStatus('notAttended')
                  setMeetingDrawerVisible(true)
                }}>
                  <h4 className={'linkText'}>{notAttendedCount ? notAttendedCount : 0}</h4>
                  <p style={{ color: 'black', fontSize: 15 }}>Meetings Not Attended</p>
                </li>

              </ul>
            </div>
            : null}*/}

                    {department == departmentObj.marketing && meetingData && meetingData.totalMeetings ?
                        <MeetingsCountComponent
                            userId={selectUser}
                            toDate={moment(toDate)}
                            fromDate={moment(fromDate)}
                            {...props}
                            meetingData={meetingData}/>
                        : null}

                </div>
            </div>
        </div>
    )
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    activeUser: global.activeUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportingList)


