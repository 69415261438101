import React, {useEffect, useState} from 'react'
import {Row, Col, Select} from 'antd'
import {
    StatusNameObj,
    StatusLabel
} from '../../../components/_utils/appUtils'
import {
    franceApplicationStatusSteps,
    netherlandsApplicationStatusSteps,
    switzerlandApplicationStatusSteps,
    germanyApplicationStatusSteps
} from '../../../components/_utils/ApplicationStatusSteps'
import _ from 'lodash'

let {Option} = Select
const EuropeInnerBlock = (props) => {
    let {callback, data, dispatch, europeCountryList} = props
    let [countryId, setCountryId] = useState('')
    let [countryName, setCountryName] = useState('')
    let [statusList, setStatusList] = useState([])
    let [countArr, setCountArr] = useState({})
    useEffect(() => {
        eventsFxn.loadCountry()
    }, [])

    useEffect(() => {
        if (countryName) {
            let cn = countryName.toLowerCase()
            switch (cn) {
                case 'germany':
                    setStatusList(germanyApplicationStatusSteps)
                    break
                case 'france ':
                    setStatusList(franceApplicationStatusSteps)
                    break
                case 'netherlands':
                    setStatusList(netherlandsApplicationStatusSteps)
                    break
                case 'switzerland':
                    setStatusList(switzerlandApplicationStatusSteps)
                    break
            }
            if (data && data[countryName] && data[countryName].countArr && data[countryName].countArr) {
                setCountArr(data[countryName].countArr)
            }
        }
    }, [countryName])

    let eventsFxn = {
        loadCountry: async () => {
            if (europeCountryList && europeCountryList.length) {
                setCountryId(europeCountryList[0]._id)
                setCountryName(europeCountryList[0].countryName)
            }
        },
        chooseCountry: (countryId) => {
            let findCountry = _.find(europeCountryList, (item) => {
                return item._id == countryId
            })
            if (findCountry) {
                setCountryId(findCountry._id)
                setCountryName(findCountry.countryName)
            }
        }
    }

    return (
        <>
            <div className='card-pane-right listing-section w-100' style={{border: 'none', flexDirection: 'column'}}>
                <Row className={'w-100'}>
                    <Col push={20} span={4}>
                        <Select className={'small-select'} value={countryId} onChange={(e) => {
                            eventsFxn.chooseCountry(e)
                        }}>
                            {europeCountryList && europeCountryList.length ? europeCountryList.map((item) => {
                                return (
                                    <Option value={item._id} key={item._id}>{item.countryName}</Option>
                                )
                            }) : null}
                        </Select>
                    </Col>
                </Row>
                <ul className={'count'}>
                    {statusList && statusList.length ? statusList.map((item, key) => {
                        return (
                            item.name !== 'New Student' ?
                                <li onClick={() => callback(countryId, item.name)} className={'pointerCls'} key={key}>
                                    <h5>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h5>
                                    <p>{StatusLabel[item.name] ? StatusLabel[item.name] : StatusNameObj[item.name] ? StatusNameObj[item.name] : item.name}</p>
                                </li> : null
                        )
                    }) : null}
                </ul>
            </div>

        </>
    )
}
export {
    EuropeInnerBlock
}
