import {apiUrl} from '../../settings'

export const addEventUrl = () => {
    return apiUrl + '/addEvent'
}

export const listAllEventsUrl = () => {
    return apiUrl + '/eventList'
}

export const updateEventStatusUrl = () => {
    return apiUrl + '/updateEventStatus'
}
export const updateEventUrl = () => {
    return apiUrl + '/updateEvent'
}

export const updateRegistrationStatusUrl = () => {
    return apiUrl + '/updateEventRegistrationStatus'
}

export const uploadEventGalleryUrl = () => {
    return apiUrl + '/uploadEventGallery'
}
export const removeSingleImageUrl = () => {
    return apiUrl + '/removeSingleImage'
}



