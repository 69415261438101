import React, {useEffect, useState, Suspense,} from 'react'
import {Button, Form, Modal, Empty} from 'antd'
import {connect, useDispatch} from 'react-redux'
import moment from 'moment'
import {push} from 'connected-react-router'
import {
  getEnquiryByUser,
  counsellingHistory,
  updateCounsellorAvailability,
  stopCounsellorWaitingFxn
} from '../../counselling/actions/counselling'
import {getUser} from '../../users/actions/user'

const DocumentBlock = React.lazy(() => import('./documentBlock'))
const NotesBlock = React.lazy(() => import('./notesBlock'))
const InfoBlock = React.lazy(() => import('./infoBlock'))

const initialState = {
  studentData: {},
  applicationId: '',
  momentKey: moment(),
  noteDrawerVisible: false,
  document: {},
  studentKeyMoment: null
}

const StudentProfile = props => {
  const [history, setHistory] = useState([])
  const dispatch = useDispatch()
  let [state, setState] = useState(initialState)
  let [userData, setUserData] = useState({})
  const [formData, setFormData] = useState([])
  useEffect(() => {
    // document.body.className = 'applicationProfile'
  getData()
    getUserHistoryData()
    getUserData()
  }, [])

  let {studentData} = state
  let {applications} = studentData

  const getData = async () => {
    let {data} = await dispatch(getEnquiryByUser())
    if (data) {
      setFormData(data)
    }
  }

  const getUserHistoryData = async () => {
    if (formData[0]) {
      let {data} = await dispatch(counsellingHistory(formData[0])) //get that single student data
      setHistory(data)
    }
  }

  const getUserData = async () => {
    let Data = JSON.parse(localStorage.getItem('user'))
    let resp = await dispatch(getUser(Data._id))
    setUserData(resp)
  }

  const counsellorEvents = {
    changeCounsellorStatus: async value => {
      let result = await dispatch(updateCounsellorAvailability())
      getUserData()
    },
    stopWaiting: async () => {
      let resp = await dispatch(stopCounsellorWaitingFxn())
      refreshList()
    }
  }

  const refreshList = () => {
    getData()
    getUserData()
    getUserHistoryData()
  }
  console.log(formData, "formData")
  return (
      <React.Fragment>
        <div className={'appProfile'} key={state.studentKeyMoment}>
          <div className={'align-self-stretch'}>
            <Suspense fallback={<></>}>
              <InfoBlock
                  formData={formData}
                  userData={userData}
                  refreshList={getData}
                  updateStatus={counsellorEvents.changeCounsellorStatus}
              />
            </Suspense>
            {formData && formData.length ? (
                <div className="student-dashboard">
                  <div className="row m-0">
                    <div className="col-lg-8 p-0">
                      <Suspense fallback={<></>}>
                        {formData && formData.length ? (
                            <NotesBlock
                                studentInfo={formData[0]}
                                userData={userData}
                                refreshList={refreshList}
                            />
                        ) : (
                            <>
                              <Empty/>
                            </>
                        )}
                      </Suspense>
                    </div>
                    <Suspense fallback={<></>}>
                      <div className="col-lg-4 p-0">
                        {history &&
                        history.length &&
                        formData &&
                        formData.length &&
                        formData[0] ? (
                            <DocumentBlock
                                formData={formData[0]}
                                history={history}
                                getUserHistoryData={getUserHistoryData}
                            />
                        ) : (
                            <Empty/>
                        )}
                      </div>
                    </Suspense>
                  </div>
                </div>
            ) : (
                <Empty style={{marginLeft: "35%", marginTop: "15%"}}/>
            )}
          </div>
        </div>

        <Modal
            visible={userData.waiting}
            centered={true}
            okText={'Start Counselling'}
            footer={null}>
          <p>
            Your current status is waiting. Click to Start for Continue
            Counselling
          </p>
          <Button
              type={'primary'}
              onClick={counsellorEvents.stopWaiting}
              style={{marginLeft: '60%', marginTop: '5%'}}>
            Start Counselling
          </Button>
        </Modal>
      </React.Fragment>
  )
}

const mapStateToProps = ({global, router}) => ({
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(StudentProfile)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedComponent)
