import React, { Component, useEffect, useState } from 'react'

class CheckRights extends Component {
  constructor() {
    super()
    this.state = {
      userType: 'subAgent'
    }
  }

  async componentDidMount() {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    this.setState({ userType: user.userType })
  }

  render() {
    return (
      <React.Fragment>
        {this.state.userType == 'admin' ? <React.Fragment>{this.props.children}</React.Fragment> : null}
      </React.Fragment>
    )
  }
}

export default CheckRights


