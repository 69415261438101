import React, { PureComponent } from 'react'
import {
    Form,
    Input,
    Select,
    Button,
    Col,
    Row, notification,
} from 'antd'
import moment from 'moment'
import {hideLoader, hidePageLoad} from '../../../../modules/actions'
import {connect} from 'react-redux'
import {listAllUniversities} from '../../../university/actions/university'
import {listAllCountries} from '../../../countries/actions/countries'
import {
    departmentObj,
    InputBox, CounsellorTypeList
} from '../../../../components/_utils/appUtils'
import GetEachFormFields from '../../../../components/_utils/appFormUtils'
import {addUser, listAllUsers} from '../../actions/user'
import {
    CountryCodeWithFlag,
} from '../../../../components/_utils/countryUtil'


const {TextArea} = Input

@Form.create()
class AddCounsellor extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            countryList: [],
            mainBranchArr: [],
            logo: {},
            uploadKey: moment(),
            countryCode: '',
            counsellingType: '',
            countrySelectionMode: 'default',
            countryArr: [],
        }
  }

  componentDidMount() {
    this.loadCountry()
    this.loadCountryUniversity()
    let { dispatch } = this.props
    dispatch({ type: 'USER_HIDELOADER' })
    dispatch(hideLoader())
    dispatch(hidePageLoad())
  }

  async loadCountry() {
    let { dispatch } = this.props
    let countryFilter = {
        results: 10,
        sortField: 'countryName',
        sortOrder: 'ascend'
    }
      let {data} = await listAllCountries(countryFilter)(dispatch)
      this.setState({
          countryList: data
      })
  }




  async loadCountryUniversity() {
    let { dispatch } = this.props
    let filters = {
      sortField: 'universityName',
      sortOrder: 'ascend',
      results: 1000000
      // universityCountry: '',
    }

    let { data } = await dispatch(listAllUniversities(filters))
    console.log('universities', data)
    this.setState({
      universityList: data
    })
  }

  handleSubmit = e => {
      e.preventDefault()
    const { dispatch, form } = this.props
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
          let {address, logo, countryCode, counsellingType, countryArr} = this.state
          if (!countryCode) {
              notification.error({
                  message: 'Please choose County Code'
              })
              return
          }
          valData.address = address
          valData.userType = 'branchUser'
          valData.department = departmentObj.counselling
          valData.counsellingType = counsellingType
          valData.countryArr = countryArr
          let fd = new FormData()
          fd.append('obj', JSON.stringify(valData))
          if (logo && logo.name) {
              fd.append('logo', logo)
          }
          console.log(valData, "valdAtta")
          let data = await dispatch(addUser(fd))
        if (data && !data.error) {
          form.resetFields()
          this.setState({
            address: '',
            uploadKey: moment(),
            logo: {},
            countryCode: '',
            countryArr: []
          })
        }
      }
    })
  }


  render() {
    const {
        form: {getFieldDecorator, setFieldsValue}
    } = this.props


    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }


    let inputTypes = {
      fields: [
        {
          label: 'Contact Person Name',
          key: 'name',
          required: true
        },
        {
          key: 'countryCode',
          span: 3,
          customField: (
            <div style={{ marginTop: '3px' }}>
              <CountryCodeWithFlag
                countryCode={this.state.countryCode}
                chooseCode={val => {
                  this.setState({
                    countryCode: val
                  })
                }}
              />
            </div>
          )
        },
        {
          label: 'Mobile no',
          key: 'mobile',
          span: 5,
          type: 'number',
          required: true
        },
        {
          label: 'Email',
          key: 'email',
          required: true
        },
        {
          label: 'Password',
          key: 'password',
          required: true
        },
        {
          label: 'Post Name',
          key: 'postName',
          required: true
        },

        // {
        //   label: 'Branch Manger',
        //   key: 'branchMangerId',
        //   required: true,
        //   type: 'select',
        //   valueAccessor: x => x.name,
        //   keyAccessor: x => x._id,
        //   options: this.state.mainBranchArr,
        //   onChange: v => {
        //     setFieldsValue({
        //       branchMangerId: v
        //     })
        //   }
        // },
          {
              label: 'Counsellor Type',
              key: 'counsellorType',
              type: 'select',
              required: true,
              span: 8,
              showSearch: true,
              allowClear: true,
              options: CounsellorTypeList,
              valueAccessor: x => x.counsellorType,
              keyAccessor: x => x._id,
              onChange: async v => {
                  setFieldsValue({
                      counsellorType: v
                  });
                  this.setState({ counsellorType: v });

              }
          },
          {
            label: 'Country',
            key:'countryArr',
            type: 'select',
            mode: 'multiple',
            span: 8,
            required: true,
            showSearch: true,
            allowClear: true,
            options: this.state.countryList, // Pass countryList as options
            valueAccessor: x => x.countryName,
            keyAccessor: x => x._id,
            onChange: async v => {
              setFieldsValue({
                countryArr: v
              })
              this.setState({ countryArr: v }); // Update the state with selected countries
            }
          },

        {
          key: 'logo',
          customField: (
            <InputBox title={'Logo'} className={'logoCls'}>
              <Input
                type={'file'}
                className={'form-control'}
                key={this.state.uploadKey}
                name={'logo'}
                id={'logo'}
                onChange={e => {
                  this.setState({
                    logo:
                      e.target.files && e.target.files.length
                        ? e.target.files[0]
                        : null
                  })
                }}
              />
            </InputBox>
          )
        },
        {
          key: 'address',
          span: 24,
          customField: (
            <InputBox title={'Address'}>
              <TextArea
                rows={4}
                className={'form-control'}
                value={this.state.address}
                onChange={e => {
                  this.setState({ address: e.target.value })
                }}
              />
            </InputBox>
          )
        }
      ]
    }

    return (
      <div className="row">
        <div className="col-lg-12">
          <Form onSubmit={this.handleSubmit}>
            <div className="form-box mt-4">
              <div className="d-flex align-items-center mb-3 heading-form">
                  <h5>Add Counsellor</h5>
              </div>

              <div className="card unizportal">
                <Row gutter={16} className={'rowWrap'}>
                  {inputTypes.fields.map((item, key) => {
                    return (
                      <React.Fragment key={key}>
                        {item.customField
                          ? !item.hidden && (
                              <Col span={item.span ? item.span : 8}>
                                {item.customField}
                              </Col>
                            )
                          : !item.hidden && (
                              <Col
                                span={item.span ? item.span : 8}
                                key={key}
                                style={
                                  item.extraBox1 ? { paddingBottom: 1 } : {}
                                }>
                                <InputBox
                                  title={item.label1 ? item.label1 : ''}
                                  extra={item.extraBox1}>
                                  <GetEachFormFields
                                    item={item}
                                    getFieldDecorator={getFieldDecorator}
                                    formItemLayout={formItemLayout}
                                  />
                                </InputBox>
                              </Col>
                            )}
                      </React.Fragment>
                    )
                  })}
                </Row>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={this.props.loading}
                    className={'btn'}>
                    SAVE
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCounsellor)
