import React, { useState, useEffect } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { Form, Select, Button, Card, notification, Spin, Row, Col } from 'antd'
import _ from 'lodash'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { useDispatch, useSelector } from 'react-redux'
import { listAllCountries } from '../../countries/actions/countries'
import { addState } from '../actions/state'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { FileInput } from '../../../components/_utils/appUtils'
const AddState = (props) => {
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } } = props
  const dispatch = useDispatch()
  const { loader, allCountries } = useSelector(state => ({
    loader: state.stateReducers.loader,
    allCountries: state.countryReducers.allCountries
  }))

  let inputTypes = {
    fields: [
      {
        key: 'country', label: 'Country', type: 'select', span: 8,
        required: true,
        options: allCountries,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.countryName}`,
        onChange: x => {
          props.form.setFieldsValue({
            country: x,
            stateName: undefined
          })
        }
      },
      { key: 'stateName', label: 'State Name', required: true, span: 8 }
    ]
  }

  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
    // labelCol: {
    //     xs: { span: 24 },
    //     sm: { span: 8 },
    //     md: { span: 8 }
    // },
    // wrapperCol: {
    //     xs: { span: 24 },
    //     sm: { span: 16 },
    //     md: { span: 12 }
    // }
  }

  const handleSubmit = e => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let data = await dispatch(addState(valData))
        if (data && !data.error) {
          form.resetFields()
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  useEffect(() => {
    dispatch({ type: 'STATE_HIDELOADER' })
    dispatch(listAllCountries({ results: 1000000, sortField: 'countryName', sortOrder: 'ascend' }))
  }, [])

  return (
    <div className="row">
      <div className="col-lg-12">
        <Form onSubmit={handleSubmit}>
          <div className="form-box mt-4">

            <div className="d-flex align-items-center mb-3 heading-form">
              <h5>Add New State</h5>
            </div>

            <div className="card unizportal">
              <Row gutter={24}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <Col span={8} key={key}>
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout}/>
                    </Col>
                  )
                })}
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="btn">
                  SAVE
                </Button>
              </Form.Item>

            </div>
          </div>

        </Form>
      </div>
    </div>
  )
}
const WrappedState = Form.create()(AddState)
export default WrappedState
