import {
    Form, Icon, Popconfirm, Tooltip
} from 'antd'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { displayDate } from '../../components/_utils/appUtils'
import { travelPlanListFxn, userTravelPlanListFxn, yourTravelPlanFxn } from './actions'
import { push } from 'connected-react-router'


const TravelPlan = () => {
    const [total, setTotal] = useState(0)
    let tableRef = useRef()
    const dispatch = useDispatch()
    let user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 150,
            render: item => {
                return item ? displayDate(item) : ''
            }
            /* sorter: (a, b) => new Date(b.date) - new Date(a.date),
             defaultSortOrder: 'descend'*/
        },
        {
            title: 'From State',
            dataIndex: 'state',
            searchTextName: 'state',
            key: 'state'
        },
        {
            title: 'From City',
            dataIndex: 'city',
            searchTextName: 'city',
            key: 'city'
        },
        {
            title: 'From Place',
            dataIndex: 'fromPlace',
            searchTextName: 'fromPlace',
            key: 'fromPlace'
        },

        {
            title: 'To State',
            dataIndex: 'toState',
            searchTextName: 'toState',
            key: 'toState'
        },
        {
            title: 'To City',
            dataIndex: 'toCity',
            searchTextName: 'toCity',
            key: 'toCity'
        },
        {
            title: 'To Place',
            dataIndex: 'toPlace',
            searchTextName: 'toPlace',
            key: 'toPlace'
        },
    ]

    const apiRequest = params => {
        return new Promise(async resolve => {
            let resp = await dispatch(yourTravelPlanFxn({ ...params, userId: user._id }))
            setTotal(resp.total)
            resolve(resp)
        })
    }


    return (
      <>
          <div className='row mt-4'>
              <div className='col-lg-12'>
                  <div className='card'>
                      <div className='table-head d-flex align-items-center'>
                          <h5>Your Travel Plans ({total})</h5>
                          <div className='search-box-table round' />
                          <div className='sort-box-table mark-btn'>
                              <a className='btn' onClick={() => dispatch(push('/travelPlan/add-travel-plan'))}>
                                  <img src={'../dist/icons/plus.png'} className={'plus'} /> Add Travel Plan</a>
                          </div>
                      </div>
                      <div className='card-body table-responsive'>
                          <TableComp
                            columns={columns}
                            apiRequest={apiRequest}
                            ref={tableRef}
                            defaultSortOrder='descend'
                          />
                      </div>
                  </div>
              </div>
          </div>
      </>
    )
}

const WrappedTravelPlan = Form.create()(TravelPlan)
export default WrappedTravelPlan
