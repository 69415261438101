import React from 'react'
import PrivateCollegePendingTTApplicationList from '../privateCollege/privateCollegePendingTTApplicationList'
import { CornerStoneCollege } from '../../../components/_utils/appUtils'

const CornerStonePendingTTApplicationList = () => {
    return (
      <PrivateCollegePendingTTApplicationList
        appName={'Cornerstone College'}
        universityId={CornerStoneCollege}
        redirectKey={'cornerstone.cornerstoneUploadTT'}
      />
    )
}
export default CornerStonePendingTTApplicationList
