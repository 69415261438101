import { apiUrl } from '../../../settings'

export const addTarget = () => {
  return apiUrl + '/targets'
}

export const singleTargetUrl = (id) => {
  return apiUrl + '/targets/' + id
}


export const managerTargetUrl = (id) => {
  return apiUrl + '/managerTarget'
}
export const getAchievedTargetsUrl = (id) => {
  return apiUrl + '/getAchievedTargets'
}
