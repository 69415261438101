import {Button, Col, Form, notification, Row} from 'antd'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {enrollCompany} from '../actions/enrollCompany'
import {LoadAllCountry, LoadState} from "../../../components/_utils/countryUtil";

let initListState = {
    allCountries: LoadAllCountry(),
    allStates: []
}
const EnrollCompany = props => {
    let [listState, setListState] = useState(initListState)
    const {form: {getFieldDecorator}} = props
    const dispatch = useDispatch();
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null


    const events = {
        chooseCountry: (name) => {
            let countryName = _.find(listState.allCountries, (item) => {
                return item.name == name
            })
            if (countryName) {
                props.form.setFieldsValue({
                    countryName: countryName.name ? countryName.name : '',
                    stateName: undefined,
                    cityName: undefined
                })

                setListState((prevState) => {
                    return {
                        ...prevState,
                        allStates: countryName.id ? LoadState(countryName.id) : []
                    }
                })
            }
        },
        chooseState: (name) => {
            let stateName = _.find(listState.allStates, (item) => {
                return item.name == name
            })
            if (stateName) {
                props.form.setFieldsValue({
                    stateName: stateName.name ? stateName.name : '',
                    cityName: undefined
                })
            }
        }
    }


    let inputTypes = {
        fields: [
            {
                key: 'contactPerson',
                label: 'Contact Person',
                required: false,
                placeholder: 'Contact Person',
                type: 'text',
                showStar: true
            },

            {
                key: 'companyName',
                label: 'Company Name',
                required: false,
                placeholder: 'Company Name',
                type: 'text',
                showStar: true
            },

            {
                key: 'email',
                label: 'Contact Email',
                required: true,
                placeholder: 'Contact Email',
                type: 'text',
                showStar: true
            },

            {
                key: 'mobile',
                span: '3',
                label: 'Contact Number',
                required: false,
                placeholder: 'Contact Number',
                type: 'text',
                showStar: true
            },

            {
                key: 'countryName',
                label: 'Country',
                type: 'select',
                span: 8,
                required: true,
                showSearch: true,
                options: listState.allCountries,
                keyAccessor: x => x.name,
                valueAccessor: x => `${x.name}`,
                onChange: x => {
                    events.chooseCountry(x)
                }
            },
            {
                key: 'stateName',
                label: 'State',
                type: 'select',
                span: 8,
                required: true,
                options: listState.allStates,
                showSearch: true,
                keyAccessor: x => x.name,
                valueAccessor: x => `${x.name}`,
                onChange: x => {
                    events.chooseState(x)
                }
            },
            {
                key: 'cityName',
                label: 'City Name',
                required: true,
                type: 'text',
                span: 8
            },
            {
                key: 'postalCode',
                label: 'Postal Code (Optional)',
                required: false,
                type: 'text',
                placeholder: 'Postal Code (Optional)',
                span: 'col-md-12'
            },
            /* {
               key: 'referralBy',
               label: 'Referral Code (if any)',
               required: false,
               type: 'text',
               placeholder: 'Referral Code',
               span: 'col-md-12'
             }*/
        ]
    }

    const submitFormLayout = {
        wrapperCol: {
            xs: {span: 24, offset: 0},
            sm: {span: 10, offset: 7},
            md: {span: 12, offset: 8}
        }
    }
    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    const handleSubmit = e => {
        const {form} = props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                valData.referralBy = user.referCode;
                if (user.userType == "admin") {
                    valData.referralBy = 'admin'
                }
                let fd = new FormData()
                fd.append('obj', JSON.stringify(valData))

                let data = await dispatch(enrollCompany(fd))
                if (data && !data.error) {
                    form.resetFields()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }


    return (
        <div className="row">
            <div className="col-lg-12">
                <Form onSubmit={handleSubmit}>
                    <div className="form-box mt-4">
                        <div className="d-flex align-items-center mb-3 heading-form">
                            <h5>Enroll Company</h5>
                        </div>

                        <div className="card unizportal">
                            <Row gutter={24}>
                                {inputTypes.fields.map((item, key) => {
                                    return (
                                        <Col span={8} key={key}>
                                            <GetEachFormFields
                                                item={item}
                                                getFieldDecorator={getFieldDecorator}
                                                formItemLayout={formItemLayout}
                                            />
                                        </Col>
                                    )
                                })}
                            </Row>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="btn">
                                    SAVE
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
}
const WrappedCity = Form.create()(EnrollCompany)
export default WrappedCity
