import {
    AutoComplete,
    Button,
    Col,
    Form,
    Row,
    notification
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {InputBox, expenseTypes, expensePerKm} from '../../../components/_utils/appUtils'
import {
    LoadCity,
    LoadState
} from '../../../components/_utils/countryUtil'
import FileInput from '../../../components/_utils/fileInput'
import {
    addExpense,
    calcAreaDistance,
    getCitiesFxn,
    getPlaceFxn,
    getStatesFxn
} from '../actions/dailyExpense'
import {listAllIndianCity} from '../../indianCity/actions'

let initialState = {
    uploadKey: moment(),
    allStates: [],
    allCities: []
}
const AddExpense = props => {
    const {
        form: {getFieldDecorator, setFieldsValue, getFieldValue, resetFields}
    } = props
    const dispatch = useDispatch()
    const {Option} = AutoComplete

    const [document, setDocument] = useState({})
    const [detail, setDetails] = useState('')
    const [state, setState] = useState(initialState)
    const {allStates, allCities} = state

    useEffect(
        () => {
            setState({
                ...state,
                allStates: 101 ? LoadState(101) : []
            })
        },
        [getFieldValue('expenseType')]
    )

    const [searchResults, setSearchResults] = useState([])

    const handleSearch = async (value) => {
        let {data} = await listAllIndianCity({results: 50, name: value, regExFilters: ['name']})

        /*const results = CityJsonWithPinCodes.filter(city =>
            city.name.toLowerCase().includes(value.toLowerCase())
        )
        */
        setSearchResults(data)
    }

    const children = searchResults.slice(0, 22).map(city => (
        <Option key={city.id} value={city.name}>
            {city.name}
        </Option>
    ))
    const [position, setPosition] = useState({fromPlace: '', toPlace: ''})

    const handlePlaceChange = (key, value) => {
        const place = _.find(searchResults, {name: value})
        if (place) {
            if (key == 'toPlace') {
                setFieldsValue({
                    toPlacePinCode: place.pinCode
                })
            } else {
                setFieldsValue({
                    fromPlacePinCode: place.pinCode
                })
            }
        }
        setPosition(prevPosition => ({
            ...prevPosition,
            [key]: value
        }))
    }
    useEffect(() => {
        calcDistanceFxn()
    }, [getFieldValue('city'), getFieldValue('toCity'), getFieldValue('fromPlace'), getFieldValue('toPlace')])

    useEffect(() => {
        calculateNetAmount()
    }, [getFieldValue('extraKlms'), getFieldValue('totalKlms'), getFieldValue('tollTax')])

    const calculateNetAmount = () => {
        let totalKm = (getFieldValue('totalKlms') ? parseFloat(getFieldValue('totalKlms')) : 0)
        let extraKlms = (getFieldValue('extraKlms') ? parseFloat(getFieldValue('extraKlms')) : 0)
        let tollTax = (getFieldValue('tollTax') ? parseFloat(getFieldValue('tollTax')) : 0)
        let netAmount = (expensePerKm * (totalKm + extraKlms)) + tollTax
        setFieldsValue({
            netAmount: parseFloat(netAmount.toFixed(2))
        })
    }

    const calcDistanceFxn = async () => {
        let obj = {}
        /* if (!getFieldValue('fromPlacePinCode')) {
             return notification.warning({
                 message: 'Please selct From Place'
             })
         }
         if (!getFieldValue('toPlacePinCode')) {
             return notification.warning({
                 message: 'Please selct To Place'
             })
         }*/
        if (getFieldValue('city') && getFieldValue('toCity')) {
            // obj.fromPlacePinCode = getFieldValue('fromPlacePinCode')
            // obj.toPlacePinCode = getFieldValue('toPlacePinCode')
            obj.state = getFieldValue('state')
            obj.city = getFieldValue('city')
            obj.fromPlace = getFieldValue('fromPlace')
            obj.toState = getFieldValue('toState')
            obj.toCity = getFieldValue('toCity')
            obj.toPlace = getFieldValue('toPlace')
            let data = await dispatch(calcAreaDistance(obj))
            if (data && data.distance) {
                // let totalKm = (getFieldValue('extraKlms') ? parseFloat(getFieldValue('extraKlms')) : 0) + (data.distance ? parseFloat(data.distance) : 0)
                setFieldsValue({
                    totalKlms: data.distance
                })
            }
        } else {
            setFieldsValue({
                totalKlms: 0
            })
        }
    }

    useEffect(
        () => {
            setSearchResults([])
        },
        [position]
    )
    useEffect(() => {
    }, [])

    const events = {
        chooseState: name => {
            let stateName = _.find(allStates, item => {
                return item.name == name
            })
            if (stateName) {
                setState({
                    ...state,
                    allCities: stateName.id ? LoadCity(stateName.id) : []
                })
            }
        },
        chooseDocument: e => {
            let {name, files} = e.target
            if (files && files.length) {
                setDocument(files[0])
            }
        }
    }

    const [indianStates, setIndianStates] = useState([])
    const [indianCities, setIndianCities] = useState([])
    const [fromPlace, setFromPlace] = useState([])
    const [toState, setToState] = useState([])
    const [toCities, setToCity] = useState([])
    const [toPlace, setToPlace] = useState([])

    const getIndianStates = async () => {
        let data = await dispatch(getStatesFxn({results: 1000}))
        setIndianStates(data)
    }
    const getIndianCities = async (params = {}) => {
        if (getFieldValue('state')) {
            params.state = getFieldValue('state')
        }
        let data = await dispatch(getCitiesFxn({results: 1000, ...params}))
        setIndianCities(data)
    }

    const getToStates = async () => {
        let data = await dispatch(getStatesFxn({results: 1000}))
        setToState(data)
    }

    const getToCities = async (params = {}) => {
        if (getFieldValue('toState')) {
            params.state = getFieldValue('toState')
        }
        let data = await dispatch(getCitiesFxn({results: 1000, ...params}))
        setToCity(data)
    }

    const getFromPlace = async (params = {}) => {
        if (getFieldValue('city')) {
            params.city = getFieldValue('city')
        }
        let data = await dispatch(getPlaceFxn({results: 1000, ...params}))
        setFromPlace(data)
    }

    const getToPlace = async (params = {}) => {
        if (getFieldValue('toCity')) {
            params.city = getFieldValue('toCity')
        }
        let data = await dispatch(getPlaceFxn({results: 1000, ...params}))
        setToPlace(data)
    }


    useEffect(() => {
        getIndianStates()
        getToStates()
    }, [])

    useEffect(() => {
        if (getFieldValue('state')) {
            getIndianCities()
        }
    }, [getFieldValue('state')])

    useEffect(() => {
        if (getFieldValue('toState')) {
            getToCities()
        }
    }, [getFieldValue('toState')])

    useEffect(() => {
        if (getFieldValue('city')) {
            getFromPlace()
        }
    }, [getFieldValue('city')])

    useEffect(() => {
        if (getFieldValue('toCity')) {
            getToPlace()
        }
    }, [getFieldValue('toCity')])

    let inputTypes = {
        fields: [
            {
                key: 'expenseType',
                label: 'Expense Type',
                type: 'select',
                span: 6,
                required: true,
                showSearch: true,
                options: expenseTypes,
                keyAccessor: x => x,
                valueAccessor: x => `${x}`,
                onChange: x => {
                    let obj = {
                        expenseType: x,
                        date: '',
                        note: '',
                        amount: null
                    }
                    if ((x = 'Other Expense')) {
                        setDetails('Gift To Agent')
                    }
                    if ((x = 'Car')) {
                        props.form.setFieldsValue({totalKlms: 0})
                    }
                    props.form.setFieldsValue(obj)
                }
            },
            {
                key: 'name',
                required: true,
                label:
                    getFieldValue('expenseType') === 'Hotel'
                        ? 'Hotel Name'
                        : getFieldValue('expenseType') === 'Food'
                        ? 'Restaurant Name'
                        : 'Hotel',

                hidden: !(
                    getFieldValue('expenseType') === 'Hotel' ||
                    getFieldValue('expenseType') === 'Food'
                )
            },
            {
                key: 'Details',
                label: 'Other Expense',
                hidden: getFieldValue('expenseType') !== 'Other Expense',
                disabled: true,
                span: 8,
                customField: (
                    <InputBox title={'Details'} className={'rowFlex'}>
                        <div className={'disbaledField'}>{detail}</div>
                    </InputBox>
                )
            },
            {
                key: 'amount',
                label: 'Amount',
                hidden: !(
                    getFieldValue('expenseType') === 'Hotel' ||
                    getFieldValue('expenseType') === 'Food' ||
                    getFieldValue('expenseType') === 'Other Expense'
                ),
                type: 'number',
                required: true,
                span: 8
            },
            {
                key: 'address',
                label:
                    getFieldValue('expenseType') === 'Hotel'
                        ? 'Hotel Address'
                        : getFieldValue('expenseType') === 'Food'
                        ? 'Restaurant Location'
                        : 'Address',
                required: true,
                placeholder: 'Address Name',
                hidden: !(
                    getFieldValue('expenseType') === 'Hotel' ||
                    getFieldValue('expenseType') === 'Food'
                ),

                span: 8
            },
            {
                key: 'state',
                label: (
                    <>
                        {getFieldValue('expenseType') === 'Hotel' ? 'Hotel State' : ''}
                        {getFieldValue('expenseType') === 'Food' ? 'Restaurant State' : ''}
                        {getFieldValue('expenseType') === 'Car' ? 'From State' : ''}
                        {getFieldValue('expenseType') === 'Other Expense' ? 'State' : ''}
                    </>
                ),
                required: true,
                placeholder: 'State Name',
                type: 'select',
                showSearch: true,
                allowClear: true,
                hidden: !(
                    getFieldValue('expenseType') === 'Hotel' ||
                    getFieldValue('expenseType') === 'Food' ||
                    getFieldValue('expenseType') === 'Car'
                ),
                options: indianStates,
                keyAccessor: x => x.name,
                valueAccessor: x => `${x.name}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        fromPlace: '',
                        city: '',
                        state: x
                    })
                },
                span: 6
            },

            {
                key: 'city',
                allowClear: true,
                label: (
                    <>
                        {getFieldValue('expenseType') === 'Hotel' ? 'Hotel City' : ''}
                        {getFieldValue('expenseType') === 'Food' ? 'Restaurant City' : ''}
                        {getFieldValue('expenseType') === 'Car' ? 'From City' : ''}
                        {getFieldValue('expenseType') === 'Other Expense' ? 'City' : ''}
                    </>
                ),
                required: true,
                placeholder: 'City Name',
                showSearch: true,
                type: 'select',
                hidden: !(
                    getFieldValue('expenseType') === 'Hotel' ||
                    getFieldValue('expenseType') === 'Food' ||
                    getFieldValue('expenseType') === 'Car'
                ),
                options: indianCities,
                keyAccessor: x => x.name,
                valueAccessor: x => `${x.name}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        fromPlace: '',
                        city: x
                    })
                },
                span: 6
            },
            {
                key: 'fromPlace',
                allowClear: true,
                label: 'From Place',
                placeholder: 'From Place',
                type: 'select',
                showSearch: true,
                hidden: !(getFieldValue('expenseType') === 'Car'),
                options: fromPlace,
                keyAccessor: x => x.name,
                valueAccessor: x => `${x.name}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        fromPlace: x
                    })
                },
                span: 6
            },
            {
                key: 'toState',
                allowClear: true,
                label: 'To State',
                required: true,
                placeholder: 'State Name',
                type: 'select',
                showSearch: true,
                hidden: !(getFieldValue('expenseType') === 'Car'),
                options: toState,
                keyAccessor: x => x.name,
                valueAccessor: x => `${x.name}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        toPlace: '',
                        toCity: '',
                        toState: x
                    })
                },
                span: 6
            },
            {
                key: 'toCity',
                allowClear: true,
                label: 'To City',
                required: true,
                placeholder: 'City Name',
                type: 'select',
                showSearch: true,
                hidden: !(getFieldValue('expenseType') === 'Car'),
                options: toCities,
                keyAccessor: x => x.name,
                valueAccessor: x => `${x.name}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        toPlace: '',
                        toCity: x
                    })
                },
                span: 6
            },

            {
                key: 'toPlace',
                allowClear: true,
                label: 'To Place',
                placeholder: 'To Place',
                type: 'select',
                showSearch: true,
                hidden: !(getFieldValue('expenseType') === 'Car'),
                options: toPlace,
                keyAccessor: x => x.name,
                valueAccessor: x => `${x.name}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        toPlace: x
                    })
                },
                span: 6
            },
            {
                key: 'button',
                label: 'Calculate Distance',
                hidden: getFieldValue('expenseType') !== 'Car',
                customField: (
                    <InputBox>
                        <a className={'btn_info'} style={{marginTop: 35}}
                           onClick={() => {
                               calcDistanceFxn()
                           }}>
                            Calculate Kms
                        </a>
                    </InputBox>
                ),
                span: 6
            },
            {
                label: 'Total Kms',
                key: 'totalKlms',
                disabled: true,
                hidden: getFieldValue('expenseType') !== 'Car',
                onChange: x => {
                    let obj = {
                        totalKlms: x
                    }
                    props.form.setFieldsValue(obj)
                },
                type: 'number',
                span: 8
            },
            {
                key: 'extraKlms',
                label: 'Extra Kms',
                hidden: getFieldValue('expenseType') !== 'Car',
                onChange: x => {
                    let obj = {
                        extraKlms: x
                    }
                    props.form.setFieldsValue(obj)
                },
                type: 'number',
                // required: true,
                span: 8
            },
            {
                key: 'tollTax',
                label: 'Toll Tax',
                hidden: getFieldValue('expenseType') !== 'Car',
                onChange: x => {
                    props.form.setFieldsValue({tollTax: x})
                },
                type: 'number',
                span: 8
            },
            {
                key: 'netAmount',
                label: 'Net Amount',
                hidden: getFieldValue('expenseType') !== 'Car',
                type: 'number',
                required: true,
                span: 8
            },

            {
                key: 'note',
                label: 'Note',
                required: true,
                type: 'textArea',
                span: 24,
                rows: 4
            },
            {
                key: 'agentName',
                label: 'Agent Name',
                hidden: getFieldValue('expenseType') !== 'Other Expense'
            },

            {
                key: 'receipt',
                label: 'Receipt',
                name: 'receipt',
                type: 'file',
                span: 6,
                required: true,
                onChange: e => {
                    events.chooseDocument(e)
                }
            },
            {
                key: 'date',
                label: 'Date',
                type: 'date',
                required: true,
                span: 6,
                placeholder: 'Choose Date'
            }
        ]
    }

    const submitFormLayout = {
        wrapperCol: {
            xs: {span: 24, offset: 0},
            sm: {span: 10, offset: 7},
            md: {span: 12, offset: 8}
        }
    }
    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    const handleSubmit = e => {
        const {form} = props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                if (valData.expenseType !== 'Car') {
                    if (!document || (document && !document.name)) {
                        return notification.warning({
                            message: 'Please choose Receipt.'
                        })
                    }
                }
                if (valData.expenseType == 'Car') {
                    if (valData.expenseType == 'Other Expense') {
                        valData.details = 'Gift To Agent'
                    }
                    if (!valData.totalKlms) {
                        return notification.warning({
                            message: 'Please calculate distance.'
                        })
                    }
                }
                if (!valData.date) {
                    notification.warning({
                        message: 'Please choose date.'
                    })
                    return
                }
                if (valData.expenseType == 'Car') {
                    valData.amount = valData.netAmount
                }
                let fd = new FormData()
                fd.append('obj', JSON.stringify(valData))
                if (document && document.name) {
                    fd.append('receipt', document)
                }
                let data = await dispatch(addExpense(fd))
                if (data && !data.error) {
                    form.resetFields()
                    setPosition({fromPlace: '', toPlace: ''})
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }

    return (
        <div className='row'>
            <div className='col-lg-12'>
                <Form onSubmit={handleSubmit}>
                    <div className='form-box mt-4'>
                        <div className='d-flex align-items-center mb-3 heading-form'>
                            <h5>Add Daily Expense</h5>
                        </div>
                        <div className='card unizportal'>
                            <Row gutter={24}>
                                {inputTypes.fields.map((item, key) => {
                                    return !item.hidden ? (
                                        item.customField ? (
                                            <Col span={item.span ? item.span : 6} key={key}>
                                                {item.customField}
                                            </Col>
                                        ) : item.type == 'file' ? (
                                            <Col span={6} key={key} className={'mt10'}>
                                                <FileInput
                                                    name={item.name}
                                                    label={`* ${item.label}`}
                                                    chooseDocument={item.onChange}
                                                    key={document}
                                                />
                                            </Col>
                                        ) : (
                                            <Col
                                                span={item.span ? item.span : 8}
                                                md={item.span ? item.span : 8}
                                                sm={12}
                                                xs={24}
                                                key={key}
                                                className={'mb10'}>
                                                <GetEachFormFields
                                                    item={item}
                                                    getFieldDecorator={getFieldDecorator}
                                                    formItemLayout={formItemLayout}
                                                />
                                            </Col>
                                        )
                                    ) : null
                                })}
                            </Row>
                            <Form.Item>
                                <Button type='primary' htmlType='submit' className='btn'>
                                    SAVE
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
}
const WrappedCity = Form.create()(AddExpense)
export default WrappedCity
