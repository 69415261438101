import React, { useState, useEffect } from 'react'
import { Drawer, Button, Icon, Modal, Radio, Table, Select, notification, Input, Tooltip } from 'antd'
import { useDispatch } from 'react-redux'
import { newFormatDisplayDate } from '../../../components/_utils/appUtils'
import { getScreenshotsFxn, getSingleScreenshotDocFxn, updateScreenshotStatusFxn } from '../actions/cornerStone'
import '../cornerStoneCollege/style.css'
import ReuploadScreenshotForm from './reuploadScreenshotDrawer'
import UploadScreenshots from './cornerStoneScreenshotDrawer'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import moment from 'moment'
import UploadScreenshotModal from './uploadScreenshot'
import { getServerDateFxn } from '../../universityCommission/actions'

const { Option } = Select

const UpdateScreenshotStatusDrawer = (props) => {
  const { visible, onClose, selectedScreenshot, apiRequest} = props
  const dispatch = useDispatch()
  const [status, setStatus] = useState('')
  const [reason, setReason] = useState('')

  const handleStatusChange = (value) => {
    setStatus(value)
  }

  const handleReasonChange = (e) => {
    setReason(e.target.value)
  }

  const handleSubmit = async () => {
    if (!status) {
      notification.warning({ message: 'Please choose a status.' })
      return
    }
    if (status === 'Not Available' && !reason) {
      notification.warning({ message: 'Please enter a reason.' })
      return
    }


    const resp = await dispatch(updateScreenshotStatusFxn({
      screenshotId: selectedScreenshot._id,
      status,
      reason
    }))

    if (resp.success) {
      // notification.success({ message: 'Screenshot status updated successfully.' });
      apiRequest()
      setReason('')
      setStatus('')
      onClose()

    } else {
      // notification.error({ message: 'Failed to update screenshot status.' });
    }
  }

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      title='Update Screenshot Status'
    >
      <Select onChange={handleStatusChange} style={{ marginBottom: '10px' }}>
        <Option value='Attended'>Attended</Option>
        <Option value='Not Available'>Not Available</Option>
      </Select>

      {status === 'Not Available' && (
        <Input.TextArea
          placeholder='Enter reason...'
          rows={4}
          onChange={handleReasonChange}
          value={reason}
          style={{ marginBottom: '10px' }}
        />
      )}

      <Button type='primary' onClick={handleSubmit}>
        Submit
      </Button>
    </Modal>
  )
}


const CornerstoneStudent = (props) => {
  const { visible, onClose, studentDetails, user } = props
  const dispatch = useDispatch()
  const [selectedScreenshot, setSelectedScreenshot] = useState(null)
  const [uploadScreenshots, setUploadScreenshots] = useState({
    visible: false,
    applicationId: '',
    studentId: ''
  })
  const [reUploadScreenshots, setReUploadScreenshots] = useState({
    visible: false,
    screenshotObjId: '',
    screenshotId: '',
    details: '',
    backDate: undefined
  })
  const [uploadState, setUploadState] = useState({
    visible: false,
    screenshotObjId: '',
    screenshotId: '',
    index: 0
  })
  const [showUpdateStatusDrawer, setShowUpdateStatusDrawer] = useState(false)
  const [screenshotObj, setScreenshotObj] = useState({})
  const [currentDate, setCurrentDate] = useState('')


  const apiRequest = async () => {
    let { currentDate } = await getServerDateFxn()
    setCurrentDate(currentDate)
    let obj = {
      studentId: studentDetails._id,
      applicationId: studentDetails.applications._id
    }
    let { data, success } = await dispatch(getSingleScreenshotDocFxn(obj))
    if (success) {
      setScreenshotObj(data)
    }
  }


  useEffect(() => {
    if (visible) {
      apiRequest()
    }
  }, [visible])


  const handleUpdateStatus = (record) => {
    setSelectedScreenshot(record)
    setShowUpdateStatusDrawer(true)
  }

  const closeUpdateStatusDrawer = () => {
    setShowUpdateStatusDrawer(false)
    apiRequest()
  }
  const screenshotColumns = [
    {
      title: 'Screenshot #',
      dataIndex: 'screenshotIndex',
      key: 'screenshotIndex',
      width: 120,
      render: (text) => {
        return (
          <strong>Screenshot {text + 1}</strong>
        )
      }
    },
    {
      title: 'Screenshot',
      dataIndex: 'path',
      key: 'path',
      width: 300,
      render: (text, record, index) => {
        let { joiningDate } = record
        const isClickable = moment(record.dueDate) < moment(currentDate).endOf('day')
        if (joiningDate) {
          return (
            <div className={'alignCenter'}>
              {text ? <LazyLoadImage src={text} alt='Screenshot Preview' className={'screenshot_img'} /> : null}
              {
                user.userType !== 'cornerStoneManager' ? (
                    <div className={'reupload-btns'}>
                      <Button
                          type='primary'
                          onClick={() => events.openReUploadDrawer(record)}
                          style={{ display: 'block', marginTop: '5px' }}
                      >
                        Re-upload
                      </Button>
                    </div>
                ) : ''
              }

            </div>
          )
        } else {
          return (
            <div className={'reupload-btns'}>
              {user.userType !== 'cornerStoneManager' ? (
                  <Icon
                      type='plus-circle'
                      theme='filled'
                      className={isClickable ? 'plus-circle-icon' : 'plus-circle-disabled'}
                      onClick={isClickable ? () => events.openUploadModal(record, index) : undefined}
                      style={{ cursor: isClickable ? 'pointer' : 'not-allowed' }}
                      disabled={!isClickable}
                  />
              ) : ''}

            </div>
          )
        }
      }
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (text) => <span>{newFormatDisplayDate(text)}</span>
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      width: 300
    },
    {
      title: 'Added On',
      dataIndex: 'joiningDate',
      key: 'joiningDate',
      render: (text) => <span>{newFormatDisplayDate(text)}</span>
    },
    {
      title: 'Back Date',
      dataIndex: 'backDate',
      key: 'backDate',
      render: (text) => text ? <span>{newFormatDisplayDate(text)}</span> : null
    }

  ]

  const events = {
    showScreenshotsDrawer: (applicationId, studentId) => {
      setUploadScreenshots({
        visible: true,
        applicationId: applicationId,
        studentId: studentId
      })
    },
    closeScreenshotsDrawer: () => {
      setUploadScreenshots(({
        visible: false,
        applicationId: '',
        studentId: ''
      }))
      apiRequest()
    },

    openReUploadDrawer: (record) => {
      setReUploadScreenshots({
        visible: true,
        screenshotObjId: screenshotObj._id,
        screenshotId: record._id,
        details: record.details,
        backDate: record.backDate
      })
    },

    closeReUploadDrawer: () => {
      setReUploadScreenshots({
        visible: false,
        screenshotObjId: '',
        screenshotId: '',
        details: '',
        backDate: undefined
      })
    },
    openUploadModal: (record, index) => {
      setUploadState({
        visible: true,
        screenshotObjId: screenshotObj._id,
        screenshotId: record._id,
        index
      })
    },
    closeUploadModal: () => {
      setUploadState({
        visible: false,
        screenshotObjId: '',
        screenshotId: '',
        index: 0
      })
    }
  }

  return (
    <Drawer
      placement='right'
      title={'Enroll Student'}
      onClose={onClose}
      visible={visible}
      width={'75%'}
    >
      <div className={'corner-stone-drawer'}>
        <div className='cornerstone-card-body cornerstone-student-data mt10'>
          <div className='row'>
            <div className='col-md-4'>
              <strong>Name : </strong>
              <span>{studentDetails.name}</span>
            </div>
            <div className='col-md-4'>
              <strong>Date Of Birth : </strong>
              <span>{newFormatDisplayDate(studentDetails.dateOfBirth)}</span>
            </div>
            <div className='col-md-4'>
              <strong>College Name : </strong>
              <span>CornerStone</span>
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-md-4'>
              <strong>Intake : </strong>
              <span>{`${studentDetails.applications.intake.month} ${studentDetails.applications.intake.year}`}</span>
            </div>
            <div className='col-md-4'>
              <strong>Campus : </strong>
              <span>{studentDetails.applications.campusId.campusName}</span>
            </div>
            <div className='col-md-4'>
              <strong>Address : </strong>
              <span>{studentDetails.address}</span>
            </div>
          </div>
        </div>


        {screenshotObj && screenshotObj._id ? null : (
          <>
            {user.userType !== 'cornerStoneManager' ? (
                <>
                <Button type={'primary'}
                        style={{ float: 'right', margin: '20px 10px', width: '100px', marginBottom: '40px' }}
                        onClick={() => events.showScreenshotsDrawer(studentDetails.applications._id, studentDetails._id)}
                >Enroll</Button><br />
                </>
            ) : ''}

          </>
        )}

        {screenshotObj && screenshotObj._id ? (
          <div className={'mt20 card-enroll-table'} style={{ width: '100%' }}>
            <div className='cornerstone-card-body'>
              <Table columns={screenshotColumns}
                     dataSource={screenshotObj.screenshots}
                     pagination={false}
                     size={'small'}
                     className={'table-screenshots'} />
            </div>
          </div>
        ) : null}
      </div>


      {reUploadScreenshots.visible ?
        <ReuploadScreenshotForm
          {...reUploadScreenshots}
          onClose={events.closeReUploadDrawer}
          onSubmit={() => {
            events.closeReUploadDrawer()
            apiRequest()
          }}
        /> : null}


      {uploadScreenshots.visible ?
        <UploadScreenshots
          onClose={() => events.closeScreenshotsDrawer()}
          applicationId={uploadScreenshots.applicationId}
          studentId={uploadScreenshots.studentId}
          visible={uploadScreenshots.visible}
        />
        : null}

      {uploadState.visible ?
        <UploadScreenshotModal
          {...uploadState}
          onClose={events.closeUploadModal}
          studentDetails={studentDetails}
          onSubmit={() => {
            events.closeUploadModal()
            apiRequest()
          }}
        />
        : null}


      {showUpdateStatusDrawer ?
        <UpdateScreenshotStatusDrawer
          visible={showUpdateStatusDrawer}
          onClose={closeUpdateStatusDrawer}
          selectedScreenshot={selectedScreenshot}
          apiRequest={apiRequest}
        /> : null
      }

    </Drawer>
  )
}

export default CornerstoneStudent
