import React, {useRef, useState, useEffect} from 'react'
import {TableComp} from 'sz-react-utils-lite'
import {listAllStory, deleteStory} from '../actions'
import {useDispatch, useSelector} from 'react-redux'
import {CheckMyRights} from '../../WebComponent/CheckUserRights'
import {Icon, notification, Popconfirm, Tooltip} from 'antd'
import {Link} from "react-router-dom";

const initialState = {
    targetObj: '',
    viewTargetDrawer: false
}

const TargetList = () => {
    const tableRef = useRef()
    const dispatch = useDispatch()

    const columns = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            width: 80,
            key: 'sno',
            render: (value, item, index) => index + 1
        },
        {
            title: 'Poster',
            dataIndex: 'image',
            key: 'image',
            render: (item) => {
                return item && item.url ? <img src={item.url} style={{height: 80}}/> : null
            }
        },
        {
            title: 'Video',
            dataIndex: 'video',
            key: 'video',
            render: (item) => {
                return <a target={'_blank'} href={item.url}>{item.name}</a>
            }
        },

        {
            key: 'actions',
            title: 'Actions',
            height: 100,
            render: (text, record) => {
                return (
                    <div>
                        <Tooltip title='Delete'>
                            <Popconfirm title={'Are your sure, you want to delete this?'}
                                        onConfirm={() => {
                                            confirmDelete(record._id)
                                        }}
                                        okText='Yes' cancelText='No'>
                                <button
                                    className={'btn'}
                                    style={{marginRight: 6}}>
                                    <Icon type={'delete'}/>
                                </button>
                            </Popconfirm>
                        </Tooltip>
                    </div>

                )
            }
        }
    ]

    const confirmDelete = async (id) => {
        let resp = await dispatch(deleteStory(id))
        if (resp && resp.success) {
            notification.warning({message: resp.message})
            tableRef.current.reload()
        }
    }

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.sortField = '_id'
            params.sortOrder = 'descend'
            let resp = await dispatch(listAllStory({...params}))
            resolve(resp)
        })
    }

    return (
        <>
            <div className='row mt-4'>
                <div className='col-lg-12'>
                    <div className='card'>
                        {/* <TargetBanner /> */}
                        <div className='table-head d-flex justify-content-between all-student-search'>
                            <h5>Trusted Story </h5>
                            <div className='sort-box-table mark-btn text-right'>
                                <CheckMyRights rightList={['admin', 'branchManager']}>
                                    <Link className='btn' to={'add-trustedStory'}>
                                        <img src={'../dist/icons/plus.png'} className={'plus'}/>Add Trusted Story</Link>
                                </CheckMyRights>
                            </div>
                        </div>

                        <div className='card-body table-responsive'>
                            <TableComp columns={columns}
                                       className={'table table-striped table-valign-middle'}
                                       apiRequest={apiRequest}
                                       pagination={{
                                           position: 'top',
                                           pageSizeOptions: ['50', '100', '200', '500'],
                                           defaultPageSize: 50,
                                           current: 1
                                       }}
                                       ref={tableRef}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default TargetList
