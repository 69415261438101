import React, { useState } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { Form, Select, Button, Card, notification, Spin, Row, Col, Drawer } from 'antd'
import _ from 'lodash'
// import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { useDispatch, useSelector } from 'react-redux'
import { addPendency } from '../actions/pendency'
import GetAllFormFields from '../../../components/_utils/formUtils'


const AddPendency = (props) => {
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields }, visible, events } = props
  const dispatch = useDispatch()
  const { loader } = useSelector(state => ({
    loader: state.countryReducers.loader
  }))

  let inputTypes = {
    fields: [
      { key: 'name', label: 'Pendency', type: 'text', required: true }
    ]
  }


  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = e => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let data = await dispatch(addPendency(valData))
        if (data && !data.error) {
          form.resetFields()
        }
        events.hideDrawer()
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }
  return (
    <Drawer
      visible={visible}
      width={600}
      placement="right"
      onClose={events.hideDrawer}
      title={'Add New Pendency'}>
      <div className="form-box">
        <div className="card unizportal">
          <Form onSubmit={handleSubmit}>
            <GetAllFormFields
              inputSchema={inputTypes}
              formItemLayout={formItemLayout}
              getFieldDecorator={getFieldDecorator}/>
            <Form.Item style={{ marginTop: 25 }}>
              <Button type="primary" htmlType="submit" className={'btn'}>
                ADD
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Drawer>
  )
}
const WrappedCountry = Form.create()(AddPendency)
export default WrappedCountry
