import React, {Component} from 'react'
import {departmentObj} from '../../../components/_utils/appUtils'
import {getMeetingsCount} from '../actions'
import {connect} from 'react-redux'
import MeetingListDrawer from './meetingListDrawer'
import moment from 'moment'


class MeetingsCountComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            courseList: [],
            skip: 0,
            loadMore: true,
            visibleStudentDrawer: false,
            meetingDrawerVisible: false,
            pendingCount: 0,
            notAttendedCount: 0,
            attendedCount: 0,
            totalMeetings: 0
        }
    }

    componentDidMount() {

    }


    render() {
        let {
            userId,
            fromDate,
            toDate,
            meetingData: {totalMeetings, pendingCount, attendedCount, notAttendedCount}
        } = this.props
        return (
            <div>
                <div className='card-pane-right listing-section w-100' style={{border: 'none', paddingTop: 0}}>
                    <ul className={'count'}>
                        <li className={'pointerCls mb20'}
                            onClick={() => {
                                this.setState({meetingDrawerVisible: true, status: 'all'})
                            }}>
                            <h4 className={'linkText'}>{totalMeetings ? totalMeetings : 0}</h4>
                            <p style={{color: 'black', fontSize: 15}}>Total Meetings</p>
                        </li>
                        <li className={'pointerCls mb20'} onClick={() => {
                            // setStatus('Pending')
                            this.setState({meetingDrawerVisible: true, status: 'Pending'})
                            // setMeetingDrawerVisible(true)
                        }}>
                            <h4 className={'linkText'}>{pendingCount ? pendingCount : 0}</h4>
                            <p style={{color: 'black', fontSize: 15}}>Meetings Pending</p>
                        </li>
                        <li className={'pointerCls mb20'} onClick={() => {
                            this.setState({meetingDrawerVisible: true, status: 'attended'})
                            /* setStatus('attended')
                             setMeetingDrawerVisible(true)*/
                        }}>
                            <h4 className={'linkText'}>{attendedCount ? attendedCount : 0}</h4>
                            <p style={{color: 'black', fontSize: 15}}>Meetings Attended</p>
                        </li>
                        <li className={'pointerCls mb20'} onClick={() => {
                            this.setState({meetingDrawerVisible: true, status: 'notAttended'})
                            /* setStatus('notAttended')
                             setMeetingDrawerVisible(true)*/
                        }}>
                            <h4 className={'linkText'}>{notAttendedCount ? notAttendedCount : 0}</h4>
                            <p style={{color: 'black', fontSize: 15}}>Meetings Not Attended</p>
                        </li>
                    </ul>
                </div>

                {this.state.meetingDrawerVisible ?
                    <MeetingListDrawer
                        status={this.state.status}
                        userId={userId}
                        toDate={toDate ? moment(toDate) : null}
                        fromDate={fromDate ? moment(fromDate) : null}
                        visible={this.state.meetingDrawerVisible}
                        closeDrawer={() => {
                            this.setState({meetingDrawerVisible: false})
                        }}
                    /> : null}
            </div>
        )
    }
}

export default MeetingsCountComponent
