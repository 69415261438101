import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Icon,
  Input,
  Modal,
  Popconfirm,
  Row,
  Table,
  Tooltip,
  notification
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  DefaultTablePagination,
  InputBox,
  displayDate
} from '../../../components/_utils/appUtils'
import {
  addAccountSendOtpFxn,
  addBankAccount,
  addEmailAccount,
  deleteBankAccount,
  deleteEmailAccount,
  listAllAccount,
  listEmailAccount,
  updateBankAccount
} from '../../bankAccount/actions/bankAccount'
import { getTransactionList } from '../../referrals/actions'
import {
  addWithdrawal,
  getStudentCurrentBalance,
  getStudentWithdrawal,
  sendWithdrawalOtp,
  updateWithdrawal
} from '../actions/student'

const initState = {
  email: '',
  confirmEmail: ''
  // accountNo: '',
  // ifscCode: '',
  // swiftCode: '',
  // address: '',
  // otp: ''
}

// const Card = styled.div``

const StudentWithdrawals = props => {
  const [totalWithdrawals, setTotalWithdrawals] = useState(0)
  let [total, setTotal] = useState([])
  const [totalTransactions, setTotalTransactions] = useState(0)
  const [bankTableData, setBankTableData] = useState([])
  const [selectedBankId, setSelectedBankId] = useState(null)
  let [listData, setListData] = useState([])
  let [loading, setLoading] = useState(true)
  const [currentBalance, setCurrentBalance] = useState(0)
  let [bankList, setBankList] = useState([])
  const [editBankData, setEditBankData] = useState({
    email: ''
  })

  const [editingBankId, setEditingBankId] = useState(null)
  let [visibleBankDrawer, setVisibleBankDrawer] = useState(false)
  const [addBankAccountState, setAddBankAccountState] = useState(initState)
  const [addBankVisible, setAddBankVisible] = useState(false)
  const [editBankModal, setEditBankModal] = useState(false)
  const [state, setState] = useState({
    studentObj: '',
    viewCourseDrawer: false,
    addWithdrawalDrawer: false,
    addBankAccountDrawer: false
  })
  const [visibleOtpModal, setVisibleOtpModal] = useState(false)
  const [visibleOtpScreen, setVisibleOtpScreen] = useState(false)
  const [otp, setOtp] = useState('')
  const [withdrawalData, setWithdrawalData] = useState({})
  const [selectedAccountId, setSelectedAccountId] = useState(null)

  const tableRef = useRef()
  const dispatch = useDispatch()

  const apiRequest = params => {
    return new Promise(async resolve => {
      let resp = await dispatch(
        getStudentWithdrawal({ ...params, regExFilters: ['name'] })
      )
      setTotalWithdrawals(resp.total)
      resolve(resp)
    })
  }
  const fetchStudentBalance = async () => {
    try {
      await dispatch(getStudentCurrentBalance())
      // console.log('Student Balance Data:', balanceData);
    } catch (error) {
      console.error('Error fetching student balance:', error)
    }
  }
  useEffect(() => {
    fetchStudentBalance()
    events.fetchStudentWithdrawals()
  }, [])
  const handleChange = data => {
    setState(prevData => {
      return {
        ...prevData,
        ...data
      }
    })
  }
  //function to get student current balance
  const studentCurrentBalance = async balanceValue => {
    // console.log('balanceValue:', balanceValue)
    try {
      let balanceData = await dispatch(getStudentCurrentBalance(balanceValue))
      console.log('Balance Data:', balanceData, '------deno---balanceData')

      setCurrentBalance(balanceValue)
      // console.log(balance, 'balance-------')
    } catch (error) {
      console.error('Error fetching student balance:', error)
    }
  }
  const fetchTransactionlist = async () => {
    let total = 0

    let params = {}
    let { data } = await dispatch(getTransactionList())
    // console.log('transactions', data)
    setTotalTransactions(data.length)
    let calculatedBalance = 0

    /*  lodash.each(data, item => {
              total = total + parseFloat(item.amount)

              if (item.type === 'Credit') {
                  calculatedBalance = total + balance
              } else if (item.type === 'Debit') {
                  calculatedBalance = balance - total
              }
          })*/

    setTotal(total)
    setListData(data)
    setLoading(false)

    const balanceData = await studentCurrentBalance(calculatedBalance)
    // console.log(calculatedBalance, 'calculatedBalance')
    if (balanceData) {
      setCurrentBalance(balanceData.balance)
    }
    return currentBalance
  }
  useEffect(() => {
    loadBankList()
    // fetchTransactionlist()
  }, [])

  const loadBankList = async () => {
    try {
      let resp = await dispatch(listEmailAccount({}))
      setBankList(resp.data)
      setBankTableData(resp.data)
    } catch (error) {
      console.error('Error loading Email list:', error)
    }
  }

  const updateState = data => {
    setState(prevState => {
      return {
        ...prevState,
        ...data
      }
    })
  }
  const fetchStudentsBalance = async () => {
    try {
      const { balance } = await dispatch(getStudentCurrentBalance())
      setCurrentBalance(balance)
    } catch (error) {
      console.error('Error fetching student balance:', error)
    }
  }

  useEffect(() => {
    fetchStudentsBalance()
  }, [])

  const handleEditInputChange = (field, value) => {
    setEditBankData(prevData => ({
      ...prevData,
      [field]: value
    }))
  }

  const handleSaveChanges = async () => {
    const updateData = {
      email: editBankData.email,
      accountNo: editBankData.accountNo,
      ifscCode: editBankData.ifscCode,
      swiftCode: editBankData.swiftCode,
      address: editBankData.address
    }

    const resp = await dispatch(
      updateBankAccount(selectedAccountId, updateData)
    )

    if (resp && resp.success) {
      loadBankList()
      setEditBankModal(false)
    }
  }

  const events = {
    viewCourse: record => {
      setState({
        ...state,
        viewCourseDrawer: true,
        studentObj: record
      })
    },
    viewCourseClose: () => {
      setState({
        ...state,
        viewCourseDrawer: false,
        studentObj: {}
      })
      tableRef.current.reload()
    },
    handleTabChange: tab => {
      setState({
        ...state,
        activeTab: tab
      })
    },
    openAddWithdrawalDrawer: () => {
      setState({
        ...state,
        addWithdrawalDrawer: true
      })
    },
    closeAddWithdrawalDrawer: () => {
      setState({
        ...state,
        addWithdrawalDrawer: false
      })
    },
    openOtpModal: () => {
      setVisibleOtpModal(true)
    },
    hideModal: () => {
      setVisibleOtpModal(false)
    },
    /*  openAddBankAccountDrawer: () => {
            setState({
              ...state,
              addBankAccountDrawer: true
            })
          },
          closeAddBankAccountDrawer: () => {
            setState({
              ...state,
              addBankAccountDrawer: false
            })
          },*/
    showBankDrawer: () => {
      setVisibleBankDrawer(true)
    },
    hideBankDrawer: () => {
      setVisibleBankDrawer(false)
      loadBankList()
    },
    openAddBankAccountDrawer: () => {
      events.hideBankDrawer()
      setAddBankVisible(true)
    },
    closeAddBankAccountDrawer: () => {
      setAddBankVisible(false)
      setAddBankAccountState(initState)
    },
    handleChangeAddBankAccount: data => {
      setAddBankAccountState(prevData => {
        return {
          ...prevData,
          ...data
        }
      })
    },
    handleSubmitBank: async e => {
      e.preventDefault()

      if (!addBankAccountState.email) {
        notification.warning({ message: 'Please Enter Email ' })
        return
      }
      if (!addBankAccountState.confirmEmail) {
        notification.warning({ message: 'Please Enter Confirm Email' })
        return
      }

      if (!(addBankAccountState.email == addBankAccountState.confirmEmail)) {
        notification.warning({
          message: 'Please check Email and Confirm Email is not same '
        })
        return
      }

      let resp = await dispatch(addAccountSendOtpFxn(addBankAccountState))
      if (resp && resp.success) {
        setVisibleOtpScreen(true)
      }
    },

    handleSubmitAddAccount: async e => {
      e.preventDefault()
      if (!addBankAccountState.email) {
        notification.warning({ message: 'Please Enter Email ' })
        return
      }
      if (!addBankAccountState.confirmEmail) {
        notification.warning({ message: 'Please Enter Confirm Email' })
        return
      }

      if (!(addBankAccountState.email == addBankAccountState.confirmEmail)) {
        notification.warning({
          message: 'Please check Email and Confirm Email is not same '
        })
        return
      }
      if (!otp) {
        notification.warning({ message: 'Please enter otp' })
        return
      }
      let resp = await dispatch(
        addEmailAccount({ ...addBankAccountState, otp })
      )
      if (resp && resp.success) {
        handleChange(initState)
        events.handleChangeAddBankAccount(initState)
        setVisibleOtpScreen(false)
        setOtp('')
        loadBankList()
      }
    },

    handleSubmit: async e => {
      e.preventDefault()

      try {
        if (!state.email || !state.amount) {
          notification.warning({
            message: 'Please choose email and enter amount'
          })
          return
        }

        const withdrawalObj = {
          email: state.email,
          amount: state.amount
        }

        setWithdrawalData(withdrawalObj)

        const otpResponse = await dispatch(sendWithdrawalOtp(withdrawalObj))

        if (otpResponse && otpResponse.success) {
          setVisibleOtpModal(true)
        } else {
        }
      } catch (error) {
        console.error('Error submitting withdrawal:', error)
      }
    },

    handleSubmitAddWithdrawal: async e => {
      e.preventDefault()

      try {
        if (!otp) {
          notification.warning({ message: 'Please enter otp' })
          return
        }

        const withdrawalObj = {
          ...withdrawalData,
          otp
        }

        const withdrawalResponse = await dispatch(addWithdrawal(withdrawalObj))

        if (withdrawalResponse && withdrawalResponse.success) {
          handleChange({
            email: '',
            amount: null,
            studentObj: '',
            addWithdrawalDrawer: false,
            addBankAccountDrawer: false
          })
          fetchStudentsBalance()
          setVisibleOtpModal(false)
          setOtp('')
          tableRef.current.reload()
        } else {
        }
      } catch (error) {
        console.error('Error submitting OTP or adding withdrawal:', error)
      }
    },
    fetchStudentWithdrawals: async () => {
      // try {
      //   const response = await dispatch(getStudentWithdrawal())
      //   console.log('student withdrawal::::', response.data)
      //   if (response && response.data) {
      //     setStudentWithdrawalData(response.data)
      //     setListData(response.data)
      //     console.log(studentWithdrawalData, "student ddatta----")
      //   } else {
      //     console.error(
      //       'Error fetching student withdrawal data. Response or data is undefined:',
      //       response
      //     )
      //   }
      // } catch (error) {
      //   console.error('Error fetching student withdrawal data:', error)
      // }
    },

    sendWithdrawalOtp: async otpData => {
      try {
        await dispatch(sendWithdrawalOtp(otpData))
      } catch (error) {
        console.error('Error sending withdrawal OTP:', error)
      }
    },

    addWithdrawal: async withdrawalData => {
      try {
        await dispatch(addWithdrawal(withdrawalData))
      } catch (error) {
        console.error('Error adding withdrawal:', error)
      }
    },

    updateWithdrawalStatus: async updateData => {
      try {
        await dispatch(updateWithdrawal(updateData))
      } catch (error) {
        console.error('Error updating withdrawal status:', error)
      }
    }
  }

  const columns = [
    /* {
             title: 'Withdrawal ID',
             key: 'studentId',
             render: val => {
                 return <div>{val.studentId ? val.studentId.name : ''}</div>
             }
         },*/
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      render: val => {
        return <div>{displayDate(val)}</div>
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: value => {
        return <p>{value}</p>
      }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: val => {
        return (
          <div>
            {val == 'Pending' ?
              <label className={'label label-xs label-warning'}>Pending</label> : null}
            {val == 'Cancel' ?
              <label className={'label label-xs label-danger'}>Cancel</label> : null}
            {val == 'Approved' ?
              <label className={'label label-xs label-success-green'}>Approved</label> : null}
          </div>
        )
      }
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      render: item => {
        return (
          <React.Fragment>
            <span>${item}</span>
          </React.Fragment>
        )
      }
    }
  ]
  const getBankTableColumns = () => [
    {
      title: 'Email',
      dataIndex: 'emails',
      key: 'email',
      render: value => {
        return <h7>{value && value.email ? value.email : ''}</h7>
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div>
          {/*  <Button type="link" onClick={() => handleEditBank(record)}>
                        <Icon type={'edit'}/>
                    </Button>*/}

          <Popconfirm
            title={`Are you sure you want to delete the email account .
            `}
            onConfirm={() => handleDeleteBank(record)}
            okText='Yes'
            cancelText='No'
            placement='topRight'>
            <Button type='link' style={{ color: 'red' }}>
              <Icon type={'delete'} />
            </Button>
          </Popconfirm>
        </div>
      )
    }
  ]

  const handleBankRowClick = record => {
    setSelectedBankId(record.accounts._id)
  }

  const handleEditBank = record => {
    setSelectedAccountId(record._id)
    setEditBankData({
      email: record.accounts.email,
      accountNo: record.accounts.accountNo,
      ifscCode: record.accounts.ifscCode,
      swiftCode: record.accounts.swiftCode,
      address: record.accounts.address
    })
    setEditingBankId(record.accounts._id)
  }

  const handleDeleteBank = async record => {
    try {
      let { emails } = record
      const resp = await dispatch(deleteEmailAccount({ emailId: emails._id }))

      loadBankList()
    } catch (error) {
      console.error('Error deleting bank account:', error)
      notification.error({ message: 'Error deleting bank account' })
    }
  }

  return (
    <div>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <Card>
            <div
              style={{
                textAlign: 'right',
                marginBottom: '10px',
                marginRight: '20px'
              }}>
              <h5 style={{ fontSize: '1em', textDecoration: 'underline' }}>
                Current Balance:{' '}
                <span style={{ color: '#0f52ba' }}>${currentBalance}</span>
              </h5>
            </div>
            <div className='table-head d-flex align-items-center'>
              <h5>Your Withdrawals({totalWithdrawals}): </h5>
              <div style={{ marginLeft: 'auto' }}>
                <Tooltip title={'Add Withdrawal'}>
                  <Button
                    style={{ marginRight: 10 }}
                    icon={'plus'}
                    onClick={events.openAddWithdrawalDrawer}>
                    Add Withdrawal
                  </Button>
                </Tooltip>
                <Tooltip title={'Add Email Account'}>
                  <Button
                    style={{ marginRight: 10 }}
                    icon={'plus'}
                    onClick={events.openAddBankAccountDrawer}>
                    Add Email Account
                  </Button>
                </Tooltip>
              </div>
            </div>

            <div className='card-body table-responsive'>
              <TableComp className={'table table-bordered'}
                         columns={columns}
                         apiRequest={apiRequest}
                         pagination={DefaultTablePagination()}
                         ref={tableRef}
              />
            </div>
          </Card>
        </div>
      </div>

      {state.addWithdrawalDrawer ? (
        <Drawer
          visible={state.addWithdrawalDrawer}
          title='Add Withdrawal'
          onClose={events.closeAddWithdrawalDrawer}
          width={'45%'}>
          <div className='card unizportal'>
            <Form
              className={'vertical-form'}
              autoComplete='off'
              onSubmit={events.handleSubmit}>
              <Row gutter={24} className={'rowWrap'}>
                <Col span={24}>
                  <Form.Item>
                    <InputBox title={'Email '}>
                      <select
                        value={state.bank}
                        className={'form-control'}
                        name='email'
                        onChange={({ target }) => {
                          updateState({ email: target.value })
                        }}
                        placeholder='Select Email'>
                        <option value={''}>Choose Email</option>
                        {bankList && bankList.length
                          ? bankList.map(item => {
                            let { emails } = item
                            return (
                              <option key={emails._id} value={emails.email}>
                                {emails.email}
                              </option>
                            )
                          })
                          : null}
                      </select>
                    </InputBox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <InputBox title={'Balance'}>
                      <Input
                        disabled={true}
                        value={currentBalance}
                        className={'form-control'}
                        name='email'
                        placeholder='Total Amount'
                      />
                    </InputBox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <InputBox title={'Amount'}>
                      <Input
                        type={'number'}
                        value={state.amount}
                        className={'form-control'}
                        name='amount'
                        onChange={({ target }) => {
                          updateState({ amount: target.value })
                        }}
                        placeholder='Total Amount'
                      />
                    </InputBox>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button type='primary' className='btn' htmlType='submit'>
                  SAVE
                </Button>
                <Modal
                  visible={visibleOtpModal}
                  closable={true}
                  title={'Enter OTP'}
                  footer={false}
                  onCancel={events.hideModal}>
                  <div style={{ textAlign: 'center', marginBottom: 10 }}>
                    <h6>
                      A text message with a 4-digit verification code has been
                      sent to your registered email.
                    </h6>
                    <InputBox title={'Enter OTP'}>
                      <input
                        className={'form-control mt10'}
                        type={'number'}
                        minLength={4}
                        maxLength={4}
                        value={otp}
                        onChange={({ target }) => {
                          setOtp(target.value)
                        }}
                      />
                    </InputBox>
                    <Button
                      className={'btn btn-success mt10'}
                      onClick={events.handleSubmitAddWithdrawal}>
                      Submit OTP
                    </Button>
                  </div>
                </Modal>
              </Form.Item>
            </Form>
          </div>
        </Drawer>
      ) : null}

      {addBankVisible ? (
        <Drawer
          visible={addBankVisible}
          title={'Add Email Account'}
          onClose={events.closeAddBankAccountDrawer}
          width={'60%'}>
          <div className='card unizportal'>
            <div className='card-body table-responsive'>


              <Form
                className={'vertical-form'}
                autoComplete='off'
                onSubmit={events.handleSubmitBank}>
                <Row gutter={16}>
                  <Col span={12}>
                    {/* Email */}
                    <Form.Item>
                      <InputBox title={'Email'}>
                        <Input
                          onChange={({ target }) => {
                            events.handleChangeAddBankAccount({
                              email: target.value
                            })
                          }}
                          value={addBankAccountState.email}
                          className={'form-control'}
                          name='email'
                          placeholder='Please enter Email'
                        />
                      </InputBox>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    {/* Confirm Email */}
                    <Form.Item>
                      <InputBox title={'Confirm Email'}>
                        <Input
                          onChange={({ target }) => {
                            events.handleChangeAddBankAccount({
                              confirmEmail: target.value
                            })
                          }}
                          value={addBankAccountState.confirmEmail}
                          className={'form-control'}
                          name='confirmEmail'
                          placeholder='Please confirm Email'
                        />
                      </InputBox>
                    </Form.Item>
                  </Col>
                </Row>

                <Button type='primary' htmlType='submit' className={'btn'}>
                  Add
                </Button>
                <Modal
                  visible={visibleOtpScreen}
                  closable={false}
                  title={'Enter OTP'}
                  footer={false}>
                  <div style={{ textAlign: 'center', marginBottom: 10 }}>
                    <h6>
                      {' '}
                      A text message with a 4-digit verification code has been
                      sent to your registered email.
                    </h6>
                    <InputBox title={'Enter OTP'}>
                      <input
                        className={'form-control mt10'}
                        type={'password'}
                        minLength={4}
                        maxLength={4}
                        value={otp}
                        onChange={({ target }) => {
                          setOtp(target.value)
                        }}
                      />
                    </InputBox>
                    <Button
                      className={'btn btn-success mt10'}
                      onClick={events.handleSubmitAddAccount}>
                      Submit OTP
                    </Button>
                  </div>
                </Modal>
              </Form>

              <div className={'mt40'}>
                <Table
                  dataSource={bankTableData}
                  bordered={true}
                  columns={getBankTableColumns()}
                  rowKey='accounts._id'
                  pagination={false}
                  onRow={record => ({
                    onClick: () => handleBankRowClick(record)
                  })}
                />
              </div>
            </div>
          </div>
        </Drawer>
      ) : null}


    </div>
  )
}

export default StudentWithdrawals
