import React, { useState, useEffect, useRef } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Button,
  Card, Icon,
  Popconfirm,
  Tooltip
} from 'antd'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { notification } from 'antd'
import { getUrlPushWrapper } from '../../../routes'
import { newsEnquiryList, deleteNewsEnquiry } from '../actions/news'
import { DefaultTablePagination, displayDate } from '../../../components/_utils/appUtils'


const RenderComment = (props) => {
  let { item } = props
  let [hideV, setHideV] = useState(true)
  return (
    item && <div>
      <div className={hideV ? 'newsContentDotsReplace' : ''}>
        {item && item.content ? item.content : ''}
      </div>
      {item && item.content.length && item.content.length > 150 ? <div className={'alignRight'}>
        {hideV ? <a onClick={() => {
          setHideV(false)
        }}>Show More</a> : <a onClick={() => {
          setHideV(true)
        }}>Show Less</a>}
      </div> : ''}

    </div>
  )
}

const ListAllNews = () => {
  const { totalNews } = useSelector((state) => ({
    totalNews: state.NewsReducers.totalNews
  }))
  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let resp = dispatch(newsEnquiryList({ ...params }))
      resolve(resp)
    })
  }
  const [itemNo, setItemNo] = useState(1)
  const confirmDelete = async (id) => {
    let resp = await dispatch(deleteNewsEnquiry(id))
    if (resp && resp.success) {
      tableRef.current.reload()
    }
  }

  const columns = [

    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (value, item, index) => (itemNo - 1) * 10 + index + 1
    },
    {
      title: 'News',
      dataIndex: 'newsId',
      width: 350,
      key: 'newsId',
      render: (item, record) => {
        return (
          <div style={{ width: 350 }}>
            {item && item.content ? <RenderComment item={item}/> : null}
          </div>
        )
      }
    },

    {
      title: 'User',
      key: 'userId',
      width: 150,
      dataIndex: 'userId',
      render: (item) => {
        return (
          <div>
            {item && item.name ? item.name : null}
            {item && item.email ? <div>({item.email})</div> : null}
          </div>
        )
      }
    },
    {
      title: 'Query',
      key: 'comment',
      width: 150,
      dataIndex: 'comment'
    },
    {
      title: 'Date',
      key: 'createdAt',
      width: 150,
      dataIndex: 'createdAt',
      render: (item) => {
        return displayDate(item)
      }
    },
    {
      key: 'actions',
      title: 'Actions',
      width: 80,
      render: (text, record) => {
        return (
          <div>
            <Tooltip title="Delete">
              <Popconfirm placement="topLeft" title={'Are you want to delete enquiry?'}
                          onConfirm={() => confirmDelete(record._id)}
                          okText="Yes" cancelText="No">
                <button
                  className={'btn'}
                  style={{ marginRight: 6 }}>
                  <Icon type={'delete'}/>
                </button>
              </Popconfirm>


            </Tooltip>

          </div>
        )

      }
    }
  ]


  return (
    <div className="row  mt-4">
      <div className="col-lg-12">
        <div className="card">
          <div className="table-head d-flex align-items-center">
            <h5>All News Enquiry : {totalNews || 0}</h5>
            <div className="search-box-table">
              {/* <input className="form-control form-control" type="search" placeholder="Search City"/>
              <img src="/dist/img/search.png" alt=""/>*/}
            </div>

            <div className="sort-box-table">
              {/*<select name="Sort By" id="">
                <option>Sort By</option>
                <option value="1">Date</option>
                <option value="2">Time</option>
              </select>*/}
            </div>
          </div>
          <div className="card-body table-responsive">
            <TableComp columns={columns}
                       apiRequest={apiRequest}
                       pagination={DefaultTablePagination()}
                       ref={tableRef}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListAllNews
