import React, {useEffect, useState, useRef} from "react"
import {getSingleAppFcmtNotes} from "../actions/fcmtAction";
import {Drawer, Card} from "antd";
import {useDispatch} from 'react-redux'
import {displayDate, longDisplayDate} from "../../../components/_utils/appUtils";
import {hidePageLoad, showPageLoad} from "../../../modules/actions";

const FcmtNoteList = (props) => {
    const messagesEndRef = useRef(null)

    let {applicationId, studentId, visible, onClose, user, universityName} = props;
    let dispatch = useDispatch()
    const [noteList, setNoteList] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(10);
    const [totalNote, setTotalNote] = useState(0);
    const [loadFirstTime, setLoadFirstTime] = useState(false);
    const scrollToBottom = () => {
        if (messagesEndRef.current && messagesEndRef.current.scrollIntoView) {
            messagesEndRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
        }
    }
  /*  useEffect(() => {
        scrollToBottom()
    }, [])*/

    useEffect(() => {
        loadFcmtNote()
    }, [])
    const loadFcmtNote = async () => {
        let obj = {
            page: page,
            count: count,
            applicationId: applicationId,
            studentId: studentId,
        }
        let {data, total} = await getSingleAppFcmtNotes(obj)
        setPage(page + 1);
        setTotalNote(total)
        setLoadFirstTime(true)
        setNoteList(data)
        // scrollToBottom()

    }

    const loadMoreData = async () => {
        dispatch(showPageLoad())
        let obj = {
            page: page,
            count: count,
            applicationId: applicationId,
            studentId: studentId,
        }
        let {data} = await getSingleAppFcmtNotes(obj)
        if (data && data.length) {
            setNoteList([...data, ...noteList])
            setPage(page + 1);
            dispatch(hidePageLoad())
        } else {
            dispatch(hidePageLoad())
        }
    }
    return (
        <>
            <Drawer width={'75%'}
                    title={`${universityName} Note List`}
                    placement="right"
                    visible={visible}
                    onClose={() => {
                        onClose()
                    }}>
                <div className="student-tab">
                    {/*   {totalNote > noteList.length ? <div className={'loadMoreBoxDiv'} onClick={loadMoreData}>
                        <a>Load More</a>
                    </div> : ""}*/}
                    <Card bordered={false} className={'noteRow'}>
                        <div className={'noteContainer'}>
                            {noteList && noteList.length ? noteList.map((item, index) => {
                                let {createdByUser: {userId}} = item;
                                return (
                                    <div key={index}
                                         className={`noteColumn ${user._id == userId._id ? "noteRight" : ""}`}>
                                        <div className={`box noteBox`}>
                                            <div className="inner card">
                                                <div className={'dateBox'}>
                                                    <span className={'date'}>{longDisplayDate(item.createdAt)}</span>
                                                </div>
                                                <div className={'noteTitle'}>
                                                    {item.note}
                                                </div>
                                                <div className={'userBox'}>
                                                    <span
                                                        className={'user'}>
                                                        {userId && userId.name ? `${userId.name}(${userId.email})` : ""}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : ""}

                            <div ref={messagesEndRef}/>
                        </div>
                    </Card>
                </div>
            </Drawer>
        </>
    )
}
export default FcmtNoteList
