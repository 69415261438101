import React, { useEffect, useState } from 'react'
import { cornerStoneVisaApprovedAppsFxn } from '../actions/cornerStone'
import { newFormatDisplayDate } from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import { Tooltip, Icon } from 'antd'
import { TableComp } from 'sz-react-utils-lite'
import UploadScreenshots from '../components/cornerStoneScreenshotDrawer'
import CornerStoneStudent from '../components/cornerStoneStudent'

const VisaApprovedAppsList = () => {
  const [studentData, setStudentData] = useState({})
  const [totalApplications, setTotalApplications] = useState(0)
  let [visibleChangeNameDrawer, setVisibleChangeNameDrawer] = useState(false)
  const [uploadScreenshots, setUploadScreenshots] = useState({
    visible: false,
    applicationId: '',
    studentId: ''
  })
  const [studentDetails, setStudentDetails] = useState({
    visible: false,
    studentDetails: ''
  })
  let user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null
  const dispatch = useDispatch()
  const apiRequest = async () => {
    return new Promise(async resolve => {

      let resp = await dispatch(cornerStoneVisaApprovedAppsFxn())
      setTotalApplications(resp.total)
      resolve(resp)
    })
  }


  useEffect(() => {
    apiRequest()
  }, [])

  const events = {
    changeNameOpen: async record => {
      setStudentData(record)
      setVisibleChangeNameDrawer(true)
    },
    showScreenshotsDrawer: (applicationId, studentId) => {
      setUploadScreenshots({
        visible: true,
        applicationId: applicationId,
        studentId: studentId
      })
    },
    closeScreenshotsDrawer: () => {
      setUploadScreenshots(({
        visible: false,
        applicationId: '',
        studentId: ''
      }))
    },
    showStudentDrawer: (record) => {
      setStudentDetails({
        visible: true,
        studentDetails: record
      })
    },
    closeStudentDrawer: () => {
      setStudentDetails(({
        visible: false,
        studentDetails: ''
      }))
    }
  }
  const columns = [
    {
      title: 'App#',
      key: 'applications.id',
      dataIndex: 'applications.id',
      // searchTextName: 'applications.id',
      width: 60,
      render: item => {
        return <div style={{ width: 60 }}>{item}</div>
      }
    },
    {
      title: 'Student ID',
      key: 'applications.studentIdNo',
      dataIndex: 'applications.studentIdNo',
      width: 80,
      render: item => {
        return <div style={{ width: 80 }}>{item}</div>
      }
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 120,
      render: (item, record) => {
        return (
          <div style={{ width: 120 }}>
            {item}
            <br />
            {newFormatDisplayDate(record.dateOfBirth)}
          </div>
        )
      }
    },
    {
      title: 'Date Of Birth',
      key: 'dateOfBirth',
      dataIndex: 'dateOfBirth',
      width: 150,
      render: item => {
        return <div style={{ width: 150 }}>{newFormatDisplayDate(item)}</div>
      }
    },
    {
      title: 'Program',
      key: 'applications.courseName',
      dataIndex: 'applications.courseName',
      width: 150,
      render: item => {
        return <div style={{ width: 150 }}>{item}</div>
      }
    },
    {
      title: 'Intake',
      key: 'applications.intake',
      dataIndex: 'applications.intake',
      width: 80,
      render: (item, record) => {
        return (
          <div style={{ width: 80 }}>
            {item && item.month && item.year
              ? `${item.month}, ${item.year}`
              : ''}
          </div>
        )
      }
    },
    {
      title: 'Campus',
      key: 'applications.campusId',
      dataIndex: 'applications.campusId',
      width: 85,
      render: (item, record) => {
        return (
          <div style={{ width: 85 }}>
            {item && item.campusName ? item.campusName : ''}
          </div>
        )
      }
    },

    {
      title: 'Family / Given Name',
      key: 'applications.familyName',
      dataIndex: 'applications.familyName',
      width: 100,
      render: (item, record) => {
        return (
          <div style={{ width: 100 }}>
            {item && item ? (
              <p>
                Family Name : <p style={{ fontWeight: 'bold' }}>{item}</p>
              </p>
            ) : (
              ''
            )}
            {record && record.applications && record.applications.givenName ? (
              <p>
                Given Name :{' '}
                <p style={{ fontWeight: 'bold' }}>
                  {record.applications.givenName}
                </p>
              </p>
            ) : (
              ''
            )}
            {record &&
            record.applications &&
            record.applications.loaRequested === 'Approved' ? (
              <Icon
                type={'edit'}
                onClick={() => events.changeNameOpen(record)}
              />
            ) : null}
          </div>
        )
      }
    },
    {
      title: 'Conditional Offer Letter',
      key: 'applications.offerReceiveLetter',
      dataIndex: 'applications.offerReceiveLetter',
      width: 100
      // render: (val, record) => {
      //   return (
      //     <div style={{ width: 100 }} className={'fcmtDiv'}>
      //       <OfferActionComponent file={val} record={record} events={events} agentList={agentList} />
      //     </div>
      //   )
      // }
    },


    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 100,
      render: (val, record) => {
        return (
          <div>

            <Tooltip title={'View Student'}>
              <a onClick={() => events.showStudentDrawer(record)}>
                <Icon type={'eye'} style={{ fontSize: '19px', marginLeft: '10px' }} />
              </a>
            </Tooltip>
          </div>

        )
      }
    }
  ]

  return (
    <React.Fragment>
      <div>
        <div className='row mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div
                className='table-head d-flex align-items-center all-student-search'
                style={{ height: 60 }}>

                <h6 className={'w-50'}>Cornerstone Visa Approved Applications (Total : {totalApplications})</h6>
                <div className='search-box-table'>
                </div>
                <div className='sort-box-table mark-btn'>

                </div>
              </div>
              <div className='card-body table-responsive'>


                <TableComp
                  columns={columns}
                  rowKey={(item, index) => {
                    return index
                  }}
                  apiRequest={apiRequest}
                  pagination={{
                    position: 'top',
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    defaultPageSize: 10
                    // current: filters.page ? filters.page : 1
                  }}
                  // ref={tableAppRef}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {uploadScreenshots.visible ?
        <UploadScreenshots
          onClose={() => events.closeScreenshotsDrawer()}

          applicationId={uploadScreenshots.applicationId}
          studentId={uploadScreenshots.studentId}
          visible={uploadScreenshots.visible}
        />

        : null}
      {studentDetails.visible ?
        <CornerStoneStudent
          onClose={() => events.closeStudentDrawer()}
          studentDetails={studentDetails.studentDetails}
          visible={studentDetails.visible}
          user={user}
        />

        : null}
    </React.Fragment>
  )
}

export default VisaApprovedAppsList
