import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

const Footer = () => {
  let dispatch = useDispatch()
  const goToPage = (link) => {
    dispatch(push(link))
    // window.location.href = link
  }
  return (
    <footer className='main-footer m-0'>
      <div style={{ flex: 1 }}>
        <div className={'row'}>
          <div className='col-lg-6'>
            &copy; Copyright UnizPortal 2021
          </div>
          <div className='col-lg-6'>
            <ul className={'linkUl'}>
              <li>
                <a onClick={() => goToPage('/privacy-policy')}>Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
