import React, {useRef, useState} from "react"
import {Card, Drawer, Icon, Popconfirm, Tooltip} from "antd";
import TableComp from "../../../components/_utils/table";
import {courseStructureListFxn, deleteCourseStructureFxn} from "../actions/courseAction";
import {useDispatch} from "react-redux";
import AddCourseStructure from "./addCourseStructure"
import renderHTML from "react-render-html";

const SingleStructure = (props) => {
    let {data} = props;
    let [hideV, setHideV] = useState(true);

    return (
        <>
            <div className={hideV ? 'structureDotsReplace' : ''} style={{width: "100%", textWrap: "wrap"}}>
                {data ? renderHTML(data) : ''}
            </div>
            {data && data.length > 50 ? (
                <div className={'alignRight'}>
                    {hideV ? (
                        <a className={'moreBtn'}
                           onClick={() => {
                               setHideV(false)
                           }}>
                            Show More
                        </a>
                    ) : (
                        <a className={'moreBtn'}
                           onClick={() => {
                               setHideV(true)
                           }}>
                            Show Less
                        </a>
                    )}
                </div>
            ) : (
                ''
            )}
        </>
    )
}


const CourseStructure = (props) => {
    let tableRef = useRef()
    let dispatch = useDispatch()
    let {courseId, visible, onClose, courseName} = props;
    let [total, setTotal] = useState(0);
    let [addVisible, setAddVisible] = useState(false)
    let apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params = {...params}
            let resp = await dispatch(courseStructureListFxn({
                ...params, courseId,
                regExFilters: ['content']
            }))
            setTotal(resp.total)
            resolve(resp)
        })
    }

    const deleteCourseStructure = async (courseStructureId) => {
        let resp = await dispatch(deleteCourseStructureFxn({courseStructureId}));
        if (resp && resp.success) {
            tableRef.current.reload()
        }
    }


    let columns = [
        {
            title: "#",
            key: "_id",
            dataIndex: "_id",
            render: (item, record, index) => {
                return index + 1;
            }
        },
        {
            title: "Content",
            key: "content",
            dataIndex: "content",
            render: (item) => {
                return (
                    item ? <SingleStructure data={item}/> : null
                )
            }
        },
        {
            title: "Action",
            key: "action",
            dataIndex: "action",
            render: (item, record) => {
                return (
                    <>
                        <Tooltip title='Delete'>
                            <Popconfirm title={'Are your sure, you want to delete this structure?'}
                                        onConfirm={() => {
                                            deleteCourseStructure(record._id)
                                        }}
                                        okText='Yes' cancelText='No'>
                                <button
                                    className={'btn'}
                                    style={{marginRight: 6}}>
                                    <Icon type={'delete'}/>
                                </button>
                            </Popconfirm>
                        </Tooltip>

                    </>
                )
            }
        }
    ]

    return (
        <>
            <Drawer
                visible={visible} onClose={onClose}
                title={(
                    <>
                        {courseName} structure (Total : {total})
                        <a className={'btn btn-primary btn-sm ml20'} onClick={() => {
                            setAddVisible(true)
                        }}>Add Structure</a>
                    </>
                )}
                width={"80%"}>
                <div className="form-box mt-4">
                    <div className="card unizportal">
                        <TableComp
                            bordered={true}
                            apiRequest={apiRequest}
                            ref={tableRef}
                            columns={columns}
                            pagination={{
                                defaultPageSize: 20,
                                pageSizeOptions: ['20', '50', '100'],
                                position: "bottom"
                            }}/>
                    </div>
                </div>
            </Drawer>

            {addVisible ?
                <AddCourseStructure
                    courseId={courseId}
                    onClose={() => {
                        setAddVisible(false)
                    }}
                    onSubmit={() => {
                        setAddVisible(false)
                        tableRef.current.reload()
                    }}
                    visible={addVisible}/>
                : null}

        </>
    )
}
export default CourseStructure
