const initialState = {
    public_key: null,
    client_secret: null,
    session_token: null,
    room_id: null,
    booking_period_id: null,
    operator_id: null,
    amount: null,
    allowSetPublicKey: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SET_START_PAYMENT_RESPONSE':
            return {
                ...state,
                public_key: action.public_key,
                client_secret: action.client_secret,
                session_token: action.session_token,
                room_id: action.roomId,
                booking_period_id: action.roomId,
                operator_id: action.roomId,
                amount: action.amount
            }

        case 'REMOVE_START_PAYMENT_RESPONSE':
            return {
                ...state,
                public_key: null,
                client_secret: null,
                session_token: null,
                room_id: null,
                booking_period_id: null,
                operator_id: null,
                amount: null,
            }

        case 'SET_PUBLIC_KEY':
            return {
                ...state,
                allowSetPublicKey: action.value
            }

        default:
            return state
    }
}
