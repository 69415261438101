import {apiUrl} from "../../../settings"

export const indianCityUrl = () => {
    return apiUrl + '/indianCity'
}


export const singleIndianCityUrl = (id) => {
    return apiUrl + '/indianCity/' + id
}
