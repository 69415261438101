export default (
  state = {
    loader: false,
    news: {},
    allNews: [],
    totalNews: 0
  },
  action
) => {
  switch (action.type) {
    case 'NEWS_SHOW_LOADER':
      return { ...state, loader: true }
    case 'NEWS_HIDE_LOADER':
      return { ...state, loader: false }
    case 'LIST_NEWS':
      return { ...state, news: action.payload.data }
    case 'ADD_NEWS':
      return { ...state, addedNews: action.payload }
    case 'LIST_ALL_NEWS':
      return { ...state, allCities: action.payload.data, totalNews: action.payload.total }
    case 'EMPTY_NEWS_LIST':
      return { ...state, allCities: [], totalNews: 0 }
    default:
      return state
  }
}
  