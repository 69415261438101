import React, {useEffect, useState} from "react"
import {Button, Card, Col, Drawer, Form, Icon, Input, notification, Row, Tooltip} from "antd";
import {countryIds, InputBox} from "../../../components/_utils/appUtils";
import {useDispatch} from "react-redux";
import GetEachFormFields from "../../../components/_utils/appFormUtils";
import {updateOshcInsuranceFxn} from "../actions/chooseStudent";

const OshcDrawerComponent = (props) => {
    let dispatch = useDispatch()
    let {visible, studentData, onClose, form: {getFieldDecorator, setFieldsValue}} = props
    let {applications, _id: studentId} = studentData;
    let [receipt, setReceipt] = useState(null)
    let [oldReceipt, setOldReceipt] = useState(null)
    let chooseDocument = (e) => {
        let {files} = e.target;
        if (files && files.length) {
            setReceipt(files[0])
        } else {
            setReceipt(null)
        }
    }
    const handleSubmit = (e) => {
        const {form} = props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let obj = {
                    studentId,
                    applicationId: applications._id,
                    ...valData
                }
                let fd = new FormData()
                fd.append('obj', JSON.stringify(obj))
                if (receipt && receipt.name) {
                    fd.append('receipt', receipt)
                }
                let data = await dispatch(updateOshcInsuranceFxn(fd))
                if (data && !data.error) {
                    form.resetFields()
                    onClose()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }
    useEffect(() => {
        setValues()
    }, [])
    const setValues = () => {
        if (applications && applications.oshcInsuranceObj) {
            let {oshcInsuranceObj} = applications;
            setTimeout(() => {
                setFieldsValue({
                    amount: oshcInsuranceObj.amount,
                    details: oshcInsuranceObj.details,
                    source: oshcInsuranceObj.source
                })
                if (oshcInsuranceObj && oshcInsuranceObj.receipt && oshcInsuranceObj.receipt.url) {
                    setOldReceipt(oshcInsuranceObj.receipt)
                }
            }, 500)
        }
    }

    let inputTypes = {
        fields: [
            {
                key: 'logo',
                customField: (
                    <InputBox title={'*TT/ Receipt'} className={'logoCls rowFlex'}>
                        <Input type={'file'} className={'form-control'} name={'receipt'}
                               id={'receipt'} required={true}
                               onChange={(e) => {
                                   chooseDocument(e)
                               }}/>
                        {oldReceipt && oldReceipt.url ?
                            <Tooltip title={oldReceipt.name}> <a className={'linkBtn'} target={'_blank'}
                                                                 href={oldReceipt.url}>
                                <Icon type={'eye'}></Icon>
                            </a> </Tooltip> : ''}
                    </InputBox>
                )
            },
            {key: 'source', label: 'Source', required: true, placeholder: 'Enter Source'},
            {key: 'amount', label: 'Amount', required: true, type: "number", placeholder: 'Enter Amount'},
            {key: 'details', label: 'Details', required: true, type: "textarea", rows: 5, placeholder: 'Enter Details'}
        ]
    }

    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }
    return (
        <Drawer
            title={'OSHC Insurance'}
            visible={visible}
            onClose={onClose}
            width={'40%'}
            placement="right">
            <Card bordered={false}>
                <Form onSubmit={handleSubmit}>
                    <Row gutter={24}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                item.customField ?
                                    <Col span={24} key={key}>
                                        <Form.Item>
                                            {item.customField}
                                        </Form.Item>
                                    </Col> :
                                    <Col span={24} key={key}>
                                        <GetEachFormFields
                                            item={item}
                                            getFieldDecorator={getFieldDecorator}
                                            formItemLayout={formItemLayout}/>
                                    </Col>)
                        })}
                        <Col span={24}>
                            <Form.Item className={'mt20'}>
                                <Button type='primary' htmlType='submit' className={'btn'}>
                                    SUBMIT
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Drawer>
    )
}

const EditOshcDrawerComponent = (props) => {
    let dispatch = useDispatch()
    let {visible, studentData, onClose, form: {getFieldDecorator}} = props
    let {applications, _id: studentId} = studentData;
    let [receipt, setReceipt] = useState(null)
    let chooseDocument = (e) => {
        let {files} = e.target;
        if (files && files.length) {
            setReceipt(files[0])
        } else {
            setReceipt(null)
        }
    }

    const handleSubmit = (e) => {
        const {form} = props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let obj = {
                    studentId,
                    applicationId: applications._id,
                    ...valData
                }
                let fd = new FormData()
                fd.append('obj', JSON.stringify(obj))
                if (receipt && receipt.name) {
                    fd.append('receipt', receipt)
                }
                let data = await dispatch(updateOshcInsuranceFxn(fd))
                if (data && !data.error) {
                    form.resetFields()
                    onClose()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }

    let inputTypes = {
        fields: [
            {
                key: 'logo',
                customField: (
                    <InputBox title={'*TT/ Receipt'} className={'logoCls'}>
                        <Input type={'file'} className={'form-control'} name={'receipt'}
                               id={'receipt'} required={true}
                               onChange={(e) => {
                                   chooseDocument(e)
                               }}/>
                    </InputBox>
                )
            },
            {key: 'source', label: 'Source', required: true},
            {key: 'amount', label: 'Amount', required: true, type: "number"},
            {key: 'details', label: 'Details', required: true, type: "textarea", rows: 5}
        ]
    }

    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }
    return (
        <Drawer
            title={'OSHC Insurance'}
            visible={visible}
            onClose={onClose}
            width={'40%'}
            placement="right">
            <Card bordered={false}>
                <Form onSubmit={handleSubmit}>
                    <Row gutter={24}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                item.customField ?
                                    <Col span={24} key={key}>
                                        <Form.Item>
                                            {item.customField}
                                        </Form.Item>
                                    </Col> :
                                    <Col span={24} key={key}>
                                        <GetEachFormFields
                                            item={item}
                                            getFieldDecorator={getFieldDecorator}
                                            formItemLayout={formItemLayout}/>
                                    </Col>)
                        })}
                        <Col span={24}>
                            <Form.Item className={'mt20'}>
                                <Button type='primary' htmlType='submit' className={'btn'}>
                                    SUBMIT
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Drawer>
    )
}

const OshcDrawer = Form.create()(OshcDrawerComponent);
const OshcEditDrawer = Form.create()(EditOshcDrawerComponent);

const ShowOshcDrawer = (props) => {
    let {children, record} = props;
    let {applications} = record;
    return (
        applications.status == 'Visa Approved' && applications.universityCountry && applications.universityCountry._id == countryIds.australia ? <>
            {children}
        </> : ""
    )
}

const OshcFieldLabel = (props) => {
    let {applications, countryId} = props;
    let {oshcInsuranceObj} = applications;
    return (
        countryId == countryIds.australia && applications && oshcInsuranceObj && oshcInsuranceObj.amount ?
            <div className={'mt10 oshcBox'}>
                <img src='../dist/img/healthcare.png' alt=''/> OSHC
            </div>
            : ""
    )
}

export {OshcDrawer, ShowOshcDrawer, OshcEditDrawer, OshcFieldLabel} ;
