import React, { useEffect, useRef, useState } from 'react'
import {
  Select,
  Button,
  Tooltip,
  Avatar,
  Icon,
  Popconfirm,
  Row,
  Col,
  Tag,
  notification, Form
} from 'antd'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getSelectedCountryApplicationsApi, exportAllApplicationsList } from '../../applications/actions/chooseStudent'
import {
  statusColors, filterOption,
  newFormatDisplayDate, defaultLogoList, longDisplayDate,
  ukApplicationSteps, canadaApplicationSteps, australiaApplicationSteps, Intakes, usaApplicationSteps
} from '../../../components/_utils/appUtils'
import { getPushPathWrapper, getUrlPushWrapper } from '../../../routes'
import { TableComp } from 'sz-react-utils-lite'
import SelectPendency from '../../applications/views/selectPendency'
import lodash from 'lodash'
import {
  AllowComponentRightsWise,
  AllowComponentUserWise,
  CheckBranchUserRight,
  CheckBranchUserFxn,
  ShowEnrollmentComponent,
  CheckManagerWiseRight
} from '../../WebComponent/allowComponentRightsWise'
import TransferApplicationDrawer from '../../applications/views/transferApplication'
import { getPendingPendency } from '../../../components/_utils/utils'
import ApplyCourse from '../../student/views/applyForApplicationDrawer'
import { CheckUserRight } from '../../dashboard/views/DashboardUserWise'
import {
  applicationCloseDirect,
  reCalculateCommission, updateApplicationTuitionFees
} from '../../student/actions/student'
import moment from 'moment'
import StudentOtherApp from '../../applications/views/StudentOtherApplication'
import { listAllUniversities } from '../../university/actions/university'
import { getBranchManagerAgentList, listAllUsers } from '../../users/actions/user'
import ReActivateApplication from '../../applications/drawers/reActivateApplicationDrawer'
import ChangeUniversityComponent from '../../applications/drawers/changeUniversity'
import { showEnrollmentAction, ConditionOptions } from '../../../components/_utils/appUtils'
import Enrollment from '../../applications/drawers/enrollment'
import { listAllCountries } from '../../countries/actions/countries'
import AddNote from '../../applications/drawers/createNoteComponent'
import { AgentBranchManagerComponent } from '../../../components/_utils/AgentBranchManagerComponent'
import DraftButtonComponent from '../../applications/views/draftButtonComponent'
import { getReportApplicationList } from '../actions'

const managerViewRight = ['admin', 'branchUser']

const { Option } = Select
const initialState = {
  applicationList: [],
  applicationObj: {}
}
const commissionObj = {
  commissionAmount: 0,
  tuitionFee: 0,
  studentId: '',
  applicationId: ''
}
const RenderComment = (props) => {
  let { item } = props
  let [hideV, setHideV] = useState(true)
  return (
    item && <div>
      {item.addedByUserId && item.addedByUserId.name ?
        <div className={'alignRight colorPrimary font12'}>
          (Added By : {item.addedByUserId.name} )
        </div> : null}
      <div className={hideV ? 'appCommentDotsReplace' : ''}>
        {item && item.text ? item.text : ''}
      </div>
      {item && item.text.length && item.text.length > 50 ? <div className={'alignRight'}>
        {hideV ? <a onClick={() => {
          setHideV(false)
        }}>Show More</a> : <a onClick={() => {
          setHideV(true)
        }}>Show Less</a>}
      </div> : ''}

    </div>
  )
}

const ReportingStudentApplication = (props) => {
  let { onClose, studentObj = {}, reloadTable, currentUserRights } = props
  const [state, setState] = useState(initialState)
  const [transferAppVisible, setTransferAppVisible] = useState(false)
  const [studentData, setStudentData] = useState({})
  const [visibleAddCourseDrawer, setVisibleAddCourseDrawer] = useState(false)
  const [addPendencyDrawer, setAddPendencyDrawer] = useState(false)
  const [name, setName] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [allAgent, setAllAgent] = useState([])
  const [agentId, setAgentId] = useState('')
  const [universityList, setUniversityList] = useState([])
  const [appUniversityId, setAppUniversityId] = useState('')
  const [visibleOtherAppDrawer, setVisibleOtherAppDrawer] = useState(false)
  const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
  const [totalApplication, setTotalApplication] = useState(0)
  const [visibleFeesModal, setVisibleFeesModal] = useState(false)
  const [feesObj, setFeesObj] = useState(commissionObj)
  const [status, setStatus] = useState('')
  const [courseId, setCourseId] = useState('')
  const [countryId, setCountryId] = useState('')
  const [countryList, setCountryList] = useState([])
  const [branchUserList, setBranchUserList] = useState([])
  const [allBranchManagerList, setAllBranchManagerList] = useState([])
  const [branchUserId, setBranchUserId] = useState('')
  const [branchManagerId, setBranchManagerId] = useState('')
  const [studentManagerId, setStudentManagerId] = useState('')
  const [draftApplication, setDraftApplication] = useState('')
  let { applicationObj } = state
  let [tableSearch, setTableSearch] = useState(false)
  let [filters, setFilters] = useState({})
  let [statusList, setStatusList] = useState([])
  let [currentStatus, setCurrentStatus] = useState('')
  let [currentNote, setCurrentNote] = useState('')
  let [intake, setIntake] = useState('')
  let [branchManagerAgentList, setBranchManagerAgentList] = useState([])
  let viewOnlyRight = CheckBranchUserFxn() // check branch user right
  let [selectedApplication, setSelectedApplication] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })
  let [enrollmentObj, setEnrollmentObj] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })
  let [changeUniversity, setChangeUniversity] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })
  let [branchManagerList, setBranchManagerList] = useState([])
  let [studentManagerList, setStudentManagerList] = useState([])
  let [userId, setUserId] = useState('')

  const dispatch = useDispatch()
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  const tableAppRef = useRef()

  let apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let resp = await getParamsForApplicationList()
      console.log('params params', resp)
      params = { ...params, ...resp }

      if (!params.countryId) {
        params.countryId = ''
      }
      if (!params.results) {
        params.results = 50
        params.count = 50
      }
      if (tableSearch) {
        params.page = 1
      }
      params.type = 'all'
      try {
        setFilters(params)
        let { data } = await dispatch(getReportApplicationList({ ...params, regExFilters: ['name'] }))
        console.log('resp', data)
        setTotalApplication(data.total)
        setTableSearch(false)
        resolve(data)
      } catch (e) {
      }

    })
  }

  let exportExcel = async () => {
    let params = {}
    let resp = await getParamsForApplicationList()
    params = { ...params, ...resp }
    if (name) {
      params.name = name
    }
    if (dateOfBirth) {
      params.dateOfBirth = moment(dateOfBirth)
    }
    params.results = totalApplication
    params.count = totalApplication
    let { success, filePath, message } = await dispatch(exportAllApplicationsList(params))
    if (success) {
      notification.success({ message: message })
      // window.open(`${apiUrl}${filePath}`, 'Download')
    } else {
      notification.error({ message: message })
    }
  }

  useEffect(() => {
    loadAgentData()
    loadCountryList()
    setFieldByParams()
    loadBranchManagerAgentList()
    loadBranchManager()
    loadStudentManager()
    loadAllBranchManager()
    // LoadUserWiseUniversity()
    if (currentUserRights.userType == 'branchManager') {
      setCountryId(currentUserRights.countryId._id)
    }
  }, [])
  useEffect(() => {
    loadUniversityNameList()
    loadCountryStatus()
    loadCurrentUserData()
  }, [countryId, countryList])

  const setFieldByParams = async () => {
    let resp = await getParamsForApplicationList()
    // setCurrentStatus(resp.status)
    if (user.userType == 'admin') {
      if (resp.countryId) {
        setCountryId(resp.countryId)
      }
    }
    if (resp.name) {
      setName(resp.name)
    }
    if (resp.agentId) {
      setAgentId(resp.agentId)
    }
    if (resp.courseId) {
      setCourseId(resp.courseId)
    }
    if (resp.appUniversityId) {
      setAppUniversityId(resp.appUniversityId)
    }
    if (resp.intake) {
      setIntake(resp.intake)
    }
    if (resp.branchUserId) {
      setBranchUserId(resp.branchUserId)
    }
    if (resp.branchManagerId) {
      setBranchManagerId(resp.branchManagerId)
    }
    if (resp.studentManagerId) {
      setStudentManagerId(resp.studentManagerId)
    }
    if (resp.draftApplication) {
      setDraftApplication(resp.draftApplication)
    }
    if (resp.status) {
      setStatus(resp.status)
    }
    if (resp.note) {
      setCurrentNote(resp.note)
    }
  }

  const loadAgentData = async () => {
    let params = {
      results: 10000,
      userType: 'agent',
      sortField: 'companyName',
      sortOrder: 'ascend',
      select: ['name', 'companyName'],
      regExFilters: ['companyName']
    }
    let { data } = await dispatch(listAllUsers(params))
    if (data && data.length) {
      setAllAgent(data)
    }
  }
  const loadCountryStatus = async () => {
    let findCountry = lodash.find(countryList, (item) => {
      return item._id == countryId
    })
    if (findCountry) {
      if (findCountry.countryName.toLowerCase() == 'uk') {
        let temp = lodash.filter(ukApplicationSteps, (item) => {
          return item.name !== 'Case Close' && item.name !== 'New Student'
        })
        setStatusList(temp)
      }
      if (findCountry.countryName.toLowerCase() == 'canada') {
        let temp = lodash.filter(canadaApplicationSteps, (item) => {
          return item.name !== 'Case Close' && item.name !== 'New Student'
        })
        setStatusList(temp)
      }
      if (findCountry.countryName.toLowerCase() == 'australia') {
        let temp = lodash.filter(australiaApplicationSteps, (item) => {
          return item.name !== 'Case Close' && item.name !== 'New Student'
        })
        setStatusList(temp)
      }
      if (findCountry.countryName.toLowerCase() == 'usa') {
        let temp = lodash.filter(usaApplicationSteps, (item) => {
          return item.name !== 'Case Close' && item.name !== 'New Student'
        })
        setStatusList(temp)
      }
    } else {
      setStatusList([])
    }
  }
  const loadCurrentUserData = async () => {
    if (countryId) {
      let params = {
        results: 1000,
        userType: 'branchUser',
        sortField: 'name',
        sortOrder: 'ascend',
        select: ['name', 'userType', 'mobile', 'email'],
        customQuery: {
          $or: [{ countryId: countryId }, { 'department': 'Visa Approved Department' }]
        }
      }
      let { data } = await dispatch(listAllUsers(params))
      if (data && data.length) {
        setBranchUserList(data)
      }
    } else {
      setBranchUserList([])
    }
  }
  const loadBranchManagerAgentList = async () => {
    let { data } = await dispatch(getBranchManagerAgentList())
    if (data && data.length) {
      setBranchManagerAgentList(data)
    } else {
      setBranchManagerAgentList([])
    }
  }
  const loadUniversityNameList = async () => {
    let params = {
      results: 5000,
      select: ['universityName'],
      sortField: 'universityName',
      sortOrder: 'ascend',
      regExFilters: ['universityName']
    }
    params.universityCountry = countryId

    let { data } = await dispatch(listAllUniversities(params))
    setAppUniversityId('')
    if (data && data.length) {
      setUniversityList(data)
    }
  }
  const loadBranchManager = async () => {
    let obj = {
      userType: 'branchManager',
      customQuery: {
        'agents.0': { $exists: true }
      },
      results: 100,
      select: ['name', 'email', 'mobile', 'agents', 'countryId']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setBranchManagerList(data)
  }
  const loadStudentManager = async () => {
    let obj = {
      userType: 'branchManager',
      branchManagerType: 'studentWise',
      results: 100,
      select: ['name', 'email', 'mobile', 'agents', 'countryId']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setStudentManagerList(data)
  }

  const loadAllBranchManager = async () => {
    let obj = {
      userType: 'branchManager',
      results: 100,
      select: ['name', 'email', 'mobile']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setAllBranchManagerList(data)
  }

  const loadCountryList = async () => {
    let params = {
      results: 5000,
      select: ['countryName'],
      sortField: 'countryName',
      sortOrder: 'ascend'
    }
    let { data } = await dispatch(listAllCountries(params))
    if (data && data.length) {
      setCountryList(data)
    }
  }

  const events = {
    openAddPendencyDrawer: (value) => {
      setState({
        ...state,
        applicationObj: value
      })
      setAddPendencyDrawer(true)
    },
    reloadAndClose: () => {
      tableAppRef.current.reload()
      setAddPendencyDrawer(false)
    },
    closeAddPendencyDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setAddPendencyDrawer(false)
    },
    reloadFxn: () => {
      tableAppRef.current.reload()
    },
    showTransferApp: () => {
      setTransferAppVisible(true)
    },
    hideTransferApp: () => {
      setTransferAppVisible(false)
      tableAppRef.current.reload()
    },
    showAddCourseDrawer: () => {
      setVisibleAddCourseDrawer(true)
    },
    hideAddCourseDrawer: () => {
      setVisibleAddCourseDrawer(false)
      tableAppRef.current.reload()
    },
    refreshApplication: () => {
      tableAppRef.current.reload()
    },

    closeDirectApp: async (record) => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(applicationCloseDirect(obj))
      tableAppRef.current.reload()
    },

    enterName: (value) => {
      setName(value)
    },
    openOtherAppDrawer: (value) => {
      setState({
        ...state,
        applicationObj: value
      })
      setVisibleOtherAppDrawer(true)
    },
    closeOtherAppDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setVisibleOtherAppDrawer(false)
    },
    openAddCommentDrawer: (value) => {
      setState({
        ...state,
        applicationObj: value
      })
      setVisibleAddCommentDrawer(true)
    },
    closeAddCommentDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setVisibleAddCommentDrawer(false)
    },
    hideVisibleFeesModal: () => {
      setVisibleFeesModal(false)
    },
    setCommissionLocal: (data) => {
      setFeesObj({
        ...feesObj,
        ...data
      })
    },
    reCalculateCommissionFxn: async () => {
      let { commissionAmount } = await dispatch(reCalculateCommission(feesObj))
      if (commissionAmount) {
        events.setCommissionLocal({ commissionAmount })
      }

    },
    updateApplicationTuitionFeesFxn: async () => {
      let { success, message } = await dispatch(updateApplicationTuitionFees(feesObj))
      if (success) {
        setFeesObj(commissionObj)
        setVisibleFeesModal(false)
        events.reloadFxn()
      }
    }
  }


  const reActivateApp = (record) => {
    setSelectedApplication({
      visible: true,
      studentId: record._id,
      studentData: record,
      application: record.applications
    })
  }
  const onCloseReActivate = () => {
    setSelectedApplication({
      visible: false,
      studentId: '',
      studentData: {},
      application: {}
    })
    tableAppRef.current.reload()
  }

  const enrollmentApp = (record) => {
    setEnrollmentObj({
      visible: true,
      studentId: record._id,
      studentData: record,
      application: record.applications
    })
  }
  const onCloseEnrollmentApp = () => {
    setEnrollmentObj({
      visible: false,
      studentId: '',
      studentData: {},
      application: {}
    })
    tableAppRef.current.reload()
  }

  const changeUniversityFxn = (record) => {
    setChangeUniversity({
      visible: true,
      studentId: record._id,
      studentData: record,
      application: record.applications
    })
  }
  const onCloseChangeUniversityFxn = () => {
    setChangeUniversity({
      visible: false,
      studentId: '',
      studentData: {},
      application: {}
    })
    tableAppRef.current.reload()
  }

  const enrollmentBtn = (record) => {
      let application = record.applications
      /*return (
        !application.enrollment ? showEnrollmentAction(application.statusList) ?
          <button className='btn' onClick={() => enrollmentApp(record)}>
            <img src={'/assets/enrollment.png'} />
          </button> : null : null
      )*/
      return null
  }

  const getParamsForApplicationList = () => {
    return new Promise((resolve) => {
      let searchParams = new URLSearchParams(window.location.search)
      let countryId = searchParams.get('countryId')
      let status = searchParams.get('status')
      let currentStatus = searchParams.get('currentStatus')
      let agentId = searchParams.get('agentId')
      let appUniversityId = searchParams.get('appUniversityId')
      let name = searchParams.get('name')
      let courseId = searchParams.get('courseId')
      let note = searchParams.get('note')
      let branchUserId = searchParams.get('branchUserId')
      let branchManagerId = searchParams.get('branchManagerId')
      let studentManagerId = searchParams.get('studentManagerId')
      let draftApplication = searchParams.get('draftApplication')
      let intake = searchParams.get('intake')
      let userId = searchParams.get('userId')
      let toDate = searchParams.get('toDate')
      let fromDate = searchParams.get('fromDate')
      let department = searchParams.get('department')
      let userType = searchParams.get('userType')
      let obj = {}
      if (agentId) {
        obj.agentId = agentId
      }
      if (countryId) {
        obj.countryId = countryId
      }
      if (status) {
        obj.status = status
      }
      if (currentStatus) {
        obj.currentStatus = currentStatus
      }
      if (courseId) {
        obj.courseId = courseId
      }
      if (appUniversityId) {
        obj.appUniversityId = appUniversityId
      }
      if (name) {
        obj.name = name
      }
      if (intake) {
        obj.intake = intake
      }
      if (branchUserId) {
        obj.branchUserId = branchUserId
      }
      if (branchManagerId) {
        obj.branchManagerId = branchManagerId
      }
      if (studentManagerId) {
        obj.studentManagerId = studentManagerId
      }
      if (draftApplication) {
        obj.draftApplication = draftApplication
      }
      if (note) {
        obj.note = note
      }
      if (userId) {
        obj.userId = userId
      }
      if (toDate) {
        obj.toDate = toDate
      }
      if (fromDate) {
        obj.fromDate = fromDate
      }
      if (department) {
        obj.department = department
      }
      if (userType) {
        obj.userType = userType
      }
      resolve(obj)
    })
  }

  const searchFxn = async () => {
    let resp = await getParamsForApplicationList()
    let obj = {}
    if (countryId) {
      obj.countryId = countryId
    } else {
      obj.countryId = resp.countryId
    }
    if (courseId) {
      obj.courseId = courseId
    }
    if (status) {
      obj.status = status
    } else {
      obj.status = resp.status
    }
    if (currentStatus) {
      obj.currentStatus = currentStatus
    }
    if (name) {
      obj.name = name
    }
    if (agentId) {
      obj.agentId = agentId
    }

    if (appUniversityId) {
      obj.appUniversityId = appUniversityId
    }
    if (currentNote) {
      obj.note = currentNote
    }
    if (branchUserId) {
      obj.branchUserId = branchUserId
    }
    if (intake) {
      obj.intake = intake
    }
    if (branchManagerId) {
      obj.branchManagerId = branchManagerId
    }
    if (studentManagerId) {
      obj.studentManagerId = studentManagerId
    }
    if (draftApplication) {
      obj.draftApplication = draftApplication
    }
    obj.userId = resp.userId
    obj.fromDate = resp.fromDate
    obj.toDate = resp.toDate
    dispatch(
      getUrlPushWrapper('reportingApplicationList', {
        ...obj
      }))
    setTableSearch(true)
    setTimeout(() => {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
    }, 200)
  }
  const clearFxn = async () => {
    let resp = await getParamsForApplicationList()
    let obj = {}
    setAppUniversityId('')
    if (currentUserRights.userType == 'branchManager') {
      setCountryId(currentUserRights.countryId)
    } else {
      setCountryId('')
    }
    setStatus('')
    setCurrentStatus('')
    setCurrentNote('')
    setBranchUserId('')
    setAgentId('')
    setName('')
    setBranchManagerId('')
    setStudentManagerId('')
    setDraftApplication('')
    dispatch(
      getUrlPushWrapper('reportingApplicationList', {
        ...obj
      }))
    setTableSearch(true)
    setTimeout(() => {
      tableAppRef.current.reload()
    }, 200)
  }

  const columns = [

    {
      title: 'App#',
      key: 'applications.id',
      dataIndex: 'applications.id',
      width: 60,
      render: (item, record) => {
        return (
          <div style={{ width: 60 }}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 150,
      dataIndex: 'name',
      render: (item, record) => {
        let { applications } = record
        return (
          viewOnlyRight ?
            <Tooltip title={'Edit Student'}>
              <a className={'linkAA'}
                 onClick={() => {
                   dispatch(
                     getPushPathWrapper('student.editStudent', { id: record._id }))
                 }}>
                {record && record.countryISOCode == 'CA_+1' ?
                  <img src={'/assets/starEnable.png'} width={14} style={{ marginRight: 3 }} /> : null
                }
                {item} <Icon type={'edit'} />
                <br />
                {record && record.countryISOCode == 'CA_+1' ?
                  <span>
                <Tag style={{ marginTop: 3, marginBottom: 3 }}
                     size={'sm'}>Docs: {applications && applications.paymentPending == true ?
                  <Icon type={'close'} style={{ color: 'red' }} /> :
                  <Icon type={'check'} style={{ color: 'limegreen' }} />}</Tag>
                <br />
                <Tag>Fees: {record && record.documentsPending == true ?
                  <Icon type={'close'} style={{ color: 'red' }} /> :
                  <Icon type={'check'} style={{ color: 'limegreen' }} />}</Tag></span>
                  : null}
              </a>
            </Tooltip> : item
        )
      }
    },
    {
      title: 'DOB',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      width: 90,
      render: (val) => {
        return (
          <div style={{ width: 90 }}>
            {newFormatDisplayDate(val)}
          </div>
        )
      }
    },
    {
      title: 'Status',
      key: 'applications.status',
      dataIndex: 'applications.status',
      width: 80,
      render: (item, record) => {
        return (
          <React.Fragment>
            <div className={'label label1'}
                 style={{ background: statusColors(item), width: 80, padding: 3 }}>{item}</div>
          </React.Fragment>
        )
      }
    },
    {
      title: 'Draft',
      key: 'applications.draftApplication',
      dataIndex: 'applications.draftApplication',
      render: (item, record) => {
        return (
          item ? <Tag color='#f50'>Draft</Tag> : null
        )
      }
    },
    {
      title: 'University',
      key: 'applications.courseUniversity',
      dataIndex: 'applications.courseUniversity',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.logo && item.logo.url ?
              <Avatar src={item.logo.url} size={20} />
              : <Avatar src={defaultLogoList.defaultUniversityLogo} size={20} />}
            {item.universityName ? `  ${item.universityName}` : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Country',
      key: 'applications.universityCountry',
      dataIndex: 'applications.universityCountry',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.countryName ? item.countryName : null}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Program',
      key: 'applications.courseName',
      dataIndex: 'applications.courseName',
      width: 150,
      render: (item) => {
        return (
          <div style={{ width: 150 }}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Intake',
      key: 'applications.intake',
      dataIndex: 'applications.intake',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Pendency',
      key: 'applications.pendencyArr',
      dataIndex: 'applications.pendencyArr',
      // width: 100,
      render: (item, record) => {
        return (
          <CheckBranchUserRight>
            <Tooltip title={'Add Pendency'}>
              <span onClick={() => viewOnlyRight ? events.openAddPendencyDrawer(record) : ''}
                    className='badge'>{item && item.length ? getPendingPendency(item) : 0}</span>
            </Tooltip>
          </CheckBranchUserRight>
        )
      }
    },
    {
      title: 'Current User',
      key: 'applications.assignedUserId',
      dataIndex: 'applications.assignedUserId',
      width: 150,
      render: (val, record) => {
        let { agentId, statusList, status, addByUserId } = record.applications
        return (
          <React.Fragment>
            {val && val.name ? val.name : ''}<br />
            <div>Agent : {agentId && agentId.companyName ? agentId.companyName : ''}</div>
            {addByUserId && addByUserId.name ?
              <div className={'mt5 colorPrimary font12'}>(Added By
                : {addByUserId && addByUserId.name ? addByUserId.name : ''})</div> : ''}

            <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser']}>
              {agentId && agentId.mobile ? <div>Mob : {agentId.mobile}</div> : ''}
            </CheckUserRight>
            <div>{longDisplayDate(statusList.date)}</div>
          </React.Fragment>
        )
      }
    },
    {
      title: 'Student Manager',
      dataIndex: 'branchManagerId',
      hidden: !managerViewRight.includes(user.userType),
      width: 125,
      key: 'branchManagerId',
      render: (item, record) => {
        let { branchManagerId } = record
        return (
          <React.Fragment>
            {branchManagerId ? branchManagerId.name : null}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Managers',
      dataIndex: 'managers',
      hidden: !managerViewRight.includes(user.userType),
      width: 120,
      key: 'managers',
      render: (item, record) => {
        let { agentId, universityCountry } = record.applications
        return (
          <React.Fragment>
            {branchManagerList && branchManagerList.length ?
              <AgentBranchManagerComponent
                userId={agentId._id}
                countryId={universityCountry._id}
                branchManagers={branchManagerList} /> : null}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Latest Note',
      key: 'applications.latestComment',
      dataIndex: 'applications.latestComment',
      width: 150,
      render: (val) => {
        return (
          <div style={{ width: 150 }}>
            {val && val.text ? <RenderComment item={val} /> : null}
          </div>
        )
      }
    }
    // {
    //   title: 'Action',
    //   key: 'actions',
    //   dataIndex: 'actions',
    //   width: 100,
    //   render: (val, record) => {
    //     return (
    //       <div className={'actionBtnGroup'}>
    //         <div>
    //           <AllowComponentRightsWise rightUserType={['admin', 'branchUser', 'branchManager']}>
    //             <Tooltip title={'View Application'}>
    //               <a className='btn'
    //                  href={`/student/application?studentId=${record._id}&appId=${record.applications._id}`}
    //               ><img src='/dist/img/view.svg' alt='' /></a>
    //             </Tooltip>
    //
    //             <DraftButtonComponent record={record} callback={() => tableAppRef.current.reload()} />
    //
    //             <CheckManagerWiseRight>
    //               {record.applications.status !== 'Case Close' &&
    //               <Tooltip title={'Case Closed'}>
    //                 <Popconfirm title={'Are your sure, you want to Close Case?'}
    //                             onConfirm={() => {
    //                               events.closeDirectApp(record)
    //                             }}
    //                             okText='Yes' cancelText='No'>
    //                   <button className='btn'><img src='dist/img/cross.svg' alt='' className={'crossBtn'} /></button>
    //                 </Popconfirm>
    //               </Tooltip>}
    //             </CheckManagerWiseRight>
    //
    //             <Tooltip title={'View Other Application'}>
    //               <button className='btn' onClick={() => {
    //                 events.openOtherAppDrawer(record)
    //               }}><Icon type={'ordered-list'} /></button>
    //
    //             </Tooltip>
    //
    //             <Tooltip title={'Add Note'}>
    //               <button className='btn' onClick={() => {
    //                 events.openAddCommentDrawer(record)
    //               }}><Icon type={'plus'} /></button>
    //             </Tooltip>
    //
    //
    //           </AllowComponentRightsWise>
    //         </div>
    //         <AllowComponentUserWise rightUserType={['admin', 'branchUser', 'branchManager']}>
    //           <Tooltip title={'Transfer'}>
    //             <button className='btn' onClick={() => {
    //               setStudentData(record)
    //               events.showTransferApp()
    //             }}>
    //               <img src={'../dist/icons/forwardArrow.png'} />
    //
    //             </button>
    //           </Tooltip>{' '}
    //         </AllowComponentUserWise>
    //
    //         <AllowComponentUserWise rightUserType={['admin', 'branchManager']}>
    //           {
    //             record.applications.status == 'Case Close' ?
    //               <Tooltip title={'Re-Open Case'}>
    //                 <button className='btn' onClick={() => reActivateApp(record)}>
    //                   <img src={'/assets/power-button.png'} />
    //                 </button>
    //               </Tooltip> : null
    //           }
    //         </AllowComponentUserWise>
    //
    //         <ShowEnrollmentComponent rightUserType={['admin', 'branchUser']}>
    //           {enrollmentBtn(record)}
    //         </ShowEnrollmentComponent>
    //
    //         <AllowComponentUserWise rightUserType={['admin', 'branchManager']}>
    //           {
    //             record.applications.status !== 'Case Close' ?
    //               <Tooltip title={'Change University'}>
    //                 <button className='btn' onClick={() => changeUniversityFxn(record)}>
    //                   <img src={'/assets/shuffle-arrows.png'} />
    //                 </button>
    //               </Tooltip> : null
    //           }
    //         </AllowComponentUserWise>
    //
    //       </div>
    //     )
    //   }
    // }

  ]

  const resp = useSelector(state => {
    let { loadNoteRedux } = state.chooseApplication
    if (loadNoteRedux) {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
      dispatch({ type: 'END_LOAD_NOTE' })
    }
  })

  const loadColumns = () => {
    let columnArr = []
    lodash.each(columns, (item) => {
      if (item.hidden == undefined) {
        columnArr.push(item)
      } else {
        if (!item.hidden) {
          columnArr.push(item)
        }
      }
    })
    return columnArr
  }

  const filterBlock = (
    <Row gutter={16}>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select name='Status'
                  onChange={(item) => {
                    setCurrentStatus(item)
                  }}
                  filterOption={filterOption}
                  className={'antSelect'}
                  placeholder={'Status'}
                  allowClear={true}
                  showSearch={true}
                  value={currentStatus || undefined}>
            {statusList && statusList.length ? statusList.map((item, key) => {
              return (
                <Option value={item.name} key={key}>{item.name}</Option>
              )
            }) : null}
          </Select>
        </div>
      </Col>

      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select name='Current User' onChange={(item) => setBranchUserId(item)}
                  filterOption={filterOption}
                  className={'antSelect'}
                  allowClear={true}
                  showSearch={true}
                  placeholder={'Current User'}
                  value={branchUserId || undefined}>
            {branchUserList && branchUserList.length ? branchUserList.map((item, key) => {
              return (
                <Option value={item._id} key={key}>{item.name}</Option>
              )
            }) : null}
          </Select>
        </div>
      </Col>

      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select name='Search By University' onChange={(item) => setAppUniversityId(item)}
                  filterOption={filterOption}
                  className={'antSelect'}
                  allowClear={true}
                  showSearch={true}
                  placeholder={'University'}
                  value={appUniversityId || undefined}>
            {universityList && universityList.length ? universityList.map((item, key) => {
              return (
                <Option value={item._id} key={key}>{item.universityName}</Option>
              )
            }) : null}
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select name='Intake' onChange={(item) => setIntake(item)}
                  filterOption={filterOption}
                  className={'antSelect'}
                  allowClear={true}
                  showSearch={true}
                  placeholder={'Intake'}
                  value={intake || undefined}>
            {Intakes && Intakes.length ? Intakes.map((item, key) => {
              return (
                <Option value={item} key={key}>{item}</Option>
              )
            }) : null}
          </Select>
        </div>
      </Col>
      <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser']}>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select name='Search By Agent'
                    filterOption={filterOption}
                    className={'antSelect'}
                    allowClear={true}
                    placeholder={'Agent'}
                    showSearch={true}
                    onChange={(item) => setAgentId(item)} value={agentId || undefined}>
              {branchManagerAgentList && branchManagerAgentList.length ? branchManagerAgentList.map((item, key) => {
                return (
                  <Option value={item._id} key={key}>{item.companyName}</Option>
                )
              }) : null}
            </Select>
          </div>
        </Col>
      </CheckUserRight>
      <CheckUserRight user={user} rightUserType={['admin', 'branchUser']}>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select name='Search By Branch Manager'
                    filterOption={filterOption}
                    className={'antSelect'}
                    allowClear={true}
                    placeholder={'Branch Manager'}
                    showSearch={true}
                    onChange={(item) => setBranchManagerId(item)} value={branchManagerId || undefined}>
              {branchManagerList && branchManagerList.length ? branchManagerList.map((item, key) => {
                return (
                  <Option value={item._id} key={key}>{item.name}</Option>
                )
              }) : null}
            </Select>
          </div>
        </Col>
      </CheckUserRight>
      <CheckUserRight user={user} rightUserType={['admin', 'branchUser']}>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select name='Search By Student Manager'
                    filterOption={filterOption}
                    className={'antSelect'}
                    allowClear={true}
                    placeholder={'Student Manager'}
                    showSearch={true}
                    onChange={(item) => setStudentManagerId(item)} value={studentManagerId || undefined}>
              {studentManagerList && studentManagerList.length ? studentManagerList.map((item, key) => {
                return (
                  <Option value={item._id} key={key}>{item.name}</Option>
                )
              }) : null}
            </Select>
          </div>
        </Col>
      </CheckUserRight>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select name='Search By Draft'
                  filterOption={filterOption}
                  className={'antSelect'}
                  allowClear={true}
                  placeholder={'Draft Application'}
                  onChange={(item) => setDraftApplication(item)}
                  value={draftApplication || undefined}>
            {ConditionOptions && ConditionOptions.length ? ConditionOptions.map((item, key) => {
              return (
                <Option value={item.name} key={key}>{item.name}</Option>
              )
            }) : null}
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='search-box-table round ml0'>
          <input className='form-control form-control' type='search'
                 placeholder='Name...' value={name} onChange={(e) => {
            events.enterName(e.target.value)
          }} />
          <img src='/dist/img/search.png' alt='' />
        </div>
      </Col>


      <Col md={8} sm={8} xs={12} lg={6}>
        <Button onClick={() => searchFxn()} className={'roundBtn'}>Search</Button>
        {/*<Button onClick={() => clearFxn()} className={'roundBtn'}>*/}
        {/*  Clear*/}
        {/*</Button>*/}
      </Col>


    </Row>
  )

  let handleBack = async () => {
    let resp = await getParamsForApplicationList()
    dispatch(getUrlPushWrapper('reportingList', {
      userId: resp.userId,
      department: resp.department,
      userType: resp.userType
    }))
  }

  return (
    <React.Fragment>
      <div>

        <div className='row mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='table-head d-flex align-items-center all-student-search' style={{ height: 60 }}>
                <Button onClick={() => handleBack()}
                        className={'roundGoBackBtn'} icon={'arrow-left'}>
                  Back
                </Button>
                <h5>Applications </h5>
                <div className='search-box-table' style={{backgroundColor: 'transparent'}}>
                  (Total - {totalApplication})
                </div>
                <div className='sort-box-table mark-btn'>
                  {/*'branchManager'*/}
                  <CheckUserRight user={user} rightUserType={['admin',]}>
                      <span className={'sort-box-table-right'}>
                        <Tooltip title={'Export Excel'}>
                          {' '} <Button onClick={() => exportExcel()} className={'roundBtn'}>Export Excel</Button>
                        </Tooltip>
                      </span>
                  </CheckUserRight>
                </div>
              </div>
              <div className='card-body table-responsive'>
                {filterBlock}
                <TableComp columns={loadColumns()}
                           rowKey={(item, index) => {
                             return index
                           }}
                           apiRequest={apiRequest}
                           pagination={{
                             position: 'top',
                             showSizeChanger: true,
                             pageSizeOptions: ['50', '100', '200', '500'],
                             defaultPageSize: 50,
                             current: filters.page ? filters.page : 1
                           }}
                           ref={tableAppRef}
                />
              </div>
            </div>
          </div>
        </div>


        {addPendencyDrawer ?
          <SelectPendency visible={addPendencyDrawer}
                          applicationObj={state.applicationObj}
                          onAddPendencyClose={events.closeAddPendencyDrawer}
                          onClose={events.reloadAndClose}
                          reloadFxn={events.reloadFxn}
          /> : null}
      </div>
      {transferAppVisible ?
        <TransferApplicationDrawer visible={transferAppVisible}
                                   closeTransferDrawer={() => events.hideTransferApp()}
                                   studentId={studentData._id}
                                   studentData={studentData}
                                   application={studentData.applications}
        /> : null}

      {visibleAddCourseDrawer ? <ApplyCourse
        visible={visibleAddCourseDrawer}
        onClose={() => events.hideAddCourseDrawer()}
        refreshApplication={() => events.refreshApplication()}
        studentId={studentObj._id} /> : null}


      {visibleOtherAppDrawer ?
        <StudentOtherApp visible={visibleOtherAppDrawer}
                         applicationObj={state.applicationObj}
                         onStudentOtherAppClose={() => events.closeOtherAppDrawer()}
                         reloadFxn={events.reloadFxn}
        /> : null}

      {visibleAddCommentDrawer && applicationObj && applicationObj._id ?
        <AddNote visible={visibleAddCommentDrawer} studentData={applicationObj}
                 addedType={'direct'}
                 onClose={() => events.closeAddCommentDrawer()}
                 applicationId={applicationObj.applications._id} /> : null}

      {selectedApplication.visible ?
        <ReActivateApplication {...selectedApplication} onClose={onCloseReActivate} /> : null}

      {enrollmentObj.visible ?
        <Enrollment {...enrollmentObj} onClose={onCloseEnrollmentApp} /> : null}


      {changeUniversity.visible ?
        <ChangeUniversityComponent {...changeUniversity} onClose={onCloseChangeUniversityFxn} /> : null}

    </React.Fragment>
  )
}
const mapStateToProps = ({ global, router, chooseApplication }) => ({
  currentUserRights: global.currentUserRights
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(ReportingStudentApplication)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedComponent)
