import {apiUrl} from '../../../settings'

export const universityRecordsUrl = () => {
  return apiUrl + '/marketing-api/universityRecords'
}

export const meetingRecordsForGraphUrl = () => {
  return apiUrl + '/marketing-api/meetingRecordsForGraph'
}

export const teamMeetingRecordsForGraphUrl = () => {
  return apiUrl + '/marketing-team-api/meetingRecordsForGraph'
}

export const expenseRecordForGraphUrl = () => {
  return apiUrl + '/marketing-api/expenseRecordForGraph'
}
export const teamExpenseRecordForGraphUrl = () => {
  return apiUrl + '/marketing-team-api/expenseRecordForGraph'
}

export const topAgentRecordGraphUrl = () => {
  return apiUrl + '/marketing-api/topAgentRecordGraph'
}
export const teamTopAgentRecordGraphUrl = () => {
  return apiUrl + '/marketing-team-api/topAgentRecordGraph'
}

export const publicCollegeRecordUrl = () => {
  return apiUrl + '/marketing-api/publicCollegeRecord'
}

export const universityWiseReportUrl = () => {
  return apiUrl + '/marketing-api/universityWiseReport'
}
export const teamUniversityWiseReportUrl = () => {
  return apiUrl + '/marketing-team-api/universityWiseReport'
}

export const marketingUserWiseUniversityReportUrl = () => {
  return apiUrl + '/marketing-api/marketingUserWiseUniversityReport'
}

export const teamMarketingUserWiseUniversityReportUrl = () => {
  return apiUrl + '/marketing-team-api/marketingUserWiseUniversityReport'
}

export const stateWiseAgentRecordsUrl = () => {
  return apiUrl + '/marketing-api/stateWiseAgentRecords'
}
export const teamStateWiseAgentRecordsUrl = () => {
  return apiUrl + '/marketing-team-api/stateWiseAgentRecords'
}

export const marketingUserCountryWiseReportUrl = () => {
  return apiUrl + '/marketing-user-api/countryWiseReport'
}
export const toalApplicationUrl = () => {
  return apiUrl + '/marketing-list-api/privateUniversityRecords'
}
export const publicUniversityGraphUrl = () => {
  return apiUrl + '/marketing-list-api/publicUniversityRecords'
}
export const meetingRecordsListUrl = () => {
  return apiUrl + '/marketing-list-api/meetingRecordsList'
}
export const expenseListDrawerUrl = () => {
    return apiUrl + '/marketing-list-api/expenseListByUser'
}
export const topAgentGraphDrawerUrl = () => {
    return apiUrl + '/marketing-list-api/topAgentApplicationList'
}
export const stateWiseReportGraphUrl = () => {
    return apiUrl + '/marketing-list-api/stateWiseReportList'
}
