import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Avatar,
  Button,
  Collapse,
  Form,
  Select,
  notification,
  Tag,
  Icon,
  Tabs,
  Tooltip,
  Drawer, Input
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import { getUrlParams } from '../../../routes'
import { getCompany } from '../actions/company'
import DocumentBlock from './documentBlock'
import NotesBlock from './notesBlock'
import InfoBlock from './infoBlock'
import moment from 'moment'
import { InputBox } from '../../../components/_utils/appUtils'
import { submitCompanyNote } from '../actions/company'

const { TextArea } = Input

const initialState = {
  companyData: {},
  momentKey: moment(),
  noteDrawerVisible: false,
  document: {},
  studentKeyMoment: moment()
}


const StudentProfile = (props) => {
  const dispatch = useDispatch()
  let [state, setState] = useState(initialState)
  let [companyId, setCompanyId] = useState('')
  useEffect(() => {
    loadSingleCompanyData()
    document.body.className = 'applicationProfile'
  }, [])

  const loadSingleCompanyData = async () => {
    let searchParams = new URLSearchParams(window.location.search)
    let id = searchParams.get('id')
    setCompanyId(id)
    if (id) {
      let obj = {}
      let resp = await dispatch(getCompany(id))
      obj.companyData = resp
      setState({
        ...state,
        ...obj
      })
    }
  }


  let { companyData } = state

  const events = {
    showDrawer: () => {
      setState({ ...state, noteDrawerVisible: true })
    },
    hideDrawer: () => {
      setState({ ...state, noteDrawerVisible: false })
    },
    setLocalState: (e) => {
      let { name, value } = e.target
      setState({
        ...state,
        [name]: value
      })
    },
    chooseDocument: (e) => {
      let { name, files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          document: files[0]
        })
      }
    },

    handleSubmit: async () => {
      let obj = {
        companyId,
        note: state.note
      }
      let fd = new FormData()
      fd.append('obj', JSON.stringify(obj))
      fd.append('document', state.document)
      let data = await dispatch(submitCompanyNote(fd))
      if (data && !data.error) {
        dispatch({ type: 'START_PUSH_NOTE_BY_REDUX', noteId: data.noteId })
        setState({
          ...state,
          note: '',
          document: '',
          documentKey: moment(),
          noteDrawerVisible: false
        })

      }
    }
  }


  return (
    <React.Fragment>
      <div className={'appProfile'} key={state.studentKeyMoment}>
        <div className={'align-self-stretch'}>

          <InfoBlock companyData={companyData}
                     showNoteDrawer={events.showDrawer}/>

          <div className="student-dashboard">
            <div className="row m-0">
              <div className="col-lg-8 p-0">
                {companyId ? <NotesBlock companyId={companyId}
                                         showNoteDrawer={events.showDrawer}/> : ''}
              </div>
              <div className="col-lg-4 p-0">
                {companyId ? <DocumentBlock
                  companyId={companyId}/> : ''}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer width={700}
              title={'Create Note'}
              placement="left"
              visible={state.noteDrawerVisible}
              onClose={events.hideDrawer}>
        <Card bordered={false}>
          <Form>
            <InputBox title={'Note'}>
              <TextArea rows={7} onChange={events.setLocalState} name={'note'}
                        placeholder={'Note'} value={state.note}/>
            </InputBox>
            <InputBox title={'Document'}>
              <Input type={'file'} name={'document'} id={'document'} key={state.documentKey}
                     onChange={(e) => {
                       events.chooseDocument(e)
                     }}/>
            </InputBox>
            <Form.Item className={'mt20 alignRight'}>
              <Button type="primary" onClick={events.handleSubmit}>
                SUBMIT
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Drawer>
    </React.Fragment>
  )
}

const mapStateToProps = ({ global, router }) => ({
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(StudentProfile)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedComponent)
