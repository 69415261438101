import React, { useState, useEffect } from 'react'
import { Col, Drawer, Form, Row, Card, Input, Button, notification } from 'antd'
import { listAllCountries } from '../../countries/actions/countries'
import { useDispatch } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { getCurrentBalance, payCommission } from '../actions'
import { countryIds, InputBox } from '../../../components/_utils/appUtils'

let paymentModeList = [
  'Credit Card',
  'Cheque',
  'BHIM UPI',
  'PayTm',
  'NEFT',
  'Cash'
]
const PayCommission = (props) => {
  let { visible, onClose, agentId, closeAndRefresh } = props
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } } = props

  let dispatch = useDispatch()
  let [countryList, setCountryList] = useState([])
  let [totalCommission, setTotalCommission] = useState(0)
  const loadCountryList = async () => {
    let filters = {
      sortField: 'countryName', sortOrder: 'ascend',
      customQuery: {
        countryName: { $in: ['UK', 'Canada'] }
      }
    }
    let { data } = await dispatch(listAllCountries(filters))
    setCountryList(data)
  }

  const loadCurrentCommissionBalance = async (countryId) => {
    let { commission, success } = await dispatch(getCurrentBalance({ agentId, countryId }))
    if (success) {
      setTotalCommission(commission)
    }
  }
  const handleSubmit = (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        if (valData.commission <= 0) {
          alert('Enter valid commission.')
          return
        }
        if (valData.commission > totalCommission) {
          alert('Enter valid commission.')
          setFieldsValue({ commission: undefined })
          return
        }
        valData.agentId = agentId
        let data = await dispatch(payCommission(valData))
        if (data && data.success) {
          closeAndRefresh()
          form.resetFields()
        } else {

        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  useEffect(() => {
    loadCountryList()
  }, [])

  const setCommissionValue = (x) => {
    setFieldsValue({
      commission: x
    })
  }

  let fields = [
    {
      key: 'countryId',
      label: 'Country',
      required: true,
      type: 'select',
      options: countryList,
      keyAccessor: x => x._id,
      valueAccessor: x => `${x.countryName}`,
      onChange: x => {
        setFieldsValue({
          countryId: x
        }, () => {
          loadCurrentCommissionBalance(x)
        })
      }
    },
    {
      key: 'totalCommission',
      customField: (
        <InputBox title={'Total Commission'} className={'rowFlex'}>
          <div className={'disbaledField'}>
            {totalCommission}
          </div>
        </InputBox>
      )
    },
    {
      key: 'paymentMode',
      label: 'Payment Mode',
      required: true,
      type: 'select',
      options: paymentModeList,
      keyAccessor: x => x,
      valueAccessor: x => x,
      onChange: x => {
        setFieldsValue({
          paymentMode: x
        })
      }
    },
    {
      key: 'commission',
      label: 'Amount',
      required: true,
      disabled: totalCommission <= 0,
      type: 'number',
      onChange: x => {
        setCommissionValue(x)
      }
    },
    {
      key: 'details',
      label: 'Details',
      type: 'textArea',
      rows: 4
    }
  ]
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  return (
    <Drawer visible={true} onClose={onClose} width={'40%'} title={'Pay Commission'}>
      <Card>
        <Form onSubmit={handleSubmit}>
          <Row gutter={24}>
            {fields.map((item, key) => {
              return (
                item.customField ?
                  <Col span={24} key={key}>
                    {item.customField}
                  </Col> :
                  <Col span={24} key={key}>
                    <GetEachFormFields
                      item={item}
                      getFieldDecorator={getFieldDecorator}
                      formItemLayout={formItemLayout}/>
                  </Col>
              )
            })}
            <Col span={24}>
              <Form.Item className={'alignRight'}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Drawer>
  )

}

const WrappedApplyForPayCommission = Form.create()(PayCommission)
export default WrappedApplyForPayCommission
