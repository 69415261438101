import React, { useEffect, useState } from 'react'
import { Icon } from 'antd'
import { connect, useDispatch } from 'react-redux'
import { getUrlPushWrapper } from '../../routes'
import {
  CornerStoneCollege,
  getDirectUniversityNameForSupportingTeam,
  LesterCollege,
  universityListForMarketing
} from '../../components/_utils/appUtils'
import _ from 'lodash'
import { getAllCountriesFxn } from './actions/action'

const LiveReporting = () => {

  const dispatch = useDispatch()
  const [countries, setCountries] = useState([])

  const goToPage = (item) => {
    let obj = {}
    if (item.universityId) {
      obj.universityId = item.universityId
      obj.countryName = item.name
    }
    if (item.countryId) {
      obj.countryId = item.countryId
      obj.countryName = item.name
    }

    dispatch(getUrlPushWrapper('liveReportingList', obj))
  }


  const fetchCountries = async () => {
    const { data } = await dispatch(getAllCountriesFxn())
    setCountries(data)
  }

  useEffect(() => {
    fetchCountries()
  }, [])

  return (
    <div className='row  mt-4'>
      <div className='col-lg-12'>
        <div className='card reportContainer'>
          <div className='table-head d-flex align-items-center'>
            <h4>Live Reporting</h4>
            <div className='search-box-table'>
            </div>
            <div className='sort-box-table mark-btn'>
            </div>
          </div>
          <div key={countries} className={'reportBox'}>
            <div className={'liveReportingOuter'}>
              {
                countries && countries.map((item, key) => (
                  <div className={'liveReportingInnerBox'} key={key}>
                    <a onClick={() => goToPage(item)}>
                      <div className={'reportingBoxLiveReporting'}>
                        <div className={'reportingInnerBox'}>
                          <div className={'flagBox'}>
                            {item && item.image && item.image.url ?
                              <img src={item.image.url}
                                   alt={item ? item.name : ''}
                              /> : null}
                          </div>
                          <div className={'reportInfoBox'}>
                            {item.universityId ?
                              (getDirectUniversityNameForSupportingTeam(item.universityId) === 'Cornerstone College' ?
                                  'Cornerstone'
                                  : getDirectUniversityNameForSupportingTeam(item.universityId) === 'Lester B. Pearson' ?
                                    'Lester B.'
                                    : getDirectUniversityNameForSupportingTeam(item.universityId)
                              )
                              : item.name}
                            <Icon type='right' />
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  activeUser: global.activeUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveReporting)
