import {notification} from 'antd'
import {getToken, customAxios as axios} from '../../../request'
import {
    oxfordMoveToInterviewDepartmentUrl,
    updateInterviewStatusUrl,
    oxfordInterviewApplicationsUrl,
    updateInterviewScheduleUrl,
    interviewScheduleDateUrl, exportOxfordInterviewListUrl
} from "../api/oxfordApi";
import {showPageLoad, hidePageLoad} from '../../../modules/actions'

export const oxfordMoveToInterviewDepartmentFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(oxfordMoveToInterviewDepartmentUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const updateInterviewStatusFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateInterviewStatusUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const updateInterviewScheduleFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateInterviewScheduleUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const interviewScheduleDateFxn = () => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(interviewScheduleDateUrl(), {}, getToken())
    dispatch(hidePageLoad())

    return data
}

export const oxfordInterviewApplicationsFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
        params: valData,
        ...await getToken()
    }
    let {data} = await axios.get(oxfordInterviewApplicationsUrl(), config)
    dispatch(hidePageLoad())
    return data
}
export const exportOxfordInterviewListFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(exportOxfordInterviewListUrl(), valData, getToken())
    dispatch(hidePageLoad())

    return data
}
