import React, { useState } from 'react'
import { Button, Col, Drawer, Form, Input, notification, Row } from 'antd'
import { TableComp } from 'sz-react-utils-lite'
import { DocumentTypes, InputBox } from '../../components/_utils/appUtils'
import GetEachFormFields from '../../components/_utils/appFormUtils'
// import { RequestLoa } from './actions/actions'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { RowTable } from '../../components/_utils/RowTable'
import { uploadInsurancefile } from './actions/actions'

const DocumentList = ['insurance']
const UploadInsurance = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, applicationId, studentId, reloadTable, editAble, studentData } = props
  console.log('student datavttt', studentData)
  let [state, setState] = useState([])
  const events = {
    chooseDocument: (e) => {
      let { name, files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          [name]: files[0]
        })
      }
    },
    handleSubmit: async () => {
      let fd = new FormData()
      let obj = {
        id: studentData._id
      }

      if (!state['insurance'] || (state['insurance'] && !state['insurance'].name)) {
        return notification.warn({ message: 'Please choose insurance file' })
      }


      fd.append('obj', JSON.stringify(obj))

      _.each(DocumentList, (item) => {
        if (state[item]) {
          fd.append([item], state[item])
        }
      })

      let data = await dispatch(uploadInsurancefile(fd))
      if (data && data.success) {
        onClose()
        reloadTable()
      }

    }

  }
  return (
    <Drawer visible={visible}
            title={'Upload Insurance'}
            onClose={onClose}
            width={'40%'}
    >
      <div className='row'>
        <div className='col-lg-12'>
          <Form className={'vertical-form'} autoComplete='off'>
            <div className='form-box'>
              <div className='card unizportal'>
                {/*{studentData && studentData.applications && studentData.applications.id ?*/}
                {/*  <div className={'tableBox striped'}>*/}
                {/*    <RowTable title={'Name (ID)'} value={<span>{studentData.name} ({studentData.studentId})</span>}/>*/}
                {/*    <RowTable title={'Application ID'} value={<span>{studentData.applications.id}</span>}/>*/}
                {/*    <RowTable title={'Application'} value={<span>{studentData.applications.courseName}</span>}/>*/}
                {/*  </div> : null}*/}
                <br />

                <div className='inner-form'>
                  <Row gutter={24} key={state.uploadKey}>
                    {DocumentList.map((item, key) => {
                      return (
                        <Col span={24} key={key}>
                          <InputBox title={`Insurance Document`}>
                            <Input type={'file'} name={item} id={item} className={'form-control'}
                                   onChange={(e) => {
                                     events.chooseDocument(e)
                                   }} />
                          </InputBox>
                        </Col>
                      )
                    })}
                  </Row>

                  <button className='btn' type='submit' onClick={events.handleSubmit}>save</button>
                </div>
              </div>

            </div>
          </Form>
        </div>
      </div>
    </Drawer>
  )
}
export default UploadInsurance