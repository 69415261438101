import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Drawer, Form, Input, notification, Row } from 'antd'
import { InputBox } from '../../../components/_utils/appUtils'
import moment from 'moment'
import { DatePicker } from 'antd/es'
import { addCallBackRequest, requestCallBackListFxn } from '../actions/dashboardAction'
import { useDispatch } from 'react-redux'
import { CountryCodeWithFlag } from '../../../components/_utils/countryUtil'

const RequestCallBack = (props) => {
  const {
    visible, onClose,
    assignedTo,
    countryId,
    universityId = undefined,
    form, requestType
  } = props
  const [state, setState] = useState({
    name: '',
    countryCode: '',
    phone: '',
    email: '',
    date: moment()
  })
  const dispatch = useDispatch()

  let setDefaultUserDetails = () => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    if (user) {
      const { name, countryCode, mobile, email } = user
      setState({
        name: name || '',
        countryCode: countryCode || '',
        phone: mobile || '',
        email: email || '',
        date: moment()
      })
    }
  }


  const handleSubmit = (e) => {
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const requestData = {
          ...values,
          assignedTo,
          countryId,
          universityId,
          type: requestType
        }
        const resp = await dispatch(addCallBackRequest(requestData))
        if (resp && resp.success) {
          onClose()
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }
  const events = {
    disabledDate: (current) => {
      return current && (current < moment() || moment(current).day() == 0)
    }
  }


  useEffect(() => {
    // apiRequest()
    setDefaultUserDetails()

  }, [])

  const handleCountryCodeChange = (value) => {
    setState(prevState => ({
      ...prevState,
      countryCode: value
    }))
  }

  return (
    <Drawer
      width={'45%'}
      title={'Request A Call Back'}
      placement='right'
      visible={visible}
      onClose={onClose}>
      <Card bordered={false}>
        <Form className={'vertical-form'} onSubmit={handleSubmit}>
          <Row className={'row'}>
            <Col className={'col-md-6 mb10'}>
              <InputBox title={'Name'}>
                {form.getFieldDecorator('name', {
                  initialValue: state.name,
                  rules: [{ required: true, message: 'Please enter your name' }]
                })(
                  <Input
                    placeholder={'Name'}
                    className={'form-control'}
                  />
                )}
              </InputBox>
            </Col>
            <Col className={'col-md-6 mb10'}>
              <InputBox title={'Email'}>
                {form.getFieldDecorator('email', {
                  initialValue: state.email,
                  rules: [{ required: true, message: 'Please enter your email' }]
                })(
                  <Input
                    placeholder={'Email'}
                    className={'form-control'}
                  />
                )}
              </InputBox>
            </Col>

            <Col className={'col-md-4 mb10'}>
              <CountryCodeWithFlag
                countryCode={state.countryCode}
                chooseCode={handleCountryCodeChange} />
            </Col>

            <Col className={'col-md-4 mb10'} style={{ marginTop: 3 }}>
              <InputBox title={'Mobile Number'}>
                {form.getFieldDecorator('phone', {
                  initialValue: state.phone,
                  rules: [{ required: true, message: 'Please enter your mobile number' }]
                })(
                  <Input
                    placeholder={'Mobile Number'}
                    className={'form-control col-md-12'}
                  />
                )}
              </InputBox>
            </Col>
            <Col className={'col-md-4 mb10'}>
              <InputBox title={'Date'}>
                {form.getFieldDecorator('date', {
                  initialValue: state.date,
                  rules: [{ required: true, message: 'Please select a date' }]
                })(
                  <DatePicker
                    className={'form-control'}
                    disabledDate={events.disabledDate}
                  />
                )}
              </InputBox>
            </Col>
          </Row>


          <div className={'mt10 alignRight'}>
            <button className={'btn btn-success roundNew'} type='submit'>Submit
            </button>
          </div>
        </Form>
      </Card>
    </Drawer>
  )
}

const WrappedRequestCallback = Form.create()(RequestCallBack)
export default WrappedRequestCallback
