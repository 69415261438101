import React, {useEffect, useState} from 'react'
import {notification, Form, Button} from 'antd'
import {useDispatch} from 'react-redux'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import {getUniversity} from "../../login/action";
import {fcmtUniversity, CasaHCollege} from "../../../components/_utils/appUtils";
import Request from "../../../request"
import {apiUrl} from "../../../settings";

const fields = {
    courseUniversity: "",
    studentIdNo: ""
}
const studentState = {
    studentId: "",
    applicationId: "",
    url: ""
}
const VerifyOfferLetter = (props) => {
    const dispatch = useDispatch()
    let [universityList, setUniversityList] = useState([])
    let [state, setState] = useState(fields)
    let [url, setUrl] = useState("")
    const setData = (e) => {
        let {value, name} = e.target
        setState({...state, [name]: value})
    }
    useEffect(() => {
        events.getUniversity()
    }, [])


    const events = {
        getUniversity: async () => {
            // fcmtUniversity
            let {data} = await getUniversity({
                results: 10, sortField: 'universityName',
                customQuery: {
                    _id: {$in: [fcmtUniversity]}
                },
                sortOrder: 'ascend', select: ['universityName', 'applicationFee']
            })
            setUniversityList(data);
        },
    }


    const searchStudentOfferLetter = async () => {
        let {courseUniversity, studentIdNo} = state
        if (!courseUniversity) {
            notification.warning({message: 'Choose course university.'})
            return
        }
        if (!studentIdNo) {
            notification.warning({message: 'Enter student ID.'})
            return
        }
        let resp = await Request.getStudentOfferLetterFxn(state);
        let {success, message, data} = resp;
        if (success) {
            let {_id, applications} = data;
            let urlName = "loaLetter"
            let url = `${apiUrl}/${urlName}/${_id}?courseId=${applications._id}`
            setUrl(url)
        } else {
            notification.warning({message: message})
        }
    }

    return (
        <React.Fragment>
            <section className="contact-form verifyOfferBox">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <h3>Verify LOA Letter</h3>
                            <div className="box">
                                <Form>
                                    <div className="form-group">
                                        <label>University</label>
                                        <select className="form-control" name={'courseUniversity'}
                                                onChange={setData}
                                                value={state.courseUniversity}>
                                            <option value={''}>Choose University</option>
                                            {universityList && universityList.length ? universityList.map((item, key) => {
                                                return (
                                                    <option key={key} value={item._id}>{item.universityName}</option>
                                                )
                                            }) : null}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Student ID</label>
                                        <input type="text" className="form-control"
                                               name={'studentIdNo'}
                                               value={state.studentIdNo} onChange={setData}/>
                                    </div>
                                    <div className={'alignRight'}>
                                        <button type="submit" className="btn btn-primary"
                                                onClick={() => searchStudentOfferLetter()}>Verify
                                        </button>
                                    </div>
                                </Form>

                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="content">
                                <div className="store">
                                    <LazyLoadImage src={'../newImages/Verification-offer_letter.png'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {url ? <div className={'iframeBox'}>
                    <iframe
                        src={url}
                        frameBorder="0" style={{width: "100%", height: "120vh"}}/>
                </div> : null}
            </section>
        </React.Fragment>
    )
}
export default VerifyOfferLetter
