import { apiUrl } from '../../../settings'

export const addCompanyUrl = () => {
  return apiUrl + '/company'
}

export const listCompanyUrl = () => {
  return apiUrl + '/company'
}

export const singleCompanyUrl = (id) => {
  return apiUrl + '/company/' + id
}

export const getCompanyNotesUrl = () => {
  return apiUrl + '/api/getCompanyNotes'
}

export const getCompanySingleNotesUrl = () => {
  return apiUrl + '/api/getCompanySingleNotes'
}

export const getCompanyDocumentsUrl = () => {
  return apiUrl + '/api/companyDocuments'
}


export const companyEmailActivitiesUrl = () => {
  return apiUrl + '/api/companyEmailActivities'
}

export const companySmsActivitiesUrl = () => {
  return apiUrl + '/api/companySmsActivities'
}


export const submitCompanyNoteUrl = () => {
  return apiUrl + '/api/submitCompanyNote'
}


export const sendSmsToCompanyUrl = () => {
  return apiUrl + '/api/sendSmsToCompany'
}

export const sendEmailToCompanyUrl = () => {
  return apiUrl + '/api/sendEmailToCompany'
}

export const uploadCompanyDocumentUrl = () => {
  return apiUrl + '/api/uploadCompanyDocument'
}

export const uploadCompanyProfileImageUrl = () => {
  return apiUrl + '/api/uploadCompanyProfileImage'
}
