import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import { notification } from 'antd'
import { AddDocumentUrl, AddPalUrl, getCornetStoneStudents, updateCornetStoneStudentUrl } from '../apis'
import { updateRequestedCallBackUrl } from '../../Intakes/apis'

export const AddPalFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(AddPalUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const AddDocumentFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(AddDocumentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const getCornetStoneStudentListFxn = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(getCornetStoneStudents(), config)
  dispatch(hidePageLoad())
  dispatch({ type: 'LIST_ALL_FEEDBACK', payload: data.data })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const updateCornerStoneStudentsFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateCornetStoneStudentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
