import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Drawer,
  Form,
  Input,
  notification,
  DatePicker
} from 'antd'
import { useDispatch } from 'react-redux'
import InputBox from '../../../components/_utils/InputBox'
import { longDisplayDate } from '../../../components/_utils/appUtils'
import { addCounsellingFollowupFxn, CounsellingFollowupListFxn } from '../actions/counselling'

const dateFormat = 'DD/MM/YYYY'
const { TextArea } = Input

const FollowupComponent = props => {
  const { visible, onClose, counsellingId } = props

  const dispatch = useDispatch()
  const [content, setContent] = useState('')
  const [date, setDate] = useState('')
  const [followupHistory, setFollowupHistory] = useState([])

  useEffect(() => {
    apiRequest()

  }, [])

  const apiRequest = async () => {
    try {
      let obj = {
        sortField: '_id',
        sortOrder: 'descend',
        counsellingId: counsellingId,
        results: 100, count: 100
      }
      const { data } = await dispatch(CounsellingFollowupListFxn(obj))
      setFollowupHistory(data)
    } catch (error) {
      console.error('Error fetching note history:', error)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (!date || !content) {
      notification.warning({
        message: 'Field are required'
      })
      return
    } else {
      const obj = {
        counsellingId: counsellingId,
        date: date,
        content: content
      }

      try {
        await dispatch(addCounsellingFollowupFxn(obj))

        setDate('')
        setContent('')
        apiRequest()

      } catch (err) {
        console.log(err)
      }
    }
  }

  return (
    <Drawer
      width={'40%'}
      title='Add Follow up'
      placement='right'
      visible={visible}
      onClose={onClose}>
      <div className='form-box'>
        <div className='card unizportal' style={{ padding: 20 }}>
          <InputBox title={'Message'}>
            <TextArea
              rows={4}
              value={content}
              onChange={e => setContent(e.target.value)}
              placeholder='Enter your followup here'
            />
          </InputBox>
          <InputBox title={'Follow up Date'}>
            <DatePicker
              format={dateFormat}
              placeholder={'Follow Up Date'}
              onChange={value => {
                setDate(value)
              }}
            />
          </InputBox>
          <button className='btn mt5' type='submit' onClick={handleSubmit}>
            save
          </button>
        </div>

        <div className='card' style={{ padding: 20 }}>
          <h5 className={'font14_1 ml20'}>
            FollowUps List
          </h5>
          {followupHistory.map((item, index) => (
            <Card size={'small'}
                  key={index}
                  className='note-card mt0'>
              <div className='header-note mb-0'>
                <div className={'font12 primary-color'}>
                  Date : {longDisplayDate(item.date)}
                </div>
              </div>
              <hr className={'mb5 mt0 mt5'} />
              <p className='mt-0 mb-0'>{item.content}</p>
            </Card>
          ))}
        </div>
      </div>
    </Drawer>
  )
}

export default FollowupComponent
