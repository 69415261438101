import React, { useState, useEffect } from 'react'
import {
  Button,
  Drawer,
  Form,
  notification,
  Input,
  Select,
  Row,
  Col,
  DatePicker,
  Popconfirm, Card, Empty
} from 'antd'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import {
  changeCounsellingDateFxn, CounsellingFollowupListFxn,
  getSingleCounsellingFxn,
  getSlotByCounsellorFxn,
  updateCounsellingSlotFxn
} from './actions/counselling'
import { RowTable } from '../../components/_utils/RowTable'
import { longDisplayDate } from '../../components/_utils/appUtils'
import FollowupComponent from './components/followup'

const { Option } = Select

const CounsellingUpdateDrawer = props => {
  const dispatch = useDispatch()
  let user =
    localStorage.getItem('user') != 'undefined'
      ? JSON.parse(localStorage.getItem('user'))
      : null

  const [showForm, setShowForm] = useState(false)
  const [showChangeDateForm, setShowChangeDateForm] = useState(false)
  const [showCancelForm, setShowCancelForm] = useState(false)
  const [slotList, setSlotList] = useState([])
  const [cancelReason, setCancelReason] = useState('')
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedSlot, setSelectedSlot] = useState(null)
  let [counsellingDetails, setCounsellingDetails] = useState({})
  const [showMeetingForm, setShowMeetingForm] = useState(false)
  const [followupHistory, setFollowupHistory] = useState([])
  const [meetingDetails, setMeetingDetails] = useState({
    meetingLink: '',
    meetingPassword: '',
    meetingId: ''
  })
  const [showUpdateDrawer, setShowUpdateDrawer] = useState({
    visible: false,
    counsellingId: ''
  })
  const {
    form: { getFieldDecorator },
    onClose,
    counsellingId,
    visible,
    dateSlotList,
    dateCellRender
  } = props

  useEffect(
    () => {
      if (counsellingId) {
        events.loadSingleCounselling()
        events.loadFollowup()
      }
    },
    [counsellingId]
  )

  const handleDateChange = date => {
    setSelectedDate(date)
  }
  const handleSubmit = async e => {
    e.preventDefault()
    const { form } = props
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        valData.counsellingId = counsellingId
        valData.slot = selectedSlot
        let resp = await dispatch(changeCounsellingDateFxn(valData))
        if (resp) {
          notification.success({
            message: 'Date Changed Successfully'
          })
        }
        form.resetFields()
        onClose()
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  const handleCancelSubmit = async () => {
    if (cancelReason) {
      let resp = await dispatch(
        updateCounsellingSlotFxn({
          counsellingId,
          status: 'Reject',
          reason: cancelReason
        })
      )
      if (resp) {
        notification.success({
          message: 'Counselling Rejected Successfully'
        })
      }
      setCancelReason('')
      onClose()
    } else {
      notification.warning({
        message: 'Reason is required for cancelling counselling'
      })
    }
  }

  const fetchSlots = async date => {
    const params = {
      results: 1000,
      count: 1000,
      counsellorId: counsellingDetails.counsellorId._id,
      date: date
    }

    try {
      const response = await dispatch(getSlotByCounsellorFxn(params))
      if (response && response.data) {
        const availableSlots = response.data.filter(
          slot => slot.disabled == false
        )
        setSlotList(availableSlots)
      }
    } catch (error) {
      console.error('Error fetching available slots:', error)
    }
  }
  useEffect(
    () => {
      if (selectedDate) {
        fetchSlots(selectedDate)
      }
    },
    [selectedDate]
  )

  const handleSubmitMeeting = async () => {
    let resp = await dispatch(
      updateCounsellingSlotFxn({
        counsellingId,
        status: 'Approved'
      })
    )
    if (resp && resp.success) {
      onClose()
    }
  }

  const handleSlotChange = value => {
    setSelectedSlot(value)
  }

  const events = {
    loadSingleCounselling: async () => {
      let { data } = await dispatch(getSingleCounsellingFxn({ counsellingId }))
      setCounsellingDetails(data)
    },
    openUpdateDrawer: () => {
      setShowUpdateDrawer({
        visible: true,
        counsellingId: counsellingId
      })
    },
    closeUpdateDrawer: async () => {
      setShowUpdateDrawer({
        visible: false,
        counsellingId: ''
      })
    },
    loadFollowup: async () => {
      try {
        let obj = {
          sortField: '_id',
          sortOrder: 'descend',
          counsellingId: counsellingId,
          results: 100, count: 100
        }
        const { data } = await dispatch(CounsellingFollowupListFxn(obj))
        setFollowupHistory(data)
      } catch (error) {
        console.error('Error fetching note history:', error)
      }
    }
  }
  let dateFormat = 'DD/MM/YYYY'

  const disabledDate = current => {
    if (moment(current).isBefore(moment().startOf('day'))) {
      return true
    }

    const findCounselling = dateSlotList.find(
      item => item.stringDate === moment(current).format(dateFormat)
    )
    return findCounselling && findCounselling.disabled
  }

  const handleApproveClick = () => {
    setShowChangeDateForm(false)
    setShowCancelForm(false)
    setShowMeetingForm(true)
    setShowForm(false)
  }

  return (
    <div>
      <Drawer
        visible={visible}
        width={'80%'}
        placement='right'
        onClose={onClose}
        title={'Update Event Slot'}>
        <div className='form-box p20'>
          {counsellingDetails ? (
            <div className={'row'}>
              <div className={'col-md-6'}>
                <div className='card unizportal'>

                  <h6>Student Details:</h6>

                  <div className={'tableBox striped'}>
                    <RowTable
                      title={'Name'}
                      value={counsellingDetails.studentName}
                    />
                    <RowTable
                      title={'Email'}
                      value={
                        counsellingDetails.studentId &&
                        counsellingDetails.studentId.email
                          ? counsellingDetails.studentId.email
                          : ''
                      }
                    />
                    <RowTable
                      title={'Mobile No'}
                      value={
                        counsellingDetails.studentId ? (
                          <div>
                            {counsellingDetails.studentId.countryCode
                              ? `${counsellingDetails.studentId.countryCode}-`
                              : ''}
                            {counsellingDetails.studentId.mobile
                              ? counsellingDetails.studentId.mobile
                              : ''}
                          </div>
                        ) : null
                      }
                    />
                    <RowTable
                      title={'Apply on'}
                      value={
                        counsellingDetails.createdAt ? (
                          <div>
                            {longDisplayDate(counsellingDetails.createdAt)}
                          </div>
                        ) : null
                      }
                    />
                    <RowTable title={'Slot'} value={counsellingDetails.slot} />
                    <RowTable
                      title={'Institute'}
                      value={
                        counsellingDetails.instituteId ? (
                          <div>
                            {counsellingDetails.instituteId.name
                              ? `${counsellingDetails.instituteId.name} `
                              : ''}
                            {counsellingDetails.instituteId.companyName
                              ? `(${counsellingDetails.instituteId.companyName})`
                              : ''}
                          </div>
                        ) : null
                      }
                    />
                    <RowTable
                      title={'Status'}
                      value={
                        counsellingDetails.status ? counsellingDetails.status : ''
                      }
                    />
                    <RowTable
                      title={'Country Name'}
                      value={
                        counsellingDetails.countryId
                          ? counsellingDetails.countryId.countryName
                          : ''
                      }
                    />
                    <RowTable
                      title={'Counsellor Email'}
                      value={
                        counsellingDetails.counsellorId
                          ? counsellingDetails.counsellorId.email
                          : ''
                      }
                    />
                    <RowTable
                      title={'Start Time'}
                      value={
                        counsellingDetails.startTime
                          ? new Date(
                            counsellingDetails.startTime
                          ).toLocaleTimeString()
                          : ''
                      }
                    />
                    <RowTable
                      title={'End Time'}
                      value={
                        counsellingDetails.endTime
                          ? new Date(
                            counsellingDetails.endTime
                          ).toLocaleTimeString()
                          : ''
                      }
                    />

                    {user.userType === 'admin' ||
                    user.userType === 'counsellingManager' ||
                    user.userType === 'branchUser' ? (
                      <RowTable value={
                        <div className='btn-counselling jcc'>
                          {counsellingDetails.status == 'Pending' && (
                            <Popconfirm
                              title={
                                'Are you sure, you want to Approved Counselling?'
                              }
                              onConfirm={handleSubmitMeeting}>
                              <a className='btn btn-success-green round-bt sm'>
                                Approve
                              </a>
                            </Popconfirm>
                          )}
                          {counsellingDetails.status !== 'Reject' && (
                            <a
                              onClick={() => {
                                setShowMeetingForm(false)
                                setShowCancelForm(true)
                                setShowChangeDateForm(false)
                              }}
                              className='btn btn-danger round-bt sm'>
                              Cancel
                            </a>
                          )}
                          {counsellingDetails.status !== 'Reject' && (
                            <a
                              onClick={() => {
                                setShowMeetingForm(false)
                                setShowCancelForm(false)
                                setShowChangeDateForm(true)
                              }}
                              className='btn btn-warning round-bt sm'>
                              Change Date
                            </a>
                          )}


                        </div>
                      } />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={'col-md-6'}>
                <div className='card unizportal'>
                  <div className={'ml20 mr20 jcsb mb10'}>
                    <h6>Follow Ups History</h6>
                    <a onClick={() => events.openUpdateDrawer()}
                       className='btn btn-default round-bt sm'>
                      Add Followup
                    </a>
                  </div>
                  {followupHistory && followupHistory.length ? followupHistory.map((item, index) => (
                      <Card
                        size={'small'}
                        key={index}
                        className='note-card mt0'>
                        <div className='header-note mb-0'>
                          <div className={'font12 primary-color'}>
                            Date : {longDisplayDate(item.date)}
                          </div>
                        </div>
                        <hr className={'mb5 mt0 mt5'} />
                        <p className='mt-0 mb-0'>{item.content}</p>
                      </Card>
                    )) :
                    <div className={'card unizportal bgWhite'}>
                      <Empty className={'mt20'} />
                    </div>
                  }
                </div>
              </div>
            </div>
          ) : null}

          {showChangeDateForm && (
            <Form onSubmit={handleSubmit} className='vertical-form'>
              <Row gutter={24}>
                <Col span={24} className={'mb10'}>
                  <Form.Item label='Date'>
                    {getFieldDecorator('date', {
                      initialValue: selectedDate,
                      rules: [
                        { required: true, message: 'Please select a date!' }
                      ]
                    })(
                      <DatePicker
                        onChange={handleDateChange}
                        className='form-control'
                        disabledDate={disabledDate}
                        cellRender={dateCellRender}
                        format='YYYY-MM-DD'
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24} className={'mb10'}>
                  <Form.Item label='Slot'>
                    <Select
                      value={selectedSlot}
                      onChange={handleSlotChange}
                      placeholder='Select a slot'>
                      {slotList
                        .filter(
                          slot =>
                            !slot.disabled ||
                            !(slot.data && slot.data.status == 'Approved')
                        )
                        .map(slot => (
                          <Option key={slot.slot} value={slot.slot}>
                            {slot.slot}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={18}>
                  <Form.Item>
                    <Button type='primary' htmlType='submit' className='btn'>
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}

          {showCancelForm && (
            <Form className='vertical-form'>
              <Row gutter={24}>
                <Col span={24} className={'mb10'}>
                  <Form.Item label='Reason'>
                    {getFieldDecorator('cancelReason', {
                      initialValue: cancelReason,
                      rules: [
                        {
                          required: true,
                          message: 'Please input the reason for cancellation!'
                        }
                      ]
                    })(
                      <Input.TextArea
                        rows={4}
                        value={cancelReason}
                        onChange={e => setCancelReason(e.target.value)}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={18}>
                  <Form.Item>
                    <Button
                      type='primary'
                      htmlType='submit'
                      className='btn'
                      onClick={handleCancelSubmit}>
                      Cancel
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}

          {showUpdateDrawer.visible ? (
            <FollowupComponent
              {...showUpdateDrawer}
              counsellingId={showUpdateDrawer.counsellingId}
              onClose={() => {
                events.closeUpdateDrawer()
                events.loadFollowup()
              }}
            />
          ) : null}
        </div>
      </Drawer>
    </div>
  )
}

const CounsellingUpdate = Form.create()(CounsellingUpdateDrawer)
export default CounsellingUpdate
