import React, { useState, useEffect } from 'react'
import { Button, Card, Drawer, Form, Input, notification } from 'antd'
import { RowTable } from '../../../components/_utils/RowTable'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { InputBox } from '../../../components/_utils/appUtils'
import { RequestLoaApproved } from '../actions/chooseStudent'
import { useDispatch } from 'react-redux'
import {
  eduBoardAndUniversity,
  getStudent,
  updateApplicationFamilyName,
  updateStudent
} from '../../student/actions/student'

const ChangeNameDrawer = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, studentId, applicationId, reloadTable, studentData } = props
  let [familyName, setFamilyName] = useState('')
  let [givenName, setGivenName] = useState('')
  // let [studentIdNo, setStudentIdNo] = useState('')
  let handleSubmit = async () => {
    familyName = familyName.trim()
    givenName = givenName.trim()
   /* if (!familyName) {
      return notification.warn({ message: 'Enter family name' })
    }*/
    if (!givenName) {
      return notification.warn({ message: 'Enter Given name' })
    }
    let obj = {
      studentId, familyName, applicationId, givenName
    }
    let data = await dispatch(updateApplicationFamilyName(obj))
    console.log('data resp', data)
    if (data && data.success) {
      onClose()
      reloadTable()
      setFamilyName('')
      setGivenName('')
    }
  }
  useEffect(() => {
    console.log('chnage name ')
    setStudentData()
  }, [])

  const setStudentData = () => {
    let { studentId, applicationId, studentData } = props
    let appData = studentData.applications
    setFamilyName(appData.familyName)
    setGivenName(appData.givenName)
  }
  return (
    <Drawer visible={visible}
            title={`Change Family and Given Name`}
            width={600}
            closable={true}
            maskClosable={true}
            onClose={onClose}>
      <Card>
        <Form className={'vertical-form'} autoComplete='off'>
          <Form.Item>
            <InputBox title={'Family name'}>
              <Input onChange={(e) => setFamilyName(e.target.value)}
                     value={familyName} className={'form-control'} />
            </InputBox>
          </Form.Item>
          <Form.Item>
            <InputBox title={'Given name'}>
              <Input onChange={(e) => setGivenName(e.target.value)}
                     value={givenName} className={'form-control'} />
            </InputBox>
          </Form.Item>
          {/*  <Form.Item>
            <InputBox title={'Student ID'}>
              <Input onChange={(e) => setStudentIdNo(e.target.value)}
                     value={studentIdNo} className={'form-control'}/>
            </InputBox>
          </Form.Item>*/}
          <Form.Item style={{ marginTop: 25 }}>
            <Button type='primary' onClick={handleSubmit}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  )
}
export default ChangeNameDrawer
