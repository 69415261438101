import React, { useState, useEffect, useRef } from 'react'
import {
  Tooltip,
  Icon
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { listAllPendency } from '../actions/pendency'
import { DefaultTablePagination } from '../../../components/_utils/appUtils'
import AddPendency from './add'
import EditPendency from './edit'
const ListAllPendency = () => {
  const { totalPendency } = useSelector((state) => ({
    totalPendency: state.pendencyReducers.totalPendency
  }))

  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      resolve(dispatch(listAllPendency({
        ...params,
        regExFilters: ['countryName', 'enrollmentBasic', 'enrollmentProcedure', 'fundSponsors']
      })))
    })
  }
  const [itemNo, setItemNo] = useState(1)
  const [id, setId] = useState(null)
  const [addPendencyVisible, setAddPendencyVisible] = useState(false)
  const [editPendencyVisible, setEditPendencyVisible] = useState(false)
  const confirmDelete = async (id) => {

  }
  const loadFilterCountry = async () => {
    let countryFilter = {
      results: 100
    }
    let resp = await dispatch(listAllPendency(countryFilter))
  }

  const events = {
    hideDrawer: () => {
      setAddPendencyVisible(false)
      tableRef.current.reload()
    },
    hideEditDrawer: () => {
      setEditPendencyVisible(false)
      tableRef.current.reload()
    }
  }
  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 100,
      key: 'sno',
      fixed: true,
      render: (item, record, index) => {
        return (
          <a className={'linkPointer'} onClick={() => {
            setId(record._id)
            setEditPendencyVisible(true)
          }}>
            {(itemNo - 1) * 10 + index + 1}
          </a>
        )
      }
    },
    {
      title: 'Pendency',
      dataIndex: 'name',
      key: 'name',
      render: (item, record) => {
        return (
          <a className={'linkPointer'} onClick={() => {
            setId(record._id)
            setEditPendencyVisible(true)
          }}>
            {item}
          </a>
        )
      }
    },
    {
      key: 'actions',
      title: 'Actions',
      fixed: 'right',
      width: 80,
      render: (text, record) => {
        return <React.Fragment>
          <Tooltip title="Edit">
            <button
              className={'btn'}
              style={{ marginRight: 6 }}
              onClick={() => {
                setId(record._id)
                setEditPendencyVisible(true)
              }}
            >
              <Icon type={'edit'}/>
            </button>
          </Tooltip>
          {/*  <Tooltip title="Delete">
            <Button
              shape="circle"
              style={{ marginRight: 6 }}
              size="small"
              onClick={() => {
                confirmDelete(record._id)
              }}
              icon="delete"
            />
          </Tooltip>*/}

        </React.Fragment>

      }
    }
  ]


  return (
    <div>
      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="card">
            <div className="table-head d-flex align-items-center">
              <h5>All Pendency {totalPendency || 0}</h5>
              <div className="search-box-table">
                {/*  <input className="form-control form-control" type="search" placeholder="Search Notifications"/>
                <img src="dist/img/search.png" alt=""/>*/}
              </div>

              <div className="sort-box-table mark-btn">
                <button className="btn" onClick={() => setAddPendencyVisible(true)}>
                  <img src={'../dist/icons/plus.png'} className={'plus'}/>
                  Add Pendency
                </button>
              </div>
            </div>
            <div className="card-body table-responsive">
              <TableComp columns={columns}
                         apiRequest={apiRequest}
                         ref={tableRef}
                         pagination={DefaultTablePagination()}

              />
            </div>
          </div>
        </div>
      </div>
      {addPendencyVisible ? <AddPendency visible={addPendencyVisible} events={events}/> : ''}
      {editPendencyVisible ? <EditPendency visible={editPendencyVisible} events={events} id={id}/> : ''}
    </div>

  )
}

export default ListAllPendency
