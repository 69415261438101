import React, {useEffect, useRef, useState} from 'react'
import {
    Drawer,
    Select,
    Card,
    Avatar,
    Table
} from 'antd'
import {useDispatch} from 'react-redux'
import {
    displayDate,
    statusColors,
    getStatusDate, displayTime
} from '../../../components/_utils/appUtils'
import lodash from 'lodash'
import moment from 'moment'
import {getMeetingsById} from '../actions'
import {TableComp} from "sz-react-utils-lite";

const {Option} = Select
const MeetingListDrawer = (props) => {
    let {visible, status, userId, closeDrawer, fromDate, toDate} = props
    const [data, setData] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        getMeetings()
    }, [])

    let getMeetings = async () => {
        let params = {
            userId: userId,
            status: status,
        }
        if (fromDate) {
            params.fromDate = new Date(fromDate);
            params.toDate = new Date(toDate);
        }
        let {data} = await dispatch(getMeetingsById(params))
        setData(data)
    }

    const meetStatusColors = (status) => {
        if (status == 'Pending') {
            return '#e0a800'
        } else if (status == 'attended') {
            return '#28a745'
        } else {
            return '#c82333'
        }
    }


    const columns = [
        {
            title: 'Topic',
            key: 'topic',
            width: 150,
            dataIndex: 'topic'
        },
        {
            title: 'Date / Time',
            key: 'fromTime',
            dataIndex: 'date',
            width: 150,
            render: (item, record) => {
                return (
                    <React.Fragment>
                        <p> {displayDate(record.date)} / {displayTime(item)} </p>
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Type',
            key: 'type',
            width: 80,
            dataIndex: 'type'
        },
        {
            title: 'Agent',
            key: 'agent',
            width: 150,
            dataIndex: 'agent',
            render: (item, record) => {
                return (
                    item ? <p>{item.name}<p>({item.companyName})</p></p> : ""
                )
            }
        },
        {
            title: 'Status',
            key: 'status',
            width: 100,
            dataIndex: 'status',
            render: (item, record) => {
                return (
                    <React.Fragment>
                        <label className={'label'}
                               style={{background: meetStatusColors(item), textTransform: 'capitalize'}}>{item}</label>
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Notes',
            key: 'notes',
            dataIndex: 'notes',
            width: 200,
            render: (item, record) => {
                return (
                    <React.Fragment>
                        {item}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Minutes of meeting',
            key: 'meetingDiscussion',
            dataIndex: 'meetingDiscussion',
            width: 200,
            render: (item, record) => {
                return (
                    <div style={{width: 200}}>
                        {item}
                    </div>
                )
            }
        }
        // {
        //   title: 'Agent',
        //   key: 'applications.agentId',
        //   dataIndex: 'applications.agentId',
        //   render: (val) => {
        //     return (
        //       <div>{val && val.companyName ? val.companyName : ''}</div>
        //     )
        //   }
        // }
    ]


    return (
        <React.Fragment>
            <Drawer visible={visible}
                    width={'90%'}
                    closable={true}
                    maskClosable={true}
                    onClose={closeDrawer}>
                <Card bordered={true} title={`Meetings list`}>
                    <TableComp columns={columns} size={'small'} bordered={true}
                               dataSource={data}
                               extraProps={{scroll: {x: 1000}}}
                               rowKey={(item, x) => {
                                   return x
                               }}
                               pagination={true}
                    />

                </Card>
            </Drawer>
        </React.Fragment>
    )
}
export default MeetingListDrawer
