import React, {useEffect, useState} from "react"
import {Button, Drawer, Select, Form, notification} from "antd";
import {InputBox} from "../../../components/_utils/appUtils";
import {useDispatch} from "react-redux";
import {addIcefUniversityFxn, icefCountryListFxn} from "../actions";

let {Option} = Select;
const AddUniversityDrawer = (props) => {
    let dispatch = useDispatch()
    let {visible, onClose, onSubmit, selectedCountryId = ""} = props;
    let [universityName, setUniversityName] = useState("");
    let [countryId, setCountryId] = useState(undefined);
    let [countryList, setCountryList] = useState([])
    useEffect(() => {
        loadCountry()
    }, [])
    let loadCountry = async () => {
        let obj = {
            results: 1000,
            count: 1000,
        }
        let {data} = await dispatch(icefCountryListFxn(obj));
        setCountryList(data)
        if (selectedCountryId) {
            setCountryId(selectedCountryId)
        }
    }
    const handleSubmit = async () => {
        if (!countryId) {
            notification.warning({message: "Choose country"})
            return
        }
        if (!universityName) {
            notification.warning({message: "Enter university name."})
            return
        }
        let resp = await dispatch(addIcefUniversityFxn({universityName, countryId}));
        if (resp && resp.success) {
            onSubmit()
        }

    }
    return (
        <>
            <Drawer title={'Add University'} visible={visible} width={'45%'} onClose={onClose}>
                <div className="form-box">
                    <div className="card unizportal">
                        <Form>
                            <InputBox title={'Country'}>
                                <Select
                                    placeholder={'Choose country'}
                                    value={countryId}
                                    onChange={(value) => {
                                        setCountryId(value)
                                    }}>
                                    {countryList && countryList.length ? countryList.map((item) => {
                                        return (
                                            <Option value={item._id}>{item.countryName}</Option>
                                        )
                                    }) : null}
                                </Select>
                            </InputBox>
                            <InputBox title={'University Name'}>
                                <input className={'form-control'}
                                       placeholder={'University Name'}
                                       value={universityName}
                                       onChange={({target}) => {
                                           setUniversityName(target.value)
                                       }}/>
                            </InputBox>
                            <Button type="primary" htmlType="submit" className="btn" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Form>
                    </div>
                </div>
            </Drawer>
        </>
    )
}
export default AddUniversityDrawer
