import { apiUrl } from '../../../settings'

export const addNewsUrl = () => {
  return apiUrl + '/news'
}

export const listNewsUrl = () => {
  return apiUrl + '/news'
}
export const singleNewsUrl = (id) => {
  return apiUrl + '/news/' + id
}

export const singleNewsEnquiryUrl = (id) => {
  return apiUrl + '/singleNewsEnquiry/' + id
}
export const newsEnquiryListUrl = (id) => {
  return apiUrl + '/newsEnquiry'
}