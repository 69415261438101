import React, {useState} from "react"
import {Popconfirm, Drawer, Form, Button, Input, notification} from "antd";
import {fcmtRevokeStatus, InputBox} from "../_utils/appUtils";
import {FcmtStatusUpdate} from "../../containers/applications/actions/chooseStudent";
import {useDispatch} from "react-redux";

const {TextArea} = Input;
const RevokeAppDrawer = (props) => {
    let dispatch = useDispatch()
    let {visible, onClose, studentId, applicationId, onSubmit} = props;
    let [revokeNote, setRevokeNote] = useState("")
    let submitHandle = async () => {
        if (!revokeNote) {
            notification.warning({message: "Please enter note."})
            return;
        }
        let obj = {
            studentId: studentId,
            applicationId: applicationId,
            status: fcmtRevokeStatus,
            revokeNote: revokeNote
        }
        let resp = await dispatch(FcmtStatusUpdate(obj))
        if (resp && resp.success) {
            onSubmit()
        }
    }
    return (
        <>
            <Drawer visible={visible} onClose={onClose} title={'Revoked/Case Closed'} width={'45%'}>
                <div className="form-box commissionBox">
                    <div className="card unizportal">
                        <Form>
                            <InputBox title={'*Note'}>
                                <TextArea
                                    rows={6}
                                    onChange={(e) => {
                                        let {value} = e.target;
                                        setRevokeNote(value)
                                    }}
                                    name={'Note'}
                                    className={'form-control gcKey'}
                                    placeholder={'Note'} value={revokeNote}/>
                            </InputBox>
                            <Form.Item className={'mt20 alignRight'}>
                                <Button type='primary' onClick={submitHandle}>
                                    SUBMIT
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Drawer>
        </>
    )
}

const RevokeComponent = (props) => {
    let {record, user, reloadFxn} = props;
    let {applications, _id: studentId} = record
    let requiredStatusForRevoked = ['File pending for submission', 'File Submitted', 'Visa Approved']
    let emails = ['kamal@ciracollege.com']
    let [visible, setVisible] = useState(false);
    let events = {
        showAppDrawer: () => {
            setVisible(true)
        },
        hideAppDrawer: () => {
            setVisible(false)
        },
        onSubmit: () => {
            setVisible(false);
            reloadFxn()
        },
        showToUser: () => {
            if (user.userType == "admin" || emails.includes(user.email)) {
                return true
            } else {
                return false
            }
        }
    }
    return (
        <>
            {applications && applications.loaRequested == "Approved" && requiredStatusForRevoked.includes(applications.status) && events.showToUser() ? <>
                <div className={'mt10'}>
                    <a className={'btn btn-default btn-xs'} onClick={events.showAppDrawer}>{fcmtRevokeStatus}</a>
                </div>
            </> : null}

            {applications && applications.status == fcmtRevokeStatus ?
                <div className={'mt10'}>
                    <label className={'label label-danger sm'}>
                        {fcmtRevokeStatus}
                    </label>
                    <br/>
                    {applications.revokeNote ? <div className={'mt10'}>
                        Note : {applications.revokeNote}
                    </div> : null}
                </div> : null
            }

            {visible ?
                <RevokeAppDrawer
                    visible={visible}
                    studentId={studentId}
                    applicationId={applications._id}
                    onClose={events.hideAppDrawer}
                    onSubmit={events.onSubmit}
                /> : null}

        </>
    )
}
export default RevokeComponent
