import React, {useState} from "react"
import {Col, Drawer, Form, Input, notification, Row} from "antd";
import {RowTable} from "../../../components/_utils/RowTable";
import {InputBox} from "../../../components/_utils/appUtils";
import {uploadAppDocumentAndStatus} from "../actions";
import {useDispatch} from "react-redux";

const MoveToStageComponent = (props) => {
    let dispatch = useDispatch()
    let {visible, status, onClose, studentData, reloadFxn} = props;
    let [state, setState] = useState({})
    const events = {
        chooseDocument: (e) => {
            let {name, files} = e.target
            if (files && files.length) {
                setState({
                    ...state,
                    [name]: files[0]
                })
            }
        },
        handleSubmit: async () => {
            let fd = new FormData()
            let obj = {
                _id: studentData._id,
                status: status,
                studentId: studentData._id,
                applicationId: studentData.applications._id
            }

            if (!state[status] || (state[status] && !state[status].name)) {
                return notification.warn({message: `Please choose ${status}`})
            }

            fd.append('obj', JSON.stringify(obj))
            fd.append('document', state[status])


            let data = await dispatch(uploadAppDocumentAndStatus(fd))
            if (data && data.success) {
                onClose()
                reloadFxn()
            }
        }
    }
    return (
        <Drawer
            title={`Move to ${status} stage`}
            onClose={onClose}
            width={'40%'}
            visible={visible}>
            <div className='row'>
                <div className='col-lg-12'>
                    <Form className={'vertical-form'} autoComplete='off'>
                        <div className='form-box'>
                            <div className='card unizportal'>
                                {studentData && studentData.applications && studentData.applications.id ?
                                    <div className={'tableBox striped'}>
                                        <RowTable title={'Name (ID)'}
                                                  value={<span>{studentData.name} ({studentData.studentId})</span>}/>
                                        <RowTable title={'Application ID'}
                                                  value={<span>{studentData.applications.id}</span>}/>
                                        <RowTable title={'Application'}
                                                  value={<span>{studentData.applications.courseName}</span>}/>
                                    </div> : null}
                                <br/>

                                <div className='inner-form'>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <InputBox title={`${status} Document`}>
                                                <Input type={'file'} name={status} id={status}
                                                       className={'form-control'}
                                                       onChange={(e) => {
                                                           events.chooseDocument(e)
                                                       }}/>
                                            </InputBox>
                                        </Col>
                                    </Row>

                                    <button className='btn' type='submit' onClick={events.handleSubmit}>save</button>
                                </div>
                            </div>

                        </div>
                    </Form>
                </div>
            </div>
        </Drawer>
    )
}
export default MoveToStageComponent
