import {
    Button,
    Col,
    Form,
    Icon,
    Row,
    Select,
    Tooltip,
    notification
} from 'antd'
import lodash from 'lodash'
import moment from 'moment'
import React, {useEffect, useRef, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {StudentMarketingUserComponent} from '../../../components/_utils/AgentBranchManagerComponent'
import {
    Intakes,
    departmentObj,
    fcmtUniversity,
    filterOption,
    longDisplayDate,
    newFormatDisplayDate
} from '../../../components/_utils/appUtils'
import {getPushPathWrapper, getUrlPushWrapper} from '../../../routes'
import {listAllCampus} from '../../campus/actions/campus'
import {getUniversity} from '../../university/actions/university'
import {listAllUsers} from '../../users/actions/user'
import {
  directUniversityApplicationReportFxn,
  exportFcmtApplicationsList
} from '../actions/chooseStudent'
import AgentTypeheadComponent from '../../../components/_utils/AgentTypeheadComponent'

const statusList = [
    'Offer Received',
    'Rqst LOA',
    'LOA Generated',
    'File pending for submission',
    'File Submitted',
    'Visa Approved',
    'Visa Rejected'
]
const {Option} = Select
const RenderComment = props => {
    let {item} = props
    let [hideV, setHideV] = useState(true)
    return (
        item && (
            <div>
                {item.addedByUserId && item.addedByUserId.name ? (
                    <div className={'alignRight colorPrimary font12'}>
                        (Added By : {item.addedByUserId.name} )
                    </div>
                ) : null}
                <div className={hideV ? 'appCommentDotsReplace' : ''}>
                    {item && item.text ? item.text : ''}
                </div>
                {item && item.text.length && item.text.length > 50 ? (
                    <div className={'alignRight'}>
                        {hideV ? (
                            <a
                                onClick={() => {
                                    setHideV(false)
                                }}>
                                Show More
                            </a>
                        ) : (
                            <a
                                onClick={() => {
                                    setHideV(true)
                                }}>
                                Show Less
                            </a>
                        )}
                    </div>
                ) : (
                    ''
                )}
            </div>
        )
    )
}
const OfferActionComponent = props => {
    let {file} = props
    return (
        <div>
            {file && file.path ? (
                <Tooltip title={'Download Offer Letter'}>
                    <a download={file.fileName} href={file.path}>
                        <img src={'/assets/icons/cloud-computing-download.png'}/>
                    </a>
                </Tooltip>
            ) : (
                ''
            )}
        </div>
    )
}
const LoaRequestActionComponent = props => {
    let {val} = props
    return (
        <div>
            {val ? (
                <Tooltip title={val}>
                    <a>
                        <img
                            src={`${
                                val == 'Pending'
                                    ? '/assets/icons/pendingCheck.png'
                                    : '/assets/icons/doneCheck.png'
                            }`}
                            className={'sm'}
                        />
                    </a>
                </Tooltip>
            ) : null}
        </div>
    )
}
const LoaActionComponent = props => {
    let {val} = props
    return val && val.path ? (
        <>
            <Tooltip title={'Download LOA'}>
                <a download={val.fileName} href={val.path}>
                    <img src={'/assets/icons/cloud-computing-download.png'}/>
                </a>
            </Tooltip>
        </>
    ) : (
        ''
    )
}
const ReceiptActionComponent = props => {
    let {val} = props
    return val && val.path ? (
        <div>
            <Tooltip title={'Download Receipt'}>
                <a download={val.fileName} href={val.path}>
                    <img src={'/assets/icons/cloud-computing-download.png'} />
                </a>
            </Tooltip>
        </div>
    ) : (
      ''
    )
}
const PalActionComponent = props => {
    let { val } = props
    return val && val.path ? (
      <div>
          <Tooltip title={'Download PAL'}>
              <a download={val.fileName} href={val.path}>
                  <img src={'/assets/icons/cloud-computing-download.png'} />
              </a>
          </Tooltip>
      </div>
    ) : (
      ''
    )
}

const ViewStudentApplication = props => {
    const [studentData, setStudentData] = useState({})
    const [id, setId] = useState('')
    const [applicationId, setApplicationId] = useState('')
    const [name, setName] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [allAgent, setAllAgent] = useState([])
    const [agentId, setAgentId] = useState('')
    const [totalApplication, setTotalApplication] = useState(0)
    const [currentStatus, setCurrentStatus] = useState('')
    const [status, setStatus] = useState('')
    let [tableSearch, setTableSearch] = useState(false)
    let [filters, setFilters] = useState({})
    let [campusList, setCampusList] = useState([])
    const [campusId, setCampusId] = useState('')
    const [branchUserId, setBranchUserId] = useState('')
    const [intake, setIntake] = useState('')
    let [courseName, setCourseName] = useState('')
    let [studentAppId, setStudentAppId] = useState('')

    let [studentShore, setStudentShore] = useState('')
    let [visibleDocumentDrawer, setVisibleDocumentDrawer] = useState(false)
    let [marketingUserList, setMarketingUserList] = useState([])
    let [marketingUserId, setMarketingUserId] = useState('')
    let [universityName, setUniversityName] = useState('')
    let [type, setType] = useState('')

    const dispatch = useDispatch()
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null

    useEffect(() => {
        setFieldByParams()
        loadMarketingUsers()
        loadUniversity()
    }, [])
    const tableAppRef = useRef()

    let loadUniversity = async () => {
        let respParams = await getParamsForApplicationList()
        let data = await dispatch(getUniversity(respParams.appUniversityId))
        if (data && data.universityName) {
            setUniversityName(data.universityName)
            loadCampusList(data._id)
        }
    }

    let apiRequest = params => {
        return new Promise(async resolve => {
            let resp = await getParamsForApplicationList()
            params = { ...params, ...resp }
            if (!params.results) {
                params.results = 50
                params.count = 50
            }
            if (tableSearch) {
                params.page = 1
            }
            try {
                setFilters(params)
                let resp = await dispatch(
                    directUniversityApplicationReportFxn({ ...params })
                )
                setTotalApplication(resp.total)
                setTableSearch(false)
                resolve(resp)
            } catch (e) {
                // Handle errors
            }
        })
    }

    let exportExcel = async () => {
        let params = {}
        let resp = await getParamsForApplicationList()
        params = {...params, ...resp}
        if (name) {
            params.name = name
        }
        if (dateOfBirth) {
            params.dateOfBirth = moment(dateOfBirth)
        }

        params.count = totalApplication
        let {success, filePath, message} = await dispatch(
            exportFcmtApplicationsList(params)
        )
        if (success) {
            notification.success({message: message})
        } else {
            notification.error({message: message})
        }
    }

    const setFieldByParams = async () => {
        let resp = await getParamsForApplicationList()
        // setCurrentStatus(resp.status)
        if (resp.applicationId) {
            setApplicationId(resp.applicationId)
        }

        if (resp.campusId) {
            setCampusId(resp.campusId)
        }
        if (resp.status) {
            setStatus(resp.status)
        }
        if (resp.agentId) {
            setAgentId(resp.agentId)
        }
        if (resp.intake) {
            setIntake(resp.intake)
        }
        if (resp.courseName) {
            setCourseName(resp.courseName)
        }
        if (resp.studentShore) {
            setStudentShore(resp.studentShore)
        }
        if (resp.branchUserId) {
            setBranchUserId(resp.branchUserId)
        }
        if (resp.marketingUserId) {
            setMarketingUserId(resp.marketingUserId)
        }
        if (resp.type) {
            setType(resp.type)
        }
        if (resp.studentAppId) {
            setStudentAppId(resp.studentAppId)
        }
    }


    const loadCampusList = async universityId => {
        let params = {}
        params.sortField = 'campusName'
        params.sortOrder = 'ascend'
        params.results = 50
        params.count = 50
        params.campusUniversity = universityId
        params.select = ['campusName']
        let {data} = await dispatch(
            listAllCampus({
                ...params,
                regExFilters: ['campusAddress', 'campusName']
            })
        )
        setCampusList(data)
    }

    const events = {
        reloadFxn: () => {
            tableAppRef.current.reload()
        },

        refreshApplication: () => {
            tableAppRef.current.reload()
        },

        openDocumentDrawer: async record => {
            setStudentData(record)
            setVisibleDocumentDrawer(true)
        },
        closeDocumentDrawer: async record => {
            setStudentData({})
            setVisibleDocumentDrawer(false)
        }
    }

    const getParamsForApplicationList = () => {
        return new Promise(resolve => {
            let searchParams = new URLSearchParams(window.location.search)
            let appUniversityId = searchParams.get('appUniversityId')
            let id  = searchParams.get('id')
            let applicationId = searchParams.get('applicationId');
            let name = searchParams.get('name')
            let campusId = searchParams.get('campusId')
            let status = searchParams.get('status')
            let agentId = searchParams.get('agentId')
            let branchUserId = searchParams.get('branchUserId')
            let intake = searchParams.get('intake')
            let courseName = searchParams.get('courseName')
            let studentShore = searchParams.get('studentShore')
            let marketingUserId = searchParams.get('marketingUserId')
            let type = searchParams.get('type')
            let studentAppId = searchParams.get('studentAppId')
            let obj = {}

            if (appUniversityId) {
                obj.appUniversityId = appUniversityId
            }
            if (applicationId) {
                obj.applicationId = applicationId
            }
            if (name) {
                obj.name = name
            }

            if (campusId) {
                obj.campusId = campusId
            }
            if (status) {
                obj.status = status
            }
            if (agentId) {
                obj.agentId = agentId
            }
            if (intake) {
                obj.intake = intake
            }
            if (courseName) {
                obj.courseName = courseName
            }
            if (studentShore) {
                obj.studentShore = studentShore
            }

            if (branchUserId) {
                obj.branchUserId = branchUserId
            }
            if (marketingUserId) {
                obj.marketingUserId = marketingUserId
            }
            if (type) {
                obj.type = type
            }
            if (studentAppId) {
                obj.studentAppId = studentAppId
            }

            resolve(obj)
        })
    }

    const searchFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {}


        if (name) {
            obj.name = name
        }
        if (campusId) {
            obj.campusId = campusId
        }
        if (status) {
            obj.status = status
        }
        if (agentId) {
            obj.agentId = agentId
        }
        if (intake) {
            obj.intake = intake
        }
        if (courseName) {
            obj.courseName = courseName
        }
        if (studentShore) {
            obj.studentShore = studentShore
        }
        if (branchUserId) {
            obj.branchUserId = branchUserId
        }
        if (marketingUserId) {
            obj.marketingUserId = marketingUserId
        }

        if (applicationId) {
        obj.applicationId = applicationId;
    }
        if (studentAppId) {
            obj.studentAppId = studentAppId
        }
        if (resp.appUniversityId) {
            obj.appUniversityId = resp.appUniversityId
        }
        if (resp.type) {
            obj.type = resp.type
        }
        dispatch(
            getUrlPushWrapper('canadaDirectUniApp', {
                ...obj
            })
        )
        console.log(obj, "objyyy:")
        setTableSearch(true)
        setTimeout(() => {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
        }, 200)

        console.log('Filtered data:', obj);
    }
    const clearFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {}
        setId('')
        setApplicationId('')
        setName('')
        setCampusId('')
        setStatus('')
        setAgentId('')
        setIntake('')
        setCourseName('')
        setStudentShore('')
        setBranchUserId('')
        setMarketingUserId('')
        setStudentAppId('')
        dispatch(
            getUrlPushWrapper('canadaDirectUniApp', {
                ...obj,
                appUniversityId: resp.appUniversityId,
                type: resp.type
            })
        )
        setTableSearch(true)
        setTimeout(() => {
            tableAppRef.current.reload()
        }, 200)
    }

    const columns = [
        {
            title: 'App#',
            key: 'applicationId',
            dataIndex: 'applicationId',
            width: 60,
            render: (item, record) => {
                let {applications} = record
                return (
                    <div style={{width: 60}}>
                        {applications && applications.id ? applications.id : null}
                    </div>
                )
            }
        },
        {
            title: 'Student ID',
            key: 'applications.studentIdNo',
            dataIndex: 'applications.studentIdNo',
            width: 80,
            render: item => {
                return <div style={{width: 80}}>{item}</div>
            }
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 120}}>
                        <Tooltip title={'Edit Student'}>
                            <a
                                className={'linkAA'}
                                onClick={() =>
                                    dispatch(
                                        getPushPathWrapper('student.editStudent', {
                                            id: record._id
                                        })
                                    )
                                }>
                                {item} <Icon type={'edit'}/>
                            </a>
                        </Tooltip>
                        <br/>
                        {newFormatDisplayDate(record.dateOfBirth)}
                        <label className={'label label-tag'}>
                            {record.studentShore ? `(${record.studentShore})` : null}
                        </label>
                    </div>
                )
            }
        },
        {
            title: 'Program',
            key: 'applications.courseName',
            dataIndex: 'applications.courseName',
            width: 150,
            render: item => {
                return <div style={{width: 150}}>{item}</div>
            }
        },
        {
            title: 'Intake',
            key: 'applications.intake',
            dataIndex: 'applications.intake',
            width: 80,
            render: (item, record) => {
                return (
                    <div style={{width: 80}}>
                        {item && item.month && item.year
                            ? `${item.month}, ${item.year}`
                            : ''}
                    </div>
                )
            }
        },
        {
            title: 'Campus',
            key: 'applications.campusId',
            dataIndex: 'applications.campusId',
            width: 85,
            render: (item, record) => {
                return (
                    <div style={{width: 85}}>
                        {item && item.campusName ? item.campusName : ''}
                    </div>
                )
            }
        },
        {
            title: 'Current User',
            key: 'applications.assignedUserId',
            dataIndex: 'applications.assignedUserId',
            width: 100,
            render: (val, record) => {
                let {agentId, statusList, status} = record.applications
                return (
                    <div style={{width: 100}}>
                        {val && val.name ? val.name : ''}
                        <br/>
                        <div>
                            Agent :{' '}
                            {agentId && agentId.companyName ? agentId.companyName : ''}
                            <br/>
                            {agentId && agentId.mobile ? (
                                <>
                                    {' '}
                                    <Icon
                                        type={'phone'}
                                        style={{
                                            position: 'relative',
                                            bottom: 3
                                        }}
                                    />{' '}
                                    {agentId.mobile}
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                        <div>{longDisplayDate(statusList.date)}</div>
                    </div>
                )
            }
        },
        {
            title: 'Family / Given Name',
            key: 'applications.familyName',
            dataIndex: 'applications.familyName',
            width: 100,
            render: (item, record) => {
                return (
                    <div style={{width: 100}}>
                        {item && item ? (
                            <div>
                                Family Name : <span style={{fontWeight: 'bold'}}>{item}</span>
                            </div>
                        ) : (
                            ''
                        )}
                        {record && record.applications && record.applications.givenName ? (
                            <div>
                                Given Name :{' '}
                                <span style={{fontWeight: 'bold'}}>
                  {record.applications.givenName}
                </span>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                )
            }
        },

        {
            title: 'Conditional Offer Letter',
            key: 'applications.offerReceiveLetter',
            dataIndex: 'applications.offerReceiveLetter',
            width: 100,
            render: (val, record) => {
                return (
                    <div style={{width: 100}} className={'fcmtDiv'}>
                        <OfferActionComponent file={val} record={record} events={events}/>
                    </div>
                )
            }
        },
        {
            title: 'Rqst LOA',
            key: 'applications.loaRequested',
            dataIndex: 'applications.loaRequested',
            width: 100,
            render: (val, record) => {
                return (
                    <div style={{width: 100}} className={'fcmtDiv'}>
                        <LoaRequestActionComponent
                            val={val}
                            record={record}
                            events={events}
                        />
                    </div>
                )
            }
        },
        {
            title: 'Download LOA',
            key: 'applications.loaLetter',
            dataIndex: 'applications.loaLetter',
            width: 100,
            render: (val, record) => {
                return (
                    <div style={{width: 100}} className={'fcmtDiv'}>
                        <LoaActionComponent val={val} record={record} events={events}/>
                    </div>
                )
            }
        },

        {
            title: 'Receipt',
            key: 'applications.receipt',
            dataIndex: 'applications.receipt',
            width: 80,
            render: (val, record) => {
                return (
                  <div style={{ width: 80 }} className={'fcmtDiv'}>
                      <ReceiptActionComponent val={val} record={record} events={events} />
                  </div>
                )
            }
        },
        {
            title: 'PAL',
            key: 'applications.palLetter',
            dataIndex: 'applications.palLetter',
            width: 80,
            render: (val, record) => {
                return (
                  <div style={{ width: 80 }} className={'fcmtDiv'}>
                      <PalActionComponent val={val} record={record} events={events} />
                  </div>
                )
            }
        },
        {
            title: 'Status',
            key: 'applications.status',
            dataIndex: 'applications.status',
            width: 150,
            render: (item, record) => {
                let { applications } = record
                return (
                  <div className={'fcmtDiv'} style={{ width: 150 }}>
                      {item.includes('File') || item.includes('Visa') || item.includes('Revoked') ? (
                            <div>
                                {item}
                                <br/>
                                {applications.statusList && applications.statusList.date ? (
                                    <div>
                                        Date : {newFormatDisplayDate(applications.statusList.date)}
                                        <br/>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                )
            }
        },

        {
            title: 'Latest Note',
            key: 'applications.latestComment',
            dataIndex: 'applications.latestComment',
            width: 250,
            render: val => {
                return (
                    <div style={{width: 220}}>
                        {val && val.text ? <RenderComment item={val}/> : null}
                    </div>
                )
            }
        },
        {
            title: 'GCKey',
            key: 'applications.gcKey',
            dataIndex: 'applications.gcKey',
            width: 150,
            render: (val, record) => {
                return (
                    <div style={{width: 150}}>
                        {val ? <RenderComment item={{text: val}}/> : null}
                    </div>
                )
            }
        },

        {
            title: 'Marketing',
            dataIndex: 'marketingUser',
            width: 120,
            key: 'marketingUser',
            render: (item, record) => {
                let {marketingUserId} = record.applications
                return (
                    <React.Fragment>
                        {marketingUserId && marketingUserId.name ? marketingUserId.name : ""}
                    </React.Fragment>
                )
            }
        },
    ]


    const loadColumns = () => {
        let columnArr = []
        lodash.each(columns, item => {
            if (item.hidden == undefined) {
                columnArr.push(item)
            } else {
                if (!item.hidden) {
                    columnArr.push(item)
                }
            }
        })
        return columnArr
    }

    const loadMarketingUsers = async () => {
        let obj = {
            userType: 'branchUser',
            department: departmentObj.marketing,
            results: 100,
            select: ['name', 'agents', 'assignStateCountry']
        }
        let {data} = await dispatch(listAllUsers(obj))
        setMarketingUserList(data)
    }

    const extra = (
        <div className={'mt10'}>
            <Row gutter={12}>
            <Col span={5}>
            <div className="sort-box-table custom-sort-box-new">
                <div className="search-box-table round">
                    <input
                        className="form-control form-control"
                        type="search"
                        placeholder="Search by App Id"
                        value={applicationId}
                        onChange={e => {
                            setApplicationId(e.target.value)
                        }}
                    />
                    <img src="/dist/img/search.png" alt=""/>
                </div>
            </div>
        </Col>
        <Col span={5}>
        <div className='sort-box-table custom-sort-box-new'>
            <div className='search-box-table round'>
                <input className='form-control form-control' type='search'
                       placeholder='Search by name...' value={name} onChange={(e) => {
                        setName(e.target.value)
                }}/>
                <img src='/dist/img/search.png' alt=''/>
            </div>
        </div>
    </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className="sort-box-table custom-sort-box-new">
                        <Select
                            name="Status"
                            onChange={item => setCampusId(item)}
                            className={'antSelect'}
                            placeholder={'Campus'}
                            allowClear={true}
                            value={campusId || undefined}>
                            {campusList && campusList.length
                                ? campusList.map((item, key) => {
                                    return (
                                        <Option value={item._id} key={key}>
                                            {item.campusName}
                                        </Option>
                                    )
                                })
                                : null}
                        </Select>
                    </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                  <div className='sort-box-table custom-sort-box-new'>
                    <AgentTypeheadComponent
                      key={agentId}
                      agentId={agentId}
                      dispatch={dispatch}
                      onSelect={item => {
                        setAgentId(item)
                      }}
                    />
                  </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By Marketing User'
                                filterOption={filterOption}
                                className={'antSelect'}
                                allowClear={true}
                                placeholder={'Marketing User'}
                                onChange={(item) => setMarketingUserId(item)} value={marketingUserId || undefined}>
                            {marketingUserList && marketingUserList.length ? marketingUserList.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>{item.name}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Intake' onChange={(item) => setIntake(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                allowClear={true}
                                showSearch={true}
                                placeholder={'Intake'}
                                value={intake || undefined}>
                            {Intakes && Intakes.length ? Intakes.map((item, key) => {
                                return (
                                    <Option value={item} key={key}>{item}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                <Col span={5}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <div className='search-box-table round'>
                            <input className='form-control form-control' type='search'
                                   placeholder='course name...' value={courseName} onChange={(e) => {
                                setCourseName(e.target.value)
                            }}/>
                            <img src='/dist/img/search.png' alt=''/>
                        </div>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="sort-box-table custom-sort-box-new">
                        <div className="search-box-table round">
                            <input
                                className="form-control form-control"
                                type="search"
                                placeholder="Student App Id"
                                value={studentAppId}
                                onChange={e => {
                                    setStudentAppId(e.target.value)
                                }}
                            />
                            <img src="/dist/img/search.png" alt=""/>
                        </div>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={12} lg={4}>
                    <Button onClick={() => searchFxn()} className={'roundBtn'}>
                        Search
                    </Button>
                    <Button onClick={() => clearFxn()} className={'roundBtn'}>
                        Clear
                    </Button>
                </Col>
            </Row>
        </div>
    )

    return (
        <React.Fragment>
            <div>
                <div className="row mt-4">
                    <div className="col-lg-12">
                        <div className="card">
                            <div
                                className="table-head d-flex align-items-center all-student-search"
                                style={{height: 60}}>
                                <Button
                                    onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                                    className={'roundGoBackBtn'}
                                    icon={'arrow-left'}>
                                    Back
                                </Button>
                                <h5>{universityName} Application List</h5>

                                <div className="sort-box-table mark-btn">
                                    {currentStatus} (Total - {totalApplication})
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                {extra}
                                <TableComp
                                    columns={loadColumns()}
                                    rowKey={(item, index) => {
                                        return index
                                    }}
                                    apiRequest={apiRequest}
                                    pagination={{
                                        position: 'top',
                                        showSizeChanger: true,
                                        pageSizeOptions: ['10', '20', '50', '100'],
                                        defaultPageSize: 10,
                                        current: filters.page ? filters.page : 1
                                    }}
                                    extraProps={{scroll: {x: 2500}}}
                                    ref={tableAppRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = ({global, router, chooseApplication}) => ({})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const WrappedComponent = Form.create()(ViewStudentApplication)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedComponent)
