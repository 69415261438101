import React, {useState} from "react"
import {Drawer, Form, Card, notification, Row, Col, Button} from "antd";
import {InputBox} from "../../../components/_utils/appUtils";
import GetEachFormFields from "../../../components/_utils/appFormUtils";
import {useDispatch} from "react-redux";
import {uploadMultiTuitionFeesDocumentFxn, uploadTuitionFeesDocumentFxn} from "../actions/fcmtAction";

const formItemLayout = {
    labelCol: {
        xs: {span: 0},
        sm: {span: 0},
        md: {span: 0}
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 24},
        md: {span: 24}
    }
}
const UploadTuitionFees = (props) => {
    let {studentId, applicationId, visible, onClose, onSubmit, form: {getFieldDecorator}} = props;
    let [document, setDocument] = useState({})
    let dispatch = useDispatch()
    const events = {
        chooseDocument: (e) => {
            let {name, files} = e.target
            if (files && files.length) {
                setDocument(files[0])
            }
        },
        handleSubmit: (e) => {
            const {form} = props
            e.preventDefault()
            form.validateFieldsAndScroll(async (err, valData) => {
                if (!err) {
                    if (!document || (document && !document.name)) {
                        return notification.error({
                            message: 'Please choose document'
                        })
                    }
                    let obj = {
                        ...valData,
                        applicationId: applicationId,
                        studentId: studentId
                    }

                    let fd = new FormData()
                    fd.append('obj', JSON.stringify(obj))
                    if (document && document.name) {
                        fd.append("document", document);
                    }
                    let resp = await dispatch(uploadMultiTuitionFeesDocumentFxn(fd));
                    if (resp && resp.success) {
                        onSubmit()
                    }
                } else {
                    notification.warning({
                        message: 'Fill All Required Fields'
                    })
                }
            })
        }
    }
    let inputTypes = {
        fields: [
            {
                key: 'amount',
                label: 'Enter tuition fees',
                placeholder: 'Enter tuition fees',
                type: 'number',
                required: true
            },
            {
                key: 'date',
                label: 'Transaction date',
                placeholder: 'Transaction date',
                type: 'date',
                required: true
            },
            {
                key: 'briefOfTransaction',
                label: 'Brief of Transaction',
                placeholder: 'Brief of Transaction',
                type: 'textarea',
                rows: 4
            },
            {
                key: 'document',
                label: 'Choose date',
                placeholder: 'Choose date',
                customField: (
                    <>
                        <InputBox title={'Choose Tuition fees'}>
                            <input type={'file'} className={'form-control'}
                                   onChange={(e) => {
                                       events.chooseDocument(e)
                                   }}
                            />
                        </InputBox>
                    </>
                )
            },
            {
                key: 'details',
                label: 'Enter Details',
                placeholder: 'Enter Details',
                type: 'textarea',
                rows: 4
            },

        ]
    }

    return (
        <Drawer
            width={"45%"}
            title={'Upload Tuition Fees & Generate Travel Document'}
            visible={visible}
            onClose={onClose}>
            <Card bordered={false}>
                <Form
                    onSubmit={events.handleSubmit}
                    hideRequiredMark
                    className={'inputForm'}>
                    <Row gutter={16}>
                        {inputTypes.fields.map((item) => {
                            return (
                                <Col span={24} key={item.key}>
                                    {item.customField ? item.customField : <GetEachFormFields
                                        item={item}
                                        getFieldDecorator={getFieldDecorator}
                                        formItemLayout={formItemLayout}
                                    />}
                                </Col>
                            )
                        })}
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Drawer>
    )
}
const UploadTuitionFeesWrapper = Form.create()(UploadTuitionFees)
export default UploadTuitionFeesWrapper
