import {Skeleton, Drawer} from 'antd'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import renderHTML from 'react-render-html'
import {
    longStringDate,
} from '../../../components/_utils/appUtils'
import {listWhatsNewFxn} from '../actions/whatsNew'
import _ from "lodash"

const SingleWhatsNewComponentOld = (props) => {
    let {item} = props;
    const [showContent, setShowContent] = useState(true)

    const toggleContent = () => {
        setShowContent(!showContent)
    }
    return (
        <>
            <div key={item._id}
                 className={`custom-container ${
                     showContent ? 'show' : 'hide'
                 }`}>
                <div className="custom-header">
                    <p className="custom-date">
                        <img
                            src={'../dist/icons/calendar.png'}
                            style={{width: '20px', marginRight: '10px'}}
                        />
                        {longStringDate(item.createdAt)}
                    </p>
                    <h3 className="custom-title">
                        {item.title}
                    </h3>
                </div>

                <div
                    className={`custom-content`}>
                    {showContent ? renderHTML(item.content) : renderHTML(item.content.slice(0, 200))}
                    {item.content.length > 200 && (
                        <div className={'alignRight'}>
                            <a className={'showMoreBtn'}
                               onClick={() => toggleContent()}>
                                {showContent ? 'Show Less' : 'Show More'}
                            </a>
                        </div>
                    )}
                </div>
                {item.attachment && item.attachment.url ?
                    <img src={item.attachment.url} style={{width: "60%"}}/> : null}
            </div>

            <hr className="line"/>

        </>
    )
}

const SingleWhatsNewComponent = (props) => {
    let {item} = props;
    const [showContent, setShowContent] = useState(true)

    const toggleContent = () => {
        setShowContent(!showContent)
    }
    return (
        <>
            <div key={item._id} className={'whatsNewBox'}>
                <div className={'flex-row'}>
                    <img src={'../dist/icons/marketing.png'} className={'icon'}/>
                    <div>
                        <div className={'title'}>
                            {item.title}
                        </div>
                        <div className={'dateRow'}>
                            <i className={'fa fa-calendar'}></i> {longStringDate(item.date)}
                        </div>
                        <div className={'content-box'}>
                            {renderHTML(item.content)}
                        </div>
                        {item.attachment && item.attachment.url ? <div className={'img-box'}>
                            <img src={item.attachment.url}/>
                        </div> : null}
                    </div>
                </div>

            </div>
        </>
    )
}


const WhatsNew = props => {
    let dispatch = useDispatch()
    let {visible, onClose, width = "45%"} = props
    let [whatsNew, setWhatsNew] = useState([])
    let [total, setTotal] = useState(0)
    let [pageObj, setPageObj] = useState({
        page: 1,
        count: 20
    })
    const loadWhatsNewList = async () => {
        let params = _.clone(pageObj);
        let resp = await dispatch(listWhatsNewFxn({...params, regExFilters: ['title', 'content']}))
        setTotal(resp.total)
        setWhatsNew((prevState) => {
            return [
                ...prevState,
                ...resp.data
            ]
        })

        setPageObj((prevState) => {
            return {
                ...prevState,
                page: pageObj.page + 1
            }
        })
    }

    useEffect(() => {
        loadWhatsNewList()
    }, [])

    const loadMore = () => {
        loadWhatsNewList()
    }

    return (
        <Drawer
            visible={visible}
            title={`Whats New`}
            onClose={onClose}
            width={width}>
            <div className={'whatsNewCard'}>
                {whatsNew && whatsNew.length
                    ? whatsNew.map((item, i) => {
                        return (
                            <>
                                <SingleWhatsNewComponent item={item}/>
                            </>
                        )
                    })
                    : <Skeleton/>}

                {total && whatsNew.length < total ? <>

                    <div className={'alignCenter p-2'}>
                        <a className={'btn btn-outline-primary'}
                           onClick={() => {
                               loadMore()
                           }}>Load More</a>
                    </div>

                </> : null}

            </div>
        </Drawer>
    )
}
export default WhatsNew
