import React, {Component} from 'react'
import {TableComp} from 'sz-react-utils-lite'
import {
    Card,
    Tooltip,
    Icon, Drawer, List, Popconfirm, Switch, Tag
} from 'antd'
import {connect} from 'react-redux'
import {
    listAllUsers,
    blockAgent,
    updateDefaultReferralApi,
    updateShareReferralAllowApi,
    getUserRating
} from '../actions/user'
import {ConditionOptions, DefaultTablePagination, managerType} from '../../../components/_utils/appUtils'
import {listAllCountries} from '../../countries/actions/countries'
import UpdatePassword from '../views/changePassword'
import RatingDrawer from '../views/ratingDrawer'
import EditUser from '../views/edit'
import EditUserExtraInfo from '../views/editExtraInfo'
import EditSubAgent from '../views/editSubAgent'
import {CheckMyRights} from '../../WebComponent/CheckUserRights'
import {ColumnWidth} from '../../WebComponent/columnWidth'
import {push} from 'connected-react-router'
import UserRightList from '../list/rightsList'
import {CheckUserRight} from '../../dashboard/views/DashboardUserWise'
import LogsComponents from '../drawer/logsComponents'
import lodash from 'lodash'

const branchManagerObj = {
    agentWise: 'Agent Wise',
    universityWise: 'University Wise',
    studentWise: 'Student Wise',
    onShoreWise: 'Onshore Wise',
}

class AllUsers extends Component {

    events = {
        showUserRightDrawer: (data) => {
            this.setState({
                visibleUserRightDrawer: true,
                selectedUser: data
            })
        },
        hideUserRightDrawer: () => {
            this.setState({
                visibleUserRightDrawer: false,
                selectedUser: {}
            })
        },
        closeAndUpdateUserRightDrawer: () => {
            this.setState({
                visibleUserRightDrawer: false,
                selectedUser: {}
            }, () => {
                this.tableRef.current.reload()
            })
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            viewInfoDrawer: false,
            selectedRow: {},
            allAgents: [],
            allBranchManager: [],
            allCountry: [],
            selectedUser: {},
            visibleUpdatePassword: false,
            visibleUpdateUser: false,
            visibleUserExtraInfo: false,
            visibleUpdateSubAgent: false,

            visibleUserRightDrawer: false,
            userPointsList: {}
        }
        this.tableRef = React.createRef()
    }

    componentDidMount() {
        this.loadAllAgent()
        this.loadAllBranchManager()
        this.loadAllCountry()
        this.getUserPointsById()
    }


    apiRequest = (params) => {
        let {dispatch} = this.props
        return new Promise(async (resolve) => {
            params.sortField = 'name'
            params.sortOrder = 'ascend'

            if (!params.userType || (params.userType && params.userType.length == 0)) {
                // params.userType = { $ne: 'student' }
                params.userType = ['branchManager']
            }
            let resp = await dispatch(listAllUsers({
                ...params,
                regExFilters: ['name', 'address', 'mobile', 'email']
            }))
            this.setState({totalBranchManager: resp.total})
            resolve(resp)
            // resolve(dispatch(listAllUsers({
            //   ...params,
            //   regExFilters: ['name', 'address', 'mobile', 'email']
            // })))
        })
    }

    async loadAllAgent() {
        let {dispatch} = this.props
        let {data} = await dispatch(listAllUsers({userType: 'agent'}))
        this.setState({allAgents: data})
    }

    async loadAllBranchManager() {
        let {dispatch} = this.props
        let {data} = await dispatch(listAllUsers({userType: 'branchManager'}))
        this.setState({allBranchManager: data})
    }

    async loadAllCountry() {
        let {dispatch} = this.props
        let {data} = await dispatch(listAllCountries())
        this.setState({allCountry: data})
    }


    viewInfo(record) {
        this.setState({
            selectedRow: record,
            viewInfoDrawer: true
        })
    }

    onClose = () => {
        this.setState({
            selectedRow: {},
            viewInfoDrawer: false
        })
    }

    closeUpdatePassword = () => {
        this.setState({
            selectedUser: {},
            visibleUpdatePassword: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    closeEditUser = () => {
        this.setState({
            selectedUser: {},
            visibleUpdateUser: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    async getUserPointsById() {
        let {dispatch} = this.props
        let userId = '6155b4fafb367853d413628b'
        let data = await dispatch(getUserRating({}))
        console.log('6155b4fafb367853d413628b points', data)
        let obj = {}
        lodash.each(data, (item) => {
            if (item && item.userId) {
                obj[item.userId] = item.totalPoints ? item.totalPoints : ''
            }
        })
        this.setState({userPointsList: obj})
    }

    closeEditSubAgent = () => {
        this.setState({
            selectedUser: {},
            visibleUpdateSubAgent: false
        }, () => {
            this.tableRef.current.reload()
        })
    }
    updateAgentBlockStatus = async (id) => {
        let {dispatch} = this.props
        let resp = await dispatch(blockAgent({agentId: id}))
        if (resp && resp.success) {
            this.tableRef.current.reload()
        }
    }

    makeDefaultReferralFxn = async (id) => {
        let {dispatch} = this.props
        let resp = await dispatch(updateDefaultReferralApi({userId: id}))
        if (resp && resp.success) {
            this.tableRef.current.reload()
        }
    }
    allowShareReferralFxn = async (id) => {
        let {dispatch} = this.props
        let resp = await dispatch(updateShareReferralAllowApi({userId: id}))
        if (resp && resp.success) {
            this.tableRef.current.reload()
        }
    }

    closeEditUserExtraInfo = () => {
        this.setState({
            selectedUser: {},
            visibleUserExtraInfo: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    closeRatingDrawer = () => {
        this.setState({
            selectedUser: {},
            visibleRatingDrawer: false
        }, () => {
            this.tableRef.current.reload()
            this.getUserPointsById()
        })
    }

    render() {
        let {
            selectedRow,
            allAgents,
            allBranchManager,
            allCountry,
            visibleUpdatePassword,
            selectedUser,
            visibleUpdateUser,
            visibleUserExtraInfo,
            visibleRatingDrawer,
            visibleUpdateSubAgent,
            visibleUserRightDrawer,
            totalBranchUser
        } = this.state
        let {dispatch} = this.props
        const columns = [
            {
                title: '#',
                key: '_id',
                dataIndex: '_id',
                width: 50,
                render: (ite, record, index) => {
                    return (
                        <React.Fragment>
                            {index + 1}
                        </React.Fragment>
                    )
                }
            },
            {
                title: 'Name',
                key: 'name',
                sorter: true,
                dataIndex: 'name',
                searchTextName: 'name',
                width: 100,
                render: (item, record) => {
                    let {referCode, branchManagerType} = record
                    return (
                        <ColumnWidth width={100}>
                            {item}
                            {record.postName ? <div>({record.postName})</div> : null}
                            {branchManagerType == 'studentWise' ?
                                <div className={'mt5'}><Tag color='#0f52ba'>Refer Code - {referCode}</Tag></div> : null}
                        </ColumnWidth>
                    )
                }
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                searchTextName: 'email',
                width: 120,
                render: (item) => {
                    return (
                        <ColumnWidth width={120}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Mobile No',
                dataIndex: 'mobile',
                key: 'mobile',
                searchTextName: 'mobile',
                width: 80,
                render: (item) => {
                    return (
                        <ColumnWidth width={80}>{item}</ColumnWidth>
                    )
                }
            },
            /* {
               title: 'Address',
               dataIndex: 'address',
               key: 'address',
               searchTextName: 'address',
               width: 150,
               render: (item) => {
                 return (
                   <ColumnWidth width={150}>{item}</ColumnWidth>
                 )
               }
             },*/

            {
                title: 'Country',
                dataIndex: 'countryId',
                key: 'countryId',
                filters: allCountry.map(x => ({value: x._id, text: x.countryName})),
                width: 100,
                render: (item) => {
                    return (
                        <ColumnWidth width={100}>{item && item.countryName ? item.countryName : ''}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Provisions',
                dataIndex: 'assignProvisions',
                key: 'assignProvisions',
                width: 100,
                render: (item, record) => {
                    let { assignProvisions } = record;
                    return (
                        <ColumnWidth width={100}>
                            {assignProvisions && assignProvisions.length ? assignProvisions.join(', ') : ''}
                        </ColumnWidth>
                    );
                }
            },


            {
                title: ' Manager Type',
                dataIndex: 'branchManagerType',
                key: 'branchManagerType',
                filters: managerType.map(x => ({value: x.value, text: x.name})),
                width: 100,
                render: (item) => {
                    return item ? branchManagerObj[item] : null
                }
            },

            {
                title: 'User Rating',
                dataIndex: 'userRating',
                key: 'userRating',
                width: 100,
                render: (item, {_id}) => {
                    let {userPointsList} = this.state
                    // console.log(record._id,userPointsList)
                    return userPointsList[_id] ? `${userPointsList[_id]} points` : ''
                    // return null
                }
            },

            {
                title: 'Default Refer Email',
                dataIndex: 'defaultEmailCode',
                key: 'defaultEmailCode',
                width: 80,
                render: (item, record) => {
                    let {branchManagerType, defaultReferral} = record
                    return (
                        branchManagerType == 'studentWise' && <div>
                            <div>
                                <Popconfirm title={`Are your sure, you want make ${item} Default Refer Email?`}
                                            onConfirm={() => {
                                                this.makeDefaultReferralFxn(record._id)
                                            }}
                                            okText='Yes' cancelText='No'>
                                    <Switch
                                        checkedChildren={'Yes'}
                                        unCheckedChildren={'No'}
                                        checked={defaultReferral}
                                    />
                                </Popconfirm>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: 'Enable Student Refer',
                dataIndex: 'studentSharing',
                key: 'studentSharing',
                width: 80,
                render: (item, record) => {
                    let {branchManagerType, shareReferralAllow} = record
                    return (
                        branchManagerType == 'studentWise' && <div>
                            <div>
                                <Popconfirm
                                    title={`Are your sure, you want to ${shareReferralAllow ? 'Disable Student Refer' : 'Enable Student Refer'} this User?`}
                                    onConfirm={() => {
                                        this.allowShareReferralFxn(record._id)
                                    }}
                                    okText='Yes' cancelText='No'>
                                    <Switch
                                        checkedChildren={'Yes'}
                                        unCheckedChildren={'No'}
                                        checked={shareReferralAllow}
                                    />
                                </Popconfirm>
                            </div>
                        </div>
                    )
                }
            },
            /*  {
                title: 'Referral Code',
                dataIndex: 'referCode',
                key: 'referCode',
                width: 80,
                render: (item, record) => {
                  let { branchManagerType, defaultReferral, shareReferralAllow } = record
                  return (
                    branchManagerType == 'studentWise' && <div>
                      {item ? item : null}
                      <div>
                        <Popconfirm title={`Are your sure, you want make ${item} default referral?`}
                                    onConfirm={() => {
                                      this.makeDefaultReferralFxn(record._id)
                                    }}
                                    okText='Yes' cancelText='No'>
                          {defaultReferral ? <div className={'label label-success labelNew'}>Default Refer</div> :
                            <div className={'label label-warning labelNew'}>
                              Refer Code
                            </div>}
                        </Popconfirm>
                      </div>
                      <div className={'mt10'}>
                        <Popconfirm
                          title={`Are your sure, you want to ${shareReferralAllow ? 'Block Sharing Referral Code' : 'Allow Sharing Referral Code'} this User?`}
                          onConfirm={() => {
                            this.allowShareReferralFxn(record._id)
                          }}
                          okText='Yes' cancelText='No'>
                          {shareReferralAllow ? <div className={'label label-success labelNew'}>Allow Sharing</div> :
                            <div className={'label label-warning labelNew'}>
                              Block Sharing
                            </div>}
                        </Popconfirm>
                      </div>
                    </div>
                  )
                }
              },*/
            /*
                  {
                    title: 'Logo',
                    dataIndex: 'logo',
                    key: 'logo',
                    width: 100,
                    render: (val) => {
                      return (
                        <ColumnWidth width={100}>  {val && val.url ? <img src={val.url} className={'imgSm'}/> : null}</ColumnWidth>
                      )
                    }
                  },*/
            {
                title: 'Block',
                dataIndex: 'block',
                width: 80,
                key: 'block',
                filters: ConditionOptions.map(x => ({value: x.value, text: x.name})),
                render: (item, record) => {
                    return (
                        <React.Fragment>
                            {record && record.userType && (record.userType !== 'admin') ?
                                <div>
                                    <Popconfirm
                                        title={`Are your sure, you want to ${item ? 'Un-Block' : 'Block'} this User?`}
                                        onConfirm={() => {
                                            this.updateAgentBlockStatus(record._id)
                                        }}
                                        okText='Yes' cancelText='No'>
                                        {item ? <div className={'label label-success labelNew'}>Yes</div> :
                                            <div className={'label label-warning labelNew'}>No</div>}
                                    </Popconfirm>
                                </div> : null}
                        </React.Fragment>
                    )
                }
            },
            {
                title: 'Action',
                key: '_id',
                dataIndex: '_id',
                width: 150,
                render: (val, record) => {
                    return (
                        <React.Fragment>
                            {record.userType !== 'master' && this.props.currentUser.userType !== 'hr' ?
                                <Tooltip title='Edit'>
                                    <button
                                        className={'btn'}
                                        style={{marginRight: 6}}
                                        onClick={() => {
                                            {
                                                record.userType == 'subAgent' ?
                                                    this.setState({
                                                        visibleUpdateSubAgent: true,
                                                        selectedUser: record
                                                    }) :
                                                    this.setState({
                                                        visibleUpdateUser: true,
                                                        selectedUser: record
                                                    })
                                            }
                                        }}>
                                        <Icon type={'edit'}/>
                                    </button>
                                </Tooltip> : null}

                            {this.props.currentUser.userType == 'hr' ?
                                <Tooltip title='Edit Extra info'>
                                    <button
                                        className={'btn'}
                                        style={{marginRight: 6}}
                                        onClick={() => {
                                            this.setState({
                                                visibleUserExtraInfo: true,
                                                selectedUser: record
                                            })
                                        }}>
                                        <Icon type={'edit'}/>
                                    </button>
                                </Tooltip> : null}

                            {this.props.currentUser.userType == 'hr' ?
                                <Tooltip title='Rating'>
                                    <button
                                        className={'btn'}
                                        style={{marginRight: 6}}
                                        onClick={() => {
                                            this.setState({
                                                visibleRatingDrawer: true,
                                                selectedUser: record
                                            })
                                        }}>
                                        <Icon type={'trophy'}/>
                                    </button>
                                </Tooltip> : null}

                            <Tooltip title='Change Password'>
                                <button
                                    className={'btn'}
                                    style={{marginRight: 6}}
                                    onClick={() => {
                                        this.setState({
                                            selectedUser: record,
                                            visibleUpdatePassword: true
                                        })
                                    }}>
                                    <Icon type={'key'}/>
                                </button>
                            </Tooltip>
                            {record.userType == 'branchUser' ?
                                <Tooltip title='Universities'>
                                    <button
                                        className={'btn'}
                                        onClick={() => {
                                            this.viewInfo(record)
                                        }}
                                    >
                                        <Icon type={'eye'}/>
                                    </button>
                                </Tooltip> : ''}

                            <CheckUserRight user={this.props.currentUser}
                                            rightUserType={['admin', 'branchManager', 'userManager']}>
                                <Tooltip title='Rights'>
                                    <button
                                      className={'btn'}
                                      onClick={() => {
                                          this.events.showUserRightDrawer(record)
                                      }}
                                    ><Icon type='trophy' />
                                    </button>
                                </Tooltip>
                            </CheckUserRight>
                            <LogsComponents userData={record} {...this.props} currentUser={this.props.currentUser} />
                        </React.Fragment>
                    )
                }
            }
        ]
        return (
            <div>

                <div className='row  mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head d-flex align-items-center'>
                                <h5>All Branch Manager ({this.state.totalBranchManager})</h5>


                                <CheckMyRights rightList={['admin', 'userManager']}>
                                    <div className='sort-box-table mark-btn row mark-btn70'>
                                        <div>
                                            <a className='btn' onClick={() => dispatch(push('/user/add-user'))}>
                                                <img src={'../dist/icons/plus.png'} className={'plus'}/> Add User</a>
                                        </div>
                                        <div className={'ml'}>
                                          <a className='btn'
                                             onClick={() => dispatch(push('/user/add-marking-user'))}>
                                            <img src={'../dist/icons/plus.png'} className={'plus'} /> Add Marketing User</a>
                                        </div>

                                        <div className={'ml'}>
                                            <a className='btn' onClick={() => dispatch(push('/user/add-counsellor'))}>
                                                <img src={'../dist/icons/plus.png'} className={'plus'}/> Add Counsellor</a>
                                        </div>
                                        <div className={'ml'}>
                                            <a className='btn' onClick={() => dispatch(push('/user/add-master'))}>
                                                <img src={'../dist/icons/plus.png'} className={'plus'}/> Add Master</a>
                                        </div>
                                    </div>
                                </CheckMyRights>
                            </div>
                            <div className='card-body table-responsive'>
                                <TableComp columns={columns}
                                           ref={this.tableRef}
                                           pagination={DefaultTablePagination()}
                                           apiRequest={this.apiRequest}
                                           extraProps={{scroll: {x: 1000}}}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <Drawer visible={this.state.viewInfoDrawer}
                        width={520}
                        onClose={this.onClose}>
                    <Card title={'Universities'}>
                        {selectedRow && selectedRow.universities && selectedRow.universities.length ?
                            <List
                                size='small'
                                bordered
                                dataSource={selectedRow.universities}
                                renderItem={item =>
                                    <List.Item>{item && item.universityName ? item.universityName : null}</List.Item>}
                            /> : null
                        }

                    </Card>
                </Drawer>
                {visibleUpdatePassword ? <UpdatePassword
                    visible={visibleUpdatePassword}
                    onClose={() => this.closeUpdatePassword()}
                    userData={selectedUser}/> : ''}

                {visibleUpdateUser ? <EditUser
                    visible={visibleUpdateUser}
                    onClose={() => this.closeEditUser()}
                    userData={selectedUser}/> : ''}

                {visibleUserExtraInfo ? <EditUserExtraInfo
                    visible={visibleUserExtraInfo}
                    onClose={() => this.closeEditUserExtraInfo()}
                    userData={selectedUser}/> : ''}

                {visibleRatingDrawer ? <RatingDrawer
                    visible={visibleRatingDrawer}
                    onClose={() => this.closeRatingDrawer()}
                    userData={selectedUser}/> : ''}

                {visibleUpdateSubAgent ? <EditSubAgent
                    visible={visibleUpdateSubAgent}
                    onClose={() => this.closeEditSubAgent()}
                    userData={selectedUser}/> : ''}

                {visibleUserRightDrawer ? <UserRightList
                    user={selectedUser}
                    visible={visibleUserRightDrawer}
                    onClose={() => this.events.hideUserRightDrawer()}
                    closeAndUpdate={() => this.events.closeAndUpdateUserRightDrawer()}/> : ''}

            </div>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllUsers)
