import {notification} from 'antd'
import {getToken, customAxios as axios} from '../../request'
import {showPageLoad, hidePageLoad} from '../../modules/actions'
import {
    addEventUrl,
    listAllEventsUrl, removeSingleImageUrl,
    updateEventStatusUrl,
    updateEventUrl,
    updateRegistrationStatusUrl,
    uploadEventGalleryUrl
} from './api'
import {singleEventUrl} from '../FrontendComponents/Webinar/api'


export const addEventAction = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addEventUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const listAllEvents = (filters) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
    }
    let {data} = await axios.get(listAllEventsUrl(), config);
    dispatch(hidePageLoad())
    return data.data;
}


export const updateEventStatus = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateEventStatusUrl(), valData, getToken())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    dispatch(hidePageLoad())
    return data
}

export const updateRegistrationStatus = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateRegistrationStatusUrl(), valData, getToken())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    dispatch(hidePageLoad())
    return data
}

export const SingleEventsFxn = (id) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
    }
    let {data} = await axios.get(singleEventUrl(id), config);
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data.data;

}

export const updateEventFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateEventUrl(), valData, getToken())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    dispatch(hidePageLoad())
    return data
}

export const uploadEventGalleryFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(uploadEventGalleryUrl(), valData, getToken())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    dispatch(hidePageLoad())
    return data
}

export const removeSingleImageFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(removeSingleImageUrl(), valData, getToken())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    dispatch(hidePageLoad())
    return data
}
