import {
  Button,
  Col,
  DatePicker,
  Form,
  Icon,
  Row,
  Select,
  Tooltip
} from 'antd'
import lodash from 'lodash'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  NationalCollege,
  filterOption,
  newFormatDisplayDate
} from '../../../components/_utils/appUtils'
import { getUrlPushWrapper } from '../../../routes'
import { listAllCampus } from '../../campus/actions/campus'

import AddNote from '../../applications/drawers/createNoteComponent'
import FcmtStatusComponent from '../../applications/drawers/fcmtStatus'
import { fcmtCollegeStatusFilterList } from '../../../components/_utils/ApplicationStatusSteps'
import UploadCornerStoneDocument from './components/uploadDocuments'
import StudentInfoDrawer from '../../applications/views/oxfordUniversity/drawers/studentInfoDrawer'
import StudentDocumentDrawer from '../../applications/drawers/studentDocumentDrawer'
import { getNationalCollegeLoaListFxn } from '../actions/nationalCollege'

const { RangePicker } = DatePicker
const { Option } = Select
const initialState = {
  applicationList: [],
  applicationObj: {}
}

const LoaRequestActionComponent = props => {
  let { record } = props
  let { applications } = record
  return (
    <div>
      {applications && applications.loaDocument && applications.loaDocument.length ? (
        <>
          {applications.loaDocument.map((item) => {
            return (
              item && item.status ? <div className={'mb5'}>
                <Tooltip title={`Download ${item.status}`}>
                  <a className={'btn btn-default btn-xs'} href={item.url} target={'_blank'}>
                    {item.status}
                  </a>
                </Tooltip>
              </div> : null
            )
          })}
        </>
      ) : null}
    </div>
  )
}
const LoaActionComponent = props => {
  let { record, val, events } = props
  return (
    <>
      <Tooltip title={`${val && val.path ? 'R-Upload LOA' : 'Upload LOA'}`}>
        <a onClick={() => events.showUploadDocument(record, 'loaLetter', 'LOA Letter')}>
          <img src={'/assets/icons/cloud-computing-upload.png'} />
        </a>
      </Tooltip>

      {val && val.path ? (
        <div className={'mt5'}>
          <Tooltip title={'Download LOA'}>
            <a download={val.fileName} href={val.path} className={'btn btn-default btn-xs'}>
              Download LOA
            </a>
          </Tooltip>
        </div>
      ) : null}
    </>
  )
}
const ReceiptActionComponent = props => {
  let { record, val, events } = props
  return (
    <>
      <Tooltip title={`${val && val.path ? 'R-Upload Receipt' : 'Upload Receipt'}`}>
        <a onClick={() => events.showUploadDocument(record, 'receipt', 'Receipt')}>
          <img src={'/assets/icons/cloud-computing-upload.png'} />
        </a>
      </Tooltip>

      {val && val.path ? (
        <div className={'mt5'}>
          <Tooltip title={'Download Receipt'}>
            <a download={val.fileName} href={val.path} className={'btn btn-default btn-xs'}>
              Download Receipt
            </a>
          </Tooltip>
        </div>
      ) : null}
    </>
  )
}
const PalActionComponent = props => {
  let { record, val, events } = props
  return (
    <>
      <Tooltip title={`${val && val.path ? 'R-Upload PAL' : 'Upload PAL'}`}>
        <a onClick={() => events.showUploadDocument(record, 'palLetter', 'Pal')}>
          <img src={'/assets/icons/cloud-computing-upload.png'} />
        </a>
      </Tooltip>

      {val && val.path ? (
        <div className={'mt5'}>
          <Tooltip title={'Download Pal'}>
            <a download={val.fileName} href={val.path} className={'btn btn-default btn-xs'}>
              Download PAL
            </a>
          </Tooltip>
        </div>
      ) : null}
    </>
  )
}

const NationalCollegeLoaApplicationList = props => {
  const [state, setState] = useState(initialState)
  const [studentData, setStudentData] = useState({})
  const [name, setName] = useState('')
  const [agentId, setAgentId] = useState('')
  const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
  const [totalApplication, setTotalApplication] = useState(0)
  const [currentStatus, setCurrentStatus] = useState('')
  const [status, setStatus] = useState('')
  let { applicationObj } = state
  let [tableSearch, setTableSearch] = useState(false)
  let [filters, setFilters] = useState({})
  let [visibleFcmtStatus, setVisibleFcmtStatus] = useState(false)
  let [campusList, setCampusList] = useState([])
  const [campusId, setCampusId] = useState('')
  const [branchUserId, setBranchUserId] = useState('')
  const [intake, setIntake] = useState('')
  let [courseName, setCourseName] = useState('')
  let [studentAppId, setStudentAppId] = useState('')
  let [studentShore, setStudentShore] = useState('')
  let [id, setId] = useState('')
  let [marketingUserId, setMarketingUserId] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')

  let [studentState, setStudentState] = useState({
    visible: false,
    student: {}
  })
  let [docState, setDocState] = useState({
    applicationId: '',
    studentId: '',
    documentName: '',
    name: '',
    visible: false,
    studentData: {}
  })
  let [uploadDocState, setUploadDocState] = useState({
    applicationId: '',
    studentId: '',
    visible: false,
    studentData: {}
  })

  const dispatch = useDispatch()
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  const tableAppRef = useRef()

  let apiRequest = params => {
    return new Promise(async resolve => {
      let resp = await getParamsForApplicationList()
      if (resp && resp.id) {
        resp['applications.id'] = [resp.id]
        delete resp['id']
      }
      params = { ...params, ...resp }
      if (!params.results) {
        params.results = 50
        params.count = 50
      }
      if (tableSearch) {
        params.page = 1
      }
      try {
        setFilters(params)
        let resp = await dispatch(getNationalCollegeLoaListFxn({ ...params }))
        setTotalApplication(resp.total)
        setTableSearch(false)
        resolve(resp)
      } catch (e) {
      }
    })
  }
  useEffect(() => {
    loadCampusList()
    setFieldByParams()
  }, [])

  const setFieldByParams = async () => {
    let resp = await getParamsForApplicationList()

    if (resp.campusId) {
      setCampusId(resp.campusId)
    }
    if (resp.status) {
      setStatus(resp.status)
    }
    if (resp.agentId) {
      setAgentId(resp.agentId)
    }
    if (resp.intake) {
      setIntake(resp.intake)
    }
    if (resp.courseName) {
      setCourseName(resp.courseName)
    }
    if (resp.studentAppId) {
      setStudentAppId(resp.studentAppId)
    }
    if (resp.studentShore) {
      setStudentShore(resp.studentShore)
    }
    if (resp.branchUserId) {
      setBranchUserId(resp.branchUserId)
    }
    if (resp.id) {
      setId(resp.id)
    }
    if (resp.marketingUserId) {
      setMarketingUserId(resp.marketingUserId)
    }
    if (resp.toDate) {
      setToDate(moment(resp.toDate, 'MM-DD-YYYY'))
    }
    if (resp.fromDate) {
      setFromDate(moment(resp.fromDate, 'MM-DD-YYYY'))
    }
  }


  const loadCampusList = async () => {
    let params = {}
    params.sortField = 'campusName'
    params.sortOrder = 'ascend'
    params.campusUniversity = NationalCollege
    params.results = 50
    params.count = 50
    params.select = ['campusName']
    let { data } = await dispatch(
      listAllCampus({
        ...params,
        regExFilters: ['campusAddress', 'campusName']
      })
    )
    setCampusList(data)
  }

  const events = {
    reloadFxn: () => {
      tableAppRef.current.reload()
    },
    refreshApplication: () => {
      tableAppRef.current.reload()
    },
    openFcmtStatusDrawer: record => {
      setStudentData(record)
      setVisibleFcmtStatus(true)
    },
    closeFcmtStatusDrawer: () => {
      setStudentData({})
      setVisibleFcmtStatus(false)
    },
    showUploadDocument: (record, documentName, name) => {
      setDocState({
        studentId: record._id,
        applicationId: record.applications._id,
        visible: true,
        documentName: documentName,
        name: name,
        studentData: record
      })
    },
    hideUploadDocument: () => {
      setDocState({
        studentId: '',
        applicationId: '',
        visible: false,
        documentName: '',
        name: '',
        studentData: {}
      })
    },
    openStudentState: (record) => {
      setStudentState({
        visible: true,
        student: record
      })
    },
    closeStudentState: () => {
      setStudentState({
        visible: false,
        student: {}
      })
    },
    openDocumentDrawer: (record) => {
      setUploadDocState({
        visible: true,
        studentData: record,
        studentId: record._id,
        applicationId: record.applications._id
      })
    },
    closeDocumentDrawer: () => {
      setUploadDocState({
        visible: false,
        studentData: {},
        studentId: '',
        applicationId: ''
      })
    }
  }
  const getParamsForApplicationList = () => {
    return new Promise(resolve => {
      let searchParams = new URLSearchParams(window.location.search)
      let appUniversityId = searchParams.get('appUniversityId')
      let name = searchParams.get('name')
      let campusId = searchParams.get('campusId')
      let status = searchParams.get('status')
      let agentId = searchParams.get('agentId')
      let branchUserId = searchParams.get('branchUserId')
      let intake = searchParams.get('intake')
      let courseName = searchParams.get('courseName')
      let studentShore = searchParams.get('studentShore')
      let marketingUserId = searchParams.get('marketingUserId')
      let fromDate = searchParams.get('fromDate')
      let toDate = searchParams.get('toDate')
      let id = searchParams.get('id')
      let studentAppId = searchParams.get('studentAppId')
      let obj = {}

      if (appUniversityId) {
        obj.appUniversityId = appUniversityId
      }
      if (name) {
        obj.name = name
      }
      if (id) {
        obj.id = id
      }

      if (campusId) {
        obj.campusId = campusId
      }
      if (status) {
        obj.status = status
      }
      if (agentId) {
        obj.agentId = agentId
      }
      if (intake) {
        obj.intake = intake
      }
      if (courseName) {
        obj.courseName = courseName
      }
      if (studentAppId) {
        obj.studentAppId = studentAppId
      }
      if (studentShore) {
        obj.studentShore = studentShore
      }

      if (branchUserId) {
        obj.branchUserId = branchUserId
      }
      if (marketingUserId) {
        obj.marketingUserId = marketingUserId
      }
      if (fromDate) {
        obj.fromDate = fromDate
      }
      if (toDate) {
        obj.toDate = toDate
      }

      resolve(obj)
    })
  }

  const searchFxn = async () => {
    let resp = await getParamsForApplicationList()
    let obj = {}
    if (name) {
      obj.name = name
    }
    if (campusId) {
      obj.campusId = campusId
    }
    if (status) {
      obj.status = status
    }
    if (agentId) {
      obj.agentId = agentId
    }
    if (intake) {
      obj.intake = intake
    }
    if (courseName) {
      obj.courseName = courseName
    }
    if (studentAppId) {
      obj.studentAppId = studentAppId
    }
    if (studentShore) {
      obj.studentShore = studentShore
    }
    if (branchUserId) {
      obj.branchUserId = branchUserId
    }
    if (marketingUserId) {
      obj.marketingUserId = marketingUserId
    }
    if (id) {
      obj['applications.id'] = id
    }
    if (toDate) {
      obj.toDate = moment(toDate).format('MM-DD-YYYY')
    }
    if (fromDate) {
      obj.fromDate = moment(fromDate).format('MM-DD-YYYY')
    }
    dispatch(
      getUrlPushWrapper('nationalCollegeLoaRequestedList', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
    }, 200)
  }
  const clearFxn = async () => {
    let resp = await getParamsForApplicationList()
    let obj = {}
    setName('')
    setCampusId('')
    setStatus('')
    setAgentId('')
    setIntake('')
    setCourseName('')
    setStudentAppId('')
    setStudentShore('')
    setBranchUserId('')
    setMarketingUserId('')
    setId('')
    setToDate('')
    setFromDate('')
    dispatch(
      getUrlPushWrapper('nationalCollegeLoaRequestedList', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      tableAppRef.current.reload()
    }, 200)
  }

  const columns = [
    {
      title: 'App#',
      key: 'applications.id',
      dataIndex: 'applications.id',
      // searchTextName: 'applications.id',
      width: 60,
      render: item => {
        return <div style={{ width: 60 }}>{item}</div>
      }
    },
    {
      title: 'Student ID',
      key: 'applications.studentIdNo',
      dataIndex: 'applications.studentIdNo',
      width: 80,
      render: item => {
        return <div style={{ width: 80 }}>{item}</div>
      }
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 120,
      render: (item, record) => {
        return (
          <div style={{ width: 120 }}>
            {item}
            <br />
            {newFormatDisplayDate(record.dateOfBirth)}

          </div>
        )
      }
    },
    {
      title: 'Program',
      key: 'applications.courseName',
      dataIndex: 'applications.courseName',
      width: 150,
      render: item => {
        return <div style={{ width: 150 }}>{item}</div>
      }
    },
    {
      title: 'Intake',
      key: 'applications.intake',
      dataIndex: 'applications.intake',
      width: 80,
      render: (item, record) => {
        return (
          <div style={{ width: 80 }}>
            {item && item.month && item.year
              ? `${item.month}, ${item.year}`
              : ''}
          </div>
        )
      }
    },
    {
      title: 'Campus',
      key: 'applications.campusId',
      dataIndex: 'applications.campusId',
      width: 85,
      render: (item, record) => {
        return (
          <div style={{ width: 85 }}>
            {item && item.campusName ? item.campusName : ''}
          </div>
        )
      }
    },

    {
      title: 'Family / Given Name',
      key: 'applications.familyName',
      dataIndex: 'applications.familyName',
      width: 100,
      render: (item, record) => {
        return (
          <div style={{ width: 100 }}>
            {item && item ? (
              <p>
                Family Name : <p style={{ fontWeight: 'bold' }}>{item}</p>
              </p>
            ) : (
              ''
            )}
            {record && record.applications && record.applications.givenName ? (
              <p>
                Given Name :{' '}
                <p style={{ fontWeight: 'bold' }}>
                  {record.applications.givenName}
                </p>
              </p>
            ) : (
              ''
            )}
            {record &&
            record.applications &&
            record.applications.loaRequested === 'Approved' ? (
              <Icon
                type={'edit'}
                onClick={() => events.changeNameOpen(record)}
              />
            ) : null}
          </div>
        )
      }
    },
    {
      title: 'Rqst LOA',
      key: 'sendToNextUser',
      dataIndex: 'sendToNextUser',
      width: 150,
      render: (val, record) => {
        let { applications } = record
        let { loaRequested } = applications
        return (
          <div style={{ width: 150 }} className={'fcmtDiv'}>
            <LoaRequestActionComponent
              val={loaRequested}
              record={record}
              events={events}
              user={user}
            />
          </div>
        )
      }
    },

    {
      title: 'LOA Letter',
      key: 'applications.loaLetter',
      dataIndex: 'applications.loaLetter',
      width: 100,
      render: (val, record) => {
        return (
          <div className={'fcmtDiv alignCenter'}>
            <LoaActionComponent val={val} record={record} events={events} />
          </div>
        )
      }
    },
    {
      title: 'Pal Document',
      key: 'applications.palLetter',
      dataIndex: 'applications.palLetter',
      width: 130,
      render: (val, record) => {
        return (
          <div className={'fcmtDiv alignCenter'}>
            <PalActionComponent val={val} record={record} events={events} />
          </div>
        )
      }
    },
    {
      title: 'Receipt',
      key: 'applications.receipt',
      dataIndex: 'applications.receipt',
      width: 130,
      render: (val, record) => {
        return (
          <div className={'fcmtDiv alignCenter'}>
            <ReceiptActionComponent val={val} record={record} events={events} />
          </div>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 100,
      render: (item, record) => {
        return (
          <>
            <Tooltip title={'View Student'}>
              <a className='btn btn-default btn-xs' onClick={() => {
                events.openStudentState(record)
              }}>
                <img src='../dist/img/view.svg' alt='' /> View
              </a>
            </Tooltip>
            <div className={'mt5'}>
              <Tooltip title={'View Student'}>
                <a className={`btn btn-default btn-xs`}
                   onClick={() => events.openDocumentDrawer(record)}>
                  <img src='../dist/img/view.svg' alt='' /> Documents
                </a>
              </Tooltip>
            </div>
          </>
        )
      }
    }
  ]

  const resp = useSelector(state => {
    let { loadNoteRedux } = state.chooseApplication
    if (loadNoteRedux) {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
      dispatch({ type: 'END_LOAD_NOTE' })
    }
  })

  const loadColumns = () => {
    let columnArr = []
    lodash.each(columns, item => {
      if (item.hidden == undefined) {
        columnArr.push(item)
      } else {
        if (!item.hidden) {
          columnArr.push(item)
        }
      }
    })
    return columnArr
  }

  const extra = (
    <div className={'mt10'}>
      <Row gutter={12} className={'filterBox'}>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round'>
            <input
              className='form-control form-control'
              type='search'
              placeholder='Search by name...'
              value={name}
              onChange={e => {
                events.enterName(e.target.value)
              }}
            />
            <img src='/dist/img/search.png' alt='' />
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='Status'
              onChange={item => setStatus(item)}
              filterOption={filterOption}
              className={'antSelect'}
              placeholder={'Status'}
              allowClear={true}
              value={status || undefined}>
              {fcmtCollegeStatusFilterList && fcmtCollegeStatusFilterList.length
                ? fcmtCollegeStatusFilterList.map((item, key) => {
                  return (
                    <Option value={item.name} key={key}>
                      {item.name}
                    </Option>
                  )
                })
                : null}
            </Select>
          </div>
        </Col>

        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='Status'
              onChange={item => setCampusId(item)}
              className={'antSelect'}
              placeholder={'Campus'}
              allowClear={true}
              value={campusId || undefined}>
              {campusList && campusList.length
                ? campusList.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>
                      {item.campusName}
                    </Option>
                  )
                })
                : null}
            </Select>
          </div>
        </Col>

        <Col span={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <div className='search-box-table round'>
              <input
                className='form-control form-control'
                type='search'
                placeholder='Student ID'
                value={studentAppId}
                onChange={e => {
                  setStudentAppId(e.target.value)
                }}
              />
              <img src='/dist/img/search.png' alt='' />
            </div>
          </div>
        </Col>
        <Col md={4} sm={4} xs={12} lg={4} key={fromDate}>
          <RangePicker
            defaultValue={[fromDate, toDate]}
            onChange={val => {
              setFromDate(val[0])
              setToDate(val[1])
            }}
          />
        </Col>

        <Col md={4} sm={4} xs={12} lg={4}>
          <Button onClick={() => searchFxn()} className={'roundBtn'}>
            Search
          </Button>
          <Button onClick={() => clearFxn()} className={'roundBtn'}>
            Clear
          </Button>
        </Col>
      </Row>
    </div>
  )

  return (
    <React.Fragment>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div
              className='table-head d-flex align-items-center all-student-search'
              style={{ height: 60 }}>
              <Button
                onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                className={'roundGoBackBtn'}
                icon={'arrow-left'}>
                Back
              </Button>
              <h5>National College All Applications </h5>
              <div
                className='search-box-table'
                style={{ backgroundColor: 'transparent' }}>
                {currentStatus} (Total - {totalApplication})
              </div>
              <div className='sort-box-table mark-btn'>

              </div>
            </div>
            <div className='card-body table-responsive'>
              {extra}

              <TableComp
                columns={loadColumns()}
                rowKey={(item, index) => {
                  return index
                }}
                apiRequest={apiRequest}
                pagination={{
                  position: 'top',
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '20', '50', '100'],
                  defaultPageSize: 10,
                  current: filters.page ? filters.page : 1
                }}
                extraProps={{ scroll: { x: 1000 } }}
                ref={tableAppRef}
              />
            </div>
          </div>
        </div>
      </div>


      {visibleAddCommentDrawer && applicationObj && applicationObj._id ? (
        <AddNote
          visible={visibleAddCommentDrawer}
          studentData={applicationObj}
          addedType={'direct'}
          onClose={() => events.closeAddCommentDrawer()}
          applicationId={applicationObj.applications._id}
        />
      ) : null}


      {visibleFcmtStatus &&
      studentData.applications &&
      studentData.applications._id ? (
        <FcmtStatusComponent
          onClose={events.closeFcmtStatusDrawer}
          reloadTable={events.reloadFxn}
          university={'FCMT'}
          applicationId={studentData.applications._id}
          studentId={studentData._id}
          studentData={studentData}
          visible={visibleFcmtStatus}
        />
      ) : null}

      {docState.visible ? (
        <UploadCornerStoneDocument
          onClose={events.hideUploadDocument}
          reloadTable={events.reloadFxn}
          {...docState}
        />
      ) : null}

      {studentState.visible ?
        <StudentInfoDrawer
          {...studentState}
          onSubmit={() => {
            events.closeStudentState()
            events.reloadFxn()
          }}
          onClose={events.closeStudentState} />
        : null}

      {
        uploadDocState.visible ?
          <StudentDocumentDrawer
            visible={state.documentDrawerVisible}
            {...uploadDocState}
            onClose={events.closeDocumentDrawer} /> : ''
      }

    </React.Fragment>
  )
}
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedCornerNationalCollegeLoaList = Form.create()(NationalCollegeLoaApplicationList)
export default connect(
  null,
  mapDispatchToProps
)(WrappedCornerNationalCollegeLoaList)
