import React from 'react'
import { Col, Form, notification, Card, Button, Drawer, Row } from 'antd'
import { connect, useDispatch } from 'react-redux'
import { FileInput, InputBox, Intakes } from '../../components/_utils/appUtils'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import GetAllFormFields from '../../components/_utils/formUtils'
import { showPageLoad, hidePageLoad } from '../../modules/actions'
import Request from '../../request'
import GetEachFormFields from '../../components/_utils/appFormUtils'

const ChangePassword = (props) => {
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } } = props
  const dispatch = useDispatch()
  /*const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 12 }
    }
  }*/
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  let inputField = {
    fields: [
      {
        label: 'Old Password',
        key: 'oldPassword',
        type: 'password',
        placeholder: 'Enter Old Password',
        required: true,
        autoComplete: 'off'
      },
      {
        label: 'New Password',
        key: 'password',
        type: 'password',
        placeholder: 'Minimum 8 letter password',
        required: true
      },
      {
        label: 'Confirm Password',
        key: 'confirmPassword',
        type: 'password',
        placeholder: 'Minimum 8 letter password',
        required: true
      }
    ]
  }

  let handleSubmit = e => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        if (!valData.oldPassword) {
          return notification.error({
            message: 'Please add your current password'
          })
        }
        if (valData.password.length < 8) {
          return notification.error({
            message: 'Password too small'
          })
        }

        if (valData.password !== valData.confirmPassword) {
          return notification.error({
            message: 'Password and Confirm password mismatch'
          })
        }
        dispatch(showPageLoad())
        let { error, message, data } = await Request.updateUserPassword(valData)
        if (!error) {
          notification.success({
            message: 'Profile Updated successfully',
            description: message
          })
          form.resetFields()
        } else {
          notification.error({
            message: 'Error Saving',
            description: message
          })
        }

        dispatch(hidePageLoad())
      }
    })
  }
  return (
    <div>
      <div className={'row mt-4'}>
        <div className={'col-md-12'}>
          <div className="form-box">

            <div className="d-flex align-items-center mb-3 heading-form">
              <h5>Change Password</h5>
            </div>
            <div className="card unizportal">
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <Form onSubmit={handleSubmit}>
                    <Row gutter={24}>
                      {inputField.fields.map((item, key) => {
                        return (
                          <Col span={24} key={key}>
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout}/>
                          </Col>
                        )
                      })}
                    </Row>


                    <Form.Item>
                      <Button type="primary" htmlType="submit" className={'btn'}>
                        UPDATE
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

const WrappedChangePasswordForm = Form.create()(ChangePassword)

const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedChangePasswordForm)
