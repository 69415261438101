import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Col, Drawer, Empty, Form, Icon, Input, Modal, notification, Row, Select, Tag } from 'antd'
import {
  countryIds, currencySymbols,
  InputBox
} from '../../../components/_utils/appUtils'
import { listAllAccount } from '../../bankAccount/actions/bankAccount'
import { useDispatch } from 'react-redux'
import { agentCurrentCommissionFxn } from '../../universityCommission/actions'
import AddBankAccount from './addBankAccounts'
import { addWithdrawalFxn, addWithdrawalSendOtpFxn } from '../../withdrawal/actions/withdrawalAction'
import _ from 'lodash'
import { searchUniversityCommissionFxn } from '../../applications/actions/chooseStudent'
import moment from 'moment'

let initState = {
  bank: '',
  balance: 0,
  amount: 0
}
const WithdrawalComponent = (props) => {
  const { onClose, visible, countryId } = props
  let [state, setState] = useState(initState)
  let [bankList, setBankList] = useState([])
  let [visibleBankDrawer, setVisibleBankDrawer] = useState(false)
  let [visibleOtpScreen, setVisibleOtpScreen] = useState(false)
  let [otp, setOtp] = useState('')
  let [selectedAccount, setSelectedAccount] = useState({})
  let [universityCommission, setUniversityCommissions] = useState([])
  let [universityCommissionIds, setUniversityCommissionIds] = useState([])
  let [universityCommissionAmount, setUniversityCommissionAmount] = useState([])
  let [canadaOpeningBalance, setCanadaOpeningBalance] = useState('')
  let [ukOpeningBalance, setUkOpeningBalance] = useState('')
  const today = moment().day()
  const nextPayableDay = (today === 6 || today === 0 || today === 1 || today === 2) ? 3 : 6;
  const nextPayableDate = moment().weekday(nextPayableDay)
  let dispatch = useDispatch()
  const loadBankList = async () => {
    let resp = await dispatch(listAllAccount({}))
    setBankList(resp.data)
  }
  const getUniversityCommission = async (params = {}) => {
    if (countryId) {
      params.countryId = countryId
    }
    params.withdrawalStatus = ['Pending', 'Cancel']
    try {
      const { data, agentData } = await dispatch(searchUniversityCommissionFxn({ ...params, results: 200, count: 200 }))
      const commissions = data
      setUniversityCommissions(commissions)
      const commissionIds = commissions.map(item => item._id)
      const commissionAmounts = commissions.map(item => ({
        id: item._id,
        amount: item.approvedByAdminCommission ? item.approvedByAdminCommission : item.commission
      }))
      setUniversityCommissionIds(commissionIds)
      setUniversityCommissionAmount(commissionAmounts)
      let totalAmount = commissionAmounts.reduce((sum, item) => sum + item.amount, 0)
      if (countryId == countryIds.canada && agentData && agentData.canadaOpeningBalance) {
        totalAmount = totalAmount + agentData.canadaOpeningBalance
        setCanadaOpeningBalance(agentData.canadaOpeningBalance)
      }
      if (countryId == countryIds.uk && agentData && agentData.ukOpeningBalance) {
        setUkOpeningBalance(agentData.ukOpeningBalance)
        totalAmount = totalAmount + agentData.ukOpeningBalance
      }
      updateState({ amount: totalAmount })
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    loadBankList()
    getUniversityCommission()
  }, [])
  useEffect(() => {
    loadCurrentCommission()
  }, [countryId])

  const updateState = (data) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...data
      }
    })
  }

  const events = {
    showBankDrawer: () => {
      setVisibleBankDrawer(true)
    },
    hideBankDrawer: () => {
      setVisibleBankDrawer(false)
      loadBankList()
    },
    chooseBankAccount: (bank) => {
      updateState({ bank: bank })
      let findBank = _.find(bankList, (item) => {
        return item.accounts._id == bank
      })
      if (findBank) {
        setSelectedAccount(findBank.accounts)
      }
    }
  }

  let loadCurrentCommission = async () => {
    let { data } = await dispatch(agentCurrentCommissionFxn())
    if (countryId == countryIds.uk) {
      updateState({ balance: data.ukCommission })
    }
    if (countryId == countryIds.canada) {
      updateState({ balance: data.canadaCommission })
    }
    if (countryId == countryIds.australia) {
      updateState({ balance: data.australiaCommission })
    }
    if (countryId == countryIds.germany) {
      updateState({ balance: data.germanyCommission })
    }
    if (countryId == countryIds.canadaOnshore) {
      updateState({ balance: data.onshoreCanadaCommission })
    }
  }

  const handleChange = (data) => {
    setState(prevData => {
      return {
        ...prevData,
        ...data
      }
    })
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!state.bank) {
      notification.warning({ message: 'Please choose account.' })
      return
    }
    if (!selectedAccount.cancelledCheque || (selectedAccount.cancelledCheque && !selectedAccount.cancelledCheque.name)) {
      notification.warning({ message: 'Please upload the cancelled cheque in the selected bank account first!' })
      return
    }
    if (universityCommissionAmount.length === 0) {
      notification.warning({ message: 'Please choose atleast one student to withdraw commission' })
      return
    }
    if (!state.amount) {
      notification.warning({ message: 'Enter enter amount' })
      return
    }
    if (state.amount > state.balance) {
      notification.warning({ message: 'Enter valid amount' })
      return
    }
    let obj = _.clone(state)
    obj.countryId = countryId

    if (ukOpeningBalance) {
      obj.ukOpeningBalance = ukOpeningBalance
    }
    if (canadaOpeningBalance) {
      obj.ukOpeningBalance = canadaOpeningBalance
    }
    obj = {
      ...obj,
      ...selectedAccount
    }
    obj.payableDate = nextPayableDate
    let resp = await dispatch(addWithdrawalSendOtpFxn(obj))
    if (resp && resp.success) {
      setVisibleOtpScreen(true)
    }
  }

  const handleSubmitAddWithdrawal = async (e) => {
    e.preventDefault()
    if (!state.bank) {
      notification.warning({ message: 'Please choose account.' })
      return
    }
    if (!selectedAccount.cancelledCheque || (selectedAccount.cancelledCheque && !selectedAccount.cancelledCheque.name)) {
      notification.warning({ message: 'Please upload the cancelled cheque in the selected bank account first!' })
      return
    }
    if (universityCommissionIds.length == 0) {
      notification.warning({ message: 'Please choose atleast one student to withdraw commission' })
      return
    }
    if (!state.amount) {
      notification.warning({ message: 'Enter enter amount' })
      return
    }
    if (state.amount > state.balance) {
      notification.warning({ message: 'Enter valid amount' })
      return
    }
    if (!otp) {
      notification.warning({ message: 'Please enter otp' })
      return
    }
    let obj = _.clone(state)
    obj.countryId = countryId

    obj = {
      ...obj,
      ...selectedAccount,
      otp
    }
    obj.universityCommissionIds = universityCommissionIds

    const AppIds = []
    universityCommissionIds.forEach(id => {
      const commission = universityCommission.find(commission => commission._id == id)
      if (commission) {
        AppIds.push(commission.appId)
      }
    })

    obj.AppIds = AppIds
    obj.payableDate = nextPayableDate
    let resp = await dispatch(addWithdrawalFxn(obj))
    if (resp && resp.success) {
      handleChange({
        bank: '',
        amount: null
      })
      setVisibleOtpScreen(false)
      setSelectedAccount({})
      onClose()
      setOtp('')
    }
  }

  useEffect(() => {
    let totalAmount = universityCommissionAmount.reduce((sum, item) => sum + item.amount, 0)
    if (countryId == countryIds.canada) {
      totalAmount = totalAmount + canadaOpeningBalance
    }
    if (countryId == countryIds.uk) {
      totalAmount = totalAmount + ukOpeningBalance
    }
    updateState({ amount: totalAmount })
  }, [universityCommissionAmount.length])

  return (
    <>
      <Drawer
        visible={visible}
        title={
          (<div className={'d-flex flex-column'}>
              Withdrawal {state.balance ? <>(Balance
              - {state.balance ? `${currencySymbols[countryId]}${state.balance}` : null})</> : null}

              <div className={'font-weight-500 colorPrimary mt-1 text-md'}>
                {countryId == countryIds.canada && canadaOpeningBalance ?
                  <>
                    Opening Balance
                    - {currencySymbols[countryIds.canada]}{canadaOpeningBalance ? canadaOpeningBalance : null}
                  </> : null
                }
                {countryId == countryIds.uk && ukOpeningBalance ?
                  <>
                    Opening Balance - {currencySymbols[countryIds.uk]}{ukOpeningBalance ? ukOpeningBalance : null}
                  </> : null
                }
              </div>
            </div>
          )
        }
        onClose={onClose} width={'70%'}>
        <div className='card unizportal'>
          <Form className={'vertical-form'} autoComplete='off' onSubmit={handleSubmit}>
            <Row gutter={24} className={'rowWrap'}>
              <Col span={24}>
                <div className='col-12 container'>
                  <InputBox title='Choose Students' />
                  <table className='table table-hover'>
                    <thead>
                    <tr>
                      {/*<th>#</th>*/}
                      <th>Name</th>
                      <th>Application Id</th>
                      <th>University Logo</th>
                      <th>University Name</th>
                      <th>Semester</th>
                      <th>Amount</th>
                      <th>Date</th>
                    </tr>
                    </thead>
                    {universityCommission.length > 0 ? (
                      <tbody>
                      {universityCommission.map((item) => (
                        <tr key={item.semesterIndex}>

                          <td>
                            <label className={'label label-tag'}>
                              <Checkbox className={'mr10 pointerCls'}
                                        checked={universityCommissionIds.includes(item._id)}
                                        onChange={(e) => {
                                          const checked = e.target.checked
                                          const amount = item.approvedByAdminCommission ? item.approvedByAdminCommission : item.commission

                                          if (checked) {
                                            setUniversityCommissionIds([...universityCommissionIds, item._id])
                                            setUniversityCommissionAmount(prevAmount => [
                                              ...prevAmount,
                                              { id: item._id, amount }
                                            ])
                                          } else {
                                            setUniversityCommissionIds(universityCommissionIds.filter(id => id !== item._id))
                                            setUniversityCommissionAmount(prevAmount => prevAmount.filter(entry => entry.id !== item._id))
                                          }
                                        }}
                              />
                              {item.studentId.name}
                            </label>
                          </td>
                          <td>{item.appId}</td>
                          <td>
                            <img className={'smallUniversityLogo'} src={item.universityId.smallLogo.url} alt='avatar' />
                          </td>
                          <td>{item.universityId.universityName}</td>
                          <td>{item.semesterIndex}</td>
                          <td>

                            {item.countryId && item.countryId.currency ? `${item.countryId.currency} ` : ''}
                            {item && item.approvedByAdminCommission ? item.approvedByAdminCommission : item.commission}</td>
                          <td>
                            {item && item.approvedCommissionDate ? moment(item.approvedCommissionDate).format('DD-MM-YYYY') : null}</td>
                        </tr>
                      ))}
                      </tbody>
                    ) : (
                      <tbody>
                      <tr>
                        <td colSpan='8' className='text-center'>
                          <Empty className='mt-2' description='No data found' />
                        </td>
                      </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </Col>
              <Col span={8}>
                <Form.Item>
                  <InputBox
                    title={'Bank'}>
                    <select
                      value={state.bank} className={'form-control'} name='bankName'
                      onChange={({ target }) => {
                        events.chooseBankAccount(target.value)
                      }}
                      placeholder='Choose account'>
                      <option value={''}>Choose Account</option>
                      {bankList && bankList.length ? bankList.map((item) => {
                        let { accounts } = item
                        return (
                          <option key={accounts._id} value={accounts._id}>
                            {accounts.bankName} ({accounts.accountNo})
                          </option>
                        )
                      }) : null}
                    </select>
                    <a className={'linkBtn'}
                       onClick={() => {
                         events.showBankDrawer()
                       }}
                       target={'_blank'}>
                      <Icon type={'plus'}></Icon>
                    </a>
                  </InputBox>
                </Form.Item>
              </Col>
              {/*<Col span={8}>
                <Form.Item>
                  <InputBox title={'Balance'}>
                    <Input
                      disabled={true}
                      value={state.balance} className={'form-control'} name='bankName'
                      placeholder='Total Amount' />
                  </InputBox>
                </Form.Item>
              </Col>*/}
              <Col span={8}>
                <Form.Item>
                  <InputBox title={'Amount'}>
                    <Input
                      addonBefore={currencySymbols[countryId]}
                      type={'number'}
                      value={state.amount}
                      className={'form-control'}
                      name='bankName'
                      onChange={({ target }) => {
                        updateState({ amount: target.value })
                      }}
                      placeholder='Total Amount'
                      disabled={true}
                    />
                  </InputBox>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button type='primary' htmlType='submit' className='btn'>
                SUBMIT
              </Button>
            </Form.Item>
          </Form>
        </div>

        {visibleBankDrawer ?
          <AddBankAccount
            onClose={events.hideBankDrawer}
            visible={visibleBankDrawer}
          /> : null}

        {visibleOtpScreen ? <Modal
          visible={visibleOtpScreen}
          closable={true}
          title={'Enter OTP'}
          onCancel={() => {
            setVisibleOtpScreen(false)
          }}
          footer={false}>
          <div style={{ textAlign: 'center', marginBottom: 10 }}>
            <h6> A text message with a
              4-digit verification code has been sent to your phone number.
            </h6>
            <InputBox title={'Enter OTP'}>
              <input
                className={'form-control mt10'}
                type={'password'}
                minLength={4}
                maxLength={4}
                value={otp} onChange={({ target }) => {
                setOtp(target.value)
              }} />
            </InputBox>
            <Button className={'btn btn-success mt10'} onClick={handleSubmitAddWithdrawal}>
              Submit OTP
            </Button>

          </div>
        </Modal> : null}

      </Drawer>
    </>
  )
}
const WrappedComponent = Form.create()(WithdrawalComponent)
export default WrappedComponent
