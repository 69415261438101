import { apiUrl } from '../../../settings'

export const getNationalCollegeApplicationListUrl = () => {
  return apiUrl + '/national-college/get-application-list'
}

export const getNationalCollegeOfferListUrl = () => {
  return apiUrl + '/national-college/offer-list'
}

export const getNationalCollegeLoaListUrl = () => {
  return apiUrl + '/national-college/loa-list'
}

export const nationalCollegeUploadDocumentUrl = () => {
  return apiUrl + '/national-college/upload-documents'
}

export const getNationalCollegeAgentsUrl = () => {
  return apiUrl + '/api/get-national-college-agents'
}
