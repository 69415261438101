export default (
  state = {
    loader: false,
    sampleDocument: {},
    allSampleDocument: [],
    totalSampleDocument: 0,
    addedSampleDocument: {}
  },
  action
) => {
  switch (action.type) {
    case 'LIST_SAMPLE_DOCUMENT':
      return { ...state, sampleDocument: action.payload.data }
    case 'ADD_SAMPLE_DOCUMENT':
      return { ...state, addedSampleDocument: action.payload }
    case 'LIST_ALL_SAMPLE_DOCUMENT':
      return { ...state, allSampleDocument: action.payload.data, totalSampleDocument: action.payload.total }
    default:
      return state
  }
}
