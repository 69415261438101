import React, { Component } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { DefaultTablePagination } from '../../components/_utils/appUtils'
import {
  Tooltip, Icon,
  Popconfirm
} from 'antd'
import { connect } from 'react-redux'
// import { CheckMyRights } from '../../WebComponent/CheckUserRights'
import { activateInstitute, instituteList } from './action'

class AllInstitutes extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedUser: {}
    }
    this.tableRef = React.createRef()
  }

  componentDidMount() {
  }

  apiRequest = async (params) => {
    let { dispatch } = this.props
    return new Promise(async (resolve) => {
      params.sortField = 'name'
      params.sortOrder = 'ascend'
      params.showDeleted = true
      let resp = await dispatch(instituteList({
        ...params
      }))
      this.setState({ totalInstitutes: resp.total })
      resolve(resp)
    })
  }

  approveInstitute = async (id, values) => {
    const { dispatch } = this.props
    const { _id, ...restValues } = values
    const resp = await dispatch(activateInstitute({ instituteId: id, ...restValues }))
    if (resp && resp.success) {
      this.tableRef.current.reload()
    }
  }


  render() {
    let { totalInstitutes } = this.state
    const columns = [
      {
        title: '#',
        key: '_id',
        dataIndex: '_id',
        width: 50,
        render: (ite, record, index) => {
          return <React.Fragment>{index + 1}</React.Fragment>
        }
      },
      {
        title: 'CompanyName',
        key: 'companyName',
        sorter: true,
        dataIndex: 'companyName'
        // searchTextName: 'companyName'
      },
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name'
        // searchTextName: 'name'
      },
      {
        title: 'State Name',
        key: 'stateName',
        sorter: true,
        dataIndex: 'stateName'
        // searchTextName: 'stateName'
      },
      {
        title: 'City Name',
        key: 'cityName',
        sorter: true,
        dataIndex: 'cityName'
        // searchTextName: 'cityName'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
        // searchTextName: 'email'
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobile',
        key: 'mobile',
        render: (val, record) => {
          return (
            <>
              {record.countryCode} {record.mobile}
            </>
          )
        }
        // searchTextName: 'mobile'
      },
      {
        title: 'Status',
        dataIndex: 'deleted',
        key: 'deleted',
        render: (item) => {
          return (
            <>
              {!item ? <label className={'label label-warning label-sm'}>Pending</label> : ''}
              {item ? <label className={'label label-success label-sm'}>Approved</label> : ''}
            </>
          )
        }
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address'

        // searchTextName: 'address'
      },
      {
        title: 'Pincode',
        dataIndex: 'pincode',
        key: 'pincode'
        // searchTextName: 'pincode'
      },
      {
        title: 'Referral Code',
        dataIndex: 'userId',
        key: 'userId',
        render: (item, record) => {
          return item && item.referCode ? item.referCode : ''
        }
      },

      {
        title: 'Action',
        key: '_id',
        dataIndex: '_id',
        width: 80,
        render: (val, record) => {
          return (
            <React.Fragment>
              {!record.deleted ?
                <Tooltip title='Approve Institute'>
                  <Popconfirm
                    title={'Are you sure, you want to approve this institute?'}
                    onConfirm={() => {
                      this.approveInstitute(record._id, record)
                    }}
                    okText='Yes'
                    cancelText='No'>
                    <a className={'btn btn-success btn-xs'}>Approved Now</a>
                  </Popconfirm>

                </Tooltip> : null}
            </React.Fragment>
          )
        }
      }
    ]
    return (
      <div className='row  mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>All Institutes({totalInstitutes})</h5>

            </div>
            <TableComp columns={columns}
                       ref={this.tableRef}
                       pagination={DefaultTablePagination()}
                       apiRequest={this.apiRequest}
            />
          </div>
        </div>
      </div>
    )

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllInstitutes)
