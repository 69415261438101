import { apiUrl, apiUrl2 } from '../../../settings'

export const expenseUrl = () => {
  return apiUrl + '/expense'
}
export const calcDistanceUrl = () => {
  return apiUrl + '/api/calcDistance'
}
export const updateExpenseWithdrawalUrl = () => {
  return apiUrl + '/updateExpenseWithdrawal'
}
export const updateExpenseUrl = () => {
    return apiUrl + '/updateExpenseStatus'
}
export const expenseWithdrawalUrl = () => {
    return apiUrl + '/expenseWithdrawal'
}

export const expenseAmountRecordUrl = () => {
    return apiUrl + '/expenseAmountRecord'
}

export const expenseMonthWiseReportUrl = () => {
    return apiUrl + '/api/expenseMonthWiseReport'
}

export const expense_exportUrl = () => {
    return apiUrl + '/expense_export'
}

export const getStatesUrl = () => {
    return apiUrl + '/indian-state'
}

export const getCitiesUrl = () => {
    return apiUrl + '/indian-city'
}

export const getPlaceUrl = () => {
  return apiUrl + '/indian-area'
}

export const calcAreaDistanceUrl = () => {
  return apiUrl + '/api/calcAreaDistance'
}

export const expenseWithdrawalCanadaUrl = () => {
  return apiUrl2 + '/expenseWithdrawalCanada'
}

export const getStopsURl = () => {
  return apiUrl2 + '/getAreas'
}
