import React, {useState, useEffect} from 'react'
import {Drawer, Form, Button, notification, Card, Row, Col, Input, Tooltip, Icon} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {getCourseCategory, updateCourseCategory} from '../actions'
import GetAllFormFields from '../../../components/_utils/formUtils'
import {InputBox} from "../../../components/_utils/appUtils";
import moment from "moment";
import GetEachFormFields from "../../../components/_utils/appFormUtils";

const Edit = (props) => {
    const dispatch = useDispatch()
    let {visible, setVisible} = props
    const [category, setCategory] = useState({})
    let [mediaKey, setMediaKey] = useState(moment())
    let [logo, setLogo] = useState({})
    let [oldLogo, setOldLogo] = useState({})
    const {form: {getFieldDecorator}, events, id} = props
    const chooseFiles = (files) => {
        setLogo(files)
    }

    let inputTypes = {
        fields: [
            {key: 'name', label: 'Course Category', type: 'text', required: true},
            {
                label: 'Logo',
                span: 8,
                customField: (
                    <InputBox title={'Logo'} className={'rowFlex'}>
                        <Input type={'file'} name={'logo'} id={'logo'} key={mediaKey}
                               className={'form-control'}
                               onChange={(e) => {
                                   if (e.target && e.target.files && e.target.files.length) {
                                       chooseFiles(e.target.files[0])
                                   }
                               }}/>
                        {oldLogo && oldLogo.url ?
                            <Tooltip title={oldLogo.name}>
                                <a className={'linkBtn'} target={'_blank'}
                                   href={oldLogo.url}>
                                    <Icon type={'eye'}></Icon>
                                </a> </Tooltip> : ''}
                    </InputBox>
                )
            },
        ]
    }


    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    useEffect(() => {
        getCourseCategoryFxn()
    }, [])

    const getCourseCategoryFxn = async () => {
        if (id) {
            let data = await dispatch(getCourseCategory(id))
            if (data) {
                setCategory(() => data)
                if (data && data.logo && data.logo.url) {
                    setOldLogo(data.logo)
                }
            }
        }
    }

    const handleSubmit = async (e) => {
        const {form} = props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let fd = new FormData()
                fd.append('obj', JSON.stringify(valData))
                if (logo && logo.name) {
                    fd.append('logo', logo)
                }
                let resp = await dispatch(updateCourseCategory(fd, id))
                if (resp && !resp.error) {
                    events.hideEditDrawer()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }


    useEffect(() => {
        props.form.setFieldsValue({
            name: `${category.name || ''}`
        })
    }, [category])


    return <div>
        <Drawer
            visible={visible}
            width={600}
            placement="right"
            onClose={events.hideEditDrawer}
            title={'Edit Category'}>
            <div className="form-box">
                <div className="card unizportal">
                    <Form onSubmit={handleSubmit}>
                        <Row gutter={16} className={'wrapBox'}>
                            {inputTypes.fields.map((item, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        {item.customField ?
                                            <Col span={24}>
                                                {item.customField}
                                            </Col>
                                            :
                                            <Col span={24} key={key}
                                                 style={item.extraBox ? {paddingBottom: 1} : {}}>
                                                <InputBox title={item.label1 ? item.label1 : ''}
                                                          extra={item.extraBox}>
                                                    <GetEachFormFields
                                                        item={item}
                                                        getFieldDecorator={getFieldDecorator}
                                                        formItemLayout={formItemLayout}/>
                                                </InputBox>
                                            </Col>}
                                    </React.Fragment>
                                )
                            })}
                        </Row>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className={'btn'}>
                                SAVE
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Drawer>
    </div>
}

const EditCountry = Form.create()(Edit)
export default EditCountry
