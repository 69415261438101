import { customAxios as axios, getToken } from '../../../request'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { ListAllAgentCommissions, reCalculateAgentCountryBalanceUrl } from '../apis'

export const loadAllAgentCommissions = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(ListAllAgentCommissions(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const reCalculateAgentCountryBalanceFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(reCalculateAgentCountryBalanceUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
