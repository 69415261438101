import {notification} from 'antd'
import {getToken, customAxios as axios} from '../../../request'
import {
    addLatestUpdateUrl, deleteLatestUpdateUrl,
    getAllLatestUpdatesUrl,
    getLatestUpdateCountUrl,
    resetLatestUpdateCountUrl
} from '../api/index'
import {showPageLoad, hidePageLoad} from '../../../modules/actions'
import {listCampusUrl} from '../../campus/api/campus'
import {downloadAgentProfileOtpUrl, downloadAgentProfileUrl} from '../../dashboard/api/dashboardApi'


export const addLatestUpdateFxn = (valData) => async dispatch => {
    dispatch(showPageLoad())
    const {data} = await axios.post(addLatestUpdateUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const getAllLatestUpdatesFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(getAllLatestUpdatesUrl(), config)
    return data.data
}

export const getLatestUpdateCountFxn = async () => {
    let {data} = await axios.post(getLatestUpdateCountUrl(), {}, getToken())
    return data
}
export const resetLatestUpdateCountFxn = async () => {
    let {data} = await axios.post(resetLatestUpdateCountUrl(), {}, getToken())
    return data
}
export const deleteLatestUpdateFxn = (id) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.delete(deleteLatestUpdateUrl(id), getToken())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    } else {
        notification.success({
            message: data.message || 'Success'
        })
        dispatch(hidePageLoad())
    }
    return data
}


export const downloadAgentProfileFxn = (valData) => async dispatch => {
    dispatch(showPageLoad())
    const {data} = await axios.post(downloadAgentProfileUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    } else {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data
}
export const downloadAgentProfileOtpFxn = () => async dispatch => {
    dispatch(showPageLoad())
    const {data} = await axios.post(downloadAgentProfileOtpUrl(), {}, getToken())
    dispatch(hidePageLoad())
    return data
}
