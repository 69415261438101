import React, {useState, useEffect, useRef} from 'react'
import {useDispatch, connect} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {Avatar, Button, Col, Popconfirm, Row, Select, Tooltip} from "antd";
import {receivedCommissionListFxn} from "../actions";
import {
    defaultLogoList,
    displayDate,
    filterOption, Intakes, getSemesterList, CommissionStatusList, YearList, longDisplayDate, longStringDate, countryIds
} from '../../../components/_utils/appUtils'
import {listAllCountries} from "../../countries/actions/countries";
import {listAllUniversities} from "../../university/actions/university";
import {getUrlPushWrapper} from "../../../routes";
import {getBranchManagerAgentList} from "../../users/actions/user";
import ReceivedCommStudentDrawer from "../drawers/receivedCommStudent";
import ClaimReceivedDrawer from "../ReceiveCommission/claimReceivedDrawer";
import CancelCommissionDrawer from "../ReceiveCommission/cancelCommissionDrawer";
import EditReceivedDrawer from "../ReceiveCommission/editReceivedCommission";

const {Option} = Select

let initState = {
    status: "",
    visible: false,
    commissionId: ""
}
let initFilter = {
    countryId: "",
    status: "",
    universityId: "",
    intake: "",
    countryList: [],
    universityList: [],
    agentList: [],
    courseName: "",
    semester: "",
}
let receivedCommissionInit = {
    visible: false,
    record: {}
}
let claimRecInit = {
    visible: false,
    record: {},
}
let cancelInit = {
    visible: false,
    record: {},
}

const DateComponent = (props) => {
    let {date} = props;
    return <>
        {date ? <div className={'statusDate'}>
            {longStringDate(date)}
        </div> : null}
    </>
}
const ReceivedOnshoreCommissionList = (props) => {
    let semesterList = getSemesterList()
    let [totalRecord, setTotalRecord] = useState(0)
    const [itemNo, setItemNo] = useState(1)
    const [state, setState] = useState(initState)
    const [filterState, setFilterState] = useState(initFilter)
    const [tableSearch, setTableSearch] = useState(false)
    const [receivedCommissionState, setReceivedCommissionState] = useState(receivedCommissionInit)
    const [claimRecState, setClaimRecState] = useState(claimRecInit)
    const [EditRecState, setEditRecState] = useState(claimRecInit)
    const [cancelState, setCancelState] = useState(cancelInit)

    const tableRef = useRef()
    const dispatch = useDispatch()
    useEffect(() => {
        loadData.loadCountryList()
    }, [])
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.sortField = '_id'
            params.sortOrder = 'descend'
            params['commissionList.countryId'] = countryIds.canadaOnshore
            let respData = await dispatch(receivedCommissionListFxn({
                ...params,
                regExFilters: ['semester', 'courseName', 'name']
            }))
            console.log(respData)
            setTotalRecord(respData.total)
            resolve(respData)
        })
    }
    const reload = () => {
        if (tableRef && tableRef.current) {
            tableRef.current.reload()
        }
    }
    const events = {

        updateLocalFilter: (data) => {
            try {
                setFilterState({
                    ...filterState,
                    ...data
                })
            } catch (e) {
            }
        },
        closeDrawer: () => {
            setState({
                ...initState
            })
        },
        getParamsForApplicationList: () => {
            return new Promise((resolve) => {
                let searchParams = new URLSearchParams(window.location.search)
                let countryId = searchParams.get('countryId')
                let agentId = searchParams.get('agentId')
                let universityId = searchParams.get('universityId')
                let name = searchParams.get('name')
                let courseName = searchParams.get('courseName')
                let intake = searchParams.get('intake')
                let semester = searchParams.get('semester')
                let status = searchParams.get('status')
                let intakeYear = searchParams.get('intakeYear')
                let obj = {}
                if (agentId) {
                    obj.agentId = agentId
                }
                if (countryId) {
                    obj.countryId = countryId
                }
                if (universityId) {
                    obj.universityId = universityId
                }
                if (name) {
                    obj.name = name
                }
                if (courseName) {
                    obj.courseName = courseName
                }
                if (intake) {
                    obj.intake = intake
                }
                if (intakeYear) {
                    obj.intakeYear = intakeYear
                }
                if (status) {
                    obj.status = status
                }
                if (semester) {
                    obj.semester = semester
                }
                resolve(obj)
            })
        },
        searchFxn: async () => {
            let obj = {}
            let {
                countryId,
                universityId,
                name,
                courseName,
                agentId,
                intake,
                intakeYear,
                semester,
                status
            } = filterState;
            if (countryId) {
                obj.countryId = countryId
            }

            if (name) {
                obj.name = name
            }
            if (courseName) {
                obj.courseName = courseName
            }
            if (universityId) {
                obj.universityId = universityId
            }
            if (agentId) {
                obj.agentId = agentId
            }
            if (semester) {
                obj.semester = semester
            }
            if (status) {
                obj.status = status
            }
            if (intake) {
                obj.intake = intake
            }
            if (intakeYear) {
                obj.intakeYear = intakeYear
            }
            dispatch(
                getUrlPushWrapper('allUniversityCommission', {
                    ...obj
                }))
            setTableSearch(true)
            setTimeout(() => {
                reload()
            }, 200)
        },
        clearFxn: async () => {
            let obj = {}
            events.updateLocalFilter({
                countryId: "",
                universityId: "",
                name: "",
                intake: "",
                agentId: "",
                courseName: "",
                semester: "",
                intakeYear: "",
                status: ""
            })
            dispatch(
                getUrlPushWrapper('allUniversityCommission', {
                    ...obj
                }))
            setTableSearch(true)
            setTimeout(() => {
                reload()
            }, 200)
        },
        showReceivedCommissionDrawer: (record) => {
            record.receivedCommissionId = record._id
            setReceivedCommissionState({
                visible: true,
                record: record
            })
        },
        hideReceivedCommissionDrawer: () => {
            setReceivedCommissionState({
                visible: false,
                record: {}
            })
        },

        showClaimRecDrawer: (record) => {
            record.receivedCommissionId = record._id
            setClaimRecState({
                visible: true,
                record: record
            })
        },
        hideClaimRecDrawer: () => {
            setClaimRecState({
                visible: false,
                record: {}
            })
        },

        showEditRecDrawer: (record) => {
            record.receivedCommissionId = record._id
            setEditRecState({
                visible: true,
                record: record
            })
        },
        hideEditRecDrawer: () => {
            setEditRecState({
                visible: false,
                record: {}
            })
        },

        showCancelDrawer: (record) => {
            record.receivedCommissionId = record._id
            setCancelState({
                visible: true,
                record: record
            })
        },
        hideCancelDrawer: () => {
            setCancelState({
                visible: false,
                record: {}
            })
        },
    }

    const loadData = {
        loadCountryList: async () => {
            let params = {
                results: 5000,
                select: ['countryName'],
                sortField: 'countryName',
                sortOrder: 'ascend'
            }
            let {data} = await dispatch(listAllCountries(params))
            let agentResp = await dispatch(getBranchManagerAgentList())
            let obj = {}
            if (data && data.length) {
                obj.countryList = data;
            }
            if (agentResp && agentResp.data && agentResp.data.length) {
                obj.agentList = agentResp.data;
            }
            events.updateLocalFilter({...obj})
        },
        loadUniversityNameList: async (countryId) => {
            let params = {
                results: 5000,
                select: ['universityName'],
                sortField: 'universityName',
                sortOrder: 'ascend',
                regExFilters: ['universityName'],
                showUniversity: [true, false],
                universityCountry: countryId
            }
            let {data} = await dispatch(listAllUniversities(params));
            let obj = {
                universityId: ""
            }
            if (data && data.length) {
                obj.universityList = data;
            }
            events.updateLocalFilter({...obj, countryId})
        },

    }


    const columns = [

        /*  {
              title: 'S.No',
              dataIndex: 'sno',
              width: 50,
              key: 'sno',
              render: (item, record, index) => {
                  return (
                      <a>
                          {(itemNo - 1) * 10 + index + 1}
                      </a>
                  )
              }
          },*/
        {
            title: 'Invoice No',
            key: 'invoiceNumber',
            dataIndex: 'invoiceNumber',
            width: 80,
            render: (item) => {
                return (
                    <div style={{width: 80}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Claimed Date',
            dataIndex: 'claimDate',
            key: 'claimDate',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 150}}>
                        <DateComponent date={item}/>
                    </div>
                )
            }
        },
        {
            title: 'Comm. Rec. Date',
            dataIndex: 'claimReceivedDate',
            key: 'claimReceivedDate',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 150}}>
                        <DateComponent date={item}/>
                    </div>
                )
            }
        },
        {
            title: 'Country',
            key: 'commissionList',
            dataIndex: 'commissionList',
            width: 100,
            render: (item, record) => {
                return (
                  <div style={{width: 100}}>
                      {item && item[0].countryId.countryName ? item[0].countryId.countryName : null}
                  </div>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 150}}>
                        {item == 'Pending' ?
                            <>
                                <label className={'label label-warning sm'}>Commission Claimed</label>
                            </> : ""}
                        {item == 'Received' ?
                            <label className={'label label-success sm'}>Commission Received</label> : ""}
                        {item == 'Cancelled' ?
                            <>
                                <label className={'label label-danger sm'}>Commission Cancelled</label>
                                <DateComponent date={record.claimCancelDate}/>
                            </> : ""}
                    </div>
                )
            }
        },
        {
            title: 'University',
            key: 'universityId',
            dataIndex: 'universityId',
            width: 150,
            render: (item, record) => {
                return (
                    <div style={{width: 150}}>
                        {item && item.logo && item.logo.url ?
                            <Avatar src={item.logo.url} size={20}/>
                            : <Avatar src={defaultLogoList.defaultUniversityLogo} size={20}/>}
                        {item.universityName ? `  ${item.universityName}` : ''}
                    </div>
                )
            }
        },

        {
            title: 'Rec. Commission',
            dataIndex: 'commission',
            key: 'commission',
            width: 120,
            render: (item, record) => {
                let {currency, receivedAmountInInr, currencyRate, receivedAmount} = record;
                return (
                    <div style={{width: 150}}>
                        {(receivedAmount || receivedAmountInInr) ? <>
                            {currency == 'INR' ?
                                <>
                                    {receivedAmountInInr}
                                    {currency ? ` ${currency}` : ""}
                                </> :
                                <>
                                    {receivedAmount}
                                    {currency ? ` ${currency}` : ""}
                                    {currencyRate ? ` (Rate : ${currencyRate})` : ""}
                                </>
                            }
                        </> : ""}
                    </div>
                )
            }
        },

        {
            title: 'Added By',
            key: 'userId',
            dataIndex: 'userId',
            width: 160,
            render: (item, record) => {
                return (
                    <div style={{width: 160}}>
                        <div className={'colorPrimary font12 textCap'}>
                            {item && item.name ? item.name : null}
                        </div>
                        <div>{longDisplayDate(record.createdAt)}</div>
                    </div>
                )
            }
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: 160,
            render: (item, record) => {
                let {status} = record;
                return (
                    <div className={'comBtnGroup'}>

                        <Tooltip title={'View Student List'}>
                            <a className={'btn btn-sm btn-view'} onClick={() => {
                                events.showReceivedCommissionDrawer(record)
                            }}>
                                <img src='../uniBtn/eye.png'/>
                                View Student
                            </a>
                        </Tooltip>

                        {status == 'Pending' ?
                            <>
                                <a className={'btn btn-success btn-sm'} onClick={() => {
                                    events.showClaimRecDrawer(record)
                                }}>
                                    <img src='../uniBtn/check.png'/>
                                    Receive Commission
                                </a>
                                <a className={'btn btn-danger btn-sm'} onClick={() => {
                                    events.showCancelDrawer(record)
                                }}>
                                    <img src='../uniBtn/cancel.png'/>
                                    Cancel Commission
                                </a>
                            </> : ""}
                    </div>
                )
            }
        },
    ]


    return (
        <div className='row mt-4'>
            <div className='col-lg-12'>
                <div className='card'>
                    <div className='table-head d-flex align-items-center'>
                        <h5>All Received Commission: {totalRecord || 0}</h5>

                        <div className='sort-box-table mark-btn mark-btn40'>

                        </div>
                    </div>
                    <div className='card-body table-responsive'>
                        {/*{FilterComponent}*/}
                        <TableComp columns={columns}
                                   apiRequest={apiRequest}
                                   pagination={{
                                       position: 'top',
                                       pageSizeOptions: ['50', '100', '200', '500'],
                                       defaultPageSize: 50
                                   }}
                                   ref={tableRef}
                            // extraProps={{scroll: {x: 1800}}}
                        />
                    </div>
                </div>
            </div>
            {receivedCommissionState.visible ?
                <ReceivedCommStudentDrawer
                    visible={receivedCommissionState.visible}
                    onClose={events.hideReceivedCommissionDrawer}
                    onSubmit={reload}
                    record={receivedCommissionState.record}
                /> : null}


            {claimRecState.visible ?
                <ClaimReceivedDrawer
                    visible={claimRecState.visible}
                    onClose={events.hideClaimRecDrawer}
                    onSubmit={reload}
                    record={claimRecState.record}
                /> : null}

            {EditRecState.visible ?
                <EditReceivedDrawer
                    visible={EditRecState.visible}
                    onClose={events.hideEditRecDrawer}
                    onSubmit={reload}
                    record={EditRecState.record}
                /> : null}

            {cancelState.visible ?
                <CancelCommissionDrawer
                    visible={cancelState.visible}
                    onClose={events.hideCancelDrawer}
                    onSubmit={reload}
                    record={cancelState.record}
                /> : null}
        </div>
    )
}

const mapStateToProps = ({global, router}) => ({
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReceivedOnshoreCommissionList)
