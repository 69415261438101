import { apiUrl, apiUrl2 } from '../../../settings'
import { Api_Url } from '../../../api'

export const addLatestUpdateUrl = () => {
  return apiUrl2 + '/api/latestUpdates'
}

export const getAllLatestUpdatesUrl = () => {
  return apiUrl2 + '/api/latestUpdates'
}

export const getLatestUpdateCountUrl = () => {
  return apiUrl2 + '/api/latest-update-count'
}

export const resetLatestUpdateCountUrl = () => {
  return apiUrl2 + '/api/reset-latest-update-count'
}

export const deleteLatestUpdateUrl = (id) => {
  return apiUrl2 + '/api/deleteLatestUpdates/' + id
}

export const cambieOnShoreCommissionListUrl = () => {
  return Api_Url + '/api/onshore-cambie-commission-app-list'
}

export const generateAgentInvoiceUrl = () => {
  return Api_Url + '/cambie/generate-invoice'
}
export const uploadAgentInvoiceUrl = () => {
  return Api_Url + '/cambie/upload-letter-head'
}
export const payCommissionUrl = () => {
  return Api_Url + '/cambie/pay-commission'
}