import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import { addBranchUrl, listBranchUrl, listAllBranchUrl } from '../api/branchApi'


export const addBranch = (valData) => async (dispatch) => {
  dispatch({ type: 'BRANCH_SHOW_LOADER' })
  let { data } = await axios.post(addBranchUrl(), valData, getToken())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
    dispatch({ type: 'BRANCH_HIDE_LOADER' })

  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const listAllBranch = (filters) => async (dispatch) => {
  dispatch({ type: 'BRANCH_SHOW_LOADER' })
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(listAllBranchUrl(), config)
  dispatch({ type: 'BRANCH_HIDE_LOADER' })

  dispatch({ type: 'LIST_ALL_BRANCH', payload: data.data })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data

}


export const getBranch = id => async (dispatch) => {
  dispatch({ type: 'BRANCH_SHOW_LOADER' })
  dispatch({ type: 'BRANCH_CARD_SHOW_LOADER' })
  let config = {
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  await axios.get(listBranchUrl(id), config).then((data) => {
    // console.log(data)
    dispatch({ type: 'BRANCH_HIDE_LOADER' })
    dispatch({ type: 'BRANCH_CARD_HIDE_LOADER' })
    dispatch({ type: 'LIST_BRANCH', payload: data.data })

  }).catch((err) => {
    // console.log(err)
    dispatch({ type: 'BRANCH_HIDE_LOADER' })
    dispatch({ type: 'BRANCH_CARD_HIDE_LOADER' })
    notification.error({
      message: JSON.stringify(err) || 'Error'
    })
  })
}


export const updateBranch = (valData, id) => async (dispatch) => {
  dispatch({ type: 'BRANCH_SHOW_LOADER' })
  let { data } = await axios.put(listBranchUrl(id), valData, getToken())
  dispatch({ type: 'BRANCH_HIDE_LOADER' })
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}

