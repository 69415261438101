import React, { useState, useEffect, useRef } from 'react'
import {
  Input,
  Button,
  Select,
  List,
  Popconfirm,
  Icon,
  Dropdown,
  Menu,
  DatePicker,
  Modal,
  Table,
  notification
} from 'antd'
import { TableComp } from 'sz-react-utils-lite'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'antd/dist/antd.css'
import styled from 'styled-components'
import '../style.css'
import { css } from 'styled-components'
import { taskLists, addTask, updateTask, deleteTask, getTaskGrouped } from '../actions/taskManager'
import { Collapse } from 'antd'
import { displayDate } from '../../../components/_utils/appUtils'
import renderHTML from 'react-render-html'

let { TextArea } = Input
const Card = styled.div`
  max-width: 90%;
  width: 90%;
  margin: 20px 30px;
  font-family: 'Roboto', sans-serif;
  word-wrap: break-word;
  overflow-wrap: break-word;
`

const hoverStyles = css`
  transition: box-shadow 0.3s ease, transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transform: scale(1.2);
  }
`


const TaskManager = () => {
  const { Option } = Select
  let conRef = useRef()
  const [newTask, setNewTask] = useState('')
  const [deadline, setDeadline] = useState(null)
  const [assignee, setAssignee] = useState('')
  const [priority, setPriority] = useState('Normal')
  const [taskList, setTaskList] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [addedUsers, setAddedUsers] = useState([])
  const [editingTaskId, setEditingTaskId] = useState(null)
  const [showCompleteTasks, setShowCompleteTasks] = useState(false)
  const [selectedTaskId, setSelectedTaskId] = useState(null)
  const [audioStream, setAudioStream] = useState(null)
  const [videoStream, setVideoStream] = useState(null)
  const [screenStream, setScreenStream] = useState(null)
  const [audioRecording, setAudioRecording] = useState(false)
  const [videoRecording, setVideoRecording] = useState(false)
  const [screenRecording, setScreenRecording] = useState(false)
  const [audioChunks, setAudioChunks] = useState([])
  const [videoChunks, setVideoChunks] = useState([])
  const [screenChunks, setScreenChunks] = useState([])
  const [audioFile, setAudioFile] = useState(null)
  const [screenRecordingFile, setScreenRecordingFile] = useState(null)
  const [videoOn, setVideoOn] = useState(true)
  const [audioOn, setAudioOn] = useState(true)
  const [showScreenRecordingModal, setShowScreenRecordingModal] = useState(false)
  const [screenRecordingDataUrl, setScreenRecordingDataUrl] = useState(null)
  const [showAudioRecordingModal, setShowAudioRecordingModal] = useState(false)
  const [audioRecordingDataUrl, setAudioRecordingDataUrl] = useState(null)
  const [audioModalVisible, setAudioModalVisible] = useState(false)
  const [audioUrl, setAudioUrl] = useState('')
  const [screenModalVisible, setScreenModalVisible] = useState(false)
  const [screenUrl, setScreenUrl] = useState('')


  const audioRecorder = useRef(null)
  const videoRecorder = useRef(null)
  const screenRecorder = useRef(null)
  const liveVideoRef = useRef(null)
  const fileInputRef = useRef(null)


  let [deadLineState, setDeadLineState] = useState({
    visible: false,
    taskId: '',
    deadline: ''
  })
  let [priorityState, setPriorityState] = useState({
    visible: false,
    taskId: ''
  })
  let [showGroupTask, setShowGroupTask] = useState(false)
  let [groupedTask, setGroupedTask] = useState([])

  const dispatch = useDispatch()
  const history = useHistory()
  const tableRef = useRef()
  const { Panel } = Collapse
  const tableStyles = {
    boxShadow: '0 8px 12px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px'
  }
  const apiRequest = async (params) => {
    return new Promise(async (resolve) => {
      if (!showCompleteTasks) {
        params['customQuery'] = { status: { $ne: 'complete' } }
      }
      const { data } = await dispatch(taskLists(params))
      console.log(data, 'dataoiooooi')
      resolve(data)
    })
  }
  const openAudioModal = (url) => {
    setAudioUrl(url)
    setAudioModalVisible(true)
  }

  const closeAudioModal = () => {
    setAudioUrl('')
    setAudioModalVisible(false)
  }
  const openScreenModal = (url) => {
    setScreenUrl(url)
    setScreenModalVisible(true)
  }

  const closeScreenModal = () => {
    setScreenUrl('')
    setScreenModalVisible(false)
  }


  const apiRequestGrouped = async (params = {}) => {
    if (!showCompleteTasks) {
      params['customQuery'] = { status: { $ne: 'complete' } }
    }
    const { data } = await dispatch(getTaskGrouped(params))
    setGroupedTask(data.data)
  }

  useEffect(() => {
    apiRequestGrouped()
  }, [])

  useEffect(() => {
    if (screenRecording) {
      startLiveVideo()
    } else {
      stopLiveVideo()
    }
  }, [screenRecording])

  const startLiveVideo = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      setVideoStream(stream)
      const videoElement = liveVideoRef.current
      if (videoElement) {
        videoElement.srcObject = stream
      }
    } catch (error) {
      console.error('Error accessing video media devices:', error)
    }
  }
  useEffect(() => {
    if (!videoOn) {
      if (liveVideoRef.current) {
        liveVideoRef.current.srcObject = null
      }
    } else {
      if (liveVideoRef.current) {
        liveVideoRef.current.srcObject = videoStream
      }
    }
  }, [videoOn, videoStream])

  useEffect(() => {
    const toggleSystemAudio = () => {
      if (audioStream && audioStream.getAudioTracks) {
        audioStream.getAudioTracks().forEach(track => {
          track.enabled = audioOn
        })
      }
    }

    toggleSystemAudio()

  }, [audioOn, audioStream])

  const stopLiveVideo = () => {
    const videoElement = liveVideoRef.current
    if (videoElement) {
      videoElement.srcObject = null
    }
    if (videoStream) {
      videoStream.getTracks().forEach(track => track.stop())
      setVideoStream(null)
    }
  }
  const toggleScreenRecordingModal = () => {
    setShowScreenRecordingModal(!showScreenRecordingModal)
  }
  const toggleAudioRecordingModal = () => {
    setShowAudioRecordingModal(!showAudioRecordingModal)
  }


  const updateAssigneeForTask = (taskId, assignee) => {
    const updatedTaskList = taskList.map((task) =>
      task._id === taskId ? { ...task, assignee } : task
    )
    setTaskList(updatedTaskList)
  }

  const handleUpdateTask = async (taskId, dataToUpdate) => {
    try {
      const result = await dispatch(updateTask(taskId, dataToUpdate))
      if (!result.error) {
        tableRef.current.reload()
      } else {
        // console.error(result.message);
      }
    } catch (error) {
      // console.error('Error updating task:', error);
    }
  }

  const handleAddUsers = () => {
    if (assignee && addedUsers.length === 0) {
      setAddedUsers([assignee])
      setAssignee('')
      setIsModalVisible(false)

      if (selectedTaskId) {
        updateAssigneeForTask(selectedTaskId, assignee)
        const dataToUpdate = { assignee }
        handleUpdateTask(selectedTaskId, dataToUpdate)
      }
    }
  }


  const handleStatusChange = async (taskId, status) => {
    const result = await dispatch(updateTask(taskId, { status }))
    if (!result.error) {
      events.reloadGroup()
    }
  }

  const handleDeadlineChange = async (data) => {
    if (data.taskId) {
      const result = await dispatch(updateTask(data.taskId, data))
      if (!result.error) {
        events.reloadGroup()
        deadlineEvents.hideDeadlineModal()
      } else {
        console.error(result.message)
      }
    }
  }

  const handleSelectPriority = async (priority) => {
    handleClosePriorityModal()
    if (priorityState.taskId) {
      try {
        const result = await dispatch(updateTask(priorityState.taskId, { priority }))
        if (!result.error) {
          events.reloadGroup()
        } else {
        }
      } catch (error) {
        console.error('Error updating task priority:', error)
      }
    } else {
      setPriority(priority)
    }
  }


  const handleShowFutureTasks = () => {
    history.push('/future-tasks')
  }


  const handleRemoveUser = (index) => {
    const updatedUsers = [...addedUsers]
    updatedUsers.splice(index, 1)
    setAddedUsers(updatedUsers)
  }

  const toggleAudio = () => {
    setAudioOn(!audioOn)
  }

  const toggleVideo = () => {
    setVideoOn(!videoOn)
  }
  const startAudioRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      setAudioStream(stream)
      const recorder = new MediaRecorder(stream)
      audioRecorder.current = recorder

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          setAudioChunks((prev) => [...prev, event.data])
        }
      }

      recorder.start()
      setAudioRecording(true)
    } catch (error) {
      console.error('Error accessing audio media devices:', error)
    }
  }


  const stopAudioRecording = () => {
    if (audioRecorder.current && audioRecording) {
      audioRecorder.current.stop()
      audioRecorder.current.onstop = () => {
        setAudioRecording(false)
        const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' })
        setAudioFile(audioBlob)
      }
    }
  }

  const deleteAudioRecording = () => {
    setAudioChunks([])
    setAudioRecordingDataUrl(null)
    setAudioFile(null)
    setShowAudioRecordingModal(false)
  }
  const deleteScreenRecording = () => {
    setScreenChunks([])
    setScreenRecordingFile(null)
    setScreenRecordingDataUrl(null)
    setShowScreenRecordingModal(false)
  }
  const downloadAudioRecording = () => {
    if (audioChunks && audioChunks.length > 0) {
      const blob = new Blob(audioChunks, { type: audioChunks[0].type })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'audio_recording.mp3'
      document.body.appendChild(a)
      a.click()
      URL.revokeObjectURL(url)
    }
  }

  const playAudioRecording = () => {
    if (audioChunks && audioChunks.length > 0) {
      const audioBlob = new Blob(audioChunks, { type: audioChunks[0].type })
      const audioUrl = URL.createObjectURL(audioBlob)
      setAudioRecordingDataUrl(audioUrl)
      toggleAudioRecordingModal()
    }
  }

  const startScreenRecording = async () => {
    try {
      let audioConstraint = audioOn ? true : false
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: { exact: true, suppressLocalAudioPlayback: true },
        selfBrowserSurface: 'include'
      })
      setScreenStream(screenStream)
      const recorder = new MediaRecorder(screenStream)
      screenRecorder.current = recorder

      let chunks = []
      let startTime = Date.now()

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data)
        }
      }

      recorder.onstop = () => {
        let endTime = Date.now()
        let duration = (endTime - startTime) / 1000
        if (duration >= 120) {
          console.log('Screen recording time limit (2 minutes) reached.')
          return
        }
        setScreenChunks((prev) => [...prev, ...chunks])
      }

      recorder.start()
      setScreenRecording(true)

      const videoStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      setVideoStream(videoStream)
      setVideoOn(true)
    } catch (error) {
      console.error('Error accessing screen media devices:', error)
    }
  }

  const stopScreenRecording = () => {
    if (screenRecorder.current && screenRecording) {
      screenRecorder.current.stop()
      setScreenRecording(false)
      const screenBlob = new Blob(screenChunks, { type: 'video/mp4' })
      setScreenRecordingFile(screenBlob)
      console.log(screenBlob, 'screenBlob')
    }
  }

  const downloadScreenRecording = () => {
    if (screenChunks && screenChunks.length > 0) {
      const blob = new Blob(screenChunks, { type: screenChunks[0].type })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'screen_recording.mp4'
      document.body.appendChild(a)
      a.click()
      URL.revokeObjectURL(url)
    }
  }
  const playScreenRecording = () => {
    if (screenChunks && screenChunks.length > 0) {
      const blob = new Blob(screenChunks, { type: 'video/mp4' })
      const dataUrl = URL.createObjectURL(blob)
      console.log(dataUrl, 'url')
      setScreenRecordingDataUrl(dataUrl)
      toggleScreenRecordingModal()
    }
  }

  const priorityOptions = [
    {
      key: 'Urgent',
      label: 'Urgent',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: '#b13a41', fontSize: '16px' }} />
    },
    {
      key: 'High',
      label: 'High',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: '#eca500', fontSize: '16px' }} />
    },
    {
      key: 'Normal',
      label: 'Normal',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: '#4466ff', fontSize: '16px' }} />
    },
    {
      key: 'Low',
      label: 'Low',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: 'gray', fontSize: '16px' }} />
    },
    { key: 'Clear', label: 'Clear', icon: <Icon type={'stop'} style={{ color: 'gray', fontSize: '16px' }} /> }
  ]

  const handleCreateTask = async () => {
    if (!newTask) {
      notification.warning({ message: 'Enter task.' })
      return
    }
    const formData = new FormData()
    const newTaskObject = {
      text: newTask,
      status: 'pending',
      deadline,
      priority: priority
      // screenRecord: audioFile,
      // audioRecord: screenRecordingFile
    }
    formData.append('obj', JSON.stringify(newTaskObject))
    console.log(audioFile, 'iii')
    if (audioFile) {
      formData.append('audioRecord', audioFile)
    }
    console.log(screenRecordingFile, 'screenrecord')
    if (screenRecordingFile) {
      formData.append('screenRecord', screenRecordingFile)

    }
    console.log(newTaskObject, 'newtaskobj')
    try {
      const result = await dispatch(addTask(formData))

      if (!result.error) {
        setTaskList((prevData) => {
          return [
            result.data,
            ...prevData
          ]
        })
        setNewTask('')
        setDeadline(null)
        setAssignee('')
        setScreenRecordingDataUrl(null)
        setAudioRecordingDataUrl(null)
        events.reloadGroup()
      } else {
        // console.error(result.message);
      }
    } catch (error) {
      console.error('Error adding task:', error)
    }
  }
  const getAssigneeInitials = (assignee) => {
    const initials = assignee
      .split(' ')
      .map((word) => word[0])
      .join('')
      .toUpperCase()
    return initials
  }


  const handleDeleteTask = async (taskId) => {
    const response = await dispatch(deleteTask(taskId))
    if (!response.error) {
      events.reloadGroup()
    }
  }


  const handleSaveEdit = (taskId, newText) => {
    const updatedTaskList = taskList.map((task) =>
      task.id === taskId ? { ...task, text: newText, isEditing: false } : task
    )
    setTaskList(updatedTaskList)
  }
  const handleOpenModal = () => {
    setIsModalVisible(true)
    setAddedUsers([])
  }

  const handleCloseModal = () => {
    setIsModalVisible(false)
  }


  const handleOpenPriorityModal = (taskId = '') => {
    setPriorityState({
      taskId: taskId,
      visible: true
    })
  }

  const handleClosePriorityModal = () => {
    setPriorityState({
      taskId: '',
      visible: false
    })
  }

  const getPriorityColor = (priority) => {
    const priorityOption = priorityOptions.find((option) => option.key === priority)
    return priorityOption ? priorityOption.icon.props.style.color : ''
  }


  useEffect(() => {
    events.reloadGroup()
  }, [showCompleteTasks, showGroupTask])

  const handleShowCompleteTasks = () => {
    setShowCompleteTasks(prevShowCompleteTasks => !prevShowCompleteTasks)
  }


  const deadlineEvents = {
    chooseDate: (date) => {
      setDeadLineState((prevState) => {
        return {
          ...prevState,
          ...date
        }
      })
    },
    showDeadlineModal: (data) => {
      setDeadLineState({
        visible: true,
        deadline: data.deadline,
        taskId: data._id
      })
    },
    hideDeadlineModal: () => {
      setDeadLineState({
        visible: false,
        deadline: '',
        taskId: ''
      })
    }
  }

  const events = {
    reloadGroup: () => {
      if (conRef.current && conRef.current.props && conRef.current.props.reload) {
        conRef.current.props.reload()
      }
      if (tableRef.current && tableRef.current.reload) {
        tableRef.current.reload()
      }
    }
  }

  const taskColumns = [
    {
      title: 'Task',
      dataIndex: 'text',
      key: 'text',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'flex-start', maxWidth: '100%' }}>
          <div
            style={{
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              border: record.status === 'pending' ? '1px solid black' : 'none',
              background:
                record.status === 'pending'
                  ? 'transparent'
                  : record.status === 'inprogress'
                    ? 'conic-gradient(red 0 75%, transparent 25%)'
                    : 'green',
              marginRight: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              flexShrink: 0
            }}>
            {record.status === 'complete' && <Icon type='check' style={{ color: 'white' }} />}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              maxWidth: '100%',
              flex: 1
            }}>
            {editingTaskId === record.id ? (
              <Input
                autoFocus
                value={text}
                onPressEnter={() => {
                  handleSaveEdit(record.id, text)
                  setEditingTaskId(null)
                }}
                onBlur={() => setEditingTaskId(null)}
                onChange={(e) => {
                  const updatedTaskList = taskList.map((task) =>
                    task.id === record.id ? { ...task, text: e.target.value } : task
                  )
                  setTaskList(updatedTaskList)
                }}
                style={{
                  maxWidth: '100%',
                  whiteSpace: 'normal',
                  textDecoration: record.status === 'complete' ? 'line-through' : 'none'
                }}
              />
            ) : (
              <div className={'display-linebreak'}
                   onDoubleClick={() => setEditingTaskId(record.id)}
                   style={{ textDecoration: record.status === 'complete' ? 'line-through' : 'none' }}>
                {text}
              </div>
            )}
          </div>
        </div>
      )
    },
    // {
    //   title: 'Assignee',
    //   dataIndex: 'assignee',
    //   key: 'assignee',
    //   width: 120,
    //   render: (_, record) => (
    //     <Button type='link' onClick={handleOpenModal}>
    //       <Icon type={'user'} style={{ color: '#666666', fontSize: '16px' }} />
    //       {record.assignee && (
    //         <span style={{ marginLeft: '8px' }}>
    //                   {getAssigneeInitials(record.assignee)}
    //                 </span>
    //       )}
    //     </Button>
    //   )
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status, record) => (
        <Select defaultValue={status} className={'small-select'}
                onChange={(value) => handleStatusChange(record._id, value)}>
          <Option value='pending'>Pending</Option>
          <Option value='inprogress'>In Progress</Option>
          <Option value='complete'>Complete</Option>
        </Select>
      )
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      width: 90,
      render: (taskPriority, record) => {
        const priorityColor = getPriorityColor(taskPriority)
        return (
          <Button type='link' onClick={() => {
            handleOpenPriorityModal(record._id)
          }}>
            <Icon type={'flag'} theme={'filled'}
                  style={{ color: priorityColor, fontSize: '14px' }} />
            {taskPriority && <span style={{ marginLeft: '8px', fontSize: '13px' }}>{taskPriority}</span>}
          </Button>
        )
      }
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      width: 90,
      render: (deadline, record) => (
        <div style={{ width: 120 }}>
          <Button type='link' className={'font14'} onClick={() => {
            deadlineEvents.showDeadlineModal(record)
          }}>
            <Icon type='calendar' style={{ fontSize: '13px' }} />
            {deadline ? displayDate(deadline) : null}
          </Button>
        </div>
      )
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 100,
      render: (date) => moment(date).format('DD MMM YY')
    },
    {
      title: 'Audio',
      dataIndex: 'audioRecord',
      key: 'audioRecord',
      width: 50,
      render: (audioRecord) => (
        <div>
          {audioRecord && (
            <Icon
              type='play-circle'
              style={{ color: 'blue', cursor: 'pointer', fontSize: '14px' }}
              onClick={() => openAudioModal(audioRecord.url)}
            />
          )}
        </div>
      )
    },
    {
      title: 'Screen',
      dataIndex: 'screenRecord',
      key: 'screenRecord',
      width: 50,
      render: (screenRecord) => (
        <div>
          {screenRecord && (
            <Icon
              type='play-circle'
              style={{ color: 'green', cursor: 'pointer', fontSize: '14px' }}
              onClick={() => openScreenModal(screenRecord.url)}
            />
          )}
        </div>
      )
    },
    {
      key: 'actions',
      width: 30,
      render: (record) => (
        <div>
          <Popconfirm
            title='Are you sure you want to delete this task?'
            onConfirm={() => handleDeleteTask(record._id)}
            okText='Yes'
            cancelText='No'
            placement='topRight'>
            <Button type={'link'}>
              <Icon type={'close'} />
            </Button>
          </Popconfirm>
        </div>
      )
    }
  ]

  console.log(screenStream, 'screenStream')
  return (
    <>


      {screenRecording && (
        <div className='mini-screen-container'>
          <div className='mini-screen'>
            {screenRecording && (
              <video
                ref={liveVideoRef}
                controls
                autoPlay
                className='live-video'
                srcObject={videoOn ? videoStream : null}
              />
            )}
            {screenRecording && (
              <div className='stop-icon'>
                <Icon type='close' onClick={stopScreenRecording} style={{
                  color: 'white',
                  fontSize: '18px',
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '10px',
                  right: '10px'
                }} />
                {audioOn ? (
                  <div className='audio-icon' onClick={toggleAudio}>
                    <Icon type={'audio'} style={{
                      color: 'white',
                      fontSize: '18px',
                      cursor: 'pointer',
                      position: 'absolute',
                      top: '10px',
                      right: '40px'
                    }} />
                  </div>
                ) : (
                  <Icon type={'audio'} style={{
                    color: 'red',
                    fontSize: '18px',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '10px',
                    right: '40px'
                  }} onClick={toggleAudio} />
                )}
                {videoOn ? (
                  <div className='video-icon' onClick={toggleVideo}>
                    <Icon type={'video-camera'} style={{
                      color: 'white',
                      fontSize: '18px',
                      cursor: 'pointer',
                      position: 'absolute',
                      top: '10px',
                      right: '70px'
                    }} />
                  </div>
                ) : (
                  <Icon type={'video-camera'} style={{
                    color: 'red',
                    fontSize: '18px',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '10px',
                    right: '70px'
                  }} onClick={toggleVideo} />
                )}
              </div>
            )}
          </div>
        </div>
      )}

      <div className='row'>

        <div className='col-lg-12'>
          <div className='form-box mt-4'>
            <div className='d-flex align-items-center mb-3 heading-form'>
              <h5>Task Manager</h5>
            </div>
            <div className='card unizportal'>
              <div className={'row'}>
                <div className={'col-md-10'}>
                  <TextArea
                    placeholder='Enter new task'
                    value={newTask}
                    style={{ minHeight: 115 }}
                    onChange={(e) => setNewTask(e.target.value)}
                    className='input-hover'
                  />

                </div>

                <div className={'col-md-2'}>
                  <div className={'row'}>
                    <div className={'col-md-12'}>
                      <DatePicker
                        className='date-picker input-hover dateH35'
                        value={deadline ? moment(deadline) : null}
                        placeholder='Deadline'
                        onChange={(date) => setDeadline(date)}
                      />
                    </div>
                    { /* <div className={'col-md-12 mt5'}>
                      <Input
                        placeholder='Assignee'
                        value={assignee}
                        onChange={(e) => setAssignee(e.target.value)}
                        suffix={<Icon type={'user'} style={{ color: '#b13a41', fontSize: '16px' }}
                                      onClick={handleOpenModal} />}
                      />
  </div> */}
                    <div className={'col-md-12 mt5'}>
                      <Input
                        placeholder='Priority'
                        value={priority}
                        disabled={true}
                        onChange={(e) => setPriority(e.target.value)}
                        suffix={<Icon type={'flag'} style={{ color: '#eca500', fontSize: '16px' }}
                                      onClick={() => {
                                        handleOpenPriorityModal()
                                      }} />}
                      />
                      <Input
                        accept='audio/*'
                        value={audioFile ? audioFile.type : ''}
                        suffix={<Icon type={'play-circle'} onClick={playAudioRecording}
                                      style={{ color: 'blue', cursor: 'pointer' }} />}
                      />

                      <Input accept='video/*' value={screenRecordingFile ? screenRecordingFile.type : ''}
                             suffix={<Icon type={'play-circle'} onClick={playScreenRecording}
                                           style={{ color: 'green', cursor: 'pointer' }} />}
                      />

                    </div>


                  </div>

                </div>
                <div className={'col-md-12 mt10 alignRight'}>
                  <Button
                    type='primary'
                    style={{ backgroundColor: '#0f52ba', border: 'none', marginRight: 5, borderRadius: 5 }}
                    onClick={handleCreateTask}
                    className='primary-button-hover'>
                    Add Task
                  </Button>
                  <Button className='cancel-button-hover'>
                    Cancel
                  </Button>
                </div>
              </div>
              <div className={'col-md-12 mt0'}>
                <div className='recorder-buttons'>
                  {screenRecording ? (
                    <Button className='btn btn-danger' onClick={stopScreenRecording}
                            style={{ marginRight: '10px', fontSize: '12px' }}>Stop Screen Recording
                      <Icon type={'stop'} onClick={stopScreenRecording} className='btn-record'
                            disabled={screenRecording} />
                    </Button>
                  ) : (
                    <>
                      {!audioRecording && (
                        <Button className='btn btn-primary me3' onClick={startScreenRecording}
                                style={{ marginRight: '10px', fontSize: '12px' }}>Start Screen Recording
                          <Icon type={'video-camera'} onClick={startScreenRecording} className='btn-record'
                                disabled={audioRecording} />
                        </Button>
                      )}
                    </>
                  )}

                  {audioRecording ? (
                    <>
                      <Button className='btn btn-danger me3' onClick={stopAudioRecording}
                              style={{ marginRight: '10px', fontSize: '12px' }}>Stop Audio
                        <Icon
                          type={'stop'}
                          onClick={stopAudioRecording}
                          className='btn-record'
                        />
                      </Button>

                      <Button className='audio-recording-icon' style={{
                        padding: '0',
                        border: 'none',
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
                        background: 'white',
                        textDecoration: 'underline'
                      }}>

                        <Icon type='audio' theme='twoTone' style={{ fontSize: '16px', color: 'darkBlue' }} />
                      </Button>

                    </>


                  ) : (
                    <>
                      {!screenRecording && (

                        <Button className='btn btn-primary me3' onClick={startAudioRecording}
                                style={{ marginRight: '10px', fontSize: '12px' }}>Start Audio
                          <Icon
                            type={'audio'}
                            onClick={startAudioRecording}
                            className='btn-record'
                          />
                        </Button>
                      )}

                    </>
                  )}


                </div>
              </div>

            </div>
          </div>
        </div>
        <div className='col-lg-12'>
          <div className='form-box'>
            <div className='card unizportal'>
              <div className={'mt5'}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: 10
                }}>
                  <Button
                    className='group-by-button-hover mr10'
                    onClick={() => {
                      setShowGroupTask(!showGroupTask)
                    }}>
                    {showGroupTask ? 'Un - Group By' : 'Group By'}
                  </Button>

                  <Button
                    className='show-complete-tasks-button-hover'
                    onClick={handleShowCompleteTasks}>
                    {showCompleteTasks ? 'Hide Complete Tasks' : 'Show Complete Tasks'}
                  </Button>
                  <Button className='show-complete-tasks-button-hover'
                          onClick={handleShowFutureTasks}>
                    Future Task
                  </Button>


                </div>
              </div>
              {showGroupTask ? (
                <div>
                  <Collapse ref={conRef} reload={() => {
                    apiRequestGrouped()
                  }}>
                    {groupedTask && groupedTask.length ? groupedTask.map((item) => {
                      const formattedDate = moment(item.date).format('Do MMM YYYY')
                      return (
                        <Panel key={item._id} header={formattedDate}>
                          <Table
                            // style={tableStyles}
                            columns={taskColumns}
                            dataSource={item.tasks}
                            pagination={false}
                            size={'small'} bordered={true} />
                        </Panel>
                      )
                    }) : null}
                  </Collapse>
                </div>
              ) : (
                <TableComp columns={taskColumns} style={tableStyles}
                           apiRequest={apiRequest}
                           ref={tableRef}
                           rowKey='_id' />
              )}
            </div>
          </div>
        </div>
      </div>


      {priorityState.visible ? <Modal
        title='Priority'
        visible={priorityState.visible}
        onCancel={handleClosePriorityModal}
        footer={null}
        width={200}>
        <List dataSource={priorityOptions}
              renderItem={(item, index) => (
                <List.Item
                  onClick={() => handleSelectPriority(item.key)}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 0',
                    borderBottom: index === 3 ? '1px solid #d9d9d9' : 'none',
                    backgroundColor: priority === item.key ? '#e6f7ff' : 'inherit'
                  }}>
                  {item.icon && <span style={{ marginRight: '8px' }}>{item.icon}</span>}
                  <div style={{
                    textAlign: 'center',
                    fontSize: '14px',
                    color: '#000'
                  }}>{item.label}</div>
                </List.Item>
              )}
        />

      </Modal> : null}

      { /* {isModalVisible ? <Modal
        title='Add Users'
        visible={isModalVisible}
        onCancel={handleCloseModal}
        width={400}
        footer={null}
      >
        <div style={{ display: 'flex' }}>
          <Input placeholder='Assignee'
                 value={assignee}
                 onChange={(e) => setAssignee(e.target.value)}
                 suffix={<Icon type={'user'} style={{ color: '#b13a41', fontSize: '16px' }} />} />
          <Button className={'ml10'}
                  type='primary'
                  onClick={handleAddUsers}>
            Add
      </Button>
        </div>
        {addedUsers.length > 0 && (
          <List
            dataSource={addedUsers}
            renderItem={(user, index) => (
              <List.Item
                actions={[
                  <Button type='link' onClick={() => handleRemoveUser(index)}
                          className='remove-btn'>
                    Remove
                  </Button>
                ]}
              >
                <span style={{ color: '#000' }}>{user}</span>
              </List.Item>
            )}
          />
        )}
              </Modal> : null} */}
      {deadLineState.visible ?
        <Modal
          title='Select Deadline'
          visible={deadLineState.visible}
          onCancel={deadlineEvents.hideDeadlineModal}
          onOk={() => handleDeadlineChange(deadLineState)}>
          <DatePicker
            value={deadLineState.deadline ? moment(deadLineState.deadline) : null}
            onChange={(date) => deadlineEvents.chooseDate({ deadline: date })}
          />
        </Modal> : null}
      <Modal
        title='Screen Recording'
        visible={showScreenRecordingModal}
        onCancel={toggleScreenRecordingModal}

        width={'60%'}
        centered
        style={{ margin: '50px' }}
        footer={null}
      >
        <video
          src={screenRecordingDataUrl}
          controls
          style={{ maxWidth: '100%', maxHeight: '80vh' }}
        />
        <div style={{ marginTop: '10px' }}>
          <Icon type={'delete'} onClick={deleteScreenRecording} style={{ marginRight: '10px', textAlign: 'end' }} />
          <Icon type={'download'} onClick={downloadScreenRecording} style={{ marginRight: '10px', textAlign: 'end' }} />
        </div>
      </Modal>
      <Modal
        title='Audio Recording'
        visible={showAudioRecordingModal}
        onCancel={toggleAudioRecordingModal}
        width={'80%'}
        centered
        style={{ margin: '50px' }}
        footer={null}
        className='audio-recording-modal'
      >
        <div>
          <audio
            src={audioRecordingDataUrl}
            controls
            style={{ maxWidth: '100%', maxHeight: '80vh' }}
          />
          {audioRecordingDataUrl && (

            <Icon type='delete' onClick={deleteAudioRecording}
                  className='delete-audio-btn' />
          )}
        </div>
      </Modal>
      <Modal
        title='Audio Player'
        visible={audioModalVisible}
        onCancel={closeAudioModal}
        footer={null}
        width={'80%'}
        centered
        style={{ margin: '50px' }}
      >
        <audio controls>
          <source src={audioUrl} />
          Your browser does not support the audio element.
        </audio>
      </Modal>
      <Modal
        title='Screen Recording Player'
        visible={screenModalVisible}
        onCancel={closeScreenModal}
        width={'60%'}
        centered
        style={{ margin: '50px' }}
        footer={null}
      >
        <video controls style={{ width: '100%' }}>
          <source src={screenUrl} />
          Your browser does not support the video element.
        </video>
      </Modal>
    </>
  )

}
export default TaskManager
