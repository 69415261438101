import React, {useState} from "react"
import {Button, Col, DatePicker, Drawer, Form, Input, notification, Row, Select} from "antd";
import {InputBox} from "../../../components/_utils/appUtils";
import moment from "moment";
import _ from "lodash"
import {updateIcefMeetingStatusFxn} from "../actions";

const dateFormat = 'DD/MM/YYYY'

let {Option} = Select
let statusList = [
    {name: "Yes", value: "Approved"},
    {name: "No", value: "Denied"},
    {name: "Deferred", value: "Deferred"}
]
let initState = {
    status: "",
    reason: "",
    deferDate: "",
    comment: "",
    uploadKey: moment(),
    contract: ""
}
const UpdateMeetingStatus = (props) => {
    let {visible, onClose, dispatch, onSubmit, meetingId} = props;
    console.log(props, 'propss-')
    let [state, setState] = useState(initState);
    let events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        handleSubmit: async () => {
            let obj = _.clone(state)
            let {status} = obj;
          obj.meetingId = meetingId
          console.log(obj, 'obj')
            if (!obj.status) {
                notification.warning({message: "Choose Status"})
                return
            }
            if (status == 'Approved') {
                if (!obj.contract || (obj.contract && !obj.contract.name)) {
                    notification.warning({message: "Choose Contract"})
                    return
                }
            }
            if (status == 'Denied') {
                if (!obj.reason) {
                    notification.warning({message: "Enter Reason"})
                    return
                }
            }
            if (status == 'Deferred') {
                if (!obj.deferDate) {
                    notification.warning({message: "Choose Date"})
                    return
                }
            }
            let fd = new FormData()
            fd.append('obj', JSON.stringify(obj))
            if (state.contract && state.contract.name) {
                fd.append('contract', state.contract)
            }
            delete obj['contract'];

            let resp = await dispatch(updateIcefMeetingStatusFxn(fd));
            if (resp && resp.success) {
                onSubmit()
            }
        }
    }

    return (
        <>
            <Drawer title={'Update Meeting Status'} visible={visible} width={'40%'} onClose={onClose}>
                <div className="form-box">
                    <div className="card unizportal">
                        <Form>
                            <Row gutter={10}>
                                <Col span={24}>
                                    <InputBox title={'Choose Status *'}>
                                        <Select
                                            placeholder={'Choose Status'}
                                            value={state.status}
                                            allowClear={true}
                                            onChange={(value) => {
                                                events._updateState({status: value})
                                            }}>
                                            {statusList && statusList.length ? statusList.map((item) => {
                                                return (
                                                    <Option value={item.value}>{item.name}</Option>
                                                )
                                            }) : null}
                                        </Select>
                                    </InputBox>
                                </Col>
                                {state.status == "Approved" ? <Col span={24}>
                                    <InputBox title={'Upload Contract *'}>
                                        <input type={'file'} className={'form-control'}
                                               key={state.uploadKey}
                                               name={'contract'}
                                               id={'contract'}
                                               onChange={({target}) => {
                                                   let file = target.files && target.files.length ? target.files[0] : null
                                                   events._updateState({contract: file})
                                               }}/>
                                    </InputBox>
                                </Col> : null}

                                {state.status == "Denied" ? <Col span={24}>
                                    <InputBox title={'Reason *'}>
                                        <textarea className={'form-control customTextArea'}
                                                  placeholder={'Reason'}
                                                  value={state.reason}
                                                  rows={5}
                                                  onChange={({target}) => {
                                                      events._updateState({reason: target.value})
                                                  }}/>
                                    </InputBox>
                                </Col> : null}

                                {state.status == "Deferred" ? <>
                                    <Col span={24}>
                                        <InputBox title={'Date *'}>
                                            <DatePicker
                                                className={'form-control'} format={dateFormat}
                                                value={state.deferDate}
                                                placeholder={'Date'}
                                                onChange={(value) => {
                                                    events._updateState({deferDate: value})
                                                }}/>
                                        </InputBox>
                                    </Col>
                                    <Col span={24}>
                                        <InputBox title={'Comment'}>
                                        <textarea className={'form-control customTextArea'}
                                                  placeholder={'Comment'}
                                                  value={state.comment}
                                                  onChange={({target}) => {
                                                      events._updateState({comment: target.value})
                                                  }}/>
                                        </InputBox>
                                    </Col>
                                </> : null}
                            </Row>

                            <Button type="primary" htmlType="submit" className="btn" onClick={events.handleSubmit}>
                                Submit
                            </Button>
                        </Form>
                    </div>
                </div>
            </Drawer>

        </>
    )
}
export default UpdateMeetingStatus
