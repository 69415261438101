import React, {useEffect, useState} from 'react';
import {Button, Card, Drawer, Form, Input, Avatar, Tabs, notification, Row, Col} from 'antd';
import {useDispatch} from 'react-redux';
import {InputBox} from "../../components/_utils/appUtils";
import {unizhomeContractNoteFxn, unizhomeNoteListFxn, unizhomeActivityFxn} from "./actions";
import {displayDate, displayTime, nameAvatar} from "../../components/_utils/appUtils";
import styles from '../../containers/IcefEvent/styles.less'
import '../../../src/common.less'

const {TextArea} = Input;
const {TabPane} = Tabs;

const NotesHistory = (props) => {
    const {contract, visible, onClose} = props;
    const [note, setNote] = useState('');
    const [noteHistory, setNoteHistory] = useState([]);
    const [activityList, setActivityList] = useState([]);
    const dispatch = useDispatch();
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null

    const apiRequest = async () => {
        try {
            const resp = await dispatch(unizhomeNoteListFxn({contractId: contract._id}));
            setNoteHistory(resp);
        } catch (error) {
            // console.error('Error fetching note history:', error);
        }
    };

    const getActivity = async () => {
        try {
            const resp = await dispatch(unizhomeActivityFxn({contractId: contract._id}));
            console.log(resp, "respppppppp")
            setActivityList(resp);
        } catch (error) {
            // console.error('Error fetching note history:', error);
        }
    };


    useEffect(() => {
        if (visible) apiRequest();
        getActivity();
    }, [visible]);

    const handleSubmit = async () => {
        if (note) {
            const valData = {contractId: contract._id, note, date: new Date()};
            const resp = await dispatch(unizhomeContractNoteFxn(valData));
            if (resp.success) {
                notification.success({message: "Note added successfully!"});
                setNote('');
                apiRequest();
                getActivity();
            } else {
                notification.error({message: resp.message});
            }
        } else {
            notification.warning({message: "Please enter a note."});
        }
    };

    return (
        <Drawer width={'900'} title="Notes and Activity" placement="right" visible={visible} onClose={onClose}>
            <div className={styles.participant_card}>
                <Row gutter={16}>
                    <Col span={24}>
                        <div style={{padding: '30px'}}>
                            <div className={'align_center'}>
                                Company Name : &nbsp; {contract.companyName}
                            </div>
                            <div className={styles.alignCenter}>
                                Contact Name : &nbsp; {contract.contactPersonName}
                            </div>
                            <div className={styles.alignCenter}>
                                Email : &nbsp; {contract.email}
                            </div>
                            <div className={styles.alignCenter}>
                                Mobile : &nbsp; {contract.contactMobileNo}
                            </div>
                        </div>

                        <div className={styles.info_card}>
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="Notes" key="1" className={styles.comments}>
                                    <div className="comment-section">
                                        <div className='comment-wrap-new' style={{overflowY: 'auto'}}>
                                            {noteHistory.length ? noteHistory.map((item) => (
                                                <div className="comment" key={item._id}>
                                                    <Avatar className="textUpp avatar_text1" size="large">
                                                        {nameAvatar(item.addedBy.name)}
                                                    </Avatar>
                                                    <div className={styles.comment_text}>
                                                        <p className="comment-author">
                                                          <span className="name">
                                                            <strong>{item.addedBy.name}</strong>
                                                            <span className="date1">
                                                              {displayDate(item.createdAt)} | {displayTime(item.createdAt)}
                                                            </span>
                                                          </span>
                                                        </p>
                                                        <div className="mb5">{item.note}</div>
                                                    </div>
                                                </div>
                                            )) : <p>No notes to display.</p>}
                                        </div>
                                        <div className={'mt10'}>
                                            <div className={styles.input_box}>
                                                <Avatar className={'textUpp avatar_text1'} size='large'>
                                                    {nameAvatar(user.name)}
                                                </Avatar>
                                                <Input
                                                    placeholder='Add Comment' className={styles.input_comment1}
                                                    value={note}
                                                    onChange={(e) => setNote(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleSubmit();
                                                        }
                                                    }}
                                                />
                                                <i className='fa fa-paper-plane fa-lg ml-2'
                                                   aria-hidden='true'
                                                   style={{cursor: 'pointer'}}
                                                   onClick={handleSubmit}
                                                />

                                            </div>
                                        </div>
                                    </div>

                                </TabPane>


                                <TabPane tab="Activity" key="2" className={styles.activity}>
                                    <div className="act-container">
                                        <div className="act-card">
                                            <div className="act-card-body">
                                                <div className="scrollView" style={{height: '62vh'}}>
                                                    <div className="application-activity profile-activity">
                                                        <div className="card-body mt0">
                                                            <ul className={'activity-container'}>
                                                                {activityList.length ? activityList.map((item, key) => (
                                                                    <li key={key}>
                                                                        <div className="date">
                                                                            {displayDate(item.createdAt)}<br/>
                                                                            {displayTime(item.createdAt)}
                                                                        </div>
                                                                        <div className={'activity_timeline'}>
                                                                        </div>
                                                                        <div className="content">
                                                                            <div
                                                                                className={'title'}>{item.activityType}</div>
                                                                            <div className={'information'}>
                                                                                <div>by <i>{item.user.name}</i></div>
                                                                                <div>{item.message}</div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )) : <li>No activity to display</li>}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </div>
        </Drawer>
    );
};

export default NotesHistory;
