import {apiUrl} from '../../../settings'

export const addWhatsNewUrl = () => {
    return apiUrl + '/addWhatsNew '
}

export const whatsNewListUrl = () => {
    return apiUrl + '/whatsNewList'
}

export const whatsNewStatusUpdateUrl = () => {
    return apiUrl + '/whatsNewStatusUpdate'
}

export const resetWhatsNewCountUrl = () => {
    return apiUrl + '/resetWhatsNewCount'
}

export const getWhatsNewCountUrl = () => {
    return apiUrl + '/getWhatsNewCount'
}

export const deleteWhatsNewUrl = () => {
    return apiUrl + '/deleteWhatsNew'
}
