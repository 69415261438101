import { notification } from 'antd'
import { hidePageLoad, showPageLoad } from '../../../../modules/actions'
import { customAxios as axios, getToken } from '../../../../request'
import {
  addNoteUrl,
  addTrainingRequestUrl,
  notesListUrl,
  trainingRequestListUrl,
  updateStatusUrl
} from '../apis'

export const listAllTrainingRequest = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(trainingRequestListUrl(), config)
  return data.data
}
export const notesList = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(notesListUrl(), config)
  return data
}
export const addTrainingRequest = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addTrainingRequestUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
export const addNoteFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addNoteUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
export const updateStatusFxn = valData => async dispatch => {
  console.log(valData, 'vvvvvvvvvvv')
  dispatch(showPageLoad())
  let { data } = await axios.post(updateStatusUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
