import React, {useState, useEffect} from 'react'
import {Button, Card, Checkbox, DatePicker, Drawer, Form, Input, notification} from 'antd'
import {departmentObj, InputBox} from '../../../components/_utils/appUtils'
import {submitNote} from '../../student/actions/student'
import moment from 'moment'
import {useDispatch} from 'react-redux'

const {TextArea} = Input
const dateFormat = 'DD/MM/YYYY'

const CreateNote = (props) => {
    let {studentData, applicationId, visible, onClose, addedType = 'note', reloadFxn} = props
    let dispatch = useDispatch()
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    const [callRecording, setCallRecording] = useState("")
    const [uploadKey, setUploadKey] = useState(moment())

    let [state, setState] = useState({
        documentKey: moment(),
        sendToStudent: false,
        note: null,
    })
    let [followUpDate, setFollowUpDate] = useState(null)
    const chooseCallRecording = (e) => {
        let {name, files} = e.target
        if (files && files.length) {
            setCallRecording(files[0])
        }
    }
    const events = {
        showDrawer: () => {
            setState({...state, noteDrawerVisible: true})
        },
        hideDrawer: () => {
            setState({...state, noteDrawerVisible: false})
        },
        setLocalState: (e) => {
            let {name, value} = e.target
            setState({
                ...state,
                [name]: value
            })
        },
        chooseDocument: (e) => {
            let {name, files} = e.target
            if (files && files.length) {
                setState({
                    ...state,
                    document: files[0]
                })
            }
        },

        handleSubmit: async () => {
            let obj = {
                studentId: studentData._id,
                note: state.note,
                followUpDate: followUpDate ? followUpDate : null,
                applicationId: applicationId,
                sendToStudent: state.sendToStudent
            }
            if (!obj.note) {
                notification.warning({
                    message: 'Please enter note.'
                })
                return
            }
            if (user && user.department == departmentObj.assDepartment && user.managerViewOnly) {
                if (!callRecording || (callRecording && !callRecording.name)) {
                    notification.warning({
                        message: 'Choose call recording.'
                    })
                    return
                }
            }
            let fd = new FormData()
            fd.append('obj', JSON.stringify(obj))
            fd.append('document', state.document)
            if (callRecording && callRecording.name) {
                fd.append('callRecording', callRecording)
            }
            let data = await dispatch(submitNote(fd))
            if (data && !data.error) {
                setUploadKey(moment())
                setState({
                    ...state,
                    note: '',
                    document: '',
                    documentKey: moment(),
                    noteDrawerVisible: false
                })
                onClose()
                reloadFxn()
                setCallRecording({})

            }
        }
    }

    return (
        <Drawer width={700}
                title={'Create Note'}
                placement="left"
                visible={visible}
                onClose={() => {
                    onClose()
                }}>
            <Card bordered={false}>
                <Form>
                    <label>
                        <Checkbox
                            rows={7}
                            onChange={() => {
                                setState({
                                    ...state,
                                    sendToStudent: !state.sendToStudent
                                })
                            }}
                            name={'sendToStudent'} value={state.sendToStudent}/> &nbsp;Send Note To Student
                    </label>
                    <InputBox title={'Note'}>
                        <TextArea rows={7} onChange={events.setLocalState} name={'note'}
                                  className={'form-control'}
                                  placeholder={'Note'} value={state.note}/>
                    </InputBox>
                    <InputBox title={'Document'}>
                        <Input type={'file'} name={'document'} id={'document'} key={state.documentKey}
                               className={'form-control'}
                               onChange={(e) => {
                                   events.chooseDocument(e)
                               }}/>
                    </InputBox>
                    {user && user.department == departmentObj.assDepartment && user.managerViewOnly ?
                        <InputBox title={'Please choose recording'}>
                            <Input type={'file'} key={uploadKey} className={'form-control'}
                                   accept=".mp3,audio/!*"
                                   name={'callRecording'} id={'callRecording'} onChange={(e) => {
                                chooseCallRecording(e)
                            }}/>
                        </InputBox>
                        : null}


                    {user && user.department == departmentObj.assDepartment ?
                        <InputBox title={'Follow Up Date'}>
                            <DatePicker
                                defaultValue={followUpDate} className={'form-control'} format={dateFormat}
                                placeholder={'Follow Up Date'}
                                onChange={(value) => {
                                    setFollowUpDate(value)
                                }}/>
                        </InputBox>
                        : null}

                    <Form.Item className={'mt20 alignRight'}>
                        <Button type='primary' onClick={events.handleSubmit}>
                            SUBMIT
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Drawer>
    )
}
export default CreateNote
