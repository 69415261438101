import { apiUrl2 } from '../../../settings'

export const AddPalUrl = () => {
  return `${apiUrl2}/addCornerStoner`
}
export const AddDocumentUrl = () => {
  return `${apiUrl2}/addDocument`
}

export const getCornetStoneStudents = () => {
  return `${apiUrl2}/getCornerStoneStudents`
}

export const updateCornetStoneStudentUrl = () => {
  return `${apiUrl2}/updateCornerStoneStudents`
}
