import React, { useState, useEffect } from 'react'
import { Form, Select, Button, notification, Row, Col, Drawer, Input, Icon } from 'antd'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { addScheduleMeeting, scheduleMeetingUsersList } from '../actions/scheduleMeeting'
import { InputBox, ScheduleTypes, durationList } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import moment from 'moment'

const { Option } = Select
const format = 'h:mm A'
const AddScheduleMeetingComponent = (props) => {
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields }, visible, events } = props
  const dispatch = useDispatch()
  let [users, setUsers] = useState([])
  let [guestUsers, setGuestUsers] = useState([])
  let [time, setTime] = useState(null)
  let [email, setEmail] = useState(null)
  let [userList, setUserList] = useState([])
  useEffect(() => {
    loadUser()
  }, [])


  const chooseGuestEmail = (email) => {
    if (!email) {
      return notification.warning({ message: 'Enter email' })
    }
    email = email.toLowerCase().trim()
    if (email.indexOf('@') > 0) {
      let find = _.find(guestUsers, (item) => {
        return email == item
      })
      if (find) {
        notification.warning({ message: 'Email already exists.' })
      } else {
        setGuestUsers([...guestUsers, email])
        setEmail(null)
      }
    } else {
      notification.warning({ message: 'Enter valid email' })
    }
  }

  const removeGuestEmail = (index) => {
    let cloneEmail = _.clone(guestUsers)
    cloneEmail.splice(index, 1)
    setGuestUsers(cloneEmail)
  }


  const onChange = (value) => {
    setTime(value)
  }

  const loadUser = async () => {
    let { data } = await dispatch(scheduleMeetingUsersList({}))
    setUserList(data)
  }


  let formFields = [
    { key: 'topic', label: 'Topic', required: true, placeholder: 'Enter Topic', span: 24 },
    {
      key: 'types',
      label: 'Type',
      required: true,
      type: 'select',
      showSearch: true,
      mode: 'multiple',
      span: 12,
      options: ScheduleTypes,
      keyAccessor: x => x,
      valueAccessor: x => `${x}`,
      onChange: x => {
        props.form.setFieldsValue({
          types: x
        })
      }
    },
    {
      key: 'date',
      label: 'Date',
      required: true, type: 'date',
      placeholder: 'Choose Date', span: 12
    },
    {
      key: 'fromTime',
      label: 'From Time',
      required: true,
      type: 'time',
      use12Hours: true,
      placeholder: 'Choose From Time', span: 12,
      minuteStep: 15,
      format: format
    },

    {
      key: 'duration',
      label: 'Duration',
      required: true,
      type: 'select',
      span: 12,
      options: durationList,
      keyAccessor: x => x.value,
      valueAccessor: x => `${x.label}`,
      onChange: x => {
        props.form.setFieldsValue({
          duration: x
        })
      }
    },

    {
      key: 'place', label: 'Place', placeholder: 'Enter Place', span: 12,
      hidden: !(getFieldValue('types') && getFieldValue('types').includes('Physical'))
    },
    {
      key: 'user',
      label: 'User',
      required: true,
      type: 'select',
      showSearch: true,
      span: 12,
      options: userList,
      keyAccessor: x => x.email,
      valueAccessor: x => `${x.label}`,
      onChange: x => {
        props.form.setFieldsValue({
          user: x
        })
      }
    }

  ]


  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = e => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        valData.guestUsers = guestUsers
        let user = _.find(userList, (item) => {
          return item.email == valData.user
        })
        valData.userName = user && user.name ? user.name : null
        let data = await dispatch(addScheduleMeeting(valData))
        if (data && !data.error) {
          form.resetFields()
          setTime(moment())
        }
        events.hideDrawer()
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }
  return (
    <Drawer
      visible={visible}
      width={'70%'}
      placement="right"
      onClose={() => events.hideDrawer()}
      title={'Add Schedule Meeting'}>
      <div className="form-box">
        <div className="card unizportal">
          <Form style={{ marginTop: 8, textAlign: 'left' }}>
            <Row gutter={16} className={'rowWrap'}>
              {formFields.map((item, key) => {
                return (
                  !item.hidden ? <Col span={item.span} key={key}>
                    <GetEachFormFields
                      item={item}
                      getFieldDecorator={getFieldDecorator}
                      formItemLayout={formItemLayout}/>
                  </Col> : null
                )
              })}

              <Col span={12}>
                <InputBox title={'Enter Guest Email'} className={'rowFlex'}>
                  <Input className={'form-control'} type={'email'} value={email}
                         onChange={(e) => setEmail(e.target.value)}/>
                  <Button type="primary" icon="plus" className={'circleBtn'} onClick={() => chooseGuestEmail(email)}/>
                </InputBox>
              </Col>
              {guestUsers && guestUsers.length ? <Col span={24}>
                <div className={'guestEmailBox'}>
                  <ul>
                    {guestUsers.map((item, index) => {
                      return (
                        <li className={'emailTag'} key={index}>
                          {item} <Icon type={'close'} onClick={() => removeGuestEmail(index)} className={'emailIcon'}/>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </Col> : null}
              <Col span={24}>
                <Form.Item className={'mt20'}>
                  <Button type="success" onClick={handleSubmit} className={'btn'}>
                    SUBMIT
                  </Button>
                </Form.Item>
              </Col>
            </Row>

          </Form>
        </div>
      </div>
    </Drawer>
  )
}
const WrappedCountry = Form.create()(AddScheduleMeetingComponent)
export default WrappedCountry
