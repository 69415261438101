import React, {useState} from "react"
import {Button, Drawer, notification} from "antd";
import InputBox from "../../../components/_utils/InputBox";
import {useDispatch} from "react-redux";
import {addCallInWithdrawalFxn, addReceiptInWithdrawalFxn} from "../actions";

let verifiedOptions = ['Verified', "Not Verified"]

let initState = {
    currencyRate: "",
    receipt: ""
}
const UploadReceiptDrawer = (props) => {
    let dispatch = useDispatch()
    let [state, setState] = useState(initState);
    let {visible, onClose, withdrawalId} = props;

    let events = {
        updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        submitReceipt: async () => {
            let {receipt} = state;
            if (!receipt || (receipt && !receipt.name)) {
                notification.warning({message: "Please choose receipt."})
                return
            }
            let fd = new FormData()
            let obj = {withdrawalId}
            fd.append('obj', JSON.stringify(obj))
            if (receipt && receipt.name) {
                fd.append('receipt', receipt)
            }
            let {data, success} = await dispatch(addReceiptInWithdrawalFxn(fd))
            if (success) {
                events.updateState(initState);
                onClose()
            }
        }
    }


    return (
        <Drawer
            visible={visible}
            title={`Upload Receipt`}
            onClose={onClose}
            width={'50%'}
        >
            <div className="form-box commissionBox">
                <div className="card unizportal">

                <InputBox title={'Receipt'}>
                        <input
                            className={'form-control'}
                            type={'file'}
                            onChange={({target}) => {
                                if (target.files && target.files.length) {
                                    events.updateState({receipt: target.files[0]})
                                } else {
                                    events.updateState({receipt: ""})
                                }
                            }}/>
                    </InputBox>
                    <Button
                        onClick={events.submitReceipt}
                        type="primary"
                        className={`btn btn-default mt10`}>
                        Upload Receipt
                    </Button>
                </div>
            </div>
        </Drawer>
    )
}
export default UploadReceiptDrawer
