import {
  Button,
  Col, DatePicker,
  Icon, Modal, notification,
  Row,
  Select,
  Tooltip
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'
import {
  filterOption, fcmtUniversity, RoyalArtsCollege
} from '../../../components/_utils/appUtils'
import { getUrlPushWrapper } from '../../../routes'
import {
  AddAgentMeetingFxn,
  agentFcmtApplicationRecordFxn, ExportApplicationRecordFxn,
  relatedUniversityVisaApprovedAgentsFxn
} from '../actions/cornerStone'
import TextArea from 'antd/es/input/TextArea'
import MobileEmailInfo from '../../../components/commonComponents/mobileEmailInfo'
import { CityJsonWithPinCodes } from '../../../components/_utils/countryUtil'

const { Option } = Select
let initFilters = {
  marketingUserId: '',
  status: '',
  fromDate: '',
  toDate: '',
  expenseType: ''
}
const AgentApplicationRecord = props => {
  let tableRef = useRef()
  const dispatch = useDispatch()
  let [total, setTotal] = useState(0)
  const [uniId, setUniId] = useState('')
  const [aboveRecord, setAboveRecord] = useState('')
  const [universityAgentId, setUniversityAgentId] = useState('')
  const [city, setCity] = useState('')
  let [agentList, setAgentList] = useState([])
  let [applicationData, setApplicationData] = useState([])
  console.log(applicationData, 'appplication data')
  const uniList = [
    { name: 'FCMT College', id: fcmtUniversity },
    { name: 'CIRA College', id: RoyalArtsCollege }
  ]
  const recordList = [
    { name: 'Above 1', value: 1 },
    { name: 'Above 2', value: 2 },
    { name: 'Above 5', value: 5 },
    { name: 'Above 10', value: 10 }
  ]

  const initialState = {
    visible: false,
    travelPlanId: '',
    agentId: '',
    user: '',
    status: ''
  }
  const [detailsModal, setDetailsModal] = useState(initialState)
  const [cancelModal, setCancelModal] = useState(initialState)
  const [addmeetingModal, setAddMeetingModal] = useState(initialState)
  const [details, setDetails] = useState('')
  const [reason, setReason] = useState('')
  const [meetingDate, setMeetingDate] = useState('')
  const [meetingDetails, setMeetingDetails] = useState('')

  useEffect(() => {
    filterEvents.setFieldByParams()
  }, [])


  const apiRequest = params => {
    return new Promise(async resolve => {
      params.uniId = uniId
      params.aboveRecord = aboveRecord
      if (universityAgentId) {
        params.universityAgentId = universityAgentId
      }
      if (city) {
        params.city = city
      }
      let data = await agentFcmtApplicationRecordFxn(params)
      let agentsData = await relatedUniversityVisaApprovedAgentsFxn(params)
      setAgentList(agentsData.data)
      setApplicationData(data.data)
      resolve(data)
    })
  }

  const handleAddMeeting = async () => {
    let obj = {}
    if (meetingDate) {
      obj.date = meetingDate
    } else {
      notification.warning({ message: 'Please Choose Date' })
      return
    }
    if (meetingDetails) {
      obj.details = meetingDetails
    }
    if (addmeetingModal.agentId) {
      obj.agentId = addmeetingModal.agentId
    }
    let resp = await dispatch(AddAgentMeetingFxn(obj))
    if (resp && resp.success) {
      setMeetingDate('')
      setMeetingDetails('')
      setAddMeetingModal({ ...initialState, visible: false })
    }
  }

  let filterEvents = {
    getParams: (field) => {
      let searchParams = new URLSearchParams(window.location.search)
      return searchParams.get(field) ? searchParams.get(field) : ''
    },
    setFieldByParams: async () => {
      let { getParams } = filterEvents
      let obj = {}
      _.each(initFilters, (item, key) => {
        if (getParams(key)) {
          obj[key] = getParams(key)
        }
      })
      setTimeout(() => {
        if (tableRef && tableRef.current) {
          tableRef.current.reload()
        }
      }, 100)
    },
    getParamsForApplicationList: () => {
      return new Promise(resolve => {
        let obj = {}

        if (uniId) {
          obj['uniId'] = uniId
        }
        if (universityAgentId) {
          obj['universityAgentId'] = universityAgentId
        }
        if (aboveRecord) {
          obj['aboveRecord'] = aboveRecord
        }
        resolve(obj)
      })
    },
    searchFxn: async () => {
      await filterEvents.getParamsForApplicationList()
      let obj = {}
      if (uniId) {
        obj['uniId'] = uniId
      }
      if (universityAgentId) {
        obj['universityAgentId'] = universityAgentId
      }
      if (aboveRecord) {
        obj['aboveRecord'] = aboveRecord
      }
      if (city) {
        obj['city'] = city
      }

      dispatch(
        getUrlPushWrapper('applicationRecord', {
          ...obj
        })
      )
      setTimeout(() => {
        if (tableRef && tableRef.current) {
          tableRef.current.reload()
        }
      }, 200)

    },
    clearFxn: () => {
      setAboveRecord('')
      setUniId('')
      setUniversityAgentId('')
      setCity('')
      setTimeout(() => {
        tableRef.current.reload()
      }, 200)
    },
    export_fxn: () => {
      return new Promise(async resolve => {
        if (applicationData.length > 0) {
          let respData = await dispatch(ExportApplicationRecordFxn({ applicationData: applicationData }))
          if (respData && respData.success) {
            notification.success({ message: respData.message })
            if (respData.filePath) {
              window.open(respData.filePath, '_blank');
            }
          } else {
            notification.error({ message: respData.message })
          }
        } else {
          notification.warning({ message: 'No Data To Export' })
        }
      })
    }

  }

  const columns = [
    {
      title: 'Sr. no.',
      key: '_id',
      dataIndex: '_id',
      width: 50,
      render: (item, record, index) => {
        return <div style={{ width: 50 }}>{index + 1}</div>
      }
    },
    {
      title: 'Company',
      key: 'agent.companyName',
      dataIndex: 'agent.companyName',
      width: 150
    },
    {
      title: 'Contact Person',
      dataIndex: 'agent.name',
      key: 'agent.name',
      width: 150
    },
    {
      title: 'Mobile/Email',
      dataIndex: 'agent',
      key: 'agent',
      width: 150,
      render: (val) => {
        return (
          <div style={{ width: 150 }}>
            <MobileEmailInfo data={val} type={'email'} value={val.email} />
          </div>
        )
      }
    },
    {
      title: 'City',
      dataIndex: 'agent.cityName',
      key: 'agent.cityName',
      width: 150
    },
    {
      title: `${uniId == fcmtUniversity ? 'FCMT' : 'CIRA'} Visa Count`,
      dataIndex: 'visaApprovedCount',
      key: 'visaApprovedCount',
      searchTextName: 'visaApprovedCount',
      width: 180
    },
    {
      title: 'Corner Stone Applications',
      dataIndex: 'cornerStoneTotalApplicationCount',
      key: 'cornerStoneTotalApplicationCount',
      width: 140,
      render: (item) => {
        return item || 0
      }
    },
    {
      title: 'Corner Stone Visa',
      dataIndex: 'cornerStoneVisaApprovedCount',
      key: 'cornerStoneVisaApprovedCount',
      width: 120
    },
    {
      title: 'Action',
      dataIndex: 'status',
      key: 'status',
      width: 190,
      render: (item, record) => {
        return (
          <>
            <div className={'btn_group'} style={{ width: 190 }}>

              <Tooltip title='Add Meeting'>
                <button className='btn'
                        onClick={() => {
                          setAddMeetingModal({
                            visible: true,
                            agentId: record.agent._id,
                            status: 'approved'
                          })
                        }}
                >
                  <Icon type={'plus'} />
                </button>
              </Tooltip>

              {/* <Tooltip title='Approve'>
                     <button className='btn'
                             onClick={() => {
                               setDetailsModal({
                                 visible: true,
                                 // travelPlanId: collaborationModal.id,
                                 // user: record.user._id,
                                 status: 'approved'
                               })
                             }}
                     >
                       <Icon type={'check'} />
                     </button>
                   </Tooltip>

                   <Tooltip title='Cancel'>
                     <button className='btn'
                             onClick={() => {
                               setCancelModal({
                                 visible: true,
                                 // travelPlanId: collaborationModal.id,
                                 // user: record.user._id,
                                 status: 'cancel'
                               })
                             }}>
                       <Icon type={'cross'} />
                     </button>
                   </Tooltip>*/}
            </div>
          </>
        )
      }
    }
  ]

  const extra = (
    <div className={'mt10'}>
      <Row gutter={12} className={'filterBox'}>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='University'
              onChange={item => {
                setUniId(item)
                setAboveRecord('')
                setUniversityAgentId('')
              }}
              filterOption={filterOption}
              className={'antSelect'}
              placeholder={'University'}
              allowClear={true}
              value={uniId || undefined}>
              {uniList.map((item, key) => {
                return (
                  <Option value={item.id} key={key}>
                    {item.name}
                  </Option>
                )
              })}
            </Select>
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='Above Records'
              onChange={item => {
                setAboveRecord(item)
                setUniversityAgentId('')
              }}
              filterOption={filterOption}
              className={'antSelect'}
              placeholder={'Above Records'}
              allowClear={true}
              value={aboveRecord || undefined}>
              {recordList.map((item, key) => {
                return (
                  <Option value={item.value} key={key}>
                    {item.name}
                  </Option>
                )
              })}
            </Select>
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='University Agent'
              onChange={item => {
                setUniversityAgentId(item)
              }}
              filterOption={filterOption}
              className={'antSelect'}
              placeholder={'University Agent'}
              allowClear={true}
              showSearch={true}
              value={universityAgentId || undefined}>
              {agentList && agentList.length ? agentList.map((item, key) => {
                let label = `${item.companyName} (${item.name})`
                return (
                  <Option value={item.agentId} key={key}>
                    {label}
                  </Option>
                )
              }) : null}
            </Select>
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='City'
              onChange={item => {
                setCity(item)
              }}
              filterOption={filterOption}
              className={'antSelect'}
              placeholder={'City'}
              allowClear={true}
              showSearch={true}
              value={city || undefined}>
              {CityJsonWithPinCodes.map((item, key) => {
                let label = `${item.name}`
                return (
                  <Option value={item.name} key={key}>
                    {label}
                  </Option>
                )
              })}
            </Select>
          </div>
        </Col>

        <Col md={24} sm={24} xs={24} lg={24} className={'mt5'}>
          <Button
            onClick={() => filterEvents.searchFxn()}
            className={'roundBtn'}
          >
            Search
          </Button>
          <Button
            onClick={() => filterEvents.clearFxn()}
            className={'roundBtn'}
          >
            Clear
          </Button>
          {/*<Button
            onClick={() => filterEvents.export_fxn()}
            className={'roundBtn'}
          >
            Export
          </Button>*/}
        </Col>
      </Row>
    </div>
  )

  return (
    <>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>FCMT/CIRA Agent Application</h5>
              <div className='search-box-table' />
            </div>

            <div className='card-body table-responsive mt10'>
              {extra}
              <TableComp
                columns={columns}
                apiRequest={apiRequest}
                ref={tableRef}
                pagination={{
                  position: 'top',
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '20', '50', '100'],
                  defaultPageSize: 20
                }}
              />
            </div>
            {
              detailsModal.visible ? (
                <Modal
                  title='Enter Detail'
                  visible={detailsModal.visible}
                  onOk={() => {
                    // updateStudent(detailsModal.id, 'done')
                  }}
                  onCancel={() => {
                    setDetailsModal({ ...detailsModal, visible: false })
                  }}
                >
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    value={details}
                    onChange={(e) => setDetails(e.target.value)}
                    placeholder='Enter Details..'
                  />
                </Modal>
              ) : null
            }
            {
              cancelModal.visible ? (
                <Modal
                  title='Enter Reason'
                  visible={cancelModal.visible}
                  onOk={() => {
                    // updateStudent(cancelModal.id, 'cancel')
                  }}
                  onCancel={() => {
                    setCancelModal({ ...cancelModal, visible: false })
                  }}
                >
                  <TextArea
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder='Enter reason for Cancel'
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Modal>
              ) : null
            }
            {
              addmeetingModal.visible ? (
                <Modal
                  title='Add Meeting'
                  visible={addmeetingModal.visible}
                  onOk={() => {
                    handleAddMeeting()
                  }}
                  onCancel={() => {
                    setAddMeetingModal({ ...addmeetingModal, visible: false })
                    setMeetingDate('')
                    setMeetingDetails('')
                  }}
                >
                  <DatePicker
                    value={meetingDate}
                    style={{ width: '300px', marginBottom: '10px' }}
                    onChange={(e) => setMeetingDate(e)}
                  />
                  <TextArea
                    value={meetingDetails}
                    onChange={(e) => setMeetingDetails(e.target.value)}
                    placeholder='Enter meeting details'
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Modal>
              ) : null
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default AgentApplicationRecord
