import React, { Component } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import {
  Button, Row, Col, Select, DatePicker, Popconfirm
} from 'antd'
import { connect } from 'react-redux'
import { approveAgentFxn, regAgentFxn } from '../actions/user'
import {
  DefaultTablePagination,
  filterOption, InputBox, longDisplayDate
} from '../../../components/_utils/appUtils'
import { listAllCountries } from '../../countries/actions/countries'
import { ColumnWidth } from '../../WebComponent/columnWidth'
import { LoadAllCountry, LoadCity, LoadState } from '../../../components/_utils/countryUtil'
import _ from 'lodash'
import { getUrlPushWrapper } from '../../../routes'
import { displayDate } from '../../../components/_utils/appUtils'
import moment from 'moment'

const { Option } = Select
const { RangePicker } = DatePicker
const defaultValue = [null, null]
const dateFormat = 'YYYY-MM-DD'

class RegisteredAgentList extends Component {

  events = {
    chooseCountry: (name) => {
      let countryName = _.find(this.state.allCountries, (item) => {
        return item.name == name
      })
      if (countryName) {
        this.setState({
          countryName: countryName.name ? countryName.name : '',
          stateName: undefined,
          cityName: undefined,
          pincode: undefined,
          allStates: countryName.id ? LoadState(countryName.id) : []
        })
      }
    },
    chooseState: (name) => {
      let stateName = _.find(this.state.allStates, (item) => {
        return item.name == name
      })
      if (stateName) {
        this.setState({
          stateName: stateName.name ? stateName.name : '',
          cityName: undefined,
          pincode: undefined,
          allCities: stateName.id ? LoadCity(stateName.id) : []
        })
      }
    },
    chooseCity: (name) => {
      this.setState({
        cityName: name ? name : ''
      })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      viewInfoDrawer: true,
      selectedRow: {},
      allAgents: [],
      allBranchManager: [],
      allCountry: [],
      selectedUser: {},
      visibleUpdatePassword: false,
      visibleUpdateUser: false,
      visibleManagersList: false,
      allCountries: LoadAllCountry(),
      allStates: [],
      allCities: [],
      managerList: [],
      time: {
        key: 'date',
        from: undefined,
        to: undefined
      }
    }
    this.tableRef = React.createRef()
  }

  componentDidMount() {

    this.loadAllCountry()
  }

  apiRequest = (params) => {
    let { dispatch } = this.props
    return new Promise(async (resolve) => {
      params.sortField = 'createdAt'
      params.sortOrder = 'decent'
      let { time } = this.state

      let resp1 = await this.getParamsForApplicationList()

      if (resp1.countryName) {
        params.countryName = resp1.countryName
      }
      if (resp1.stateName) {
        params.stateName = resp1.stateName
      }
      if (resp1.cityName) {
        params.cityName = resp1.cityName
      }
      if (resp1.pincode) {
        params.pincode = resp1.pincode
      }
      if (resp1.name) {
        params.name = resp1.name
      }
      if (resp1.email) {
        params.email = resp1.email
      }
      if (resp1.companyName) {
        params.companyName = resp1.companyName
      }
      if (time.from) {
        params.createdAt = { $gte: time.from ? time.from._d : time.from, $lt: time.to ? time.to._d : time.to }
      }

      resolve(dispatch(regAgentFxn({
        ...params,
        regExFilters: ['name', 'address', 'mobile', 'email', 'companyName', 'cityName', 'stateName', 'countryName', 'pincode']
      })))
    })
  }

  async loadAllCountry() {
    let { dispatch } = this.props
    let { data } = await dispatch(listAllCountries())
    this.setState({ allCountry: data })
  }


  getParamsForApplicationList = () => {
    return new Promise((resolve) => {
      let searchParams = new URLSearchParams(window.location.search)
      let countryName = searchParams.get('countryName')
      let stateName = searchParams.get('stateName')
      let cityName = searchParams.get('cityName')
      let pincode = searchParams.get('pincode')
      let name = searchParams.get('name')
      let companyName = searchParams.get('companyName')
      let email = searchParams.get('email')

      let obj = {}
      if (countryName) {
        obj.countryName = countryName
      }
      if (stateName) {
        obj.stateName = stateName
      }
      if (cityName) {
        obj.cityName = cityName
      }
      if (pincode) {
        obj.pincode = pincode
      }
      if (name) {
        obj.name = name
      }
      if (companyName) {
        obj.companyName = companyName
      }
      if (email) {
        obj.email = email
      }
      resolve(obj)
    })
  }


  searchFxn = async () => {
    let { dispatch } = this.props
    let obj = {}
    if (this.state.countryName) {
      obj.countryName = this.state.countryName
    }
    if (this.state.stateName) {
      obj.stateName = this.state.stateName
    }
    if (this.state.cityName) {
      obj.cityName = this.state.cityName
    }
    if (this.state.pincode) {
      obj.pincode = this.state.pincode
    }
    if (this.state.email) {
      obj.email = this.state.email
    }
    if (this.state.companyName) {
      obj.companyName = this.state.companyName
    }
    if (this.state.name) {
      obj.name = this.state.name
    }
    dispatch(
      getUrlPushWrapper('registeredAgent', {
        ...obj
      }))
    setTimeout(() => {
      if (this.tableRef && this.tableRef.current) {
        this.tableRef.current.reload()
      }
    }, 200)
  }

  clearFxn = async () => {
    let { dispatch } = this.props

    let obj = {}
    this.setState({
      countryName: '',
      stateName: '',
      cityName: '',
      pincode: '',
      email: '',
      companyName: '',
      name: ''
    })
    dispatch(
      getUrlPushWrapper('registeredAgent', {
        ...obj
      }))
    setTimeout(() => {
      this.tableRef.current.reload()
    }, 200)
  }

  render() {
    let {
      countryName
    } = this.state
    const columns = [
      {
        title: '#',
        key: '_id',
        dataIndex: '_id',
        width: 50,
        render: (ite, record, index) => {
          return (
            <React.Fragment>
              {index + 1}
            </React.Fragment>
          )
        }
      },
      {
        title: 'Created At',
        key: 'createdAt',
        // searchDateName: 'createdAt',
        width: 100,
        render: (item, record) => {
          return (
            <ColumnWidth width={100}>
              {displayDate(item)}
            </ColumnWidth>
          )
        }
      },
      {
        title: 'Company Name',
        key: 'companyName',
        dataIndex: 'companyName',
        width: 150,
        render: (item) => {
          return (
            <ColumnWidth width={110}>
              {item}
            </ColumnWidth>
          )
        }
      },
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        width: 110,
        render: (item, record) => {
          return (
            <ColumnWidth width={110}>
              {item}
            </ColumnWidth>
          )
        }
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 120,
        render: (item, record) => {
          return (
            <ColumnWidth width={120}>
              {item}
            </ColumnWidth>
          )
        }
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobile',
        key: 'mobile',
        width: 110,
        render: (item) => {
          return (
            <ColumnWidth width={80}>
              {item}
            </ColumnWidth>
          )
        }
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        width: 150,
        render: (item) => {
          return (
            <ColumnWidth width={150}>{item}</ColumnWidth>
          )
        }
      },
      {
        title: 'State',
        dataIndex: 'stateName',
        key: 'stateName',
        width: 100,
        render: (item) => {
          return (
            <ColumnWidth width={100}>{item}</ColumnWidth>
          )
        }
      },
      {
        title: 'City',
        dataIndex: 'cityName',
        key: 'cityName',
        width: 100,
        render: (item) => {
          return (
            <ColumnWidth width={100}>{item}</ColumnWidth>
          )
        }

      },
      {
        title: 'Country',
        key: 'countryName',
        width: 100,
        render: (item) => {
          return (
            <ColumnWidth width={100}>{item}</ColumnWidth>
          )
        }
      },
      {
        title: 'Refer By',
        key: 'referBy',
        width: 70,
        render: (item) => {
          return (
              <div>
                {item}
              </div>
          )
        }
      },
      {
        title: 'Approved By',
        key: 'approvedBy',
        width: 120,
        render: (item, record) => {
          let {approvedTime} = record
          return (
              <div>
                {item && item.name ? item.name : ''}<br/>
                {approvedTime ?
                    <a className={'btn btn-default roundNew xss mt5'}>{longDisplayDate(approvedTime)}</a> : ''}
              </div>
          )
        }
      },
      {
        title: 'Status',
        key: 'deleted',
        width: 100,
          render: (item, record) => {
              return (
                  <>
                      {!item ? <label className='label label-info sm'>Pending</label> : null}
                      {item ? <label className='label label-success-green sm'>Approved</label> : null}
                  </>
              )
          }
      },
        {
            title: 'Certificate',
            dataIndex: 'certificate',
            width: 120,
            key: 'certificate',
            render: (item) => {
                return (
                    <div>
                        {item && item.url ? (
                            <a
                                className={'btn btn-link roundNew xs'}
                                href={item.url}
                                target={'_blank'}>
                                View Certificate
                            </a>
                        ) : null}
                    </div>
                )
            }
        },
        {
            title: 'Action',
            key: '_id',
            dataIndex: '_id',
            width: 120,
            render: (val, record) => {
                let {deleted, referBy} = record;
                let {currentUser} = this.props;
                return (
                    <React.Fragment>
                        {!deleted && currentUser.userType == "itTeam" ?
                            <Popconfirm
                                title={'Are you sure, you want to Approve Agent?'}
                                onConfirm={() => {
                                    approveAgentSubmit(record)
                                }}
                                okText='Yes' cancelText='No'>
                                <a className={'btn btn-default roundNew xs'}>
                                    Approve Now
                                </a>
                            </Popconfirm> : null}

                        {!referBy && !deleted && currentUser.userType == "userManager" ?
                            <Popconfirm
                                title={'Are you sure, you want to Approve Agent?'}
                                onConfirm={() => {
                                    approveAgentSubmit(record)
                                }}
                                okText='Yes' cancelText='No'>
                                <a className={'btn btn-default roundNew xs'}>
                                    Approve Now
                                </a>
                            </Popconfirm> : null}
                    </React.Fragment>
                )
            }
        }
    ]

    const approveAgentSubmit = async (agent) => {
      let { dispatch } = this.props
      let resp = await dispatch(approveAgentFxn(agent))
      if (resp && resp.success) {
        if (this.tableRef && this.tableRef.current) {
          this.tableRef.current.reload()
        }
      }
    }

    const filterBlock = (
      <div className={'filter_box'}>
        <Row gutter={16}>
          <Col md={4} sm={4} xs={8} lg={4}>
            <input type='search'
                   placeholder='Name' value={this.state.name} onChange={(e) => {
              this.setState({ name: e.target.value })
            }} />
          </Col>
          <Col md={4} sm={4} xs={8} lg={4}>
            <input type='search'
                   placeholder='Email' value={this.state.email} onChange={(e) => {
              this.setState({ email: e.target.value })
            }} />
          </Col>
          <Col md={4} sm={4} xs={8} lg={4}>
            <input type='search'
                   placeholder='Company Name' value={this.state.companyName} onChange={(e) => {
              this.setState({ companyName: e.target.value })
            }} />
          </Col>
          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='sort-box-table custom-sort-box-new'>
              <RangePicker
                className={'round-date-picker'}
                format={dateFormat}
                defaultValue={defaultValue}
                onChange={(val) => {
                  this.setState({
                    time: {
                      key: 'date',
                      from: val[0],
                      to: val[1]
                    }
                  })
                }}
                onOk={this.onOk}
              />
            </div>
          </Col>
          <Col md={4} sm={4} xs={8} lg={4}>

            <div className='sort-box-table custom-sort-box-new'>
              <Select name='Search By Country'
                      onChange={(item) => this.events.chooseCountry(item)}
                      filterOption={filterOption}
                      placeholder='Country'
                      allowClear={true}
                      showSearch={true}
                      value={this.state.countryName || undefined}>
                {this.state.allCountries && this.state.allCountries.length ? this.state.allCountries.map((item, key) => {
                  return (
                    <Option value={item.name} key={key}>{item.name}</Option>
                  )
                }) : null}
              </Select>
            </div>
          </Col>
          <Col md={4} sm={4} xs={8} lg={4}>

            <div className='sort-box-table custom-sort-box-new'>
              <Select name='Search By State'
                      onChange={(item) => this.events.chooseState(item)}
                      filterOption={filterOption}
                      placeholder='State'
                      allowClear={true}
                      showSearch={true}
                      value={this.state.stateName || undefined}>
                {this.state.allStates && this.state.allStates.length ? this.state.allStates.map((item, key) => {
                  return (
                    <Option value={item.name} key={key}>{item.name}</Option>
                  )
                }) : null}
              </Select>
            </div>
          </Col>
          <Col md={4} sm={4} xs={8} lg={4}>
            {!countryName || countryName == 'India' ?
              <div className='sort-box-table custom-sort-box-new'>
                <Select name='Search By City'
                        onChange={(item) => this.events.chooseCity(item)}
                        filterOption={filterOption}
                        placeholder='City'
                        allowClear={true}
                        showSearch={true}
                        value={this.state.cityName || undefined}>
                  {this.state.allCities && this.state.allCities.length ? this.state.allCities.map((item, key) => {
                    return (
                      <Option value={item.name} key={key}>{item.name}</Option>
                    )
                  }) : null}
                </Select>
              </div>
              :
              <div className='search-box-table round ml0'>
                <input name='Search By City'
                       onChange={(e) => {
                         this.setState({ cityName: e.target.value })
                       }}
                       placeholder='City'
                       value={this.state.cityName} />
              </div>
            }


          </Col>


          <Col md={4} sm={4} xs={8} lg={4}>
            <input type='search'
                   placeholder='Pincode' value={this.state.pincode} onChange={(e) => {
              this.setState({ pincode: e.target.value })
            }} />
          </Col>
          <Col md={16} sm={16} xs={16} lg={16}>
            <Button onClick={() => this.searchFxn()} className={'roundBtn'}>Search</Button>
            <Button onClick={() => this.clearFxn()} className={'roundBtn'}>
              Clear
            </Button>
          </Col>
        </Row>

      </div>
    )

    return (
      <div>

        <div className='row  mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='table-head d-flex align-items-center'>
                <h5>All Registered Agents</h5>
                <div className='search-box-table'>
                </div>
              </div>
              <div className='card-body table-responsive'>
                {filterBlock}
                <TableComp columns={columns}
                           ref={this.tableRef}
                           pagination={DefaultTablePagination()}
                           apiRequest={this.apiRequest}
                           extraProps={{ scroll: { x: 2000 } }}
                />
              </div>
            </div>
          </div>
        </div>

      </div>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  activeUser: global.activeUser,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisteredAgentList)
