import React, {useEffect, useState, useRef} from "react";
import {Button, Form, Col, Row, DatePicker, notification, Tooltip, Popconfirm, Icon} from 'antd';
import moment from "moment";
import {useDispatch} from "react-redux";
import {updateForexStatusFxn, getForexStatusFxn, currentForexStatusFxn, deleteForexStatusFxn} from "../actions";
import {TableComp} from "sz-react-utils-lite";
import {displayDate, FileInput} from "../../../components/_utils/appUtils";
import GetEachFormFields from "../../../components/_utils/appFormUtils";

const ForexStatusOffDates = (props) => {
    const {form: {getFieldDecorator}} = props;
    const [selectedDate, setSelectedDate] = useState(undefined);
    const [total, setTotal] = useState(0);
    const dispatch = useDispatch();
    const tableRef = useRef();

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const apiRequest = (params) => {
        return new Promise(async (resolve, reject) => {
                const resp = await dispatch(getForexStatusFxn(params));
                setTotal(resp.total);
                resolve(resp);

        });
    };


    const currentForexStatus = async () => {
        try {
            const resp = await dispatch(currentForexStatusFxn());
            console.log('Forex current:', resp);
        } catch (error) {
            console.error('Error fetching forex status:', error);
        }
    };

    useEffect(() => {
        currentForexStatus();
    }, []);

    const deleteForexStatus = async (id) => {
        const resp = await dispatch(deleteForexStatusFxn({ id: id }));
        if (resp.success) {
            tableRef.current.reload();
        }
    };

    const columns = [
        {
            title: 'Sr No.',
            dataIndex: 'serialNo',
            width: 70,
            render: (val, record, index) => <div>{index + 1}</div>,
        },
        {
            title: 'Off Dates',
            dataIndex: 'offDate',
            render: (text) => <span>{displayDate(text, 'DD/MM/YYYY')}</span>,
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (text, record) => (
                <Tooltip title="Delete">
                    <Popconfirm
                        title="Are you sure you want to delete this record?"
                        onConfirm={() => deleteForexStatus(record._id)}
                    >
                        <a className="btn">
                            <Icon type={'delete'}/>
                        </a>
                    </Popconfirm>
                </Tooltip>
            ),
        },
    ];

    const handleSubmit = async () => {
        if (!selectedDate) {
            notification.warning({
                message: 'Please fill all required fields!',
            });
            return
        }
        const resp = await dispatch(updateForexStatusFxn({date: selectedDate}));
        if (resp.success) {
            notification.success({
                message: 'Forex Status Updated Successfully',
            });
            setSelectedDate(undefined)
            tableRef.current.reload();
        } else {
            notification.error({
                message: resp.message,
            });
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="form-box mt-4">
                        <div className="d-flex align-items-center mb-3 heading-form">
                            <h5>Add Forex Holidays</h5>
                        </div>

                        <div className="card unizportal">
                            <Form>
                                <Row gutter={24}>
                                    <Col span={8}>
                                        <Form.Item label="Select Date" required>
                                            {getFieldDecorator('date')(
                                                <div>
                                                    <DatePicker
                                                        format="YYYY-MM-DD"
                                                        onChange={handleDateChange}
                                                        className="form-control"
                                                        value={selectedDate}
                                                        disabledDate={current => current && (current < moment().startOf('day'))}
                                                    />
                                                </div>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Button onClick={handleSubmit} className="roundBtn btn-default mt40">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>

                        </div>
                    </div>
                </div>
            </div>


            <div className="card">
                <div className="table-head d-flex align-items-center">
                    <h5>Forex Closed Dates: (Total: {total})</h5>
                </div>
                <div className="card-body table-responsive">
                    <TableComp
                        apiRequest={apiRequest}
                        columns={columns}
                        ref={tableRef}
                        extraProps={{scroll: {x: 600}}}
                        pagination={{
                            position: 'top',
                            pageSizeOptions: ['10', '20', '25', '50'],
                            defaultPageSize: 10,
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default Form.create()(ForexStatusOffDates);
