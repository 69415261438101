import React, {useEffect, useState} from "react"
import {Col, Drawer, Form, notification, Row} from "antd";
import GetEachFormFields from "../../../components/_utils/appFormUtils";
import {useDispatch} from "react-redux";
import {updateIndianCity} from "../actions";
import { getCitiesFxn, getStatesFxn } from '../../dailyExpense/actions/dailyExpense'

const EditIndianCity = (props) => {
    let {visible, data, onClose, onSubmit} = props;
    const {form: {getFieldDecorator, setFieldsValue, getFieldValue}} = props;
    let [cityId, setCityId] = useState("")
    const dispatch = useDispatch()
    useEffect(() => {
        setFormData()
    }, [])
    const setFormData = () => {
        setTimeout(() => {
            setFieldsValue({
                name: data.name,
                stateName: data.stateName,
                area: data.area,
                pinCode: data.pinCode
            })
            setCityId(data._id)
        }, 500)
    }

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    const [indianStates, setIndianStates] = useState([])
    const [indianCities, setIndianCities] = useState([])

    const getIndianStates = async () => {
        let data = await dispatch(getStatesFxn({results: 1000,}))
        setIndianStates(data)
    }
    const getIndianCities = async (params={}) => {
        if (getFieldValue("stateName")){
            params.state = getFieldValue("stateName")
        }
        let data = await dispatch(getCitiesFxn({results: 1000,...params}))
        setIndianCities(data)
    }

    useEffect(() => {
        getIndianStates()
    }, [])

    useEffect(() => {
        if (getFieldValue("stateName")){
            getIndianCities()
        }
    }, [getFieldValue("stateName")])

    let inputField = [
        {
            key: 'stateName',
            label:'State',
            required: true,
            placeholder: 'State Name',
            type: 'select',
            showSearch: true,
            allowClear:true,
            options: indianStates,
            keyAccessor: x => x.name,
            valueAccessor: x => `${x.name}`,
            onChange: x => {
                props.form.setFieldsValue({
                    name:"",
                    stateName: x
                })
            },
            span: 6
        },
        {
            key: 'name',
            allowClear:true,
            label: 'City',
            required: true,
            placeholder: 'City Name',
            showSearch: true,
            type: 'select',
            options: indianCities,
            keyAccessor: x => x.name,
            valueAccessor: x => `${x.name}`,
            onChange: x => {
                props.form.setFieldsValue({
                    name: x
                })
            },
            span: 6
        },
        {
            key: 'area',
            type:"text",
            label: 'Area',
            span: 6,
            required: true
        },
        {
            key: 'pinCode',
            type:"text",
            label: 'City Pin Code',
            span: 6,
            required: true
        }
    ]
    const submitIndianCity = (e) => {
        let {form} = props;
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                valData.cityId = cityId;
                let data = await dispatch(updateIndianCity(valData))
                if (data && !data.error) {
                    onSubmit()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }
    return (
        <>
            <Drawer width={'35%'} visible={visible} title={`Edit City - ${data.name}`} onClose={onClose}>
                <div className='card unizportal'>
                    <Form className={'vertical-form'} autoComplete='off' onSubmit={submitIndianCity}>
                        <Row gutter={24} className={'rowWrap pt-3'}>
                            {inputField.map((item, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        <Col span={24} key={key}>
                                            <GetEachFormFields
                                                item={item}
                                                getFieldDecorator={getFieldDecorator}
                                                formItemLayout={formItemLayout}/>
                                        </Col>
                                    </React.Fragment>
                                )
                            })}
                            <Col span={24}>
                                <button className='btn mt10' type='submit'>Update
                                </button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Drawer>
        </>
    )
}
const WrappedEditIndianCity = Form.create()(EditIndianCity);
export default WrappedEditIndianCity
