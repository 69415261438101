import React, { PureComponent } from 'react'
import {
  Form,
  Input,
  Select,
  Button, Col, Row, notification, Icon, Card, Table, Tag
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { hideLoader, hidePageLoad } from '../../modules/actions'
import { connect } from 'react-redux'
import { listAllUniversities } from '../university/actions/university'
import { listAllCountries } from '../countries/actions/countries'
import {
  InputBox,
  departmentList,
  BooleanOptions,
  UniversityOptions,
  departmentObj,
  managerType,
  assignAgentTypeOptions,
  countryIds,
  PreferredLanguage,
  AustraliaState,
  newFormatDisplayDate
} from '../../components/_utils/appUtils'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import { addUser, listAllUsers } from '../users/actions/user'
import debounce from 'lodash/debounce'
import { CountryCodeJson, CountryCodeFlagJson, CountryCodeWithFlag } from '../../components/_utils/countryUtil'
import { addStudentInsurance } from './actions/actions'
import styles from '../../components/Qualification/style.less'

let users = [
  {
    name: 'Admin',
    value: 'admin'
  },
  {
    name: 'Branch Manager',
    value: 'branchManager'
  },
  {
    name: 'Branch User',
    value: 'branchUser'
  },
  {
    name: 'Agent',
    value: 'agent'
  }
  /*{
    name: 'Student',
    value: 'student'
  }*/
]

let productList = [
  {
    name: 'nib OSHC',
    value: 'nib OSHC'
  }
]

let declaration = [
  'I declare the details in this Application Form to be true and complete, and I agree to be bound by the rules and by-laws of nib as amended from time to time. I have read and understood nib’s rules for transferring from another health fund',
  'I declare that dependants on this policy are under the age of 18 years and are listed as a dependant on the student visa.',
  'I authorise my previous health fund, hospital, medical or other authorities to release to nib all information required to confirm my benefit entitlements.',
  'I acknowledge I have been given access to the nib Privacy Policy, and the opportunity to clarify any issues or concerns. I consent to nib collecting, using or disclosing my personal information for the purposes set out in the nib Privacy Policy. I acknowledge nib is not responsible for the security of my personal information contained in this Application Form until it is received by nib.',
  'I realise that by submitting this Application Form I am authorised by each person listed herein to consent to nib making disclosures about their health information to other people listed in the application.',
  'I acknowledge that upon requesting cancellation or refund of my OSHC Policy with nib, that my details will be forwarded to the Department of Immigration and Citizenship (DIAC)'
]


const FormItem = Form.Item
const { Option } = Select
const { TextArea } = Input

@Form.create()
class AddUser extends PureComponent {


  agentEvents = {
    fetchAgent: async (name) => {
      let { dispatch } = this.props
      let obj = {
        results: 100,
        userType: 'agent',
        sortField: 'name',
        sortOrder: 'ascend',
        select: ['name', 'email', 'companyName'],
        name: name,
        block: false
      }
      let { data } = await dispatch(listAllUsers(obj))
      this.setState({
        agentList: data
      })
    },
    removeDependent: (index) => {
      let cloneAgent = _.clone(this.state.agents)
      cloneAgent.splice(index, 1)
      this.setState({
        agents: cloneAgent
      })
    },
    chooseAgent: (data) => {
      let { agents, agentList } = this.state
      let findUser = _.find(agents, (item) => {
        return item._id && item._id.toString() == data
      })
      if (findUser) {
        this.setState({
          agentName: '',
          agentList: []
        })
        return notification.warning({
          message: 'Agent already exists.'
        })
      } else {
        let findAgent = _.find(agentList, (item) => {
          return item._id && item._id.toString() == data
        })
        if (findAgent) {

          let cloneAgents = _.clone(agents)
          cloneAgents.push(findAgent)

          this.setState({
            agentName: '',
            agentList: [],
            agents: cloneAgents
          })
        }
      }
    }
  }
  events = {
    selectAllUni: () => {
      const { form: { setFieldsValue } } = this.props
      let { universityList } = this.state
      let universities = []
      _.each(universityList, (item) => {
        if (item && item._id) {
          universities.push(item._id)
        }
      })
      setFieldsValue({
        universities: universities
      })
    },
    deSelectAllUni: () => {
      const { form: { setFieldsValue } } = this.props
      let universities = []
      setFieldsValue({
        universities: universities
      })
    }
  }
  conditionalFxn = {
    countryFieldFxn: () => {
      const {
        form: { getFieldValue }
      } = this.props
      return (getFieldValue('userType') && ((getFieldValue('userType') == 'branchUser' && (getFieldValue('department') !== departmentObj.visaApproved && getFieldValue('department') !== departmentObj.warningDepartment) && getFieldValue('department')) || getFieldValue('userType') == 'branchManager'))
    },
    branchManagerFieldFxn: () => {
      const {
        form: { getFieldValue }
      } = this.props
      return ((getFieldValue('userType') && getFieldValue('department')) && (getFieldValue('userType') == 'branchUser' && (getFieldValue('department') !== departmentObj.visaApproved && getFieldValue('department') !== departmentObj.warningDepartment)))
    },
    universitySelectFieldFxn: () => {
      const {
        form: { getFieldValue }
      } = this.props
      return (getFieldValue('userType') &&
        ((getFieldValue('userType') == 'branchUser' && getFieldValue('department') && (getFieldValue('department').indexOf('Visa') < 0 && getFieldValue('department') !== departmentObj.warningDepartment)) || (getFieldValue('userType') == 'branchManager' && getFieldValue('branchManagerType') == 'universityWise'))
      )
    },
    universitySelectFieldFxnRequired: () => {
      const {
        form: { getFieldValue }
      } = this.props
      return (getFieldValue('userType') &&
        ((getFieldValue('userType') == 'branchUser' && getFieldValue('department') && (getFieldValue('department').indexOf('Visa') < 0 && getFieldValue('department') !== departmentObj.warningDepartment)) || (getFieldValue('userType') == 'branchManager' && getFieldValue('branchManagerType') == 'universityWise'))
      )
    }
  }

  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
    // this.tableRef = useRef();
    this.state = {
      countryOfOrigin: '',
      product: '',
      insuranceCoverFor: '',
      primaryStudent: '',
      title: '',
      name: '',
      middleName: '',
      lastName: '',
      dateOfBirth: '',
      details: '',
      preferredLanguage: '',
      studentName: '',
      mobile: '',
      currentlyInAustralia: '',
      addressLine1: '',
      addressLine2: '',
      suburb: '',
      postCode: '',
      state: '',
      visaStartDate: '',
      visaEndDate: '',
      passportNumber: '',
      visaType: '',
      dependentList: [],
      acceptCondition: false,
      uploadKey: moment(),
      uploadOfferKey: moment() + 5,
      passport: {},
      offerLetter: {}
    }
  }

  componentDidMount() {
    this.loadCountry()
    let { dispatch, form: { setFieldsValue } } = this.props
    dispatch({ type: 'USER_HIDELOADER' })
    dispatch(hideLoader())
    dispatch(hidePageLoad())

    setFieldsValue({
      countryOfOrigin: 'India',
      product: 'nib OSHC',
      insuranceCoverFor: 'Single',
      title: 'Mr',
      gender: 'Male',
      preferredLanguage: 'English',
      gst: 'No'
    })
  }

  async loadCountry() {
    let { dispatch } = this.props
    let countryFilter = {
      results: 10,
      sortField: 'countryName',
      sortOrder: 'ascend'
    }
    let { data } = await listAllCountries(countryFilter)(dispatch)
    this.setState({
      countryList: data
    })
  }

  async loadCountryUniversity(countryId) {
    let { dispatch } = this.props
    let filters = {
      sortField: 'universityName',
      sortOrder: 'ascend',
      results: 1000000, universityCountry: countryId
    }
    let { data } = await dispatch(listAllUniversities(filters))
    this.setState({
      universityList: data
    })
  }

  addDependent = () => {
    const {
      form: { getFieldValue, setFieldsValue }
    } = this.props
    let { dependentList } = this.state
    let obj = {
      title: getFieldValue('dependentTitle'),
      gender: getFieldValue('dependentGender'),
      name: getFieldValue('dependentName'),
      middleName: getFieldValue('dependentMiddleName'),
      lastName: getFieldValue('dependentLastName'),
      dateOfBirth: getFieldValue('dependentDateOfBirth')
    }

    let list = _.clone(dependentList)
    list.push(obj)
    this.setState({ dependentList: list })

    setFieldsValue({
      'dependentTitle': 'Mr',
      'dependentGender': 'Male',
      'dependentName': '',
      'dependentLastName': '',
      'dependentMiddleName': '',
      'dependentDateOfBirth': ''
    })

  }

  removeDependent = (index) => {
    let { dependentList } = this.state
    let cloneList = _.clone(dependentList)
    cloneList.splice(index, 1)
    this.setState({ dependentList: cloneList })
  }


  handleSubmit = e => {
    const { dispatch, form } = this.props
    let { getFieldValue } = form
    e.preventDefault()
    let fd = new FormData()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { address, dependentList, passport, offerLetter } = this.state

        valData.dependentList = dependentList

        fd.append('obj', JSON.stringify(valData))

        if (passport && passport.name) {
          fd.append('passport', passport)
        } else {
          notification({ message: 'Please Upload Passport' })
        }

        if (offerLetter && offerLetter.name) {
          fd.append('offerLetter', offerLetter)
        } else {
          notification({ message: 'Please Upload Offer Letter' })
        }

        let data = await dispatch(addStudentInsurance(fd))
        if (data && !data.error) {
          form.resetFields()
          // this.setState({
          //   address: '',
          //   uploadKey: moment(),
          //   logo: {},
          //   agents: [],
          //   countryCode: ''
          // })
        }
      }
    })
  }

  render() {

    const { submitting, dispatch } = this.props
    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue }
    } = this.props

    const { editorState, agentList, agentName, agents } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }

    // const prefixSelector = getFieldDecorator('prefix', {
    //   initialValue: '86'
    // })(
    //   <Select style={{ width: 80 }}>
    //     <Option value='86'>+86</Option>
    //     <Option value='87'>+87</Option>
    //   </Select>
    // )


    const chooseCountryCode = (val) => {
      this.setState({
        'countryCode': val
      })
    }

    let columns = [
      {
        title: 'First name',
        // width: 400,
        key: 'name',
        dataIndex: 'name'
      },
      {
        title: 'Middle name',
        // width: 400,
        key: 'middleName',
        dataIndex: 'middleName'
      },
      {
        title: 'Last name',
        // width: 400,
        key: 'lastName',
        dataIndex: 'lastName'
      },
      {
        title: 'Date Of Birth',
        // width: 400,
        key: 'dateOfBirth',
        render: (val) => {
          return (
            <span>
            {newFormatDisplayDate(val)}
          </span>
          )
        },
        dataIndex: 'dateOfBirth'
      },
      {
        title: 'Actions',
        // width: 400,
        render: (val, result, index) => {
          return (

            <a onClick={() => {
              this.removeDependent(index)
            }}>
              <Icon type={'close'} style={{ color: 'red' }} />
            </a>
          )
        },
        dataIndex: 'dateOfBirth'
      }
    ]


    let inputTypes = {

      fields: [
        {
          label: 'Country Of Origin',
          key: 'countryOfOrigin',
          required: true,
          type: 'select',
          options: ['India', 'Others'],
          valueAccessor: x => x,
          keyAccessor: x => x,
          onChange: async (v) => {
            setFieldsValue({
              countryOfOrigin: v
            })
          }
        },
        {
          label: 'Product',
          key: 'product',
          required: true,
          type: 'select',
          options: productList,
          valueAccessor: x => x.name,
          keyAccessor: x => x.value,
          onChange: async (v) => {
            setFieldsValue({
              product: v
            })
          }
        }, {
          key: '1',
          span: 8,
          customField: (
            <div></div>
          )
        },
        {
          label: 'Who is this cover for',
          key: 'insuranceCoverFor',
          required: true,
          type: 'select',
          options: ['Single', 'Couple', 'Family'],
          valueAccessor: x => x,
          keyAccessor: x => x,
          onChange: async (v) => {
            setFieldsValue({
              insuranceCoverFor: v
            })
          }
        },
        {
          key: '2',
          span: 16,
          customField: (
            <div></div>
          )
        },
        {
          label: (
            <small style={{ color: 'black', fontWeight: 'bold' }}>The policy holder is the primary student visa
              applicant</small>
          ),
          key: 'primaryStudent',
          placeholder: 'primaryStudent',
          required: true,
          type: 'select',
          options: ['Yes', 'No'],
          hidden: !(getFieldValue('insuranceCoverFor') == 'Single'),
          valueAccessor: x => x,
          keyAccessor: x => x,
          onChange: async (v) => {
            setFieldsValue({
              primaryStudent: v
            })
          }
        }
        // {
        //   label: 'Post Name',
        //   key: 'postName',
        //   hidden: !(getFieldValue('userType') && (getFieldValue('userType') == 'branchUser' || getFieldValue('userType') == 'branchManager' || getFieldValue('userType') == 'admin'))
        // },
        //
        // {
        //   key: 'alternateEmails',
        //   label: (
        //     <span>
        //       Alternate Emails <small style={{ color: 'black', fontWeight: 'bold' }}>( Note : Use comma for multiple emails )</small>
        //     </span>
        //   ),
        //   placeholder: 'Alternate Emails',
        //   span: 24,
        //   // hidden: !(getFieldValue('userType') && getFieldValue('userType') == 'agent')
        // },
      ],

      CustomerDetails: [
        {
          label: 'Title',
          key: 'title',
          required: true,
          type: 'select',
          options: ['Mr', 'Mrs', 'Ms', 'Miss'],
          valueAccessor: x => x,
          keyAccessor: x => x,
          onChange: async (v) => {
            setFieldsValue({
              title: v,
              gender: v == 'Mr' ? 'Male' : 'Female'
            })
          }
        },
        {
          label: 'Gender',
          key: 'gender',
          disabled: true
          /* customField: (
             <InputBox title={'Gender kk'} className={'rowFlex'}>
               <div className={'disbaledField'}>
                 {getFieldValue('title') == 'Mr' ? 'Male' : 'Female'}
               </div>
             </InputBox>
           )*/
        },
        {
          label: 'First Name',
          key: 'name',
          required: true
        },
        {
          label: 'Middle Name',
          key: 'middleName'
          // required: true
        },
        {
          label: 'Last Name',
          key: 'lastName',
          required: true
        },
        {
          label: 'Date Of Birth',
          key: 'dateOfBirth',
          type: 'date',
          required: true
        }
      ],

      PartnerDetails: [
        {
          label: 'Title',
          key: 'partnerTitle',
          required: true,
          type: 'select',
          options: ['Mr', 'Mrs', 'Ms', 'Miss'],
          valueAccessor: x => x,
          keyAccessor: x => x,
          onChange: async (v) => {
            setFieldsValue({
              partnerTitle: v,
              partnerGender: v == 'Mr' ? 'Male' : 'Female'
            })
          }
        },
        {
          label: 'Gender',
          key: 'partnerGender',
          disabled: true
          // value: getFieldValue('partnerTitle') == 'Mr' ? 'Male' : 'Female'
        },
        {
          label: 'First Name',
          key: 'partnerName',
          required: true
        },
        {
          label: 'Middle Name',
          key: 'partnerMiddleName'
        },
        {
          label: 'Last Name',
          key: 'partnerLastName',
          required: true
        },
        {
          label: 'Date Of Birth',
          key: 'partnerDateOfBirth',
          type: 'date',
          required: true
        }
      ],

      DependentsDetails: [
        {
          label: (
            <span>
                  <span style={{ color: 'red', fontSize: 16 }}>*</span>Title
                </span>
          ),
          // label: 'Title',
          key: 'dependentTitle',
          type: 'select',
          options: ['Mr', 'Mrs', 'Ms', 'Miss'],
          valueAccessor: x => x,
          keyAccessor: x => x,
          onChange: async (v) => {
            setFieldsValue({
              dependentTitle: v,
              dependentGender: v == 'Mr' ? 'Male' : 'Female'
            })
          }
        },
        {
          label: (
            <span>
                  <span style={{ color: 'red', fontSize: 16 }}>*</span>Gender
                </span>
          ),
          key: 'dependentGender',
          disabled: true
          // value: getFieldValue('dependentTitle') == 'Mr' ? 'Male' : 'Female'
        },
        {
          label: (
            <span>
                  <span style={{ color: 'red', fontSize: 16 }}>*</span>First Name
                </span>
          ),
          key: 'dependentName'

        },
        {
          label: (
            <span>
                  <span style={{ color: 'red', fontSize: 16 }}>*</span>Middle Name
                </span>
          ),
          key: 'dependentMiddleName'

        },
        {
          label: (
            <span>
                  <span style={{ color: 'red', fontSize: 16 }}>*</span>Last Name
                </span>
          ),
          key: 'dependentLastName'
        },
        {
          label: (
            <span>
                  <span style={{ color: 'red', fontSize: 16 }}>*</span>Date Of Birth
                </span>
          ),
          key: 'dependentDateOfBirth',
          type: 'date'
        }
      ],

      ContactDetailsInAustralia: [
        {
          label: 'Preferred Language',
          key: 'preferredLanguage',
          required: true,
          type: 'select',
          options: PreferredLanguage,
          valueAccessor: x => x,
          keyAccessor: x => x,
          onChange: async (v) => {
            setFieldsValue({
              preferredLanguage: v
            })
          }
        },
        {
          label: 'Student Email',
          key: 'studentEmail',
          required: true
        },
        {
          label: 'Mobile Number',
          key: 'mobile'
        },
        {
          label: 'Is the student currently in Australia?',
          key: 'currentlyInAustralia',
          required: true,
          type: 'select',
          options: ['Yes', 'No'],
          valueAccessor: x => x,
          keyAccessor: x => x,
          onChange: async (v) => {
            setFieldsValue({
              'currentlyInAustralia': v
            })
          }
        },

        {
          label: 'Address Line 1',
          key: 'addressLine1'
        },
        {
          label: 'Address Line 2',
          key: 'addressLine2'
        },
        {
          label: 'Suburb',
          key: 'suburb',
        },
        {
          label: 'PostCode',
          key: 'postCode',
        },
        {
          label: 'State',
          key: 'state',
          type: 'select',
          options: AustraliaState,
          valueAccessor: x => x,
          keyAccessor: x => x,
          onChange: async (v) => {
            setFieldsValue({
              'state': v
            })
          }
        }
      ],

      VisaDetails: [
        {
          label: 'Visa Start Date',
          key: 'visaStartDate',
          type: 'date',
          required: true
        },
        {
          label: 'Visa End Date',
          key: 'visaEndDate',
          type: 'date',
          required: true
        },
        {
          label: 'Passport Number',
          key: 'passportNumber',
          required: true
        },
        {
          label: 'Visa Type',
          key: 'visaType',
          required: true,
          type: 'select',
          span: 8,
          options: ['500 - Student Visa'],
          valueAccessor: x => x,
          keyAccessor: x => x,
          onChange: async (v) => {
            setFieldsValue({
              'visaType': v
            })
          }
        },
        {
          key: 'passport',
          customField: (
            <InputBox title={'* Upload Passport'} className={'logoCls'} required={true}>
              <Input type={'file'} className={'form-control'} required={true} key={this.state.uploadKey}
                     name={'passport'}
                     id={'passport'}
                     onChange={(e) => {
                       this.setState({
                         passport: e.target.files && e.target.files.length ? e.target.files[0] : null
                       })
                     }} />
            </InputBox>
          )
        },
        {
          key: 'offerLetter',
          customField: (
            <InputBox title={'* Upload Offer Letter'} className={'logoCls'} required={true}>
              <Input type={'file'} className={'form-control'} required={true} key={this.state.uploadOfferKey}
                     name={'offerLetter'}
                     id={'offerLetter'}
                     onChange={(e) => {
                       this.setState({
                         offerLetter: e.target.files && e.target.files.length ? e.target.files[0] : null
                       })
                     }} />
            </InputBox>
          )
        },
        // {
        //   key: '33',
        //   span: 17,
        //   customField: (
        //     <div></div>
        //   )
        // },

        {
          label: (
            <span>
                 GST<small style={{ color: 'black', fontWeight: 'bold' }}> ( Does the student have an Australian Business Number (ABN) and are they entitled to claim GST back on the policy premiums? )</small>
                </span>
          ),
          // label: 'GST',
          span: 16,
          key: 'gst',
          required: true,
          type: 'select',
          options: ['Yes', 'No'],
          valueAccessor: x => x,
          keyAccessor: x => x,
          onChange: async (v) => {
            setFieldsValue({
              'gst': v
            })
          }
        }
      ]
    }


    return (
      <div className='row'>
        <div className='col-lg-12'>
          <Form onSubmit={this.handleSubmit}>
            <div className='form-box mt-4'>

              <div className='d-flex align-items-center mb-3 heading-form'>
                <h5>Student Insurance</h5>
              </div>

              <div className='card unizportal'>

                <Row gutter={16} className={'rowWrap'}>
                  {inputTypes.fields.map((item, key) => {
                    return (
                      <React.Fragment key={key}>
                        {item.customField ? !item.hidden && <Col span={item.span ? item.span : 8}>
                          {item.customField}
                        </Col> : !item.hidden &&
                          <Col span={item.span ? item.span : 8} key={key}
                               style={item.extraBox ? { paddingBottom: 1 } : {}}>
                            <InputBox title={item.label1 ? item.label1 : ''} extra={item.extraBox}>
                              <GetEachFormFields
                                item={item}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={formItemLayout} />
                            </InputBox>
                          </Col>}
                      </React.Fragment>
                    )
                  })}

                  <div className='inner-form mt-4'>
                    <h4>Customer Details</h4>
                    <Row gutter={24} className={'rowWrap'}>
                      {inputTypes.CustomerDetails.map((item, key) => {
                        return (
                          <React.Fragment key={key}>
                            {item.customField ?
                              <Col span={item.span ? item.span : 8}>
                                {item.customField}
                              </Col> : !item.hidden &&
                              <Col span={item.span ? item.span : 8} key={key}>
                                <GetEachFormFields
                                  item={item}
                                  getFieldDecorator={getFieldDecorator}
                                  formItemLayout={formItemLayout} />
                              </Col>}
                          </React.Fragment>
                        )
                      })}
                    </Row>
                  </div>

                  {getFieldValue('insuranceCoverFor') == 'Couple' || getFieldValue('insuranceCoverFor') == 'Family' ?
                    <div className='inner-form mt-4'>
                      <h4>Partner Details </h4>

                      <Row gutter={24}>
                        {inputTypes.PartnerDetails.map((item, key) => {
                          return (
                            <Col span={8} key={key}>
                              <GetEachFormFields
                                item={item}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={formItemLayout} />
                            </Col>
                          )
                        })}
                      </Row>
                    </div> : null}
                  {getFieldValue('insuranceCoverFor') == 'Family' ? <div className='inner-form mt-4'>
                    <div className={'row'} style={{ justifyContent: 'space-between' }}>
                      <h4>Dependents</h4>
                    </div>
                    <Row gutter={24}>
                      {inputTypes.DependentsDetails.map((item, key) => {
                        return (
                          <Col span={8} key={key}>
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout} />
                          </Col>
                        )
                      })}
                    </Row>
                    <div className={'row'} style={{ justifyContent: 'space-between' }}>
                      <div><p></p></div>
                      <Button
                        onClick={() => {
                          this.addDependent()
                        }}
                        style={{ marginTop: 10 }}
                      ><Icon type={'plus'} style={{ fontSize: 18 }} />Add</Button>
                    </div>
                    {this.state.dependentList && this.state.dependentList.length ?
                      <Table
                        // rowSelection={{
                        //   type: 'checkbox',
                        //   ...rowSelection
                        // }}
                        style={{ marginTop: 20 }}
                        ref={this.tableRef}
                        columns={columns}
                        pagination={false}
                        dataSource={this.state.dependentList}
                      /> : null}

                  </div> : null}
                  <div className='inner-form mt-4'>
                    <h4>Contact Details In Australia</h4>
                    <Row gutter={24}>
                      {inputTypes.ContactDetailsInAustralia.map((item, key) => {
                        return (
                          <Col span={8} key={key}>
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout} />
                          </Col>
                        )
                      })}
                    </Row>
                  </div>

                  <div className='inner-form mt-4'>
                    <h4>Visa Details</h4>
                    <Row gutter={24}>
                      {inputTypes.VisaDetails.map((item, key) => {
                        return (
                          <React.Fragment key={key}>
                            {item.customField ?
                              <Col span={item.span ? item.span : 8}>
                                {item.customField}
                              </Col> : !item.hidden &&
                              <Col span={item.span ? item.span : 8} key={key}>
                                <GetEachFormFields
                                  item={item}
                                  getFieldDecorator={getFieldDecorator}
                                  formItemLayout={formItemLayout} />
                              </Col>}
                          </React.Fragment>
                        )
                      })}
                    </Row>
                  </div>
                </Row>


                <h4 style={{ marginTop: 20 }}>Declaration:</h4>
                <ul style={{ listStyleType: 'disc', marginVertical: 10 }}>
                  {
                    declaration.map((item) => {
                      return (
                        <li>{item}</li>
                      )
                    })
                  }
                </ul>

                <div className={'col-4'}>
                  <label className={styles.test}>I have read and accept the terms and conditions above</label>
                  <Select
                    onChange={(val) => {
                      if (val == 'Yes') {
                        this.setState({ acceptCondition: true })
                      } else {
                        this.setState({ acceptCondition: false })
                      }
                    }}
                  >
                    <Option value={'Yes'}>Yes</Option>
                    <Option value={'No'} selected={true}>No</Option>
                  </Select>
                </div>

                <Form.Item>
                  <Button type='primary' htmlType='submit'
                    // disabled={getFieldValue('disableSubmit')}
                          disabled={!this.state.acceptCondition}
                    // loading={this.props.loading}
                          className={'btn'}>
                    SAVE
                  </Button>
                </Form.Item>


              </div>
            </div>

          </Form>
        </div>
      </div>

    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUser)
