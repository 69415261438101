import React, {useState, useEffect} from 'react';

const ReeudoTimer = (props) => {
    let {
        callback = () => {
        }
    } = props;

    const [timeLeft, setTimeLeft] = useState(60); // Initialize time left
    const [isPaused, setIsPaused] = useState(false); // To handle the 5-second pause

    useEffect(() => {
        // If paused, do nothing
        if (isPaused) return;

        // Set up the countdown interval when not paused
        const intervalId = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    setIsPaused(true); // Pause the countdown when it hits 0
                    return 0;
                }
                return prevTime - 1; // Otherwise, decrement the time
            });
        }, 1000);

        return () => clearInterval(intervalId); // Clean up the interval on unmount
    }, [isPaused]); // Effect depends on `isPaused`

    useEffect(() => {
        // When paused (timer reaches 0), start a 5-second delay before resetting
        if (isPaused) {
            const timeoutId = setTimeout(() => {
                setTimeLeft(60); // Reset timer to 60 seconds after 5 seconds
                setIsPaused(false); // Resume the countdown
            }, 5000); // 5-second delay

            return () => clearTimeout(timeoutId); // Clean up the timeout if component unmounts
        }
    }, [isPaused]); // Effect depends on `isPaused`

    return (
        <div style={{width: 200}}>
            <h6 style={{fontSize: 18}}>Timer - <b className={'colorPrimaryDark'}>00:{timeLeft}</b>
            </h6>
        </div>
    );
};

export default ReeudoTimer;
