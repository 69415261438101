import React from 'react'
import { Modal, List, Card } from 'antd'
import _ from 'lodash'

const AgentTeamModal = (props) => {
  let { visible, onClose, onSubmit, teamList, teamState } = props
  let findCountryFxn = (teamDoc) => {
    let { countryArr: teamCountries } = teamDoc
    let findCon = _.find(teamCountries, (item) => {
      return teamState.countryArr.includes(item._id)
    })
    return {
      countryName: findCon && findCon.countryName ? findCon.countryName : '',
      name: teamDoc.name
    }
  }

  return (
    <Modal
      width={'50%'}
      title={'Before adding your team, please note the below points.'}
      visible={visible}
      onOk={onSubmit}
      okText={'Submit'}
      onCancel={onClose}>
      <ul>
        {teamList && teamList.length ? teamList.map((item, index) => {
          let doc = findCountryFxn(item)
          return (
            <li key={item._id} className={'mb5'}>
              <Card>
                <div className={'rowFlex'}>
                  <span>{index + 1}).&nbsp;</span>
                  {teamState.transferApplication ?
                    <div>
                      {doc.countryName} - All new and previous applications will be allocated to User: {teamState.name},
                      and all previous applications
                      will be removed from User: {doc.name}.
                    </div> :
                    <div>
                      {doc.countryName} - All new applications will be allocated to User: {teamState.name}.
                    </div>}
                </div>
              </Card>
            </li>
          )
        }) : null}
      </ul>
    </Modal>
  )
}
export default AgentTeamModal
