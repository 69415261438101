import React from 'react'
import {
  ukApplicationSteps,
  canadaApplicationSteps,
  australiaApplicationSteps,
  StatusNameObj,
  usaApplicationSteps, departmentObj
} from '../../../components/_utils/appUtils'
// import { FcmtUniversity } from './adminDashboard'
import { Row, Col } from 'antd'

const UkCountryCount = (props) => {
  let { data, callback } = props
  let { countryId = '', countArr = {} } = data

  return (
    <React.Fragment>
      <div className='card-pane-right listing-section'>
        <label><img src='/dist/img/united-kingdomFlag.png' alt='' /> United kingdom</label>

        <InnerBlock data={data} callback={callback} statusList={ukApplicationSteps} />
      </div>
    </React.Fragment>
  )
}

const CanadaCountryCount = (props) => {
  let { data, callback, userData, userCountryName } = props
  let { countryId = '', countArr = {} } = data

  return (
    <React.Fragment>
      <div className='card-pane-right listing-section'>
        <label><img src='/dist/img/canadaFlag.png' alt='' /> Canada</label>

        <InnerBlock data={data} callback={callback} statusList={canadaApplicationSteps} />

      </div>
    </React.Fragment>
  )
}

const AustraliaCountryCount = (props) => {
  let { data, callback } = props
  let { countryId = '', countArr = {} } = data

  return (
    <React.Fragment>
      <div className='card-pane-right listing-section'>
        <label><img src='/dist/img/australiaFlag.png' alt='' /> Australia</label>

        <InnerBlock data={data} callback={callback} statusList={australiaApplicationSteps} />

      </div>
    </React.Fragment>
  )
}

const USACountryCount = (props) => {
  let { data, callback } = props
  let { countryId = '', countArr = {} } = data

  return (
    <React.Fragment>
      <div className='card-pane-right listing-section'>
        <label><img src='/dist/img/australiaFlag.png' alt='' /> USA</label>
        <InnerBlock data={data} callback={callback} statusList={usaApplicationSteps} />
      </div>
    </React.Fragment>
  )
}

const InnerBlock = ({ statusList, callback, data }) => {
  let { countryId = '', countArr = {} } = data
  return (
    <ul>
      {statusList.map((item, key) => {
        return (
          item.name !== 'New Student' &&
          <li onClick={() => callback(countryId, item.name)} className={'pointerCls'} key={key}>
            <h5>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h5>
            <p>{item.name == 'Case Close' ? 'Case Closed' : StatusNameObj[item.name] ? StatusNameObj[item.name] : item.name} </p>
          </li>
        )
      })}
    </ul>
  )
}

const InnerBlockNew = ({ statusList, callback, data, userCountryName, userData, department }) => {
  let { countryId = '', countArr = {} } = data
  return (
    <div className='card-pane-right listing-section w-100' style={{ border: 'none' }}>
      <ul className={'count'}>
        {statusList.map((item, key) => {
          if (department == departmentObj.marketing) {
            return (
              item.marketing == true || item.onlyMarketing == true ?
                <li onClick={() => callback(countryId, item.name)} className={'pointerCls mb20'} key={key}>
                  <h4 className={'linkText'}>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h4>
                  <p style={{
                    color: 'black',
                    fontSize: 15
                  }}>{item.name == 'Case Close' ? 'Case Closed' : StatusNameObj[item.name] ? StatusNameObj[item.name] : item.label}</p>
                </li> : null
            )
          } else {
            if (item.onlyMarketing == true) {
              return
            }
            return (
              <li onClick={() => callback(countryId, item.name)} className={'pointerCls mb20'} key={key}>
                <h4 className={'linkText'}>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h4>
                <p style={{
                  color: 'black',
                  fontSize: 15
                }}>{item.name == 'Case Close' ? 'Case Closed' : StatusNameObj[item.name] ? StatusNameObj[item.name] : item.label}</p>
              </li>
            )
          }

        })}
      </ul>
    </div>
  )
}

const CanadaInnerBlock = ({ statusList, callback, data, userCountryName, userData, userType, department }) => {
  let { countryId = '', countArr = {} } = data
  return (
    <div className='card-pane-right listing-section w-100' style={{ border: 'none' }}>
      <ul className={'count'}>
        {statusList.map((item, key) => {
          if (department == departmentObj.marketing) {
            return (
              item.marketing === true ?
                <li onClick={() => callback(countryId, item.name)} className={'pointerCls mb20'} key={key}>
                  <h4 className={'linkText'}>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h4>
                  <p style={{
                    color: 'black',
                    fontSize: 15
                  }}>{item.name == 'Case Close' ? 'Case Closed' : StatusNameObj[item.name] ? StatusNameObj[item.name] : item.label}</p>
                </li> : null
            )
          } else {
            return (
              <li onClick={() => callback(countryId, item.name)} className={'pointerCls mb20'} key={key}>
                <h4 className={'linkText'}>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h4>
                <p style={{
                  color: 'black',
                  fontSize: 15
                }}>{item.name == 'Case Close' ? 'Case Closed' : StatusNameObj[item.name] ? StatusNameObj[item.name] : item.label}</p>
              </li>

            )
          }

        })}
        {/*<FcmtUniversity userData={userData} userCountryName={userCountryName}/>*/}
      </ul>
    </div>
  )
}


export { UkCountryCount, CanadaCountryCount, AustraliaCountryCount, InnerBlock, InnerBlockNew, CanadaInnerBlock }
