import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  adminRightUser,
  agentTeamTypes,
  ConditionOptions,
  DefaultTablePagination
} from '../../../components/_utils/appUtils'
import { ColumnWidth } from '../../WebComponent/columnWidth'
import {
  blockAgent,
  loadUserListFxn
} from '../../users/actions/user'
import { Popconfirm } from 'antd'
import ViewApplication from '../../applications/views/viewApplication'
import LogsComponents from '../../users/drawer/logsComponents'
import WebActivityComponents from '../../users/components/webActivityComponents'
import S from 'string'

const OnlineTeamList = props => {
  let dispatch = useDispatch()
  let tableRef = useRef()
  let [total, setTotal] = useState(0)
  let [applicationState, setApplicationState] = useState({
    visible: false,
    queryData: {}
  })

  let apiRequest = async params => {
    return new Promise(async resolve => {
      params.sortField = 'lastSeen'
      params.sortOrder = 'descend'
      params.userType = { $nin: [...agentTeamTypes, ...adminRightUser, 'student'] }
      params.isOnline = true
      params.results = 50
      params.count = 50
      let resp = await dispatch(
        loadUserListFxn({
          ...params,
          regExFilters: ['name', 'address', 'mobile', 'email', 'countryId', 'userType', 'department']
        })
      )
      setTotal(resp.total)
      resolve(resp)
    })
  }

  const events = {
    hideApplicationDrawer: () => {
      setApplicationState({
        visible: false,
        queryData: {}
      })
    },
    showApplicationDrawer: data => {
      setApplicationState({
        visible: true,
        queryData: {
          agentId: data._id
        }
      })
    },
    updateAgentBlockStatus: async id => {
      let resp = await dispatch(blockAgent({ agentId: id, sendMail: true }))
      if (resp && resp.success) {
        if (tableRef && tableRef.current) {
          tableRef.current.reload()
        }
      }
    }
  }


  const resp = useSelector(state => {
    let { reloadOnlineAgent } = state.global
    if (reloadOnlineAgent) {
      dispatch({ type: 'RELOAD_ONLINE_AGENT', value: false })
      setTimeout(() => {
        if (tableRef && tableRef.current) {
          tableRef.current.reload()
        }
      }, 500)
    }
  })

  const columns = [
    {
      title: '#',
      key: '_id',
      dataIndex: '_id',
      width: 50,
      render: (ite, record, index) => {
        return <React.Fragment>{index + 1}</React.Fragment>
      }
    },
    {
      title: 'Name',
      key: 'name',
      sorter: true,
      dataIndex: 'name',
      searchTextName: 'name',
      width: 100,
      render: (item, record) => {
        return (
          <ColumnWidth width={100}>
            <div className={'agentName'}>
              {item}
              <div className={'agentOnline'} />
            </div>
          </ColumnWidth>
        )
      }
    },
    {
      title: 'User Type',
      dataIndex: 'userType',
      key: 'userType',
      width: 150,
      render: (item, record) => {
        let { department } = record
        return (
          <ColumnWidth width={150}>
            {item ? <label className={'label label-success round label-sm'}>{S(item).humanize().s}</label> : null}
            {department ? <div className={'mt5'}>
              {department}
            </div> : null}
          </ColumnWidth>
        )
      }
    },
    {
      title: 'Country',
      dataIndex: 'countryId',
      key: 'countryId',
      width: 70,
      render: (item) => {
        return (
          <ColumnWidth width={70}>
            {item && item.countryName ? item.countryName : ''}
          </ColumnWidth>
        )
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      searchTextName: 'email',
      width: 120,
      render: item => {
        return <ColumnWidth width={120}>{item}</ColumnWidth>
      }
    },
    {
      title: 'Mobile No',
      dataIndex: 'mobile',
      key: 'mobile',
      searchTextName: 'mobile',
      width: 80,
      render: item => {
        return <ColumnWidth width={80}>{item}</ColumnWidth>
      }
    },

    {
      title: 'Block',
      dataIndex: 'block',
      width: 80,
      key: 'block',
      filters: ConditionOptions.map(x => ({ value: x.value, text: x.name })),
      render: (item, record) => {
        return (
          <React.Fragment>
            <ColumnWidth width={80}>
              <Popconfirm
                title={`Are your sure, you want to ${
                  item ? 'Un-Block' : 'Block'
                } this User?`}
                onConfirm={() => {
                  events.updateAgentBlockStatus(record._id)
                }}
                okText='Yes'
                cancelText='No'>
                {item ? (
                  <div className={'btn btn-danger roundNew xs'}>Yes</div>
                ) : (
                  <div className={'btn btn-success-green roundNew xs'}>No</div>
                )}
              </Popconfirm>
            </ColumnWidth>
          </React.Fragment>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 100,
      render: (val, record) => {
        return (
          <React.Fragment>

            <LogsComponents
              userData={record}
              {...props}
              currentUser={props.user}
            />
            <WebActivityComponents
              userData={record}
              {...props}
              currentUser={props.user}
            />
          </React.Fragment>
        )
      }
    }
  ]
  return (
    <>

      <div className='row  mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>All Online Team ( Total : {total})</h5>
              <div className='search-box-table' />
            </div>
            <div className='card-body table-responsive'>
              <TableComp
                columns={columns}
                ref={tableRef}
                pagination={DefaultTablePagination()}
                apiRequest={apiRequest}
                extraProps={{ scroll: { x: 1000 } }}
              />
            </div>
          </div>
        </div>
      </div>

      {applicationState.visible ? (
        <ViewApplication
          {...applicationState}
          pageType={'allApplication'}
          onClose={() => events.hideApplicationDrawer()}
        />
      ) : null}
    </>
  )
}
export default OnlineTeamList
