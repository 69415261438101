import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'
import {
  addPrivateMessageUrl,
  listPrivateMessagesUrl
} from '../api/privateMessage'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'

export const addPrivateMessage = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addPrivateMessageUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const listPrivateMessages = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(listPrivateMessagesUrl(), config)
  dispatch(hidePageLoad())

  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  dispatch({ type: 'ALL_PRIVATE_MESSAGE', payload: data.data })
  return data.data

}

