import {notification} from 'antd'
import {getToken, customAxios as axios} from '../../../request'
import {showPageLoad, hidePageLoad} from '../../../modules/actions'
import {indianCityUrl, singleIndianCityUrl} from '../api'

export const addIndianCity = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(indianCityUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
export const updateIndianCity = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.put(singleIndianCityUrl(valData.cityId), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const listAllIndianCity = async (filters) => {
    let config = {
        params: {...filters},
        ...await getToken()
    }
    let {data} = await axios.get(indianCityUrl(), config)
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}


export const deleteIndianCity = (id) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.delete(singleIndianCityUrl(id), getToken())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    dispatch(hidePageLoad())
    return data
}
