import React, { useEffect, useState } from 'react'
import { Button, Card, Drawer, Form, Input, notification } from 'antd'
import { InputBox } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { onshoreCanadaQueryFxn } from '../actions'
import { useDispatch } from 'react-redux'
import { sendEmail } from '../../applications/actions/chooseStudent'

let { TextArea } = Input
let initState = {
  emailSubject: '',
  replyTo: ''
}
const HelpComponent = (props) => {
  let { visible, onClose, currentUser, manager } = props
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } } = props
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  let dispatch = useDispatch()
  let [state, setState] = useState(initState)
  useEffect(() => {
    console.log(currentUser)
    events.updateState({ replyTo: currentUser && currentUser.email ? currentUser.email : '' })
  }, [currentUser])

  let events = {
    submitEmail: async (e) => {
      const { form } = props
      e.preventDefault()
      form.validateFieldsAndScroll(async (err, valData) => {
        if (!err) {

          if (!state.replyTo) {
            return notification.warn({
              message: 'Please enter reply to email.'
            })
          }
          if (!state.emailSubject) {
            return notification.warn({
              message: 'Please enter email subject'
            })
          }
          if (!valData.emailContent) {
            return notification.warn({
              message: 'Please enter email content'
            })
          }
          let resp = await dispatch(onshoreCanadaQueryFxn({
            replyTo: state.replyTo,
            message: valData.emailContent,
            emailSubject: state.emailSubject,
            managerName: manager.name,
            managerId: manager._id,
            managerEmail: manager.email
          }))
          if (resp && resp.success) {
            setState({
              ...state,
              emailContent: '',
              replyTo: '',
              emailSubject: ''
            })
            setFieldsValue({ emailContent: '' })
            onClose()
          }
        } else {
          notification.warning({
            message: 'Fill All Required Fields'
          })
        }
      })


    },

    updateState: (data) => {
      setState((prevState) => {
        return {
          ...prevState,
          ...data
        }
      })
    }
  }

  let emailFields = {
    key: 'emailContent',
    label: '',
    placeholder: 'Email',
    type: 'editor',
    onChange: (e) => {
      setState({
        ...state,
        emailContent: e.target.value
      })
      setFieldsValue({ emailContent: e.target.value })
    }
  }

  return (
    <Drawer width={'45%'}
            title={'Send Mail to Manager'}
            placement='right'
            visible={visible}
            onClose={onClose}>
      <Card bordered={false}>
        <Form className={'vertical-form'}>
          <InputBox title={'To'}>
            <Input disabled={true}
                   placeholder={'User'}
                   value={manager.email} name={'emailSubject'}
                   className={'form-control'}
            />
          </InputBox>
          <InputBox title={'Reply To'}>
            <Input
              placeholder={'User'}
              value={state.replyTo} name={'replyTo'}
              className={'form-control'}
              onChange={({ target }) => {
                events.updateState({ replyTo: target.value })
              }}
            />
          </InputBox>
          <InputBox title={'Subject'}>
            <Input placeholder={'Subject'} value={state.emailSubject} name={'emailSubject'}
                   className={'form-control'}
                   onChange={({ target }) => {
                     events.updateState({ emailSubject: target.value })
                   }} />
          </InputBox>
          <InputBox title={'Email'}>
            <GetEachFormFields
              item={emailFields}
              getFieldDecorator={getFieldDecorator}
              formItemLayout={formItemLayout} />
          </InputBox>

          <div className={'mt20 alignRight'}>
            <Button onClick={onClose} type={'cancel'}>Cancel</Button> {' '}
            <Button type={'success'} onClick={events.submitEmail}>Submit</Button>
          </div>
        </Form>
      </Card>
    </Drawer>
  )
}

const WrappedHelpComponent = Form.create()(HelpComponent)
export default WrappedHelpComponent
