import React, {Component} from 'react'
import {Button, Row, Col} from 'antd'

class InputBox extends Component {
    constructor(props) {
        super()
        this.state = {}
    }

    render() {
        let {title = '', className = '', customClass = "", extra, labelCls = '', customStyle = {}} = this.props
        return (
            <Row gutter={16} className={`inputBox ${customClass}`} style={customStyle}>
                <Col span={24} className={'labelRow'}>
                    {title ? <React.Fragment>
                        <label className={`labelNew ${labelCls}`}>{title} : </label>
                        {extra ? <div className={'extra'}>{extra}</div> : ''}
                    </React.Fragment> : ''}
                </Col>
                <Col span={24}>
                    <div className={`${className} innerBox rowFlex`}>
                        {this.props.children}
                    </div>
                </Col>
            </Row>
        )
    }
}

export default InputBox

