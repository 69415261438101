export default (
  state = {
    loader: false,
    privateMessage: {},
    allPrivateMessage: [],
    totalPrivateMessage: 0
  },
  action
) => {
  switch (action.type) {
    case 'ADD_PRIVATE_MESSAGE':
      return { ...state, privateMessage: action.payload }
    case 'ALL_PRIVATE_MESSAGE':
      return { ...state, allPrivateMessage: action.payload.data, totalPrivateMessage: action.payload.total }
    default:
      return state
  }
}
