import React from "react"
import {Card} from "antd";

const AgentInfoComponent = (props) => {
    let {agentId} = props;
    return (
        <div className={`universityParentBox`}>
            <Card className={'commissionUniInfo'}>
                {agentId && agentId._id ?
                    <>
                        {agentId && agentId.logo && agentId.logo.url ?
                            <div className={'uniLogo'}>
                                <img src={agentId.logo.url}/>
                            </div> : <img src={'../dist/img/chat_user.png'}/>}
                        <div className={'studentInfoBox'}>
                            <div className={'uniName'}>
                                {agentId.name}
                                <div className={'darkFont'}>
                                    {agentId && agentId.companyName ? agentId.companyName : null}
                                </div>
                            </div>
                        </div>
                    </>
                    : null}
            </Card>
        </div>
    )
}
export default AgentInfoComponent
