import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    Modal,
    Row,
    notification
} from 'antd'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import { InputBox } from '../../../components/_utils/appUtils'
import { getUserCanadaWallet } from '../../users/actions/user'
import { addExpenseWithdrawalCanadaFxn, sendExpenseWithdrawalCanadaOtp } from '../actions'

let initState = {
    bank: '',
    email:'',
    confirmEmail:'',
    balance: 0,
    amount: 0
}
const WithdrawalComponent = props => {
    const { onClose, visible, onSubmit, user } = props
    let [state, setState] = useState(initState)
    let [visibleOtpScreen, setVisibleOtpScreen] = useState(false)
    let [otp, setOtp] = useState('')
    let dispatch = useDispatch()

    useEffect(() => {
        loadCurrentBalance()
    }, [])


    const loadCurrentBalance = async () => {
        let data = await dispatch(getUserCanadaWallet(user._id));
        if (data && data.walletCanada) {
            updateState({balance: data.walletCanada})
        }
    }

    const updateState = data => {
        setState(prevState => {
            return {
                ...prevState,
                ...data
            }
        })
    }


    const handleChange = data => {
        setState(prevData => {
            return {
                ...prevData,
                ...data
            }
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        if (!state.email) {
            notification.warning({message: 'Please Enter Email.'})
            return
        }
        if (!state.confirmEmail) {
            notification.warning({message: 'Please Enter Confirm Email.'})
            return
        }
        if (state.confirmEmail != state.email) {
            notification.warning({message: 'Email Should Match Confirm Email.'})
            return
        }
        if (!state.amount) {
            notification.warning({message: 'Enter enter amount'})
            return
        }
        if (state.amount > state.balance) {
            notification.warning({message: 'Enter valid amount'})
            return
        }
        let obj = _.clone(state)
        // let findBank = _.find(bankList, item => {
        //     return item.accounts._id == obj.bank
        // })
        obj = {
            ...obj,
        }
        let resp = await dispatch(sendExpenseWithdrawalCanadaOtp(obj))
        if (resp && resp.success) {
            setVisibleOtpScreen(true)
        }
    }

    const handleSubmitAddWithdrawal = async e => {
        e.preventDefault()
        if (!state.email) {
            notification.warning({message: 'Please Enter Email.'})
            return
        }
        if (!state.confirmEmail) {
            notification.warning({message: 'Please Enter Confirm Email.'})
            return
        }
        if (state.confirmEmail != state.email) {
            notification.warning({message: 'Email Should Match Confirm Email.'})
            return
        }
        if (!state.amount) {
            notification.warning({message: 'Enter enter amount'})
            return
        }
        if (state.amount > state.balance) {
            notification.warning({message: 'Enter valid amount'})
            return
        }
        if (!otp) {
            notification.warning({message: 'Please enter otp'})
            return
        }
        let obj = _.clone(state)
        // let findBank = _.find(bankList, item => {
        //     return item.accounts._id == obj.bank
        // })
        obj = {
            ...obj,
            // ...findBank.accounts,
            otp
        }
        let resp = await dispatch(addExpenseWithdrawalCanadaFxn(obj))
        if (resp && resp.success) {
            handleChange({
                bank: '',
                amount: null
            })
            setVisibleOtpScreen(false)
            onSubmit()
            setOtp('')
            loadCurrentBalance()
        }
    }
    return (
        <>
            <Drawer
                visible={visible}
                title={'Withdrawal'}
                onClose={onClose}
                width={'45%'}>
                <div className="card unizportal">
                    <Form
                        className={'vertical-form'}
                        autoComplete="off"
                        onSubmit={handleSubmit}>
                        <Row gutter={24} className={'rowWrap'}>
                            <Col span={24}>
                                <Form.Item>
                                    <InputBox title={'Email'}>
                                        <Input
                                          value={state.email}
                                          className={'form-control'}
                                          name="email"
                                          onChange={({target}) => {
                                              updateState({email: target.value})
                                          }}
                                          placeholder="Email.."
                                        />
                                    </InputBox>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <InputBox title={'Confirm Email'}>
                                        <Input
                                          value={state.confirmEmail}
                                          className={'form-control'}
                                          name="confirmEmail"
                                          onChange={({target}) => {
                                              updateState({confirmEmail: target.value})
                                          }}
                                          placeholder="Confirm Email.."
                                        />
                                    </InputBox>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <InputBox title={'Balance'}>
                                        <Input
                                            disabled={true}
                                            value={state.balance}
                                            className={'form-control'}
                                            name="bankName"
                                            placeholder="Total Amount"
                                        />
                                    </InputBox>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <InputBox title={'Amount'}>
                                        <Input
                                            type={'number'}
                                            value={state.amount}
                                            className={'form-control'}
                                            name="bankName"
                                            onChange={({target}) => {
                                                updateState({amount: target.value})
                                            }}
                                            placeholder="Total Amount"
                                        />
                                    </InputBox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="btn">
                                SAVE
                            </Button>
                        </Form.Item>
                    </Form>
                </div>

                <Modal
                    visible={visibleOtpScreen}
                    closable={true}
                    onCancel={onClose}
                    title={'Enter OTP'}
                    footer={false}>
                    <div style={{textAlign: 'center', marginBottom: 10}}>
                        <h6>
                            {' '}
                            A text message with a 4-digit verification code has been sent to
                            your phone number.
                        </h6>
                        <InputBox title={'Enter OTP'}>
                            <input
                                className={'form-control mt10'}
                                type={'password'}
                                minLength={4}
                                maxLength={4}
                                value={otp}
                                onChange={({target}) => {
                                    setOtp(target.value)
                                }}
                            />
                        </InputBox>
                        <Button
                            className={'btn btn-success mt10'}
                            onClick={handleSubmitAddWithdrawal}>
                            Submit OTP
                        </Button>
                    </div>
                </Modal>
            </Drawer>
        </>
    )
}
const WrappedComponent = Form.create()(WithdrawalComponent)
export default WrappedComponent
