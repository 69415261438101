import {notification} from 'antd'
import {getToken, customAxios as axios} from '../../../request'
import {addCourseCategoryUrl, listCourseCategoryUrl, singleCourseCategoryUrl} from '../api'
import {showPageLoad, hidePageLoad} from '../../../modules/actions'

export const addCourseCategory = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addCourseCategoryUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}


export const listAllCourseCategory = (filters) => async (dispatch) => {
    let config = {
        params: {...filters},
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
    }
    let {data} = await axios.get(listCourseCategoryUrl(), config)
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data

}


export const getCourseCategory = id => async (dispatch) => {
    let config = {
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
    }
    let {data} = await axios.get(singleCourseCategoryUrl(id), config)
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}


export const updateCourseCategory = (valData, id) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.put(singleCourseCategoryUrl(id), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}

