import React, {useEffect, useState} from 'react'
import {Button, Drawer, notification, Form, Card, Popconfirm} from 'antd'
import {listAllUniversities, listQuickSearchUniversities} from '../../university/actions/university'
import {listAllCourse, loadCourseIntakesFxn} from '../../course/actions/courseAction'
import {useDispatch} from 'react-redux'
import {listAllCountries} from '../../countries/actions/countries'
import {checkBranchUserExists, reActivateApplicationByAgentFxn} from '../../student/actions/student'
import {FormUtils as GetAllFormFields} from 'sz-react-utils-lite'
import _ from 'lodash'
import {RowTable} from '../../../components/_utils/RowTable'
import {getCurrentStatusForActivation} from '../../../components/_utils/appUtils'
import {getBranchUserForReActivateApplication} from '../../users/actions/user'
import {reActivateApplication} from '../../student/actions/student'

const ReActivateApplication = (props) => {
    const {onClose, studentId, visible, application, studentData} = props
    const dispatch = useDispatch()
    let [status, setStatus] = useState('')

    useEffect(() => {
        setDefaultFields()
    }, [])

    const setDefaultFields = () => {
        setStatus(application.statusList.status)
    }


    const handleSubmitByAgent = async () => {
        let valData = {
            studentId,
            applicationId: application._id,
        }
        let resp = await dispatch(reActivateApplicationByAgentFxn(valData))
        if (resp && resp.success) {
            onClose()
        }
    }

    return (
        <Drawer visible={visible}
                title={`Re-open Case to ${status}`}
                width={600}
                closable={true}
                maskClosable={true}
                onClose={onClose}>
            <Card>
                <div className={'tableBox striped'}>
                    <RowTable title={'Student Name'} value={studentData.name}/>
                    <RowTable title={'Student Id'} value={studentData.studentId}/>
                    <RowTable title={'Status'} value={status}/>
                    {application.universityCountry && application.universityCountry.countryName ?
                        <RowTable title={'Country'} value={application.universityCountry.countryName}/> : null}
                    {application.courseUniversity && application.courseUniversity.universityName ?
                        <RowTable title={'University'} value={application.courseUniversity.universityName}/> : null}
                    <RowTable title={'Course'} value={application.courseName}/>

                    {application.intake && application.intake.year ?
                        <RowTable title={'Intake'}
                                  value={`${application.intake.month}, ${application.intake.year}`}/> : null}
                </div>

                <Popconfirm
                    okText='Yes' cancelText='No'
                    title={'Are you sure, you want to Re-Activate this applications?'}
                    onConfirm={() => {
                        handleSubmitByAgent()
                    }}>
                    <Button type="primary">
                        Re-Activate Application
                    </Button>
                </Popconfirm>
            </Card>
        </Drawer>
    )
}
const WrappedApplyReActivateApplication = Form.create()(ReActivateApplication)
export default WrappedApplyReActivateApplication
