import React from 'react'
import { Card, Row, Col, Avatar, Icon } from 'antd'
import { getDirectUniversityName } from '../../../components/_utils/appUtils'

let listData = [
  {
    city: 'Brampton',
    name: 'Akshay Sharma',
    email: 'akshay@unizportal.com',
    mobile: '+1 (905) 781-6338',
    address: 'Unit 7 143 Clarence St\n' +
      'Brampton ON L6W 1T2\n' +
      'Canada'
  },
  {
    city: 'Vancouver',
    name: 'Kamal Bawa',
    email: 'kamal@unizportal.com',
    mobile: '+16474566776',
    address: ' Office 433, 3rd Floor, 9850 King George Blvd, British Columbia Surrey V3T 0P9'
  }
]
const CanadaLocalOffice = (props) => {
  return (
    <React.Fragment>
      <div className='row  mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>Canada Local Offices</h5>
            </div>
            <div>
              <div className='card-pane-right' style={{ padding: 20 }}>
                <div className='nav flex-column nav-pills w-100'>
                  <Row gutter={100}>

                    {listData && listData.length ? listData.map((eachData, key) => {
                      return (
                        <Col span={8}>
                          <div className={'marketingUserBox'}>
                            <div className={'managerHead'}
                                 style={{
                                   backgroundColor: '#003399'
                                 }}>
                                                                              <span style={{
                                                                                marginLeft: 'auto',
                                                                                fontSize: 14
                                                                              }} className={'textUpp'}>
                                                                                {eachData.city} Office
                                                                              </span>
                            </div>
                            <div className={'managerBody'}>
                              <div className={'center'}
                                   style={{ paddingRight: 10 }}>
                                <Avatar
                                  size={60}
                                  style={{
                                    border: `3px solid #003399`,
                                    marginLeft: 15,
                                    marginRight: 15
                                  }}
                                  src={'../dist/img/man.png'}
                                />
                              </div>
                              <div className={'row'}>
                                <div
                                  style={{
                                    textAlign: 'left',
                                    padding: 5,
                                    fontSize: 12,
                                    color: '#585858'
                                  }}>
                                  <div>

                                    <div className={'mt5'}>
                                      <Icon type={'user'} />&nbsp;
                                      <b>{eachData.name}</b>
                                    </div>
                                    <div>
                                      <Icon
                                        type={'mail'} />&nbsp; {eachData.email}
                                    </div>
                                    <div>
                                      <Icon type={'phone'} />&nbsp;
                                      {eachData.mobile}
                                    </div>
                                    <div>
                                      <Icon type='environment' />&nbsp;
                                      {eachData.address}
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </Col>
                      )
                    }) : null}


                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </React.Fragment>
  )
}

export default CanadaLocalOffice
