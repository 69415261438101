import { apiUrl2 } from '../../../settings'

export const getAllCountriesUrl = () => {
  return apiUrl2 + '/api/getAllCountries'
}

export const getMarketingUserApplicationRecordUrl = () => {
  return apiUrl2 + '/api/liveReporting'
}
export const getPublicUniversityApplicationRecordUrl = () => {
  return apiUrl2 + '/api/liveReportingPublic'
}