import {apiUrl, apiUrl2} from '../../../settings'

export const addAgentUrl = () => {
    return apiUrl + '/agents'
}

export const countryListUrl = () => {
    return apiUrl + '/countryWithoutAuth'
}

export const addEducatorUrl = () => {
    return apiUrl + '/educator'
}

export const getUniversityByCountryUrl = () => {
    return apiUrl + '/universityWithoutAuth'
}

export const getCourseUrl = () => {
    return apiUrl + '/courseWithoutAuth'
}

export const AddStudentWithoutAuthUrl = () => {
    return apiUrl + '/studentWithoutAuthNew'
}

export const RecoverPasswordUrl = () => {
    return apiUrl + '/api/recoverPassword'
}

export const ResetPasswordUrl = () => {
    return apiUrl + '/api/resetPassword'
}
export const LogoutUrl = () => {
    return apiUrl + '/api/logout'
}
export const StudentWithoutAuthSendOtpUrl = () => {
    return apiUrl + '/studentWithoutAuthSendOtp'
}

export const getUserRightsUrl = () => {
    return apiUrl + '/api/getUserRights'
}
export const registerNewStudentUrl = () => {
    return apiUrl + '/registerStudent/add'
}
export const registerStudentIntakeUrl = () => {
    return apiUrl + '/registerStudent/intakes'
}

export const registerStudentUniversityUrl = () => {
    return apiUrl + '/registerStudent/university'
}
export const registerStudentUniversityCourseUrl = () => {
    return apiUrl + '/registerStudent/courses'
}
export const uploadDocumentByStudentUrl = () => {
    return apiUrl + '/api/uploadDocumentByStudent'
}

// new api for register student

export const intakesListUrl = () => {
    return apiUrl + '/registerStudent/intakes'
}
export const universityListUrl = () => {
    return apiUrl + '/registerStudent/university'
}
export const courseListUrl = () => {
    return apiUrl + '/registerStudent/courses'
}
export const paymentUrl = () => {
    return apiUrl + '/registerStudent/payment'
}
export const studentDetailsUrl = () => {
    return apiUrl + '/registerStudent/studentDetails'
}

export const checkRecoverCodeUrl = () => {
    return apiUrl + '/api/checkRecoverCode'
}
export const studentRegistrationForCanadaUrl = () => {
    return apiUrl + '/studentRegistrationForCanada'
}
export const onShoreStudentRegisteredUrl = () => {
    return apiUrl + '/onshoreStudent/registration'
}

export const getStudentByReferCodeUrl = () => {
  return apiUrl + '/api/getStudentByReferCode'
}

export const addInstituteUrl = () => {
  return apiUrl + '/api/institute'
}

export const getUserOtherRightsUrl = () => {
  return apiUrl2 + '/api/getUserOtherRights'
}

export const reactivationUrl = () => {
    return apiUrl2 + '/reactivationRequest'
}

export const unblockUserUrl = () => {
    return apiUrl2 + '/unblock-user'
}