import React, {useState, useEffect, useRef} from 'react'
import {
    Tooltip,
    Icon
} from 'antd'
import _ from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {listAllCourseCategory} from '../actions'
import {DefaultTablePagination} from '../../../components/_utils/appUtils'
import AddCourseCategory from './add'
import EditCourseCategory from './edit'
import {LazyLoadImage} from 'react-lazy-load-image-component'

const ListAllCourseCategoryComponent = () => {
    const tableRef = useRef()
    const dispatch = useDispatch()
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let resp = await dispatch(listAllCourseCategory({
                ...params,
                regExFilters: ['name']
            }))
            setTotalCategory(resp.total)
            resolve(resp)
        })
    }
    const [itemNo, setItemNo] = useState(1)
    const [id, setId] = useState(null)
    const [addCategoryVisible, setAddCategoryVisible] = useState(false)
    const [editCategoryVisible, setEditCategoryVisible] = useState(false)
    const [totalCategory, setTotalCategory] = useState(0)
    const confirmDelete = async (id) => {

    }


    const events = {
        hideDrawer: () => {
            setAddCategoryVisible(false)
            tableRef.current.reload()
        },
        hideEditDrawer: () => {
            setEditCategoryVisible(false)
            tableRef.current.reload()
        }
    }
    const columns = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            width: 100,
            key: 'sno',
            fixed: true,
            render: (item, record, index) => {
                return (
                    <a className={'linkPointer'} onClick={() => {
                        setId(record._id)
                        setEditCategoryVisible(true)
                    }}>
                        {(itemNo - 1) * 10 + index + 1}
                    </a>
                )
            }
        },
        {
            title: 'Category',
            dataIndex: 'name',
            key: 'name',
            render: (item, record) => {
                return (
                    <a className={'linkPointer'} onClick={() => {
                        setId(record._id)
                        setEditCategoryVisible(true)
                    }}>
                        {item}
                    </a>
                )
            }
        },
        {
            title: 'Logo',
            dataIndex: 'logo',
            width: 350,
            key: 'logo',
            render: (item) => {
                return item && item.url ? <LazyLoadImage src={item.url} style={{width: "auto", height: 60}}/> : null
            }
        },
        {
            key: 'actions',
            title: 'Actions',
            fixed: 'right',
            width: 80,
            render: (text, record) => {
                return <React.Fragment>
                    <Tooltip title="Edit">
                        <button
                            className={'btn'}
                            style={{marginRight: 6}}
                            onClick={() => {
                                setId(record._id)
                                setEditCategoryVisible(true)
                            }}
                        >
                            <Icon type={'edit'}/>
                        </button>
                    </Tooltip>
                    {/*  <Tooltip title="Delete">
            <Button
              shape="circle"
              style={{ marginRight: 6 }}
              size="small"
              onClick={() => {
                confirmDelete(record._id)
              }}
              icon="delete"
            />
          </Tooltip>*/}

                </React.Fragment>

            }
        }
    ]


    return (
        <div>
            <div className="row mt-4">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="table-head d-flex align-items-center">
                            <h5>All Category ({totalCategory || 0})</h5>
                            <div className="search-box-table">
                            </div>

                            <div className="sort-box-table mark-btn">
                                <button className="btn" onClick={() => setAddCategoryVisible(true)}>
                                    <img src={'../dist/icons/plus.png'} className={'plus'}/>
                                    Add Category
                                </button>
                            </div>
                        </div>
                        <div className="card-body table-responsive">
                            <TableComp columns={columns}
                                       apiRequest={apiRequest}
                                       ref={tableRef}
                                       pagination={DefaultTablePagination()}

                            />
                        </div>
                    </div>
                </div>
            </div>
            {addCategoryVisible ? <AddCourseCategory visible={addCategoryVisible} events={events}/> : ''}
            {editCategoryVisible ? <EditCourseCategory visible={editCategoryVisible} events={events} id={id}/> : ''}
        </div>

    )
}

export default ListAllCourseCategoryComponent
