import { apiUrl } from '../../../settings'

export const addPendencyUrl = () => {
  return apiUrl + '/pendency'
}

export const listPendencyUrl = () => {
  return apiUrl + '/pendency'
}

export const singlePendencyUrl = (id) => {
  return apiUrl + '/pendency/' + id
}
