import React, {Children, PureComponent, useEffect, useState} from 'react'
import {
    Form,
    Input, InputNumber,
    Select,
    Button, Col, Row, notification, Icon, Card, Table, Checkbox, Popconfirm
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import {hideLoader, hidePageLoad} from '../../../modules/actions'
import {connect} from 'react-redux'
import {listAllUniversities} from '../../university/actions/university'
import {listAllCountries} from '../../countries/actions/countries'
import {
    InputBox,
    departmentObj,
    marketingUniversity,
    oxfordUniversity,
    fcmtUniversity,
    cambieUniversity,
    RoyalArtsCollege,
    LesterCollege, currencyArrList, marketingForList
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {addUser, getUser, listAllUsers, updateUser} from '../actions/user'
import {CountryCodeWithFlag, LoadAllCountry, LoadState} from '../../../components/_utils/countryUtil'
import {
    canadaApplicationStatusSteps, fcmtStatusList, lesterStatusList, oxfordStatusList, germanyApplicationStatusSteps,
    ukApplicationStatusSteps, australiaApplicationStatusSteps, singaporeApplicationSteps, usaApplicationStatusSteps
} from '../../../components/_utils/ApplicationStatusSteps'
import MarketingComponent from '../components/marketingComponent'

const markStateInit = {
    countryId: undefined,
    universityList: []
}

const {TextArea} = Input

@Form.create()
class EditMarketingUser extends PureComponent {

    agentEvents = {
        fetchAgent: async (name) => {
            let {dispatch} = this.props
            let obj = {
                results: 100,
                userType: 'agent',
                sortField: 'name',
                sortOrder: 'ascend',
                select: ['name', 'email', 'companyName'],
                name: name,
                block: false
            }
            let {data} = await dispatch(listAllUsers(obj))
            this.setState({
                agentList: data
            })
        },
        removeAgent: (index) => {
            let cloneAgent = _.clone(this.state.agents)
            cloneAgent.splice(index, 1)
            this.setState({
                agents: cloneAgent
            })
        },
        chooseAgent: (data) => {
            let {agents, agentList} = this.state
            let findUser = _.find(agents, (item) => {
                return item._id && item._id.toString() == data
            })
            if (findUser) {
                this.setState({
                    agentName: '',
                    agentList: []
                })
                return notification.warning({
                    message: 'Agent already exists.'
                })
            } else {
                let findAgent = _.find(agentList, (item) => {
                    return item._id && item._id.toString() == data
                })
                if (findAgent) {
                    let cloneAgents = _.clone(agents)
                    cloneAgents.push(findAgent)
                    this.setState({
                        agentName: '',
                        agentList: [],
                        agents: cloneAgents
                    })
                }
            }
        }
    }

    events = {
        selectAllUni: () => {
            const {form: {setFieldsValue}} = this.props
            let {universityList} = this.state
            let universities = []
            _.each(universityList, (item) => {
                if (item && item._id) {
                    universities.push(item._id)
                }
            })
            setFieldsValue({
                universities: universities
            })
        },
        deSelectAllUni: () => {
            const {form: {setFieldsValue}} = this.props
            let universities = []
            setFieldsValue({
                universities: universities
            })
        },
        removeStateCountry: (index) => {
            let cloneArr = _.clone(this.state.assignStateCountry)
            cloneArr.splice(index, 1)
            this.setState({
                assignStateCountry: cloneArr
            })
        },
        countryName: (id) => {
            let country = _.find(this.state.countryList, (item) => {
                return item._id === id
            })
            return country && country.countryName ? country.countryName : null
        }
    }
    marketingEvents = {
        updateState: (data, index) => {
            let {marketingCountries} = this.state
            let cloneEdu = _.clone(marketingCountries)
            cloneEdu[index] = {...cloneEdu[index], ...data}
            this.setState({
                marketingCountries: cloneEdu
            })
        },
        updateMarketingCountries: (data, index) => {
            let {marketingCountries} = this.state
            let cloneEdu = _.clone(marketingCountries)
            cloneEdu[index] = {...cloneEdu[index], ...data}
            this.setState({
                marketingCountries: cloneEdu
            })
        },
        allData: () => {
            let {marketingCountries} = this.state
            this.setState({marketingCountries: [...marketingCountries, markStateInit]})
        },
        deleteItem: (index) => {
            let {marketingCountries} = this.state
            let cloneEdu = _.clone(marketingCountries)
            cloneEdu = _.reject(cloneEdu, (item, index1) => {
                return index1 == index
            })
            this.setState({
                marketingCountries: cloneEdu
            })
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            userId: '',
            allBranch: [],
            universityList: [],
            countryList: [],
            stateList: [],
            mainBranchArr: [],
            logo: {},
            uploadKey: moment(),
            agentList: [],
            agents: [],
            assignStateCountry: [],
            countryCode: '',
            marketingCountries: [],
            marketingUserList: [],
            allCountries: LoadAllCountry()

        }
    }

    componentDidMount() {
        this.getUserData()
        this.loadCountry()
        this.LoadStateFxn()
        this.loadManagers()
        this.loadMarketingUsers()
        // this.marketingEvents.updateState();
        this.loadCountryUniversity()
        let {dispatch} = this.props
        dispatch({type: 'USER_HIDELOADER'})
        dispatch(hideLoader())
        dispatch(hidePageLoad())
    }

    async loadCountry() {
        let {dispatch} = this.props
        let countryFilter = {
            results: 10,
            sortField: 'countryName',
            sortOrder: 'ascend'
        }
        let {data} = await listAllCountries(countryFilter)(dispatch)
        this.setState({
            countryList: data
        })
    }

    async LoadStateFxn() {
        let {dispatch} = this.props
        let data = await LoadState('101')
        this.setState({
            stateList: data
        })
    }

    async loadManagers() {
        let {dispatch} = this.props
        let {data} = await listAllUsers({
            userType: 'branchManager', customQuery: {
                countryId: {$exists: false}
            }
        })(dispatch)
        this.setState({
            mainBranchArr: data
        })
    }

    async loadCountryUniversity() {
        let {dispatch} = this.props
        let filters = {
            sortField: 'universityName',
            sortOrder: 'ascend',
            results: 1000000
            // universityCountry: '',
        }

        let {data} = await dispatch(listAllUniversities(filters))
        this.setState({
            universityList: data
        })
    }

    loadMarketingUsers = async () => {
        let {dispatch, currentUser} = this.props
        let obj = {
            userType: 'branchUser',
            department: departmentObj.marketing,
            results: 100,
            select: ['name', 'email', 'mobile'],
            customQuery: {
                _id: {$ne: currentUser._id}
            }
        }
        let {data} = await dispatch(listAllUsers(obj))
        // const filteredData = data.filter(user => user._id !== this.state.userId)
        this.setState({
            marketingUserList: data
        })
    }

    async getUserData() {
        let {dispatch, form: {setFieldsValue}} = this.props
        let {pathname} = window.location
        let params = pathname.split('/').pop()
        if (params && params.length) {
            let resp = await dispatch(getUser(params))
            if (resp) {
                let obj = {
                    userId: resp._id,
                    oldLogo: resp.logo,
                    address: resp.address,
                    agents: resp.agents,
                    email: resp.email,
                    assignStateCountry: resp.assignStateCountry,
                    createdByUserIdName: resp.createdByUser ? resp.createdByUser.name : '',
                    countryCode: resp.countryISOCode,
                    countryName: resp.countryName
                }
                if (resp.marketingCountries && resp.marketingCountries.length) {
                    let marketingCountries = []
                    _.each(resp.marketingCountries, (item) => {
                        marketingCountries.push({
                            countryId: item.countryId._id,
                            country: item.countryId && item.countryId.countryName ? item.countryId.countryName.toLowerCase() : '',
                            universityList: item.universityList
                        })
                    })
                    obj.marketingCountries = marketingCountries
                } else {
                    obj.marketingCountries = [markStateInit]
                }
                this.setState({
                    ...obj
                })
                setFieldsValue({
                    name: resp.name,
                    mobile: resp.mobile,
                    email: resp.email,
                    userType: resp.userType,
                    address: resp.address,
                    universities: resp.universities,
                    branchMangerId: resp.branchMangerId,
                    postName: resp.postName,
                    countryName: resp.countryName,
                    agentTarget: resp.agentTarget || undefined,
                    joiningDate: resp.joiningDate ? moment(resp.joiningDate) : '',
                    assignedMarketingUsers: resp.assignedMarketingUsers,
                    marketingFor: resp.marketingFor || "unizportal",
                    defaultCurrency: resp.defaultCurrency ? resp.defaultCurrency : undefined
                })
            }
        }
    }

    async AddStateCountry() {
        const {
            form: {getFieldValue, setFieldsValue}
        } = this.props
        let state = getFieldValue('state') ? getFieldValue('state') : undefined
        let countryId = getFieldValue('countryId') ? getFieldValue('countryId') : undefined

        if (state == undefined) {
            notification.error({
                message: 'Please choose State.'
            })
            return
        }
        if (countryId == undefined) {
            notification.error({
                message: 'Please choose Country.'
            })
            return
        }

        let obj = {
            stateName: state,
            countryId: countryId
        }
        let cloneD = _.clone(this.state.assignStateCountry)
        let findA = _.find(cloneD, (item) => {
            return item.stateName == obj.stateName && item.countryId == obj.countryId
        })
        if (findA) {
            notification.warning({message: 'Already exists'})
        } else {
            this.setState({assignStateCountry: [...cloneD, obj]})
            setFieldsValue({
                state: undefined,
                countryId: undefined
            })
        }
    }

    handleSubmit = e => {
        const {dispatch, form} = this.props
        let {getFieldValue} = form
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let {address, logo, assignStateCountry, agents, countryCode, marketingCountries} = this.state

                if (!countryCode) {
                    notification.error({
                        message: 'Please choose Country Code'
                    })
                    return
                }

                valData.countryISOCode = countryCode
                valData.address = address
                valData.department = departmentObj.marketing
                valData.userType = 'branchUser'
                valData.assignStateCountry = assignStateCountry
                valData.marketingCountries = _.filter(marketingCountries, (item) => {
                    return item.countryId
                })

                let fd = new FormData()
                fd.append('obj', JSON.stringify(valData))
                if (logo && logo.name) {
                    fd.append('logo', logo)
                }
                // let data = await dispatch(addUser(fd))
                let data = await dispatch(updateUser(fd, this.state.userId))
                if (data && !data.error) {
                    this.setState({
                        uploadKey: moment()
                    })
                }
            }
        })
    }


    render() {
        const {
            form: {getFieldDecorator, getFieldValue, setFieldsValue}
        } = this.props

        const {marketingCountries, marketingUserList} = this.state

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            }
        }

        const assignStateColumns = [
            {
                title: 'State',
                key: 'stateName',
                dataIndex: 'stateName'
            },
            {
                title: 'Country',
                key: 'countryId',
                dataIndex: 'countryId',
                render: (item) => {
                    return this.events.countryName(item)
                }
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: 'action',
                render: (item, record, index) => {
                    return (
                        <Icon type={'close'}
                              style={{color: 'red'}}
                              onClick={() => {
                                  this.events.removeStateCountry(index)
                              }}/>
                    )
                }
            }

        ]


        let inputTypes = {
            fields: [
                {
                    label: 'Contact Person Name',
                    key: 'name',
                    required: true
                },
                {
                    key: 'countryCode',
                    span: 8,
                    customField: (
                        <div style={{marginTop: 5}}>
                            <CountryCodeWithFlag countryCode={this.state.countryCode} chooseCode={(val) => {
                                this.setState({
                                    countryCode: val
                                })
                            }}/>
                        </div>
                    )
                },
                {
                    label: 'Mobile no',
                    key: 'mobile',
                    required: true,
                    type: 'number'
                },
                {
                    label: 'Email',
                    key: 'email',
                    required: true
                },

                {
                    label: 'Post Name',
                    key: 'postName'
                },
                {
                    label: 'Marketing For',
                    key: 'marketingFor',
                    required: true,
                    type: 'select',
                    valueAccessor: x => x.name,
                    keyAccessor: x => x.value,
                    options: marketingForList,
                    onChange: v => {
                        setFieldsValue({
                            marketingFor: v
                        })
                    }
                },
                {
                    label: 'Branch Manger',
                    key: 'branchMangerId',
                    required: true,
                    type: 'select',
                    valueAccessor: x => x.name,
                    keyAccessor: x => x._id,
                    options: this.state.mainBranchArr,
                    onChange: v => {
                        setFieldsValue({
                            branchMangerId: v
                        })
                    }
                },
                {
                    label: 'Joining Date',
                    key: 'joiningDate',
                    required: true,
                    type: 'date'
                },
                {
                    key: 'countryName',
                    label: 'Country Name',
                    required: true,
                    type: 'select',
                    showSearch: true,
                    options: this.state.allCountries,
                    keyAccessor: x => x.name,
                    valueAccessor: x => `${x.name}`,
                    onChange: x => {
                        setFieldsValue({
                            countryName: x
                        })
                    }
                },

                {
                    key: 'logo',
                    customField: (
                        <InputBox title={'Profile Image'} className={'logoCls'}>
                            <Input type={'file'} className={'form-control'} key={this.state.uploadKey} name={'logo'}
                                   id={'logo'}
                                   onChange={(e) => {
                                       this.setState({
                                           logo: e.target.files && e.target.files.length ? e.target.files[0] : null
                                       })
                                   }}/>
                        </InputBox>
                    )
                },
                {
                    key: 'universities',
                    label: '',
                    label1: 'Select University',
                    type: 'select',
                    extraBox1: (
                        <React.Fragment>
                            <a onClick={() => this.events.selectAllUni()}>Select All</a>{' '}
                            <a onClick={() => this.events.deSelectAllUni()}>De-select All</a>
                            {getFieldValue('universities') && getFieldValue('universities').length ?
                                <a className={'selectedUni'}>{getFieldValue('universities').length}</a>
                                : null}
                        </React.Fragment>
                    ),
                    showSearch: true,
                    options: this.state.universityList,
                    mode: 'multiple',
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.universityName}`,
                    onChange: x => {
                        setFieldsValue({
                            universities: x
                        })
                    }
                },

                {
                    label: 'Assign Marketing Users',
                    key: 'assignedMarketingUsers',
                    type: 'select',
                    span: 8,
                    showSearch: true,
                    mode: 'multiple',
                    options: marketingUserList,
                    valueAccessor: x => `${x.name}`,
                    keyAccessor: x => x._id,
                    onChange: async (v) => {
                        setFieldsValue({
                            assignedMarketingUsers: v
                        })
                    }
                },
                {
                    label: 'Default Currency',
                    key: 'defaultCurrency',
                    type: 'select',
                    span: 8,
                    showSearch: true,
                    options: currencyArrList,
                    required: true,
                    valueAccessor: x => `${x.name}`,
                    keyAccessor: x => x.name,
                    onChange: async (v) => {
                        setFieldsValue({
                            defaultCurrency: v
                        })
                    }
                },
                {
                    label: 'Agent Target',
                    key: 'agentTarget',
                    // required: true,
                    type: 'number',
                    placeholder: "Agent Target"
                },
                {
                    key: 'address',
                    span: 24,
                    customField: (
                        <InputBox title={'Address'}>
                            <TextArea rows={4} className={'form-control'} value={this.state.address} onChange={(e) => {
                                this.setState({address: e.target.value})
                            }}>
                            </TextArea>
                        </InputBox>
                    )
                },
                {
                    label: 'State',
                    // key: 'stateName',
                    key: 'state',
                    type: 'select',
                    // mode: 'multiple',
                    showSearch: true,
                    allowClear: true,
                    span: 10,
                    options: this.state.stateList,
                    valueAccessor: x => x.name,
                    keyAccessor: x => x.name,
                    onChange: async (v) => {
                        setFieldsValue({
                            state: v
                        })
                    }
                },
                {
                    label: 'Country',
                    key: 'countryId',
                    // key: 'country',
                    type: 'select',
                    // mode: 'multiple',
                    span: 10,
                    showSearch: true,
                    allowClear: true,
                    options: this.state.countryList,
                    valueAccessor: x => x.countryName,
                    keyAccessor: x => x._id,
                    onChange: async (v) => {
                        setFieldsValue({
                            countryId: v
                        })
                    }
                },
                {
                    key: 'add',
                    span: 4,
                    customField: (
                        <InputBox title={'Add'}>
                            <a className='btn mt10' type='primary'
                               onClick={() => {
                                   this.AddStateCountry()
                               }}
                               style={{backgroundColor: '#1556bc', textColor: 'white', color: 'white'}}>
                                ADD
                            </a>
                        </InputBox>
                    )
                }

            ]
        }


        return (
            <div className='row'>
                <div className='col-lg-12'>
                    <Form
                        onSubmit={this.handleSubmit}
                    >
                        <div className='form-box mt-4'>

                            <div className='d-flex align-items-center mb-3 heading-form'>
                                <h5>Edit User</h5>
                            </div>

                            <div className='card unizportal'>

                                <Row gutter={16} className={'rowWrap'}>
                                    {inputTypes.fields.map((item, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                {item.customField ? !item.hidden &&
                                                    <Col span={item.span ? item.span : 8}>
                                                        {item.customField}
                                                    </Col> : !item.hidden &&
                                                    <Col span={item.span ? item.span : 8} key={key}
                                                         style={item.extraBox1 ? {paddingBottom: 1} : {}}>
                                                        <InputBox title={item.label1 ? item.label1 : ''}
                                                                  extra={item.extraBox1}>
                                                            <GetEachFormFields
                                                                item={item}
                                                                getFieldDecorator={getFieldDecorator}
                                                                formItemLayout={formItemLayout}/>
                                                        </InputBox>
                                                    </Col>}
                                            </React.Fragment>
                                        )
                                    })}


                                </Row>


                                {this.state.assignStateCountry && this.state.assignStateCountry.length ?
                                    <Table dataSource={this.state.assignStateCountry}
                                           className={'table table-bordered'}
                                           bordered={true} size={'small'}
                                           pagination={false} columns={assignStateColumns}/>
                                    : null
                                }

                                <Card className={'mt20'}>
                                    {marketingCountries && marketingCountries.length ?
                                        marketingCountries.map((data, index) => {
                                            return (
                                                <>
                                                    <MarketingComponent
                                                        data={data}
                                                        key={index}
                                                        marketingIndex={index}
                                                        countryList={this.state.countryList}
                                                        marketingEvents={this.marketingEvents}
                                                    />
                                                </>
                                            )
                                        }) : null}

                                    <a className='btn btn-link'
                                       style={{float: 'right'}}
                                       onClick={() => this.marketingEvents.allData()}> <Icon type='plus'/> Add More</a>

                                </Card>

                                <Form.Item>
                                    <Button type='primary' htmlType='submit' loading={this.props.loading}
                                            className={'btn'}>
                                        SAVE
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    currentUser: global.currentUser,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditMarketingUser)
