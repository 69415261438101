import { Col, DatePicker, Row, Select } from 'antd'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'

import {
  DefaultTablePagination,
  InputBox,
  departmentObj,
  newFormatDisplayDate, dateFilterRange, filterOption
} from '../../../components/_utils/appUtils'
import { listAllUsers } from '../../users/actions/user'
import UpdateWithDrawalStatus from './updateStatus'
import { getUrlPushWrapper } from '../../../routes'
import { expenseWithdrawalCanadaFxn } from '../../dailyExpense/actions/dailyExpense'

let initFilters = {
  marketingUserId: '',
  status: '',
  fromDate: '',
  toDate: '',
  expenseType: ''
}
const { Option } = Select

const { RangePicker } = DatePicker
const statusList = [
  'Pending',
  'Approved',
  'Cancel'
]

const FilterComponent = (props) => {
  let dispatch = useDispatch()
  let { filterData, events } = props
  let [marketingUserList, setMarketingUserList] = useState([])
  const loadMarketingUsers = async () => {
    let obj = {
      results: 100,
      select: ['name', 'email', 'mobile'],
      defaultCurrency: 'CAD',
      customQuery: {
        $or: [
          { userType: 'branchUser', department: departmentObj.marketing },
          { userType: 'marketingManager' }
        ]
      }
    }
    let { data } = await dispatch(listAllUsers(obj))
    setMarketingUserList(data)
  }
  useEffect(() => {
    loadMarketingUsers()
  }, [])

  return (
    <Row gutter={12} className={'filter_box'}>
      <Col span={4}>
        <InputBox title={'Search by date'}>
          <RangePicker
            defaultValue={[filterData.fromDate, filterData.toDate]}
            onChange={val => {
              events.updateFilter({ fromDate: val[0], toDate: val[1] })
            }}
            ranges={dateFilterRange}
          />
        </InputBox>
      </Col>
      <Col span={4}>
        <InputBox title={'Search by marketing user'}>
          <Select
            name='Marketing User'
            filterOption={filterOption}
            allowClear={true}
            placeholder={'Marketing User'}
            onChange={item => {
              events.updateFilter({ marketingUserId: item })
            }}
            value={filterData.marketingUserId || undefined}>
            {marketingUserList && marketingUserList.length
              ? marketingUserList.map((item, key) => {
                return (
                  <Option value={item._id} key={key}>
                    {item.name}
                  </Option>
                )
              })
              : null}
          </Select>
        </InputBox>
      </Col>

      <Col span={4}>
        <InputBox title={'Search by status'}>
          <Select
            name='Status'
            filterOption={filterOption}
            allowClear={true}
            placeholder={'Status'}
            onChange={item => {
              events.updateFilter({ status: item })
            }}
            value={filterData.status || undefined}>
            {statusList.map((item, key) => {
              return (
                <Option value={item} key={key}>
                  {item}
                </Option>
              )
            })}
          </Select>
        </InputBox>
      </Col>
      <Col span={24}>
        <div className={'btn_group'}>
          <a className={'default_btn'} onClick={events.searchFxn}>Search</a>
          <a className={'default_btn'} onClick={events.clearFxn}>Clear</a>
        </div>
      </Col>
    </Row>
  )
}

const ExpenseWithdrawalList = props => {
  let tableRef = useRef()
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  let [marketingUserList, setMarketingUserList] = useState([])
  let [expense, setExpense] = useState('')
  let [updateType, setUpdateType] = useState('')
  let [filterData, setFilterData] = useState(initFilters)

  useEffect(() => {
    // loadMarketingUsers()
  }, [])
  const ranges = {
    Today: [moment().startOf('day'), moment().endOf('day')],
    'Last Week': [
      moment()
        .subtract(1, 'week')
        .startOf('week'),
      moment()
        .subtract(1, 'week')
        .endOf('week')
    ],
    'Last 15 Days': [moment().subtract(14, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')]
  }


  useEffect(() => {
    filterEvents.setFieldByParams()
  }, [])


  const loadMarketingUsers = async () => {
    let obj = {
      userType: 'branchUser',
      department: departmentObj.marketing,
      results: 100,
      select: ['name', 'email', 'mobile', 'agents', 'assignStateCountry']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setMarketingUserList(data)
  }


  const apiRequest = params => {
    return new Promise(async resolve => {
      let filterResp = await filterEvents.getParamsForApplicationList()
      params = { ...params, ...filterResp }
      let { toDate, fromDate } = params
      if (fromDate) {
        let date = {}
        date.$gte = new Date(fromDate)
        date.$lte = new Date(toDate)
        params.date = date
        delete params['fromDate']
        delete params['toDate']
      }
      if (filterResp.marketingUserId) {
        params.userId = filterResp.marketingUserId
        delete params['marketingUserId']
      }

      let respData = await dispatch(
        expenseWithdrawalCanadaFxn({
          ...params
        })
      )
      resolve(respData)
    })
  }

  const columns = [
    {
      title: 'Sr .No',
      key: '_id',
      dataIndex: '_id',
      width: 80,
      render: (item, record, index) => {
        return <div style={{ width: 50 }}>{index + 1}</div>
      }
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: val => {
        return (
          <div
            // style={{ width: 90 }}
          >
            {newFormatDisplayDate(val)}
          </div>
        )
      }
    },
    {
      title: 'User',
      dataIndex: 'userId',
      key: 'userId',
      render: val => {
        return (
          <div>
            {val && val.name ? val.name : ''}
          </div>
        )
      }
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      searchTextName: 'email',
      width: 300,
      render: (val, record) => {
        return val ? (
          <p>
            {val}
          </p>
        ) : null
      }
    },

    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      searchTextName: 'amount',
      render: (val, record) => {
        let { userId } = record
        return val ? <p>{userId && userId.defaultCurrency ? userId.defaultCurrency : ''}&nbsp; {val}</p> : null
      }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 150,
      render: (val, record) => {
        return (
          <React.Fragment>
            <label
              className={
                val == 'Cancel'
                  ? 'label label-danger'
                  : val == 'Pending'
                    ? 'label label-warning'
                    : val == 'Approved'
                      ? 'label label-success'
                      : 'label'
              }>
              {val}
            </label>
            <br />
          </React.Fragment>
        )
      }
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      searchTextName: 'reason',
      render: (val, record) => {
        return <p>{val}</p>
      }
    },


    {
      title: 'Action',
      dataIndex: 'status',
      key: 'status',
      render: (item, record) => {
        return (
          <>
            {item == 'Pending' ? <>
                <a
                  className={'btn btn-default roundNew sm'}
                  onClick={() => {
                    setExpense(record._id)
                    setVisible(true)
                  }}>
                  Update Status
                </a>

                {/* <a
                  className={'btn btn-default roundNew sm'}
                  onClick={() => {
                    setExpense(record._id)
                    setVisible(true)
                    setUpdateType('Cancel')
                  }}
                  icon={<span className='cancel-icon' />}>
                  <img
                    src='../dist/img/cross.svg'
                    className={'crossBtn'}
                    style={{ height: '11px', marginRight: '4px' }}
                  /> Cancel
                </a>
                <a
                  className={'btn btn-default roundNew sm'}
                  onClick={() => {
                    setExpense(record._id)
                    setVisible(true)
                    setUpdateType('Approved')
                  }}
                  style={{ marginLeft: '10px' }}
                  icon={<span className='cancel-icon' />}>
                  <img
                    src='../dist/img/check-solid.svg'
                    className={'crossBtn'}
                    style={{ height: '11px', marginRight: '4px' }}
                  />
                  Approve
                </a>*/}
              </>
              : null}
          </>
        )
      }
    }
  ]
  let filterEvents = {
    getParams: (field) => {
      let searchParams = new URLSearchParams(window.location.search)
      return searchParams.get(field) ? searchParams.get(field) : ''
    },
    updateFilter: (data) => {
      setFilterData((prevState) => {
        return {
          ...prevState,
          ...data
        }
      })
    },
    setFieldByParams: async () => {
      let { getParams } = filterEvents
      let obj = {}
      _.each(initFilters, (item, key) => {
        if (getParams(key)) {
          obj[key] = getParams(key)
        }
      })
      setFilterData((prevState) => {
        return {
          ...prevState,
          ...obj
        }
      })
      setTimeout(() => {
        if (tableRef && tableRef.current) {
          tableRef.current.reload()
        }
      }, 100)
    },
    getParamsForApplicationList: () => {
      return new Promise(resolve => {
        let obj = {}
        _.each(initFilters, (item, key) => {
          if (filterData[key]) {
            obj[key] = filterData[key]
          }
        })
        resolve(obj)
      })
    },
    searchFxn: async () => {
      let obj = {}
      _.each(initFilters, (item, key) => {
        if (filterData[key]) {
          obj[key] = filterData[key]
        }
      })
      dispatch(getUrlPushWrapper('expenseCanada.cadExpenseWithDrawList', { ...obj }))
      setTimeout(() => {
        if (tableRef && tableRef.current) {
          tableRef.current.reload()
        }
      }, 200)
    },
    clearFxn: () => {
      let obj = {}
      dispatch(getUrlPushWrapper('expenseCanada.cadExpenseWithDrawList', { ...obj }))
      setFilterData(initFilters)
      setTimeout(() => {
        tableRef.current.reload()
      }, 500)
    }
  }

  return (
    <>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>Expense Withdrawal List</h5>
              <div className='search-box-table' />

            </div>

            <div className='card-body table-responsive '>
              <FilterComponent filterData={filterData} events={filterEvents} />

              <TableComp
                columns={columns}
                apiRequest={apiRequest}
                ref={tableRef}
                pagination={DefaultTablePagination()}
              />
            </div>
            {visible ? (
              <UpdateWithDrawalStatus
                withDrawlId={expense}
                visible={visible}
                onClose={() => {
                  setVisible(false)
                  tableRef.current.reload()
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default ExpenseWithdrawalList
