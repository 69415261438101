import React, { useState, useEffect } from 'react'
import { Icon, Tabs, Button, Drawer, Timeline, Card, Form, Input, Row, Col, notification, Tag, Tooltip } from 'antd'
import moment from 'moment'
import {
  getApplicationNotes,
  getApplicationActivity,
  communicationActivities,
  emailActivities,
  smsActivities,
  getApplicationSingleNotes, getSingleAppComment
} from '../../actions/chooseStudent'
import { connect, useDispatch } from 'react-redux'
import { InputBox, displayDate, longDisplayDate } from '../../../../components/_utils/appUtils'
import { submitComment } from '../../../student/actions/student'
import { useSelector, shallowEqual } from 'react-redux'
import lodash from 'lodash'
import async from 'async'
import renderHTML from 'react-render-html'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ActivityComponent from '../applicationProfile/activityComponent'

const { TabPane } = Tabs
const { TextArea } = Input
const initialState = {
  document: {},
  note: '',
  documentKey: moment(),
  commentDrawerVisible: false,
  noteId: ''
}


const NotesBlock = (props) => {
  const dispatch = useDispatch()
  let [state, setState] = useState(initialState)
  let [notes, setNotes] = useState([])
  let [smsArr, setSmsArr] = useState([])
  let [emailArr, setEmailArr] = useState([])
  let [commentArr, setCommentArr] = useState([])
  let { applicationId, studentId, showNoteDrawer } = props
  useEffect(() => {
    loadNotes()
  }, [])

  const setStateData = (data) => {
    Promise.resolve()
      .then(() => {
        setState({
          ...state,
          ...data
        })
      })
      .then(() => {

      })
  }

  const loadNotes = async () => {
    let { data } = await dispatch(getApplicationNotes({ applicationId, studentId }))
    setNotes(data)
  }
  const loadSingleNotes = async (noteId) => {
    let { data, success } = await dispatch(getApplicationSingleNotes({ applicationId, noteId }))
    dispatch({ type: 'END_PUSH_NOTE_BY_REDUX' })
    setTimeout(() => {
      if (success) {
        let noteArr = lodash.clone(notes)
        let findObj = lodash.find(noteArr, (item) => {
          return item._id.month === data._id.month && item._id.year === data._id.year
        })
        if (findObj) {
          if (!findObj.items) {
            findObj.items = []
          }
          let findId = lodash.find(findObj.items, (item1) => {
            return item1._id == data.items[0]._id
          })
          if (!findId) {
            findObj.items.unshift(data.items[0])
            setNotes(noteArr)
          }
        } else {
          noteArr.unshift(data)
          setNotes(noteArr)
        }
      }
    }, 500)
  }


  const loadComments = async () => {
    let { data } = await dispatch(getSingleAppComment({ studentId, applicationId }))
    setCommentArr(data)
  }

  const resp = useSelector(state => {
    let { loadNoteRedux, loadEmailRedux, loadSmsRedux, noteId } = state.chooseApplication
    if (loadNoteRedux) {
      loadNotes()
      dispatch({ type: 'END_LOAD_NOTE' })
    }

    if (noteId) {
      loadSingleNotes(noteId)
    }
  })


  const events = {
    showCommentDrawer: (noteId) => {
      setState({ ...state, commentDrawerVisible: true, noteId: noteId })
    },
    hideCommentDrawer: () => {
      setState({ ...state, commentDrawerVisible: false })
    },
    setLocalState: (e) => {
      let { name, value } = e.target
      setState({
        ...state,
        [name]: value
      })
    },
    commentSubmit: async () => {
      if (!state.noteId) {
        return notification.error({
          message: 'Please try again.'
        })
      }
      if (!state.comment) {
        return notification.error({
          message: 'Please enter comment.'
        })
      }
      let obj = {
        noteId: state.noteId,
        comment: state.comment
      }
      let { comment, success } = await dispatch(submitComment(obj))
      if (success) {
        let noteArr = lodash.clone(notes)

        lodash.each(noteArr, (item) => {
          let findObj = lodash.find(item.items, (item) => {
            return item._id === state.noteId
          })
          if (findObj) {
            findObj.comments.unshift(comment)
          }
        })

        setNotes(noteArr)
        setState({
          ...state,
          commentDrawerVisible: false,
          noteId: '',
          comment: ''
        })
      }
      // loadSingleNotes(state.noteId)
      // loadNotes()
    }
  }

  const sortSubject = (data) => {
    if (data) {
      if (data.length > 80) {
        data = data.substring(1, 80)
        return data + '...'
      } else {
        return data
      }
    } else {
      return null
    }
  }

  return (
    <div className={'profile-12-column noteBlock noteBlockAgent'}>
      <div className="student-tab">
        <ul className="nav nav-tabs card" id="myTab" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" id="activity-tab" data-toggle="tab" href="#activity" role="tab"
               aria-controls="activity" aria-selected="false">activity</a>
          </li>
          <li className="nav-item">
            <a className="nav-link " id="Notes-tab" data-toggle="tab" href="#notes" role="tab"
               aria-controls="notes" aria-selected="true">Notes</a>
          </li>

        </ul>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="activity" role="tabpanel" aria-labelledby="activity-tab">
            <div className={'scrollView'}>
              <div className="card application-activity">
                {applicationId ?
                  <ActivityComponent studentId={studentId} applicationId={applicationId}/> : null}
              </div>
            </div>
          </div>

          <div className="tab-pane fade" id="notes" role="tabpanel" aria-labelledby="Notes-tab">
            <div className={'scrollView'}>
              <div style={{ paddingLeft: 20 }}>
                {!notes.length ?
                  <div className={'dateLabel'} style={{ background: 'white', textAlign: 'right', padding: 20 }}>
                    <button className="btn" type="button" onClick={showNoteDrawer}>+ create note</button>
                  </div> : ''}

                {notes && notes.length ? notes.map((data, key1) => {
                  return (
                    <div className="box" key={key1}>
                      <div className="head d-flex align-items-center">
                        <h5>{moment().month(data._id.month - 1).format('MMMM')}, {data._id.year}</h5>
                        {key1 == 0 ?
                          <button className="btn" type="button" onClick={showNoteDrawer}>+ create note</button> : null}

                      </div>

                      {data && data.items && data.items.length ? data.items.map((item, key) => {
                        return (
                          <div className="inner card" key={key} style={{ marginBottom: 10 }}>
                            <img src="/dist/img/edit-icon.svg" alt="" className="edit"/>
                            <div className={'noteTitle'}>
                              <h5>note</h5>
                              <span className={'date'}>
                              {longDisplayDate(item.createdAt)}
                              </span>
                            </div>
                            <p>
                              {item.assignedUserId && item.assignedUserId.name ?
                                <strong>@{item.assignedUserId.name} </strong>
                                : null}
                              {item.note}
                            </p>
                            <div className={'paddingHz'}>
                              {item.attachment && item.attachment.url ?
                                <Tooltip title={item.attachment.name}>
                                  <a download={item.attachment.name} href={item.attachment.url}>
                                    <Tag>{item.attachment.name}</Tag>
                                  </a>
                                </Tooltip>
                                : null}
                            </div>
                            <span><img src='../../btnImgs/user.png'
                                       alt=""/> {item.createdByUser.name} left a note.</span>

                            <div>
                              {item.comments && item.comments.length ? item.comments.map((eacCom, key1) => {
                                return (
                                  <React.Fragment key={key}>
                                    <div className={'commentBox'}>
                                      <Row>
                                        <Col span={2} className={'avatar'}>
                                          <Icon type={'user'}></Icon>
                                        </Col>
                                        <Col span={22}>
                                          <div className={'noteTitle'}>
                                            <div>
                                              @{eacCom.addedByUserId && eacCom.addedByUserId.name ? eacCom.addedByUserId.name : null}
                                            </div>
                                            <span className={'date'}><Icon
                                              type="calendar"/> {longDisplayDate(eacCom.time)}</span>
                                          </div>
                                          <div className={'commentText'}>
                                            {eacCom.text}
                                          </div>
                                        </Col>

                                      </Row>
                                    </div>

                                  </React.Fragment>
                                )
                              }) : null}
                            </div>
                            <br/>
                            <button type="button" onClick={() => {
                              events.showCommentDrawer(item._id)
                            }}>+ add comment
                            </button>
                          </div>
                        )
                      }) : null}


                    </div>
                  )
                }) : null}
              </div>
            </div>
          </div>

        </div>


      </div>
      <Drawer width={700}
              title={'Comment'}
              placement="left"
              visible={state.commentDrawerVisible}
              onClose={events.hideCommentDrawer}>
        <Card bordered={false}>
          <Form>
            <InputBox title={'Comment'}>
              <TextArea rows={5} onChange={events.setLocalState} name={'comment'} placeholder={'Comment'}
                        value={state.comment}/>
            </InputBox>
            <Form.Item className={'mt20 alignRight'}>
              <Button type="primary" onClick={events.commentSubmit}>
                SUBMIT
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Drawer>
    </div>
  )
}


const mapStateToProps = ({ global, router, chooseApplication }) => ({})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(NotesBlock)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedComponent)

