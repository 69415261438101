import React, { useState, useEffect, useRef } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Button,
  Card, Col, Icon,
  Popconfirm, Row, Select,
  Tooltip
} from 'antd'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { notification } from 'antd'
import { getUrlPushWrapper } from '../../../routes'
import { listAllCampus, deleteCampus } from '../actions/campus'
import { DefaultTablePagination, filterOption } from '../../../components/_utils/appUtils'
import { push } from 'connected-react-router'
import { listAllCountries } from '../../countries/actions/countries'
import { listAllStates } from '../../state/actions/state'
import { listAllUniversities } from '../../university/actions/university'
import { listAllCities } from '../../city/actions/city'

const { Option } = Select

const ListAllCampus = () => {
  let [allUniversity, setAllUniversity] = useState([])
  let [allCities, setAllCities] = useState([])

  let [allCountry, setAllCountry] = useState([])
  let [allState, setAllState] = useState([])
  let [campusName, setCampusName] = useState('')
  let [countryId, setCountryId] = useState('')
  let [universityId, setUniversityId] = useState('')
  let [filters, setFilters] = useState({})

  let [tableSearch, setTableSearch] = useState(false)
  const { totalCampus } = useSelector((state) => ({
    totalCampus: state.campusReducers.totalCampus
  }))

  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.sortField = 'campusName'
      params.sortOrder = 'ascend'
      let resp = await getParamsForFilter()
      if (resp.countryId) {
        params.campusCountry = resp.countryId
      }
      if (resp.campusName) {
        params.campusName = resp.campusName
      }
      if (resp.universityId) {
        params.campusUniversity = resp.universityId
      }
      let page = params.page
      if (tableSearch) {
        params.page = 1
      }
      if (!params.results) {
        params.results = 50
        params.count = 50
      }

      resolve(dispatch(listAllCampus({ ...params, page, regExFilters: ['campusAddress', 'campusName'] })))
      setTableSearch(false)
    })
  }
  const [itemNo, setItemNo] = useState(1)
  const confirmDelete = async (id) => {
    let resp = await dispatch(deleteCampus(id))
    if (resp && resp.success) {
      notification.warning({ message: resp.message })
      tableRef.current.reload()
    }
  }
  useEffect(() => {
    loadAllCountry()
    loadAllState()
    loadAllUniversity()
    loadAllCities()
    events.setFieldByParams()
  }, [])

  const loadAllCountry = async () => {
    let { data } = await dispatch(listAllCountries({
      results: 1000,
      sortField: 'countryName',
      sortOrder: 'ascend'
    }))
    setAllCountry(data)
  }

  const loadAllState = async () => {
    let { data } = await dispatch(listAllStates({
      results: 1000,
      sortField: 'stateName',
      sortOrder: 'ascend'
    }))
    setAllState(data)
  }
  const loadAllCities = async () => {
    let { data } = await dispatch(listAllCities({
      results: 1000,
      sortField: 'cityName',
      sortOrder: 'ascend'
    }))
    setAllCities(data)
  }

  const loadAllUniversity = async () => {
    let { data } = await dispatch(listAllUniversities({
      results: 1000,
      sortField: 'universityName',
      sortOrder: 'ascend',
      select: ['universityName']
    }))
    setAllUniversity(data)
  }
  const getParamsForFilter = () => {
    return new Promise((resolve) => {
      let searchParams = new URLSearchParams(window.location.search)
      let countryId = searchParams.get('countryId')
      let campusName = searchParams.get('campusName')
      let universityId = searchParams.get('universityId')
      let obj = {}
      if (countryId) {
        obj.countryId = countryId
      }
      if (campusName) {
        obj.campusName = campusName
      }
      if (universityId) {
        obj.universityId = universityId
      }
      resolve(obj)
    })
  }
  const events = {
    searchData: async () => {
      let obj = {}
      if (countryId) {
        obj.countryId = countryId
      }
      if (campusName) {
        obj.campusName = campusName
      }
      if (universityId) {
        obj.universityId = universityId
      }

      dispatch(
        getUrlPushWrapper('masters.allCampus', {
          ...obj
        }))
      setTimeout(() => {
        tableRef.current.reload()
      }, 200)
    },
    setFieldByParams: async () => {
      let resp = await getParamsForFilter()
      if (resp.campusName) {
        setCampusName(resp.campusName)
      }
      if (resp.countryId) {
        setCountryId(resp.countryId)
      }
      if (resp.universityId) {
        setUniversityId(resp.universityId)
      }
    },
    goBack: () => {
      dispatch(getUrlPushWrapper('dashboard'))
    },
    clearFxn: () => {
      setUniversityId('')
      setCampusName('')
      setCountryId('')
      dispatch(
        getUrlPushWrapper('masters.allCampus'))
      setTimeout(() => {
        tableRef.current.reload()
      }, 200)

    }

  }

  const columns = [

    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (item, record, index) => {
        return <a className={'linkPointer'} onClick={() => {
          dispatch(
            getUrlPushWrapper('masters.editCampus', {
              id: record._id
            }))
        }}>
          {(itemNo - 1) * 10 + index + 1}
        </a>
      }
    },
    {
      title: 'Name',
      dataIndex: 'campusName',
      width: 150,
      key: 'campusName',
      // searchTextName: 'campusName',
      render: (item, record) => {
        return (
          <a className={'linkPointer'} onClick={() => {
            dispatch(
              getUrlPushWrapper('masters.editCampus', {
                id: record._id
              }))
          }}>
            {item}
          </a>
        )
      }

    },
    {
      title: 'University',
      dataIndex: 'campusUniversity.universityName',
      width: 150,
      key: 'campusUniversity'
      // filters: allUniversity.map(x => ({ value: x._id, text: x.universityName }))
      // searchTextName: 'campusUniversity',

    },
    {
      title: 'Country',
      key: 'campusCountry',
      width: 150,
      dataIndex: 'campusCountry.countryName'
      // filters: allCountry.map(x => ({ value: x._id, text: x.countryName }))
      // searchTextName: 'campusCountry',
    },
    {
      title: 'State',
      width: 150,
      key: 'campusState',
      dataIndex: 'campusState.stateName',
      filters: allState.map(x => ({ value: x._id, text: x.stateName }))
      // searchTextName: 'state',

    },
    {
      title: 'City',
      width: 150,
      key: 'campusCity',
      dataIndex: 'campusCity.cityName',
      filters: allCities.map(x => ({ value: x._id, text: x.cityName }))
      // searchTextName: 'campusCity'

    },

    {
      title: 'Address',
      key: 'campusAddress',
      width: 150,
      dataIndex: 'campusAddress',
      searchTextName: 'campusAddress'
    },
    /*{
      title: 'Admission Person',
      key: 'AdmissionPersonName',
      width: 150,
      dataIndex: 'AdmissionPersonName'
      // searchTextName: 'AdmissionPersonName',
    },
    {
      title: 'Admission Person Email',
      key: 'AdmissionPersonEmail',
      width: 150,
      dataIndex: 'AdmissionPersonEmail'
      // searchTextName: 'AdmissionPersonEmail',
    },
    {
      title: 'Admission Person Mobile',
      key: 'AdmissionPersonMobile',
      width: 150,
      dataIndex: 'AdmissionPersonMobile'
      // searchTextName: 'AdmissionPersonMobile',
    },
    {
      title: 'Additional requirements',
      key: 'additionalRequirments',
      width: 150,
      dataIndex: 'additionalRequirments'
      // searchTextName: 'additionalRequirments',
    },*/

    {
      key: 'actions',
      title: 'Actions',
      // fixed: 'right',
      width: 80,
      render: (text, record) => {
        return <React.Fragment>
          <Tooltip title="Edit">
            <button
              className={'btn'}
              style={{ marginRight: 6 }}
              onClick={() => {
                dispatch(
                  getUrlPushWrapper('masters.editCampus', {
                    id: record._id
                  }))
              }}
            >
              <Icon type={'edit'}/>
            </button>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm title={'Are your sure, you want to delete campus?'}
                        onConfirm={() => {
                          confirmDelete(record._id)
                        }}
                        okText="Yes" cancelText="No">
              <button
                className={'btn'}
                style={{ marginRight: 6 }}>
                <Icon type={'delete'}/>
              </button>
            </Popconfirm>
          </Tooltip>

        </React.Fragment>

      }
    }
  ]


  return (
    <div className="row mt-4">
      <div className="col-lg-12">
        <div className="card">
          <div className="table-head d-flex align-items-center">
            <Button onClick={() => events.goBack()}
                    className={'roundGoBackBtn'} icon={'arrow-left'}>
              Back
            </Button>
            <h5>All Campus: {totalCampus || 0}</h5>
            <div className="search-box-table">

            </div>
            <div className="sort-box-table mark-btn">
              <a className="btn" onClick={() => dispatch(push('/masters/add-campus'))}>
                <img src={'../dist/icons/plus.png'} className={'plus'}/>
                Add Campus</a>
            </div>
          </div>
          <div className="card-body table-responsive">

            <Row gutter={16}>
              <Col span={6}>
                <div className="sort-box-table custom-sort-box-new">
                  <Select placeholder={'Country'}
                          value={countryId || undefined}
                          filterOption={filterOption}
                          className={'antSelect'}
                          allowClear={true}
                          showSearch={true}
                          onChange={(x) => setCountryId(x)}>
                    {allCountry.map((item, key) => {
                      return (
                        <Option key={key} value={item._id}>{item.countryName}</Option>
                      )
                    })}
                  </Select>
                </div>
              </Col>
              <Col span={6}>
                <div className="sort-box-table custom-sort-box-new">
                  <Select placeholder={'University'}
                          value={universityId || undefined}
                          filterOption={filterOption}
                          className={'antSelect'}
                          allowClear={true}
                          showSearch={true}
                          onChange={(x) => setUniversityId(x)}>
                    {allUniversity && allUniversity.length ? allUniversity.map((item, key) => {
                      return (
                        <Option key={key} value={item._id}>{item.universityName}</Option>
                      )
                    }) : null}
                  </Select>
                </div>
              </Col>
              <Col span={6}>
                <div className="sort-box-table custom-sort-box-new">
                  <div className="search-box-table round">
                    <input className="form-control form-control" type="search"
                           placeholder="Campus Name" value={campusName} onChange={(e) => {
                        setCampusName(e.target.value)
                    }}/>
                      <img src="/dist/img/search.png" alt=""/>
                  </div>
                </div>
              </Col>

              <Col span={6}>
                <Button onClick={() => events.searchData()} className={'roundBtn'}>Search</Button>
                <Button onClick={() => events.clearFxn()} className={'roundBtn'}>
                  Clear
                </Button>
              </Col>

            </Row>
            <TableComp columns={columns}
                       apiRequest={apiRequest}
                       pagination={{
                         position: 'top',
                         pageSizeOptions: ['50', '100', '200', '500'],
                         defaultPageSize: 50,
                         current: filters.page ? filters.page : 1
                       }}
                       ref={tableRef}
              // extraProps={{ scroll: { x: 600 } }}

            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListAllCampus
